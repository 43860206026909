import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Paper,
  Card,
  CardContent,
  Select,
  MenuItem,
  TablePagination,
  TableFooter,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputBase,
  AppBar,
  Tabs,
  Tab,
  TableContainer,
  // Modal,
  // Backdrop
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import Modal from "../../../hoc/modal/modal";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./faceToFaceReassign.style";
import { useAlert } from "../../../hoc/alert/alert";
import DownloadLink from "react-download-link";
import CloseIcon from '@material-ui/icons/Close';

const FaceToFaceReassign = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [startDate, setStartDate] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [endDate, setEndDate] = useState(null);
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [viewOpenning, setViewOpennings] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDate, setSelectedDate] = useState(" ");
  const [selectedTime, setSelectedTime] = useState(" ");
  const [assignOpen, setAssignOpen] = useState(false);
  const [duration, setdDration] = useState();
  const [interviewerFree, setInterviewerFree] = useState({ response: null });
  const [selectedInterviewer, setSelectedInterviewer] = useState("");
  const [applicantId, setApplicantId] = useState(null);
  const [applicentName, setApplicentName] = useState("");
  const [value, setValue] = React.useState(0);
  const [isCancelled, setIsCancelled] = useState(false);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [f2fData, setFace2faceData] = useState(null);
  const [gradeCheck, setGradeCheck] = useState('');
  const [subjectCheck, setSubjectCheck] = useState('');
  const [branch, setBranch] = useState("");
  const [checked, setChecked] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const statusDetails = [
    { statusId: 1, statusName: "Re-Scheduled" },
    { statusId: 2, statusName: "Cancelled" },
  ];
  const alert = useAlert();

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList,
  } = useFetch([], { suppressAlert: true });

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings,
  } = useFetch([], { suppressAlert: true });

  const {
    data: cancelInterviewData,
    isLoading: loadingCancelInterviewData,
    doFetch: fetchcancelInterviewData,
  } = useFetch(null);

  const {
    data: roleData,
    isLoading: roleDataLoading,
    doFetch: fetchRole,
  } = useFetch("", { suppressAlert: true });

  const {
    // data: f2fData,
    isLoading: gettingF2Fdata,
    doFetch: fetchF2Fdetails,
  } = useFetch(null);

  const {
    data: isInterviewerFree,
    isLoading: isInterviewerFreeLoading,
    doFetch: fetchIsInterviewerFree,
  } = useFetch([], { suppressAlert: true });

  const {
    data: assignSpokenTest,
    isLoading: assignSpokenTestLoading,
    doFetch: fetchAssignSpokenTest,
  } = useFetch([], { suppressAlert: true });

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch("", { suppressAlert: true });

  const {
    data: interviewerList,
    isLoading: interviewerListLoading,
    doFetch: fetchInterviewerList,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branchesList,
    isLoading: gettingBranches,
    doFetch: fetchBranches
  } = useFetch([], { suppressAlert: true });

  const {
    data: grades,
    isLoading: gradesLoading,
    doFetch: fetchGrades
  } = useFetch([], { suppressAlert: true });

  const {
    data: subjects,
    isLoading: subjectsLoading,
    doFetch: fetchSubjects
  } = useFetch([], { suppressAlert: true });

  const { data: gradeList, doFetch: fetchGradeList } = useFetch(null);

  const { data: cityList, doFetch: fetchCityList } = useFetch([], { suppressAlert: true });



  function OpenViewDetails(ID) {
    fetchApplicantDetail({
      url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (isInterviewerFree && isInterviewerFree.response) {
      setInterviewerFree(isInterviewerFree);
    }
  }, [isInterviewerFree]);

  useEffect(() => {
    if (selectedRole) {
      fetchInterviewerList({
        url: `${urls.interviewersRoleWiseEmployeeApi}?role_id=${selectedRole}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [selectedRole]);

  useEffect(() => {
    if (applicantDetail) {
      setOpen(true);
    }
  }, [applicantDetail]);

  const handleClose = () => {
    setOpen(false);
    setAssignOpen(false);
    setShowEdit(false);
  };

  let loader = null;
  if (
    gettingRoleList ||
    interviewerListLoading ||
    isInterviewerFreeLoading ||
    assignSpokenTestLoading ||
    gettingViewOpennings ||
    gettingF2Fdata ||
    applicantDetailLoading ||
    roleDataLoading ||
    loadingCancelInterviewData ||
    cancelLoader ||
    loading ||
    gettingBranches ||
    gradesLoading ||
    subjectsLoading

  ) {
    loader = <Loader open />;
  }

  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchGrades({
        url: `${urls.newGrade}?position_type=${positionType}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });
      fetchCityList({
        url: urls.cityList,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setChecked("");
      setCheckedLoc("");
      setBranch("");
      setGradeCheck("");
      setSubjectCheck("");
    }
  }, [positionType]);

  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [RoleId, positionType, branch, checkedLoc, subjectCheck, gradeCheck]);

  const handleTabChange = (event, newValue) => {
    setFace2faceData(null);
    setIsCancelled(statusDetails[newValue].statusId === 2);
    setPositionType("");
    setBranch("");
    setCheckedLoc("");
    setRoleId("");
    setGradeCheck("");
    setStartDate(null);
    setEndDate(null);
    setSubjectCheck("");
    setViewOpennings("");
    setPage(0);
    setValue(newValue);
  };

  const cancelInterview = (id) => {
    setCancelLoader(true);
    fetch(urls.cancelInterviewAPI, {
      method: "PUT",
      body: JSON.stringify({ id }),
      headers: {
        Authorization: `Bearer ${name.personal_info.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setCancelLoader(false);
        if (res.status === 200) {
          alert.success("Successfully Fetched");
          functiontoSearchF2FDetails();
          return res.json();
        }
        // if (res.status !== 200) {
        //   alert.warning('something went wrong please try again ');
        // }
        return 0;
      })
      .catch((error) => {
        setCancelLoader(false);

        alert.warning("Something went wrong. Please try again");
      });
  };

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(10);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(10);
  }

  const fetchFace2FaceDetails = (link) => {
    setLoading(true);
    fetch(link, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${name.personal_info.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          alert.success("Successfully Fetched");
          return res.json();
        }
        // if (res.status !== 200) {
        //   alert.warning("Filter the fields to get data ");
        // }
        // return 0;
      })
      .then((data) => {
        setLoading(false);
        setFace2faceData(data);
      })
      .catch((error) => {
        // alert.error('Something went wrong. Please try again.');
      });
  };
  function functiontoSearchF2FDetails() {
    // if (!RoleId || !positionType) {
    //   alert.warning("Fill all details");
    //   return;
    // }
    fetchFace2FaceDetails(
      `${urls.f2fReassignApplicentsApi
      }?role_id=${RoleId}&position_type=${positionType}&opening_id=${viewOpenning}&page_size=${rowsPerPage}&page=${page +
      1}&start_date=${startDate}&end_date=${endDate}&cancelled=${isCancelled}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`
    );
    /* fetchFace2FaceDetails({
       url: `${urls.f2fReassignApplicentsApi}?role_id=${RoleId}&openning_id=${viewOpenning}&page_size=${rowsPerPage}&page=${page + 1}&start_date=${startDate}&end_date=${endDate}&cancelled=${isCancelled}`,
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
         Authorization: `Bearer ${name.personal_info.token}`,
       },
     })*/
  }
  //comment
  useEffect(() => {
    if (RoleId && positionType) {
      functiontoSearchF2FDetails();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (assignSpokenTest) {
      functiontoSearchF2FDetails();
      setInterviewerFree({ response: null });
      setAssignOpen(false);
      setdDration("");
      setSelectedInterviewer("");
      setSelectedDate("");
      setSelectedTime("");
    }
  }, [assignSpokenTest]);

  const assignZoom = () => {
    if (!selectedInterviewer || !selectedTime || !selectedDate || !duration) {
      alert.warning("Select all the fields");
      return;
    }
    fetchIsInterviewerFree({
      url: `${urls.checkRescheduleInterviewFree
        }?user=${selectedInterviewer}&applicant=${applicantId}&duration=${duration}&start_time=${`${selectedDate},${selectedTime}:00`}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  useEffect(() => {
    if (interviewerFree.response === "success") {
      const obj = {
        applicant: applicantId,
        user: selectedInterviewer,
        // topic: selectTopic,
        duration: duration,
        start_time: `${selectedDate},${selectedTime}:00`,
      };
      fetchAssignSpokenTest({
        url: urls.reScheduleZoomMeeting,
        method: "post",
        body: obj,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [interviewerFree, fetchAssignSpokenTest, name]);

  const assignModal = (
    <React.Fragment>
      <Modal
        className={classes.model}
        click={handleClose}
        open={assignOpen}
        large
        style={{ padding: "15px" }}
      >
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Typography className="headings" variant="h4">
            <strong>Re Schedule</strong>
          </Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={2} className={classes.gride}>
          <Grid item md={4} xs={12}>
            <TextField
              label="Role"
              margin="dense"
              required
              fullWidth
              className="textList"
              select
              // SelectProps={{ multiple: true }}
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              variant="outlined"
            >
              {roleData &&
                roleData &&
                roleData.map((data) => (
                  <MenuItem value={data.id} key={data.id} name={data.role_name}>
                    {data.role_name ? data.role_name : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          {selectedRole && (
            <Grid item md={4} xs={12}>
              <TextField
                margin="dense"
                required
                label="Interviewer"
                fullWidth
                className="textList"
                select
                value={selectedInterviewer}
                onChange={(e) => setSelectedInterviewer(e.target.value)}
                variant="outlined"
              >
                {interviewerList &&
                  interviewerList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.first_name}
                    >
                      {data.first_name ? data.first_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
          <Grid item md={4} xs={12}>
            <TextField
              // className={classes.textField}
              helperText="Select Date"
              type="date"
              margin="dense"
              fullWidth
              className="textList"
              onChange={(e) => setSelectedDate(e.target.value)}
              required
              value={selectedDate || " "}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              // className={classes.timeField}
              helperText="Select Time"
              type="time"
              margin="dense"
              fullWidth
              className="textList"
              onChange={(e) => setSelectedTime(e.target.value)}
              required
              value={selectedTime || " "}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              // className={classes.timeField}
              label="Duration in minutes"
              type="number"
              margin="dense"
              fullWidth
              className="textList"
              onChange={(e) => setdDration(e.target.value)}
              required
              value={duration || " "}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              variant="contained"
              onClick={assignZoom}
            >
              Re Schedule
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );

  function AssignFuncion(id) {
    fetchRole({
      url: urls.interviewPanalRolesApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
    setApplicantId(id);
    setAssignOpen(true);
  }

  let modal = null;
  if (applicantDetail) {
    modal = (
      <Modal
        className={classes.model}
        click={handleClose}
        large
        style={{ padding: "15px" }}
        open={open}
      >
        <React.Fragment>
          <div style={{display: "flex", justifyContent: "space-between"}}>
          <Typography className="headings" variant="h4">
            <strong>Details</strong>
          </Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        </div>
          <Divider className={classes.divider} />
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} className={classes.gridOne}>
              <Typography className="textDisplay" variant="h6">
                <strong> Name :</strong>{" "}
                {applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.user &&
                  applicantDetail.applicant.user.first_name}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridOne}>
              <Typography className="textDisplay" variant="h6">
                <strong> PhoneNo : </strong>
                {applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.contact_no}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} className={classes.gridOne}>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong> Email : </strong>{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.email) ||
                    ""}
                </Typography>

                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Email:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.email) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>

                <Typography className="textDisplay" variant="h6">
                  <strong> Grade : </strong>{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name) ||
                    ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Grade:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong> Role : </strong>{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name) ||
                    ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Role:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong>  Position Applied : </strong>{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant.position_apply &&
                    applicantDetail.applicant.position_apply.title) ||
                    ""}
                </Typography>

                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Position Applied:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant.position_apply &&
                    applicantDetail.applicant.position_apply.title) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong> Branch Interested : </strong>{" "}
                  {(applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name) ||
                    ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Branch Interested:{" "}
                  {(applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong> Application Date : </strong>{" "}
                  {applicantDetail.applicant.register_date || ""}
                </Typography>

                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Application Date:{" "}
                  {applicantDetail.applicant.register_date || ""}
                </b> */}
              </div>
            </Grid>
            <Grid item md={4} xs={6} >
              <Typography className="textDisplay" variant="h5"> <strong>Resume</strong></Typography>
            </Grid>
            <Grid item md={6} xs={6} className={classes.item}>
              {applicantDetail.applicant &&
                applicantDetail.applicant.resume ? (
                <a
                  target='_blank'
                  href={applicantDetail.applicant.resume || ""}
                  onclick="window.open(this.href); return false;"
                >
                  {" "}
                  <Typography className="textDisplay" variant="h5"> View Resume</Typography>
                </a>
              ) : (
                "Resume Not Uploaded"
              )}
            </Grid>
            <Grid item md={4} xs={12} className={classes.gridOne}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    1.MCQ{" "}
                    {applicantDetail && applicantDetail.mcq_test_completed ? (
                      <a>
                        {" "}
                        Completed{" "}
                        <Typography variant="h6">
                          Marks=
                          {(applicantDetail &&
                            applicantDetail.mcq_test_completed &&
                            applicantDetail.mcq_score &&
                            applicantDetail.mcq_score.mark_scored) ||
                            "Not Uploaded"}
                        </Typography>
                      </a>
                    ) : (
                      "Pending"
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} className={classes.gridOne}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    2.Written Test{" "}
                    {applicantDetail &&
                      applicantDetail.written_test_completed ? (
                      <a>
                        {" "}
                        Completed{" "}
                        <Typography variant="h6">
                          Marks=
                          {(applicantDetail &&
                            applicantDetail.written_test_completed &&
                            applicantDetail.written_score &&
                            applicantDetail.written_score.mark_scored) ||
                            "Not Uploaded"}
                        </Typography>
                      </a>
                    ) : (
                      "Pending"
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} className={classes.gridOne}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    3.Spoken Test{" "}
                    {applicantDetail &&
                      applicantDetail.spoken_test_completed ? (
                      <a>
                        {" "}
                        Completed{" "}
                        <Typography variant="h6">
                          Marks=
                          {(applicantDetail &&
                            applicantDetail.spoken_test_completed &&
                            applicantDetail.spoken_score &&
                            applicantDetail.spoken_score.mark_scored) ||
                            "Not Uploaded"}
                        </Typography>
                      </a>
                    ) : (
                      "Pending"
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {applicantDetail &&
              applicantDetail.applicant_f2f &&
              applicantDetail.applicant_f2f.map((item, index) => (
                <Grid
                  item
                  md={12}
                  xs={12}
                  className={classes.gridOnee}
                  key={index}
                >
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {4 + index}: {item.round}
                      </Typography>
                      <Typography variant="h6" component="h2">
                        Marks : {item.mark_scored || "pending"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  const fetchSearch = (e) => {
    setPage(0);
    setRowsPerPage(5);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicentName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };

  function functionSearch(data) {
    // fetchApplicant({
    //   url: `${urls.searchApplicantRecruiter}?page=${page +
    //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   }
    // });
  }
  const [fetchingData, setFetchingData] = useState();
  useEffect(() => {
    if (fetchingData) {
      fetchFace2FaceDetails(
        `${urls.f2fReassignApplicentsApi
        }?role_id=${RoleId}&position_type=${positionType}&opening_id=${viewOpenning}&page_size=${rowsPerPage}&page=${page +
        1}&start_date=${startDate}&end_date=${endDate}&cancelled=${isCancelled}&search=${fetchingData}`
      );
    }
  }, [fetchingData]);

  const fetchface2facereassignData = (evt) => {

    setFetchingData(evt.target.value);
  };

  useEffect(() => {
    if (gradeCheck) {
      fetchSubjects({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeCheck}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
      setSubjectCheck("");
    }
  }, [gradeCheck, fetchSubjects])

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
      setBranch("");
    }
  }, [checkedLoc])

  function handleBranch(e) {
    setBranch(e.target.value);
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={6} xs={12}>
          <Typography className="headings" variant="h4"><strong>Re Schedule Face To Face</strong></Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={fetchface2facereassignData}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor="primary"
          color="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {statusDetails &&
            statusDetails.map((statusData) => (
              <Tab
                key={statusData.statusId}
                style={{ marginRight: "4%", marginLeft: "4%" }}
                label={statusData.statusName}
              />
            ))}
        </Tabs>
      </AppBar>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>
              {" "}
              Select Position Type <b style={{ color: "red" }}>*</b>
            </Typography>
            <Select
              required
              fullWidth
              className="textList"
              margin="dense"
              value={positionType || ""}
              onChange={(e) => setPositionType(e.target.value)}
              variant="outlined"
            >
              {positionTypeList.map((option, index) => (
                <MenuItem key={index} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>
              {" "}
              Select Role <b style={{ color: "red" }}>*</b>
            </Typography>
            <Select
              required
              fullWidth
              className="textList"
              margin="dense"
              value={RoleId || ""}
              onChange={(e) => setRoleId(e.target.value)}
              variant="outlined"
            >
              {roleList &&
                roleList.length !== 0 &&
                roleList.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.id}
                    name={option.applicant_role_name}
                  >
                    {option.applicant_role_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>City</Typography>
            <Select
              required
              fullWidth
              // multiple
              margin='dense'
              value={checkedLoc || ""}
              onChange={e => setCheckedLoc(e.target.value)}
              variant='outlined'
            >
              {cityList && cityList.length !== 0 &&
                cityList.map(option => (
                  <MenuItem key={option.value} value={option.id} name={option.id}>
                    {option.city_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>Branch</Typography>
            <Select
              required
              fullWidth
              // multiple
              margin='dense'
              value={branch || ""}
              onChange={handleBranch}
              variant='outlined'
            >
              {branchesList && branchesList.length !== 0 &&
                branchesList.map(option => (
                  <MenuItem key={option.value} value={option.id} name={option.id}>
                    {option.branch_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>{positionType=="Academic" ?"Grade":"Level"}</Typography>
            <Select
              required
              fullWidth
              margin='dense'
              value={gradeCheck || ""}
              onChange={e => setGradeCheck(e.target.value)}
              variant='outlined'
            >
              {grades &&  grades.map(option => (
                <MenuItem key={option.id} value={option.id} name={option.id}>
                  {option.grade_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>{positionType=="Academic" ?"Subject":"Topic"}</Typography>
            <Select
              required
              fullWidth
              margin='dense'
              value={subjectCheck || ""}
              onChange={e => setSubjectCheck(e.target.value)}
              variant='outlined'
            >
              {subjects && subjects && subjects.length > 0 && subjects.map(option => (
                <MenuItem key={option.subject.id} value={option.subject.id} name={option.subject.id}>
                  {option.subject.subject_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>
              Select Opening
            </Typography>
            <Select
              required
              fullWidth
              className="textList"
              margin="dense"
              value={viewOpenning || ""}
              onChange={(e) => setViewOpennings(e.target.value)}
              variant="outlined"
            >
              {viewOpenningsData &&
                viewOpenningsData.length !== 0 &&
                viewOpenningsData.map((option, index) => (
                  <MenuItem key={index} value={option.id} name={option.title}>
                    {option.title}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>Start Date<b style={{ color: "red" }}>*</b></Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={(e) => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>End Date<b style={{ color: "red" }}>*</b></Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={(e) => handleEndDateChange(e.target.value)}
              type="date"
              value={endDate || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xss={12}>
            <Button
              style={{ marginTop: "33px" }}
              variant="contained"
              color="primary"
              onClick={functiontoSearchF2FDetails}
              disabled={startDate && endDate ? false:true}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Divider className={classes.divider} />
      {f2fData && f2fData.results.length === 0 && (
        <Typography
          variant="h4"
          style={{ color: "blue", marginTop: "50px", textAlign: "center" }}
        >
          Records Not Found
        </Typography>
      )}
      {f2fData && f2fData.results.length !== 0 && (
        <Paper>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>S.No.</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Meeting Name</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Name</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Contact No</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Location</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Start Date</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>View</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Re Schedule</strong></Typography>
                      </TableCell>
                      {!isCancelled && (
                        <TableCell align="center" style={{ border: '1px solid black' }}>
                          <Typography><strong>Cancel</strong></Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {f2fData &&
                      f2fData.results.length !== 0 &&
                      f2fData.results.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>{data.meeting_name || ""}</Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>
                              {(data.applicant &&
                                data.applicant.applicant &&
                                data.applicant.applicant.user &&
                                data.applicant.applicant.user.first_name) ||
                                ""}
                            </Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>
                              {(data.applicant &&
                                data.applicant.applicant &&
                                data.applicant.applicant &&
                                data.applicant.applicant.contact_no) ||
                                ""}
                            </Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>
                              {(data.applicant &&
                                data.applicant.applicant &&
                                data.applicant.applicant &&
                                data.applicant.applicant.current_location) ||
                                ""}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            <Typography>{data.start_date || ""}</Typography>
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            <Button
                              color="primary"
                              variant="contained"
                              disabled={!(data.applicant && data.applicant.id)}
                              onClick={() =>
                                OpenViewDetails(
                                  data.applicant && data.applicant.id
                                )
                              }
                            >
                              View
                            </Button>
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() =>
                                AssignFuncion(data.applicant && data.applicant.id)
                              }
                            >
                              Re Schedule
                            </Button>
                          </TableCell>
                          {!isCancelled && (
                            <TableCell align="center" style={{ border: '1px solid black' }}>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => cancelInterview(data && data.id)}
                              >
                                Cancel
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Table>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        style={{ marginTop: "15px" }}
                        colSpan={5}
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={f2fData && f2fData.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                          "aria-label": "previous page",
                        }}
                        nextIconButtonProps={{
                          "aria-label": "next page",
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                      <TableCell>
                        <IconButton
                          onClick={firstPageChange}
                          disabled={page === 0 || page === 1}
                        >
                          <FirstPageIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => lastPageChange(f2fData.total_pages - 1)}
                          disabled={page === +f2fData.total_pages - 1}
                        >
                          <LastPageIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      )}
      {loader}
      {assignModal}
      {modal}
    </React.Fragment>
  );
};

FaceToFaceReassign.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(FaceToFaceReassign);
