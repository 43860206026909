import React, { useState } from 'react'
import Typography from "@material-ui/core/Typography";

const SubjectHeadDashboard = () => {
    const [profile] = useState(JSON.parse(localStorage.getItem('user')));

    return (
        <div>
            <Typography>
                Welcome to <strong style={{ fontSize: '40px', color: 'rgb(68 35 32)' }}>Subject Head Dashboard</strong>({profile?.academic_profile?.name})
            </Typography>

        </div>
    )
}

export default SubjectHeadDashboard;