import React, { useState, useEffect } from 'react';
import {
  TextField,
  withStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './education.style';
import MediaQuery from "react-responsive";
import urls from '../../../../url'
import Loader from '../../../../hoc/loader'
import useFetch from '../../../../hoc/useFetch'
import { useAlert } from "../../../../hoc/alert/alert";

const Education = ({ classes }) => {

  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [roleName, setRoleName] = useState('');
  const [instuteName, setInstuteName] = useState('');
  const [JoiningDate, setJoiningDate] = useState();
  const [passOutDate, setPassOutDate] = useState();
  const [educationDetails, setEducationDetails] = useState([]);
  const [getEducationDetails, setGetEducationDetails] = useState('');
  const [loading, setloading] = useState(false);
  const alert = useAlert();

  const {
    // data: submitEducatiinDetail,
    isLoading: loadingEduactionDetails,
    doFetch: fetchEducationDetail
  } = useFetch(null);

  // const {
  //   data: getEducationDetails,
  //   isLoading:gettingEduactionDetails,
  //   doFetch: fetchEducationDetailData
  // } = useFetch(null);

  useEffect(() => {
    if (getEducationDetails) {
      setEducationDetails(getEducationDetails)
    }
  }, [getEducationDetails])

  useEffect(() => {
    if (name) {
      setloading(true);
      fetch(`${urls.submitBasicProfileApi}?type=1`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          setloading(false);
          alert.success('Successfully Recived');
          return res.json();
        }
        if (res.status !== 200) {
          setloading(false);
          alert.error('something went wrong please try again');
          return res.json();
        }
      }).then((data) => {
        setGetEducationDetails(data);
      })
    }
  }, [alert, name])

  function addDetaisFunction() {
    if (!roleName) {
      alert.warning('Enter Role');
      return;
    } else if (!instuteName) {
      alert.warning('Enter Institute Name');
      return;
    } else if (!JoiningDate) {
      alert.warning('Enter Joining Date');
      return;
    } else if (!passOutDate) {
      alert.warning('Enter passout Date');
      return;
    }
    const UserDate = document.getElementById('passOutDateId').value;
    const joiningInfo = document.getElementById('joiningDate').value;
    if (new Date(UserDate).getTime() < new Date(joiningInfo).getTime()) {
      alert.warning('The passout Date must be Greater Than Joining Date');
      return false;
    }
    let data = educationDetails;
    data.push(
      {
        'role': roleName,
        'instuite_name': instuteName,
        'joining_year': JoiningDate,
        'passing_year': passOutDate,
      }
    )
    setEducationDetails(data);
    setRoleName('');
    setInstuteName('');
    setJoiningDate('');
    setPassOutDate('');
  }

  function removeDetail(index) {
    setEducationDetails((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }
  function submitEducationDetails() {
    if (educationDetails.length === 0) {
      alert.warning('Fill your Education Details');
      return;
    }
    const data = {
      'education': educationDetails
    }
    fetchEducationDetail({
      url: `${urls.submitBasicProfileApi}?type=1`,
      body: data,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }

  let loader = null;
  if (loadingEduactionDetails || loading) {
    loader = <Loader open />;
  }

  function handleJoiningDate(e) {
    const UserDate = document.getElementById('joiningDate').value;
    const ToDate = new Date();
    if (new Date(UserDate).getTime() > ToDate.getTime()) {
      alert.warning('The Joining Date must be less than or equal to today date');
      return false;
    }
    setJoiningDate(e.target.value)
  }

  function handlePassOutDate(e) {
    const UserDate = document.getElementById('passOutDateId').value;
    const joiningInfo = document.getElementById('joiningDate').value;
    const ToDate = new Date();
    if (!JoiningDate) {
      alert.warning('first Enter Joining Date');
      return false;
    }
    if (new Date(UserDate).getTime() < new Date(joiningInfo).getTime()) {
      alert.warning('The passout Date must be Greater Than Joining Date');
      return false;
    }
    if (new Date(UserDate).getTime() > ToDate.getTime()) {
      alert.warning('The passout Date must be less than or equal to today date');
      return false;
    }
    setPassOutDate(e.target.value)
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              label="Enter Role"
              margin="dense"
              required
              fullWidth
              className='textList'
              value={roleName || ''}
              onChange={e => setRoleName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Enter Institute Name"
              margin="dense"
              required
              fullWidth
              className='textList'
              value={instuteName || ''}
              onChange={e => setInstuteName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              helperText="Joining Date *"
              id="joiningDate"
              margin="dense"
              type="date"
              required
              fullWidth
              className='textList'
              value={JoiningDate || ''}
              onChange={e => handleJoiningDate(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              helperText="Passout Date *"
              id="passOutDateId"
              margin="dense"
              type="date"
              required
              fullWidth
              className='textList'
              value={passOutDate || ''}
              onChange={e => handlePassOutDate(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: '8px' }}
              onClick={() => addDetaisFunction()}
              disabled={(!roleName || !instuteName || !JoiningDate || !passOutDate) ? true : false}
            >
              ADD More +
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        {educationDetails && educationDetails.length === 0 &&
          <Typography variant="h5" style={{ color: 'blue', textAlign: 'center' }}>Please Add Your Education Details</Typography>
        }
        {educationDetails && educationDetails.length !== 0 &&
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell float="right">
                        <Typography>S.No</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Role Name</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Institute Name</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Joining Date</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Passout Date</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Remove</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {educationDetails && educationDetails.length !== 0 &&
                      educationDetails.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell float="right">
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.role || ''}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.instuite_name || ''}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.joining_year || ''}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.passing_year || ''}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Button variant="contained" color="primary"
                              onClick={() => removeDetail(index)}>Remove</Button>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        }
        <Divider className={classes.divider} />
        <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={(educationDetails.length === 0) ? true : false}
            onClick={() => submitEducationDetails()}
          >
            Submit
          </Button>
        </Grid>
      </Paper>
      {loader}
    </React.Fragment>
  )
}

Education.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Education);