import React, { useState, useEffect } from "react";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import {
  Form,
  Row,
  Col,
  Select,
  Checkbox,
  DatePicker,
  Button,
  Table,
  message,
} from "antd";
import moment from "moment";
import axios from "axios";
import { useForm } from "antd/es/form/Form";

const PositionReportNew = () => {
  const { Option } = Select;
  const [positionReportForm] = useForm();
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [positionValue, setPositionValue] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const [branch, setBranch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const [roleName, setRoleName] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [add, setAdd] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [checked, setChecked] = useState("");
  const [check, setCheck] = useState(true);

  const [gradeCheck, setGradeCheck] = useState("");
  const [subjectCheck, setSubjectCheck] = useState("");
  const [status, setStatus] = useState("");
  const [bottomHRef, setBottomHRef] = useState("");
  const [dataReport, setDataReport] = useState([]);
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [designationList, setDesignationList] = useState([]);

  const alert = useAlert();

  const {
    data: grades,
    isLoading: gradesLoading,
    doFetch: fetchGrades,
  } = useFetch([], { suppressAlert: true });

  const {
    data: roles,
    isLoading: rolesLoading,
    doFetch: fetchRoles,
  } = useFetch([], { suppressAlert: true });

  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity,
  } = useFetch([], { suppressAlert: true });
  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });
  const {
    data: subjects,
    isLoading: subjectsLoading,
    doFetch: fetchSubjects,
  } = useFetch([], { suppressAlert: true });

  useEffect(() => {
    fetchPositionTypesList();
    fetchGrades({
      url: `${urls.newGrade}`,
      method: "GET",
      flag: false,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, []);

  useEffect(() => {
    if (gradeCheck) {
      fetchSubjects({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeCheck}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [gradeCheck, fetchSubjects]);

  useEffect(() => {
    const start_date = startDate + "T00:00:00";
    const end_date = endDate + "T23:59:59";
    setBottomHRef([
      {
        csv: `${urls.positionReport}?position_type=${positionValue}&start_date=${start_date}&end_date=${end_date}&role=${checked}&active=${check}&branch=${branch}&city=${checkedLoc}&subject=${subjectCheck}&grade=${gradeCheck}&download=true`,
      },
    ]);
  }, [
    setBottomHRef,
    positionValue,
    checked,
    status,
    startDate,
    endDate,
    check,
    branch,
    subjectCheck,
  ]);

  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [fetchRoles, fetchCity]);
  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [checkedLoc]);
  const [fetchingRoleData, setFetchingRoleData] = useState();
  const fetchRoleData = (e) => {
    setFetchingRoleData(e.target.value);
  };

  useEffect(() => {
    if (positionValue) {
    }
  }, [positionValue]);

  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      })
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const handlePositionTypesChange = (e, val) => {
    setPositionValue(val?.value);
    const position_type = positionReportForm.getFieldsValue()?.position_type;
    positionReportForm.setFieldsValue({
      designation: null,
    });
    if (position_type) {
      fetchDesignationList({
        position_type: position_type,
      });
    } else {
      setDesignationList([]);
    }
  };

  const fetchDesignationList = (params = {}) => {
    axios
      .get(`${urls.designationListApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const handleChange = (event) => {
    setCheck(event.target.checked);
  };

  function handleBranch(e, val) {
    // let eventIndex = e.target.value[e.target.value.length - 1];
    // if (eventIndex === branchList[0].id) {
    //   setBranch([eventIndex]);
    // } else {
    //   if (e.target.value[0] === branchList[0].id) {
    //     setBranch(e.target.value.shift());
    //   }
    //   setBranch(e.target.value);

    // }
    setBranch(val?.value);
  }
  function addRole() {
    if (positionValue && startDate && endDate) {
      const start_date = startDate + "T00:00:00";
      const end_date = endDate + "T23:59:59";
      fetch(
        `${urls.positionReport}?position_type=${positionValue}&start_date=${start_date}&end_date=${end_date}&role=${checked}&active=${check}&branch=${branch}&city=${checkedLoc}&subject=${subjectCheck}&grade=${gradeCheck}&page=${page}&page_size=${rowsPerPage}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setDataReport(data);
          console.log(data?.results, "data");
          setLoading(false);
        })
        .catch(() => {
          alert.error("Failed to Complete Action");
        });
    }
  }

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    console.log(val, "end_date");
    setEndDate(val);
  };

  const columns = [
    {
      title: "Position Type",
      key: "position_type",
      width: "20%",
      render: (data) => <span>{data?.position_type}</span>,
    },
    {
      title: "Title",
      key: "title",
      width: "20%",
      render: (data) => <span>{data?.title}</span>,
    },
    {
      title: "Experience",
      key: "experience",
      width: "15%",
      render: (data) => <span>{data?.experience}</span>,
    },
    {
      title: "Skill",
      key: "skill",
      width: "15%",
      render: (data) => <span>{data?.skill}</span>,
    },
    {
      title: "Salary",
      key: "salary",
      width: "15%",
      render: (data) => <span> {data?.salary}</span>,
    },
    {
      title: "Create Date",
      key: "creaye_date",
      width: "15%",
      render: (data) => <span>{data?.create_date.split("T")[0]}</span>,
    },
  ];

  return (
    <>
      <Row className="page_title">Position Report</Row>
      <div className="page_body">
        <Form
          id="positionReportForm"
          form={positionReportForm}
          layout="vertical"
          onFinish={() => addRole()}
        >
          <Row align="middle" gutter={24}>
            <Col span={6}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Position Type"
                  onChange={(e, val) => handlePositionTypesChange(e, val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {positionTypeList &&
                    positionTypeList.length !== 0 &&
                    positionTypeList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.position_type}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  onChange={(e, val) => setChecked(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {designationList &&
                    designationList.length !== 0 &&
                    designationList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="city" label="City">
                <Select
                  placeholder="Select City"
                  onChange={(e, val) => setCheckedLoc(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {cityList &&
                    cityList.length !== 0 &&
                    cityList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.city_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="branch" label="Branch">
                <Select
                  placeholder="Select Branch"
                  onChange={(e, val) => handleBranch(e, val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {branchList &&
                    branchList.length !== 0 &&
                    branchList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.branch_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="grade"
                label={positionValue == "Academic" ? "Grade" : "Level"}
              >
                <Select
                  placeholder={`Select ${
                    positionValue === "Academic" ? "Grade" : "Level"
                  }`}
                  onChange={(e, val) => setGradeCheck(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {grades &&
                    grades.length !== 0 &&
                    grades.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.grade_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="subject"
                label={positionValue == "Academic" ? "Subject" : "Topic"}
              >
                <Select
                  placeholder={`Select ${
                    positionValue === "Academic" ? "Subject" : "Topic"
                  }`}
                  onChange={(e, val) => setSubjectCheck(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {subjects &&
                    subjects.length !== 0 &&
                    subjects.map((option, index) => {
                      return (
                        <Option
                          key={option?.subject?.id}
                          value={option?.subject?.id}
                        >
                          {option?.subject?.subject_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="active_users">
                <Checkbox checked={check} onChange={handleChange}>
                  Active Users
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e, val) => handleStartDateChange(val)}
                  disabledDate={(current) => {
                    return current > moment(endDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e, val) => handleEndDateChange(val)}
                  disabledDate={(current) => {
                    return current < moment(startDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "space-between" }}
              span={4}
            >
              <Button
                form="positionReportForm"
                htmlType="submit"
                type="primary"
                disabled={startDate && endDate ? false : true}
              >
                GET
              </Button>
              <Button
                type="primary"
                href={bottomHRef && bottomHRef[0].csv}
                disabled={startDate && endDate ? false : true}
              >
                CSV
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={dataReport?.results}
          pagination={{
            showSizeChanger: false,
            total: dataReport?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
        />
      </div>
    </>
  );
};
export default PositionReportNew;
