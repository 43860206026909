import {
  Input,
  Space,
  Typography,
  Row,
  Col,
  Form,
  Select,
  Switch,
  Button,
  Drawer,
  Table,
  Tag,
  Alert,
  Spin,
  message,
  Flex,
} from "antd";
import {
  EditOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import urls from "../../../url";
import axios from "axios";

const ViewChapterNew = () => {
  const [filterForm] = useForm();
  const [updateform] = useForm();
  const [addForm] = useForm();
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState("");
  const [grade, setGrade] = useState("");
  const [gardeList, setGradeList] = useState([]);
  const [gardeListForm, setGradeListForm] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectListForm, setSubjectListForm] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [status, setStatus] = useState("");
  const [index, setIndex] = useState("");
  const [gradeId, setGradeId] = useState("");
  const [subject, setSubject] = useState("");
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState([]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showDrawer2 = () => {
    setOpen2(true);
  };

  const onClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    handleGetSubjectOnPageChange();
  }, [page]);

  useEffect(() => {
    const { position_type, level, subject } = filterForm?.getFieldValue();

    // if (search) {
    fetchgetChapters({
      grade_id: level,
      subject_id: subject,
      page_size: rowsPerPage,
      page: page,
      search: search,
    });
    // }
  }, [search]);

  // To get position's list on mounting
  const handlePositionTypeChange = () => {
    const { position_type } = filterForm?.getFieldValue();
    filterForm.setFieldsValue({
      level: null,
      subject: null,
    });
    if (position_type) {
      setPosition(position_type);
      fetchGradeList({ position_type: position_type });
    } else {
      setGradeList([]);
      setSubjectList([]);
    }
  };

  // To get position's list for the add subject form
  const handleFormPositionTypeChange = () => {
    const { position_type } = addForm?.getFieldValue();
    addForm.setFieldsValue({
      level: null,
      subject: null,
    });
    if (position_type) {
      setPosition(position_type);
      fetchGradeListForm({ position_type: position_type });
    } else {
      setGradeListForm([]);
      setSubjectListForm([]);
    }
  };

  const handleGradeChange = () => {
    const { position_type, level } = filterForm?.getFieldValue();
    filterForm.setFieldsValue({
      subject: null,
    });
    if (position_type) {
      setGrade(level);
      getSubjectsList({ grade_id: level });
    } else {
      setSubjectList([]);
    }
  };

  const handleFormGradeChange = () => {
    setPage(1);
    const { position_type, level } = addForm?.getFieldValue();
    addForm.setFieldsValue({
      subject: null,
    });
    if (position_type) {
      setGrade(level);
      getFormSubjectsList({ grade_id: level });
    } else {
      setSubjectListForm([]);
    }
  };

  // To get grades's list based on position
  const fetchGradeList = (params = {}) => {
    axios
      .get(`${urls.newGrade}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setGradeList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // To get subjects List based on grade_id
  const getSubjectsList = (params = {}) => {
    axios
      .get(`${urls.subjectMapping}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSubjectList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // To get subjects List based on grade_id
  const getFormSubjectsList = (params = {}) => {
    axios
      .get(`${urls.subjectMapping}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSubjectListForm(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // To get grades's list based on position for Add Subject
  const fetchGradeListForm = (params = {}) => {
    axios
      .get(`${urls.newGrade}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          setGradeListForm(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // To get table data
  const handleGetSubject = () => {
    const { position_type, level, subject } = filterForm?.getFieldValue();
    setPage(1);
    fetchgetChapters({
      grade_id: level,
      subject_id: subject,
      page_size: rowsPerPage,
      page: page,
    });
  };

  // To get table data on page change
  const handleGetSubjectOnPageChange = () => {
    const { position_type, level, subject } = filterForm?.getFieldValue();

    fetchgetChapters({
      grade_id: level,
      subject_id: subject,
      page_size: rowsPerPage,
      page: page,
    });
  };

  // To get table data
  const fetchgetChapters = (params = {}) => {
    const { position_type, level } = filterForm?.getFieldValue();
    if (position_type && level) {
      axios
        .get(`${urls?.viewChaptersApi}`, {
          params: { ...params },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${name?.personal_info?.token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("====", response?.data?.results);
            setTableData(response?.data?.results);
            setCount(response?.data?.count);
            onClose();
          }
        })
        .catch((error) => {
          console.log(error);
          onClose();
        });
    }
  };

  const editFunction = (data) => {
    console.log(data);
    setSubject(data?.subject?.id);
    setGradeId(data?.grade?.id);
    setIndex(data?.id);
    setStatus(data?.is_delete);
    showDrawer();

    updateform.setFieldsValue({
      chapter: data?.chapter?.chapter_name,
      status: data?.is_delete,
    });
  };

  const handleSwitchChange = (record) => {
    setLoading(true);
    axios
      .put(
        `${urls.updateChapterApi}${record?.id}/retrieve_update_destroy_chapters/`,
        {
          chapter: record?.chapter?.chapter_name,
          is_delete: !record?.is_delete,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${name?.personal_info?.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          const { level, subject } = filterForm?.getFieldValue();

          fetchgetChapters({
            grade_id: level,
            subject_id: subject,
            page_size: rowsPerPage,
            page: page,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatusUpdate = () => {
    const { chapter } = updateform.getFieldValue();
    const dataToBeUpdated = {
      subject: subject,
      is_delete: status,
      grade: gradeId,
      chapter: chapter,
    };
    // const formData = new FormData();
    // formData.append("chapter", chapter);
    // formData.append("grade", gradeId);
    // formData.append("subject", subject);
    // formData.append("is_delete", status);

    if (chapter && subject) {
      axios
        .put(
          `${urls.updateChapterApi}${index}/retrieve_update_destroy_chapters/`,
          dataToBeUpdated,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${name?.personal_info?.token}`,
            },
          }
        )
        .then((response) => {
          if (response?.status === 200) {
            const { level, subject } = filterForm?.getFieldValue();

            fetchgetChapters({
              grade_id: level,
              subject_id: subject,
              page_size: rowsPerPage,
              page: page,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // To add new chapter
  const addNewChapter = () => {
    const { position_type, level, subject, chapter } = addForm?.getFieldValue();
    const formData = new FormData();
    formData.append("chapter_name", chapter);
    formData.append("grade", level);
    formData.append("subject", subject);

    if (position_type && level && subject && chapter) {
      // setLoading(true);
      axios
        .post(`${urls.createChapterApi}`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${name?.personal_info?.token}`,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            handleGetSubject();
            addForm.setFieldsValue({
              position_type: null,
              level: null,
              subject: null,
              chapter: null,
            });
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.log(error);
        });
    } else {
      message.warning("Please fill all required fields");
    }
    onClose2();
  };

  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  const positionTypesOptions = positionTypeList?.map((each, index) => (
    <Select.Option key={index} value={each?.label}>
      {each?.label}
    </Select.Option>
  ));

  const gradeOptions = gardeList?.map((each, index) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.grade_name}
    </Select.Option>
  ));

  const gradeOptionsForm = gardeListForm?.map((each, index) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.grade_name}
    </Select.Option>
  ));

  const subjectOptions = subjectList?.map((each, index) => (
    <Select.Option key={each?.id} value={each?.subject?.id}>
      {each?.subject?.subject_name}
    </Select.Option>
  ));

  const subjectOptionsForm = subjectListForm?.map((each, index) => (
    <Select.Option key={each?.id} value={each?.subject?.id}>
      {each?.subject?.subject_name}
    </Select.Option>
  ));

  const columns = [
    {
      title: <strong>S.No.</strong>,
      key: "id",
      dataIndex: "id",
      align: "center",
      render: (data, reports, index) => <span>{index + 1}</span>,
    },
    {
      title: <strong>Chapter Name</strong>,
      key: "subject_name",
      dataIndex: "subject_name",
      align: "center",
      render: (data, record) => (
        <span>
          {record?.chapter?.chapter_name ? record?.chapter?.chapter_name : "-"}
        </span>
      ),
    },
    {
      title: <strong>Status</strong>,
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (data, record) => (
        <Switch
          checked={!record?.is_delete}
          onChange={() => handleSwitchChange(record)}
        >
          {}
        </Switch>
      ),
    },
    {
      title: <strong>Update</strong>,
      key: "update",
      dataIndex: "update",
      align: "center",
      render: (data, record) => (
        <>
          <Space>
            <Tag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </Tag>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Flex wrap="wrap" justify="space-between">
        <Col>
          <Typography.Title level={3}> Chapters</Typography.Title>
        </Col>
        <Col>
          <Row gutter={12} style={{ marginBottom: "10px", marginTop: "10px" }}>
            <Col style={{ marginBottom: "10px" }}>
              <Input
                id="search-bar"
                placeholder="I'm Looking For..."
                allowClear
                value={search}
                onChange={handleSearch}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => showDrawer2()}
              >
                Add Chapter
              </Button>
            </Col>
          </Row>
        </Col>
      </Flex>

      <Drawer
        title="Add Chapter"
        width={400}
        onClose={onClose2}
        open={open2}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
      >
        <Form
          id="addForm"
          form={addForm}
          layout="vertical"
          style={{ width: "100%", marginBlock: "10px" }}
        >
          <Row align="middle" gutter={12}>
            <Col span={24}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[{ required: true, message: "Please select position" }]}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleFormPositionTypeChange}
                  placeholder="Select Position Type"
                  style={{ width: "100%" }}
                >
                  {positionTypesOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="level"
                label={position == "Academic" ? "Grade" : "Level"}
                rules={[{ required: true, message: "Please select level" }]}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleFormGradeChange}
                  placeholder={
                    position == "Academic" ? "Select Grade" : "Select Level"
                  }
                  style={{ width: "100%" }}
                >
                  {gradeOptionsForm}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="subject"
                label={"Subject"}
                rules={[{ required: true, message: "Please select subject" }]}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  // onChange={handleGradeChange}
                  placeholder={"Select Subject"}
                  style={{ width: "100%" }}
                >
                  {subjectOptionsForm}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                required
                name="chapter"
                label="Chapter"
                rules={[{ required: true, message: "Please enter chapter" }]}
              >
                <Input placeholder="Enter Chapter" />
              </Form.Item>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "space-between" }}
              span={24}
            >
              <Space size={24}>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={addNewChapter}
                >
                  ADD
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
      <Form
        id="filterForm"
        form={filterForm}
        layout="vertical"
        style={{ width: "100%", marginBlock: "10px" }}
        onFinish={() => true}
      >
        <Row align="middle" gutter={12}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Form.Item
              required
              name="position_type"
              label="Position Type"
              rules={[{ required: true, message: "Please select position" }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input, options) => {
                  return (options?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                onChange={handlePositionTypeChange}
                placeholder="Select Position Type"
                style={{ width: "100%" }}
              >
                {positionTypesOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Form.Item
              required
              name="level"
              label={position == "Academic" ? "Grade" : "Level"}
              rules={[{ required: true, message: "Please select Grade/Level" }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input, options) => {
                  return (options?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                onChange={handleGradeChange}
                placeholder={
                  position == "Academic" ? "Select Grade" : "Select Level"
                }
                style={{ width: "100%" }}
              >
                {gradeOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Form.Item
              name="subject"
              label={"Subject"}
              rules={[{ required: true, message: "Please select subject" }]}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input, options) => {
                  return (options?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                // onChange={handleSubjectChange}
                placeholder={"Select Subject"}
                style={{ width: "100%" }}
              >
                {subjectOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col
            style={{ display: "flex", justifyContent: "space-between" }}
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
          >
            <Space size={24}>
              <Button
                htmlType="submit"
                type="primary"
                onClick={handleGetSubject}
              >
                Get Chapter
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>

      <Table
        dataSource={tableData}
        columns={columns}
        pagination={{
          current: page,
          showSizeChanger: true,
          total: count,
          onChange: (current, pageSize) => {
            setPage(current);
            setRowsPerPage(pageSize);
          },
          pageSizeOptions: [10, 25, 50, 75, 100],
        }}
        // scroll={{ x: "max-content" }}
      />

      <Drawer
        title="Update Chapter"
        width={400}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => {
                updateform.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleStatusUpdate}
              form="updateform"
              type="primary"
              htmlType="submit"
            >
              Update
            </Button>
          </div>
        }
      >
        <Form
          id="updateform"
          form={updateform}
          layout="vertical"
          style={{ width: "100%", marginBlock: "10px" }}
        >
          <Row align="middle" gutter={12}>
            <Col span={24}>
              <Form.Item
                required
                name="chapter"
                label="Chpater Name"
                rules={[
                  { required: true, message: "Please enter chapter name" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ViewChapterNew;
