import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import styles from './ocfpMcq.style'
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch'
import Loader from '../../../hoc/loader'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {useAlert} from '../../../hoc/alert/alert';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TextField,
    Button,
    Grid,
    Typography,
    InputBase,
    TableContainer,
} from '@material-ui/core';
import SearchIcon from "@material-ui/icons/Search";
import MediaQuery from "react-responsive";

const OcfpMcq = ({ classes }) => {
    const [auth] = useState(JSON.parse(localStorage.getItem('user')));
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [page, setPage] = React.useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [handleGet, setHandleGet] = useState(null);
    const [applicentName, setApplicentName] = useState('');
    const alert = useAlert();

    const {
        data: score,
        isLoading: scoreLoading,
        doFetch: fetchScore,
    } = useFetch(null);
    const [hRef, setHRef] = useState('');

    useEffect(() => {
        setHRef([{ csv: `${urls.exportOcfpMcqCompleted}?user_id=${auth.personal_info.user_id}&start_date=${startDate}&end_date=${endDate}&export_type=csv` }]);
    }, [setHRef, startDate, endDate]);
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleStartDateChange = (val) => {
        setStartDate(val)
    }
    const handleEndDateChange = (val) => {
        setEndDate(val);
    }
    const handleGetData = () => {
        if (startDate == null) {
            alert.warning("Please Select Start Date..!")
            return;
        }
        if (endDate == null) {
            alert.warning("Please Select End Date..!")
            return;
        }
        fetchScore({
            url: `${urls.ocfpMcq}?page_size=${rowsPerPage || 50}&page=${page + 1}&start_date=${startDate}&end_date=${endDate}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.personal_info.token}`,
            },
        })
        setHandleGet(1);
    }

    const fetchSearch = e => {
        setPage(0);
        setRowsPerPage(50);
        // if (e.keyCode === 13) {
        //   const data = document.getElementById("search-bar").value;
          setApplicentName(e.target.value);
          functionSearch(e.target.value);
        // }
      };
    
      function functionSearch(data){
        fetchScore({
          url: `${urls.ocfpMcq}?page=${page +
            1}&page_size=${rowsPerPage || 5}&search=${data}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`
          }
        }); 
      }

    let loader = null;
    if (scoreLoading) {
        loader = <Loader open />;
    }
    return (
        <React.Fragment>
            <Grid container spacing={2} className={classes.modalPaper}>
                <Grid item sm={12} md={5} xs={12}>
                <Typography className='headings' variant="h4"><strong>Ocfp Mcq Applicant</strong></Typography>
                </Grid>
                {/* <Grid item md={7} xs={12}>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                    <SearchIcon />
                    </div>
                    <InputBase
                    placeholder="I'm Looking For..."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput
                    }}
                    id='search-bar'
                    onKeyDown={fetchSearch}
                    />
                </div>
                </Grid> */}
                  <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={fetchSearch}
            />
          </div>
        </Grid>
                {/* <Grid item md={5}/> */}
              <Grid item  sm={5} md={3} xs={12}>
                <TextField
                    // className={classes.textField}
                    className="textList"
                    margin="dense"
                    required
                    fullWidth
                    onChange={e => handleStartDateChange(e.target.value)}
                    type="date"
                    value={startDate || ' '}
                    variant="outlined"
                    helperText="Start Date"
                />
                </Grid>
                {/* &nbsp; */}
                <Grid item  sm={5} md={3} xs={12}>
                  <TextField
                    
                    helperText="End Date"
                    // className={classes.textField}
                    className="textList"
                    margin="dense"
                    required
                    fullWidth
                    onChange={e => handleEndDateChange(e.target.value)}
                    type="date"
                    value={endDate || ' '}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={2} md={2} xs={5}>
                  <Button variant="contained" color="primary" fullWidth
                  className={classes.button} onClick={handleGetData}>Get</Button>
                </Grid>
                {score && score.result
                  ? (
                    <>
                    {/* <Grid item sm={2} md={10} xs={2} /> */}
                    {/* <Grid item sm={2} md={10} xs={2} /> */}
                    <Grid item sm={2} md={2} xs={5}>
                    <Button
                        className={classes.button}
                        color="primary"
                        // size="small"
                        fullWidth 
                        variant="contained"
                        href={hRef[0].csv}
                        // onClick={onDownloadClick}
                        // style={{ float: 'left' }}
                    >
                    CSV
                    </Button>
                    </Grid>
                    </>
                  )
                  : ''
                }
            </Grid>
           
            
            <Paper className={classes.root}>
                <TableContainer className="role">
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell align="left"><strong>Email</strong></TableCell>
                            <TableCell align="left"><strong>Mobile</strong></TableCell>
                            <TableCell align="left"><strong>College</strong></TableCell>
                            <TableCell align="left"><strong>Math</strong></TableCell>
                            <TableCell align="left"><strong>Science</strong></TableCell>
                            <TableCell align="left"><strong>Logical</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    
                    <TableBody>
                        {(((score && score.result) && (handleGet != null)) ?
                            score.result.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell component="th" scope="row">
                                        {item.name}
                                    </TableCell>

                                    <TableCell align="left">{item.email}</TableCell>
                                    <TableCell align="left">{item.contact_no}</TableCell>
                                    <TableCell align="left">{item.college}</TableCell>
                                    <TableCell align="left">{item.math_mark_scored?item.math_mark_scored:""}{item.math_mark_scored? "/40":""} </TableCell>
                                    <TableCell align="left">{item.science_mark_scored?item.science_mark_scored:""} {item.science_mark_scored?"/40":""}</TableCell>
                                    <TableCell align="left">{item.logical_mark_scored?item.logical_mark_scored:""}{item.logical_mark_scored?"/20":""} </TableCell>
                                </TableRow>
                            ))
                            : null)}
                    </TableBody>
                    
                </Table>
                {(handleGet != null) ?
                    <TablePagination
                        rowsPerPageOptions={[50, 75, 100]}
                        component="div"
                        count={score && score.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    /> : null}
                     </TableContainer>
            </Paper>
            {loader}
        </React.Fragment>
    );
}
OcfpMcq.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(OcfpMcq);

