import React, { useState, useEffect, useRef } from "react";
import styles from "./spokenTest.Styles";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Paper,
  Button
} from "@material-ui/core";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import RecordingAPI from "../audio/audio";
import Icon from "@material-ui/core/Icon";
const audioType = "audio/*";

const SpokenTest = ({ classes }) => {
  // const [recording, setRecording] = useState(false);
  // const [audios, setAudios] = useState([]);
  // const [recording, setRecording] = useState(false);
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [audioFile, setAudioFile] = useState("");
  const [startTest, setStartTest] = useState(false);
  const [duration, setDuration] = useState(120);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (duration > 0) {
      setDuration(t => t - 1);
    }
  }, [1000]);


  const alert = useAlert();

  const {
    data: spokenTestAssignResponse,
    isLoading: spokenTestAssignResponseLoading,
    doFetch: fetchspokenTestAssignResponse
  } = useFetch(null);

  const {
    data: zoomData,
    isLoading: zoomDataLoading,
    doFetch: fetchZoomData
  } = useFetch(null);

  const {
    data: submitData,
    isLoading: submitingDataLoading,
    doFetch: fetchSubmitData
  } = useFetch(null);

  var loader = null;
  if (
    spokenTestAssignResponseLoading ||
    zoomDataLoading ||
    submitingDataLoading
  ) {
    loader = <Loader open />;
  }
  // useEffect(() => {
  //   setStartTest(false);
  //   fetchspokenTestAssignResponse({
  //     url: urls.applicantspokenTestAssignResApi,
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${auth.personal_info.token}`
  //     }
  //   });
  // }, []);

  function getZoomData() {
    fetchZoomData({
      url: `${urls.getZoomDataApi}?round_id=${localStorage.getItem(
        "round_id"
      )}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  }

  useEffect(() => {
    getZoomData();
  }, []);

  function audioData(data) {
    setAudioFile(data);
  }

  // const onSubmit = () => {
  //   if (!audioFile) {
  //     alert.warning("Please record audio");
  //     return;
  //   }
  //   const formData = new FormData();

  //   formData.append("audio_file", audioFile);

  //   fetchSubmitData({
  //     url: urls.getZoomDataApi,
  //     method: "put",
  //     body: formData,
  //     headers: {
  //       Authorization: `Bearer ${auth.personal_info.token}`
  //     }
  //   });
  // };

  function onSubmit() {
    if (!audioFile) {
      alert.warning("Please record audio");
      return;
    }
    const formData = new FormData();

    formData.append("audio_file", audioFile);
    fetch(urls.getZoomDataApi, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    }).then(res => {
      if (res.status === 200) {
        // writtenTestRes()
        alert.success("Successfully Submitted");
        window.location = "/process";
      }
      if (res.status !== 200) {
        alert.warning("somthing went wrong please try again ");
        return;
      }
    });
  }

  function countDown() {
    const time = duration;
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor((time / 60) % 60);
    return (
      <Grid item sm={4} md={12} xs={12}
        style={{
          textAlign: 'center',
        }}
      >
        <Typography style={{ fontSize: '20px' }}>Remaining Time :{minutes > 0 ? <span style={{ fontSize: "40px", fontWeight: 'bold', color: 'rgb(63 81 181)' }}>{minutes}:{seconds}</span> : <span style={{ fontSize: "40px", fontWeight: 'bold', color: 'red' }}>{minutes}:{seconds}</span>}</Typography>
      </Grid>
    );
  }

  useEffect(() => {
    if (countDown().props?.children[0] === 0 && countDown()?.props?.children[2] === 0) {
      setAudioFile(null);
      onSubmit();
    }
  }, [countDown])

  return (
    <>
      <Typography variant='h4' style={{fontWeight: 'bold'}}>Spoken test</Typography>
      <Divider className={classes.divider} />
      <React.Fragment>
        {/* {spokenTestAssignResponse && spokenTestAssignResponse.spoken_test_assign === false ? <Grid className={classes.GridText}><p variant='h5'  style={{color:'blue'}} className={classes.papertext}>
    If you have any concerns please get in touch with us at <a href="https://www.recruitment_mumbai@gmail.com"  target="_blank">recruitment_mumbai@gmail.com</a> if you have applied for <strong>Mumbai</strong>, <strong> Pune </strong>and <strong>Kolkata</strong> positions or <a  target="_blank" href="https://www.recruitment_bangalore@gmail.com">recruitment_bangalore@gmail.com</a> if you have applied for Bangalore, Hyderabad and Chennai positions.
    Best of luck! 
       </p></Grid>:''} */}
        {
          <div>
            {/* {
              <Grid className={classes.grid}>
                <Paper className={classes.paperr}>
                  <Typography variant='h5' className={classes.typography}>
                    Instructions
                  </Typography>
                  <Divider className={classes.divider} />
                  <p style={{ fontSize: "18px" }}>
                    Please take this 'Spoken English' test, you need to speak on
                    the topic for a minute, once you're ready click on start and
                    it will automatically end after 1 min (60 seconds), if you
                    want to end it before, you can click on 'End'.
                  </p>
                  <Divider className={classes.divider} />
                </Paper>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  onClick={() => setStartTest(true)}
                >
                  Start Test
                </Button>
              </Grid>
            } */}

            {
              <>
                {countDown()}
                {countDown().props?.children?.props?.children[1]?.props?.children[0] !== 0 || countDown().props?.children?.props?.children[1]?.props?.children[2] !== 0 ?
                  <Grid className={classes.grid}>
                    <Paper className={classes.paper}>
                      <Typography variant='h5' className={classes.typography}>
                        Topic Name{" "}
                      </Typography>
                      <Divider className={classes.divider} />
                      <Typography className={classes.topic}>
                        {zoomData && zoomData.topic_name !== null
                          ? zoomData.topic_name && zoomData.topic_name.topic_name
                          : ""}
                      </Typography>
                    </Paper>
                    <Paper className={classes.paper}>
                      <RecordingAPI getAudio={audioData} />
                    </Paper>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                    <Typography className={classes.topic}>
                        <strong>Note:</strong>
                        <i>
                          <ul>
                            <li>1.Make sure of enabling microphone before you start recording</li>
                            <li>2.Wait Until 1 minute recording is completed and submit it.</li>
                            </ul>
                            </i>
                      </Typography>
                  </Grid>
                  :
                  (
                        <Typography
                          variant='h4'
                          style={{ color: "red", fontFamily: "Nunito", marginTop: "50px", textAlign: 'center' }}
                        >
                          Your Test Session Expired
                        </Typography>
                  )
                }
              </>
            }
          </div>
        }

        {/* {spokenTestAssignResponse &&
        spokenTestAssignResponse.spoken_test_completed === true &&
        spokenTestAssignResponse.spoken_test_clear === false &&
        spokenTestAssignResponse.spoken_score_submitted === true ? (
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography
                variant='h5'
                style={{ color: "blue", marginTop: "10%" }}
              >
                Thank you for applying to ORCHIDs! We wish to inform you that
                you have not achieved the threshold marks required to progress
                to the next round. We do hope you apply again to our
                institution! Till then – preparation is key!
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )} */}

        {/* {spokenTestAssignResponse &&
        spokenTestAssignResponse.spoken_test_clear === true &&
        spokenTestAssignResponse.spoken_score_submitted === true &&
        spokenTestAssignResponse.spoken_test_completed === true ? (
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography
                variant='h4'
                style={{
                  textAlign: "center",
                  color: "blue",
                  marginTop: "50px"
                }}
              >
                Kudos! You have succesfully cleared the Spoken test round. You
                will receive a text message once the F2F rounds will be
                assigned.
              </Typography>
            </Grid>
            <Grid item md={5} />
            <Grid item md={4} xs={12}>
              <a href='/facetofaceround'>
                <Button
                  color='primary'
                  variant='contained'
                  size='large'
                  endIcon={<Icon>send</Icon>}
                >
                  Next
                </Button>
              </a>
            </Grid>
          </Grid>
        ) : (
          ""
        )} */}
        {/* 
        {spokenTestAssignResponse &&
        spokenTestAssignResponse.spoken_test_clear === false &&
        spokenTestAssignResponse.spoken_score_submitted === false &&
        spokenTestAssignResponse.spoken_test_completed === true ? (
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography
                variant='h4'
                style={{ textAlign: "center", color: "blue", marginTop: "10%" }}
              >
                You have successfully completed the spoken test
              </Typography>
            </Grid>
          </Grid>
        ) : (
          ""
        )} */}

        {loader}
      </React.Fragment>
    </>
  );
};
SpokenTest.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};

export default withStyles(styles)(SpokenTest);
