import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  Grid,
  Menu,
} from "@material-ui/core/";
import { NavLink } from "react-router-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  Input as InputIcon,
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@material-ui/icons";
import ComponentList from "./componentList";
import styles from "./layout.style";
import MediaQuery from "react-responsive";
import Link from "@material-ui/core/Link";

function Layout(props) {
  const classes = styles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [loginData] = useState(JSON.parse(localStorage.getItem("user")));
  const [routeLink, setRouteLink] = useState("");

  const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
  ));

  const role = loginData && loginData.personal_info.role;

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const handleMenu = () => {
    localStorage.clear();
    window.location = "/";
  };

  const sideBarList = ComponentList;
  const roleList = () => {
    if (role) {
      const roleLower = role.toLowerCase();
      return sideBarList[roleLower];
    }
  };
  const [opened, setOpened] = React.useState(false);
  const handleClick = () => {
    setOpened(!opened);
  };

  function onListClick(route) {
    setRouteLink(roleList().filter((item) => item.link === route)[0]);
  }
  function onListClickMobile(route) {
    setRouteLink(roleList().filter((item) => item.link === route)[0]);
    setOpen(false);
  }

  const copyRightsFooter = (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright  "}
      &copy; {new Date().getFullYear()}, K12 Techno Services Pvt. Ltd.
    </Typography>
  );

  const TermsAndCondition = (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link
        style={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => (window.location.pathname = "/")}
      >
        Terms and Conditions&nbsp;
      </Link>
      |
      <Link
        style={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => (window.location.pathname = "/")}
      >
        &nbsp;Privacy Policy
      </Link>
      .
    </Typography>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ fontWeight: "bold" }}>
            CareerBox - {role ? role : " "} Dashboard
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <IconButton onClick={handleMenu} color="inherit">
              <InputIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          {/* <IconButton onClick={handleDrawerClose}> */}
          {theme.direction === "rtl" ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon />
            </IconButton>
          ) : (
            <>
              {loginData &&
                loginData.personal_info.role.toLowerCase() !== "applicant" && (
                  <Grid container>
                    <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        {(
                          loginData && loginData.personal_info.role
                        ).toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      <a href="/view_profile">View Profile</a>
                    </Grid>
                  </Grid>
                )}
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </>
          )}
          {/* </IconButton> */}
        </div>
        <Divider />
        <List>
          {roleList() &&
            roleList().length &&
            roleList().map((text, index) =>
              !text.children ? (
                <BrowserRouter key={index}>
                  <MediaQuery minWidth={451}>
                    {text.link === "/applicant_test" ||
                    text.link === "/written_test" ||
                    text.link === "/spoken_test" ||
                    text.link === "/facetofaceround" ||
                    text.link === "/view_profile" ||
                    text.link === "/parameters" ? (
                      <ListItem
                        button
                        key={text.name}
                        component={ForwardNavLink}
                        to={text.link}
                        onClick={() => onListClick(text.link)}
                        style={{ display: "none" }}
                      >
                        <ListItemIcon> {text.icon} </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              type="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {text.name}
                            </Typography>
                          }
                          // primary={text.name}
                        />
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        key={text.name}
                        component={ForwardNavLink}
                        to={text.link}
                        onClick={() => onListClick(text.link)}
                      >
                        <ListItemIcon> {text.icon} </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              type="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {text.name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </MediaQuery>
                  <MediaQuery maxWidth={450}>
                    {text.link === "/applicant_test" ||
                    text.link === "/written_test" ||
                    text.link === "/spoken_test" ||
                    text.link === "/facetofaceround" ||
                    text.link === "/view_profile" ||
                    text.link === "/parameters" ? (
                      <ListItem
                        button
                        key={text.name}
                        component={ForwardNavLink}
                        to={text.link}
                        onClick={() => onListClick(text.link)}
                        style={{ display: "none" }}
                      >
                        <ListItemIcon> {text.icon} </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              type="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {text.name}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        key={text.name}
                        component={ForwardNavLink}
                        to={text.link}
                        onClick={() => onListClick(text.link)}
                      >
                        <ListItemIcon> {text.icon} </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              type="body2"
                              style={{ fontWeight: "bold" }}
                            >
                              {text.name}
                            </Typography>
                          }
                          //  primary={text.name}
                        />
                      </ListItem>
                    )}
                  </MediaQuery>
                </BrowserRouter>
              ) : (
                <BrowserRouter key={text.name}>
                  <MediaQuery minWidth={451}>
                    <ListItem button onClick={handleClick}>
                      <ListItemIcon>{text.icon}</ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {text.name}
                          </Typography>
                        }
                        //  primary={text.name}
                      />
                      {opened ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={opened} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          key={text.name}
                          component={ForwardNavLink}
                          to={text.link}
                          onClick={() => onListClick(text.link)}
                        >
                          <ListItemIcon>{text.children[0].icon}</ListItemIcon>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type="body2"
                                style={{ fontWeight: "bold" }}
                              >
                                {text.children[0].name}
                              </Typography>
                            }
                            //  primary={text.children[0].name}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </MediaQuery>
                  <MediaQuery maxWidth={450}>
                    <ListItem button onClick={handleClick}>
                      <ListItemIcon>{text.icon}</ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            type="body2"
                            style={{ fontWeight: "bold" }}
                          >
                            {text.name}
                          </Typography>
                        }
                      />
                      {opened ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={opened} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          className={classes.nested}
                          key={text.name}
                          component={ForwardNavLink}
                          to={text.link}
                          onClick={() => onListClick(text.link)}
                        >
                          <ListItemIcon>{text.children[0].icon}</ListItemIcon>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                type="body2"
                                style={{ fontWeight: "bold" }}
                              >
                                {text.children[0].name}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  </MediaQuery>
                </BrowserRouter>
              )
            )}
          <Divider />
        </List>
      </Drawer>
      <div style={{ width: "-webkit-fill-available" }}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {routeLink && window.location.reload() ? (
            <routeLink.component />
          ) : (
            <BrowserRouter>
              <Switch>
                {roleList() &&
                  roleList().length &&
                  roleList().map((comp, index) => (
                    <Route
                      key={index}
                      path={comp.link}
                      exact
                      component={comp.component}
                    />
                  ))}
              </Switch>
            </BrowserRouter>
          )}
        </main>
        <div className={classes.footer}>
          {copyRightsFooter}
          {TermsAndCondition}
        </div>
      </div>
    </div>
  );
}

Layout.propTypes = {
  className: PropTypes.string,
};

Layout.defaultProps = {
  className: "",
};

export default withStyles(styles)(Layout);
