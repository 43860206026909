/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  Grid,
  TextField,
  withWidth,
  withStyles,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import useFetch from "../hoc/useFetch";
import styles from "./login.styles";
import urls from "../url";
import axios from "axios";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useAlert } from "../hoc/alert/alert";
import { useHistory } from "react-router-dom";
import { login } from "../redux/actions";
import { useDispatch } from "react-redux";

const LoginForm = ({ classes, width: brkPoint }) => {
  const dispatch = useDispatch();
  const emailRef = useRef();
  const passRef = useRef();
  const phoneRef = useRef();
  const mailRef = useRef();
  const otpRef = useRef();
  const history = useHistory();

  const [loginSet, setLoginSet] = useState(true);
  const [loginEmailSet, setLoginEmailSet] = useState(true);
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const alert = useAlert();

  const {
    data: userData,
    // isLoading: contactSourceLoading,
    doFetch: fetchUserData,
  } = useFetch(null);

  useEffect(() => {
    emailRef.current.value = "";
    if (userData) {
      localStorage.setItem("user", JSON.stringify(userData));
      // history.push(
      //   `/${userData?.personal_info?.role?.toLowerCase()}-dashboard`
      // );
      // window.location.reload();
      // const role = userData?.personal_info?.role?.toLowerCase();
      // window.location = `/${role}-dashboard`;
      axios.defaults.headers.Authorization = `Bearer ${userData?.personal_info?.token}`;
      // the above line is written to handle api call when a user lands on page first time
      dispatch(login(userData));
    }
  }, [userData]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginSet = () => {
    setLoginSet(!loginSet);
  };

  const handleLoginMailSet = () => {
    setLoginEmailSet(!loginEmailSet);
  };

  const handleLoginUserSet = () => {
    setLoginSet(true);
    setLoginEmailSet(true);
    setPhoneNumber("");
    setEmail("");
    setOtp("");
    setIsOtpSend(false);
  };

  const getRenderBasedView = () => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        loginHandler();
      } else {
      }
    };

    const loginHandler = () => {
      const username = emailRef.current.value;
      const password = passRef.current.value;

      if (!username.length || !password.length) {
        return;
      }
      const body = {
        username,
        password,
      };

      fetchUserData({
        url: urls.login,
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      });
    };
    const loginHandlerOTP = () => {
      if (!phoneNumber && !email) {
        alert.warning("Please Enter a valid Input");
        return;
      }
      if (email) {
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(email)) {
          alert.warning("Please enter valid Email...");
          return;
        } else if (email.match(/[0-9]+/)) {
          alert.warning("Please enter valid Email Not a Number...");
          return;
        }
      }

      if (phoneNumber) {
        var IndNum = /^[0]?[56789]\d{9}$/;
        if (!IndNum.test(phoneNumber)) {
          alert.warning("Please enter valid mobile number...!");
          return;
        }
      }
      try {
        axios
          .get(
            `${urls.loginOtpSend}?mobile_number=${
              phoneNumber ? phoneNumber : ""
            }&email=${email ? email : ""}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
            if (response.status === 201) {
              setIsOtpSend(true);
              setOtp("");
            } else {
              alert.error("Invalid Mobile Number");
            }
          });
      } catch (e) {
        alert.error("Invalid");
      }
    };

    const verifyHandlerOtp = () => {
      if (!otp) {
        return;
      }
      axios
        .get(
          `${urls.loginOtpVerify}?mobile_number=${
            phoneNumber ? phoneNumber : ""
          }&email=${email ? email : ""}&otp=${otp ? otp : ""}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("user", JSON.stringify(response.data));
            history.push(
              `/${response.data?.personal_info?.role?.toLowerCase()}-dashboard`
            );
          } else {
            alert.error("invalid");
          }
        });
    };

    const viewOpenigHandler = (val) => {
      localStorage.setItem("loginValType", val);
      window.location.href = "/register";
    };

    const newOpening = (
      <div className={classes.paper} squared>
        <Typography variant="h5" className={classes.heading}>
          New To Orchids?
        </Typography>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div className={classes.loginButton}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => viewOpenigHandler("viewOpening")}
              >
                View Opening
              </Button>
              <br />
              <br />
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => viewOpenigHandler("viewTransfer")}
              >
                Transfer
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );

    const loginPaper = (
      <>
        {loginSet && loginEmailSet ? (
          <Grid container justify="center" alignItems="center">
            <Grid item xs={8} className={classes.item}>
              <TextField
                autoFocus
                label="User Name"
                margin="normal"
                variant="outlined"
                fullWidth
                inputRef={emailRef}
              />
            </Grid>
            <Grid item xs={8} className={classes.item}>
              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                margin="normal"
                variant="outlined"
                fullWidth
                onKeyDown={handleKeyDown}
                inputRef={passRef}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        ) : !isOtpSend && loginEmailSet && !loginSet ? (
          <Grid container justify="center" alignItems="center">
            <Grid item xs={8} className={classes.item}>
              <TextField
                autoFocus
                label="Phone Number"
                margin="normal"
                variant="outlined"
                fullWidth
                value={phoneNumber || ""}
                // inputRef={phoneRef}
                onChange={(e) => setPhoneNumber(e.target.value)}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>
        ) : !isOtpSend && !loginEmailSet ? (
          <Grid container justify="center" alignItems="center">
            <Grid item xs={8} className={classes.item}>
              <TextField
                autoFocus
                label="Email"
                margin="normal"
                variant="outlined"
                fullWidth
                // inputRef={mailRef}
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                // inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center" alignItems="center">
            <Grid item xs={8} className={classes.item}>
              <TextField
                autoFocus
                label="OTP"
                margin="normal"
                variant="outlined"
                fullWidth
                // inputRef={otpRef}
                value={otp || ""}
                onChange={(e) => setOtp(e.target.value)}
                inputProps={{ maxLength: 4 }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container justify="center" alignItems="center">
          <Grid item xs={8}>
            <div className={classes.loginButton}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={
                  (!isOtpSend && !loginSet) || (!isOtpSend && !loginEmailSet)
                    ? loginHandlerOTP
                    : (isOtpSend && loginSet) || (isOtpSend && loginEmailSet)
                    ? verifyHandlerOtp
                    : loginHandler
                }
              >
                {(!isOtpSend && !loginSet) || (!isOtpSend && !loginEmailSet)
                  ? "Send Otp"
                  : (isOtpSend && loginSet) || (isOtpSend && loginEmailSet)
                  ? "Login"
                  : "Login"}
              </Button>
              <a
                style={{
                  textAlign: "right",
                  marginTop: "20px",
                  textDecoration: "none",
                }}
                href="/ForgetPassword"
              >
                Forgot Password?
              </a>
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        {/* <Typography variant="caption" className={classes.caption}>
          To register for any opening click on
          <span>
            <a
              onClick={() => viewOpenigHandler("viewOpening")}
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                color: "blue",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              View opening
            </a>
          </span>
        </Typography> */}
        {loginSet && loginEmailSet ? (
          <>
            {/* <Typography variant='caption' className={classes.caption}>
              To Login with Phone Number Otp
              <span>
                <a onClick={handleLoginSet} style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue', textDecoration: 'underline', fontWeight: 'bold' }}>
                  Click Here
                </a>
              </span>

            </Typography>
            <Typography variant='caption' className={classes.caption}>
              To Login with Email Otp
              <span>
                <a onClick={handleLoginMailSet} style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue', textDecoration: 'underline', fontWeight: 'bold' }}>
                  Click Here
                </a>
              </span>
            </Typography> */}
          </>
        ) : (
          <Typography variant="caption" className={classes.caption}>
            To Login with Username and Password
            <span>
              <a
                onClick={handleLoginUserSet}
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  color: "blue",
                  textDecoration: "underline",
                  fontWeight: "bold",
                }}
              >
                Click Here
              </a>
            </span>
          </Typography>
        )}
      </>
    );

    const copyRightsFooter = (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright  "}
        &copy; {new Date().getFullYear()}, K12 Techno Services Pvt. Ltd.
      </Typography>
    );

    const TermsAndCondition = (
      <Typography variant="body1" color="textSecondary" align="center">
        By proceeding, you agree to our
        <Link
          style={{ cursor: "pointer", textDecoration: "none" }}
          onClick={() => (window.location.pathname = "/")}
        >
          &nbsp;Terms and Conditions&nbsp;
        </Link>
        and
        <Link
          style={{ cursor: "pointer", textDecoration: "none" }}
          onClick={() => (window.location.pathname = "/")}
        >
          &nbsp;Privacy Policy
        </Link>
        .
      </Typography>
    );

    return (
      <>
        {loginPaper}
        {TermsAndCondition}
        {copyRightsFooter}
      </>
    );
  };

  return <React.Fragment>{getRenderBasedView()}</React.Fragment>;
};

LoginForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.string.isRequired,
};

export default withStyles(styles)(withWidth()(LoginForm));
