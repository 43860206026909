import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TableFooter,
  TablePagination,
  Paper,
  TextField,
  IconButton,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import urls from '../../../url'
import Modal from '../../../hoc/modal/modal'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import styles from './spokenTestCompleted.style';
import CloseIcon from '@material-ui/icons/Close';
import { useAlert } from '../../../hoc/alert/alert'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SpokenTestCompleted = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [value, setValue] = React.useState(0);
  const [Id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [profileId ,setProfileId] = useState('');
  const [openAudio ,setOpenAudio] = useState(null)
  const [Loder ,setLoader] = useState(false)
  const [viewSpokenData ,setviewSpokenData] = useState('')

  const alert = useAlert();

  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id);
    if(profileDetail[newValue].applicant_role_name === "Teacher"){
      setProfileId(2)
    }else if(profileDetail[newValue].applicant_role_name === "Coordinator"){
      setProfileId(3)
    }else if(profileDetail[newValue].applicant_role_name === "Principal"){
      setProfileId(4)
    }
  }

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch(null);

  const {
    data: contacted,
    isLoading: contactedLoading,
    doFetch: fetchContacted,
  } = useFetch(null);

  const {
    data: subjectDetail,
    isLoading: subjectDetailLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  const {
    data: branch,
    isLoading: branchLoading,
    doFetch: fetchBranch,
  } = useFetch(null);

  let loader = null;
  if (branchLoading || subjectDetailLoading || contactedLoading || Loder
    || applicantDetailLoading || applicantsListLoading || profileDetailLoading) {
    loader = <Loader open />;
  }


  const handleCloseAudio = () => {
    setOpenAudio(false)
  };

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.academicRoleList,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchSubject({
        url: urls.subjectApiView,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranch({
        url: urls.branch,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchBranch, fetchProfile, fetchSubject, name]);

  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setId(profileDetail[0].id);
      if(profileDetail[0].applicant_role_name === "Teacher"){
        setProfileId(2)
      }else if(profileDetail[0].applicant_role_name === "Coordinator"){
        setProfileId(3)
      }else if(profileDetail[0].applicant_role_name === "Principal"){
        setProfileId(4)
      }
    }
  }, [profileDetail])

  useEffect(() => {
    if (Id) {
      fetchApplicant({
        url: `${urls.spokenTestCompletedApi}?role_id=${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setStartDate(null);
      setEndDate(null);
      setFilteredBranch([]);
      setFilteredSub([]);
      setPage(0);
    }
  }, [Id, fetchApplicant, name])

  const handleStartDateChange = (val) => {
    setStartDate(val)
  }
  const handleEndDateChange = (val) => {
    setEndDate(val);
  }
  const handleGetData = () => {
    if (startDate == null) {

      alert.warning('Please Select Start Date..!')
      return;
    }
    if (endDate == null) {
      alert.warning('Please Select End Date..!')
      return;
    }
    setPage(0);
    fetchApplicant({
      url: `${urls.spokenTestCompletedApi}?branch=${filteredBranch}&subject=${filteredSub}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}&role_id=${Id}&page=1&page_size=${rowsPerPage || 10}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })

  }

  function updateHandler(ID, hr) {
    const firstName = name.personal_info.first_name
    setEdit(firstName === hr ? false : true)
    setOpen(true);
    fetchApplicantDetail({
      url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  const onContacted = (ID) => {
    const body = { id: ID, role_id:Id }
    fetchContacted({
      url: urls.isContactedAPI,
      method: 'put',
      body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleBranch = (value) => () => {
    const currentIndex = filteredBranch.indexOf(value);
    const newChecked = [...filteredBranch];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredBranch(newChecked);
  };

  const handleSub = (value) => () => {
    const currentIndex = filteredSub.indexOf(value);
    const newChecked = [...filteredSub];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredSub(newChecked);
  };

  const applyFilter = () => {
    setPage(0);
    fetchApplicant({
      url: `${urls.spokenTestCompletedApi}?branch=${filteredBranch}&subject=${filteredSub}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}&role_id=${Id}&page=1&page_size=${rowsPerPage || 10}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(()=>{
    if(contacted){
      setOpen(false)
      setPage(0)
      setRowsPerPage(10)
      fetchApplicant({
        url: `${urls.spokenTestCompletedApi}?role_id=${Id}&page=${page + 1}&page_size=${rowsPerPage || 10}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  },[contacted])

  useEffect(() => {
    if (page || rowsPerPage) {
      fetchApplicant({
      url: `${urls.spokenTestCompletedApi}?branch=${filteredBranch}&subject=${filteredSub}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}&role_id=${Id}&page=${page + 1}&page_size=${rowsPerPage || 10}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [page, fetchApplicant, rowsPerPage, name]);

  function handleChangePage(event, newPage) {
    setPage(newPage)
    !rowsPerPage && setRowsPerPage(10)
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0)
  }

  function lastPageChange(lastPage) {
    setPage(lastPage)
    !rowsPerPage && setRowsPerPage(10)
  }

  const subjectList = () => {
    let subject = null;
    if (subjectDetail && subjectDetail.length) {
      subject = subjectDetail.map(value => {
        const subId = `Sub Id : ${value.id}`;
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleSub(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredSub.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': subId }}
              />
            </ListItemIcon>
            <ListItemText id={subId} primary={value.subject_name} />
          </ListItem>
        );
      })
    }
    return subject;
  }

  const branchList = () => {
    let branchDetail = null;
    if (branch && branch.length) {
      branchDetail = branch.map(value => {
        const branchId = `Branch Id : ${value.id}`;
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleBranch(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredBranch.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': branchId }}
              />
            </ListItemIcon>
            <ListItemText id={branchId} primary={value.branch_name} />
          </ListItem>
        );
      })
    }
    return branchDetail;
  }

  function ViewSpokenFunction(id){
    setLoader(true)
    fetch(`${urls.getViewSpokenDataApi}?applicant_id=${id}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
  })
  .then(res=>
    {
    if(res.status === 404){
      setLoader(false)
      alert.error('File not Uploaded')
      
  }
  if(res.status === 200){
      setLoader(false)
      setOpenAudio(true)
      alert.success('Success')
      return res.json()
  }
  if(res.status != 200 && res.status != 404){
      alert.warning('somthing went wrong please try again ')
      
  }
    
}).then(data => {
  setviewSpokenData(data)
})
}
  function profileTable() {
    let applicantTable = null;
    if(applicantsList && applicantsList.result && applicantsList.result.length===0){
      applicantTable = (
        <React.Fragment>
          <Typography style={{textAlign:'center', color:'blue',marginTop:'100px'}} variant='h4'>Records Not Found</Typography>
        </React.Fragment>
      )
    }
    if (applicantsList && applicantsList.result && applicantsList.result.length) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{marginTop:'10px'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Subject Interested</TableCell>
                <TableCell align="left">Grade</TableCell>
                <TableCell align="left">Branch Interested</TableCell>
                <TableCell align="left">Score</TableCell>
                <TableCell align="left">View Spoken Test</TableCell>
                <TableCell align="left">Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applicantsList.result.map((details, i) => (
                <TableRow key={details.id}>
                  <TableCell align="left">{details.name}</TableCell>
                  <TableCell align="left">{details.subject}</TableCell>
                  <TableCell align="left">{details.grade}</TableCell>
                  <TableCell align="left">{details.branch}</TableCell>
                  <TableCell align="left">
                    <div>{details.test_name}</div>
                    <div>{details.test_status}</div>
                    <div>{details.cleared_status}</div>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      className={classes.addButton}
                      // color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => ViewSpokenFunction(details.id)}
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      className={classes.addButton}
                      // color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => updateHandler(details.id, details.Hr_name)}
                    >
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={5}
                    labelDisplayedRows={() => `Page ${page + 1} of ${+applicantsList.total_pages}`}
                    rowsPerPageOptions={[10, 20, 30]}
                    rowsPerPage={rowsPerPage || 10}
                    count={+applicantsList.count}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: '13px' }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => lastPageChange(applicantsList.total_pages - 1)}
                      disabled={page === (+applicantsList.total_pages - 1)}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
          </Table>
          </Paper>
        </React.Fragment>
      )
    }
    return applicantTable;
  }

    function idFunction(){
    if(profileId===4){
    return(    
      <Grid item md={4} className={classes.gridOne}>    
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" component="h2">
                6.Cultural Fit Round {(applicantDetail && applicantDetail.f2f_score.length!==0 && (applicantDetail && applicantDetail.f2f_score.length!==0  && applicantDetail.f2f_score.filter(check =>check.round.round_name === "Cultural Fit Round").map((items)=>{return items.mark_scored})).length !==0) ?  <a>Completed <Typography variant='h6'>Marks={(applicantDetail &&applicantDetail.f2f_score.length>0 &&applicantDetail.f2f_score.filter(check =>check.round.round_name === "Cultural Fit Round").map((items)=>{return items.mark_scored}))|| 'Not Uploaded'}</Typography></a> : ' Pending'}
            </Typography>
          </CardContent>
        </Card>
      </Grid>   
       )
      }
    }

    const Audio = (
      <React.Fragment>
          <Modal
          small
          className={classes.modall}
          open={openAudio}
          click={handleCloseAudio}
          > 
            <Paper className={classes.audioPaper}>
            <Typography variant='h5' style={{float:'left'}}>Audio</Typography><Button style={{margin:0,float:'right'}} onClick={handleCloseAudio}><CloseIcon>/</CloseIcon></Button><br/>
              <Divider className={classes.divider}/>
              {viewSpokenData && viewSpokenData.spoken_audio && 
              <Grid container spacing={2}>
              <a target="_blank" href={viewSpokenData && viewSpokenData.spoken_audio || ''}><Button color="primary"size="small" variant="contained">Click to Listen</Button></a>
              </Grid> || <Typography variant="h4" style={{color:'blue'}}>File Not Uploaded</Typography>}
              <Divider className={classes.divider}/>
              </Paper>
          </Modal>
      </React.Fragment>
        )

  let modal = null;
  if (applicantDetail) {
    modal = (
      <Modal
        click={handleClose} large style={{ padding: '15px' }}
        open={open}
      >
        <React.Fragment>
        <Typography variant='h5'>Details</Typography>
          <Divider className={classes.divider}/>
         <Grid container spacing={3} >
          <Grid item md={3} className={classes.gridOne}>
              <Typography variant='h6'>Name : {applicantDetail && applicantDetail.applicant && applicantDetail.applicant.user && applicantDetail.applicant.user.first_name}</Typography>
            </Grid>
            <Grid item md={6} className={classes.gridOne}>
            <Typography variant='h6'>PhoneNo : {applicantDetail && applicantDetail.applicant && applicantDetail.applicant.contact_no}</Typography>
            </Grid>
            {/* <Grid item md={3} className={classes.item}>
              <Button
                className={classes.addButton}
                color="primary"
                size="small"
                variant="contained"
                disabled={applicantDetail.recruiter_assign}
                onClick={() => onContacted(applicantDetail.id)}
              >
                {applicantDetail.recruiter_assign ? 'IsContacted?' : 'Contacted'}
              </Button>
            </Grid> */}
            <Grid item md={5} className={classes.gridOne}>
            <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Email: {(applicantDetail && applicantDetail.applicant && applicantDetail.applicant.user && applicantDetail.applicant.user.email) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Grade: {applicantDetail && applicantDetail.applicant && applicantDetail.applicant.relevant_fk && applicantDetail.applicant.relevant_fk.grade && applicantDetail.applicant.relevant_fk.grade.grade_name}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Position Applied: {applicantDetail && applicantDetail.applicant && applicantDetail.applicant.applicant_role && applicantDetail.applicant.applicant_role.applicant_role_name}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Branch Interested: {applicantDetail.applicant && applicantDetail.applicant.relevant_fk && applicantDetail.applicant.relevant_fk.branch && applicantDetail.applicant.relevant_fk.branch.branch_name}</b>
              </div>
            </Grid>
            <Grid item md={3} className={classes.item} />
            {/* <Grid item md={4} className={classes.item}>
              <Button
                className={classes.addButton}
                color="primary"
                size="small"
                disabled={edit}
                variant="contained"
                onClick={updateHandler}
              >
                Edit Applicant Details?
            </Button>
            </Grid> */}
            <Grid item md={12} className={classes.item}>
              <Table>
                <TableBody>
                  {/* <TableRow>
                    <TableCell align="left">
                      Current CTC(Lakhs per annumn)
                  </TableCell>
                    <TableCell align="left">
                      {applicantDetail.applicant && applicantDetail.applicant.relevant_fk && applicantDetail.applicant.relevant_fk.last_drawn_salary}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Expected CTC(Lakhs per annumn)
                  </TableCell>
                    <TableCell align="left">
                      {applicantDetail.applicant && applicantDetail.applicant.relevant_fk && applicantDetail.applicant.relevant_fk.excepted_salary}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Previous School/Organization
                  </TableCell>
                    <TableCell align="left">
                      {applicantDetail.applicant && applicantDetail.applicant.relevant_fk && applicantDetail.applicant.relevant_fk.current_company}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Notice Period (if any)
                  </TableCell>
                    <TableCell align="left">
                      {applicantDetail.applicant && applicantDetail.applicant.relevant_fk && applicantDetail.applicant.relevant_fk.duration_notice_period}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Skills
                  </TableCell>
                    <TableCell align="left">
                      {applicantDetail.applicant && applicantDetail.applicant.relevant_fk && applicantDetail.applicant.relevant_fk.skills}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Work Experience
                  </TableCell>
                    <TableCell align="left">
                      {applicantDetail.applicant && applicantDetail.applicant.relevant_fk && applicantDetail.applicant.relevant_fk.working_since}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Current Location
                  </TableCell>
                    <TableCell align="left">
                      {applicantDetail && applicantDetail.current_location}
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell align="left">
                      Resume
                  </TableCell>
                    <TableCell align="left">
                      {applicantDetail.applicant && applicantDetail.applicant.resume ? <a
target='_blank'
href={applicantDetail.applicant.resume || ""}
onclick="window.open(this.href); return false;"
>
{" "}
View Resume
</a> : 'Resume Not Uploaded'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item md={4} className={classes.gridOne}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" component="h2">
                  1.MCQ {applicantDetail && applicantDetail.mcq_test_completed ? <a> Completed <Typography variant='h6'>Marks={(applicantDetail && applicantDetail.mcq_test_completed && applicantDetail.mcq_score &&applicantDetail.mcq_score.mark_scored)
                  ||  'Not Uploaded'}</Typography></a> : 'Pending'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4} className={classes.gridOne}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" component="h2">
                           2.Written Test {applicantDetail && applicantDetail.written_test_completed ? <a> Completed  <Typography variant='h6'>Marks={(applicantDetail && applicantDetail.written_test_completed && applicantDetail.written_score &&applicantDetail.written_score.mark_scored) ||'Not Uploaded'}</Typography></a> : 'Pending'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4} className={classes.gridOne}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" component="h2">
                           3.Spoken Test {applicantDetail && applicantDetail.spoken_test_completed  ? <a> Completed <Typography variant='h6'>Marks={(applicantDetail && applicantDetail.spoken_test_completed && applicantDetail.spoken_score &&applicantDetail.spoken_score.mark_scored) ||'Not Uploaded'}</Typography></a> : 'Pending'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {profileId ===2 && <Grid item md={4} className={classes.gridOne}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" component="h2">
                       4.Planner Round {(applicantDetail && applicantDetail.f2f_score.length!==0 && (applicantDetail && applicantDetail.f2f_score.length!==0 && applicantDetail.f2f_score.filter(check =>check.round.round_name === "Planner Round").map((items)=>{return items.mark_scored})).length!==0) ?  <a>Completed  <Typography variant='h6'>Marks={(applicantDetail &&applicantDetail.f2f_score.length>0 &&applicantDetail.f2f_score.filter(check =>check.round.round_name === "Planner Round").map((items)=>{return items.mark_scored})) ||'Not Uploaded'}</Typography></a> : ' Pending'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>}
          {(profileId === 3 || profileId===4) && 
          <Grid item container spacing={2}>
          <Grid item md={4} className={classes.gridOne}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" component="h2">
                     4.Stress Round {(applicantDetail && applicantDetail.f2f_score.length!==0 && (applicantDetail && applicantDetail.f2f_score.length!==0  && applicantDetail.f2f_score.filter(check =>check.round.round_name === "Stress Round").map((items)=>{return items.mark_scored})).length !==0) ?  <a>Completed <Typography variant='h6'>Marks={(applicantDetail &&applicantDetail.f2f_score.length>0 &&applicantDetail.f2f_score.filter(check =>check.round.round_name === "Stress Round").map((items)=>{return items.mark_scored}))|| 'Not Uploaded'}</Typography></a> : ' Pending'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4} className={classes.gridOne}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="h2">
                    5.Leadership Round {(applicantDetail && applicantDetail.f2f_score.length!==0 && (applicantDetail && applicantDetail.f2f_score.length!==0  && applicantDetail.f2f_score.filter(check =>check.round.round_name === "Leadership Round").map((items)=>{return items.mark_scored})).length !==0) ?  <a>Completed <Typography variant='h6'>Mark={(applicantDetail &&applicantDetail.f2f_score.length>0 &&applicantDetail.f2f_score.filter(check =>check.round.round_name === "Leadership Round").map((items)=>{return items.mark_scored}))|| 'Not Uploaded'}</Typography></a> : ' Pending'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {idFunction()}
        </Grid>}
          </Grid>
        </React.Fragment>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
         <Grid item sm={12} md={5} xs={12}>
          <Typography variant="h4">Spoken Test Completed</Typography>
         </Grid>
         <Grid item sm={5} md={3} xs={7}>
           <label>Spoken Completed Date</label>
           <TextField
              helperText="Start Date"
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={e => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || ''}
              variant="outlined"
            />
        </Grid>
        <Grid item sm={5} md={3} xs={7}>
          <TextField
            style={{ marginTop: '28px' }}
            helperText="End Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={e => handleEndDateChange(e.target.value)}
            type="date"
            value={endDate || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={2} md={1} xs={6}>
          <Button variant="contained" color="primary" className={classes.button} style={{ marginTop: '28px'}} onClick={handleGetData}>Get</Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        <Grid item md={3} className={classes.paperMain}>
          <Typography variant="h6" className={classes.heading}>Filter By</Typography>
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classes.heading}>Subjects</Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                {subjectList()}
              </Paper>
            </Grid>
          </List>
          <Typography variant="h6" className={classes.heading}>Branch</Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                {branchList()}
              </Paper>
            </Grid>
          </List>
          <Grid className={classes.loginButton}>
            <Button
              className={classes.Button}
              color="primary"
              variant="contained"
              size="large"
              onClick={applyFilter}
            >
              Apply Filter
              </Button>
          </Grid>
        </Grid>
        <Grid item md={9} className={classes.paperMain}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {profileDetail && profileDetail.length && profileDetail.map((role, i) => (
                <Tab key={role.id} label={role.applicant_role_name} />
              ))}
            </Tabs>
          </AppBar>
            {profileTable()}
        </Grid>
      </Grid>
      {modal}
      {Audio}
      {loader}
    </React.Fragment>
  )
}

SpokenTestCompleted.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SpokenTestCompleted);