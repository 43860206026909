import {
  Button,
  Space,
  message,
  DatePicker,
  Form,
  Card,
  Col,
  Row,
  Table,
  Switch,
  Drawer,
} from "antd";
import React, { useEffect, useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import urls from "../../../url";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { each } from "highcharts";
const { RangePicker } = DatePicker;

const AcademicYearsList = () => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [addYearForm] = useForm();
  const [yearsList, setYearsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    addYearForm.resetFields();
  };

  const handleAddNewAcademicYear = () => {
    let academic_year = addYearForm.getFieldsValue()?.academic_year;

    if (academic_year?.length) {
      let start_year = dayjs(academic_year[0])?.year() ?? "";
      let end_year = dayjs(academic_year[1])?.year() ?? "";

      if (end_year > start_year && end_year - start_year == 1) {
        let academicYear = `${start_year}-${end_year}`;

        let duplicate = yearsList?.filter(
          (each) => each?.academic_year == academicYear
        );
        duplicate?.length
          ? message.error("Academic Year already exists")
          : postNewAcademicYear({ academic_year: academicYear, status: true });
      } else {
        message.warning("Please select valid Academic Year.");
      }
    }
  };

  // To get the list of academic years
  const getAcademicYearsList = () => {
    setLoading(true);
    axios
      .get(`${urls.academicYearsList}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        setYearsList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // To add new academic year
  const postNewAcademicYear = (data) => {
    setLoading(true);
    axios
      .post(`${urls.academicYearsList}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response?.status == 200) {
          message.success("Added Successfully");
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
        getAcademicYearsList();
        addYearForm.resetFields();
        onClose();
      });
  };

  // To change the status of an academic year
  const academicYearStatusChange = (id, newStatus) => {
    setLoading(true);
    axios
      .put(
        `${urls.academicYearsList}${id}/`,
        { status: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${name.personal_info.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status == 200) {
          getAcademicYearsList();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatusChange = (record) => {
    let id = record?.id;
    academicYearStatusChange(id, !record?.status);
  };

  useEffect(() => {
    getAcademicYearsList();
  }, []);

  const columns = [
    {
      title: <strong>S.No.</strong>,
      key: "id",
      dataIndex: "id",
      align: "center",
      width: "20%",
      render: (data, reports, index) => <span>{index + 1}</span>,
    },
    {
      title: <strong>Academic Year</strong>,
      key: "year",
      dataIndex: "year",
      width: "40%",
      render: (data, record) => (
        <span>{record?.academic_year ? record?.academic_year : "-"}</span>
      ),
    },
    {
      title: <strong>Status</strong>,
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (data, record) => (
        <Switch
          checked={record?.status}
          onChange={() => handleStatusChange(record)}
        />
      ),
    },
  ];

  return (
    <>
      <Card>
        <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
            margin: "10px",
          }}
        >
          <div className="page_title">
            <strong> Academic Years List</strong>
          </div>
          <Col xs={8}>
            <Button type="primary" onClick={showDrawer}>
              <PlusCircleOutlined />
              Add Academic Year
            </Button>
          </Col>
        </Space>
        <Drawer
          title="Add Academic Year"
          onClose={onClose}
          open={open}
          footer={[
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button onClick={onClose} style={{ marginRight: "10px" }}>
                Cancel
              </Button>
              <Button
                form="addYearForm"
                htmlType="submit"
                type="primary"
                onClick={handleAddNewAcademicYear}
              >
                ADD
              </Button>
            </div>,
          ]}
        >
          <Form id="addYearForm" form={addYearForm} layout="vertical">
            <Row align="middle" gutter={12}>
              <Col xs={24} sm={20} md={20} lg={20} xl={20}>
                <Form.Item
                  name="academic_year"
                  label={"Add Academic Year"}
                  rules={[{ required: true, message: "Please Enter Year" }]}
                >
                  <RangePicker
                    picker="year"
                    id={{
                      start: "startInput",
                      end: "endInput",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>

        <Table
          dataSource={yearsList}
          loading={loading}
          columns={columns}
          count={yearsList?.length}
          width={"600px"}
          pagination={false}
        />
      </Card>
    </>
  );
};

export default AcademicYearsList;
