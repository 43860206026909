import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TableFooter,
  TablePagination,
  Paper,
  Modal,
  Backdrop,
  Fade,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import styles from './spokenScoreUpload.style';
import {useAlert} from '../../../hoc/alert/alert';

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     'aria-controls': `scrollable-auto-tabpanel-${index}`,
//   };
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SpokenScoreUpload = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [value, setValue] = React.useState(0);
  const [Id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [testScore, setTestScore] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [applicantId, setApplicantId] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openAudio ,setOpenAudio] = useState(null)
  const [pagination , setPagination] = useState(false)
  const [Loder ,setLoader] = useState(false)
  const [viewSpokenData ,setviewSpokenData] = useState('')
  const alert = useAlert();

  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id)
  }

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch(null);

  const {
    // data: contacted,
    isLoading: contactedLoading,
    doFetch: fetchContacted,
  } = useFetch(null);

  const {
    data: subjectDetail,
    isLoading: subjectDetailLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  const {
    data: branch,
    isLoading: branchLoading,
    doFetch: fetchBranch,
  } = useFetch(null);

  const {
    data: scoreUploaded,
    isLoading: scoreUploadedLoading,
    doFetch: fetchUploadTestScore,
  } = useFetch(null);

  // const {
  //   data:viewSpokenData,
  //   isLoading:viewSpokenDataLoading,
  //   doFetch:fetchviewSpokenData
  // } = useFetch(null)

  let loader = null;
  if (scoreUploadedLoading || branchLoading || subjectDetailLoading
    || contactedLoading || applicantDetailLoading || applicantsListLoading || profileDetailLoading ||Loder) {
    loader = <Loader open />;
  }

  // useEffect(()=>{
  //   if(viewSpokenData){
  //     setOpenAudio(true)
  //   }
  // },[viewSpokenData])

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.academicRoleList,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchSubject({
        url: urls.subjectApiView,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranch({
        url: urls.branch,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchBranch, fetchProfile, fetchSubject, name]);

  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setId(profileDetail[0].id);
    }
  }, [profileDetail])

  useEffect(() => {
    if (scoreUploaded) {
      fetchApplicant({
        url: `${urls.spokenScoreRetrieveApi}?branch=${filteredBranch}&subject=${filteredSub}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}&role_id=${Id}&page=${page + 1}&page_size=${rowsPerPage || 50}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setOpen(false)
      setTestScore('');
    }
  }, [fetchApplicant, name, scoreUploaded])

  useEffect(() => {
    if (Id) {
      fetchApplicant({
        url: `${urls.spokenScoreRetrieveApi}?role_id=${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setOpen(false)
      setTestScore('');
      setStartDate(null);
      setEndDate(null);
      setFilteredBranch([]);
      setFilteredSub([]);
      setPage(0);
    }
  }, [Id, fetchApplicant, name])

  const handleStartDateChange = (val) => {
    setStartDate(val)
  }
  const handleEndDateChange = (val) => {
    setEndDate(val);
  }
  const handleGetData = () => {
    if (startDate == null) {
      alert.warning('Please Select Start Date..!')
      return;
    }
    if (endDate === null) {
      alert.warning('Please Select End Date..!')
      return;
    }
    setPage(0);
    fetchApplicant({
      url: `${urls.spokenScoreRetrieveApi}?branch=${filteredBranch}&subject=${filteredSub}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}&role_id=${Id}&page=1&page_size=${rowsPerPage || 10}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })

  }

  function updateHandler(ID, hr) {
    setApplicantId(ID)
    const firstName = name.personal_info.first_name
    setEdit(firstName === hr ? false : true)
    setOpen(true);
    // fetchApplicantDetail({
    //   url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${name.personal_info.token}`,
    //   },
    // });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAudio = () => {
    setOpenAudio(false)
  };

  const handleBranch = (value) => () => {
    const currentIndex = filteredBranch.indexOf(value);
    const newChecked = [...filteredBranch];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredBranch(newChecked);
  };

  const handleSub = (value) => () => {
    const currentIndex = filteredSub.indexOf(value);
    const newChecked = [...filteredSub];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredSub(newChecked);
  };

  const applyFilter = () => {
    setPage(0);
    if(filteredBranch === '' && filteredSub === '' ){
      alert.warning('select fields either branch or subject')
      return
    }
    if((filteredBranch &&filteredBranch.length===0) &&(filteredSub && filteredSub.length===0)){
      alert.warning('select fields either branch or subject')
      return
    }
    fetchApplicant({
      url: `${urls.spokenScoreRetrieveApi}?branch=${filteredBranch}&subject=${filteredSub}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}&role_id=${Id}&page=1&page_size=${rowsPerPage || 50}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (pagination && (page || rowsPerPage)) {
      fetchApplicant({
      url: `${urls.spokenScoreRetrieveApi}?branch=${filteredBranch}&subject=${filteredSub}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}&role_id=${Id}&page=${page + 1}&page_size=${rowsPerPage || 50}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [page, fetchApplicant, rowsPerPage, name]);

  function handleChangePage(event, newPage) {
    setPage(newPage)
    setPagination(true)
    !rowsPerPage && setRowsPerPage(50)
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPagination(true)
    setPage(0);
  }

  function firstPageChange() {
    setPage(0)
    setPagination(true)
  }

  function lastPageChange(lastPage) {
    setPage(lastPage)
    setPagination(true)
    !rowsPerPage && setRowsPerPage(50)
  }

  const subjectList = () => {
    let subject = null;
    if (subjectDetail && subjectDetail.length) {
        subject = subjectDetail.map(value => {
          const subId = `Sub Id : ${value.id}`;
          return (
            <ListItem key={value.id} role={undefined} dense button onClick={handleSub(value.id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={filteredSub.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': subId }}
                />
              </ListItemIcon>
              <ListItemText id={subId} primary={value.subject_name} />
            </ListItem>
          );
        })
    }
    return subject;
  }

  const branchList = () => {
    let branchDetail = null;
    if (branch && branch.length) {
      branchDetail = branch.map(value => {
          const branchId = `Branch Id : ${value.id}`;
          return (
            <ListItem key={value.id} role={undefined} dense button onClick={handleBranch(value.id)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={filteredBranch.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': branchId }}
                />
              </ListItemIcon>
              <ListItemText id={branchId} primary={value.branch_name} />
            </ListItem>
          );
        })
    }
    return branchDetail;
  }

  const uploadTestScore = () => {
    if(testScore === ''){
      alert.warning('enter score out of 20')
      return;
    }
    const body = {
      applicant: applicantId,
      score: testScore
    }

    fetchUploadTestScore({
      url: urls.uploadSpokenScore,
      method: 'post',
      body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

   function ViewSpokenFunction(id){
    setLoader(true)
    fetch(`${urls.getViewSpokenDataApi}?applicant_id=${id}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
  })
  .then(res=>
    {
    if(res.status === 404){
      setLoader(false)
      alert.error('File not Uploaded')
      
  }
  if(res.status === 200){
      setLoader(false)
      setOpenAudio(true)
      alert.success('Success')
      return res.json()
  }
  if(res.status != 200 && res.status != 404){
      alert.warning('somthing went wrong please try again ')
      
  }
    
}).then(data => {
  setviewSpokenData(data)
})

    //  fetchviewSpokenData({
    //   url: `${urls.getViewSpokenDataApi}?applicant_id=${id}`,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${name.personal_info.token}`,
    //   },
    // });
  }

  function profileTable() {
    let applicantTable = null;
    if(applicantsList && applicantsList.result && applicantsList.result.length===0){
      applicantTable = (
        <React.Fragment>
          <Typography style={{textAlign:'center', color:'blue',marginTop:'100px'}} variant='h4'>Records Not Found</Typography>
        </React.Fragment>
      )
    }
    if (applicantsList && applicantsList.result && applicantsList.result.length) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{marginTop:'10px'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Subject Interested</TableCell>
                <TableCell align="left">Grade</TableCell>
                <TableCell align="left">Branch Interested</TableCell>
                <TableCell align="left">View Spoken</TableCell>
                <TableCell align="left">Score Upload</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applicantsList.result.map((details, i) => (
                <TableRow key={details.id}>
                  <TableCell align="left">{details.name}</TableCell>
                  <TableCell align="left">{details.subject}</TableCell>
                  <TableCell align="left">{details.grade}</TableCell>
                  <TableCell align="left">{details.branch}</TableCell>
                  <TableCell align="left">
                    <Button
                      className={classes.addButton}
                      // color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => ViewSpokenFunction(details.id)}
                    >
                      View
                    </Button>
                </TableCell>
                  <TableCell align="left">
                    <Button
                      className={classes.addButton}
                      // color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => updateHandler(details.id, details.Hr_name)}
                    >
                      Upload
                    </Button>
                </TableCell>
                </TableRow>
              ))}
            </TableBody>
            </Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={5}
                labelDisplayedRows={() => `Page ${page + 1} of ${+applicantsList.total_pages}`}
                rowsPerPageOptions={[50, 75, 100]}
                rowsPerPage={rowsPerPage || 50}
                count={+applicantsList.count}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
              <TableCell style={{ marginTop: '13px' }}>
                <IconButton
                  onClick={firstPageChange}
                  disabled={page === 0 || page === 1}
                >
                  <FirstPageIcon />
                </IconButton>
                <IconButton
                  onClick={() => lastPageChange(applicantsList.total_pages - 1)}
                  disabled={page === (+applicantsList.total_pages - 1)}
                >
                  <LastPageIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
          </Paper>
        </React.Fragment>
      )
    }
    return applicantTable;
  }

  function testScoreFunction(e){
    if(parseInt(e.target.value)<=20 || e.target.value.length===0){
      setTestScore(e.target.value)
    }
  }

  const Audio = (
    <React.Fragment>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAudio}
        onClose={handleCloseAudio}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        > 
          <Fade in={openAudio}>
          <Paper className={classes.audioPaper}>
          <Typography variant='h5' style={{float:'left'}}>Audio</Typography><Button style={{margin:0,float:'right'}} onClick={handleCloseAudio}><CloseIcon>/</CloseIcon></Button><br/>
            <Divider className={classes.divider}/>
            <Grid container spacing={2}>
            <a target="_blank" href={viewSpokenData && viewSpokenData.spoken_audio || ''}><Button color="primary"size="small" variant="contained">Click to Listen</Button></a>
            </Grid>
            <Divider className={classes.divider}/>
            </Paper>
            </Fade>
        </Modal>
    </React.Fragment>
      )

  const modal = (
<React.Fragment>
    <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className={classes.modal}
    open={open}
    onClose={handleClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
        timeout: 500,
    }}
    > 
      <Fade in={open} className={classes.paperr}>
      <div>
      <Grid>
      <Paper className={classes.paperquestion}>
        <Typography variant='h5'>Upload Spoken Score out of 20</Typography>
        <Divider className={classes.divider}/>
        <Grid container spacing={2}>
        <Grid item md={8} xs={16}>
          <TextField
            label="Test Score"
            type="number"
            margin="dense"
            required
            fullWidth
            value={testScore || ''}
            onInput = {(e) =>{e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)}}
            onChange={e =>  (e.target.value>=0 && e.target.value<= 30) && testScoreFunction(e)}
            variant="outlined"
          />
        </Grid>
        <Grid item md={4} xs={6}>
          <Button
            className={classes.addButton}
            color="primary"
            size="small"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={uploadTestScore}
            >
             Upload
          </Button>
        </Grid>
        </Grid>
        <Divider className={classes.divider}/>
        </Paper>
        </Grid>
        </div>
        </Fade>
    </Modal>
</React.Fragment>
  )

  return (
    <React.Fragment>     
      <Grid container spacing={2} className={classes.modalPaper}>
         <Grid item sm={12} md={5} xs={12}>
          <Typography variant="h4">Spoken Score Upload</Typography>
         </Grid>
         <Grid item sm={5} md={3} xs={7}>
           <label>Spoken Completed Date</label>
           <TextField
              helperText="Start Date"
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={e => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || ''}
              variant="outlined"
            />
        </Grid>
        <Grid item sm={5} md={3} xs={7}>
          <TextField
            style={{ marginTop: '28px' }}
            helperText="End Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={e => handleEndDateChange(e.target.value)}
            type="date"
            value={endDate || ''}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={2} md={1} xs={6}>
          <Button variant="contained" color="primary" className={classes.button} style={{ marginTop: '28px'}} onClick={handleGetData}>Get</Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        <Grid item md={3} className={classes.paperMain}>
          <Typography variant="h6" className={classes.heading}>Filter By</Typography>
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classes.heading}>Subjects</Typography>
            <List className={classes.root}>
              <Grid className={classes.fixed}>
                <Paper>
                {subjectList()}
                </Paper>
              </Grid>
            </List>
          <Typography variant="h6" className={classes.heading}>Branch</Typography>
            <List className={classes.root}>
              <Grid className={classes.fixed}>
                <Paper>
                {branchList()}
                </Paper>
              </Grid>
            </List>
            <Grid className={classes.loginButton}>
              <Button
                className={classes.Button}
                color="primary"
                variant="contained"
                size="large"
                onClick={applyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
        </Grid>
        <Grid item md={9} className={classes.paperMain}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {profileDetail && profileDetail.length && profileDetail.map((role, i) => (
                <Tab key={role.id} label={ role.applicant_role_name } />
              ))}
            </Tabs>
          </AppBar>
          {profileTable()}
        </Grid>
      </Grid>
      {modal}
      {Audio}
      {loader}
    </React.Fragment>
  )
}

SpokenScoreUpload.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(SpokenScoreUpload);