export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: '5px 40px',
      marginTop: '15px',
    },
    gridOnee :{
      marginTop:'5px',
      marginBottom:'5px'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 2, 1),
    },
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    textField:{
      width:'100%'
    },
    button:{
      marginTop:'22px',
    },
    selectFile: {
      marginTop:'8px'
    }
  });
  