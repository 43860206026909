import React, { useState, useEffect } from "react";
import styles from "./spokenTopic.style";
import PropTypes from "prop-types";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import Switch from '@material-ui/core/Switch';

import {
  Typography,
  Divider,
  Grid,
  TextField,
  // MenuItem,
  TableContainer,
  Paper,
  Modal,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import Close from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from "@material-ui/core/FormControlLabel";


const SpokenTopic = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [topicName, setTopicName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [createupdateTopic, setCreateupdateTopic] = React.useState("");
  const [index, setIndex] = useState(null);
  const [newTopicRes, setNewTopicRes] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [EachMarksQuestion, setEachQuestionMarks] = useState("");
  const [cutOfMarks, setCutOfMarks] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [searchData, setSearchData] = useState(null);
  
  // const IOSSwitch = withStyles((theme) => ({
  //   root: {
  //     width: 50,
  //     height: 26,
  //     padding: 0,
  //     margin: theme.spacing(1)
  //   },
  //   switchBase: {
  //     padding: 1,
  //     "&$checked": {
  //       transform: "translateX(24px)",
  //       color: theme.palette.common.white,
  //       "& + $track": {
  //         backgroundColor: "green",
  //         opacity: 1,
  //         border: "none"
  //       }
  //     },
  //     "&$focusVisible $thumb": {
  //       color: "",
  //       border: "6px solid #fff"
  //     }
  //   },
  //   thumb: {
  //     width: 24,
  //     height: 24
  //   },
  //   track: {
  //     borderRadius: 52 / 2,
  //     border: `1px solid ${theme.palette.grey[400]}`,
  //     backgroundColor: "red",
  //     opacity: 1,
  //     transition: theme.transitions.create(["background-color", "border"])
  //   },
  //   checked: {},
  //   focusVisible: {}
  // }))(({ classes, ...props }) => {
  //   return (
  //     <Switch
  //       focusVisibleClassName={classes.focusVisible}
  //       disableRipple
  //       classes={{
  //         root: classes.root,
  //         switchBase: classes.switchBase,
  //         thumb: classes.thumb,
  //         track: classes.track,
  //         checked: classes.checked
  //       }}
  //       {...props}
  //     />
  //   );
  // });

  const alert = useAlert();

  // const [ids, setIds] = useState();
  const handleClose = () => {
    // setGrade('')
    setIndex("");
    // setSubject('')
    setTopicName("");
    setEachQuestionMarks("");
    setCutOfMarks("");
    setOpen(false);
    // setIds(false);
    setChecked(null);
  };

//   useEffect(() => {
//       setChecked(!(topicTableData[ids]?.is_delete));
//   },[ids])

// const [checkedValue, setCheckedValue] = React.useState(false);
// useEffect(() => {
//   setCheckedValue(!(topicTableData[ids]?.is_delete));
// },[ids])

  const {
    data: topicTableData,
    isLoading: topicTableDataLoding,
    doFetch: fetchTableData,
  } = useFetch("", { suppressAlert: true });

  const {
    data: topicResponse,
    isLoading: topicResponseLoding,
    doFetch: spokenTopic,
  } = useFetch([], { suppressAlert: true });
const [deleteResponse,deleteData]= useState()
  // const {
  //   data: deleteResponse,
  //   isLoading: deleteResponceLoding,
  //   doFetch: deleteData,
  // } = useFetch([], { suppressAlert: true });

  useEffect(() => {
    if (topicResponse) {
      setNewTopicRes(topicResponse.results);
      setOpen(false);
      setEachQuestionMarks("");
      setCutOfMarks("");
      //  setGrade('');
      setIndex("");
      //  setSubject('');
      setTopicName("");
    }
    if (deleteResponse) {
      setNewTopicRes(deleteResponse);
    }
  }, [topicResponse, auth, deleteResponse]);

  useEffect(() => {
    if (newTopicRes) {
      // setGrade('')
      setIndex("");
      setTopicName("");
      setEachQuestionMarks("");
      setCutOfMarks("");
      // setSubject('')
      setNewTopicRes(null);
    }
  });

  // const handleCheckedValue = (status,id) => {
  //   let obj = {is_delete : !status}
  //   spokenTopic({
  //     url: `${urls.spokenRetrieveDel}${id}/retrieve_update_delete_spoken_topic_name/`,
  //     method: "PUT",
  //     body: obj,
  //     headers: {
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //       "Content-Type": "application/json",
  //     },
  //   });
  // }

  function functionSearch(data){
    setSearchData(data);
  }

  const CreateSpokenFunction = () => {
    if (!topicName || !cutOfMarks || !EachMarksQuestion) {
      alert.warning("Select All Fields");
      return;
    }
    const formData = new FormData();
    formData.append("topic_name", topicName);
    // formData.append('grade', grade);
    // formData.append('subject', subject);
    formData.append("cut_off", cutOfMarks);
    formData.append("each_topic_mark", EachMarksQuestion);
    formData.append("is_delete", false);
    spokenTopic({
      url: urls.spokenTopicApi,
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  };

  useEffect(() => {
    if (createupdateTopic[index]) {
      // setGrade(createupdateTopic[index].grade && createupdateTopic[index].grade.id)
      // setSubject(createupdateTopic[index].subject && createupdateTopic[index].subject.id)
      setEachQuestionMarks(createupdateTopic[index].each_topic_mark);
      setCutOfMarks(createupdateTopic[index].cut_off);
    }
  }, [createupdateTopic, index]);
  const updateTopicFunction = () => {
    if (
      !createupdateTopic[index].topic_name ||
      !cutOfMarks ||
      !EachMarksQuestion
    ) {
      alert.warning("Enter all fields");
      return;
    }
    const updateData = {
      id: createupdateTopic[index].id,
      topic_name: createupdateTopic[index].topic_name,
      // 'grade' :  grade,
      // 'subject' :  subject,
      cut_off: cutOfMarks,
      each_topic_mark: EachMarksQuestion,
      "is_delete": checked=== true?false:true,
    };
    spokenTopic({
      // url: `${urls.topicUpdateApi}${createupdateTopic[index].id}/retrieve_update_delete_written_topic/`,
      url: `${urls.spokenRetrieveDel}${createupdateTopic[index].id}/retrieve_update_delete_spoken_topic_name/`,
      method: "PUT",
      body: updateData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        "Content-Type": "application/json",
      },
    });
    alert.success("data updated successfully");
  };

  const {
    data: addQuestionRes,
    isLoading: addQuesLoading,
    doFetch: addQuestion,
  } = useFetch(null);

    function searchFunction() {
      fetchTableData({
      url: `${
      urls.spokenRetrieveApi
      }?search=${searchData || ''}`,
      method: "GET",
      headers: {
      "Content-Type": "apinterviewStatusplication/json",
      Authorization: `Bearer ${auth.personal_info.token}`,
      },
      });
      }
      
      useEffect(() => {
      if (auth) {
      searchFunction();
      }
      }, [auth, searchData]);

  function getResponse() {
    if (auth) {
      fetchTableData({
        url: `${urls.spokenRetrieveApi}?page_size=${rowsPerPage ||
          50}&page=${page + 1}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }


  useEffect(() => {
    getResponse();
  }, [fetchTableData, auth, page, rowsPerPage, topicResponse, deleteResponse]);

  useEffect(() => {
    if (topicTableData) {
      setCreateupdateTopic(topicTableData.results);
    }
  }, [topicTableData]);
  const editFunction = (data,i) => {
    setOpen(true);
    setIndex(i);
    // setIds(i);
    setChecked(!(data?.is_delete));
  };

 
  // const [deleteModel,setDeleteModel]=useState(false);
  // const [deleteId,setDeleteId]=useState();
  // function deleteTopic(data){
  //   setDeleteModel(true);
  //   setDeleteId(data);
  // }

  // function cancelDelete(){
  //   // setDeleteModel(false);
  //   // setDeleteId(null);
  // }
  // const deleteFunction = () => {
  //   fetch(`${urls.spokenRetrieveDel}${deleteId}/retrieve_update_delete_spoken_topic_name/`,{
  //     method: "DELETE",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   }
  //   ).then((response) => response.json())
  //   .then((data) => {
  //     deleteData(data);
  //     cancelDelete();
  //     alert.success("data deleted successfully")
  //   }).catch(() => {
  //     alert.error("Failed to delete");
  //   });
  // };
  const changeHandler = (e, key) => {
    setCreateupdateTopic((createupdateTopic) => {
      const newData = [...createupdateTopic];
      newData[index][key] = e;
      return newData;
    });
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function modalOpen() {
    let modal = null;
    if (createupdateTopic && createupdateTopic[index]) {
      modal = (
        <React.Fragment>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            // onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            // style={{ width: "80%", marginLeft: "10%" }}
          >
            <Fade in={open} style={{ width: "70%", marginLeft: "10%" }}>
              <div className={classes.paper}>
                <React.Fragment>
                  {/* <Typography className="headings" variant="h4">
                    {" "}
                    <strong>Update Topic</strong>
                  </Typography> */}
                  <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h4">
                    <strong>Update Topic</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
                  <Divider className={classes.divider} />
                  <Grid container spacing={2} style={{marginTop: "10px"}}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Topic Name"
                        margin="dense"
                        fullWidth
                        // required
                        value={
                          (createupdateTopic &&
                            createupdateTopic.length &&
                            createupdateTopic[index].topic_name) ||
                          ""
                        }
                        onChange={(e) =>
                          changeHandler(e.target.value, "topic_name")
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Enter Topic Marks"
                        className={classes.textField}
                        margin="dense"
                        required
                        type="number"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 2);
                        }}
                        onChange={(e) =>
                          e.target.value < 51 &&
                          setEachQuestionMarks(e.target.value)
                        }
                        value={EachMarksQuestion || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Enter Cut-of Marks"
                        className={classes.textField}
                        margin="dense"
                        required
                        type="number"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                        }}
                        onChange={(e) =>
                          e.target.value < 101 && setCutOfMarks(e.target.value)
                        }
                        value={cutOfMarks || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography style={{marginTop:"-15px"}}>Status</Typography>
                      In Active<Switch checked={checked || false} value={checked || false} onChange={(e) => setChecked(e.target.checked)} color='primary'/> Active
                   </Grid>
                    <Grid container align="center" justify="center">
                      <Grid item md={3} xs={12}>
                        <Button
                          // className={classes.applyButtonModal}
                          color="primary"
                          variant="contained"
                          size="large"
                          fullWidth
                          onClick={() => updateTopicFunction()}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </React.Fragment>
              </div>
            </Fade>
          </Modal>
        </React.Fragment>
      );
    }
    return modal;
  }

  function tableTopicDetails() {
    let tableData = null;
    if (topicTableData && topicTableData.results.length) {
      tableData = (
        <React.Fragment>
          <TableBody>
            {topicTableData.results.map((data, i) => (
              <TableRow key={data.id}>
                <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.topic_name || ""}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.each_topic_mark || ""}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.cut_off || ""}</TableCell>
               
                {/* <TableCell align="center" style={{ border: "1px solid black"}}>{data.grade && data.grade.grade_name || ''}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.subject && data.subject.subject_name || ''}</TableCell> */}
                <TableCell align="center" style={{ border: "1px solid black"}}>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => editFunction(data,i)}
                  >
                    Update
                  </Button>
                </TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>
                  {/* <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    // onClick={() => deleteTopic(data.id)}
                  >
                    In Active
                  </Button> */}
        <Grid item xs={3} md={12}>
      
          {data.is_delete ? (
            <CancelIcon style={{ color: "red" }} />
            ) : (
            <CheckCircleRoundedIcon style={{ color: "green" }} />
          )}
        
          {/* <FormControlLabel
          style={{ marginLeft: '15px'}}
          control={
          <IOSSwitch
              checked={!(data?.is_delete) || false}
              onChange={()=>handleCheckedValue(data?.is_delete,data?.id)}
          />
          } 
          />*/}
        </Grid>
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </React.Fragment>
      );
    }
    return tableData;
  }

  let loader = null;
  if (topicResponseLoding || topicTableDataLoding ) {
    loader = <Loader open />;
  }
  return (
    <React.Fragment>
    <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'space-between' }}>
    <div >
      <Typography className="headings" variant="h4">
        <strong>Spoken Topics</strong>
      </Typography>
    </div>    
    <Paper style={{ margin: '5px' }} >
          <TextField
            id="search-bar"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            label="I'm Looking For..."
            variant="outlined"
            size="small"
            name="subject"
            autoComplete="on"
            onChange={(e) => functionSearch(e.target.value)}
          />
    </Paper>
    </div>
    <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              label="Spoken Name"
              margin="dense"
              fullWidth
              required
              value={topicName}
              onChange={(e) => setTopicName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Enter Topic Marks"
              className={classes.textField}
              margin="dense"
              required
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 2);
              }}
              onChange={(e) =>
                e.target.value < 51 && setEachQuestionMarks(e.target.value)
              }
              value={EachMarksQuestion || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Cut-of Marks (%)"
              className={classes.textField}
              margin="dense"
              required
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              }}
              onChange={(e) =>
                e.target.value < 101 && setCutOfMarks(e.target.value)
              }
              value={cutOfMarks || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12} className={classes.applyButtonSection}>
            <Button
              className={classes.applyButton}
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              onClick={() => CreateSpokenFunction()}
            >
              Add Topic
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Divider className={classes.divider} />
      {topicTableData && topicTableData.results.length === 0 ? (
        <h1 style={{ marginTop: "10%", textAlign: "center", color: "blue" }}>
          Records Not Found
        </h1>
      ) : (
        ""
      )}
      {topicTableData && topicTableData.results.length !== 0 && (
        <Paper>
          <React.Fragment>
            <TableContainer className="role">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>S.No.</strong></TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>Topic Name</strong></TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>Marks For Topic</strong></TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>Cut_off Marks (%)</strong>
                    </TableCell>
                    {/* <TableCell align="center" style={{ border: "1px solid black"}}><strong>Status</strong></TableCell> */}
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>Update</strong></TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>Status</strong></TableCell>
                  </TableRow>
                </TableHead>
                {tableTopicDetails()}
              </Table>

              {modalOpen()}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    labelDisplayedRows={() =>
                      `Page ${page + 1} of ${+topicTableData.total_pages}`
                    }
                    rowsPerPageOptions={[50, 75, 100]}
                    count={+topicTableData.count}
                    rowsPerPage={rowsPerPage || 50}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: "13px" }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        lastPageChange(topicTableData.total_pages - 1)
                      }
                      disabled={page === +topicTableData.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </TableContainer>
          </React.Fragment>
        </Paper>
      )}
      {/*{deleteModel ==true && deleteId &&
      <Dialog
        open={deleteModel}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       <DialogTitle
            id='draggable-dialog-title'
          >
            Delete Spoken Topic
          </DialogTitle>     
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete ?
            </DialogContentText>
          </DialogContent>   
       
                  
                  <Divider className={classes.divider} />
                  <DialogActions>
            <Button onClick={cancelDelete} >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              style={{ color: 'white' }}
              onClick={deleteFunction}
              >
              Confirm
            </Button>
          </DialogActions>
      </Dialog>
      } */}
      {loader}
    </React.Fragment>
  );
};
SpokenTopic.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(SpokenTopic);
