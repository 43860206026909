import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  Stepper,
  Step,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  StepButton,
  Card
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./dashboard.style";

const OcefDashboard = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [testData, setTestData] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showPaper, setShowPaper] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [duration, setDuration] = useState(null);

  const {
    data: testStatus,
    isLoading: testStatusLoading,
    doFetch: fetchTestStatus
  } = useFetch(null, { suppressAlert: true });

  const {
    data: testDetail,
    isLoading: testDetailLoading,
    doFetch: fetchTestDetails
  } = useFetch(null, { suppressAlert: true });

  const {
    data: testAns,
    isLoading: testAnsLoading,
    doFetch: fetchTestAns
  } = useFetch(null, { suppressAlert: true });

  useEffect(() => {
    fetchTestStatus({
      url: urls.ocefTestStatus,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }, [name, fetchTestStatus]);

  useEffect(() => {
    if (testDetail) {
      setTestData(testDetail);
    }
  }, [testDetail]);

  useEffect(() => {
    if (testStatus) {
      fetchTestDetails({
        url: urls.ccefApplicantTestStart,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  }, [name, fetchTestDetails, testStatus]);

  useEffect(() => {
    if (testAns) {
      setShowPaper(false);
      setDuration(null);
      setActiveStep(0);
      setSelectedOption([]);
      setSelectedValue([]);
      fetchTestDetails({
        url: urls.ccefApplicantTestStart,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  }, [testAns, name, fetchTestDetails]);

  useEffect(() => {
    if (
      testDetail &&
      testDetail.test_detail &&
      testDetail.test_detail.duration
    ) {
      const sec = +testDetail.test_detail.duration * 60;
      setDuration(sec);
    }
  }, [testDetail]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (showPaper && duration > 0) {
      setDuration(t => t - 1);
    }
  }, [1000]);

  useEffect(() => {
    if (duration === 0) {
      let obj = {};
      obj = {
        response: selectedOption,
        question_id: testData && testData.test_detail && testData.test_detail.id
      };
      fetchTestAns({
        url: urls.ocefAnswerSave,
        body: obj,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  }, [duration, fetchTestAns, selectedOption, testData, name]);

  function startTest() {
    if (testData && testData.test_detail) {
      setShowDetails(true);
    }
  }

  function startPaper() {
    if (testData && testData.question_detail) {
      setShowPaper(true);
    }
  }

  function handleChange(e, ql, index) {
    const awV = [...selectedValue];
    awV[index] = true;
    setSelectedValue(awV);
    const awa = [...selectedOption];
    const ans = { id: ql.id, answer: e.target.value };
    awa[index] = ans;
    setSelectedOption(awa);
  }

  function handleClick(e, ql, index) {
    const awV = [...selectedValue];
    awV[index] = awV[index] ? false : true;
    setSelectedValue(awV);
    const awa = [...selectedOption];
    const ans = { id: ql.id, answer: e.target.value };
    awa[index] = awa[index] ? null : ans;
    setSelectedOption(awa);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleStep = step => () => {
    setActiveStep(step);
  };

  function handleNext(quesLen, stepLen, id) {
    if (stepLen < quesLen.length - 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    if (stepLen === quesLen.length - 1) {
      let obj = {};
      obj = {
        response: selectedOption,
        question_id: id
      };
      fetchTestAns({
        url: urls.ocefAnswerSave,
        body: obj,
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  }

  function instruction() {
    return (
      <Grid container spacing={3}>
        {testStatus === "Not Assign" ? (
          <React.Fragment>
            <Grid item md={4} className={classes.paperMain} />
            <Grid item md={4} className={classes.paperMain}>
              <Card className={classes.card}>
                <Typography variant='h5'>
                  Test has not yet Assigned to you please contact HR for further
                  process
                </Typography>
              </Card>
            </Grid>
          </React.Fragment>
        ) : (
          ""
        )}
        {testStatus && testStatus.status && testStatus.status.length ? (
          <React.Fragment>
            <Grid item md={4} className={classes.paperMain} />
            <Grid item md={4} className={classes.paperMain}>
              <Card className={classes.card}>
                <Typography variant='h5'>
                  Subject Completed :{testStatus.status[0]}
                  {testStatus.status[1] ? `, ${testStatus.status[1]}` : ""}
                </Typography>
                <div style={{ marginLeft: "40%" }}>
                  <Button
                    className={classes.addButton}
                    size='small'
                    // disabled={edit}
                    variant='contained'
                    onClick={startTest}
                  >
                    Countinue Test
                  </Button>
                </div>
              </Card>
            </Grid>
          </React.Fragment>
        ) : (
          ""
        )}
        {testStatus === "Test already completed" ||
        testData === "Test already completed" ||
        testDetail === "Test already completed" ? (
          <React.Fragment>
            <Grid item md={4} className={classes.paperMain} />
            <Grid item md={4} className={classes.paperMain}>
              <Card className={classes.card}>
                <Typography variant='h5'>
                  You have succesfully completed the test
                </Typography>
              </Card>
            </Grid>
          </React.Fragment>
        ) : (
          ""
        )}
        {testStatus === "Assign" ? (
          <React.Fragment>
            <Grid item md={3} className={classes.paperMain} />
            <Grid item md={6} className={classes.paperMain}>
              <Card className={classes.card}>
                <Typography variant='h5'>FACULTY RECRUITMENT TEST</Typography>
                <Divider className={classes.dividerInstruction} />
                <div style={{ padding: "20px" }}>
                  <h3>General Instructions for Online Examination</h3>
                  <ol>
                    <li>
                      You are writing three different Subject tests : Test 1
                      (Section A) - contains 20 questions in Mathematics ,Test 2
                      (Section B) - contains 20 questions in Science(Physics and
                      Chemistry and Test 3(Section C) - contains 10 questions in
                      Logical Reasoning
                    </li>
                    <li>
                      The time allotted is different for different tests. There
                      is a timer for each test
                    </li>
                    <li>
                      The timer starts as soon as you click on the start test
                      button to take the test and there is no provision to pause
                      or restart the timer.
                    </li>
                    <li>
                      The clock will be set at the server. The countdown timer
                      in the top left corner of screen will display the
                      remaining time available for you to complete the test.
                      When the timer reaches zero, the test will end by itself
                      and for writing the next test you need to click the start
                      button for the respective test.{" "}
                    </li>
                    <li>
                      If you finish any test before time or you want to take new
                      test then you have to click finish button displayed at the
                      last question page of that test . For taking next test you
                      have to click again on start button for that respective
                      test and there will be no carry over time from the
                      previous test
                    </li>
                    <li>
                      For the right answer to any question +2 marks will be
                      awarded
                    </li>
                    <li>
                      For an unattempted question 0 marks will be awarded. There
                      is no negative marking for any question
                    </li>
                    <li>For every question there is only one option correct</li>
                    <li>
                      The Maximum marks for Section A , Section B and Section C
                      are 40, 40 and 20 respectively
                    </li>
                  </ol>
                  <h3>Answering a Question</h3>
                  <ul>
                    <li>
                      Question palette will appear on the top of the screen
                    </li>
                    <li>
                      To select your option click on the button of one of the
                      options of that respective question
                    </li>
                    <li>
                      To change your chosen answer any time you can select the
                      respective question from the palette and change the option
                    </li>
                    <li>
                      If you want to go from one question to just another
                      question you can do it by selecting question from the
                      palette box or by clicking the next button
                    </li>
                    <li>
                      If you want to go from one question to any next / previous
                      question you can go by selecting the question from the
                      palette box
                    </li>
                    <li>
                      If you have chosen option for any question that question
                      will appear as marked question in palette box
                    </li>
                  </ul>
                </div>
                <div style={{ marginLeft: "40%" }}>
                  <Button
                    className={classes.addButton}
                    size='small'
                    // disabled={edit}
                    variant='contained'
                    onClick={startTest}
                  >
                    Start Test
                  </Button>
                </div>
              </Card>
            </Grid>
          </React.Fragment>
        ) : (
          ""
        )}
      </Grid>
    );
  }

  function sectionDetails() {
    if (testData && testData.test_detail) {
      return (
        <Grid container spacing={3}>
          <Grid item md={4} className={classes.paperMain} />
          <Grid item md={4} className={classes.paperMain}>
            <Card className={classes.card}>
              <Typography variant='h5'>Section Details</Typography>
              <Divider className={classes.dividerInstruction} />
              <div style={{ marginLeft: "14%" }}>
                <p>
                  Subject :{" "}
                  {testData.test_detail && testData.test_detail.test_name}
                </p>
                <p>
                  No of Questions :{" "}
                  {testData.test_detail && testData.test_detail.no_of_ques}
                </p>
                <p>
                  Duration :{" "}
                  {testData.test_detail && testData.test_detail.duration}{" "}
                  minutes
                </p>
                <p>
                  Cut Off :{" "}
                  {testData.test_detail && testData.test_detail.cut_off}
                </p>
              </div>
              <div style={{ marginLeft: "40%" }}>
                <Button
                  className={classes.addButton}
                  size='small'
                  // disabled={edit}
                  variant='contained'
                  onClick={startPaper}
                >
                  Start Test
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      );
    }
    return null;
  }

  function getStepContent(stepIndex) {
    const optionList = testData.question_detail[stepIndex || 0].option;
    switch (stepIndex) {
      case stepIndex:
        return (
          <div>
            <div>
              {ReactHtmlParser(
                `${stepIndex + 1}${
                  testData.question_detail[stepIndex].question
                }`
              )}
            </div>
            <FormControl
              component='fieldset'
              name='radioOption'
              className={classes.formControl}
            >
              <RadioGroup
                style={{ marginLeft: "30px" }}
                aria-label='answer'
                name='option'
                className={classes.group}
                onChange={e =>
                  handleChange(
                    e,
                    testData.question_detail[stepIndex],
                    stepIndex
                  )
                }
                onClick={e =>
                  handleClick(e, testData.question_detail[stepIndex], stepIndex)
                }
              >
                {optionList.map((opt, i) => (
                  <FormControlLabel
                    checked={
                      selectedOption &&
                      selectedOption.length &&
                      selectedOption[stepIndex] &&
                      selectedOption[stepIndex].answer - 1 === i
                        ? true
                        : false
                    }
                    key={opt.id}
                    // name={ReactHtmlParser(opt)}
                    value={opt.id}
                    control={<Radio />}
                    label={ReactHtmlParser(opt.option_name)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        );
      default:
        return "Uknown stepIndex";
    }
  }

  function countDown() {
    const time = duration;
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor((time / 60) % 60);
    return (
      <Grid item sm={4} md={8} xs={12} style={{ fontSize: "20px" }}>
        {minutes}:{seconds}
      </Grid>
    );
  }

  const testQuestions = () => {
    if (
      testData &&
      testData.question_detail &&
      testData.question_detail.length
    ) {
      return (
        <Grid container spacing={3}>
          <Grid item md={12} className={classes.paperMain}>
            <Card className={classes.card}>
              {countDown()}
              <Stepper activeStep={activeStep} nonLinear>
                {testData &&
                  testData.question_detail &&
                  testData.question_detail.length &&
                  testData.question_detail.map((label, i) => (
                    <Step key={label.id}>
                      <StepButton
                        onClick={handleStep(i)}
                        completed={!!selectedValue[i]}
                      />
                    </Step>
                  ))}
              </Stepper>
              <div>
                <div>{getStepContent(activeStep)}</div>
                <div>
                  <Button
                    style={{ margin: "10px" }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    style={{
                      marginTop: "10px",
                      float:
                        activeStep === testData.question_detail.length - 1
                          ? "right"
                          : ""
                    }}
                    onClick={() =>
                      handleNext(
                        testData.question_detail,
                        activeStep,
                        testData.test_detail.id
                      )
                    }
                  >
                    {activeStep === testData.question_detail.length - 1
                      ? "Finish"
                      : "Next"}
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  let loader = null;
  if (testAnsLoading || testDetailLoading || testStatusLoading) {
    loader = <Loader open />;
  }

  return (
    <React.Fragment>
      <Typography variant='h4'>OCFP</Typography>
      <Divider className={classes.divider} />
      <React.Fragment>
        {!showDetails ? instruction() : null}
        {showDetails && !showPaper ? sectionDetails() : null}
        {showPaper ? testQuestions() : ""}
        {testData && showDetails === "Test already completed"
          ? instruction()
          : null}
      </React.Fragment>
      {loader}
    </React.Fragment>
  );
};

OcefDashboard.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};

export default withStyles(styles)(OcefDashboard);
