import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Button,
  Table,
  Modal,
  Tag,
  Input,
  Tabs,
  message,
  Tooltip,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert/alert";
import { useForm } from "antd/es/form/Form";
import axios from "axios";

const FaceToFaceReassignNew = () => {
  const [rescheduleFaceToFaceForm] = useForm();
  const [asigneeForm] = useForm();
  const { Option } = Select;
  const { TabPane } = Tabs;
  const { RangePicker } = DatePicker;
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [startDate, setStartDate] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [endDate, setEndDate] = useState(null);
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [gettingRoleList, setGettingRoleList] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [openingList, setOpeningList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [viewOpenning, setViewOpennings] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDate, setSelectedDate] = useState(" ");
  const [selectedTime, setSelectedTime] = useState(" ");
  const [assignOpen, setAssignOpen] = useState(false);
  const [duration, setdDration] = useState();
  const [interviewerFree, setInterviewerFree] = useState({ response: null });
  const [selectedInterviewer, setSelectedInterviewer] = useState("");
  const [applicantId, setApplicantId] = useState(null);
  const [applicentName, setApplicentName] = useState("");
  const [value, setValue] = React.useState(1);
  const [isCancelled, setIsCancelled] = useState(false);
  const [cancelLoader, setCancelLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [f2fData, setFace2faceData] = useState(null);
  const [gradeCheck, setGradeCheck] = useState("");
  const [subjectCheck, setSubjectCheck] = useState("");
  const [branch, setBranch] = useState("");
  const [checked, setChecked] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const [recruiterList, setRecruiterList] = useState([]);
  const [recruiters, setRecruiters] = useState(null);
  const statusDetails = [
    { statusId: 0, statusName: "Re-Scheduled" },
    { statusId: 1, statusName: "Cancelled" },
  ];
  const alert = useAlert();

  // const {
  //   data: roleList,
  //   isLoading: gettingRoleList,
  //   doFetch: FetchRoleList,
  // } = useFetch([], { suppressAlert: true });

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings,
  } = useFetch([], { suppressAlert: true });

  const {
    data: cancelInterviewData,
    isLoading: loadingCancelInterviewData,
    doFetch: fetchcancelInterviewData,
  } = useFetch(null);

  const {
    data: roleData,
    isLoading: roleDataLoading,
    doFetch: fetchRole,
  } = useFetch("", { suppressAlert: true });

  const {
    // data: f2fData,
    isLoading: gettingF2Fdata,
    doFetch: fetchF2Fdetails,
  } = useFetch(null);

  const {
    data: isInterviewerFree,
    isLoading: isInterviewerFreeLoading,
    doFetch: fetchIsInterviewerFree,
  } = useFetch([], { suppressAlert: true });

  const {
    data: assignSpokenTest,
    isLoading: assignSpokenTestLoading,
    doFetch: fetchAssignSpokenTest,
  } = useFetch([], { suppressAlert: true });

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch("", { suppressAlert: true });

  const {
    data: interviewerList,
    isLoading: interviewerListLoading,
    doFetch: fetchInterviewerList,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branchesList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });

  // const {
  //   data: grades,
  //   isLoading: gradesLoading,
  //   doFetch: fetchGrades,
  // } = useFetch([], { suppressAlert: true });

  // const {
  //   data: subjects,
  //   isLoading: subjectsLoading,
  //   doFetch: fetchSubjects,
  // } = useFetch([], { suppressAlert: true });

  // const { data: gradeList, doFetch: fetchGradeList } = useFetch(null);

  // const { data: cityList, doFetch: fetchCityList } = useFetch([], {
  //   suppressAlert: true,
  // });

  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const handlePositionChange = (e, val) => {
    setPositionType(val?.value);
    const position_type = rescheduleFaceToFaceForm.getFieldsValue()
      ?.position_type;

    rescheduleFaceToFaceForm.setFieldsValue({
      role: null,
      opening: null,
    });
    if (position_type) {
      fetchRoleList({
        position_type: position_type,
      });
    } else {
      setRoleList([]);
    }
  };

  const fetchCityList = () => {
    axios
      .get(`${urls.cityList}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        setCityList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const handleCityChange = (e, val) => {
    const city = rescheduleFaceToFaceForm.getFieldsValue()?.city;
    setCheckedLoc(city);
    rescheduleFaceToFaceForm.setFieldsValue({
      branch: null,
    });
    if (city) {
      fetchBranchList({
        city_id: city,
      });
    } else {
      setCityList([]);
    }
  };

  const fetchBranchList = (params = {}) => {
    axios
      .get(`${urls.branchCityMapping}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setBranchList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  function OpenViewDetails(ID) {
    fetchApplicantDetail({
      url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  const fetchGradeList = () => {
    setLoading(true);
    axios
      .get(`${urls.newGrade}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setGradeList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGradeChange = (val) => {
    setGradeCheck(val);
    const grade = rescheduleFaceToFaceForm.getFieldsValue()?.grade;
    rescheduleFaceToFaceForm.setFieldsValue({
      subject: null,
    });
    if (grade) {
      fetchSubjectList({
        grade_id: grade,
      });
    } else {
      setSubjectList([]);
    }
  };

  const fetchSubjectList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.viewSubjectApiView}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setSubjectList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function fetchViewOpeningsData(params = {}) {
    axios
      .get(`${urls.ViewOpenningsApi}`, {
        params: params,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOpeningList(response?.data);
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const fetchRecruiterList = () => {
    axios
      .get(`${urls.retrieveRecuriterPanel}`, {
        headers: {
          Authorization: `Bearer ${name?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          setRecruiterList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {});
  };

  useEffect(() => {
    fetchPositionTypesList();
    fetchCityList();
    fetchGradeList();
    fetchRecruiterList();
  }, []);

  useEffect(() => {
    if (isInterviewerFree && isInterviewerFree.response) {
      setInterviewerFree(isInterviewerFree);
    }
  }, [isInterviewerFree]);

  useEffect(() => {
    if (selectedRole) {
      fetchInterviewerList({
        url: `${urls.interviewersRoleWiseEmployeeAdminApi}?role_id=${selectedRole}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [selectedRole]);

  useEffect(() => {
    if (applicantDetail) {
      setOpen(true);
    }
  }, [applicantDetail]);

  const handleClose = () => {
    setOpen(false);
    setAssignOpen(false);
    setShowEdit(false);
  };

  // useEffect(() => {
  //   if (positionType) {
  //     fetchRoleList({ position_type: positionType });
  //     fetchGrades({
  //       url: `${urls.newGrade}?position_type=${positionType}`,
  //       method: "GET",
  //       flag: false,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     fetchCityList({
  //       url: urls.cityList,
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${name.personal_info.token}`,
  //       },
  //     });
  //     setChecked("");
  //     setCheckedLoc("");
  //     setBranch("");
  //     setGradeCheck("");
  //     setSubjectCheck("");
  //   }
  // }, [positionType]);

  useEffect(() => {
    if (positionType && RoleId) {
      const formData = rescheduleFaceToFaceForm.getFieldsValue();
      fetchViewOpeningsData({
        position_type: formData?.position_type,
        role: formData?.role,
        city_id: formData?.city,
        branch_id: formData?.branch,
        grade_id: formData?.grade,
        subject_id: formData?.subject,
      });
    }
  }, [RoleId, positionType, branch, checkedLoc, subjectCheck, gradeCheck]);

  // const handleBranchChange = (val) => {
  //   setBranch(val);
  // const formData = rescheduleFaceToFaceForm.getFieldsValue();
  // fetchViewOpeningsData({
  //   position_type: formData?.position_type,
  //   role: formData?.role,
  //   city_id: formData?.city,
  //   branch_id: formData?.branch,
  //   grade_id: formData?.grade,
  //   subject_id: formData?.subject,
  // });
  // };

  const handleTabChange = (newValue) => {
    console.log({ newValue });
    rescheduleFaceToFaceForm.resetFields();
    setFace2faceData(null);
    setIsCancelled(newValue == 1);
    setPositionType("");
    setBranch("");
    setCheckedLoc("");
    setRoleId("");
    setGradeCheck("");
    setStartDate(null);
    setEndDate(null);
    setSubjectCheck("");
    setViewOpennings("");
    setPage(1);
    setValue(newValue);
  };

  const cancelInterview = (id) => {
    setCancelLoader(true);
    fetch(urls.cancelInterviewAPI, {
      method: "PUT",
      body: JSON.stringify({ id }),
      headers: {
        Authorization: `Bearer ${name.personal_info.token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        setCancelLoader(false);
        if (res.status === 200) {
          alert.success("Successfully Fetched");
          functiontoSearchF2FDetails(true);
          return res.json();
        }
        return 0;
      })
      .catch((error) => {
        setCancelLoader(false);
        alert.warning("Something went wrong. Please try again");
      });
  };

  const fetchFace2FaceDetails = (params = {}) => {
    setLoading(true);
    axios
      .get(urls.f2fReassignApplicentsApi, { params: { ...params } })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          alert.success("Successfully Fetched");
          setFace2faceData(res.data);
        }
      })

      .catch((error) => {
        setLoading(false);
      });
  };

  function functiontoSearchF2FDetails(firstPage) {
    const formData = rescheduleFaceToFaceForm.getFieldsValue();
    fetchFace2FaceDetails({
      page_size: rowsPerPage,
      page: firstPage ? 1 : page,
      cancelled: formData.cancelled,
      position_type: formData?.position_type,
      role: formData?.role,
      opening: formData?.opening,
      start_date: formData?.date_range
        ? formData?.date_range[0]?.format("YYYY-MM-DD")
        : null,
      end_date: formData?.date_range
        ? formData?.date_range[1]?.format("YYYY-MM-DD")
        : null,
      city_id: formData?.city,
      branch_id: formData?.branch,
      grade_id: formData?.grade,
      subject_id: formData?.subject,
    });
    if (firstPage) {
      setPage(1);
    }
  }

  //comment
  useEffect(() => {
    if (RoleId && positionType) {
      functiontoSearchF2FDetails();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (assignSpokenTest) {
      if (RoleId && positionType) {
        functiontoSearchF2FDetails();
      }
      setInterviewerFree({ response: null });
      setAssignOpen(false);
      setdDration("");
      setSelectedInterviewer("");
      setSelectedDate("");
      setSelectedTime("");
    }
  }, [assignSpokenTest]);

  const assignZoom = () => {
    if (!selectedInterviewer || !selectedTime || !selectedDate || !duration) {
      alert.warning("Select all the fields");
      return;
    }
    fetchIsInterviewerFree({
      url: `${
        urls.checkInterviewFree
      }?user=${selectedInterviewer}&applicant=${applicantId}&duration=${duration}&start_time=${`${dayjs(
        selectedDate
      ).format("YYYY-MM-DD")},${selectedTime}:00`}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  useEffect(() => {
    if (interviewerFree.response === "success") {
      const obj = {
        applicant: applicantId,
        user: selectedInterviewer,
        // topic: selectTopic,
        interviewers_list:
          recruiters && recruiters?.length > 0
            ? [...recruiters, selectedInterviewer]
            : [selectedInterviewer],
        is_cancel: true,
        duration: duration,
        start_time: `${`${dayjs(selectedDate).format(
          "YYYY-MM-DD"
        )},${selectedTime}:00`}`,
      };
      fetchAssignSpokenTest({
        url: urls.assignPlannerRound,
        method: "post",
        body: obj,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [interviewerFree, fetchAssignSpokenTest, name]);

  const assignModal = (
    <>
      <Modal
        title="Re Schedule"
        open={assignOpen}
        onCancel={handleClose}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={handleClose}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={assignZoom}>
                Re Schedule
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form form={asigneeForm} layout="vertical">
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  onChange={(e, val) => setSelectedRole(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {roleData &&
                    roleData.length !== 0 &&
                    roleData.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.role_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {selectedRole && (
              <Col span={24}>
                <Form.Item
                  name="interviewer"
                  label="Interviewer"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Interviewer",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Interviewer"
                    onChange={(e, val) => setSelectedInterviewer(val?.value)}
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {interviewerList &&
                      interviewerList.length !== 0 &&
                      interviewerList.map((option, index) => {
                        return (
                          <Option key={option?.value} value={option?.id}>
                            {option.first_name ? option.first_name : ""}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item name={"interviewers_list"} label="Select Recruiters">
                <Select
                  placeholder="Select Recruiter"
                  mode="multiple"
                  allowClear
                  onChange={(e) => setRecruiters(e)}
                  showSearch
                  filterOption={(input, options) => {
                    console.log(input, options);
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {recruiterList &&
                    recruiterList.length !== 0 &&
                    recruiterList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option.first_name
                            ? `${option.first_name} [${option?.username}]`
                            : ""}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="select_date"
                label="Select Date"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e) => setSelectedDate(e)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="select_time"
                label="Select Time"
                rules={[
                  {
                    required: true,
                    message: "Please Select Time",
                  },
                ]}
              >
                <TimePicker
                  onChange={(val) => {
                    setSelectedTime(val.format("HH:MM"));
                  }}
                  style={{ width: "100%" }}
                  format="HH:mm"
                  use12Hours
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="duration"
                label="Duration in minutes"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Duration (in min)",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Enter Duration (in min)"
                  min={1}
                  style={{ width: "100%" }}
                  onChange={(val) => setdDration(val)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );

  const fetchRoleList = (params = {}) => {
    setGettingRoleList(true);
    axios
      .get(`${urls.designationListApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setRoleList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setGettingRoleList(false);
      });
  };

  function AssignFuncion(id) {
    fetchRole({
      url: urls.interviewPanalRolesApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
    setApplicantId(id);
    setAssignOpen(true);
  }

  let modal = null;
  if (applicantDetail) {
    modal = (
      <Modal
        title={showEdit ? "Edit Profile" : "Details"}
        open={open}
        onCancel={handleClose}
        centered
        width={1000}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={false}
      >
        <>
          <Row align="middle" gutter={24}>
            <Col span={10}>
              <div className="details-modal-subheading">Name</div>
              <div className="details-modal-subheading">PhoneNo</div>
              <div className="details-modal-subheading">Email</div>
              <div className="details-modal-subheading">Grade</div>
              <div className="details-modal-subheading">Role</div>
              <div className="details-modal-subheading">Position Applied</div>
              <div className="details-modal-subheading">Branch Interested</div>
              <div className="details-modal-subheading">Application Date</div>
              <div className="details-modal-subheading">Resume</div>
            </Col>
            <Col span={2}>
              <div className="details-modal-colon">:</div>
              <div className="details-modal-colon">:</div>
              <div className="details-modal-colon">:</div>
              <div className="details-modal-colon">:</div>
              <div className="details-modal-colon">:</div>
              <div className="details-modal-colon">:</div>
              <div className="details-modal-colon">:</div>
              <div className="details-modal-colon">:</div>
              <div className="details-modal-colon">:</div>
            </Col>
            <Col span={12}>
              <div className="details-modal-text">
                {(applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.user &&
                  applicantDetail.applicant.user.first_name) ||
                  "No Data"}
              </div>
              <div className="details-modal-text">
                {(applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.contact_no) ||
                  "No Data"}
              </div>
              <div className="details-modal-text">
                {(applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.user &&
                  applicantDetail.applicant.user.email) ||
                  "No Data"}
              </div>
              <div className="details-modal-text">
                {(applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.relevant_fk &&
                  applicantDetail.applicant.relevant_fk.grade &&
                  applicantDetail.applicant.relevant_fk.grade.grade_name) ||
                  "No Data "}
              </div>
              <div className="details-modal-text">
                {(applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.applicant_role &&
                  applicantDetail.applicant.applicant_role
                    .applicant_role_name) ||
                  "No Data"}
              </div>
              <div className="details-modal-text">
                {(applicantDetail &&
                  applicantDetail.applicant.position_apply &&
                  applicantDetail.applicant.position_apply.title) ||
                  "No Data"}
              </div>
              <div className="details-modal-text">
                {(applicantDetail.applicant &&
                  applicantDetail.applicant.relevant_fk &&
                  applicantDetail.applicant.relevant_fk.branch &&
                  applicantDetail.applicant.relevant_fk.branch.branch_name) ||
                  ""}
              </div>
              <div className="details-modal-text">
                {applicantDetail.applicant.register_date || "No Data"}
              </div>
              <div className="details-modal-text">
                {applicantDetail.applicant &&
                applicantDetail.applicant.resume ? (
                  <a
                    target="_blank"
                    href={applicantDetail.applicant.resume || ""}
                    onclick="window.open(this.href); return false;"
                  >
                    <span>View Resume</span>
                  </a>
                ) : (
                  "Resume Not Uploaded"
                )}
              </div>
            </Col>
          </Row>

          <Row
            align="middle"
            style={{ marginTop: "50px", marginBottom: "50px" }}
            gutter={24}
          >
            <Col span={8} className="page_body">
              <div>
                <b>1. MCQ </b>
              </div>
              {applicantDetail && applicantDetail.mcq_test_completed ? (
                <a>
                  Completed
                  <div>
                    Marks=
                    {(applicantDetail &&
                      applicantDetail.mcq_test_completed &&
                      applicantDetail.mcq_score &&
                      applicantDetail.mcq_score.mark_scored) ||
                      "Not Uploaded"}
                  </div>
                </a>
              ) : (
                "Pending"
              )}
            </Col>
            <Col span={8} className="page_body">
              <div>
                <b>2.Written Test </b>
              </div>
              {applicantDetail && applicantDetail.written_test_completed ? (
                <a>
                  Completed
                  <div>
                    Marks=
                    {(applicantDetail &&
                      applicantDetail.written_test_completed &&
                      applicantDetail.written_score &&
                      applicantDetail.written_score.mark_scored) ||
                      "Not Uploaded"}
                  </div>
                </a>
              ) : (
                "Pending"
              )}
            </Col>
            <Col span={8} className="page_body">
              <div>
                <b> 3.Spoken Test </b>
              </div>
              {applicantDetail && applicantDetail.spoken_test_completed ? (
                <a>
                  Completed
                  <div>
                    Marks=
                    {(applicantDetail &&
                      applicantDetail.spoken_test_completed &&
                      applicantDetail.spoken_score &&
                      applicantDetail.spoken_score.mark_scored) ||
                      "Not Uploaded"}
                  </div>
                </a>
              ) : (
                "Pending"
              )}
            </Col>
            {applicantDetail &&
              applicantDetail.applicant_f2f &&
              applicantDetail.applicant_f2f.map((item, index) => (
                <Col span={8} className="page_body">
                  <div>
                    <b>
                      {4 + index}: {item.round}{" "}
                    </b>
                  </div>
                  <a>
                    <div>Marks : {item.mark_scored || "pending"}</div>
                  </a>
                </Col>
              ))}
          </Row>
        </>
      </Modal>
    );
  }

  const columns = [
    {
      title: "Meeting Name",
      key: "meeting_name",
      width: "10%",
      dataIndex: "meeting_name",
    },
    {
      title: "Name",
      key: "applicant_name",
      width: "10%",
      dataIndex: "applicant_name",
    },
    {
      title: "Contact",
      key: "applicant_contact",
      width: "10%",
      dataIndex: "applicant_contact",
    },
    {
      title: "Location",
      key: "branch",
      width: "10%",
      dataIndex: "branch",
    },
    {
      title: "Start Date",
      key: "start_date",
      width: "10%",
      render: (data) => <span>{data.start_date || ""}</span>,
    },

    {
      title: "Re Schedule",
      key: "re_schedule",
      width: "10%",
      render: (data) => (
        <span>
          <Tooltip
            title={
              data?.is_cancel
                ? null
                : "You need to cancel this interview to reschedule it"
            }
          >
            <Tag
              color="geekblue"
              style={{
                cursor: !data?.is_cancel ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                if (data?.is_cancel) {
                  AssignFuncion(data.applicant);
                }
              }}
            >
              Re Schedule
            </Tag>
          </Tooltip>
        </span>
      ),
    },

    {
      title: "Cancel",
      key: "cancel",
      width: "10%",
      render: (data) => (
        <span>
          {!data?.is_cancel ? (
            <Tag
              color="error"
              style={{
                cursor: data?.process_start ? "not-allowed" : "pointer",
              }}
              onClick={() => cancelInterview(data.id)}
            >
              Cancel
            </Tag>
          ) : (
            <span
              style={{
                color: "#a1a1c2",
                fontStyle: "italic",
              }}
            >
              Cancelled
            </span>
          )}
        </span>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between">
        <Col span={18} className="page_title">
          Re Schedule Face To Face
        </Col>
      </Row>{" "}
      <div className="page_body">
        <Form
          id="rescheduleFaceToFaceForm"
          form={rescheduleFaceToFaceForm}
          layout="vertical"
          onFinish={functiontoSearchF2FDetails}
        >
          <Row align="middle" gutter={24}>
            <Col span={6}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Position Type"
                  onChange={(e, val) => handlePositionChange(e, val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {positionTypeList &&
                    positionTypeList.length !== 0 &&
                    positionTypeList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.position_type}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  onChange={(e, val) => setRoleId(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {roleList &&
                    roleList.length !== 0 &&
                    roleList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="cancelled" label="Cancellation Status">
                <Select placeholder="Select Cancellation Status">
                  <Option key={"cancelled"} value={true}>
                    Cancelled
                  </Option>
                  <Option key={"not"} value={false}>
                    Active
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="city" label="City">
                <Select
                  placeholder="Select City"
                  onChange={(e, val) => handleCityChange(val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {cityList &&
                    cityList.length !== 0 &&
                    cityList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.city_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="branch" label="Branch">
                <Select
                  placeholder="Select Branch"
                  onChange={(e, val) => setBranch(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {branchList &&
                    branchList.length !== 0 &&
                    branchList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.branch_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="grade"
                label={positionType == "Academic" ? "Grade" : "Level"}
              >
                <Select
                  placeholder={`Select ${
                    positionType === "Academic" ? "Grade" : "Level"
                  }`}
                  onChange={(e, val) => handleGradeChange(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {gradeList &&
                    gradeList.length !== 0 &&
                    gradeList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.grade_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="subject"
                label={positionType == "Academic" ? "Subject" : "Topic"}
              >
                <Select
                  placeholder={`Select ${
                    positionType === "Academic" ? "Subject" : "Topic"
                  }`}
                  onChange={(e, val) => setSubjectCheck(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {subjectList &&
                    subjectList.length !== 0 &&
                    subjectList.map((option, index) => {
                      return (
                        <Option
                          key={option?.subject?.id}
                          value={option?.subject?.id}
                        >
                          {option?.subject?.subject_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="opening" label="Opening">
                <Select
                  placeholder="Select Opening"
                  onChange={(e, val) => setViewOpennings(e, val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {openingList &&
                    openingList.length !== 0 &&
                    openingList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col>
              <Form.Item name="date_range" label="Start Date - End Date">
                <RangePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col>
              <Button
                form="rescheduleFaceToFaceForm"
                htmlType="submit"
                type="primary"
                disabled={positionType && RoleId ? false : true}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={f2fData?.results}
          pagination={{
            showSizeChanger: false,
            total: f2fData?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={
            gettingRoleList ||
            interviewerListLoading ||
            isInterviewerFreeLoading ||
            assignSpokenTestLoading ||
            gettingViewOpennings ||
            gettingF2Fdata ||
            applicantDetailLoading ||
            roleDataLoading ||
            loadingCancelInterviewData ||
            cancelLoader ||
            loading ||
            gettingBranches
          }
          rowKey={(data) => data?.id}
        />
      </div>
      {assignModal}
      {modal}
    </>
  );
};

export default FaceToFaceReassignNew;
