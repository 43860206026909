import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Button,
  Table,
  Modal,
  Tag,
  Input,
  message,
} from "antd";
import moment from "moment";
import urls from "../../../url";
import debounce from "lodash/debounce";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import { SyncOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const FaceToFaceAllRoundsNew = () => {
  const [asigneeForm] = useForm();
  const [faceToFaceForm] = useForm();
  const { Option } = Select;
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [recruiters, setRecruiters] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [endDate, setEndDate] = useState(null);
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [viewOpenning, setViewOpennings] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDate, setSelectedDate] = useState(" ");
  const [selectedTime, setSelectedTime] = useState(" ");
  const [assignOpen, setAssignOpen] = useState(false);
  const [duration, setdDration] = useState();
  const [interviewerFree, setInterviewerFree] = useState({ response: null });
  const [selectedInterviewer, setSelectedInterviewer] = useState("");
  const [applicantId, setApplicantId] = useState(null);
  const [gradeCheck, setGradeCheck] = useState("");
  const [subjectCheck, setSubjectCheck] = useState("");
  const [branch, setBranch] = useState("");
  const [checked, setChecked] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [viewOpenningsData, setViewOpenningsData] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [f2fData, setF2FData] = useState([]);
  const [isInterviewerFree, setIsInterviewerFree] = useState();
  const [assignSpokenTest, setAssignSpokenTest] = useState([]);
  const [applicantDetail, setApplicantDetail] = useState([]);
  const [interviewerList, setInterviewerList] = useState([]);
  const [recruiterList, setRecruiterList] = useState([]);
  const [classDemo, setClassDemo] = useState(false);

  const fetchRoleList = ({ url }) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRoleList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const fetchRecruiterList = () => {
    axios
      .get(`${urls.retrieveRecuriterPanel}`, {
        headers: {
          Authorization: `Bearer ${name?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          setRecruiterList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {});
  };

  const fetchViewOpennings = ({ url }) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setViewOpenningsData(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const fetchInterviewerList = ({ url }) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInterviewerList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };
  const fetchApplicantDetail = ({ url }) => {
    setLoading(true);
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setApplicantDetail(response?.data);
          setOpen(true);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchAssignSpokenTest = ({ url, body }) => {
    setLoading(true);
    axios
      .post(`${url}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAssignSpokenTest(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchIsInterviewerFree = ({ url }) => {
    setLoading(true);
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setIsInterviewerFree(response?.data);
          message.success("Assigned Successfully");
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDebounce = useCallback(
    debounce(() => {
      fetchF2Fdetails();
    }, 1000),
    []
  );

  const fetchF2Fdetails = (params = {}) => {
    const values = faceToFaceForm.getFieldsValue();
    let obj = {
      page: page,
      page_size: rowsPerPage,
    };
    if (values.position_type) {
      obj.position_type = values.position_type;
    }
    if (values.role) {
      obj.role_id = values.role;
    }
    if (values.opening) {
      obj.openning_id = values.opening;
    }
    if (values.start_date) {
      obj.start_date = dayjs(values.start_date).format("YYYY-MM-DD");
    }
    if (values.end_date) {
      obj.end_date = dayjs(values.end_date).format("YYYY-MM-DD");
    }
    if (values.search) {
      obj.global_search = values.search;
    }
    if (values.city) {
      obj.city_id = values.city;
    }
    if (values.branch) {
      obj.branch_id = values.branch;
    }
    if (values.is_class_demo) {
      obj.is_class_demo = values.is_class_demo;
    }

    setLoading(true);
    axios
      .get(urls.f2fApplicentsApi, {
        params: obj,
      })
      .then((response) => {
        if (response.status === 200) {
          setF2FData(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchRole = ({ url }) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRoleData(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const fetchBranches = ({ url }) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setBranchesList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`)
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchCityList = ({ url }) => {
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCityList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  function OpenViewDetails(ID) {
    fetchApplicantDetail({
      url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
    });
  }

  useEffect(() => {
    if (isInterviewerFree && isInterviewerFree.response) {
      setInterviewerFree(isInterviewerFree);
    }
  }, [isInterviewerFree]);

  useEffect(() => {
    if (selectedRole) {
      fetchInterviewerList({
        url: `${urls.interviewersRoleWiseEmployeeAdminApi}?role_id=${selectedRole}`,
      });
    }
  }, [selectedRole]);

  const handleClose = () => {
    setOpen(false);
    setAssignOpen(false);
    setShowEdit(false);
    setSelectedRole("");
    setSelectedInterviewer("");
    setSelectedDate("");
    setSelectedTime("");
    asigneeForm.resetFields();
  };

  const [positionTypeList, setPositionTypeList] = useState([]);

  useEffect(() => {
    if (positionType) {
      fetchRoleList({
        url: `${urls.designationListApi}?position_type=${positionType}`,
      });
      // fetchGrades({
      //   url: `${urls.newGrade}?position_type=${positionType}`,
      // });
      fetchCityList({
        url: urls.cityList,
      });
      setChecked("");
      setCheckedLoc("");
      setBranch("");
      setGradeCheck("");
      setSubjectCheck("");
    }
  }, [positionType]);

  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
      });
    }
  }, [RoleId, positionType, branch, checkedLoc, subjectCheck, gradeCheck]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  function functiontoSearchF2FDetails() {
    // if (!positionType) {
    //   message.warning("Please Select Position Type");
    //   return;
    // }
    // if (!RoleId) {
    //   message.warning("Please Select Role");
    //   return;
    // }
    fetchF2Fdetails();
  }
  useEffect(() => {
    fetchPositionTypesList();
  }, []);
  useEffect(() => {
    // if (RoleId && positionType) {
    functiontoSearchF2FDetails();
    // }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (assignSpokenTest) {
      functiontoSearchF2FDetails();
      setInterviewerFree({ response: null });
      setAssignOpen(false);
      setdDration("");
      handleClose();
    }
  }, [assignSpokenTest]);

  const assignZoom = () => {
    if (!selectedInterviewer || !selectedTime || !selectedDate || !duration) {
      message.warning("Select all the fields");
      return;
    }
    fetchIsInterviewerFree({
      url: `${
        urls.checkInterviewFree
      }?user=${selectedInterviewer}&duration=${duration}&start_time=${`${dayjs(
        selectedDate
      ).format("YYYY-MM-DD")},${selectedTime}:00`}`,
    });
  };

  useEffect(() => {
    if (interviewerFree.response === "success") {
      const obj = {
        applicant: applicantId,
        user: selectedInterviewer,
        // topic: selectTopic,
        interviewers_list:
          recruiters && recruiters?.length > 0
            ? [...recruiters, selectedInterviewer]
            : [selectedInterviewer],
        duration: duration,
        start_time: `${`${dayjs(selectedDate).format(
          "YYYY-MM-DD"
        )},${selectedTime}:00`}`,
      };
      fetchAssignSpokenTest({
        url: urls.assignPlannerRound,
        body: obj,
      });
    }
  }, [interviewerFree, name]);

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
      });
      setBranch("");
    }
  }, [checkedLoc]);

  function handleBranch(e, val) {
    // setBranch(e.target.value);
    setBranch(val?.value);
  }

  function AssignFuncion(id) {
    fetchRole({
      url: urls.interviewPanalRolesApi,
    });
    fetchRecruiterList();
    setApplicantId(id);
    setAssignOpen(true);
  }

  const fetchSearch = (e) => {
    setPage(1);
    setRowsPerPage(10);
    handleDebounce();
  };

  useEffect(() => {
    if ((page || rowsPerPage) && RoleId) {
      fetchF2Fdetails();
    }
  }, [page, rowsPerPage, name]);

  function functionSearch(data) {
    fetchF2Fdetails();
  }

  const columns = [
    {
      title: "Name",
      key: "name",
      width: "15%",
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: "Contact",
      key: "contact_no",
      width: "10%",
      render: (data) => <span>{data?.contact_no}</span>,
    },
    {
      title: "Position Name",
      key: "position",
      width: "15%",
      render: (data) => <span> {data?.position ?? "-"}</span>,
    },
    {
      title: "Branch",
      key: "branch",
      width: "15%",
      render: (data) => <span> {data?.branch}</span>,
    },
    {
      title: "Grade",
      key: "grade",
      width: "15%",
      render: (data) => <span>{data?.grade}</span>,
    },
    {
      title: "Subject",
      key: "subject",
      width: "15%",
      render: (data) => <span>{data?.subject}</span>,
    },

    {
      title: "View Details",
      key: "view_details",
      width: "10%",
      render: (data) => (
        <span>
          <Tag
            color="processing"
            style={{ cursor: "pointer" }}
            onClick={() => OpenViewDetails(data.id)}
          >
            Details
          </Tag>
        </span>
      ),
    },
    {
      title: "Assign",
      key: "assign",
      width: "10%",
      render: (data) => (
        <span>
          <Tag
            color="error"
            style={{
              cursor: data?.process_start ? "not-allowed" : "pointer",
            }}
            onClick={() => AssignFuncion(data.id)}
          >
            Assign
          </Tag>
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="page_body">
        <Row
          justify="space-between"
          gutter={24}
          style={{ marginBottom: "20px" }}
        >
          <Col span={18} className="page_title">
            Face to Face / Class Demo
          </Col>
          <Col span={6}>
            <Form
              id="faceToFaceForm"
              layout="vertical"
              form={faceToFaceForm}
              onFinish={functiontoSearchF2FDetails}
            >
              <Form.Item name={"search"}>
                <Input
                  placeholder="I'm Looking For..."
                  onChange={(e) => fetchSearch(e)}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Form
          id="faceToFaceForm"
          layout="vertical"
          form={faceToFaceForm}
          onFinish={functiontoSearchF2FDetails}
        >
          <Row align="middle" gutter={24}>
            <Col span={6}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Position Type"
                  allowClear
                  onChange={(e, val) => {
                    setPositionType(val?.value);
                    faceToFaceForm.resetFields(["role"]);
                  }}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {positionTypeList &&
                    positionTypeList.length !== 0 &&
                    positionTypeList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.position_type}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  onChange={(e, val) => setRoleId(val?.value)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {roleList &&
                    roleList.length !== 0 &&
                    roleList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="city" label="City">
                <Select
                  placeholder="Select City"
                  onChange={(e, val) => setCheckedLoc(val?.value)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {cityList &&
                    cityList.length !== 0 &&
                    cityList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.city_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="branch" label="Branch">
                <Select
                  placeholder="Select Branch"
                  onChange={(e, val) => handleBranch(e, val)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {branchesList &&
                    branchesList.length !== 0 &&
                    branchesList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.branch_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item
                name="grade"
                label={positionType == "Academic" ? "Grade" : "Level"}
              >
                <Select
                  placeholder={`Select ${
                    positionType === "Academic" ? "Grade" : "Level"
                  }`}
                  onChange={(e, val) => setGradeCheck(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {grades &&
                    grades.length !== 0 &&
                    grades.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.grade_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col span={6}>
              <Form.Item
                name="subject"
                label={positionType == "Academic" ? "Subject" : "Topic"}
              >
                <Select
                  placeholder={`Select ${
                    positionType === "Academic" ? "Subject" : "Topic"
                  }`}
                  onChange={(e, val) => setSubjectCheck(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {subjects &&
                    subjects.length !== 0 &&
                    subjects.map((option, index) => {
                      return (
                        <Option
                          key={option?.subject?.id}
                          value={option?.subject?.id}
                        >
                          {option?.subject?.subject_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={6}>
              <Form.Item name="opening" label="Opening">
                <Select
                  placeholder="Select Opening"
                  onChange={(e, val) => setViewOpennings(e, val)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {viewOpenningsData &&
                    viewOpenningsData.length !== 0 &&
                    viewOpenningsData.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="start_date" label="Start Date">
                <DatePicker
                  style={{ width: "100%" }}
                  allowClear
                  onChange={(e, val) => handleStartDateChange(val)}
                  disabledDate={(current) => {
                    return current > dayjs(endDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="end_date" label="End Date">
                <DatePicker
                  style={{ width: "100%" }}
                  allowClear
                  onChange={(e, val) => handleEndDateChange(val)}
                  disabledDate={(current) => {
                    return current < dayjs(startDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="is_class_demo" label="Interview Type">
                <Select
                  onChange={(e) => {
                    setClassDemo(e);
                  }}
                  placeholder="Select type"
                >
                  <Option value={false}>Face to Face</Option>
                  <Option value={true}>Class Demo</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Button form="faceToFaceForm" htmlType="submit" type="primary">
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={f2fData?.result}
          pagination={{
            showSizeChanger: false,
            total: f2fData?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
        />
      </div>
      {assignOpen && (
        <Modal
          title="Assign"
          open={assignOpen}
          onCancel={handleClose}
          centered
          width={500}
          style={{ maxHeight: "90vh", overflow: "auto" }}
          footer={[
            <Row justify="space-around">
              <Col>
                <Button type="default" onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={loading ? <SyncOutlined spin /> : null}
                  form="asigneeForm"
                >
                  Assign
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form
            id="asigneeForm"
            form={asigneeForm}
            layout="vertical"
            onFinish={assignZoom}
          >
            <Row align="middle" gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Role",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Role"
                    onChange={(e, val) => setSelectedRole(val?.value)}
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {roleData &&
                      roleData.length !== 0 &&
                      roleData.map((option, index) => {
                        return (
                          <Option key={option?.value} value={option?.id}>
                            {option?.role_name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {selectedRole && (
                <Col span={24}>
                  <Form.Item
                    name="interviewer"
                    label="Interviewer"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Interviewer",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Interviewer"
                      onChange={(e, val) => setSelectedInterviewer(val?.value)}
                      showSearch
                      filterOption={(input, options) => {
                        return (options?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {interviewerList &&
                        interviewerList.length !== 0 &&
                        interviewerList.map((option, index) => {
                          return (
                            <Option key={index} value={option?.id}>
                              {option.first_name
                                ? `${option.first_name} [ ${option.erp ??
                                    "-"} ]`
                                : ""}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <Form.Item name={"interviewers_list"} label="Select Recruiters">
                  <Select
                    placeholder="Select Recruiter"
                    mode="multiple"
                    allowClear
                    onChange={(e) => setRecruiters(e)}
                    showSearch
                    filterOption={(input, options) => {
                      console.log(input, options);
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {recruiterList &&
                      recruiterList.length !== 0 &&
                      recruiterList.map((option, index) => {
                        return (
                          <Option key={option?.id} value={option?.id}>
                            {option.first_name
                              ? `${option.first_name} [${option?.username}]`
                              : ""}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="select_date"
                  label="Select Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={(e) => setSelectedDate(e)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="select_time"
                  label="Select Time"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Time",
                    },
                  ]}
                >
                  <TimePicker
                    onChange={(val) => {
                      setSelectedTime(val.format("HH:mm"));
                    }}
                    style={{ width: "100%" }}
                    format="HH:mm"
                    use12Hours
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="duration"
                  label="Duration in minutes"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Duration (in min)",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter Duration (in min)"
                    min={1}
                    style={{ width: "100%" }}
                    onChange={(val) => setdDration(val)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {open && (
        <Modal
          title="Details"
          open={open}
          onCancel={handleClose}
          centered
          width={1000}
          style={{ maxHeight: "90vh", overflow: "auto" }}
          footer={false}
        >
          <>
            <Row align="middle" gutter={24}>
              <Col span={10}>
                <div className="details-modal-subheading">Name</div>
                <div className="details-modal-subheading">PhoneNo</div>
                <div className="details-modal-subheading">Email</div>
                <div className="details-modal-subheading">Grade</div>
                <div className="details-modal-subheading">Role</div>
                <div className="details-modal-subheading">Position Applied</div>
                <div className="details-modal-subheading">
                  Branch Interested
                </div>
                <div className="details-modal-subheading">Application Date</div>
                <div className="details-modal-subheading">Resume</div>
              </Col>
              <Col span={2}>
                <div className="details-modal-colon">:</div>
                <div className="details-modal-colon">:</div>
                <div className="details-modal-colon">:</div>
                <div className="details-modal-colon">:</div>
                <div className="details-modal-colon">:</div>
                <div className="details-modal-colon">:</div>
                <div className="details-modal-colon">:</div>
                <div className="details-modal-colon">:</div>
                <div className="details-modal-colon">:</div>
              </Col>
              <Col span={12}>
                <div className="details-modal-text">
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.first_name) ||
                    "No Data"}
                </div>
                <div className="details-modal-text">
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.contact_no) ||
                    "No Data"}
                </div>
                <div className="details-modal-text">
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.email) ||
                    "No Data"}
                </div>
                <div className="details-modal-text">
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name) ||
                    "No Data "}
                </div>
                <div className="details-modal-text">
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name) ||
                    "No Data"}
                </div>
                <div className="details-modal-text">
                  {(applicantDetail &&
                    applicantDetail.applicant.position_apply &&
                    applicantDetail.applicant.position_apply.title) ||
                    "No Data"}
                </div>
                <div className="details-modal-text">
                  {(applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name) ||
                    "No Data"}
                </div>
                <div className="details-modal-text">
                  {applicantDetail.applicant.register_date || "No Data"}
                </div>
                <div className="details-modal-text">
                  {applicantDetail.applicant &&
                  applicantDetail.applicant.resume_link ? (
                    <a
                      target="_blank"
                      href={applicantDetail.applicant.resume_link || ""}
                      onclick="window.open(this.href); return false;"
                    >
                      <span>View Resume</span>
                    </a>
                  ) : (
                    "Resume Not Uploaded"
                  )}
                </div>
              </Col>
            </Row>

            {applicantDetail?.written_score !== null ||
            applicantDetail?.spoken_score !== null ? (
              <Row
                align="middle"
                style={{ marginTop: "50px", marginBottom: "50px" }}
                gutter={24}
              >
                {/* <Col span={8} className="page_body">
                  <div>
                    <b>MCQ </b>
                  </div>
                  {applicantDetail && applicantDetail.mcq_test_completed ? (
                    <a>
                      Completed
                      <div>
                        Marks=
                        {(applicantDetail &&
                          applicantDetail.mcq_test_completed &&
                          applicantDetail.mcq_score &&
                          applicantDetail.mcq_score.mark_scored) ||
                          "Not Uploaded"}
                      </div>
                    </a>
                  ) : (
                    "Pending"
                  )}
                </Col> */}
                <Col span={12} className="page_body">
                  <div>
                    <b>Written Test </b>
                  </div>
                  {applicantDetail && applicantDetail.written_test_completed ? (
                    <a>
                      Cleared
                      {/* <div>
                        Marks=
                        {(applicantDetail &&
                          applicantDetail.written_test_completed &&
                          applicantDetail.written_score &&
                          applicantDetail.written_score.mark_scored) ||
                          "Not Uploaded"}
                      </div> */}
                    </a>
                  ) : (
                    "Pending"
                  )}
                </Col>
                <Col span={12} className="page_body">
                  <div>
                    <b>Spoken Test </b>
                  </div>
                  {applicantDetail && applicantDetail.spoken_test_completed ? (
                    <a>
                      Cleared
                      {/* <div>
                        Marks=
                        {(applicantDetail &&
                          applicantDetail.spoken_test_completed &&
                          applicantDetail.spoken_score &&
                          applicantDetail.spoken_score.mark_scored) ||
                          "Not Uploaded"}
                      </div> */}
                    </a>
                  ) : (
                    "Pending"
                  )}
                </Col>
              </Row>
            ) : (
              ""
            )}
          </>
        </Modal>
      )}
    </>
  );
};

export default FaceToFaceAllRoundsNew;
