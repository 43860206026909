import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  TableFooter,
  TablePagination,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  InputBase,
  TableContainer,
} from '@material-ui/core';
import urls from '../../../url'
import SearchIcon from "@material-ui/icons/Search";
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import styles from './offerLetter.Style';
import Icon from '@material-ui/core/Icon';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useAlert } from '../../../hoc/alert/alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';


const OfferLetter = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [value, setValue] = React.useState(0);
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [Id, setId] = React.useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pagination, setPagination] = useState(false)
  const [pageChange, setPageChange] = useState(false)
  const [profileId, setProfileId] = useState('')
  const [applicentName, setApplicentName] = useState('');
  const alert = useAlert();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [bcc, setBcc] = useState('');
  const [cC, setCC] = useState('');
  const [escaltionPointEmail, setEscaltionPointEmail] = useState('');
  const [hrMobile, setHrMobile] = useState('');
  const [hrEmail, setHrEmail] = useState('');
  const [submitDocBeforeDate, setSubmitDocBeforeDate] = useState(null);
  const [schoolName, setSchoolName] = useState('');
  const [cTC, setCTC] = useState('');
  const [doJoining, setDoJoining] = useState(null);
  const [probationPeriod, setProbationPeriod] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [reportingTime, setReportingTime] = useState(null);
  const [hrName, setHrName] = useState('');
  const [cbDate, setCBDate] = useState(null);
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [flag, setFlag] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedCtcWords, setSelectedCtcWords] = useState('');
  console.log(selectedItem)

  const handleClickOpen = (data) => () => {
    setOpen(true);
    setCandidateDetails(data);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePreview = () => {
    // setSchoolName('');
    // setDoJoining('');
    // setCTC('');
    // setProbationPeriod('');
    // setNoticePeriod('');
    // setReportingTime('');
    // setCBDate(null);
    // setSubmitDocBeforeDate(null);
    // setHrName('');
    // setHrEmail('');
    // setHrMobile('');
    // setEscaltionPointEmail('');
    // setCC('');
    // setBcc('');
    setFlag(false);
  };


  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id)
    if (profileDetail[newValue].applicant_role_name === "Teacher") {
      setProfileId(2)
    } else if (profileDetail[newValue].applicant_role_name === "Coordinator") {
      setProfileId(3)
    } else if (profileDetail[newValue].applicant_role_name === "Principal") {
      setProfileId(4)
    }
  }

  const {
    data: subjectDetail,
    isLoading: subjectDetailLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  const {
    data: branch,
    isLoading: branchLoading,
    doFetch: fetchBranch,
  } = useFetch(null);

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);


  let loader = null;
  if (applicantsListLoading || profileDetailLoading || branchLoading || subjectDetailLoading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.FaceToFaceRolesApi,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      });
      fetchSubject({
        url: urls.subjectApiView,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranch({
        url: urls.branch,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchProfile, name, fetchSubject, fetchBranch]);


  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setId(profileDetail[0].id);
      if (profileDetail[0].applicant_role_name === "Teacher") {
        setProfileId(2)
      } else if (profileDetail[0].applicant_role_name === "Coordinator") {
        setProfileId(3)
      } else if (profileDetail[0].applicant_role_name === "Principal") {
        setProfileId(4)
      }
    }
  }, [profileDetail])

  useEffect(() => {
    if (Id) {
      setPageChange(false)
      setStartDate('')
      setEndDate('')
      setFilteredBranch('')
      setFilteredSub('')
      fetchApplicant({
        url: `${urls.OfferLetterRetriveApi}?role_id=${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [Id, fetchApplicant, name])

  useEffect(() => {
    if (applicantsList) {
      setPageChange(true)
    }
  }, [applicantsList])

  const handleStartDateChange = (val) => {
    setStartDate(val)
  }
  const handleEndDateChange = (val) => {
    setEndDate(val);
  }
  const handleGetData = () => {
    if (startDate === '') {
      alert.warning('Please Select Start Date..!')
      return;
    }
    if (endDate === '') {
      alert.warning('Please Select End Date..!')
      return;
    }
    setPage(0)
    fetchApplicant({
      url: `${urls.OfferLetterRetriveApi}?role_id=${Id}&page_size=${rowsPerPage || 50}&page=1&start_date=${startDate}&end_date=${endDate}${filteredBranch && filteredBranch.length !== 0 ? `&branch=${filteredBranch}` : ''}${filteredSub && filteredSub.length !== 0 ? `&subject=${filteredSub}` : ''}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }

  const handleBranch = (value) => () => {
    const currentIndex = filteredBranch.indexOf(value);
    const newChecked = [...filteredBranch];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredBranch(newChecked);
  };

  const handleSub = (value) => () => {
    const currentIndex = filteredSub.indexOf(value);
    const newChecked = [...filteredSub];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredSub(newChecked);
  };

  function applyFilter() {
    if (filteredBranch === '' && filteredSub === '') {
      alert.warning('select fields either branch or subject')
      return
    }
    if (((filteredBranch && filteredBranch.length === 0) && filteredSub === '') || (filteredBranch === '' && (filteredSub && filteredSub.length === 0))) {
      alert.warning('select fields either branch or subject')
      return
    }
    if ((filteredBranch && filteredBranch.length === 0) && (filteredSub && filteredSub.length === 0)) {
      alert.warning('select fields either branch or subject')
      return
    }
    setPage(0)
    fetchApplicant({
      // url: `${urls.OfferLetterRetriveApi}?branch=${filteredBranch}&subject=${filteredSub}&role_id=${Id}&page=1&page_size=${rowsPerPage || 10}${startDate ? `&start_date=${startDate}`:''}${endDate ? `&end_date=${endDate}`:''}`,
      url: `${urls.OfferLetterRetriveApi}${filteredBranch.length > 0 && filteredSub.length === 0 ? `?branch=${filteredBranch}` : filteredBranch.length > 0 && filteredSub.length > 0 ? `?branch=${filteredBranch}` : ''}${filteredSub.length > 0 && filteredBranch.length === 0 ? `?subject=${filteredSub}` : filteredSub.length > 0 && filteredBranch.length > 0 ? `&subject=${filteredSub}` : ''}&role_id=${Id}&page=1&page_size=${rowsPerPage || 10}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (pagination) {
      fetchApplicant({
        url: `${urls.OfferLetterRetriveApi}?&role_id=${Id}&page=${page + 1}&page_size=${rowsPerPage || 50}${filteredBranch && filteredBranch.length !== 0 ? `&branch=${filteredBranch}` : ''}${filteredSub && filteredSub.length !== 0 ? `&subject=${filteredSub}` : ''}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
    setPagination(false)
  }, [page, fetchApplicant, rowsPerPage, name, Id]);


  function handleChangePage(event, newPage) {
    setPagination(true)
    setPage(newPage)
    { !rowsPerPage && setRowsPerPage(50) }
  }

  function handleChangeRowsPerPage(event) {
    setPagination(true)
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPagination(true)
    setPage(0)
  }

  function lastPageChange(lastPage) {
    setPagination(true)
    setPage(lastPage)
    { !rowsPerPage && setRowsPerPage(50) }
  };

  function handleSendEmail() {
    const data = {
      "candidate_email": candidateDetails?.applicant?.user?.email,
      "candidate_name": candidateDetails?.applicant?.user?.first_name,
      "designation": candidateDetails?.applicant?.applicant_role?.applicant_role_name,
      "role": candidateDetails?.applicant?.relevant_fk?.grade?.grade_name + "(" + candidateDetails?.applicant?.relevant_fk?.subjects?.subject_name + ")",
      "school_name": schoolName,
      "date_of_joining": doJoining,
      "fixed_gross_per_month": cTC,
      "probation_period": probationPeriod,
      "notice_period": noticePeriod,
      "reporting_time": reportingTime,
      "confirm_before_date": cbDate,
      "submit_documents_before_date": submitDocBeforeDate,
      "hr_name": hrName,
      "hr_email": hrEmail,
      "hr_mobile": hrMobile,
      "escalation_point_email": escaltionPointEmail,
      "submited_by": candidateDetails?.recruiter?.first_name,
      "cc": cC,
      "bcc": bcc
    }
    axios.post(`${urls.offerLetterRelease}?is_preview=False`, data).then((response) => {
      console.log('response:', response);
      if (response?.data?.status_code === 201) {
        alert.success(response?.data?.message);
        setSchoolName('');
        setDoJoining('');
        setCTC('');
        setProbationPeriod('');
        setNoticePeriod('');
        setReportingTime('');
        setCBDate(null);
        setSubmitDocBeforeDate(null);
        setHrName('');
        setHrEmail('');
        setHrMobile('');
        setEscaltionPointEmail('');
        setCC('');
        setBcc('');
        setFlag(false);
        setOpen(false);
      } else {
        alert.error('Something Went Wrong...')
      }
    })
      .catch((error) => {
        alert.error(error);
      })
  }

  function handlePreview() {
    const data = {
      "candidate_email": candidateDetails?.applicant?.user?.email,
      "candidate_name": candidateDetails?.applicant?.user?.first_name,
      "designation": candidateDetails?.applicant?.applicant_role?.applicant_role_name,
      "role": candidateDetails?.applicant?.relevant_fk?.grade?.grade_name + "(" + candidateDetails?.applicant?.relevant_fk?.subjects?.subject_name + ")",
      "school_name": schoolName,
      "date_of_joining": doJoining,
      "fixed_gross_per_month": cTC,
      "probation_period": probationPeriod,
      "notice_period": noticePeriod,
      "reporting_time": reportingTime,
      "confirm_before_date": cbDate,
      "submit_documents_before_date": submitDocBeforeDate,
      "hr_name": hrName,
      "hr_email": hrEmail,
      "hr_mobile": hrMobile,
      "escalation_point_email": escaltionPointEmail,
      "submited_by": candidateDetails?.recruiter?.first_name,
      "cc": cC,
      "bcc": bcc
    }
    axios.post(`${urls.offerLetterRelease}?is_preview=True`, data).then((response) => {
      console.log('response:', response);
      if (response?.data?.status_code === 201) {
        alert.success(response?.data?.message);
        setFlag(true);
        setSelectedItem(response?.data);
        console.log('preview', response?.data?.result?.salary_details[0])
        setSelectedCtcWords(response?.data?.ctc_in_words);
      } else {
        alert.error('Something Went Wrong...')
      }
    })
      .catch((error) => {
        alert.error(error);
      })
  }

  const subjectList = () => {
    let subject = null;
    if (subjectDetail && subjectDetail.length) {
      subject = subjectDetail.map(value => {
        const subId = `Sub Id : ${value.id}`;
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleSub(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredSub.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': subId }}
              />
            </ListItemIcon>
            <ListItemText id={subId} primary={value.subject_name} />
          </ListItem>
        );
      })
    }
    return subject;
  }

  const branchList = () => {
    let branchDetail = null;
    if (branch && branch.length) {
      branchDetail = branch.map(value => {
        const branchId = `Branch Id : ${value.id}`;
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleBranch(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredBranch.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': branchId }}
              />
            </ListItemIcon>
            <ListItemText id={branchId} primary={value.branch_name} />
          </ListItem>
        );
      })
    }
    return branchDetail;
  }

  function addScore(a, b, c, d, e, f, g) {
    return ((((parseInt(a)) || 0) + ((parseInt(b)) || 0) + ((parseInt(c)) || 0) + ((d.length !== 0 && parseInt(d[0])) || 0) + ((e.length !== 0 && parseInt(e[0])) || 0) + ((f.length !== 0 && parseInt(f[0])) || 0) + ((g.length !== 0 && parseInt(g[0])) || 0)) === 0 ? '' : (parseInt(a) || 0) + (parseInt(b) || 0) + (parseInt(c) || 0) + ((d.length !== 0 && parseInt(d[0])) || 0) + ((e.length !== 0 && parseInt(e[0])) || 0) + ((f.length !== 0 && parseInt(f[0])) || 0) + ((g.length !== 0 && parseInt(g[0])) || 0))
  }

  function profileTable() {
    let applicantTable = null;
    if (applicantsList && applicantsList.results && applicantsList.results.length === 0 && pageChange) {
      applicantTable = (
        <React.Fragment>
          <Typography variant='h5' style={{ color: 'blue', textAlign: 'center', marginTop: '17%' }}>
            Records Not Found
          </Typography>
        </React.Fragment>
      )
    }
    if (applicantsList && applicantsList.results && applicantsList.results.length && pageChange) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{ marginTop: '10px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left"><strong>S.No</strong></TableCell>
                  <TableCell align="left"><strong>Name</strong></TableCell>
                  <TableCell align="center"><strong>MCQ Test<br /> (Score out of 50)</strong></TableCell>
                  <TableCell align="center"><strong>Written Test<br /> (Score out of 30)</strong></TableCell>
                  <TableCell align="center"><strong>Spoken Test<br />  (Score out of 20)</strong></TableCell>
                  <TableCell align="center"><strong>Round 1 <br /> (Score out of 20)</strong></TableCell>
                  {(profileId === 3 || profileId === 4) &&
                    <TableCell align="center"><strong>Round 2 <br /> (Score out of 20)</strong></TableCell>}
                  {profileId === 4 &&
                    <TableCell align="center"><strong>Round 3 <br /> (Score out of 20)</strong></TableCell>}
                  <TableCell align="center"><strong>Total <br />{profileId === 2 ? '(Score out of 120)' : ''}{profileId === 3 ? '(Score out of 140)' : ''}{profileId === 4 ? '(Score out of 160)' : ''}</strong></TableCell>
                  <TableCell><strong>Offer Letter</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicantsList.results.map((details, i) => (
                  <TableRow key={details.id}>
                    <TableCell align="left">{i + 1}</TableCell>
                    <TableCell align="left">{(details.applicant && details.applicant.user && details.applicant.user.first_name && details.applicant.user.first_name) || ''}</TableCell>
                    <TableCell align="center">{(details.mcq_score && details.mcq_score.mark_scored) || ''}</TableCell>
                    <TableCell align="center">{(details.written_score && details.written_score.mark_scored) || ''}</TableCell>
                    <TableCell align="center">{(details.spoken_score && details.spoken_score.mark_scored) || ''}</TableCell>
                    {profileId === 2 ? <TableCell align="center">{(details.f2f_score && details.f2f_score && details.f2f_score.filter(check => check.round?.round_name === "Planner Round").map((items) => { return items.mark_scored })) || ''}</TableCell> : ''}
                    {(profileId === 3 || profileId === 4) && <TableCell align="center">{(details.f2f_score && details.f2f_score && details.f2f_score.filter(check => check.round?.round_name === "Stress Round").map((items) => { return items.mark_scored })) || ''}</TableCell>}
                    {(profileId === 3 || profileId === 4) &&
                      <TableCell align="center">{(details.f2f_score && details.f2f_score && details.f2f_score.filter(check => check.round?.round_name === "Leadership Round").map((items) => { return items.mark_scored })) || ''}</TableCell>}
                    {profileId === 4 &&
                      <TableCell align="center">{(details.f2f_score && details.f2f_score && details.f2f_score.filter(check => check.round?.round_name === "Cultural Fit Round").map((items) => { return items.mark_scored })) || ''}</TableCell>}
                    <TableCell align="center">{addScore(((details.mcq_score && details.mcq_score.mark_scored) || 0), ((details.written_score && details.written_score.mark_scored) || 0), ((details.spoken_score && details.spoken_score.mark_scored) || 0), ((details.f2f_score && details.f2f_score && details.f2f_score.filter(check => check.round?.round_name === "Stress Round").map((items) => { return items.mark_scored })) || 0), ((details.f2f_score && details.f2f_score && details.f2f_score.filter(check => check.round?.round_name === "Leadership Round").map((items) => { return items.mark_scored })) || 0), ((details.f2f_score && details.f2f_score && details.f2f_score.filter(check => check.round?.round_name === "Planner Round").map((items) => { return items.mark_scored })) || 0), ((details.f2f_score && details.f2f_score && details.f2f_score.filter(check => check.round?.round_name === "Cultural Fit Round").map((items) => { return items.mark_scored })) || 0))}</TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<Icon>send</Icon>}
                      // onClick={handleClickOpen(details)}
                      >
                        Send
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <TableRow className={classes.tableFooter}>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+applicantsList.total_pages}`}
                  rowsPerPageOptions={[50, 75, 100]}
                  count={+applicantsList.count}
                  rowsPerPage={rowsPerPage || 50}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(applicantsList.total_pages - 1)}
                    disabled={page === (+applicantsList.total_pages - 1)}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Paper>
          {open ?
            (
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                style={{
                  width: '-webkit-fill-available',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                fullScreen={fullScreen}
              >
                <DialogTitle id="responsive-dialog-title">Offer Letter</DialogTitle>
                <DialogContent
                >
                  <DialogContentText
                    id="responsive-dialog-description"
                    tabIndex={-1}
                  >
                    <Grid container spacing={2} className={classes.modalPaper}>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="School Name"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setSchoolName(e.target.value)}
                          type="text"
                          value={schoolName || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Date of Joining"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setDoJoining(e.target.value)}
                          type="date"
                          value={doJoining || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Fixed Gross Per Year in Digits"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setCTC(e.target.value)}
                          type="number"
                          value={cTC || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Probation Period"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setProbationPeriod(e.target.value)}
                          type="text"
                          value={probationPeriod || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Notice Period"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setNoticePeriod(e.target.value)}
                          type="text"
                          value={noticePeriod || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Reporting Time"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setReportingTime(e.target.value)}
                          type="time"
                          value={reportingTime || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Confirm Before Date"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setCBDate(e.target.value)}
                          type="date"
                          value={cbDate || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Submit Documents Before Date"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setSubmitDocBeforeDate(e.target.value)}
                          type="date"
                          value={submitDocBeforeDate || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Hr Name"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setHrName(e.target.value)}
                          type="text"
                          value={hrName || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Hr Email"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setHrEmail(e.target.value)}
                          type="email"
                          value={hrEmail || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Hr Mobile"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setHrMobile(e.target.value)}
                          type="number"
                          value={hrMobile || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Escalation Point Email"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setEscaltionPointEmail(e.target.value)}
                          type="email"
                          value={escaltionPointEmail || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="CC"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setCC(e.target.value)}
                          type="email"
                          value={cC || ' '}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item sm={5} md={5} xs={5}>
                        <TextField
                          style={{ marginLeft: '10px' }}
                          helperText="Bcc"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={e => setBcc(e.target.value)}
                          type="email"
                          value={bcc || ' '}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-around' }}>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      float: 'left'
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handlePreview}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px'
                    }}
                    disabled={(!cC || !bcc || !schoolName || !escaltionPointEmail || !hrMobile || !hrEmail || !hrName || !submitDocBeforeDate || !cbDate || !reportingTime || !noticePeriod || !probationPeriod || !cTC || !doJoining || !schoolName) ? true : false}

                  >
                    Preview
                  </Button>
                  <Button
                    onClick={handleSendEmail}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px'
                    }}
                    disabled={(!cC || !bcc || !schoolName || !escaltionPointEmail || !hrMobile || !hrEmail || !hrName || !submitDocBeforeDate || !cbDate || !reportingTime || !noticePeriod || !probationPeriod || !cTC || !doJoining || !schoolName) ? true : false}
                  >
                    Send
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )
          }
          {flag ?
            (
              <Dialog
                open={flag}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                style={{
                  width: '-webkit-fill-available',
                  textAlign: 'left',
                  alignItems: 'left',
                  justifyContent: 'center'
                }}
                fullScreen={fullScreen}
              >
                <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'center', backgroundColor: 'primary' }}>Offer Letter Preview</DialogTitle>
                <DialogContent
                >
                  <DialogContentText
                    id="responsive-dialog-description"
                    tabIndex={-1}
                  >
                    <p><b>Dear {candidateDetails?.applicant?.user?.first_name}</b>,</p>
                    <p><b>Congratulations!! </b></p>
                    <p>This is with reference to your application and subsequent interviews you had with us.</p>
                    <p>We would like to offer you the position of <b>{candidateDetails?.applicant?.applicant_role?.applicant_role_name} {candidateDetails?.applicant?.relevant_fk?.grade?.grade_name + "(" + candidateDetails?.applicant?.relevant_fk?.subjects?.subject_name + ")"}</b> with <b>{schoolName}.</b></p>
                    <p>This offer<b> (subject to you joining us) on {doJoining} </b> will carry an annual CTC of <b>INR {cTC}/- ({selectedCtcWords})rupees per annum</b>.<br/> The offer break-up is provided below.</p>
                    <Table>
                      <TableHead style={{ border: "1px solid", textAlign: 'center', backgroundColor: 'black' }}>
                        <TableRow>
                          <TableCell align="center" style={{ border: "1px solid", color: 'white' }}><strong>Salary Details</strong></TableCell>
                          <TableCell align="center" style={{ border: "1px solid", color: 'white' }}><strong>Per Month</strong></TableCell>
                          <TableCell align="center" style={{ border: "1px solid", color: 'white' }}><strong>Per Annum</strong></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedItem && selectedItem?.result?.salary_details?.map((item, index) =>
                          <TableRow key={index}>
                            <TableCell align="center" style={{ border: "1px solid" }}><strong>{item ? item : ''}</strong></TableCell>
                            <TableCell align="right" style={{ border: "1px solid" }}>{(selectedItem?.result?.per_month[index]) ? (selectedItem?.result?.per_month[index]) : ''}</TableCell>
                            <TableCell align="right" style={{ border: "1px solid" }}>{(selectedItem?.result?.per_annum[index]) ? (selectedItem?.result?.per_annum[index]) : ''}</TableCell>
                          </TableRow>
                        )}

                      </TableBody>
                    </Table>
                    {/* {selectedItem} */}

                    <p>This is a full-time position and there will be a probationary period of <b> {probationPeriod} months/years </b> and the notice period will be <b> {noticePeriod} months/year</b>. Your date of joining will be on <b>
                      {doJoining} </b>. Reporting Time on the date of joining will be at <b> {reportingTime}</b>. Please revert with a confirmation of your acceptance of the offer and date of joining by <b> {cbDate}</b>.</p>
                    <p><b>You are required to submit the following documents on or before {submitDocBeforeDate}.</b></p>
                    <ul>
                      <li>·10th & 12th Passing Certificates/ Marksheets - Originals and Photocopy</li>
                      <li>· Graduation certificate/ Mark sheets - Originals and Photocopy</li>
                      <li>· PG certificate / Mark sheet copies - If any</li>
                      <li>· Aadhaar card<br />· Pan Card</li>
                      <li>· Appraisal/Promotion/increment letter of last organization - both Original and Photocopy -- If Any</li>
                      <li>· Appointment letter of the last organisation</li>
                      <li>· Relieving letter of the last organisation</li>
                      <li>· 4 passport size colour photographs</li>
                      <li>· Cancelled Cheque/bank account details proof (Joint account not allowed)</li>
                      <li>· Last 3 Months Payslips and Bank Statement copy, if applicable</li>
                      <li>· Universal Account Number (Related to PF, if applicable)</li>
                      <li>· Employee State Insurance details from last organization (if applicable)</li>
                      <li>· Latest Resume copy<br />. Permanent  Address Proof</li>
                      <li>. Correspondence Address Proof</li>
                      <br />
                    </ul>
                    <b><p>(Bring all original documents for Verification and all the photocopies should be self-attested)</p>
                      <p>Important Points:</p></b>
                    <ul>
                      <li>1.      Your net salary will be subject to income tax deductible at source. At the time of joining, we request you to submit your investment/tax deduction declaration in our employee HR portal (GreytHR) to ensure that TDS applicable is correctly calculated.</li>
                      <li>2.      By accepting this offer/reporting for duty at the branch you confirm and agree that you are bound by the organization policies and rules prescribed in the employee handbook which will be available with the branch EA and your employee portal (GreytHR) and the policies and rules will come into effect from the day you report to the branch.</li>
                      <li>3.       This offer to you has been made based on information furnished by you. However, if there is a discrepancy in the documents, certificates or information given by you, we reserve the right to terminate the employment with immediate effect with no liability for any compensation to be paid to you.</li>
                      <li>4.       You provide us with the right to conduct background checks to validate your educational and professional credentials</li>
                      <li>5.       Orchids The International School reserves the right to alter the salary structure components and benefits provided as per its requirement at any time, including but limited to the retention deduction, retention fund, insurance benefit, transportation benefit. However, in case such alteration is made, the gross salary of the employee will not be reduced.</li>
                      <li>6.       All employees joining by 30th of September of a calendar year are eligible for increment and appraisal in the next calendar year of joining. Employees joining on or after 1st of October will NOT be eligible for increments and appraisal in the next appraisal cycle but will be eligible for increments in the appraisal cycle that comes after that.</li>
                      <li>7.      In case you do not have an updated Adhaar card you will be considered as a retainer and will not be considered as a regular employee. In such a case you will work as a retainer payroll for a maximum of 2 months and if you do not provide the updated Adhaar soft copy by then, the organization will be forced to remove you from the organization and the notice period payment will be recovered from you.  Aadhaar copy should match with online adhaar records. To facilitate this, download your latest E-aadhaar copy that is not more than 1 week old and provide the same at the time of joining.</li>
                      <li>8.       In case your work location is a school premise, it is mandatory to get the police verification done at your end within one month of joining the organization to get your salary processed. The branch EA/HR can guide you on this in case you need any help.</li>
                      <li>9.      Subject to the terms of the letter of appointment (LOA) to be signed by the employee, the employee shall be eligible for, and the Employer shall pay to the Employee, a Retention Payment; provided that the Employee remains in continuous employment with the Employer until the end of the school Academic year as defined in the policies (usually March end for this purpose but subject to change). Except as provided in the LOA Employee shall not be eligible for, and shall not be paid, the Retention Payment if Employee’s employment with the Employer is terminated for any reason on or prior to the end of the academic year, including, without limitation, a termination due to resignation by the Employee herself or termination for Cause (as defined in the LOA) by the Employer due to grave misconduct.</li>
                      <li>10.     Travel/transportation benefits will be available for recipients of this salary structure. A pick-up route will be shared from the start of the academic year 2021-22 for the same. It cannot be guaranteed that the transportation facility will be provided near to your place of preference. The maximum distance covered under this facility will be 8KM one way per day. In case the employee does not avail of transportation from the organization’s provided route, no reimbursement or any other claim can be entertained in lieu of the same.</li>
                      <li>11.     Medical insurance will be provided to the employee, the employee's spouse, and a maximum of 2 dependent children. The insurance cost is an approximation of the insurance cost and can vary.</li>
                    </ul>
                    <p><u>Contact person from HR team after joining:{hrName} ( {hrEmail}) ({hrMobile})</u></p>
                    <p>Contact person in case there is any issue with joining formalities, induction session or any other HR-related concern after joining - {escaltionPointEmail} </p>
                    <p><u>Congratulations and wishing you a successful career at Orchids The International School. </u></p>
                    "<p><b>Congratulations & welcome aboard!</b> </p>
                    <img style={{ width: '25%' }} src='https://ci6.googleusercontent.com/proxy/CEXxPZUvWSWfVFq3V8RS6sz0dxpeVHhhLV9Fp71KsMp7gl-0XYEdkqAAL5Ioc9UovWD9e9PTXb-JnpnD8ijBAGkt0Bo0fjgcbNLpvs8apOWDgzTgu-qnFOVgKRgfxxbJLwpEed32qNQ8m1mtEHAxf-o5D0sxOjQpGpJQVr9qmQAIICm_1Qm-SwJahQj8PdtoEUWFl-UzyEBSgLRPnr7emhks=s0-d-e1-ft#https://s3.amazonaws.com/ucwebapp.wisestamp.com/faaa76e0-d0f4-480c-a06c-2bebbce88316/orchidsinternationalschoollogo.format_png.resize_200x.png' />
                    <br />
                    <p><b>Warm Regards,<br />Team Recruitment, Orchids - The International School</b></p>

                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-around' }}>
                  <Button
                    onClick={handleClosePreview}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      float: 'left'
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSendEmail}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px'
                    }}
                    disabled={(!cC || !bcc || !schoolName || !escaltionPointEmail || !hrMobile || !hrEmail || !hrName || !submitDocBeforeDate || !cbDate || !reportingTime || !noticePeriod || !probationPeriod || !cTC || !doJoining || !schoolName) ? true : false}
                  >
                    Send
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              ''
            )
          }
        </React.Fragment>
      )
    }
    return applicantTable;
  }

  const fetchSearch = e => {
    setPage(0);
    setRowsPerPage(5);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicentName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };

  function functionSearch(data) {
    // fetchApplicant({
    //   url: `${urls.searchApplicantRecruiter}?page=${page +
    //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   }
    // }); 
  }


  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={5} xs={12}>
          <Typography className="headings" variant="h4"><strong>Offer Letter</strong></Typography>
        </Grid>
        <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="i'm looking for..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              id='search-bar'
              onKeyDown={fetchSearch}
            />
          </div>
        </Grid>
        <Grid item md={5} />
        <Grid item sm={5} md={3} xs={5}>
          <TextField
            style={{ marginLeft: '10px' }}
            helperText="Start Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={e => handleStartDateChange(e.target.value)}
            type="date"
            value={startDate || ' '}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={5} md={3} xs={5}>
          <TextField
            style={{ marginLeft: '10px' }}
            helperText="End Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={e => handleEndDateChange(e.target.value)}
            type="date"
            value={endDate || ' '}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={2} md={1} xs={2}>
          <Button variant="contained" color="primary" className={classes.button} style={{ marginTop: '10px', marginLeft: '10px' }} onClick={handleGetData}>Get</Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={2} className={classes.paperMain}>
          <Typography variant="h6" className={classes.heading}><strong>Filter By</strong></Typography>
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classes.heading}><strong>Subjects</strong></Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                {subjectList()}
              </Paper>
            </Grid>
          </List>
          <Typography variant="h6" className={classes.heading}><strong>Branch</strong></Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                {branchList()}
              </Paper>
            </Grid>
          </List>
          <Grid className={classes.loginButton}>
            <Button
              className={classes.Button}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => applyFilter()}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
        <Grid item md={10} className={classes.paperMain}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {profileDetail && profileDetail.length && profileDetail.map((role, i) => (
                <Tab style={{ width: '500px', marginRight: '4%', marginLeft: '4%' }} key={role.id} label={role.applicant_role_name} />
              ))}
            </Tabs>
          </AppBar>
          {profileTable()}
        </Grid>
      </Grid>
      {loader}
    </React.Fragment>
  )
}

OfferLetter.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(OfferLetter);
