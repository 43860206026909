import React, { useState, useEffect } from "react";
import urls from "../../../url";
import {
  EditOutlined,
  PlusCircleOutlined,
  DatabaseOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Space,
  Table,
  Tag,
  Input,
  Row,
  Col,
  Select,
  message,
  Tooltip,
  Switch,
  Modal,
} from "antd";
import reactRouterDom, { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import moment from "moment";

const UpdatePositions = () => {
  const history = useHistory();
  const [filterForm] = useForm();
  const [updateForm] = useForm();
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [rowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedPositionType, setSelectedPositionType] = useState({});
  const [positionTypesList, setPositionTypesList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [modalDesignationList, setModalDesignationList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [positionTypes, setPositionTypes] = useState([]);
  const [isEditPostion, setIsEditPosition] = useState(null);
  const [editModal, setEditModal] = useState(false);
  let role = name?.personal_info?.role_id;
  let employeeId = name?.personal_info?.user_id;

  useEffect(() => {
    let id = setTimeout(() => {
      fetchTableData({
        search: searchTerm,
      });
    }, 2000);

    return () => clearTimeout(id);
  }, [searchTerm]);

  const fetchTableData = (params = {}) => {
    setTableDataLoading(true);
    if (role != 1 && role != 4) {
      params.employee_id = employeeId;
    }
    axios
      .get(`${urls.retrievePositions}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setTableData(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setTableDataLoading(false);
      });
  };
  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.personal_info.token}`,
        },
      })
      .then((response) => {
        setPositionTypesList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };
  const fetchDesignationList = (params = {}) => {
    axios
      .get(`${urls.designationListApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };
  const fetchModalDesignation = (params = {}) => {
    axios
      .get(`${urls.designationListApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setModalDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };
  const fetchRoleList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.RoleListApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setRoleList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCityList = () => {
    setLoading(true);
    axios
      .get(`${urls.cityList}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCityList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchBranchList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.branchCityMapping}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setBranchList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchGradeList = () => {
    setLoading(true);
    axios
      .get(`${urls.newGrade}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setGradeList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchSubjectList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.viewSubjectApiView}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setSubjectList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const filterTableData = () => {
    if (page === 1) {
      const values = filterForm.getFieldsValue();
      fetchTableData({
        page_size: rowsPerPage,
        page: page,
        opening_type: values?.position_type,
        applicant_role: values?.role,
        location: values?.city,
        branch: values?.branch,
        grade: values?.grade,
        subject: values?.subject,
        position_type_fk: values?.position_type_fk,
        designation_fk: values?.designation_fk,
      });
    } else {
      setPage(1);
    }
  };
  function fetchPositionTypes(positionName) {
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        const data = response?.data?.filter(
          (item) => item?.position_type == positionName
        );
        // setSelectedPositionType(data);
        setPositionTypes(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  }
  useEffect(() => {
    fetchCityList();
    fetchPositionTypesList();
    fetchGradeList();
  }, []);

  useEffect(() => {
    const values = filterForm.getFieldsValue();
    fetchTableData({
      page_size: rowsPerPage,
      page: page,
      opening_type: values?.position_type,
      applicant_role: values?.role,
      location: values?.city,
      branch: values?.branch,
      grade: values?.grade,
      subject: values?.subject,
      position_type_fk: values?.position_type_fk,
      designation: values?.designation_fk,
    });
  }, [page]);

  const handleSearch = (e) => {
    const search = e.target.value;
    if (search) {
      if (search.length % 4 === 0) {
        fetchTableData({
          search: search,
        });
      }
    } else {
      filterTableData();
    }
  };

  const handlePositionTypesChange = () => {
    const position_types = filterForm.getFieldsValue()?.position_type_fk;
    filterForm.setFieldsValue({
      designation: null,
    });
    if (position_types) {
      fetchDesignationList({
        position_type: position_types,
      });
    } else {
      setDesignationList([]);
    }
  };
  const handleDesignationChange = () => {
    // filterTableData();
  };
  const handleCityChange = () => {
    const city = filterForm.getFieldsValue()?.city;
    filterForm.setFieldsValue({
      branch: null,
    });
    if (city) {
      fetchBranchList({
        city_id: city,
      });
    } else {
      setBranchList([]);
    }
    filterTableData();
  };
  const handleBranchChange = () => {
    filterTableData();
  };
  const handleGradeChange = () => {
    const grade = filterForm.getFieldsValue()?.grade;
    filterForm.setFieldsValue({
      subject: null,
    });
    if (grade) {
      fetchSubjectList({
        grade_id: grade,
      });
    } else {
      setSubjectList([]);
    }
    filterTableData();
  };
  const handleSubjectChange = () => {
    filterTableData();
  };
  const handleClear = () => {
    filterForm.resetFields();
    filterTableData();
  };

  const handleUpdatePosition = (formValues) => {
    setLoading(true);
    axios
      .put(
        `${urls.updatePositionType}`,
        { ...formValues },
        {
          headers: {
            Authorization: `Bearer ${name?.personal_info?.token}`,
          },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          message.success("Position Data Updated Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
        const values = filterForm.getFieldsValue();
        fetchTableData({
          page_size: rowsPerPage,
          page: page,
          opening_type: values?.position_type,
          applicant_role: values?.role,
          location: values?.city,
          branch: values?.branch,
          grade: values?.grade,
          subject: values?.subject,
          position_type_fk: values?.position_type_fk,
          designation_fk: values?.designation_fk,
        });
        closeModal();
      });
  };

  const columns = [
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "Position Type",
      key: "position_type",
      editable: true,
      render: (data, data2, index) => {
        return (
          <Space>
            <span>
              {data?.position_type_fk
                ? data?.position_type_fk_name
                : data?.position_type}{" "}
              {data?.designation_fk_name && `- ${data?.designation_fk_name}`}
            </span>
            {[1, 3, 4].includes(userData?.personal_info?.role_id) &&
            (!data?.position_type_fk || !data?.designation_fk) ? (
              <EditOutlined
                onClick={() => {
                  setIsEditPosition(data?.id);
                  setEditModal(true);
                  updateForm.setFieldsValue({
                    position_type_fk: data?.position_type_fk,
                  });
                  if (data?.position_type_fk) {
                    fetchModalDesignation({
                      position_type: data?.position_type_fk,
                    });
                  }
                }}
                style={{
                  color: "#cf1322",
                }}
              />
            ) : null}
          </Space>
        );
      },
      // dataIndex: "position_type",
    },
    {
      title: "Grade Name",
      key: "grade_name",
      dataIndex: "grade_name",
    },
    {
      title: "Skill",
      key: "skill",
      dataIndex: "skill",
    },
    {
      title: "Experience",
      key: "experience",
      dataIndex: "experience",
    },
    {
      title: "City",
      key: "city_name",
      dataIndex: "city_name",
    },
    {
      title: "Branch",
      key: "branch_name",
      dataIndex: "branch_name",
    },
    // {
    //   title: "Applicant Role",
    //   key: "applicant_role_name",
    //   dataIndex: "applicant_role_name",
    // },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (data) => (
        <Space>
          <Tag
            onClick={() => {
              history.push("/other-job-posts", {
                mrf: data.mrf,
                position: data.id,
              });
            }}
            style={{ cursor: "pointer" }}
          >
            Job Portals
          </Tag>
          <Tag
            color="orange"
            onClick={() => {
              history.push("/create_positions", {
                data: { ...data, url: "/update_positions" },
              });
            }}
            style={{ cursor: "pointer" }}
          >
            <EditOutlined color="orange" style={{ marginInlineEnd: "5px" }} />
            Update
          </Tag>
          {/* <Tag
            color="orange"
            onClick={() => {
              history.push("/bulk_candidate", {
                data: { position: data?.id },
              });
            }}
            style={{ cursor: "pointer" }}
          >
            <EditOutlined color="orange" style={{ marginInlineEnd: "5px" }} />
            Bulk Upload Candidate
          </Tag> */}
          {
            <Tag
              icon={<DatabaseOutlined />}
              color="purple"
              style={{ cursor: "pointer" }}
              onClick={() => {
                history.push({
                  pathname: `/mrf_form_applicants`,
                  state: {
                    rowData: data,
                    mrf: data?.mrf,
                    position_id: data?.id,
                    url: "/update_positions",
                  },
                });
              }}
            >
              Applicants
            </Tag>
          }
        </Space>
      ),
    },
    {
      title: "Position Current Status",
      key: "pos_current_status",
      align: "center",
      render: (data) => (
        <Space>
          <Tooltip
            autoAdjustOverflow="false"
            placement="bottom"
            title={data?.is_active ? "DEACTIVATE" : "ACTIVATE"}
          >
            <Switch
              checked={data?.is_active}
              checkedChildren="Activated"
              unCheckedChildren="Deactivated"
              onChange={() => {
                setIsEditPosition(data?.id);
                handleUpdatePosition({
                  id: data?.id,
                  is_active: !data?.is_active,
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const roleOptions = roleList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.applicant_role_name}
    </Select.Option>
  ));
  const positionTypesOptions = positionTypesList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.position_type}
    </Select.Option>
  ));
  const designationOptions = designationList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.name}
    </Select.Option>
  ));
  const cityOptions = cityList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.city_name}
    </Select.Option>
  ));
  const branchOptions = branchList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.branch_name}
    </Select.Option>
  ));
  const gradeOptions = gradeList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.grade_name}
    </Select.Option>
  ));
  const subjectOptions = subjectList?.map((each) => (
    <Select.Option key={each?.id} value={each?.subject?.id}>
      {each?.subject?.subject_name}
    </Select.Option>
  ));
  const positionOptions = positionTypes?.map((each) => (
    <Select.Option key={each?.id} value={each?.id} name={each?.position_type}>
      <Tooltip title={each?.position_type}>{each?.position_type}</Tooltip>
    </Select.Option>
  ));

  const closeModal = () => {
    setEditModal(false);
    updateForm.resetFields();
    setIsEditPosition(null);
  };
  return (
    <React.Fragment>
      <Card>
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <div className="page_title">
            <strong>Position List</strong>
          </div>
          <Space>
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="I'm looking for.."
              allowClear
            />
            <Link to={`/create_positions`}>
              <Button icon={<PlusCircleOutlined />} type={"primary"}>
                Create Position
              </Button>
            </Link>
          </Space>
        </Space>
        <Form
          id="filterForm"
          form={filterForm}
          layout="vertical"
          style={{ width: "100%", marginBlock: "20px" }}
          onFinish={() => filterTableData()}
        >
          <Row align="middle" gutter={24}>
            <Col md={6}>
              <Form.Item name="position_type_fk" label="Position Types">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handlePositionTypesChange}
                  placeholder="Select Position Type"
                  style={{ width: "100%" }}
                >
                  {positionTypesOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="designation_fk" label="Designation">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleDesignationChange}
                  placeholder="Select Designation"
                  style={{ width: "100%" }}
                >
                  {designationOptions}
                </Select>
              </Form.Item>
            </Col>

            <Col md={6}>
              <Form.Item name="city" label="City">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  style={{ width: "100%" }}
                >
                  {cityOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="branch" label="Branch">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleBranchChange}
                  placeholder="Select Branch"
                  style={{ width: "100%" }}
                >
                  {branchOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="grade" label="Grade">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleGradeChange}
                  placeholder="Select Grade"
                  style={{ width: "100%" }}
                >
                  {gradeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="subject" label="Subject">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleSubjectChange}
                  placeholder="Select Subject"
                  style={{ width: "100%" }}
                >
                  {subjectOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Space size={24}>
                <Button type="default" onClick={handleClear}>
                  Clear All
                </Button>
                {/* </Col>
            <Col span={2}> */}
                <Button form="filterForm" htmlType="submit" type="primary">
                  Search
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>

        <Table
          dataSource={tableData?.results}
          columns={columns}
          loading={loading || tableDataLoading}
          pagination={{
            showSizeChanger: false,
            total: tableData.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
        />
      </Card>
      <Modal
        open={editModal}
        title="Update Position and Designation"
        onCancel={closeModal}
        footer={[
          <Button onClick={closeModal}>Cancel</Button>,
          <Button type="primary" form="updateForm" htmlType="submit">
            Submit
          </Button>,
        ]}
      >
        <Form
          style={{ marginTop: "10px", marginBottom: "10px" }}
          onFinish={handleUpdatePosition}
          form={updateForm}
          id="updateForm"
          layout="vertical"
        >
          <Row>
            <Col md={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Position Type is required!" },
                ]}
                name="position_type_fk"
                label="Position Type"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Position Type"
                  onChange={(e) => {
                    fetchModalDesignation({
                      position_type: e,
                    });
                  }}
                  style={{ width: "100%" }}
                >
                  {positionTypesOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24}>
              <Form.Item
                rules={[
                  { required: true, message: "Designation is required!" },
                ]}
                name="designation_fk"
                label="Designation"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleDesignationChange}
                  placeholder="Select Designation"
                  style={{ width: "100%" }}
                >
                  {modalDesignationList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UpdatePositions;
