import React, { useState, useEffect } from "react";
import tinymce from "tinymce";
import MediaQuery from "react-responsive";
// import PropTypes from 'prop-types';

import {
  Form,
  Input as AntdInput,
  Col,
  Row,
  Pagination,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Typography as AntdTypography,
  Card,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableFooter,
  IconButton,
  Select,
  TextField,
  InputLabel,
  Input,
  FormControl,
  MenuItem,
  TableContainer,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import styles from "./addRole.style";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import "../addQuestion/pluGins";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";

const AddRole = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [positionValue, setPositionValue] = useState("");
  const [roleName, setRoleName] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(1);
  const [add, setAdd] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isActive, setIsActive] = useState(null);
  const alert = useAlert();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  const positionType = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  const {
    data: roleData,
    isLoading: gettingRoleList,
    doFetch: FetchRoles,
  } = useFetch([], { suppressAlert: true });

  const [fetchingRoleData, setFetchingRoleData] = useState("");
  useEffect(() => {
    if (positionValue) {
      FetchRoles({
        url: `${
          urls.crudRoles
        }?position_type=${positionValue}&search=${fetchingRoleData}&page=${page}&page_size=${rowsPerPage ||
          50}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
    setRoleName("");
  }, [page, rowsPerPage, add, fetchingRoleData]);

  const filterData = () => {
    if (positionValue) {
      FetchRoles({
        url: `${
          urls.crudRoles
        }?position_type=${positionValue}&search=${fetchingRoleData}&page=${page}&page_size=${rowsPerPage ||
          50}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  };

  const fetchRoleData = (e) => {
    setFetchingRoleData(e.target.value);
  };

  let loader = null;
  if (gettingRoleList) {
    loader = <Loader open />;
  }

  async function addRole(formValue) {
    let obj = {};
    if (!roleName) {
      alert.warning("Please Enter the Role Value...!");
      return;
    }

    const arrOfWords = formValue?.applicant_role_name?.split(" ");
    const arrOfWordsCased = [];

    for (let i = 0; i < arrOfWords.length; i++) {
      const word = arrOfWords[i];
      arrOfWordsCased.push(word[0].toUpperCase() + word.slice(1).toLowerCase());
    }
    obj = {
      position_type: formValue?.position_type,
      applicant_role_name: arrOfWordsCased.join(" "),
    };
    // setdataLoading(true);
    const response = await fetch(urls.crudRoles, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(obj), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
    const getData = await response.json();
    if (getData === "Applicant Role Already Exist...!") {
      alert.error("Applicant Role Already Exist...!");
    } else {
      if (getData?.id !== null) {
        alert.success("Role Created");
        onClose();
      }

      setAdd(Math.random());
      // console.log(getData, "getData");
      return getData;
    }
  }
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState();
  function deleteTopic(data) {
    console.log(data, "data");
    setDeleteModel(true);
    setDeleteId(data);
  }

  function cancelDelete() {
    setDeleteModel(false);
    setDeleteId(null);
  }
  function removeRole(idToDelete) {
    axios
      .delete(`${urls.crudRoles}${idToDelete}`, {
        method: "DELETE",
        // body: deleteObj,
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((response) => {
        //     // console.log(data,"data");

        alert.success("data deleted successfully");
        setPage(1);
        setAdd(Math.random());

        setRowsPerPage(50);
      })
      .catch(() => {
        alert.success("data deleted successfully");
        setAdd(Math.random());
      });
  }

  // function roleTable() {
  //   let applicantTable = null;
  //   if (roleData && roleData.results && roleData.results.length === 0) {
  //     applicantTable = (
  //       <React.Fragment>
  //         <Typography
  //           style={{ textAlign: "center", color: "blue", marginTop: "100px" }}
  //           variant="h4"
  //         >
  //           Records Not Found
  //         </Typography>
  //       </React.Fragment>
  //     );
  //   }
  //   if (roleData && roleData.results && roleData.results.length) {
  //     applicantTable = (
  //       <React.Fragment>
  //         <MediaQuery minWidth={451}>
  //           <Paper style={{ marginTop: "10px" }}>
  //             <Table>
  //               <TableHead>
  //                 <TableRow>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>S.No.</strong>
  //                   </TableCell>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>Role Name</strong>
  //                   </TableCell>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>Delete</strong>
  //                   </TableCell>
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 {roleData.results.map((details, i) => (
  //                   <TableRow key={details.id}>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {i + 1}
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {details.applicant_role_name}
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       <Button
  //                         className={classes.addButton}
  //                         color="primary"
  //                         size="small"
  //                         variant="contained"
  //                         onClick={() => deleteTopic(details.id)}
  //                       >
  //                         Delete
  //                       </Button>
  //                     </TableCell>
  //                   </TableRow>
  //                 ))}
  //               </TableBody>
  //             </Table>
  //             <TableFooter>
  //               <TableRow>
  //                 <TablePagination
  //                   style={{ marginTop: "15px" }}
  //                   colSpan={5}
  //                   rowsPerPageOptions={[50, 75, 100]}
  //                   component="div"
  //                   count={roleData && roleData.count}
  //                   rowsPerPage={rowsPerPage}
  //                   page={page}
  //                   backIconButtonProps={{
  //                     "aria-label": "previous page",
  //                   }}
  //                   nextIconButtonProps={{
  //                     "aria-label": "next page",
  //                   }}
  //                   onChangePage={handleChangePage}
  //                   onChangeRowsPerPage={handleChangeRowsPerPage}
  //                 />
  //                 <TableCell>
  //                   <IconButton
  //                     onClick={firstPageChange}
  //                     disabled={page === 0 || page === 1}
  //                   >
  //                     <FirstPageIcon />
  //                   </IconButton>
  //                   <IconButton
  //                     onClick={() => lastPageChange(roleData.total_pages - 1)}
  //                     disabled={page === +roleData.total_pages - 1}
  //                   >
  //                     <LastPageIcon />
  //                   </IconButton>
  //                 </TableCell>
  //               </TableRow>
  //             </TableFooter>
  //             {/* : null} */}
  //           </Paper>
  //         </MediaQuery>

  //         <MediaQuery maxWidth={450}>
  //           <Paper style={{ marginTop: "10px" }}>
  //             <TableContainer style={{ width: "230px" }}>
  //               <Table>
  //                 <TableHead>
  //                   <TableRow>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       <strong>S.No.</strong>
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       <strong>Role Name</strong>
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       <strong>Delete</strong>
  //                     </TableCell>
  //                   </TableRow>
  //                 </TableHead>
  //                 <TableBody>
  //                   {roleData.results.map((details, i) => (
  //                     <TableRow key={details.id}>
  //                       <TableCell
  //                         align="center"
  //                         style={{ border: "1px solid black" }}
  //                       >
  //                         {i + 1}
  //                       </TableCell>
  //                       <TableCell
  //                         align="center"
  //                         style={{ border: "1px solid black" }}
  //                       >
  //                         {details.applicant_role_name}
  //                       </TableCell>
  //                       <TableCell
  //                         align="center"
  //                         style={{ border: "1px solid black" }}
  //                       >
  //                         <Button
  //                           className={classes.addButton}
  //                           color="primary"
  //                           size="small"
  //                           variant="contained"
  //                           onClick={() => removeRole(details.id)}
  //                         >
  //                           Delete
  //                         </Button>
  //                       </TableCell>
  //                     </TableRow>
  //                   ))}
  //                 </TableBody>
  //               </Table>

  //               <TableFooter>
  //                 <TableRow>
  //                   <TablePagination
  //                     style={{ marginTop: "15px" }}
  //                     colSpan={5}
  //                     rowsPerPageOptions={[50, 75, 100]}
  //                     component="div"
  //                     count={roleData && roleData.count}
  //                     rowsPerPage={rowsPerPage}
  //                     page={page}
  //                     backIconButtonProps={{
  //                       "aria-label": "previous page",
  //                     }}
  //                     nextIconButtonProps={{
  //                       "aria-label": "next page",
  //                     }}
  //                     onChangePage={handleChangePage}
  //                     onChangeRowsPerPage={handleChangeRowsPerPage}
  //                   />
  //                   <TableCell>
  //                     <IconButton
  //                       onClick={firstPageChange}
  //                       disabled={page === 0 || page === 1}
  //                     >
  //                       <FirstPageIcon />
  //                     </IconButton>
  //                     <IconButton
  //                       onClick={() => lastPageChange(roleData.total_pages - 1)}
  //                       disabled={page === +roleData.total_pages - 1}
  //                     >
  //                       <LastPageIcon />
  //                     </IconButton>
  //                   </TableCell>
  //                 </TableRow>
  //               </TableFooter>
  //             </TableContainer>
  //             {/* : null} */}
  //           </Paper>
  //         </MediaQuery>
  //       </React.Fragment>
  //     );
  //   }
  //   return applicantTable;
  // }

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Role Name</strong>,
      dataIndex: "applicant_role_name",
      key: "applicant_role_name",
      align: "center",
    },
    {
      title: <strong>Delete</strong>,
      key: "delete",
      align: "center",
      render: (text, data) => (
        <>
          <AntdPopconfirm
            title="Sure to delete?"
            onConfirm={(e) => removeRole(data.id)}
          >
            <AntdTag
              icon={<CloseCircleOutlined />}
              color="error"
              style={{ cursor: "pointer" }}
            >
              Delete
            </AntdTag>
          </AntdPopconfirm>
        </>
      ),
    },
  ];

  const [form] = Form.useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>Roles</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add Roles
          </AntdButton>
        </div>
      </div>
      <Card style={{ marginBottom: "10px" }}>
        <Row>
          <Col md={6}>
            <AntdSelect
              style={{ width: "100%" }}
              value={positionValue || "Select Position Type"}
              onChange={(e) => setPositionValue(e)}
            >
              {positionType &&
                positionType.map((data) => (
                  <AntdSelect.Option
                    value={data.label}
                    key={data.value}
                    name={data.label}
                  >
                    {data.label ? data.label : ""}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
          </Col>

          <AntdButton
            onClick={filterData}
            type="primary"
            style={{ marginInline: "10px" }}
          >
            Filter
          </AntdButton>
          {/* </Col> */}

          <AntdButton
            type="primary"
            disabled={positionType ? false : true}
            onClick={() => {
              setPositionValue(null);
            }}
            // style={{ width: "100%" }}
          >
            Clear
          </AntdButton>
        </Row>
      </Card>

      <AntdDrawer
        title="Create Role"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="role" type="primary" htmlType="submit">
              Add
            </AntdButton>
          </div>
        }
      >
        <Form id="role" form={form} layout="vertical" onFinish={addRole}>
          <Row>
            <Col xs={24}>
              <Form.Item
                name="position_type"
                label="Select Position Type"
                value={positionValue || "Select Position Type"}
                rules={[{ required: true, message: "Please Select Position" }]}
              >
                <AntdSelect placeholder="Position Name">
                  {positionType &&
                    positionType.map((data) => (
                      <AntdSelect.Option
                        value={data.label}
                        key={data.value}
                        name={data.label}
                      >
                        {data.label ? data.label : ""}
                      </AntdSelect.Option>
                    ))}
                </AntdSelect>
              </Form.Item>
            </Col>

            <Col xs={24}>
              <Form.Item
                name="applicant_role_name"
                label="Add Role"
                rules={[{ required: true, message: "Please enter Role Name" }]}
              >
                <AntdInput
                  onChange={(e) => setRoleName(e)}
                  placeholder="Role Name"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>

      {roleData?.results && roleData?.results?.length !== 0 && (
        <AntdTable
          columns={columns}
          dataSource={roleData?.results}
          pagination={{
            pageSizeOptions: [50, 75, 100],
            current: page,
            total: roleData?.count,
            pageSize: rowsPerPage,

            onChange: (current, pageSize) => {
              setPage(current);
              setRowsPerPage(pageSize);
            },
          }}
          loading={gettingRoleList}
        />
      )}
    </React.Fragment>
  );
};

AddRole.propTypes = {};

export default withStyles(styles)(AddRole);
