import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, withStyles } from "@material-ui/core/";
import { NavLink } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import styles from "./layout.style";
import Link from "@material-ui/core/Link";
import Sidebar from "./sidebar";
import routeList from "./routeList";
import NotAuthorised from "../components/notAuthorised";
import { Layout, theme } from "antd";
import TopBar from "./topbar";
import { Footer } from "antd/es/layout/layout";
import { useDispatch } from "react-redux";
import { logout } from "../redux/actions";
function NewLayout(props) {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const classes = styles();
  const [open, setOpen] = React.useState(false);
  const [loginData] = useState(JSON.parse(localStorage.getItem("user")));
  const [routeLink, setRouteLink] = useState("");
  const [collapsed, setCollapsed] = useState(true);

  const role = loginData && loginData.personal_info.role;
  const role_id = loginData && loginData.personal_info.role_id;
  const handleMenu = () => {
    localStorage.clear();
    dispatch(logout());
    window.location = "/";
  };

  const copyRightsFooter = (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright  "}
      &copy; {new Date().getFullYear()}, K12 Techno Services Pvt. Ltd.
    </Typography>
  );

  const TermsAndCondition = (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link
        style={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => (window.location.pathname = "/")}
      >
        Terms and Conditions&nbsp;
      </Link>
      |
      <Link
        style={{ cursor: "pointer", textDecoration: "none" }}
        onClick={() => (window.location.pathname = "/")}
      >
        &nbsp;Privacy Policy
      </Link>
      .
    </Typography>
  );

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <div
      className={"th-custom-scrollbar th-hidden-scroll"}
      style={{
        maxHeight: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Layout hasSider>
        <Sidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          colorBgContainer={colorBgContainer}
          open={open}
          setRouteLink={setRouteLink}
          role={role_id}
        />
        <Layout>
          <TopBar
            colorBgContainer={colorBgContainer}
            setCollapsed={setCollapsed}
            collapsed={collapsed}
            handleMenu={handleMenu}
          />
          <Content
            style={{
              maxHeight: "90vh",
              overflowY: "scroll",
              padding: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
            className="th-hidden-scroll"
          >
            {/* {routeLink ? (
              <routeLink.component />
            ) : ( */}
            <Switch>
              {routeList?.map(({ path, component, roles }, index) => {
                console.log(roles?.includes(Number(role_id)), path);
                return (
                  <Route
                    key={index}
                    path={path}
                    exact
                    component={
                      roles?.indexOf(Number(role_id)) >= 0
                        ? component
                        : NotAuthorised
                    }
                  />
                );
              })}
            </Switch>
            {/* )} */}
          </Content>
          <Footer>
            <div className="" style={{ textAlign: "center" }}>
              Copyright &copy; {new Date().getFullYear()}, K12 Techno Services
              Pvt. Ltd.
            </div>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
}

NewLayout.propTypes = {
  className: PropTypes.string,
};

NewLayout.defaultProps = {
  className: "",
};

export default withStyles(styles)(NewLayout);
