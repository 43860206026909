import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./mrfForm.style";
import PropTypes from "prop-types";
import Loader from "../../../../hoc/loader";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { useAlert } from "../../../../hoc/alert";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import urls from "../../../../url";
import useFetch from "../../../../hoc/useFetch";
import axios from "axios";

const MrfForm = ({ classes, location }) => {
  const history = useHistory();
  const [rowId] = useState(location?.state ? location?.state?.rowId : null);
  const alert = useAlert();
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const [dropDownsData] = useState({
    positionTypeList: [
      { id: "Academic", value: "Academic" },
      { id: "Non Academic", value: "Non Academic" },
      { id: "IT Recruitment", value: "IT Recruitment" },
      {
        id: "Marketing and Communications",
        value: "Marketing and Communications",
      },
      {
        id: "Sales and Business Development",
        value: "Sales and Business Development",
      },
    ],
    genderPreferenceList: [
      { id: "male", value: "Male" },
      { id: "female", value: "Female" },
      { id: "any", value: "No Preference" },
    ],
    priorityTypeList: [
      { id: 1, value: "P1" },
      { id: 2, value: "P2" },
      { id: 3, value: "P3" },
      { id: 4, value: "P4" },
      { id: 5, value: "P5" },
    ],
    minimumQualificationList: [
      { id: "12th_pass", value: "12th Pass" },
      { id: "graduate_non_technical", value: "Graduate Non Technical" },
      { id: "gaduate_technical", value: "Gaduate Technical" },
      {
        id: "post_graduate_non_technical",
        value: "Post Graduate Non Technical",
      },
      { id: "post_graduate_technical", value: "Post Graduate Technical" },
    ],
    jobTypeList: [
      { id: "Full Time", value: "Full Time" },
      { id: "Part Time", value: "Part Time" },
      { id: "Intern", value: "Intern" },
    ],
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [mrfFormData, setMrfFormData] = useState({
    positionName: "",
    positionType: "",
    departmentName: "",
    zoneName: "",
    genderPreference: "",
    experienceRequired: "",
    priorityType: "",
    minimumQualification: "",
    noOfVacancy: "",
    salaryRange: "",
    skillsRequired: "",
    jobType: "",
    workLocationName: "",
    branchName: "",
    raisedByName: "",
    jobDescription: "",
    remarks: "",
  });
  const [jobDescriptionFile, setJobDescriptionFile] = useState();
  const [jobDescriptionFileLink, setJobDescriptionFileLink] = useState();
  const [positionTypeList] = useState(dropDownsData?.positionTypeList);
  const [genderPreferenceList] = useState(dropDownsData?.genderPreferenceList);
  const [priorityTypeList] = useState(dropDownsData?.priorityTypeList);
  const [minimumQualificationList] = useState(
    dropDownsData?.minimumQualificationList
  );
  const [jobTypeList, setJobTypeList] = useState(dropDownsData?.jobTypeList);
  const { data: workLocationList, doFetch: fetchWorkLocationList } = useFetch(
    [],
    {
      suppressAlert: true,
    }
  );
  const { data: branchList, doFetch: fetchBranchList } = useFetch([], {
    suppressAlert: true,
  });

  useEffect(() => {
    fetchWorkLocationList({
      url: urls.cityList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData?.personal_info?.token}`,
      },
    });
    setMrfFormData({
      ...mrfFormData,
      raisedByName: userData?.academic_profile?.name,
    });
  }, []);
  useEffect(() => {
    if (rowId) {
      fetchFormData();
    }
  }, [rowId]);
  useEffect(() => {
    if (mrfFormData?.workLocationName) {
      fetchBranchList({
        url: `${urls.branchCityMapping}?city_id=${mrfFormData?.workLocationName}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [mrfFormData?.workLocationName]);
  const fetchFormData = () => {
    setLoading(true);
    axios
      .get(`${urls?.mrfForm}${rowId}/`, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          const formData = response?.data;
          setMrfFormData({
            positionName: formData?.position,
            positionType: formData?.position_type,
            departmentName: formData?.department,
            zoneName: formData?.zone,
            genderPreference: formData?.gender_preference,
            experienceRequired: formData?.experience,
            priorityType: formData?.priority,
            minimumQualification: formData?.minimum_qualification,
            noOfVacancy: formData?.no_of_vacancies,
            salaryRange: formData?.salary,
            skillsRequired: formData?.skill,
            jobType: formData?.job_type,
            workLocationName: formData?.location,
            branchName: formData?.branch,
            raisedByName: formData?.employee,
            jobDescription: formData?.description,
            remarks: formData?.remark,
          });
          setJobDescriptionFileLink(formData?.description_file);
          setLoading(false);
        }
      })
      .catch((error) => {
        alert.error("Something Went Wrong. Please Try Again");
        setLoading(false);
      });
  };
  const changeHandler = (e) => {
    setMrfFormData({
      ...mrfFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleOpenConfirmDialog = () => {
    if (
      !mrfFormData?.positionName ||
      !mrfFormData?.positionType ||
      !mrfFormData?.departmentName ||
      !mrfFormData?.zoneName ||
      !mrfFormData?.genderPreference ||
      !mrfFormData?.experienceRequired ||
      !mrfFormData?.priorityType ||
      !mrfFormData?.minimumQualification ||
      !mrfFormData?.noOfVacancy ||
      !mrfFormData?.salaryRange ||
      !mrfFormData?.skillsRequired ||
      !mrfFormData?.jobType ||
      !mrfFormData?.workLocationName ||
      !mrfFormData?.branchName ||
      !mrfFormData?.raisedByName
    ) {
      alert.warning("Please Fill All Compulsory Fields");
      return;
    }
    if (jobDescriptionFile && jobDescriptionFile.size > 5 * 1024 * 1024) {
      alert.warning("File Size Limit Exceeded");
      return;
    }
    setOpenConfirmDialog(true);
  };
  const handleCloseConfrmDialog = () => setOpenConfirmDialog(false);

  const handleConfirm = () => {
    handleCloseConfrmDialog();

    const formDataParams = {
      position: mrfFormData?.positionName ?? null,
      position_type: mrfFormData?.positionType ?? null,
      department: mrfFormData?.departmentName ?? null,
      zone: mrfFormData?.zoneName ?? null,
      gender_preference: mrfFormData?.genderPreference ?? null,
      experience_required: mrfFormData?.experienceRequired ?? null,
      priority: mrfFormData?.priorityType ?? null,
      minimum_qualification: mrfFormData?.minimumQualification ?? null,
      no_of_vacancies: mrfFormData?.noOfVacancy ?? null,
      salary: mrfFormData?.salaryRange ?? null,
      description: mrfFormData?.jobDescription ?? null,
      description_file: jobDescriptionFile ?? null,
      skill: mrfFormData?.skillsRequired ?? null,
      job_type: mrfFormData?.jobType ?? null,
      is_active: "true",
      status: "pending",
      remarks: mrfFormData?.remarks ?? null,
      // employee: userData?.personal_info?.user_id ?? null,
      employee: 1,
      branch: mrfFormData?.branchName ?? null,
      location: mrfFormData?.workLocationName ?? null,
    };
    console.log(formDataParams, "formData through PARAMS");

    const formData = new FormData();
    formData.append("position", mrfFormData?.positionName ?? null);
    formData.append("position_type", mrfFormData?.positionType ?? null);
    formData.append("department", mrfFormData?.departmentName ?? null);
    formData.append("zone", mrfFormData?.zoneName);
    formData.append("gender_preference", mrfFormData?.genderPreference ?? null);
    formData.append("experience", mrfFormData?.experienceRequired ?? null);
    formData.append("priority", mrfFormData?.priorityType ?? null);
    formData.append(
      "minimum_qualification",
      mrfFormData?.minimumQualification ?? null
    );
    formData.append("no_of_vacancies", mrfFormData?.noOfVacancy ?? null);
    formData.append("salary", mrfFormData?.salaryRange ?? null);
    formData.append("description", mrfFormData?.jobDescription ?? null);
    formData.append("description_file", jobDescriptionFile ?? null);
    formData.append("skill", mrfFormData?.skillsRequired ?? null);
    formData.append("job_type", mrfFormData?.jobType);
    formData.append("remark", mrfFormData?.remarks ?? null);
    formData.append("branch", mrfFormData?.branchName ?? null);
    formData.append("location", mrfFormData?.workLocationName ?? null);
    formData.append("is_active", "true");
    formData.append("status", "pending");
    formData.append("employee", 1);
    console.log(formData, "formData through FORM");
    if (rowId) {
      axios
        .put(`${urls?.mrfForm}${rowId}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.personal_info?.token}`,
          },
        })
        .then((response) => {
          console.log(response, "test");
          if (response?.status === 200) {
            alert.success("Data Updated Successfully");
            history.push("/mrf_list");
          }
        })
        .catch((error) => {
          alert.error("Something Went Wrong. Please Try Again");
        });
    } else {
      axios
        .post(`${urls?.mrfForm}`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.personal_info?.token}`,
          },
        })
        .then((response) => {
          console.log(response, "test");
          if (response?.status === 201) {
            alert.success("Data Submitted Successfully");
            history.push("/mrf_list");
          }
        })
        .catch((error) => {
          alert.error("Something Went Wrong. Please Try Again");
        });
    }
  };

  return (
    <React.Fragment>
      {loading && <Loader open />}
      <>
        <Grid container>
          <Grid item md={3}>
            <Typography className="headings" variant="h4">
              <strong>MRF Form</strong>
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Paper className={classes.paper}>
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            <Grid item md={6} xs={12}>
              <TextField
                name="positionName"
                label={
                  <div>
                    Position Name <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                value={mrfFormData?.positionName ?? null}
                onChange={(e) => changeHandler(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="positionType"
                label={
                  <div>
                    Select Position Type <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
                value={mrfFormData?.positionType ?? null}
                onChange={(e) => changeHandler(e)}
              >
                {positionTypeList &&
                  positionTypeList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="departmentName"
                label={
                  <div>
                    Department Name <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                value={mrfFormData?.departmentName ?? null}
                onChange={(e) => changeHandler(e)}
              ></TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="zoneName"
                label={
                  <div>
                    Zone Name <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                value={mrfFormData?.zoneName ?? null}
                onChange={(e) => changeHandler(e)}
              ></TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="genderPreference"
                label={
                  <div>
                    Gender Preference <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
                value={mrfFormData?.genderPreference ?? null}
                onChange={(e) => changeHandler(e)}
              >
                {genderPreferenceList &&
                  genderPreferenceList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="experienceRequired"
                label={
                  <div>
                    Experience Required <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                value={mrfFormData?.experienceRequired ?? null}
                onChange={(e) => changeHandler(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="priorityType"
                label={
                  <div>
                    Select Priority <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
                value={mrfFormData?.priorityType ?? null}
                onChange={(e) => changeHandler(e)}
              >
                {priorityTypeList &&
                  priorityTypeList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="minimumQualification"
                label={
                  <div>
                    Select Minimum Qualification{" "}
                    <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
                value={mrfFormData?.minimumQualification ?? null}
                onChange={(e) => changeHandler(e)}
              >
                {minimumQualificationList &&
                  minimumQualificationList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="noOfVacancy"
                label={
                  <div>
                    No of Vacancy <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                type="number"
                value={mrfFormData?.noOfVacancy ?? null}
                onChange={(e) => changeHandler(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="salaryRange"
                label={
                  <div>
                    Salary Range <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                value={mrfFormData?.salaryRange ?? null}
                onChange={(e) => changeHandler(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="skillsRequired"
                label={
                  <div>
                    Skills Required <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                value={mrfFormData?.skillsRequired ?? null}
                onChange={(e) => changeHandler(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="jobType"
                label={
                  <div>
                    Select Job Type <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
                value={mrfFormData?.jobType ?? null}
                onChange={(e) => changeHandler(e)}
              >
                {jobTypeList &&
                  jobTypeList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.value}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="workLocationName"
                label={
                  <div>
                    Select Work Location <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
                value={mrfFormData?.workLocationName ?? null}
                onChange={(e) => changeHandler(e)}
              >
                {workLocationList &&
                  workLocationList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.city_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="branchName"
                label={
                  <div>
                    Select Branch <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
                value={mrfFormData?.branchName ?? null}
                onChange={(e) => changeHandler(e)}
              >
                {branchList &&
                  branchList.map((each) => (
                    <MenuItem key={each?.id} value={each?.id}>
                      {each?.branch_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="raisedByName"
                label={
                  <div>
                    MRF Raised By Name <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                margin="dense"
                fullWidth
                value={mrfFormData?.raisedByName ?? null}
                onChange={(e) => changeHandler(e)}
                disabled
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid>
                <Typography>
                  Job Description File (Max Size 5MB, Pdf only){" "}
                  <b style={{ color: "red" }}>*</b>
                </Typography>
                <input
                  name="jobDescriptionFile"
                  id="outlined-button"
                  type="file"
                  accept="application/pdf"
                  style={{ display: "none", marginTop: "10px" }}
                  onChange={(e) => {
                    setJobDescriptionFile(e.target.files[0]);
                  }}
                />
                <label htmlFor="outlined-button">
                  <Button
                    variant="contained"
                    component="span"
                    size="small"
                    color="primary"
                    style={{ height: "30px", width: "100px" }}
                    startIcon={<CloudUploadIcon />}
                    id="Jd"
                  >
                    Browse
                  </Button>
                  &nbsp;&nbsp;
                </label>
                {jobDescriptionFile && (
                  <span
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      marginLeft: "10px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        URL.createObjectURL(jobDescriptionFile),
                        "_blank"
                      )
                    }
                  >
                    {jobDescriptionFile?.name}
                  </span>
                )}
                {!jobDescriptionFile && jobDescriptionFileLink && (
                  <a
                    href={jobDescriptionFileLink}
                    target="_blank"
                    style={{
                      fontSize: "16px",
                      color: "blue",
                      marginLeft: "10px",
                    }}
                  >
                    {jobDescriptionFileLink?.split("/").pop()}
                    <p>File name</p>
                  </a>
                )}
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="jobDescription"
                label={
                  <div>
                    Job Description <b style={{ color: "red" }}>*</b>
                  </div>
                }
                variant="outlined"
                fullWidth
                multiline
                minRows={4}
                value={mrfFormData?.jobDescription ?? null}
                onChange={(e) => changeHandler(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                name="remarks"
                label={<div>Remarks</div>}
                variant="outlined"
                fullWidth
                multiline
                minRows={4}
                value={mrfFormData?.remarks ?? null}
                onChange={(e) => changeHandler(e)}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button
                color="primary"
                variant="outlined"
                style={{ marginRight: "5px" }}
                onClick={() => history.push("/mrf_list")}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: "5px" }}
                onClick={() => handleOpenConfirmDialog()}
              >
                {rowId ? "Update MRF" : "Raise MRF"}
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Dialog open={openConfirmDialog} onClose={handleCloseConfrmDialog}>
          <DialogTitle>
            <strong>
              {" "}
              {rowId ? "Confirm Update MRF" : "Confirm Raise MRF"}{" "}
            </strong>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to {rowId ? "update" : "submit"} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseConfrmDialog}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleConfirm}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </React.Fragment>
  );
};

MrfForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(MrfForm);
