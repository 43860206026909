import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  withStyles,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid, 
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './viewProfile.style';

import BasicProfile from '../basicProfile/basicProfile'
import Education from '../education/education'
import Experience from '../Experience/experience'
import Projects from '../Projects/projects'
import OtherDetails from '../otherDetails/otherDetails'


const ViewApplicentProfile = ({ classes }) => {

  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [activeStep, setActiveStep] = useState(1);
  const [activeStepName, setActivStepName] = useState('');

  let menuItems;
  if (name.academic_profile && name.academic_profile.applicant_role && name.academic_profile.applicant_role.position_type === 'IT Recruitment') {
     menuItems = [
      {id:1, name: 'Basic Profile'},
      {id:2, name: 'Education'},
      {id:3, name: 'Experience'},
      {id:4, name: 'Projects'},
      {id:5, name: 'Other Details*'},
    ]
  }else {
     menuItems = [
      {id:1, name: 'Basic Profile'},
      {id:2, name: 'Education'},
      {id:3, name: 'Experience'},
      {id:5, name: 'Other Details*'},
  ]
  }

  useEffect(()=>{
    if (activeStep) {
      setActivStepName(menuItems.filter((data)=> data.id === parseInt(activeStep,10))[0].name)
    }
  },[activeStep, menuItems])

  return (
    <React.Fragment>
      <Typography variant="h4">{activeStepName}</Typography>
      <Divider className={classes.divider} />
        <Grid container spacing={1}>
            <Grid item md={1}/>
            {menuItems.map((data, index)=>(
                <Grid item md={2} xs={12} key={index}>
                  <RadioGroup aria-label="profilSteps" name="profileSteps" 
                     value={parseInt(activeStep, 10)} 
                     onChange={(e)=> setActiveStep(e.target.value)}
                   >
                    <FormControlLabel value={data.id} control={<Radio color="primary" />} label={data.name} />
                  </RadioGroup>
                </Grid>
            ))}
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={1} style={{marginTop:'15px'}}>
          <Grid item md={12} xs={12}>
              {parseInt(activeStep, 10) === 1 && <BasicProfile/>}
          </Grid>
          <Grid item md={12} xs={12}>
              {parseInt(activeStep, 10) === 2 && <Education/>}
          </Grid>
          <Grid item md={12} xs={12}>
              {parseInt(activeStep, 10) === 3 && <Experience/>}
          </Grid>
          <Grid item md={12} xs={12}>
              {parseInt(activeStep, 10) === 4 && <Projects/>}
          </Grid>
          <Grid item md={12} xs={12}>
              {parseInt(activeStep, 10) === 5 && <OtherDetails/>}
          </Grid>
        </Grid>
    </React.Fragment>
  )
}

ViewApplicentProfile.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ViewApplicentProfile);