import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Select,
  Button,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  TablePagination,
  Tab,
  Tabs,
  AppBar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  Paper,
  InputBase,
  TableContainer,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import urls from "../../../url";
import Modal from "../../../hoc/modal/modal";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./assignToRecruiter.style";
import { useAlert } from "../../../hoc/alert/alert";
import CloseIcon from '@material-ui/icons/Close'; 

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     'aria-controls': `scrollable-auto-tabpanel-${index}`,
//   };
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AssignToRecruiter = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [value, setValue] = React.useState(0);
  const [Id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [isChecked, setIsChecked] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [testPaper, setTestPaper] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [comment, setComment] = useState(null);
  const [profileId, setProfileId] = useState("");
  const [position, setPosition] = useState(null);
  const [teacher, setTeacher] = useState(null);

  const [applicentName, setApplicentName] = useState("");
  const [applicantsList, setApplicantList] = useState("");

  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [viewOpenning, setViewOpennings] = useState("");

  const alert = useAlert();

  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id);
    if (profileDetail[newValue].applicant_role_name === "Teacher") {
      setProfileId(2);
    } else if (profileDetail[newValue].applicant_role_name === "Coordinator") {
      setProfileId(3);
    } else if (profileDetail[newValue].applicant_role_name === "Principal") {
      setProfileId(4);
    } else if (profileDetail[newValue].applicant_role_name === "others") {
      setProfileId(9);
    }
  }

  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList,
  } = useFetch(null);

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings,
  } = useFetch(null);

  const {
    data: RolesData,
    isLoading: RolesDataLoading,
    doFetch: FetchRoles,
  } = useFetch(null);

  useEffect(() => {
    FetchRoles({
      url: urls.academicRoleList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }, [Id]);

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [positionType]);

  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [RoleId, positionType]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);
  const {
    data: profilePosition,
    isLoading: profilePositionLoading,
    doFetch: fetchPosition,
  } = useFetch(null);
  const {
    data: UpdatePosition,
    isLoading: fetchUpdatePositionLoading,
    doFetch: fetchUpdatePosition,
  } = useFetch(null);

  const {
    data: applicantsListData,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch(null);

  const {
    // data: contacted,
    isLoading: contactedLoading,
    doFetch: fetchContacted,
  } = useFetch(null);

  const {
    data: subjectDetail,
    isLoading: subjectDetailLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  const {
    data: branch,
    isLoading: branchLoading,
    doFetch: fetchBranch,
  } = useFetch(null);

  const {
    data: paperList,
    isLoading: paperListLoading,
    doFetch: fetchPaperList,
  } = useFetch(null);

  const {
    data: paperAssigned,
    isLoading: paperAssignedLoading,
    doFetch: fetchPaperAssigned,
  } = useFetch(null);

  const {
    data: searchedApplicent,
    isLoading: searchingLoading,
    doFetch: fetchSearchApplicent,
  } = useFetch(null);

  useEffect(() => {
    if (applicantsListData) {
      setApplicantList(applicantsListData);
    }
  }, [applicantsListData]);

  useEffect(() => {
    if (searchedApplicent) {
      setApplicantList(searchedApplicent);
    }
  }, [searchedApplicent]);

  let loader = null;
  if (
    paperAssignedLoading ||
    paperListLoading ||
    branchLoading ||
    subjectDetailLoading ||
    contactedLoading ||
    applicantDetailLoading ||
    applicantsListLoading ||
    profileDetailLoading ||
    searchingLoading
  ) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.academicRoleListApi,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchSubject({
        url: urls.subjectApiView,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranch({
        url: urls.branch,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchBranch, fetchProfile, fetchSubject, name]);

  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setApplicentName("");
      setId(profileDetail[0].id);
      if (profileDetail[0].applicant_role_name === "Teacher") {
        setProfileId(2);
      } else if (profileDetail[0].applicant_role_name === "Coordinator") {
        setProfileId(3);
      } else if (profileDetail[0].applicant_role_name === "Principal") {
        setProfileId(4);
      } else if (profileDetail[0].applicant_role_name === "others") {
        setProfileId(9);
      }
    }
  }, [profileDetail]);

  useEffect(() => {
    if (paperAssigned) {
      fetchApplicant({
        url: `${
          urls.assignToRecruiter
        }?position_id=${viewOpenning}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=${page +
          1}&page_size=${rowsPerPage || 50}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setIsChecked({});
      setSelectAll(false);
      setTestPaper("");
    }
  }, [fetchApplicant, name, paperAssigned]);

  useEffect(() => {
    if (Id) {
      // fetchApplicant({
      //   url: `${urls.assignToRecruiter}?role_id=${Id}`,
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${name.personal_info.token}`
      //   }
      // });
      fetchPaperList({
        url: urls.retrieveRecuriterPanel,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setStartDate(null);
      setEndDate(null);
      setFilteredBranch([]);
      setFilteredSub([]);
      setPage(0);
      setIsChecked({});
      setSelectAll(false);
      setTestPaper("");
    }
  }, [Id, fetchApplicant, name]);
  useEffect(() => {
    if (position) {
      fetchPosition({
        url: `${urls.roleWisePositionApi}?role=${position}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [position, name.personal_info.token]);

  // const getPosition = () => {
  //   if (position == null) {
  //     alert.warning('Please Select Position..!')
  //     return;
  //   }
  //   fetchPosition({
  //     url: `${urls.roleWisePositionApi}?role=${position}`,
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${name.personal_info.token}`,
  //     },
  //   });
  // }
  const submit = () => {
    if (position == null) {
      alert.warning("Please Select Position..!");
      return;
    }
    if (teacher == null) {
      alert.warning("Please Select Teacher..!");
      return;
    }
    const obj = {
      position_id: teacher,
      applicant_id: selectedId,
    };
    fetchUpdatePosition({
      url: urls.applicantUpdatePosition,
      method: "PUT",
      body: obj,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };
  const handleGetData = () => {
    if (!positionType) {
      alert.warning("Please Select Position!");
      return;
    }
    if (!RoleId){
      alert.warning("Please Select Role!");
      return;
    }
    // if (startDate == null) {
    //   alert.warning("Please Select Start Date..!");
    //   return;
    // }
    // if (endDate == null) {
    //   alert.warning("Please Select End Date..!");
    //   return;
    // }
    setPage(0);
    setIsChecked({});
    setSelectAll(false);
    fetchApplicant({
      url: `${
        urls.assignToRecruiter
      }?position_id=${viewOpenning}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=1&page_size=${rowsPerPage ||
        50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  useEffect(() => {
    if (applicentName) {
      fetchSearchApplicent({
        url: `${
          urls.searchApplicentsApi
        }?position_type=${positionType}&position_id=${viewOpenning}&role_id=${RoleId}&page=${page +
          1}&page_size=${rowsPerPage || 50}&search=${applicentName}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [applicentName]);

  const fetchApplicantData = (e) => {
    setApplicentName(e.target.value);
  };

  // function functionSearch(data) {
  //   fetchSearchApplicent({
  //     url: `${urls.searchApplicentsApi}?page=${page +
  //       1}&page_size=${rowsPerPage || 5}&search=${data}`,
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${name.personal_info.token}`,
  //     },
  //   });
  // }

  // useEffect(() => {
  //   if (applicentName) {
  //     functionSearch(applicentName);
  //   }
  // }, [page, rowsPerPage]);

  const fetchSearch = (e) => {
    // setPage(0);
    // setRowsPerPage(5);
    // if (e.keyCode === 13) {
    //   const data = document.getElementById("search-bar").value;
    //   setApplicentName(document.getElementById("search-bar").value);
    //   functionSearch(data);
    // }
  };

  //hrHead

  function updateHandler(ID, hr) {
    const firstName = name.personal_info.first_name;
    setEdit(firstName === hr ? false : true);
    setOpen(true);
    setSelectedId(ID);
    fetchApplicantDetail({
      url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  const onContacted = (ID) => {
    const body = { id: ID };
    fetchContacted({
      url: urls.isContactedAPI,
      method: "put",
      body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBranch = (value) => () => {
    const currentIndex = filteredBranch.indexOf(value);
    const newChecked = [...filteredBranch];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredBranch(newChecked);
  };

  const handleSub = (value) => () => {
    const currentIndex = filteredSub.indexOf(value);
    const newChecked = [...filteredSub];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredSub(newChecked);
  };

  const applyFilter = () => {
    setPage(0);
    setIsChecked({});
    setSelectAll(false);
    fetchApplicant({
      url: `${
        urls.assignToRecruiter
      }?position_id=${viewOpenning}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=1&page_size=${rowsPerPage ||
        50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  useEffect(() => {

    // if ((page || rowsPerPage) && !applicentName) {
    if ((page || rowsPerPage)&& positionType) {
      fetchApplicant({
        url: `${
          urls.assignToRecruiter
        }?position_id=${viewOpenning}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=${page +
          1}&page_size=${rowsPerPage || 50}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [page, rowsPerPage]);
// }, [page, fetchApplicant, rowsPerPage, name]);


  function handleChangePage(event, newPage) {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  const subjectList = () => {
    let subject = null;
    if (subjectDetail && subjectDetail.length) {
      subject = subjectDetail.map((value) => {
        const subId = `Sub Id : ${value.id}`;
        return (
          <ListItem
            key={value.id}
            role={undefined}
            dense
            button
            onClick={handleSub(value.id)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredSub.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": subId }}
              />
            </ListItemIcon>
            <ListItemText id={subId} primary={value.subject_name} />
          </ListItem>
        );
      });
    }
    return subject;
  };

  const branchList = () => {
    let branchDetail = null;
    if (branch && branch.length) {
      branchDetail = branch.map((value) => {
        const branchId = `Branch Id : ${value.id}`;
        return (
          <ListItem
            key={value.id}
            role={undefined}
            dense
            button
            onClick={handleBranch(value.id)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredBranch.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": branchId }}
              />
            </ListItemIcon>
            <ListItemText id={branchId} primary={value.branch_name} />
          </ListItem>
        );
      });
    }
    return branchDetail;
  };

  const checkboxChangeHandler = (e, id) => {
    const checked = { ...isChecked };
    if (e.target.checked) {
      checked[id] = true;
    } else {
      checked[id] = false;
      setSelectAll(false);
    }
    setIsChecked(checked);
  };

  const checkAllLeadsChangeHandler = (e) => {
    const checked = {};
    if (applicantsList && applicantsList.result.length) {
      applicantsList.result.forEach((ele) => {
        checked[ele.id] = e.target.checked;
      });
      setIsChecked(checked);
      setSelectAll(!selectAll);
    }
  };

  const assignPaper = () => {
    // const checkedRow = Object.keys(isChecked).filter(ele => isChecked[ele]);
    // if (checkedRow.length === 0) {
    //   alert.warning('select to assign')
    //   return
    // }
    // if (!testPaper) {
    //   alert.warning("select Test Paper")
    //   return
    // }
    if (!testPaper || !comment) {
      alert.warning("Please Fill All Fields");
      return;
    }
    const body = {
      applicant: selectedId,
      recruiter: testPaper,
      comment: comment,
      // role: Id
    };
    fetchPaperAssigned({
      url: urls.reassignedApplicant,
      method: "put",
      body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
    setOpen(false);
  };

  function profileTable() {
    let applicantTable = null;
    if (
      applicantsList &&
      applicantsList.result &&
      applicantsList.result.length === 0
    ) {
      applicantTable = (
        <React.Fragment>
          <Typography
            style={{ textAlign: "center", color: "blue", marginTop: "100px" }}
            variant="h4"
          >
            Records Not Found
          </Typography>
        </React.Fragment>
      );
    }
    if (
      applicantsList &&
      applicantsList.result &&
      applicantsList.result.length
    ) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{ marginTop: "10px" }}>
            <TableContainer className="role">
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell align="left">
                    <input
                      type="checkbox"
                      style={{ width: '20px', height: '20px' }}
                      checked={selectAll}
                      onChange={checkAllLeadsChangeHandler}
                    />
                </TableCell> */}
                  <TableCell align="left"><strong>Name</strong></TableCell>
                  <TableCell align="left"><strong>Contact No.</strong></TableCell>

                  {/* <TableCell align="left">Recruiter</TableCell> */}
                  <TableCell align="left"><strong>Subject Interested</strong></TableCell>
                  <TableCell align="left"><strong>Grade</strong></TableCell>
                  <TableCell align="left"><strong>Branch Interested</strong></TableCell>
                  <TableCell align="left"><strong>Details</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicantsList.result.map((details, i) => (
                  <TableRow key={details.id}>
                    {/* <TableCell align="left">
                    <input
                      type="checkbox"
                      style={{ width: '20px', height: '20px' }}
                      checked={isChecked[details.id]}
                      onChange={(e) => { checkboxChangeHandler(e, details.id); }}
                    />  
                  </TableCell> */}
                    <TableCell align="left">{details.name || ""}</TableCell>
                    <TableCell align="left">
                      {details.contact_no || "no num"}

                      {/* {applicantDetail &&
                        applicantDetail.applicant &&
                        applicantDetail.applicant.contact_no} */}
                    </TableCell>
                    {/* <TableCell align="left">{details.Hr_name || ''}</TableCell> */}
                    <TableCell align="left">{details.subject || ""}</TableCell>
                    <TableCell align="left">{details.grade || ""}</TableCell>
                    <TableCell align="left">{details.branch || ""}</TableCell>
                    <TableCell align="left">
                      <Button
                        className={classes.addButton}
                        // color="primary"
                        size="small"
                        variant="contained"
                        onClick={() =>
                          updateHandler(details.id, details.Hr_name)
                        }
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={5}
                    labelDisplayedRows={() =>
                      `Page ${page + 1} of ${+applicantsList.total_pages}`
                    }
                    rowsPerPageOptions={[50, 75, 100]}
                    rowsPerPage={rowsPerPage || 50}
                    count={+applicantsList.count}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: "13px" }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        lastPageChange(applicantsList.total_pages - 1)
                      }
                      disabled={page === +applicantsList.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
            </TableContainer>
          </Paper>
        </React.Fragment>
      );
    }
    return applicantTable;
  }

  let modal = null;
  if (applicantDetail) {
    modal = (
      <Modal click={handleClose} large style={{ padding: "15px" }} open={open}>
        <React.Fragment>
        <div style={{display: "flex", justifyContent: "space-between"}}>
        <Typography className="headings" variant="h4">
            {" "}
            <strong> Details</strong>
          </Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        </div>
          <Divider className={classes.divider} />
          <Grid container >
            <Grid item md={6} xs={12} className={classes.gridOne}>
              <Typography className="textDisplay"  variant="h5">
                <strong> Name :</strong>{" "}
                {applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.user &&
                  applicantDetail.applicant.user.first_name}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridOne}>
              <Typography className="textDisplay"  variant="h5">
                <strong>PhoneNo :</strong>{" "}
                {applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.contact_no}
              </Typography>
            </Grid>

            <Grid item md={5} xs={12} className={classes.gridOne}>
              <div style={{ display: "flex" }}>
              <Typography className="textDisplay"  variant="h5">
                <strong>Grade :</strong>{" "}
                {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name}
              </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Grade:{" "}
                  {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
              <Typography className="textDisplay"  variant="h5">
              <strong>Position Applied :</strong>{" "}
              {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name}
              </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Position Applied:{" "}
                  {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
              <Typography className="textDisplay" variant="h5">
              <strong>Branch Interested :</strong>{" "}
              {applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name}
              </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Branch Interested:{" "}
                  {applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name}
                </b> */}
              </div>
              {applicantDetail &&
              applicantDetail.applicant &&
              applicantDetail.applicant.applicant_role &&
              applicantDetail.applicant.applicant_role.applicant_role_name ===
                "others" ? (
                <Grid container spacing={2} className={classes.modalPaper}>
                  <Grid item sm={5} md={4} xs={5}>
                    <TextField
                      label="Role"
                      margin="dense"
                      required
                      fullWidth
                      select
                      // SelectProps={{ multiple: true }}
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      variant="outlined"
                    >
                      {profileDetail &&
                        profileDetail.length !== 0 &&
                        profileDetail.map((role) => (
                          <MenuItem
                            value={role.id}
                            key={role.id}
                            name={role.applicant_role_name}
                          >
                            {role.applicant_role_name
                              ? role.applicant_role_name
                              : ""}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item sm={5} md={4} xs={5}>
                    <TextField
                      label="position"
                      margin="dense"
                      required
                      fullWidth
                      select
                      // SelectProps={{ multiple: true }}
                      value={teacher}
                      onChange={(e) => setTeacher(e.target.value)}
                      variant="outlined"
                    >
                      {profilePosition &&
                        profilePosition.length !== 0 &&
                        profilePosition.map((role) => (
                          <MenuItem
                            value={role.id}
                            key={role.id}
                            name={role.title}
                          >
                            {role.title ? role.title : ""}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item sm={1} md={1} xs={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      style={{ marginTop: "10px", marginLeft: "10px" }}
                      onClick={submit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
            </Grid>

            <Grid item md={3} className={classes.item} />

            <Grid item md={6} xs={12} className={classes.item}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <Typography className="textDisplay" variant="h5"> <strong>Resume</strong></Typography>
                    </TableCell>
                    <TableCell >
                      {applicantDetail.applicant &&
                      applicantDetail.applicant.resume ? (
                        <a
                          target="_blank"
                          href={applicantDetail.applicant.resume || ""}
                        >
                          {" "}
                          <Typography className="textDisplay" variant="h5">  View Resume</Typography>
                          
                        </a>
                      ) : (
                        "Resume Not Uploaded"
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          {profileId == 9 ? (
            <Typography variant="h5" component="h2">
              For Others Profile Please Fill The Position and Role First
            </Typography>
          ) : (
            ""
          )}
          <Grid container spacing={3} style={{ marginTop: "6px" }}>
            <Grid item md={3} sm={12} xs={12}>
              <TextField
                label="Recruiter"
                margin="dense"
                required
                fullWidth
                select
                // SelectProps={{ multiple: true }}
                value={testPaper}
                onChange={(e) => setTestPaper(e.target.value)}
                variant="outlined"
              >
                {paperList &&
                  paperList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.first_name}
                    >
                      {data.first_name ? data.first_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <TextField
                label="Comment"
                margin="dense"
                required
                fullWidth
                // SelectProps={{ multiple: true }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                className={classes.addButton}
                // color="primary"
                fullWidth
                size="small"
                variant="contained"
                onClick={assignPaper}
              >
                Assign
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={5} xs={12}>
          <Typography className="headings" variant="h4"><strong>Assign To Recruiter</strong></Typography>
        </Grid>
        {/* <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="i'm looking for..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              id="search-bar"
              onKeyDown={fetchApplicantData}
            />
          </div>
        </Grid> */}
        <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={fetchApplicantData}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.root} >
        <Paper className={classes.paperrrrr}>
          <Grid container spacing={2} style={{padding:"10px"}}>
            <Grid item md={4} xs={12} >
              <Typography>
                {" "}
                Select Position Type &nbsp; <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={positionType || ""}
                onChange={(e) => setPositionType(e.target.value)}
                variant="outlined"
              >
                {positionTypeList.map((option, index) => (
                  <MenuItem key={index} value={option.label} name={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>
                {" "}
                Select Role &nbsp; <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={RoleId || ""}
                onChange={(e) => setRoleId(e.target.value)}
                variant="outlined"
              >
                {roleList &&
                  roleList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.applicant_role_name}
                    >
                      {option.applicant_role_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>Select Opening</Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={viewOpenning || ""}
                onChange={(e) => setViewOpennings(e.target.value)}
                variant="outlined"
              >
                {viewOpenningsData &&
                  viewOpenningsData.length !== 0 &&
                  viewOpenningsData.map((option, index) => (
                    <MenuItem key={index} value={option.id} name={option.title}>
                      {option.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>Start Date</Typography>
              <TextField
                // className={classes.textField}
                margin="dense"
                required
                fullWidth
                className="textList"
                onChange={(e) => handleStartDateChange(e.target.value)}
                type="date"
                value={startDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>End Date</Typography>
              <TextField
                // className={classes.textField}
                margin="dense"
                required
                fullWidth
                className="textList"
                onChange={(e) => handleEndDateChange(e.target.value)}
                type="date"
                value={endDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12} style={{ marginTop: "33px" }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGetData}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Divider className={classes.divider} />
        {profileTable()}
      </div>
      {modal}
      {loader}
    </React.Fragment>
  );
};

AssignToRecruiter.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AssignToRecruiter);
