export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    grid:{
      padding: theme.spacing(1, 2, 1), 
    },
    textfieldwidth:{
      // marginleft: "-45px",width:"170%"
      marginLeft: "-45px",
      width:"170%",
    },
    });