import React, { useState, useEffect } from "react";
import tinymce from "tinymce";
import MediaQuery from "react-responsive";
// import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableFooter,
  IconButton,
  Select,
  TextField,
  InputLabel,
  Input,
  FormControl,
  MenuItem,
  TableContainer,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import styles from "./offerReport.style";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";

const OfferReport = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  


  const [check, setCheck] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [bottomHRef, setBottomHRef] = useState("");

  const alert = useAlert();
  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  

  useEffect(() => {
    if(startDate && endDate){
    setBottomHRef([
      {
        csv: `${urls.offerCsv}?active=${check}&start_date=${startDate}&end_date=${endDate}&download=true`,
      },
    ]);
  }
}, [
    setBottomHRef,
    check,
    startDate,
    endDate,
 
  ]);

  const handleChange = (event) => {
    setCheck(event.target.checked);
  };


  
  

  
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item md={3}>
          <Typography
            className="headings"
            style={{ whiteSpace: "nowrap" }}
            variant="h4"
          >
            <strong>Offer Letter Report</strong>
          </Typography>
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={3}></Grid>
       
      </Grid>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
         
          <Grid item md={3} xs={12} style={{ marginTop: "21px" }}>
            <FormControlLabel
              control={<Checkbox checked={check} onChange={handleChange} />}
              label="Active Users"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>Start Date</Typography>

            <TextField
              margin="dense"
              className={classes.textField}
              required
              fullWidth
              onChange={(e) => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || " "}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>End Date</Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={(e) => handleEndDateChange(e.target.value)}
              type="date"
              value={endDate || ""}
              variant="outlined"
            />
          </Grid>
          
          
          <Grid item md={1} xs={12} style={{ marginTop: "30px" }}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              // size='large'
              margin="dense"
              href={bottomHRef && bottomHRef[0].csv}
              disabled={startDate && endDate ? false : true}
            >
              CSV
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
OfferReport.propTypes = {};
export default withStyles(styles)(OfferReport);
