import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useState } from "react";
import urls from "../../../../url";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
function ReplacementFormModal({
  open,
  onClose,
  dataArray,
  handleChange,
  handleClearVacancy,
}) {
  const [form] = useForm();
  const [fileUploading, setFileUploading] = useState(false);
  function handleUploadFile(event, index) {
    setFileUploading(true);
    let file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("file_name", file.name);
    axios
      .post(urls.fileUploadReplacement, formData)
      .then((res) => {
        handleChange(index, "file", res.data?.path);
        message.success("File uploaded successfully!");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setFileUploading(false);
      });
  }
  return (
    <Modal
      width={window.innerWidth > 600 ? 600 : window.innerWidth - 10}
      title={
        <Row justify={"space-between"}>
          <div>Replacement Form</div>{" "}
          <Tag
            onClick={() => {
              form.resetFields();
              handleClearVacancy();
            }}
            className="th-pointer"
            color="volcano"
            icon={<EditOutlined />}
          >
            Edit Vacancy Number
          </Tag>
        </Row>
      }
      open={open}
      closable={false}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>,
        <Button form="replacementForm" htmlType="submit" type="primary">
          Save
        </Button>,
      ]}
    >
      <Spin
        size="large"
        spinning={fileUploading}
        indicator={<LoadingOutlined />}
      >
        <Form
          id="replacementForm"
          form={form}
          layout="vertical"
          style={{
            maxHeight: "340px",
            overflowY: "scroll",
            padding: "0px 15px",
          }}
          onFinish={onClose}
          // className="th-hidden-scrollbar"
        >
          {dataArray?.map((replacement, index) => {
            return (
              <Row
                style={{
                  background: "#f3f3f3",
                  borderRadius: "5px",
                  paddingTop: "10px",
                  marginBottom: "10px",
                }}
                gutter={24}
              >
                <Col md={24}>
                  <Typography.Title className="m-0" level={5}>
                    Replacement Candidate {index + 1}
                  </Typography.Title>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_name_${index}`}
                    label={`Name`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                    initialValue={replacement?.name}
                  >
                    <Input
                      onKeyPress={(e) => {
                        const pattern = /^[a-zA-Z\s]+$/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        handleChange(index, "name", e.target.value);
                      }}
                      placeholder="Enter Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_erp_${index}`}
                    label={`ERP`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                    initialValue={replacement?.erp}
                  >
                    <Input
                      maxLength={11}
                      onKeyPress={(e) => {
                        const pattern = /^[0-9]$/;
                        const inputChar = String.fromCharCode(e.charCode);
                        if (!pattern.test(inputChar)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        handleChange(index, "erp", e.target.value);
                      }}
                      placeholder="Enter ERP"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_file_${index}`}
                    label={`Resignation Letter`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                    extra={
                      replacement?.file && (
                        <Row justify={"space-between"}>
                          <small>
                            {replacement?.file
                              ?.split("/")
                              ?.pop()
                              ?.split(" ")
                              ?.pop()}
                          </small>
                          <Tag
                            className="m-0 th-pointer"
                            onClick={() => {
                              form.resetFields([`replacement_file_${index}`]);
                              handleChange(index, "file", "");
                            }}
                            icon={<DeleteOutlined />}
                            color="error"
                          />
                        </Row>
                      )
                    }
                  >
                    <Input
                      onChange={(event) => handleUploadFile(event, index)}
                      type="file"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_exit_date_${index}`}
                    label={`Exit Date`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                    initialValue={
                      replacement?.exit_date
                        ? dayjs(replacement?.exit_date)
                        : null
                    }
                  >
                    <DatePicker
                      onChange={(e) => {
                        handleChange(
                          index,
                          "exit_date",
                          dayjs(e).format("YYYY-MM-DD")
                        );
                      }}
                      style={{ width: "100%" }}
                      placeholder="Enter Exit Date"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_joining_date_${index}`}
                    label={`Expected joining date of replacement`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                    initialValue={
                      replacement?.joining_date
                        ? dayjs(replacement?.joining_date)
                        : null
                    }
                  >
                    <DatePicker
                      onChange={(e) => {
                        handleChange(
                          index,
                          "joining_date",
                          dayjs(e).format("YYYY-MM-DD")
                        );
                      }}
                      style={{ width: "100%" }}
                      placeholder="Enter Tentative Joining Date"
                    />
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
        </Form>
      </Spin>
    </Modal>
  );
}

export default ReplacementFormModal;
