import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { useAlert } from "../../../hoc/alert";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import { Option } from "antd/es/mentions";

const CrudBranchForm = ({ classes }) => {
  const [form] = useForm();
  const history = useHistory();
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const location = useLocation();
  const { yourData } =
    location && location.state ? location.state : { yourData: null };

  // Now yourData will be either the value from location.state or null if location or location.state is null

  const [checkedLoc, setCheckedLoc] = useState(null);
  const [branch, setBranch] = useState("");
  const [addedBy, setAddedBy] = useState(auth.personal_info.user_id);
  const [selectedRoleID, setSelectedRoleID] = useState(null);

  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });

  const {
    data: addInterviewPanelResponse,
    isLoading: addInterviewPanelResponseLoading,
    doFetch: addInterview,
  } = useFetch([], { suppressAlert: true });

  const {
    data: updatainteriewData,
    isLoading: updatainteriewDataLoading,
    doFetch: fetchUpdataInterview,
  } = useFetch([], { suppressAlert: true });
  const [deleteInterviewData, fetchDeleteInterview] = useState();

  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [fetchCity]);

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [checkedLoc]);

  useEffect(() => {
    // console.log(yourData, "YourDat");
    if (yourData) {
      setSelectedRoleID(yourData.id);
      handleCityMultipleChange(yourData?.branch?.city_id?.id);
      form.setFieldsValue({
        branch_id: yourData?.branch?.id,
        first_st_poc_erp_code: yourData?.first_st_poc_erp_code,
        first_St_poc_from_hr_name_EA_or_EA_substitute_email:
          yourData?.first_St_poc_from_hr_name_EA_or_EA_substitute_email,
        first_st_poc_email: yourData.first_st_poc_email,
        first_st_poc_contact_number: yourData?.first_st_poc_contact_number,
        first_st_escalation_point_name: yourData.first_st_escalation_point_name,
        first_escalation_point_email: yourData.first_escalation_point_email,
        cic_email: yourData.cic_email,
        tic_email: yourData.tic_email,
        second_escalation_point_name: yourData?.second_escalation_point_name,
        second_escalation_point_email: yourData?.second_escalation_point_email,
        bdm_contact_number: yourData.bdm_contact_number,
        bdm_id: yourData.bdm_id,
        bdm_name: yourData.bdm_name,
        ops_manager: yourData.ops_manager,
        ops_manager_contact_number: yourData?.ops_manager_contact_number,
        ops_manager_email: yourData?.ops_manager_email,
        ops_zonal_head_contact_number: yourData?.ops_zonal_head_contact_number,
        ops_zonal_head_email: yourData?.ops_zonal_head_email,
        ops_zonal_head_name: yourData?.ops_zonal_head_name,
        principal_contact_number: yourData?.principal_contact_number,
        principal_email: yourData?.principal_email,
        principal_name: yourData?.principal_name,
        acad_ea_name: yourData?.acad_ea_name,
        acad_ea_email: yourData?.acad_ea_email,
        acad_ea_contact_number: yourData?.acad_ea_contact_number,
        added_by: addedBy,
      });
    }
  }, [yourData]);

  function handleCityMultipleChange(event) {
    setCheckedLoc(event);
    setBranch();
  }

  function handleMultipleChange(event) {
    setBranch(event);
  }

  function addInterviewPanel(formValue) {
    const formData = new FormData();
    Object.entries(formValue).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (selectedRoleID) {
      formData.append("id", selectedRoleID);
      axios
        .put(urls.updateBranch, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
          },
        })
        .then((res) => {
          form.resetFields();
          setSelectedRoleID(null);
          history.push("/branch_details");
          message.success("Branch Details added successfully!");
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? "Something went wrong!"
          );
        });
    } else {
      formData.append("added_by", addedBy);
      axios
        .post(urls.updateBranch, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
          },
        })
        .then((res) => {
          form.resetFields();
          setSelectedRoleID(null);
          history.push("/branch_details");
          message.success("Branch Details added successfully!");
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? "Something went wrong!"
          );
        });
      // addInterview({
      //   url: urls.postBranch,
      //   method: "POST",
      //   body: formData,
      //   headers: {
      //     // "Content-Type": "application/json",
      //     Authorization: `Bearer ${auth.personal_info.token}`,
      //   },
      // });
    }
  }

  return (
    <>
      <Card>
        <Form onFinish={addInterviewPanel} id="branchForm" form={form}>
          <Row gutter={16}>
            <Col md={12}>
              <Form.Item
                // name="city"
                label="City"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                rules={[{ required: true, message: "Please Select City" }]}
              >
                <Select
                  value={checkedLoc || "Please Select City"}
                  onChange={(e) => handleCityMultipleChange(e)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {cityList &&
                    cityList.length !== 0 &&
                    cityList.map((option) => (
                      <Option
                        key={option.value}
                        value={option.id}
                        name={option.id}
                      >
                        {option.city_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="branch_id"
                label="Branch"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                rules={[{ required: true, message: "Please Select Branch" }]}
                showSearch
                filterOption={(input, options) => {
                  return (options?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                <Select
                  value={branch || ""}
                  onChange={(e) => handleMultipleChange(e)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {branchList &&
                    branchList.map((data) => (
                      <Option
                        value={data.id}
                        key={data.id}
                        name={data.branch_name}
                      >
                        {data.branch_name ? data.branch_name : ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="first_st_poc_erp_code"
                label="First Poc Erp Code"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter First Poc Erp Code",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="first_St_poc_from_hr_name_EA_or_EA_substitute_email"
                label="First Poc Hr Name"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter First Poc Hr Name",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="first_st_poc_email"
                label="First Poc Hr Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter First Poc Hr Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="number"
                name="first_st_poc_contact_number"
                label="First Poc Contact No"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter First Poc Contact No",
                //   },
                // ]}
              >
                <Input
                  maxLength={10}
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="first_st_escalation_point_name"
                label="First Escalation Name"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter First Escalation Name",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="first_escalation_point_email"
                label="First Escalation Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter First Escalation Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="second_escalation_point_name"
                label="Second Escalation Name"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Second Escalation Name",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="second_escalation_point_email"
                label="Second Escalation Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Second Escalation Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="acad_ea_name"
                label="Acad Name"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Acad Name",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="acad_ea_email"
                label="Acad Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Acad Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="number"
                name="acad_ea_contact_number"
                label="Acad Contact"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Acad Contact",
                //   },
                // ]}
              >
                <Input
                  maxLength={10}
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="ops_manager"
                label="Ops Manager Name"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Ops Manager Name",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="ops_manager_email"
                label="Ops Manager Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Ops Manager Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={12}>
              <Form.Item
                type="phone"
                name="ops_manager_contact_number"
                label="Ops Manager Contact"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Ops Manager Contact",
                //   },
                // ]}
              >
                <Input
                  maxLength={10}
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="phone"
                name="ops_zonal_head_contact_number"
                label="Ops Zonal Contact"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Ops Zonal Head Contact",
                //   },
                // ]}
              >
                <Input
                  maxLength={10}
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="ops_zonal_head_name"
                label="Ops Zonal Head Name"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Ops Zonal Head Name",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="ops_zonal_head_email"
                label="Ops Zonal Head Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Ops Zonal Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="bdm_name"
                label="Bdm Name"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Ops Bdm name",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="bdm_id"
                label="Bdm Id"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Bdm Id",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="phone"
                name="bdm_contact_number"
                label="Bdm Contact"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Bdm Contact",
                //   },
                // ]}
              >
                <Input
                  maxLength={10}
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                name="principal_name"
                label="Principal Name"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Principal Name",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="phone"
                name="principal_contact_number"
                label="Principal Contact"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Principal Contact",
                //   },
                // ]}
              >
                <Input
                  maxLength={10}
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="principal_email"
                label="Principal Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Principal Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="cic_email"
                label="Cic Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Cic Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                type="email"
                name="tic_email"
                label="Tic Email"
                labelCol={{ span: 24 }} // Full width for the label
                wrapperCol={{ span: 24 }} // Full width for the input
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Enter Tic Email",
                //   },
                // ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={() => {
                form.resetFields();
                setSelectedRoleID(null);
                history.push("/branch_details");
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              loading={addInterviewPanelResponseLoading}
              form="branchForm"
              type="primary"
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default CrudBranchForm;
