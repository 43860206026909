import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Table,
  Tag,
  Row,
  Col,
  Form,
  Select,
  DatePicker,
  Typography,
  Space,
  Input,
  Modal,
  message,
} from "antd";
import { useHistory } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import urls from "../../url";
const budgetApprover = [13, 21];
const offerApprover = [1, 4];
const OfferLetterList = () => {
  const history = useHistory();
  const [filterForm] = useForm();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { TextArea } = Input;
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [tableDataList, setTableDataList] = useState([]);
  const [search, setSearch] = useState("");
  let role = userData?.personal_info?.role_id;
  const [offerStatusList] = useState([
    { id: "approved", value: "Approved" },
    { id: "pending", value: "Pending" },
    { id: "rejected", value: "Reject" },
  ]);
  const [budgetStatusList] = useState([
    { id: "approved", value: "Approved" },
    { id: "pending", value: "Pending" },
    { id: "rejected", value: "Reject" },
  ]);

  useEffect(() => {
    let obj = {
      page: page,
      page_size: pageSize || 50,
    };
    getTableData(obj);
  }, []);

  const handleFilter = () => {
    const {
      date_range,
      released_status,
      budget_approver_status,
    } = filterForm.getFieldsValue();
    let obj = {
      page: 1,
      page_size: pageSize || 50,
    };
    if (date_range?.length && date_range[0]) {
      obj.created_date_gte = dayjs(date_range[0]).format("YYYY-MM-DD");
    }
    if (date_range?.length && date_range[1]) {
      obj.created_date_lte = dayjs(date_range[1]).format("YYYY-MM-DD");
    }
    if (budget_approver_status) {
      obj.budget_approver_status = budget_approver_status;
    }
    if (released_status) {
      obj.released_status = released_status;
    }
    if (search) {
      obj.search = search;
      obj.page = 1;
    }
    getTableData(obj);
  };

  const getTableData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.offerLetterApproval}`, {
        params: params,
      })
      .then((response) => {
        setTableDataList(response?.data?.results);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const offerStatusOptions = offerStatusList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const budgetStatusOptions = budgetStatusList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });

  const columns = [
    {
      title: "ID",
      key: "id",
      align: "center",
      render: (data) => (
        <span>{data?.applicant_id ? data?.applicant_id : "-"} </span>
      ),
    },
    {
      title: "Candidate Name ",
      key: "name",
      align: "center",
      render: (data) => <span>{data?.candidate_name} </span>,
    },
    {
      title: "Contact No",
      key: "contact_no",
      align: "center",
      render: (data) => <span>{data?.candidate_contact_no ?? "-"}</span>,
    },
    {
      title: "Designation",
      key: "designation",
      align: "center",
      render: (data) => <span>{data?.designation ?? "-"}</span>,
    },
    {
      title: "Offer Approved Status",
      key: "released_status",
      align: "center",
      render: (data) =>
        data?.budget_approver_id ? (
          data?.budget_approver_status == "pending" ? (
            <div style={{ color: "blue" }}>Budget Approval Pending</div>
          ) : data?.released_status == "pending" ? (
            offerApprover?.includes(role) ? (
              <Tag
                style={{ cursor: "pointer" }}
                color="geekblue"
                onClick={() =>
                  window.open(
                    `/offer-letter-approval?offer_id=${data?.id}`,
                    "_blank"
                  )
                }
              >
                Approve
              </Tag>
            ) : (
              <span style={{ color: "blue", fontStyle: "italic" }}>
                Pending
              </span>
            )
          ) : (
            <span
              style={{
                textTransform: "capitalize",
                fontStyle: "italic",
                color: `${
                  data?.released_status == "approved"
                    ? "green"
                    : data?.released_status == "reject"
                    ? "red"
                    : "blue"
                }`,
              }}
            >
              {data?.released_status ?? "-"}
            </span>
          )
        ) : data?.released_status == "pending" ? (
          offerApprover?.includes(role) ? (
            <Tag
              style={{ cursor: "pointer" }}
              color="geekblue"
              onClick={() =>
                window.open(
                  `/offer-letter-approval?offer_id=${data?.id}`,
                  "_blank"
                )
              }
            >
              Approve
            </Tag>
          ) : (
            <span style={{ color: "blue", fontStyle: "italic" }}>Pending</span>
          )
        ) : (
          <span
            style={{
              textTransform: "capitalize",
              fontStyle: "italic",
              color: `${
                data?.released_status == "approved"
                  ? "green"
                  : data?.released_status == "reject"
                  ? "red"
                  : "blue"
              }`,
            }}
          >
            {data?.released_status ?? "-"}
          </span>
        ),
    },
    {
      title: "Budget Approval Status",
      key: "budget_approval_status",
      align: "center",
      render: (data) =>
        !data?.budget_approver_id ? (
          <div style={{ color: "blue" }}>No Approval needed</div>
        ) : data?.budget_approver_status == "pending" ? (
          budgetApprover?.includes(role) ? (
            <Tag
              style={{ cursor: "pointer" }}
              color="geekblue"
              onClick={() =>
                window.open(
                  `/offer-letter-approval?offer_id=${data?.id}`,
                  "_blank"
                )
              }
            >
              Approve
            </Tag>
          ) : (
            <span style={{ color: "blue", fontStyle: "italic" }}>Pending</span>
          )
        ) : (
          <span
            style={{
              textTransform: "capitalize",
              fontStyle: "italic",
              color: `${
                data?.budget_approver_status == "approved"
                  ? "green"
                  : data?.budget_approver_status == "reject"
                  ? "red"
                  : "blue"
              }`,
            }}
          >
            {data?.budget_approver_status ?? "-"}
          </span>
        ),
    },
  ];

  if (offerApprover?.includes(role)) {
  } else if (budgetApprover?.includes(role)) {
  }

  return (
    <>
      <div className="page_body">
        <Row
          gutter={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 16 }}
          >
            <Typography className="page_title">Offer Letter </Typography>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            style={{ display: "flex", gap: "10px" }}
          >
            <Form.Item>
              <Input
                placeholder="Search candidate"
                onChange={(e) => setSearch(e.target.value)}
                allowClear
              />
            </Form.Item>
            <Button
              type="primary"
              onClick={() => {
                setPage(1);
                handleFilter();
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Form
          id="filterForm"
          form={filterForm}
          layout="vertical"
          style={{ width: "100%", marginBlock: "20px" }}
        >
          <Row justify="" align="middle" gutter={24}>
            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
              <Form.Item name="date_range" label="Start Date - End Date">
                <RangePicker style={{ width: "100%" }} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
              <Form.Item
                name="released_status"
                label="Offer Letter Approval Status"
              >
                <Select placeholder="Select Status" allowClear>
                  {offerStatusOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
              <Form.Item
                name="budget_approver_status"
                label="Budget Approval Status"
              >
                <Select placeholder="Select Status" allowClear>
                  {budgetStatusOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
              <Button
                form="filterForm"
                htmlType="submit"
                type="primary"
                onClick={handleFilter}
                className="button primary"
                style={{ marginLeft: "8px" }}
              >
                Filter
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={tableDataList}
          count={tableDataList?.length}
          pagination={{
            showSizeChanger: false,
            total: tableDataList?.length,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
        />
      </div>
    </>
  );
};

export default OfferLetterList;
