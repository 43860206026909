import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Collapse,
  Tag,
  Tooltip,
  Space,
  Table,
  message,
  Modal,
  Form,
  Spin,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import "./viewApplicantProfile.css";

import {
  CheckCircleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  CloseCircleFilled,
  InfoCircleOutlined,
  EyeOutlined,
  SyncOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import urls from "../../../../url";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";

const ViewApplicantProfile = ({ location }) => {
  const history = useHistory();
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [prevState] = useState(location?.prevState);
  const [applicantId] = useState(location?.state?.applicantId);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [feedBackModal, setFeedBackModal] = useState(false);
  const [docId, setDocId] = useState();
  const [feedBackModalForm] = useForm();
  const [applicantData, setApplicantData] = useState([]);
  const [positionId, setPositionId] = useState();
  const [applicant, setApplicant] = useState();
  const [isUG, setIsUG] = useState(false);
  const [isPG, setIsPG] = useState(false);
  const [allDocuments, setAllDocuments] = useState([]);
  const [bottomHRef, setBottomHRef] = useState("");

  const closeFeedBackModal = () => {
    setFeedBackModal(false);
    feedBackModalForm.resetFields();
  };

  useEffect(() => {
    fetchApplicantDetails();
  }, []);

  const fetchApplicantDetails = () => {
    setDownloadLoading(true);
    setLoading(true);
    axios
      .get(`${urls.multipleApplicantDetails}/`, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
        params: {
          applicants_id: applicantId.toString(),
        },
      })
      .then((response) => {
        if (response?.status == 200) {
          setApplicantData(response?.data?.length && response?.data[0]);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again ");
      })
      .finally(() => {
        setLoading(false);
        setDownloadLoading(false);
      });
  };

  const handleDocStatus = (data, val) => {
    setDocId(data?.id);
    setApplicant(data?.applicant);
    setPositionId(data?.position_apply);
    if (val === "reject") {
      setFeedBackModal(true);
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("is_approved", true);
      formData.append("applicant", data?.applicant);
      formData.append("position_apply", data?.position_apply);
      axios
        .put(`${urls.applicantDocuments}/${data?.id}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.personal_info?.token}`,
          },
        })
        .then((response) => {
          if (response?.status == 200) {
            message.success("Status Updated Successfully");
            fetchApplicantDetails();
          }
        })
        .catch((error) => {
          message.error("Something Went Wrong. Please Try Again");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleDocReject = () => {
    const values = feedBackModalForm.getFieldsValue();
    setLoading(true);
    const formData = new FormData();
    formData.append("is_rejected", true);
    formData.append("applicant", applicant);
    formData.append("position_apply", positionId);
    formData.append("feedback", values?.remarks);
    axios
      .put(`${urls.applicantDocuments}/${docId}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status == 200) {
          message.success("Status Updated Successfully");
          fetchApplicantDetails();
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
        closeFeedBackModal();
      });
  };

  const getGraduationDetails = (data) => {
    if (data?.qualification?.length) {
      data?.qualification?.forEach((each) => {
        if (each?.degree === "ug") {
          setIsUG(true);
        } else if (each?.degree === "pg") {
          setIsPG(true);
        }
      });
    }
  };

  const handleDownloadProfileDetails = () => {
    axios
      .get(`${urls.multipleApplicantDetails}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: { download: "True", applicants_id: applicantId.toString() },
        responseType: "blob",
      })
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.toLowerCase().includes("application/json")) {
          return false;
        } else {
          message.success("Downloading....");
          const blob = window.URL.createObjectURL(
            new Blob([response?.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            })
          );
          let link = document.createElement("a");
          link.href = blob;
          link.setAttribute("download", "Applicant_Profile.xlsx");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };

  const PersonalDetails = () => {
    return (
      <>
        <Row>
          <Col className="subheading" span={10}>
            Email Address {"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.email ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Aadhaar Number {"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.aadhar_number ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Name (as per aadhaar) {"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.name_as_per_aadhar ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Date of Birth (as per aadhaar) {"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.date_of_birth ?? "No Data Available"}
          </Col>
        </Row>
      </>
    );
  };

  const BankAccountDetails = () => {
    return (
      <>
        <Row>
          <Col className="subheading" span={10}>
            Name (as per bank account) {"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.name ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Bank Account Number{"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.account_no ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            BANK IFSC {"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.ifsc_code ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Do you have a PAN ID? {"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.pan_no ? "Yes" : "No"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            PAN ID{"  "}
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.pan_no ?? "No Data Available"}
          </Col>
        </Row>
      </>
    );
  };

  const GeneralInformation = () => {
    return (
      <>
        <Row>
          <Col className="subheading" span={10}>
            From which location you want to collect the ID Card?
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {"No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Please mention your Father's name
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.general_info?.father_name ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Please mention your Mother's name
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.general_info?.mother_name ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Please select your Gender
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.general_info?.gender ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Please mention your Nationality
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text" style={{ textTransform: "capitalize" }}>
            {"  "}
            {applicantData?.general_info?.nationality ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Contact/Phone/Mobile Number
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.contact_no ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Personal Email Address
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.email ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Do you have a govt. approved physical disability certificate?
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.general_info?.disability_certificate ??
              "No Data Available"}
          </Col>
        </Row>
      </>
    );
  };

  const AddressInformation = () => {
    return (
      <>
        <Row>
          <Col className="subheading" span={10}>
            Flat, House No., Building, Apartment
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.address?.length
              ? applicantData?.address[0]?.house_no
              : "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Area, Street, Sector, Village
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.address?.length
              ? applicantData?.address[0]?.area
              : "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Landmark
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.address?.length
              ? applicantData?.address[0]?.landmark
              : "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Town/City
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}

            {applicantData?.address?.length
              ? applicantData?.address[0]?.city
              : "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            District
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.address?.length
              ? applicantData?.address[0]?.state
              : "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            State/Union Territory
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.address?.length
              ? applicantData?.address[0]?.state
              : "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Pincode
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.address?.length
              ? applicantData?.address[0]?.pincode
              : "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Is current address same as permanent address?
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.address?.length == 1
              ? "Yes"
              : "No" ?? "No Data Available"}
          </Col>
        </Row>
        {applicantData?.address?.length > 1 && (
          <>
            {" "}
            <Row>
              <Col className="subheading" span={10}>
                Flat, House No., Building, Apartment
              </Col>
              <Col className="subheading" span={2}>
                :
              </Col>
              <Col className="text">
                {"  "}
                {applicantData?.address[1]?.house_no
                  ? applicantData?.address[1]?.house_no
                  : "No Data Available"}
              </Col>
            </Row>
            <Row>
              <Col className="subheading" span={10}>
                Area, Street, Sector, Village
              </Col>
              <Col className="subheading" span={2}>
                :
              </Col>
              <Col className="text">
                {"  "}
                {applicantData?.address[1]?.area
                  ? applicantData?.address[1]?.area
                  : "No Data Available"}
              </Col>
            </Row>
            <Row>
              <Col className="subheading" span={10}>
                Landmark
              </Col>
              <Col className="subheading" span={2}>
                :
              </Col>
              <Col className="text">
                {"  "}
                {applicantData?.address[1]?.landmark
                  ? applicantData?.address[1]?.landmark
                  : "No Data Available"}
              </Col>
            </Row>
            <Row>
              <Col className="subheading" span={10}>
                Town/City
              </Col>
              <Col className="subheading" span={2}>
                :
              </Col>
              <Col className="text">
                {"  "}
                {applicantData?.address[1]?.city
                  ? applicantData?.address[1]?.city
                  : "No Data Available"}
              </Col>
            </Row>
            <Row>
              <Col className="subheading" span={10}>
                District
              </Col>
              <Col className="subheading" span={2}>
                :
              </Col>
              <Col className="text">
                {"  "}
                {applicantData?.address[1]?.state
                  ? applicantData?.address[1]?.state
                  : "No Data Available"}
              </Col>
            </Row>
            <Row>
              <Col className="subheading" span={10}>
                State/Union Territory
              </Col>
              <Col className="subheading" span={2}>
                :
              </Col>
              <Col className="text">
                {"  "}
                {applicantData?.address[1]?.state
                  ? applicantData?.address[1]?.state
                  : "No Data Available"}
              </Col>
            </Row>
            <Row>
              <Col className="subheading" span={10}>
                Pincode
              </Col>
              <Col className="subheading" span={2}>
                :
              </Col>
              <Col className="text">
                {"  "}
                {applicantData?.address[1]?.pincode
                  ? applicantData?.address[1]?.pincode
                  : "No Data Available"}
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const EmergencyContactDetails = () => {
    return (
      <>
        <Row>
          <Col className="subheading" span={10}>
            Emergency Contact Name
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.emergency_contact_name ??
              "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Emergency Contact Number
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.emergency_contact_number ??
              "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Blood Group
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.blood_group ??
              "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Please mention your Marital Status
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.marital_status ??
              "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Marriage Date
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.marriage_date ?? "No Data"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Spouse Name (As Per Aadhaar)
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.spouse_name ?? "No Data"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Spouse DOB (As per Aadhaar)
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.spouse_dob ??
              "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Spouse Gender
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.spouse_gender ??
              "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Number of Children
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.emergency_contact[0]?.no_of_children ??
              "No Data Available"}
          </Col>
        </Row>
        {applicantData?.emergency_contact[0]?.child_json?.length &&
          applicantData?.emergency_contact[0]?.child_json.map((each, index) => (
            <>
              <Row>
                <Col className="subheading" span={10}>
                  {` ${
                    index == 0
                      ? "First"
                      : index == 1
                      ? "Second"
                      : index == 2
                      ? "Third"
                      : index == 3
                      ? "Fourth"
                      : ""
                  }`}{" "}
                  Child'S Name (As Per Aadhaar)
                </Col>
                <Col className="subheading" span={2}>
                  :
                </Col>
                <Col className="text">
                  {"  "}
                  {each?.child_name ?? "No Data Available"}
                </Col>
              </Row>
              <Row>
                <Col className="subheading" span={10}>
                  {` ${
                    index == 0
                      ? "First"
                      : index == 1
                      ? "Second"
                      : index == 2
                      ? "Third"
                      : index == 3
                      ? "Fourth"
                      : ""
                  }`}{" "}
                  Child's DOB (As per Aadhaar)
                </Col>
                <Col className="subheading" span={2}>
                  :
                </Col>
                <Col className="text">
                  {"  "}
                  {each?.child_dob ?? "No Data Available"}
                </Col>
              </Row>
              <Row>
                <Col className="subheading" span={10}>
                  {` ${
                    index == 0
                      ? "First"
                      : index == 1
                      ? "Second"
                      : index == 2
                      ? "Third"
                      : index == 3
                      ? "Fourth"
                      : ""
                  }`}{" "}
                  Child's Gender
                </Col>
                <Col className="subheading" span={2}>
                  :
                </Col>
                <Col className="text">
                  {"  "}
                  {each?.child_gender ?? "No Data Available"}
                </Col>
              </Row>
            </>
          ))}

        <Row>
          <Col className="subheading" span={10}>
            Are you aware of your UAN?
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.uan ? "Yes" : "No"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            UAN
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.uan ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Are you aware of your ESIC number?
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.esic ? "Yes" : "No"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            ESIC Number
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.bank_account[0]?.esic == ""
              ? "No Data"
              : applicantData?.bank_account[0]?.esic ?? "No Data Available"}
          </Col>
        </Row>
      </>
    );
  };

  const EducationalInformation = () => {
    return (
      <>
        <Row>
          <Col className="subheading" span={10}>
            Have you completed UG/Degree?
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {isUG ? "Yes" : "No" ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Have you completed PG/MBA?
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {isPG ? "Yes" : "No" ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Do you have any relative in K12/Orchids?
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.is_relative_in_company ? "Yes" : "No"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Name of the Relative
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.relative_name ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Relation with employee
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.relative_relation ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            ERP Code of the Relative
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {applicantData?.relative_erp ?? "No Data Available"}
          </Col>
        </Row>
      </>
    );
  };

  const ExperienceInformation = () => {
    return applicantData?.experience?.map((each) => (
      <>
        <Row>
          <Col className="subheading" span={10}>
            Company Name
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {each?.company_name ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            From Date
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {each?.from_date ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            To Date
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {each?.to_date ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Total Work Experience
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {each?.experience ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Position Held/Designation
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {each?.designation ?? "No Data Available"}
          </Col>
        </Row>
        <Row>
          <Col className="subheading" span={10}>
            Annual CTC & Variable pay
          </Col>
          <Col className="subheading" span={2}>
            :
          </Col>
          <Col className="text">
            {"  "}
            {each?.monthly_ctc ?? "No Data Available"}
          </Col>
        </Row>{" "}
      </>
    ));
  };

  const DocumentInformation = () => {
    return (
      <>
        <Table
          columns={columns}
          dataSource={applicantData?.applicant_documents}
          scroll={{ x: "max-content" }}
          rowKey={(data) => data?.id}
          pagination={false}
          loading={loading}
        />
      </>
    );
  };

  const columns = [
    {
      title: "Document",
      key: "documents",
      render: (data) => <span>{data?.file_name}</span>,
    },
    {
      title: "Upload Date (YYYY-MM-DD)",
      key: "upload_date",
      render: (data) => (
        <span>{dayjs(data?.created_at).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      title: "View",
      key: "view",
      render: (data) => {
        return (
          <a href={data?.files} target="_blank">
            <Tag color="blue" icon={<EyeOutlined />}></Tag>
          </a>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      render: (data) => (
        <>
          {data?.is_approved ? (
            <span style={{ color: "green", fontStyle: "italic" }}>
              Approved
            </span>
          ) : data?.is_rejected ? (
            <Tooltip title={`Rejection Remarks: ${data?.feedback}`}>
              <span style={{ color: "red", fontStyle: "italic" }}>
                Rejected <InfoCircleOutlined />
              </span>
            </Tooltip>
          ) : (
            <Space>
              <Tooltip
                autoAdjustOverflow="false"
                placement="bottom"
                title="APPROVE"
              >
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="green"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDocStatus(data, "approve");
                  }}
                >
                  Approve
                </Tag>
              </Tooltip>
              <Tooltip
                autoAdjustOverflow="false"
                placement="bottom"
                title="REJECT"
              >
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="red"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDocStatus(data, "reject");
                    setDocId(data?.id);
                  }}
                >
                  Reject
                </Tag>
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Card className="card">
        <Row justify="space-between" style={{ marginBottom: "10px" }}>
          <div className="page_title">Applicant Name - Details</div>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              type="primary"
              disabled={!applicantData?.is_nj_form_completed}
              onClick={handleDownloadProfileDetails}
              icon={
                downloadLoading ? (
                  <SyncOutlined spin />
                ) : (
                  <CloudUploadOutlined />
                )
              }
            >
              Download
            </Button>
            <Button
              onClick={() => {
                history.push({
                  pathname: "/mrf_form_applicants",
                  state: {
                    ...prevState,
                  },
                });
              }}
            >
              Go Back
            </Button>
          </div>
        </Row>

        <Card>
          {downloadLoading ? (
            <Spin style={{ display: "flex", justifyContent: "center" }} />
          ) : (
            <Collapse
              items={[
                {
                  key: "1",
                  label: <span className="label-header">Personal Details</span>,
                  children: <PersonalDetails />,
                  className: "segment-container",
                },
                {
                  key: "2",
                  label: (
                    <span className="label-header">Bank Account Details</span>
                  ),
                  children: <BankAccountDetails />,
                  className: "segment-container",
                },
                {
                  key: "3",
                  label: (
                    <span className="label-header">General Information</span>
                  ),
                  children: <GeneralInformation />,
                  className: "segment-container",
                },
                {
                  key: "4",
                  label: (
                    <span className="label-header">Address Information</span>
                  ),
                  children: <AddressInformation />,
                  className: "segment-container",
                },
                {
                  key: "5",
                  label: (
                    <span className="label-header">
                      Emergency Contact Details
                    </span>
                  ),
                  children: <EmergencyContactDetails />,
                  className: "segment-container",
                },
                {
                  key: "6",
                  label: (
                    <span className="label-header">
                      Educational Information
                    </span>
                  ),
                  children: <EducationalInformation />,
                  className: "segment-container",
                },
                {
                  key: "7",
                  label: (
                    <span className="label-header">Experience Information</span>
                  ),
                  children: <ExperienceInformation />,
                  className: "segment-container",
                },
                {
                  key: "8",
                  label: (
                    <span className="label-header">Document Information</span>
                  ),
                  children: <DocumentInformation />,
                  className: "segment-container",
                },
              ]}
            />
          )}
        </Card>
      </Card>

      <Modal
        title="Reason for Rejecting"
        open={feedBackModal}
        onCancel={closeFeedBackModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={closeFeedBackModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" form="feedBackModalForm" htmlType="submit">
                Reject
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="feedBackModalForm"
          form={feedBackModalForm}
          layout="vertical"
          onFinish={handleDocReject}
        >
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Remarks",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  maxLength={1500}
                  showCount
                  placeholder="Enter Remarks"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ViewApplicantProfile;
