export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    applyButtonSection :{
     marginTop:'6px'
    },
    applyButtonSection1 :{
        marginTop:'10px',
        marginLeft:'40%',
        marginBottom:'1%'
    },
    applyButtonModal : {
        marginTop: '6px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    title : {
      float : 'left',
      fontSize : '18px',
    },
    textField: {
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    checkBox : {
        marginTop : '100px',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(1, 2, 1),
      width:'80%'
    },
    paperpaper:{
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(2, 2, 1),
    },
    fab:{
        float:'right'
    },
    para:{
        float:'left',
    },
    editbutton:{
        marginTop:'0px',
        position:'top',
        float:'right',
        padding: theme.spacing(1, 2, 1),
    },
    questionWrap: {
        marginTop: 10,
        marginRight: 30,
        marginBottom: 50,
        marginLeft: 30,
        padding: 0
      },
      questionContainer: {
        padding: 10
      },
    });