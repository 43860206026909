import {
  Card,
  Col,
  Form,
  InputNumber,
  Typography,
  message,
  Button,
  Modal,
  Row,
  Table,
  Tag,
  Space,
  Input,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import urls from "../../url";
import {
  EditOutlined,
  SaveOutlined,
  SearchOutlined,
  CloseOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import moment from "moment";

const EmployeeScoring = () => {
  const [rewardParametersForm] = useForm();
  const [loading, setLoading] = useState();
  const [tableData, setTableData] = useState();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageT, setPageT] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [scoringFormData, setScoringFormData] = useState([]);
  const [roleId] = useState("3");
  const [pageSize] = useState(10);
  const [historyTableData, setHistoryTableData] = useState([]);
  const [empId, setEmpId] = useState();
  const [form] = useForm();
  const [search, setSearch] = useState();
  useEffect(() => {
    fetchTableData({
      page: page,
      role_id: roleId,
      page_size: pageSize,
    });
  }, [page]);

  const fetchRewardParameters = () => {
    setLoading(true);
    axios
      .get(`${urls.rewardParameters}`, {})
      .then((response) => {
        if (response.status === 200 && response?.data?.length > 0) {
          rewardParametersForm.setFieldsValue(response?.data[0]);
          setScoringFormData(response?.data);
          setId(response?.data[0]?.id);
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchTableData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.employeeScoringList}`, {
        params: { ...params },
      })
      .then((response) => {
        if (response.status === 200) {
          let data = response.data?.results;
          let dataCount = response?.data?.count;
          setTableData([...data]);
          setCount(dataCount);
          message.success("Data Fetched Successfully");
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchHistoryTableData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.employeeScoringHistory}`, {
        params: { ...params },
      })
      .then((response) => {
        if (response.status === 200) {
          setHistoryTableData(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRewardsParameters = () => {
    const values = rewardParametersForm.getFieldsValue();
    const formData = new FormData();
    formData.append(
      "profiles_for_shortlisting",
      values?.profiles_for_shortlisting
    );
    formData.append("profiles_shortlisted", values?.profiles_shortlisted);
    formData.append("candidates_interviewed", values?.candidates_interviewed);
    formData.append("offers_released", values?.offers_released);
    formData.append("people_joined", values?.people_joined);

    if (scoringFormData?.length > 0) {
      setLoading(true);
      axios
        .put(`${urls.rewardParameters}${id}/`, formData)
        .then((response) => {
          if (response.status === 200) {
            message.success("Submitted Successfully");
            rewardParametersForm.resetFields();
          }
        })
        .catch((error) => {
          message.error(
            error?.response?.data?.message ?? "Something Went Wrong !"
          );
        })
        .finally(() => {
          setLoading(false);
          handleCloseModal();
        });
    } else {
      setLoading(true);
      axios
        .post(`${urls.rewardParameters}`, formData)
        .then((response) => {
          if (response.status === 200) {
            message.success("Submitted Successfully");
            rewardParametersForm.resetFields();
          }
        })
        .catch((error) => {
          message.error(
            error?.response?.data?.message ?? "Something Went Wrong !"
          );
        })
        .finally(() => {
          setLoading(false);
          handleCloseModal();
        });
    }
  };

  useEffect(() => {
    fetchHistoryTableData({
      page: pageT,
      employee: empId,
      page_size: pageSize,
    });
  }, [pageT]);

  const handleOpenModal = () => {
    fetchRewardParameters();
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    rewardParametersForm.resetFields();
    setModalOpen(false);
  };

  const handleOpenHistoryModal = (data) => {
    setHistoryModalOpen(true);
    setEmpId(data?.user?.id);
    fetchHistoryTableData({
      page: pageT,
      employee: data?.user?.id,
      page_size: pageSize,
    });
  };

  const handleHistoryModalCancel = () => {
    setHistoryModalOpen(false);
    setEmpId("");
    setPageT(1);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (data) => <Typography>{data?.user?.first_name}</Typography>,
    },
    {
      title: "ERP No",
      key: "erp_no",
      render: (data) => <Typography>{data?.erp}</Typography>,
    },
    {
      title: "Contact No",
      key: "contact_no",
      render: (data) => <Typography>{data?.phone_no}</Typography>,
    },
    {
      title: "Location",
      key: "loaction",
      render: (data) => (
        <Typography>{`${data?.branch[0]?.branch_name ?? ""} - ${data?.city[0]
          ?.city_name ?? ""}`}</Typography>
      ),
    },
    {
      title: "History",
      key: "history",
      render: (data) => (
        <Tag
          color="orange"
          onClick={() => handleOpenHistoryModal(data)}
          style={{ cursor: "pointer" }}
        >
          <EditOutlined color="orange" style={{ marginInlineEnd: "5px" }} />
          History
        </Tag>
      ),
    },
    {
      title: "Total Score",
      key: "score",
      align: "center",
      render: (data) => <Typography>{data?.score}</Typography>,
    },
  ];

  const historyTableColumns = [
    {
      title: "Candidate Name",
      key: "candidate_name",
      render: (data) => <Typography>{data?.candidate_name}</Typography>,
    },
    {
      title: "Position Applied",
      key: "erp_no",
      render: (data) => <Typography>{data?.position_applyed}</Typography>,
    },
    {
      title: "Title",
      key: "title",
      render: (data) => <Typography>{data?.title}</Typography>,
    },
    {
      title: "Date",
      key: "date",
      align: "center",
      render: (data) => (
        <Typography>{moment(data?.created_at).format("DD/MM/YYYY")}</Typography>
      ),
    },
    {
      title: "Score",
      key: "score",
      render: (data) => <Typography>{data?.score}</Typography>,
    },
  ];

  useEffect(() => {
    let timer;
    if (search && search.length > 0) {
      timer = setTimeout(() => {
        handleSearch();
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [search]);

  function handleSearch() {
    fetchTableData({
      page: 1,
      role_id: roleId,
      page_size: pageSize,
      search: search,
    });
    setPage(1);
  }
  function handleReportDownload() {
    axios
      .get(`${urls.individualRewardReport}?employee=${empId}`)
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });

        // Create URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "reward_report";

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Cleanup: remove the link and revoke the Blob URL
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      });
  }
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  console.log(
    "includes",
    [1, 4].includes(userData.personal_info?.role_id),
    userData.personal_info?.role_id
  );
  return (
    <>
      <Card>
        <Row justify="space-between">
          <Typography className="page_title">Employee Rewards</Typography>
          {[1, 4].includes(userData.personal_info?.role_id) && (
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={handleOpenModal}
              style={{ marginBottom: "24px" }}
            >
              Reward Parameters
            </Button>
          )}
        </Row>
        <Form
          id="filterForm"
          form={form}
          layout="vertical"
          style={{ marginBottom: "20px" }}
        >
          <Row>
            <Col md={6}>
              <Input
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                suffix={<SearchOutlined />}
                style={{ width: "100%" }}
                placeholder="Search"
                allowClear
              />
            </Col>
          </Row>
        </Form>

        <Table
          columns={columns}
          dataSource={tableData}
          loading={loading}
          scroll={{ x: "max-content" }}
          rowKey={(data) => data?.id}
          pagination={{
            showSizeChanger: false,
            total: count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
        />
      </Card>
      <Modal
        title={"Reward Parameters - Points"}
        open={modalOpen}
        onCancel={handleCloseModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                form="rewardParametersForm"
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="rewardParametersForm"
          form={rewardParametersForm}
          onFinish={handleRewardsParameters}
          layout="vertical"
        >
          <Col justify="center">
            <Col>
              <Form.Item
                name="profiles_for_shortlisting"
                label={
                  <Typography> No profiles given for Shortlisting</Typography>
                }
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Please enter this field"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="profiles_shortlisted"
                label={<Typography> No of Profiles shortlisted</Typography>}
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Please enter this field"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="candidates_interviewed"
                label={<Typography>No of Candidates interviewed</Typography>}
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Please enter this field"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="offers_released"
                label={<Typography>No of Offer Released</Typography>}
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Please enter this field"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="people_joined"
                label={<Typography>No of People joined</Typography>}
                rules={[
                  {
                    required: true,
                    message: "This is a required field",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Please enter this field"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Col>
        </Form>
      </Modal>
      <Modal
        width={800}
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <div className="">Points History</div>
            <Space className="">
              <Button
                onClick={handleReportDownload}
                icon={<FileExcelOutlined />}
              >
                Report
              </Button>
              <CloseOutlined onClick={handleHistoryModalCancel} />
            </Space>
          </Space>
        }
        closeIcon={null}
        open={historyModalOpen}
        onCancel={handleHistoryModalCancel}
        centered
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={handleHistoryModalCancel}>
                Cancel
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Table
          columns={historyTableColumns}
          dataSource={historyTableData?.results}
          loading={loading}
          scroll={{ x: "max-content" }}
          rowKey={(data) => data?.id}
          pagination={{
            showSizeChanger: false,
            total: historyTableData?.count,
            onChange: (page) => {
              setPageT(page);
            },
            current: pageT,
          }}
        />
      </Modal>
    </>
  );
};

export default EmployeeScoring;
