import { rgbToHex } from "@material-ui/core/styles";

export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    gride:{
        marginTop:'0px',
    },
    search: {
      float: 'right',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    Tabletab :{
      marginTop:'10px',
      marginBottom:'20px',
      marginLeft:'1%',
      marginRight:'1%',
      fontSize:'15px',
      fontFormate:'bold',
    },
    TableHeadtab:{
      marginTop:'10px',
      marginLeft:'10%',
      marginRight:'10%',
      cellPadding:"10px",
      cellSpacing:'10px',
      fontFamily:' Helvetica',
      fontSize:'15px',
      backgroundColor: rgbToHex('#7FF548 '), 
    },
    TableHeadtabBottom: {
      marginTop:'10px',
      cellPadding:"10px",
      cellSpacing:'10px',
      fontFamily:' Helvetica',
      fontSize:'15px',
      color: '#FFFFFF',
      backgroundColor: rgbToHex('#ff0000 '), 
    },
    tableHeadtab1 : {
      backgroundColor: rgbToHex('#F1E700 '), 
      fontSize:'18px',
      color:'black',
      width: '100%',
      height: '100%'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabled: {
      maxHeight: 250,
      overflow: 'auto'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(1, 2, 1),
    }, 
    paperr:{
      width:'100%',
    },
    tableRowtab1 :{
      backgroundColor: rgbToHex('#FFFD91'),
      fontSize:'15px',
      color:'black',
      width: '100%',
      height: '100%'
    },
    tableRowtab2 :{
      backgroundColor: rgbToHex('#70C3E5 '),
      fontSize:'15px',
      color:'black',
      width: '100%',
      height: '100%'
    },
    tableHeadtab1 : {
      backgroundColor: rgbToHex('#F1E700 '), 
      fontSize:'18px',
      color:'black',
      width: '100%',
      height: '100%'
    },
    tableHeadtab2 : {
      backgroundColor: rgbToHex('#01A9EE'), 
      fontSize:'18px',
      color:'black',
      width: '100%',
      height: '100%'
    },
  });
  