import {
  Button,
  Card,
  Drawer,
  Empty,
  message,
  Row,
  Spin,
  Statistic,
  Table,
  Tag,
  Tooltip,
  Badge,
} from "antd";
import { InfoCircleOutlined, SyncOutlined } from "@ant-design/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import urls from "../../../../url";
import OfferLetterFormModal from "../../../../utils/components/OfferLetterComponents/offerLetterFormModal";

function OfferLetterDrawer({
  open,
  onClose,
  applicantId,
  position,
  designation,
}) {
  const [loading, setLoading] = useState(true);
  const [applicantData, setApplicantData] = useState(null);
  const [offerMode, setOfferMode] = useState(null);
  const [offerId, setOfferId] = useState(null);
  const [offerFormModal, setOfferFormModal] = useState(false);
  const [isReRelease, setIsReRelease] = useState(false);
  const [isExistingOffer, setIsExistingOffer] = useState(false);
  useEffect(() => {
    if (applicantId) {
      fetchApplicantData({
        applicant: applicantId,
        position_type: position,
        designation,
      });
    }
  }, [applicantId]);
  const fetchApplicantData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.OfferLetterOutBoundRetriveApi}`, {
        params: params,
      })
      .then((response) => {
        if (response?.data?.results?.length > 0) {
          setApplicantData(response?.data?.results[0]);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOpenSendOfferLetterModal = (data, mode) => {
    setOfferMode(mode);
    setOfferFormModal(true);
  };
  const sendToCandidate = (id) => {
    setOfferMode("release");
    setOfferFormModal(true);
    setIsExistingOffer(true);
    setOfferId(id);
  };
  const handleOpenReRelaseOfferLetterModal = (data) => {
    setIsReRelease(true);
    setIsExistingOffer(true);
    setOfferId(data?.offer_letter);
    setOfferMode("resend");
    setOfferFormModal(true);
  };

  const handleResendForApproval = (data) => {
    setIsExistingOffer(true);
    setOfferMode("resend");
    setOfferId(data?.offer_letter);
    setOfferFormModal(true);
  };
  const columns = [
    {
      title: "Offer Release Status",
      key: "offer_status",
      align: "center",
      render: (data) => (
        <span>
          {data?.is_revoked ? (
            <span style={{ color: "red", fontStyle: "italic" }}>Revoked</span>
          ) : data?.is_offer_letter_realesed == true ? (
            <span style={{ color: "green", fontStyle: "italic" }}>
              Released
            </span>
          ) : (
            <span style={{ color: "blue", fontStyle: "italic" }}>
              Not Released
            </span>
          )}
        </span>
      ),
    },
    {
      title: "Manager Approval Status",
      key: "approval_status",
      align: "center",
      render: (data) =>
        data?.is_revoked ? (
          <Tag
            color="processing"
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleOpenReRelaseOfferLetterModal(data);
            }}
          >
            Re-release Offer Letter
          </Tag>
        ) : (
          <span>
            <>
              {data?.budget_data?.budget_approver ? (
                data?.budget_data?.budget_approver_status == "rejected" ? (
                  <div>
                    <div style={{ color: "red" }}>
                      Budget Rejected{" "}
                      <Tooltip
                        title={data?.budget_data?.budget_approver_feedback}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                    <Tag
                      color="error"
                      onClick={() => handleResendForApproval(data)}
                      style={{ cursor: "pointer" }}
                    >
                      Resend for approval
                    </Tag>
                  </div>
                ) : data?.budget_data?.budget_approver_status == "approved" ? (
                  <>
                    <div
                      style={{
                        color: "green",
                        fontStyle: "italic",
                        textTransform: "capitalize",
                      }}
                    >
                      Budget {data?.budget_data?.budget_approver_status}
                    </div>
                    {data?.offer_letter_approval ? (
                      data?.offer_letter_approval == "rejected" ? (
                        <div>
                          <div style={{ color: "red" }}>
                            Offer Rejected{" "}
                            <Tooltip title={data?.reporting_manager_feedback}>
                              <InfoCircleOutlined />
                            </Tooltip>
                          </div>
                          <Tag
                            color="error"
                            onClick={() => handleResendForApproval(data)}
                            style={{ cursor: "pointer" }}
                          >
                            Resend for Offer approval
                          </Tag>
                        </div>
                      ) : data?.offer_letter_approval == "approved" ? (
                        <span
                          style={{
                            color: "green",
                            fontStyle: "italic",
                            textTransform: "capitalize",
                          }}
                        >
                          <div>Offer Letter {data?.offer_letter_approval}</div>
                          <Tag
                            color="processing"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpenReRelaseOfferLetterModal(data);
                            }}
                          >
                            Re-release Offer Letter
                          </Tag>
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "green",
                            fontStyle: "italic",
                            textTransform: "capitalize",
                          }}
                        >
                          Offer Letter {data?.offer_letter_approval}
                        </span>
                      )
                    ) : (
                      <Tag
                        color="processing"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleOpenSendOfferLetterModal(data, "approval")
                        }
                      >
                        Send
                      </Tag>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        color: "green",
                        fontStyle: "italic",
                        textTransform: "capitalize",
                      }}
                    >
                      Budget Approval{" "}
                      {data?.budget_data?.budget_approver_status}
                    </div>
                  </>
                )
              ) : (
                <>
                  {data?.offer_letter_approval ? (
                    data?.offer_letter_approval == "rejected" ? (
                      <div>
                        <div style={{ color: "red" }}>
                          Rejected{" "}
                          <Tooltip title={data?.reporting_manager_feedback}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                        <Tag
                          color="error"
                          onClick={() => handleResendForApproval(data)}
                          style={{ cursor: "pointer" }}
                        >
                          Resend for approval
                        </Tag>
                      </div>
                    ) : data?.offer_letter_approval == "approved" ? (
                      <div
                        style={{
                          color: "green",
                          fontStyle: "italic",
                          textTransform: "capitalize",
                        }}
                      >
                        <div>Offer Letter {data?.offer_letter_approval}</div>
                        <Tag
                          color="processing"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOfferMode("approval");
                            handleOpenReRelaseOfferLetterModal(data);
                          }}
                        >
                          Re-release Offer Letter
                        </Tag>
                      </div>
                    ) : (
                      <span
                        style={{
                          color: "green",
                          fontStyle: "italic",
                          textTransform: "capitalize",
                        }}
                      >
                        Offer Letter {data?.offer_letter_approval}
                      </span>
                    )
                  ) : (
                    <Tag
                      color="processing"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOpenSendOfferLetterModal(data, "approval")
                      }
                    >
                      Send
                    </Tag>
                  )}
                </>
              )}
            </>
          </span>
        ),
    },
    {
      title: "Offer Letter",
      key: "offer_letter",
      align: "center",
      render: (data) => (
        <span>
          {data?.is_revoked ? (
            <span style={{ color: "red", fontStyle: "italic" }}>
              Offer Revoked
            </span>
          ) : data?.offer_letter_approval == "approved" ? (
            data?.is_offer_letter_realesed ? (
              data?.is_offer_letter_accepted ? (
                <span style={{ color: "green", fontStyle: "italic" }}>
                  Accepted
                </span>
              ) : data?.is_offer_letter_rejected ? (
                <span style={{ color: "red", fontStyle: "italic" }}>
                  Rejcted by Applicant
                </span>
              ) : (
                <Tag
                  color="error"
                  onClick={() => sendToCandidate(data?.offer_letter, true)}
                >
                  Resend
                </Tag>
              )
            ) : (
              <Tag
                color="processing"
                onClick={() => {
                  sendToCandidate(data?.offer_letter, true);
                }}
              >
                Send
              </Tag>
            )
          ) : data?.offer_letter_approval == "rejected" ? (
            <span style={{ color: "red", fontStyle: "italic" }}>
              Rejcted by Manager
            </span>
          ) : (
            <i style={{ color: "red", fontSize: "13px" }}>
              Yet To Be Approved By Manager
            </i>
          )}
        </span>
      ),
    },
  ];
  const handleCloseFormModal = () => {
    setOfferFormModal(false);
    setIsReRelease(false);
  };
  return (
    <React.Fragment>
      <Drawer
        width={window.innerWidth > 700 ? 700 : window.innerWidth - 20}
        title={
          <Row justify={"space-between"} align={"middle"}>
            <div>Offer Letter</div>{" "}
            {applicantData?.is_resend ? (
              <Tag color="warning">Reissued Offer</Tag>
            ) : (
              ""
            )}
            <Button
              onClick={() => {
                fetchApplicantData({
                  applicant: applicantId,
                  position_type: position,
                  designation,
                });
              }}
              shape="circle"
              type="primary"
              icon={<SyncOutlined />}
              loading={loading}
            />
          </Row>
        }
        open={open}
        onClose={onClose}
      >
        <Spin spinning={loading}>
          {applicantData ? (
            <>
              <Table
                dataSource={[applicantData]}
                columns={columns}
                size={"small"}
                pagination={false}
                rowKey={(e) => e?.id}
              />
              <Card
                style={{ marginTop: "10px", background: "#e7f2fe" }}
                bordered
              >
                <Statistic
                  title={"Applicant Acceptance Status"}
                  value={
                    applicantData?.is_revoked
                      ? "Offer Revoked"
                      : applicantData?.is_offer_letter_realesed == false
                      ? " Not Released"
                      : applicantData?.is_offer_letter_accepted
                      ? "Accepted"
                      : applicantData?.is_offer_letter_rejected
                      ? "Rejected"
                      : !applicantData?.is_offer_letter_rejected &&
                        !applicantData?.is_offer_letter_accepted
                      ? "Pending"
                      : "-"
                  }
                  valueStyle={{
                    color: applicantData?.is_offer_letter_rejected
                      ? "red"
                      : applicantData?.is_offer_letter_accepted
                      ? "green"
                      : "#347acf",
                  }}
                />
              </Card>
            </>
          ) : (
            <Empty />
          )}
        </Spin>
      </Drawer>

      {offerFormModal && (
        <OfferLetterFormModal
          open={offerFormModal}
          onClose={handleCloseFormModal}
          offerMode={offerMode}
          offerId={offerId}
          candidateDetails={applicantData}
          isReRelease={isReRelease}
          isExistingOffer={isExistingOffer}
          successCallback={() =>
            fetchApplicantData({
              applicant: applicantId,
              position_type: position,
              designation,
            })
          }
        />
      )}
    </React.Fragment>
  );
}

export default OfferLetterDrawer;
