import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./viewSubjects.style";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import urls from "../../../url";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import useFetch from "../../../hoc/useFetch";
import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  TablePagination,
  Modal,
  TableContainer,
} from "@material-ui/core";
import CreateSubject from "../createSubject/createSubject";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import Switch from "@material-ui/core/Switch";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import MediaQuery from "react-responsive";
import Close from "@material-ui/icons/Close"

function ViewSubjects({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [grade, setGrade] = useState("");
  const [chapter, setChapter] = useState("");
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [index, setIndex] = useState("");
  const [pagination, setPagination] = useState(false);
  const [status, setStatus] = useState(false);
  const [searchSubject, setSearchSubject] = useState("");
  const [positionType, setPositionType] = useState("");

  



  const alert = useAlert();
  const {
    data: gradeData,
    isLoading: gradeLoading,
    doFetch: fetchGrade,
  } = useFetch([], { suppressAlert: true });

  const {
    data: getChaptersData,
    isLoading: ViewChapterDataLoading,
    doFetch: fetchgetChapters,
  } = useFetch("", { suppressAlert: true });

  const {
    data: updateChapterData,
    isLoading: updateChapterLoading,
    doFetch: fetchupdateChapters,
  } = useFetch([], { suppressAlert: true });

  useEffect(() => {
    if (positionType) {
      fetchGrade({
        url: `${urls.newGrade}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [fetchGrade, auth,positionType]);
  let loader = null;
  if (gradeLoading || ViewChapterDataLoading || updateChapterLoading) {
    loader = <Loader open />;
  }
  function getChapters() {
    // if (!grade) {
    //   alert.warning("Select Grade");
    //   return;
    // }
    fetchgetChapters({
      url: `${urls.viewSubjectApi}?grade_id=${grade}&page_size=${rowsPerPage ||
        50}&page=${page + 1}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  function editChapter(i, e) {
    setOpen(true);
    setChapter(
      getChaptersData &&
        getChaptersData.results.length &&
        getChaptersData.results[i].subject.subject_name
    );
    setStatus(
      !(
        getChaptersData &&
        getChaptersData.results.length &&
        getChaptersData.results[i].is_delete
      )
    );
    setIndex(e);
  }

  function handleChangePage(event, newPage) {
    setPagination(true);
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }

  function handleChangeRowsPerPage(event) {
    setPagination(true);
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPagination(true);
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPagination(true);
    setPage(lastPage);
  }

  useEffect(() => {
    if (pagination || updateChapterData) {
      getChapters();
    }
  }, [page, rowsPerPage, updateChapterData]);

  function updateChapter() {
    setOpen(false);
    setPage(0);
    if (!grade) {
      alert.warning("select Grade");
      return;
    } else if (!chapter) {
      alert.warning("Enter Subject");
      return;
    }
    const UpdateData = {
      grade_id: grade,
      subject_name: chapter,
      is_delete: status === true ? false : true,
    };
    fetchupdateChapters({
      url: `${urls.updateSubjectApi}${index}/retrieve_update_subjects/`,
      method: "PUT",
      body: UpdateData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        "Content-Type": "application/json",
      },
    });
  }
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (searchSubject) {
      fetchgetChapters({
        url: `${
          urls.viewSubjectApi
        }?grade_id=${grade}&page_size=${rowsPerPage || 10}&page=${page +
          1}&search=${searchSubject}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [searchSubject]);
  const searchSubjectFunction = (e) => {
    setSearchSubject(e.target.value);
  };


const positionTypeList = [
  {
    value: "1",
    label: "Academic",
  },
  {
    value: "2",
    label: "Non Academic",
  },
  {
    value: "3",
    label: "IT Recruitment",
  },
  {
    value: "4",
    label: "Marketing and Communications",
  },
  {
    value: "5",
    label: "Sales and Business Development",
  },
];
  return (
    <React.Fragment>
      <MediaQuery minWidth={451}>
      <div>
        {" "}
        <b style={{ fontSize: "36px" }}>Subjects/Topics</b>{" "}
        <b style={{ float: "right", fontSize: "20px" }}>
          {getChaptersData && (
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={searchSubjectFunction}
            />
          )}{" "}
          <CreateSubject />
        </b>{" "}
      </div>
      <Divider className={classes.divider} />
      </MediaQuery>
      <MediaQuery maxWidth={450}>
      <div>
        {" "}
        <b style={{ fontSize: "24px" }}>Subjects</b>{" "} &nbsp; &nbsp; &nbsp;
        <CreateSubject /><br/><br/>
        <b >
          {getChaptersData && (
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="i'm looking for..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              // style={{width:"130px",height:"10px"}}
              onChange={searchSubjectFunction}
            />
          )}{" "}
        </b>{" "}
      </div>
      <Divider className={classes.divider} />
      </MediaQuery>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
        <Grid item md={3} xs={12} >
            {/* <Typography>
              {" "}
              Select Position Type <b style={{ color: "red" }}>*</b>
            </Typography> */}
            <TextField
              select
              required
              fullWidth
              label="Position Type"
              margin="dense"
              value={positionType || ""}
              onChange={(e, id) => {
                setPositionType(e.target.value);
              }}
              variant="outlined"
            >
              {positionTypeList.map((option, index) => (
                <MenuItem key={index} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label={positionType=="Academic" ?"Grade":"Level"}
              // className={classes.textField}
              margin="dense"
              // required
              onChange={(e) => setGrade(e.target.value)}
              select
              value={grade || ""}
              variant="outlined"
              fullWidth
              className="textList"
            >
              {gradeData.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.grade_name}
                    >
                      {data.grade_name ? data.grade_name : ""}
                    </MenuItem>
                  ))
                }
            </TextField>
          </Grid>
          <Grid item md={2} xs={12}>
            {grade && (
              <Button
                className={classes.applyButtonModal}
                color="primary"
                fullWidth
                variant="contained"
                size="large"
                onClick={() => getChapters(setPage(0))}
              >
                Get Subject
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Divider className={classes.divider} />
      {getChaptersData && getChaptersData.results.length === 0 ? (
        <h1
          align="center"
          style={{ marginTop: "10%", textAlign: "center", color: "blue" }}
        >
          Records Not Found
        </h1>
      ) : (
        ""
      )}
      {getChaptersData && getChaptersData.results.length !== 0 && (
        <Paper>
          <React.Fragment>
            <TableContainer className="role">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>S.No.</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Subject Name</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Status</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Update</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getChaptersData &&
                  getChaptersData.results.map((data, i) => (
                    <TableRow key={data.id}>
                      <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        {data.subject.subject_name}
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        {data.is_delete ? (
                          <CancelIcon style={{ color: "red" }} />
                        ) : (
                          <CheckCircleRoundedIcon style={{ color: "green" }} />
                        )}
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <Button
                          className={classes.addButton}
                          color="primary"
                          size="small"
                          variant="contained"
                          onClick={() => editChapter(i, data.id)}
                        >
                          Update
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() =>
                    `Page ${page + 1} of ${+getChaptersData.total_pages}`
                  }
                  rowsPerPageOptions={[50, 75, 100]}
                  count={+getChaptersData.count}
                  rowsPerPage={rowsPerPage || 50}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Rows per page" },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: "13px" }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      lastPageChange(getChaptersData.total_pages - 1)
                    }
                    disabled={page === +getChaptersData.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
            </TableContainer>
          </React.Fragment>
        </Paper>
      )}

      <React.Fragment>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          // onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{width:"80%",marginLeft:"10%"}}
        >
          <Fade in={open}>
            <div className={classes.paperr}>
              <React.Fragment>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h4">
                    <strong> Update Subject Name</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
                {/* <Typography  className="headings" variant="h4"><strong>Update Subject Name</strong></Typography> */}
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Subject Name"
                      margin="dense"
                      fullWidth
                      value={chapter || ""}
                      onChange={(e) => setChapter(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography>Set Subject as Active/InActive :</Typography>
                    In Active
                    <Switch
                      checked={status || false}
                      value={status || false}
                      onChange={(e) => setStatus(e.target.checked)}
                      color="primary"
                    />
                    Active
                  </Grid>
                  <Grid container align="center" justify="center" >
                  <Grid item md={4} xs={12}>
                    <Button
                      // className={classes.applyButtonModal1}
                      color="primary"
                      variant="contained"
                      fullWidth
                      size="small"
                      onClick={() => updateChapter()}
                    >
                      Update
                    </Button>
                  </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </React.Fragment>
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
      {loader}
    </React.Fragment>
  );
}
ViewSubjects.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ViewSubjects);
