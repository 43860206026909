import React, { useState } from 'react'
import Typography from "@material-ui/core/Typography";

const PrincipalDashboard = () => {
    const [profile] = useState(JSON.parse(localStorage.getItem('user')));

    return (
        <div>
            <Typography>
                Welcome to <strong style={{ fontSize: '40px', color: 'rgb(136 96 146 / 75%)' }}>Principal Dashboard</strong>({profile?.academic_profile?.name})
            </Typography>

        </div>
    )
}

export default PrincipalDashboard;