import React, { useState, useEffect } from "react";
import styles from "./employeeList.style";
import PropTypes from "prop-types";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";

import {
  Form,
  Input,
  Col,
  Row,
  Pagination,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Typography as AntdTypography,
  Card,
  message,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Table,
  TableCell,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Checkbox,
  Modal,
  TableContainer,
  InputBase,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import { Close } from "@material-ui/icons";

const EmployeeList = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [role, setRole] = useState("");
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [Id, setId] = React.useState(null);

  const [userName, setUserName] = useState("");
  const [erp, setErp] = useState("");
  const [phoneNo, setPhoneNo] = useState();
  const [isActive, setIsactive] = useState(false);
  const [rolee, setRolee] = useState("");
  const [branch, setBranch] = useState();
  const [userCitys, setUserCitys] = useState([]);
  const [applicentName, setApplicentName] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [search, setSearch] = useState("");

  const [form] = Form.useForm();

  const handleSearch = () => {
    if (role) {
      listEmpDetails({
        url: `${
          urls.employeeList
        }?role_id=${role}&search=${search}&page=${page}&page_size=${rowsPerPage ||
          50}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      listEmpDetails({
        url: `${
          urls.employeeList
        }?search=${search}&page=${page}&page_size=${rowsPerPage || 50}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  };

  const {
    data: createEmpResponse,
    // isLoading: responseLoading,
    doFetch: createEmpDetails,
  } = useFetch("", { suppressAlert: false });

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setSelectedRoleId(null);
  };

  const editFunction = (data) => {
    setVisible(true);
    console.log(data, "data");
    form.setFieldsValue({
      phone_no: data?.phone_no,
      erp: data?.erp,
      city: data?.city?.map((cityObj) => cityObj.id) || [], // Extract id values from city objects
      branch: data?.branch?.map((branchObj) => branchObj.id) || [], // Extract id values from branch objects
      name: data?.name,
      role_id: data?.role?.id,
      email: data?.user?.email,
    });
    setIsactive(data?.is_active);
    setSelectedRoleId(data?.id);
  };

  const createEmployeeFunction = (formValue) => {
    const formData = new FormData();

    Object.entries(formValue).forEach(([key, value]) => {
      formData.append(key, value);
    });

    console.log(formValue, "formvalue");
    if (!selectedRoleId) {
      createEmpDetails({
        url: urls.employeeRegistration,
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      formData.append("is_active", `${isActive}`);
      fectchUpdataData({
        url: `${urls.updateEmpDetails}${selectedRoleId}/retrieve_update_employee_details/`,
        body: { ...formValue, is_active: isActive },
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
    onClose();
  };

  const alert = useAlert();

  const {
    data: roleData,
    isLoading: roleLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });

  const {
    data: applicantsList,
    isLoading: responseLoading,
    doFetch: listEmpDetails,
  } = useFetch([], { suppressAlert: true });

  const {
    data: updataData,
    isLoading: UpdateDataLoading,
    doFetch: fectchUpdataData,
  } = useFetch(null);

  const { data: cityList, doFetch: fetchCityList } = useFetch([], {
    suppressAlert: true,
  });

  const { data: branchList, doFetch: fetchBranchList } = useFetch([], {
    suppressAlert: true,
  });
  function handleMobileNO(e) {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 10
    ) {
      setPhoneNo(e.target.value);
    }
  }
  function handleErpNO(e) {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 11
    ) {
      setErp(e.target.value);
    }
  }

  useEffect(() => {
    if (auth) {
      fetchRole({
        url: urls.createEmpRole,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
      fetchCityList({
        url: urls.cityList,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
      fetchBranchList({
        url: urls.branch,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [fetchRole, fetchCityList, fetchBranchList, auth]);

  let loader = null;
  if (roleLoading || responseLoading || UpdateDataLoading) {
    loader = <Loader open />;
  }

  const handleGetData = () => {
    if (!role) {
      alert.warning("select Role");
      return;
    }
    listEmpDetails({
      url: `${
        urls.employeeList
      }?role_id=${role}&page=${page}&page_size=${rowsPerPage || 50}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  };

  useEffect(() => {
    if (applicantsList) {
      listEmpDetails({
        url: `${
          urls.employeeList
        }?role_id=${role}&page=${page}&page_size=${rowsPerPage || 50}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [page, rowsPerPage, auth]);

  useEffect(() => {
    if (updataData) {
      setOpen(false);
      handleGetData();
    }
  }, [updataData]);

  const updateRole = () => {
    if (
      !rolee ||
      !phoneNo ||
      !userName ||
      !erp ||
      !userCitys.length ||
      !branch
    ) {
      alert.warning("fill all the Fields");
      return;
    }

    const updatedata = {
      role_id: rolee,
      phone_no: phoneNo,
      name: userName,
      erp: erp,
      city: userCitys,
      branch: branch,
      is_active: isActive ? isActive : false,
    };
    console.log(1);
    fectchUpdataData({
      url: `${
        urls.updateEmpDetails
      }${Id}/retrieve_update_employee_details/?is_active=${
        isActive ? isActive : false
      }`,
      body: updatedata,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }
  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  const handleClose = () => {
    setOpen(false);
    setId(null);
  };

  const updateHandler = (ID, i) => {
    setId(ID);
    setErp(
      (applicantsList &&
        applicantsList.results[i] &&
        applicantsList.results[i].erp) ||
        ""
    );
    setIsactive(
      (applicantsList &&
        applicantsList.results[i] &&
        applicantsList.results[i].is_active) ||
        ""
    );
    setPhoneNo(
      (applicantsList &&
        applicantsList.results[i] &&
        applicantsList.results[i].phone_no) ||
        ""
    );
    setRolee(
      (applicantsList &&
        applicantsList.results[i] &&
        applicantsList.results[i].role.id) ||
        ""
    );
    setUserName(
      (applicantsList &&
        applicantsList.results[i] &&
        applicantsList.results[i].name) ||
        ""
    );

    setUserCitys(
      (applicantsList &&
        applicantsList.results[i] &&
        applicantsList.results[i].city.map((item) => {
          return item.id;
        })) ||
        ""
    );
    setBranch(
      (applicantsList &&
        applicantsList.results[i] &&
        applicantsList.results[i].branch.map((item) => {
          return item.id;
        })) ||
        ""
    );

    setOpen(true);
  };
  const fetchSearch = (e) => {
    setPage(0);
    setRowsPerPage(50);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicentName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };
  function functionSearch(data) {
    // fetchApplicent({
    //   url: `${urls.searchApplicentRecruiter}?page=${page +
    //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   }
    // });
  }

  // function profileTable() {
  //   let applicantTable = null;
  //   if (
  //     applicantsList &&
  //     applicantsList.results &&
  //     applicantsList.results.length === 0
  //   ) {
  //     applicantTable = (
  //       <React.Fragment>
  //         <Typography
  //           style={{ textAlign: "center", color: "blue", marginTop: "100px" }}
  //           variant="h4"
  //         >
  //           Records Not Found
  //         </Typography>
  //       </React.Fragment>
  //     );
  //   }
  //   if (
  //     applicantsList &&
  //     applicantsList.results &&
  //     applicantsList.results.length
  //   ) {
  //     applicantTable = (
  //       <React.Fragment>
  //         <Paper className={classes.paper} style={{ marginTop: "10px" }}>
  //           <TableContainer className="role">
  //             <Table>
  //               <TableHead>
  //                 <TableRow>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>S.No.</strong>
  //                   </TableCell>
  //                   {/* <TableCell align="center" style={{ border: "1px solid black"}}>User Name</TableCell> */}
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>Name</strong>
  //                   </TableCell>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>Erp No.</strong>
  //                   </TableCell>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>Phone No.</strong>
  //                   </TableCell>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong style={{ paddingLeft: "14px" }}>City</strong>
  //                   </TableCell>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong style={{ paddingLeft: "14px" }}>Branch</strong>
  //                   </TableCell>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>Active</strong>
  //                   </TableCell>
  //                   <TableCell
  //                     align="center"
  //                     style={{ border: "1px solid black" }}
  //                   >
  //                     <strong>Update</strong>
  //                   </TableCell>
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 {applicantsList.results.slice(0).map((details, i) => (
  //                   <TableRow key={details.id}>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {i + 1}
  //                     </TableCell>
  //                     {/* <TableCell align="center" style={{ border: "1px solid black"}}>{details.user.username}</TableCell> */}
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {details.name}
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {details.erp}
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {details.phone_no}
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {details.city.map((data) => (
  //                         <MenuItem
  //                           style={{ cursor: "text" }}
  //                           value={data.id}
  //                           key={data.id}
  //                           name={data.city_name}
  //                         >
  //                           {data.city_name ? data.city_name : ""}
  //                         </MenuItem>
  //                       ))}
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {details.branch.map((data) => (
  //                         <MenuItem
  //                           style={{ cursor: "text" }}
  //                           value={data.id}
  //                           key={data.id}
  //                           name={data.branch_name}
  //                         >
  //                           {data.branch_name ? data.branch_name : ""}
  //                         </MenuItem>
  //                       ))}
  //                     </TableCell>

  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       {details.is_active ? (
  //                         <CheckCircleRoundedIcon style={{ color: "green" }} />
  //                       ) : (
  //                         <CancelIcon style={{ color: "red" }} />
  //                       )}
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       style={{ border: "1px solid black" }}
  //                     >
  //                       <Button
  //                         className={classes.addButton}
  //                         size="small"
  //                         variant="contained"
  //                         margin="dense"
  //                         color="primary"
  //                         onClick={() => updateHandler(details.id, i)}
  //                       >
  //                         Update
  //                       </Button>
  //                     </TableCell>
  //                   </TableRow>
  //                 ))}
  //               </TableBody>
  //             </Table>
  //             <TableFooter>
  //               <TableRow>
  //                 <TablePagination
  //                   colSpan={5}
  //                   labelDisplayedRows={() =>
  //                     `Page ${page} of ${+applicantsList.total_pages}`
  //                   }
  //                   rowsPerPageOptions={[50, 75, 100]}
  //                   rowsPerPage={rowsPerPage || 50}
  //                   count={+applicantsList.count}
  //                   page={page}
  //                   SelectProps={{
  //                     inputProps: { "aria-label": "Rows per page" },
  //                   }}
  //                   onChangePage={handleChangePage}
  //                   onChangeRowsPerPage={handleChangeRowsPerPage}
  //                   TablePagi
  //                 />
  //                 <TableCell style={{ marginTop: "13px" }}>
  //                   <IconButton
  //                     onClick={firstPageChange}
  //                     disabled={page === 0 || page === 1}
  //                   >
  //                     <FirstPageIcon />
  //                   </IconButton>
  //                   <IconButton
  //                     onClick={() =>
  //                       lastPageChange(applicantsList.total_pages - 1)
  //                     }
  //                     disabled={page === +applicantsList.total_pages - 1}
  //                   >
  //                     <LastPageIcon />
  //                   </IconButton>
  //                 </TableCell>
  //               </TableRow>
  //             </TableFooter>
  //           </TableContainer>
  //         </Paper>
  //       </React.Fragment>
  //     );
  //   }
  //   return applicantTable;
  // }

  //employeelist
  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "5",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Name</strong>,
      dataIndex: "name", // Update with your actual dataIndex
      key: "name", // Update with your actual key
      align: "center",
      width: "15%",
    },
    {
      title: <strong>Erp No.</strong>,
      dataIndex: "erp", // Update with your actual dataIndex
      key: "erp", // Update with your actual key
      align: "center",
      width: "10%",
    },
    {
      title: <strong>Phone No.</strong>,
      dataIndex: "phone_no", // Update with your actual dataIndex
      key: "phone_no", // Update with your actual key
      align: "center",
      width: "10%",
    },
    {
      title: <strong>City</strong>,
      dataIndex: "city", // Update with your actual dataIndex
      key: "city", // Update with your actual key
      align: "center",
      width: "25%",
      render: (text, record) => (
        // Assuming details.city is an array of objects with a city_name property
        <span>
          {record.city.map((data) => (
            <AntdTag style={{ marginBottom: "5px" }} key={data.id}>
              {data.city_name}
            </AntdTag>
          ))}
        </span>
      ),
    },
    {
      title: <strong>Branch</strong>,
      dataIndex: "branch", // Update with your actual dataIndex
      key: "branch", // Update with your actual key
      align: "center",
      width: "25%",
      render: (text, record) => (
        // Assuming details.branch is an array of objects with a branch_name property
        // <AntdSpace size="">
        <span>
          {record.branch.map((data) => (
            <AntdTag key={data.id}>{data.branch_name}</AntdTag>
          ))}
        </span>
        // </AntdSpace>
      ),
    },

    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      width: "5%",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
    {
      title: "Status",
      key: "is_active",
      width: "5%",
      render: (data) => (
        <AntdSwitch
          onChange={() => {
            console.log(data, "data");

            const updatedata = {
              phone_no: data?.phone_no,
              erp: data?.erp,
              city: data?.city?.map((cityObj) => cityObj.id) || [], // Extract id values from city objects
              branch: data?.branch?.map((branchObj) => branchObj.id) || [], // Extract id values from branch objects
              name: data?.name,
              role_id: data?.role?.id,
              email: data?.user?.email,
            };

            fectchUpdataData({
              url: `${urls.updateEmpDetails}${data?.id}/retrieve_update_employee_details/`,
              body: {
                ...updatedata,
                is_active: !data?.is_active,
              },
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth.personal_info.token}`,
              },
            });
          }}
          checked={data?.is_active}
        />
      ),
    },
  ];

  let modal = null;
  if (roleData) {
    modal = (
      <React.Fragment>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          // onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{ width: "80%", marginLeft: "10%" }}
        >
          <Fade in={open}>
            <Paper className={classes.paperr}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10, justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h4">
                    <strong>Update Employee</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <TextField
                    label="Name"
                    className={classes.textField}
                    margin="dense"
                    required
                    value={userName || ""}
                    onChange={(e) => setUserName(e.target.value)}
                    variant="outlined"
                  ></TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    label="Phone Number"
                    type="text"
                    className={classes.textField}
                    margin="dense"
                    // helperText="Enter 10 Digits Number"
                    required
                    onChange={(e) => handleMobileNO(e)}
                    value={phoneNo || ""}
                    variant="outlined"
                  ></TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    label="Erp Number "
                    type="text"
                    className={classes.textField}
                    margin="dense"
                    required
                    onChange={(e) => handleErpNO(e)}
                    value={erp || ""}
                    variant="outlined"
                  ></TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    label="Role "
                    className={classes.textField}
                    margin="dense"
                    required
                    onChange={(e) => setRolee(e.target.value)}
                    select
                    value={rolee || ""}
                    variant="outlined"
                  >
                    {roleData ? (
                      roleData.map((data) => (
                        <MenuItem
                          value={data.id}
                          key={data.id}
                          name={data.role_name}
                        >
                          {data.role_name ? data.role_name : ""}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="0" key="0" name="None">
                        None
                      </MenuItem>
                    )}
                  </TextField>
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    label="City "
                    className={classes.textField}
                    margin="dense"
                    required
                    select
                    onChange={(e) => setUserCitys(e.target.value)}
                    SelectProps={{ multiple: true }}
                    value={userCitys || ""}
                    variant="outlined"
                  >
                    {cityList &&
                      cityList.map((data) => (
                        <MenuItem
                          value={data.id}
                          key={data.id}
                          name={data.city_name}
                        >
                          {data.city_name ? data.city_name : ""}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12} className={classes.gridOne}>
                  <TextField
                    id="Branch-name"
                    label="Branch"
                    margin="dense"
                    required
                    fullWidth
                    select
                    SelectProps={{ multiple: true }}
                    value={branch || ""}
                    onChange={(e) => setBranch(e.target.value)}
                    variant="outlined"
                  >
                    {branchList &&
                      branchList.map((data) => (
                        <MenuItem
                          value={data.id}
                          key={data.id}
                          name={data.branch_name}
                        >
                          {data.branch_name ? data.branch_name : ""}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                  Active
                  <Checkbox
                    checked={isActive}
                    value={isActive || ""}
                    onChange={(e) => setIsactive(e.target.checked)}
                    inputProps={{
                      "aria-label": "primary checkbox",
                    }}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    margin="dense"
                    fullWidth
                    className={classes.button}
                    style={{ marginTop: "10px" }}
                    onClick={updateRole}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </Paper>
          </Fade>
        </Modal>
      </React.Fragment>
    );
  }
  const [fetchingEMPData, setFetchingEMPData] = useState();
  useEffect(() => {
    if ((page || rowsPerPage) && role) {
      listEmpDetails({
        url: `${urls.employeeList}?role_id=${role}&search=${fetchingEMPData}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [fetchingEMPData]);
  const fetchEmployeeData = (e) => {
    setFetchingEMPData(e.target.value);
  };

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>Employee</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add Employee
          </AntdButton>
        </div>
      </div>

      <Card style={{ marginBottom: "10px" }}>
        <Row gutter={18}>
          <Col>
            <AntdSelect
              style={{ width: "300px", marginLeft: "5px" }}
              label="Role"
              onChange={(e) => setRole(e)}
              value={role || "Select Role"}
              showSearch
              allowClear
              filterOption={(input, options) => {
                return (options?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {roleData.map((data) => (
                <AntdSelect.Option
                  value={data.id}
                  key={data.id}
                  name={data.role_name}
                >
                  {data.role_name ? data.role_name : ""}
                </AntdSelect.Option>
              ))}
            </AntdSelect>
            <AntdButton
              // style={{ width: "100%" }}
              onClick={() => handleGetData()}
              type="primary"
              disabled={role ? false : true}
            >
              Get
            </AntdButton>
          </Col>
          <Col span={2}></Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Input
              style={{ width: "200px", marginRight: "10px" }}
              value={search}
              placeholder="search..."
              onChange={(e) => setSearch(e.target.value)}
            />
            <AntdButton type="primary" onClick={() => handleSearch()}>
              Search
            </AntdButton>
          </Col>
        </Row>
      </Card>

      <AntdTable
        columns={columns}
        dataSource={applicantsList.results}
        pagination={{
          current: page,
          total: applicantsList.count,
          pageSize: rowsPerPage,
          onChange: (current, pageSize) => {
            setPage(current);
            setRowsPerPage(pageSize);
          },
          pageSizeOptions: [50, 75, 100],
        }}
        scroll={{ x: "max-content" }}
        loading={roleLoading || responseLoading || UpdateDataLoading}
      />

      <AntdDrawer
        title="Add Employee"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          setSelectedRoleId(null);
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="employee" type="primary" htmlType="submit">
              Add
            </AntdButton>
          </div>
        }
      >
        <Form
          onFinish={createEmployeeFunction}
          id="employee"
          form={form}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please Enter Name" }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please Enter Email" },
                  {
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input placeholder="Email" type="email" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="phone_no"
                label="Phone Number"
                rules={[
                  { required: true, message: "Please Enter Phone Number" },
                  {
                    pattern: /^[0-9]+$/g,
                    message: "Please enter a valid 10-digit Phone Number",
                  },
                ]}
              >
                <Input placeholder="phone number" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="erp"
                label="Erp"
                rules={[
                  { required: true, message: "Please Enter ERP" },
                  {
                    pattern: /^[0-9]+$/g,
                    message: "Please enter a valid ERP",
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="city"
                label="City"
                rules={[{ required: true, message: "Please Select City" }]}
              >
                <AntdSelect mode="multiple">
                  {cityList &&
                    cityList.map((data) => (
                      <AntdSelect.Option key={data.id} value={data.id}>
                        {data.city_name}
                      </AntdSelect.Option>
                    ))}
                </AntdSelect>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="branch"
                label="Branch"
                rules={[{ required: true, message: "Please Select Branch" }]}
              >
                <AntdSelect mode="multiple">
                  {branchList &&
                    branchList.map((data) => (
                      <AntdSelect.Option key={data.id} value={data.id}>
                        {data.branch_name}
                      </AntdSelect.Option>
                    ))}
                </AntdSelect>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="role_id"
                label="Role"
                rules={[{ required: true, message: "Please Select a Role" }]}
              >
                <AntdSelect
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {roleData ? (
                    roleData.map((data) => (
                      <AntdSelect.Option key={data.id} value={data.id}>
                        {data.role_name}
                      </AntdSelect.Option>
                    ))
                  ) : (
                    <AntdSelect.Option key="0" value="0">
                      None
                    </AntdSelect.Option>
                  )}
                </AntdSelect>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>
    </React.Fragment>
  );
};
EmployeeList.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(EmployeeList);
