import React, { useState, useEffect } from 'react';
import {
  Typography,
  withStyles,
  Paper,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer
} from '@material-ui/core';
import { useAlert } from "../../../../hoc/alert/alert";
import PropTypes from 'prop-types';
import styles from './otherDetails.style';
import MediaQuery from "react-responsive";
import Loader from '../../../../hoc/loader'
import urls from '../../../../url'
import useFetch from '../../../../hoc/useFetch'

const OtherDetails = ({ classes }) => {

  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [skillName, setSkillName] = useState('');
  const [skillLevel, setSkillLevel] = useState('');
  const [skillAddesList, setSkillAddedList] = useState([]);

  const [achivements, setAchivements] = useState('');
  const [aboutYourSelf, setAboutYourSelf] = useState('');

  const [courseName, setCourseName] = useState('');
  const [instituteName, setInstituteName] = useState('');
  const [joiningDate, setJoiningDate] = useState('');
  const [endingDate, setEndingDate] = useState('');
  const [validityDate, setValidtydate] = useState('');
  const [CertificationList, setCertificationList] = useState([]);

  const [languageName, setLanguageName] = useState('');
  const [languageLevel, setLanguageLevel] = useState([]);
  const [languageList, setLanguageList] = useState([]);

  const [loading, setloading] = useState(false);
  const [otherDetailsData, setOtherDetailsData] = useState('');


  const alert = useAlert();

  const {
    // data: submitOtherDetailsRes,
    isLoading: submittingOtherDetails,
    doFetch: fetchOtherDetails
  } = useFetch(null);


  useEffect(() => {
    if (name) {
      setloading(true);
      fetch(`${urls.submitBasicProfileApi}?type=4`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          setloading(false);
          alert.success('Successfully Recived');
          return res.json();
        }
        if (res.status !== 200) {
          setloading(false);
          alert.error('something went wrong please try again');
          return res.json();
        }
      }).then((data) => {
        setOtherDetailsData(data);
      })
    }
  }, [alert, name])

  useEffect(() => {
    if (otherDetailsData) {
      setSkillAddedList(otherDetailsData.skills || [])
      setCertificationList(otherDetailsData.certifications || [])
      setLanguageList(otherDetailsData.languages || [])
      setAchivements(otherDetailsData.achievements || [])
      setAboutYourSelf(otherDetailsData.about_yourself || [])
    }
  }, [otherDetailsData])

  const languageLevelList = [
    { id: '1', name: 'Speak' },
    { id: '2', name: 'Understand' },
    { id: '3', name: 'Write' },
  ]

  const skillLevelList = [
    { id: '1', name: 'Beignner' },
    { id: '2', name: 'Intermediate' },
    { id: '3', name: 'Advanced' },
    { id: '4', name: 'Master' },
  ]

  function addSkillFunction() {
    if (!skillName || !skillLevel) {
      alert.warning('Enter skillName  and skill Level');
      return;
    }
    let array = skillAddesList;
    array.push({ 'skill': skillName, 'level': skillLevel })
    setSkillAddedList(array);
    setSkillName('');
    setSkillLevel('');
  }

  function removeSkillFromList(index) {
    setSkillAddedList((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  function addCertificationList() {
    if (!courseName || !instituteName || !joiningDate || !endingDate || !validityDate) {
      alert.warning('Enter All fields');
      return;
    }
    let arrayee = CertificationList;
    arrayee.push({
      'course': courseName,
      'instuite_name': instituteName,
      'joining_date': joiningDate,
      'passout_date': endingDate,
      'validity_date': validityDate
    })
    setCertificationList(arrayee);
    setCourseName('');
    setInstituteName('');
    setJoiningDate('');
    setEndingDate('');
    setValidtydate('');
  }
  function removeCertification(index) {
    setCertificationList((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  function addLanguageList() {
    if (languageLevel.length === 0 || !languageName) {
      alert.warning('Enter language Name  and Language Level');
      return;
    }
    let oreeee = languageList;
    oreeee.push({ 'language_name': languageName, 'level': languageLevel })
    setLanguageList(oreeee);
    setLanguageName('');
    setLanguageLevel([]);
  }
  function removeLanguageFromList(index) {
    setLanguageList((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  let loader = null;
  if (submittingOtherDetails || loading) {
    loader = <Loader open />;
  }

  function submitOtherProfileData() {
    if (skillAddesList.length === 0 || CertificationList.length === 0 || languageList.length === 0) {
      alert.warning('Enter all fields');
      return;
    }
    const data = {
      "skills": skillAddesList,
      "certifications": CertificationList,
      "languages": languageList,
      "achievements": achivements,
      "about_yourself": aboutYourSelf,
    }
    const formdata = {
      'others_details': data
    }
    fetchOtherDetails({
      url: `${urls.submitBasicProfileApi}?type=4`,
      body: formdata,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Typography variant="h5">Skills</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              label="Skills"
              variant="outlined"
              margin="dense"
              fullWidth
              className="textList"
              required
              value={skillName || ''}
              onChange={(e) => setSkillName(e.target.value)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              margin="dense"
              label="Level"
              fullWidth
              className="textList"
              select
              required
              value={skillLevel || ''}
              onChange={(e) => { setSkillLevel(e.target.value) }}
              variant="outlined"
            >
              {skillLevelList && skillLevelList.map(data => (
                <MenuItem
                  value={data.name}
                  key={data.id}
                  name={data.name}
                >
                  {data.name ? data.name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <Button variant="outlined" color="primary" style={{ marginTop: '10px' }} onClick={() => addSkillFunction()}>Add More +</Button>
          </Grid>
          <Grid item md={12} xs={12}>
            {skillAddesList && skillAddesList.length === 0 && <Typography variant="h5" style={{ color: "blue", textAlign: 'center' }}>Please Add Your Skills</Typography>}
            {skillAddesList && skillAddesList.length !== 0 &&
              <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell float="left">
                        <Typography>S.No</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Skill Name</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Skill Level</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Remove</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {skillAddesList && skillAddesList.length !== 0 && skillAddesList.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell float="left">
                          <Typography>{index + 1}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>{data.skill}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>{data.level}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Button variant="contained" color="primary" onClick={() => removeSkillFromList(index)}>Remove</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextField
              label="Achievements"
              variant="outlined"
              margin="dense"
              fullWidth
              className="tableList"
              row="4"
              multiline
              value={achivements || ''}
              onChange={(e) => setAchivements(e.target.value)}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              label="About Your Self"
              variant="outlined"
              margin="dense"
              fullWidth
              className="tableList"
              row="4"
              multiline
              value={aboutYourSelf || ''}
              onChange={(e) => setAboutYourSelf(e.target.value)}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography variant="h5">Certification</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item md={2} xs={12}>
            <TextField
              label="Course"
              variant="outlined"
              margin="dense"
              fullWidth
              className="tableList"
              required
              row="4"
              multiline
              value={courseName || ''}
              onChange={(e) => setCourseName(e.target.value)}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              label="Institution Name"
              variant="outlined"
              margin="dense"
              fullWidth
              className="tableList"
              required
              multiline
              value={instituteName || ''}
              onChange={(e) => setInstituteName(e.target.value)}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              helperText="Joining Date"
              type="date"
              variant="outlined"
              margin="dense"
              fullWidth
              className="tableList"
              value={joiningDate || ''}
              onChange={(e) => setJoiningDate(e.target.value)}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              helperText="Passout Date"
              type="date"
              variant="outlined"
              margin="dense"
              fullWidth
              className="tableList"
              value={endingDate || ''}
              onChange={(e) => setEndingDate(e.target.value)}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextField
              helperText="Validity Date"
              type="date"
              variant="outlined"
              margin="dense"
              fullWidth
              className="tableList"
              value={validityDate || ''}
              onChange={(e) => setValidtydate(e.target.value)}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Button color="primary" variant="outlined" style={{ marginTop: '10px' }} onClick={() => addCertificationList()}>Add More +</Button>
          </Grid>
          <Grid item md={12} xs={12}>
            {CertificationList && CertificationList.length === 0 && <Typography variant="h5" style={{ color: "blue", textAlign: 'center' }}>Please Add Your Certification</Typography>}
            {CertificationList && CertificationList.length !== 0 &&
              <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell float="left">
                        <Typography>S.No</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Course Name</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Institution Name</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Joining Date</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Passout Date</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Validity Date</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Remove</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CertificationList && CertificationList.length !== 0 && CertificationList.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell float="left">
                          <Typography>{index + 1}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>{data.course}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>{data.instuite_name}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>{data.joining_date}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>{data.passout_date}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>{data.validity_date}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Button variant="contained" color="primary" onClick={() => removeCertification(index)}>Remove</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography variant='h5'>Languages</Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              label="Language Name"
              variant="outlined"
              margin="dense"
              fullWidth
              className="tableList"
              required
              value={languageName || ''}
              onChange={(e) => setLanguageName(e.target.value)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              margin="dense"
              label="Level"
              fullWidth
              className="tableList"
              required
              select
              SelectProps={{ multiple: true }}
              value={languageLevel || ''}
              onChange={(e) => { setLanguageLevel(e.target.value) }}
              variant="outlined"
            >
              {languageLevelList && languageLevelList.map(data => (
                <MenuItem
                  value={data.name}
                  key={data.id}
                  name={data.name}
                >
                  {data.name ? data.name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <Button color="primary" variant="outlined" style={{ marginTop: '10px' }} onClick={() => addLanguageList()}>Add More +</Button>
          </Grid>
          <Grid item md={12} xs={12}>
            {languageList && languageList.length === 0 && <Typography variant="h5" style={{ color: "blue", textAlign: 'center' }}>Please Add Languages</Typography>}
            {languageList && languageList.length !== 0 &&
              <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell float="left">
                        <Typography>S.No</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Language Name</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Level</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>Remove</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {languageList && languageList.length !== 0 && languageList.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell float="left">
                          <Typography>{index + 1}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>{data.language_name}</Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Typography>
                            {data.level && data.level.length !== 0 && data.level.map((data, index) => (
                              <span key={index}>&nbsp;{data},</span>
                            ))}
                          </Typography>
                        </TableCell>
                        <TableCell float="left">
                          <Button variant="contained" color="primary" onClick={() => removeLanguageFromList(index)}>Remove</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>}
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={(skillAddesList.length === 0 || CertificationList.length === 0 || languageList.length === 0) ? true : false}
            onClick={() => submitOtherProfileData()}
          >
            Submit
          </Button>
        </Grid>
      </Paper>
      {loader}
    </React.Fragment>
  )
}

OtherDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(OtherDetails);
