import React, { useState, useEffect } from 'react'
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  IconButton,
  Box,
} from '@material-ui/core';

const EADashboard = () => {
  const [profile] = useState(JSON.parse(localStorage.getItem('user')));

  const {
    data: dashboardCount,
    isLoading: dasboardLoading,
    doFetch: FetchDashboardStatus
  } = useFetch(null, { suppressAlert: true });

  let loader = null;
  if (dasboardLoading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    FetchDashboardStatus({
      url: `${urls.dashboardStatus}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`
      }
    });
  }, []);


  let branch = (profile?.academic_profile?.branch[0]?.branch_name) ? (profile?.academic_profile?.branch[0]?.branch_name) : " ";


  return (
    <div>
      <Typography>
        Welcome to
        <strong style={{ fontSize: '40px', color: 'rgb(132 90 47)' }}>EA Dashboard</strong>
        ({profile?.academic_profile?.name})
        - <strong>Branch :</strong> {branch}
      </Typography>
      <Grid container spacing={1}>
        <Grid item md={2} />
        <Grid item md={8} xs={12}>
          <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
            <Grid container>
              <Grid item md={9} xs={12}>
                <Typography style={{ color: 'black' }}><strong>Total Registered Candidates on Career Box :</strong> </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography style={{ color: 'black' }}>
                  {dashboardCount?.applicant_count}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={2} />
        <Grid item md={2} />
        <Grid item md={8} xs={12}>
          <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
            <Grid container>
              <Grid item md={9} xs={12}>
                <Typography style={{ color: 'black' }}><strong>Total Candidates Assigned to You:</strong> </Typography>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography style={{ color: 'black' }}>
                  {dashboardCount?.applicant_count_assigned}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {loader}
    </div>
  )
}

export default EADashboard;