import "./sidebar.css";
import React, { useState } from "react";
import MyDesktop from "../utils/MyDesktop";
import MyMobile from "../utils/MyMobile";
import menu from "./menu";
import Sider from "antd/es/layout/Sider";
import { Drawer, Menu } from "antd";
import routeList from "./routeList";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { MoneyCollectOutlined } from "@ant-design/icons";

function Sidebar({
  setRouteLink,
  role,
  collapsed,
  setCollapsed,
  colorBgContainer,
}) {
  const history = useHistory();
  const userData = useSelector((state) => state?.auth?.user);
  function onListClick(route) {
    history.push(route);
    setRouteLink(routeList.filter((item) => item.path === route)[0]);
  }
  const [opened, setOpened] = React.useState(false);
  let menus = [];
  for (let i = 0; i < menu?.length; i++) {
    let obj = menu[i];
    let newChildren = [];
    if (menu[i]?.children && menu[i]?.children?.length > 0) {
      let children = menu[i]?.children;
      for (let j = 0; j < children?.length; j++) {
        children[j].onClick = () => {
          onListClick(children[j].link);
        };
        children[j].key = children[j].link;
        if (children[j].roles?.includes(role)) {
          newChildren.push(children[j]);
        }
      }
      obj.children = newChildren;
    } else if (menu[i]?.link) {
      menu[i].onClick = () => {
        onListClick(menu[i].link);
      };
    }
    obj.key = menu[i]?.link;

    if (obj?.roles?.includes(role)) menus.push(obj);
  }
  if (userData?.academic_profile?.is_budget_approver == true) {
    menus.push({
      key: "/budget_approval_list",
      label: "Budget Approval List",
      icon: <MoneyCollectOutlined />,
      link: "/budget_approval_list",
      roles: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
      ],
      onClick: () => onListClick("/budget_approval_list"),
    });
  }
  const [collapsedWidth, setCollapsedWidth] = useState(78);
  return (
    <React.Fragment>
      <MyDesktop>
        <Sider
          collapsible
          collapsedWidth={collapsedWidth}
          collapsed={collapsed}
          onCollapse={() => {
            setCollapsed(!collapsed);
          }}
          width="270px"
          theme="dark"
          classname="th-hidden-scroll"
          style={{
            height: "calc(100vh - 48px)",
            overflowY: "scroll",
            overflowX: "hidden",
            position: "sticky",
            left: 0,
            top: 0,
            bottom: 0,
            // background: colorBgContainer,
          }}
          zeroWidthTriggerStyle={{
            width: "78px",
          }}
        >
          <div
            className="demo-logo-vertical"
            style={{
              height: "32px",
              margin: "10px",
              background: "#e9e9e9",
              borderRadius: "6px",
              display: "grid",
              placeItems: "center",
              fontSize: collapsed ? "12px" : "16px",
              fontWeight: collapsed ? 600 : 500,
            }}
          >
            {collapsed ? "CBox" : "CareerBox"}
          </div>
          <Menu
            className=""
            mode="inline"
            theme="dark"
            selectedKeys={[window.location.pathname]}
            items={menus}
          />
        </Sider>
      </MyDesktop>
      <MyMobile>
        <Drawer
          style={{ maxWidth: "256px", padding: 0 }}
          placement="left"
          open={!collapsed}
          className="th-mobile-sidebar"
          onClose={() => {
            setCollapsed(!collapsed);
          }}
          closeIcon={null}
          contentWrapperStyle={{ width: "256px" }}
          title={
            <div
              className="demo-logo-vertical"
              style={{
                height: "32px",
                background: "#e9e9e9",
                borderRadius: "6px",
                display: "grid",
                placeItems: "center",
                fontSize: "16px",
                fontWeight: 600,
              }}
            >
              CareerBox
            </div>
          }
        >
          <Menu
            className=""
            mode="inline"
            selectedKeys={[window.location.pathname]}
            items={menus}
          />
        </Drawer>
      </MyMobile>
    </React.Fragment>
  );
}
export default Sidebar;
