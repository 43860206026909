import React, { useEffect, useState } from "react";
import urls from "../../url";
import axios from "axios";
import {
  Card,
  message,
  Row,
  Col,
  Spin,
  Button,
  Space,
  Form,
  Modal,
  Input,
  Tooltip,
} from "antd";
import { SyncOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

const OfferBudget = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const offerId = urlParams.get("offer_id");

  const { TextArea } = Input;
  const [offerLetterData, setOfferLetterData] = useState();
  const [loading, setLoading] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const [feedBackModalForm] = useForm();

  useEffect(() => {
    if (offerId) {
      fetchOfferLetterData();
    }
  }, []);

  const fetchOfferLetterData = () => {
    setLoading(true);
    axios
      .get(`${urls.offerLetterApproval}${offerId}/`)
      .then((response) => {
        if (response.status === 200) {
          setOfferLetterData(response?.data[0]);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatus = (val) => {
    setLoading(true);
    const values = feedBackModalForm.getFieldsValue();
    const formData = new FormData();
    formData.append("budget_approver_status", val);
    if (val == "rejected") {
      formData.append(
        "reporting_manager_feedback",
        values?.reporting_manager_feedback
      );
    }

    axios
      .put(`${urls.offerLetterApproval}${offerId}/`, formData)
      .then((response) => {
        if (response.status === 200) {
          fetchOfferLetterData();
          closeFeedBackModal();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const closeFeedBackModal = () => {
    setFeedbackModalOpen(false);
    feedBackModalForm.resetFields();
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={5}>
          <Card
            style={{
              overflowY: "scroll",
              maxHeight: "90vh",
              borderRadius: "20px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
              background: "#f5f5f5",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                width: "0",
                height: "0",
                display: "none",
              },
            }}
          >
            <>
              <div>
                <h2 style={{ fontSize: "1.2rem", marginBottom: "10px" }}>
                  Offer Details
                </h2>

                <Row align="middle" gutter={24}>
                  <Col span={24} style={{ display: "flex" }}>
                    <span style={{ fontWeight: "bold" }}>CTC (Annual) : </span>
                    <br />

                    <span style={{ textTransform: "uppercase" }}>
                      {offerLetterData?.fixed_gross_per_month}
                    </span>
                  </Col>
                  <Col span={24} style={{ display: "flex" }}>
                    <span style={{ fontWeight: "bold" }}>
                      Budget (Annual) :{" "}
                    </span>
                    <br />

                    <span style={{ textTransform: "uppercase" }}>
                      {offerLetterData?.budget}
                    </span>
                  </Col>
                </Row>
              </div>
            </>
          </Card>
        </Col>
        <Col span={19}>
          <Card
            style={{
              overflowY: "scroll",
              maxHeight: "90vh",
              borderRadius: "20px",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
              background: "#f5f5f5",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                width: "0",
                height: "0",
                display: "none",
              },
            }}
          >
            <>
              <Row justify="space-between" alignItems="center">
                <h2>Offer Letter</h2>
                {offerLetterData?.budget_approver_status === "pending" && (
                  <Space>
                    <Button
                      type="primary"
                      style={{ width: "100%", backgroundColor: "red" }}
                      onClick={() => setFeedbackModalOpen(true)}
                    >
                      <b>Reject</b>
                    </Button>
                    <Button
                      type="primary"
                      style={{ width: "100%", backgroundColor: "green" }}
                      icon={loading ? <SyncOutlined spin /> : null}
                      onClick={() => handleStatus("approved")}
                    >
                      <b>Approve</b>
                    </Button>
                  </Space>
                )}
                {offerLetterData?.budget_approver_status === "rejected" && (
                  <Tooltip
                    title={`Rejection Remarks: ${offerLetterData?.reporting_manager_feedback}`}
                  >
                    <span
                      style={{
                        color: "red",
                        fontStyle: "italic",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Rejected <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                )}
                {offerLetterData?.budget_approver_status === "approved" && (
                  <span
                    style={{
                      color: "green",
                      fontStyle: "italic",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Approved
                  </span>
                )}
              </Row>
              {loading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "200px",
                  }}
                >
                  <Spin size="large" />
                </div>
              )}
              {!loading && (
                <>
                  <div
                    style={{
                      padding: "10px",
                      marginBottom: "50px",
                      marginRight: "100px",
                    }}
                  >
                    <br />
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <strong>
                        To:<span style={{ visibility: "hidden" }}>H</span>
                      </strong>{" "}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: offerLetterData?.candidate_email,
                        }}
                      />
                    </div>
                    <br />
                    <strong>CC:</strong>
                    {offerLetterData?.cc &&
                      JSON.parse(offerLetterData?.cc?.replace(/'/g, '"'))?.map(
                        (tag, i) => (
                          <span key={i}>
                            {i > 0 && " "}
                            <ul>
                              <li>
                                <div
                                  dangerouslySetInnerHTML={{ __html: tag }}
                                />
                              </li>
                            </ul>
                          </span>
                        )
                      )}
                    <br />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      <strong>
                        Subject:
                        <span style={{ visibility: "hidden" }}>H</span>
                      </strong>

                      <div
                        style={{ maxWidth: "100%" }}
                        dangerouslySetInnerHTML={{
                          __html: offerLetterData?.email_subject,
                        }}
                      />
                    </div>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: offerLetterData?.offer_letter,
                      }}
                    />
                  </div>
                </>
              )}
            </>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Reason for Rejecting"
        open={feedbackModalOpen}
        onCancel={closeFeedBackModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={closeFeedBackModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" form="feedBackModalForm" htmlType="submit">
                Reject
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="feedBackModalForm"
          form={feedBackModalForm}
          layout="vertical"
          onFinish={() => handleStatus("rejected")}
        >
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="reporting_manager_feedback"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Remarks",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  maxLength={1500}
                  showCount
                  placeholder="Enter Remarks"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default OfferBudget;
