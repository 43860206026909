/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import {
  Table as AntdTable,
  Select as AntdSelect,
  Input as AntdInput,
  Form,
  Card as AntdCard,
  Row,
  Col,
  InputNumber,
  Space as AntdSpace,
  Tag as AntdTag,
  Button as AntdButton,
  message,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  Divider,
  withStyles,
  Grid,
  Paper,
  TextField,
  MenuItem,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Select,
  TableRow,
  Typography,
  TableContainer,
} from "@material-ui/core";
import urls from "../../../../url";
import useFetch from "../../../../hoc/useFetch";
import styles from "./positionStep2.style";
import Loader from "../../../../hoc/loader";
import axios from "axios";

const PositionStep2 = ({
  classes,
  backFunction,
  functionSubmit,
  paperList,
  grade,
  subject,
  addLoader,
}) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [round, setRound] = useState("");
  const [roundName, setRoundName] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [cutOffMarks, setCutOffMarks] = useState("");
  const [questionpaper, setQuestionPaper] = useState([]);
  const [addedPaperList, setAddedPaperList] = useState(paperList);
  const [questionPapers, setQuestionPapers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  // const {
  //   data: questionPapers,
  //   isLoading: gettingQuestionPapers,
  //   doFetch: fetchQuestionPapersList,
  // } = useFetch(null);

  const fetchQuestionPapersList = ({ url }) => {
    setLoading(true);
    axios
      .get(`${url}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          setQuestionPapers(response?.data);
          // message.success("Data Fetched Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let loader = null;
  // if (gettingQuestionPapers) {
  //   loader = <Loader open />;
  // }

  console.log("questions:", grade, subject);

  useEffect(() => {
    if (round) {
      fetchQuestionPapersList({
        url: `${urls.questionPapersListApi}?id=${
          round == 5 ? 4 : round
        }&grade_id=${grade}&subject_id=${subject}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [round]);

  const roundsList = [
    // { id: "1", roundName: "Mcq" },
    { id: "2", roundName: "Spoken" },
    { id: "3", roundName: "Written" },
    { id: "4", roundName: "F2F" },
    { id: "5", roundName: "Class Demo" },
  ];
  const roundsLists = [
    { id: "2", roundName: "Spoken" },
    { id: "3", roundName: "Written" },
    { id: "4", roundName: "F2F" },
  ];

  function addPaperFunction() {
    if (!round || !roundName) {
      message.warning("Please Fill All Fields");
      return;
    }
    if (round != 4 && round != 5) {
      if (questionpaper.length === 0) {
        message.warning("Select QuestionPaper");
        return;
      }
    }
    // if (round === "4") {
    //   if (!totalMarks || !cutOffMarks) {
    //     message.warning("Enter Marks");
    //     return;
    //   }
    // }
    let data;

    if (round === "4") {
      data = {
        round_no: round,
        // eslint-disable-next-line array-callback-return
        round_type: roundsList
          .filter((data) => data.id == round)[0]
          ?.roundName?.toLowerCase()
          ?.split(" ")
          ?.join("_"),

        questionpaperNames:
          round === "1"
            ? questionpaper.map(
                (ids) =>
                  questionPapers.filter((item) => {
                    if (item.id === ids) {
                      return item.id;
                    }
                  })[0].test_name
              )
            : round === "2" || round === "3"
            ? questionpaper.map(
                (ids) =>
                  questionPapers.filter((item) => {
                    if (item.id === ids) {
                      return item.id;
                    }
                  })[0].topic_name
              )
            : round == 4 || round == 5
            ? questionpaper.map(
                (ids) =>
                  questionPapers.filter((item) => {
                    if (item.id === ids) {
                      return item.id;
                    }
                  })[0].question
              )
            : "",
        round_id: null,
        id_list: questionpaper,
        round_name: roundName,
        total_marks: totalMarks,
        cutoff_marks: cutOffMarks,
        previous_round: false,
        round_assign: false,
        round_complete: null,
        round_clear: null,
        is_class_demo: round == 5,
      };
    } else {
      data = {
        round_no: round,
        round_type: roundsList
          .filter((data) => data.id == round)[0]
          ?.roundName?.toLowerCase()
          ?.split(" ")
          ?.join("_"),
        questionpaperNames:
          round === "1"
            ? questionpaper.map(
                (ids) =>
                  questionPapers.filter((item) => {
                    if (item.id === ids) {
                      return item.id;
                    }
                  })[0].test_name
              )
            : round === "2" || round === "3"
            ? questionpaper.map(
                (ids) =>
                  questionPapers.filter((item) => {
                    if (item.id === ids) {
                      return item.id;
                    }
                  })[0].topic_name
              )
            : round == 4 || round == 5
            ? questionpaper.map(
                (ids) =>
                  questionPapers.filter((item) => {
                    if (item.id === ids) {
                      return item.id;
                    }
                  })[0].question
              )
            : "",
        round_id: null,
        id_list: questionpaper,
        round_name: roundName,
        previous_round: false,
        round_assign: false,
        round_complete: null,
        round_clear: null,
        is_class_demo: round == 5,
      };
    }

    let newArray = [...addedPaperList, data];
    setAddedPaperList(newArray);
    setRound("");
    setCutOffMarks("");
    setTotalMarks("");
    setQuestionPaper([]);
    setRoundName("");
    form.resetFields();
  }

  function functionDeletePaper(index) {
    setAddedPaperList((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Rounds</strong>,
      dataIndex: "round_type",
      key: "round_name",
      align: "center",
      render: (data) => (
        <span className="" style={{ textTransform: "capitalize" }}>
          {data?.split("_")?.join(" ")}
        </span>
      ),
    },
    {
      title: <strong>Round Name</strong>,
      dataIndex: "round_name",
      key: "round_name",
      align: "center",
    },
    {
      title: <strong>Papers Selected</strong>,
      dataIndex: "questionPaperNames",
      key: "questionPaperNames",
      align: "center",
      width: "20%",
      render: (text, record, index) => {
        return record?.questionpaperNames?.map((data, ind) => (
          <AntdTag key={ind}>
            &nbsp;{data}
            <br />
          </AntdTag>
        ));
      },
    },
    // {
    //   title: <strong>Total Marks</strong>,
    //   dataIndex: "total_marks",
    //   key: "total_marks",
    //   align: "center",
    // },
    // {
    //   title: <strong>Cut-off Marks</strong>,
    //   dataIndex: "cutoff_marks",
    //   key: "cutoff_marks",
    //   align: "center",
    // },

    {
      title: <strong>Delete</strong>,
      key: "update",
      align: "center",
      render: (text, record, index) => (
        <>
          <AntdSpace size="">
            <AntdTag
              style={{ cursor: "pointer" }}
              color="red"
              onClick={() => functionDeletePaper(index)}
            >
              <DeleteOutlined style={{ marginInlineEnd: "5px" }} color="red" />
              Delete
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div>
        <Form form={form}>
          <AntdCard style={{ marginBottom: "10px" }}>
            <Row gutter={16}>
              <Col md={8}>
                <Form.Item
                  name="round"
                  label="Select Round"
                  labelCol={{ span: 24 }}
                >
                  <AntdSelect placeholder="Round" onChange={(e) => setRound(e)}>
                    {roundsList.map((option) => (
                      <AntdSelect.Option
                        key={option.value}
                        value={option.id}
                        name={option.id}
                      >
                        {option.roundName}
                      </AntdSelect.Option>
                    ))}
                  </AntdSelect>
                </Form.Item>
              </Col>
              <Col md={8}>
                <Form.Item
                  name="round_name"
                  label="Round Name"
                  labelCol={{ span: 24 }}
                >
                  <AntdInput
                    onChange={(e) => setRoundName(e.target.value)}
                    placeholder="Round Name"
                  />
                </Form.Item>
              </Col>
              {round != 4 && round != 5 && (
                <Col md={8}>
                  <Form.Item
                    name="id_list"
                    label="Select Question paper"
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      mode="multiple"
                      onChange={(e) => setQuestionPaper(e)}
                    >
                      {questionPapers &&
                        questionPapers.length !== 0 &&
                        questionPapers.map((option) => (
                          <AntdSelect.Option key={option.id} value={option.id}>
                            {option.test_name ||
                              option.topic_name ||
                              option.question}
                          </AntdSelect.Option>
                        ))}
                    </AntdSelect>
                  </Form.Item>
                </Col>
              )}
              {/* {parseInt(round, 10) === 4 && (
              <Col md={8}>
                <Row gutter={16}>
                  <Col md={12}>
                    <Form.Item
                      name="total_marks"
                      label="Total Marks"
                      labelCol={{ span: 24 }}
                    >
                      <InputNumber
                        defaultValue={0}
                        style={{ width: "100%" }}
                        min={0}
                        onChange={(e) => setTotalMarks(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="cutoff_marks"
                      label="Cut-Off Marks"
                      labelCol={{ span: 24 }}
                    >
                      <InputNumber
                        defaultValue={0}
                        style={{ width: "100%" }}
                        min={0}
                        onChange={(e) => setCutOffMarks(e)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )} */}
              <Col md={24}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <AntdButton
                    type="primary"
                    size="large"
                    style={{ width: "200px" }}
                    onClick={() => addPaperFunction()}
                  >
                    Add
                  </AntdButton>
                </div>
              </Col>
            </Row>
          </AntdCard>
        </Form>
      </div>
      {/* {addedPaperList?.length !== 0 && (
        <> */}
      {/* {console.log(addedPaperList)} */}
      <AntdTable
        columns={columns}
        dataSource={addedPaperList}
        pagination={false}
        rowKey={(data) => data?.id}
        loading={loading}
      />
      {/* </>
      )} */}
      {/* <Paper className={classes.paper}>
        <Grid className={classes.createPosition}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <Typography> Select Round</Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={round || ""}
                onChange={(e) => setRound(e.target.value)}
                variant="outlined"
              >
                {grade
                  ? roundsList.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.id}
                        name={option.id}
                      >
                        {option.roundName}
                      </MenuItem>
                    ))
                  : roundsLists.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.id}
                        name={option.id}
                      >
                        {option.roundName}
                      </MenuItem>
                    ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12} className={classes.textField}>
              <TextField
                margin="dense"
                fullWidth
                className="textList"
                label="Enter Round Name"
                value={roundName || ""}
                onChange={(e) => setRoundName(e.target.value)}
                variant="outlined"
              />
            </Grid>
            {parseInt(round, 10) !== 4 && (
              <Grid item md={3} xs={12}>
                <Typography>Select Question Paper</Typography>
                <Select
                  multiple
                  required
                  fullWidth
                  className="textList"
                  margin="dense"
                  value={questionpaper || ""}
                  id={questionpaper}
                  onChange={(e) => setQuestionPaper(e.target.value)}
                  variant="outlined"
                >
                  {questionPapers &&
                    questionPapers.length !== 0 &&
                    questionPapers.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.test_name ||
                          option.topic_name ||
                          option.question}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            )}
            {parseInt(round, 10) === 4 && (
              <>
                <Grid item md={2} xs={12} className={classes.textField}>
                  <TextField
                    margin="dense"
                    fullWidth
                    className="textList"
                    label="Enter Total Marks"
                    value={totalMarks || ""}
                    type="number"
                    onChange={(e) => setTotalMarks(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={2} xs={12} className={classes.textField}>
                  <TextField
                    margin="dense"
                    fullWidth
                    className="textList"
                    label="Enter Cut-Off Marks"
                    value={cutOffMarks || ""}
                    type="number"
                    onChange={(e) => setCutOffMarks(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            <Grid item md={2} xs={12}>
              <Button
                style={{ marginTop: "21px" }}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                onClick={() => addPaperFunction()}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper> */}
      {/* 
      {console.log(addedPaperList, "addedPaperList")}
      <Divider className={classes.divider} />
      {addedPaperList && addedPaperList.length !== 0 && (
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell float="left">
                        <Typography>
                          <strong>S.No</strong>
                        </Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>
                          <strong>Rounds</strong>
                        </Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>
                          <strong>Round Name</strong>
                        </Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>
                          <strong>Papers Selected</strong>
                        </Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>
                          <strong>Total Marks</strong>
                        </Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>
                          <strong>Cut-off Marks</strong>
                        </Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>
                          <strong>Delete</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {addedPaperList &&
                      addedPaperList.length !== 0 &&
                      addedPaperList.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell float="left">
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell float="left">
                            <Typography>{data.round_type}</Typography>
                          </TableCell>
                          <TableCell float="left">
                            <Typography>{data.round_name}</Typography>
                          </TableCell>
                          <TableCell float="left">
                            <Typography>
                              {data.questionpaperNames &&
                                data.questionpaperNames.length !== 0 &&
                                data.questionpaperNames.map((dataaa, ind) => {
                                  return (
                                    <a key={ind}>
                                      &nbsp;{dataaa}
                                      <br />
                                    </a>
                                  );
                                })}
                            </Typography>
                          </TableCell>
                          <TableCell float="left">
                            <Typography>{data.total_marks || ""}</Typography>
                          </TableCell>
                          <TableCell float="left">
                            <Typography>{data.cutoff_marks || ""}</Typography>
                          </TableCell>
                          <TableCell float="left">
                            <Typography>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => functionDeletePaper(index)}
                              >
                                Delete
                              </Button>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      )} */}
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          paddingBlockStart: "20px",
        }}
      >
        <div>
          <AntdButton
            type="primary"
            onClick={() => backFunction(addedPaperList)}
          >
            Previous
          </AntdButton>
        </div>
        <div>
          <AntdButton
            type="primary"
            coloe="purple"
            onClick={() => functionSubmit(addedPaperList)}
            loading={addLoader}
          >
            Submit
            {/* <UploadOutlined /> */}
          </AntdButton>
        </div>
      </div>
      {loader}
    </React.Fragment>
  );
};

PositionStep2.propTypes = {};

export default withStyles(styles)(PositionStep2);
