import React, { useState, useEffect } from "react";

import {
  Grid,
  TextField,
  withStyles,
  Typography,
  Divider,
  Button,
  Paper,
  MenuItem,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel
} from "@material-ui/core";

import styles from "./TransferPage.style";
import urls from "../../url";
import useFetch from "../../hoc/fetch/useFetch";

const INITIAL_STATE = {
  name: "",
  erp: "",
  mobile: "",
  email: "",
  from_branch: "",
  to_branch: "",
  grade: "",
  reason_of_transfer: ""
};

function TransferPage({ classes, branchName }) {
  const { data: branchList, doFetch: fetchBranchList } = useFetch(null);
  const { data: gradeList, doFetch: fetchGradesList } = useFetch(null);

  const [submitData, setSubmitData] = useState(INITIAL_STATE);

  const handleSubmitData = e => {
    setSubmitData({
      ...submitData,
      to_branch: branchName.id,
      [e.target.name]: e.target.value
    });
  };

  const submitApplyTransfer = async () => {
    const response = await fetch(urls.applyTransferPost, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(submitData), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });
    const fetchData = await response.json();
    return fetchData;
  };

  useEffect(() => {
    fetchBranchList({
      url: urls.branch,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
        //   Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    fetchGradesList({
      url: urls.getGrades,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
        //   Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  }, []);
  return (
    <div className={classes.mainDiv}>
      <h2 style={{ textAlign: "center", fontWeight: "bold", color: "teal" }}>
        Transfer Form
      </h2>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item sm={1} md={1} />
          <Grid item sm={3} md={5} xs={12}>
            <TextField
              fullWidth
              variant='outlined'
              id='transferName'
              label='Name'
              type='text'
              name='name'
              value={submitData.name}
              onChange={handleSubmitData}
            />
          </Grid>
          <Grid item sm={3} md={5} xs={12}>
            <TextField
              fullWidth
              id='transferERP'
              label='Erp Number'
              type='number'
              variant='outlined'
              name='erp'
              value={submitData.erp}
              onChange={handleSubmitData}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item sm={1} md={1} />
          <Grid item sm={3} md={5} xs={12}>
            <TextField
              fullWidth
              id='transferMobile'
              label='Mobile Number'
              type='number'
              variant='outlined'
              name='mobile'
              value={submitData.mobile}
              onChange={handleSubmitData}
            />
          </Grid>
          <Grid item sm={3} md={5} xs={12}>
            <TextField
              fullWidth
              id='transferEmail'
              label='Email Id'
              type='text'
              variant='outlined'
              name='email'
              value={submitData.email}
              onChange={handleSubmitData}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item sm={1} md={1} />
          <Grid item sm={3} md={5} xs={12}>
            <TextField
              className={classes.textField}
              label='From Branch'
              fullWidth
              required
              select
              variant='outlined'
              name='from_branch'
              value={submitData.from_branch}
              onChange={handleSubmitData}
            >
              <MenuItem value='fromBranch' key='fb' name='from_branch' disabled>
                Select From Branch
              </MenuItem>
              {branchList &&
                branchList.map(data => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.branch_name}
                  >
                    {data.branch_name ? data.branch_name : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item sm={3} md={5} xs={12}>
            <TextField
              fullWidth
              id='transferBranch'
              label='To Branch'
              type='text'
              variant='outlined'
              name='To Branch'
              value={branchName.branch_name}
              onChange={handleSubmitData}
              disabled
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item sm={1} md={1} />
          <Grid item sm={3} md={5} xs={12}>
            <TextField
              className={classes.textField}
              label='Grade'
              fullWidth
              required
              select
              variant='outlined'
              name='grade'
              value={submitData.grade}
              onChange={handleSubmitData}
            >
              {" "}
              <MenuItem
                value='transferGrade'
                key='tgrade'
                name='transfer_branch'
                disabled
              >
                Select A Grade
              </MenuItem>
              {gradeList &&
                gradeList.map(data => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.grade_name}
                  >
                    {data.grade_name ? data.grade_name : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item sm={3} md={5} xs={12}>
            <TextField
              fullWidth
              placeholder='Reason To Transfer'
              multiline
              rows={1}
              rowsMax={4}
              variant='outlined'
              name='reason_of_transfer'
              value={submitData.reason_of_transfer}
              onChange={handleSubmitData}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item sm={3} md={4} />
          <Grid item sm={10} md={4} xs={6}>
            <Button
              style={{ width: "100%" }}
              color='primary'
              variant='contained'
              size='large'
              onClick={submitApplyTransfer}
            >
              Apply Transfer
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(TransferPage);
