
import React, { useState, useEffect } from 'react';
import {
    Divider,
    Grid,
    TextField,
    MenuItem,
    Paper,
    Button,
    withStyles,
    Fab,
    Modal,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './createSubject.style';
import urls from '../../../url';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import useFetch from '../../../hoc/useFetch';
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

function CreateSubject({ classes }) {
    const [auth] = useState(JSON.parse(localStorage.getItem('user')));
    const [grade, setGrade] = useState('');
    const [subject, setSubjects] = useState('')
    const [open, setOpen] = useState(false);
    const [positionType, setPositionType] = useState("");


    const alert = useAlert();

    const {
        data: gradeData,
        isLoading: gradeLoading,
        doFetch: fetchGrade,
    } = useFetch([], { suppressAlert: true });


    const {
        data: createSubjectData,
        isLoading: createSubjectDataLoading,
        doFetch: fetchAddSubject
    } = useFetch([], { suppressAlert: true });

    useEffect(() => {
        if (createSubjectData) {
            setOpen(false);
            setGrade('');
            setSubjects('');
        }
    }, [createSubjectData])

    useEffect(() => {
        if (positionType) {
            fetchGrade({
                url: `${urls.newGrade}?position_type=${positionType}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.personal_info.token}`,
                }
            });
        }
    }, [fetchGrade, auth,positionType]);

    let loader = null;
    if (gradeLoading || createSubjectDataLoading) {
        loader = <Loader open />;
    }
    function createSubject() {
        if (!grade) {
            alert.warning('Select Grade')
            return false;
        } else if (!subject) {
            alert.warning('Enter Subject')
            return false;
        }

        const subjectData = {
            'subject_name': subject,
            'grade_id': grade
        }

        fetchAddSubject({
            url: urls.createSubjectApi,
            method: 'POST',
            body: subjectData,
            headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                'Content-Type': 'application/json',
            }
        });
    }
    const handleClose = () => {
        setOpen(false);
        setGrade('');
        setSubjects('');
    };
    const positionTypeList = [
        {
          value: "1",
          label: "Academic",
        },
        {
          value: "2",
          label: "Non Academic",
        },
        {
          value: "3",
          label: "IT Recruitment",
        },
        {
          value: "4",
          label: "Marketing and Communications",
        },
        {
          value: "5",
          label: "Sales and Business Development",
        },
      ];

    return (
        <React.Fragment>
            <Fab title = "Add Subjects" color="primary" aria-label="add" size="small" className={classes.fab} onClick={() => { setOpen(true) }}>
                <AddIcon fontSizeSmall></AddIcon>
            </Fab>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{width:"80%",marginLeft:"10%"}}
            >
                <Fade in={open}>
                    <Paper className={classes.paperr}>
                        <Typography  className="headings" variant='h4'><strong>Add {positionType=="Academic" ?"Subject":"Topic"}</strong></Typography>
                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                        <TextField
              select
              required
              fullWidth
              label="Position Type"
              margin="dense"
              value={positionType || ""}
              onChange={(e, id) => {
                setPositionType(e.target.value);
              }}
              variant="outlined"
            >
              {positionTypeList.map((option, index) => (
                <MenuItem key={index} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label={positionType=="Academic" ?"Grade":"Level"}
                                    // className={classes.textField}
                                    fullWidth
                                    className="textList"
                                    margin="dense"
                                    // required
                                    onChange={e => setGrade(e.target.value)}
                                    select
                                    value={grade || ''}
                                    variant="outlined"
                                >
                                    {gradeData.map((data) => (
                                            <MenuItem
                                                value={data.id}
                                                key={data.id}
                                                name={data.grade_name}
                                            >
                                                {data.grade_name ? data.grade_name : ''}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <TextField
                                    label={positionType=="Academic" ?"Subject":"Topic"}
                                    // className={classes.textField}
                                    fullWidth
                                    className="textList"
                                    margin="dense"
                                    type='text'
                                    required
                                    onChange={e => setSubjects(e.target.value)}
                                    value={subject || ''}
                                    variant="outlined"
                                >
                                </TextField>
                            </Grid>
                            <Grid container align="center" justify='center'>
                            <Grid item md={4} xs={12}>
                                <Button
                                    // className={classes.applyButtonModal}
                                    color="primary"
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    // style={{ marginLeft: '35%' }}
                                    onClick={() => createSubject()}
                                >
                                    Add
                                </Button>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                    </Paper>
                </Fade>
            </Modal>
            {loader}
        </React.Fragment>
    )
}
CreateSubject.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CreateSubject);