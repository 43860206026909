import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Form,
  Input,
  Col,
  Row,
  Pagination,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Typography as AntdTypography,
  Card,
  DatePicker,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  Modal,
  TablePagination,
  InputBase,
  TableContainer,
  Select,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./roleDetails.style";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import SearchIcon from "@material-ui/icons/Search";
import MediaQuery from "react-responsive";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import dayjs from "dayjs";

function RoleDetails({ classes }) {
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";

  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [role, setRole] = useState("");
  const [inerviewPanel, setInerviewPanel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [applicentName, setApplicentName] = useState("");
  const [bulkFile, setBulkFile] = useState("");
  const [accordianOpen, setAccordianOpen] = useState(false);
  const [accordianOpenFilter, setAccordianOpenFilter] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [checked, setChecked] = useState(null);

  const [visible, setVisible] = useState(false);

  const {
    data: addInterviewPanelResponse,
    isLoading: addInterviewPanelResponseLoading,
    doFetch: addInterview,
  } = useFetch([], { suppressAlert: true });
  const {
    data: tableData,
    isLoading: tableDataLoading,
    doFetch: fetchTableData,
  } = useFetch(null);
  console.log(tableData, "whole");

  const {
    data: updatainteriewData,
    isLoading: updatainteriewDataLoading,
    doFetch: fetchUpdataInterview,
  } = useFetch([], { suppressAlert: true });

  const {
    data: deleteInterviewData,
    isLoading: deleteInterviewDataLoading,
    doFetch: fetchDeleteInterview,
  } = useFetch([], { suppressAlert: true });

  // const fetchSearch = (e) => {
  //   setPage(0);
  //   setRowsPerPage(50);
  //   if (e.keyCode === 13) {
  //     const data = document.getElementById("search-bar").value;
  //     setApplicentName(document.getElementById("search-bar").value);
  //     functionSearch(data);
  //   }
  // };
  function functionSearch(data) {
    if (data !== "") {
      fetchTableData({
        url: `${urls.searchRoleDetails}?search=${data}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      getTableData();
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }
  const clearBulkFunction = () => {
    setBulkFile("");
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  function getTableData() {
    fetchTableData({
      url: `${urls.getRoleListDetails}?page_size=${rowsPerPage ||
        50}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (auth) {
      getTableData();
    }
  }, [
    auth,
    addInterviewPanelResponse,
    updatainteriewData,
    deleteInterviewData,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (addInterviewPanelResponse) {
      setRoleName("");
      setBulkFile("");
    }
  }, [addInterviewPanelResponse]);

  const alert = useAlert();

  const handleBulkQuestions = (value) => {
    setBulkFile(value);
  };

  const handleClose = () => {
    setRole("");
    setInerviewPanel("");
    setOpen(false);
    setChecked(null);
  };

  useEffect(() => {
    if (updatainteriewData) {
      setOpen(false);
      setRoleName("");
      setBulkFile("");
    }
  }, [updatainteriewData]);

  let loader = null;
  if (
    addInterviewPanelResponseLoading ||
    tableDataLoading ||
    updatainteriewDataLoading ||
    deleteInterviewDataLoading
  ) {
    loader = <Loader open />;
  }

  function editFunction(data) {
    setVisible(true);
    form.setFieldsValue({ name: data?.name });

    setSelectedRoleId(data?.id);
  }

  function updateInterviewFunction() {
    if (!roleName) {
      alert.warning("fill all fields");
      return;
    }
    const data = {
      id: selectedRoleId,
      name: roleName.replace(/\b\w/g, (l) => l.toUpperCase()),
      is_active: checked,
    };
    fetchUpdataInterview({
      url: `${urls.updateRoleDetails}`,
      method: "PUT",
      body: data,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        "Content-Type": "application/json",
      },
    });
  }

  function dateSetter(value) {
    if (value?.length > 0) {
      setStartDate(dayjs(value[0]).format("YYYY-MM-DD"));
      setEndDate(dayjs(value[1]).format("YYYY-MM-DD"));
    }
    console.log(startDate, endDate, "SDED");
  }

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  function fetchData() {
    if (startDate && endDate) {
      fetchTableData({
        url: `${urls.searchRoleDetails}?start_date=${startDate}&end_date=${endDate}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }
  const [form] = Form.useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setSelectedRoleId(null);
  };

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Role Name</strong>,
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
    {
      title: "Status",
      key: "is_active",

      render: (data) => (
        <AntdSwitch
          onChange={() => {
            fetchUpdataInterview({
              url: `${urls.updateRoleDetails}`,
              method: "PUT",
              body: {
                name: data?.name,
                id: data?.id,
                is_active: !data?.is_active,
              },
              headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                "Content-Type": "application/json",
              },
            });
          }}
          checked={data?.is_active}
        />
      ),
    },
  ];

  function addInterviewPanel(formValue) {
    if (!selectedRoleId) {
      if (bulkFile.name) {
        const formData = new FormData();
        formData.append("file", bulkFile);
        addInterview({
          url: urls.bulkUploadRoleDetails,
          method: "POST",
          body: formData,
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
          },
        });
      } else {
        addInterview({
          url: urls.createRoleDetails,
          method: "POST",
          body: {
            name:
              formValue && formValue.name
                ? formValue.name.replace(/\b\w/g, (l) => l.toUpperCase())
                : null,
          },

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`,
          },
        });
      }
    } else {
      fetchUpdataInterview({
        url: `${urls.updateRoleDetails}`,
        method: "PUT",
        body: {
          name:
            formValue && formValue.name
              ? formValue.name.replace(/\b\w/g, (l) => l.toUpperCase())
              : null,
          id: selectedRoleId,
        },
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
        },
      });
    }
  }

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>Role Details</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Role Details
          </AntdButton>
        </div>
      </div>

      <AntdDrawer
        title="Create Grade/Level Details"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          setSelectedRoleId(null);
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="addRole" type="primary" htmlType="submit">
              Add
            </AntdButton>
          </div>
        }
      >
        <Form
          id="addRole"
          form={form}
          layout="vertical"
          onFinish={addInterviewPanel}
        >
          <Row>
            <Col xs={24}>
              <Form.Item
                name="name"
                label={"Name"}
                placeholder={"Enter Role Name"}
                rules={[
                  {
                    required: bulkFile.name ? false : true,
                    message: "Please Enter Role Name",
                  },
                ]}
              >
                <Input placeholder="Enter Role Name" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <input
          style={{ display: "none" }}
          type="file"
          id="excel-file"
          accept=".csv"
          onChange={(e) => handleBulkQuestions(e.target.files[0])}
        />
        <label htmlFor="excel-file">
          <Button
            variant="contained"
            component="span"
            size="small"
            // fullWidth
            color="primary"
            startIcon={<CloudUploadIcon />}
            id="bulkUpload"
          >
            Bulk Upload
          </Button>
          &nbsp;&nbsp;
          {bulkFile.name}
        </label>
        {bulkFile && (
          <IconButton size="small" onClick={() => clearBulkFunction()}>
            <DeleteIcon />
          </IconButton>
        )}
      </AntdDrawer>

      <Card>
        <Row gutter={18}>
          <Col md={6}>
            <RangePicker
              onChange={dateSetter}
              format={dateFormat}
              className="w-100"
            />
          </Col>
          <Col md={4}>
            <AntdButton type="primary" onClick={() => fetchData()}>
              Filter
            </AntdButton>
          </Col>
        </Row>
      </Card>

      {/*  */}
      {/*  */}

      {tableData?.results && tableData?.length !== 0 && (
        <AntdTable
          columns={columns}
          dataSource={tableData?.results}
          pagination={{
            current: page,
            total: tableData?.count,
            pageSize: rowsPerPage,
            onChange: (current, pageSize) => {
              setPage(current);
              setRowsPerPage(pageSize);
            },
            pageSizeOptions: [25, 50, 75, 100],
          }}
          loading={
            tableDataLoading ||
            updatainteriewDataLoading ||
            deleteInterviewDataLoading
          }
        />
      )}
    </React.Fragment>
  );
}
RoleDetails.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(RoleDetails);
