import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Select,
  Button,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  TableFooter,
  TablePagination,
  Paper,
  InputBase,
  TableContainer,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import urls from "../../../url";
import Modal from "../../../hoc/modal/modal";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./CampusDrive.style";
import { useAlert } from "../../../hoc/alert/alert";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CampusDrive = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [value, setValue] = React.useState(0);
  const [Id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [isChecked, setIsChecked] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [testPaper, setTestPaper] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [comment, setComment] = useState(null);
  const [profileId, setProfileId] = useState("");
  const [position, setPosition] = useState(null);
  const [teacher, setTeacher] = useState(null);

  const [applicentName, setApplicentName] = useState("");
  const [applicantsList, setApplicantList] = useState("");

  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [viewOpenning, setViewOpennings] = useState("");

  const [selected, setSelected] = React.useState([]);
  const [assigned, setAssigned] = useState("");

  const alert = useAlert();

  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id);
    if (profileDetail[newValue].applicant_role_name === "Teacher") {
      setProfileId(2);
    } else if (profileDetail[newValue].applicant_role_name === "Coordinator") {
      setProfileId(3);
    } else if (profileDetail[newValue].applicant_role_name === "Principal") {
      setProfileId(4);
    } else if (profileDetail[newValue].applicant_role_name === "others") {
      setProfileId(9);
    }
  }

  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList,
  } = useFetch(null);

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings,
  } = useFetch(null);

  const {
    data: RolesData,
    isLoading: RolesDataLoading,
    doFetch: FetchRoles,
  } = useFetch(null);

  useEffect(() => {
    FetchRoles({
      url: urls.academicRoleList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }, [Id]);

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [positionType]);

  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [RoleId, positionType]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);
  const {
    data: profilePosition,
    isLoading: profilePositionLoading,
    doFetch: fetchPosition,
  } = useFetch(null);
  const {
    data: UpdatePosition,
    isLoading: fetchUpdatePositionLoading,
    doFetch: fetchUpdatePosition,
  } = useFetch(null);

  const {
    data: applicantsListData,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch(null);

  const {
    // data: contacted,
    isLoading: contactedLoading,
    doFetch: fetchContacted,
  } = useFetch(null);

  const {
    data: subjectDetail,
    isLoading: subjectDetailLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  const {
    data: branch,
    isLoading: branchLoading,
    doFetch: fetchBranch,
  } = useFetch(null);

  const {
    data: paperList,
    isLoading: paperListLoading,
    doFetch: fetchPaperList,
  } = useFetch(null);

  const {
    data: paperAssigned,
    isLoading: paperAssignedLoading,
    doFetch: fetchPaperAssigned,
  } = useFetch(null);

  const {
    data: searchedApplicent,
    isLoading: searchingLoading,
    doFetch: fetchSearchApplicent,
  } = useFetch(null);

  useEffect(() => {
    if (applicantsListData) {
      setApplicantList(applicantsListData);
    }
  }, [applicantsListData]);

  useEffect(() => {
    if (searchedApplicent) {
      setApplicantList(searchedApplicent);
    }
  }, [searchedApplicent]);

  let loader = null;
  if (
    paperAssignedLoading ||
    paperListLoading ||
    branchLoading ||
    subjectDetailLoading ||
    contactedLoading ||
    applicantDetailLoading ||
    applicantsListLoading ||
    profileDetailLoading ||
    searchingLoading
  ) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.academicRoleListApi,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchSubject({
        url: urls.subjectApiView,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranch({
        url: urls.branch,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchBranch, fetchProfile, fetchSubject, name]);

  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setApplicentName("");
      setId(profileDetail[0].id);
      if (profileDetail[0].applicant_role_name === "Teacher") {
        setProfileId(2);
      } else if (profileDetail[0].applicant_role_name === "Coordinator") {
        setProfileId(3);
      } else if (profileDetail[0].applicant_role_name === "Principal") {
        setProfileId(4);
      } else if (profileDetail[0].applicant_role_name === "others") {
        setProfileId(9);
      }
    }
  }, [profileDetail]);

  useEffect(() => {
    if (paperAssigned) {
      fetchApplicant({
        url: `${
          urls.assignMcqRetrieveApplicant
        }?branch=${filteredBranch}&subject=${filteredSub}${
          startDate ? `&start_date=${startDate}` : ""
        }${endDate ? `&end_date=${endDate}` : ""}&role_id=${Id}&page=${page +
          1}&page_size=${rowsPerPage || 10}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setIsChecked({});
      setSelectAll(false);
      setTestPaper("");
    }
  }, [fetchApplicant, name, paperAssigned]);

  useEffect(() => {
    if (Id) {
      fetchPaperList({
        url: urls.retrieveRecuriterPanel,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setStartDate(null);
      setEndDate(null);
      setFilteredBranch([]);
      setFilteredSub([]);
      setPage(0);
      setIsChecked({});
      setSelectAll(false);
      setTestPaper("");
    }
  }, [Id, fetchApplicant, name]);
  useEffect(() => {
    if (position) {
      fetchPosition({
        url: `${urls.roleWisePositionApi}?role=${position}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [position, name.personal_info.token]);

  const submit = () => {
    if (position == null) {
      alert.warning("Please Select Position..!");
      return;
    }
    if (teacher == null) {
      alert.warning("Please Select Teacher..!");
      return;
    }
    const obj = {
      position_id: teacher,
      applicant_id: selectedId,
    };
    fetchUpdatePosition({
      url: urls.applicantUpdatePosition,
      method: "PUT",
      body: obj,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };
  const handleGetData = () => {
    setPage(0);
    setIsChecked({});
    setSelectAll(false);
    fetchApplicant({
      url: `${urls.campusDrive}?role_id=${Id}&page=1&page_size=${rowsPerPage ||
        10}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  useEffect(() => {
    if (applicentName) {
      fetchSearchApplicent({
        url: `${urls.campusDrive}?role_id=${Id}&page=${page +
          1}&page_size=${rowsPerPage || 5}&search=${applicentName}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [applicentName]);
  const fetchApplicantData = (e) => {
    setApplicentName(e.target.value);
  };

  function functionSearch(data) {
    // fetchSearchApplicent({
    //   url: `${urls.campusDrive}?role_id=${Id}&page=${page +
    //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   }
    // });
  }

  useEffect(() => {
    if (applicentName) {
      functionSearch(applicentName);
    }
  }, [page, rowsPerPage]);

  const fetchSearch = (e) => {
    setPage(0);
    setRowsPerPage(5);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicentName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };

  function assignCampusHandler(ID, hr) {
    // const firstName = name.personal_info.first_name;
    // setEdit(firstName === hr ? false : true);
    // setOpen(true);
    setSelected([]);
    const data = {
      recruiter_id: Id,
      applicants: selected,
    };
    fetchApplicantDetail({
      url: `${urls.assignCampusDrive}`,
      method: "PUT",
      body: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
    setAssigned(applicantDetail + Math.random());
  }

  useEffect(() => {
    if ((page || rowsPerPage) && !applicentName) {
      fetchApplicant({
        url: `${urls.campusDrive}?role_id=${Id}&page=${page +
          1}&page_size=${rowsPerPage || 10}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [page, fetchApplicant, rowsPerPage, name, assigned]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(10);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(10);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const assignPaper = () => {
    if (!testPaper || !comment) {
      alert.warning("Please Fill All Fields");
      return;
    }
    const body = {
      applicant: selectedId,
      recruiter: testPaper,
      comment: comment,
      // role: Id
    };
    fetchPaperAssigned({
      url: urls.reassignedApplicant,
      method: "put",
      body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
    setOpen(false);
  };

  function profileTable() {
    let applicantTable = null;
    if (
      applicantsList &&
      applicantsList.results &&
      applicantsList.results.length === 0
    ) {
      applicantTable = (
        <React.Fragment>
          <Typography
            style={{ textAlign: "center", color: "blue", marginTop: "100px" }}
            variant="h4"
          >
            Records Not Found
          </Typography>
        </React.Fragment>
      );
    }
    if (
      applicantsList &&
      applicantsList.results &&
      applicantsList.results.length
    ) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{ marginTop: "10px" }}>
            <TableContainer className="role">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left"><strong>Select</strong></TableCell>
                  <TableCell align="left"><strong>Name</strong></TableCell>
                  <TableCell align="left"><strong>Email</strong></TableCell>
                  <TableCell align="left"><strong>Contact No</strong></TableCell>
                  <TableCell align="left"><strong>Current Location</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicantsList.results.map((details, i) => {
                  const isItemSelected = isSelected(details.id);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, details.id)}
                      role="checkbox"
                      tabIndex={-1}
                      key={details.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                      <TableCell align="left">
                        {details.applicant.user.first_name || ""}
                      </TableCell>
                      {/* <TableCell align="left">{details.Hr_name || ''}</TableCell> */}
                      <TableCell align="left">
                        {details.applicant.user.email || ""}
                      </TableCell>
                      <TableCell align="left">
                        {details.applicant.contact_no || ""}
                      </TableCell>
                      <TableCell align="left">
                        {details.applicant.current_location || ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={4}
                    labelDisplayedRows={() =>
                      `Page ${page + 1} of ${+applicantsList.total_pages}`
                    }
                    rowsPerPageOptions={[50, 75, 100]}
                    rowsPerPage={rowsPerPage || 50}
                    count={+applicantsList.count}
                    
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: "13px" }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        lastPageChange(applicantsList.total_pages - 1)
                      }
                      disabled={page === +applicantsList.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
            </TableContainer>
            <Grid container justify="center">
            <Grid item md={2} xs={12}>
            <Button
              className={classes.addButton}
              color="primary"
              size="large"
              variant="contained"
              fullWidth
              onClick={() => assignCampusHandler()}
              style={{
                marginLeft: "5%",
                marginBottom: "3rem",
                marginTop: "2rem",
                marginRight: "5%",
                width:"90%"

                // padding:"10px 50px 10px 50px"
              }}
            >
              Assign
            </Button>
            </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      );
    }
    return applicantTable;
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={5} xs={12}>
          <Typography className="headings" variant="h4"><strong>Campus Drive</strong></Typography>
        </Grid>
        <Grid item md={6} xs={12} >
          <div className={classes.search}>
            <TextField
              id="search-bar"
              align="right"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="i'm looking for..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={fetchApplicantData}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.root}>
        <Paper className={classes.paperrrrr}>
          <Grid container spacing={2} style={{padding:"10px"}}>
            <Grid item md={3} xs={12}>
              <Typography>
                {" "}
                Select Position Type &nbsp; <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={positionType || ""}
                onChange={(e) => setPositionType(e.target.value)}
                variant="outlined"
              >
                {positionTypeList.map((option, index) => (
                  <MenuItem key={index} value={option.label} name={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography> Select Role</Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={RoleId || ""}
                onChange={(e) => setRoleId(e.target.value)}
                variant="outlined"
              >
                {RolesData &&
                  RolesData.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.applicant_role_name}
                    >
                      {option.applicant_role_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>Select Opening</Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={viewOpenning || ""}
                onChange={(e) => setViewOpennings(e.target.value)}
                variant="outlined"
              >
                {viewOpenningsData &&
                  viewOpenningsData.length !== 0 &&
                  viewOpenningsData.map((option, index) => (
                    <MenuItem key={index} value={option.id} name={option.title}>
                      {option.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={2} xs={12} style={{ marginTop: "24px" }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGetData}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Divider className={classes.divider} />
        {profileTable()}
      </div>

      {loader}
    </React.Fragment>
  );
};

CampusDrive.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(CampusDrive);
