import React from "react";
import {
  Dashboard as DashboardIcon,
  Edit as EditIcon,
  NoteAdd as NoteAddIcon,
  FileCopy as FileCopyIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ListIcon from "@material-ui/icons/List";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import SwitchVideoIcon from "@material-ui/icons/SwitchVideo";
import RepeatIcon from "@material-ui/icons/Repeat";
import ForwardIcon from "@material-ui/icons/Forward";
import TodayIcon from "@material-ui/icons/Today";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ContactsIcon from "@material-ui/icons/Contacts";
import {
  Add as AddIcon,
  PermIdentity as UserIcon,
  // Menu as MenuIcon,
  // Close as CloseIcon,
} from "@material-ui/icons";
// import Profile from "../components/applicant/profile/profile";
import ViewApplicentProfile from "../components/applicant/newProfile/viewApplicentProfile/viewApplicentProfile";
// import AddProfile from '../components/applicant/addProfile/addProfile';
import ApplicantTest from "../components/applicant/applicantTest/applicantTest";
import SpokenTest from "../components/applicant/spokenTest/spokenTest";
import WrittenTest from "../components/applicant/writtenTest/writtenTest";
import F2F from "../components/applicant/f2f/f2f";
import AdminDashboard from "../components/admin/dashboard/dashboard";
import CreatePositions from "../components/admin/createPositions/createPositions";
import UpdatePositions from "../components/admin/updatePositions/updatePositions";
import CreateEmployee from "../components/admin/createEmployee/createEmployee";
import EmployeeList from "../components/admin/employeeList/employeeList";
import Dashboard from "../components/hrHead/dashboard/dashboard";
import AssignToRecruiter from "../components/hrHead/assignToRecruiter/assignToRecruiter";
import OcefDashboard from "../components/ocef/dashboard/dashboard";
import RecruiterDashboard from "../components/recruiter/dashboard/dashboard";
import EADashboard from "../components/recruiter/dashboard/eaDashboard";
import InvigilatorDashboard from "../components/recruiter/dashboard/invigilatorDashboard";
import SpokenTestCompleted from "../components/recruiter/spokenTestCompleted/spokenTestCompleted";
import SpokenScoreUpload from "../components/recruiter/spokenScoreUpload/spokenScoreUpload";
import WrittenTestCompleted from "../components/recruiter/writtenTestCompleted/writtenTestCompleted";
import WrittenScoreUpload from "../components/recruiter/writtenScoreUpload/writtenScoreUpload";
import ApplicantProfile from "../components/recruiter/applicantProfile/applicantProfile";
import McqAssignTest from "../components/recruiter/mcqTest/assignTest/assignTest";
import McqReAssignTest from "../components/recruiter/mcqTest/reAssignTest/reAssignTest";
import McqCompleted from "../components/recruiter/mcqTest/mcqCompleted/mcqCompleted";
import WrittenAssignTest from "../components/recruiter/writtenTest/assignTest/assignTest";
import WrittenReAssignTest from "../components/recruiter/writtenTest/reAssignTest/reAssignTest";
import PlanerRound from "../components/recruiter/planerRound/planerRound";
import Round3 from "../components/recruiter/round3/round3";
import Round2 from "../components/recruiter/round2/round2";
import Round1 from "../components/recruiter/round1/round1";
import AssignOtherRecruiter from "../components/recruiter/assignOtherRecruiter/assignOtherRecruiter";
import InterviewDetailsRecruiter from "../components/recruiter/interviewDetail/interviewDetail";
import InterviewPanel from "../components/admin/interviewPanel/interviewPanel";
import CreateTopic from "../components/admin/createTopic/createTopic";
import SpokenTopic from "../components/admin/spokenTopic/spokenTopic";
import QuestionPaper from "../components/admin/questionPaper/questionPaper";
import AddQuestion from "../components/admin/addQuestion/addQuestion";
import ViewQuestion from "../components/admin/viewQuestion/viewQuestion";
import OcfpMcq from "../components/recruiter/ocfpMcq/ocfpMcq";
import AssignSpokenTest from "../components/recruiter/assignSpokenTest/assignSpokenTest";
import ViewChapters from "../components/admin/viewChapters/viewChapter";
import viewSubjects from "../components/admin/viewSubjects/viewSubjects";
import Instructions from "../components/admin/instructions/instructions";

import ViewF2Fquestions from "../components/admin/FaceToFace/viewF2Fquestions";
import ViewParameter from "../components/admin/parameter/viewParameter";
import ChangePassword from "../components/admin/changePassword/changePassword";
import InterviewerDashboard from "../components/interviewer/interviewer";
import Calendar from "../components/interviewer/calendar/calendar";
import TodaysInterview from "../components/interviewer/todaysInterview/todaysInterview";
import UpCommingInterview from "../components/interviewer/upCommingInterview/upCommingInterview";
import PendingInterview from "../components/interviewer/pendingInterview/pendingInterview";
import InterviewCompleted from "../components/interviewer/interviewCompleted/interviewCompleted";
// import TodaysSpoken from '../components/interviewer/todaysSpokenTest/todaysSpoken';
import SpokenCompleted from "../components/interviewer/spokenCompleted/spokenCompleted";
import FaceToFace from "../components/recruiter/FaceToFace/faceToFace";
import OfferLetter from "../components/recruiter/offerLetter/offerLetter";
import OfferLetters from "../components/recruiter/offerLetterOutBound/offerLetter";
import OfferListViews from "../components/recruiter/offerLetterOutBound/offerView";
import ScoreAssignedBy from "../components/hrHead/scoreAssignedBy/scoreAssignedBy";
import SuperDashboard from "../components/superAdmin/dashboard/dashboard";
import UserTable from "../components/superAdmin/userTable/userTable";
import Round4 from "../components/recruiter/round4/round4";
import BussinesHeadRound from "../components/recruiter/bussinesHeadRound/bussinesHeadRound";
import HrRound from "../components/recruiter/hrRound/hrRound";
import { ResumeRepository } from "../components/common";
import DemoTest from "../components/ocef/demoTest/demoTest";
import TransferTabs from "../components/principal/TransferTabs";

import ReviewerDashBoard from "../components/reviewer/dashboard/ReviewerDashboard";
import ApplicantReview from "../components/reviewer/applicantReview/applicantReview";
import OcfpAssign from "../components/hrHead/ocfpAssign/ocfpAssign";
import OcfpReAssign from "../components/hrHead/ocfpReassign/ocfpReassign";
import OcfcInterview from "../components/interviewer/ocfcInterview/OcfcInterview";
import OcfcHrInterview from "../components/hrHead/ocfcHrInterview/OcfcInterview";
import process from "../components/applicant/process/process";
import FaceToFaceAllRounds from "../components/recruiter/faceToFaceAllRounds/faceToFaceAllRounds";
import AddRole from "../components/admin/addRole/addRole";
import AssignScore from "../components/recruiter/assignScore/assignScore";
import ViewProfile from "../components/viewProfile/viewProfile";
import CampusDrive from "../components/hrHead/campusDrive/CampusDrive";
import Parameters from "../components/interviewer/todaysInterview/Parameters";
import ApplicentHistory from "../components/admin/applicentHistory/applicentHistory";
import InterviewDetails from "../components/admin/interviewDetails/interviewDetails";
import FaceToFaceReassign from "../components/recruiter/faceToFaceReassign/facetoFaceReassign";
import SubjectHeadDashboard from "../components/subjectHead/subjectHeadDashboard";
import PrincipalDashboard from "../components/principal/principalDashboard";
import ApplicantDashboard from "../components/applicant/applicantDashboard";
import ApplicantOfferLetter from "../components/applicant/applicantOfferLetter";
import SearchApplicantProfile from "../components/recruiter/searchApplicantProfile/applicantProfile";
import OtherApplicants from "../components/admin/otherapplicants/otherApplicants";
import Reports from "../components/admin/reports/reports";
import PositionReport from "../components/admin/positionReport/positionReport";
import OfferReport from "../components/admin/offerReport/offerReport";
import CrudBranch from "../components/admin/crudBranch/crudBranch";
import CrudDesignation from "../components/admin/crudDesignation/crudDesignation";
import RoleDetails from "../components/admin/roleDetails/roleDetails";
import Branch from "../components/admin/branches/branches";
import City from "../components/admin/cities/cities";
import State from "../components/admin/state/state";
import StateCity from "../components/admin/stateCity/stateCity";
import grade from "../components/admin/grade/grade";
import PreviousCandidate from "../components/recruiter/previousCandidate/previousCandidate";
import MrfList from "../components/admin/mrf/mrfList/mrfList";
import MrfListNew from "../components/admin/mrf/mrfList/mrfListNew";
import MrfForm from "../components/admin/mrf/mrfForm/mrfForm";
import MrfFormNew from "../components/admin/mrf/mrfForm/mrfFormNew";
import CrudBranchForm from "../components/admin/CrudBranchForm/crudBranchForm";
import LinkedinScraper from "../components/admin/linkedinScraper/linkedinScraper";
import BulkCandidate from "../components/admin/bulkCandidate";

const ComponentList = {
  admin: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: AdminDashboard,
    },
    {
      name: "MRF List",
      icon: <AddIcon />,
      link: "/mrf_list",
      component: MrfList,
    },
    {
      name: "LinkedIn Scraper",
      icon: <PlaylistAddIcon />,
      link: "/linkedin_scraper",
      component: LinkedinScraper,
    },
    {
      name: "MRF List New",
      icon: <AddIcon />,
      link: "/mrf_list_new",
      component: MrfListNew,
    },
    {
      name: "MRF Form",
      icon: <AddIcon />,
      link: "/mrf_form",
      component: MrfForm,
    },
    {
      name: "MRF Form New",
      icon: <AddIcon />,
      link: "/mrf_form_new",
      component: MrfFormNew,
    },
    {
      name: "Bulk upload Candidate",
      icon: <AddIcon />,
      link: "/bulk_candidate",
      component: BulkCandidate,
    },
    {
      name: "Create Position",
      icon: <AddIcon />,
      link: "/create_positions",
      component: CreatePositions,
    },
    {
      name: "Create Role",
      icon: <AddIcon />,
      link: "/add_role",
      component: AddRole,
    },
    {
      name: "Create Employee",
      icon: <AddIcon />,
      link: "/create_Employee",
      component: CreateEmployee,
    },
    {
      name: "Position List",
      icon: <ListIcon />,
      link: "/update_positions",
      component: UpdatePositions,
    },
    {
      name: "Employee List",
      icon: <ListIcon />,
      link: "/employee_List",
      component: EmployeeList,
    },
    {
      name: "Written Topics",
      icon: <PlaylistAddIcon />,
      link: "/create_topic",
      component: CreateTopic,
    },
    {
      name: "Spoken Topics",
      icon: <PlaylistAddIcon />,
      link: "/spoken_topic",
      component: SpokenTopic,
    },
    {
      name: "MCQ Test Papers",
      icon: <PlaylistAddIcon />,
      link: "/question_paper",
      component: QuestionPaper,
    },
    {
      name: "Interview Panel",
      icon: <PlaylistAddIcon />,
      link: "/interview_panel",
      component: InterviewPanel,
    },
    {
      name: "City",
      icon: <PlaylistAddIcon />,
      link: "/city",
      component: City,
    },
    {
      name: "Branch",
      icon: <PlaylistAddIcon />,
      link: "/branch",
      component: Branch,
    },
    {
      name: "State",
      icon: <PlaylistAddIcon />,
      link: "/state",
      component: State,
    },
    {
      name: "State City Details",
      icon: <PlaylistAddIcon />,
      link: "/state_city_details",
      component: StateCity,
    },
    {
      name: "Branch details",
      icon: <PlaylistAddIcon />,
      link: "/branch_details",
      component: CrudBranch,
    },
    {
      name: "Branch Create",
      icon: <AddIcon />,
      link: "/CrudBranchForm",
      component: CrudBranchForm,
    },
    {
      name: "Designation details",
      icon: <PlaylistAddIcon />,
      link: "/designation_details",
      component: CrudDesignation,
    },
    {
      name: "Role details",
      icon: <PlaylistAddIcon />,
      link: "/role_details",
      component: RoleDetails,
    },
    {
      name: "Grade/Level",
      icon: <PlaylistAddIcon />,
      link: "/grade",
      component: grade,
    },
    {
      name: "Subjects/Topics",
      icon: <PlaylistAddIcon />,
      link: "/viewSubjects",
      component: viewSubjects,
    },
    {
      name: "Chapters",
      icon: <PlaylistAddIcon />,
      link: "/View_Chapters",
      component: ViewChapters,
    },
    {
      name: "Create Question",
      icon: <PlaylistAddIcon />,
      link: "/add_Question",
      component: AddQuestion,
    },
    {
      name: "Questions",
      icon: <PlaylistAddIcon />,
      link: "/View_Questions",
      component: ViewQuestion,
    },
    {
      name: "F2F Questions",
      icon: <PlaylistAddIcon />,
      link: "/view_F2F_questions",
      component: ViewF2Fquestions,
    },
    {
      name: "F2F Parameters",
      icon: <PlaylistAddIcon />,
      link: "/View_F2F_parameter",
      component: ViewParameter,
    },
    {
      name: "Instructions",
      icon: <PlaylistAddIcon />,
      link: "/View_Instructions",
      component: Instructions,
    },
    {
      name: "Reset Password",
      icon: <LockOpenIcon />,
      link: "/Reset_Password",
      component: ChangePassword,
    },
    {
      name: "Interview Details",
      icon: <PlaylistAddIcon />,
      link: "/interview_details",
      component: InterviewDetails,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      name: "Other Applicants",
      icon: <ContactsIcon />,
      link: "/other_applicants",
      component: OtherApplicants,
    },
    // {
    //   name: "Applicant History",
    //   icon: <FileCopyIcon />,
    //   link: "/Score_Assigned_By",
    //   component: ScoreAssignedBy
    // },
    {
      name: "Resume Repository",
      icon: <FileCopyIcon />,
      link: "/resume_repository",
      component: ResumeRepository,
    },
    {
      name: "Reports",
      icon: <FileCopyIcon />,
      link: "/report",
      component: Reports,
      children: [
        {
          name: "Users Report",
          icon: <FileCopyIcon />,
          link: "/report",
        },
      ],
    },
    {
      name: "Position Report",
      icon: <FileCopyIcon />,
      link: "/position_report",
      component: PositionReport,
    },
    {
      name: "Offer Report",
      icon: <FileCopyIcon />,
      link: "/offer_report",
      component: OfferReport,
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
  ],
  applicant: [
    // {
    //   link: "/view_profile",
    //   component: ViewProfile
    // },
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: ApplicantDashboard,
    },
    // {
    //   name: 'Personal',
    //   icon: <EditIcon />,
    //   link: '/applicant_Profile',
    //   component: AddProfile
    // },
    {
      name: "Profile",
      icon: <EditIcon />,
      link: "/applicant_ProfileAdd",
      component: ViewApplicentProfile,
    },
    {
      name: "Process",
      icon: <UserIcon />,
      link: "/process",
      component: process,
    },
    {
      // name: "Applicant Test",
      // icon: <FileCopyIcon />,
      link: "/applicant_test",
      component: ApplicantTest,
    },
    {
      // name: "Written Test",
      // icon: <UserIcon />,
      link: "/written_test",
      component: WrittenTest,
    },
    {
      // name: "Spoken Test",
      // icon: <UserIcon />,
      link: "/spoken_test",
      component: SpokenTest,
    },
    {
      // name: "Face To Face Round",
      // icon: <UserIcon />,
      link: "/facetofaceround",
      component: F2F,
    },
    {
      name: "Offer Letter",
      icon: <FileCopyIcon />,
      link: "/applicant_offer_letter",
      component: ApplicantOfferLetter,
    },
  ],
  hrhead: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: Dashboard,
    },
    // {
    //   name: "Applicant Profile",
    //   icon: <UserIcon />,
    //   link: "/applicant_profile",
    //   component: ApplicantProfile
    // },
    {
      name: "Assign To Recruiter",
      icon: <ForwardIcon />,
      link: "/assignToRecruiter",
      component: AssignToRecruiter,
    },
    {
      name: "ReAssign To Recruiter",
      icon: <ForwardIcon />,
      link: "/assignToOtherRecruiter",
      component: AssignOtherRecruiter,
    },
    {
      name: "Todays Interviews",
      icon: <TodayIcon />,
      link: "/todays_interview",
      component: TodaysInterview,
    },
    {
      name: "UpComing Interviews",
      icon: <WatchLaterIcon />,
      link: "/upComming_interview",
      component: UpCommingInterview,
    },
    {
      name: "Resume Repository",
      icon: <FileCopyIcon />,
      link: "/resume_repository",
      component: ResumeRepository,
    },
    {
      name: "Ocfp Assign",
      icon: <FileCopyIcon />,
      link: "/Ocfp_Assign",
      component: OcfpAssign,
    },
    {
      name: "Ocfp ReAssign",
      icon: <FileCopyIcon />,
      link: "/Ocfp_ReAssign",
      component: OcfpReAssign,
    },
    {
      name: "OCFC Interview",
      icon: <FileCopyIcon />,
      link: "/ocfcHrInterview",
      component: OcfcHrInterview,
    },
    {
      name: "Campus Drive",
      icon: <FileCopyIcon />,
      link: "/campusDrive",
      component: CampusDrive,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      name: "Other Applicants",
      icon: <ContactsIcon />,
      link: "/other_applicants",
      component: OtherApplicants,
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
  ],
  subjecthead: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: SubjectHeadDashboard,
    },
    {
      name: "Add Question",
      icon: <AddIcon />,
      link: "/add_Question",
      component: AddQuestion,
    },
    {
      name: "View Questions",
      icon: <FileCopyIcon />,
      link: "/View_Questions",
      component: ViewQuestion,
    },
    {
      name: "Todays Interviews",
      icon: <TodayIcon />,
      link: "/todays_interview",
      component: TodaysInterview,
    },
    {
      name: "UpComing Interviews",
      icon: <WatchLaterIcon />,
      link: "/upComming_interview",
      component: UpCommingInterview,
    },
    {
      name: "Resume Repository",
      icon: <FileCopyIcon />,
      link: "/resume_repository",
      component: ResumeRepository,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },

    {
      link: "/view_profile",
      component: ViewProfile,
    },
  ],
  recruiter: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: RecruiterDashboard,
    },
    {
      name: "Search Applicant Profile",
      icon: <FindInPageIcon />,
      link: "/search_applicant_profile",
      component: SearchApplicantProfile,
    },
    {
      name: "Applicant Profile",
      icon: <UserIcon />,
      link: "/applicant_profile",
      component: ApplicantProfile,
    },
    // {
    //   name: "Assign MCQ Test",
    //   icon: <EditIcon />,
    //   link: "/assign_test",
    //   component: McqAssignTest
    // },
    // {
    //   name: "ReAssign MCQ Test",
    //   icon: <EditIcon />,
    //   link: "/reassign_test",
    //   component: McqReAssignTest
    // },
    // {
    //   name: "Mcq Test Completed",
    //   icon: <EditIcon />,
    //   link: "/mcq_Completed",
    //   component: McqCompleted
    // },
    // {
    //   name: 'Assign Written Test',
    //   icon: <NoteAddIcon />,
    //   link: '/assign_written_test',
    //   component: WrittenAssignTest
    // },
    // {
    //   name: 'ReAssign Written Test',
    //   icon: <FileCopyIcon />,
    //   link: '/reassign_written_test',
    //   component: WrittenReAssignTest
    // },
    // {
    //   name: "Written Test Completeed",
    //   icon: <FileCopyIcon />,
    //   link: "/writtenTestCompleted",
    //   component: WrittenTestCompleted
    // },
    {
      name: "Score Upload",
      icon: <CloudUploadIcon />,
      link: "/Score_upload",
      component: AssignScore,
    },
    // {
    //   name: "Written Score Upload",
    //   icon: <CloudUploadIcon />,
    //   link: "/writtenScoreUpload",
    //   component: WrittenScoreUpload
    // },
    {
      name: "OcfpMcq Applicant",
      icon: <FileCopyIcon />,
      link: "/Ocfp_MCQ",
      component: OcfpMcq,
    },
    // {
    //   name: 'Assign Spoken Test',
    //   icon: <UserIcon />,
    //   link: '/AssignSpokenTest',
    //   component :AssignSpokenTest
    // },
    // {
    //   name: "Spoken Test Completeed",
    //   icon: <FileCopyIcon />,
    //   link: "/spoken_test_completed",
    //   component: SpokenTestCompleted
    // },
    // {
    //   name: "Spoken Score Upload",
    //   icon: <CloudUploadIcon />,
    //   link: "/spoken_score_upload",
    //   component: SpokenScoreUpload
    // },
    // {
    //   name: "Planer Round ",
    //   icon: <FileCopyIcon />,
    //   link: "/planer_round",
    //   component: PlanerRound
    // },
    {
      name: "Face To Face",
      icon: <SwitchVideoIcon />,
      link: "/Face_To_Face_All",
      component: FaceToFaceAllRounds,
    },
    {
      name: "Re Schedule Face To Face",
      icon: <RepeatIcon />,
      link: "/Face_To_Face_Reassign",
      component: FaceToFaceReassign,
    },

    // {
    //   name: "Round 1",
    //   icon: <FileCopyIcon />,
    //   link: "/Round_1",
    //   component: Round1
    // },
    // {
    //   name: "Round 2",
    //   icon: <FileCopyIcon />,
    //   link: "/round_2",
    //   component: Round2
    // },
    // {
    //   name: "Round 3",
    //   icon: <FileCopyIcon />,
    //   link: "/round_3",
    //   component: Round3
    // },
    // {
    //   name: "Round 4",
    //   icon: <FileCopyIcon />,
    //   link: "/round_4",
    //   component: Round4
    // },
    // {
    //   name: "BussinesHeadRound",
    //   icon: <FileCopyIcon />,
    //   link: "/Bussines_Head_Round",
    //   component: BussinesHeadRound
    // },
    // {
    //   name: "HR Round",
    //   icon: <FileCopyIcon />,
    //   link: "/hr_round",
    //   component: HrRound
    // },
    {
      name: "Assign To Other Recruiter",
      icon: <ForwardIcon />,
      link: "/assignToOtherRecruiter",
      component: AssignOtherRecruiter,
    },
    {
      name: "Interview Detail",
      icon: <PlaylistAddIcon />,
      link: "/interview_details",
      component: InterviewDetailsRecruiter,
    },
    // {
    //   name: "Todays Interviews",
    //   icon: <TodayIcon />,
    //   link: "/todays_interview",
    //   component: TodaysInterview
    // },
    // {
    //   name: "UpComing Interviews",
    //   icon: <WatchLaterIcon />,
    //   link: "/upComming_interview",
    //   component: UpCommingInterview
    // },
    // {
    //   name: "Add Positions",
    //   icon: <AddIcon />,
    //   link: "/create_positions",
    //   component: CreatePositions
    // },

    // {
    //   name: "Face To Face",
    //   icon: <FileCopyIcon />,
    //   link: "/Face_To_Face",
    //   component: FaceToFace
    // },
    // {
    //   name: "Offer Letter",
    //   icon: <FileCopyIcon />,
    //   link: "/Offer_Letter",
    //   component: OfferLetter
    // },
    {
      name: "Offer Letter",
      icon: <MailOutlineIcon />,
      link: "/Offer_Letters",
      component: OfferLetters,
    },
    {
      name: "Released Offer Letter List",
      icon: <ContactMailIcon />,
      link: "/Offer_Letters_List",
      component: OfferListViews,
    },
    {
      name: "Resume Repository",
      icon: <FileCopyIcon />,
      link: "/resume_repository",
      component: ResumeRepository,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      name: "Other Applicants",
      icon: <ContactsIcon />,
      link: "/other_applicants",
      component: OtherApplicants,
    },
    {
      name: "Previous Candidate",
      icon: <UserIcon />,
      link: "/previous_candidate",
      component: PreviousCandidate,
    },

    {
      name: "Reports",
      icon: <FileCopyIcon />,
      link: "/report",
      component: Reports,
      children: [
        {
          name: "Users Report",
          icon: <FileCopyIcon />,
          link: "/report",
        },
      ],
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
    {
      link: "/parameters",
      component: Parameters,
    },
  ],
  ocfp: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: OcefDashboard,
    },
    {
      name: "DemoTest",
      icon: <UserIcon />,
      link: "/demo_test",
      component: DemoTest,
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
  ],
  interviewer: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: InterviewerDashboard,
    },
    {
      name: "Calendar",
      icon: <UserIcon />,
      link: "/calendar",
      component: Calendar,
    },
    {
      name: "Todays Interviews",
      icon: <TodayIcon />,
      link: "/todays_interview",
      component: TodaysInterview,
    },
    {
      name: "UpComing Interviews",
      icon: <WatchLaterIcon />,
      link: "/upComming_interview",
      component: UpCommingInterview,
    },
    {
      name: "Pending Interviews",
      icon: <WatchLaterIcon />,
      link: "/pending_interview",
      component: PendingInterview,
    },
    {
      name: "Completed Interviews",
      icon: <FileCopyIcon />,
      link: "/interviews_completed",
      component: InterviewCompleted,
    },
    {
      name: "OCFC Interview",
      icon: <FileCopyIcon />,
      link: "/ocfcInterview",
      component: OcfcInterview,
    },
    {
      name: "Resume Repository",
      icon: <FileCopyIcon />,
      link: "/resume_repository",
      component: ResumeRepository,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
    // {
    //   name: 'Todays Spoken',
    //   icon: <UserIcon />,
    //   link: '/todays_Spoken',
    //   component :TodaysSpoken
    // },
    // {
    //   name: 'Spoken Completed',
    //   icon: <FileCopyIcon />,
    //   link: '/Spoken_Completed',
    //   component :SpokenCompleted
    // },
  ],
  reviewer: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: ReviewerDashBoard,
    },
    {
      name: "Applicant Review",
      icon: <UserIcon />,
      link: "/applicant_review",
      component: ApplicantReview,
    },
    {
      name: "Resume Repository",
      icon: <FileCopyIcon />,
      link: "/resume_repository",
      component: ResumeRepository,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
  ],
  superadmin: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: SuperDashboard,
    },
    {
      name: "User Table",
      icon: <UserIcon />,
      link: "/user_Table",
      component: UserTable,
    },
    {
      name: "Resume Repository",
      icon: <FileCopyIcon />,
      link: "/resume_repository",
      component: ResumeRepository,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
  ],
  principal: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: PrincipalDashboard,
    },
    {
      name: "Transfer Requests",
      icon: <DashboardIcon />,
      link: "/",
      component: TransferTabs,
    },
    {
      name: "Todays Interviews",
      icon: <TodayIcon />,
      link: "/todays_interview",
      component: TodaysInterview,
    },
    {
      name: "UpComing Interviews",
      icon: <WatchLaterIcon />,
      link: "/upComming_interview",
      component: UpCommingInterview,
    },
    {
      name: "Pending Interviews",
      icon: <WatchLaterIcon />,
      link: "/pending_interview",
      component: PendingInterview,
    },
    {
      name: "Completed Interviews",
      icon: <FileCopyIcon />,
      link: "/interviews_completed",
      component: InterviewCompleted,
    },
    {
      name: "Resume Repository",
      icon: <FileCopyIcon />,
      link: "/resume_repository",
      component: ResumeRepository,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
  ],
  ea: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: EADashboard,
    },
    {
      name: "Search Applicant Profile",
      icon: <FindInPageIcon />,
      link: "/search_applicant_profile",
      component: SearchApplicantProfile,
    },
    // {
    //   name: "Applicant Profile",
    //   icon: <UserIcon />,
    //   link: "/applicant_profile",
    //   component: ApplicantProfile
    // },
    {
      name: "Score Upload",
      icon: <CloudUploadIcon />,
      link: "/Score_upload",
      component: AssignScore,
    },
    // {
    //   name: "OcfpMcq Applicant",
    //   icon: <FileCopyIcon />,
    //   link: "/Ocfp_MCQ",
    //   component: OcfpMcq
    // },
    {
      name: "Face To Face",
      icon: <SwitchVideoIcon />,
      link: "/Face_To_Face_All",
      component: FaceToFaceAllRounds,
    },
    {
      name: "Re Schedule Face To Face",
      icon: <RepeatIcon />,
      link: "/Face_To_Face_Reassign",
      component: FaceToFaceReassign,
    },
    {
      name: "Assign To Other Recruiter",
      icon: <ForwardIcon />,
      link: "/assignToOtherRecruiter",
      component: AssignOtherRecruiter,
    },
    {
      name: "Todays Interviews",
      icon: <TodayIcon />,
      link: "/todays_interview",
      component: TodaysInterview,
    },
    {
      name: "UpComing Interviews",
      icon: <WatchLaterIcon />,
      link: "/upComming_interview",
      component: UpCommingInterview,
    },
    // {
    //   name: "Add Positions",
    //   icon: <AddIcon />,
    //   link: "/create_positions",
    //   component: CreatePositions
    // },

    // {
    //   name: "Offer Letter",
    //   icon: <MailOutlineIcon />,
    //   link: "/Offer_Letters",
    //   component: OfferLetters
    // },
    // {
    //   name: "Released Offer Letter List",
    //   icon: <FileCopyIcon />,
    //   link: "/Offer_Letters_List",
    //   component: OfferListViews
    // },
    // {
    //   name: "Resume Repository",
    //   icon: <FileCopyIcon />,
    //   link: "/resume_repository",
    //   component: ResumeRepository
    // },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      name: "Other Applicants",
      icon: <ContactsIcon />,
      link: "/other_applicants",
      component: OtherApplicants,
    },
    {
      name: "Reports",
      icon: <FileCopyIcon />,
      link: "/report",
      component: Reports,
      children: [
        {
          name: "Users Report",
          icon: <FileCopyIcon />,
          link: "/report",
        },
      ],
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
    {
      link: "/parameters",
      component: Parameters,
    },
  ],
  invigilator: [
    {
      name: "Dashboard",
      icon: <DashboardIcon />,
      link: "/",
      component: InvigilatorDashboard,
    },
    {
      name: "Score Upload",
      icon: <CloudUploadIcon />,
      link: "/Score_upload",
      component: AssignScore,
    },
    {
      name: "Applicant History",
      icon: <PlaylistAddIcon />,
      link: "/applicant_history",
      component: ApplicentHistory,
    },
    {
      link: "/view_profile",
      component: ViewProfile,
    },
  ],
};

export default ComponentList;
