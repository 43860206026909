import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Typography,
  Table,
  Tag,
  Modal,
  message,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import urls from "../../url";
import axios from "axios";
import { RiseOutlined } from "@ant-design/icons";
import moment from "moment";
import { useHistory } from "react-router-dom";

const TeacherReferralRepo = () => {
  const history = useHistory();
  const [filterForm] = useForm();
  const [openPositionForm] = useForm();
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [boardList, setBoardList] = useState([
    {
      id: "1",
      board: "cbse",
      value: "CBSE",
    },
    {
      id: "2",
      board: "icse",
      value: "ICSE",
    },
    {
      id: "3",
      board: "state",
      value: "STATE",
    },
    {
      id: "4",
      board: "igcse",
      value: "IGCSE",
    },
    {
      id: "5",
      board: "ib",
      value: "IB",
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openPositionList, setOpenPositionList] = useState([]);
  const [applicantId, setApplicantId] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [multiSelect, setMultiSelect] = useState(false);
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const user_id = useSelector(
    (state) => state?.auth?.user?.personal_info?.user_id
  );

  useEffect(() => {
    fetchCityList();
    fetchRoleList();
    fetchSubjectList();
  }, []);
  useEffect(() => {
    const values = filterForm.getFieldsValue();
    fetchTableData({
      page: page,
      referring_city_branch: values?.city,
      branch_name: values?.branch,
      role: values?.role,
      subject: values?.subject,
      experience_in_school: values?.board,
    });
  }, [page]);

  const fetchCityList = () => {
    axios
      .get(`${urls.referralCityList}`)
      .then((response) => {
        if (response?.status === 200) {
          setCityList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      });
  };
  const fetchBranchList = (params = {}) => {
    axios
      .get(`${urls.referralBranchList}`, {
        params: params,
      })
      .then((response) => {
        if (response?.status === 200) {
          setBranchList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      });
  };
  const fetchRoleList = () => {
    axios
      .get(`${urls.referralRoleList}`, {})
      .then((response) => {
        if (response?.status === 200) {
          setRoleList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      });
  };
  const fetchSubjectList = () => {
    axios
      .get(`${urls.referralSubjectList}`, {})
      .then((response) => {
        if (response?.status === 200) {
          setSubjectList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      });
  };
  const fetchTableData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.teacherReferralListApi}`, {
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setTableData(response?.data);
          console.log("kk", response?.data?.results);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPositionTypeList = () => {
    axios
      .get(`${urls.positionTypes}`)
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };
  const fetchDesignationList = (params = {}) => {
    axios
      .get(`${urls.designationListApi}`, {
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };
  const fetchOpenPositions = (params = {}) => {
    axios
      .get(`${urls.openPositionListApi}`, {
        params: params,
      })
      .then((response) => {
        if (response?.status === 200) {
          setOpenPositionList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      })
      .finally(() => {});
  };
  const handleCityChange = () => {
    const city = filterForm.getFieldsValue()?.city;
    filterForm.setFieldsValue({
      branch: null,
    });
    if (city) {
      fetchBranchList({
        city_id: city,
      });
    } else {
      setBranchList([]);
    }
  };
  const handlePositionTypeChange = () => {
    const position_type = openPositionForm.getFieldsValue()?.position_type;
    openPositionForm.setFieldsValue({
      designation: null,
    });
    if (position_type) {
      fetchDesignationList({
        position_type: position_type,
      });
    } else {
      setDesignationList([]);
    }
  };
  const handleDesignationChange = () => {
    const designation = openPositionForm.getFieldsValue()?.designation;
    const position_type = openPositionForm.getFieldsValue()?.position_type;
    openPositionForm.setFieldsValue({
      open_position: null,
    });
    if (designation) {
      fetchOpenPositions({
        position_type_fk: position_type,
        designation_fk: designation,
      });
    } else {
      setOpenPositionList([]);
    }
  };
  const handleClear = () => {
    filterForm.resetFields();
    setSelectedRowKeys([]);
    setMultiSelect(false);
    setDesignationList([]);
    setOpenPositionList([]);
    handleFilter();
  };
  const handleFilter = () => {
    if (page === 1) {
      // if page is 1 fetch table data with filters
      const values = filterForm.getFieldsValue();
      fetchTableData({
        page: page,
        referring_city_branch: values?.city,
        branch_name: values?.branch,
        role: values?.role,
        subject: values?.subject,
        experience_in_school: values?.board,
      });
    } else {
      // else set page to 1 useEffect will do rest of the work
      setPage(1);
    }
  };
  const handleOpenModal = () => {
    fetchPositionTypeList();
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    openPositionForm.resetFields();
    setModalOpen(false);
  };
  const handleSubmit = () => {
    setSubmitLoading(true);
    const values = openPositionForm.getFieldsValue();
    const formData = new FormData();
    formData.append("candidate", multiSelect ? selectedRowKeys : applicantId);
    formData.append("position_apply", values?.open_position);
    formData.append("recruiter", user_id);
    axios
      .post(`${urls.applicantPositionMappingApi}`, formData)
      .then((response) => {
        if (response?.status === 200) {
          message.success("Applicant(s) Mapped Successfully");
          setSelectedRowKeys([]);
          setMultiSelect(false);
          handleCloseModal();
          handleFilter();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something Went Wrong !"
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  const handleMultiSelectButton = () => {
    if (multiSelect) {
      if (selectedRowKeys.length) {
        handleOpenModal();
      } else {
        message.warning("Please select atleast one applicant");
      }
    } else {
      setMultiSelect(true);
    }
  };
  const handleCloseMultiSelect = () => {
    setSelectedRowKeys([]);
    setMultiSelect(false);
  };

  const handleHistory = (position_id) => {
    axios
      .get(`${urls.retrieveApplicants}${position_id}/`, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (data) => <Typography>{data?.candidate_name}</Typography>,
    },
    {
      title: "Contact",
      key: "contact",
      render: (data) => <Typography>{data?.candidate_phone_number}</Typography>,
    },
    {
      title: "Role",
      key: "role",
      render: (data) => <Typography>{data?.role}</Typography>,
    },
    {
      title: "Subject",
      key: "subject",
      render: (data) => <Typography>{data?.subject}</Typography>,
    },
    {
      title: "Board",
      key: "board",
      render: (data) => (
        <Typography>{data?.experience_in_school.toUpperCase()}</Typography>
      ),
    },
    {
      title: "Experience",
      key: "experience",
      align: "center",
      render: (data) => (
        <Typography>{data?.teaching_experience} Years</Typography>
      ),
    },
    {
      title: "Location",
      key: "location",
      render: (data) => (
        <Typography>{`${data?.branch}-${data?.city_name}`}</Typography>
      ),
    },
    {
      title: "Current Status",
      key: "status",
      render: (data) => (
        <Typography style={{ textTransform: "capitalize" }}>
          {data?.current_progress === null ? "pending" : data?.current_progress}
        </Typography>
      ),
    },
    {
      title: "Resume",
      key: "resume",
      render: (data) => (
        <a href={data?.resume} target="_blank">
          <Tag color="purple">Resume</Tag>
        </a>
      ),
    },
    {
      title: "Shortlist",
      key: "action",
      render: (data) =>
        data?.is_mapped ? (
          <Tag color="green">{data?.position_apply_name}</Tag>
        ) : (
          <Tag
            color="blue"
            style={{ cursor: multiSelect ? "not-allowed" : "pointer" }}
            onClick={() => {
              if (!multiSelect) {
                setApplicantId(data?.id);
                handleOpenModal();
              }
            }}
          >
            Shortlist
          </Tag>
        ),
    },
    {
      title: "Applicant History",
      key: "applicant",
      render: (data) => (
        <Tag
          icon={<RiseOutlined />}
          color={data?.is_mapped ? "green" : "red"}
          onClick={() => {
            if (data?.is_mapped) {
              history.push({
                pathname: `/mrf_form_applicants`,
                state: {
                  rowData: data,
                  mrf: data?.mrf,
                  position_id: data?.position_apply,
                  url: "/teacher_referral_repository",
                },
              });
            }
          }}
          style={{
            cursor: data?.is_mapped ? "pointer" : "not-allowed",
          }}
        >
          Go to Applicant
        </Tag>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.is_mapped === true,
    }),
  };

  return (
    <>
      <Card>
        <Row justify="space-between">
          <Typography className="page_title">
            Teacher Referral Repository
          </Typography>
          <Space>
            {multiSelect && (
              <Button
                size="small"
                type="default"
                style={{ color: "red" }}
                onClick={handleCloseMultiSelect}
              >
                Close Multi Select
              </Button>
            )}
            <Button
              size="small"
              type="primary"
              onClick={handleMultiSelectButton}
            >
              {multiSelect ? "Select Position" : "Select Multiple Applicants"}
            </Button>
          </Space>
        </Row>
        <Form
          id="filterForm"
          form={filterForm}
          onFinish={handleFilter}
          layout="vertical"
        >
          <Row align="middle" gutter={24}>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 8 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
            >
              <Form.Item name="city" label="City">
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select City"
                  onChange={handleCityChange}
                  style={{ width: "100%" }}
                >
                  {cityList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.city_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 8 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
            >
              <Form.Item name="branch" label="Branch">
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Branch"
                  style={{ width: "100%" }}
                >
                  {branchList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.branch_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 8 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
            >
              <Form.Item name="role" label="Role">
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Role"
                  style={{ width: "100%" }}
                >
                  {roleList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 8 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
            >
              <Form.Item name="subject" label="Subject">
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Subject"
                  style={{ width: "100%" }}
                >
                  {subjectList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.subject_name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 8 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
            >
              <Form.Item name="board" label="Board">
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Board"
                  style={{ width: "100%" }}
                >
                  {boardList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.board}>
                      {each?.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Space>
                <Button type="default" onClick={handleClear}>
                  Clear All
                </Button>
                <Button form="filterForm" htmlType="submit" type="primary">
                  Filter
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        <Table
          rowSelection={multiSelect && rowSelection}
          columns={columns}
          dataSource={tableData?.results}
          loading={loading}
          scroll={{ x: "max-content" }}
          rowKey={(data) => data?.id}
          pagination={{
            showSizeChanger: false,
            total: tableData?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          expandable={{
            expandedRowRender: (record) => (
              <Row align="middle" gutter={48}>
                <Col>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Referred By : </span>
                    <span style={{ textTransform: "uppercase" }}>
                      {`${record?.employee_name} - [ ${record?.erp_id} ]`}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Created At : </span>
                    <span style={{ textTransform: "uppercase" }}>
                      {moment(record?.created_at).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Position: </span>
                    <span>
                      {record?.position_type_name ?? "Not shortlisted yet"}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div>
                    <span style={{ fontWeight: "bold" }}>Designation : </span>
                    <span>
                      {record?.designation_name ?? "Not shortlisted yet"}
                    </span>
                  </div>
                </Col>
              </Row>
            ),
          }}
        />
      </Card>
      <Modal
        title={<Typography>Select Open Position</Typography>}
        open={modalOpen}
        onCancel={handleCloseModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row className="justify-content-end" gutter={6}>
            <Col>
              <Button type="default" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                form="openPositionForm"
                htmlType="submit"
                loading={submitLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="openPositionForm"
          form={openPositionForm}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please select this field",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Position Type"
                  style={{ width: "100%" }}
                  onChange={handlePositionTypeChange}
                >
                  {positionTypeList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.position_type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="designation"
                label="Designation"
                rules={[
                  {
                    required: true,
                    message: "Please select this field",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Designation"
                  style={{ width: "100%" }}
                  onChange={handleDesignationChange}
                >
                  {designationList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="open_position"
                label="Open Position"
                rules={[
                  {
                    required: true,
                    message: "Please select this field",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Open Position"
                  style={{ width: "100%" }}
                >
                  {openPositionList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default TeacherReferralRepo;
