import React, { useState, useEffect } from "react";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import {
  Card,
  Typography,
  Row,
  Col,
  Badge,
  Space,
  Button,
  Statistic,
  Modal,
  Table,
} from "antd";
import {
  InfoCircleFilled,
  FileSearchOutlined,
  UserOutlined,
  FileDoneOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

const applicantColumns = [
  {
    title: "S.No",
    key: "s_no",
    width: 80,
    render: (data, data2, index) => {
      return <div>{index + 1}</div>;
    },
  },
  {
    title: "Applicant Name",
    key: "applicant_name",
    width: 150,
    render: (data) => <div>{data?.applicant?.user?.first_name}</div>,
  },
  {
    title: "Contact",
    key: "contact",
    width: 100,
    render: (data) => <div>{data?.applicant?.user?.username}</div>,
  },
  {
    title: "Role",
    key: "role",
    width: 100,
    render: (data) => (
      <div>{data?.applicant?.applicant_role?.applicant_role_name}</div>
    ),
  },
  {
    title: "City",
    key: "city",
    width: 100,
    render: (data) => <div>{data?.city_name}</div>,
  },
  {
    title: "Branch",
    key: "branch",
    width: 100,
    render: (data) => <div>{data?.branch_name}</div>,
  },
  {
    title: "Registration Date",
    key: "registration_date",
    width: 150,
    render: (data) => <div>{data?.applicant?.register_date}</div>,
  },
];
const interviewColumns = [
  {
    title: "S.No",
    key: "s_no",
    width: 80,
    render: (data, data2, index) => {
      return <div>{index + 1}</div>;
    },
  },
  {
    title: "Applicant Name",
    key: "applicant_name",
    width: 150,
    render: (data) => <div>{data?.applicant_data?.name ?? "-"}</div>,
  },
  {
    title: "Mobile No.",
    key: "mobile_no",
    width: 100,
    render: (data) => <div>{data?.applicant_data?.contact_no ?? "-"}</div>,
  },
  {
    title: "Registration Date",
    key: "registration_date",
    width: 120,
    render: (data) => <div>{data?.applicant_data?.register_date ?? "-"}</div>,
  },
  {
    title: "Interview Date",
    key: "Interview_date",
    width: 120,
    render: (data) => (
      <div>{dayjs(data?.start_date).format("YYYY-MM-DD") ?? "-"}</div>
    ),
  },

  {
    title: "Interviewer",
    key: "interviewer",
    width: 120,
    render: (data) => (
      <div>{data?.zoom?.interviewer_data?.interviewer_name ?? "-"}</div>
    ),
  },

  {
    title: "Interviewer E-mail ID",
    key: "interviewer_email",
    width: 100,
    render: (data) => (
      <div>{data?.zoom?.interviewer_data?.interviewer_email ?? "-"}</div>
    ),
  },
];
const offerColumns = [
  {
    title: "S.No",
    key: "s_no",
    width: 80,
    render: (data, data2, index) => {
      return <div>{index + 1}</div>;
    },
  },
  {
    title: "Applicant Name",
    key: "applicant_name",
    width: 150,
    render: (data) => <div>{data?.candidate_name}</div>,
  },
  {
    title: "Applicant Email",
    key: "applicant_email",
    width: 100,
    render: (data) => <div>{data?.candidate_email}</div>,
  },
  {
    title: "Designation",
    key: "designation",
    width: 100,
    render: (data) => <div>{data?.designation}</div>,
  },
  {
    title: "Date Of Joining",
    key: "date_of_joining",
    width: 100,
    render: (data) => <div>{data?.date_of_joining}</div>,
  },
  {
    title: "CTC (LPA)",
    key: "ctc",
    width: 100,
    render: (data) => <div>{data?.fixed_gross_per_month}</div>,
  },
  {
    title: "Recruiter",
    key: "recruiter",
    width: 150,
    render: (data) => <div>{data?.submited_by}</div>,
  },
];
const RecruiterDashboard = () => {
  const history = useHistory();
  const [profile] = useState(JSON.parse(localStorage.getItem("user")));
  const [openInterviewModal, setOpenInterviewModal] = useState(false);
  const [openApplicantModal, setOpenApplicantModal] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [interviewPage, setInterviewPage] = useState(1);
  const [interviewRowsPerPages, setInterviewRowsPerPages] = useState(50);
  const [applicantPage, setApplicantPage] = useState(1);
  const [applicantRowsPerPages, setApplicantRowsPerPages] = useState(50);
  const [offerLetterPage, setOfferLetterPage] = useState(1);
  const [rowsPerPageOfferLetter, setRowsPerPageOfferLetter] = useState(50);
  const [interviewStatus, setInterviewStatus] = useState("");
  const [applicantIid, setApplicantIid] = useState("");
  const [offerLetterId, setOfferLetterId] = useState("");
  const [interviewData, setInterviewData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [paramsObj, setParamsObj] = useState({});

  const {
    data: dashboardCount,
    isLoading: dasboardLoading,
    doFetch: FetchDashboardStatus,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: ApplicantCount,
    isLoading: applicantLoading,
    doFetch: getReportCountList,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: interviewDetailsCount,
    isLoading: interviewDetailsCountLoading,
    doFetch: getInterviewDetailsCountList,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: offerLetterDashboardCount,
    isLoading: offerLetterDashboardCountLoading,
    doFetch: getofferLetterCountList,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: registeredApplicantCandidates,
    isLoading: registeredApplicantCandidatesLoading,
    doFetch: todayRegisteredCandidates,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: positionCountOpeinngs,
    isLoading: positionCountOpeinngsLoading,
    doFetch: getPositionCount,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: getInterviewDetailData,
    isLoading: viewInterviewDetailLoading,
    doFetch: fetchgetInterViewDetail,
  } = useFetch("", { suppressAlert: true });

  const {
    data: recruiterDetailsCount,
    isLoading: recruiterDetailsCountLoading,
    doFetch: fetchgetRecruiterDetailsCount,
  } = useFetch("", { suppressAlert: true });

  const {
    data: offerLetterCountData,
    isLoading: offerLetterCountDataLoading,
    doFetch: offerLetterCountsList,
  } = useFetch(null, { suppressAlert: true });

  let loader = null;
  if (dasboardLoading || applicantLoading) {
    loader = <Loader open />;
  }

  function fetchInterviews(params = {}) {
    setParamsObj(params);
    setLoading(true);
    axios
      .get(`${urls.allInterviewsApi}`, { params: params })
      .then((res) => {
        setInterviewData(res?.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getInterviewDetails(
    status,
    page = interviewPage,
    rows = interviewRowsPerPages
  ) {
    // setOpenInterviewModal(true);
    setInterviewStatus(status);
    fetchgetInterViewDetail({
      url: `${
        urls.recruiterInterviewDetails
      }?interview_status=${status}&page=${page}&page_size=${rows || 50}`,
      method: "GET",
      headers: {
        "Content-Type": "apinterviewStatusplication/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }

  function getRecruiterCount() {
    fetchgetRecruiterDetailsCount({
      url: `${urls.recruiterInterViewDetail}`,
      method: "GET",
      headers: {
        "Content-Type": "apinterviewStatusplication/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }

  function getApplicantDetails(
    id,
    page = applicantPage,
    rows = applicantRowsPerPages
  ) {
    // setOpenApplicantModal(true);
    setApplicantIid(id);
    todayRegisteredCandidates({
      url: `${
        urls.todayRegisteredCandidate
      }?dashboard_id=${id}&page=${page}&page_size=${rows || 50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }

  function getOfferLetter(
    id,
    page = offerLetterPage,
    rows = rowsPerPageOfferLetter
  ) {
    // setOpenOfferModal(true);
    setOfferLetterId(id);
    offerLetterCountsList({
      url: `${
        urls.offerletterCountList
      }?dashboard_id=${id}&page=${page}&page_size=${rows || 50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }

  const handleClose = () => {
    setOpenApplicantModal(false);
    setOpenOfferModal(false);
    setOpenInterviewModal(false);
  };

  useEffect(() => {
    FetchDashboardStatus({
      url: `${urls.dashboardStatus}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }, []);

  useEffect(() => {
    if (profile) {
      getOfferLetter(offerLetterId);
    }
  }, [profile, offerLetterPage, rowsPerPageOfferLetter]);

  useEffect(() => {
    if (profile) {
      getApplicantDetails(applicantIid);
    }
  }, [profile, applicantPage, applicantRowsPerPages]);

  useEffect(() => {
    paramsObj.page = page;
    paramsObj.page_size = interviewRowsPerPages;
    fetchInterviews(paramsObj);
  }, [page, interviewRowsPerPages]);

  useEffect(() => {
    getReportCountList({
      url: `${urls.applicantCountApi}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }, []);

  useEffect(() => {
    getInterviewDetailsCountList({
      url: `${urls.interviewDetailsCountList}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }, []);

  useEffect(() => {
    getofferLetterCountList({
      url: `${urls.offerLetterCount}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }, []);

  useEffect(() => {
    getPositionCount({
      url: `${urls.openingsPositionCount}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }, []);

  function handleApplicantChange(curr, pgSize) {
    if (pgSize == applicantRowsPerPages) {
      setApplicantPage(curr);
    } else {
      setApplicantRowsPerPages(pgSize);
      setApplicantPage(1);
    }
  }

  function handleOfferLetterChange(curr, pgSize) {
    if (pgSize == rowsPerPageOfferLetter) {
      setOfferLetterPage(curr);
    } else {
      setRowsPerPageOfferLetter(pgSize);
      setOfferLetterPage(1);
    }
  }

  function handleInterviewChange(curr, pgSize) {
    if (pgSize == interviewRowsPerPages) {
      setInterviewPage(curr);
    } else {
      setInterviewRowsPerPages(pgSize);
      setInterviewPage(1);
    }
  }
  function handleInterviewPageChange(curr, pgSize) {
    if (pgSize == interviewRowsPerPages) {
      setPage(curr);
    } else {
      setInterviewRowsPerPages(pgSize);
      setPage(1);
    }
  }

  return (
    <div>
      {loader}
      <Row>
        <Col>
          <Typography.Title type="primary" level={3}>
            Recruiter Dashboard
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title type="secondary" level={4}>
            {" "}
            ({profile?.academic_profile?.name})
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          style={{ display: "grid" }}
        >
          <Card
            title={"Applicant History"}
            extra={
              <UserOutlined
                style={{
                  fontSize: "24px",
                  color: "#1677ff",
                }}
              />
            }
          >
            <Row>
              <Col span={16}>Today Registered Count</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#1677ff",
                      backgroundColor: "#e6f7ff",
                    }}
                    overflowCount={99999}
                    count={ApplicantCount?.today_registered_count}
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      setOpenApplicantModal(true);
                      getApplicantDetails(1);
                      setApplicantPage(1);
                    }}
                    style={{
                      color: "#1677ff",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Weekly Registered Count</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#1677ff",
                      backgroundColor: "#e6f7ff",
                    }}
                    overflowCount={99999}
                    count={ApplicantCount?.week_registered_count}
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      setOpenApplicantModal(true);
                      getApplicantDetails(2);
                      setApplicantPage(1);
                    }}
                    style={{
                      color: "#1677ff",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Monthly Registered Count</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#1677ff",
                      backgroundColor: "#e6f7ff",
                    }}
                    overflowCount={99999}
                    count={ApplicantCount?.month_registered_count}
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      setOpenApplicantModal(true);
                      getApplicantDetails(3);
                      setApplicantPage(1);
                    }}
                    style={{
                      color: "#1677ff",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Total Registered Count</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#1677ff",
                      backgroundColor: "#e6f7ff",
                    }}
                    overflowCount={99999}
                    count={ApplicantCount?.total_applicant_count}
                    showZero
                  />
                  <InfoCircleFilled
                    style={{
                      visibility: "hidden",
                      color: "#1677ff",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end", marginTop: "1rem" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push("/update_positions");
                }}
              >
                View All
              </Button>
            </Row>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          style={{ display: "grid" }}
        >
          <Card
            title={"Interview Details"}
            extra={
              <BarChartOutlined
                style={{
                  fontSize: "24px",
                  color: "#531CAA",
                }}
              />
            }
          >
            <Row>
              <Col span={16}>Today's Interview</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#531CAA",
                      backgroundColor: "#F8F0FF",
                    }}
                    overflowCount={99999}
                    count={interviewDetailsCount?.today_interview_count}
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      // getInterviewDetails("Today Interview");
                      fetchInterviews({
                        start_date__date__lte: dayjs(new Date()).format(
                          "YYYY-MM-DD"
                        ),
                        start_date__date__gte: dayjs(new Date()).format(
                          "YYYY-MM-DD"
                        ),
                        page: 1,
                        page_size: 50,
                      });
                      // setInterviewPage(1);
                      setOpenInterviewModal(true);
                    }}
                    style={{
                      color: "#531CAA",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Upcoming Interview</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#531CAA",
                      backgroundColor: "#F8F0FF",
                    }}
                    overflowCount={99999}
                    count={interviewDetailsCount?.upcoming_interview_count}
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      fetchInterviews({
                        start_date__date__gte: dayjs(new Date()).format(
                          "YYYY-MM-DD"
                        ),
                        interview_completed: false,
                        page: 1,
                        page_size: 50,
                      });
                      // getInterviewDetails("Upcoming Interview");
                      // setInterviewPage(1);
                      setOpenInterviewModal(true);
                    }}
                    style={{
                      color: "#531CAA",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Feedback Pending</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#531CAA",
                      backgroundColor: "#F8F0FF",
                    }}
                    overflowCount={99999}
                    count={interviewDetailsCount?.feedback_pending_count}
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      fetchInterviews({
                        start_date__date__lte: dayjs(new Date()).format(
                          "YYYY-MM-DD"
                        ),
                        interview_completed: false,
                        page: 1,
                        page_size: 50,
                      });
                      setOpenInterviewModal(true);
                    }}
                    style={{
                      color: "#531CAA",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Completed Interview</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#531CAA",
                      backgroundColor: "#F8F0FF",
                    }}
                    overflowCount={99999}
                    count={interviewDetailsCount?.completed_interview_count}
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      fetchInterviews({
                        start_date__date__lte: dayjs(new Date()).format(
                          "YYYY-MM-DD"
                        ),
                        interview_completed: true,
                        page: 1,
                        page_size: 50,
                      });
                      setOpenInterviewModal(true);
                    }}
                    style={{
                      color: "#531CAA",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>

            <Row>
              <Col span={16}>Total Interview</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#531CAA",
                      backgroundColor: "#F8F0FF",
                    }}
                    overflowCount={99999}
                    count={interviewDetailsCount?.total_interview_count}
                    showZero
                  />
                  <InfoCircleFilled
                    style={{
                      visibility: "hidden",
                      color: "#1677ff",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end", marginTop: "1rem" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push("/interview_details");
                }}
              >
                View All
              </Button>
            </Row>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          style={{ display: "grid" }}
        >
          <Card
            title={"Offer Letter"}
            extra={
              <FileDoneOutlined
                style={{
                  fontSize: "24px",
                  color: "#379E0E",
                }}
              />
            }
          >
            <Row>
              <Col span={16}>Today's offer</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#379E0E",
                      backgroundColor: "#F6FFEC",
                    }}
                    overflowCount={99999}
                    count={offerLetterDashboardCount?.today_offer_letter_count}
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      setOpenOfferModal(true);
                      getOfferLetter(1);
                      setOfferLetterPage(1);
                    }}
                    style={{
                      color: "#379E0E",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Last Week</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#379E0E",
                      backgroundColor: "#F6FFEC",
                    }}
                    overflowCount={99999}
                    count={
                      offerLetterDashboardCount?.week_ago_offer_letter_count
                    }
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      setOpenOfferModal(true);
                      getOfferLetter(2);
                      setOfferLetterPage(1);
                    }}
                    style={{
                      color: "#379E0E",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Last Month</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#379E0E",
                      backgroundColor: "#F6FFEC",
                    }}
                    overflowCount={99999}
                    count={
                      offerLetterDashboardCount?.month_ago_offer_letter_count
                    }
                    showZero
                  />
                  <InfoCircleFilled
                    onClick={() => {
                      setOpenOfferModal(true);
                      getOfferLetter(3);
                      setOfferLetterPage(1);
                    }}
                    style={{
                      color: "#379E0E",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={16}>Total</Col>
              <Col span={8} style={{ textAlign: "end" }}>
                <Space>
                  <Badge
                    style={{
                      color: "#379E0E",
                      backgroundColor: "#F6FFEC",
                    }}
                    overflowCount={99999}
                    count={offerLetterDashboardCount?.total_offer_letter_count}
                    showZero
                  />
                  <InfoCircleFilled
                    style={{
                      visibility: "hidden",
                      color: "#379E0E",
                      fontSize: "18px",
                    }}
                  />
                </Space>
              </Col>
            </Row>
            <Row style={{ justifyContent: "end", marginTop: "1rem" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.push("/Offer_Letters_List");
                }}
              >
                View All
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Active Openings</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{
                        fontSize: "24px",
                        color: "#52c41a",
                      }}
                    />
                  </Col>
                </Row>
              }
              value={positionCountOpeinngs?.active_position_count}
              valueStyle={{ color: "#52c41a" }}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row style={{}}>
                  <Col span={20}>Total Openings</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{ fontSize: "24px", color: "#1677ff" }}
                    />
                  </Col>
                </Row>
              }
              valueStyle={{ color: "#1677ff" }}
              value={positionCountOpeinngs?.total_position_count}
            ></Statistic>
          </Card>
        </Col>
      </Row>

      <Modal
        open={openApplicantModal}
        title={
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col>
              <Typography.Text strong>Applicant History</Typography.Text>
            </Col>
            <Col style={{ marginRight: "1rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/update_positions",
                    state: applicantIid,
                  });
                }}
              >
                View All
              </Button>
            </Col>
          </Row>
        }
        onCancel={handleClose}
        footer={null}
        width={1000}
      >
        <Table
          loading={registeredApplicantCandidatesLoading}
          scroll={{ x: "max-content", y: 300 }}
          dataSource={registeredApplicantCandidates?.results}
          columns={applicantColumns}
          pagination={{
            size: "small",
            defaultCurrent: 1,
            current: applicantPage,
            defaultPageSize: applicantRowsPerPages ?? 50,
            pageSizeOptions: [10, 25, 50, 100],
            showSizeChanger: true,
            total: registeredApplicantCandidates?.count,
            onChange: (current, pageSize) => {
              handleApplicantChange(current, pageSize);
            },
          }}
        />
      </Modal>
      <Modal
        open={openInterviewModal}
        title={
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col>
              <Typography.Text strong>Interview Details</Typography.Text>
            </Col>
            <Col style={{ marginRight: "1rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/interview_details",
                    state: interviewStatus,
                  });
                }}
              >
                View All
              </Button>
            </Col>
          </Row>
        }
        onCancel={handleClose}
        footer={null}
        width={1000}
      >
        <Table
          loading={loading}
          scroll={{ x: "max-content", y: 300 }}
          dataSource={interviewData?.results}
          columns={interviewColumns}
          pagination={{
            size: "small",
            defaultCurrent: 1,
            current: page,
            defaultPageSize: interviewRowsPerPages ?? 50,
            pageSizeOptions: [10, 25, 50, 100],
            showSizeChanger: true,
            total: interviewData?.count,
            onChange: (current, pageSize) => {
              setPage(current);
              handleInterviewChange(current, pageSize);
            },
          }}
        />
      </Modal>
      <Modal
        open={openOfferModal}
        title={
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col>
              <Typography.Text strong>Release Offer Letter</Typography.Text>
            </Col>
            <Col style={{ marginRight: "1rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push({
                    pathname: "/Offer_Letters_List",
                    state: offerLetterId,
                  });
                }}
              >
                View All
              </Button>
            </Col>
          </Row>
        }
        onCancel={handleClose}
        footer={null}
        width={1000}
      >
        <Table
          loading={offerLetterCountDataLoading}
          scroll={{ x: "max-content", y: 300 }}
          dataSource={offerLetterCountData?.results}
          columns={offerColumns}
          pagination={{
            size: "small",
            defaultCurrent: 1,
            current: offerLetterPage,
            defaultPageSize: rowsPerPageOfferLetter ?? 50,
            pageSizeOptions: [10, 25, 50, 100],
            showSizeChanger: true,
            total: offerLetterCountData?.count,
            onChange: (current, pageSize) => {
              handleOfferLetterChange(current, pageSize);
            },
          }}
        />
      </Modal>
    </div>
  );
};
export default RecruiterDashboard;
