import React, { useState, useEffect, useRef, useDebugValue } from "react";
import {
  Col,
  Row,
  Input,
  DatePicker,
  Form,
  Button,
  Select,
  Table,
  Checkbox,
  Tag,
  message,
} from "antd";
import urls from "../../../url";
import { useAlert } from "../../../hoc/alert/alert";
import moment from "moment";
import axios from "axios";
import dayjs from "dayjs";

const ReportsNew = () => {
  const { Option } = Select;
  const formRef = useRef();
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [positionValue, setPositionValue] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState("");
  const [positionType, setPositionType] = useState([]);
  const [designtionList, setDesignationList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [dataReport, setDataReport] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [checked, setChecked] = useState("");
  const [check, setCheck] = useState(true);

  const [gradeCheck, setGradeCheck] = useState("");
  const [subjectCheck, setSubjectCheck] = useState("");
  const [status, setStatus] = useState("");

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (checkedLoc?.length) {
  //     fetchBranchList({ city: checkedLoc });
  //   }
  // }, [checkedLoc]);

  const fetchDesignationList = (params = {}) => {
    axios
      .get(`${urls.designationListApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const handleCityChange = () => {
    const city = formRef.current.getFieldsValue()?.city;
    formRef.current.setFieldsValue({
      branch: null,
    });
    if (city) {
      fetchBranchList({
        city_id: city,
      });
    } else {
      setBranchList([]);
    }
  };

  const alert = useAlert();

  useEffect(() => {
    fetchPositionTypesList();
    fetchGradeList();
    fetchCityList();
  }, []);

  const fetchPositionTypesList = (params = {}) => {
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setPositionType(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchCityList = () => {
    axios
      .get(`${urls.cityList}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCityList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const fetchBranchList = (params = {}) => {
    axios
      .get(`${urls.branchCityMapping}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setBranchList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const handlePositionTypesChange = (e, val) => {
    setPositionValue(val?.value);

    const { position_type } = formRef.current.getFieldsValue();
    console.log("==", position_type);
    formRef.current.setFieldsValue({
      role: null,
    });
    if (position_type) {
      fetchDesignationList({
        position_type: position_type,
      });
    } else {
      setDesignationList([]);
    }
  };

  const fetchGradeList = () => {
    axios
      .get(`${urls.newGrade}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setGradeList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const fetchSubjectList = (params = {}) => {
    axios
      .get(`${urls.viewSubjectApiView}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setSubjectList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const handleGradeChange = (e, val) => {
    setGradeCheck(val?.value);
    const { grade } = formRef.current.getFieldsValue();
    formRef.current.setFieldsValue({
      subject: null,
    });
    if (grade) {
      fetchSubjectList({
        grade_id: grade,
      });
    } else {
      setSubjectList([]);
    }
  };

  const handleGetTableData = () => {
    setPage(1);
    getTableData();
  };

  const statusType = [
    {
      value: "1",
      label: "in_process",
      displayValue: "In Process",
    },
    {
      value: "2",
      label: "document_submitted",
      displayValue: "Document Submitted",
    },
    {
      value: "3",
      label: "joined",
      displayValue: "Joined",
    },
    {
      value: "4",
      label: "not_joined",
      displayValue: "Not Joined",
    },
  ];

  // useEffect(() => {
  //   setBottomHRef([
  //     {
  //       csv: `${
  //         urls.reportData
  //       }?position_type=${positionValue}&status=${status}&start_date=${startDate}&end_date=${endDate}&role=${checked}&active=${check}&branch=${branch ||
  //         ""}&city=${checkedLoc}&subject=${subjectCheck}&grade=${gradeCheck}&download=true`,
  //     },
  //   ]);
  // }, [
  //   setBottomHRef,
  //   checked,
  //   status,
  //   startDate,
  //   endDate,
  //   check,
  //   branch,
  //   subjectCheck,
  // ]);

  const handleReportDownload = () => {
    const {
      position_type,
      role,
      grade,
      subject,
      start_date,
      end_date,
      city,
      branch,
    } = formRef.current.getFieldsValue();

    let obj = {
      download: "true",
    };

    if (position_type) {
      obj.position_type_fk = position_type;
    }
    if (role) {
      obj.designation_fk = role;
    }
    if (start_date) {
      obj.start_date = dayjs(start_date).format("YYYY-MM-DD");
    }
    if (end_date) {
      obj.end_date = dayjs(end_date).format("YYYY-MM-DD");
    }
    if (status) {
      obj.status = status;
    }
    if (position_type) {
      obj.position_type = position_type;
    }
    if (check) {
      obj.active = check;
    }
    if (!check) {
      obj.active = "false";
    }
    if (branch) {
      obj.branch_id = branch;
    }
    if (city) {
      obj.city_id = city;
    }
    if (search) {
      obj.search = search;
    }
    if (subject) {
      obj.subject = subject;
    }
    if (grade) {
      obj.grade = grade;
    }

    axios
      .get(`${urls.applicantsProfiles}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: obj,
        responseType: "blob",
      })
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.toLowerCase().includes("application/json")) {
          return false;
        } else {
          message.success("Downloading....");
          const blob = window.URL.createObjectURL(
            new Blob([response?.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            })
          );
          let link = document.createElement("a");
          link.href = blob;
          link.setAttribute("download", "User_Report.xlsx");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      });
  };

  const handleChange = (event) => {
    setCheck(event.target.checked);
  };

  const getTableData = (params = {}) => {
    const {
      position_type,
      role,
      grade,
      subject,
      start_date,
      end_date,
      city,
      branch,
    } = formRef.current.getFieldsValue();

    let obj = {
      page,
      page_size: rowsPerPage,
    };

    if (position_type) {
      obj.position_type_fk = position_type;
    }
    if (role) {
      obj.designation_fk = role;
    }
    if (start_date) {
      obj.start_date = dayjs(start_date).format("YYYY-MM-DD");
    }
    if (end_date) {
      obj.end_date = dayjs(end_date).format("YYYY-MM-DD");
    }
    if (status) {
      obj.status = status;
    }
    if (position_type) {
      obj.position_type = position_type;
    }
    if (check) {
      obj.active = check;
    }
    if (!check) {
      obj.active = "false";
    }
    if (branch) {
      obj.branch_id = branch;
    }
    if (city) {
      obj.city_id = city;
    }
    if (search) {
      obj.search = search;
    }
    if (subject) {
      obj.subject = subject;
    }
    if (grade) {
      obj.grade = grade;
    }

    setLoading(true);
    axios
      .get(`${urls.applicantsProfiles}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: obj,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response?.data);
          setDataReport(response?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let id = setTimeout(() => {
      getTableData();
    }, 2000);
    return () => clearTimeout(id);
  }, [search]);

  useEffect(() => {
    getTableData();
  }, [page, rowsPerPage]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      width: "15%",
      render: (data) => <span>{data?.applicant?.name ?? "-"}</span>,
    },
    {
      title: "Contact No.",
      key: "contact_no",
      width: "15%",
      render: (data) => <span>{data?.applicant?.contact_no ?? "-"}</span>,
    },
    {
      title: "Position Applied",
      key: "position_applied",
      render: (data) => <span>{data?.position_type_name ?? "-"}</span>,
    },
    {
      title: "Role",
      key: "role",
      render: (data) => <span>{data?.designation_name ?? "-"}</span>,
    },
    {
      title: "City",
      key: "city",
      render: (data) => <span>{data?.city_name ?? "-"}</span>,
    },
    {
      title: "Branch",
      key: "branch",
      render: (data) => <span>{data?.branch_name ?? "-"}</span>,
    },
    {
      title: "Register Date",
      key: "register_date",
      render: (data) => <span>{data?.applicant?.register_date ?? "-"}</span>,
    },
    {
      title: "Resume",
      key: "resume",
      render: (data) => (
        <a href={data?.applicant?.resume} target="_blank">
          <Tag
            disabled={data?.applicant?.resume ? false : true}
            style={{
              cursor: data?.applicant?.resume ? "pointer" : "not-allowed",
            }}
            color="purple"
          >
            View Resume
          </Tag>
        </a>
      ),
    },
  ];
  return (
    <>
      <Row justify="space-between">
        <Col span={18} className="page_title">
          Users Report
        </Col>
        <Col span={6}>
          <Input
            placeholder="I'm Looking For..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>
      <div className="page_body">
        <Form
          id="usersReportForm"
          ref={formRef}
          layout="vertical"
          style={{ marginBottom: "10px" }}
        >
          <Row align="middle" gutter={24}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Position Type"
                  onChange={(e, val) => handlePositionTypesChange(e, val)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {positionType &&
                    positionType.length !== 0 &&
                    positionType.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.position_type}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  onChange={(e, val) => setChecked(val?.value)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {designtionList &&
                    designtionList.length !== 0 &&
                    designtionList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item name="city" label="City">
                <Select
                  placeholder="Select City"
                  onChange={(e, val) => handleCityChange(e, val)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {cityList &&
                    cityList.length !== 0 &&
                    cityList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.city_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item name="branch" label="Branch">
                <Select
                  placeholder="Select Branch"
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {branchList &&
                    branchList.length !== 0 &&
                    branchList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.branch_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                name="grade"
                label={positionValue == "Academic" ? "Grade" : "Level"}
                rules={[
                  {
                    required: true,
                    message: "Please Select Level",
                  },
                ]}
              >
                <Select
                  placeholder={`Select ${
                    positionValue === "Academic" ? "Grade" : "Level"
                  }`}
                  onChange={(e, val) => handleGradeChange(e, val)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {gradeList &&
                    gradeList.length !== 0 &&
                    gradeList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.grade_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                name="subject"
                label={positionValue == "Academic" ? "Subject" : "Topic"}
              >
                <Select
                  placeholder={`Select ${
                    positionValue === "Academic" ? "Subject" : "Topic"
                  }`}
                  onChange={(e, val) => setSubjectCheck(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {subjectList &&
                    subjectList.length !== 0 &&
                    subjectList.map((option, index) => {
                      return (
                        <Option
                          key={option?.subject?.id}
                          value={option?.subject?.id}
                        >
                          {option?.subject?.subject_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item name="status" label="Status">
                <Select
                  placeholder="Select Status"
                  onChange={(e, val) => setStatus(val?.value)}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {statusType &&
                    statusType.length !== 0 &&
                    statusType.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.label}>
                          {option?.displayValue}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item name="active_users">
                <Checkbox checked={check} onChange={handleChange}>
                  Active Users
                </Checkbox>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Please Select Start Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e, val) => handleStartDateChange(val)}
                  disabledDate={(current) => {
                    return current > moment(endDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6}>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[
                  {
                    required: true,
                    message: "Please Select End Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e, val) => handleEndDateChange(val)}
                  disabledDate={(current) => {
                    return current < moment(startDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "space-between" }}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={6}
            >
              <Button
                form="usersReportForm"
                htmlType="submit"
                type="primary"
                onClick={handleGetTableData}
              >
                GET
              </Button>
              <Button
                type="primary"
                onClick={handleReportDownload}
                // href={bottomHRef && bottomHRef[0].csv}
                disabled={startDate && endDate ? false : true}
              >
                CSV
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={dataReport?.results}
          pagination={{
            showSizeChanger: false,
            total: dataReport?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
        />
      </div>
    </>
  );
};
export default ReportsNew;
