import {
  Card,
  Divider,
  Drawer,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import urls from "../../../../url";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
function MrfDetailDrawer({ open, onClose, data, mrfId }) {
  const [selectedTab, setSelectedTab] = useState("details");
  const [mrfDetails, setMrfDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [openVacancyLoading, setOpenVacancyLoading] = useState(false);
  const userData = useSelector((state) => state?.auth?.user);
  useEffect(() => {
    if (mrfId) {
      fetchMrfData();
    }
  }, [mrfId]);

  function fetchMrfData() {
    setLoading(true);
    axios
      .get(`${urls?.mrfForm}${mrfId}/`)
      .then((response) => {
        setMrfDetails(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
        message.error(error.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleReopenVacancy(id) {
    setOpenVacancyLoading(true);
    axios
      .delete(`${urls.mrfVacancyCode}${id}/`)
      .then((response) => {
        message.success(response?.data?.message ?? "Vacancy Reopened!");
        fetchMrfData();
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setOpenVacancyLoading(false);
      });
  }

  const tabContent = {
    details: (
      <>
        <p className="">
          <b>Salary Range :</b> {mrfDetails?.salary}
        </p>
        <p className="">
          <b>Raised By :</b> {mrfDetails?.employee_name}
        </p>
        <p className="">
          <b>Employee Email :</b> {mrfDetails?.employee_email}
        </p>
        <p className="">
          <b>Employee ERP :</b> {mrfDetails?.employee_erp}
        </p>
        <p className="">
          <b>Position Type :</b> {mrfDetails?.position_type_fk_name}
        </p>
        <p className="">
          <b>Number of Vacancies :</b> {mrfDetails?.no_of_vacancies}
        </p>
        <p className=" text-capitalize">
          <b>Min. Qualification :</b>{" "}
          {mrfDetails?.minimum_qualification?.split("_")?.join(" ")}
        </p>
        <p className="">
          <b>Skills :</b> {mrfDetails?.skill}
        </p>
        <p className="">
          <b>Experience Requirements (In years) :</b> {mrfDetails?.experience}
        </p>
        <p className="">
          <b>Remarks :</b> {mrfDetails?.remark}
        </p>
        <p className="">
          <b>Job Type :</b> {mrfDetails?.job_type}
        </p>
        <p className="">
          <b>Location :</b> {mrfDetails?.location_name}
        </p>
        <p className="">
          <b>Priority :</b> {mrfDetails?.priority}
        </p>
        <p className="">
          <b>Max Budget (Annual) :</b> {mrfDetails?.max_budget}
        </p>
        {mrfDetails?.additional_recruiter_data?.length > 0 && (
          <p className="">
            <b>Additional Recruiters :</b>{" "}
            {mrfDetails?.additional_recruiter_data
              ?.map((each) => `${each?.first_name} (${each?.username})`)
              ?.join(", ")}
          </p>
        )}
        {mrfDetails?.mrf_type == "new_hire" && mrfDetails?.file && (
          <p className="">
            <b>New Hire Document :</b>{" "}
            <a href={mrfDetails?.file} target="__blank">
              <Tag color={"geekblue"} icon={<EyeOutlined />}>
                View File
              </Tag>
            </a>
          </p>
        )}
      </>
    ),
    vacancies: (
      <Table
        dataSource={mrfDetails?.mrf_vacancy_code ?? []}
        size="small"
        style={{ fontSize: "10px" }}
        loading={openVacancyLoading}
        columns={[
          {
            title: "Vacancy Code",
            dataIndex: "code",
            key: "code",
            className: "th-12",
          },
          {
            title: "Status",
            dataIndex: "is_active",
            key: "is_active",
            render: (data) => <span>{data ? "Active" : "Inactive"}</span>,
            className: "th-12",
          },
          {
            title: "Occupancy Status",
            key: "is_used",
            className: "th-12",
            render: (data) => (
              <span>
                {data?.is_used
                  ? `Occupied by ${data?.candidate_name}`
                  : "Vacant"}
                {userData?.personal_info?.role_id == 1 && data?.is_used && (
                  <Popconfirm
                    title="Are you sure to re-open this vacancy?"
                    okText="Yes"
                    onConfirm={() => {
                      handleReopenVacancy(data?.id);
                    }}
                  >
                    <Tag color={"error"}>Reopen Vacancy</Tag>
                  </Popconfirm>
                )}
              </span>
            ),
          },
        ]}
        pagination={false}
      />
    ),
  };

  const tabItems = [
    {
      key: "details",
      label: "MRF Details",
    },
    {
      key: "vacancies",
      label: "Vacancies",
    },
  ];

  if (mrfDetails?.replacement_json) {
    tabContent["replacements"] = (
      JSON.parse(mrfDetails?.replacement_json) ?? []
    )?.map((replacement) => (
      <Card
        key={replacement?.id}
        style={{
          background: "#f3f3f3",
          marginBottom: "15px",
        }}
        bodyStyle={{ padding: "10px 15px" }}
      >
        <Row
          style={{ marginBottom: "4px" }}
          justify={"space-between"}
          align={"middle"}
        >
          <Typography.Text className="m-0" level={1}>
            <strong>{replacement?.name}</strong> ({replacement?.erp})
          </Typography.Text>
          {replacement?.file && (
            <a target="__blank" href={replacement?.file}>
              <Tag className="m-0" color="blue">
                Resignation File
              </Tag>
            </a>
          )}
        </Row>
        <Divider className="m-0" />
        <div style={{ margintop: "10px" }}>
          <p className="m-0">
            <b style={{ fontWeight: "500" }}>Exit Date :</b>{" "}
            {dayjs(replacement?.exit_date).format("Do MMM, YYYY")}
          </p>
          <p className="m-0">
            <b style={{ fontWeight: "500" }}>Tentative Joining Date :</b>{" "}
            {dayjs(replacement?.joining_date).format("Do MMM, YYYY")}
          </p>
        </div>
      </Card>
    ));
    tabItems.push({
      key: "replacements",
      label: "Replacements",
    });
  }

  console.log({ mrfDetails });

  return (
    <Drawer
      open={open}
      onClose={() => {
        setMrfDetails(null);
        setSelectedTab("details");
        onClose();
      }}
      closeIcon={null}
      title={null}
      width={400}
    >
      <Row justify={"space-between"}>
        {loading ? (
          <Skeleton.Input />
        ) : (
          <Typography.Title level={5} style={{ margin: 0 }}>
            {mrfDetails?.position}
          </Typography.Title>
        )}
        <CloseOutlined onClick={onClose} />
      </Row>
      {loading ? (
        <Skeleton>
          <Skeleton.Input />
          <Skeleton.Input />
          <Skeleton.Button />
        </Skeleton>
      ) : (
        <>
          <Tabs
            items={tabItems}
            onChange={(e) => {
              setSelectedTab(e);
            }}
          />
          {tabContent[selectedTab]}
        </>
      )}
    </Drawer>
  );
}

export default MrfDetailDrawer;
