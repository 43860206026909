import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Paper,
  Grid,
  Avatar,
  FormControlLabel,
  Radio,
  TextField,
  FormControl,
  RadioGroup,
  FormLabel,
  MenuItem,
  Button,
} from '@material-ui/core';
import useFetch from '../../../../hoc/useFetch'
import urls from '../../../../url'
import { useAlert } from "../../../../hoc/alert/alert";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import Loader from '../../../../hoc/loader'
import styles from './basicProfile.Style';
import { FontDownload } from '@material-ui/icons';

const BasicProfile = ({ classes }) => {

  const [profileDetails, setProfileDetails] = useState([
    {first_name: '',email: '',contact_no:'',date_of_birth: '',current_location:'',gender:'', expected_salary: '', current_salary:'',preffered_location:'', branch_prefer_3: '',branch_prefer_2: '', branch_prefer_1: ''}
  ]);
  const alert = useAlert();
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [choosePhoto, setChoosePhoto] = useState('');
  const [resume, setResume] = useState('');
  const [loading, setloading] = useState(false);
  const [basicProfileGettingRes, setBasicProfileGettingRes] = useState('');
  const [profileImage, setProfileimage] = useState('');

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranchList,
  } = useFetch(null);

  const {
    data: uploadImageAndResume,
    isLoading:submitingResumeandImage,
    doFetch: fetchUploadResumeAndImage
  } = useFetch(null);

  const {
    // data: basicProfileSubmit,
    isLoading: submittingBasicProfile,
    doFetch: fetchBacicProfile
  } = useFetch(null);

  
  useEffect(()=>{
    if (basicProfileGettingRes) {
      setProfileDetails(
        [
          {
          first_name: basicProfileGettingRes.full_name,
          email: basicProfileGettingRes.email,
          contact_no: basicProfileGettingRes.contact_no,
          date_of_birth: basicProfileGettingRes.date_of_birth,
          current_location: basicProfileGettingRes.current_location,
          gender: basicProfileGettingRes.gender,
          expected_salary: basicProfileGettingRes.expected_salary, 
          current_salary: basicProfileGettingRes.current_salary,
          preffered_location: basicProfileGettingRes.preffered_location,
          branch_prefer_3: basicProfileGettingRes.branch_preference_3,
          branch_prefer_2: basicProfileGettingRes.branch_preference_2,
          branch_prefer_1: basicProfileGettingRes.branch_preference_1,
        }
      ]
    )
    setProfileimage(basicProfileGettingRes.profile)
    
    setChoosePhoto(basicProfileGettingRes.profile)
    setResume(basicProfileGettingRes.resume)
  }
  },[basicProfileGettingRes])

  useEffect(()=>{
    if(name){
      fetchBranchList({
        url: urls.branch,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setloading(true);
      fetch(`${ urls.submitBasicProfileApi}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          setloading(false);
          alert.success('Successfully Recived');
          return res.json();
        }
        if (res.status !== 200) {
          setloading(false);
          alert.error('something went wrong please try again');
          return res.json();
        }
      }).then((data) => {
        setBasicProfileGettingRes(data);
    })
   }
 },[alert, fetchBranchList, name]);


  let loader = null;
  if (branchListLoading || submittingBasicProfile || loading || submitingResumeandImage) {
    loader = <Loader open />;
  }
  const changeHandler = (e, key, i) => {
    if (!profileDetails.length) {
      alert.warning('No data available')
      return;
    }
    setProfileDetails((data) => {
      const newData = [...data];
      switch (key) {
        case 'first_name':
          newData[0][key] = e;
          return newData;
        case 'email':
          newData[0][key] = e;
          return newData;
        case 'contact_no':
          newData[0][key] = e;
          return newData;
        case 'date_of_birth':
          newData[0][key] = e;
          return newData;
        case 'current_location':
          newData[0][key] = e;
          return newData;
        case 'gender':
          newData[0][key] = e;
          return newData;
        case 'current_salary':
          newData[0][key] = e;
          return newData;
        case 'expected_salary':
          newData[0][key] = e;
          return newData;
        case 'preffered_location':
           newData[0][key] = e;
           return newData;
        case 'branch_prefer_1':
            newData[0][key] = e;
            return newData;
        case 'branch_prefer_2':
            newData[0][key] = e;
            return newData;
        case 'branch_prefer_3':
           newData[0][key] = e;
           return newData;
        default:
          return null;
      }
    });
  }
  const salaryRange = [
    {value: '1', label: '1-3'},
    {value: '2', label: '3-5'},
    {value: '3', label: '5-7'},
    {value: '4', label: '7-10'},
    {value: '5', label: '10+'},
    {value: '6', label: 'Other'},
  ]
// e.target.files[0]
  function setProfileImageFUnction(e) {
    if (e && e.name) {
      setProfileimage(e);
      setChoosePhoto(window.URL.createObjectURL(e));
    }
  }

  function saveBasicProfileData(){

    if (!profileDetails[0].first_name) {
      alert.warning('fill all Fields');
      return;
    }
    const data = {
      "full_name": profileDetails[0].first_name,
      "email":  profileDetails[0].email,
      "contact_no":  profileDetails[0].contact_no,
      "date_of_birth":  profileDetails[0].date_of_birth,
      "current_location":  profileDetails[0].current_location,
      "preffered_location":  profileDetails[0].preffered_location,
      "branch_preference_1":  profileDetails[0].branch_prefer_1,
      "branch_preference_2":  profileDetails[0].branch_prefer_2,
      "branch_preference_3":  profileDetails[0].branch_prefer_3,
      "gender":  profileDetails[0].gender,
      "current_salary":  profileDetails[0].current_salary,
      "expected_salary":  profileDetails[0].expected_salary,
      }
      const info = {
        'basic_profile': data,
      }
      fetchBacicProfile({
        url: urls.submitBasicProfileApi,
        body: info,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      const formData = new FormData();
      if (typeof(resume) === 'object') {
        formData.append('resume', resume);
      }
      if (typeof(profileImage) === 'object') {
        formData.append('profile', profileImage);
      }
      
      fetchUploadResumeAndImage({
        url: urls.uploadResumeImageApi,
        body: formData,
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper} style={{padding:"10px"}}>
        <Grid container direction="row" justify="center" align="center">
          <Grid item md={12} xs={12}>
            <input style={{ display: 'none' }}
              accept="image/*"
              className={classes.fileUpload}
              id="outlined-button-file"
              type="file"
              onChange={e => setProfileImageFUnction(e.target.files[0])}
            />
            <label htmlFor="outlined-button-file">
              <Avatar variant="rounded" className={classes.square} src={choosePhoto ? ReactHtmlParser(choosePhoto) : require('../../profile/Upp3.png')} alt="Remy Sharp" />
            </label>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.gridTwo}>
          <Grid item md={3} xs={12}>
            <TextField
              label="Full-Name"
              margin="dense"
              required
              className="textList"
              fullWidth
              value={(profileDetails[0].first_name) || ''}
              onChange={e => changeHandler(e.target.value, 'first_name')}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Email"
              margin="dense"
              required
              className="textList"
              fullWidth
              value={(profileDetails[0].email) || ''}
              onChange={e => changeHandler(e.target.value, 'email')}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Contact No"
              margin="dense"
              required
              className="textList"
              fullWidth
              type="number"
              value={(profileDetails[0].contact_no) || ''}
              onChange={e => e.target.value.length <= 10 ? changeHandler(e.target.value, 'contact_no') : ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              // className={classes.textField}
              helperText="Birth Date"
              margin="dense"
              required
              className="textList"
              fullWidth
              value={(profileDetails[0].date_of_birth) || ''}
              onChange={e => changeHandler(e.target.value, 'date_of_birth')}
              type="date"
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="Current Location"
              margin="dense"
              required
              className="textList"
              fullWidth
              value={(profileDetails[0].current_location) || ''}
              onChange={e => changeHandler(e.target.value, 'current_location')}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="dense"
              label="Preffered Location"
              className="textList"
              fullWidth
              max='3'
              select
              value={profileDetails[0].preffered_location || ''}
              onChange={(e) => { changeHandler(e.target.value, 'preffered_location') }}
              variant="outlined"
            >
              {branchList && branchList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.branch_name}
                >
                  {data.branch_name ? data.branch_name : ''}
                </MenuItem>
              ))}
            </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
            <TextField
              margin="dense"
              label=" Branch Preferences 1"
              placeholder='Branch Preferences (Max 3 /Any in (City)'
              fullWidth
              className="textList"
              max='3'
              select
              value={ profileDetails[0].branch_prefer_1|| ''}
              onChange={(e) => { changeHandler(e.target.value, 'branch_prefer_1') }}
              variant="outlined"
            >
              {branchList && branchList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.branch_name}
                >
                  {data.branch_name ? data.branch_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
            <Grid item md={3} xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  row
                  style={{ flexDirection: 'row',  display: 'flex' }}
                  value={profileDetails[0].gender}
                  onChange={(e) => { changeHandler(e.target.value, 'gender') }}
                >
                  <FormControlLabel
                    value="Female"
                    control={<Radio color="primary" />}
                    label="Female"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="Male"
                    control={<Radio color="primary" />}
                    label="Male"
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              margin="dense"
              label="Branch Preferences  2"
              placeholder='Branch Preferences (Max 3 /Any in (City)'
              fullWidth
              className="textList"
              max='3'
              select
              value={profileDetails[0].branch_prefer_2 || ''}
              onChange={(e) => { changeHandler(e.target.value, 'branch_prefer_2') }}
              variant="outlined"
            >
              {branchList && branchList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.branch_name}
                >
                  {data.branch_name ? data.branch_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          
          <Grid item md={3} xs={12}>
            <TextField
              margin="dense"
              label=" Branch Preferences 3"
              placeholder='Branch Preferences (Max 3 /Any in (City)'
              fullWidth
              className="textList"
              max='3'
              select
              value={profileDetails[0].branch_prefer_3 || ''}
              onChange={(e) => { changeHandler(e.target.value, 'branch_prefer_3') }}
              variant="outlined"
            >
              {branchList && branchList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.branch_name}
                >
                  {data.branch_name ? data.branch_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                select
                required
                fullWidth
                className="textList"
                margin='dense'
                label='Current Salary'
                // className={classes.textField}
                value={ profileDetails[0].current_salary || ""}
                onChange={e => changeHandler(e.target.value, 'current_salary')}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                variant='outlined'
              >
                {salaryRange.map(option => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                select
                required
                fullWidth
                className="textList"
                margin='dense'
                label='Expected Salary'
                // className={classes.textField}
                value={ profileDetails[0].expected_salary || ""}
                onChange={e => changeHandler(e.target.value, 'expected_salary')}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                variant='outlined'
              >
                {salaryRange.map(option => (
                  <MenuItem key={option.value} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={9} xs={12}>
            <input
              style={{ display: 'none' }}
              className={classes.fileUpload}
              id="outlined-button-resume"
              type="file"
              accept="application/pdf,application/vnd.ms-excel"
              onChange={(e) =>{ 
                var format = e.target.files[0].type
                if(format==="application/pdf"){
                  setResume(e.target.files[0])
                  alert.success('Resume uploded sucessfully');
                }else{
                setResume("")
                alert.warning('File format not supported');
               }
              }}
            />
            <label htmlFor="outlined-button-resume">
              <b style={{ fontFamily: '' }}> Upload Resume <b>(format: .pdf)</b><i>Only Pdf files are Supported</i></b>
              &nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.fileUpload}
                startIcon={<CloudUploadIcon />}
              >
                Browse
              </Button>
              &nbsp;
              {resume && typeof(resume) === 'object' && resume.name}
              {resume && typeof(resume) === 'string' && <a href={resume} onclick="window.open(this.href); return false;" target="_blank">Click to View</a>}
            </label>
            </Grid>
            <Grid item md={11}/>
            <Grid item md={1} xs={12}>
              <Button variant="contained" fullWidth color="primary" onClick={()=> saveBasicProfileData()}>Save</Button>
            </Grid>
        </Grid>
      </Paper>
      {loader}
    </React.Fragment>
  )
}

BasicProfile.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(BasicProfile);
