import { rgbToHex } from "@material-ui/core/styles";

export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    applyButtonSection :{
     display : 'block',
     margin : '0 auto',
     padding : '20px'
    },
    applyButtonModal : {
        marginTop: '6px',
    },
    applyButton:{
    marginLeft:'5px',
    marginTop:'5px'
    },

    questionContainer: {
      padding: 5
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    title : {
      float : 'left',
      fontSize : '18px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    button: {
      marginTop:'20px',
      marginLeft:'87%',
      margin: theme.spacing(1),
    },
    tabled: {
      maxHeight: 250,
      overflow: 'auto'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    checkBox : {
        marginTop : '100px',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(1, 2, 1),
    }, 
    // paperquestion:{
    //   // backgroundColor: theme.palette.background.paper,
    //   // boxShadow: theme.shadows[2],
    //   padding: theme.spacing(1, 2, 1),
    //   color:'blue',
    // },
    paperr:{
      width:'100%',
    },
    tableRowtab1 :{
      backgroundColor: rgbToHex('#FFFD91'),
      fontSize:'15px',
      color:'black',
      width: '100%',
      height: '100%'
    },
    tableRowtab2 :{
      backgroundColor: rgbToHex('#70C3E5 '),
      fontSize:'15px',
      color:'black',
      width: '100%',
      height: '100%'
    },
    TableHeadtabBottom: {
      marginTop:'10px',
      cellPadding:"10px",
      cellSpacing:'10px',
      fontFamily:' Helvetica',
      fontSize:'15px',
      color: '#FFFFFF',
      backgroundColor: rgbToHex('#ff0000 '), 
    },
    tableHeadtab1 : {
      backgroundColor: rgbToHex('#F1E700 '), 
      fontSize:'18px',
      color:'black',
      width: '100%',
      height: '100%'
    },
    tableHeadtab2 : {
      backgroundColor: rgbToHex('#01A9EE'), 
      fontSize:'18px',
      color:'black',
      width: '100%',
      height: '100%'
    },
    TableHeadtab:{
      marginTop:'10px',
      marginLeft:'10%',
      marginRight:'10%',
      cellPadding:"10px",
      cellSpacing:'10px',
      fontFamily:' Helvetica',
      fontSize:'15px',
      backgroundColor: rgbToHex('#7FF548 '), 
    },
    TableBodytab:{
      marginLeft:'10%',
      marginRight:'10%',
      cellPadding:"10px",
      cellSpacing:'10px',
      backgroundColor: rgbToHex('#A7F882 '), 
    },
    Tabletab :{
      marginTop:'10px',
      marginBottom:'20px',
      marginLeft:'1%',
      marginRight:'1%',
      fontSize:'15px',
      fontFormate:'bold',
    },
    tablepagination: {
      marginTop:'0.5%',
      marginLeft:'30px',
      // float:'left',
      width:'inherit',
      textAlign:'-webkit-right',
    },
    search: {
      float: 'right',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    });
  