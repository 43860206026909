import React, { useState,useEffect } from 'react';
import {
    Typography,
    Divider,
    Grid,
    withStyles,
    Table,
    Card,
    Avatar,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    TableFooter,
    TablePagination,
    Paper,
    InputBase,
    TextField,
    Button,
    Dialog,
    TableContainer,
  } from '@material-ui/core';
  import SearchIcon from "@material-ui/icons/Search";
  import PropTypes, { func } from 'prop-types';
  import styles from './upCommingInterview.Style';
  import urls from '../../../url';
  import useFetch from '../../../hoc/useFetch';
  import Loader from '../../../hoc/loader';
  import FirstPageIcon from '@material-ui/icons/FirstPage';
  import LastPageIcon from '@material-ui/icons/LastPage';
  import { rgbToHex } from '@material-ui/core/styles';
  import ReactHtmlParser from 'react-html-parser';
  import Close from "@material-ui/icons/Close";
  import Backdrop from '@material-ui/core/Backdrop';
  import Fade from '@material-ui/core/Fade';
  import Parameters from '../../../components/interviewer/todaysInterview/Parameters';
  import { makeStyles } from '@material-ui/core/styles';

function UpCommingInterview({classes}){
    const [auth] = useState(JSON.parse(localStorage.getItem('user')));
    const [rowsPerPage, setRowsPerPage] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [applicentName, setApplicentName] = useState('');
    const [totalMarks, setTotalMarks] = useState('');
    const [comments, setComments] = useState('');
    const [viewModdel, setViewModel] = useState(false);
    const [applicentId, setApplicentId] = useState('');
    const [faceToFaceId, setfaceToFaceId] = useState('');
    const [confirmMarks, setConfirmMarks] = useState('');
    const [view, setView] = useState(false);
    const [open, setOpen] = useState(false);
    const [searchData, setSearchData] = useState(null);


    const useStyles = makeStyles((theme) => ({
      dialogMarks: {
        height:305,
        width: '35%'
      }
    }));

    const classesD = useStyles();

    function handleChangePage(event, newPage) {
      setPage(newPage)
      {!rowsPerPage && setRowsPerPage(10)} 
    }
  
    function handleChangeRowsPerPage(event) {
      setRowsPerPage(event.target.value);
      setPage(0);
    }
    function firstPageChange() {
      setPage(0)
    }
      
    function lastPageChange(lastPage) {
      setPage(lastPage)
    };

    const{
        data:getUpCommingInterviewesData,
        isLoading:getUpCommingInterviewesDataLoading,
        doFetch:fetchUpCommingInterviews
    }=useFetch("", { suppressAlert: true })

    const {
      data: getQuestionsData,
      isLoading: getQuestionsDataLoading,
      doFetch: fetchQuestions
    } = useFetch("", { suppressAlert: true })

    const {
      data: marksSubmitRes,
      isLoading: submittingMarks,
      doFetch: fetchSubmitMarks,
    } = useFetch("", { suppressAlert: true });

    useEffect(() => {
      if (getQuestionsData) {
        if (getQuestionsData) {
          setOpen(true)
        }
      }
    }, [getQuestionsData])

    useEffect(()=>{
        if(auth){
            getUpCommingInterviewes()
        }
    },[auth,page,rowsPerPage])

    function getUpCommingInterviewes(){
        fetchUpCommingInterviews({
            url:`${urls.upcomingInterviewsApi}?page=${page+1}&page_size=${rowsPerPage || 10}&search=${searchData || ''}`,
            method:'GET',
            headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                'Content-Type': 'application/json',
              },
        })
    }
    
    var loader = null;
    if (getUpCommingInterviewesDataLoading || getQuestionsDataLoading || submittingMarks) {
      loader = <Loader open />;
    }

    const fetchSearch = e => {
      setPage(0);
      setRowsPerPage(5);
      if (e.keyCode === 13) {
        const data = document.getElementById("search-bar").value;
        setApplicentName(document.getElementById("search-bar").value);
        functionSearch(data);
      }
    };

    function getQuestions(id, F2fId, marks) {
      setApplicentId(id)
      setfaceToFaceId(F2fId)
      setConfirmMarks(marks);
      fetchQuestions({
        url: `${urls.getParameters}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json'
        },
      })
    }

    function functionSearch(data){
      setSearchData(data);
    }
  
    function getMarks(id, F2fId, marks) {
      setView(true)
      setApplicentId(id)
      setfaceToFaceId(F2fId)
      setConfirmMarks(marks);
    }

    async function fetchQuestionsVal(id) {
      localStorage.setItem('param_id', id)
      setViewModel(true);
    }

    const onCloseFunc = () => {
      setViewModel(false);
    }

    function submitMarks() {
      if (!comments || !totalMarks) {
        alert.warning('Enter Marks and Comment it');
        return;
      }
      
      const data = {
        'applicent_Id': applicentId,
        'total_marks': totalMarks,
        'comments': comments,
        'f2f_id': faceToFaceId,
      }
      fetchSubmitMarks({
        url: `${urls.submitMarksApi}`,
        body: data,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json'
        },
      })
    }

    useEffect(() => {
      if (auth) {
        setOpen(false);
        setView(false);
        setConfirmMarks('');
        setTotalMarks('');
        setComments('');
        setApplicentId('');
        setfaceToFaceId('')
        getUpCommingInterviewes()
      }
    }, [page, rowsPerPage, auth, marksSubmitRes, searchData])

    // useEffect(() => {
    //   getUpCommingInterviewes();
    // }, [rowsPerPage, page, searchData]);

    const handleClose = () => {
      setOpen(false);
      setView(false);
      setConfirmMarks('');
      setTotalMarks('');
      setComments('');
      setApplicentId('');
      setfaceToFaceId('');
    };
  
  return(
      <React.Fragment>
        <Grid container spacing={2} className={classes.modalPaper}>
         <Grid item sm={12} md={6} xs={12}>
           <Typography className="headings" variant="h4"><strong>UpComing Interviews</strong></Typography>
         </Grid>
         <Grid item md={6} xs={12}>
          <div className={classes.search}>
             <TextField
            id="search-bar"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            label="I'm Looking For..."
            variant="outlined"
            size="small"
            name="subject"
            autoComplete="on"
            onChange={(e) => functionSearch(e.target.value)}
          />
          </div>
        </Grid>
      </Grid>
        <Divider className={classes.divider} />
          <Grid className={classes.gride}>
          { getUpCommingInterviewesData&& getUpCommingInterviewesData.results.length === 0 ? <Typography variant='h4' align="center" style={{marginTop:'10%',color:'blue'}}>Interviews Not Found</Typography>:''}
             {/* {getUpCommingInterviewesData && getUpCommingInterviewesData.results.length !==0 && */}
              {getUpCommingInterviewesData && getUpCommingInterviewesData.results.length !== 0 && getUpCommingInterviewesData.results.map((item) => {
             return (
              <div style={{ float: 'left', marginRight: '20px', marginLeft: '30px' }}>
                <div style={{ width: '35%', height: '35%', display: 'inline', textAlign: 'center' }} class="box">
                  <div style={{ display: 'table-row' }} class="box-row">
                    <Paper style={{ border: "1px solid black", borderRadius: "10px", marginBottom: "15px" }}>
                      {/* <p style={{ backgroundColor: rgbToHex('#40DDB7'), fontSize: '20px', color: 'white', width: '100%', height: '100%' }}>{(item.round && item.round.round_name && item.round.round_name) || 'None'}</p> */}
                      <Card>
                        <Grid container justify="center" alignItems="center">
                          <Avatar style={{ marginBottom: '10px', marginTop: '10px', fontSize: '50px', width: '90px', height: '90px' }} src={item.applicant && item.applicant.applicant.photo ? ReactHtmlParser(item.applicant.applicant.photo) : require('./3.png')} alt="Remy Sharp" className={classes.bigAvatar} />
                        </Grid>
                        <div style={{ fontSize: '18px' }}>
                          <span style={{ color: 'blue' }}>{(item.applicant && item.applicant.applicant.user && item.applicant.applicant.user.first_name && item.applicant.applicant.user.first_name) || ''}</span><br />
                          <span>Applied For : {(item.applicant && item.applicant.applicant.position_apply && item.applicant.applicant.position_apply.position_type && item.applicant.applicant.position_apply.position_type) || ''}</span><br />
                          <span>Subject: {(item.applicant && item.applicant.applicant.relevant_fk && item.applicant.applicant.relevant_fk.subjects && item.applicant.applicant.relevant_fk.subjects.subject_name) || ''}</span><br />
                          <span>Grade: {(item.applicant && item.applicant.applicant.relevant_fk && item.applicant.applicant.relevant_fk.grade && item.applicant.applicant.relevant_fk.grade.grade_name) || ''}</span><br />
                          <span>MCQ Score: {(item.mcq_test && item.mcq_test.mark_scored) || ''}</span><br />
                        </div>
                        <Grid style={{ marginTop: '10px' }} container justify="center" alignItems="center">
                          <table style={{ marginLeft: '5px', borderCollapse: 'collapse', width: "200px", marginBottom: "20px" }} border='0px' cellPadding="5px">
                            <th colspan="2" className={classes.tableHeadtab1}>Written Test</th>
                            <tr className={classes.tableRowtab1}>
                              <td>Score</td>
                              <td>{item.written_test !== null ? item.written_test.mark_scored : ''}</td>
                            </tr>
                            <tr className={classes.tableRowtab1}>
                              <td>Topic</td>
                              <td>{item.written_test && item.written_test.topic !== null ? item.written_test.topic.topic_name : ''}</td>
                            </tr>
                            <tr className={classes.tableRowtab1}>
                              <td>Submission</td>
                              <td>{(item.written_test && item.written_test.written_test_file && <a target="_blank" rel="noopener noreferrer" href={(item.written_test && item.written_test.written_test_file) || ''}>Click To View</a>) || <span>Not Upload</span>}</td>
                            </tr>
                          </table>
                          <table style={{ marginLeft: '5px', borderCollapse: 'collapse', width: "200px" }} border='0px' cellPadding="5px">
                            <th colspan="2" className={classes.tableHeadtab2}>Spoken Test</th>
                            <tr className={classes.tableRowtab2}>
                              <td>Score</td>
                              <td>{item.spoken_test !== null ? item.spoken_test.mark_scored : ''}</td>
                            </tr>
                            <tr className={classes.tableRowtab2}>
                              <td>Topic</td>
                              <td>{item.spoken_test && item.spoken_test.topic_name !== null ? item.spoken_test.topic_name.topic_name : ''}</td>
                            </tr>
                            <tr className={classes.tableRowtab2}>
                              <td>Audio File</td>
                              <td>{(item.spoken_test && item.spoken_test.spoken_audio && <a target="_blank" rel="noopener noreferrer" href={(item.spoken_test && item.spoken_test.spoken_audio) || ''}>Click To Listen</a>) || <span>Not Upload</span>}</td>
                            </tr>
                          </table>&nbsp;&nbsp;
                        </Grid>
                        <Grid container justify="center" alignItems="center">
                          <Table className={classes.Tabletab}>
                            <TableHead className={classes.TableHeadtab}>
                              <b>Planner Round : Scheduled On {(item.zoom && item.zoom.start_time) || ''}.</b>
                            </TableHead>
                            <TableBody>
                              <div style={{margin: '5px'}}>
                              <Button variant="contained" color="primary"
                              style={{ textTransform: 'capitalize', size: '20px', fontFamily: ' Helvetica', width: '92%', margin: '2px 0 0 0' }} 
                              onClick={() => { window.open((item.zoom && item.zoom.presenter_url && item.zoom.presenter_url) || '') }}
                              >Join Zoom Session To Take Interview</Button>
                              </div>
                            </TableBody>
                            <TableBody>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                              <Button variant="contained" color="primary"
                                style={{ textTransform: 'capitalize', size: '20px', fontFamily: ' Helvetica', width: '40%', margin: '5px 20px 0px 20px' }}
                                onClick={() => getQuestions(item.applicant && item.applicant.id, item.id)}
                                >
                                View Questions</Button>
 
                     
                              <Button variant="contained" color="primary"
                                style={{ textTransform: 'capitalize', size: '20px', fontFamily: ' Helvetica', width: '40%', margin:'5px 20px 0px 20px' }}
                                onClick={() => getMarks(item.applicant && item.applicant.id, item.id, item.total_mark)}
                                >
                                Feedback</Button>
                            </div>
                            </TableBody>
                            <Typography className={classes.TableHeadtabBottom}>
                              <b>Note: Please upload marks after completing Interview</b>
                            </Typography>
                          </Table>
                        </Grid>
                      </Card>
                    </Paper>
                  </div>
                </div>
              </div>
            )
          })}

        {getUpCommingInterviewesData && getUpCommingInterviewesData.results.length !==0 && 
            <Paper>
              <TableContainer className="role">
           <TableFooter>
           <TableRow>
             <TablePagination
               colSpan={6}
               labelDisplayedRows={() => `Page ${page + 1} of ${+getUpCommingInterviewesData.total_pages}`}
               rowsPerPageOptions={[10, 20, 30]}
               count={+getUpCommingInterviewesData.count}
               rowsPerPage={rowsPerPage || 10}
               page={page}
               SelectProps={{
                 inputProps: { 'aria-label': 'Rows per page' },
               }}
               onChangePage={handleChangePage}
               onChangeRowsPerPage={handleChangeRowsPerPage}
             />
             <TableCell style={{ marginTop: '13px' }}>
               <IconButton
                 onClick={firstPageChange}
                 disabled={page === 0 || page === 1}
               >
                 <FirstPageIcon />
               </IconButton>
               <IconButton
                 onClick={() => lastPageChange(getUpCommingInterviewesData.total_pages - 1)}
                 disabled={page === (+getUpCommingInterviewesData.total_pages - 1)}
               >
                 <LastPageIcon />
               </IconButton>
             </TableCell>
           </TableRow>
         </TableFooter>
         </TableContainer>
         </Paper>} 
          </Grid>

          <React.Fragment>
          <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          // classes={{ paper: classesD.dialogQuestions }}
          open={open}
          maxWidth="xl"
          // onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open} className={classes.paperr}>
            <div>
              <Grid>
                <Paper className={classes.paper}>
                  {/* <Typography variant='h4'>gggF2F Questions</Typography> */}
                  <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h4">
                    <strong> F2F Questions</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
                  <Divider className={classes.divider} />
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Paper className={classes.tabled}>
                        {getQuestionsData && getQuestionsData.length === 0 ? <Grid><Typography variant='h5' style={{ color: 'blue', marginTop: '15px', textAlign: 'center' }}> Questions are not Found </Typography> </Grid> : ''}
                        {getQuestionsData && getQuestionsData.length !== 0 ?
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left" style={{ width: '15%', fontWeight: 'bold' }}>Parameter</TableCell>
                                <TableCell align="left" style={{ width: '15%', fontWeight: 'bold' }}>View Question</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {getQuestionsData && getQuestionsData.map((item, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="left" style={{ width: '15%' }}>{item.parameter}</TableCell>
                                    <TableCell align="left" style={{ width: '15%' }}>
                                      <Button
                                        onClick={() => fetchQuestionsVal(item.id)}
                                        variant="contained"
                                        color="primary" >
                                        View Questions
                                        {/* <a href="/parameters" style={{textDecoration : 'none' , color : 'white'}}>View Question</a> */}
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                              }
                            </TableBody>
                          </Table> : ''}
                      </Paper>
                    </Grid>
                  </Grid>
                  {/* <Divider className={classes.divider} /> */}
                </Paper>
              </Grid>
            </div>
          </Fade>
        </Dialog>

        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          // className={classes.modal}
          classes={{ paper: classesD.dialogMarks }}
          open={view}
          maxWidth="35%"
          // onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
        <Fade in={view} className={classes.paperr}>
            <div>
              <Grid>
                <Paper className={classes.paper}>
                  {/* <Typography variant='h4'>gggF2F Questions</Typography> */}
                <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h5">
                    <strong>Feedback</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
                  <Divider className={classes.divider} />

                <Grid container spacing={2}>
                    <Grid item md={5} xs={12}>
                      <Typography variant="h6" style={{ marginTop: '10px' }}>Enter Marks Out of {confirmMarks} : </Typography>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <TextField
                        label="Enter Marks"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        required
                        type="number"
                        value={totalMarks || ''}
                        onChange={(e) => ((confirmMarks && e.target.value >= 0 && e.target.value <= parseInt(confirmMarks) && setTotalMarks(e.target.value)))}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        label="Comments"
                        variant="outlined"
                        fullWidth
                        rows="2"
                        required
                        margin="dense"
                        multiline
                        value={comments || ''}
                        onChange={(e) => setComments(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
                      <Button color="primary" variant="contained" onClick={() => submitMarks()}>Submit</Button>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </Paper>
              </Grid>
            </div>
          </Fade>
        </Dialog>
        {viewModdel && <Parameters onCloseFunction={onCloseFunc} open={viewModdel} />}
          </React.Fragment>
          {loader}
            </React.Fragment>
      )
}
UpCommingInterview.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
  };
export default withStyles(styles)(UpCommingInterview);
