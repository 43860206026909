const F2FTimeSlots = [
    { id: 1, value: '9:30 AM - 9:50 AM', send: '9:30:00-9:50:00' },
    { id: 2, value: '10:00 AM - 10:20 AM', send: '10:00:00-10:20:00' },
    { id: 3, value: '10:30 AM - 10:50 AM', send: '10:30:00 - 10:50:00' },
    { id: 4, value: '11:00 AM - 11:20 AM', send: '11:00:00-11:20:00' },
    { id: 5, value: '11:30 AM - 11:50 AM', send: '11:30:00-11:50:00' },
    { id: 6, value: '12:00 PM - 12:20 PM', send: '12:00:00-12:20:00' },
    { id: 7, value: '1:30 PM - 1:50 PM', send: '13:30:00-13:50:00' },
    { id: 8, value: '2:00 PM - 2:20 PM', send: '14:00:00-14:20:00' },
    { id: 9, value: '2:30 PM - 2:50 PM', send: '14:30:00-14:50:00' },
    { id: 10, value: '3:00 PM - 3:20 PM', send: '15:00:00-15:20:00' },
    { id: 11, value: '3:30 PM - 3:50 PM', send: '15:30:00-15:50:00' },
    { id: 12, value: '4:00 PM - 4:20 PM', send: '16:00:00-16:20:00' },
    { id: 13, value: '5:30 PM - 5:50 PM', send: '17:30:00-17:50:00' },
    { id: 14, value: '6:00 PM - 6:20 PM', send: '18:00:00-18:20:00' },
    { id: 15, value: '6:30 PM - 6:50 PM', send: '18:30:00-18:50:00' },
    { id: 16, value: '7:00 PM - 7:20 PM', send: '19:00:00-19:20:00' },
    { id: 17, value: '7:30 PM - 7:50 PM', send: '19:30:00-19:50:00' },
    { id: 18, value: '8:00 PM - 8:20 PM', send: '20:00:00-20:20:00' },
    { id: 19, value: '8:30 PM - 8:50 PM', send: '20:30:00-20:50:00' },
    { id: 20, value: '9:00 PM - 9:20 PM', send: '21:00:00-21:20:00' },

];

export default F2FTimeSlots;