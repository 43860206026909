import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  Paper
} from "@material-ui/core";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./f2f.Style";
// import { Button } from "@material-ui/core/Button";

const F2F = ({ classes }) => {
  const [token] = useState(JSON.parse(localStorage.getItem("user")));
  const [activeStep, setActiveStep] = useState(0);
  const [time, setTime] = useState("");
  const [joinMeeting, setJoinMeeting] = useState(true);
  const {
    data: f2fDetail,
    isLoading: f2fDetailLoading,
    doFetch: fetchf2fDetail
  } = useFetch(null);

  useEffect(() => {
    if (f2fDetail) {
      setActiveStep(f2fDetail.current_count - 1);
    }
  }, [f2fDetail]);

  useEffect(() => {
    if (token) {
      fetchf2fDetail({
        url: `${urls.f2FRound}?round_id=${localStorage.getItem("round_id")}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.personal_info.token}`
        }
      });
    }
  }, [fetchf2fDetail, token]);

  useEffect(() => {
    if (f2fDetail && f2fDetail.start_time) {
      setTime(
        f2fDetail && f2fDetail.start_time && f2fDetail.start_time.split(" ")
      );
    }
  }, [f2fDetail]);

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return "Select campaign settings...";
      case 1:
        return "What is an ad group anyways?";
      case 2:
        return "This is the bit I really care about!";
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  let loader = null;
  if (f2fDetailLoading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (f2fDetail) {
      let time = f2fDetail?.time.split(':');
      let minutes = parseInt(time[1])+ 20;
      let finalTime = time[0] + ':' + minutes + ':' + time[2];
      let apiDate = f2fDetail?.date + ' ' + finalTime;
      let fullDate = new Date(apiDate).toISOString();
      const d = new Date().toISOString();
      if (d > fullDate) {
        setJoinMeeting(false);
      } else {
        setJoinMeeting(true);
      }
    }
  }, [f2fDetail])

  return (
    <>
      <Typography variant='h4'>Face To Face Round</Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        {/* {f2fDetail && f2fDetail.assign === false && f2fDetail.f2f_complete === false && f2fDetail.is_clear === false && f2fDetail.complete === false && <Grid className={classes.GridText}> <p variant='h5' className={classes.papertext} style={{ color: 'blue' }}>
          If you have any concerns please get in touch with us at <a href="https://www.recruitment_mumbai@gmail.com" target="_blank">recruitment_mumbai@gmail.com</a> if you have applied for <strong>Mumbai</strong>, <strong> Pune </strong>and <strong>Kolkata</strong> positions or <a target="_blank" href="https://www.recruitment_bangalore@gmail.com">recruitment_bangalore@gmail.com</a> if you have applied for Bangalore, Hyderabad and Chennai positions.
          Best of luck!
       </p></Grid>}

        {f2fDetail && f2fDetail.round1 === true && f2fDetail.assign === true && f2fDetail.complete === true && f2fDetail.current_count === 1 && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Kudos! You have succesfully cleared the Face to Face  first round.{f2fDetail && f2fDetail.round.lenght === 2 ? "You will receive a text message once the Face to Face  Second rounds will be assigned." : ''}
              </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round1 === false && f2fDetail.assign === true && f2fDetail.current_count === 1 && f2fDetail.complete === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Thank you for applying to ORCHIDs! We wish to inform you that you have not achieved the threshold marks required to progress to the next round. We do hope you apply again to our institution! Till then – preparation is key!
         </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round2 === false && f2fDetail.assign && f2fDetail.current_count === 2 && f2fDetail.complete === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Thank you for applying to ORCHIDs! We wish to inform you that you have not achieved the threshold marks required to progress to the next round. We do hope you apply again to our institution! Till then – preparation is key!
         </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round2 === true && f2fDetail.assign && f2fDetail.current_count === 2 && f2fDetail.complete === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Kudos! You have cleared the Face to Face  Second round. {f2fDetail && f2fDetail.round.lenght === 3 ? "You will receive a text message once the Face to Face Third rounds will be assigned." : ''}
              </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round3 === false && f2fDetail.assign && f2fDetail.current_count === 3 && f2fDetail.complete === true && f2fDetail.round2 === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Thank you for applying to ORCHIDs! We wish to inform you that you have not achieved the threshold marks required to progress to the next round. We do hope you apply again to our institution! Till then – preparation is key!
         </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round3 === true && f2fDetail.assign && f2fDetail.current_count === 3 && f2fDetail.complete === true && f2fDetail.round2 === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Kudos! You have cleared the Face to Face  third round.
         </Typography>
            </Grid>
          </Grid>}



        {f2fDetail && f2fDetail.round4 === false && f2fDetail.assign && f2fDetail.current_count === 4 && f2fDetail.complete === true && f2fDetail.round3 === true && f2fDetail.round2 === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Thank you for applying to ORCHIDs! We wish to inform you that you have not achieved the threshold marks required to progress to the next round. We do hope you apply again to our institution! Till then – preparation is key!
         </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round4 === true && f2fDetail.assign && f2fDetail.current_count === 4 && f2fDetail.complete === true && f2fDetail.round3 === true && f2fDetail.round2 === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Kudos! You have cleared the Face to Face  Fourth round.
         </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round5 === false && f2fDetail.assign && f2fDetail.current_count === 5 && f2fDetail.complete === true && f2fDetail.round4 === true && f2fDetail.round3 === true && f2fDetail.round2 === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Thank you for applying to ORCHIDs! We wish to inform you that you have not achieved the threshold marks required to progress to the next round. We do hope you apply again to our institution! Till then – preparation is key!
         </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round5 === true && f2fDetail.assign && f2fDetail.current_count === 5 && f2fDetail.complete === true && f2fDetail.round4 === true && f2fDetail.round3 === true && f2fDetail.round2 === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Kudos! You have cleared the Face to Face  Fifth round.
         </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round6 === false && f2fDetail.assign && f2fDetail.current_count === 6 && f2fDetail.complete === true && f2fDetail.round5 === true && f2fDetail.round4 === true && f2fDetail.round3 === true && f2fDetail.round2 === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Thank you for applying to ORCHIDs! We wish to inform you that you have not achieved the threshold marks required to progress to the next round. We do hope you apply again to our institution! Till then – preparation is key!
         </Typography>
            </Grid>
          </Grid>}

        {f2fDetail && f2fDetail.round6 === true && f2fDetail.assign && f2fDetail.current_count === 6 && f2fDetail.complete === true && f2fDetail.round5 === true && f2fDetail.round4 === true && f2fDetail.round3 === true && f2fDetail.round2 === true && f2fDetail.round1 === true && f2fDetail.is_clear === false &&
          <Grid container spacing={2}>
            <Grid item md={2} />
            <Grid item md={8} xs={12}>
              <Typography variant="h4" style={{ color: 'blue', marginTop: '50px' }}>
                Kudos! You have cleared the Face to Face  Sixth round.
         </Typography>
            </Grid>
          </Grid>}*/}

        {f2fDetail && f2fDetail.url && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '70vh' }}>
              <h3>Your InterView Starts At {f2fDetail.start_time}</h3>
              {joinMeeting ? (
                <a
                  href={f2fDetail.url}
                  style={{ textAlign: "center" }}
                  alignItems='center'
                  target="_blank"
                >
                  <Button
                   variant='contained'
                    color='primary'>
                    Take Interview
                  </Button>
                </a>
              ) : (
                <>
                  <h3>Your Meeting Time Expired</h3>
                  <ul>
                    <h3>Note:</h3>
                    <li>To Reschedule Meeting please contact your SPOC</li>
                  </ul>
                </>
              )}
            </div>
          </>
        )}
      </Grid>
      {loader}
    </>
  );
};

F2F.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};

export default withStyles(styles)(F2F);
