import React, { useState } from 'react'
import Typography from "@material-ui/core/Typography";

const SuperDashboard = () => {
  const [profile] = useState(JSON.parse(localStorage.getItem('user')));

  return (
    <div>
      <Typography>
        Welcome to <strong style={{ fontSize: '40px', color: 'rgb(63 80 176)' }}>Super Admin Dashboard</strong>({profile?.academic_profile?.name})
      </Typography>

    </div>
  )
}

export default SuperDashboard;