export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: '5px 40px',
      marginTop: '15px',
    },
    fileUpload:{
      display:'none'
    },
    paper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1,10,10,10),
      textAlign: 'center',
    },
    UploadPaper: {
      boxShadow: theme.shadows[5],
      padding: theme.spacing(10,10,10,10),
      textAlign: 'center',
      marginTop:'1%'
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paperr: {
      padding: theme.spacing(1, 2, 1),
    },
    paperMain: {
    },
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    fixed : {
      height : '245px',
      overflowY : 'scroll',
    },
    loginButton: {
      margin: '20px 0px',
      display: 'grid',
    },
    Button: {
      borderRadius: '0px',
    },
    button: {
        margin: theme.spacing(1),
      },
      input: {
        display: 'none',
      },
});
