import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Form,
  Input,
  Col,
  Row,
  Pagination,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Typography as AntdTypography,
  Card,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  Modal,
  TablePagination,
  InputBase,
  TableContainer,
  Select,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./statecity.style";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import SearchIcon from "@material-ui/icons/Search";
import MediaQuery from "react-responsive";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";

function StateCity({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));

  const [role, setRole] = useState("");
  const [inerviewPanel, setInerviewPanel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(1);
  const [applicentName, setApplicentName] = useState("");
  const [bulkFile, setBulkFile] = useState("");
  const [accordianOpen, setAccordianOpen] = useState(false);
  const [accordianOpenFilter, setAccordianOpenFilter] = useState(false);
  const [cityName, setCityName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [checkedLoc, setCheckedLoc] = useState([]);
  const [checkedLoca, setCheckedLoca] = useState("");
  const [checked, setChecked] = React.useState(false);

  const [branch, setBranch] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [visible, setVisible] = useState(false);

  const {
    data: addInterviewPanelResponse,
    isLoading: addInterviewPanelResponseLoading,
    doFetch: addInterview,
  } = useFetch([]);
  const {
    data: tableData,
    isLoading: tableDataLoading,
    doFetch: fetchTableData,
  } = useFetch("");
  const {
    data: tableDatad,
    isLoading: tableDataLoadings,
    doFetch: fetchTableDatad,
  } = useFetch("", { suppressAlert: true });
  const {
    data: tableDatas,
    isLoading: tableDataLoadingss,
    doFetch: fetchTableDatas,
  } = useFetch("", { suppressAlert: true });

  const {
    data: updatainteriewData,
    isLoading: updatainteriewDataLoading,
    doFetch: fetchUpdataInterview,
  } = useFetch([], { suppressAlert: true });
  const [deleteInterviewData, fetchDeleteInterview] = useState();
  // const {
  //   data: deleteInterviewData,
  //   isLoading: deleteInterviewDataLoading,
  //   doFetch: fetchDeleteInterview,
  // } = useFetch([], { suppressAlert: true });

  // const fetchSearch = (e) => {
  //   setPage(0);
  //   setRowsPerPage(50);
  //   if (e.keyCode === 13) {
  //     const data = document.getElementById("search-bar").value;
  //     setApplicentName(document.getElementById("search-bar").value);
  //     functionSearch(data);
  //   }
  // };

  function functionSearch(data) {
    if (data !== "") {
      fetchTableData({
        url: `${urls.searchCityState}?search=${data}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      getTableData();
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }
  const clearBulkFunction = () => {
    setBulkFile("");
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }
  function getTableData() {
    fetchTableData({
      url: `${urls.getCityState}?page_size=${rowsPerPage || 50}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  function tableDropdown() {
    fetchTableDatad({
      url: `${urls.getState}?page_size=${rowsPerPage || 50}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  useEffect(() => {
    tableDropdown();
  }, [auth]);

  const [activeStates, setActiveStates] = useState("");

  // function activeStateDropDown() {
  //   axios.get(`${urls.getStateActive}`,{
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   })
  // }

  // useEffect(() => {
  //   activeStateDropDown();
  // },[activeStates])
  function activeStateDropDown() {
    fetchTableDatas({
      url: `${urls.getStateActive}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  useEffect(() => {
    activeStateDropDown();
  }, [auth]);

  useEffect(() => {
    if (auth) {
      getTableData();
    }
  }, [
    auth,
    addInterviewPanelResponse,
    updatainteriewData,
    deleteInterviewData,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (addInterviewPanelResponse) {
      setCheckedLoc("");
      setActiveStates("");
    }
  }, [addInterviewPanelResponse]);

  const alert = useAlert();

  const handleBulkQuestions = (value) => {
    setBulkFile(value);
  };

  const handleClose = () => {
    setRole("");
    setInerviewPanel("");
    setOpen(false);
    setChecked(null);
    setActiveStates("");
  };

  useEffect(() => {
    if (updatainteriewData) {
      setOpen(false);
      setCityName("");
      setBulkFile("");
    }
  }, [updatainteriewData]);

  let loader = null;
  if (
    addInterviewPanelResponseLoading ||
    tableDataLoading ||
    updatainteriewDataLoading
  ) {
    loader = <Loader open />;
  }

  console.log(checked, "checked");

  function updateInterviewFunction() {
    // if (!cityName) {
    //   alert.warning("fill all fields");
    //   return;
    // }
    const data = {
      id: selectedRoleId,
      state: role,
      city: checkedLoca,
      is_active: checked,
    };

    // getTableData();
    // functionSearch();
    // alert.success("data updated successfully");
  }
  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity,
  } = useFetch([], { suppressAlert: true });
  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [fetchCity]);

  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });

  function handleMultipleChange(event) {
    console.log(event.target.value);
    setBranch(event.target.value);
  }
  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [checkedLoc]);
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState();
  function deleteTopic(data) {
    setDeleteModel(true);
    setDeleteId(data);
  }

  function cancelDelete() {
    setDeleteModel(false);
    setDeleteId(null);
  }

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  function editFunction(data) {
    console.log(data, "sghhd");
    setVisible(true);
    form.setFieldsValue({
      state_id: data?.state?.id,
      citys: data?.city?.id,
    });
    setRole(data?.state?.id);

    setCheckedLoca(data?.city?.id);
    setSelectedRoleId(data?.id);
  }

  function fetchData() {
    fetchTableData({
      url: `${
        urls.filterStateCity
      }?search=${role}&page=${page}&page_size=${rowsPerPage || 50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  function handleCityMultipleChange(event) {
    setCheckedLoc(event.target.value);
  }
  function handleCityMultipleChanges(event) {
    setCheckedLoca(event.target.value);
  }

  function addInterviewPanel(formValue) {
    // const city_object = checkedLoc.map((item) => item)
    console.log(selectedRoleId, "selectedRoleId");
    if (!selectedRoleId) {
      addInterview({
        url: urls.postCityState,
        method: "POST",
        body: formValue,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      fetchUpdataInterview({
        url: `${urls.updateCityState}`,
        method: "PUT",
        body: { ...formValue, id: selectedRoleId },
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
        },
      });
    }
  }

  const [form] = Form.useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>State Name</strong>,
      dataIndex: "state_name",
      key: "state_name",
      align: "center",
      render: (text, record) => (
        <span>{record?.state ? record?.state?.state_name : ""}</span>
      ),
    },
    {
      title: <strong>City Name</strong>,
      dataIndex: "city_name",
      key: "city_name",
      align: "center",
      render: (text, record) => (
        <span>{record.city ? record.city.city_name : ""}</span>
      ),
    },

    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
    {
      title: "Active",
      key: "is_active",
      render: (data) => (
        <AntdSwitch
          onChange={() => {
            console.log(data, "data");
            fetchUpdataInterview({
              url: `${urls.updateCityState}`,
              method: "PUT",
              body: {
                is_active: !data?.is_active,
                id: data?.id,
                state: data?.state?.id,
                city: data?.city?.id,
              },
              headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                "Content-Type": "application/json",
              },
            });
          }}
          checked={data?.is_active}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>State City Details</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add State City
          </AntdButton>
        </div>
      </div>

      <Card style={{ marginBottom: "10px" }}>
        <Row>
          <Col md={6}>
            <AntdSelect
              value={role || ""}
              placeholder="Select State"
              onChange={(e) => setRole(e)}
              style={{ width: "100%" }}
              maxTagCount={3}
            >
              {tableDatad &&
                tableDatad?.results.map((option) => (
                  <AntdSelect.Option
                    key={option.id}
                    value={option.state_name}
                    name={option.state_name}
                  >
                    {option.state_name}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
          </Col>

          {/* <Col xs={2} md={4} style={{ paddingInline: "10px" }}> */}
          <AntdButton
            onClick={fetchData}
            type="primary"
            style={{ marginInline: "10px" }}
          >
            Filter
          </AntdButton>
          {/* </Col> */}

          <AntdButton
            type="primary"
            disabled={role == "" ? true : false}
            onClick={() => {
              setRole("");
            }}
            // style={{ width: "100%" }}
          >
            Clear All
          </AntdButton>
        </Row>
      </Card>

      <AntdDrawer
        title="Create"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          setSelectedRoleId(null);
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="statecity" type="primary" htmlType="submit">
              Add
            </AntdButton>
          </div>
        }
      >
        <Form
          id="statecity"
          form={form}
          layout="vertical"
          onFinish={addInterviewPanel}
        >
          <Row>
            <Col xs={24}>
              <Form.Item
                name="state_id"
                label="State"
                rules={[{ required: true, message: "Please Select State" }]}
              >
                <AntdSelect placeholder="Select State">
                  {tableDatas &&
                    tableDatas.map((option) => (
                      <AntdSelect.Option key={option.id} value={option.id}>
                        {option.state_name}
                      </AntdSelect.Option>
                    ))}
                </AntdSelect>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="citys"
                label="City"
                rules={[{ required: true, message: "Please Select City" }]}
              >
                <AntdSelect mode="multiple" placeholder="Select City">
                  {cityList &&
                    cityList.length !== 0 &&
                    cityList.map((option) => (
                      <AntdSelect.Option key={option.value} value={option.id}>
                        {option.city_name}
                      </AntdSelect.Option>
                    ))}
                </AntdSelect>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>

      {/* </Paper>  */}
      {tableData && tableData?.results?.length !== 0 && (
        <AntdTable
          columns={columns}
          dataSource={tableData?.results}
          pagination={{
            current: page,
            total: tableData?.count,
            pageSize: rowsPerPage,
            onChange: (current, pageSize) => {
              setPage(current);
              setRowsPerPage(pageSize);
            },
            pageSizeOptions: [25, 50, 75, 100],
          }}
          loading={
            addInterviewPanelResponseLoading ||
            tableDataLoading ||
            updatainteriewDataLoading
          }
        />
      )}
    </React.Fragment>
  );
}
StateCity.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(StateCity);
