export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    dividerInstruction: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      flexGrow: 1,
    },
    card: {
      minWidth: 275,
      padding: '10px',
    },
});
