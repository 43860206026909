import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
    Typography,
    Divider,
    withStyles,
    Grid,
    Button,
    TextField,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Tab,
    Tabs,
    AppBar,
    IconButton,
    TableFooter,
    TablePagination,
    InputLabel
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch'
import Loader from '../../../hoc/loader'
import styles from './spokenCompleted.Style';
import { useAlert } from '../../../hoc/alert/alert'

const SpokenCompleted = ({ classes }) => {
    const [name] = useState(JSON.parse(localStorage.getItem('user')));
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [Id, setId] = React.useState(null);
    const [page, setPage] = useState(0);
    const [value, setValue] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const alert = useAlert();


    function handleChange(event, newValue) {
        setValue(newValue);
        setId(profileDetail[newValue].id)
    }

    const {
        data: profileDetail,
        isLoading: profileDetailLoading,
        doFetch: fetchProfile,
    } = useFetch(null);
    const {
        data: applicantsList,
        isLoading: applicantsListLoading,
        doFetch: fetchApplicant,
    } = useFetch(null);

    let loader = null;
    if (applicantsListLoading || profileDetailLoading) {
        loader = <Loader open />;
    }

    useEffect(() => {
        if (name) {
            fetchProfile({
                url: urls.academicRoleList,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${name.personal_info.token}`,
                },
            });
        }
    }, [fetchProfile, name]);

    useEffect(() => {
        if (profileDetail && profileDetail.length) {
            setId(profileDetail[0].id);
        }
    }, [profileDetail])

    useEffect(() => {
        if (Id) {
            fetchApplicant({
                url: `${urls.intervieweSpokenCompleted}?role_id=${Id}&page=1&page_size=${rowsPerPage || 50}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${name.personal_info.token}`,
                },
            });
            setStartDate(null);
            setEndDate(null);
            setPage(0);
        }
    }, [Id, fetchApplicant, name])

    function handleChangePage(event, newPage) {
        setPage(newPage)
        !rowsPerPage && setRowsPerPage(50)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }

    function firstPageChange() {
        setPage(0)
    }

    function lastPageChange(lastPage) {
        setPage(lastPage)
        !rowsPerPage && setRowsPerPage(50)
    }
    const handleStartDateChange = (val) => {
        setStartDate(val)
    }
    const handleEndDateChange = (val) => {
        setEndDate(val);
    }
    const handleGetData = () => {
        if (startDate == null) {

            alert.warning('Please Select Start Date..!')
            return;
        }
        if (endDate == null) {
            alert.warning('Please Select End Date..!')
            return;
        }
        setPage(0);
        fetchApplicant({
            url: `${urls.intervieweSpokenCompleted}?start_date=${startDate}&end_date=${endDate}&role_id=${Id}&page=1&page_size=${rowsPerPage || 50}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${name.personal_info.token}`,
            },
        })

    }
    function profileTable() {
        let applicantTable = null;
        if (applicantsList && applicantsList.results && applicantsList.results.length === 0) {
            applicantTable = (
                <React.Fragment>
                    <Typography style={{ textAlign: 'center', color: 'blue', marginTop: '100px' }} variant='h4'>Records Not Found</Typography>
                </React.Fragment>
            )
        }
        if (applicantsList && applicantsList.results && applicantsList.results.length) {
            applicantTable = (
                <React.Fragment>
                    <Paper className={classes.paper} style={{ marginTop: '10px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left"><b>Name</b></TableCell>
                                    <TableCell align="left"><b>Topic Name</b></TableCell>
                                    <TableCell align="left"><b>Mark Score</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {applicantsList.results.map((details, i) => (
                                    <TableRow key={details.id}>
                                        <TableCell align="left">{details.applicant.applicant.user.first_name}</TableCell>
                                        <TableCell align="left">{details.topic_name.topic_name}</TableCell>
                                        <TableCell align="left">{details.mark_scored}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={5}
                                        labelDisplayedRows={() => `Page ${page + 1} of ${+applicantsList.total_pages}`}
                                        rowsPerPageOptions={[50, 75, 100]}
                                        rowsPerPage={rowsPerPage || 50}
                                        count={+applicantsList.count}
                                        page={page}
                                        SelectProps={{
                                            inputProps: { 'aria-label': 'Rows per page' },
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                    <TableCell style={{ marginTop: '13px' }}>
                                        <IconButton
                                            onClick={firstPageChange}
                                            disabled={page === 0 || page === 1}
                                        >
                                            <FirstPageIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => lastPageChange(applicantsList.total_pages - 1)}
                                            disabled={page === (+applicantsList.total_pages - 1)}
                                        >
                                            <LastPageIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                    </Paper>
                </React.Fragment>
            )
        }

        return applicantTable;
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} className={classes.modalPaper}>
                <Grid item sm={12} md={5} xs={12}>
                    <Typography variant="h4">Spoken Completed</Typography>
                </Grid>
                <Grid item sm={5} md={3} xs={5}>
                    <TextField
                        style={{ marginLeft: '10px' }}
                        helperText="Start Date"
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={e => handleStartDateChange(e.target.value)}
                        type="date"
                        value={startDate || ''}
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={5} md={3} xs={5}>
                    <TextField
                        style={{ marginLeft: '10px' }}
                        helperText="End Date"
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={e => handleEndDateChange(e.target.value)}
                        type="date"
                        value={endDate || ''}
                        variant="outlined"
                    />
                </Grid>
                <Grid item sm={2} md={1} xs={2}>
                    <Button variant="contained" color="primary" className={classes.button} style={{ marginTop: '10px', marginLeft: '10px' }} onClick={handleGetData}>Get</Button>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid item md={12} className={classes.paperMain}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        {profileDetail && profileDetail.length && profileDetail.map((role, i) => (
                            <Tab key={role.id} label={role.applicant_role_name} />
                        ))}
                    </Tabs>
                </AppBar>
                {profileTable()}
            </Grid>
            {loader}
        </React.Fragment>
    )
}

SpokenCompleted.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(SpokenCompleted);