import axios from "axios";
import React, { useEffect, useState } from "react";
import urls from "../../url";
import { useDispatch } from "react-redux";
import { Card, Spin, Typography, message } from "antd";
import { login } from "../../redux/actions";
import { LoadingOutlined, WarningOutlined } from "@ant-design/icons";
function SSOLogin() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loginError, setLoginError] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    if (token) {
      console.log({ token: `Bearer ${token}` });
      const formData = new FormData();
      formData.append("token", token);
      axios
        .post(`${urls.tokenAuth}`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("user", JSON.stringify(response?.data));
            dispatch(login(response?.data));
            window.location = "/";
          }
        })
        .catch((error) => {
          console.error(error, "kiuytrfd");
          message.error(
            error?.response?.data?.message ?? "Something went wrong!"
          );
          setLoginError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);
  return (
    <React.Fragment>
      <div
        className=""
        style={{
          width: "100%",
          height: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Card
          style={{
            textAlign: "center",
            background: "#F5F5F5",
            paddingTop: "0px",
          }}
        >
          <Spin
            size="large"
            spinning={loading}
            indicator={<LoadingOutlined />}
          />
          {loading && (
            <Typography.Title level={5}>
              Please wait while we log you in
            </Typography.Title>
          )}
          {loginError && (
            <>
              <Typography.Title className="">
                <WarningOutlined style={{ color: "#DC3444" }} />
              </Typography.Title>
              <Typography.Title level={5}>Cannot log you in</Typography.Title>
            </>
          )}
          <Typography.Text>
            Something went wrong with auto login?{" "}
            <a href="/login">Sign in here</a>
          </Typography.Text>
        </Card>
      </div>
    </React.Fragment>
  );
}

export default SSOLogin;
