import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Steps,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { ccEmailList } from "../../constants";
import { useForm } from "antd/es/form/Form";
import {
  EditOutlined,
  CloseOutlined,
  LeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import OfferPreview from "./OfferPreview";
import AdditionalCC from "../../../components/recruiter/offerLetterOutBound/AdditionalCC";
import urls from "../../../url";

function OfferLetterFormModal({
  open,
  onClose,
  offerMode,
  candidateDetails,
  successCallback,
  isReRelease,
  isExistingOffer,
  offerId,
}) {
  const userData = useSelector((state) => state?.auth?.user);
  const [designationList, setDesignationList] = useState([]);
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [offerApproverList, setOfferApproverList] = useState([]);
  const [additionalCCs, setAdditionalCCs] = useState([]);
  const [budgetExceeded, setBudgetExceeded] = useState(false);
  const [additionalFormData, setAdditionalFormData] = useState({});
  const [sendMode, setSendMode] = useState(false);
  const [budgetApprover, setBudgetApprover] = useState();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [formState, setFormState] = useState();
  const [editOffer, setEditOffer] = useState(false);
  const [offerForm] = useForm();

  useEffect(() => {
    if (candidateDetails) {
      fetchPositionTypeList();
      fetchDesignationListModal({
        position_type: candidateDetails?.position_type,
      });
      fetchCityList({ page_size: 100 });
      fetchOfferApproverList({ role_id: 4 });
      offerForm.setFieldsValue({
        candidate_name: candidateDetails?.applicant?.user?.first_name,
        candidate_email: candidateDetails?.applicant?.user?.email,
        candidate_contact_no: candidateDetails?.applicant?.user?.username,
        gender: candidateDetails?.applicant?.gender,
        position_type: candidateDetails?.position_type,
        designation_id: candidateDetails?.designation,
      });
      if (isExistingOffer) {
        setLoading(true);
        fetchOfferDetails();
      }
    }
    return () => {};
  }, [candidateDetails]);

  const fetchDesignationListModal = (params = {}) => {
    axios
      .get(`${urls.designationListApi}`, {
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
        if (candidateDetails?.designation) {
          let designation =
            response?.data?.filter(
              (e) => e?.id == candidateDetails?.designation
            )[0]?.name ?? null;
          setAdditionalFormData({
            ...additionalFormData,
            designation: designation,
          });
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchPositionTypeList = () => {
    axios
      .get(`${urls.positionTypes}`)
      .then((response) => {
        setPositionTypeList(response?.data);
        if (candidateDetails?.position_type) {
          let user =
            response?.data?.filter(
              (e) => e?.id == candidateDetails?.position_type
            )[0]?.budget_approver ?? null;
          setBudgetApprover(user);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchCityList = (params = {}) => {
    axios
      .get(`${urls.cityListApi}`, { params: { ...params } })
      .then((response) => {
        setCityList(response?.data?.results);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchBranchList = (params = {}, branch) => {
    axios
      .get(`${urls.branchListApi}`, {
        params: params,
      })
      .then((response) => {
        setBranchList(response?.data);
        if (isExistingOffer) {
          let branchData = response?.data?.filter(
            (e) => e?.branch?.id == branch
          )[0];
          handleBranchChange({ data: { ...branchData } });
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchOfferApproverList = (params = {}) => {
    axios
      .get(urls.employeeList, { params: { ...params } })
      .then((response) => {
        setOfferApproverList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchOfferDetails = () => {
    setLoading(true);
    axios
      .get(`${urls.offerLetter}${offerId}/`)
      .then((response) => {
        let data = response?.data[0];
        if (isExistingOffer && offerMode !== "release") {
          fetchBranchList({}, data?.branch_id);
          setAdditionalFormData((prev) => {
            return {
              ...prev,
              designation: data?.designation,
              designation_id: candidateDetails?.designation,
              offer_approver: data?.offer_approver_id,
              role: "",
            };
          });
          offerForm.setFieldsValue({
            candidate_name: data?.candidate_name,
            candidate_email: data?.candidate_email,
            candidate_contact_no: data?.candidate_contact_no,
            city: data?.state,
            branch: data?.branch_id,
            date_of_joining: dayjs(data?.date_of_joining),
            ctc_offered: Number(data?.ctc_offered),
            reporting_time: dayjs(data?.reporting_time, "hh:mm"),
            submit_documents_before_date: dayjs(
              data?.submit_documents_before_date
            ),
            confirm_before_date: dayjs(data?.confirm_before_date),
            cc_email: data?.cc,
            reporting_manager: data?.reporting_manager,
            offer_approver_email: data?.offer_approver_email,
            gender: data?.gender,
            cc: data?.cc && JSON.parse(data?.cc?.replace(/'/g, '"')),
            mrf_vacancy_code: data?.mrf_vacancy_code,
          });
        } else {
          setSendMode(true);
          setPreviewData({
            from_email: null,
            to_email: data?.candidate_email,
            cc_email: JSON.parse(data?.cc?.replace(/'/g, '"')) ?? [],
            email_subject: data?.email_subject,
            email_body: data?.offer_letter,
          });
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBranchChange = (branchData) => {
    const branch = branchData?.data?.branch;
    const school_name = `ORCHIDS - The International School, ${branch?.branch_name} - ${branch?.city_id?.city_name}`;
    const hr_name = branchData?.data?.first_st_escalation_point_name;
    const hr_mobile = branchData?.data?.first_st_poc_contact_number;
    const hr_email = branchData?.data?.first_escalation_point_email;
    const escalation_point_email = branchData?.data?.first_st_poc_email;
    console.log({
      branchData,
      branch,
      school_name,
      hr_name,
      hr_email,
      hr_mobile,
      escalation_point_email,
    });
    setAdditionalFormData((prev) => {
      return {
        ...prev,
        school_name,
        hr_name,
        hr_email,
        hr_mobile,
        escalation_point_email,
      };
    });
  };

  const createPayload = (values) => {
    console.log({ additionalFormData });
    let formValues = {
      ...values,
      ...additionalFormData,
      designation_fk: values?.designation_id,
      date_of_joining: dayjs(values?.date_of_joining)?.format("YYYY-MM-DD"),
      confirm_before_date: dayjs(values?.confirm_before_date)?.format(
        "YYYY-MM-DD"
      ),
      reporting_time: dayjs(values?.reporting_time, "HH:mm").format("hh:mm A"),
      fixed_gross_per_month: values?.ctc_offered,
      probation_period: "6 Month",
      notice_period: "1 Month",
      recruiter_id: userData?.personal_info?.user_id,
      submited_by: userData?.academic_profile?.name,
      cc: [...values?.cc, ...additionalCCs],
      interview_process: candidateDetails?.id,
      applicant: candidateDetails?.applicant?.id,
      is_budget_approval: budgetExceeded,
    };
    if (budgetExceeded) {
      formValues.budget_approver = budgetApprover;
    }
    if (!sendMode) {
      delete formValues.mrf_vacancy_code;
    }

    return formValues;
  };

  const handlePreview = (value) => {
    setSubmitLoading(true);
    setFormState(value);
    let payloadData = createPayload(value);
    payloadData.mode = "preview";
    axios
      .post(`${urls.offerLetter}`, payloadData)
      .then((response) => {
        if (response?.data?.status_code === 201) {
          setSendMode(true);
          setPreviewData({
            from_email: response?.data?.from_email,
            to_email: response?.data?.to_email,
            cc_email: response?.data?.cc_email,
            email_subject: response?.data?.email_subject,
            email_body: response?.data?.email_body,
          });
          message.success(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleSend = () => {
    setSubmitLoading(true);
    let payloadData = createPayload(formState);
    payloadData = {
      ...payloadData,
      email_subject: previewData?.email_subject,
      email_content: previewData?.email_body,
      offer_id: candidateDetails?.offer_letter,
      is_revoked: false,
      joining_date: dayjs(payloadData?.date_of_joining).format("YYYY-MM-DD"),
      mode: offerMode,
      is_resend: isReRelease,
    };
    axios
      .post(`${urls.offerLetter}`, payloadData)
      .then((response) => {
        message.success(
          `Offer Letter ${
            offerMode == "approval" ? "sent for approval" : "sent"
          } successfully`
        );
        successCallback();
        onClose();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleSendToCandidate = () => {
    setSubmitLoading(true);
    let obj = {
      offer_id: offerId,
      mode: offerMode,
      is_revoked: false,
      email_subject: previewData?.email_subject,
      email_content: previewData?.email_body,
      cc: previewData?.cc_email,
    };
    axios
      .post(`${urls.offerLetter}`, obj)
      .then((response) => {
        message.success("Offer Letter sent successfully");
        onClose();
        successCallback();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "others" },
  ].map(({ label, value }) => {
    return (
      <Select.Option
        style={{ textTransform: "capitalize" }}
        key={value}
        value={value}
      >
        {label}
      </Select.Option>
    );
  });

  const positionOptions = positionTypeList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.position_type}
    </Select.Option>
  ));

  const designationOptions = designationList?.map((each) => {
    return (
      <Select.Option key={each?.id} value={each?.id}>
        {each?.name}
      </Select.Option>
    );
  });

  const cityOptions = cityList?.map((option, index) => {
    return (
      <Select.Option key={index} value={option?.id} name={option?.city_name}>
        {option?.city_name}
      </Select.Option>
    );
  });

  const branchOptions = branchList?.map((option, index) => {
    return (
      <Select.Option key={index} value={option?.branch?.id} data={option}>
        {option?.branch?.branch_name}
      </Select.Option>
    );
  });

  const mrfVacancyOptions = candidateDetails?.mrf_vacancy_code?.map((each) => (
    <Select.Option disabled={each?.is_used} key={each?.id} value={each?.id}>
      {each?.code} {each?.is_used ? "(Occupied)" : ""}
    </Select.Option>
  ));

  const ccOptions = ccEmailList?.map((option, index) => {
    return (
      <Select.Option key={index} value={option?.email}>
        {option?.email}
      </Select.Option>
    );
  });

  const offerApproverOptions = offerApproverList?.map((each) => (
    <Select.Option key={each?.user} value={each?.email}>
      {each?.email} | {each?.erp}
    </Select.Option>
  ));

  const maxBudgetPerPosition =
    candidateDetails?.mrf_vacancy_code &&
    candidateDetails?.mrf_vacancy_code?.length > 0
      ? Number(candidateDetails?.mrf_vacancy_code[0]?.max_budget)
      : null;

  return (
    <Modal
      title={
        <Row justify={"space-between"}>
          <div>Offer Letter</div>
          <Space>
            {sendMode ? (
              <>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => {
                    setEditOffer(true);
                  }}
                >
                  Edit Offer
                </Button>
                {sendMode && offerMode != "release" && (
                  <Button
                    icon={<LeftOutlined />}
                    onClick={() => {
                      setEditOffer(false);
                      setSendMode(false);
                      offerForm.setFieldsValue({ ...formState });
                    }}
                  >
                    Back
                  </Button>
                )}
              </>
            ) : (
              <CloseOutlined onClick={onClose} />
            )}
          </Space>
        </Row>
      }
      open={open}
      centered
      onCancel={onClose}
      maskClosable={false}
      closeIcon={null}
      width={1000}
      style={{ maxHeight: "90vh", overflow: "auto" }}
      footer={[
        <Row justify="space-around">
          <Col>
            <Button type="default" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col>
            {sendMode ? (
              <Button
                loading={submitLoading}
                type="primary"
                onClick={() => {
                  if (offerMode == "release") {
                    handleSendToCandidate();
                  } else {
                    handleSend();
                  }
                }}
              >
                Send
              </Button>
            ) : (
              <Button
                loading={submitLoading}
                form="offerForm"
                htmlType="submit"
                type="primary"
              >
                Preview
              </Button>
            )}
          </Col>
        </Row>,
      ]}
    >
      <div
        style={{
          height: "70vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Spin indicator={<LoadingOutlined />} size="large" />
          </div>
        ) : sendMode ? (
          <OfferPreview
            data={previewData}
            setData={setPreviewData}
            editOffer={editOffer}
          />
        ) : (
          <Form
            id="offerForm"
            form={offerForm}
            layout="vertical"
            onFinish={handlePreview}
            scrollToFirstError
          >
            <Row align="middle" gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="candidate_name"
                  label="First Name, Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Candidate Name",
                    },
                  ]}
                >
                  <Input placeholder="Enter Candidate Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="candidate_email"
                  label="Candidate Email"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Candidate Email",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const filterEmailId = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!value || value.match(filterEmailId)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter a Valid Email")
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Candidate Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="candidate_contact_no"
                  label="Candidate Mobile No."
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Candidate Mobile No.",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const filterMobile = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
                        if (!value || value.match(filterMobile)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter a Valid Mobile Number")
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Candidate Mobile No." />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Gender",
                    },
                  ]}
                >
                  <Select placeholder="Select Gender">{genderOptions}</Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="position_type"
                  label="Position Type"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Position Type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Position Type"
                    onChange={(e) => {
                      fetchDesignationListModal({
                        position_type: e,
                      });
                      offerForm.resetFields(["designation_id"]);
                    }}
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {positionOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="designation_id"
                  label="Designation"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Designation",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Designation"
                    onChange={(e, val) => {
                      console.log(val?.children);
                      setAdditionalFormData({
                        ...additionalFormData,
                        designation: val?.children,
                      });
                    }}
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {designationOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    {
                      required: true,
                      message: "Please Select City",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select City"
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    onChange={(e, val) => {
                      fetchBranchList({
                        branch__city_id: e,
                      });
                      offerForm.resetFields(["branch"]);
                      setAdditionalFormData({
                        ...additionalFormData,
                        state: val?.name,
                      });
                    }}
                  >
                    {cityOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="branch"
                  label="Branch"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Branch",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Branch"
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    onChange={(e, val) => {
                      handleBranchChange(val);
                    }}
                  >
                    {branchOptions}
                  </Select>
                </Form.Item>
              </Col>
              {candidateDetails?.mrf_vacancy_code &&
                candidateDetails?.mrf_vacancy_code?.length > 0 && (
                  <Col span={12}>
                    <Form.Item
                      name="mrf_vacancy_code"
                      label="Vacancy Code"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Vacancy Code",
                        },
                      ]}
                    >
                      <Select
                        disabled={isExistingOffer}
                        placeholder="Vacancy Code"
                        className="w-100"
                      >
                        {mrfVacancyOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              <Col span={12}>
                <Form.Item
                  name="date_of_joining"
                  label="Date of Joining"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date of Joining",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => {
                      return current < dayjs().startOf("day");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ctc_offered"
                  label="Fixed Gross Per Annum in Digits"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Salary Details",
                    },
                    {
                      type: "number",
                      warningOnly: true,
                    },
                  ]}
                  extra={
                    maxBudgetPerPosition && (
                      <span>
                        Budget (Annual) for this position in{" "}
                        {maxBudgetPerPosition}
                      </span>
                    )
                  }
                >
                  <InputNumber
                    onChange={(e) => {
                      if (maxBudgetPerPosition) {
                        setBudgetExceeded(e > maxBudgetPerPosition);
                      }
                    }}
                    placeholder="Enter Salary Details"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="reporting_time"
                  label="Reporting Time"
                  rules={[
                    {
                      required: true,
                      message: "Please Reporting Time",
                    },
                  ]}
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    use12Hours
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="submit_documents_before_date"
                  label="Submit Documents Before Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Submit Documents Before Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => {
                      return current < dayjs().startOf("day");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="confirm_before_date"
                  label="Confirm Before Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Submit Documents Before Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => {
                      return current < dayjs().startOf("day");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="cc"
                  label="CC"
                  rules={[
                    {
                      required: true,
                      message: "Please Select CC Mails",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    maxTagCount={3}
                    placeholder="Select CC Mails"
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {ccOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="offer_approver_email"
                  label="Offer Approver Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Offer Approver Email",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, options) => {
                      return (
                        options?.children[0]
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase()) ||
                        options?.children[2]
                          ?.toLowerCase()
                          ?.includes(input?.toLowerCase())
                      );
                    }}
                    placeholder={"Select Offer Approver"}
                    onChange={(e, fullObj) => {
                      setAdditionalFormData({
                        ...additionalFormData,
                        offer_approver: Number(fullObj?.key),
                      });
                    }}
                  >
                    {offerApproverOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="reporting_manager"
                  label="Reporting Manager Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter reporting manager email",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const filterEmailId = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!value || value.match(filterEmailId)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter a Valid Email")
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Reporting Manager" className="w-100" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Additional CC's">
                  <AdditionalCC
                    tags={additionalCCs}
                    setTags={setAdditionalCCs}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </Modal>
  );
}

export default OfferLetterFormModal;
