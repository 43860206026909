let HOSTNAME = "";
let PROTO = "";
let BASE = "";
let MEDIA_BASE = "";

// Developed by Pallapu Shiva Shankar
if (typeof window !== "undefined") {
  const host = window.location.hostname;
  if (host === "careerbox.letseduvate.com") {
    HOSTNAME = "careerbox.letseduvate.com/qbox";
    PROTO = "https";
    BASE = `${PROTO}://${HOSTNAME}`;
    MEDIA_BASE = BASE;
  } else if (host === "cvbox.stage-gke.letseduvate.com") {
    HOSTNAME = "cvbox.stage-gke.letseduvate.com/qbox";
    PROTO = "https";
    BASE = `${PROTO}://${HOSTNAME}`;
    MEDIA_BASE = BASE;
  } else {
    //*******For hitting local directly*******////////////

    HOSTNAME = "cvbox.stage-gke.letseduvate.com/qbox";
    PROTO = "https";
    const PORT = "8000";
    PROTO = "https";
    // BASE = `https://7qx9ei.tunnel.pyjam.as/qbox`;
    BASE = `${PROTO}://${HOSTNAME}`;

    //*******For hitting dev directly*******////////////

    // HOSTNAME = "cvbox.dev-k8.letseduvate.com/qbox";
    // PROTO = "https";
    // BASE = `${PROTO}://${HOSTNAME}`;
    // MEDIA_BASE = BASE;

    //*******For hitting prod directly*******////////////

    // HOSTNAME = "careerbox.letseduvate.com/qbox";
    // PROTO = "https";
    // BASE = `${PROTO}://${HOSTNAME}`;
    // MEDIA_BASE = BASE;
  }
}

const urls = {
  BASE,
  MEDIA_BASE,
  base: `${BASE}`,
  login: `${BASE}/authenticate/login/`,
  profileRetrieve: `${BASE}/recruiter/ApplicantProfileRetrieveAPIView/`,
  cityList: `${BASE}/recruiter/CityListAPIView/`,
  fetchRoleBranch: `${BASE}/recruiter/applicant_roles/`,
  fetchOpeningData: `${BASE}/recruiter/filtering_openings/`,
  fetchOpeningDataAllList: `${BASE}/recruiter/filtering_openings_all_list/`,
  mobileNumSendUrl: `${BASE}/recruiter/otp_send/`,
  loginOtpSend: `${BASE}/recruiter/login_otp_send/`,
  otpVerifyurl: `${BASE}/recruiter/otp_verify/`,
  loginOtpVerify: `${BASE}/recruiter/login_otp_verify/`,
  registrationComplete: `${BASE}/recruiter/applicant_registration/`,
  registrationOthersComplete: `${BASE}/recruiter/applicant_other_registration/`,
  othersApplicantList: `${BASE}/recruiter/applicant_other_list/`,
  applicantRelevant: `${BASE}/recruiter/ApplicantRelevantRetrieveAPIView/`,
  getProfileImageApi: `${BASE}/recruiter/upload_resume_and_profile/`,
  gradeMapping: `${BASE}/recruiter/GradeMappingApiView/`,
  subjectApiView: `${BASE}/recruiter/SubjectApiView/`,
  retrievePositions: `${BASE}/recruiter/retrieve_positions/`,
  updatePositions: `${BASE}/recruiter/retrieve_update_positions/`,
  updatePositionType: `${BASE}/apiV2/recruiter/update-position/`,
  academicRoleList: `${BASE}/recruiter/AcademicRoleList/`,
  academicRoleListApi: `${BASE}/recruiter/applicant_profile_academic_roles/`,
  retrieveApplicant: `${BASE}/recruiter/RetrieveApplicantAPIView/`,
  retrieveSearchApplicant: `${BASE}/recruiter/RetrieveSearchApplicantAPIView/`,
  applicantViewProfileRetrieve: `${BASE}/recruiter/ApplicantViewProfileRetrieveAPIView/`,
  intervieweSpokenCompleted: `${BASE}/recruiter/interviewer_spoken_completed_applicants/`,
  isContactedAPI: `${BASE}/recruiter/IsContactedAPIView/`,
  assignMcqRetrieveApplicant: `${BASE}/recruiter/AssignMcqRetrieveApplicantAPIView/`,
  branch: `${BASE}/authenticate/branches/`,
  creatPositionUrl: `${BASE}/recruiter/create_positions/`,
  createEmpRole: `${BASE}/authenticate/employee_roles/`,
  interviewPanalRolesApi: `${BASE}/authenticate/interview_panel_roles/`,
  interviewersApi: `${BASE}/authenticate/retrieve_interviewers/`,
  interviewersRoleWiseEmployeeApi: `${BASE}/authenticate/interview_panel_list/`,
  interviewersRoleWiseEmployeeAdminApi: `${BASE}/authenticate/interview_panel_role_wise_employees/`,
  interviewpanalDataApi: `${BASE}/recruiter/retrieve_update_destroy_interview_panel/`,
  interviewPanalUpdataApi: `${BASE}/recruiter/`,
  employeeRegistration: `${BASE}/authenticate/employee_registration/`,
  topicCreateApi: `${BASE}/recruiter/create_written_topic/`,
  topicUpdateApi: `${BASE}/recruiter/`,
  topicGetApi: `${BASE}/recruiter/retrieve_update_delete_written_topic/`,
  spokenTopicApi: `${BASE}/recruiter/create_spoken_topic_name/`,
  spokenRetrieveApi: `${BASE}/recruiter/retrieve_update_delete_spoken_topic_name/`,
  testPaperList: `${BASE}/recruiter/TestPaperList/`,
  assignedMcqToApplicant: `${BASE}/recruiter/AssignedMcqToApplicantAPIView/`,
  reAssignMcqRetrieveApplicant: `${BASE}/recruiter/ReassignMcqRetrieveApplicantAPIView/`,
  ccefApplicantTestStart: `${BASE}/recruiter/OcefApplicantTestStart/`,
  addQuestionApi: `${BASE}/recruiter/create_question_paper/`,
  retrieveAddQuestionApi: `${BASE}/recruiter/retrieve_question_paper/`,
  addQuesUpdateDeleteApi: `${BASE}/recruiter/`,
  interviewPanelApi: `${BASE}/authenticate/retrieve_interview_panel/`,
  addInterviewApi: `${BASE}/recruiter/add_interview_panel/`,
  addQuestions: `${BASE}/question/add_questions/`,
  bulkUploadQuestions: `${BASE}/question/bulk_upload_questions/`,
  addQues: `${BASE}/question/testing/`,
  viewQuestionApi: `${BASE}/question/retrieve_update_destroy_questions/`,
  downloadQuestionApi: `${BASE}/question/download_questions/`,
  downloadQuestionWordApi: `${BASE}/question/download_questions_word_set/`,
  ocefAnswerSave: `${BASE}/recruiter/OcefAnswerSave/`,
  ocefTestStatus: `${BASE}/recruiter/OcefTestStatus/`,
  subjectMapping: `${BASE}/question/retrieve_grade_related_subjects/`,
  spokenRetrieveDel: `${BASE}/recruiter/`,
  updateQuestionApi: `${BASE}/question/`,
  ocfpMcq: `${BASE}/recruiter/ocef_mcq_completed_applicants/`,
  createChapterApi: `${BASE}/question/create_chapters/`,
  viewChaptersApi: `${BASE}/question/retrieve_update_destroy_chapters/`,
  viewSubjectApi: `${BASE}/recruiter/retrieve_subjects/`,
  CreateParameterApi: `${BASE}/recruiter/create_round_wise_parameter/`,
  viewParameterApi: `${BASE}/recruiter/retrieve_update_round_wise_parameters/`,
  updateParameterApi: `${BASE}/recruiter/`,
  updateChapterApi: `${BASE}/question/`,
  parametersApi: `${BASE}/recruiter/round_wise_parameters/`,
  createF2FquestionApi: `${BASE}/recruiter/create_f2f_questions/`,
  getF2FQuestionsApi: `${BASE}/recruiter/retrieve_f2f_questions/`,
  getRoundMappingApi: `${BASE}/recruiter/f2f_round_names/`,
  createSubjectApi: `${BASE}/recruiter/create_subject/`,
  updateF2FQuestionApi: `${BASE}/recruiter/`,
  exportOcfpMcqCompleted: `${BASE}/recruiter/export_ocfp_mcq_completed_applicants`,
  updateSubjectApi: `${BASE}/recruiter/`,
  todaysInterviewApi: `${BASE}/recruiter/today_interviews/`,
  allInterviewsApi: `${BASE}/apiV2/recruiter/interview-details/`,
  completedInterviewApi: `${BASE}/recruiter/completed_interviews/`,
  checkAccessCode: `${BASE}/recruiter/check_access_code/`,
  retrieveInstructions: `${BASE}/recruiter/retrieve_instructions/`,
  applicantMcqTest: `${BASE}/recruiter/applicant_mcq_test/`,
  viewWrittenTest: `${BASE}/recruiter/view_written_test/`,
  saveApplicantMcqTest: `${BASE}/recruiter/save_applicant_mcq_test/`,
  accessCodeApi: `${BASE}/recruiter/check_written_test_access_code/`,
  getTopicApi: `${BASE}/recruiter/applicant_written_test_topic/`,
  submitTopicApi: `${BASE}/recruiter/`,
  writtenRetrieveApplicant: `${BASE}/recruiter/WrittenRetrieveApplicantAPIView/`,
  assignWrittenTestPapper: `${BASE}/recruiter/AssignWrittenTestPapper/`,
  spokenTestRetrieveApplicant: `${BASE}/recruiter/SpokenTestRetrieveApplicantAPIView/`,
  uploadSpokenTest: `${BASE}/recruiter/UploadSpokenTest/`,
  retrieveInterviewers: `${BASE}/authenticate/retrieve_interviewers/`,
  retrieveSpokenTopics: `${BASE}/recruiter/retrieve_spoken_topics/`,
  checkInterviewFree: `${BASE}/recruiter/CheckInterviewFree/`,
  scheduleInterview: `${BASE}/recruiter/schedule_interview/`,
  assignSpokenTest: `${BASE}/recruiter/AssignSpokenTest/`,
  upcomingInterviewsApi: `${BASE}/recruiter/upcoming_interviews/`,
  pendingInterviewApi: `${BASE}/recruiter/pending_interviews/`,
  interviewDetailApi: `${BASE}/recruiter/admin_interview_detail/`,
  recruiterInterviewDetails: `${BASE}/recruiter/recruiter_interview_detail/`,
  ocfpApplicantRollApi: `${BASE}/recruiter/ocfp_applicant_roles/`,
  addInstructionApi: `${BASE}/recruiter/create_instructions/`,
  getInstructionApi: `${BASE}/recruiter/retrieve_update_instructions/`,
  updateInstructionApi: `${BASE}/recruiter/`,
  todaysSpokenApi: `${BASE}/recruiter/retrieve_today_spoken_interviews/`,
  spokenTestCompletedApi: `${BASE}/recruiter/SpokenTestCompletedApiView/`,
  spokenScoreRetrieveApi: `${BASE}/recruiter/SpokenScoreRetrieveApiView/`,
  uploadSpokenScore: `${BASE}/recruiter/UploadSpokenScore/`,
  reassignWrittenApplicantList: `${BASE}/recruiter/ReassignWrittenApplicantList/`,
  applicantWrittenTestAssignResApi: `${BASE}/recruiter/CheckApplicantStatus/`,
  applicantMcqTestAssignResApi: `${BASE}/recruiter/CheckApplicantStatus/`,
  reassigningWrittenTest: `${BASE}/recruiter/ReassigningWrittenTest/`,
  mcqTestCompletedApi: `${BASE}/recruiter/MCQTestCompletedApiView/`,
  applicantspokenTestAssignResApi: `${BASE}/recruiter/CheckApplicantStatus/`,
  plannerRoundRetrieve: `${BASE}/apiV2/recruiter/planner-round-retrieve/`,
  culturalFitRoundRetrieve: `${BASE}/recruiter/CulturalFitRoundRetrieveApiView/`,
  stressRoundRetrieve: `${BASE}/recruiter/StressRoundRetrieveApiView/`,
  leaderShipRoundRetrieve: `${BASE}/recruiter/LeaderShipRoundRetrieveApiView/`,
  getWrittenTopic: `${BASE}/recruiter/WrittenPaperList/`,
  writtenTestCompletedApiView: `${BASE}/recruiter/WrittenTestCompletedApiView/`,
  writtenScoreRetrieveApiView: `${BASE}/recruiter/WrittenScoreRetrieveApiView/`,
  uploadWrittenScore: `${BASE}/recruiter/UploadWrittenScore/`,
  reassignRetrieveApplicant: `${BASE}/recruiter/ReassignRetrieveApplicantAPIView/`,

  assignToRecruiter: `${BASE}/recruiter/hr_head_assign_to_recruiter/`,

  reassignedApplicant: `${BASE}/recruiter/ReassignedApplicantAPIView/`,

  retrieveRecuriterPanel: `${BASE}/authenticate/RetrieveRecuriterPanelEmployees/`,
  f2FRound: `${BASE}/recruiter/F2FRound/`,
  assignStressRound: `${BASE}/recruiter/AssignStressRound/`,
  assignLeaderShipRound: `${BASE}/recruiter/AssignLeaderShipRound/`,
  assignPlannerRound: `${BASE}/apiV2/recruiter/AssignPlannerRound/`,
  assignCulturalFitRound: `${BASE}/recruiter/AssignCulturalFitRound/`,
  getZoomDataApi: `${BASE}/recruiter/applicant_spoken_test/`,
  getf2fInterviewQuestionsApi: `${BASE}/recruiter/f2f_questions_for_interviewer/`,
  sendingMarksofTodaysInterviewApi: `${BASE}/recruiter/`,
  reassigningMcqTest: `${BASE}/recruiter/ReassignedMcqTest/`,
  viewWrittenTestApi: `${BASE}/recruiter/view_written_test/`,
  todaysSpokenTopicCompletedApi: `${BASE}/recruiter/retrieve_today_spoken_interviews/`,
  FaceToFaceRolesApi: `${BASE}/recruiter/academic_roles/`,
  FaceToFaceScoreRetriveApi: `${BASE}/recruiter/f2f_completed_applicants/`,
  OfferLetterRetriveApi: `${BASE}/recruiter/applicant_offer_letter/`,
  OfferLetterOutBoundRetriveApi: `${BASE}/apiV2/recruiter/applicants-outbound-offer-letter/`,
  coordinateAndPrincipleRoleList: `${BASE}/recruiter/CoordinateAndPrincipleRoleList/`,
  changePasswordApi: `${BASE}/recruiter/user_reset_password/`,

  employeeList: `${BASE}/authenticate/retrieve_update_employee_details/`,
  updateEmpDetails: `${BASE}/authenticate/`,
  deleteEmpDeatails: `${BASE}/authenticate/`,
  ScoreAssignedApi: `${BASE}/recruiter/applicant_round_wise_score_details/`,
  GetPositionsApi: `${BASE}/recruiter/retrieve_positions_view/`,
  RecruterUpdateOthersRoleApi: `${BASE}/recruiter/recruiter_update_applicant_role/`,
  getCSVfileAPi: `${BASE}/recruiter/export_applicant_history/`,
  getViewSpokenDataApi: `${BASE}/recruiter/view_spoken_test_api/`,
  forgetPasswordOtpGeneratedApi: `${BASE}/recruiter/forgot_password_link/`,
  forgetPasswordApi: `${BASE}/recruiter/forgot_password/`,
  userTableList: `${BASE}/authenticate/HrHeadListAPIView/`,
  updateHrHeadDetail: `${BASE}/authenticate/`,
  graduationList: `${BASE}/recruiter/GraduationListAPIView/`,
  postGraduationList: `${BASE}/recruiter/PostGraduationListAPIView/`,
  postCollegeList: `${BASE}/recruiter/CollegeListAPIView/`,
  updateRecuriter: `${BASE}/recruiter/update_applicant_relevant_profile/`,
  Round4Retrieve: `${BASE}/recruiter/Round4RetrieveApiView/`,
  AssignRound4: `${BASE}/recruiter/AssignRound4/`,
  BusinessHeadRetrieve: `${BASE}/recruiter/BusinessHeadRetrieveApiView/`,
  AssignBusinessHead: `${BASE}/recruiter/AssignBusinessHead`,
  HRRoundRetrieve: `${BASE}/recruiter/HRRoundRetrieveApiView/`,
  AssignHRRound: `${BASE}/recruiter/AssignHRRound`,
  AddResume: `${BASE}/recruiter/add_resume/`,

  listResume: `${BASE}/recruiter/list_resume/`,

  viewSubjectApiView: `${BASE}/recruiter/SubjectListApiView/`,
  roleWisePositionApi: `${BASE}/recruiter/RoleWisePositionApiView/`,
  applicantUpdatePosition: `${BASE}/recruiter/ApplicantUpdatePosition/`,
  // retrieveUpdateOcfpDemoTest: `${BASE}/retrieve_update_ocfp_demo_test/`,
  getTopicApiOCFPDemo: `${BASE}/recruiter/retrieve_update_ocfp_demo_test/`,
  getGrades: `${BASE}/recruiter/get_grades/`,
  applyTransferPost: `${BASE}/transfer/teacher_transfer/`,
  fetchTransferData: `${BASE}/transfer/transfer_position/`,
  transferAuntenticate: `${BASE}/transfer/principal_transfer_verification/`,
  requestTypes: `${BASE}/transfer/get_transfer_list/`,
  acceptedRequests: `${BASE}/transfer/principal_transfer_verification/`,
  reviererRoleListApi: `${BASE}/reviewer/ApplicantRoleReviewer/`,
  reviewApplicantApi: `${BASE}/reviewer/ApplicantDetailReviewer`,
  applicantFullDetailsApi: `${BASE}/reviewer/DetailViewReviewer/`,
  openningsListApi: `${BASE}/recruiter/OcfpPositionListAPIView/`,
  OcfpAssignApplicentsApi: `${BASE}/recruiter/OcfpAssignToInterviewer/`,
  hrHeadListApi: `${BASE}/recruiter/HrHeadEmployeeAPIView/`,
  InterviewerListApi: `${BASE}/recruiter/InterviewerListAPIView/`,
  assignOcfpApi: `${BASE}/recruiter/OcfpAssigned/`,
  reassiginRetitveApplicentsApi: `${BASE}/recruiter/OcfpReAssignToInterviewer/`,
  reAssignOcfpApi: `${BASE}/recruiter/OcfpAssigned/`,
  getOcfcInterviewApi: `${BASE}/recruiter/RetrieveApplicantInterviewer/`,
  getOcfcHrInterviewApi: `${BASE}/recruiter/RetrieveApplicantHrHead/`,
  getProcessModalInfoApi: `${BASE}/recruiter/RetrieveDemoTest/`,
  getSalarySlabApi: `${BASE}/recruiter/SalarySlapAPI/`,
  searchApplicentsApi: `${BASE}/recruiter/SearchHRHeadAssignToRecruiter/`,
  applicantStatus: `${BASE}/recruiter/ApplicantStatus/`,
  questionPapersListApi: `${BASE}/recruiter/question_paper_filter/`,
  RoleListApi: `${BASE}/apiV2/recruiter/position-type-wise-role/`,
  ViewOpenningsApi: `${BASE}/recruiter/position_type_wise_openings/`,
  scoreUploadApplicentsList: `${BASE}/apiV2/recruiter/score-upload-retrieve/`,
  ViewTestFileApi: `${BASE}/recruiter/ViewUploadApi/`,
  uploadTestScoreApi: `${BASE}/recruiter/UploadScore/`,
  f2fApplicentsApi: `${BASE}/apiV2/recruiter/planner-round-retrieve/`,
  crudRoles: `${BASE}/recruiter/create_retrieve_update_delete_applicant_role/`,
  campusDrive: `${BASE}/recruiter/retrieve_campus_drive_applicants/`,
  userProfileApi: `${BASE}/authenticate/employee_user_info/`,
  changePasswordApiinProfil: `${BASE}/authenticate/change_password/`,
  startProcess: `${BASE}/recruiter/StartProcessApi/`,
  startProcessNew: `${BASE}/recruiter/StartProcessNewApi/`,
  assignCampusDrive: `${BASE}/recruiter/assign_campus_drive_applicants/`,
  submitBasicProfileApi: `${BASE}/recruiter/applicant_profile_details/`,
  uploadResumeImageApi: `${BASE}/recruiter/resume_and_profile_upload/`,
  recruiterReports: `${BASE}/apiV2/recruiter/recruiter-reports/`,
  getParameters: `${BASE}/recruiter/parameters/`,
  getQuestionsVal: `${BASE}/recruiter/f2f_questions_for_interviewer/`,
  addBulkData: `${BASE}/recruiter/resume_bulk_upload/`,
  submitMarksApi: `${BASE}/recruiter/applicant_f2f_score_upload/`,
  f2fApplicentntHistoryApi: `${BASE}/recruiter/retrieve_applicant_history/`,
  f2fReassignApplicentsApi: `${BASE}/recruiter/retrieve_reschedule_f2f_interview/`,
  reScheduleZoomMeeting: `${BASE}/recruiter/RescheduleZoomMeeting/`,
  checkRescheduleInterviewFree: `${BASE}/recruiter/CheckRescheduleInterviewFree/`,
  reassignedToRecruiterAPIView: `${BASE}/recruiter/ReassignedToRecruiterAPIView/`,
  searchApplicantRecruiter: `${BASE}/apiV2/recruiter/SearchApplicantRecruiter/`,
  downloadMainCsv: `${BASE}/recruiter/export_new_process_applicant_history/`,
  downloadApplicantHistoryCsv: `${BASE}/recruiter/export_applicant_history_by_filter/`,
  cancelInterviewAPI: `${BASE}/recruiter/CancelInterview/`,
  searchF2FAPI: `${BASE}/recruiter/SearchApplicantRecruiter/`,
  offerLetterRelease: `${BASE}/apiV2/recruiter/offer-letter-email/`,
  updateUserPosition: `${BASE}/recruiter/Change_user_position/`,
  deleteApplicant: `${BASE}/recruiter/user_active_and_inactive/`,
  offerLetterList: `${BASE}/recruiter/offer_letter_retrieve/`,
  retrivePositionDetails: `${BASE}/recruiter/retrieve_position_apply_details/`,
  dashboardStatus: `${BASE}/recruiter/dashboard_status/`,
  branchCityMapping: `${BASE}/authenticate/branchcity/`,
  otherApplicantsUpdate: `${BASE}/recruiter/update_other_applicants/`,
  reportData: `${BASE}/recruiter/registerd_users_report/`,
  positionReport: `${BASE}/recruiter/positions_report/`,
  offerCsv: `${BASE}/recruiter/offer_letter_report/`,
  positionUpdateCityBranch: `${BASE}/recruiter/update_position_city_branch/`,
  reAssignTest: `${BASE}/apiV2/recruiter/reassign-test/`,
  offerLetterAccept: `${BASE}/recruiter/offer_letter_accept/`,
  getBranch: `${BASE}/recruiter/list_branch_details/`,
  deleteBranch: `${BASE}/recruiter/delete_branch_details/`,
  postBranch: `${BASE}/recruiter/create_branch_details/`,
  uploadBranch: `${BASE}/recruiter/create_branch_details_csv/`,
  getRoleDetails: `${BASE}/recruiter/list_role_details/`,
  getRoleListDetails: `${BASE}/recruiter/list_of_all_role_details/`,
  getZoomRecording: `${BASE}/recruiter/zoom_recordings_fetch/`,
  createRoleDetails: `${BASE}/recruiter/create_role_details/`,
  updateRoleDetails: `${BASE}/recruiter/update_role_details/`,
  bulkUploadRoleDetails: `${BASE}/recruiter/bulk_upload_role_details/`,
  searchRoleDetails: `${BASE}/recruiter/get_role_details/`,
  updateBranch: `${BASE}/recruiter/update_branch_details/`,
  getBranchesList: `${BASE}/recruiter/branch_list/`,
  createBranchApi: `${BASE}/recruiter/branch_crud_api/`,
  branchFilterListApi: `${BASE}/recruiter/branch_filter_list/`,
  cityListApi: `${BASE}/recruiter/city_list/`,
  cityCreateUpdateApi: `${BASE}/recruiter/city_api/`,
  cityListFilter: `${BASE}/recruiter/city_list_filter/`,
  filterBranch: `${BASE}/recruiter/Filter_branch_details/`,
  getState: `${BASE}/recruiter/list_state/`,
  getStateActive: `${BASE}/recruiter/list_of_states/`,
  postState: `${BASE}/recruiter/create_state/`,
  putState: `${BASE}/recruiter/update_state/`,
  filterState: `${BASE}/recruiter/get_state_filter/`,
  filterStateCity: `${BASE}/recruiter/filter_and_search_state_city_mapping/`,
  getCityState: `${BASE}/recruiter/filter_and_search_state_city_mapping/`,
  postCityState: `${BASE}/recruiter/state_city_mapping_create/`,
  searchCityState: `${BASE}/recruiter/filter_and_search_state_city_mapping/`,
  deleteCityState: `${BASE}/recruiter/delete_state_city_mapping/`,
  updateCityState: `${BASE}/recruiter/update_state_city_mapping/`,
  updateStatus: `${BASE}/recruiter/re_update_rouond/`,
  updateStatusF2f: `${BASE}/recruiter/whatsapp_notifications/`,
  getGrade: `${BASE}/recruiter/GradeMappingApiView/`,
  deleteGrade: `${BASE}/recruiter/delete_grade/`,
  postGrade: `${BASE}/recruiter/create_grade/`,
  updateGrade: `${BASE}/recruiter/update_grade/`,
  filterGrade: `${BASE}/recruiter/retrieve_filter_grade/`,
  newGrade: `${BASE}/recruiter/GradeMappingListPositionType/`,
  previousApplicantHistory: `${BASE}/recruiter/previous_applicant_history/`,
  previousRoleUpdate: `${BASE}/recruiter/update_applicant_role_api/`,
  designationDetails: `${BASE}/recruiter/designation_api/`,
  designationListApi: `${BASE}/recruiter/designationlist_api/`,
  branchListApi: `${BASE}/recruiter/list_of_branch_details/`,
  stateListApi: `${BASE}/recruiter/list_of_states/`,
  applicantCountApi: `${BASE}/recruiter/registered_candidate_count/`,
  todayRegisteredCandidate: `${BASE}/recruiter/retrieve_applicant_history/`,
  interviewDetailsCountList: `${BASE}/recruiter/interview_detail_count/`,
  offerLetterCount: `${BASE}/recruiter/offer_letter_count/`,
  offerletterCountList: `${BASE}/recruiter/offer_letter_retrieve/`,
  openingsPositionCount: `${BASE}/recruiter/position_count/`,
  recruiterInterViewDetail: `${BASE}/recruiter/recruiter_interview_detail/`,
  mrfForm: `${BASE}/apiV2/mrf/mrf-forms/`,
  mrfTimeLine: `${BASE}/apiV2/mrf/progress-tracker/`,
  otherJobPosts: `${BASE}/apiV2/mrf/job-portal/`,
  shortList: `${BASE}/apiV2/recruiter/applicant-position-apply`,
  joiningStatus: `${BASE}/apiV2/recruiter/position-apply-update`,
  applicantDocuments: `${BASE}/apiV2/recruiter/applicant-document-upload`,
  applicantAcceptReject: `${BASE}/apiV2/recruiter/accept-reject-applicant`,
  bulkRegister: `${BASE}/apiV2/recruiter/bulk-register/`,
  registrationStatus: `${BASE}/apiV2/recruiter/registration-status/`,
  retrieveApplicants: `${BASE}/apiV2/recruiter/applicants-retrieve/`,
  positionTypes: `${BASE}/apiV2/recruiter/position-type/`,
  employeeListGlobal: `${BASE}/apiV2/authenticate/employee-erp-search/`,
  mrfDashboard: `${BASE}/apiV2/mrf/mrf-dashboard/`,
  tempPerformance: `${BASE}/apiV2/recruiter/recruiter-dashboard`,
  applicantDashboard: `${BASE}/recruiter/candidate_detail_count/`,
  mrfChat: `${BASE}/apiV2/mrf/clarity-chatbox/`,
  tokenAuth: `${BASE}/apiV2/authenticate/decode-token/`,
  rewardParameters: `${BASE}/apiV2/recruiter/recruiter-scoring-parameters/`,
  employeeScoringList: `${BASE}/authenticate/retrieve_update_employee_details/`,
  employeeScoringHistory: `${BASE}/apiV2/authenticate/employee-scoring/`,
  referralCityList: `${BASE}/apiV2/referral/city-list/`,
  referralBranchList: `${BASE}/apiV2/referral/branch-city/`,
  teacherReferralListApi: `${BASE}/apiV2/referral/referral-form-list/`,
  referralRoleList: `${BASE}/apiV2/referral/referral-designation-type/`,
  referralSubjectList: `${BASE}/apiV2/referral/subject-list/`,
  openPositionListApi: `${BASE}/apiV2/referral/retrieve-positions/`,
  applicantPositionMappingApi: `${BASE}/apiV2/referral/align-referred-candidate/`,
  OfferLetterRevokeApi: `${BASE}/apiV2/recruiter/offer-letter-revoke/`,
  mrfReport: `${BASE}/apiV2/mrf/mrf-reports-v2/`,
  mrfPendingReport: `${BASE}/apiV2/mrf/mrf-reports-v3/`,
  cancelInterview: `${BASE}/apiV2/recruiter/cancel-interview/`,
  academicYearsList: `${BASE}/apiV2/mrf/academic-years/`,
  offerLetter: `${BASE}/apiV2/recruiter/offer-letter/`,
  offerLetterApproval: `${BASE}/apiV2/recruiter/offer-letter-approval/`,
  applicantDetails: `${BASE}/apiV2/recruiter/applicant-profile`,
  individualRewardReport: `${BASE}/apiV2/authenticate/employee-scoring-reports/`,
  multipleApplicantDetails: `${BASE}/apiV2/recruiter/applicants-profile`,
  applicantsProfiles: `${BASE}/apiV2/recruiter/applicants-search-retrieve/`,
  fileUploadReplacement: `${BASE}/apiV2/mrf/generate-presigned-url/`,
  retrieveEmployeeList: `${BASE}/apiV2/authenticate/employee-details/`,
  downloadEmployeeReport: `${BASE}/apiV2/authenticate/employee-reports/`,
  updateEmployeeDetails: `${BASE}/apiV2/authenticate/update-employee-details/`,
  mrfVacancyCode: `${BASE}/apiV2/mrf/mrf-vacancy-code/`,
  singleCandidateUpload: `${BASE}/apiV2/recruiter/single-applicant-registration/`,
  releasedOfferLetterList: `${BASE}/apiV2/recruiter/released-offer-letter/`,
};
export default urls;
