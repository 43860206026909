import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
} from '@material-ui/core';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import styles from './changePassword.Style';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader'

const ChangePassword = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [ERPPhone, setERP] = useState('');
  const [password , setPassword] = useState('');

  const alert = useAlert();

    const {
        data:ChangePasswordData,
        isLoading:ChangePasswordDataLoading,
        doFetch:fetchUpdatePassword
    } = useFetch(null)

  let loader = null;
  if (ChangePasswordDataLoading) {
    loader = <Loader open />;
  }

  useEffect(()=>{
      if(ChangePasswordData){
          setERP('')
          setPassword('')
      }
  },[ChangePasswordData])

  function handleMobileNO(e) {
    const re= /^[0-9]+$/g
    if ((e.target.value === '' || re.test(e.target.value))&&(e.target.value.length <= 10)) {
      setERP(e.target.value)
      
    }
  }

  


 function CreateNewPasswordFunction(){
     if(!ERPPhone || !password){
         alert.warning('Fill the required Fields')
         return
     }
     const erpData = {
         'password':password,
         'username':ERPPhone,
     }
     fetchUpdatePassword({
        url: urls.changePasswordApi,
        method: 'PUT',
        body:erpData,
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
        }
     });
 }
 
  return (
    <React.Fragment>
      <Typography className ="headings" variant="h4"><strong>Reset Password</strong></Typography>
      <Divider className={classes.divider} />  
        <Grid className={classes.createPassword}> 
        <Grid container spacing={2}>
            <Grid item md={4}/>
            <Grid item md={4} xs={12}>
              <TextField
                label="Mobile No."
                margin="dense"
                fullWidth
                required
                type='text'
                value={ERPPhone || ''}
                //onChange={e => setERP(Math.max(0, parseInt(e.target.value)).toString().slice(0,11))}
                onChange={e => handleMobileNO(e)}
                variant="outlined"
              />
              <TextField
                label="New Password"
                margin="dense"
                fullWidth
                required
                value={password || ''}
                onChange={e => setPassword(e.target.value)}
                
                variant="outlined"
              />
            </Grid>
            <Grid item md={5}/>
              <Grid item md={2} xs={12}>
                <Button
                  className={classes.applyButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => CreateNewPasswordFunction()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
        </Grid>        
      {loader}
    </React.Fragment>
  )
}

ChangePassword.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ChangePassword);
