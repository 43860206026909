
import React, { useState, useEffect } from 'react';
import {
    Divider,
    Grid,
    TextField,
    MenuItem,
    Paper,
    Button,
    withStyles,
    Fab,
    Modal,
    Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './createParameter.style';
import urls from '../../../url';
import { useAlert } from '../../../hoc/alert/alert';
import Loader from '../../../hoc/loader';
import useFetch from '../../../hoc/useFetch';
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


function CreateParameter({ classes }) {
    const [auth] = useState(JSON.parse(localStorage.getItem('user')));
    const [round, setRound] = useState('');
    const [parameter, setParameter] = useState('')
    const [open, setOpen] = useState(false);

    const alert = useAlert();

    const {
        data: roleData,
        isLoading: roleLoading,
        doFetch: fetchRole,
    } = useFetch([], { suppressAlert: true });


    const {
        data: CreateParameterData,
        isLoading: CreateParameterDataLoading,
        doFetch: fetchAddParameter
    } = useFetch([], { suppressAlert: true });

    useEffect(() => {
        if (CreateParameterData) {
            setParameter('')
            setOpen(false)
        }
    }, [CreateParameterData])

    useEffect(() => {
        if (auth) {
            fetchRole({
                url: urls.getRoundMappingApi,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.personal_info.token}`,
                }
            });
        }
    }, [fetchRole, auth]);

    let loader = null;
    if (roleLoading || CreateParameterDataLoading) {
        loader = <Loader open />;
    }
    function CreateParameterFunction() {
        if (!round) {
            alert.warning('Select Round')
            return
        }else if(!parameter){
            alert.warning('Enter Parameter')
            return
        }

        const parameterData = {
            'parameter_name': parameter,
            'round_id': round
        }

        fetchAddParameter({
            url: urls.CreateParameterApi,
            method: 'POST',
            body: parameterData,
            headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                'Content-Type': 'application/json',
            }
        });
    }
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Fab title="Add Parameter" color="primary" aria-label="add" size="small" className={classes.fab} onClick={() => { setOpen(true) }}>
                <AddIcon fontSizeSmall></AddIcon>
            </Fab>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                style={{width:"80%",marginLeft:"10%"}}
            >

                <Fade in={open}>
                    <Paper className={classes.paperr}>
                        <Typography className="headings" variant='h4'><strong>Add F2F Parameter</strong></Typography>
                        <Divider className={classes.divider} />
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Round"
                                    className={classes.textField}
                                    margin="dense"
                                    required
                                    onChange={e => setRound(e.target.value)}
                                    select
                                    value={round || ''}
                                    variant="outlined"
                                >
                                    {roleData
                                        ? (roleData.map(data => (
                                            <MenuItem
                                                value={data.id}
                                                key={data.id}
                                                name={data.round_name}
                                            >
                                                {data.round_name ? data.round_name : ''}
                                            </MenuItem>
                                        )))
                                        : (<MenuItem
                                            value='0'
                                            key='0'
                                            name='None'
                                        >
                                            None
                                        </MenuItem>)
                                    }
                                </TextField>
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <TextField
                                    label="Parameter"
                                    className={classes.textField}
                                    margin="dense"
                                    type='text'
                                    required
                                    onChange={e => setParameter(e.target.value)}
                                    value={parameter || ''}
                                    variant="outlined"
                                >
                                </TextField>
                            </Grid>
                            <Grid container align="center" justify="center">
                            <Grid item md={6} xs={12}>
                                <Button
                                    // className={classes.applyButtonModal}
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    // style={{ marginLeft: '35%' }}
                                    onClick={() => CreateParameterFunction()}
                                >
                                    Add Parameter
                                </Button>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                    </Paper>
                </Fade>
            </Modal>
            {loader}
        </React.Fragment>
    )
}
CreateParameter.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CreateParameter);