export default theme => ({
  mainBox: {
    width: "80%",
    height: "auto",
    margin: "0 auto",
    boxShadow: " -3px -3px 7px #b4c4d070 , 3px 3px 7px #52628870 ",
    display: "grid",
    gridTemplateColumns: "repeat(2 , 1fr)",
    padding: "15px 75px 15px 15px",
    '@media (max-width: 600px)' : {
      width: '70%',
      height: 'auto',
      margin: '0 auto',
      display: 'grid',
      padding:'0px !important',
      boxShadow: '-3px -3px 7px #b4c4d070, 3px 3px 7px #52628870',
      gridTemplateColumns: 'repeat(2 , 1fr)',
        }
  },
  leftBox: {
    padding: "5px"
  },
  rightBox: {
    // width: "60%",
    // height: "70%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    boxShadow: " -3px -3px 7px #b4c4d070 , 3px 3px 7px #52628870 ",
    marginTop: "10%",
    // marginLeft: "25%",
    flexDirection: "column",
    '@media (max-width: 600px)' : {
    height: '11vh',
    position: 'relative',
    top: '162px',
    left: '-128px',
    width: '65px !important',
    height: '13vh !important',
    marginLeft: '0px !important',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    boxShadow: " -3px -3px 7px #b4c4d070 , 3px 3px 7px #52628870 ",
      }
  },
  completedParas: {
    margin: "0px",
    textAlign: "center",
    fontWeight: "bold"
  },
  processHead: {
    margin: "0px 0px 5px 0px"
  },
  textBold: {
    fontWeight: "bold"
  }
});
