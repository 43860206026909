import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  Row,
  Typography,
  Input,
  Col,
  message,
  Select,
  Form,
  DatePicker,
  Space,
  Button,
  Tag,
  Table,
  Modal,
  TimePicker,
  Spin,
  InputNumber,
  Tooltip,
  Tabs,
  AutoComplete,
  Empty,
} from "antd";
import axios from "axios";
import urls from "../../../url";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import AdditionalCC from "./AdditionalCC";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import {
  EditOutlined,
  SyncOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
const OfferLetterNewOptimized = () => {
  const userData = useSelector((state) => state?.auth?.user);

  const [filterForm] = useForm();
  const [modalForm] = useForm();
  const { RangePicker } = DatePicker;
  const [offerId, setOfferId] = useState();
  const [loading, setLoading] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [editPreview, setEditPreview] = useState(false);
  const [actionKey, setActionkey] = useState();
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [designationListModal, setDesignationListModal] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [city, setCity] = useState();
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [designationName, setDesignationName] = useState();
  const [interviewProcessId, setInterviewProcessId] = useState();
  const [tags, setTags] = useState([]);
  const [emailSubject, setEmailSubject] = useState();
  const [emailContent, setEmailContent] = useState();
  const [vacancyCode, setVacancyCode] = useState("");
  const [candidateDetails, setCandidateDetails] = useState();
  const [resendOfferId, setResendOfferId] = useState("");
  const [sendOfferId, setSendOfferId] = useState();
  const [offerMode, setOfferMode] = useState();
  const [offerResendMode, setOfferResendMode] = useState();
  const [previewApprovedModal, setPreviewApprovedModal] = useState(false);
  const [previewApprovedContent, setPreviewApprovedContent] = useState();
  const [positionType, setPositionType] = useState();
  const [budgetExceeded, setBudgetExceeded] = useState(false);
  const [budgetApprover, setBudgetApprover] = useState();
  const [isVacancyCode, setIsVacancyCode] = useState("");
  const [isResend, setIsResend] = useState(false);
  const [search, setSearch] = useState("");
  const [previewData, setPreviewData] = useState([
    {
      from_email: "",
      to_email: "",
      cc_email: [],
      email_subject: "",
      email_body: "",
    },
  ]);
  const [branchData, setBranchData] = useState({
    hr_email: "",
    hr_mobile: "",
    hr_name: "",
    escalation_point_email: "",
    location: "",
    name: "",
  });
  const ccEmailList = [
    {
      id: 1,
      email: "hr_sampark@orchids.edu.in",
    },
    {
      id: 2,
      email: "hr.corporate@orchids.edu.in",
    },
    {
      id: 3,
      email: "sai.vadlamani@orchids.edu.in",
    },
    {
      id: 4,
      email: "adil.khan@orchids.edu.in",
    },
    {
      id: 5,
      email: "rahulsingh@orchids.edu.in",
    },
  ];
  const genderList = [
    { id: "female", value: "Female", label: "Female" },
    { id: "male", value: "Male", label: "Male" },
    { id: "other", value: "Other", label: "Other" },
  ];

  const offerLetterTypeOptions = [
    { id: 1, value: "pending", label: "Pending" },
    { id: 2, value: "approved", label: "Approved" },
    { id: 3, value: "rejected", label: "Rejected" },
  ];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const positionType = params.get("position_type");
    const designation = params.get("designation");

    if (positionType) {
      filterForm.setFieldsValue({
        position_type: Number(positionType),
      });
    }
    if (designation) {
      filterForm.setFieldsValue({
        designation: Number(designation),
      });
    }
    getofferApprover({ role_id: 4 });
  }, []);

  const [searchEmailLoading, setSearchEmailLoading] = useState(false);

  const fetchPositionTypeList = () => {
    setLoading(true);
    axios
      .get(`${urls.positionTypes}`)
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchDesignationList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.designationListApi}`, {
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchData = () => {
    const values = filterForm.getFieldsValue();
    if (selectedTab == "1") {
      fetchTableData({
        page: page,
        page_size: pageSize,
        position_type: values?.position_type,
        designation: values?.designation,
        released_status: values?.offer_letter_status ?? null,
        start_date:
          values?.date_range && values?.date_range[0].format("YYYY-MM-DD"),
        end_date:
          values?.date_range && values?.date_range[1].format("YYYY-MM-DD"),
        search: search,
      });
    } else {
      fetchDirectTableData({
        page: page,
        page_size: pageSize,
        position_type: values?.position_type,
        designation: values?.designation,
        released_status: values?.offer_letter_status ?? null,
        is_direct_release: true,
        start_date:
          values?.date_range && values?.date_range[0].format("YYYY-MM-DD"),
        end_date:
          values?.date_range && values?.date_range[1].format("YYYY-MM-DD"),
        search: search,
      });
    }
  };
  const fetchTableData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.OfferLetterOutBoundRetriveApi}`, {
        params: params,
      })
      .then((response) => {
        setTableData(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchDirectTableData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.offerLetterList}`, {
        params: params,
      })
      .then((response) => {
        setTableData(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchDesignationListModal = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.designationListApi}`, {
        params: params,
      })
      .then((response) => {
        setDesignationListModal(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchStateList = () => {
    setLoading(true);
    axios
      .get(`${urls.stateListApi}`)
      .then((response) => {
        setStateList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCityList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.cityListApi}`, { params: { ...params } })
      .then((response) => {
        setCityList(response?.data?.results);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchBranchList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.branchListApi}`, {
        params: params,
      })
      .then((response) => {
        setBranchList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchPositionTypeList();
    fetchCityList({
      page_size: 100,
    });
    fetchBranchList();
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    const { position_type } = filterForm.getFieldsValue();
    fetchDesignationList({ position_type });
  }, [positionType]);

  const handleFilter = () => {
    if (page === 1) {
      fetchData();
    } else {
      setPage(1);
    }
  };

  const createPayloadData = () => {
    const values = modalForm.getFieldsValue();
    console.log({ branchData });
    const payloadData = {
      candidate_email: values?.candidate_email,
      candidate_name: values?.candidate_name,
      gender: values?.gender,
      designation: designationName,
      school_name:
        "ORCHIDS - The International School, " +
        branchData?.name +
        " - " +
        branchData?.location,
      date_of_joining: dayjs(values?.date_of_joining).format("YYYY-MM-DD"),
      designation_id: values?.designation,
      fixed_gross_per_month: values?.ctc_per_annum,
      ctc_offered: values?.ctc_per_annum,
      probation_period: "6 Month",
      notice_period: "1 Month",
      reporting_time: dayjs(values?.reporting_time, "HH:mm").format("hh:mm A"),
      confirm_before_date: dayjs(values?.confirm_before_date).format(
        "YYYY-MM-DD"
      ),
      submit_documents_before_date: dayjs(
        values?.submit_documents_before_date
      ).format("YYYY-MM-DD"),
      hr_name: branchData?.hr_name,
      hr_email: branchData?.hr_email,
      hr_mobile: branchData?.hr_mobile,
      escalation_point_email: branchData?.escalation_point_email,
      submited_by: userData?.academic_profile?.name,
      //   academic_type: ,
      role: "",
      recruiter_id: userData?.personal_info?.user_id,
      cc: [...values?.cc, ...tags],
      state: city,
      position_type: values?.position_type,
      designation_fk: values?.designation,
      candidate_contact_no: values?.candidate_contact_no,
      branch: values?.branch,
      reporting_manager: values?.reporting_manager,
      interview_process: interviewProcessId,
      applicant: candidateDetails?.applicant?.id,
      offer_approver_email: values?.offer_approver_email,
      offer_approver: offerApprover,
      is_budget_approval: budgetExceeded,
    };
    if (budgetExceeded) {
      payloadData.budget_approver = budgetApprover;
    }
    return payloadData;
  };
  const handleViewPreview = () => {
    setLoading(true);
    let payloadData = createPayloadData();
    payloadData.mode = "preview";

    axios
      .post(`${urls.offerLetter}`, payloadData)
      .then((response) => {
        if (response?.data?.status_code === 201) {
          setOpenPreviewModal(true);
          setPreviewData({
            from_email: response?.data?.from_email,
            to_email: response?.data?.to_email,
            cc_email: response?.data?.cc_email,
            email_subject: response?.data?.email_subject,
            email_body: response?.data?.email_body,
          });
          setEmailSubject(response?.data?.email_subject);
          setEmailContent(response?.data?.email_body);
          message.success(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
        setIsVacancyCode("");
      });
  };

  const handleApprovedEmailSend = (data) => {
    // editEmailResend(data?.id);
    let obj = {
      offer_id: data?.id,
      cc: JSON.parse(data?.cc?.replace(/'/g, '"')) ?? [],
      mode: "release",
      is_revoked: false,
      email_subject: emailSubject,
      email_content: emailContent,
    };
    axios
      .post(`${urls.offerLetter}`, obj)
      .then((response) => {
        message.success("Offer Letter sent successfully");
        setPreviewApprovedContent("");
        setPreviewApprovedModal(false);
        handleFilter();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendEmail = () => {
    setLoading(true);
    const values = modalForm.getFieldsValue();
    let payloadData = createPayloadData();
    // note: extra payload to add in case of sending offer letter
    payloadData = {
      ...payloadData,
      email_subject: emailSubject,
      email_content: emailContent,
      offer_id: sendOfferId,
      is_revoked: false,
      joining_date: dayjs(values?.date_of_joining).format("YYYY-MM-DD"),
      mode: offerMode ?? "approval",
      mrf_vacancy_code: values?.mrf_vacancy_code,
    };
    axios
      .post(`${urls.offerLetter}`, payloadData)
      .then((response) => {
        message.success(
          `Offer Letter ${
            offerMode == "approval" ? "sent for approval" : "sent"
          } successfully`
        );
        handleClosePreviewModal();
        handleCloseFormModal();
        handleFilter();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReSendEmail = () => {
    setLoading(true);
    const values = modalForm.getFieldsValue();
    let payloadData = createPayloadData();
    // note: extra payload to add in case of sending offer letter
    payloadData = {
      ...payloadData,
      email_subject: emailSubject,
      email_content: emailContent,
      offer_id: resendOfferId,
      mode: "resend",
      is_resend: isResend,
      is_revoked: false,
      mrf_vacancy_code: values?.mrf_vacancy_code,
    };
    axios
      .post(`${urls.offerLetter}`, payloadData)
      .then((response) => {
        message.success(response?.data?.message);
        handleClosePreviewModal();
        handleCloseFormModal();
        handleFilter();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPastOfferLetterDetails = (data) => {
    setLoading(true);
    axios
      .get(`${urls.offerLetterList}?search=${data?.applicant?.user?.email}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.results[0];
          modalForm.setFieldsValue({
            state: data?.state,
            branch: data?.branch,
            city: data?.state,
            date_of_joining: dayjs(data?.date_of_joining),
            ctc_per_annum: data?.fixed_gross_per_month,
            reporting_time: dayjs(data?.reporting_time, "hh:mm"),
            submit_documents_before_date: dayjs(
              data?.submit_documents_before_date
            ),
            confirm_before_date: dayjs(data?.confirm_before_date),
            cc_email: "",
          });
          // setTags
          setBranchData({
            hr_email: data?.hr_email,
            hr_mobile: data?.hr_mobile,
            hr_name: data?.hr_name,
            escalation_point_email: data?.escalation_point_email,
            location: data?.location,
          });
          setOfferId(data?.id);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseFormModal = () => {
    modalForm.resetFields();
    setDesignationName();
    setInterviewProcessId();
    setActionkey();
    setDesignationListModal([]);
    setTags([]);
    setBranchData([]);
    setOpenFormModal(false);
    setIsVacancyCode("");
    setIsResend(false);
  };

  const handlePositionChange = (e, val) => {
    setPositionType(val?.value);
    setBudgetApprover(val?.budgetApprover);
    const position_type = filterForm.getFieldsValue()?.position_type;
    filterForm.setFieldsValue({
      designation: null,
    });
    if (position_type) {
      fetchDesignationList({
        position_type: position_type,
      });
    } else {
      setDesignationList([]);
    }
  };

  const handleOpenReleaseOfferLetterModal = () => {
    setOpenFormModal(true);
    setActionkey("release");
  };
  const handleOpenSendOfferLetterModal = (data, mode) => {
    if (data?.offer_letter_approval == "pending") {
      return;
    }
    setOfferMode(mode);

    modalForm.setFieldsValue({
      candidate_name: data?.applicant?.user?.first_name,
      candidate_email: data?.applicant?.user?.email,
      candidate_contact_no: data?.applicant?.user?.username,
      gender: data?.applicant?.gender,
      position_type: data?.position_type,
      designation: data?.designation,
    });
    fetchDesignationListModal({
      position_type: data?.position_type,
    });
    setCandidateDetails(data);
    setDesignationName(data?.designation_name);
    setInterviewProcessId(data?.id);
    setSendOfferId(data?.offer_letter);
    setOpenFormModal(true);
    setActionkey("send");
  };
  const handleOpenReSendOfferLetterModal = (data) => {
    if (
      data?.is_offer_letter_rejected == true &&
      data?.is_offer_letter_accepted == false
    ) {
      setOfferResendMode("approval");
    } else {
      setOfferResendMode("release");
    }
    modalForm.setFieldsValue({
      candidate_name: data?.applicant?.user?.first_name,
      candidate_email: data?.applicant?.user?.email,
      candidate_contact_no: data?.applicant?.user?.username,
      gender: data?.applicant?.gender,
      position_type: data?.position_type,
      designation: data?.designation,
      city: data?.state,
      mrf_vacancy_code: data?.mrf_vacancy_code_id,
    });
    fetchDesignationListModal({
      position_type: data?.position_type,
    });
    setCandidateDetails(data);
    setDesignationName(data?.designation_name);
    setInterviewProcessId(data?.id);
    setResendOfferId(data?.offer_letter);
    getResendFormDetails(data?.offer_letter);
    setOpenFormModal(true);
    setActionkey("re_send");
  };

  const handleOpenReRelaseOfferLetterModal = (data) => {
    console.log("==2", data);
    setOfferResendMode("approval");
    modalForm.setFieldsValue({
      candidate_name: data?.applicant?.user?.first_name,
      candidate_email: data?.applicant?.user?.email,
      candidate_contact_no: data?.applicant?.user?.username,
      gender: data?.applicant?.gender,
      position_type: data?.position_type,
      designation: data?.designation,
      city: data?.state,
    });
    fetchDesignationListModal({
      position_type: data?.position_type,
    });
    setCandidateDetails(data);
    setDesignationName(data?.designation_name);
    setInterviewProcessId(data?.id);
    setResendOfferId(data?.offer_letter);
    getResendFormDetails(data?.offer_letter);
    setOpenFormModal(true);
    setActionkey("re_send");
  };

  const handleOpenDirectReSendOfferLetterModal = (data) => {
    console.log("==1", data);
    setOfferResendMode("approval");
    modalForm.setFieldsValue({
      candidate_name: data?.candidate_name,
      candidate_email: data?.candidate_email,
      candidate_contact_no: data?.candidate_contact_no,
      gender: data?.gender,
      position_type: data?.position_type,
      designation: data?.designation_fk,
      city: data?.branch,
    });
    let val = branchList?.filter((e) => e.branch?.id == data?.branch)[0];
    setBranchData({
      location: val?.branch?.city_id?.city_name,
      escalation_point_email: val?.first_st_poc_email,
      hr_name: val?.first_st_escalation_point_name,
      hr_mobile: val?.first_st_poc_contact_number,
      hr_email: val?.first_escalation_point_email,
      name: val?.branch?.branch_name,
    });
    fetchDesignationListModal({
      position_type: data?.position_type,
    });
    setCandidateDetails(data);
    setDesignationName(data?.designation);
    // setInterviewProcessId(data?.id);
    setResendOfferId(data?.id);
    getResendFormDetails(data?.id);
    setOpenFormModal(true);
    setActionkey("re_send");
  };

  const getResendFormDetails = (id, preview) => {
    axios
      .get(`${urls.offerLetter}${id}/`)
      .then((response) => {
        if (preview) {
          setPreviewApprovedModal(true);
          setPreviewApprovedContent(response?.data[0]);
          setEmailContent(response?.data[0]?.offer_letter);
          setEmailSubject(response?.data[0]?.email_subject);
          setIsVacancyCode(response?.data[0]?.mrf_vacancy_code_id ?? "");
        } else {
          if (response?.data[0]?.budget) {
            if (
              Number(response?.data[0]?.ctc_offered) >
              Number(response?.data[0]?.budget)
            ) {
              setBudgetExceeded(true);
            }
          }
          setIsVacancyCode(response?.data[0]?.mrf_vacancy_code_id ?? "");
          setOfferApprover(response?.data[0]?.offer_approver_id);
          modalForm.setFieldsValue({
            candidate_name: response?.data[0]?.candidate_name,
            candidate_email: response?.data[0]?.candidate_email,
            candidate_contact_no: response?.data[0]?.candidate_contact_no,
            position_type: response?.data[0]?.position_type_id,
            designation: response?.data[0]?.designation_fk_id,
            city: response?.data[0]?.state,
            branch: response?.data[0]?.branch_id,
            date_of_joining: dayjs(response?.data[0]?.date_of_joining),
            ctc_per_annum: Number(response?.data[0]?.ctc_offered),
            reporting_time: dayjs(response?.data[0]?.reporting_time, "hh:mm"),
            submit_documents_before_date: dayjs(
              response?.data[0]?.submit_documents_before_date
            ),
            confirm_before_date: dayjs(response?.data[0]?.confirm_before_date),
            cc_email: response?.data[0]?.cc,
            reporting_manager: response?.data[0]?.reporting_manager,
            offer_approver_email: response?.data[0]?.offer_approver_email,
            gender: response?.data[0]?.gender,
            mrf_vacancy_code: response?.data[0]?.mrf_vacancy_code_id,
            cc:
              response?.data[0]?.cc &&
              JSON.parse(response?.data[0]?.cc?.replace(/'/g, '"')),
          });
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const handleClosePreviewModal = () => {
    setOpenPreviewModal(false);
    setPreviewData([
      {
        from_email: "",
        to_email: "",
        cc_email: [],
        email_subject: "",
        email_body: "",
      },
    ]);
    setEmailContent();
    setEmailSubject();
    setEditPreview(false);
    setIsResend(false);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      width: "15%",
      render: (data) => <span>{data?.applicant?.user?.first_name}</span>,
    },
    {
      title: "Mobile No",
      key: "mobile_no",
      width: "15%",
      render: (data) => <span>{data?.applicant?.user?.username}</span>,
    },
    {
      title: "Email",
      key: "email",
      render: (data) => <span>{data?.applicant?.user?.email ?? "-"}</span>,
    },
    {
      title: "Offer Release Status",
      key: "offer_status",
      width: "20%",
      align: "center",
      render: (data) => (
        <span>
          {data?.is_revoked ? (
            <span style={{ color: "red", fontStyle: "italic" }}>Revoked</span>
          ) : data?.is_offer_letter_realesed == true ? (
            <span style={{ color: "green", fontStyle: "italic" }}>
              Released
            </span>
          ) : (
            <span style={{ color: "blue", fontStyle: "italic" }}>
              Not Released
            </span>
          )}
        </span>
      ),
    },
    {
      title: "Manager Approval Status",
      key: "approval_status",
      align: "center",
      render: (data) =>
        data?.is_revoked ? (
          <Tag
            color="processing"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOfferResendMode("approval");
              handleOpenReRelaseOfferLetterModal(data);
            }}
          >
            Re-release Offer Letter
          </Tag>
        ) : (
          <span>
            <>
              {data?.budget_data?.budget_approver ? (
                data?.budget_data?.budget_approver_status == "rejected" ? (
                  <div>
                    <div style={{ color: "red" }}>
                      Budget Rejected{" "}
                      <Tooltip
                        title={data?.budget_data?.budget_approver_feedback}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                    <Tag
                      color="error"
                      onClick={() => handleOpenReSendOfferLetterModal(data)}
                      style={{ cursor: "pointer" }}
                    >
                      Resend for approval
                    </Tag>
                  </div>
                ) : data?.budget_data?.budget_approver_status == "approved" ? (
                  <>
                    <div
                      style={{
                        color: "green",
                        fontStyle: "italic",
                        textTransform: "capitalize",
                      }}
                    >
                      Budget {data?.budget_data?.budget_approver_status}
                    </div>
                    {data?.offer_letter_approval ? (
                      data?.offer_letter_approval == "rejected" ? (
                        <div>
                          <div style={{ color: "red" }}>
                            Offer Rejected{" "}
                            <Tooltip title={data?.reporting_manager_feedback}>
                              <InfoCircleOutlined />
                            </Tooltip>
                          </div>
                          <Tag
                            color="error"
                            onClick={() =>
                              handleOpenReSendOfferLetterModal(data)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            Resend for Offer approval
                          </Tag>
                        </div>
                      ) : data?.offer_letter_approval == "approved" ? (
                        <span
                          style={{
                            color: "green",
                            fontStyle: "italic",
                            textTransform: "capitalize",
                          }}
                        >
                          <div>Offer Letter {data?.offer_letter_approval}</div>
                          <Tag
                            color="processing"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOfferResendMode("approval");
                              handleOpenReRelaseOfferLetterModal(data);
                            }}
                          >
                            Re-release Offer Letter
                          </Tag>
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "green",
                            fontStyle: "italic",
                            textTransform: "capitalize",
                          }}
                        >
                          Offer Letter {data?.offer_letter_approval}
                        </span>
                      )
                    ) : (
                      <Tag
                        color="processing"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleOpenSendOfferLetterModal(data, "approval")
                        }
                      >
                        Send
                      </Tag>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        color: "green",
                        fontStyle: "italic",
                        textTransform: "capitalize",
                      }}
                    >
                      Budget Approval{" "}
                      {data?.budget_data?.budget_approver_status}
                    </div>
                  </>
                )
              ) : (
                <>
                  {data?.offer_letter_approval ? (
                    data?.offer_letter_approval == "rejected" ? (
                      <div>
                        <div style={{ color: "red" }}>
                          Rejected{" "}
                          <Tooltip title={data?.reporting_manager_feedback}>
                            <InfoCircleOutlined />
                          </Tooltip>
                        </div>
                        <Tag
                          color="error"
                          onClick={() => handleOpenReSendOfferLetterModal(data)}
                          style={{ cursor: "pointer" }}
                        >
                          Resend for approval
                        </Tag>
                      </div>
                    ) : data?.offer_letter_approval == "approved" ? (
                      <div
                        style={{
                          color: "green",
                          fontStyle: "italic",
                          textTransform: "capitalize",
                        }}
                      >
                        <div>Offer Letter {data?.offer_letter_approval}</div>
                        <Tag
                          color="processing"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setOfferResendMode("approval");
                            handleOpenReRelaseOfferLetterModal(data);
                            setIsResend(true);
                          }}
                        >
                          Re-release Offer Letter
                        </Tag>
                      </div>
                    ) : (
                      <span
                        style={{
                          color: "green",
                          fontStyle: "italic",
                          textTransform: "capitalize",
                        }}
                      >
                        Offer Letter {data?.offer_letter_approval}
                      </span>
                    )
                  ) : (
                    <Tag
                      color="processing"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleOpenSendOfferLetterModal(data, "approval")
                      }
                    >
                      Send
                    </Tag>
                  )}
                </>
              )}
            </>
          </span>
        ),
    },
    {
      title: "Applicant Acceptance Status",
      key: "applicant_status",
      width: "20%",
      align: "center",
      render: (data) => (
        <span>
          {data?.is_revoked ? (
            <span style={{ color: "red", fontStyle: "italic" }}>
              Offer Revoked
            </span>
          ) : data?.is_offer_letter_realesed == false ? (
            <span style={{ color: "blue", fontStyle: "italic" }}>
              Not Released
            </span>
          ) : (
            <>
              {data?.is_offer_letter_accepted ? (
                <span style={{ color: "green", fontStyle: "italic" }}>
                  Accepted
                </span>
              ) : data?.is_offer_letter_rejected ? (
                <span style={{ color: "red", fontStyle: "italic" }}>
                  Rejected
                </span>
              ) : !data?.is_offer_letter_rejected &&
                !data?.is_offer_letter_accepted ? (
                <span style={{ color: "blue", fontStyle: "italic" }}>
                  Pending
                </span>
              ) : (
                "-"
              )}
            </>
          )}
        </span>
      ),
    },
    {
      title: "Offer Letter",
      key: "offer_letter",
      width: "20%",
      align: "center",
      render: (data) => (
        <span>
          {data?.is_revoked ? (
            <span style={{ color: "red", fontStyle: "italic" }}>
              Offer Revoked
            </span>
          ) : data?.offer_letter_approval == "approved" ? (
            data?.is_offer_letter_realesed ? (
              data?.is_offer_letter_accepted ? (
                <span style={{ color: "green", fontStyle: "italic" }}>
                  Accepted
                </span>
              ) : data?.is_offer_letter_rejected ? (
                <span style={{ color: "red", fontStyle: "italic" }}>
                  Rejcted by Applicant
                </span>
              ) : (
                <Tag
                  color="error"
                  onClick={() => getResendFormDetails(data?.offer_letter, true)}
                  // onClick={() => handleOpenReSendOfferLetterModal(data)}
                >
                  Resend
                </Tag>
              )
            ) : (
              <Tag
                color="processing"
                onClick={() => {
                  getResendFormDetails(data?.offer_letter, true);
                }}
              >
                Send
              </Tag>
            )
          ) : data?.offer_letter_approval == "rejected" ? (
            <span style={{ color: "red", fontStyle: "italic" }}>
              Rejcted by Manager
            </span>
          ) : (
            <i style={{ color: "red", fontSize: "13px" }}>
              Yet To Be Approved By Manager
            </i>
          )}
        </span>
      ),
    },
  ];
  const directColumns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "candidate_name",
    },
    {
      title: "Mobile No",
      key: "mobile_no",
      dataIndex: "candidate_contact_no",
    },
    {
      title: "Offer Release Status",
      key: "offer_status",
      align: "center",
      render: (data) => (
        <span>
          {data?.is_offer_letter_released == true ? (
            <span style={{ color: "green", fontStyle: "italic" }}>
              Released
            </span>
          ) : (
            <span style={{ color: "blue", fontStyle: "italic" }}>
              Not Released
            </span>
          )}
        </span>
      ),
    },
    {
      title: "Manager Approval Status",
      key: "approval_status",
      align: "center",
      render: (data) => (
        <span>
          <>
            {data?.released_status ? (
              data?.released_status == "rejected" ? (
                <div>
                  <div style={{ color: "red" }}>
                    Rejected{" "}
                    <Tooltip title={data?.reporting_manager_feedback}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                  <Tag
                    color="error"
                    onClick={() => handleOpenDirectReSendOfferLetterModal(data)}
                  >
                    Resend for approval
                  </Tag>
                </div>
              ) : data?.released_status == "approved" ? (
                <div style={{ display: "block" }}>
                  <span
                    style={{
                      color: "green",
                      fontStyle: "italic",
                      textTransform: "capitalize",
                      display: "block",
                    }}
                  >
                    {data?.released_status}
                  </span>
                  <Tag
                    color="processing"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOfferResendMode("approval");
                      handleOpenDirectReSendOfferLetterModal(data);
                    }}
                  >
                    Re-release Offer Letter
                  </Tag>
                </div>
              ) : (
                <span
                  style={{
                    color: "green",
                    fontStyle: "italic",
                    textTransform: "capitalize",
                  }}
                >
                  {data?.released_status}
                </span>
              )
            ) : (
              <Tag
                color="processing"
                style={{ cursor: "pointer" }}
                onClick={() => handleOpenSendOfferLetterModal(data, "approval")}
              >
                Send
              </Tag>
            )}
          </>
        </span>
      ),
    },
    {
      title: "Offer Letter",
      key: "offer_letter",
      align: "center",
      render: (data) => (
        <span>
          {data?.released_status == "approved" ? (
            data?.is_offer_letter_released ? (
              <span
                style={{
                  color: "green",
                  fontStyle: "italic",
                  textTransform: "capitalize",
                }}
              >
                Released
              </span>
            ) : (
              <Tag
                color="processing"
                onClick={() => {
                  // getResendFormDetails(data?.offer_letter, true);
                  setPreviewApprovedModal(true);
                  setPreviewApprovedContent({
                    candidate_email: data?.candidate_email,
                    cc: data?.cc,
                    email_subject: data?.email_subject,
                    offer_letter: data?.offer_letter,
                    id: data?.id,
                  });
                }}
              >
                Send
              </Tag>
            )
          ) : data?.released_status == "rejected" ? (
            <span style={{ color: "red", fontStyle: "italic" }}>
              Rejcted by Manager
            </span>
          ) : (
            <i style={{ color: "red", fontSize: "13px" }}>
              Yet To Be Approved By Manager
            </i>
          )}
        </span>
      ),
    },
  ];
  const [selectedTab, setSelectedTab] = useState("1");
  const tabs = [
    {
      label: "Platform Candidates",
      key: "1",
    },
    {
      label: "Direct Release",
      key: "2",
    },
  ];
  function handleTabs(tab) {
    setSelectedTab(tab);
    const values = filterForm.getFieldsValue();
    if (tab == "1") {
      fetchTableData({
        page: page,
        page_size: pageSize,
        position_type: values?.position_type,
        designation: values?.designation,
        released_status: values?.offer_letter_status ?? null,
        start_date:
          values?.date_range && values?.date_range[0].format("YYYY-MM-DD"),
        end_date:
          values?.date_range && values?.date_range[1].format("YYYY-MM-DD"),
      });
    } else {
      const values = filterForm.getFieldsValue();
      fetchDirectTableData({
        page: page,
        page_size: pageSize,
        position_type: values?.position_type,
        designation: values?.designation,
        is_direct_release: true,
        released_status: values?.offer_letter_status ?? null,
        start_date:
          values?.date_range && values?.date_range[0].format("YYYY-MM-DD"),
        end_date:
          values?.date_range && values?.date_range[1].format("YYYY-MM-DD"),
      });
    }
  }

  const [emailOptions, setEmailOptions] = useState([]);
  const [offerApprover, setOfferApprover] = useState("");
  function getofferApprover(params = {}) {
    setSearchEmailLoading(true);
    axios
      .get(urls.employeeList, { params: { ...params } })
      .then((response) => {
        setEmailOptions(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setSearchEmailLoading(false);
      });
  }

  // const handleDebounce = useCallback(
  //   debounce((value) => {
  //     searchEmployee({ search: value, role_id: "1,4" });
  //   }, 1500),
  //   []
  // );

  return (
    <>
      <Card>
        <Row
          gutter={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 16 }}
          >
            <Typography className="page_title">Offer Letter Release</Typography>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            style={{ display: "flex", gap: "10px" }}
          >
            <Form.Item>
              <Input
                placeholder="Search candidate"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                allowClear
              />
            </Form.Item>
            <Button form="filterForm" htmlType="submit" type="primary">
              Search
            </Button>
          </Col>
        </Row>
        <Tabs
          onChange={handleTabs}
          defaultActiveKey="1"
          size={"large"}
          items={tabs}
        />
        <Form
          id="filterForm"
          form={filterForm}
          onFinish={handleFilter}
          layout="vertical"
        >
          <Row align="middle" gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  { required: true, message: "Please select position type" },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Position Type"
                  allowClear
                  onChange={(e, val) => {
                    console.log({ val });
                    handlePositionChange(e, val);
                  }}
                  style={{ width: "100%" }}
                >
                  {positionTypeList?.map((each) => (
                    <Select.Option
                      key={each?.id}
                      value={each?.id}
                      budgetApprover={each?.budget_approver}
                    >
                      {each?.position_type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Form.Item
                name="designation"
                label="Designation"
                // rules={[
                //   {
                //     required: true,
                //     message: "This is a required field",
                //   },
                // ]}
              >
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Designation"
                  allowClear
                  style={{ width: "100%" }}
                >
                  {designationList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="offer_letter_status" label="Approval Status">
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Offer Letter Status"
                  allowClear
                  style={{ width: "100%" }}
                >
                  {offerLetterTypeOptions?.map((each) => (
                    <Select.Option key={each?.id} value={each?.value}>
                      {each?.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="date_range" label="Start Date - End Date">
                <RangePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Space>
                <Button form="filterForm" htmlType="submit" type="primary">
                  Filter
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        <Table
          columns={selectedTab == "1" ? columns : directColumns}
          dataSource={tableData?.results}
          pagination={{
            showSizeChanger: false,
            total: tableData?.count,
            onChange: (page) => {
              setPage(page);
              setSearch("");
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
          style={{ marginTop: "10px" }}
        />
      </Card>
      <Modal
        title={
          <Typography className="page_title">
            {actionKey === "release"
              ? "Releasing Offer Letter"
              : actionKey === "send"
              ? "Sending Offer Letter"
              : "Re Sending Offer Letter"}
          </Typography>
        }
        open={openFormModal}
        centered
        onCancel={handleCloseFormModal}
        maskClosable={false}
        width={1000}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={handleCloseFormModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                form="modalForm"
                htmlType="submit"
                type="primary"
                icon={loading ? <SyncOutlined spin /> : null}
              >
                Preview
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="modalForm"
          form={modalForm}
          layout="vertical"
          onFinish={handleViewPreview}
        >
          <Row align="middle" gutter={24}>
            <Col span={12}>
              <Form.Item
                name="candidate_name"
                label="First Name, Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Candidate Name",
                  },
                ]}
              >
                <Input placeholder="Enter Candidate Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="candidate_email"
                label="Candidate Email"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Candidate Email",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const filterEmailId = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (!value || value.match(filterEmailId)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please Enter a Valid Email")
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Enter Candidate Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="candidate_contact_no"
                label="Candidate Mobile No."
                rules={[
                  {
                    required: true,
                    message: "Please Enter Candidate Mobile No.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const filterMobile = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
                      if (!value || value.match(filterMobile)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please Enter a Valid Mobile Number")
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Enter Candidate Mobile No." />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="gender"
                label="Gender"
                rules={[
                  {
                    required: true,
                    message: "Please Select Gender",
                  },
                ]}
              >
                <Select placeholder="Select Gender">
                  {genderList &&
                    genderList.length !== 0 &&
                    genderList.map((option, index) => {
                      return (
                        <Select.Option key={index} value={option?.id}>
                          {option?.label}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Position Type"
                  onChange={() => {
                    const position_type = modalForm.getFieldsValue()
                      ?.position_type;
                    modalForm.setFieldsValue({
                      designation: null,
                    });
                    if (position_type) {
                      fetchDesignationListModal({
                        position_type: position_type,
                      });
                    } else {
                      setDesignationListModal([]);
                      setDesignationName();
                    }
                  }}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {positionTypeList?.map((each) => (
                    <Select.Option key={each?.id} value={each?.id}>
                      {each?.position_type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="designation"
                label="Designation"
                rules={[
                  {
                    required: true,
                    message: "Please Select Designation",
                  },
                ]}
              >
                <Select
                  placeholder="Select Designation"
                  onChange={(e, val) => setDesignationName(val?.children)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {designationListModal?.map((each) => {
                    return (
                      <Select.Option key={each?.id} value={each?.id}>
                        {each?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "Please Select City",
                  },
                ]}
              >
                <Select
                  placeholder="Select City"
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={(e, val) => {
                    fetchBranchList({
                      branch__city_id: e,
                    });
                    modalForm.resetFields(["branch"]);
                    setCity(val?.name);
                  }}
                >
                  {cityList?.map((option, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={option?.id}
                        name={option?.city_name}
                      >
                        {option?.city_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="branch"
                label="Branch"
                rules={[
                  {
                    required: true,
                    message: "Please Select Branch",
                  },
                ]}
              >
                <Select
                  placeholder="Select Branch"
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={(e, val) =>
                    setBranchData({
                      location: val?.data?.branch?.city_id?.city_name,
                      escalation_point_email: val?.data?.first_st_poc_email,
                      hr_name: val?.data?.first_st_escalation_point_name,
                      hr_mobile: val?.data?.first_st_poc_contact_number,
                      hr_email: val?.data?.first_escalation_point_email,
                      name: val?.data?.branch?.branch_name,
                    })
                  }
                >
                  {branchList &&
                    branchList.length !== 0 &&
                    branchList.map((option, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={option?.branch?.id}
                          data={option}
                        >
                          {option?.branch?.branch_name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {candidateDetails?.mrf_vacancy_code &&
              candidateDetails?.mrf_vacancy_code?.length > 0 && (
                <Col span={12}>
                  <Form.Item
                    name="mrf_vacancy_code"
                    label="Vacancy Code"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Vacancy Code",
                      },
                    ]}
                  >
                    <Select
                      onChange={(e) => {
                        setVacancyCode(e);
                      }}
                      placeholder="Vacancy Code"
                      className="w-100"
                      disabled={isVacancyCode ? true : false}
                    >
                      {candidateDetails?.mrf_vacancy_code?.map((each) => (
                        <Select.Option
                          disabled={each?.is_used}
                          key={each?.id}
                          value={each?.id}
                        >
                          {each?.code} {each?.is_used ? "(Occupied)" : ""}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            <Col span={12}>
              <Form.Item
                name="date_of_joining"
                label="Date of Joining"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Date of Joining",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(current) => {
                    return current < dayjs().startOf("day");
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="ctc_per_annum"
                label="Fixed Gross Per Annum in Digits"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Salary Details",
                  },
                  {
                    type: "number",
                    warningOnly: true,
                  },
                ]}
                extra={
                  candidateDetails?.mrf_vacancy_code &&
                  candidateDetails?.mrf_vacancy_code?.length > 0 && (
                    <span>
                      Budget (Annual) for this position in{" "}
                      {candidateDetails?.mrf_vacancy_code[0]?.max_budget}
                    </span>
                  )
                }
              >
                <InputNumber
                  onChange={(e) => {
                    if (
                      candidateDetails?.mrf_vacancy_code &&
                      candidateDetails?.mrf_vacancy_code?.length > 0
                    ) {
                      let budget = Number(
                        candidateDetails.mrf_vacancy_code[0]?.max_budget
                      );
                      setBudgetExceeded(e > budget);
                    }
                  }}
                  placeholder="Enter Salary Details"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="reporting_time"
                label="Reporting Time"
                rules={[
                  {
                    required: true,
                    message: "Please Reporting Time",
                  },
                ]}
              >
                <TimePicker
                  style={{ width: "100%" }}
                  format="HH:mm"
                  use12Hours
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="submit_documents_before_date"
                label="Submit Documents Before Date"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Submit Documents Before Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(current) => {
                    return current < dayjs().startOf("day");
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirm_before_date"
                label="Confirm Before Date"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Submit Documents Before Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  disabledDate={(current) => {
                    return current < dayjs().startOf("day");
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="cc"
                label="CC"
                rules={[
                  {
                    required: true,
                    message: "Please Select CC Mails",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  maxTagCount={3}
                  placeholder="Select CC Mails"
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {ccEmailList &&
                    ccEmailList.length !== 0 &&
                    ccEmailList.map((option, index) => {
                      return (
                        <Select.Option key={index} value={option?.email}>
                          {option?.email}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="offer_approver_email"
                label="Offer Approver Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter Offer Approver Email",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, options) => {
                    return (
                      options?.children[0]
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase()) ||
                      options?.children[2]
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    );
                  }}
                  placeholder={"Select Offer Approver"}
                  onChange={(e, fullObj) => {
                    setOfferApprover(Number(fullObj?.key));
                  }}
                >
                  {emailOptions?.map((each) => (
                    <Select.Option key={each?.user} value={each?.email}>
                      {each?.email} | {each?.erp}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="reporting_manager"
                label="Reporting Manager Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter reporting manager email",
                  },

                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const filterEmailId = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (!value || value.match(filterEmailId)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please Enter a Valid Email")
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Reporting Manager" className="w-100" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Additional CC's">
                <AdditionalCC tags={tags} setTags={setTags} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography className="page_title">
              {actionKey === "release"
                ? "Releasing Offer Letter Preview"
                : actionKey === "send"
                ? "Sending Offer Letter Preview"
                : "Re Sending Offer Letter Preview"}
            </Typography>

            <Space>
              <Button
                onClick={() => setEditPreview(true)}
                icon={<EditOutlined style={{ fontSize: "14px" }} />}
              >
                Edit
              </Button>
              <Button onClick={handleClosePreviewModal}>Close</Button>
            </Space>
          </Space>
        }
        open={openPreviewModal}
        // onCancel={handleCloseViewPreview}
        centered
        closeIcon={null}
        width={1000}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={handleClosePreviewModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  actionKey === "re_send"
                    ? handleReSendEmail()
                    : handleSendEmail();
                }}
                icon={loading ? <SyncOutlined spin /> : null}
                type="primary"
              >
                {actionKey === "release"
                  ? "Release Offer"
                  : actionKey === "send"
                  ? "Send Offer"
                  : "Re-Send Offer"}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "230px",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {!loading && (
          <>
            <div style={{ marginBottom: "50px" }}>
              <div style={{ marginLeft: "50px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <strong>
                    From:<span style={{ visibility: "hidden" }}>H</span>
                  </strong>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: previewData?.from_email,
                    }}
                  />
                </div>
                <br />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <strong>
                    To:<span style={{ visibility: "hidden" }}>H</span>
                  </strong>{" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: previewData?.to_email,
                    }}
                  />
                </div>
                <br />
                <strong>CC:</strong>
                {previewData?.cc_email &&
                  previewData?.cc_email?.length > 0 &&
                  previewData?.cc_email.map((tag, i) => (
                    <span key={i}>
                      {i > 0 && " "}
                      <ul>
                        <li>
                          <div dangerouslySetInnerHTML={{ __html: tag }} />
                        </li>
                      </ul>
                    </span>
                  ))}
                <br />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                  }}
                >
                  <strong>
                    Subject:<span style={{ visibility: "hidden" }}>H</span>
                  </strong>
                  {editPreview ? (
                    <>
                      <TextArea
                        value={emailSubject}
                        onChange={(e) => setEmailSubject(e.target.value)}
                      />
                    </>
                  ) : (
                    <div
                      style={{ maxWidth: "100%" }}
                      dangerouslySetInnerHTML={{
                        __html: previewData?.email_subject,
                      }}
                    />
                  )}
                </div>
                {editPreview ? (
                  <Editor
                    apiKey="c03cc5kmzse4y7i90fwrbswwrzl9g3rxbtd23ohrzt8pcd1k"
                    init={{
                      height: 500,
                      plugins: "link image code table",
                      toolbar:
                        "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | outdent indent | code",
                    }}
                    value={emailContent}
                    onEditorChange={(content) => setEmailContent(content)}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: previewData?.email_body,
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </Modal>
      <Modal
        open={previewApprovedModal}
        onCancel={() => {
          setPreviewApprovedContent(null);
          setPreviewApprovedModal(false);
        }}
        closeIcon={null}
        okText={"Send"}
        okButtonProps={{
          onClick: () => {
            handleApprovedEmailSend(previewApprovedContent);
          },
        }}
        width={1000}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography className="page_title">{"Offer Letter"}</Typography>

            <Space>
              <Button
                onClick={() => setEditPreview(true)}
                icon={<EditOutlined style={{ fontSize: "14px" }} />}
              >
                Edit
              </Button>
              <Button onClick={() => setEditPreview(false)}>Close</Button>
            </Space>
          </Space>
        }
      >
        <div style={{ marginBottom: "50px" }}>
          <div style={{ marginLeft: "50px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <strong>To:</strong>{" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: previewApprovedContent?.candidate_email,
                }}
              />
            </div>
            <br />
            <strong>CC:</strong>
            {previewApprovedContent?.cc &&
              JSON.parse(previewApprovedContent?.cc?.replace(/'/g, '"'))?.map(
                (tag, i) => (
                  <span key={i}>
                    {i > 0 && " "}
                    <ul>
                      <li>
                        <div dangerouslySetInnerHTML={{ __html: tag }} />
                      </li>
                    </ul>
                  </span>
                )
              )}
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <strong>
                Subject:<span style={{ visibility: "hidden" }}>H</span>
              </strong>
              {editPreview ? (
                <>
                  <TextArea
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                  />
                </>
              ) : (
                <div
                  style={{ maxWidth: "100%" }}
                  dangerouslySetInnerHTML={{
                    __html: previewApprovedContent?.email_subject,
                  }}
                />
              )}
            </div>
            {editPreview ? (
              <Editor
                apiKey="c03cc5kmzse4y7i90fwrbswwrzl9g3rxbtd23ohrzt8pcd1k"
                init={{
                  height: 500,
                  plugins: "link image code table",
                  toolbar:
                    "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | outdent indent | code",
                }}
                value={emailContent}
                onEditorChange={(content) => setEmailContent(content)}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: previewApprovedContent?.offer_letter,
                }}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OfferLetterNewOptimized;
