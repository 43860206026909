import { Editor } from "@tinymce/tinymce-react";
import { FloatButton } from "antd";
import { EditOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";

function OfferPreview({ data, setData, editOffer }) {
  return (
    <div style={{ position: "relative" }}>
      <div style={{ marginBottom: "50px" }}>
        <div style={{ marginLeft: "50px" }}>
          {data?.from_email && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <strong>
                From:<span style={{ visibility: "hidden" }}>H</span>
              </strong>{" "}
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.from_email,
                }}
              />
            </div>
          )}
          <br />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <strong>
              To:<span style={{ visibility: "hidden" }}>H</span>
            </strong>{" "}
            <div
              dangerouslySetInnerHTML={{
                __html: data?.to_email,
              }}
            />
          </div>
          <br />
          <strong>CC:</strong>
          {data?.cc_email &&
            data?.cc_email?.length > 0 &&
            data?.cc_email.map((tag, i) => (
              <span key={i}>
                {i > 0 && " "}
                <ul>
                  <li>
                    <div dangerouslySetInnerHTML={{ __html: tag }} />
                  </li>
                </ul>
              </span>
            ))}
          <br />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "10px",
            }}
          >
            <strong>
              Subject:<span style={{ visibility: "hidden" }}>H</span>
            </strong>
            {editOffer ? (
              <>
                <TextArea
                  value={data?.email_subject}
                  onChange={(e) =>
                    setData({
                      ...data,
                      email_subject: e.target.value,
                    })
                  }
                />
              </>
            ) : (
              <div
                style={{ maxWidth: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: data?.email_subject,
                }}
              />
            )}
          </div>
          {editOffer ? (
            <Editor
              apiKey="c03cc5kmzse4y7i90fwrbswwrzl9g3rxbtd23ohrzt8pcd1k"
              init={{
                height: 500,
                plugins: "link image code table",
                toolbar:
                  "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | outdent indent | code",
              }}
              value={data?.email_body}
              onEditorChange={(content) =>
                setData({
                  ...data,
                  email_body: content,
                })
              }
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: data?.email_body,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default OfferPreview;
