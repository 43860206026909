import React, { useState, useEffect } from "react";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import { Card, Col, Row, Statistic, Typography } from "antd";
import {
  FileSearchOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
const AdminDashboard = () => {
  const [profile] = useState(JSON.parse(localStorage.getItem("user")));

  const {
    data: dashboardCount,
    isLoading: dasboardLoading,
    doFetch: FetchDashboardStatus,
  } = useFetch([], { suppressAlert: true });

  let loader = null;
  if (dasboardLoading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    FetchDashboardStatus({
      url: `${urls.dashboardStatus}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${profile.personal_info.token}`,
      },
    });
  }, []);
  return (
    <div>
      <Row>
        <Col>
          <Typography.Title type="primary" level={3}>
            Admin Dashboard
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title type="secondary" level={4}>
            {" "}
            ({profile?.academic_profile?.name})
          </Typography.Title>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Total Registered Candidates on CareerBox</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <UserOutlined
                      style={{ fontSize: "24px", color: "#1677ff" }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardCount?.applicant_count}
              valueStyle={{ color: "#1677ff" }}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Total Active Recruiters on CareerBox</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <TeamOutlined
                      style={{ fontSize: "24px", color: "#fa8c16" }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardCount?.recruiter_count}
              valueStyle={{
                color: "#fa8c16",
              }}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row style={{}}>
                  <Col span={20}>Total Active EA's on CareerBox</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <TeamOutlined
                      style={{ fontSize: "24px", color: "#1677ff" }}
                    />
                  </Col>
                </Row>
              }
              valueStyle={{ color: "#1677ff" }}
              value={dashboardCount?.ea_count}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Total Active Principals on CareerBox</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <TeamOutlined
                      style={{ fontSize: "24px", color: "#1677ff" }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardCount?.principal_count}
              valueStyle={{ color: "#1677ff" }}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row style={{}}>
                  <Col span={20}>Total Active Interviewers on CareerBox</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <TeamOutlined
                      style={{ fontSize: "24px", color: "#fa8c16" }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardCount?.interviewer_count}
              valueStyle={{ color: "#fa8c16" }}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Total Active Openings on CareerBox</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{
                        fontSize: "24px",
                        color: "#52c41a",
                      }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardCount?.position_count}
              valueStyle={{ color: "#52c41a" }}
            ></Statistic>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;
