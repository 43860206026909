import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Paper,
  Card,
  CardContent,
  Select,
  MenuItem,
  TablePagination,
  TableFooter,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputBase,
  TableContainer,
  // Modal,
  // Backdrop
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import Profile from "../../applicant/profile/profile";
import Modal from "../../../hoc/modal/modal";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./faceToFaceAllRounds.style";
import { useAlert } from "../../../hoc/alert/alert";
// import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import MediaQuery from "react-responsive";

const FaceToFaceAllRounds = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [startDate, setStartDate] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [endDate, setEndDate] = useState(null);
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [viewOpenning, setViewOpennings] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedDate, setSelectedDate] = useState(" ");
  const [selectedTime, setSelectedTime] = useState(" ");
  const [assignOpen, setAssignOpen] = useState(false);
  const [duration, setdDration] = useState();
  const [interviewerFree, setInterviewerFree] = useState({ response: null });
  const [selectedInterviewer, setSelectedInterviewer] = useState("");
  const [applicantId, setApplicantId] = useState(null);
  const [applicentName, setApplicentName] = useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [gradeCheck, setGradeCheck] = useState('');
  const [subjectCheck, setSubjectCheck] = useState('');
  const [branch, setBranch] = useState("");
  const [checked, setChecked] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const alert = useAlert();

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList,
  } = useFetch([], { suppressAlert: true });

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings,
  } = useFetch([], { suppressAlert: true });

  const {
    data: roleData,
    isLoading: roleDataLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });

  const {
    data: f2fData,
    isLoading: gettingF2Fdata,
    doFetch: fetchF2Fdetails,
  } = useFetch("", { suppressAlert: true });

  const {
    data: isInterviewerFree,
    isLoading: isInterviewerFreeLoading,
    doFetch: fetchIsInterviewerFree,
  } = useFetch([], { suppressAlert: true });

  const {
    data: assignSpokenTest,
    isLoading: assignSpokenTestLoading,
    doFetch: fetchAssignSpokenTest,
  } = useFetch([], { suppressAlert: true });

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch("", { suppressAlert: true });

  const {
    data: interviewerList,
    isLoading: interviewerListLoading,
    doFetch: fetchInterviewerList,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branchesList,
    isLoading: gettingBranches,
    doFetch: fetchBranches
  } = useFetch([], { suppressAlert: true });

  const {
    data: grades,
    isLoading: gradesLoading,
    doFetch: fetchGrades
  } = useFetch([], { suppressAlert: true });

  const {
    data: subjects,
    isLoading: subjectsLoading,
    doFetch: fetchSubjects
  } = useFetch([], { suppressAlert: true });

  const { data: gradeList, doFetch: fetchGradeList } = useFetch(null);

  const { data: cityList, doFetch: fetchCityList } = useFetch([], { suppressAlert: true });


  function OpenViewDetails(ID) {
    fetchApplicantDetail({
      url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (isInterviewerFree && isInterviewerFree.response) {
      setInterviewerFree(isInterviewerFree);
    }
  }, [isInterviewerFree]);

  useEffect(() => {
    if (selectedRole) {
      fetchInterviewerList({
        url: `${urls.interviewersRoleWiseEmployeeApi}?role_id=${selectedRole}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [selectedRole]);

  useEffect(() => {
    if (applicantDetail) {
      setOpen(true);
    }
  }, [applicantDetail]);

  const handleClose = () => {
    setOpen(false);
    setAssignOpen(false);
    setShowEdit(false);
  };

  let loader = null;
  if (
    gettingRoleList ||
    interviewerListLoading ||
    isInterviewerFreeLoading ||
    assignSpokenTestLoading ||
    gettingViewOpennings ||
    gettingF2Fdata ||
    applicantDetailLoading ||
    roleDataLoading ||
    gettingBranches ||
    gradesLoading ||
    subjectsLoading
  ) {
    loader = <Loader open />;
  }

  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchGrades({
        url: `${urls.newGrade}?position_type=${positionType}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });
      fetchCityList({
        url: urls.cityList,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setChecked("");
      setCheckedLoc("");
      setBranch("");
      setGradeCheck("");
      setSubjectCheck("");
    }
  }, [positionType]);

  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [RoleId, positionType, branch, checkedLoc, subjectCheck, gradeCheck]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };
  //comment
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  function functiontoSearchF2FDetails() {
    // if (!positionType) {
    //   alert.warning("Please Select Position Type");
    //   return;
    // }
    // if (!RoleId) {
    //   alert.warning("Please Select Role");
    //   return;
    // }
    fetchF2Fdetails({
      url: `${urls.f2fApplicentsApi
        }?role_id=${RoleId}&openning_id=${viewOpenning}&page_size=${rowsPerPage}&page=${page +
        1}&start_date=${startDate}&end_date=${endDate}&search=${searchTerm}&position_type=${positionType}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (RoleId && positionType) {
      functiontoSearchF2FDetails();
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (assignSpokenTest) {
      functiontoSearchF2FDetails();
      setInterviewerFree({ response: null });
      setAssignOpen(false);
      setdDration("");
      setSelectedInterviewer("");
      setSelectedDate("");
      setSelectedTime("");
    }
  }, [assignSpokenTest]);

  const assignZoom = () => {
    if (!selectedInterviewer || !selectedTime || !selectedDate || !duration) {
      alert.warning("Select all the fields");
      return;
    }
    fetchIsInterviewerFree({
      url: `${urls.checkInterviewFree
        }?user=${selectedInterviewer}&duration=${duration}&start_time=${`${selectedDate},${selectedTime}:00`}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  useEffect(() => {
    if (interviewerFree.response === "success") {
      const obj = {
        applicant: applicantId,
        user: selectedInterviewer,
        // topic: selectTopic,
        duration: duration,
        start_time: `${selectedDate},${selectedTime}:00`,
      };
      fetchAssignSpokenTest({
        url: urls.assignPlannerRound,
        method: "post",
        body: obj,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [interviewerFree, fetchAssignSpokenTest, name]);

  useEffect(() => {
    if (gradeCheck) {
      fetchSubjects({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeCheck}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
      setSubjectCheck("");
    }
  }, [gradeCheck, fetchSubjects])

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
      setBranch("");
    }
  }, [checkedLoc])

  function handleBranch(e) {
    setBranch(e.target.value);
  }


  const assignModal = (
    <React.Fragment>
      <Modal
        className={classes.model}
        click={handleClose}
        open={assignOpen}
        large
        style={{ padding: "15px" }}
      >
        
        <div style={{display: "flex", justifyContent: "space-between"}}>
        <Typography variant="h4">Assign</Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={2} className={classes.gride}>
          <Grid item md={4} xs={12}>
            <TextField
              label="Role"
              margin="dense"
              required
              fullWidth
              className="textList"
              select
              // SelectProps={{ multiple: true }}
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value)}
              variant="outlined"
            >
              {roleData &&
                roleData &&
                roleData.map((data) => (
                  <MenuItem value={data.id} key={data.id} name={data.role_name}>
                    {data.role_name ? data.role_name : ""}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          {selectedRole && (
            <Grid item md={4} xs={12}>
              <TextField
                margin="dense"
                required
                label="Interviewer"
                fullWidth
                className="textList"
                select
                value={selectedInterviewer}
                onChange={(e) => setSelectedInterviewer(e.target.value)}
                variant="outlined"
              >
                {interviewerList &&
                  interviewerList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.first_name}
                    >
                      {data.first_name ? data.first_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
          <Grid item md={4}>
            <TextField
              // className={classes.textField}
              helperText="Select Date"
              type="date"
              margin="dense"
              className="textList"
              fullWidth
              onChange={(e) => setSelectedDate(e.target.value)}
              required
              value={selectedDate || " "}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              // className={classes.timeField}
              helperText="Select Time"
              type="time"
              margin="dense"
              className="textList"
              fullWidth
              onChange={(e) => setSelectedTime(e.target.value)}
              required
              value={selectedTime || " "}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              // className={classes.timeField}
              label="Duration in minutes"
              type="number"
              margin="dense"
              className="textList"
              fullWidth
              onChange={(e) => setdDration(e.target.value)}
              required
              value={duration || " "}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Button
              className={classes.addButton}
              color="primary"
              size="small"
              fullWidth
              variant="contained"
              onClick={assignZoom}
            >
              Assign
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </React.Fragment>
  );

  function AssignFuncion(id) {
    fetchRole({
      url: urls.interviewPanalRolesApi,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
    setApplicantId(id);
    setAssignOpen(true);
  }

  let modal = null;
  if (applicantDetail) {
    modal = (
      <Modal
        className={classes.model}
        click={handleClose}
        large
        style={{ padding: "15px" }}
        open={open}
      >
        <React.Fragment>
          <div style={{display: "flex", justifyContent: "space-between"}}>
          <Typography className="headings" variant="h4">
            <strong>Details</strong>
          </Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        </div>
          <Divider className={classes.divider} />
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} className={classes.gridOne}>
              <Typography className="textDisplay" variant="h6">
                <strong>Name :</strong>{" "}
                {applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.user &&
                  applicantDetail.applicant.user.first_name}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridOne}>
              <Typography className="textDisplay" variant="h6">
                <strong>PhoneNo : </strong>
                {applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.contact_no}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12} className={classes.gridOne}>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong>Email : </strong>{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.email) ||
                    ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Email:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.email) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong>Grade : </strong>{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name) ||
                    ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Grade:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong>Role : </strong>{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name) ||
                    ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Role:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong>Position Applied : </strong>{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant.position_apply &&
                    applicantDetail.applicant.position_apply.title) ||
                    ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Position Applied:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant.position_apply &&
                    applicantDetail.applicant.position_apply.title) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong>Branch Interested : </strong>{" "}
                  {(applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name) ||
                    ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Branch Interested:{" "}
                  {(applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
                <Typography className="textDisplay" variant="h6">
                  <strong> Application Date: </strong>{" "}
                  {applicantDetail.applicant.register_date || ""}
                </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Application Date:{" "}
                  {applicantDetail.applicant.register_date || ""}
                </b> */}
              </div>
            </Grid>
            {/* <Grid item md={6}/> */}
            <Grid item md={4} xs={6} >
              <Typography className="textDisplay" variant="h5"> <strong>Resume</strong></Typography>
            </Grid>
            <Grid item md={6} xs={6} className={classes.item}>
              {applicantDetail.applicant &&
                applicantDetail.applicant.resume ? (
                <a
                  target='_blank'
                  href={applicantDetail.applicant.resume || ""}
                  onclick="window.open(this.href); return false;"
                >
                  {" "}
                  <Typography className="textDisplay" variant="h5"> View Resume</Typography>
                </a>
              ) : (
                "Resume Not Uploaded"
              )}
            </Grid>
            {(applicantDetail?.mcq_score !== null || applicantDetail?.written_score !== null || applicantDetail?.spoken_score !== null) ?
              (
                <>
                  <Grid item md={4} xs={12} className={classes.gridOne}>
                    {applicantDetail && applicantDetail?.mcq_score ? (
                      <Card className={classes.card}>
                        <CardContent>
                          <Typography variant="h6" xs={12} component="h2">
                            MCQ{" "}
                            <a>
                              {" "}
                              Completed{" "}
                              <Typography variant="h6">
                                Marks=
                                {(applicantDetail &&
                                  applicantDetail?.mcq_score &&
                                  applicantDetail?.mcq_score?.mark_scored) ||
                                  "Not Uploaded"}
                              </Typography>
                            </a>

                          </Typography>
                        </CardContent>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={4} xs={12} className={classes.gridOne}>
                    {applicantDetail &&
                      applicantDetail?.written_score ? (
                      <Card className={classes.card}>
                        <CardContent>
                          <Typography variant="h6" component="h2">
                            Written Test{" "}
                            <a>
                              {" "}
                              Completed{" "}
                              <Typography variant="h6">
                                Marks=
                                {(applicantDetail &&
                                  applicantDetail?.written_score &&
                                  applicantDetail?.written_score?.mark_scored) ||
                                  "Not Uploaded"}
                              </Typography>
                            </a>
                          </Typography>
                        </CardContent>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item md={4} xs={12} className={classes.gridOne}>
                    {applicantDetail &&
                      applicantDetail?.spoken_score ? (
                      <Card className={classes.card}>
                        <CardContent>
                          <Typography variant="h6" component="h2">
                            Spoken Test{" "}
                            <a>
                              {" "}
                              Completed{" "}
                              <Typography variant="h6">
                                Marks=
                                {(applicantDetail &&
                                  applicantDetail?.spoken_score &&
                                  applicantDetail?.spoken_score.mark_scored) ||
                                  "Not Uploaded"}
                              </Typography>
                            </a>
                          </Typography>
                        </CardContent>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Grid>
                </>
              ) : (
                ""
              )
            }
            {applicantDetail &&
              applicantDetail.applicant_f2f &&
              applicantDetail.applicant_f2f.map((item, index) => (
                <Grid
                  item
                  md={12}
                  xs={12}
                  className={classes.gridOnee}
                  key={index}
                >
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        {4 + index}: {item.round}
                      </Typography>
                      <Typography variant="h6" component="h2">
                        Marks : {item.mark_scored || "pending"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  const fetchSearch = (e) => {
    setPage(0);
    setRowsPerPage(50);
    setSearchTerm(e.target.value);
    if(e.target.value.length > 4)
    functionSearch(e.target.value);
  };

  useEffect(() => {
    if ((page || rowsPerPage) && RoleId) {
      fetchF2Fdetails({
        url: `${urls.f2fApplicentsApi
          }?role_id=${RoleId}&openning_id=${viewOpenning}&page_size=${rowsPerPage}&page=${page +
          1}&start_date=${startDate}&end_date=${endDate}&search=${searchTerm}&position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [page, fetchF2Fdetails, rowsPerPage, name]);

  function functionSearch(data) {
    fetchF2Fdetails({
      url: `${urls.f2fApplicentsApi
        }?global_search=${searchTerm}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }
  // useEffect(() => {
  //   if (searchTerm.length > 2) {
  //     functionSearch(searchTerm);
  //   }
  // }, [searchTerm]);

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={5} xs={12}>
          <Typography className="headings" variant="h4"><strong>Face To Face</strong></Typography>
        </Grid>
        {/* <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              type="text"
              placeholder="search"
              // id='search-bar'
              value={searchTerm}
              onChange={fetchSearch}
            />
          </div>
        </Grid> */}
                <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={fetchSearch}
            />
          </div>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />
      <MediaQuery minWidth={451}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                {" "}
                Select Position Type <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                margin="dense"
                value={positionType || ""}
                onChange={(e) => setPositionType(e.target.value)}
                variant="outlined"
              >
                {positionTypeList.map((option, index) => (
                  <MenuItem key={index} value={option.label} name={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                {" "}
                Select Role <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                margin="dense"
                value={RoleId || ""}
                onChange={(e) => setRoleId(e.target.value)}
                variant="outlined"
              >
                {roleList &&
                  roleList.length !== 0 &&
                  roleList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.applicant_role_name}
                    >
                      {option.applicant_role_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>City</Typography>
              <Select
                required
                fullWidth
                // multiple
                margin='dense'
                value={checkedLoc || ""}
                onChange={e => setCheckedLoc(e.target.value)}
                variant='outlined'
              >
                {cityList && cityList.length !== 0 &&
                  cityList.map(option => (
                    <MenuItem key={option.value} value={option.id} name={option.id}>
                      {option.city_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>Branch</Typography>
              <Select
                required
                fullWidth
                // multiple
                margin='dense'
                value={branch || ""}
                onChange={handleBranch}
                variant='outlined'
              >
                {branchesList && branchesList.length !== 0 &&
                  branchesList.map(option => (
                    <MenuItem key={option.value} value={option.id} name={option.id}>
                      {option.branch_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>{positionType=="Academic" ?"Grade":"Level"}</Typography>
              <Select
                required
                fullWidth
                margin='dense'
                value={gradeCheck || ""}
                onChange={e => setGradeCheck(e.target.value)}
                variant='outlined'
              >
                {grades && grades.map(option => (
                  <MenuItem key={option.id} value={option.id} name={option.id}>
                    {option.grade_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>{positionType=="Academic" ?"Subject":"Topic"}</Typography>
              <Select
                required
                fullWidth
                margin='dense'
                value={subjectCheck || ""}
                onChange={e => setSubjectCheck(e.target.value)}
                variant='outlined'
              >
                {subjects && subjects && subjects.length > 0 && subjects.map(option => (
                  <MenuItem key={option.subject.id} value={option.subject.id} name={option.subject.id}>
                    {option.subject.subject_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                Select Opening
              </Typography>
              <Select
                required
                fullWidth
                margin="dense"
                value={viewOpenning || ""}
                onChange={(e) => setViewOpennings(e.target.value)}
                variant="outlined"
              >
                {viewOpenningsData &&
                  viewOpenningsData.length !== 0 &&
                  viewOpenningsData.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.title}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>Start Date</Typography>
              <TextField
                className={classes.textField}
                margin="dense"
                required
                fullWidth
                onChange={(e) => handleStartDateChange(e.target.value)}
                type="date"
                value={startDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>End Date</Typography>
              <TextField
                className={classes.textField}
                margin="dense"
                required
                fullWidth
                onChange={(e) => handleEndDateChange(e.target.value)}
                type="date"
                value={endDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xss={12}>
              <Button
                style={{ marginTop: "33px" }}
                variant="contained"
                color="primary"
                onClick={functiontoSearchF2FDetails}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </MediaQuery>

      <MediaQuery maxWidth={451}>
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                {" "}
                Select Position Type <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                style={{ width: "200px" }}
                margin="dense"
                value={positionType || ""}
                onChange={(e) => setPositionType(e.target.value)}
                variant="outlined"
              >
                {positionTypeList.map((option, index) => (
                  <MenuItem key={index} value={option.label} name={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                {" "}
                Select Role <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                style={{ width: "200px" }}
                fullWidth
                margin="dense"
                value={RoleId || ""}
                onChange={(e) => setRoleId(e.target.value)}
                variant="outlined"
              >
                {roleList &&
                  roleList.length !== 0 &&
                  roleList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.applicant_role_name}
                    >
                      {option.applicant_role_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>City</Typography>
              <Select
                required
                fullWidth
                // multiple
                margin='dense'
                value={checkedLoc || ""}
                onChange={e => setCheckedLoc(e.target.value)}
                variant='outlined'
              >
                {cityList && cityList.length !== 0 &&
                  cityList.map(option => (
                    <MenuItem key={option.value} value={option.id} name={option.id}>
                      {option.city_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>Branch</Typography>
              <Select
                required
                fullWidth
                // multiple
                margin='dense'
                value={branch || ""}
                onChange={handleBranch}
                variant='outlined'
              >
                {branchesList && branchesList.length !== 0 &&
                  branchesList.map(option => (
                    <MenuItem key={option.value} value={option.id} name={option.id}>
                      {option.branch_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>Grade</Typography>
              <Select
                required
                fullWidth
                margin='dense'
                value={gradeCheck || ""}
                onChange={e => setGradeCheck(e.target.value)}
                variant='outlined'
              >
                {grades && grades && grades.length > 0 && grades.map(option => (
                  <MenuItem key={option.id} value={option.id} name={option.id}>
                    {option.grade_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>Subject</Typography>
              <Select
                required
                fullWidth
                margin='dense'
                value={subjectCheck || ""}
                onChange={e => setSubjectCheck(e.target.value)}
                variant='outlined'
              >
                {subjects && subjects && subjects.length > 0 && subjects.map(option => (
                  <MenuItem key={option.subject.id} value={option.subject.id} name={option.subject.id}>
                    {option.subject.subject_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                Select Opening
              </Typography>
              <Select
                required
                fullWidth
                style={{ width: "200px" }}
                margin="dense"
                value={viewOpenning || ""}
                onChange={(e) => setViewOpennings(e.target.value)}
                variant="outlined"
              >
                {viewOpenningsData &&
                  viewOpenningsData.length !== 0 &&
                  viewOpenningsData.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.title}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>Start Date</Typography>
              <TextField
                className={classes.textField}
                margin="dense"
                required
                style={{ width: "200px" }}
                fullWidth
                onChange={(e) => handleStartDateChange(e.target.value)}
                type="date"
                value={startDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>End Date</Typography>
              <TextField
                className={classes.textField}
                margin="dense"
                required
                style={{ width: "200px" }}
                fullWidth
                onChange={(e) => handleEndDateChange(e.target.value)}
                type="date"
                value={endDate || ""}
                variant="outlined"
              />
            </Grid>

            <Grid item md={2} xs={12}>
              <Button
                style={{ marginTop: "33px" }}
                variant="contained"
                color="primary"
                fullWidth
                onClick={functiontoSearchF2FDetails}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </MediaQuery>

      <Divider className={classes.divider} />
      {f2fData && f2fData.result.length === 0 && (
        <Typography
          variant="h4"
          style={{ color: "blue", marginTop: "50px", textAlign: "center" }}
        >
          Records Not Found
        </Typography>
      )}

      {f2fData && f2fData.result.length !== 0 && (
        <Paper>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>S.No</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Name</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Contact</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Branch</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Grade</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Subject</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>View Details</strong></Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography><strong>Assign</strong></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {f2fData &&
                      f2fData.result.length !== 0 &&
                      f2fData.result.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>{data.name || ""}</Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>{data.contact_no || " "}</Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>{data.branch || ""}</Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>{data.grade || ""}</Typography>
                          </TableCell>
                          <TableCell float="left" style={{ border: '1px solid black' }}>
                            <Typography>{data.subject}</Typography>
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => OpenViewDetails(data.id)}
                            >
                              View
                            </Button>
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => AssignFuncion(data.id)}
                            >
                              Assign
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Table>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        style={{ marginTop: "15px" }}
                        colSpan={5}
                        rowsPerPageOptions={[50, 75, 100]}
                        component="div"
                        count={f2fData && f2fData.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                          "aria-label": "previous page",
                        }}
                        nextIconButtonProps={{
                          "aria-label": "next page",
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                      <TableCell>
                        <IconButton
                          onClick={firstPageChange}
                          disabled={page === 0 || page === 1}
                        >
                          <FirstPageIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => lastPageChange(f2fData.total_pages - 1)}
                          disabled={page === +f2fData.total_pages - 1}
                        >
                          <LastPageIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      )}

      {loader}
      {assignModal}
      {modal}
    </React.Fragment>
  );
};

FaceToFaceAllRounds.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(FaceToFaceAllRounds);
