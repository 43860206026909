
export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: '5px 40px',
      marginTop: '15px',
    },
    paper: {
      position: 'absolute',
      width: '60%',
      marginLeft: '15%',
      backgroundColor: theme.palette.background.paper,
    //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: true,
      // maxHeight: '400px',
    },
    paperMain: {
      // position: 'absolute',
      // width: '90%',
      // margin: '5px',
      // backgroundColor: theme.palette.background.paper,
    //   border: '2px solid #000',
      // boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 4, 3),
    },
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    fixed : {
      height : '245px',
      overflowY : 'scroll',
    },
    loginButton: {
      margin: '20px 0px',
      display: 'grid',
    },
    Button: {
      borderRadius: '0px',
    },
    search: {
      float: 'right',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
  });
