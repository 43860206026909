/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Card,
  Divider,
  Button,
  Row,
  Col,
  Avatar,
  Empty,
  Typography,
  Tag,
  Space,
  Pagination,
  Modal,
  message,
  Select,
  Table,
  Input,
  Rate,
  Form,
  InputNumber,
} from "antd";
import { EditOutlined, EyeOutlined, FilePdfOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import Parameters from "./Parameters";
import urls from "../../../url";
import Loader from "../../../hoc/loader";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import Title from "antd/es/skeleton/Title";

// import { Link } from 'react-router-dom';

function TodaysInterview({ classes }) {
  const questionColumns = [
    {
      title: "Parameter",
      dataIndex: "parameter",
      key: "parameter",
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (data) => {
        return (
          <Tag
            style={{ cursor: "pointer" }}
            color="geekblue"
            icon={<EyeOutlined />}
            onClick={() => fetchQuestionsVal(data)}
          >
            View Questions
          </Tag>
        );
      },
    },
  ];
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = useState(1);
  const [f2fModalOpen, setF2fModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [totalMarks, setTotalMarks] = useState("");
  const [passingStatus, setPassingStatus] = useState();
  const [comments, setComments] = useState("");
  const [viewModdel, setViewModel] = useState(false);
  const [applicentId, setApplicentId] = useState("");
  const [faceToFaceId, setfaceToFaceId] = useState("");
  const [confirmMarks, setConfirmMarks] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [currentTime, setCurrentTime] = useState(moment().format("hh:mm A"));
  const [parameterTitle, setParameterTitle] = useState(null);
  const [rating, setRating] = useState(0);
  const [redFlag, setRedFlag] = useState("");
  const [feedbackForm] = useForm();
  const [feedbackViewForm] = useForm();
  const [getInterviewData, setGetInterviewData] = useState([]);
  const [getInterviewDataLoading, setGetInterviewDataLoading] = useState(false);
  const [marksSubmitRes, setMarksSubmitRes] = useState([]);
  const [submittingMarks, setSubmittingMarks] = useState(false);
  const [getQuestionsData, setQuestionsData] = useState([]);
  const [getQuestionsDataLoading, setGetQuestionsDataLoading] = useState(false);
  const [isClassDemo, setIsClassDemo] = useState(false);

  const desc = ["No Hire", "Average", "Good", "Very Good", "Excellent"];
  const desc2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    const intervalId = setInterval(() => {
      const formattedTime = moment().format("YYYY-MM-DD HH:mm A");
      setCurrentTime(formattedTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function todaysInterview() {
    viewInterview({
      page: page,
      page_size: rowsPerPage || 10,
      searchData: searchData || "",
      user_id: auth?.personal_info?.user_id,
    });
  }

  function parseStringToObjectArray(dataString) {
    dataString = dataString.replace(/'/g, '"').replace(/}{/g, "},{");
    const dataArray = JSON.parse(dataString);
    const keyValueArray = dataArray.map((obj) => Object.entries(obj)[0]);
    return keyValueArray;
  }

  const fetchSubmitMarks = (data = {}) => {
    setSubmittingMarks(true);
    axios
      .post(`${urls.submitMarksApi}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      })
      .then((response) => {
        setMarksSubmitRes(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setSubmittingMarks(false);
        feedbackForm.resetFields();
      });
  };

  const fetchQuestions = (params = {}) => {
    setGetQuestionsDataLoading(true);
    axios
      .get(`${urls.getParameters}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setQuestionsData(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setGetQuestionsDataLoading(false);
      });
  };

  const viewInterview = (params = {}) => {
    setGetInterviewDataLoading(true);
    axios
      .get(`${urls.todaysInterviewApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setGetInterviewData(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setGetInterviewDataLoading(false);
      });
  };

  function getQuestions(id, F2fId, marks) {
    setApplicentId(id);
    setfaceToFaceId(F2fId);
    setConfirmMarks(marks);
    fetchQuestions();
  }

  function getMarks(id, F2fId, marks) {
    setFeedbackModalOpen(true);
    setApplicentId(id);
    setfaceToFaceId(F2fId);
    setConfirmMarks(marks);
  }

  function functionSearch(data) {
    setSearchData(data);
  }

  useEffect(() => {
    todaysInterview();
  }, [rowsPerPage, page, searchData]);

  async function fetchQuestionsVal(data) {
    localStorage.setItem("param_id", data?.id);
    setViewModel(true);
    setParameterTitle(data?.parameter);
  }

  useEffect(() => {
    if (getQuestionsData) {
      if (getQuestionsData) {
        setF2fModalOpen(true);
      }
    }
  }, [getQuestionsData]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!rowsPerPage) {
      setRowsPerPage(12);
    }
  }

  useEffect(() => {
    if (auth) {
      setF2fModalOpen(false);
      setFeedbackModalOpen(false);
      setConfirmMarks("");
      setTotalMarks("");
      setComments("");
      setApplicentId("");
      setfaceToFaceId("");
      todaysInterview();
    }
  }, [page, rowsPerPage, auth, marksSubmitRes]);

  let loader = null;
  if (getInterviewDataLoading || submittingMarks) {
    loader = <Loader open />;
  }

  const handleClose = () => {
    setF2fModalOpen(false);
    setFeedbackModalOpen(false);
    setPassingStatus("");
    setConfirmMarks("");
    setTotalMarks("");
    setComments("");
    setApplicentId("");
    setfaceToFaceId("");
    setRedFlag("");
    setRating(0);
    feedbackForm.resetFields();
  };

  const onCloseFunc = () => {
    setViewModel(false);
  };

  // function submitMarks(values) {
  //   // if (!comments || passingStatus == undefined) {
  //   //   return message.error("Enter Passing Status and Comment!");
  //   // }
  //   const data = {
  //     applicent_Id: applicentId,
  //     passing_status: passingStatus,
  //     comments: values?.functional_evaluation,
  //     f2f_id: faceToFaceId,
  //     feedback_by: auth.personal_info?.user_id,
  //     rating: rating,
  //     red_flag: redFlag,
  //     behavioral: values?.behavioural_evaluation,
  //   };
  //   fetchSubmitMarks({
  //     url: `${urls.submitMarksApi}`,
  //     body: data,
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   handleClear();
  // }

  function submitMarks() {
    const values = feedbackForm.getFieldsValue();
    if (isClassDemo) {
      if (
        !values?.movement_rating ||
        !values?.checking_notbooks_rating ||
        !values?.encouraging_kids_rating ||
        !values?.no_chorus_answer_rating ||
        !values?.differentiation_rating ||
        !values?.public_speaking_rating ||
        !values?.subject_delivery_rating ||
        values?.status == "undefined"
      ) {
        return message.error("Please fill all required fields!");
      } else if (
        values?.subject_delivery_rating > 40 ||
        values?.subject_delivery_rating < 0
      ) {
        return message.error("Please submit valid marks!");
      } else {
        const values = feedbackForm.getFieldsValue();
        fetchSubmitMarks({
          applicent_Id: applicentId,
          f2f_id: faceToFaceId,
          feedback_by: auth.personal_info?.user_id,
          comments: [
            { movement_rating: values?.movement_rating },
            { checking_notbooks_rating: values?.checking_notbooks_rating },
            { encouraging_kids_rating: values?.encouraging_kids_rating },
            { no_chorus_answer_rating: values?.no_chorus_answer_rating },
            { differentiation_rating: values?.differentiation_rating },
            { public_speaking_rating: values?.public_speaking_rating },
            {
              subject_delivery_rating:
                values?.subject_delivery_rating > 40
                  ? 40
                  : values?.subject_delivery_rating < 0
                  ? 0
                  : values?.subject_delivery_rating,
            },
          ],
          passing_status: values?.status,
          red_flag: redFlag ?? "-",
          rating: 0,
        });
        handleClear();
      }
    } else {
      if (
        !values?.comment ||
        values?.status == "undefined" ||
        !values?.rating
      ) {
        return message.error("Enter Passing Status and Comment!");
      }
      // applicentId
      const data = {
        applicent_Id: applicentId,
        passing_status: values?.status,
        comments: values?.comment,
        f2f_id: faceToFaceId,
        feedback_by: auth.personal_info?.user_id,
        rating: rating ?? "-",
        red_flag: redFlag,
      };
      fetchSubmitMarks(data);
      handleClear();
    }
  }

  const handleClear = () => {
    setApplicentId("");
    setPassingStatus("");
    setComments("");
    setRating(0);
    setRedFlag("");
    feedbackForm.resetFields();
    setIsClassDemo("");
    setFeedbackModalOpen(false);
  };

  const compareTime = (current = currentTime, startTime) => {
    const date1 = moment(current, "YYYY-MM-DD hh:mm A");
    const date2 = moment(startTime, "YYYY-MM-DD hh:mm A");
    if (date1.isBefore(date2)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <Row style={{ justifyContent: "space-between" }}>
        <Col>
          <div className="page_title">
            <strong>Today's Interview</strong>
          </div>
        </Col>
        <Col>
          <Input
            placeholder="I'm Looking For..."
            name="subject"
            defaultValue={searchData}
            value={searchData}
            onChange={(e) => {
              functionSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Divider />

      {viewModdel && (
        <Parameters
          onCloseFunction={onCloseFunc}
          open={viewModdel}
          title={parameterTitle}
        />
      )}
      {loader}
      {getInterviewData && getInterviewData?.results?.length !== 0 ? (
        <>
          <Row gutter={[16, 16]} style={{ marginBlock: "16px" }}>
            {getInterviewData?.results?.map((item) => {
              const words = item?.applicant_data?.name?.split(" "); // Split name into words
              const initials = words
                ?.map((word) => word.charAt(0))
                ?.join("")
                .toUpperCase();
              return (
                <Col
                  md={{ span: 12 }}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <Card>
                    <Space>
                      {item?.applicant_data?.photo ? (
                        <Avatar
                          src={<img src={item?.applicant_data?.photo} />}
                          size={60}
                        />
                      ) : (
                        <Avatar size={60}>{initials}</Avatar>
                      )}
                      <div className="">
                        <Row>
                          <div style={{ fontSize: "18px" }}>
                            <strong> {item?.applicant_data?.name || ""}</strong>
                          </div>
                        </Row>
                        <Row>
                          <Typography>Applied for: </Typography>
                          <Typography style={{ marginLeft: "5px" }}>
                            {" "}
                            {item?.position_applied}
                          </Typography>
                        </Row>
                        <a
                          href={
                            item?.applicant_data?.resume ||
                            item?.applicant_data?.resume_link
                          }
                          target="__blank"
                        >
                          <Tag
                            color="processing"
                            icon={<FilePdfOutlined />}
                            style={{ marginTop: "5px" }}
                          >
                            Resume
                          </Tag>
                        </a>
                      </div>
                    </Space>
                    <Divider />
                    <Row style={{ marginTop: "8px" }}>
                      {!item?.is_class_demo ? (
                        <Tag
                          style={{ cursor: "pointer" }}
                          color="purple"
                          onClick={() =>
                            getQuestions(
                              item?.applicant?.id,
                              item?.id,
                              item?.rating,
                              item?.is_class_demo
                            )
                          }
                        >
                          Questions <EyeOutlined />
                        </Tag>
                      ) : (
                        ""
                      )}
                      {!item?.comment && (
                        <Tag
                          color="orange"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            getMarks(
                              item?.applicant,
                              item?.id,
                              item?.total_mark
                            );
                            setIsClassDemo(item?.is_class_demo ? true : false);
                          }}
                        >
                          Feedback <EditOutlined />
                        </Tag>
                      )}
                    </Row>
                    {!item?.comment && (
                      <Row style={{ marginBlock: "8px" }}>
                        <div>
                          <strong>
                            Note: Please upload marks after completing
                            Interview.
                          </strong>
                        </div>
                      </Row>
                    )}
                    <div style={{ marginBlock: "8px" }}>
                      <div>
                        <strong>Interview Feedback</strong> :{" "}
                        {item?.is_class_demo && item?.comment
                          ? "-"
                          : item?.comment}
                      </div>
                      <div>
                        <strong>Evaluated by</strong> : {item?.feedback_by}
                      </div>
                      <div>
                        <strong>Candidate Rating</strong> : {item?.rating}
                      </div>
                    </div>
                    <Row>
                      {" "}
                      {item?.comment ? (
                        <Button
                          style={{ width: "100%", cursor: "pointer" }}
                          disabled
                        >
                          Interview Completed
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          style={{ width: "100%" }}
                          onClick={() => {
                            window.open(item?.zoom?.presenter_url || "");
                          }}
                          disabled={compareTime(
                            currentTime,
                            item?.zoom?.start_time
                          )}
                        >
                          {compareTime(currentTime, item?.zoom?.start_time) ? (
                            <b>
                              Interview Scheduled at&nbsp;
                              {item?.zoom?.start_time?.split(" ")[1] +
                                " " +
                                item?.zoom?.start_time?.split(" ")[2]}
                            </b>
                          ) : (
                            "Join Interview"
                          )}
                        </Button>
                      )}
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Card
              style={{ width: "100%" }}
              bodyStyle={{ padding: "0.5rem 0.75rem" }}
            >
              <Pagination
                showSizeChanger={false}
                defaultCurrent={1}
                current={page}
                total={getInterviewData?.results?.count}
                style={{ textAlign: "end" }}
                onChange={(page, pageSize) => {
                  handleChangePage(page, page);
                }}
              />
            </Card>
          </Row>
        </>
      ) : (
        <Empty />
      )}
      <Modal
        title={"Feedback"}
        onCancel={handleClose}
        width={600}
        open={feedbackModalOpen}
        footer={[
          <Button type="primary" form="feedbackForm" htmlType="submit">
            Submit
          </Button>,
        ]}
      >
        <Form
          id="feedbackForm"
          form={feedbackForm}
          layout="vertical"
          onFinish={submitMarks}
        >
          {isClassDemo ? (
            <>
              <br />
              <Form.Item
                // rules={[{ required: true, message: "Please give rating!" }]}
                name="movement_rating"
              >
                <Typography style={{ marginBottom: "5px" }}>
                  <span style={{ color: "red" }}>* </span>
                  1. Rate movement across all benches rows & front to back :
                </Typography>
                <Rate
                  style={{ marginLeft: "20px" }}
                  count={10}
                  tooltips={desc2}
                  isRequired
                  onChange={(value) => {
                    feedbackForm.setFieldValue("movement_rating", value);
                  }}
                />
              </Form.Item>

              <Form.Item
                // rules={[{ required: true, message: "Please give rating!" }]}
                name="checking_notbooks_rating"
              >
                <Typography style={{ marginBottom: "5px" }}>
                  <span style={{ color: "red" }}>* </span>
                  2. Rate if he/she is checking notebooks of the kids :
                </Typography>
                <Rate
                  style={{ marginLeft: "20px" }}
                  count={10}
                  tooltips={desc2}
                  isRequired
                  onChange={(value) => {
                    feedbackForm.setFieldValue(
                      "checking_notbooks_rating",
                      value
                    );
                  }}
                />
              </Form.Item>

              <Form.Item
                // rules={[{ required: true, message: "Please give rating!" }]}
                name="encouraging_kids_rating"
              >
                <Typography style={{ marginBottom: "5px" }}>
                  <span style={{ color: "red" }}>* </span>
                  3. Rate if he/she is encouraging & ensuring raising hands by
                  kids while asking any questions :
                </Typography>
                <Rate
                  style={{ marginLeft: "20px" }}
                  count={10}
                  tooltips={desc2}
                  isRequired
                  onChange={(value) => {
                    feedbackForm.setFieldValue(
                      "encouraging_kids_rating",
                      value
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                // rules={[{ required: true, message: "Please give rating!" }]}
                name="no_chorus_answer_rating"
              >
                <Typography style={{ marginBottom: "5px" }}>
                  <span style={{ color: "red" }}>* </span>
                  4. Rate if he/she is encouraging & ensuring
                  <i> NO CHORUS ANSWERS EVER :</i>
                </Typography>
                <Rate
                  style={{ marginLeft: "20px" }}
                  count={10}
                  tooltips={desc2}
                  isRequired
                  onChange={(value) => {
                    feedbackForm.setFieldValue(
                      "no_chorus_answer_rating",
                      value
                    );
                  }}
                />
              </Form.Item>

              <Form.Item
                // rules={[{ required: true, message: "Please give rating!" }]}
                name="differentiation_rating"
              >
                <Typography style={{ marginBottom: "5px" }}>
                  <span style={{ color: "red" }}>* </span>
                  5. Rate if Differentiation is being followed across 3 category
                  of students :
                </Typography>
                <Rate
                  style={{ marginLeft: "20px" }}
                  count={10}
                  tooltips={desc2}
                  isRequired
                  onChange={(value) => {
                    feedbackForm.setFieldValue("differentiation_rating", value);
                  }}
                />
              </Form.Item>

              <Form.Item
                // rules={[{ required: true, message: "Please give rating!" }]}
                name="public_speaking_rating"
              >
                <Typography style={{ marginBottom: "5px" }}>
                  <span style={{ color: "red" }}>* </span>
                  6. Rate if kids are being given chance to do public speaking
                  in the class around the topic being taught :
                </Typography>
                <Rate
                  style={{ marginLeft: "20px" }}
                  count={10}
                  tooltips={desc2}
                  isRequired
                  onChange={(value) => {
                    feedbackForm.setFieldValue("public_speaking_rating", value);
                  }}
                />
              </Form.Item>

              <Form.Item
                // rules={[{ required: true, message: "Please give rating!" }]}
                name="subject_delivery_rating"
              >
                <Typography style={{ marginBottom: "5px" }}>
                  <span style={{ color: "red" }}>* </span>
                  7. Rate the technical subject delivery out of 40 marks :
                  {"    "}
                  <InputNumber
                    type="number"
                    // placeholder="(Write only numbers here)"
                    style={{ WebkitAppearance: "none", MozAppearance: "none" }}
                    // min={0}
                    // max={40}
                    // formatter={(value) => `${value}`.replace(/[^0-9]/g, "")}
                    inputMode="numeric"
                  />
                </Typography>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Please select status!" }]}
                name="status"
                label="Shortlist Status :"
              >
                <Select
                  allowClear
                  placeholder="Select Status"
                  style={{ width: "100%" }}
                  // defaultValue={passingStatus}
                  // value={passingStatus}
                  onChange={(e) => {
                    console.log("e", e);
                    setPassingStatus(e);
                  }}
                >
                  <Select.Option key={1} value={true}>
                    Shortlist
                  </Select.Option>
                  <Select.Option key={2} value={false}>
                    Reject
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item name="redflag" label="Red Flag (if any) :">
                <Input.TextArea
                  rows={4}
                  placeholder="Red Flag (if any)"
                  onChange={(e) => setRedFlag(e.target.value)}
                />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                rules={[{ required: true, message: "Please select status!" }]}
                name="status"
                label="Shortlist Status :"
              >
                <Select
                  allowClear
                  placeholder="Select Status"
                  style={{ width: "100%" }}
                  // defaultValue={passingStatus}
                  // value={passingStatus}
                  onChange={(e) => {
                    console.log("e", e);
                    setPassingStatus(e);
                  }}
                >
                  <Select.Option key={1} value={true}>
                    Shortlist
                  </Select.Option>
                  <Select.Option key={2} value={false}>
                    Reject
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Please add comment!" }]}
                name="comment"
                label="Feedback :"
              >
                <TextArea
                  value={comments}
                  onChange={(e) => {
                    setComments(e?.target?.value);
                  }}
                  placeholder="Comments"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                />
              </Form.Item>
              <br />
              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="rating"
              >
                <Typography>
                  <span style={{ color: "red" }}>* </span>
                  Candidate Rating :{" "}
                  <Rate
                    style={{ marginLeft: "20px", marginRight: "10px" }}
                    isRequired
                    tooltips={desc}
                    onChange={(value) => {
                      setRating(value);
                      feedbackForm.setFieldValue("rating", value);
                    }}
                  />
                </Typography>
              </Form.Item>
              <Form.Item name="redflag" label="Red Flag (if any) :">
                <Input.TextArea
                  rows={4}
                  placeholder="Red Flag (if any)"
                  onChange={(e) => setRedFlag(e.target.value)}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>

      <Modal
        title={"F2F Questions"}
        onCancel={handleClose}
        open={f2fModalOpen}
        footer={null}
        width={600}
      >
        <Table
          loading={getQuestionsDataLoading}
          scroll={{ y: 300 }}
          dataSource={getQuestionsData}
          columns={questionColumns}
        ></Table>
      </Modal>
    </React.Fragment>
  );
}
TodaysInterview.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default TodaysInterview;
