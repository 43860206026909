import React, { useState, useEffect } from 'react';
import {
  withStyles,
  Paper,
  Grid,
  TextField,
  Divider,
  Typography,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './projects.style';
import { useAlert } from "../../../../hoc/alert/alert";
import Loader from '../../../../hoc/loader'
import urls from '../../../../url'
import MediaQuery from "react-responsive";
import useFetch from '../../../../hoc/useFetch'
const Projects = ({ classes }) => {

  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [projectTitle, setProjectTitle] = useState('');
  const [projectLink, setProjectLink] = useState('');
  const [projectBreif, setProjectBreif] = useState('');
  const [projectYourRole, setProjectYourRole] = useState('');

  const [iitJeeRank, setIitJeeRank] = useState('');
  const [gateScore, setGateScore] = useState('');
  const [gmateScore, setGmateScore] = useState('');
  const [catScore, setCatScore] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [loading, setloading] = useState(false);
  const [projectDataRes, setProjectDataRes] = useState('');
  const alert = useAlert();

  const {
    // data: projectSubmitRes,
    isLoading: submittingProjectDetails,
    doFetch: fetchProjectDetails
  } = useFetch(null);

  useEffect(() => {
    if (name) {
      setloading(true);
      fetch(`${urls.submitBasicProfileApi}?type=3`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          setloading(false);
          alert.success('Successfully Recived');
          return res.json();
        }
        if (res.status !== 200) {
          setloading(false);
          alert.error('something went wrong please try again');
          return res.json();
        }
      }).then((data) => {
        setProjectDataRes(data);
      })
    }
  }, [alert, name])

  useEffect(() => {
    if (projectDataRes) {
      setIitJeeRank(projectDataRes.iit_jee_rank);
      setGateScore(projectDataRes.gate_score);
      setGmateScore(projectDataRes.gmat_score);
      setCatScore(projectDataRes.cat_percentage);
      if (typeof (projectDataRes) === 'object') {
        setProjectList(projectDataRes);
      } else {
        setProjectList(projectDataRes);
      }
    }
  }, [projectDataRes])

  function removeProjectFromList(index) {
    setProjectList((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }
  function projectAddListfunction() {
    if (!projectTitle || !projectLink || !projectBreif || !projectYourRole) {
      alert.warning('fill all the Fields');
      return;
    }
    let array = projectList;
    array.push(
      {
        'project_title': projectTitle,
        'project_link': projectLink,
        'brief_about_project': projectBreif,
        'your_role': projectYourRole
      });
    setProjectList(array);
    setProjectTitle('');
    setProjectYourRole('')
    setProjectBreif('');
    setProjectLink('');
  }
  function submitProjectDetails() {
    if (projectList.length === 0) {
      alert.warning('Enter Project Details');
      return;
    }

    const data = {
      "iit_jee_rank": iitJeeRank,
      "gate_score": gateScore,
      "gmat_score": gmateScore,
      "cat_percentage": catScore,
      "projects": projectList
    }
    fetchProjectDetails({
      url: `${urls.submitBasicProfileApi}?type=3`,
      body: data,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }

  let loader = null;
  if (submittingProjectDetails || loading) {
    loader = <Loader open />;
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <TextField
              label="IIT JEE Rank"
              margin="dense"
              fullWidth
              className="textList"
              type="number"
              value={iitJeeRank || ''}
              onChange={e => setIitJeeRank(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="GATE Score"
              margin="dense"
              fullWidth
              className="textList"
              type="number"
              value={gateScore || ''}
              onChange={e => setGateScore(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="GMAT Score"
              margin="dense"
              fullWidth
              className="textList"
              type="number"
              value={gmateScore || ''}
              onChange={e => setGmateScore(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label="CAT Percentile"
              margin="dense"
              fullWidth
              className="textList"
              type="number"
              value={catScore || ''}
              onChange={e => setCatScore(e.target.value)}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography variant='h5' style={{ textAlign: 'center' }}>Add Project Details</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Project Title"
              margin="dense"
              required
              fullWidth
              className="textList"
              value={projectTitle || ''}
              onChange={e => setProjectTitle(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TextField
              label="Project Link"
              margin="dense"
              required
              fullWidth
              className="textList"
              value={projectLink || ''}
              onChange={e => setProjectLink(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              label="Breif about the Project"
              margin="dense"
              required
              fullWidth
              className="textList"
              multiline
              row="4"
              value={projectBreif || ''}
              onChange={e => setProjectBreif(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              label="Your Role"
              margin="dense"
              required
              fullWidth
              className="textList"
              multiline
              row="4"
              value={projectYourRole || ''}
              onChange={e => setProjectYourRole(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12} style={{ textAlign: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => projectAddListfunction()}
              disabled={(!projectTitle || !projectLink || !projectBreif || !projectYourRole) ? true : false}
            >
              Add More +
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          {projectList && projectList.length === 0 &&
            <Grid item md={12} xs={12}>
              <Typography variant="h5" style={{ color: 'blue', textAlign: 'center' }}>Please Add Your Project Details</Typography>
            </Grid>
          }
          {projectList && projectList.length !== 0 &&
            <TableContainer className="role">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell float="left">
                      <Typography>S.No</Typography>
                    </TableCell>
                    <TableCell float="left">
                      <Typography>Project Title</Typography>
                    </TableCell>
                    <TableCell float="left">
                      <Typography>Project Links</Typography>
                    </TableCell>
                    <TableCell float="left">
                      <Typography>Breif of Project </Typography>
                    </TableCell>
                    <TableCell float="left">
                      <Typography>Your Role in Project </Typography>
                    </TableCell>
                    <TableCell float="left">
                      <Typography>Remove</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectList && projectList.length !== 0 && projectList.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell float="left">
                        <Typography>{index + 1}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{data.project_title}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{data.project_link}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{data.brief_about_project}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography>{data.your_role}</Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Button variant="contained" color="primary" onClick={() => removeProjectFromList(index)}>Remove</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Grid>
        <Divider className={classes.divider} />
        <Grid container>
          <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => submitProjectDetails()}
              disabled={(projectList.length === 0) ? true : false}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {loader}
    </React.Fragment>
  )
}

Projects.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Projects);