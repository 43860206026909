/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  withWidth,
  withStyles,
  Typography,
  Divider,
  Button,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import { useAlert } from '../hoc/alert';
import Loader from '../hoc/loader';
import styles from "./login-otp-form.styles";
import axios from "axios";

import urls from '../url'
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';

const LoginOtpForm = ({ classes, width: brkPoint }) => {
  const [handleEmailorNumber, setHandleEmailorNumber] = useState('')
  const [otp, setOtp] = useState('')
  const [emailOrPhone, setEmailOrPhone] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState('')
  const [confirmPassword, setConfrmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [activeStep, setActiveStep] = useState(1)
  const [LoaderOnn, setLoaderOnn] = useState(false)

  const alert = useAlert();
  const handleMobileNo=(e)=>{
    const re= /^[0-9]+$/g;
    if(handleEmailorNumber === 'MobileNo'){
    if ((e.target.value === '' || re.test(e.target.value))&&(e.target.value.length <= 10)) {
      setEmailOrPhone(e.target.value)
      
    }
  }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => {
    setShowPassword1(!showPassword1);
  };

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

 

  let loader = null;
  if (LoaderOnn) {
    loader = <Loader open />;
  }

  function SendOtpFunctionstepone() {
    if (!emailOrPhone) {
      alert.warning('Enter Email or PhoneNo')
      return
    }
    setLoaderOnn(true)
    fetch(`${urls.loginOtpSend}${handleEmailorNumber === 'MobileNo' && `?mobile_number=${emailOrPhone}` || `?email=${emailOrPhone}`}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status === 404) {
          setLoaderOnn(false)
          alert.error('Please Register in CV BOX')
        }
        if (res.status === 201) {
          setLoaderOnn(false)
          setActiveStep(2)
          alert.success('Otp is send to your give EmailId or PhoneNo')
          return res.json()
        }
        if (res.status != 201 && res.status != 404) {
          setLoaderOnn(false)
          alert.warning('somthing went wrong please try again ')
        }
      })

  }

  const OtpVerifyFunction = () => {
    if (!otp) {
      alert.warning('enter Otp');
      return;
    }
    axios.get(`${urls.loginOtpVerify}?otp=${otp}${handleEmailorNumber === 'MobileNo' && `&mobile_number=${emailOrPhone}` || `&email=${emailOrPhone}`}`, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then((response) => {
      if (response.status === 200) {
        localStorage.setItem("user", JSON.stringify(response.data));
        window.location.reload();
      }
      else {
        alert.error("invalid");
      }

    })

  };


  // function OtpVerifyFunction(){
  //   if(!otp){
  //     alert.warning('enter Otp')
  //     return
  //   }
  //   setLoaderOnn(true)
  //   fetch(`${urls.loginOtpVerify}?otp=${otp}${handleEmailorNumber === 'MobileNo' &&  `&mobile_number=${emailOrPhone}` || `&email=${emailOrPhone}`}`,{
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  // })
  // .then(res=>
  //   {
  //     console.log('response',res.json())

  //   if(res.status === 404){
  //     setLoaderOnn(false)
  //     alert.error("In valid OTP")
  // }
  // if(res.status === 200){
  //     localStorage.setItem("user", JSON.stringify(res.data));
  //     window.location.reload();
  //     setLoaderOnn(false)

  //     // return res.json()   
  // }
  // if(res.status != 200 && res.status != 404){
  //     setLoaderOnn(false)
  //     alert.warning('somthing went wrong please try again ')   
  // }
  // }).then(data => {
  //     if(data.response==="success"){
  //       setLoaderOnn(false)
  //       setActiveStep(3)
  //       alert.success("Otp Verified Successfully")
  //     }else {
  //       setLoaderOnn(false)
  //       alert.warning('Incorrect OTP')
  //     }
  // })
  // }
  const handleRadio =(e)=>{
    setHandleEmailorNumber(e.target.value);
    if(handleEmailorNumber === 'EmailId'){
      console.log(handleEmailorNumber);
      setEmailOrPhone('');
    }

  }
  const handleRadioSec =(e)=>{
    setHandleEmailorNumber(e.target.value);
    if(handleEmailorNumber === 'MobileNo'){
      console.log(handleEmailorNumber);
      setEmailOrPhone('');
    }

  }



  function SubmitChangePassword() {
    if (!confirmPassword || !password) {
      alert.warning('Fill the Fields')
      return
    }
    if (confirmPassword !== password) {
      alert.warning('Confirm Password In Not Matching')
      return
    }
    setLoaderOnn(true)
    fetch(`${urls.forgetPasswordApi}?password=${confirmPassword}&applicant_details=${emailOrPhone}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status === 404) {
          setLoaderOnn(false)
          alert.error("In valid OTP")
        }
        if (res.status === 200) {
          setLoaderOnn(false)
          alert.success('Password changed Successfully')
          window.location.href = '/';
          return res.json()
        }
        if (res.status != 200 && res.status != 404) {
          setLoaderOnn(false)
          alert.warning('somthing went wrong please try again ')
        }
      }).then(data => {

      })
  }




  //   useEffect(() => {
  //     emailRef.current.value = ""
  //     if (userData) {
  //       localStorage.setItem("user", JSON.stringify(userData));
  //       window.location.reload();
  //     }
  //   }, [userData]);

  const loginPaper = (
    <>
      {activeStep === 1 &&
        <Grid container spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%' }}
        >
          <Grid item md={8} xs={12}>
            <Radio
              checked={handleEmailorNumber === 'MobileNo'}
              // onChange={e => setHandleEmailorNumber(e.target.value)}
              onChange={handleRadio}
              value="MobileNo"
              hideradio
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'A' }}
            /> Mobile No.
            <Radio
              checked={handleEmailorNumber === 'EmailId'}
              // onChange={e => setHandleEmailorNumber(e.target.value)}
              onChange={handleRadioSec}

              value="EmailId"
              hideradio
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'B' }}
            />Email Id
          </Grid>
          <Grid item md={4} />
          {handleEmailorNumber &&
            <Grid item md={8} xs={10}>
              {handleEmailorNumber === "MobileNo" &&
                <TextField
                  label="Enter Your Mobile No."
                  margin="normal"
                  type="text"
                  variant="outlined"
                  //    style={{marginleft: "-45px",width:"170%"}}
                  className={classes.textfieldwidth}

                  value={emailOrPhone || ''}
                  // onChange={(e) => e.target.value.length < 11 && setEmailOrPhone(e.target.value) || ''}
                  onChange={handleMobileNo}
                ></TextField>}
              {handleEmailorNumber === "EmailId" &&
                <TextField
                  label="Enter Your Email Id"
                  type="Email"
                  margin="normal"
                  variant="outlined"
                  className={classes.textfieldwidth}

                  value={emailOrPhone || ''}
                  onChange={(e) => setEmailOrPhone(e.target.value)}
                ></TextField>}
            </Grid>}
          <Grid container spacing={1} align="center" style={{ marginLeft: "-52px" }}>
            <Grid item md={4} xs={1} />
            <Grid item md={3} xs={5}>
              <a href="/">
                <Button
                  color='primary'
                  variant="contained"
                  fullWidth
                  size="medium">
                  Back
                </Button>
              </a>
            </Grid>
            <Grid item md={3} xs={5}>
              <Button
                color='primary'
                variant="contained"
                fullWidth
                size="medium"
                onClick={() => { SendOtpFunctionstepone() }}
              >Next</Button>
            </Grid>
          </Grid>
        </Grid>}

      {/* Step 2 */}


      {activeStep === 2 &&
        <Grid container spacing={2} className={classes.grid}>
          <Grid item md={4} />
          <Grid item md={4} xs={12}>
            <TextField
              label="Enter OTP"
              margin="normal"
              variant="outlined"
              className={classes.textfieldwidth}

              value={otp || ''}
              onChange={(e) => e.target.value.length < 7 ? setOtp(e.target.value) : ''}
            ></TextField>
          </Grid>
          <Grid container spacing={1}>
            <Grid item md={4} xs={1} />
            <Grid item md={2} xs={5}>
              <Button
                color='primary'
                variant="contained"
                fullWidth
                size="medium"
                onClick={() => setActiveStep(1)}>
                Back
              </Button>
            </Grid>
            <Grid item md={2} xs={5}>
              <Button
                color='primary'
                variant="contained"
                fullWidth
                size="medium"
                onClick={() => { OtpVerifyFunction() }}
              >Next</Button>
            </Grid>
          </Grid>
        </Grid>}

      {/* Step 3 */}

      {activeStep === 3 &&
        <React.Fragment>
          <Grid container spacing={2} className={classes.grid}>
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <TextField
                label={handleEmailorNumber}
                margin="normal"
                variant="outlined"
                className={classes.textfieldwidth}
                disabled
                value={emailOrPhone || ''}
              ></TextField>
            </Grid>
            <Grid item md={2} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <TextField
                label="New Password"
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={password}
                onChange={e => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <TextField
                label="Confirm Password"
                margin="normal"
                type={showPassword1 ? 'text' : 'password'}
                required
                className={classes.textfieldwidth}

                value={confirmPassword || ''}
                onChange={e => setConfrmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCPassword}
                        onMouseDown={handleMouseDownCPassword}
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {password && confirmPassword && confirmPassword !== password
                ? <FormHelperText id="component-error-text" style={{ color: 'red' }}>Confirm Password should match password</FormHelperText>
                : null
              }
              {password && confirmPassword && confirmPassword === password
                ? <FormHelperText id="component-error-text" style={{ color: 'green' }}>Confirm Password matched</FormHelperText>
                : null
              }
            </Grid>
            <Grid container spacing={1}>
              <Grid item md={4} xs={1} />
              <Grid item md={2} xs={5}>
                <Button
                  color='primary'
                  variant="contained"
                  fullWidth
                  size="medium"
                  onClick={() => setActiveStep(2)}>
                  Back
                </Button>
              </Grid>
              <Grid item md={2} xs={5}>
                <Button
                  color='primary'
                  variant="contained"
                  size="large"
                  fullWidth
                  // size="medium"
                  onClick={() => { SubmitChangePassword() }}
                >Submit</Button>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>}
      {/* <Grid container justify='center' alignItems='center'>
          <Grid item xs={8}>
            <div className={classes.loginButton}>
              <Button
                color='primary'
                variant='contained'
                size='large'
                onClick={(!isOtpSend && !loginSet || !isOtpSend && !loginEmailSet) ? loginHandlerOTP : ((isOtpSend && loginSet) || (isOtpSend && loginEmailSet)) ? verifyHandlerOtp : loginHandler}
              >
                {(!isOtpSend && !loginSet || !isOtpSend && !loginEmailSet) ? 'Send Otp' : ((isOtpSend && loginSet) || (isOtpSend && loginEmailSet)) ? 'Login' : 'Login'}
              </Button>
              <a
                style={{ textAlign: "right", marginTop: "20px", textDecoration: 'none' }}
                href='/ForgetPassword'
              >
                Forgot Password?
              </a>
            </div>
          </Grid>
        </Grid> */}
      <Divider className={classes.divider} />
      {/* <Typography variant='caption' className={classes.caption}>
          To register for any opening click on
          <span>
            <a onClick={() => viewOpenigHandler("viewOpening")} style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue', textDecoration: 'underline', fontWeight: 'bold' }}>
              View opening
            </a>
          </span>

        </Typography> */}

    </>);

  return (
    <div style={{ overflow: 'hidden' }}>
      {loginPaper}
    </div>
  );
};



LoginOtpForm.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.string.isRequired
};

export default withStyles(styles)(withWidth()(LoginOtpForm));
