import { rgbToHex } from "@material-ui/core/styles";

export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: '5px 40px',
      marginTop: '15px',
    },
    paper: {
      padding: theme.spacing(2, 3, 2),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '48%',
      height:"90%",
      marginLeft:"500px",
      marginTop:"50px"
    },
    // parentdiv: {
    // },
    // childdiv:{
    //   display: "inline-block",
    // },
    paperr: {
      padding: theme.spacing(1, 2, 1),
    },
    paperMain: {
    },
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    fixed : {
      height : '245px',
      overflowY : 'scroll',
    },
    search: {
      float: 'right',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    tablepagination: {
      marginTop:'0.5%',
      marginLeft:'30px',
      // float:'left',
      width:'inherit',
      textAlign:'-webkit-right',
    },
    TableHeadtabBottom: {
      marginTop:'10px',
      paddingLeft: '20px',
      cellPadding:"10px",
      cellSpacing:'10px',
      fontFamily:' Helvetica',
      fontSize:'15px',
      color: '#FFFFFF',
      backgroundColor: rgbToHex('#ff0000 '), 
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loginButton: {
      margin: '20px 0px',
      display: 'grid',
    },
    Button: {
      borderRadius: '0px',
    },
    button: {
        margin: theme.spacing(1),
      },
      input: {
        display: 'none',
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 120,
          '&:focus': {
            width: 200,
          },
        },
      },
});
