/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  TablePagination,
  TableFooter,
  IconButton,
  Box,
  Card,
  CardContent,
  Paper,
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Modal from '../../../hoc/modal/modal'
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import styles from './applicantReview.style';
import Loader from '../../../hoc/loader';
import DownloadLink from "react-download-link";
import CloseIcon from '@material-ui/icons/Close';

// import { useAlert } from '../../../hoc/alert/alert'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ApplicantReview = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [value, setValue] = React.useState(0);
  const [Id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);

  const [profileId ,setProfileId] = useState('')

//   const alert = useAlert();
  
  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id)
    if(profileDetail[newValue].applicant_role_name === "Teacher"){
      setProfileId(2)
    }else if(profileDetail[newValue].applicant_role_name === "Coordinator"){
      setProfileId(3)
    }else if(profileDetail[newValue].applicant_role_name === "Principal"){
      setProfileId(4)
    }else if(profileDetail[newValue].applicant_role_name === "others"){
      setProfileId(9)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    !rowsPerPage && setRowsPerPage(50)
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0)
  }
  
  function lastPageChange(lastPage) {
    setPage(lastPage)
    !rowsPerPage && setRowsPerPage(50)
  }

  // const {
  //   data: fileData,
  //   isLoading: fileLoading,
  //   doFetch: fetchFile,
  // } = useFetch(null);

  const {
    data: profileDetail,
    isLoading:profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);

  const {
    data: applicantDetail,
    isLoading:applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch(null);


  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.reviererRoleListApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchProfile, name]);


  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setId(profileDetail[0].id);
      if(profileDetail[0].applicant_role_name === "Teacher"){
        setProfileId(2)
      }else if(profileDetail[0].applicant_role_name === "Coordinator"){
        setProfileId(3)
      }else if(profileDetail[0].applicant_role_name === "Principal"){
        setProfileId(4)
      }else if(profileDetail[0].applicant_role_name === "others"){
        setProfileId(9)
      }
    }
  }, [profileDetail])

  useEffect(() => {
    if (Id && name) {
      setOpen(false)
      fetchApplicant({
        url: `${urls.reviewApplicantApi}?role=${Id}&page_size=${rowsPerPage}&page=${page + 1}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [Id, fetchApplicant, name, page, rowsPerPage])

  function updateHandler(ID) {
    // setOpen(true);
    fetchApplicantDetail({
      url: `${urls.applicantFullDetailsApi}?id=${ID}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  // useEffect(()=> {
  //   if(name) {
  //     updateHandler();
  //   }
  // },[name]);

  useEffect(()=>{
    if (applicantDetail) {
     setOpen(true); 
    //  if (applicantDetail.resume) {
    //   fetchFile({
    //     url: applicantDetail.resume,
    //     method: 'get',
    //     type: 'blob',
    //     headers: {
    //       // 'Content-Type': 'application/json',
    //       // Authorization: `Bearer ${auth.personal_info.token}`,
    //     },
    //   });
    //  }
    }
  },[applicantDetail])

  // function getFileUrl() {
  //   let url;
  //   if (fileData && window && URL) {
  //     url = window.URL.createObjectURL(
  //       new Blob([fileData], { type: 'application/pdf' }),
  //     );
  //   }
  //   return url;
  // }


  const handleClose = () => {
    setOpen(false);
  };
  let loader = null;
  if (applicantsListLoading ||profileDetailLoading ||applicantDetailLoading ) {
    loader = <Loader open />;
  }

  function profileTable() {
    let applicantTable = null;
    if(applicantsList && applicantsList.result && applicantsList.result.length===0){
      applicantTable = (
        <React.Fragment>
          <Typography style={{textAlign:'center', color:'blue',marginTop:'100px'}} variant='h4'>Records Not Found</Typography>
        </React.Fragment>
      )
    }
    if (applicantsList && applicantsList.result && applicantsList.result.length) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{marginTop:'10px'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">S.No</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Contact No</TableCell>
                <TableCell align="left">Grade</TableCell>
                <TableCell align="left">Branch Interested</TableCell>
                <TableCell align="left">Subject</TableCell>
                <TableCell align="left">View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applicantsList.result.map((details, i) => (
                <TableRow key={details?.id}>
                  <TableCell align="left">{i + 1}</TableCell>
                  <TableCell align="left">{details.name || ''}</TableCell>
                  <TableCell align="left">{details.contact_no || ''}</TableCell>
                  <TableCell align="left">{details.grade || ''}</TableCell>
                  <TableCell align="left">{details.branch || ''}</TableCell>
                  <TableCell align="left">{details.subject || ''}</TableCell>
                  <TableCell align="left">
                    <Button
                      className={classes.addButton}
                      // color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => updateHandler(details.id)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* {(handleGet != null) ? */}
            <TableFooter>
              <TableRow>
                <TablePagination 
                  style={{marginTop:'15px'}}
                  colSpan={5}
                  rowsPerPageOptions={[50, 75, 100]}
                  component="div"
                  count={applicantsList && applicantsList.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'previous page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'next page',
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(applicantsList.total_pages - 1)}
                    disabled={page === (+applicantsList.total_pages - 1)}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
            {/* : null} */}
            </Paper>
        </React.Fragment>
      )
    }
    return applicantTable;
  }

  let modal = null;
  if (applicantDetail) {
    modal = (
      <Modal
        click={handleClose} large style={{ padding: '15px' }}
        open={open}
      >
        <React.Fragment >
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Typography variant='h5'>Details</Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        </div>
          <Divider className={classes.divider}/>
           <Grid container spacing={3}>
            <Grid item md={3} xs={12} className={classes.gridOne}>
              <Typography variant='h6'>Name : {(applicantDetail && applicantDetail.name) || ''}</Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridOne}>
            <Typography variant='h6'>PhoneNo : {(applicantDetail && applicantDetail.contact_no)|| ''}</Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridOne}>
            <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Email: {(applicantDetail && applicantDetail.email) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Grade: {(applicantDetail && applicantDetail.grade) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Role: {(applicantDetail && applicantDetail.applicant_role) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Position Applied: {(applicantDetail && applicantDetail.position_apply) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Branch: {(applicantDetail && applicantDetail.branch) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}>Application Date: {(applicantDetail &&applicantDetail.applicant_date) || ''}</b>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
            <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}><u>Hr Details:</u></b>
              </div>
              {/* <Divider classNames = {classes.divider}/> */}
              <div style={{ display: 'flex' }}>
    <b style={{ display: 'flex', marginTop: '7px', marginLeft:'25px' }}>Name: {(applicantDetail && applicantDetail.hr_head) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px', marginLeft:'25px' }}>Comment: {(applicantDetail && applicantDetail.comment) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px' }}><u>Recruiter Details:</u></b>
              </div>
              {/* <Divider classNames = {classes.divider}/> */}
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px', marginLeft:'25px' }}>Recruiter Name: {(applicantDetail && applicantDetail.recruiter) || ''}</b>
              </div>
              <div style={{ display: 'flex' }}>
                <b style={{ display: 'flex', marginTop: '7px', marginLeft:'25px' }}>Assigned Date: {(applicantDetail && applicantDetail.assign_date) || ''}</b>
              </div>
            </Grid>
            <Grid item md={12} className={classes.item}>
              <Table>
                <TableBody>
                <TableRow>
<TableCell align="left">Resume</TableCell>
<TableCell align="center">
{applicantDetail.applicant &&
applicantDetail.applicant.resume ? (
<a
target='_blank'
href={applicantDetail.applicant.resume || ""}
onclick="window.open(this.href); return false;"
>
{" "}
View Resume
</a>
) : (
"Resume Not Uploaded"
)}
</TableCell>
</TableRow>

                </TableBody>
              </Table>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Grid item md={12} xs={12} className={classes.gridOnee}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h6" component="h2">
                        1.MCQ 
                      </Typography>
                      {(applicantDetail && applicantDetail.applicant_mcq.score && 
                      <div>
                      <Typography variant="h6">Topic: {(applicantDetail && applicantDetail.applicant_mcq.mcq_test_name) || ''}</Typography>
                      <Typography variant="h6">Marks: {(applicantDetail && applicantDetail.applicant_mcq.score) || ''}</Typography></div>) ||
                      <Typography variant="h6">Pending</Typography>
                      }
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridOnee}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h6" component="h2">2.Written Test </Typography>
                      {(applicantDetail && applicantDetail.applicant_written.topic_name && 
                      <div>
                      <Typography variant="h6">Topic: {(applicantDetail && applicantDetail.applicant_written.topic_name) || ''}</Typography>
                      <Typography variant="h6">Marks: {(applicantDetail && applicantDetail.applicant_written.mark_scored) || ''}</Typography>
                      {(applicantDetail && applicantDetail.applicant_written.written_test_file && 
                      <Typography variant="h6">View Written Test : <a rel="noopener noreferrer"  target="_blank" href={applicantDetail && applicantDetail.applicant_written.written_test_file}>Click to View </a></Typography>)}
                      {(applicantDetail && applicantDetail.applicant_written.written_test_file === '' && <Typography variant="h6">File Not Uploaded :</Typography>)}
                      </div>) ||
                      <Typography variant="h6">Pending</Typography>
                      }
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={12} xs={12} className={classes.gridOnee}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography variant="h6" component="h2"> 3.Spoken Test </Typography>
                      {(applicantDetail && applicantDetail.applicant_spoken.topic_name && 
                      <div>
                      <Typography variant="h6">Topic: {(applicantDetail && applicantDetail.applicant_spoken.topic_name) || ''}</Typography>
                      <Typography variant="h6">Marks: {(applicantDetail && applicantDetail.applicant_spoken.mark_scored) || ''}</Typography>
                      {(applicantDetail &&applicantDetail && applicantDetail.applicant_spoken.spoken_audio &&
                      <Typography variant="h6">View Spoken Test : 
                      <a rel="noopener noreferrer"  target="_blank" href={applicantDetail && applicantDetail && applicantDetail.applicant_spoken.spoken_audio}>Click to View </a>
                      </Typography>)}
                      {(applicantDetail && applicantDetail && applicantDetail.applicant_spoken.spoken_audio === '' && <Typography variant="h6">File Not Uploaded :</Typography>)}
                      </div>) ||
                      <Typography variant="h6">Pending</Typography>
                      }
                    </CardContent>
                  </Card>
                </Grid>
                {applicantDetail && applicantDetail.applicant_f2f && applicantDetail.applicant_f2f.map((item,index)=>(
                 <Grid item md={12} xs={12} className={classes.gridOnee} key={index}>
                 <Card className={classes.card}>
                   <CardContent>
                     <Typography variant="h6" component="h2">{4+index}: {item.round}</Typography>
                     <Typography variant="h6" component="h2">Marks : {item.mark_scored || 'pending'}</Typography>
                   </CardContent>
                 </Card>
               </Grid>
                ))}

                </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </Modal>
    )
  }
  return (
    <React.Fragment>
      <Typography variant="h4">Applicant Review</Typography>
      <Divider className={classes.divider} />
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {profileDetail && profileDetail.length && profileDetail.map((role, i) => (
              <Tab key={role.id} label={role.applicant_role_name} />
            ))}
          </Tabs>
        </AppBar>
        {profileTable()}
      </div>
      {modal}
      {loader}
    </React.Fragment>
  )
}

ApplicantReview.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ApplicantReview);