import React, { useState, useEffect } from "react";
import tinymce from "tinymce";
import MediaQuery from "react-responsive";
// import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableFooter,
  IconButton,
  Select,
  TextField,
  InputLabel,
  Input,
  FormControl,
  MenuItem,
  TableContainer,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import styles from "./position.style";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";

const PositionReport = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [positionValue, setPositionValue] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const [branch, setBranch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] =useState(false);

  const [roleName, setRoleName] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [add, setAdd] = useState(0);
  const [deleted, setDeleted] = useState(0);
  const [checked, setChecked] = useState("");
  const [check, setCheck] = useState(true);

  const [gradeCheck, setGradeCheck] = useState("");
  const [subjectCheck, setSubjectCheck] = useState("");
  const [status, setStatus] = useState("");
  const [bottomHRef, setBottomHRef] = useState("");
  const [dataReport ,setDataReport] = useState([]);

  const alert = useAlert();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }
  function lastPageChange(lastPage) {
    console.log(lastPage);
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }
  const positionType = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  const {
    data: grades,
    isLoading: gradesLoading,
    doFetch: fetchGrades,
  } = useFetch([], { suppressAlert: true });
  const {
    data: roleData,
    isLoading: gettingRoleList,
    doFetch: FetchRoles,
  } = useFetch(null);
  const {
    data: roles,
    isLoading: rolesLoading,
    doFetch: fetchRoles,
  } = useFetch([], { suppressAlert: true });

  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity,
  } = useFetch([], { suppressAlert: true });
  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });
  const {
    data: subjects,
    isLoading: subjectsLoading,
    doFetch: fetchSubjects,
  } = useFetch([], { suppressAlert: true });
  // const {
  //   data: dataReport,
  //   isLoading: dataReportLoading,
  //   doFetch: fetchDataReport,
  // } = useFetch(null);

  useEffect(() => {
    if (gradeCheck) {
      fetchSubjects({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeCheck}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [gradeCheck, fetchSubjects]);

  useEffect(() => {
    const start_date = startDate + 'T00:00:00';
      const end_date = endDate + 'T23:59:59';
    setBottomHRef([
      {
        csv: `${urls.positionReport}?position_type=${positionValue}&start_date=${start_date}&end_date=${end_date}&role=${checked}&active=${check}&branch=${branch}&city=${checkedLoc}&subject=${subjectCheck}&grade=${gradeCheck}&download=true`,
      },
    ]);
  }, [
    setBottomHRef,
    positionValue,
    checked,
    status,
    startDate,
    endDate,
    check,
    branch,
    subjectCheck,
  ]);

  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [fetchRoles, fetchCity]);
  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [checkedLoc]);
  const [fetchingRoleData, setFetchingRoleData] = useState();
  // useEffect(() => {
  //   if (fetchingRoleData) {
  //     fetchDataReport({
  //       url: `${urls.positionReport}?search=${fetchingRoleData}&page=${page +
  //         1}&page_size=${rowsPerPage || 50}`,
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${auth.personal_info.token}`,
  //       },
  //     });
  //   }
  // }, [fetchingRoleData, page, rowsPerPage]);
  const fetchRoleData = (e) => {
    setFetchingRoleData(e.target.value);
  };

  useEffect(() => {
    if (positionValue) {
      fetchRoles({
        url: `${urls.RoleListApi}?position_type=${positionValue}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
      fetchGrades({
        url: `${urls.newGrade}?position_type=${positionValue}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [fetchRoles, positionValue]);
  let loader = null;
  if (loading) {
    loader = <Loader open />;
  }
  const handleChange = (event) => {
    setCheck(event.target.checked);
  };

  function handleBranch(e) {
    let eventIndex = e.target.value[e.target.value.length - 1];
    if (eventIndex === branchList[0].id) {
      setBranch([eventIndex]);
    } else {
      if (e.target.value[0] === branchList[0].id) {
        setBranch(e.target.value.shift());
      }
      setBranch(e.target.value);
    }
  }
  function addRole() {
    if (positionValue && startDate && endDate) {
      const start_date = startDate + 'T00:00:00';
      const end_date = endDate + 'T23:59:59';
      fetch(
         `${urls.positionReport
          }?position_type=${positionValue}&start_date=${start_date}&end_date=${end_date}&role=${checked}&active=${check}&branch=${branch}&city=${checkedLoc}&subject=${subjectCheck}&grade=${gradeCheck}&page=${page +
          1}&page_size=${rowsPerPage || 50}`,{
        method: "GET",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      }
      ).then((response) => response.json())
      .then((data) => {
        setDataReport(data);
        setLoading(false);

      })
      .catch(() => {
        alert.error("Failed to Complete Action");
      });
    }
  }
  // useEffect(() => {
  //   addRole();
  // }, [positionValue, page, rowsPerPage]);


  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  function roleTable() {
    let applicantTable = null;
    if (dataReport && dataReport.results && dataReport.results.length === 0) {
      applicantTable = (
        <React.Fragment>
          <Typography
            style={{ textAlign: "center", color: "blue", marginTop: "100px" }}
            variant="h4"
          >
            Records Not Found
          </Typography>
        </React.Fragment>
      );
    }
    if (dataReport && dataReport.results && dataReport.results.length) {
      applicantTable = (
        <React.Fragment>
          <MediaQuery minWidth={451}>
            <Paper style={{ marginTop: "10px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>S.No.</strong>
                    </TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>Position Type</strong>
                    </TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>Title</strong>
                    </TableCell>

                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>Experience</strong>
                    </TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>Skill</strong>
                    </TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>Salary</strong>
                    </TableCell>
                    {/* <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>Description</strong>
                    </TableCell> */}
                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong>Create Date</strong>
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataReport.results.map((details, i) => (
                    <TableRow key={details.id}>
                      <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>{details.position_type}</TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>{details.title}</TableCell>

                      <TableCell align="center" style={{ border: "1px solid black"}}>{details.experience}</TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        {details.skill}
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        {details.salary}
                      </TableCell>
                      {/* <TableCell align="center" style={{ border: "1px solid black"}}>
                        
                          {details.description}
                      </TableCell> */}
                      <TableCell align="center" style={{ border: "1px solid black"}}>

                        {details.create_date.split('T')[0]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ marginTop: "15px" }}
                    colSpan={5}
                    labelDisplayedRows={() =>
                      `Page ${page + 1} of ${+dataReport.total_pages}`
                    }
                    rowsPerPageOptions={[50, 75, 100]}
                    component="div"
                    count={+dataReport.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => lastPageChange(dataReport.total_pages - 1)}
                      disabled={page === +dataReport.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
              {/* : null} */}
            </Paper>
          </MediaQuery>
          <MediaQuery maxWidth={450}>
            <Paper style={{ marginTop: "10px" }}>
              <TableContainer style={{ width: "230px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <strong>S.No.</strong>
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <strong>Name</strong>
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <strong>Contact No.</strong>
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <strong>Applicant Role</strong>
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <strong>Register Date</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataReport.results.map((details, i) => (
                      <TableRow key={details.id}>
                        <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                        <TableCell align="center" style={{ border: "1px solid black"}}>{details.name}</TableCell>
                        <TableCell align="center" style={{ border: "1px solid black"}}>{details.contact_no}</TableCell>
                        <TableCell align="center" style={{ border: "1px solid black"}}>
                          {details.applicant_role}
                        </TableCell>
                        <TableCell align="center" style={{ border: "1px solid black"}}>
                          {details.register_date}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      style={{ marginTop: "15px" }}
                      colSpan={5}
                      rowsPerPageOptions={[50, 75, 100]}
                      component="div"
                      count={dataReport && dataReport.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                        "aria-label": "previous page",
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page",
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <TableCell>
                      <IconButton
                        onClick={firstPageChange}
                        disabled={page === 0 || page === 1}
                      >
                        <FirstPageIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          lastPageChange(dataReport.total_pages - 1)
                        }
                        disabled={page === +dataReport.total_pages - 1}
                      >
                        <LastPageIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </TableContainer>
              {/* : null} */}
            </Paper>
          </MediaQuery>
        </React.Fragment>
      );
    }
    return applicantTable;
  }
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item md={3}>
          <Typography
            className="headings"
            style={{ whiteSpace: "nowrap" }}
            variant="h4"
          >
            <strong>Position Report</strong>
          </Typography>
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={3}></Grid>
        {/* <Grid item md={3}>
          <TextField
            id="search-bar"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            label="i'm looking for..."
            variant="outlined"
            size="small"
            name="subject"
            autoComplete="on"
            onChange={fetchRoleData}
          />
        </Grid> */}
      </Grid>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <Typography>
              Position Type<strong style={{ color: "red" }}>*</strong>
            </Typography>
            <Select
              required
              fullWidth
              margin="dense"
              value={positionValue || ""}
              onChange={(e) => setPositionValue(e.target.value)}
              variant="outlined"
            >
              {positionType.map((option) => (
                <MenuItem key={option.id} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>
              Role<strong style={{ color: "red" }}>*</strong>
            </Typography>
            <Select
              required
              fullWidth
              // multiple
              margin="dense"
              value={checked || ""}
              onChange={(e) => setChecked(e.target.value)}
              variant="outlined"
            >
              {roles &&
                roles &&
                roles.length > 0 &&
                roles.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.id}
                    name={option.id}
                  >
                    {option.applicant_role_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>City</Typography>
            <Select
              required
              fullWidth
              // multiple
              margin="dense"
              value={checkedLoc || ""}
              onChange={(e) => setCheckedLoc(e.target.value)}
              variant="outlined"
            >
              {cityList &&
                cityList.length !== 0 &&
                cityList.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.id}
                    name={option.id}
                  >
                    {option.city_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>Branch</Typography>
            <Select
              required
              fullWidth
              // multiple
              margin="dense"
              value={branch || ""}
              onChange={handleBranch}
              variant="outlined"
            >
              {branchList &&
                branchList.length !== 0 &&
                branchList.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.id}
                    name={option.id}
                  >
                    {option.branch_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>{positionValue=="Academic" ?"Grade":"Level"}</Typography>
            <Select
              required
              fullWidth
              margin="dense"
              value={gradeCheck || ""}
              onChange={(e) => setGradeCheck(e.target.value)}
              variant="outlined"
            >
              {grades &&
                
                grades.map((option) => (
                  <MenuItem key={option.id} value={option.id} name={option.id}>
                    {option.grade_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>{positionValue=="Academic" ?"Subject":"Topic"}</Typography>
            <Select
              required
              fullWidth
              margin="dense"
              value={subjectCheck || ""}
              onChange={(e) => setSubjectCheck(e.target.value)}
              variant="outlined"
            >
              {subjects &&
                subjects &&
                subjects.length > 0 &&
                subjects.map((option) => (
                  <MenuItem
                    key={option.subject.id}
                    value={option.subject.id}
                    name={option.subject.id}
                  >
                    {option.subject.subject_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>

          <Grid item md={3} xs={12} style={{ marginTop: "21px" }}>
            <FormControlLabel
              control={<Checkbox checked={check} onChange={handleChange} />}
              label="Active Users"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>Start Date<strong style={{ color: "red" }}>*</strong></Typography>

            <TextField
              margin="dense"
              className={classes.textField}
              required
              fullWidth
              onChange={(e) => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || " "}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>End Date<strong style={{ color: "red" }}>*</strong></Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={(e) => handleEndDateChange(e.target.value)}
              type="date"
              value={endDate || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item md={1} xs={12} style={{ marginTop: "24px" }}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              // size='large'
              margin="dense"
              onClick={addRole}
              disabled={startDate && endDate ? false : true}
            >
              Get
            </Button>
          </Grid>
          <Grid item md={1} xs={12} style={{ marginTop: "24px" }}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              // size='large'
              margin="dense"
              href={bottomHRef && bottomHRef[0].csv}
              disabled={startDate && endDate ? false : true}
            >
              CSV
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Divider className={classes.divider} />
      <Paper>{roleTable()}</Paper>
      {loader}
    </React.Fragment>
  );
};
PositionReport.propTypes = {};
export default withStyles(styles)(PositionReport);
