export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: '5px 40px',
      marginTop: '15px',
    },
    search: {
      float: 'right',
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paperrrrr:{
      // boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: true,
    },
    paper: {
      position: 'absolute',
      width: '60%',
      marginLeft: '15%',
      backgroundColor: theme.palette.background.paper,
    //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: true,
      // maxHeight: '400px',
    },
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    textField:{
      width:'100%'
    },
    button:{
      marginTop:'2%',
    }
  });
  