import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Typography,
  message,
  DatePicker,
  Tag,
} from "antd";
import urls from "../../../../url";
import axios from "axios";
import { useForm } from "antd/es/form/Form";
import {
  SyncOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import dayjs from "dayjs";
import ReplacementFormModal from "./replacementFormModal";

const MrfFormNew = ({ location }) => {
  const [mrfForm] = useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const { Title } = Typography;
  const history = useHistory();
  const rowData = location?.state ? location?.state?.rowData : null;
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const [mrfType, setMrfType] = useState();
  const [dropDownsData] = useState({
    genderPreferenceList: [
      { id: "male", value: "Male" },
      { id: "female", value: "Female" },
      { id: "any", value: "No Preference" },
    ],
    priorityTypeList: [
      { id: 1, value: "P1" },
      { id: 2, value: "P2" },
      { id: 3, value: "P3" },
      { id: 4, value: "P4" },
      { id: 5, value: "P5" },
    ],
    minimumQualificationList: [
      { id: "12th_pass", value: "12th Pass" },
      { id: "graduate_non_technical", value: "Graduate Non Technical" },
      { id: "graduate_technical", value: "Graduate Technical" },
      {
        id: "post_graduate_non_technical",
        value: "Post Graduate Non Technical",
      },
      { id: "post_graduate_technical", value: "Post Graduate Technical" },
    ],
    jobTypeList: [
      { id: "Intern", value: "Intern" },
      { id: "Part Time", value: "Part Time" },
      { id: "Full Time", value: "Full Time" },
    ],
    mrfTypeList: [
      { id: "new_hire", value: "New Hire" },
      { id: "replacement", value: "Replacement" },
      { id: "buffer", value: "Buffer" },
    ],
    salaryRangeList: [
      { id: "1-3", value: "1-3 LPA" },
      { id: "3-5", value: "3-5 LPA" },
      { id: "5-7", value: "5-7 LPA" },
      { id: "7-10", value: "7-10 LPA" },
      { id: "10-12", value: "10-12 LPA" },
      { id: "12-15", value: "12-15 LPA" },
      { id: "15-18", value: "15-18 LPA" },
      { id: "18-20", value: "18-20 LPA" },
      { id: "20+", value: "20+ LPA" },
    ],

    experienceList: [
      { id: "fresher", value: "fresher" },
      { id: "less than one year", value: "less than one year" },
      { id: "1-3", value: "1-3 Years" },
      { id: "3-5", value: "3-5 Years" },
      { id: "5-7", value: "5-7 Years" },
      { id: "7-9", value: "7-9 Years" },
      { id: "9-11", value: "9-11 Years" },
      { id: "11-13", value: "11-13 Years" },
      { id: "13-15", value: "13-15 Years" },
    ],
  });
  const [designationName, setDesignationName] = useState();
  const [jobDescriptionFile, setJobDescriptionFile] = useState();
  const [jobDescriptionFileLink, setJobDescriptionFileLink] = useState();
  const [newHireFile, setNewHireFile] = useState("");
  const [newHireFileLink, setNewHireFileLink] = useState("");
  const [jdDescriptionText, setJobDescriptionText] = useState();
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [genderPreferenceList] = useState(dropDownsData?.genderPreferenceList);
  const [experienceList] = useState(dropDownsData?.experienceList);
  const [priorityTypeList] = useState(dropDownsData?.priorityTypeList);
  const [minimumQualificationList] = useState(
    dropDownsData?.minimumQualificationList
  );
  const [salaryRangeList] = useState(dropDownsData?.salaryRangeList);
  const [jobTypeList] = useState(dropDownsData?.jobTypeList);
  const [mrfTypeList] = useState(dropDownsData?.mrfTypeList);
  const [locationList, setLocationList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [replacementArray, setReplacementArray] = useState([]);
  const [replacementModal, setReplacementModal] = useState(false);

  console.log("rowData", rowData);

  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`)
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {});
  };
  const fetchDesignationList = (params = {}) => {
    axios
      .get(`${urls.designationListApi}`, {
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {});
  };
  const fetchLocationList = () => {
    axios
      .get(`${urls.cityList}`)
      .then((response) => {
        if (response.status === 200) {
          setLocationList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {});
  };
  const fetchBranchList = (params = {}) => {
    axios
      .get(`${urls.branchCityMapping}`, {
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setBranchList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {});
  };

  const fetchAcademicYearsList = (params = {}) => {
    axios
      .get(`${urls.academicYearsList}`, {
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setAcademicYearList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  useEffect(() => {
    fetchLocationList();
    fetchPositionTypesList();
    fetchAcademicYearsList();
    if (rowData === null) {
      mrfForm.setFieldsValue({
        employee_name: userData?.personal_info?.first_name,
      });
    }
  }, []);
  useEffect(() => {
    // if user comes from edit through mrf list
    if (rowData) {
      mrfForm.setFieldsValue(rowData);
      if (rowData?.mrf_type == "replacement" && rowData?.replacement_json) {
        setReplacementArray(JSON.parse(rowData?.replacement_json));
      }
      mrfForm.setFieldValue("new_hire_doc", rowData?.file);
      setJobDescriptionFileLink(rowData?.description_file);
      setNewHireFileLink(rowData?.file);
      // setNewHireFile(rowData?.file);
      fetchDesignationList({
        position_type: rowData?.position_type_fk,
      });
      fetchBranchList({
        city_id: rowData?.location,
      });
      setJobDescriptionText(rowData?.description);
      setMrfType(rowData?.mrf_type);
    }
  }, [rowData]);

  const handlePositionTypesChange = () => {
    mrfForm.setFieldsValue({
      department_fk: null,
    });
    const position_type = mrfForm.getFieldsValue()?.position_type_fk;
    fetchDesignationList({
      position_type: position_type,
    });
  };

  const handleCityChange = () => {
    mrfForm.setFieldsValue({
      branch: null,
    });
    const locationId = mrfForm.getFieldsValue().location;
    fetchBranchList({
      city_id: locationId,
    });
  };

  // invoke it if you want to enable multi-select for branches dropdown
  const handleBranches = () => {
    const branchesSelected = mrfForm.getFieldsValue()?.branch;
    if (branchesSelected.some((item) => item === "All")) {
      mrfForm.setFieldsValue({
        branch: branchList.map((item) => item.id),
      });
    }
  };

  const handleRaiseMrf = () => {
    if (mrfType === "replacement") {
      if (
        replacementArray?.filter(
          (each) =>
            !each?.name ||
            !each?.file ||
            !each?.erp ||
            !each?.exit_date ||
            !each?.joining_date
        )?.length > 0
      ) {
        setReplacementModal(true);
        return message.error("All details are necessary!");
      }
    }
    const values = mrfForm.getFieldsValue();
    const formData = new FormData();
    formData.append("position", values?.position ?? null);
    formData.append("position_type_fk", values?.position_type_fk ?? null);
    formData.append("department_fk", values?.department_fk ?? null);
    formData.append("designation", designationName ?? null);
    formData.append("experience", values?.experience ?? "");
    formData.append("priority", values?.priority ?? null);
    formData.append(
      "minimum_qualification",
      values?.minimum_qualification ?? ""
    );
    formData.append("no_of_vacancies", values?.no_of_vacancies ?? null);
    formData.append("salary", values?.salary ?? "");
    formData.append("description", jdDescriptionText ?? "");
    formData.append("skill", values?.skill ?? "");
    formData.append("job_type", values?.job_type);
    formData.append("mrf_type", values?.mrf_type);
    // if (mrfType === "replacement") {
    //   formData.append(
    //     "mrf_replacement_emp_name",
    //     values?.mrf_replacement_emp_name
    //   );
    //   formData.append("mrf_replacement_emp_id", values?.mrf_replacement_emp_id);
    // }
    formData.append("remark", values?.remark ?? "");
    formData.append("branch", values?.branch ?? null);
    formData.append("location", values?.location ?? null);
    formData.append("is_active", "true");
    formData.append("status", "pending");
    formData.append("employee", userData?.personal_info?.user_id);
    if (jobDescriptionFile) {
      formData.append("description_file", jobDescriptionFile ?? null);
    }
    formData.append("academic_year_fk", values?.academic_year_fk ?? "");
    setLoading(true);
    if (replacementArray?.length > 0) {
      formData.append("replacement_json", JSON.stringify(replacementArray));
    }
    if (newHireFile) {
      formData.append("file", newHireFile);
    }

    if (rowData) {
      axios
        .put(`${urls?.mrfForm}${rowData?.id}/`, formData)
        .then((response) => {
          if (response?.status === 200) {
            message.success("MRF Updated Successfully");
            history.push("/mrf_list");
          }
        })
        .catch((error) => {
          message.error("Something Went Wrong. Please Try Again");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post(`${urls?.mrfForm}`, formData)
        .then((response) => {
          if (response?.status === 201) {
            message.success("MRF Submitted Successfully");
            history.push("/mrf_list");
          }
        })
        .catch((error) => {
          message.error("Something Went Wrong. Please Try Again");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleUpload = (e) => {
    if (e.target.files[0]) {
      const allowedExtensions = [".pdf"];
      const fileExtension = e.target.files[0]?.name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedExtensions?.includes(`.${fileExtension}`)) {
        message.error("Document must be in PDF format only");
        mrfForm.setFieldValue({ description_file: null });
        e.target.files = null;
        return;
      }
      if (e.target.files[0].size > 5242880) {
        message.error("File Size should be less than 5MB");
        mrfForm.setFieldValue({ description_file: null });
      } else {
        setJobDescriptionFile(e.target.files[0]);
        setJobDescriptionFileLink(null);
      }
    } else {
      setJobDescriptionFile(null);
    }
  };

  const handleNewHireFileUpload = (e) => {
    if (e.target.files[0]) {
      // const allowedExtensions = [
      //   ".pdf",
      //   ".doc",
      //   ".docx",
      //   ".jpg",
      //   ".jpeg",
      //   ".png",
      // ];
      // const fileExtension = e.target.files[0]?.name
      //   .split(".")
      //   .pop()
      //   .toLowerCase();
      // if (!allowedExtensions?.includes(`.${fileExtension}`)) {
      //   message.error("Document must be in PDF/Docs format only");
      //   mrfForm.setFieldValue({ description_file: null });
      //   e.target.files = null;
      //   return;
      // }
      if (e.target.files[0].size > 2242880) {
        message.error("File Size should be less than 2MB");
        mrfForm.setFieldValue({ new_hire_doc: null });
      } else {
        setNewHireFile(e.target.files[0]);
        setNewHireFileLink(null);
      }
    } else {
      setNewHireFile(null);
    }
  };

  const handleVacancy = (number) => {
    if (mrfType == "replacement") {
      let arr = Array.from({ length: number })?.map((each, ind) => {
        return {
          index: ind,
          name: "",
          erp: "",
          file: "",
        };
      });
      setReplacementArray(arr);
      setReplacementModal(true);
    }
  };

  const handleReplacementValues = (index, name, value) => {
    let arr = replacementArray;
    arr[index][name] = value;
    setReplacementArray([...arr]);
  };

  const handleClearVacancy = () => {
    setReplacementModal(false);
    setReplacementArray([]);
    mrfForm.resetFields(["no_of_vacancies"]);
  };

  const positionTypeOptions = positionTypeList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.position_type}
      </Option>
    );
  });
  const designationOptions = designationList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id} name={each?.name}>
        {each?.name}
      </Option>
    );
  });
  const genderPreferenceOptions = genderPreferenceList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const experienceOptions = experienceList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const priorityTypeOptions = priorityTypeList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const minimumQualificationOptions = minimumQualificationList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const salaryRangeOptions = salaryRangeList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const jobTypeOptions = jobTypeList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const mrfTypeOptions = mrfTypeList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const locationOptions = locationList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.city_name}
      </Option>
    );
  });
  const branchOptions = branchList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.branch_name}
      </Option>
    );
  });

  const academicYearOptions = academicYearList.map((each) => {
    return each?.status ? (
      <Option key={each?.id} value={each?.id}>
        {each?.academic_year}
      </Option>
    ) : (
      ""
    );
  });

  return (
    <>
      <Card>
        <Typography className="page_title">MRF Form</Typography>
        <Form
          id="hiringForm"
          layout="vertical"
          form={mrfForm}
          onFinish={() => handleRaiseMrf()}
        >
          <Row align="middle" gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="position"
                label="Position Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Position Name",
                  },
                ]}
              >
                <Input
                  maxLength={50}
                  showCount
                  placeholder="Enter Position Name"
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              {" "}
              <Form.Item
                name="position_type_fk"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Position Type"
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handlePositionTypesChange}
                >
                  {positionTypeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              {" "}
              <Form.Item
                name="department_fk"
                label="Designation"
                rules={[
                  {
                    required: true,
                    message: "Please Select Designation",
                  },
                ]}
              >
                <Select
                  placeholder="Select Designation"
                  onChange={(e, val) => {
                    setDesignationName(val?.name);
                  }}
                >
                  {designationOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="academic_year_fk"
                label="Academic Year"
                rules={[
                  {
                    required: true,
                    message: "Please Select Academic Year",
                  },
                ]}
              >
                <Select placeholder="Select Academic Year" allowClear>
                  {academicYearOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item name="experience" label="Experience Requirements">
                <Select placeholder="Select Experience Requirements" allowClear>
                  {experienceOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="priority"
                label="Priority Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Priority Type",
                  },
                ]}
              >
                <Select placeholder="Select Priority Type">
                  {priorityTypeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="minimum_qualification"
                label="Minimum Qualification"
              >
                <Select placeholder="Select Minimum Qualification" allowClear>
                  {minimumQualificationOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="mrf_type"
                label="MRF Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select MRF Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select MRF Type"
                  onChange={(e, val) => {
                    setMrfType(val?.value);
                    mrfForm.resetFields(["no_of_vacancies"]);
                    setReplacementArray([]);
                  }}
                >
                  {mrfTypeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Row
                style={{ padding: "0 0 8px", margin: 0 }}
                justify={"space-between"}
              >
                <div>
                  <span style={{ color: "red" }}>*</span> No of Vacancy
                </div>
                {replacementArray?.length > 0 && (
                  <Tag
                    onClick={() => setReplacementModal(true)}
                    color={"blue-inverse"}
                    className="th-pointer"
                  >
                    Replacement Details
                  </Tag>
                )}
              </Row>
              <Form.Item
                name="no_of_vacancies"
                rules={[
                  {
                    required: true,
                    message: "Please Enter No of Vacancy",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value > 10) {
                        return Promise.reject(
                          new Error("Maximum vacancies allowed: 10")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber
                  // disabled={
                  //   rowData && rowData?.mrf_type === "replacement"
                  //     ? true
                  //     : false
                  // }
                  placeholder="Enter No of Vacancy"
                  min={1}
                  onChange={handleVacancy}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {mrfType === "new_hire" && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <Row>
                  <Col span={18}>
                    <Form.Item
                      name="new_hire_doc"
                      label="New Hire Supported Documents (Max size 2MB)"
                      rules={[
                        {
                          required: true,
                          message: "Please upload document",
                        },
                      ]}
                    >
                      <Row>
                        <Space>
                          <label for="doc-upload">
                            <Col
                              style={{
                                color: "#fff",
                                backgroundColor: "#1677ff",
                                fontSize: "14px",
                                height: "24px",
                                padding: "0px 7px",
                                borderRadius: "4px",
                                cursor: "pointer",
                              }}
                            >
                              <CloudUploadOutlined
                                style={{ marginRight: "5px" }}
                              />
                              Browse
                            </Col>
                          </label>
                          <Input
                            type="file"
                            id="doc-upload"
                            // accept="application/pdf"
                            onChange={(e) => handleNewHireFileUpload(e)}
                            style={{ display: "none" }}
                          />
                        </Space>
                        <Space>
                          {newHireFile && (
                            <span
                              onClick={() =>
                                window.open(
                                  URL.createObjectURL(newHireFile),
                                  "_blank"
                                )
                              }
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {newHireFile?.name}
                            </span>
                          )}
                          {newHireFileLink && (
                            <a
                              href={newHireFileLink}
                              target="_blank"
                              style={{
                                color: "blue",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              View Document
                            </a>
                          )}
                        </Space>
                        {newHireFile && (
                          <Col span={1} style={{ marginLeft: "10px" }}>
                            <DeleteOutlined
                              style={{
                                fontSize: "20px",
                                color: "red",
                                cursor: "pointer",
                              }}
                              onClick={() => setNewHireFile(null)}
                            />
                          </Col>
                        )}
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            )}
            {/* {replacementArray?.map((replacement, index) => (
              <>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_name_${index}`}
                    label={`Replacement Name ${index + 1}`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        handleReplacementValues(index, "name", e.target.value);
                      }}
                      placeholder="Enter Replacement Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_erp_${index}`}
                    label={`Replacement ERP ${index + 1}`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        handleReplacementValues(index, "erp", e.target.value);
                      }}
                      placeholder="Enter Replacement ERP"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_file_${index}`}
                    label={`File ${index + 1}`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        handleReplacementValues(index, "file", e.target.value);
                      }}
                      placeholder="Enter Replacement File"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name={`replacement_exit_date_${index}`}
                    label={`File ${index + 1}`}
                    rules={[
                      { required: true, message: "This field is required!" },
                    ]}
                  >
                    <DatePicker
                      onChange={(e) => {
                        handleReplacementValues(
                          index,
                          "exit_date",
                          dayjs(e).format("YYYY-MM-DD")
                        );
                      }}
                      style={{ width: "100%" }}
                      placeholder="Enter Exit Date"
                    />
                  </Form.Item>
                </Col>
              </>
            ))} */}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item name="salary" label="Salary Range">
                <Select placeholder="Select Salary Range" allowClear>
                  {salaryRangeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item name="skill" label="Skills Required">
                <Input
                  maxLength={50}
                  showCount
                  placeholder="Enter Skills Required"
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="job_type"
                label="Job Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Job Type",
                  },
                ]}
              >
                <Select placeholder="Select Job Type">{jobTypeOptions}</Select>
              </Form.Item>
            </Col>
            {/* {mrfType === "replacement" && (
              <>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name="mrf_replacement_emp_name"
                    label="Replacement For Employee Name"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Employee Name",
                      },
                    ]}
                  >
                    <Input
                      maxLength={50}
                      showCount
                      placeholder="Enter Employee Name"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item
                    name="mrf_replacement_emp_id"
                    label="Replacement For Employee Erp"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Employee Erp",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value && value.toString().length !== 11) {
                            return Promise.reject(
                              new Error("Please Enter a Valid Erp")
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      placeholder="Enter Employee Erp"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </>
            )} */}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="location"
                label="Location"
                rules={[
                  {
                    required: true,
                    message: "Please Select Location",
                  },
                ]}
              >
                <Select
                  placeholder="Select Location"
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  // onChange={(e) => setLocationId(e)}
                  onChange={handleCityChange}
                >
                  {locationOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="branch"
                label="Branch"
                rules={[
                  {
                    required: true,
                    message: "Please Select Branch",
                  },
                ]}
              >
                <Select
                  // mode="multiple"
                  // maxTagCount={4}
                  // allowClear
                  placeholder="Select Branch"
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  // onChange={handleBranches}
                >
                  {/* <Option key="All" value="All">
                          Select All
                        </Option> */}
                  {branchOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Form.Item
                name="employee_name"
                label="MRF Raised By"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Name",
                  },
                ]}
              >
                <Input placeholder="Enter Your Name" disabled />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Row>
                <Col span={18}>
                  <Form.Item
                    name="descriptionfile"
                    label="Job Description File (Max size 5MB only PDF)"
                  >
                    <Row>
                      <Space>
                        <label for="doc-upload">
                          <Col
                            style={{
                              color: "#fff",
                              backgroundColor: "#1677ff",
                              fontSize: "14px",
                              height: "24px",
                              padding: "0px 7px",
                              borderRadius: "4px",
                              cursor: "pointer",
                            }}
                          >
                            <CloudUploadOutlined
                              style={{ marginRight: "5px" }}
                            />
                            Browse
                          </Col>
                        </label>
                        <Input
                          type="file"
                          id="doc-upload"
                          accept="application/pdf"
                          onChange={(e) => handleUpload(e)}
                          style={{ display: "none" }}
                        />
                      </Space>
                      <Space>
                        {jobDescriptionFile && (
                          <span
                            onClick={() =>
                              window.open(
                                URL.createObjectURL(jobDescriptionFile),
                                "_blank"
                              )
                            }
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {jobDescriptionFile?.name}
                          </span>
                        )}
                        {jobDescriptionFileLink && (
                          <a
                            href={jobDescriptionFileLink}
                            target="_blank"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            View Document
                          </a>
                        )}
                      </Space>
                      {jobDescriptionFile && (
                        <Col span={1} style={{ marginLeft: "10px" }}>
                          <DeleteOutlined
                            style={{
                              fontSize: "20px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => setJobDescriptionFile(null)}
                          />
                        </Col>
                      )}
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <Form.Item
                rules={[{ message: "Remarks is required!", required: true }]}
                name="remark"
                label="Remarks"
              >
                <TextArea
                  rows={3}
                  maxLength={1500}
                  showCount
                  placeholder="Enter Remarks"
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              {" "}
              <Form.Item label="Job Description" labelCol={{ span: 24 }}>
                <Editor
                  apiKey="c03cc5kmzse4y7i90fwrbswwrzl9g3rxbtd23ohrzt8pcd1k"
                  init={{
                    height: 200,
                    plugins: "link image code table",
                    toolbar:
                      "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | outdent indent | code", // Add 'table' to the toolbar
                  }}
                  value={jdDescriptionText}
                  onEditorChange={(content) => setJobDescriptionText(content)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end">
            <Space>
              <Button
                className="button secondary"
                style={{ width: "100%" }}
                onClick={() => history.push("/mrf_list")}
              >
                Cancel
              </Button>
              <Button
                form="hiringForm"
                htmlType="submit"
                type="primary"
                className="button primary"
                style={{ width: "100%" }}
                icon={loading ? <SyncOutlined spin /> : null}
              >
                {rowData ? "Update MRF" : "Raise MRF"}
              </Button>
            </Space>
          </Row>
        </Form>
      </Card>
      <ReplacementFormModal
        open={replacementModal}
        onClose={() => {
          setReplacementModal(false);
          console.log({ replacementArray });
        }}
        dataArray={replacementArray}
        handleChange={handleReplacementValues}
        handleClearVacancy={handleClearVacancy}
      />
    </>
  );
};

export default MrfFormNew;
