import React, { useState,useEffect } from 'react';
import {
  Typography,
  TextField,
  Divider,
  Grid,
  Paper,
  Card,
  withStyles,
  Avatar,
  Table,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableFooter,
  Button,
  Dialog,
  InputBase,
  // Modal,
} from '@material-ui/core';
import PropTypes, { func } from 'prop-types';
import styles from './interviewCompleted.Style';
import SearchIcon from "@material-ui/icons/Search";
import urls from '../../../url';
import useFetch from '../../../hoc/useFetch';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { rgbToHex } from '@material-ui/core/styles';
import Loader from '../../../hoc/loader';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ReactHtmlParser from 'react-html-parser';
import { useAlert } from '../../../hoc/alert/alert';
import Close from "@material-ui/icons/Close";
import { makeStyles } from '@material-ui/core/styles';
import Parameters from '../../../components/interviewer/todaysInterview/Parameters';

function InterviewCompleted({classes}){
    const [auth] = useState(JSON.parse(localStorage.getItem('user')));
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(null);
    const [view, setView] = useState(false);
    const [totalMarks, setTotalMarks] = useState('');
    const [comments, setComments] = useState('');
    const [applicentId, setApplicentId] = useState('');
    const [faceToFaceId, setfaceToFaceId] = useState('');
    const [confirmMarks, setConfirmMarks] = useState('');
    const [open, setOpen] = useState(false);
    const alert = useAlert();
    const [viewModdel, setViewModel] = useState(false);
    const [searchData, setSearchData] = useState(null);

    const useStyles = makeStyles((theme) => ({
      dialogMarks: {
        height:305,
        width: '35%'
      }
    }));

    const classesD = useStyles();

    const {
      data: getInterviewData,
      isLoading: getInterviewDataLoading,
      doFetch: viewInterview,
    } = useFetch("", { suppressAlert: true });

    const {
      data: marksSubmitRes,
      isLoading: submittingMarks,
      doFetch: fetchSubmitMarks,
    } = useFetch("", { suppressAlert: true });

    const {
      data: getQuestionsData,
      isLoading: getQuestionsDataLoading,
      doFetch: fetchQuestions
    } = useFetch("", { suppressAlert: true })

    // var loader = null;
    // if () {
    //   loader = <Loader open />;
    // }

    function interviewCompleted() {
      viewInterview({
        url: `${urls.completedInterviewApi}?current_page=${page+1}&page_size=${rowsPerPage}&search=${searchData || ''}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json'
        },
      });
    }

    function getMarks(id, F2fId, marks) {
      setView(true)
      setApplicentId(id)
      setfaceToFaceId(F2fId)
      setConfirmMarks(marks);
      // fetchQuestions({
      //   url: `${urls.getParameters}`,
      //   method: 'GET',
      //   headers: {
      //     Authorization: `Bearer ${auth.personal_info.token}`,
      //     'Content-Type': 'application/json'
      //   },
      // })
    }

    let loader = null;
    if (getInterviewDataLoading || getQuestionsDataLoading || submittingMarks ) {
      loader = <Loader open />;
    }

    // useEffect(() => {
    //   interviewCompleted();
    // }, [rowsPerPage, page]);

    const handleClose = () => {
      setOpen(false);
      setView(false);
      setConfirmMarks('');
      setTotalMarks('');
      setComments('');
      setApplicentId('');
      setfaceToFaceId('');
    };

    function functionSearch(data){
      setSearchData(data);
    }
  
    useEffect(() => {
      if (auth) {
        setOpen(false);
        setView(false);
        setConfirmMarks('');
        setTotalMarks('');
        setComments('');
        setApplicentId('');
        setfaceToFaceId('')
        interviewCompleted()
      }
    }, [page, rowsPerPage, auth, marksSubmitRes, searchData])

    useEffect(() => {
      if (getQuestionsData) {
        if (getQuestionsData) {
          setOpen(true)
        }
      }
    }, [getQuestionsData])

    async function fetchQuestionsVal(id) {
      localStorage.setItem('param_id', id)
      setViewModel(true);
    }

    function handleChangePage(event, newPage) {
      setPage(newPage)
      if (!rowsPerPage) {
        setRowsPerPage(12)
      }
    }

    function handleChangeRowsPerPage(event) {
      setRowsPerPage(event.target.value);
      setPage(0);
    }
    function firstPageChange() {
      setPage(0)
    }
    function lastPageChange(lastPage) {
      setPage(lastPage)
    };


  const onCloseFunc = () => {
    setViewModel(false);
  }

return(
<React.Fragment>
  {/* <Typography variant="h4">Interview's Completed</Typography>
    <Divider className={classes.divider} />
      // {loader} */}
    <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={6} xs={12}>
          <Typography className="headings" variant="h4"><strong>Completed Interviews</strong></Typography>
        </Grid>
        <Grid item md={6} xs={12}>
             <div className={classes.search}>
             <TextField
            id="search-bar"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            label="I'm Looking For..."
            variant="outlined"
            size="small"
            name="subject"
            autoComplete="on"
            onChange={(e) => functionSearch(e.target.value)}
          />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {getInterviewData && getInterviewData.results.length === 0 ? <Typography variant='h4' align="center" style={{ marginTop: '10%', color: 'blue' }}>Records Not Found</Typography> : ''}
      {getInterviewData && getInterviewData.results.length !== 0 && getInterviewData.results.map((item) => {
        return (
      <div style={{ float: 'left', marginRight: '20px', marginLeft: '30px' }}>
            <div style={{ width: '35%', height: '35%', display: 'inline', textAlign: 'center' }} class="box">
              <div style={{ display: 'table-row' }} class="box-row">
                <Paper style={{ border: "1px solid black", borderRadius: "10px", marginBottom: "15px" }}>
                  {/* <p style={{ backgroundColor: rgbToHex('#40DDB7'), fontSize: '20px', color: 'white', width: '100%', height: '100%' }}>{(item.round && item.round.round_name && item.round.round_name) || 'None'}</p> */}
                  <Card>
                    <Grid container justify="center" alignItems="center">
                      <Avatar style={{ marginBottom: '10px',marginTop: '10px', fontSize: '50px', width: '90px', height: '90px' }} src={item.applicant && item.applicant.applicant.photo ? ReactHtmlParser(item.applicant.applicant.photo) : require('./3.png')} alt="Remy Sharp" className={classes.bigAvatar} />
                    </Grid>
                    <div style={{ fontSize: '18px' }}>
                      <span style={{ color: 'blue' }}>{(item.applicant && item.applicant.applicant.user && item.applicant.applicant.user.first_name && item.applicant.applicant.user.first_name) || ''}</span><br />
                      <span>Applied For : {(item.applicant && item.applicant.applicant.position_apply && item.applicant.applicant.position_apply.position_type && item.applicant.applicant.position_apply.position_type) || ''}</span><br />
                      <span>Subject: {(item.applicant && item.applicant.applicant.relevant_fk && item.applicant.applicant.relevant_fk.subjects && item.applicant.applicant.relevant_fk.subjects.subject_name) || ''}</span><br />
                      <span>Grade: {(item.applicant && item.applicant.applicant.relevant_fk && item.applicant.applicant.relevant_fk.grade && item.applicant.applicant.relevant_fk.grade.grade_name) || ''}</span><br />
                      <span>MCQ Score: {(item.mcq_test && item.mcq_test.mark_scored) || ''}</span><br />
                    </div>
                    <Grid style={{ marginTop: '10px' }} container justify="center" alignItems="center">
                      <table style={{ marginLeft: '5px', borderCollapse: 'collapse', width: "200px", marginBottom: "20px" }} border='0px' cellPadding="5px">
                        <th colspan="2" className={classes.tableHeadtab1}>Written Test</th>
                        <tr className={classes.tableRowtab1}>
                          <td>Score</td>
                          <td>{item.written_test !== null ? item.written_test.mark_scored : ''}</td>
                        </tr>
                        <tr className={classes.tableRowtab1}>
                          <td>Topic</td>
                          <td>{item.written_test && item.written_test.topic !== null ? item.written_test.topic.topic_name : ''}</td>
                        </tr>
                        <tr className={classes.tableRowtab1}>
                          <td>Submission</td>
                          <td>{(item.written_test && item.written_test.written_test_file && <a target="_blank" rel="noopener noreferrer" href={(item.written_test && item.written_test.written_test_file) || ''}>Click To View</a>) || <span>Not Upload</span>}</td>
                        </tr>
                      </table>
                      <table style={{ marginLeft: '5px', borderCollapse: 'collapse', width: "200px" }} border='0px' cellPadding="5px">
                        <th colspan="2" className={classes.tableHeadtab2}>Spoken Test</th>
                        <tr className={classes.tableRowtab2}>
                          <td>Score</td>
                          <td>{item.spoken_test !== null ? item.spoken_test.mark_scored : ''}</td>
                        </tr>
                        <tr className={classes.tableRowtab2}>
                          <td>Topic</td>
                          <td>{item.spoken_test && item.spoken_test.topic_name !== null ? item.spoken_test.topic_name.topic_name : ''}</td>
                        </tr>
                        <tr className={classes.tableRowtab2}>
                          <td>Audio File</td>
                          <td>{(item.spoken_test && item.spoken_test.spoken_audio && <a target="_blank" rel="noopener noreferrer" href={(item.spoken_test && item.spoken_test.spoken_audio) || ''}>Click To Listen</a>) || <span>Not Upload</span>}</td>
                        </tr>
                      </table>&nbsp;&nbsp;
                    </Grid>
                    <Grid container justify="center" alignItems="center">
                      <Table className={classes.Tabletab}>
                        <TableHead className={classes.TableHeadtab}>
                          <b>Planner Round : Scheduled On {(item.zoom && item.zoom.start_time) || ''}.</b>
                        </TableHead>   
                  
                        <Typography className={classes.TableHeadtabBottom}>
                          <b>Note: Please upload marks after completing Interview</b>
                        </Typography>
                      </Table>
                    </Grid>
                  </Card>
                </Paper>
              </div>
            </div>
          </div>
        )
            })}
      <Grid container justify="bottom" alignItems="bottom">
        {getInterviewData && getInterviewData.results.length !== 0 &&
          <Paper className={classes.tablepagination}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+getInterviewData.total_pages}`}
                  rowsPerPageOptions={[12]}
                  count={+getInterviewData.count}
                  rowsPerPage={rowsPerPage || 10}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(getInterviewData.total_pages - 1)}
                    disabled={page === (+getInterviewData.total_pages - 1)}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Paper>
        }
      </Grid>
      {loader}
      
</React.Fragment>
)
}
InterviewCompleted.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
  };
export default withStyles(styles)(InterviewCompleted);
