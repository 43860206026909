export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(1, 2, 1),
      width:'50%',
      padding:'20px',
      marginTop:'10px',
      marginBottom:'10px',
      marginLeft:'25%',
      marginRight:'25%',
    }, 
    buttonn: {
        margin: theme.spacing(1),
        float:'right',
      },
      fileUpload : {
        margin: theme.spacing(1),
        float:'left',
      },
      label : {
        fontSize: '14px',
      },
      gride:{
          marginTop:'20px'
      },
      papertext:{
        padding: theme.spacing(1, 1, 1,1),
        fontSize:'20px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontFamily: "Times"
      },
      GridText:{
        marginTop:'100px',
        marginLeft:'11%',
        marginRight:'11%',
        // marginRight:'25%',
        display: 'block',
        // position:'fixed'
      },
      bold:{
        padding: theme.spacing(2,0,0,3),
        // textAlign:'left',
        fontFamily: "Times",
        textTransform:'initial'
      }
    });
  