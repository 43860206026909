export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    createPassword:{
        marginTop:'50px'
    },
    applyButton:{
        width:'100%'
    }
  });
  