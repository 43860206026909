import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Modal,
  TablePagination,
  TableContainer,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import urls from '../../../url'
import Switch from '@material-ui/core/Switch';
import useFetch from '../../../hoc/useFetch';
import styles from './questionPaper.style';
import Loader from '../../../hoc/loader';
import Backdrop from '@material-ui/core/Backdrop';
import { useAlert } from '../../../hoc/alert/alert'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';


function QuestionPaper({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [testName, setTestName] = useState('');
  const [duration, setDuration] = useState('');
  const [nuOfQues, setNuOfQues] = useState('');
  const [grade, setGrade] = useState('');
  const [subject, setSubject] = useState('');
  const [EachMarksQuestion, setEachQuestionMarks] = useState('');
  const [cutOfMarks, setCutOfMarks] = useState('');
  const [negitiveMarkBool, setNegitiveMarkBool] = useState(false);
  const [negitiveMarks, setNegitiveMarks] = useState('');
  const [updateUI, setUpdateUI] = useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [id, setId] = useState('');
  const [chapter, setChapter] = useState('');
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(null);
  const [searchData, setSearchData] = useState(null);

  // const [grade, setGrade] = useState(createupdateTopic[index] && createupdateTopic[index].grade && createupdateTopic[index].grade.id);
  // const [subject,setSubject] = useState(createupdateTopic[index] && createupdateTopic[index].subject && createupdateTopic[index].subject.id);


  const {
    data: gradeData,
    isLoading: gradeDataLoading,
    doFetch: fetchGrade,
  } = useFetch([], { suppressAlert: true });
  const {
    data: subjectData,
    isLoading: subjectDataLoading,
    doFetch: fetchSubject,
  } = useFetch([], { suppressAlert: true });

  const {
    data: chapterData,
    isLoading: chapterDataLoading,
    doFetch: fetchChapter,
  } = useFetch([], { suppressAlert: true });

  const {
    data: addQuestionResponse,
    isLoading: addQuestionResponseLoading,
    doFetch: addQuestion,
  } = useFetch([], { suppressAlert: true });
  const {
    data: topicTableData,
    isLoading: topicTableDataLoading,
    doFetch: fetchTableData,
  } = useFetch("", { suppressAlert: true });
  const [deleteResponse,deleteData]=useState();
  // const {
  //   data: deleteResponse,
  //   isLoading: deleteResponseLoading,
  //   doFetch: deleteData,
  // } = useFetch([], { suppressAlert: true });

  const {
    data: UpdateQuestionData,
    isLoading: UpdateQuestionLoading,
    doFetch: UpdateQuestion,
  } = useFetch();


  useEffect(() => {
    if (addQuestionResponse) {
      setTestName('')
      setDuration('')
      setNuOfQues('')
      setGrade('')
      setSubject('')
      setChapter('')
      setEachQuestionMarks();
      setCutOfMarks('');
      setNegitiveMarkBool(false);
      setNegitiveMarks('');
    }
  }, [addQuestionResponse])

  function searchFunction() {
    fetchTableData({
    url: `${
    urls.retrieveAddQuestionApi
    }?search=${searchData || ''}`,
    method: "GET",
    headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${auth.personal_info.token}`,
    },
    });
    }
    
    useEffect(() => {
    if (auth) {
    searchFunction();
    }
    }, [auth, searchData]);


  useEffect(() => {
    if (auth) {
      fetchTableData({
        url: `${urls.retrieveAddQuestionApi}?page_size=${rowsPerPage || 50}&page=${page + 1}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [fetchTableData, auth, addQuestionResponse, deleteResponse, page, rowsPerPage, UpdateQuestionData]);

  useEffect(() => {
    if (value) {
      fetchGrade({
        url: `${urls.newGrade}?position_type=${value}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [fetchGrade, auth,value]);

  useEffect(() => {
    if (auth) {
      if (grade) {
        fetchSubject({
          url: `${urls.subjectMapping}?grade_id=${grade}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
          }
        });
      }
    }
  }, [grade])
  useEffect(() => {
    if (auth) {
      if (grade) {
        fetchChapter({
          url: `${urls.subjectMapping}?grade_id=${grade}&subject_id=${subject}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
          }
        });
      }
    }
  }, [subject])
  // const [deleteModel,setDeleteModel]=useState(false);
  // const [deleteId,setDeleteId]=useState();
  // function deleteTopic(data){
  //   setDeleteModel(true);
  //   setDeleteId(data);
  // }

  // function cancelDelete(){
  //   setDeleteModel(false);
  //   setDeleteId(null);
  // }

  // const deleteFunction = () => {
  //   fetch(`${urls.addQuesUpdateDeleteApi}${deleteId}/retrieve_update_delete_question_paper/`,{
  //     method: 'DELETE',
  //     headers: {
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //       'Content-Type': 'application/json',
  //     },
  //   }
  //   ).then((response) => response.json())
  //   .then((data) => {
  //     deleteData(data);
  //     cancelDelete();
  //     alert.success("data deleted successfully")
  //   }).catch(() => {
  //     alert.error("Failed to delete");
  //   });
  // }

  const alert = useAlert();

  function addQuestionFun() {
    if (!testName || !grade || !duration || !subject || !grade || !cutOfMarks || !EachMarksQuestion) {
      alert.warning('Filled All Field');
      return
    }
    if (negitiveMarkBool === true) {
      if (!negitiveMarks) {
        alert.warning('Enter Negitive Marks');
        return
      }
    }
    const data = {
      'test_name': testName,
      'no_of_ques': nuOfQues,
      'duration': duration,
      'chapter' : chapter?chapter:'',
      'subject': subject,
      'grade': grade,
      'cut_off': cutOfMarks,
      'is_negative': negitiveMarkBool,
      'negative_mark': negitiveMarkBool === true ? negitiveMarks : 0,
      'each_question_mark': EachMarksQuestion,
      'is_checked': checked,
    }
    addQuestion({
      url: urls.addQuestionApi,
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    }
    )
  }


  function handleChangePage(event, newPage) {
    setPage(newPage)
    { !rowsPerPage && setRowsPerPage(50) }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0)
  }

  function lastPageChange(lastPage) {
    setPage(lastPage)
  };


  function editFunction(i) {
    setUpdateUI(true);
    setId(topicTableData && topicTableData?.results[i]?.id);
    setGrade((topicTableData && topicTableData.results.length && topicTableData?.results[i]?.grade?.id) || '');
    setDuration((topicTableData && topicTableData.results.length && topicTableData.results[i].duration) || '');
    setNuOfQues((topicTableData && topicTableData.results.length && topicTableData.results[i].no_of_ques) || '');
    setSubject((topicTableData && topicTableData.results.length && topicTableData.results[i].subject.id) || '');
    setChapter((topicTableData && topicTableData.results.length && topicTableData.results[i].chapter?.id) || '');
    setTestName((topicTableData && topicTableData.results.length && topicTableData.results[i].test_name) || '');
    setEachQuestionMarks((topicTableData && topicTableData.results.length && topicTableData.results[i].each_question_mark) || '');
    setCutOfMarks((topicTableData && topicTableData.results.length && topicTableData.results[i].cut_off) || '');
    setNegitiveMarkBool((topicTableData && topicTableData.results.length && topicTableData.results[i].is_negative) || '');
    setNegitiveMarks((topicTableData && topicTableData.results.length && topicTableData.results[i].negative_mark) || '');
    setChecked(!(topicTableData.results[i]?.is_delete) || '');
  }
  function updateQuestionFunction() {
    if (updateUI) {
      const data = {
        'test_name': testName,
        'no_of_ques': nuOfQues,
        'duration': duration,
        'subject': subject,
        'grade': grade,
        'cut_off': cutOfMarks,
        'is_negative': negitiveMarkBool,
        'negative_mark': (negitiveMarkBool === true) ? negitiveMarks : 0,
        'each_question_mark': EachMarksQuestion,
        'is_delete' : checked==true?false:true,
      }
      if (!testName || !grade || !duration || !subject || !grade || !cutOfMarks || !EachMarksQuestion) {
        alert.warning('Filled All Field');
        return
      }
      if (negitiveMarkBool === true) {
        if (!negitiveMarks) {
          alert.warning('Enter Negitive Marks');
          return
        }
      }
      UpdateQuestion({
        url: `${urls.addQuesUpdateDeleteApi}${id}/retrieve_update_delete_question_paper/`,
        method: 'PUT',
        body: data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      }
      )
    }

    setUpdateUI(false)
    setGrade('')
    setDuration('')
    setNuOfQues('')
    setSubject('')
    setChapter('')
    setTestName('')
    setEachQuestionMarks();
    setCutOfMarks('');
    setNegitiveMarkBool(false);
    setNegitiveMarks('');

  }

  function functionSearch(data){
    setSearchData(data);
  }

  function handleMobileNO(e) {
    const re= /^[0-9]+$/g
    if ((e.target.value === '' || re.test(e.target.value))&&(e.target.value.length <= 3)) {
      setNuOfQues(e.target.value)
      
    }
  }
  function handleDuration(e) {
    const re= /^[0-9]+$/g
    if ((e.target.value === '' || re.test(e.target.value))&&(e.target.value.length <= 4)) {
      setDuration(e.target.value)
      
    }
  }

  const handleClose = () => {
    setUpdateUI(false);
    setGrade('')
    setDuration('')
    setNuOfQues('')
    setSubject('')
    setChapter('')
    setTestName('')
    setEachQuestionMarks();
    setCutOfMarks('');
    setNegitiveMarkBool(false);
    setNegitiveMarks('');
    setChecked(null);
  };

  let loader = null;
  if ( topicTableDataLoading || addQuestionResponseLoading || subjectDataLoading || chapterDataLoading || gradeDataLoading || UpdateQuestionLoading) {
    loader = <Loader open />;
  }

  function tableTopicDetails() {
    let tableData = null;
    if (topicTableData && topicTableData.results.length) {
      tableData = (
        <React.Fragment>

          <TableBody>
            {topicTableData.results.map((data, i) => (
              <TableRow key={data.id}>
                <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.test_name}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.no_of_ques}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.duration}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.grade && data.grade.grade_name}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.subject && data.subject.subject_name}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => editFunction(i)}
                  >
                    Update
                  </Button>
                </TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>
                  {/* <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => deleteTopic(data.id)}
                  >
                    In Active
                  </Button> */}
                  {!data?.is_delete ?
                      <CheckCircleRoundedIcon style={{ color: "green" }}/>
                      :
                      <CancelIcon style={{color: "red"}} />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {loader}
        </React.Fragment>
      )
    }
    return tableData;
  };


  const categoryList = [
    {
      value: "1",
      label: "Academic"
    },
    {
      value: "2",
      label: "Non Academic"
    },
    {
      value: "3",
      label: "IT Recruitment"
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    }
  ];
  return (
    <React.Fragment>
    <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'space-between' }}>
    <div >
    <Typography className="headings" variant="h4"><strong>Question Papers</strong></Typography>
    </div>    
    <Paper style={{ margin: '5px' }} >
          <TextField
            id="search-bar"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            label="I'm Looking For..."
            variant="outlined"
            size="small"
            name="subject"
            autoComplete="on"
            onChange={(e) => functionSearch(e.target.value)}
          />
    </Paper>
    </div>




      <Divider className={classes.divider} />
      <Paper className={classes.paper} >
        <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
            {/* <Typography>Category</Typography> */}
            <TextField
              required
              select
              label="Category"
              fullWidth
              margin='dense'
              value={value || ""}
              onChange={e => setValue(e.target.value)}
              variant='outlined'
            >
              {categoryList.map(option => (
                <MenuItem key={option.id} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Test Name"
              margin="dense"
              fullWidth
              required
              value={updateUI ? '' : testName}
              onChange={e => setTestName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Duration in min.."
              margin="dense"
              fullWidth
              type='text'
              required
              value={updateUI ? '' : duration}
              onChange={handleDuration}

              // onChange={e => e.target.value.length < 4 ? setDuration(e.target.value) : ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="No. Of Question"
              margin="dense"
              fullWidth
              type='text'
              required
              value={updateUI ? '' : nuOfQues}
              // onChange={e => e.target.value.length < 3 ? setNuOfQues(e.target.value) : ''}
              onChange={handleMobileNO}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Enter Question Marks"
              // className={classes.textField}
              fullWidth
              margin="dense"
              required
              type="number"
              onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2) }}
              onChange={e => e.target.value < 6 && setEachQuestionMarks(e.target.value)}
              value={EachMarksQuestion || ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Cut-of marks (%)"
              // className={classes.textField}
              fullWidth
              margin="dense"
              required
              type="number"
              onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3) }}
              onChange={e => e.target.value < 101 && setCutOfMarks(e.target.value)}
              value={cutOfMarks || ''}
              variant="outlined"
            />
          </Grid>
          {EachMarksQuestion &&
            <>
              <Grid item md={2} xs={6}>
                <span>Set Negative Mark</span>
                <Switch
                  checked={negitiveMarkBool}
                  onChange={e => setNegitiveMarkBool(e.target.checked)}
                  value="checkedB"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
              {negitiveMarkBool &&
                <Grid item md={2} xs={6}>
                  <TextField
                    label="Enter Negative Marks"
                    // className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    type="text"
                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2) }}
                    onChange={e => (e.target.value < parseInt(EachMarksQuestion)) && setNegitiveMarks(e.target.value)}
                    value={negitiveMarks || ''}
                    variant="outlined"
                  />
                </Grid>
              }
            </>
          }
          <Grid item md={4} xs={12}>
            <TextField
              label={value=="Academic" ?"Grade":"Level"}
              // className={classes.textField}
              fullWidth
              margin="dense"
              required
              onChange={e => setGrade(e.target.value)}
              select
              value={updateUI ? '' : grade || ''}
              variant="outlined"
              className="textList"
            >
              {gradeData && gradeData && gradeData.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.grade_name}
                  className="textList"
                >
                  {data.grade_name ? data.grade_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            {grade && <TextField
              label={value=="Academic" ?"Subject":"Topic"}
              // className={classes.textField}
              fullWidth
              margin="dense"
              required
              onChange={e => setSubject(e.target.value)}
              select
              value={subject || ''}
              variant="outlined"
              className="textList"
            >
              {subjectData && subjectData && subjectData.map(data => (
                <MenuItem
                  value={data.subject.id}
                  key={data.subject.id}
                  name={data.subject.subject_name}
                >
                  {data.subject.subject_name ? data.subject.subject_name : ''}
                </MenuItem>
              ))}
            </TextField>}
          </Grid>
          <Grid item md={4} xs={12}>
            {subject && chapterData?.length > 0 && <TextField
              label="Chapter"
              // className={classes.textField}
              fullWidth
              margin="dense"
              required
              onChange={e => setChapter(e.target.value)}
              select
              value={chapter || ''}
              variant="outlined"
              className="textList"
            >
              {chapterData && chapterData && chapterData.map(data => (
                <MenuItem
                  value={data.chapter.id}
                  key={data.chapter.id}
                  name={data.chapter.chapter_name}
                >
                  {data.chapter.chapter_name ? data.chapter.chapter_name : ''}
                </MenuItem>
              ))}
            </TextField>}
          </Grid>
          <Grid item md={4} xs={12}>
            <Button
              // className={classes.applyButtonModal}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => addQuestionFun()}
            >
              Add Question Paper
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Divider className={classes.divider} />
      {topicTableData && topicTableData.results.length === 0 ? <h1 style={{ marginTop: '10%', textAlign: 'center', color: 'blue' }}>Records Not Found</h1> : ''}
      {topicTableData && topicTableData.results.length !== 0 && <Paper>
        <React.Fragment>
          <TableContainer className="role">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>S.No.</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Test Name</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>No. Of Question</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Duration in Minuites</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Grade</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Subject</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Update</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Status</strong></TableCell>
                </TableRow>
              </TableHead>
              {tableTopicDetails()}
            </Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+topicTableData.total_pages}`}
                  rowsPerPageOptions={[25, 50, 75, 100]}
                  count={+topicTableData.count}
                  rowsPerPage={rowsPerPage || 50}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(topicTableData.total_pages - 1)}
                    disabled={page === (+topicTableData.total_pages - 1)}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </TableContainer>
        </React.Fragment>
        {/* {modalOpen()} */}
      </Paper>}

      {updateUI && <React.Fragment>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={updateUI}
          // onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{ width: "80%", marginLeft: "10%" }}
        >
          <Fade in={updateUI}>
            <div className={classes.paper}>
              <React.Fragment>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h4">
                    <strong>Update Question Paper</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
                {/* <Typography className="headings" variant='h4'><strong>Update Question Paper</strong></Typography> */}
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      label="Test Name"
                      margin="dense"
                      fullWidth
                      required
                      value={testName}
                      onChange={e => setTestName(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      label="Duration in minutes"
                      margin="dense"
                      fullWidth
                      required
                      type="number"
                      value={duration}
                      onChange={e => e.target.value.length < 4 ? setDuration(e.target.value) : ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      label="No. Of Question"
                      margin="dense"
                      fullWidth
                      required
                      type="number"
                      value={nuOfQues}
                      onChange={e => e.target.value.length < 3 ? setNuOfQues(e.target.value) : ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      label="Enter Each Question Marks"
                      className={classes.textField}
                      margin="dense"
                      required
                      type="number"
                      onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2) }}
                      onChange={e => e.target.value < 6 && setEachQuestionMarks(e.target.value)}
                      value={EachMarksQuestion || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      label="Cut-of marks"
                      className={classes.textField}
                      margin="dense"
                      required
                      type="number"
                      onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3) }}
                      onChange={e => e.target.value < 101 && setCutOfMarks(e.target.value)}
                      value={cutOfMarks || ''}
                      variant="outlined"
                    />
                  </Grid>
                  {EachMarksQuestion &&
                    <>
                      <Grid item md={2} xs={6}>
                        <span>Set Negative Mark</span>
                        <Switch
                          checked={negitiveMarkBool}
                          onChange={e => setNegitiveMarkBool(e.target.checked)}
                          value="checkedB"
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </Grid>
                      {negitiveMarkBool &&
                        <Grid item md={2} xs={6}>
                          <Typography>Enter Negative Marks</Typography>
                          <TextField
                            // label="Enter Negative Marks"
                            className={classes.textField}
                            margin="dense"
                            required
                            fullWidth
                            type="number"
                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2) }}
                            onChange={e => (e.target.value < parseInt(EachMarksQuestion)) && setNegitiveMarks(e.target.value)}
                            value={negitiveMarks || ''}
                            variant="outlined"
                          />
                        </Grid>
                      }
                    </>
                  }
                  <Grid item md={4} xs={12}>
                    <TextField
                      label="Grade"
                      className={classes.textField}
                      margin="dense"
                      required
                      onChange={e => setGrade(e.target.value)}
                      select
                      value={grade || ''}
                      variant="outlined"
                    >
                      {gradeData && gradeData && gradeData.map(data => (
                        <MenuItem
                          value={data.id}
                          key={data.id}
                          name={data.grade_name}
                        >
                          {data.grade_name ? data.grade_name : ''}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      label="Subject"
                      className={classes.textField}
                      margin="dense"
                      required
                      onChange={e => setSubject(e.target.value)}
                      select
                      value={subject || ''}
                      variant="outlined"
                    >
                      {subjectData && subjectData && subjectData.map(data => (
                        <MenuItem
                          value={data.subject.id}
                          key={data.subject.id}
                          name={data.subject.subject_name}
                        >
                          {data.subject.subject_name ? data.subject.subject_name : ''}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      label="Chapter"
                      // className={classes.textField}
                      fullWidth
                      margin="dense"
                      required
                      onChange={e => setChapter(e.target.value)}
                      select
                      value={chapter || ''}
                      variant="outlined"
                      className="textList"
                    >
                      {chapterData && chapterData && chapterData.map(data => (
                        <MenuItem
                          value={data.chapter.id}
                          key={data.chapter.id}
                          name={data.chapter.chapter_name}
                        >
                          {data.chapter.chapter_name ? data.chapter.chapter_name : ''}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>

                <Grid item xs={4} md={3}>
                <Typography style={{marginTop: '15px'}}>Status</Typography>
                In Active<Switch checked={checked || false} value={checked || false} onChange={(e) => setChecked(e.target.checked)} color='primary'/> Active
                </Grid>

                <Grid container align="center" justify="center">
                  <Grid item md={3} xs={12}>
                    <Button
                      // className={classes.applyButtonModal}
                      color="primary"
                      fullWidth
                      variant="contained"
                      size="large"
                      style={{ marginTop: '10%' }}
                      onClick={() => updateQuestionFunction()}
                    >
                      Update
                    </Button>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </React.Fragment>
            </div>
          </Fade>
        </Modal>
      </React.Fragment>}
      {/* {deleteModel ==true && deleteId &&
      <Dialog
        open={deleteModel}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       <DialogTitle
            id='draggable-dialog-title'
          >
            Delete Question Paper
          </DialogTitle>     
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete ?
            </DialogContentText>
          </DialogContent>   
       
                  
                  <Divider className={classes.divider} />
                  <DialogActions>
            <Button onClick={cancelDelete} >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              style={{ color: 'white' }}
              onClick={deleteFunction}
              >
              Confirm
            </Button>
          </DialogActions>
      </Dialog>
      } */}
      {loader}
    </React.Fragment>
  );
}
QuestionPaper.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(QuestionPaper);
