import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  TextField,
  MenuItem,
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Fab,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useFetch from '../../../hoc/useFetch'
import urls from '../../../url'
import styles from './profile.style';
import { useAlert } from '../../../hoc/alert/alert'
import Loader from '../../../hoc/loader'
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactHtmlParser from 'react-html-parser';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const CATEGORIES = ['Campus Hire', 'Lateral Hire', 'Re-Hire'];

const Profile = ({ classes, applicantId }) => {

  const [name, setName] = useState(JSON.parse(localStorage.getItem('user')));
  const [profileDetails, setProfileDetails] = useState([]);
  const [relDetails, setRelDetails] = useState('')
  const [activeStep, setActiveStep] = React.useState(0);
  const [relavant, setRelevant] = useState(false);
  const [choosePhoto, setChoosePhoto] = useState('')
  const [chooseResume, setChooseResume] = useState('')
  const [sendProfileImage, setSendProfileImage] = useState('')
  const [qual, setQual] = useState([{ id: null }]);


  const alert = useAlert();

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: cityList,
    isLoading: cityListLoading,
    doFetch: fetchCityList,
  } = useFetch(null);

  const {
    data: branchList,
    isLoading: branchListLoading,
    doFetch: fetchBranchList,
  } = useFetch(null);

  const {
    data: gradeList,
    isLoading: gradeListLoading,
    doFetch: fetchGradeList,
  } = useFetch(null);

  const {
    data: subjectList,
    isLoading: subjectListLoading,
    doFetch: fetchSubjectList,
  } = useFetch(null);

  const {
    data: relProfile,
    isLoading: relProfileLoading,
    doFetch: fetchRelProfile,
  } = useFetch(null);

  const {
    data: fetechProfileImageData,
    isLoading: gettingProfileImage,
    doFetch: fetchProfileImage
  } = useFetch(null)

  const {
    data: UploadProfileImageData,
    isLoading: UploadProfileImageDataLoading,
    doFetch: fetchUpoladImageAndResume
  } = useFetch(null)

  const {
    data: graduationList,
    doFetch: fetchGraduationList
  } = useFetch(null)

  const {
    data: postGraduationList,
    doFetch: fetchPostGraduationList
  } = useFetch(null)

  const {
    data: recruiterUpdatedData,
    doFetch: updateRecruiterResult,
    isLoading: recruiterUpdating
  } = useFetch(null);

  useEffect(() => {
    setName(JSON.parse(localStorage.getItem('user')));
  }, [])

  useEffect(() => {
    if (profileDetail) {
      setProfileDetails([profileDetail]);
    }
  }, [profileDetail]);

  useEffect(() => {
    if (relProfile) {
      setRelDetails([relProfile]);
    }
  }, [relProfile]);

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: name.personal_info && name.personal_info.role === 'Recruiter' ? `${urls.profileRetrieve}?user_id=${applicantId}` : urls.profileRetrieve,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchCityList({
        url: urls.cityList,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranchList({
        url: urls.branch,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchGradeList({
        url: urls.gradeMapping,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchSubjectList({
        url: urls.subjectApiView,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchRelProfile({
        url: name.personal_info && name.personal_info.role === 'Recruiter' ? `${urls.applicantRelevant}?user_id=${applicantId}` : urls.applicantRelevant,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchProfileImage({
        url: name.personal_info && name.personal_info.role === 'Recruiter' ? `${urls.getProfileImageApi}?user_id=${applicantId}` : urls.getProfileImageApi,
        method: 'GET',
        headers: {  
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      fetchGraduationList({
        url: urls.graduationList,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchPostGraduationList({
        url: urls.postGraduationList,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchProfile, fetchCityList, fetchGradeList, fetchSubjectList, fetchRelProfile, fetchGraduationList, fetchPostGraduationList, name]);

  useEffect(() => {
    if (profileDetail && profileDetail.qualification && profileDetail.qualification.length) {
      setQual(profileDetail.qualification)
    }
  }, [profileDetail])

  useEffect(() => {
    if (UploadProfileImageData) {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  }, [UploadProfileImageData])

  useEffect(() => {
    if (fetechProfileImageData) {
      if (fetechProfileImageData.photo !== null) {
        setChoosePhoto(fetechProfileImageData.photo)
      }
      if (fetechProfileImageData.resume !== null) {
        setChooseResume(fetechProfileImageData.resume)
      }
    }
  }, [fetechProfileImageData])


  let loader = null;
  if (profileDetailLoading || cityListLoading || gradeListLoading || subjectListLoading || relProfileLoading || branchListLoading || gettingProfileImage || UploadProfileImageDataLoading) {
    loader = <Loader open />;
  }

  const changeHandler = (e, key, i) => {
    if (!profileDetails.length) {
      alert.warning('No data available')
      return;
    }
    setProfileDetails((dt) => {
      const newData = [...dt];
      if ((key === 'first_name') || key === 'email') {
        newData[0].user[key] = e;
        return newData;
      }
      newData[0][key] = e;
      return newData;
    })
  }

  const changeHandlerq = (e, key, i) => {
    if (!qual.length) {
      alert.warning('No data available ')
      return;
    }
    setQual((data) => {
      const newData = [...data];
      switch (key) {
        case 'degree':
          newData[i][key] = e;
          return newData;
        case 'specialization':
          newData[i][key] = e;
          return newData;
        case 'institute':
          newData[i][key] = e;
          return newData;
        case 'university':
          newData[i][key] = e;
          return newData;
        case 'year_of_passing':
          newData[i][key] = e;
          return newData;
        case 'percentage':
          newData[i][key] = e;
          return newData;
        default:
          return null;
      }
    })
  }

  const relHandler = (e, key) => {
    if (!relDetails.length) {
      alert.warning('No data available')
      return;
    }
    setRelDetails((dt) => {
      const newData = [...dt];
      newData[0].relevant_fk[key] = e;
      return newData;
    });
  };




  function handleNext() {

    if (activeStep === 1 && (name && name.personal_info && name.personal_info.role === 'Recruiter')) {
      const formData = new FormData();
      const relevantField = (relDetails &&
      relDetails.length &&
      relDetails[0].relevant_fk) || {}
        if(!relevantField.branch_prefer_1 ||
          !relevantField.branch_prefer_2 ||
          !relevantField.branch_prefer_3 ||
          !String(relevantField.relevant_graduation) ||
          !String(relevantField.relevant_post_graduation) ||
          !relevantField.is_relevant_experience ||
          !String(relevantField.extra_curricular_profile) ||
          !relevantField.category ||
          !relevantField.gross_pay) {
            alert.warning('Please Fill all fields');
            return;
          }

        Object.keys(relevantField).forEach(key => {
          if(`${key}` === "id") {
            formData.append(`${key}`, applicantId)
          } else {
            formData.append(`${key}`, relevantField[key]);
          }
        })

        updateRecruiterResult({
          url: urls.updateRecuriter,
          method: 'PUT',
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${name.personal_info.token}`,
          },
          body: formData
        });
        // recallApi(c => c + 1);
        return;
    }

    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.graduation === '') {
      relHandler('Na', 'graduation')
    }
    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.post_graduation === '') {
      relHandler('Na', 'post_graduation')
    }
    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.b_ed === '') {
      relHandler('Na', 'b_ed')
    }
    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.total_experience === '') {
      relHandler('Na', 'total_experience')
    }
    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.current_salary === '') {
      relHandler('Na', 'current_salary')
    }
    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.duration_notice_period === '') {
      relHandler('Na', 'duration_notice_period')
    }
    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.branch_prefer_1 === '') {
      relHandler('Na', 'branch_prefer_1')
    }
    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.branch_prefer_2 === '') {
      relHandler('Na', 'branch_prefer_2')
    }
    if (activeStep && relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.branch_prefer_3 === '') {
      relHandler('Na', 'branch_prefer_3')
    }

    if (profileDetails && !profileDetails.length) {
      alert.warning('Fill all Fields')
      return
    }

    if (profileDetails && profileDetails.length && !profileDetails[0].user) {
      alert.warning('Fill all Fields')
      return
    }

    if (qual && !qual.length) {
      alert.warning('Fill all Fields')
      return
    }

    // if (profileDetails && profileDetails.length && ((!profileDetails[0].user.first_name) || (!profileDetails[0].user.email) || (!profileDetails[0].contact_no) || (!profileDetails[0].current_location) || (!profileDetails[0].date_of_birth) || (!profileDetails[0].gender))) {
    //   alert.warning('fill all fields ')
    //   return
    // }



    // if (activeStep && ((relDetails[0].relevant_fk && !relDetails[0].relevant_fk.grade) || (!relDetails[0].relevant_fk.subjects))) {
    //   alert.warning('fill all fields 4')
    //   return
    // }

    // if((qual.map((item)=>{return item.degree}).filter(data=> data===undefined || data ==='').length !== 0) || (qual.map((item)=>{return item.specialization}).filter(data=> data===undefined || data ==='').length !==0) || (qual.map((item)=>{return item.institute}).filter(data=> data===undefined || data ==='').length !== 0) || (qual.map((item)=>{return item.university}).filter(data=> data===undefined || data ==='').length !== 0) || (qual.map((item)=>{return item.year_of_passing}).filter(data=> data===undefined || data ==='').length !== 0) || (qual.map((item)=>{return item.percentage}).filter(data=> data===undefined || data ==='').length !== 0)){
    //   alert.warning('fill the fields 2')
    //   return
    // }
    // if((qual.map((item)=>{return item.degree}).filter(data=> data===undefined || data ==='').length !== 0) || (qual.map((item)=>{return item.specialization}).filter(data=> data===undefined || data ==='').length !==0) || (qual.map((item)=>{return item.institute}).filter(data=> data===undefined || data ==='').length !== 0) || (qual.map((item)=>{return item.university}).filter(data=> data===undefined || data ==='').length !== 0) || (qual.map((item)=>{return item.year_of_passing}).filter(data=> data===undefined || data ==='').length !== 0) || (qual.map((item)=>{return item.percentage}).filter(data=> data===undefined || data ==='').length !== 0)){
    //   alert.warning('fill the fields 1')
    //   return
    // }


    if (!activeStep) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    const obj = profileDetails[0];
    // obj.qualification = qual;

    const relUpdated = relDetails[0]
    fetchProfile({
      url: relavant ? urls.applicantRelevant : urls.profileRetrieve,
      method: 'PUT',
      body: relavant ? relUpdated : obj,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
    setRelevant(true);

    if (activeStep === 1 && (name && name.personal_info && name.personal_info.role !== 'Recruiter')) {
      const formData = new FormData();
      sendProfileImage && formData.append('photo', sendProfileImage && sendProfileImage)
      chooseResume && chooseResume.name && formData.append('resume', chooseResume)
      fetchUpoladImageAndResume({
        url: urls.getProfileImageApi,
        method: 'PUT',
        body: formData,
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
    }
  }

  function setProfileImageFUnction(e) {
    if (e && e.name) {
      setSendProfileImage(e)
      setChoosePhoto(window.URL.createObjectURL(e))
    }
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    setRelevant(false);
  }
  function setToUpoladResumeFunction(e) {
    if (e.target.files[0].type === "video/mp4" || e.target.files[0].type === "video/x-matroska" || e.target.files[0].type === "application/pdf" || e.target.files[0].type === "application/msword" || e.target.files[0].type === "video/3gp" || e.target.files[0].type === "OGG") {
      setChooseResume(e.target.files[0])
    } else {
      alert.warning('upload File Should be PDF or DOC or Video')
      return
    }
  }
  let radioValue = relDetails &&
    relDetails.length &&
    relDetails[0].relevant_fk &&
    relDetails[0].relevant_fk.b_ed;
  if (radioValue == null) {
    radioValue = false;
  }
  let finalRadioValue = radioValue.toString();

  let radioGradValue = relDetails &&
    relDetails.length &&
    relDetails[0].relevant_fk &&
    relDetails[0].relevant_fk.relevant_graduation;

  if (radioValue == null) {
    radioValue = false;
  }
  let finalRadioGradValue = String(true) || radioGradValue.toString();

  let radioPostGradValue = relDetails &&
    relDetails.length &&
    relDetails[0].relevant_fk &&
    relDetails[0].relevant_fk.relevant_post_graduation;

  if (radioValue == null) {
    radioValue = false;
  }
  let finalPostRadioGradValue = String(true) || radioPostGradValue.toString();

  let radioCurricularValue = relDetails &&
    relDetails.length &&
    relDetails[0].relevant_fk &&
    relDetails[0].relevant_fk.extra_curricular_profile;

  if (radioValue == null) {
    radioValue = false;
  }
  let finalRadioCurricularValue = String(true) || radioCurricularValue.toString();

  const personalInfo = (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container align="center" justify="center">
          <Grid item md={2} className={classes.gridOne}>
            <input style={{ display: 'none' }}
              accept="image/*"
              className={classes.fileUpload}
              id="outlined-button-file"
              disabled={name && name.personal_info && name.personal_info.role === 'Recruiter'}
              type="file"
              onChange={e => setProfileImageFUnction(e.target.files[0])}
            />
            <label htmlFor="outlined-button-file">
              <Avatar variant="rounded" className={classes.square} src={choosePhoto ? ReactHtmlParser(choosePhoto) : require('./Upp3.png')} alt="Remy Sharp" />
            </label>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.gridTwo}>
          <Grid item md={3} className={classes.gridOne}>
            <TextField
              label="Full-Name"
              margin="dense"
              required
              disabled={applicantId ? true : false}
              fullWidth
              value={(profileDetails && profileDetails[0] && profileDetails[0].user && profileDetails[0].user.first_name) || ''}
              onChange={e => changeHandler(e.target.value, 'first_name')}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} className={classes.gridOne}>
            <TextField
              label="Email"
              margin="dense"
              required
              disabled={applicantId ? true : false}
              fullWidth
              value={(profileDetails[0] && profileDetails[0] && profileDetails[0].user && profileDetails[0].user.email) || ''}
              onChange={e => changeHandler(e.target.value, 'email')}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} className={classes.gridOne}>
            <TextField
              label="Contact No"
              margin="dense"
              disabled={applicantId ? true : false}
              required
              fullWidth
              type="number"
              value={(profileDetails && profileDetails[0] && profileDetails[0].contact_no) || ''}
              onChange={e => e.target.value.length <= 10 ? changeHandler(e.target.value, 'contact_no') : ''}
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} className={classes.gridOne}>
            <TextField
              className={classes.textField}
              label="Birth Date"
              margin="dense"
              disabled={applicantId ? true : false}
              required
              fullWidth
              value={(profileDetails && profileDetails[0] && profileDetails[0].date_of_birth) || ''}
              onChange={e => changeHandler(e.target.value, 'date_of_birth')}
              type="date"
              variant="outlined"
            />
          </Grid>
          <Grid item md={3} className={classes.gridOne}>
            <TextField
              label="Current Location"
              margin="dense"
              disabled={applicantId ? true : false}
              required
              fullWidth
              value={(profileDetails && profileDetails[0] && profileDetails[0].current_location) || ''}
              onChange={e => changeHandler(e.target.value, 'current_location')}
              variant="outlined"
            />
          </Grid>

          {profileDetails && profileDetails.length &&
            (<Grid item md={4}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"

                  row
                  style={{ display: 'flex' }}
                  style={{ flexDirection: 'row' }}
                  value={profileDetails[0].gender}
                  onChange={(e) => { changeHandler(e.target.value, 'gender') }}
                >
                  <FormControlLabel
                    value="Female"
                    control={<Radio color="primary" />}
                    label="Female"
                    disabled={applicantId ? true : false}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="Male"
                    control={<Radio color="primary" />}
                    label="Male"
                    disabled={applicantId ? true : false}
                    labelPlacement="start"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>)}

        </Grid>
      </Paper>
    </React.Fragment>
  )

  const relInfo = (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Typography className={classes.label} variant="h6" noWrap>
              Graduation
                </Typography>
            <TextField
              className={classes.textField}
              placeholder='Graduation'
              margin="dense"
              disabled={applicantId ? true : false}
              fullWidth
              required
              select
              value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.graduation) || ''}
              onChange={(e) => { relHandler(e.target.value, 'graduation') }}
              variant="outlined"
            >
              {graduationList && graduationList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.degree_name}
                >
                  {data.degree_name ? data.degree_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className={classes.label} variant="h6" noWrap>
              Post Graduation
                </Typography>
            <TextField
              className={classes.textField}
              placeholder='Post Graduation'
              margin="dense"
              disabled={applicantId ? true : false}
              fullWidth
              required
              select
              value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.post_graduation) || ''}
              onChange={(e) => { relHandler(e.target.value, 'post_graduation') }}
              variant="outlined"
            >
              {postGraduationList && postGraduationList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.degree_name}
                >
                  {data.degree_name ? data.degree_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>


          <Grid item md={4} xs={12}>
            <Typography className={classes.label} variant="h6" noWrap>
              Experience (in years)
                </Typography>
            <TextField
              margin="dense"
              placeholder='Year'
              disabled={applicantId ? true : false}
              fullWidth
              value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.total_experience) || ''}
              onChange={(e) => { relHandler(e.target.value, 'total_experience') }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className={classes.label} variant="h6" noWrap>
              Current Salary (in Lakhs)
                </Typography>
            <TextField
              margin="dense"
              placeholder='Current Salry'
              disabled={applicantId ? true : false}
              // type="Number"
              fullWidth
              value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.current_salary) || ''}
              onChange={(e) => { relHandler(e.target.value, 'current_salary') }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className={classes.label} variant="h6" noWrap>
              Notice Period (in days)
                </Typography>
            <TextField
              margin="dense"
              disabled={applicantId ? true : false}
              placeholder='Notice period'
              fullWidth
              value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.duration_notice_period) || ''}
              onChange={(e) => { relHandler(e.target.value, 'duration_notice_period') }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className={classes.label} variant="h6" noWrap>
              Branch Preferences 1
                </Typography>
            <TextField
              margin="dense"
              placeholder='Branch Preferences (Max 3 /Any in (City)'
              fullWidth
              max='3'
              select

              value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.branch_prefer_1) || ''}
              onChange={(e) => { relHandler(e.target.value, 'branch_prefer_1') }}
              variant="outlined"
            >
              {branchList && branchList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.branch_name}
                >
                  {data.branch_name ? data.branch_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className={classes.label} variant="h6" noWrap>
              Branch Preferences  2
                </Typography>
            <TextField
              margin="dense"
              placeholder='Branch Preferences (Max 3 /Any in (City)'
              fullWidth
              max='3'
              select
              value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.branch_prefer_2) || ''}
              onChange={(e) => { relHandler(e.target.value, 'branch_prefer_2') }}
              variant="outlined"
            >
              {branchList && branchList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.branch_name}
                >
                  {data.branch_name ? data.branch_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className={classes.label} variant="h6" noWrap>
              Branch Preferences 3
                </Typography>
            <TextField
              margin="dense"
              placeholder='Branch Preferences (Max 3 /Any in (City)'
              fullWidth
              max='3'
              select
              value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.branch_prefer_3) || ''}
              onChange={(e) => { relHandler(e.target.value, 'branch_prefer_3') }}
              variant="outlined"
            >
              {branchList && branchList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.branch_name}
                >
                  {data.branch_name ? data.branch_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">B.Ed</FormLabel>
              <RadioGroup
                row
                style={{ display: 'flex' }}
                style={{ flexDirection: 'row' }}
                // value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.b_ed) || ''}
                value={finalRadioValue}
                onChange={(e) => { relHandler(e.target.value, 'b_ed') }}
              >
                <FormControlLabel
                  value='true'
                  control={<Radio color="primary" />}
                  label="Yes"
                  disabled={applicantId ? true : false}
                  labelPlacement="start"
                />
                <FormControlLabel
                  value='false'
                  control={<Radio color="primary" />}
                  label="No"
                  disabled={applicantId ? true : false}
                  labelPlacement="start"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {
            (name && name.personal_info && name.personal_info.role === 'Recruiter') ? (
              <React.Fragment>
                <Grid item md={4}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Relevant Graduation</FormLabel>
                    <RadioGroup
                      row
                      style={{ display: 'flex' }}
                      value={(relDetails &&
                        String(relDetails[0].relevant_fk['relevant_graduation'])) ||
                        ''}
                      onChange={(e) => { relHandler(e.target.value, 'relevant_graduation') }}
                    >
                      <FormControlLabel
                        value='true'
                        control={<Radio color="primary" />}
                        label="Yes"
                        disabled={!applicantId ? true : false}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value='false'
                        control={<Radio color="primary" />}
                        label="No"
                        disabled={!applicantId ? true : false}
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Relevant Post Graduaton</FormLabel>
                    <RadioGroup
                      row
                      style={{ display: 'flex' }}
                      // style={{ flexDirection: 'row' }}
                      // value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.b_ed) || ''}
                      value={(relDetails &&
                        String(relDetails[0].relevant_fk['relevant_post_graduation'])) ||
                        ''}
                      onChange={(e) => { relHandler(e.target.value, 'relevant_post_graduation') }}
                    >
                      <FormControlLabel
                        value='true'
                        control={<Radio color="primary" />}
                        label="Yes"
                        disabled={!applicantId ? true : false}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value='false'
                        control={<Radio color="primary" />}
                        label="No"
                        disabled={!applicantId ? true : false}
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend">Extra Curricular Profile</FormLabel>
                    <RadioGroup
                      row
                      style={{ display: 'flex' }}
                      // style={{ flexDirection: 'row' }}
                      // value={(relDetails && relDetails.length && relDetails[0].relevant_fk && relDetails[0].relevant_fk.b_ed) || ''}
                      value={(relDetails &&
                        String(relDetails[0].relevant_fk['extra_curricular_profile'])) ||
                        ''}
                      onChange={(e) => { relHandler(e.target.value, 'extra_curricular_profile') }}
                    >
                      <FormControlLabel
                        value='true'
                        control={<Radio color="primary" />}
                        label="Yes"
                        disabled={!applicantId ? true : false}
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value='false'
                        control={<Radio color="primary" />}
                        label="No"
                        disabled={!applicantId ? true : false}
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className={classes.label} variant="h6" noWrap>
                    Relevant Experience (in years)
                    </Typography>
                  <TextField
                    margin="dense"
                    placeholder='Year'
                    type="number"
                    disabled={!applicantId ? true : false}
                    fullWidth
                    value={(relDetails &&
                      relDetails.length && relDetails[0].relevant_fk &&
                      relDetails[0].relevant_fk.is_relevant_experience) || ''}
                    onChange={(e) => { relHandler(e.target.value, 'is_relevant_experience') }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className={classes.label} variant="h6" noWrap>
                    Gross Pay (Per Month)
                </Typography>
                  <TextField
                    margin="dense"
                    placeholder="Gross"
                    type="number"
                    disabled={!applicantId ? true : false}
                    fullWidth
                    value={(relDetails &&
                      relDetails.length && relDetails[0].relevant_fk &&
                      relDetails[0].relevant_fk.gross_pay) || ''}
                    onChange={(e) => { relHandler(e.target.value, 'gross_pay') }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className={classes.label} variant="h6" noWrap>
                    Categories
                  </Typography>
                  <TextField
                    margin="dense"
                    placeholder="Categories"
                    fullWidth
                    max='3'
                    select
                    value={(relDetails &&
                      relDetails.length && relDetails[0].relevant_fk &&
                      relDetails[0].relevant_fk.category) || ''}
                    onChange={(e) => { relHandler(e.target.value, 'category') }}
                    variant="outlined"
                  >
                    {CATEGORIES && CATEGORIES.map(data => (
                      <MenuItem
                        value={data}
                        key={data}
                        name={data}
                      >
                        {data ? data : ''}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography className={classes.label} variant="h6" noWrap>
                    Pay Slip
                  </Typography>
                  <TextField
                    margin="dense"
                    placeholder="Pay Slip"
                    type="file"
                    disabled={!applicantId ? true : false}
                    fullWidth
                    onChange={(e) => { relHandler(e.target.files[0], 'pay_slip') }}
                    variant="outlined"
                    inputProps={{
                      "accept": "application/pdf, image/x-png, image/jpeg"
                    }}
                  />
                </Grid>
              </React.Fragment>
            ) : null
          }

          {(name && name.personal_info && name.personal_info.role !== 'Recruiter') && (
            <React.Fragment>
              <Grid item md={12} className={classes.gridOne}>
                <input style={{ display: 'none' }}
                  className={classes.fileUpload}
                  id="outlined-button-file"
                  type="file"
                  onChange={e => setToUpoladResumeFunction(e)}
                />
                <p>Upload File Should be <b>PDF</b> or <b>DOC</b> or <b>Video</b></p>
                <label htmlFor="outlined-button-file">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    disabled={applicantId ? true : false}
                    className={classes.fileUpload}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Resume
                 </Button>
                  {chooseResume && chooseResume.name ? chooseResume.name : chooseResume && !chooseResume.name ? <a target="_blank" href={chooseResume}>Resume</a> : ''}
                </label>
              </Grid>
            </React.Fragment>
          )}
          <Grid item md={12} className={classes.gridOne}>
            {(name &&
              name.personal_info &&
              name.personal_info.role === 'Recruiter' &&
              chooseResume &&
              (<Button
                variant="contained"
                color="primary"
                target="_blank"
                onClick={() => window.open(chooseResume)}>
                  View Resume
              </Button>)) ||
              (name &&
              name.personal_info &&
              name.personal_info.role === 'Recruiter' &&
              <p style={{ color: 'blue' }}> Resume is Not Upload</p>)
            }
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );


  function content() {
    if (activeStep === 0) {
      return personalInfo;
    } else if (activeStep === 1) {
      return relInfo;
    }

    return null;
  }
  return (
    <React.Fragment>
      <Typography className="headings" variant="h4"><strong>Edit Profile</strong></Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={3} className={classes.root}>
        <Grid item md={3} />
        <Grid item md={6}>
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel>Personal Info</StepLabel>
            </Step>
            <Step>
              <StepLabel>Relevant Info</StepLabel>
            </Step>
          </Stepper>
        </Grid>
        <Grid item md={5} />
        {content()}
        <Grid className={classes.nextButton}>
          <div>
            <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
              Back
              </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext()}
              className={classes.button}
            >
              {activeStep === 1 ? 'Submit' : 'Next'}
            </Button>
          </div>
        </Grid>
      </Grid>
      {loader}
    </React.Fragment>
  )
}

Profile.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  applicantId: PropTypes.number,
};

Profile.defaultProps = {
  applicantId: null,
};

export default withStyles(styles)(Profile);