export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(1, 2, 1),
      width:'100%',
      padding:'20px',
      marginTop:'10px',
      marginBottom:'10px'
    }, 
    button: {
        margin: theme.spacing(1),
        float:'right'
      },
      fileUpload : {
        textAlign: 'center',
        background: '#f7f7f7',
        padding: '20px',
        position: 'relative',
        borderRadius: '0px',
        float:'left',
      },
      label : {
        fontSize: '14px',
      },
      gride:{
          marginTop:'20px'
      },
      GridText:{
        marginTop:'100px',
        marginLeft:'11%',
        marginRight:'11%',
        fontSize:'20px'
        // marginRight:'25%',
        // position:'fixed'
      },
    });
  