import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Pagination,
  Rate,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { FilePdfOutlined, EditOutlined } from "@ant-design/icons";
import urls from "../../../url";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import debounce from "lodash/debounce";

function AllInterviews() {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [interviewList, setInterviewList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [applicantId, setApplicantId] = useState(null);
  const [interviewId, setInterviewId] = useState(null);

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [isClassDemo, setIsClassDemo] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchDebouncedText, setSearchDebouncedText] = useState("");
  const [form] = useForm();
  const [feedbackForm] = useForm();
  useEffect(() => {
    let value = form.getFieldsValue();
    fetchInterviews({
      page: page,
      page_size: pageSize,
      start_date__gte: value?.date_range
        ? dayjs(value?.date_range[0]).format("YYYY-MM-DD")
        : null,
      start_date__lte: value?.date_range
        ? dayjs(value?.date_range[1]).format("YYYY-MM-DD")
        : null,
      interview_completed: value?.interview_completed,
      is_class_demo: value?.is_class_demo,
      search: searchValue,
    });
  }, [page, pageSize, searchValue]);

  const fetchInterviews = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.allInterviewsApi}`, {
        params: { ...params },
      })
      .then((response) => {
        setInterviewList(response?.data?.results);
        setCount(response?.data?.count);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilter = () => {
    let value = form.getFieldsValue();
    fetchInterviews({
      page: page,
      page_size: pageSize,
      start_date__gte: value?.date_range
        ? dayjs(value?.date_range[0]).format("YYYY-MM-DD")
        : null,
      start_date__lte: value?.date_range
        ? dayjs(value?.date_range[1]).format("YYYY-MM-DD")
        : null,
      interview_completed: value?.interview_completed,
      is_class_demo: value?.is_class_demo,
      search: searchValue,
    });
  };

  function openFeedbackModel(id, F2fId) {
    setFeedbackModal(true);
    setApplicantId(id);
    setInterviewId(F2fId);
  }

  const compareTime = (current, startTime) => {
    const date1 = dayjs(current, "YYYY-MM-DD hh:mm A");
    const date2 = dayjs(startTime, "YYYY-MM-DD hh:mm A");
    if (date1.isBefore(date2)) {
      return true;
    } else {
      return false;
    }
  };
  const currentTime = dayjs().format("hh:mm A");
  const handleCloseFeedback = () => {
    setFeedbackModal(false);
    setIsClassDemo(false);
    feedbackForm.resetFields();
  };

  function submitFeedback(values) {
    console.log("iyfd");
    let formValue = {};
    formValue.applicent_Id = applicantId;
    formValue.f2f_id = interviewId;
    formValue.feedback_by = auth.personal_info?.user_id;
    if (isClassDemo) {
      let class_demo_feedback = {};
      Object.keys(values).forEach((key) => {
        if (key == "passing_status" || key == "red_flag") {
          formValue[key] = values[key];
        } else {
          class_demo_feedback[key] = values[key];
        }
      });
      formValue.class_demo_feedback = class_demo_feedback;
    } else {
      formValue = { ...formValue, ...values };
    }
    console.log({ formValue });
    setSubmitLoading(true);
    axios
      .post(`${urls.submitMarksApi}`, formValue)
      .then(() => {
        message.success("Feedback submitted successfully!");
        handleFilter();
        feedbackForm.resetFields();
        handleCloseFeedback();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  }

  const handleDebounce = useCallback(
    debounce((value) => {
      console.log({ value });
      setSearchValue(value);
    }, 500),
    []
  );
  return (
    <React.Fragment>
      <Row style={{ justifyContent: "space-between" }}>
        <Col>
          <div className="page_title">
            <strong>All Interviews</strong>
          </div>
        </Col>
        <Col>
          <Input
            placeholder="I'm Looking For Name/Email"
            name="subject"
            defaultValue={searchDebouncedText}
            value={searchDebouncedText}
            onChange={(e) => {
              setSearchDebouncedText(e.target.value);
              handleDebounce(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Card
          style={{ width: "100%" }}
          bodyStyle={{ padding: "0.5rem 0.75rem" }}
        >
          <Form
            id="filterForm"
            form={form}
            onFinish={handleFilter}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col md={6}>
                <Form.Item name="interview_completed" label="Interview Status">
                  <Select
                    placeholder="Select Status"
                    allowClear
                    style={{ width: "100%" }}
                  >
                    <Select.Option value={false}>
                      Pending Interviews
                    </Select.Option>
                    <Select.Option value={true}>
                      Completed Interviews
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item name="is_class_demo" label="Interview Type">
                  <Select
                    placeholder="Select Status"
                    allowClear
                    style={{ width: "100%" }}
                  >
                    <Select.Option value={false}>F2F</Select.Option>
                    <Select.Option value={true}>Class Demo</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item name="date_range" label="Date Range">
                  <DatePicker.RangePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={6}>
                <Form.Item label=" ">
                  <Button form="filterForm" htmlType="submit" type="primary">
                    Search
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Row>

      <Spin spinning={loading}>
        {interviewList?.length > 0 ? (
          <>
            <Row
              gutter={[16, 16]}
              style={{
                marginBlock: "16px",
                height: "calc(100vh - 320px)",
                overflowY: "scroll",
              }}
            >
              {interviewList?.map((item) => {
                const words = item?.applicant_data?.name?.split(" "); // Split name into words
                const initials = words
                  ?.map((word) => word.charAt(0))
                  ?.join("")
                  .toUpperCase();
                return (
                  <Col
                    md={{ span: 12 }}
                    xs={{ span: 24 }}
                    sm={{ span: 12 }}
                    lg={{ span: 8 }}
                  >
                    <Card>
                      <Space>
                        {item?.applicant_data?.photo ? (
                          <Avatar
                            src={<img src={item?.applicant_data?.photo} />}
                            size={60}
                          />
                        ) : (
                          <Avatar size={60}>{initials}</Avatar>
                        )}
                        <div className="">
                          <Row>
                            <div style={{ fontSize: "18px" }}>
                              <strong>
                                {" "}
                                {item?.applicant_data?.name || ""}
                              </strong>
                            </div>
                          </Row>
                          <Row>
                            <Typography>Applied for: </Typography>
                            <Typography style={{ marginLeft: "5px" }}>
                              {" "}
                              {item?.position_applied}
                            </Typography>
                          </Row>
                          <a
                            href={
                              item?.applicant_data?.resume ||
                              item?.applicant_data?.resume_link
                            }
                            target="__blank"
                          >
                            <Tag
                              color="processing"
                              icon={<FilePdfOutlined />}
                              style={{ marginTop: "5px" }}
                            >
                              Resume
                            </Tag>
                          </a>
                        </div>
                      </Space>
                      <Divider />
                      <Row style={{ marginTop: "8px" }}>
                        {!item?.interview_completed && (
                          <Tag
                            color="orange"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              openFeedbackModel(
                                item?.applicant,
                                item?.id,
                                item?.total_mark
                              );
                              setIsClassDemo(
                                item?.is_class_demo ? true : false
                              );
                            }}
                          >
                            Feedback <EditOutlined />
                          </Tag>
                        )}
                      </Row>
                      {!item?.interview_completed && (
                        <Row style={{ marginBlock: "8px" }}>
                          <div>
                            <strong>
                              Note: Please upload marks after completing
                              Interview.
                            </strong>
                          </div>
                        </Row>
                      )}
                      <div style={{ marginBlock: "8px" }}>
                        <div>
                          <strong>Interview Feedback</strong> :{" "}
                          {item?.is_class_demo && item?.comment
                            ? "-"
                            : item?.comment}
                        </div>
                        <div>
                          <strong>Evaluated by</strong> : {item?.feedback_by}
                        </div>
                        <div>
                          <strong>Candidate Rating</strong> : {item?.rating}
                        </div>
                        <div>
                          <strong>Interview Date</strong> :{" "}
                          {dayjs(item?.start_date)?.format("Do MMM,YYYY")}
                        </div>
                        <div>
                          <strong>Interview Created on</strong> :{" "}
                          {dayjs(item?.created_at)?.format("Do MMM,YYYY")}
                        </div>
                      </div>
                      <Row>
                        {" "}
                        {item?.interview_completed ? (
                          <Button
                            style={{ width: "100%", cursor: "pointer" }}
                            disabled
                          >
                            Interview Completed
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            style={{ width: "100%" }}
                            onClick={() => {
                              window.open(item?.zoom?.presenter_url || "");
                            }}
                            disabled={compareTime(
                              currentTime,
                              item?.zoom?.start_time
                            )}
                          >
                            {compareTime(
                              currentTime,
                              item?.zoom?.start_time
                            ) ? (
                              <b>
                                Interview Scheduled at&nbsp;
                                {item?.zoom?.start_time?.split(" ")[1] +
                                  " " +
                                  item?.zoom?.start_time?.split(" ")[2]}
                              </b>
                            ) : (
                              "Join Interview"
                            )}
                          </Button>
                        )}
                      </Row>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <Row>
              <Card
                style={{ width: "100%" }}
                bodyStyle={{ padding: "0.5rem 0.75rem" }}
              >
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={count}
                  showSizeChanger
                  pageSizeOptions={[10, 25, 50, 100]}
                  style={{ textAlign: "end" }}
                  onChange={(newPage, newPageSize) => {
                    setPage((prev) => newPage);
                    setPageSize((prev) => newPageSize);
                  }}
                />
              </Card>
            </Row>
          </>
        ) : (
          <Empty />
        )}
      </Spin>

      <Modal
        title={"Feedback"}
        onCancel={handleCloseFeedback}
        width={600}
        open={feedbackModal}
        footer={[
          <Button
            loading={submitLoading}
            type="primary"
            form="feedbackForm"
            htmlType="submit"
          >
            Submit
          </Button>,
        ]}
      >
        <Form
          id="feedbackForm"
          form={feedbackForm}
          layout="vertical"
          onFinish={submitFeedback}
        >
          {isClassDemo ? (
            <>
              <br />
              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="movement_rating"
                label="Rate movement across all benches rows & front to back"
              >
                <Rate style={{ marginLeft: "20px" }} count={10} />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="checking_notbooks_rating"
                label="Rate if he/she is checking notebooks of the kids"
              >
                <Rate style={{ marginLeft: "20px" }} count={10} isRequired />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="encouraging_kids_rating"
                label="Rate if he/she is encouraging & ensuring raising hands by
                kids while asking any questions"
              >
                <Rate style={{ marginLeft: "20px" }} count={10} isRequired />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="no_chorus_answer_rating"
                label={
                  <>
                    Rate if he/she is encouraging & ensuring
                    <i> NO CHORUS ANSWERS EVER </i>
                  </>
                }
              >
                <Rate style={{ marginLeft: "20px" }} count={10} isRequired />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="differentiation_rating"
                label="Rate if Differentiation is being followed across 3 category
                of students"
              >
                <Rate style={{ marginLeft: "20px" }} count={10} isRequired />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="public_speaking_rating"
                label="Rate if kids are being given chance to do public speaking
                in the class around the topic being taught"
              >
                <Rate style={{ marginLeft: "20px" }} count={10} isRequired />
              </Form.Item>

              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="subject_delivery_rating"
                label="Rate the technical subject delivery out of 40 marks"
              >
                <InputNumber type="number" max={40} min={0} />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                rules={[{ required: true, message: "Please add comment!" }]}
                name="comments"
                label="Feedback :"
              >
                <TextArea
                  placeholder="Comments"
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                />
              </Form.Item>
              <br />
              <Form.Item
                rules={[{ required: true, message: "Please give rating!" }]}
                name="rating"
                label="Candidate Rating"
              >
                <Rate
                  style={{ marginLeft: "20px", marginRight: "10px" }}
                  isRequired
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            rules={[{ required: true, message: "Please select status!" }]}
            name="passing_status"
            label="Shortlist Status :"
          >
            <Select
              allowClear
              placeholder="Select Status"
              style={{ width: "100%" }}
              onChange={(e) => {
                console.log("e", e);
              }}
            >
              <Select.Option key={1} value={true}>
                Shortlist
              </Select.Option>
              <Select.Option key={2} value={false}>
                Reject
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="red_flag" label="Red Flag (if any) :">
            <Input.TextArea rows={4} placeholder="Red Flag (if any)" />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default AllInterviews;
