import React, { useState, useEffect } from 'react';
import {
  TextField,
  withStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  MenuItem,
  Checkbox,
  Switch,
  TableContainer
} from '@material-ui/core';
import MediaQuery from "react-responsive";
import PropTypes from 'prop-types';
import styles from './experience.style';
import { useAlert } from "../../../../hoc/alert/alert";
import Loader from '../../../../hoc/loader'
import urls from '../../../../url'
import useFetch from '../../../../hoc/useFetch'
const Experience = ({ classes }) => {

  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [roleName, setRoleName] = useState('');
  const [orginationName, setOrginationName] = useState('');
  const [JoiningDate, setJoiningDate] = useState();
  const [passOutDate, setPassOutDate] = useState();
  const [categoryName, setCategoryName] = useState('');
  const [CurrentlyWorking, setCurrentlyWorking] = useState(false);
  const [experienceDetails, setexperienceDetails] = useState([]);
  const [loading, setloading] = useState(false);
  const [experienceDetailsRes, setexperienceDetailsRes] = useState('');

  const alert = useAlert();

  const positionType = [
    {
      value: "1",
      label: "Academic"
    },
    {
      value: "2",
      label: "Non Academic"
    },
    {
      value: "3",
      label: "IT Requriment"
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    }
  ];

  const {
    // data : submitExperienceDetailsRes,
    isLoading: submittingExperienceDetails,
    doFetch: fetchExperienceDetails
  } = useFetch(null);

  let loader = null;
  if (submittingExperienceDetails || loading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (name) {
      setloading(true);
      fetch(`${urls.submitBasicProfileApi}?type=2`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          setloading(false);
          alert.success('Successfully Recived');
          return res.json();
        }
        if (res.status !== 200) {
          setloading(false);
          alert.error('something went wrong please try again');
          return res.json();
        }
      }).then((data) => {
        setexperienceDetailsRes(data);
      })
    }
  }, [alert, name])

  useEffect(() => {
    if (experienceDetailsRes) {
      setexperienceDetails(experienceDetailsRes)
    }
  }, [experienceDetailsRes])

  function addDetaisFunction() {
    if (!categoryName) {
      alert.warning('Enter Category Name');
      return;
    } else if (!orginationName) {
      alert.warning('Enter Organization Name');
      return;
    } else if (!JoiningDate) {
      alert.warning('Enter Joining Date');
      return;
    } else if (!roleName) {
      alert.warning('Enter Role');
      return;
    } else if (CurrentlyWorking === false) {
      if (!passOutDate) {
        alert.warning('Enter Last Working Date');
        return;
      }
    }
    const UserDate = document.getElementById('passOutDateId').value;
    const joiningInfo = document.getElementById('joiningDate').value;
    if (new Date(UserDate).getTime() < new Date(joiningInfo).getTime()) {
      alert.warning('The passout Date must be Greater Than Joining Date');
      return false;
    }
    let data = experienceDetails;
    data.push(
      {
        'category': categoryName,
        'role': roleName,
        'organization_name': orginationName,
        'joining_date': JoiningDate,
        'passout_date': passOutDate,
        'currently_working': CurrentlyWorking
      }
    )
    setexperienceDetails(data);
    setRoleName('');
    setCategoryName('');
    setOrginationName('');
    setJoiningDate('');
    setPassOutDate('');
    setCurrentlyWorking(false);
  }

  function removeDetail(index) {
    setexperienceDetails((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  function submitExperience() {
    if (experienceDetails.length === 0) {
      alert.warning('Enter Experiance Details');
      return;
    }
    const data = {
      'experience': experienceDetails
    }
    fetchExperienceDetails({
      url: `${urls.submitBasicProfileApi}?type=2`,
      body: data,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }

  function handleJoiningDate(e) {
    const UserDate = document.getElementById('joiningDate').value;
    const ToDate = new Date();
    if (new Date(UserDate).getTime() > ToDate.getTime()) {
      alert.warning('The Joining Date must be less than or equal to today date');
      return false;
    }
    setJoiningDate(e.target.value)
  }

  function handlePassOutDate(e) {
    const UserDate = document.getElementById('passOutDateId').value;
    const joiningInfo = document.getElementById('joiningDate').value;
    const ToDate = new Date();
    if (!JoiningDate) {
      alert.warning('first Enter Joining Date');
      return false;
    }
    if (new Date(UserDate).getTime() < new Date(joiningInfo).getTime()) {
      alert.warning('The passout Date must be Greater Than Joining Date');
      return false;
    }
    if (new Date(UserDate).getTime() > ToDate.getTime()) {
      alert.warning('The passout Date must be less than or equal to today date');
      return false;
    }
    setPassOutDate(e.target.value)
  }

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              select
              required
              fullWidth
              margin='dense'
              label='Category'
              // className={classes.textField}
              className="textList"
              value={categoryName || ""}
              onChange={e => setCategoryName(e.target.value)}
              SelectProps={{
                MenuProps: {
                  className: classes.menu
                }
              }}
              variant='outlined'
            >
              {positionType.map(option => (
                <MenuItem key={option.value} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Role (Previous History)"
              margin="dense"
              required
              fullWidth
              className="textList"
              value={roleName || ''}
              onChange={e => setRoleName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Enter Organization"
              margin="dense"
              required
              fullWidth
              className="textList"
              value={orginationName || ''}
              onChange={e => setOrginationName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              helperText="Joining Date *"
              id="joiningDate"
              margin="dense"
              type="date"
              required
              fullWidth
              className="textList"
              value={JoiningDate || ''}
              onChange={e => handleJoiningDate(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              helperText="Passout Date *"
              id="passOutDateId"
              margin="dense"
              type="date"
              required
              fullWidth
              className="textList"
              value={passOutDate || ''}
              onChange={e => handlePassOutDate(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12}>
            Currently Working :
            <br />
            No
            <Switch
              checked={CurrentlyWorking || false}
              value={CurrentlyWorking || false}
              onChange={(e) => setCurrentlyWorking(e.target.checked)}
              color="primary"
            />
            yes
          </Grid>
          <Grid item md={3} xs={12}>
            <Button
              color="primary"
              variant="contained"
              style={{ marginTop: '8px' }}
              onClick={() => addDetaisFunction()}
              disabled={(!categoryName || !orginationName || !JoiningDate || !roleName) ? true : false}
            >
              ADD More +
            </Button>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        {experienceDetails && experienceDetails.length === 0 &&
          <Typography variant="h5" style={{ color: 'blue', textAlign: 'center' }}>Please Add Your Experience Details</Typography>
        }
        {experienceDetails && experienceDetails.length !== 0 &&
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell float="right">
                        <Typography>S.No</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Category</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Role Name</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Institute Name</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Joining Date</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Passout Date</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Currently Working</Typography>
                      </TableCell>
                      <TableCell float="right">
                        <Typography>Remove</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {experienceDetails && experienceDetails.length !== 0 &&
                      experienceDetails.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell float="right">
                            <Typography>{index + 1}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.category}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.role || ''}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.organization_name || ''}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.joining_date || ''}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.passout_date || ''}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Typography>{data.currently_working ? 'Yes' : 'No'}</Typography>
                          </TableCell>
                          <TableCell float="right">
                            <Button variant="contained" color="primary"
                              onClick={() => removeDetail(index)}>Remove</Button>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        }
        <Divider className={classes.divider} />
        <Grid item md={12} xs={12} style={{ textAlign: 'right' }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => submitExperience()}
            disabled={(experienceDetails.length === 0) ? true : false}
          >
            Submit
          </Button>
        </Grid>
      </Paper>
      {loader}
    </React.Fragment>
  )
}

Experience.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Experience);