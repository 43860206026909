export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: '5px 40px',
      marginTop: '15px',
    },
    gridOnee :{
      marginTop:'5px',
      marginBottom:'5px'
    },
    paper: {
      position: 'absolute',
      width: '60%',
      marginLeft: '15%',
      backgroundColor: theme.palette.background.paper,
    //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: true,
      // maxHeight: '400px',
    },
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    textField:{
      width:'100%'
    },
    button:{
      marginTop:'2%',
    }
  });
  