import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./styles.css";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Paper,
  Select,
  MenuItem,
  TablePagination,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Backdrop,
  InputBase,
  FormControl,
  FormHelperText,
  Modal,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import { Fetch } from "../../../hoc/fetch";
import Loader from "../../../hoc/loader";
import styles from "./applicentHistory.style";
import { useAlert } from "../../../hoc/alert/alert";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 750,
      minWidth: "fit-content",
    },
  },
};

const ApplicentHistory = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [topStartDate, setTopStartDate] = useState(null);
  const [topEndDate, setTopEndDate] = useState(null);
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [viewOpenning, setViewOpennings] = useState(null);
  const [csv, setCsv] = useState("");
  const [hRef, setHRef] = useState("");
  const [bottomHRef, setBottomHRef] = useState("");
  const [applicentName, setApplicentName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [testType, setTestType] = useState("");
  const [openAudio, setOpenAudio] = useState(null);
  const [viewId, setViewId] = useState("");
  const alert = useAlert();
  const [click, setClick] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  const [loading,setLoading] = useState(false);

  // Created by: Shiva Shankar

  const [createModal, setCreateModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState("");
  const [positionApply, setPositionApply] = useState("");
  const [applicantName, setApplicantName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [MobileNu, setMobileNu] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [confrmPassword, setConfrmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [expYear, setExpYear] = useState("");
  const [ctc, setCTC] = useState("");
  const [resume, setResume] = useState("");
  const [position, setPosition] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [currentCity, setCurrentCity] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [graduation, setGraduation] = useState("");
  const [postGraduation, setPostGraduation] = useState("");
  const [college, setCollege] = useState("");
  const [bED, setBED] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [branchPreference1, setBranchPreference1] = useState("");
  const [branchPreference2, setBranchPreference2] = useState("");
  const [branchPreference3, setBranchPreference3] = useState("");
  const [applicantEditForm, setApplicantEditForm] = useState(false);
  const [editPostionType, setEditPostionType] = useState("");
  const [deleteApplicantForm, setDeleteApplicationForm] = useState(false);
  const [selectedDeleteApplicant, setSelectedDeleteApplicant] = useState("");
  // if (mobNu.match(/[a-z@]/i)) {
  //   email_id = mobNu;
  // } else {
  //   mobile = mobNu;
  // }

  const [gradeCheck, setGradeCheck] = useState("");
  const [subjectCheck, setSubjectCheck] = useState("");
  const [branch, setBranch] = useState("");
  // const [checked, setChecked] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");

  const [editCity, setEditCity] = useState(false);
  const [selectedEditCity, setSelectedEditCity] = useState("");
  const [selectedEditCityData, setSelectedEditCityData] = useState([]);

  const [editBranch, setEditBranch] = useState(false);
  const [selectedEditBranch, setSelectedEditBranch] = useState("");
  const [selectedEditBranchData, setSelectedEditBranchData] = useState([]);

  const [data, setData] = useState({
    age: "",
    name: "hai",
  });

  const {
    data: branchesList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branchesEditList,
    isLoading: gettingEditBranches,
    doFetch: fetchEditBranches,
  } = useFetch([], { suppressAlert: true });

  const {
    data: grades,
    isLoading: gradesLoading,
    doFetch: fetchGrades,
  } = useFetch([], { suppressAlert: true });

  const {
    data: subjects,
    isLoading: subjectsLoading,
    doFetch: fetchSubjects,
  } = useFetch([], { suppressAlert: true });

  const { data: gradeList, doFetch: fetchGradeList } = useFetch(null);

  const { data: cityList, doFetch: fetchCityList } = useFetch([], { suppressAlert: true });
  const {
    data: subjectData,
    isLoading: subjectLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  function handleChange(event) {
    setData((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }
  const {
    data: regFormResponse,
    isLoading: regFormLoading,
    doFetch: formDetails,
  } = Fetch(null,{ suppressAlert: true});

  const { data: branchList, doFetch: fetchBranchList } = Fetch(null,{ suppressAlert: true});

  const { data: graduationList, doFetch: fetchGraduationList } = Fetch(null,{ suppressAlert: true});

  const { data: collegeList, doFetch: fetchCollegeList } = Fetch(null,{ suppressAlert: true});

  const { data: postGraduationList, doFetch: fetchPostGraduationList } = Fetch(
    null,{ suppressAlert: true}
  );

  useEffect(() => {
    fetchBranchList({
      url: urls.branch,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchGraduationList({
      url: urls.graduationList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchPostGraduationList({
      url: urls.postGraduationList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchCollegeList({
      url: urls.postCollegeList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [
    fetchBranchList,
    fetchGraduationList,
    fetchPostGraduationList,
    fetchCollegeList,
  ]);

  useEffect(() => {
    if (regFormResponse) {
      alert.warning(regFormResponse);
    }
  }, [regFormResponse]);

  const [checked, setChecked] = React.useState(true);
  const handleChanges = (event) => {
    setChecked(event.target.value);
  };

  const completeApplication = () => {
    setLoading(true)
    if (
      !name || !password || !confrmPassword || emailId
        ? !emailId
        : !email || MobileNu
          ? !MobileNu
          : !mobNo ||
          !currentCity ||
          // || !birthDate || !gender || !graduation || !postGraduation || !bED || !expYear || !ctc || !noticePeriod || !branchPreference1 || !branchPreference2 || !branchPreference3 ||
          !resume
    ) {
      alert.warning("Fill All The Fields");
      setLoading(false)
      return;
    }
    if (password !== confrmPassword) {
      alert.warning("Please enter correct Password");
      setLoading(false)
      return;
    }

    if ((emailId || email).match(/[a-z@]/i)) {
      const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(emailId || email)) {
        alert.warning("Please enter valid Email...");
        setLoading(false)
        return;
      }
    } else {
      var IndNum = /^[0]?[789]\d{9}$/;
      if (!IndNum.test(mobNo || MobileNu)) {
        alert.warning("Please enter valid mobile number...!");
        setLoading(false)
        return;
      }
    }
    const formData = new FormData();
    const origin = JSON.parse(sessionStorage.getItem("origin"));
    formData.append("name", applicantName);
    formData.append("contact_no", `${mobNo || MobileNu}`);
    formData.append("email", `${email || emailId}`);
    formData.append("password", password);
    formData.append("current_location", currentCity);
    formData.append("resume", resume);
    formData.append("is_child", checked);
    formData.append("origin", origin ? origin.params : "");
    {
      positionApply && formData.append("position_apply", positionApply?.id);
    }
    {
      positionApply?.title
        ? formData.append("position_name", positionApply?.title)
        : formData.append("role", "other");
    }
    formDetails({
      url: urls.registrationComplete,
      method: "Post",
      body: formData,
    });
    setLoading(false)
  };

  useEffect(() => {
    if (regFormResponse && regFormResponse) {
      alert.success("Registration completed successfully");
      setApplicantName("");
      setPassword("");
      setConfrmPassword("");
      setEmail("");
      setMobNo("");
      setResume("");
      setPositionType("");
      setCreateModal(false);
    }
    if (regFormResponse && regFormResponse === "Applicant already exists") {
      alert.warning("Applicant already exists");
    }
  },[regFormResponse]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  function handleBranch(e) {
    setBranch(e.target.value);
  }

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: f2fData,
    isLoading: gettingF2Fdata,
    doFetch: fetchF2Fdetails,
  } = useFetch(null, { suppressAlert: true });

  const {
    data: downloadMainCsvData,
    isLoading: downloadMainCsvLoader,
    doFetch: downloadMainCsv,
  } = useFetch(null);

  const {
    data: downloadApplicantHistoryCsvData,
    isLoading: downloadApplicantHistoryCsvLoader,
    doFetch: downloadApplicantHistoryCsv,
  } = useFetch(null);
  const [getViewDaata, fetchViewData] = useState();

  // const {
  //   data: getViewDaata,
  //   isLoading: gettingViewData,
  //   doFetch: fetchViewData,
  // } = useFetch(null);

  const {
    data: positionList,
    isLoading: gettingOpeningData,
    doFetch: fetchViewPositionData,
  } = useFetch(null,{ suppressAlert: true});

  let loader = null;
  if (
    gettingRoleList ||
    gettingViewOpennings ||
    gettingF2Fdata ||
    gettingOpeningData ||
    gettingBranches ||
    gradesLoading ||
    subjectsLoading ||
    subjectLoading ||
    regFormLoading ||
    gettingEditBranches
  ) {
    loader = <Loader open />;
  }

  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchGrades({
        url: `${urls.newGrade}?position_type=${positionType}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
      // fetchCityList({
      //   url: urls.cityList,
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${name.personal_info.token}`,
      //   },
      // });
      setChecked("");
      setCheckedLoc("");
      setBranch("");
      setGradeCheck("");
      setSubjectCheck("");
    }
    fetchCityList({
      url: urls.cityList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });

  }, [FetchRoleList, fetchCityList, name.personal_info.token, positionType]);

  // Applicant History

  useEffect(() => {
    if (positionType && RoleId && checkedLoc && branch) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [
    RoleId,
    fetchViewOpennings,
    name.personal_info.token,
    positionType,
    checkedLoc,
    branch,
    gradeCheck,
    subjectCheck,
  ]);

  // const downloadMainCsvFunc = () => {
  //   downloadMainCsv({
  //     url: `${urls.downloadMainCsv}?start_date=${topStartDate}&end_date=${topEndDate}&export_type=csv`,
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${name.personal_info.token}`
  //     }
  //   });
  // };

  useEffect(() => {
    if (gradeCheck) {
      fetchSubjects({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeCheck}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setSubjectCheck("");
    }
  }, [gradeCheck, fetchSubjects]);

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setBranch("");
    }
  }, [checkedLoc]);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }

  useEffect(() => {
    setHRef([
      {
        csv: `${urls.downloadMainCsv}?city_id=${checkedLoc}&branch_id=${branch}&start_date=${topStartDate}&end_date=${topEndDate}&export_type=csv`,
      },
    ]);
  }, [setHRef, topStartDate, topEndDate, checkedLoc, branch]);

  useEffect(() => {
    setBottomHRef([
      {
        csv: `${urls.downloadApplicantHistoryCsv}?role_id=${RoleId}&position_id=${viewOpenning}&start_date=${startDate}&end_date=${endDate}&export_type=csv`,
      },
    ]);
  }, [setBottomHRef, RoleId, viewOpenning, startDate, endDate]);

  // function downloadBottomCsvFunc() {
  //   // if (!RoleId || !positionType || !viewOpenning) {
  //   //   alert.warning("Fill all details");
  //   //   return;
  //   // }
  //   downloadMainCsv({
  //     url: `${urls.downloadMainCsv}?role_id=${RoleId}&position_id=${viewOpenning}&${startDate}&${endDate}`,
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${name.personal_info.token}`
  //     }
  //   });
  // }

  useEffect(() => {
    if (downloadMainCsvData || downloadApplicantHistoryCsvData) {
      setCsv(downloadMainCsvData || downloadApplicantHistoryCsvData);
    }
  }, [downloadMainCsvData || downloadApplicantHistoryCsvData]);

  // const startTest = id => {
  //   fetchQues({
  //     url: `${urls.applicentMcqTest}?round_id=${id}`,
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${auth.personal_info.token}`
  //     }
  //   });
  // };

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };
  const handleTopStartDateChange = (val) => {
    setTopStartDate(val);
  };
  const handleTopEndDateChange = (val) => {
    setTopEndDate(val);
  };
  const handleCloseAudio = () => {
    setOpenAudio(false);
  };
  const handleCloseEditCity = () => {
    setEditCity(false);
  };
  const handleCloseEditBranch = () => {
    setEditBranch(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  function functiontoSearchF2FDetails() {
    // if (!RoleId || !positionType || !viewOpenning) {
    //   alert.warning("Fill all details");
    //   return;
    // }
    fetchF2Fdetails({
      url: `${urls.f2fApplicentntHistoryApi}?${RoleId ? `&role_id=${RoleId}` : ""
        }${viewOpenning ? `&position_id=${viewOpenning}` : ""
        }&page_size=${rowsPerPage}&page=${page + 1}${startDate ? `&start_date=${startDate}` : ""
        }${endDate ? `&end_date=${endDate}` : ""
        }&position_type=${positionType}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (getViewDaata) {
      setOpenAudio(true);
    }
  }, [getViewDaata]);

  function openViewModel(id, test) {
    setTestType(test);
    setViewId(id);
    setClick(true);
  }
  useEffect(() => {
    if (click) {
      fetch(
        `${urls.ViewTestFileApi
        }?round_id=${viewId}&round_type=${testType.toLocaleLowerCase()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${name.personal_info.token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          fetchViewData(data);
        })
        .catch(() => {
          // alert.error("Failed to Complete Action");
        });
    }
    setClick(false);
  }, [click]);

  function handleEditCityOpen(data) {
    setSelectedEditCityData(data);
    setEditCity(true);
  }

  function handleEditBranchOpen(data) {
    setSelectedEditBranchData(data);
    fetchEditBranches({
      url: `${urls.branchCityMapping}?city_id=${data?.position_apply?.city?.id}`,
      method: "GET",
      flag: false,
      headers: {
        "Content-Type": "application/json",
      },
    });
    setEditBranch(true);
  }

  function handleUpdateCity() {
    console.log("selectedEditCity:", selectedEditCity);
    const updateObj = {
      city_id: selectedEditCity,
      position_id: selectedEditCityData?.position_apply?.id,
    };
    axios
      .put(`${urls.positionUpdateCityBranch}?status=City`, updateObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name?.personal_info?.token}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response?.status === 200) {
          setEditCity(false);
          alert.success("Updated City Successfully");
          functiontoSearchF2FDetails();
        }
      });
  }

  const EditCity = (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editCity}
        onClose={handleCloseEditCity}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
          className={classes.paperr}
          style={{ width: "-webkit-fill-available" }}
        >
          <Typography variant="h5">{`Update City`}</Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>City</Typography>
              <Select
                required
                fullWidth
                // multiple
                margin="dense"
                value={selectedEditCity || ""}
                onChange={(e) => setSelectedEditCity(e.target.value)}
                variant="outlined"
              >
                {cityList &&
                  cityList.length !== 0 &&
                  cityList.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.id}
                      name={option.id}
                    >
                      {option.city_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdateCity()}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Paper>
      </Modal>
    </React.Fragment>
  );

  function handleUpdateBranch() {
    console.log("selectedEditBranch:", selectedEditBranch);
    const updateObj = {
      branch_id: selectedEditBranch,
      position_id: selectedEditBranchData?.position_apply?.id,
    };
    axios
      .put(`${urls.positionUpdateCityBranch}?status=Branch`, updateObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name?.personal_info?.token}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response?.status === 200) {
          setEditBranch(false);
          alert.success("Updated Branch Successfully");
          functiontoSearchF2FDetails();
        }
      });
  }
  const EditBranch = (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={editBranch}
        onClose={handleCloseEditBranch}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
          className={classes.paperr}
          style={{ width: "-webkit-fill-available" }}
        >
          <Typography variant="h5">{`Update Branch`}</Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>Branch</Typography>
              <Select
                required
                fullWidth
                // multiple
                margin="dense"
                value={selectedEditBranch || ""}
                onChange={(e) => setSelectedEditBranch(e.target.value)}
                variant="outlined"
              >
                {branchesEditList &&
                  branchesEditList.length !== 0 &&
                  branchesEditList?.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.id}
                      name={option.id}
                    >
                      {option.branch_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={12} xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdateBranch()}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Paper>
      </Modal>
    </React.Fragment>
  );

  const Audio = (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openAudio}
        onClose={handleCloseAudio}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.paperr}>
          {testType === "f2f" ? (
            <Typography variant="h5">{`F2F Feedback`}</Typography>
          ) : (
            <Typography variant="h5">{`View  ${testType} File`}</Typography>
          )}
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            {testType === "written" && (
              <Grid item md={12} xs={12}>
                {/* <Typography variant='h7'>{`Topic : ${getViewDaata && getViewDaata.topic_name.topic_name} `}</Typography>
                <Divider className={classes.divider} /> */}
                {getViewDaata &&
                  (getViewDaata?.fileformat === ".docx" ||
                    getViewDaata?.fileformat === ".jpg" ||
                    getViewDaata?.fileformat === ".png" ||
                    getViewDaata?.fileformat === ".jpeg" ||
                    getViewDaata?.fileformat === "") ? (
                  <img
                    src={
                      getViewDaata && getViewDaata?.filedata?.written_test_file
                    }
                    alt="crash"
                    width="100%"
                    height="80%"
                  />
                ) : getViewDaata && getViewDaata?.fileformat === ".pdf" ? (
                  <a
                    target="_blank"
                    href={
                      (getViewDaata &&
                        getViewDaata?.filedata?.written_test_file) ||
                      ""
                    }
                    onclick="window.open(this.href); return false;"
                  >
                    {" "}
                    View File
                  </a>
                ) : getViewDaata?.fileformat === ".pptx" ||
                  getViewDaata?.fileformat === ".ppt" ||
                  getViewDaata?.fileformat === ".potx" ||
                  getViewDaata?.fileformat === ".pptm" ||
                  getViewDaata?.fileformat === ".xlsx" ? (
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${(getViewDaata &&
                      getViewDaata?.filedata?.written_test_file) ||
                      ""}`}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                  ></iframe>
                ) : (
                  <b>File not supported</b>
                )}
              </Grid>
            )}
            {testType === "spoken" && (
              <Grid item md={12} xs={12}>
                {/* <Typography variant='h7'>{`Topic : ${getViewDaata && getViewDaata.topic_name.topic_name} `}</Typography>
                <Divider className={classes.divider} /> */}
                {getViewDaata && getViewDaata.filedata.spoken_audio ? (
                  <audio controls preload="auto">
                    <source
                      src={getViewDaata && getViewDaata.filedata.spoken_audio}
                      type="audio/mpeg"
                    />
                  </audio>
                ) : (
                  <b>No Audio is found</b>
                )}
              </Grid>
            )}
            {testType === "f2f" && (
              <Grid item md={12} xs={12}>
                {/* <Typography variant='h7'>{`Topic : ${getViewDaata && getViewDaata.topic_name.topic_name} `}</Typography>
                <Divider className={classes.divider} /> */}
                {getViewDaata && getViewDaata.filedata.comment ? (
                  <div>
                    <Typography>{getViewDaata?.filedata?.comment}</Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="h5">
                      F2F Interview - {getViewDaata?.filedata?.meeting_name}
                    </Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="h6">
                      Interviewd by: {getViewDaata?.filedata?.user?.first_name}
                    </Typography>
                    <Button
                      href={getViewDaata?.response?.share_url}
                      style={{ backgroundColor: "blue" }}
                    >
                      Click to Play
                    </Button>
                    <form>
                      <Typography variant="h6">Passcode:</Typography>
                      <textarea
                        ref={textAreaRef}
                        value={getViewDaata?.response?.password}
                      />
                    </form>
                    {document.queryCommandSupported("copy") && (
                      <div>
                        <button onClick={copyToClipboard}>Copy</button>
                        {copySuccess}
                      </div>
                    )}
                  </div>
                ) : (
                  <b>No Feedback found</b>
                )}
              </Grid>
            )}
          </Grid>
          <Divider className={classes.divider} />
        </Paper>
      </Modal>
    </React.Fragment>
  );

  useEffect(() => {
    if (RoleId || positionType || viewOpenning) {
      functiontoSearchF2FDetails();
    }
  }, [page, rowsPerPage]);

  const fetchSearch = (e) => {
    setPage(0);
    setRowsPerPage(50);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicentName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };
  useEffect(() => {
    // if (applicentName) {
      fetchF2Fdetails({
        url: `${urls.f2fApplicentntHistoryApi}?${RoleId ? `&role_id=${RoleId}` : ""
          }${viewOpenning ? `&position_id=${viewOpenning}` : ""
          }&page_size=${rowsPerPage || 50}&page=${page + 1}${startDate ? `&start_date=${startDate}` : ""
          }${endDate ? `&end_date=${endDate}` : ""
          }&position_type=${positionType}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    // }
  }, [page,rowsPerPage]);

  useEffect(() => {
    // if (applicentName) {
      fetchF2Fdetails({
        url: `${urls.f2fApplicentntHistoryApi}?${RoleId ? `&role_id=${RoleId}` : ""
          }${viewOpenning ? `&position_id=${viewOpenning}` : ""
          }&dashboard_id=${history?.location?.state ? history?.location?.state : ""}&position_type=${positionType}&search=${applicentName || ''}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    // }
  }, [applicentName]);


  function getMobileDataDetails() {

    fetchF2Fdetails({
      url: `${urls.f2fApplicentntHistoryApi}?mobile_no=${mobileNo || ''}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }


  const fetchApplicantData = (e) => {
    setApplicentName(e.target.value);
  };

  const searchMobileData = (val) => {
    setMobileNo(val)
  }
  function functionSearch(data) {
    // fetchApplicent({
    //   url: `${urls.searchApplicentRecruiter}?page=${page +
    //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   }
    // });
  }

  // Created by: Shiva Shankar
  // Purpose: Create Applicant

  function createApplicantModal() {
    setCreateModal(true);
  }

  function handleCloseApplicantModal() {
    setCreateModal(false);
  }

  function handleEditApplicant() {
    const updateObj = {
      username: selectedApplicant?.user?.username,
      position_apply_id: editPostionType?.id,
    };
    axios
      .post(`${urls.updateUserPosition}`, updateObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response?.data?.status_code === 200) {
          setApplicantEditForm(false);
          alert.success(response?.data?.messsage);
          functiontoSearchF2FDetails();
        }
      });
  }
  function updateStatus(id, round_type, info, index) {
    const updateObj = {
      round_type: round_type,
      round_id: info?.round_id,
      id: id?.id,
      index: index,
    };
    axios
      .post(`${urls.updateStatus}`, updateObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response) {
          // setApplicantEditForm(false);
          // alert.success(response?.data?.messsage);
          functiontoSearchF2FDetails();
        }
      });
  }
  function updateStatusF2f(id, round_type, info, index) {
    const updateObj = {
      first_name: id.applicant.user.first_name,
      username: id.applicant.user.username,
      round_type: round_type,
      round_id: info?.round_id,
      id: id?.id,
      index: index,
    };
    axios
      .post(`${urls.updateStatusF2f}`, updateObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        console.log("response", response);
        if (response) {
          // setApplicantEditForm(false);
          // alert.success(response?.data?.messsage);
          functiontoSearchF2FDetails();
        }
      });
  }

  function handleCloseApplicantEditModal() {
    setApplicantEditForm(false);
  }

  // useEffect(() => {
  //   // if (auth) {
  //   getMobileDataDetails();
  //   // }
  // }, [mobileNo]);

  useEffect(() => {
    if (positionType && RoleId && checkedLoc && branch) {
      fetchViewPositionData({
        url: `${urls.fetchOpeningDataAllList}?opening_type=${positionType}&role=${RoleId}&city_id=${checkedLoc}&branch_id=${branch}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [positionType, fetchViewPositionData, RoleId, checkedLoc, branch]);

  function handleUpdateApplicant(data) {
    setSelectedApplicant(data);
    setApplicantEditForm(true);
  }

  function handleDeleteApplicant(data) {
    setSelectedDeleteApplicant(data);
    setDeleteApplicationForm(true);
  }
  function handleDeleteApplicantClose() {
    setDeleteApplicationForm(false);
  }

  function handleDeleteConfirmApplicant() {
    const deleteObj = {
      username: selectedDeleteApplicant?.user?.username,
      active_status: false,
    };
    axios
      .post(`${urls.deleteApplicant}`, deleteObj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.data.status_code === 200) {
          setDeleteApplicationForm(false);
          alert.success(response.data.messsage);
          functiontoSearchF2FDetails();
        }
      });
  }

  // useEffect(() => {
  //   if (selectedApplicant) {
  //     setEditPostionType(selectedApplicant?.position_apply)
  //   }
  // }, [selectedApplicant])

  return (
    <React.Fragment>
      {loading ? (
        <Loader open/>
      ) : (
        <>
        <Grid container spacing={2} className={classes.modalPaper}>
          <Grid item sm={12} md={6} xs={12}>
            <MediaQuery minWidth={451}>
              <Typography variant="h4">
                <strong>Applicant History</strong>
              </Typography>
            </MediaQuery>
            <MediaQuery maxWidth={450}>
              <Typography className="headings" variant="h4">
                <strong>Applicant History</strong>
              </Typography>
            </MediaQuery>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={classes.search}>
              <TextField
                id="search-bar"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                label="I'm Looking For..."
                variant="outlined"
                size="small"
                name="subject"
                autoComplete="on"
                onChange={fetchApplicantData}
              />
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2} className={classes.modalPaper}>
          {/* <Grid item md={3} /> */}
          {name.personal_info.role === "Admin" ||
            name.personal_info.role === "EA" ||
            name.personal_info.role === "Recruiter" ? (
            <Grid item md={2} xs={12}>
              <Button
                style={{ marginTop: "33px" }}
                variant="contained"
                color="primary"
                onClick={createApplicantModal}
              >
                Create Applicant
              </Button>
            </Grid>
          ) : (
            ""
          )}
          <div style={{ display: 'none'}}>
          <Grid item md={2} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>City <b style={{ color: "red" }}>*</b></Typography>
            <Select
              required
              fullWidth
              // multiple
              margin="dense"
              value={checkedLoc || ""}
              onChange={(e) => setCheckedLoc(e.target.value)}
              variant="outlined"
            >
              {cityList &&
                cityList.length !== 0 &&
                cityList?.map((option) => (
                  <MenuItem key={option.value} value={option.id} name={option.id}>
                    {option.city_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={2} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>Branch <b style={{ color: "red" }}>*</b></Typography>
            <Select
              required
              fullWidth
              // multiple
              margin="dense"
              value={branch || ""}
              onChange={handleBranch}
              variant="outlined"
            >
              {branchesList &&
                branchesList.length !== 0 &&
                branchesList?.map((option) => (
                  <MenuItem key={option.value} value={option.id} name={option.id}>
                    {option.branch_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={2} xs={12}>
            <Typography>Start Date <b style={{ color: "red" }}>*</b></Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={(e) => handleTopStartDateChange(e.target.value)}
              type="date"
              value={topStartDate || ""}
              variant="outlined"
              
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <Typography>End Date <b style={{ color: "red" }}>*</b></Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={(e) => handleTopEndDateChange(e.target.value)}
              type="date"
              value={topEndDate || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xss={12}>
            <Button
              style={{ marginTop: "33px" }}
              variant="contained"
              color="primary"
              href={hRef && hRef[0].csv}
              // onClick={downloadMainCsvFunc}
              disabled={topStartDate && topEndDate ? false : true}
            >
              Download CSV
            </Button>
          </Grid>
          </div>
          <Grid item md={2} xs={12}>
          {/* <Typography style={{margin: '5px'}}>Search with Mobile no.</Typography> */}
            <div className={classes.search} style={{marginTop: '32px',marginRight: '2px',}}>
              <TextField
                id="search-bar"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                label="Search with Mobile no."
                variant="outlined"
                size="small"
                name="subject"
                autoComplete="on"
                // type="number"
                inputProps={{ maxLength: 10 }}
                // onChange={searchMobileData}
                onChange={(e) => searchMobileData(e.target.value)}
              />
            </div>
          </Grid>
  
          <Grid item md={2} xs={12}>
              <Button
                style={{ marginTop: "33px" }}
                variant="contained"
                color="primary"
                onClick={getMobileDataDetails}
              >
                Search
              </Button>
            </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                {" "}
                Select Position Type <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                margin="dense"
                value={positionType || ""}
                onChange={(e, id) => {
                  setPositionType(e.target.value);
                }}
                variant="outlined"
              >
                {positionTypeList.map((option, index) => (
                  <MenuItem key={index} value={option.label} name={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                {" "}
                Select Role
              </Typography>
              <Select
                // required
                fullWidth
                margin="dense"
                value={RoleId || ""}
                onChange={(e) => setRoleId(e.target.value)}
                variant="outlined"
              >
                {roleList &&
                  roleList.length !== 0 &&
                  roleList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.applicant_role_name}
                    >
                      {option.applicant_role_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>City <b style={{ color: "red" }}>*</b></Typography>
              <Select
                required
                fullWidth
                // multiple
                margin="dense"
                value={checkedLoc || ""}
                onChange={(e) => setCheckedLoc(e.target.value)}
                variant="outlined"
              >
                {cityList &&
                  cityList.length !== 0 &&
                  cityList.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.id}
                      name={option.id}
                    >
                      {option.city_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>Branch <b style={{ color: "red" }}>*</b></Typography>
              <Select
                required
                fullWidth
                // multiple
                margin="dense"
                value={branch || ""}
                onChange={handleBranch}
                variant="outlined"
              >
                {branchesList &&
                  branchesList.length !== 0 &&
                  branchesList.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.id}
                      name={option.id}
                    >
                      {option.branch_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>{positionType == "Academic" ? "Grade" : "Level"}</Typography>
              <Select
                required
                fullWidth
                margin="dense"
                value={gradeCheck || ""}
                onChange={(e) => setGradeCheck(e.target.value)}
                variant="outlined"
              >
                {grades &&
                  grades.map((option) => (
                    <MenuItem key={option.id} value={option.id} name={option.id}>
                      {option.grade_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>{positionType == "Academic" ? "Subject" : "Topic"}</Typography>
              <Select
                required
                fullWidth
                margin="dense"
                value={subjectCheck || ""}
                onChange={(e) => setSubjectCheck(e.target.value)}
                variant="outlined"
              >
                {subjects &&
                  subjects &&
                  subjects.length > 0 &&
                  subjects.map((option) => (
                    <MenuItem
                      key={option.subject.id}
                      value={option.subject.id}
                      name={option.subject.id}
                    >
                      {option.subject.subject_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography style={{ marginBottom: "8px" }}>
                Select Opening <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                margin="dense"
                value={viewOpenning || ""}
                onChange={(e, values) => {
                  setViewOpennings(e.target.value);
                }}
                variant="outlined"
              >
                {viewOpenningsData &&
                  viewOpenningsData.length !== 0 &&
                  viewOpenningsData.map((option, index) => (
                    <MenuItem key={index} value={option.id} name={option.title}>
                      {option.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography>Start Date <b style={{ color: "red" }}>*</b></Typography>
              <TextField
                className={classes.textField}
                margin="dense"
                required
                fullWidth
                onChange={(e) => handleStartDateChange(e.target.value)}
                type="date"
                value={startDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Typography>End Date <b style={{ color: "red" }}>*</b></Typography>
              <TextField
                className={classes.textField}
                margin="dense"
                required
                fullWidth
                onChange={(e) => handleEndDateChange(e.target.value)}
                type="date"
                value={endDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                style={{ marginTop: "33px" }}
                variant="contained"
                color="primary"
                onClick={functiontoSearchF2FDetails}
              >
                Search
              </Button>
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                style={{ marginTop: "33px" }}
                variant="contained"
                color="primary"
                href={bottomHRef && bottomHRef[0].csv}
                disabled={
                  RoleId && viewOpenning || startDate || endDate ? false : true
                }
              // onClick={downloadBottomCsvFunc}
              >
                Download CSV
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Divider className={classes.divider} />
  
        {f2fData && f2fData.results.length === 0 && (
          <Typography
            variant="h5"
            style={{ textAlign: "center", marginTop: "30px" }}
          >
            No Records Found
          </Typography>
        )}
        {f2fData && f2fData.results.length !== 0 && (
          <Paper className={classes.paper} style={{ width: 'fit-content' }}>
            <Grid container spacing={2}>
              {f2fData && f2fData.results.length !== 0 && (
                <Grid item md={12} xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell float="left" style={{ border: '1px solid black' }}>
                          <Typography>
                            <strong>S.No</strong>
                          </Typography>
                        </TableCell>
                        <TableCell style={{ border: '1px solid black' }}>
                          <Typography style={{ width: "130px" }}>
                            <strong>Applicant Name</strong>
                          </Typography>
                        </TableCell>
                        <TableCell float="left" style={{ border: '1px solid black' }}>
                          <Typography>
                            <strong>Contact </strong>{" "}
                          </Typography>
                        </TableCell>
                        <TableCell float="left" style={{ border: '1px solid black' }}>
                          <Typography style={{ width: "40px" }}>
                            {" "}
                            <strong>Grade</strong>
                          </Typography>
                        </TableCell>
                        <TableCell float="left" style={{ border: '1px solid black' }}>
                          <Typography style={{ width: "40px" }}>
                            {" "}
                            <strong>Role</strong>
                          </Typography>
                        </TableCell>
                        <TableCell float="left" style={{ border: '1px solid black' }}>
                          <Typography style={{ width: "40px" }}>
                            {" "}
                            <strong>City</strong>
                          </Typography>
                        </TableCell>
                        <TableCell float="left" style={{ border: '1px solid black' }}>
                          <Typography>
                            <strong>Branch</strong>
                          </Typography>
                        </TableCell>
                        {/* <TableCell float="left" style={{ border: '1px solid black' }}>
                          <Typography>
                            <strong>Origin</strong>
                          </Typography>
                        </TableCell> */}
  
                        <TableCell style={{ border: '1px solid black' }}>
                          <Typography style={{ width: "150px" }}>
                            <strong>Registration Date </strong>{" "}
                          </Typography>
                        </TableCell>
  
                        <TableCell style={{ border: '1px solid black' }}>
                          <Typography style={{ width: "150px" }}>
                            <strong>Is Child In Same Branch </strong>{" "}
                          </Typography>
                        </TableCell>
                        {/* <TableCell  >
                          <Typography style={{ width: "130px" }} >
                            <strong >Recruiter Name </strong>
                          </Typography>
                        </TableCell> */}
  
                        {/* {f2fData &&
                        f2fData.results.length &&
                        f2fData.results[0].response.length &&
                        f2fData.results[0].response ? (
                          f2fData.results[0].response.map((data, index) => (
                            <TableCell float="left" key={index}>
                              <Typography>
                                <strong>{data.round_type.toUpperCase()}</strong>
                              </Typography>
                            </TableCell>
                          ))
                        ) : (
                          <> */}
                        <TableCell style={{ border: '1px solid black' }}>
                          <Typography
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <strong>Rounds</strong>
                          </Typography>
                        </TableCell>
                        <TableCell style={{ border: '1px solid black' }}>
                          <Typography
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <strong>Marks</strong>
                          </Typography>
                        </TableCell>
                        <TableCell style={{ border: '1px solid black' }}>
                          <Typography
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <strong>Update Status</strong>
                          </Typography>
                        </TableCell>
                        <TableCell style={{ border: '1px solid black' }}>
                          <Typography
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <strong>F2F Status</strong>
                          </Typography>
                        </TableCell>
                        <TableCell style={{ border: '1px solid black' }}>
                          <Typography
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <strong>Actions</strong>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {f2fData &&
                        f2fData.results.length !== 0 &&
                        f2fData.results.map((data, index) => (
                          <TableRow key={index}>
                            <TableCell style={{ border: '1px solid black' }}>{index + 1}</TableCell>
                            <TableCell style={{ border: '1px solid black' }}>
                              {(data.applicant &&
                                data.applicant.user &&
                                data.applicant.user.first_name) ||
                                ""}
                            </TableCell>
                            <TableCell style={{ border: '1px solid black' }}>
                              {(data.applicant && data.applicant.user.username) ||
                                ""}
                            </TableCell>
  
                            <TableCell style={{ border: '1px solid black' }}>
                              {(data.applicant &&
                                data.applicant.position_apply &&
                                data.applicant.position_apply.grade &&
                                data.applicant.position_apply.grade.grade_name) ||
                                ""}
                            </TableCell>
                            <TableCell style={{ border: '1px solid black' }}>
                              {(data?.applicant &&
                                data?.applicant?.applicant_role &&
                                data?.applicant?.applicant_role
                                  ?.applicant_role_name) ||
                                ""}
                            </TableCell>
                            <TableCell style={{ border: '1px solid black' }}>
                              <Button
                                color="primary"
                                onClick={() => handleEditCityOpen(data.applicant)}
                              >
                                {(data.applicant &&
                                  data.applicant.user &&
                                  data.applicant.position_apply &&
                                  data.applicant.position_apply.city
                                    ?.city_name) ||
                                  ""}
                                <EditIcon />
                              </Button>
                            </TableCell>
                            <TableCell style={{ border: '1px solid black' }}>
                              <Button
                                color="primary"
                                onClick={() =>
                                  handleEditBranchOpen(data.applicant)
                                }
                              >
                                {(data.applicant &&
                                  data.applicant.user &&
                                  data.applicant.position_apply &&
                                  data.applicant.position_apply.branch
                                    ?.branch_name) ||
                                  ""}
                                <EditIcon />
                              </Button>
                            </TableCell>
                            {/* <TableCell style={{ border: '1px solid black' }}>
                              {(data.applicant &&
                                data.applicant.relevant_fk &&
                                data.applicant.relevant_fk.basic_profile &&
                                data.applicant.relevant_fk.basic_profile
                                  .origin) ||
                                ""}
                            </TableCell> */}
                            <TableCell style={{ textAlign: "left", border: '1px solid black' }}>
                              {(data.applicant && data.applicant.register_date) ||
                                ""}
                            </TableCell>
                            <TableCell style={{ textAlign: "left", border: '1px solid black' }}>
                              {data.applicant.is_child_in_same_branch ? "Yes" : "No"}
                            </TableCell>
                            {/* <TableCell style={{ textAlign: "left" }}>
                              {(data.recruiter && data.recruiter.first_name) ||
                                ""}
                            </TableCell> */}
                            <TableCell float="left" style={{ border: '1px solid black' }}>
                              <Typography>
                                {data &&
                                  data.response &&
                                  data.response.map((info, indexa) => (
                                    <TableCell
                                      float="left"
                                      key={indexa}
                                      style={{ display: "flex", border: "none" }}
                                    >
                                      <Typography>
                                        <b>{info.round_type || " "}</b>
                                      </Typography>
                                    </TableCell>
                                  ))}
                              </Typography>
                            </TableCell>
                            <TableCell float="left" style={{ borderBottom: '1px solid black' }}>
                              <Typography>
                                {data &&
                                  data.response &&
                                  data.response.map((info, indexa) => (
                                    <TableCell
                                      float="left"
                                      key={indexa}
                                      style={{
                                        display: "flex",
                                        width: "200px",
                                        justifyContent: "center",
                                        border: "none",
                                      }}
                                    >
                                      {info.marks_scored !== "Not Completed" &&
                                        (info.round_type === "spoken" ||
                                          info.round_type === "written" ||
                                          info.round_type === "f2f") ? (
                                        <Button
                                          style={{ backgroundColor: "white" }}
                                          onClick={() =>
                                            openViewModel(
                                              info.round_id,
                                              info.round_type
                                            )
                                          }
                                        >
                                          <Typography
                                            style={{ fontSize: "15px" }}
                                          >
                                            {info?.marks_scored !== null &&
                                              info?.round_clear === true ? (
                                              <u style={{ color: "green" }}>
                                                {info?.marks_scored} - Qualified
                                              </u>
                                            ) : info?.marks_scored === null &&
                                              info?.round_clear === true ? (
                                              <u style={{ color: "blue" }}>
                                                Upload - Pending
                                              </u>
                                            ) : info?.marks_scored === null &&
                                              info?.round_clear === false ? (
                                              <u style={{ color: "blue" }}>
                                                Upload - Pending
                                              </u>
                                            ) : info?.marks_scored === null &&
                                              info?.round_clear === null ? (
                                              <u style={{ color: "blue" }}>
                                                Upload - Pending
                                              </u>
                                            ) : (
                                              <u style={{ color: "red" }}>
                                                {info?.marks_scored} - Rejected
                                              </u>
                                            )}
                                          </Typography>
                                        </Button>
                                      ) : info.round_type === "mcq" ? (
                                        <>
                                          <Typography
                                            style={{ fontSize: "15px" }}
                                          >
                                            {info?.marks_scored !== null &&
                                              info?.round_clear === true ? (
                                              <span style={{ color: "green" }}>
                                                {info?.marks_scored} - QUALIFIED
                                              </span>
                                            ) : info?.marks_scored !== null &&
                                              info?.round_clear === false ? (
                                              <span style={{ color: "red" }}>
                                                {info?.marks_scored} - REJECTED
                                              </span>
                                            ) : (
                                              <span>{info?.marks_scored}</span>
                                            )}
                                          </Typography>
                                        </>
                                      ) : (
                                        <Typography style={{ fontSize: "15px" }}>
                                          {info.marks_scored || " "}
                                        </Typography>
                                      )}
                                    </TableCell>
                                  ))}
                              </Typography>
                            </TableCell>
                            <TableCell float="left" style={{ borderBottom: '1px solid black' }}>
                              <Typography style={{ marginLeft: "-60px" }}>
                                {data &&
                                  data.response &&
                                  data.response.map((info, indexa) => (
                                    <TableCell
                                      float="left"
                                      key={indexa}
                                      style={{
                                        display: "flex",
                                        width: "200px",
                                        justifyContent: "center",
                                        border: "none",
                                      }}
                                    >
                                      {info.marks_scored !== "Not Completed" &&
                                        (info.round_type === "spoken" ||
                                          info.round_type === "written") ? (
                                        <Button
                                          style={{ backgroundColor: "white" }}
                                        // onClick={() =>
                                        //   openViewModel(
                                        //     info.round_id,
                                        //     info.round_type
                                        //   )
                                        // }
                                        >
                                          <Typography
                                            style={{ fontSize: "15px" }}
                                          >
                                            {info?.marks_scored !== null &&
                                              info?.round_clear === true ? (
                                              <Button
                                                color="primary"
                                                variant="outlined"
                                                disabled={
                                                  info?.round_assign === true
                                                    ? true
                                                    : false
                                                }
                                                style={{
                                                  whiteSpace: "noWrap",
                                                  fontSize: "9px",
                                                }}
                                                onClick={() =>
                                                  updateStatus(
                                                    data,
                                                    info.round_type,
                                                    info,
                                                    indexa
                                                  )
                                                }
                                              >
                                                update status
                                              </Button>
                                            ) : info?.marks_scored === null &&
                                              info?.round_clear === true ? (
                                              <Button
                                                color="primary"
                                                variant="outlined"
                                                // disabled={
                                                //   info.round_complete ===true && info.previous_round ===true ? true :false
                                                // }
                                                disabled={
                                                  info?.round_assign === true
                                                    ? true
                                                    : false
                                                }
                                                style={{
                                                  whiteSpace: "noWrap",
                                                  fontSize: "9px",
                                                }}
                                                onClick={() =>
                                                  updateStatus(
                                                    data,
                                                    info.round_type,
                                                    info,
                                                    indexa
                                                  )
                                                }
                                              >
                                                update status
                                              </Button>
                                            ) : info?.marks_scored === null &&
                                              info?.round_clear === false ? (
                                              <Button
                                                color="primary"
                                                variant="outlined"
                                                // disabled={
                                                //   info.round_complete ===true && info.previous_round ===true ? true :false
  
                                                //  }
                                                disabled={
                                                  info?.round_assign === true
                                                    ? true
                                                    : false
                                                }
                                                style={{
                                                  whiteSpace: "noWrap",
                                                  fontSize: "9px",
                                                }}
                                                onClick={() =>
                                                  updateStatus(
                                                    data,
                                                    info.round_type,
                                                    info,
                                                    indexa
                                                  )
                                                }
                                              >
                                                update status
                                              </Button>
                                            ) : info?.marks_scored === null &&
                                              info?.round_clear === null ? (
                                              <Button
                                                color="primary"
                                                variant="outlined"
                                                // disabled={
                                                //   info.round_complete ===true && info.previous_round ===true ? true :false
  
                                                //  }
                                                disabled={
                                                  info?.round_assign === true
                                                    ? true
                                                    : false
                                                }
                                                style={{
                                                  whiteSpace: "noWrap",
                                                  fontSize: "9px",
                                                }}
                                                onClick={() =>
                                                  updateStatus(
                                                    data,
                                                    info.round_type,
                                                    info,
                                                    indexa
                                                  )
                                                }
                                              >
                                                update status
                                              </Button>
                                            ) : (
                                              <Button
                                                color="primary"
                                                variant="outlined"
                                                // disabled={
                                                //   info.round_complete ===true && info.previous_round ===true ? true :false
                                                // }
                                                disabled={
                                                  info?.round_assign === true
                                                    ? true
                                                    : false
                                                }
                                                style={{
                                                  whiteSpace: "noWrap",
                                                  fontSize: "9px",
                                                }}
                                                onClick={() =>
                                                  updateStatus(
                                                    data,
                                                    info.round_type,
                                                    info,
                                                    indexa
                                                  )
                                                }
                                              >
                                                update status
                                              </Button>
                                            )}
                                          </Typography>
                                        </Button>
                                      ) : info.round_type === "mcq" ? (
                                        <>
                                          <Typography
                                            style={{ fontSize: "15px" }}
                                          >
                                            {info?.marks_scored !== null &&
                                              info?.round_clear === true ? (
                                              <Button
                                                disabled
                                                color="primary"
                                                style={{
                                                  whiteSpace: "noWrap",
                                                  fontSize: "9px",
                                                }}
                                                variant="outlined"
                                              >
                                                update status
                                              </Button>
                                            ) : info?.marks_scored !== null &&
                                              info?.round_clear === false ? (
                                              <span style={{ color: "red" }}>
                                                <Button
                                                  disabled
                                                  color="primary"
                                                  style={{
                                                    whiteSpace: "noWrap",
                                                    fontSize: "9px",
                                                  }}
                                                  variant="outlined"
                                                >
                                                  update status
                                                </Button>{" "}
                                              </span>
                                            ) : (
                                              <span>
                                                <Button
                                                  disabled
                                                  color="primary"
                                                  variant="outlined"
                                                  style={{
                                                    whiteSpace: "noWrap",
                                                    fontSize: "9px",
                                                  }}
                                                >
                                                  update status
                                                </Button>
                                              </span>
                                            )}
                                          </Typography>
                                        </>
                                      ) : (
                                        <>
                                          {info?.round_type === "f2f" ? (
                                            <Button
                                              color="primary"
                                              variant="outlined"
                                              disabled
                                              style={{
                                                whiteSpace: "noWrap",
                                                fontSize: "9px",
                                              }}
                                            // onClick={() =>
                                            //   updateStatusF2f(
                                            //     data,
                                            //     info.round_type,
                                            //     info,
                                            //     indexa
                                            //   )
                                            // }
                                            >
                                              update status
                                            </Button>
                                          ) : (
                                            <Typography
                                              style={{ fontSize: "15px" }}
                                            >
                                              <Button
                                                color="primary"
                                                variant="outlined"
                                                disabled={
                                                  info.round_assign === true
                                                    ? true
                                                    : false
                                                }
                                                style={{
                                                  whiteSpace: "noWrap",
                                                  fontSize: "9px",
                                                }}
                                                onClick={() =>
                                                  updateStatus(
                                                    data,
                                                    info.round_type,
                                                    info,
                                                    indexa
                                                  )
                                                }
                                              >
                                                update status
                                              </Button>
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ border: '1px solid black' }}>
  
                              {data &&
                                data.response &&
                                data.response.map((info, indexa) => (
                                  <TableCell
                                    float="left"
                                    key={indexa}
                                    style={{
                                      display: "flex",
                                      width: "200px",
                                      justifyContent: "center",
                                      border: "none",
                                    }}
                                  >
  
  
                                    {info?.round_type === "f2f" ? (
                                      <Button
                                        color="primary"
                                        variant="outlined"
                                        disabled={
                                          info?.round_assign === true ? true : false
                                        }
                                        style={{
                                          whiteSpace: "noWrap",
                                          fontSize: "9px",
                                        }}
                                        onClick={() =>
                                          updateStatusF2f(
                                            data,
                                            info.round_type,
                                            info,
                                            indexa
                                          )
                                        }
                                      >
                                        update status
                                      </Button>
                                    ) : ""}
                                  </TableCell>))}
                            </TableCell>
                            <TableCell style={{ border: '1px solid black' }}>
                              <Button
                                color="primary"
                                onClick={() =>
                                  handleUpdateApplicant(data.applicant)
                                }
                                disabled={
                                  data?.response[1]?.round_complete === true
                                    ? true
                                    : false
                                }
                              >
                                <EditIcon />
                              </Button>
                              {/* <Button
                                color="primary"
                                onClick={() => handleDeleteApplicant(data.applicant)}
                              >
                                <DeleteIcon />
                              </Button> */}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TablePagination
                          colSpan={6}
                          labelDisplayedRows={() =>
                            `Page ${page + 1} of ${+f2fData.total_pages}`
                          }
                          rowsPerPageOptions={[10, 25, 50, 75, 100]}
                          count={+f2fData.count}
                          rowsPerPage={rowsPerPage || 50}
                          page={page}
                          SelectProps={{
                            inputProps: { "aria-label": "Rows per page" },
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                        <TableCell style={{ marginTop: "13px" }}>
                          <IconButton
                            onClick={firstPageChange}
                            disabled={page === 0 || page === 1}
                          >
                            <FirstPageIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              lastPageChange(f2fData.total_pages - 1)
                            }
                            disabled={page === +f2fData.total_pages - 1}
                          >
                            <LastPageIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
        {Audio}
        {EditCity}
        {EditBranch}
        {loader}
        {createModal ? (
          <Dialog
            open={createModal}
            // onClose={handleCloseApplicantModal}
            aria-labelledby="responsive-dialog-title"
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
  
            }}
          // fullScreen={fullScreen}
          >
            <DialogTitle id="responsive-dialog-title">
              Create Applicant
            </DialogTitle>
            <DialogContent >
              <DialogContentText id="responsive-dialog-description" tabIndex={-1}>
                <Grid className={classes.registraionPage}>
                  <Paper className={classes.paper}>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Select Position Type: <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Select
                          required
                          fullWidth
                          margin="dense"
                          value={positionType || ""}
                          onChange={(e, id) => {
                            setPositionType(e.target.value);
                          }}
                          variant="outlined"
                        >
                          {positionTypeList.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option.label}
                              name={option.id}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      {/* <Grid item md={4} /> */}
                      {positionType && (
                        <>
                          <Grid item md={4} />
                          <Grid item md={4} xs={12}>
                            <Typography
                              className={classes.label}
                              style={{ float: "right" }}
                              noWrap
                            >
                              {" "}
                              Select Role:<b style={{ color: "red" }}>*</b>
                            </Typography>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Select
                              // required
                              fullWidth
                              margin="dense"
                              value={RoleId || ""}
                              onChange={(e) => setRoleId(e.target.value)}
                              variant="outlined"
                            >
                              {roleList &&
                                roleList.length !== 0 &&
                                roleList.map((option, index) => (
                                  <MenuItem
                                    key={index}
                                    value={option.id}
                                    name={option.applicant_role_name}
                                  >
                                    {option.applicant_role_name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Grid>
                        </>
                      )}
                      <Grid item md={4} />
                      <Grid item md={4} xs={12}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          Select City:<b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Select
                          required
                          fullWidth
                          // multiple
                          margin="dense"
                          value={checkedLoc || ""}
                          onChange={(e) => setCheckedLoc(e.target.value)}
                          variant="outlined"
                        >
                          {cityList &&
                            cityList.length !== 0 &&
                            cityList.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.id}
                                name={option.id}
                              >
                                {option.city_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      <Grid item md={4} />
                      <Grid item md={4} xs={12}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          Select Branch:<b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Select
                          required
                          fullWidth
                          // multiple
                          margin="dense"
                          value={branch || ""}
                          onChange={handleBranch}
                          variant="outlined"
                        >
                          {branchesList &&
                            branchesList.length !== 0 &&
                            branchesList.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.id}
                                name={option.id}
                              >
                                {option.branch_name}
                              </MenuItem>
                            ))}
                        </Select>
                      </Grid>
                      {/* <Grid item md={4} /> */}
                      {positionType && (
                        <>
                          <Grid item md={4} />
                          <Grid item md={4}>
                            <Typography
                              className={classes.label}
                              style={{ float: "right" }}
                              noWrap
                            >
                              {" "}
                              Select Position : <b style={{ color: "red" }}>*</b>
                            </Typography>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormControl style={{ display: "unset" }}>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                margin="dense"
                                required
                                fullWidth
                                onChange={(e) => setPositionApply(e.target.value)}
                                value={positionApply || " "}
                                variant="outlined"
                                type="text"
                                // input={<OutlinedInput label="Organization" />}
                                MenuProps={MenuProps}
                              >
                                <MenuItem
                                  style={{ minWidth: "fit-content" }}
                                  value=""
                                >
                                  <em>None</em>
                                </MenuItem>
                                {positionList &&
                                  positionList
                                    ?.map((option) => (
                                      <MenuItem
                                        style={{ minWidth: "fit-content" }}
                                        key={option.id}
                                        value={option}
                                      >
                                        {option.title}
                                      </MenuItem>
                                    ))
                                    .sort()}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid item md={4} />
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Full Name : <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          margin="dense"
                          required
                          placeholder="Name"
                          fullWidth
                          value={applicantName}
                          onChange={(e) => setApplicantName(e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} />
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Email Id : <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          type="email"
                          margin="dense"
                          disabled={emailId ? true : false}
                          required
                          placeholder="Email"
                          fullWidth
                          value={emailId || email}
                          onChange={(e) => setEmail(e.target.value)}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} />
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Mobile Number : <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          type="number"
                          margin="dense"
                          placeholder="Mobile Number"
                          disabled={MobileNu ? true : false}
                          fullWidth
                          value={MobileNu || mobNo}
                          onChange={(e) =>
                            setMobNo(
                              Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 10)
                            )
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={4} />
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Is Your Child Study At Same Branch*
                          : <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12} onChange={handleChanges}>
                        <input type="radio" value="true" checked={checked === "true"}
                        />Yes
                        <input type="radio" value="false" checked={checked === "false"} />No
                      </Grid>
                      <Grid item md={4} />
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Current City : <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <form noValidate>
                        <TextField
                          margin="dense"
                          required
                          fullWidth
                          placeholder="Current City"
                          value={currentCity}
                          onChange={(e) => setCurrentCity(e.target.value)}
                          variant="outlined"
                        />
  
                        </form>
                      </Grid>
                      <Grid item md={4} />
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Password : <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <form noValidate>
                        <TextField
                          margin="dense"
                          type={showPassword ? "text" : "password"}
                          fullWidth
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
  
                        </form>
                      </Grid>
                      <Grid item md={4} />
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Confirm Password : <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <form noValidate>
                        <TextField
                          margin="dense"
                          type={showCPassword ? "text" : "password"}
                          required
                          placeholder="Confirm Password"
                          fullWidth
                          value={confrmPassword}
                          onChange={(e) => setConfrmPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowCPassword}
                                  onMouseDown={handleMouseDownCPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                        </form>
                        {password &&
                          confrmPassword &&
                          confrmPassword !== password ? (
                          <FormHelperText id="component-error-text">
                            Confirm Password should match password
                          </FormHelperText>
                        ) : null}
                      </Grid>
                      <Grid item md={4} />
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Upload Resume : <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <input
                          className={classes.fileUpload}
                          type="file"
                          accept="application/pdf,application/vnd.ms-excel"
                          onChange={(e) => setResume(e.target.files[0])}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "left" }}
                          noWrap
                        >
                          {" "}
                          <i>
                            <b>(format: .pdf)</b>{" "}
                          </i>
                        </Typography>
                      </Grid>
                      <Grid item md={4} />
                    </Grid>
                  </Paper>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-around" }}>
              <Button
                onClick={handleCloseApplicantModal}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  float: "left",
                }}
              >
                Cancel
              </Button>
  
              <Button
                onClick={completeApplication}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {applicantEditForm ? (
          <Dialog
            open={applicantEditForm}
            onClose={handleCloseApplicantEditModal}
            aria-labelledby="responsive-dialog-title"
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
            fullScreen={fullScreen}
          >
            <DialogTitle id="responsive-dialog-title">Edit Applicant</DialogTitle>
            <DialogContent>
              <DialogContentText id="responsive-dialog-description" tabIndex={-1}>
                <Grid className={classes.registraionPage}>
                  <Paper className={classes.paper}>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography
                          className={classes.label}
                          style={{ float: "left" }}
                          noWrap
                        >
                          {" "}
                          Position Applied For:{" "}
                          <b style={{ color: "green" }}>
                            {selectedApplicant?.position_apply?.title}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography
                          className={classes.label}
                          style={{ float: "right" }}
                          noWrap
                        >
                          {" "}
                          Select Position Type: <b style={{ color: "red" }}>*</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Select
                          required
                          fullWidth
                          margin="dense"
                          value={positionType || ""}
                          onChange={(e, id) => {
                            setPositionType(e.target.value);
                          }}
                          variant="outlined"
                        >
                          {positionTypeList.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option.label}
                              name={option.id}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <>
                        <Grid item md={4} />
                        <Grid item md={4} xs={12}>
                          <Typography
                            className={classes.label}
                            style={{ float: "right" }}
                            noWrap
                          >
                            {" "}
                            Select Role:<b style={{ color: "red" }}>*</b>
                          </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Select
                            // required
                            fullWidth
                            margin="dense"
                            value={RoleId || ""}
                            onChange={(e) => setRoleId(e.target.value)}
                            variant="outlined"
                          >
                            {roleList &&
                              roleList.length !== 0 &&
                              roleList?.map((option, index) => (
                                <MenuItem
                                  key={index}
                                  value={option.id}
                                  name={option.applicant_role_name}
                                >
                                  {option.applicant_role_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item md={4} />
                        <Grid item md={4} xs={12}>
                          <Typography
                            className={classes.label}
                            style={{ float: "right" }}
                            noWrap
                          >
                            Select City:<b style={{ color: "red" }}>*</b>
                          </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Select
                            required
                            fullWidth
                            // multiple
                            margin="dense"
                            value={checkedLoc || ""}
                            onChange={(e) => setCheckedLoc(e.target.value)}
                            variant="outlined"
                          >
                            {cityList &&
                              cityList.length !== 0 &&
                              cityList.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.id}
                                  name={option.id}
                                >
                                  {option.city_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
                        <Grid item md={4} />
                        <Grid item md={4} xs={12}>
                          <Typography
                            className={classes.label}
                            style={{ float: "right" }}
                            noWrap
                          >
                            Select Branch:<b style={{ color: "red" }}>*</b>
                          </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <Select
                            required
                            fullWidth
                            // multiple
                            margin="dense"
                            value={branch || ""}
                            onChange={handleBranch}
                            variant="outlined"
                          >
                            {branchesList &&
                              branchesList.length !== 0 &&
                              branchesList.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.id}
                                  name={option.id}
                                >
                                  {option.branch_name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Grid>
  
                        <Grid item md={4} />
                        <Grid item md={4}>
                          <Typography
                            className={classes.label}
                            style={{ float: "right" }}
                            noWrap
                          >
                            {" "}
                            Select Position : <b style={{ color: "red" }}>*</b>
                          </Typography>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FormControl style={{ display: "unset" }}>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              margin="dense"
                              required
                              fullWidth
                              onChange={(e) => setEditPostionType(e.target.value)}
                              value={editPostionType || " "}
                              variant="outlined"
                              type="text"
                              // input={<OutlinedInput label="Organization" />}
                              MenuProps={MenuProps}
                            >
                              <MenuItem
                                style={{ minWidth: "fit-content" }}
                                value=""
                              >
                                <em>None</em>
                              </MenuItem>
                              {positionList &&
                                positionList
                                  ?.map((option) => (
                                    <MenuItem
                                      style={{ minWidth: "fit-content" }}
                                      key={option.id}
                                      value={option}
                                    >
                                      {option.title}
                                    </MenuItem>
                                  ))
                                  .sort()}
                            </Select>
        
                          </FormControl>
                        </Grid>
                      </>
                      <Grid item md={4} />
                    </Grid>
                  </Paper>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-around" }}>
              <Button
                onClick={handleCloseApplicantEditModal}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  float: "left",
                }}
              >
                Cancel
              </Button>
  
              <Button
                onClick={handleEditApplicant}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        {deleteApplicantForm ? (
          <Dialog
            open={deleteApplicantForm}
            onClose={handleDeleteApplicantClose}
            aria-labelledby="responsive-dialog-title"
            style={{
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          // fullScreen={fullScreen}
          >
            <DialogTitle id="responsive-dialog-title">
              Delete Applicant
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="responsive-dialog-description" tabIndex={-1}>
                <Grid className={classes.registraionPage}>
                  <Paper className={classes.paper}>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography
                          className={classes.label}
                          style={{ float: "left" }}
                          noWrap
                        >
                          {" "}
                          Are You Sure you want to delete this Applicant ?{" "}
                          <b style={{ color: "red" }}>
                            {selectedDeleteApplicant?.user?.first_name}
                          </b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} />
                    </Grid>
                  </Paper>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-around" }}>
              <Button
                onClick={handleDeleteApplicantClose}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  float: "left",
                }}
              >
                Close
              </Button>
  
              <Button
                onClick={handleDeleteConfirmApplicant}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        </>

      )}
    </React.Fragment>
  );
};

ApplicentHistory.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ApplicentHistory);
