import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Button,
  Typography,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableFooter,
  TablePagination,
  Divider,
  IconButton,
  Paper,
  InputBase,
  TableContainer,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert";
import urls from "../../../url";
import styles from "./resumeRepository.styles";
import Loader from "../../../hoc/loader";

const ResumeRepository = ({ classes }) => {
  const [name, setName] = useState(JSON.parse(localStorage.getItem("user")));
  const [resumeData, setResumeData] = useState({});
  const [bulkData, setBulkData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const alert = useAlert();
  const [applicantName, setApplicantName] = useState("");

  useEffect(() => {
    setName(JSON.parse(localStorage.getItem("user")));
  }, []);

  const {
    data: uploadedRes,
    isLoading: resumeDataLoading,
    doFetch: postResumeData,
  } = useFetch([], { suppressAlert: true });
  const {
    data: bulkUploadData,
    isLoading: uploadingBulkData,
    doFetch: postBulkData,
  } = useFetch([], { suppressAlert: true });

  const {
    data: applicantsListData,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch("", { suppressAlert: true });

  useEffect(() => {
    fetchApplicant({
      url: `${urls.listResume}?page=${page + 1}&page_size=${rowsPerPage || 50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }, [
    fetchApplicant,
    uploadedRes,
    bulkUploadData,
    page,
    rowsPerPage,
    name.personal_info.token,
  ]);

  // useEffect(() => {
  //   setBulkData(prevData => !prevData);
  // }, [bulkUploadData]);
  const updateResumeData = (key, data) => {
    const newData = { ...resumeData };
    newData[`${key}`] = data;
    setResumeData(newData);
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  useEffect(() => {
    if (uploadedRes || bulkUploadData) {
      setResumeData({});
    }
  }, [uploadedRes, bulkUploadData]);

  const submitResumeData = () => {
    if (bulkData === false) {
      if (
        resumeData &&
        resumeData.contact_no &&
        String(resumeData.contact_no).length !== 10
      ) {
        alert.warning("Please Enter Correct Number");
        return;
      }

      if (
        !resumeData.name ||
        !resumeData.designation ||
        (!resumeData.resume && !resumeData.bulkData)
      ) {
        alert.warning("Please Fill all mandatory fields");
        return;
      }
    }

    if (bulkData === false) {
      const formData = new FormData();
      formData.append("name", resumeData.name);
      formData.append("designation", resumeData.designation);
      formData.append("contact_no", resumeData.contact_no);
      formData.append("date_of_birth", resumeData.date_of_birth);
      formData.append("resume", resumeData.resume);
      postResumeData({
        url: urls.AddResume,
        method: "POST",
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        body: formData,
      });
    } else {
      const formData = new FormData();
      formData.append("excel_file", resumeData.bulkData);
      postBulkData({
        url: urls.addBulkData,
        method: "POST",
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        body: formData,
      });
      setBulkData((prevData) => !prevData);
    }

    // formInfo.append("excel_file", resumeData.bulkData);

    // postResumeData({
    //   url: urls.AddResume,
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   },
    //   body: formData
    // });

    // if (resumeData.bulkData) {
    //   postBulkData({
    //     url: urls.addBulkData,
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${name.personal_info.token}`
    //     },
    //     body: formInfo
    //   });
    // }
  };

  let loader = null;
  if (resumeDataLoading || uploadingBulkData || applicantsListLoading) {
    loader = <Loader open />;
  }

  function handleResume(data) {
    
    if (
      data.type === "application/msword" ||
      data.type === "application/pdf" ||
      data.type === "image/jpeg"
    ) {
      updateResumeData("resume", data);
    } else {
      alert.warning("upload resume format: doc/pdf/jpeg");
      return;
    }
  }

  
  function handleBulkResume(data) {
    if (
      data.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      data.type === "application/vnd.ms-excel"
    ) {
      updateResumeData("bulkData", data);
      setBulkData(true);
    } else {
      alert.warning("bulk upload format: .xlsx");
      return;
    }
  }

  function profileTable() {
    let applicantTable = null;
    if (applicantsListData && applicantsListData.length === 0) {
      applicantTable = (
        <React.Fragment>
          <Typography
            style={{ textAlign: "center", color: "blue", marginTop: "100px" }}
            variant="h4"
          >
            Records Not Found
          </Typography>
        </React.Fragment>
      );
    }
    if (
      applicantsListData &&
      applicantsListData &&
      applicantsListData.results.length
    ) {
      applicantTable = (
        <>
          <Paper style={{ marginTop: "10px" }}>
            <TableContainer className="role">
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ border: '1px solid black' }}>
                    <Typography><strong>S.No.</strong></Typography>
                  </TableCell>
                  <TableCell align="center" style={{ border: '1px solid black' }}>
                    <Typography><strong>Name</strong></Typography>
                  </TableCell>
                  <TableCell align="center" style={{ border: '1px solid black' }}>
                    <Typography><strong>Designation</strong></Typography>
                  </TableCell>
                  <TableCell align="center" style={{ border: '1px solid black' }}>
                    <Typography><strong>Phone No.</strong></Typography>
                  </TableCell>
                  <TableCell align="center" style={{ border: '1px solid black' }}>
                    <Typography><strong>Date of Birth</strong></Typography>
                  </TableCell>
                  <TableCell align="center" style={{ border: '1px solid black' }}>
                    <Typography><strong>Resume</strong></Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicantsListData &&
                  applicantsListData.results.map((details, i) => (
                    <TableRow key={details.id}>
                      <TableCell align="center" style={{ border: '1px solid black' }}>{i + 1 || ""}</TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>{details.name || ""}</TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        {details.designation || ""}
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        {details.contact_no || ""}
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        {details.date_of_birth || ""}
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <a
                          className={classes.addButton}
                          size="small"
                          variant="contained"
                          href={details.resume}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Resume
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={5}
                  labelDisplayedRows={() =>
                    `Page ${page + 1} of ${+applicantsListData.total_pages}`
                  }
                  rowsPerPageOptions={[50, 75, 100]}
                  rowsPerPage={rowsPerPage || 50}
                  count={+applicantsListData.count}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Rows per page" },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: "13px" }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      lastPageChange(applicantsListData.total_pages - 1)
                    }
                    disabled={page === +applicantsListData.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
            </TableContainer>
          </Paper>
        </>
      );
    }
    return applicantTable;
  }

  function clearResumeFunction() {
    const newData = { ...resumeData };
    newData[`resume`] = "";
    setResumeData(newData);
  }
  function clearBulkUpload() {
    const newData = { ...resumeData };
    newData[`bulkData`] = "";
    setResumeData(newData);
  }

  const fetchSearch = (e) => {
    setPage(0);
    setRowsPerPage(5);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicantName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };

  function functionSearch(data) {
    // fetchApplicant({
    //   url: `${urls.searchApplicantRecruiter}?page=${page +
    //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   }
    // });
  }
  useEffect(() => {
    if (applicantName) {
      fetchApplicant({
        url: `${urls.listResume}?search=${applicantName}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [applicantName]);
  const searchData = (e) => {
    setApplicantName(e.target.value);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item sm={12} md={5} xs={12}>
          <Typography className="headings" variant="h4"><strong>Resume Repositories</strong></Typography>
        </Grid>
        <Grid item md={7} xs={12}>
          <div className={classes.search}>
            {/* <div className={classes.searchIcon}>
            <SearchIcon />
            </div>
            <InputBase
            placeholder="i'm looking for..."
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput
            }}
            id='search-bar'
            onKeyDown={fetchSearch}
            /> */}

            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={searchData}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Name"
            margin="dense"
            required
            fullWidth
            className="textList"
            // SelectProps={{ multiple: true }}
            value={(resumeData && resumeData.name) || ""}
            onChange={(e) => updateResumeData("name", e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Designation"
            margin="dense"
            required
            fullWidth
            className="textList"
            value={(resumeData && resumeData.designation) || ""}
            onChange={(e) => updateResumeData("designation", e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Phone Number"
            margin="dense"
            type="number"
            fullWidth
            className="textList"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value, 10))
                .toString()
                .slice(0, 10);
            }}
            onChange={(e) =>
              e.target.value.length < 11 && e.target.value > -1
                ? updateResumeData("contact_no", e.target.value)
                : ""
            }
            value={(resumeData && resumeData.contact_no) || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            helperText="Date Of Birth"
            margin="dense"
            type="date"
            fullWidth
            className="textList"
            value={(resumeData && resumeData.date_of_birth) || "dd/mm/yyyy"}
            onChange={(e) => updateResumeData("date_of_birth", e.target.value)}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className={classes.label} variant="h6" noWrap>
            Upload Resume (format: doc/pdf/jpeg)
          </Typography>
          <input
            className={classes.fileUpload}
            type="file"
            id="outlined-button-file"
            accept=".ppt,.pptx,.pdf"
            disabled={typeof resumeData.bulkData === "object"}
            onChange={(e) => handleResume(e.target.files[0])}
          />
          <label htmlFor="outlined-button-file">
            <Button
              variant="contained"
              component="span"
              size="small"
              color="primary"
              // fullWidth
              disabled={typeof resumeData.bulkData === "object"}
              startIcon={<CloudUploadIcon />}
              id="resumeUpload"
            >
              Browse
            </Button>
            &nbsp;&nbsp;
            {resumeData && resumeData.resume && resumeData.resume.name}
          </label>
          {resumeData && resumeData.resume && resumeData.resume.name && (
            <IconButton size="small" onClick={() => clearResumeFunction()}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography className={classes.label} variant="h6" noWrap>
            Bulk Upload Resume (format: .xlsx/.xls)
          </Typography>
          <input
            className={classes.fileUpload}
            type="file"
            id="excel-file"
            accept=".xlsx || .xls"
            disabled={typeof resumeData.resume === "object"}
            onChange={(e) => handleBulkResume(e.target.files[0])}
          />
          <label htmlFor="excel-file">
            <Button
              variant="contained"
              component="span"
              size="small"
              // fullWidth
              disabled={typeof resumeData.resume === "object"}
              color="primary"
              startIcon={<CloudUploadIcon />}
              id="bulkUpload"
            >
              Bulk Upload
            </Button>
            &nbsp;&nbsp;
            {resumeData && resumeData.bulkData && resumeData.bulkData.name}
          </label>
          {resumeData && resumeData.bulkData && resumeData.bulkData.name && (
            <IconButton size="small" onClick={() => clearBulkUpload()}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={3} justify="center">
          <Button
            color="primary"
            fullWidth
            variant="contained"
            style={{
              marginTop: "12px",
            }}
            onClick={submitResumeData}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      {loader}
      <Divider style={{ marginTop: "10px" }} />
      {/* <h3>Resume Repositories</h3> */}
      {profileTable()}
    </React.Fragment>
  );
};

ResumeRepository.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ResumeRepository);
