export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    applyButtonSection :{
      marginTop: '6px !important',
      padding: '20px !important',
    },
    applyButtonModal : {
      display: 'block',
      // margin : '0 auto',
    },
    applyButton : {
      margin: '0px 0px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    title : {
      float : 'left',
      fontSize : '18px',
    },
    textField: {
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  });
  