import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Col,
  Row,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./state.style";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";

import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";

import { useForm } from "antd/es/form/Form";

function State({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [role, setRole] = useState("");
  const [inerviewPanel, setInerviewPanel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [applicentName, setApplicentName] = useState("");
  const [bulkFile, setBulkFile] = useState("");
  const [accordianOpen, setAccordianOpen] = useState(false);
  const [accordianOpenFilter, setAccordianOpenFilter] = useState(false);
  const [cityName, setCityName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [checkedLoc, setCheckedLoc] = useState("");
  const [branch, setBranch] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [onlyActiveChange, setOnlyActiveChange] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>State Name</strong>,
      dataIndex: "state_name",
      key: "state_name",
      align: "center",
    },
    {
      title: <strong>State Code</strong>,
      dataIndex: "state_code",
      key: "state_code",
      align: "center",
    },
    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },

    {
      title: "Active",
      key: "is_active",
      render: (data) => (
        <AntdSwitch
          onChange={() => {
            fetchUpdataInterview({
              url: `${urls.putState}`,
              method: "PUT",
              body: {
                is_active: !data?.is_active,
                id: data?.id,
              },
              headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                "Content-Type": "application/json",
              },
            });
          }}
          checked={data?.is_active}
        />
      ),
    },
  ];

  function editFunction(data) {
    form.setFieldsValue({
      state_name: data?.state_name,
      state_code: data?.state_code,
    });

    setVisible(true);

    setSelectedRoleId(data?.id);
  }

  function addInterviewPanel(formValue) {
    if (!selectedRoleId) {
      addInterview({
        url: urls.postState,
        method: "POST",
        body: formValue,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      fetchUpdataInterview({
        url: `${urls.putState}`,
        method: "PUT",
        body: { ...formValue, id: selectedRoleId },
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
        },
      });
    }
  }

  const {
    data: addInterviewPanelResponse,
    isLoading: addInterviewPanelResponseLoading,
    doFetch: addInterview,
  } = useFetch([]);

  const {
    data: tableData,
    isLoading: tableDataLoading,
    doFetch: fetchTableData,
  } = useFetch("", { suppressAlert: true });

  const {
    data: updatainteriewData,
    isLoading: updatainteriewDataLoading,
    doFetch: fetchUpdataInterview,
  } = useFetch([], { suppressAlert: true });

  const {
    data: deleteInterviewData,
    isLoading: deleteInterviewDataLoading,
    doFetch: fetchDeleteInterview,
  } = useFetch([], { suppressAlert: true });

  function functionSearch(data) {
    if (data !== "") {
      fetchTableData({
        url: `${urls.filterState}?search=${data}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      getTableData();
    }
  }

  function getTableData() {
    fetchTableData({
      url: `${urls.getState}?page_size=${rowsPerPage || 50}&page=${page + 1}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (auth) {
      getTableData();
    }
  }, [
    auth,
    addInterviewPanelResponse,
    updatainteriewData,
    deleteInterviewData,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (addInterviewPanelResponse) {
      setCityName("");
      setBulkFile("");
    }
  }, [addInterviewPanelResponse]);

  const alert = useAlert();

  const handleBulkQuestions = (value) => {
    setBulkFile(value);
  };

  const handleClose = () => {
    setRole("");
    setInerviewPanel("");
    setOpen(false);
  };

  useEffect(() => {
    if (updatainteriewData) {
      setOpen(false);
      setCityName("");
      setBulkFile("");
    }
  }, [updatainteriewData]);

  let loader = null;
  if (
    addInterviewPanelResponseLoading ||
    tableDataLoading ||
    updatainteriewDataLoading ||
    deleteInterviewDataLoading
  ) {
    loader = <Loader open />;
  }

  function updateInterviewFunction() {
    if (!cityName) {
      alert.warning("fill all fields");
      return;
    }
    const data = {
      id: selectedRoleId,
      state_name: cityName,
      state_code: stateCode,
      is_active: checked,
    };
    fetchUpdataInterview({
      url: `${urls.putState}`,
      method: "PUT",
      body: data,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        "Content-Type": "application/json",
      },
    });
  }
  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity,
  } = useFetch([], { suppressAlert: true });
  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [fetchCity]);

  const [form] = useForm();
  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });
  function handleCityMultipleChange(event) {
    setCheckedLoc(event.target.value);
    setBranch();
  }
  function handleMultipleChange(event) {
    console.log(event.target.value);
    setBranch(event.target.value);
  }
  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [checkedLoc]);

  function deleteFunction(data) {
    const deleteObj = {
      id: data?.id,
      // name: data?.name,
      is_active: false,
    };
    fetchDeleteInterview({
      url: `${urls.updateRoleDetails}`,
      method: "PUT",
      body: deleteObj,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        "Content-Type": "application/json",
      },
    });
    setPage(0);
    setRowsPerPage(50);
  }

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  function fetchData() {
    fetchTableData({
      url: `${
        urls.filterState
      }?get_state_city_mapping=${checkedLoc}&page=${page + 1}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>State</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add State
          </AntdButton>
        </div>
      </div>
      <AntdDrawer
        title="Add State"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="stateForm" type="primary" htmlType="submit">
              Add
            </AntdButton>
          </div>
        }
      >
        <Form
          id="stateForm"
          form={form}
          layout="vertical"
          onFinish={addInterviewPanel}
        >
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item
                name="state_name"
                label="State Name"
                rules={[{ required: true, message: "Please enter State Name" }]}
              >
                <Input placeholder="State Name" />
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                name="state_code"
                label="State Code"
                rules={[{ required: true, message: "Please enter State Code" }]}
              >
                <Input placeholder="State Code" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>

      {tableData && tableData?.results?.length !== 0 && (
        <AntdTable
          columns={columns}
          dataSource={tableData?.results}
          pagination={false}
          loading={
            addInterviewPanelResponseLoading ||
            tableDataLoading ||
            updatainteriewDataLoading ||
            deleteInterviewDataLoading
          }
        />
      )}
    </React.Fragment>
  );
}
State.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(State);
