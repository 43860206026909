import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Pagination,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Typography as AntdTypography,
  Card,
  message,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  Modal,
  TablePagination,
  InputBase,
  TableContainer,
} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./interviewPanel.style";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import SearchIcon from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";

import MediaQuery from "react-responsive";

function InterviewPanel({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [role, setRole] = useState("");
  const [inerviewPanel, setInerviewPanel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [applicentName, setApplicentName] = useState("");
  const [
    fetchingInterviewPanelData,
    setFechingInterviewPanelData,
  ] = React.useState();
  const [checked, setChecked] = React.useState(false);

  const [visible, setVisible] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState("");

  const {
    data: roleData,
    isLoading: roleDataLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });
  const {
    data: inerviewData,
    isLoading: inerviewDataLoading,
    doFetch: fetchinterviewPanelData,
  } = useFetch([], { suppressAlert: true });
  const {
    data: addInterviewPanelResponse,
    isLoading: addInterviewPanelResponseLoading,
    doFetch: addInterview,
  } = useFetch([], { suppressAlert: true });
  const {
    data: tableData,
    isLoading: tableDataLoading,
    doFetch: fetchTableData,
  } = useFetch("", { suppressAlert: true });

  const {
    data: updatainteriewData,
    isLoading: updatainteriewDataLoading,
    doFetch: fetchUpdataInterview,
  } = useFetch([], { suppressAlert: true });
  const [deleteInterviewData, fetchDeleteInterview] = useState();
  // const{
  // data:deleteInterviewData,
  // isLoading:deleteInterviewDataLoading,
  // doFetch:fetchDeleteInterview
  // }=useFetch([], { suppressAlert: true })

  const fetchSearch = (e) => {
    setPage(0);
    setRowsPerPage(50);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicentName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };
  function functionSearch(data) {
    // fetchApplicent({
    //   url: `${urls.searchApplicentRecruiter}?page=${page +
    //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   }
    // });
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  useEffect(() => {
    if (auth) {
      fetchRole({
        url: urls.interviewPanalRolesApi,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [fetchRole, fetchinterviewPanelData, auth]);

  useEffect(() => {
    if (role) {
      fetchinterviewPanelData({
        url: `${urls.interviewersRoleWiseEmployeeAdminApi}?role_id=${role}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [role]);

  function getTableData() {
    fetchTableData({
      url: `${urls.interviewpanalDataApi}?page_size=${rowsPerPage ||
        50}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (auth) {
      getTableData();
    }
  }, [
    auth,
    addInterviewPanelResponse,
    updatainteriewData,
    deleteInterviewData,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (addInterviewPanelResponse) {
      setRole("");
      setInerviewPanel("");
    }
  }, [addInterviewPanelResponse]);

  const alert = useAlert();

  const handleClose = () => {
    setRole("");
    setInerviewPanel("");
    setOpen(false);
  };

  useEffect(() => {
    if (updatainteriewData) {
      setOpen(false);
      setRole("");
      setInerviewPanel("");
    }
  }, [updatainteriewData]);

  let loader = null;
  if (
    addInterviewPanelResponseLoading ||
    roleDataLoading ||
    tableDataLoading ||
    inerviewDataLoading ||
    updatainteriewDataLoading
  ) {
    loader = <Loader open />;
  }

  function updateInterviewFunction() {
    if (!role || !inerviewPanel) {
      alert.warning("fill all fields");
      return;
    }
    const data = {
      user: inerviewPanel,
      role: role,
      is_delete: checked === true ? false : true,
    };
    fetchUpdataInterview({
      url: `${urls.interviewPanalUpdataApi}${index}/retrieve_update_destroy_interview_panel/`,
      method: "PUT",
      body: data,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        "Content-Type": "application/json",
      },
    });
  }
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteId, setDeleteId] = useState();
  function deleteTopic(data) {
    setDeleteModel(true);
    setDeleteId(data);
  }
  function cancelDelete() {
    setDeleteModel(false);
    setDeleteId(null);
  }

  function deleteFunction() {
    fetch(
      `${urls.interviewPanalUpdataApi}${deleteId}/retrieve_update_destroy_interview_panel/`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        fetchDeleteInterview(data);
        cancelDelete();
        alert.success("data deleted successfully");
        setPage(0);
        setRowsPerPage(50);
      })
      .catch(() => {
        alert.error("Failed to delete");
      });
  }

  useEffect(() => {
    if (fetchingInterviewPanelData) {
      fetchTableData({
        url: `${urls.interviewpanalDataApi}?search=${fetchingInterviewPanelData}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [fetchingInterviewPanelData]);
  const fetchInterviewPanel = (e) => {
    setFechingInterviewPanelData(e.target.value);
  };

  const [form] = Form.useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setSelectedRoleId(null);
    setRole("");
  };
  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Role</strong>,
      dataIndex: "role",
      key: "role",
      align: "center",
      render: (text, record) => (
        <span>{record.role ? record.role?.role_name : ""}</span>
      ),
    },
    {
      title: <strong>User</strong>,
      dataIndex: "user",
      key: "user",
      align: "center",
      render: (text, record) => (
        <span>{record.user ? record.user?.first_name : ""}</span>
      ),
    },

    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
    {
      title: "Status",
      key: "is_active",
      render: (data) => (
        <AntdSwitch
          onChange={() => {
            fetchUpdataInterview({
              url: `${urls.interviewPanalUpdataApi}${data?.id}/retrieve_update_destroy_interview_panel/`,
              method: "PUT",
              body: {
                role: data?.role?.id,
                is_delete: !data?.is_delete,
                user: data?.user?.id,
              },
              headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                "Content-Type": "application/json",
              },
            });
          }}
          checked={data?.is_delete ? false : true}
        />
      ),
    },
  ];

  function editFunction(data) {
    setRole(data?.role?.id);
    setVisible(true);
    form.setFieldsValue({ role: data?.role?.id, user: data?.user?.id });
    setSelectedRoleId(data?.id);
  }

  function addInterviewPanel(formValue) {
    const data = {
      user: inerviewPanel,
      role: role,
    };
    if (!selectedRoleId) {
      addInterview({
        url: urls.addInterviewApi,
        method: "POST",
        body: formValue,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      const isValueInArray =
        inerviewData && inerviewData.some((data) => data.id === formValue.user);
      if (isValueInArray) {
        fetchUpdataInterview({
          url: `${urls.interviewPanalUpdataApi}${selectedRoleId}/retrieve_update_destroy_interview_panel/`,
          method: "PUT",
          body: formValue,
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            "Content-Type": "application/json",
          },
        });
      } else {
        alert.error("Please Select Different User");
        // message.open();
      }
    }
  }

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>Interview Panel</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add Interview
          </AntdButton>
        </div>
      </div>

      {tableData && tableData?.results?.length !== 0 && (
        <AntdTable
          columns={columns}
          dataSource={tableData?.results}
          pagination={{
            current: page,
            total: tableData?.count,
            pageSize: rowsPerPage,
            onChange: (current, pageSize) => {
              setPage(current);
              setRowsPerPage(pageSize);
            },
            pageSizeOptions: [25, 50, 75, 100],
          }}
          loading={
            addInterviewPanelResponseLoading ||
            tableDataLoading ||
            updatainteriewDataLoading
          }
        />
      )}

      <AntdDrawer
        title="Add Interviewer"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="interview" type="primary" htmlType="submit">
              Add
            </AntdButton>
          </div>
        }
      >
        <Form
          id="interview"
          form={form}
          layout="vertical"
          onFinish={addInterviewPanel}
        >
          <Row>
            <Col xs={24}>
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "Please Select Role" }]}
              >
                <AntdSelect
                  placeholder="Select Role"
                  onChange={(e) => setRole(e)}
                >
                  {roleData &&
                    roleData &&
                    roleData.map((data) => (
                      <AntdSelect.Option
                        value={data.id}
                        key={data.id}
                        name={data.role_name}
                      >
                        {data.role_name ? data.role_name : ""}
                      </AntdSelect.Option>
                    ))}
                </AntdSelect>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="user"
                label="User"
                rules={[{ required: true, message: "Please Select User" }]}
              >
                <AntdSelect placeholder="Select User">
                  {inerviewData &&
                    inerviewData &&
                    inerviewData.map((data) => (
                      <AntdSelect.Option
                        value={data.id}
                        key={data.id}
                        name={data.first_name}
                      >
                        {data.first_name ? data.first_name : ""}
                      </AntdSelect.Option>
                    ))}
                </AntdSelect>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>
    </React.Fragment>
  );
}
InterviewPanel.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(InterviewPanel);
