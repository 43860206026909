import React, { useState, useEffect } from "react";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Paper,
  Button,
  Select,
  TextField,
  InputLabel,
  Input,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel
} from "@material-ui/core";
import styles from "./OcfcInterview.style";
import urls from "../../../url";
import useFetch from "../../../hoc/fetch/useFetch";
import Loader from "../../../hoc/loader";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

function OcfcInterview({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [academicValue, setAcademicValue] = useState("");
  const [nonAcademicValue, setNonAcademicValue] = useState("");
  const [overAllValue, setOverallValue] = useState("");
  const [audioUpload, setAudioUpload] = useState("");
  const [reason, setReason] = useState("");
  const [processId, setProcessId] = useState("");
  const [refresh, setRefresh] = useState("");

  const handleAcademicChange = event => {
    setAcademicValue(event.target.value);
  };

  const handleNAcademicChange = event => {
    setNonAcademicValue(event.target.value);
  };

  const handleOverallChange = event => {
    setOverallValue(event.target.value);
  };

  const audioUploadFunc = data => {
    setAudioUpload(data);
  };

  const handleReasonChange = event => {
    setReason(event.target.value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("audio_file", audioUpload);
    formData.append("applicant", processId);
    formData.append("reason", reason);
    formData.append("is_qualify", overAllValue === "Yes" ? true : false);
    formData.append(
      "is_academic_qualify",
      academicValue === "Yes" ? true : false
    );
    formData.append(
      "is_non_academic_qualify",
      nonAcademicValue === "Yes" ? true : false
    );
    const response = await fetch(urls.getOcfcInterviewApi, {
      method: "POST", // or 'PUT'
      body: formData, // data can be `string` or {object}!
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    const getDataiNFO = await response.json();
    setRefresh(processId);
    setAudioUpload("");
    setProcessId("");
    setReason("");
    setOverallValue("");
    setAcademicValue("");
    setNonAcademicValue("");
    setOpen(false);
    return getDataiNFO;
  };

  const {
    data: ocfcInterviewers,
    isLoading: ocfcLoading,
    doFetch: fetchOcfcInterviewList
  } = useFetch(null);

  const {
    data: processModalInfo,
    isLoading: processModalLoading,
    doFetch: fetchProcessModalInfo
  } = useFetch(null);

  let loader = null;
  if (ocfcLoading || processModalLoading) {
    loader = <Loader open />;
  }

  const processCandidate = id => {
    setProcessId(id);
    fetchProcessModalInfo({
      url: `${urls.getProcessModalInfoApi}?applicant=${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    setOpen(true);
  };

  const handleClose = () => {
    setAudioUpload("");
    setProcessId("");
    setReason("");
    setOverallValue("");
    setAcademicValue("");
    setNonAcademicValue("");
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchOcfcInterviewList({
      url: `${urls.getOcfcInterviewApi}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  }, [refresh]);
  return (
    <div>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Typography variant='h6'>OCFC interview</Typography>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' id='nameHead'>
                Name
              </TableCell>
              <TableCell align='center' id='dateHead'>
                Contact
              </TableCell>
              <TableCell align='center' id='scoreHead'>
                Process
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ocfcInterviewers &&
              ocfcInterviewers.map((row, index) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell align='center'>{row.applicant.name}</TableCell>
                  <TableCell align='center'>
                    {row.applicant.contact_no}
                  </TableCell>
                  <TableCell align='center' id='scoreHead'>
                    <Button
                      size='small'
                      fullWidth
                      color='primary'
                      variant='contained'
                      onClick={() => processCandidate(row.id)}
                    >
                      Process
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* {ocfcInterviewers && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={ocfcInterviewers.results.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )} */}
      </Paper>
      <Dialog
        open={open}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ width: "80%", margin: "0 auto", textAlign: "center" }}
        >
          OCFP Interview Details
        </DialogTitle>
        <DialogContent>
          <Paper elevation={2}>
            <Grid container style={{ width: "80%", margin: "0 auto" }}>
              <Grid item md={6} xs={12} xs={12} style={{ marginTop: "10px" }}>
                <FormLabel component='legend'>PDF file</FormLabel>
                <p>
                  Open a PDF file of &nbsp;
                  <a
                    href={
                      processModalInfo && processModalInfo.presentation_file
                    }
                    target='_new'
                  >
                    {processModalInfo && processModalInfo.topic_name}
                  </a>
                  .
                </p>
              </Grid>

              <Grid item md={6} xs={12} xs={12} style={{ marginTop: "10px" }}>
                <FormLabel component='legend'>Audio File</FormLabel>
                <audio controls>
                  <source
                    src={processModalInfo && processModalInfo.audio_file}
                    type='audio/mp3'
                  />
                </audio>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ marginTop: "20px" }} elevation={3}>
            <h3 style={{ paddingTop: "10px", margin: "0 auto", width: "80%" }}>
              Qualification Form
            </h3>
            <Grid container style={{ margin: "10px auto", width: "80%" }}>
              <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                <FormLabel component='legend'>Academic Qualify</FormLabel>
                <RadioGroup
                  row
                  aria-label='gender'
                  name='academicQualify'
                  value={academicValue}
                  onChange={handleAcademicChange}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio color='primary' />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value='No'
                    control={<Radio color='primary' />}
                    label='No'
                  />
                </RadioGroup>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                <FormLabel component='legend'>Non Academic Qualify</FormLabel>
                <RadioGroup
                  row
                  aria-label=''
                  name='nonAcademicQualify'
                  value={nonAcademicValue}
                  onChange={handleNAcademicChange}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio color='primary' />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value='No'
                    control={<Radio color='primary' />}
                    label='No'
                  />
                </RadioGroup>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginTop: "10px" }}>
                <FormLabel component='legend'>Overall Qualify</FormLabel>
                <RadioGroup
                  row
                  aria-label=''
                  name='overAllQualify'
                  value={overAllValue}
                  onChange={handleOverallChange}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio color='primary' />}
                    label='Accept'
                  />
                  <FormControlLabel
                    value='No'
                    control={<Radio color='primary' />}
                    label='Reject'
                  />
                </RadioGroup>
              </Grid>
              <Grid item md={6} xs={12} style={{ marginTop: "15px" }}>
                <FormLabel component='legend'>Audio File Upload</FormLabel>
                <div className={classes.root} id='uploadVideo'>
                  <input
                    accept='audio/mp3'
                    className={classes.input}
                    id='contained-button-file'
                    multiple
                    type='file'
                    onChange={e => audioUploadFunc(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                  <label htmlFor='contained-button-file'>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      component='span'
                      startIcon={<CloudUploadIcon />}
                    >
                      Audio File Upload
                    </Button>
                    <span>{audioUpload && audioUpload.name}</span>
                  </label>
                </div>
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  id='outlined-multiline-flexible'
                  label='Reason to Accept/Reject'
                  multiline
                  rowsMax='4'
                  value={reason}
                  onChange={handleReasonChange}
                  variant='outlined'
                  style={{ marginTop: "15px" }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={4} />
              <Grid item md={4}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  component='span'
                  onClick={() => handleSubmit()}
                  style={{ marginBottom: "15px" }}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item md={4} />
            </Grid>
          </Paper>
        </DialogContent>
      </Dialog>
      {loader}
    </div>
  );
}

export default withStyles(styles)(OcfcInterview);
