import { positions } from "@material-ui/system";

export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    button:{
      fontSize:'20px',
      marginTop:'50px',
      margin: '0 auto',
      display: 'block'
    },
    papertext:{
      padding: theme.spacing(1, 1, 1,1),
      fontSize:'20px',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontFamily: "Times"
    },
    GridText:{
      marginTop:'100px',
      marginLeft:'11%',
      marginRight:'11%'
      // marginRight:'25%',
      // display: 'block',
      // position:'fixed'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(1, 2, 1),
    }, 
    paperr:{
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(1, 2, 1),
    },
    topic:{
      padding: theme.spacing(1, 2, 3),
    },
    grid:{
      marginLeft:'25%',
      marginRight:'25%'
    },
    typography:{
      padding: theme.spacing(1,0,0),
    }
});