import React, { useState, useEffect } from "react";
import useFetch from "../../../hoc/useFetch";
import { useAlert } from "../../../hoc/alert";
import urls from "../../../url";
import {
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Button,
  Table,
  Tag,
} from "antd";
import moment from "moment";
import { useForm } from "antd/es/form/Form";

const ResumeRepositoryNew = ({ classes }) => {
  const [formRef] = useForm();
  const [name, setName] = useState(JSON.parse(localStorage.getItem("user")));
  const [resumeData, setResumeData] = useState({});
  const [bulkData, setBulkData] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const alert = useAlert();
  const [applicantName, setApplicantName] = useState("");

  useEffect(() => {
    setName(JSON.parse(localStorage.getItem("user")));
  }, []);

  const {
    data: uploadedRes,
    isLoading: resumeDataLoading,
    doFetch: postResumeData,
  } = useFetch([], { suppressAlert: true });
  const {
    data: bulkUploadData,
    isLoading: uploadingBulkData,
    doFetch: postBulkData,
  } = useFetch([], { suppressAlert: true });

  const {
    data: applicantsListData,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch("", { suppressAlert: true });

  useEffect(() => {
    fetchApplicant({
      url: `${urls.listResume}?page=${page}&page_size=${10}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }, [
    fetchApplicant,
    uploadedRes,
    bulkUploadData,
    page,
    rowsPerPage,
    name.personal_info.token,
  ]);

  const updateResumeData = (key, data) => {
    const newData = { ...resumeData };
    newData[`${key}`] = data;
    setResumeData(newData);
  };

  useEffect(() => {
    if (uploadedRes || bulkUploadData) {
      setResumeData({});
    }
  }, [uploadedRes, bulkUploadData]);

  const handleClear = () => {
    formRef.resetFields();
  };
  const submitResumeData = () => {
    const values = formRef.getFieldsValue();
    if (bulkData === false) {
      if (
        values &&
        values?.contact_no &&
        String(values?.contact_no).length !== 10
      ) {
        alert.warning("Please Enter Correct Number");
        return;
      }
    }

    if (bulkData === false) {
      const formData = new FormData();
      formData.append("name", values?.name);
      formData.append("designation", values?.designation);
      formData.append("contact_no", values?.contact_no);
      formData.append(
        "date_of_birth",
        moment(values?.date_of_birth).format("YYYY-MM-DD")
      );
      formData.append("resume", resumeData?.resume);
      postResumeData({
        url: urls.AddResume,
        method: "POST",
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        body: formData,
      });
      if (uploadedRes && uploadedRes?.id) {
        handleClear();
      }
    } else {
      const formData = new FormData();
      formData.append("excel_file", resumeData?.bulkData);
      postBulkData({
        url: urls.addBulkData,
        method: "POST",
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        body: formData,
      });
      setBulkData((prevData) => !prevData);
      if (bulkUploadData && bulkUploadData === "success") {
        handleClear();
      }
    }
  };

  useEffect(() => {
    if (applicantName) {
      fetchApplicant({
        url: `${urls.listResume}?search=${applicantName}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    } else {
      fetchApplicant({
        url: `${urls.listResume}?page=${page}&page_size=${10}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [applicantName]);
  const searchData = (e) => {
    setApplicantName(e.target.value);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      width: "25%",
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: "Designation",
      key: "designation",
      width: "20%",
      render: (data) => <span>{data?.designation}</span>,
    },
    {
      title: "Phone No.",
      key: "contact_no",
      width: "15%",
      render: (data) => <span>{data?.contact_no}</span>,
    },
    {
      title: "Date of Birth",
      key: "date_of_birth",
      width: "15%",
      render: (data) => <span>{data?.date_of_birth}</span>,
    },
    {
      title: "Resume",
      key: "resume",
      width: "15%",
      render: (data) => (
        <a href={data?.resume} target="_blank">
          <Tag color="purple">View Resume</Tag>
        </a>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between">
        <Col span={18} className="page_title">
          Resume Repositories
        </Col>
        <Col span={6}>
          <Input placeholder="I'm Looking For..." onChange={searchData} />
        </Col>
      </Row>

      <div className="page_body">
        <Form
          id="resumeForm"
          form={formRef}
          layout="vertical"
          onFinish={submitResumeData}
        >
          <Row align="middle" gutter={24}>
            <Col span={6}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Name",
                  },
                ]}
              >
                <Input disabled={bulkData} placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="designation"
                label="Designation"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Designation",
                  },
                ]}
              >
                <Input disabled={bulkData} placeholder="Enter Designation" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="contact_no"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Phone Number",
                  },
                ]}
              >
                <InputNumber
                  disabled={bulkData}
                  placeholder="Enter Phone Number"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="date_of_birth"
                label="Date Of Birth"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Date of Birth",
                  },
                ]}
              >
                <DatePicker disabled={bulkData} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="resume"
                label="Upload Resume (doc/pdf/jpeg)"
                rules={[
                  {
                    required: true,
                    message: "Please Upload Resume",
                  },
                ]}
              >
                <Input
                  type="file"
                  accept=".pdf, .doc, .jpeg"
                  onChange={(e) =>
                    updateResumeData("resume", e.target.files[0])
                  }
                  disabled={bulkData}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="bulk_upload"
                label="Bulk Upload Resume (format: .xlsx/.xls)"
              >
                <Input
                  type="file"
                  accept=".xlsx, .xls"
                  disabled={typeof resumeData?.resume === "object"}
                  onChange={(e) => {
                    updateResumeData("bulkData", e.target.files[0]);
                    if (e.target.files[0]) {
                      setBulkData(true);
                    } else {
                      setBulkData(false);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {!bulkData && (
              <Col span={2}>
                <Button
                  form="resumeForm"
                  htmlType="submit"
                  type="primary"
                  className="button primary"
                >
                  Submit
                </Button>
              </Col>
            )}
            {bulkData && (
              <Col span={2}>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="button primary"
                  onClick={submitResumeData}
                >
                  Upload Bulk Data
                </Button>
              </Col>
            )}
          </Row>
        </Form>

        <Table
          columns={columns}
          dataSource={applicantsListData?.results}
          pagination={{
            showSizeChanger: false,
            total: applicantsListData?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={
            resumeDataLoading || uploadingBulkData || applicantsListLoading
          }
          rowKey={(data) => data?.id}
        />
      </div>
    </>
  );
};

export default ResumeRepositoryNew;
