import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Register from "./register/register";
import Layout from "./layout/layout";
import NewLayout from "./layout/layoutNew";
import Login from "./login/login";
import RegistrationPage from "./register/registrationPage/registration";
import ForgetPassword from "./forgetPassword/forgetPassword";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./redux/actions";
import axios from "axios";
import urls from "./url";
import { message } from "antd";
import SSOLogin from "./components/ssoLogin";

function App() {
  const dispatch = useDispatch();
  const [loginData] = useState(JSON.parse(localStorage.getItem("user")));
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  useEffect(() => {
    if (loginData) {
      dispatch(login(loginData));
      axios.defaults.headers.Authorization = `Bearer ${loginData?.personal_info?.token}`;
    }
  }, [loginData]);

  return (
    <div>
      {/* <Layout /> */}
      <BrowserRouter>
        {isAuthenticated ? (
          <NewLayout />
        ) : (
          <Switch>
            <Route
              path="/register"
              exact
              component={Register}
              render={() => <Redirect to="/" />}
            />
            <Route
              path="/RegistrationPage"
              exact
              component={RegistrationPage}
              render={() => <Redirect to="/" />}
            />
            <Route
              path="/ForgetPassword"
              exact
              component={ForgetPassword}
              render={() => <Redirect to="/" />}
            />
            <Route
              path="/sso"
              exact
              component={SSOLogin}
              render={() => <Redirect to="/" />}
            />
            <Route path="/" component={Login} />
          </Switch>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
