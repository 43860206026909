import React, { useState, useEffect } from "react";
import styles from "./registration.style";
import { Fetch } from "../../hoc/fetch";
import urls from "../../url";
import PropTypes from "prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
// import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import { useAlert } from "../../hoc/alert";
import Loader from "../../hoc/loader";
// import { makeStyles } from '@material-ui/core/styles';
// import Logo from './logo.png';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Checkbox from "@material-ui/core/Checkbox";

import {
  Grid,
  TextField,
  withStyles,
  Typography,
  Divider,
  Button,
  Paper,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";

const RegistrationPage = ({
  classes,
  jobId,
  title,
  mobNu,
  applicantRoleName,
}) => {
  var mobile = null;
  var email_id = null;

  if (mobNu.match(/[a-z@]/i)) {
    email_id = mobNu;
  } else {
    mobile = mobNu;
  }

  const [name, setName] = useState("");
  const [emailId, setEmailId] = useState(email_id);
  const [MobileNu, setMobileNu] = useState(mobile);

  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");

  const [confrmPassword, setConfrmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [expYear, setExpYear] = useState("");
  const [ctc, setCTC] = useState("");
  const [resume, setResume] = useState("");
  const [position, setPosition] = useState(title);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [currentCity, setCurrentCity] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [graduation, setGraduation] = useState("");
  const [postGraduation, setPostGraduation] = useState("");
  const [college, setCollege] = useState("");
  const [bED, setBED] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [branchPreference1, setBranchPreference1] = useState("");
  const [branchPreference2, setBranchPreference2] = useState("");
  const [branchPreference3, setBranchPreference3] = useState("");
  const [positionApplyFor, setPositionApplyFor] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const [branch, setBranch] = useState("");


  if (mobNu.match(/[a-z@]/i)) {
    email_id = mobNu;
  } else {
    mobile = mobNu;
  }

  const [data, setData] = useState({
    age: "",
    name: "hai",
  });

  const alert = useAlert();

  function handleChange(event) {
    setData((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }
  const {
    data: regFormResponse,
    isLoading: regFormLoading,
    doFetch: formDetails,
  } = Fetch(null);

  // const {
  //   data: branchList,
  //   isLoading: gettingBranches,
  //   doFetch: fetchBranches
  // } = useFetch(null);

  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity
  } = Fetch(null);

  const { data: branchList, doFetch: fetchBranchList } = Fetch(null);

  const { data: graduationList, doFetch: fetchGraduationList } = Fetch(null);

  const { data: collegeList, doFetch: fetchCollegeList } = Fetch(null);

  const { data: postGraduationList, doFetch: fetchPostGraduationList } = Fetch(
    null
  );

  useEffect(() => {

    fetchGraduationList({
      url: urls.graduationList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchPostGraduationList({
      url: urls.postGraduationList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchCollegeList({
      url: urls.postCollegeList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [
    fetchBranchList,
    fetchGraduationList,
    fetchPostGraduationList,
    fetchCollegeList,
  ]);

  useEffect(() => {
    if (checkedLoc) {
      fetchBranchList({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
    }

  }, [checkedLoc])

  useEffect(() => {
    if (regFormResponse) {
      alert.warning(regFormResponse);
    }
  }, [regFormResponse]);
  const [checked, setChecked] = React.useState(true);
  const handleChanges = (event) => {
    setChecked(event.target.value);
  };

  const completeApplication = () => {
    if (
      !name || !password || !confrmPassword || emailId
        ? !emailId
        : !email || MobileNu
          ? !MobileNu
          : !mobNo ||
          !currentCity ||
          // || !birthDate || !gender || !graduation || !postGraduation || !bED || !expYear || !ctc || !noticePeriod || !branchPreference1 || !branchPreference2 || !branchPreference3 ||
          !resume
    ) {
      alert.warning("Fill All The Fields");
      return;
    }
    if (password !== confrmPassword) {
      alert.warning("Please enter correct Password");
      return;
    }

    if ((emailId || email).match(/[a-z@]/i)) {
      const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(emailId || email)) {
        alert.warning("Please enter valid Email...");
        return;
      }
    } else {
      var IndNum = /^[0]?[789]\d{9}$/;
      if (!IndNum.test(mobNo || MobileNu)) {
        alert.warning("Please enter valid mobile number...!");
        return;
      }
    }
    const formData = new FormData();
    const origin = JSON.parse(sessionStorage.getItem('origin'));
    formData.append("name", name);
    formData.append("contact_no", `${mobNo || MobileNu}`);
    formData.append("email", `${email || emailId}`);
    formData.append("password", password);
    formData.append("is_child", checked);

    //formData.append('confrm_Password', confrmPassword);
    // formData.append('excepted_salary', expCtc);
    formData.append("current_location", currentCity);
    // formData.append('date_of_birth', birthDate);
    // formData.append('gender', gender);
    // formData.append('graduation', graduation);
    // formData.append('post_graduation', postGraduation);
    // formData.append('b_ed', bED);
    // formData.append('total_experience', expYear);
    // formData.append('duration_notice_period', noticePeriod);
    // formData.append('current_salary', ctc);
    // formData.append('branch_prefer_1', branchPreference1);
    // formData.append('branch_prefer_2', branchPreference2);
    // formData.append('branch_prefer_3', branchPreference3);
    formData.append("resume", resume);
    formData.append("origin", origin ? origin.params : '');

    // formData.append('month', data.months === undefined ? '' : data.months);
    {
      jobId && formData.append("position_apply", jobId);
    }
    {
      title
        ? formData.append("position_name", position)
        : formData.append("role", "other");
    }
    formDetails({
      url: urls.registrationComplete,
      method: "Post",
      body: formData,
    });
  };

  const submitApplication = () => {
    if (
      !name || !password || !confrmPassword || emailId
        ? !emailId
        : !email || MobileNu
          ? !MobileNu
          : !mobNo ||
          !currentCity ||
          !resume ||
          !positionApplyFor ||
          !checkedLoc ||
          !branch
    ) {
      alert.warning("Fill All The Fields");
      return;
    }
    if (password !== confrmPassword) {
      alert.warning("Please enter correct Password");
      return;
    }

    if ((emailId || email).match(/[a-z@]/i)) {
      const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(emailId || email)) {
        alert.warning("Please enter valid Email...");
        return;
      }
    } else {
      var IndNum = /^[0]?[789]\d{9}$/;
      if (!IndNum.test(mobNo || MobileNu)) {
        alert.warning("Please enter valid mobile number...!");
        return;
      }
    }
    const formData = new FormData();
    const origin = JSON.parse(sessionStorage.getItem('origin'));
    formData.append("name", name);
    // formData.append("photo", null);
    // formData.append("date_of_birth", 'null');
    // formData.append("gender", null);
    formData.append("contact_no", `${mobNo || MobileNu}`);
    formData.append("email", `${email || emailId}`);
    formData.append("password", password);
    formData.append("current_location", currentCity);
    formData.append("resume", resume);
    formData.append("origin", origin ? origin.params : '');
    formData.append('position_other_looking', positionApplyFor);
    formData.append('city_id', checkedLoc);
    formData.append('branch_id', branch);
    formData.append('city_prefer_id', checkedLoc);
    formData.append("is_child", checked);

    // formData.append('position_apply', null);
    formDetails({
      url: urls.registrationOthersComplete,
      method: "Post",
      body: formData,
    });
  };

  useEffect(() => {
    if (regFormResponse && regFormResponse) {
      alert.success("Registration completed successfully");
    }
    if (regFormResponse && regFormResponse !== "Applicant already exists") {
      window.location.href = "/";
    }
  }, [regFormResponse]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  let loader = null;
  if (regFormLoading) {
    loader = <Loader open />;
  }

  // function handleCtc(e) {
  //   if (e.target.value.length <= 4) {
  //     setCtc(e.target.value)
  //   }
  // }
  // function handleExpCtc(e) {
  //   if (e.target.value.length <= 4) {
  //     setExpCtc(e.target.value)
  //   }
  // }

  useEffect(() => {
    // fetchBranches({
    //   url: urls.branchCityMapping,
    //   method: "GET",
    //   flag: false,
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // })
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })

  }, [fetchCity]);

  function handleBranch(e) {
    let eventIndex = e.target.value[e.target.value.length - 1];

    // eventIndex===branchList[0].id?setBranch([eventIndex]):e.target.value[0]===branchList[0].id?setBranch(e.target.value.shift()):setBranch(e.target.value);

    if (eventIndex === branchList[0].id) {
      setBranch([eventIndex])
    } else {
      if (e.target.value[0] === branchList[0].id) {
        setBranch(e.target.value.shift());
      }
      setBranch(e.target.value);
    }
  }

  return (
    <React.Fragment>
      <Grid className={classes.registraionPage}>
        {/* <img src={Logo} alt='website logo' /> */}
        <Paper className={classes.paper}>
          <React.Fragment>
            <Grid container spacing={0}>
              {title ? (
                <Grid item md={12} xs={12}>
                  <Typography
                    className={classes.title}
                    style={{ marginTop: "10px" }}
                    variant="h5"
                    noWrap
                  >
                    Position Apply For :{" "}
                    <span className={classes.jobTitle}>{title}</span>
                  </Typography>
                </Grid>
              ) : (
                // <Grid container spacing={0}>
                //   <Grid item md={3} xs={12}>
                //     <Typography
                //       className={classes.title}
                //       style={{ marginTop: "10px" }}
                //       variant="h5"
                //       noWrap
                //     >
                //       Position Apply For :{" "}
                //       <span className={classes.jobTitle}>Other</span>
                //     </Typography>
                //   </Grid>
                // </Grid>
                " "
              )}
            </Grid>
          </React.Fragment>
          <Divider className={classes.divider} />
          {/* <Typography className={classes.title} variant="h6" noWrap>
            Personal Information*
            </Typography>
          <Divider className={classes.divider} /> */}


          <Grid container spacing={2}>

            {!title ? (
              <>
                <Grid item md={4} />
                <Grid item md={4} xs={12}>
                  <Typography className={classes.label} variant="h6" noWrap>
                    Position Looking For*
                  </Typography>
                  <TextField
                    margin="dense"
                    required
                    placeholder="Mention Position Looking For with Subject"
                    fullWidth
                    value={positionApplyFor}
                    onChange={(e) => setPositionApplyFor(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} />
                <Grid item md={4} />
                <Grid item md={4} xs={12}>
                  <Typography className={classes.label} variant="h6" noWrap>City*</Typography>
                  <Select
                    required
                    fullWidth
                    // multiple
                    margin='dense'
                    value={checkedLoc || ""}
                    onChange={e => setCheckedLoc(e.target.value)}
                    variant='outlined'
                  >
                    {cityList && cityList.length !== 0 &&
                      cityList.map(option => (
                        <MenuItem key={option.value} value={option.id} name={option.id}>
                          {option.city_name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item md={4} />
                <Grid item md={4} />
                <Grid item md={4} xs={12}>
                  <Typography className={classes.label} variant="h6" noWrap>Branch*</Typography>
                  <Select
                    required
                    fullWidth
                    // multiple
                    margin='dense'
                    value={branch || ""}
                    onChange={handleBranch}
                    variant='outlined'
                  >
                    {branchList && branchList.length !== 0 &&
                      branchList.map(option => (
                        <MenuItem key={option.value} value={option.id} name={option.id}>
                          {option.branch_name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid item md={4} />
              </>
            ) : ("")}
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Full Name*
              </Typography>
              <TextField
                margin="dense"
                required
                placeholder="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Email Id*
              </Typography>
              <form noValidate>
              <TextField
                type="email"
                margin="dense"
                disabled={emailId ? true : false}
                required
                placeholder="Email"
                fullWidth
                value={emailId || email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
              />

              </form>
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
         
            <Grid item md={4}/>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
              Is Your Child Study At Same Branch*
              </Typography>
              <Grid item md={4} xs={12} onChange={handleChanges}>
                    <input type="radio" value="true"               checked={checked === "true"}
/>Yes
                    <input type="radio" value="false"  checked={checked === "false"} />No
                    </Grid>
         
    
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Current City
              </Typography>
              <form noValidate>
              <TextField
                margin="dense"
                required
                fullWidth
                placeholder="Current City"
                value={currentCity}
                onChange={(e) => setCurrentCity(e.target.value)}
                variant="outlined"
              />

              </form>
            </Grid>
            <Grid item md={4}/>
            <Grid item md={4}/>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Mobile Number*
              </Typography>
              <TextField
                type="number"
                margin="dense"
                placeholder="Mobile Number"
                disabled={MobileNu ? true : false}
                fullWidth
                value={MobileNu || mobNo}
                onChange={(e) =>
                  setMobNo(
                    Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10)
                  )
                }
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Password*
              </Typography>
              <form noValidate>
              <TextField
                margin="dense"
                type={showPassword ? "text" : "password"}
                fullWidth
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />

              </form>
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Confirm Password*
              </Typography>
              <form noValidate>
              <TextField
                margin="dense"
                type={showCPassword ? "text" : "password"}
                required
                placeholder="Confirm Password"
                fullWidth
                value={confrmPassword}
                onChange={(e) => setConfrmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCPassword}
                        onMouseDown={handleMouseDownCPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />

              </form>
              {password && confrmPassword && confrmPassword !== password ? (
                <FormHelperText id="component-error-text">
                  Confirm Password should match password
                </FormHelperText>
              ) : null}
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Upload Resume <b>(format: .pdf)</b><i>Only Pdf files are Supported</i>
              </Typography>
              <input
                className={classes.fileUpload}
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                onChange={(e) => setResume(e.target.files[0])}
              />
            </Grid>
            <Grid item md={4} />
            <Grid item md={4} />
            <Grid item md={4} xs={12}>
              <Button
                onClick={title ? completeApplication : submitApplication}
                className={classes.completeAppForm}
                style={{ backgroundColor: "blue", color: "black" }}
              >
                {title ? "Complete Registration" : "submit Application"}
              </Button>
            </Grid>
          </Grid>
          {/* <Typography className={classes.title} variant="h6" noWrap>
            Other Information
            </Typography>
          <Divider className={classes.divider} /> */}
          <Grid container spacing={2}>
            {/* <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Current City
              </Typography>
              <TextField
                margin='dense'
                required
                fullWidth
                value={currentCity}
                onChange={e => setCurrentCity(e.target.value)}
                variant='outlined'
              />
            </Grid> */}
            {/* <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Date of Birth
              </Typography>
              <TextField
                className={classes.textField}
                margin='dense'
                required
                fullWidth
                onChange={e => setBirthDate(e.target.value)}
                type='date'
                value={birthDate || " "}
                variant='outlined'
              />
            </Grid> */}
            {/* {applicantRoleName === 'OCFP' ? */}
            {/* <Grid item md={4}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  row
                  style={{ display: 'flex' }}
                  style={{ flexDirection: 'row' }}
                  value={gender || ''}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <FormLabel component='legend'>Gender</FormLabel>
                  <RadioGroup
                    aria-label='gender'
                    name='gender'
                    row
                    style={{ display: "flex" }}
                    style={{ flexDirection: "row" }}
                    value={gender || ""}
                    onChange={e => setGender(e.target.value)}
                  >
                    <FormControlLabel
                      value='Female'
                      control={<Radio color='primary' />}
                      label='Female'
                      labelPlacement='start'
                    />
                    <FormControlLabel
                      value='Male'
                      control={<Radio color='primary' />}
                      label='Male'
                      labelPlacement='start'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            ) : (
              <Grid item md={4}>
                <FormControl
                  component='fieldset'
                  className={classes.formControl}
                >
                  <FormLabel component='legend'>Gender</FormLabel>
                  <RadioGroup
                    aria-label='gender'
                    name='gender'
                    row
                    style={{ display: "flex" }}
                    style={{ flexDirection: "row" }}
                    value={gender || ""}
                    onChange={e => setGender(e.target.value)}
                  >
                    <FormControlLabel
                      value='Female'
                      control={<Radio color='primary' />}
                      label='Female'
                      labelPlacement='start'
                    />
                    {/* <FormControlLabel
                    value="Male"
                    control={<Radio color="primary" />}
                    label="Male"
                    labelPlacement="start"
                  /> */}

            {/* </RadioGroup>
              </FormControl>
              </Grid>
              } */}
            {/* <Grid item md={4} xs={12} >
              <Typography className={classes.label} variant="h6" noWrap>
                Graduation
              </Typography>
              <TextField
                className={classes.textField}
                margin='dense'
                fullWidth
                required
                select
                onChange={e => setGraduation(e.target.value)}
                // SelectProps={{ multiple: true }}
                value={graduation || ""}
                variant='outlined'
              >
                {graduationList &&
                  graduationList.map(data => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      // name={data.degree_name}
                    >
                      {data.degree_name ? data.degree_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant='h6' noWrap>
                Post Graduation
              </Typography>
              <TextField
                className={classes.textField}
                margin='dense'
                fullWidth
                required
                select
                onChange={e => setPostGraduation(e.target.value)}
                // SelectProps={{ multiple: true }}
                value={postGraduation || ""}
                variant='outlined'
              >
                {postGraduationList &&
                  postGraduationList.map(data => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      // name={data.degree_name}
                    >
                      {data.degree_name ? data.degree_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant='h6' noWrap>
                College
              </Typography>
              <TextField
                className={classes.textField}
                margin='dense'
                fullWidth
                required
                select
                onChange={e => setCollege(e.target.value)}
                // SelectProps={{ multiple: true }}
                value={college || ""}
                variant='outlined'
              >
                {collegeList &&
                  collegeList.map(data => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      // name={data.degree_name}
                    >
                      {data.college_name ? data.college_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid> */}
            {/* <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                B.Ed
                </Typography>
              <TextField
                className={classes.textField}
                margin="dense"
                required
                fullWidth
                onChange={e => setBED(e.target.value)}
                value={bED || ' '}
                variant="outlined"
              />
            </Grid> */}
            {/* <Grid item md={4}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">b.Ed</FormLabel>
                <RadioGroup
                  aria-label='gender'
                  name='gender'
                  row
                  style={{ display: "flex" }}
                  style={{ flexDirection: "row" }}
                  value={bED || ""}
                  onChange={e => setBED(e.target.value)}
                >
                  <FormControlLabel
                    value='false'
                    control={<Radio color='primary' />}
                    label='No'
                    labelPlacement='start'
                  />
                  <FormControlLabel
                    value='true'
                    control={<Radio color='primary' />}
                    label='Yes'
                    labelPlacement='start'
                  />
                </RadioGroup>
              </FormControl>
              </Grid> */}
            {/* <Grid item md={4} xs={6}>
              <Typography className={classes.label} variant="h6" noWrap>
                Experience (in years)
              </Typography>
              <TextField
                margin='dense'
                type='Number'
                fullWidth
                value={expYear || ""}
                // onChange={e => e.target.value.length <= 4 ? setYear(e.target.value) : ''}
                onChange={e => setExpYear(e.target.value)}
                variant='outlined'
              />
            </Grid> */}
            {/* <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                CTC (In lakhs per annum )
              </Typography>
              <TextField
                margin='dense'
                type='Number'
                fullWidth
                value={ctc || ""}
                onChange={e => setCTC(e.target.value)}
                variant='outlined'
              />
            </Grid> */}
            {/* <Grid item md={4} xs={6}>
              <Typography className={classes.label} variant="h6" noWrap>
                Notice Period (in days)
              </Typography>
              <TextField
                margin='dense'
                type='Number'
                fullWidth
                value={noticePeriod || ""}
                onChange={e => setNoticePeriod(e.target.value)}
                variant='outlined'
              />
            </Grid> */}
            {/* <Grid item md={2} xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="Months">Months</InputLabel>
                <Select
                  value={data.months}
                  onChange={handleChange}
                  inputProps={{
                    name: 'months',
                    id: 'Months',
                  }}
                >
                  <MenuItem value={1}>0</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={8}>8</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={11}>11</MenuItem>
                </Select>
                <FormHelperText>Select Months Here</FormHelperText>
              </FormControl>
            </Grid> */}

            {/* <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Branch Preferences 1
              </Typography>
              <TextField
                margin='dense'
                placeholder='Branch Preferences (Max 3 /Any in (City)'
                fullWidth
                max='3'
                select
                value={branchPreference1 || ""}
                onChange={e => setBranchPreference1(e.target.value)}
                variant='outlined'
              >
                {branchList &&
                  branchList.map(data => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.branch_name}
                    >
                      {data.branch_name ? data.branch_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid> */}
            {/* <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Branch Preferences 2
              </Typography>
              <TextField
                margin='dense'
                placeholder='Branch Preferences (Max 3 /Any in (City)'
                fullWidth
                max='3'
                select
                value={branchPreference2 || ""}
                onChange={e => setBranchPreference2(e.target.value)}
                variant='outlined'
              >
                {branchList &&
                  branchList.map(data => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.branch_name}
                    >
                      {data.branch_name ? data.branch_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid> */}
            {/* <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Branch Preferences 3
              </Typography>
              <TextField
                margin='dense'
                placeholder='Branch Preferences (Max 3 /Any in (City)'
                fullWidth
                max='3'
                select
                value={branchPreference3 || ""}
                onChange={e => setBranchPreference3(e.target.value)}
                variant='outlined'
              >
                {branchList &&
                  branchList.map(data => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.branch_name}
                    >
                      {data.branch_name ? data.branch_name : ""}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid> */}

            {/* <Grid item md={4} xs={12}>
              <Typography className={classes.label} variant="h6" noWrap>
                Expected CTC (In lakhs per annum )
                </Typography>
              <TextField
                margin="dense"
                type="Number"
                fullWidth
                value={expCtc}
                onChange={e => handleExpCtc(e)}
                variant="outlined"
              />
            </Grid> */}
          </Grid>
        </Paper>
      </Grid>
      {loader}
    </React.Fragment>
  );
};
RegistrationPage.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  jobId: PropTypes.number,
  mobNu: PropTypes.number,
  // jobId: PropTypes.number.isRequired,
  title: PropTypes.string,
};
export default withStyles(styles)(RegistrationPage);
