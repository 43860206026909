export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    dividerInstruction: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    root: {
      flexGrow: 1,
    },
    card: {
      minWidth: 275,
      padding: '10px',
    },
    papertext:{
      padding: theme.spacing(1, 1, 1,1),
      marginTop: theme.spacing(2),
      fontSize:'20px',
      marginBottom: theme.spacing(2),
      fontFamily: "Times"
    },
    GridText:{
      marginTop:'100px',
      marginLeft:'11%',
      marginRight:'11%',
      // marginRight:'25%',
      display: 'block',
      // position:'fixed'
    }
});