import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import styles from './viewF2Fquestions.style';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Loader from '../../../hoc/loader';
import F2FQuestions from './createF2Fquestions'
import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  withStyles,
  Modal,
  TablePagination,
  TableContainer,
} from '@material-ui/core';
import { useAlert } from '../../../hoc/alert/alert';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Switch from '@material-ui/core/Switch';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import MediaQuery from 'react-responsive';
import Close from '@material-ui/icons/Close';

const ViewF2FQuestions = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = useState('');
  const [parameter,setParameter] = useState('');
  const [question, setQuestion] = useState('');
  const [indexId ,setIndexId] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [pagination , setPagination] =useState(false)
  const [status, setStatus] = useState(false);
  const alert = useAlert();

  const {
    data: parameterData,
    isLoading:ParameterDataLoadind ,
    doFetch: fetchParameter,
  } = useFetch([], { suppressAlert: true });
  const {
    data: roleData,
    isLoading: RoleDataLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });
  const {
      data : F2FTableData,
      isLoading:F2FTableDataLoding,
      doFetch : fetchTableData,
  } = useFetch("", { suppressAlert: true });
  
  const {
    data:updateQuestionData,
    isLoading:updateQuestionDataLoading,
    doFetch:fetchUpdateQuestion
  } = useFetch(null);

  const {
    data :DeleteQuestionData,
    isLoading:DeleteQuestionDataLoading,
    doFetch:fetchDeleteQuestion
  } = useFetch(null);

  useEffect(()=>{
      if(pagination || DeleteQuestionData ||updateQuestionData){
      getF2FQuestions();
      setOpen(false)
      }
  },[updateQuestionData,DeleteQuestionData,page,rowsPerPage])


  const getF2FQuestions =() =>{
    if (!role) {
      alert.warning('Select Round');
      return;
    } else if(!parameter){
      alert.warning('Select Parameter');
      return;
    }
    fetchTableData({
      url: `${urls.getF2FQuestionsApi}?round_id=${role}&parameter_id=${parameter}&page_size=${rowsPerPage || 50}&page=${page+1}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    if (auth) {
      fetchRole({
        url: urls.getRoundMappingApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [fetchRole,auth]);

  useEffect(()=>{
    if(role){
    fetchParameter({
      url: `${urls.parametersApi}?round_id=${role}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      }
    });
  }
  },[role])

  function handleChangePage(event, newPage) {
    setPagination(true)
    setPage(newPage)
    {!rowsPerPage && setRowsPerPage(50)} 
  }

  function handleChangeRowsPerPage(event) {
    setPagination(true)
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPagination(true)
    setPage(0)
  }
    
  function lastPageChange(lastPage) {
    setPagination(true)
    setPage(lastPage)
  };

  function editQuestionFunction(i){
    setQuestion(F2FTableData && F2FTableData.results[i].question)
    setIndexId(F2FTableData && F2FTableData.results[i].id)
    setStatus(!(F2FTableData && F2FTableData.results[i].is_delete))
    setOpen(true)
  }
  function updateQuestion(){
    if(!question){
      alert.warning('Enter Question')
      return
    }
    const updatedQuestion = {
      'question':question,
      'round_id':role,
      'parameter_id':parameter,
      'is_delete': status === true ? false : true
    }
    setPage(0)
    fetchUpdateQuestion({
      url: `${urls.updateF2FQuestionApi}${indexId}/retrieve_f2f_questions/`,
      method:'PUT',
      body:updatedQuestion,
      headers: {
       Authorization: `Bearer ${auth.personal_info.token}`,
       'Content-Type':'application/json'
     }
    })
  }

  // function deleteFunction(id){
  //   setPage(0)
  //   fetchDeleteQuestion({
  //       url: `${urls.updateF2FQuestionApi}${id}/retrieve_f2f_questions/`,
  //       method:'DELETE',
  //       headers: {
  //        Authorization: `Bearer ${auth.personal_info.token}`,
  //        'Content-Type': 'application/json',
  //      }
  //   });
  // }

  function tableTopicDetails() {
    let tableData = null;
    if (F2FTableData && F2FTableData.results.length) {
      tableData =  (
        <React.Fragment>
            <TableBody>
              {F2FTableData.results.map((data, i) => (
                <TableRow key={data.id}>
                  <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}>{data.question}</TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}>
                    {/* <Button
                      className={classes.addButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => deleteFunction(data.id)}
                    >
                      In Active
                    </Button> */}
                      {data.is_delete ?  
                      <CancelIcon style={{ color: 'red' }} /> :
                      <CheckCircleRoundedIcon style={{ color: 'green' }} /> 
                      }
                  </TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => editQuestionFunction(i)}
                    >
                      Update
                    </Button>
                  </TableCell>
                  {/* <TableCell align="center" style={{ border: "1px solid black"}}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => deleteFunction(data.id)}
                    >
                      In Active
                    </Button>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
        </React.Fragment>
      )
  }
  return tableData;
};

let loader = null;
    if (F2FTableDataLoding || RoleDataLoading || ParameterDataLoadind|| DeleteQuestionDataLoading ||updateQuestionDataLoading) {
  loader = <Loader open />;
}

  return (
    <React.Fragment>
       <MediaQuery minWidth={451}> <div> <b style={{fontSize:'36px'}}>F2F Questions</b> <b style={{float:'right',fontSize:'20px'}}><F2FQuestions/></b> </div> </MediaQuery>
       <MediaQuery maxWidth={450}> <div> <b style={{fontSize:'24px'}}>F2F Questions</b> <b style={{float:'right'}}><F2FQuestions/></b> </div> </MediaQuery>
      <Divider className={classes.divider} />
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
          <Grid item md={5} xs={12} >
                <TextField
                label="Round"
                // className={classes.textField}
                fullWidth
                className="textList"
                margin="dense"
                required
                onChange={e => setRole(e.target.value)}
                select
                value={role || ''}
                variant="outlined"
              >
                {roleData
                  ? (roleData.map(data => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.round_name}
                    >
                      {data.round_name ? data.round_name : ''}
                    </MenuItem>
                    )))
                  : (<MenuItem
                      value='0'
                      key='0'
                      name='None'
                    >
                      None
                    </MenuItem>)
                }
            </TextField>
            </Grid>
            <Grid item md={5} xs={12} >
             {role &&  <TextField
                label="Parameter"
                // className={classes.textField}
                fullWidth
                className="textList"
                margin="dense"
                required
                onChange={e => setParameter(e.target.value)}
                select
                value={parameter || ''}
                variant="outlined"
              >
                {parameterData
                  ? (parameterData.map(data => (
                    <MenuItem
                      value={data.parameter?data.parameter.id:''}
                      key={data.parameter?data.parameter.id:''}
                      name={data.parameter?data.parameter.parameter:''}
                    >
                      {data.parameter && data.parameter.parameter ? data.parameter.parameter : ''}
                    </MenuItem>
                    )))
                  : (<MenuItem
                      value='0'
                      key='0'
                      name='None'
                    >
                      None
                    </MenuItem>)
                }
            </TextField>}
            </Grid>
            
            <Grid item md={2} xs={12} className={classes.applyButtonSection}>
             <Button
                className={classes.applyButton}
                color="primary"
                variant="contained"
                size="large"
                onClick={() => getF2FQuestions(setPage(0))}
              >
                Get F2F Questions
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Divider className={classes.divider} />
        {F2FTableData && F2FTableData.results.length === 0 ? <h1 style ={{textAlign:'center',marginTop:'10%',color:'blue'}}>No Records Found</h1>:'' }
        {F2FTableData && F2FTableData.results.length !== 0 && <Paper>  
         <React.Fragment>
           <TableContainer className="role">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ border: "1px solid black"}}><strong>S.No.</strong></TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}><strong>Question</strong></TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}><strong>In Active</strong></TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}><strong>Update</strong></TableCell>
              </TableRow>
            </TableHead>
            {tableTopicDetails()}
         </Table>
        
         <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+F2FTableData.total_pages}`}
                  rowsPerPageOptions={[50,75,100]}
                  count={+F2FTableData.count}
                  rowsPerPage={rowsPerPage || 50}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(F2FTableData.total_pages - 1)}
                    disabled={page === (+F2FTableData.total_pages - 1)}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>  
            </TableContainer>
            </React.Fragment> 
        </Paper>}
        <React.Fragment>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          style={{width:'80%',marginLeft:"10%"}}
          // onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <React.Fragment>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h4">
                    <strong> Update F2F Questions</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
                  {/* <Typography className="headings" variant="h4" ><strong>Update F2F Question</strong></Typography> */}
                  <Divider className={classes.divider} />
                <Grid container spacing={20}>
                  <Grid item md={12}  xs={12}>
                    <TextField
                      label="Question"
                      margin="dense"
                      fullWidth
                      multiline
                      rows="10"
                      value={question|| ''}
                      onChange={e => setQuestion(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                   <Typography>Set This Question as Active/InActive :</Typography>
                      In Active
                      <Switch 
                        checked={status|| false}
                        value={status|| false}
                        onChange={(e)=> setStatus(e.target.checked)}
                        color="primary"
                      />Active
                   </Grid>
                   <Grid container align="center" justify="center">
                  <Grid item  md={3} xs={12}>
                    <Button
                      // className={classes.applyButtonModal}
                      fullWidth
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={() => updateQuestion()}
                    >
                      Update
                    </Button>
                  </Grid>
                  </Grid>
              </Grid>
              <Divider className={classes.divider} />
          </React.Fragment> 
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
        {loader}
    
    </React.Fragment>
  ); 
}
ViewF2FQuestions.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ViewF2FQuestions);