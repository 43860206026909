import React, { useState, useEffect } from "react";
// import SpokenTest from "../spokenTest/spokenTest";
import ApplicantTest from "../applicantTest/applicantTest";
import Loader from "../../../hoc/loader";
import useFetch from "../../../hoc/useFetch";
import urls from "../../../url";
import styles from "./process.style";
import MediaQuery from "react-responsive";
// import { Link } from "react-router-dom";
import {
  withStyles,
  Divider,
  Button,
  Grid,
  Typography,
  Stepper,
  Step,
  StepButton,
  StepContent,
  StepLabel,
  TextField,
  MenuItem,
  Box,
} from "@material-ui/core";
import axios from 'axios';
import F2FTimeSlots from './f2fTimeSlots';
import { useAlert } from '../../../hoc/alert/alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import jsPDF from 'jspdf';
import PrintIcon from '@material-ui/icons/Print';
import html2canvas from 'html2canvas';
import './styles.css';



function Process({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState(" ");
  const [selectedTime, setSelectedTime] = useState(" ");
  const [open, setOpen] = useState(false);
  const alert = useAlert();


  const {
    data: processData,
    isLoding: processDataLoading,
    doFetch: fetchProcessData
  } = useFetch(null);

  const {
    data: positionDetails,
    isLoading: applicantLoading,
    doFetch: fetchPositionDetails
  } = useFetch(null);

  const {
    data: offerDetails,
    isLoading: offerLoading,
    doFetch: fetchOfferDetails
  } = useFetch(null);


  let loader = null;
  if (processDataLoading || applicantLoading || offerLoading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    fetchProcessData({
      url: urls.applicantStatus,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  }, []);

  useEffect(() => {
    if (auth) {
      fetchPositionDetails({
        url: `${urls.retrivePositionDetails}?position_id=${auth?.academic_profile?.position_apply}&mobile_number=${auth?.academic_profile?.contact_no}`,
        method: "GET",
        flag: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }

  }, [auth])

  useEffect(() => {
    if (positionDetails && positionDetails[0]?.applicant && processData &&
      processData.next_round === "" &&
      processData.completed === true &&
      processData.round_clear === true) {
      fetchOfferDetails({
        url: `${urls.offerLetterList}?search=${positionDetails[0]?.applicant?.user?.email}`,
        method: "GET",
        flag: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [positionDetails, processData, processData?.next_round, processData?.completed, processData?.round_clear]);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function toWordsconver(price) {
    var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
      dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
      tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (price += "", isNaN(parseInt(price))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 2:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, price[digitIdx + 1]));
          break;
        case 9:
          words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
      }
      str = words.reverse().join("")
    } else str = "";
    return str

  }

  function onButtonClick() {
    var doc = new jsPDF("p", "mm", "a4");
    html2canvas(document.querySelector('#divToPrint'), { useCORS: true }).then(function (canvas) {
      var imgData = canvas.toDataURL('image/png');
      var pageHeight = 290;
      var imgWidth = (canvas.width * 50) / 180;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var position = 5;

      doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      // doc.output('dataurlnewwindow');
      doc.save('ORCHIDS Offer Letter.pdf');

    });
    setOpen(false);
  }

  function handleAcceptDeclineOffer(resValue) {
    if (resValue === 'decline') {
      const payLoad = {
        is_offer_letter_accepted: false
      }
      axios.put(`${urls.offerLetterAccept}`, payLoad,
        {
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            'Content-Type': 'application/json'
          },
        }).then((response) => {
          if (response.status === 200) {
            alert.success('Successfully Declined');
            setOpen(false);
          } else {
            alert.error('Something went wrong');
          }
        })
        .catch((error) => {
          alert.error('Something went wrong');
        })
    }
    if (resValue === 'accept') {
      const payLoad = {
        is_offer_letter_accepted: true
      }
      axios.put(`${urls.offerLetterAccept}`, payLoad,
        {
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
            'Content-Type': 'application/json'
          },
        }).then((response) => {
          if (response.status === 200) {
            alert.success('Successfully Accepted');
            setOpen(false);
          } else {
            alert.error('Something went wrong');
          }
        })
        .catch((error) => {
          alert.error('Something went wrong');
        })
    }
  }

  function handleScheduleInterview() {
    axios.get(`${urls.scheduleInterview}?start_time=${`${selectedDate},${selectedTime.split('-')[0]}`}`,
      {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json'
        },
      }).then((response) => {
        console.log('response', response);
        if (response.status === 200) {
          if (response?.data?.response === 'success') {
            const obj = {
              applicant: positionDetails[0]?.id,
              user: response?.data?.selected_interviewer,
              // topic: selectTopic,
              duration: "20",
              start_time: `${selectedDate},${selectedTime.split('-')[0]}`,
            };
            axios.post(`${urls.assignPlannerRound}`, obj,
              {
                headers: {
                  Authorization: `Bearer ${auth.personal_info.token}`,
                  'Content-Type': 'application/json'
                },
              }).then((response) => {
                if (response.status === 200) {
                  alert.success('Your interview slot was successfully Scheduled');
                  fetchProcessData({
                    url: urls.applicantStatus,
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${auth.personal_info.token}`
                    }
                  });
                  fetchPositionDetails({
                    url: `${urls.retrivePositionDetails}?position_id=${auth?.academic_profile?.position_apply}&mobile_number=${auth?.academic_profile?.contact_no}`,
                    method: "GET",
                    flag: true,
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${auth.personal_info.token}`,
                    }
                  });
                }
                else {
                  alert.warning("Selected slot is reserved, Please select another slot");
                }
              })
              .catch((error) => {
                alert.warning("Selected slot is reserved, Please select another slot");
              })
          }
          else {
            alert.warning("Selected slot is reserved, Please select another slot");
          }
        } else {
          alert.warning("Selected slot is reserved, Please select another slot");
        }
      })
      .catch((error) => {
        alert.warning("Selected slot is reserved, Please select another slot");
      })
  }

  // useEffect(() => {
  //   if (interviewerFree.response === "success") {
  //     const obj = {
  //       applicant: applicantId,
  //       user: selectedInterviewer,
  //       // topic: selectTopic,
  //       duration: duration,
  //       start_time: `${selectedDate},${selectedTime}:00`,
  //     };
  //     fetchAssignSpokenTest({
  //       url: urls.assignPlannerRound,
  //       method: "post",
  //       body: obj,
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${name.personal_info.token}`,
  //       },
  //     });
  //   }
  // }, [interviewerFree, fetchAssignSpokenTest, name]);

  const showRegistration = () => {
    return (
      <>
        <h1 className={classes.completedParas}>Round {processData.round}</h1>
        <p className={classes.completedParas} style={{ color: "green" }}>
          Registration Completed !!
        </p>
      </>
    );
  };

  const roundData = (round, stringVal, color, next) => {
    if (next) {
      return (
        <>
          {round.round_type === 'mcq' ?
            <h1 className={classes.completedParas}>Mcq Round</h1>
            : round.round_type === 'f2f' ?
              <h1 className={classes.completedParas}>Face To Face Round</h1>
              :
              <h1 className={classes.completedParas}>{round?.round_type[0].toUpperCase() + round?.round_type.substring(1)} Round</h1>
          }
          <p className={classes.completedParas} style={{ color: color }}>
            {stringVal}
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className={classes.completedParas} style={{ color: color }}>
            {stringVal}
          </p>
        </>
      );
    }
  };

  const showProfile = () => {
    if (
      processData.round_type === "profile" &&
      processData.completed === false
    ) {
      return (
        <>
          <h1 className={classes.completedParas}>Round {processData.round}</h1>
          <p
            className={classes.completedParas}
            style={{ color: "red", fontWeight: "bold" }}
          >
            !! Profile Not Yet Completed
          </p>
        </>
      );
    } else {
      return (
        <>
          <h1 className={classes.completedParas}>Round {processData.round}</h1>
          <p className={classes.completedParas} style={{ color: "green" }}>
            Profile Completed
          </p>
        </>
      );
    }
  };

  const showMcqAssign = () => {
    if (processData.round_type === "mcq" && processData.round_assign === true) {
      return (
        <>
          <h1 className={classes.completedParas}>Round {processData.round}</h1>
          <p
            className={classes.completedParas}
            style={{ color: "green", fontWeight: "bold" }}
          >
            MCQ Assigned
          </p>
        </>
      );
    } else {
      return (
        <>
          <h1 className={classes.completedParas}>Round {processData.round}</h1>
          <p className={classes.completedParas} style={{ color: "red" }}>
            MCQ Not Assigned Yet
          </p>
        </>
      );
    }
  };

  const takingTest = id => {
    localStorage.setItem("round_id", id);
  };

  const successfullyCompleted = (completed, next) => {
    return (
      <h2 style={{ fontWeight: 500 }}>
        Kudos! you have successfully completed your{" "}
        <span className={classes.textBold}>{completed}</span>, Please complete
        your <span className={classes.textBold}>{next}</span> to go to next step
        or contact HR for further process
      </h2>
    );
  };

  const showClearedMsg = (roundClear, roundType) => {
    if (roundClear === false) {
      return (
        <h2 style={{ color: "blue", fontFamily: "Times", marginTop: "50px" }}>
          Thank you for applying to ORCHIDs! We wish to inform you that you have
          not achieved the threshold marks required to progress to the next
          round. We do hope you apply again to our institution! Till then –
          preparation is key!
        </h2>
      );
    } else if (roundClear === true) {
      successfullyCompleted();
    } else {
      return (
        <h2 style={{ fontWeight: 500 }}>
          Your <span className={classes.textBold}> {roundType} Test </span>
          result is being processed,Please Wait or Contact Hr for further
          process...
        </h2>
      );
    }
  };

  const showMcqClearMsg = roundClear => {
    if (roundClear === false) {
      return (
        <h2 style={{ color: "blue", fontFamily: "Times", marginTop: "50px" }}>
          Thank you for applying to ORCHIDs! We wish to inform you that you have
          not achieved the threshold marks required to progress to the next
          round. We do hope you apply again to our institution! Till then –
          preparation is key!
        </h2>
      );
    } else if (roundClear === true) {
      return (
        <>
          <h1>Cleared</h1>
        </>
      );
    }
  };

  return (
    <div>
      <h2 className={classes.processHead}>Process</h2>
      <Divider />
      <br />
      <MediaQuery minWidth={985}>
        <div className={classes.mainBox} >
          <div className={classes.leftBox} >
            {processData && processData.round_type === "registration" && (
              <h2 style={{ fontWeight: 500 }}>
                Kudos! you have successfully completed your{" "}
                <span className={classes.textBold}>{processData.round_type}</span>
                , Please complete your{" "}
                <span className={classes.textBold}>Profile</span> to go to next
                step{" "}  or contact HR for further process
              </h2>
            )}
            {processData &&
              processData.round_type === "profile" &&
              processData.completed === false && (
                <h2 style={{ fontWeight: 500 }}>
                  Please fill your{" "}
                  <span className={classes.textBold}>Profile</span> to Proceed to
                  Next Step
                </h2>
              )}
            {processData &&
              processData.round_type === "profile" &&
              processData.completed === true && (
                <h2 style={{ fontWeight: 500 }}>
                  Kudos! you have successfully completed your{" "}
                  <span className={classes.textBold}>
                    {processData.round_type}
                  </span>
                  , Please complete your{" "}
                  <span className={classes.textBold}>HR</span> to go to next step{" "} or  or contact HR for further process
                </h2>
              )}
            <div>
              <h2>Sequence For Test Process</h2>
              <p>Your test will be processed step wise as mentioned below</p>
              <Stepper
                activeStep={parseInt(processData?.round)}
                orientation="vertical"
              >
                {positionDetails && positionDetails[0]?.response?.map((item) =>
                  <Step key={item?.round_no}>
                    <StepLabel>
                      <h2 style={{ fontWeight: 500 }}>
                        {item.round_type === 'profile' ?
                          <span>{(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1))}</span>
                          :
                          item?.round_type === 'f2f' ?
                            item?.round_assign === true && item?.round_clear === true && item?.mark_scored !== null ?
                              <Typography style={{ color: 'green', fontWeight: 'bold' }}>
                                Face to Face Interview - Completed<i>(Hurrah,you're qualified)</i>
                                <br />
                                &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test has been successfully completed</span>
                              </Typography>
                              :
                              item?.round_assign === true && item?.round_clear === null && item?.round_complete === true ?
                                <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                  Face to Face Interview - Completed<i> (we are verifying)</i>
                                  <br />
                                  &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test has been successfully completed</span>
                                </Typography>
                                :
                                // item?.previous_round === true && item?.round_assign === false && item?.round_clear === null && item?.round_complete === null && item?.round_id === null ?
                                //   <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                //     Face to Face Interview - We are Scheduling Interview Slot
                                //     <br />
                                //     &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test is getting Scheduled,<br /><i>Sit Back and Relax...</i></span>
                                //   </Typography>
                                //   :
                                item?.previous_round === true && item?.round_assign === false && item?.round_clear === null && item?.round_complete === null && item?.round_id === null ?
                                  positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_assign === true && positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_clear === true && positionDetails && !positionDetails[0]?.response[parseInt(processData.round - 1)]?.marks_scored ?
                                    <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                      Face to Face Interview - we are reviewing
                                      {/* &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test is ready to attempt</span> */}
                                      <ul style={{ color: "black", fontWeight: 'normal' }}>
                                        Note:<li>Please wait, until you're previous rounds are verified</li>
                                      </ul>
                                    </Typography>
                                    :
                                    <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                      Face to Face Interview - Ready To Attempt(Book Interview Slot)
                                      <br />
                                      &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test is ready to attempt</span>
                                      <ul style={{ color: "black", fontWeight: 'normal' }}>
                                        Note:<li>Please select date, time slot for scheduling interview</li>
                                      </ul>
                                    </Typography>
                                  :
                                  item?.previous_round === true && item?.round_assign === true && item?.round_clear === null && item?.round_complete === null && item?.round_id !== null ?
                                    <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                      Face to Face Interview - Ready To Attempt
                                      <br />
                                      &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test is ready to attempt</span>
                                    </Typography>
                                    :
                                    item?.round_assign === true && item?.round_clear === false ?
                                      <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                                        Face to Face Interview - Rejected
                                        <br />
                                        &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}><i style={{ color: "red", fontWeight: 'bold' }}>Oops..!</i>you're rejected in Face to Face Interview. You can't been proceed for further process</span>
                                      </Typography>
                                      :
                                      <Typography style={{ color: 'gray', fontWeight: 'bold' }}>
                                        Face to Face Interview - Pending
                                        <br />
                                        &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your not qualified for <span>Face to Face Interview</span> test yet</span>
                                      </Typography>
                            :
                            item?.round_assign === true && item?.round_clear === true && item?.marks_scored ?
                              <Typography style={{ color: 'green', fontWeight: 'bold' }}>
                                {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + " Test - Completed(Hurrah,you're qualified)")}
                                <br />
                                &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>{item?.round_type}</span> test has been successfully completed</span>
                              </Typography>
                              :
                              item?.round_assign === true && item?.round_clear === true && !item?.marks_scored ?
                                <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                  {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + ' Test - Completed (we are verifying you can move to next step)')}
                                  <br />
                                  &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>{item?.round_type}</span> test has been successfully completed</span>
                                </Typography>
                                :
                                item?.round_assign === true && item?.round_clear === null ?
                                  <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                    {(item?.round_type?.toUpperCase() + ' Test' + ' - Ready To Attempt')}
                                    <br />
                                    &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>{item?.round_type}</span> test is ready to attempt</span>
                                  </Typography>
                                  :
                                  item?.round_assign === true && item?.round_clear === false ?
                                    <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                                      {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + ' Test' + ' - Rejected')}
                                      <br />
                                      &nbsp;<i style={{ color: "black", fontWeight: 'normal' }}><i style={{ color: "red", fontWeight: 'bold' }}>Oops..!</i> you're rejected in <span>{item?.round_type}</span> test. You're not qualified for futher process.</i>
                                    </Typography>
                                    :
                                    <Typography style={{ color: 'gray', fontWeight: 'bold' }}>
                                      {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + ' Test' + ' - Pending')}
                                      <br />
                                      &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your not qualified for <span>{item?.round_type}</span> test yet</span>
                                    </Typography>
                        }
                      </h2>
                    </StepLabel>
                    <StepContent>
                      {processData &&
                        processData.round_type === "mcq" &&
                        processData.round_clear === null &&
                        processData.round_assign === true &&
                        processData.completed === null && (
                          <>
                            <a href='/applicant_test'>
                              <Button
                                variant='contained'
                                color='primary'
                                id='mcqTestBtn'
                                onClick={() => takingTest(processData.round_id)}
                              >
                                Take MCQ Test
                              </Button>
                            </a>
                          </>
                        )}
                      {processData &&
                        processData.round_type === "spoken" &&
                        processData.round_clear === null &&
                        processData.round_assign === true &&
                        processData.completed === null && (
                          <>
                            <a href='/spoken_test'>
                              <Button
                                variant='contained'
                                color='primary'
                                id='mcqTestBtn'
                                onClick={() => takingTest(processData.round_id)}
                              >
                                Take Spoken Test
                              </Button>
                            </a>
                            <br />
                            <br />
                            <strong>Note:</strong><p><i>Please, allow microphone access while giving spoken test.</i></p>
                          </>
                        )}
                      {processData &&
                        processData?.round_type === "written" &&
                        processData?.round_clear === null &&
                        processData?.round_assign === true &&
                        processData?.completed === null && (
                          <>
                            <a href='/written_test'>
                              <Button
                                variant='contained'
                                color='primary'
                                id='mcqTestBtn'
                                onClick={() => takingTest(processData.round_id)}
                              >
                                Take Written Test
                              </Button>
                            </a>
                          </>
                        )}
                      {positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_type !== "profile" ?
                        <>
                          {processData &&
                            processData.round_type === "f2f" &&
                            processData.round_clear === null &&
                            processData.round_assign === false &&
                            processData.completed === null &&
                            processData.round_id === null &&
                            positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_assign === true && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_clear === true && positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.marks_scored &&
                            (
                              <>
                                <Grid container spacing={2} className={classes.gride}>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      // className={classes.textField}
                                      helperText="Select Date"
                                      type="date"
                                      margin="dense"
                                      className="textList"
                                      fullWidth
                                      onChange={(e) => setSelectedDate(e.target.value)}
                                      required
                                      value={selectedDate || " "}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      helperText="Select Time Slot"
                                      label="F2F Time Slots 9:30 AM - 9:30 PM"
                                      // className={classes.textField}
                                      className="textList"
                                      margin="dense"
                                      // required
                                      onChange={e => setSelectedTime(e.target.value)}
                                      select
                                      value={selectedTime || ''}
                                      variant="outlined"
                                      fullWidth
                                    >
                                      {F2FTimeSlots
                                        ? (F2FTimeSlots.map(data => (
                                          <MenuItem
                                            value={data.send}
                                            key={data.id}
                                            name={data.value}
                                          >
                                            {data.value ? data.value : ''}
                                          </MenuItem>
                                        ))) : ''}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <Button
                                      variant='contained'
                                      color='primary'
                                      id='mcqTestBtn'
                                      disabled={(selectedTime === " " || selectedDate === " ") ? true : false}
                                      onClick={() => handleScheduleInterview()}
                                    >
                                      Schedule Interview Slot
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                        </>
                        :
                        <>
                          {processData &&
                            processData.round_type === "f2f" &&
                            processData.round_clear === null &&
                            processData.round_assign === false &&
                            processData.completed === null &&
                            processData.round_id === null &&
                            (
                              <>
                                <Grid container spacing={2} className={classes.gride}>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      // className={classes.textField}
                                      helperText="Select Date"
                                      type="date"
                                      margin="dense"
                                      className="textList"
                                      fullWidth
                                      onChange={(e) => setSelectedDate(e.target.value)}
                                      required
                                      value={selectedDate || " "}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      helperText="Select Time Slot"
                                      label="F2F Time Slots 9:30 AM - 9:30 PM"
                                      // className={classes.textField}
                                      className="textList"
                                      margin="dense"
                                      // required
                                      onChange={e => setSelectedTime(e.target.value)}
                                      select
                                      value={selectedTime || ''}
                                      variant="outlined"
                                      fullWidth
                                    >
                                      {F2FTimeSlots
                                        ? (F2FTimeSlots.map(data => (
                                          <MenuItem
                                            value={data.send}
                                            key={data.id}
                                            name={data.value}
                                          >
                                            {data.value ? data.value : ''}
                                          </MenuItem>
                                        ))) : ''}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <Button
                                      variant='contained'
                                      color='primary'
                                      id='mcqTestBtn'
                                      disabled={(selectedTime === " " || selectedDate === " ") ? true : false}
                                      onClick={() => handleScheduleInterview()}
                                    >
                                      Schedule Interview Slot
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                        </>
                      }
                      {processData &&
                        processData.round_type === "f2f" &&
                        processData.round_clear === null &&
                        processData.round_assign === true &&
                        processData.completed === null &&
                        processData.round_id !== null && (
                          <>
                            <a href='/facetofaceround'>

                              <Button
                                variant='contained'
                                color='primary'
                                id='mcqTestBtn'
                                onClick={() => takingTest(processData.round_id)}
                              >
                                Take Face To Face Interview
                              </Button>
                            </a>
                          </>
                        )}
                    </StepContent>
                  </Step>
                )}
              </Stepper>
            </div>
            {processData &&
              processData.round_type === "mcq" &&
              processData.round_clear === false &&
              showClearedMsg(processData.round_clear)}
            {processData &&
              (processData.round_type === "mcq" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f") &&
              processData.round_clear === true &&
              processData.next_round !== "" &&
              successfullyCompleted(
                processData.round_type,
                processData.next_round
              )}

            {/* {processData &&
            processData.round_type === "written" &&
            processData.round_assign === true &&
            processData.completed === true &&
            showClearedMsg(processData.round_clear)} */}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f") &&
              processData.round_assign === true &&
              processData.completed === true &&
              showClearedMsg(processData.round_clear, processData.round_type)}


            {processData &&
              processData.next_round === "f2f" &&
              processData.completed === true && (
                <>
                  <br></br> <h2 style={{ fontWeight: 500 }}>
                    <span className={classes.textBold}>
                      Face to Face Interview {" "}
                    </span>
                    will be Assigned shortly, Please wait for HR to assign you a slot.
                  </h2>
                </>
              )}



            {processData &&
              processData.next_round === "" &&
              processData.completed === true &&
              processData.round_clear === true && (
                <>
                  <h2 style={{ fontWeight: 500 }}>
                    Congrats You have completed all the rounds successfully
                  </h2>
                  <div >
                    <Typography style={{ fontSize: '25px' }}>
                      <strong style={{ color: '#4c7bac' }}>Offer Letter Section</strong>
                      {/* {offerDetails?.results[0]} */}
                    </Typography>
                  </div>
                  <div style={{ marginTop: '50px' }}>
                    {offerDetails?.results[0] ?
                      <Grid container spacing={1}>
                        <Grid item md={2} />
                        <Grid item md={12} xs={12}>
                          <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                            <Grid container>
                              <Grid item md={9} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ color: 'black' }}><strong>To View Offer Letter :</strong> </Typography>
                              </Grid>
                              <Grid item md={3} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ color: 'black' }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleOpen()}
                                  >
                                    Click Here
                                  </Button>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      :
                      <Grid container spacing={1}>
                        <Grid item md={2} />
                        <Grid item md={12} xs={12}>
                          <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                            <Grid container>
                              <Grid item md={3} xs={12}>
                                <Typography style={{ color: 'black' }}><strong>We Are Revewing your application</strong> </Typography>
                              </Grid>
                              {/* <Grid item md={9} xs={12}>
                                    <Typography style={{ color: 'black' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpen()}
                                        >
                                            Click Here
                                        </Button>
                                    </Typography>
                                </Grid> */}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  </div>
                </>
              )}
          </div>
          <div className={classes.rightBox} style={{ width: "40vh", height: "40vh", marginLeft: "15%", }}>
            {processData &&
              processData.round_type === "registration" &&
              showRegistration()}
            {processData && processData.round_type === "profile" && showProfile()}
            {/* {processData &&
            processData.round_type === "mcq" &&
            processData.round_clear === null &&
            showMcqAssign()} */}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === true &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Processing...",
                "blue",
                processData.round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.round_clear === true &&
              processData.round_assign === true &&
              processData.completed === true &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Cleared",
                "green",
                processData.next_round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.round_clear === false &&
              processData.round_assign === true &&
              processData.completed === true &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Rejected",
                "red",
                processData.next_round
              )}
            {processData &&
              processData.round_type === "written" &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === null &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Written Test",
                "black",
                processData.next_round
              )}
            {processData &&
              processData.round_type === "spoken" &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === null &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Spoken Test",
                "black",
                processData.next_round
              )}
            {processData &&
              processData.round_type === "f2f" &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === null &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Face to Face Interview",
                "black",
                processData.next_round
              )}
            {processData &&
              processData.round_type === "mcq" &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === null &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "MCQ Test",
                "black",
                processData.next_round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.next_round === "" &&
              processData.completed === true &&
              processData.round_clear === true &&
              !offerDetails?.results[0] &&
              roundData(
                processData,
                "Congrats All Process Completed, Offer letter will be released soon...",
                "green",
                processData.next_round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.next_round === "" &&
              processData.completed === true &&
              processData.round_clear === true &&
              offerDetails?.results[0] &&
              roundData(
                processData,
                "Offer letter released successfully",
                "green",
                processData.next_round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.next_round === "" &&
              processData.completed === null &&
              processData.round_clear === null &&
              processData.round_assign === false &&
              processData.round_id === null &&
              roundData(
                processData,
                "We Are Reviewing",
                "blue",
                processData.next_round
              )}
          </div>
        </div>
        {open ?
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              style={{
                width: '-webkit-fill-available',
                textAlign: 'left',
                alignItems: 'left',
                justifyContent: 'center'
              }}
            // fullScreen={fullScreen}
            >
              <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'center', backgroundColor: 'primary' }}>Offer Letter Preview</DialogTitle>
              <DialogContent
              >
                <DialogContentText
                  id="responsive-dialog-description"
                  tabIndex={-1}
                >
                  <div id='divToPrint'>
                    <div id=":n8">
                      <div
                        class="adn ads"
                        style={{ display: "dataMessageId=#msg-f:1728703631299798012 dataLegacyMessage-id=17fd96cb298d63fc" }}>
                        <div class="">
                          <div id=":oo" class="ii gt" jslog="20277; u014N:xr6bB; 4:W251bGwsbnVsbCxbXV0.">
                            <div id=":on" class="a3s aiL ">
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}>Dear {offerDetails?.results[0]?.candidate_name}</b>
                              ,<br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}>Congratulations!!
                              </b>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>This is with reference to your application and subsequent interviews you had with us.
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>We would like to offer you the position of
                                <b> {offerDetails?.results[0]?.designation} </b>
                                with
                                <b> {offerDetails?.results[0]?.school_name}. </b>
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>This offer<b> (subject to you joining us) on {offerDetails?.results[0]?.date_of_joining} </b> will carry an annual CTC of <b>INR {offerDetails?.results[0]?.ctc_offered}/- ({toWordsconver(offerDetails?.results[0]?.ctc_offered)}).</b></span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}> The offer break-up is provided below.</span><br />
                              <span>
                                <table style={{ borderCollapse: 'collapse', width: '303pt' }} width="404" cellspacing="0" cellpadding="0" border="0">
                                  <colgroup>
                                    <col style={{ width: '155pt' }} width="206" />
                                    <col style={{ width: '73pt' }} width="98" />
                                    <col style={{ width: '75pt' }} width="100" />
                                  </colgroup>
                                  <tbody>
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', width: '155pt', color: 'white', fontWeight: '700', verticalAlign: 'middle', borderWidth: '1pt 0.5pt 0.5pt 1pt', borderStyle: 'solid', borderColor: 'windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} width="206" height="19">Salary Details</td>
                                      <td style={{ borderLeft: 'none', width: '73pt', color: 'white', fontWeight: '700', textAlign: 'center', verticalAlign: 'middle', borderTop: '1pt solid windowtext', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif' }} width="98">Per month</td>
                                      <td style={{ borderLeft: 'none', width: '75pt', color: 'white', fontWeight: '700', textAlign: 'center', verticalAlign: 'middle', borderTop: '1pt solid windowtext', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif' }} width="100">Per Annum</td>
                                    </tr>
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }} height="19">Fixed Gross</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][0] : offerDetails?.results[0]?.salary_excel_data['Per Month'][0]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][0] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][0]} </td>
                                    </tr>
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Basic Allowance</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][1] : offerDetails?.results[0]?.salary_excel_data['Per Month'][1]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][1] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][1]} </td>
                                    </tr>
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">HRA</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][2] : offerDetails?.results[0]?.salary_excel_data['Per Month'][2]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][2] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][2]} </td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Conveyance&nbsp;</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][3] : offerDetails?.results[0]?.salary_excel_data['Per Month'][3]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][3] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][3]} </td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Medical&nbsp; Allowance&nbsp;</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][4] : offerDetails?.results[0]?.salary_excel_data['Per Month'][4]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][4] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][4]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Statutory Bonus</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][5] : offerDetails?.results[0]?.salary_excel_data['Per Month'][5]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][5] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][5]}</td>
                                    </tr >
                                    <tr style={{
                                      height: '14.4pt'
                                    }} height="19"><td style={{ height: '14.4pt', borderTop: 'none', color: 'black', fontWeight: '700', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'rgb(191,191,191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Gross Salary</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(191, 191, 191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }
                                      } >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][6] : offerDetails?.results[0]?.salary_excel_data['Per Month'][6]} </td >
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(191, 191, 191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][6] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][6]} </td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Employee contribution to PF</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][7] : offerDetails?.results[0]?.salary_excel_data['Per Month'][7]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][7] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][7]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Employee contribution&nbsp; to ESIC</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][8] : offerDetails?.results[0]?.salary_excel_data['Per Month'][8]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][8] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][8]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Profession Tax</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][9] : offerDetails?.results[0]?.salary_excel_data['Per Month'][9]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][9] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][9]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }} height="19">Retention Fund*</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][10] : offerDetails?.results[0]?.salary_excel_data['Per Month'][10]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][10] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][10]}</td>
                                    </tr >
                                    <tr style={{
                                      height: '14.4pt'
                                    }} height="19"><td style={{ height: '14.4pt', borderTop: 'none', color: 'black', fontWeight: '700', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'rgb(191,191,191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Net Salary</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(191, 191, 191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }
                                      } >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][11] : offerDetails?.results[0]?.salary_excel_data['Per Month'][11]}</td >
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(191, 191, 191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][11] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][11]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Employer contribution to PF</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][12] : offerDetails?.results[0]?.salary_excel_data['Per Month'][12]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][12] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][12]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Employer contribution&nbsp; to ESIC</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][13] : offerDetails?.results[0]?.salary_excel_data['Per Month'][13]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][13] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][13]}</td>
                                    </tr >
                                    <tr style={{ height: '15pt' }} height="20">
                                      <td style={{ height: '15pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="20">Gratuity</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][14] : offerDetails?.results[0]?.salary_excel_data['Per Month'][14]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][14] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][14]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }} height="19">Retention additional Incentive**</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][15] : offerDetails?.results[0]?.salary_excel_data['Per Month'][15]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][15] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][15]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }} height="19">Medical Insurance cost***</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][16] : offerDetails?.results[0]?.salary_excel_data['Per Month'][16]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][16] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][16]} </td>
                                    </tr >
                                    <tr style={{ height: '15pt' }} height="20">
                                      <td style={{ height: '15pt', borderTop: 'none', color: 'white', fontWeight: '700', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '1pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="20">Cost To Company&nbsp;</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', color: 'white', fontWeight: '700', borderRight: '0.5pt solid windowtext', borderBottom: '1pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][17] : offerDetails?.results[0]?.salary_excel_data['Per Month'][17]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', color: 'white', fontWeight: '700', borderRight: '1pt solid windowtext', borderBottom: '1pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][17] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][17]}</td>
                                    </tr >
                                  </tbody >
                                </table >
                              </span >
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>This is a full-time position and there will be a probationary period of <b> {offerDetails?.results[0]?.probation_period} </b> and the notice period will be <b> {offerDetails?.results[0]?.notice_period}</b>. Your date of joining will be on <b> {offerDetails?.results[0]?.date_of_joining}</b>. Reporting Time on the date of joining will be at <b>{offerDetails?.results[0]?.reporting_time}</b>. Please revert with a confirmation of your acceptance of the offer and date of joining by <b>{offerDetails?.results[0]?.confirm_before_date}</b>.
                              </span>
                              <br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}>You are required to submit the following documents on or before {offerDetails?.results[0]?.submit_documents_before_date}.
                              </b>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;.10th &amp; 12th Passing Certificates/ Marksheets - Originals and Photocopy
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Graduation certificate/ Mark sheets - Originals and Photocopy</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. PG certificate / Mark sheet copies - If any</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Aadhaar card</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Pan Card</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Appraisal/Promotion/increment letter of last organization - both Original and Photocopy -- If Any</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Appointment letter of the last organisation</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Relieving letter of the last organisation</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. 4 passport size colour photographs</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Cancelled Cheque/bank account details proof (Joint account not allowed)</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Last 3 Months Payslips and Bank Statement copy, if applicable</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Universal Account Number (Related to PF, if applicable)</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Employee State Insurance details from last organization (if applicable)</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Latest Resume copy</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Permanent  Address Proof
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Correspondence Address Proof</span>
                              <br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>(Bring all original documents for Verification and all the photocopies should be self-attested)
                              </b>

                              <br />
                              <br />
                              <br />
                              <br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>Important Points:</b>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>1.       Your net salary will be subject to income tax deductible at source. At the time of joining, we request you to submit your investment/tax deduction declaration in our employee HR portal (GreytHR) to ensure that TDS applicable is correctly calculated.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>2.       By accepting this offer/reporting for duty at the branch you confirm and agree that you are bound by the organization policies and rules prescribed in the employee handbook which will be available with the branch EA and your employee portal (GreytHR) and the policies and rules will come into effect from the day you report to the branch.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>3.       This offer to you has been made based on information furnished by you. However, if there is a discrepancy in the documents, certificates or information given by you, we reserve the right to terminate the employment with immediate effect with no liability for any compensation to be paid to you.
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>4.       You provide us with the right to conduct background checks to validate your educational and professional credentials</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>5.       Orchids The International School reserves the right to alter the salary structure components and benefits provided as per its requirement at any time, including but limited to the retention deduction, retention fund, insurance benefit, transportation benefit. However, in case such alteration is made, the gross salary of the employee will not be reduced.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>6.       All employees joining by 30th of September of a calendar year are eligible for increment and appraisal in the next calendar year of joining. Employees joining on or after 1st of October will NOT be eligible for increments and appraisal in the next appraisal cycle but will be eligible for increments in the appraisal cycle that comes after that.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>7.      In case you do not have an updated Adhaar card you will be considered as a retainer and will not be considered as a regular employee. In such a case you will work as a retainer payroll for a maximum of 2 months and if you do not provide the updated Adhaar soft copy by then, the organization will be forced to remove you from the organization and the notice period payment will be recovered from you.  Aadhaar copy should match with online adhaar records. To facilitate this, download your latest E-aadhaar copy that is not more than 1 week old and provide the same at the time of joining.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>8.       In case your work location is a school premise, it is mandatory to get the police verification done at your end within one month of joining the organization to get your salary processed. The branch EA/HR can guide you on this in case you need any help.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>9. Subject to the terms of the letter of appointment (LOA) to be signed by the employee, the employee shall be eligible for, and the Employer shall pay to the Employee, a Retention Payment; provided that the Employee remains in continuous employment with the Employer until the end of the school Academic year as defined in the policies (usually March end for this purpose but subject to change). Except as provided in the LOA Employee shall not be eligible for, and shall not be paid, the Retention Payment if Employee’s employment with the Employer is terminated for any reason on or prior to the end of the academic year, including, without limitation, a termination due to resignation by the Employee herself or termination for Cause (as defined in the LOA) by the Employer due to grave misconduct.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>10. Medical insurance will be provided to the employee, the employee’s spouse,and a maximum of 2 dependent children. The insurance cost is an approxima tion of the insurance cost and can vary.</span>
                              <br />
                              <br />
                              <u style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}><b>Contact person from HR team after joining: {offerDetails?.results[0]?.hr_name} (<a>{offerDetails?.results[0]?.hr_email}</a>)  ({offerDetails?.results[0]?.hr_mobile})</b> <br /><br /><b>Contact person in case there is any issue with joining formalities, induction session or any other HR-related concern after joining - <a>{offerDetails?.results[0]?.escalation_point_email}</a></b>
                              </u>
                              <br />
                              <br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}> Congratulations and wishing you a successful career at ORCHIDS The International School!!!</b>
                              <br />
                              <br />
                              <table style={{ width: '470px' }} width="470" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                  <tr valign="top">
                                    <td style={{ width: '10px', paddingRight: '10px' }}>
                                      <b>
                                        <font face="arial, sans-serif" color="#000000">
                                          <img
                                            alt="photo"
                                            style={{ borderRadius: '0px', width: '65px', height: '49.9052px', maxWidth: '120px' }}
                                            width="65"
                                            height="49.9052132701"
                                            src="https://ci6.googleusercontent.com/proxy/CEXxPZUvWSWfVFq3V8RS6sz0dxpeVHhhLV9Fp71KsMp7gl-0XYEdkqAAL5Ioc9UovWD9e9PTXb-JnpnD8ijBAGkt0Bo0fjgcbNLpvs8apOWDgzTgu-qnFOVgKRgfxxbJLwpEed32qNQ8m1mtEHAxf-o5D0sxOjQpGpJQVr9qmQAIICm_1Qm-SwJahQj8PdtoEUWFl-UzyEBSgLRPnr7emhks=s0-d-e1-ft#https://s3.amazonaws.com/ucwebapp.wisestamp.com/faaa76e0-d0f4-480c-a06c-2bebbce88316/orchidsinternationalschoollogo.format_png.resize_200x.png"
                                          />
                                          &nbsp;
                                        </font>
                                      </b>
                                    </td>
                                    <td style={{ borderRight: '1px solid rgb(69,102,142)' }}>
                                    </td>
                                    <td style={{ fontStretch: 'normal', lineHeight: 'normal', textAlign: 'initial', padding: '0px 10px' }}>
                                      <div
                                        style={{
                                          marginBottom: '5px',
                                          marginTop: '0px'
                                        }}
                                      >
                                        <font
                                          face="arial, sans-serif"
                                          color="#000000">
                                          <b>Best regards,</b>
                                        </font>
                                      </div>
                                      <div style={{ marginBottom: '5px', marginTop: '0px' }}>
                                        <font
                                          face="arial, sans-serif"
                                          color="#000000">
                                          <b>
                                            {offerDetails?.results[0]?.submited_by}
                                          </b>
                                        </font>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContentText >
              </DialogContent >
              <DialogActions style={{ justifyContent: 'space-around' }}>
                <div
                // style={{
                //   width: '100%',
                //   justifyContent: 'end',
                //   display: 'flex',
                //   marginTop: '10px',
                // }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      float: 'left'
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'end',
                    display: 'flex',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    onClick={() => handleAcceptDeclineOffer('decline')}
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      float: 'left',
                      backgroundColor: '#c33434'

                    }}
                  >
                    Decline
                  </Button>
                  <Button
                    onClick={() => handleAcceptDeclineOffer('accept')}
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      float: 'left'
                    }}
                  >
                    Accept
                  </Button>
                </div>
                <div
                  style={{
                    width: '90%',
                    justifyContent: 'flex-end',
                    display: 'flex',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PrintIcon />}
                    onClick={() => onButtonClick()}
                    style={{ textTransform: 'none' }}
                  >
                    Download PDF
                  </Button>
                </div>
              </DialogActions>
            </Dialog >
          </div >
          : ""}
      </MediaQuery>
      <MediaQuery maxWidth={984}>
        <div className={classes.mainBox} >
          <div className={classes.leftBox} >
            {processData && processData.round_type === "registration" && (
              <h2 style={{ fontWeight: 500 }}>
                Kudos! you have successfully completed your{" "}
                <span className={classes.textBold}>{processData.round_type}</span>
                , Please complete your{" "}
                <span className={classes.textBold}>Profile</span> to go to next
                step{" "}  or contact HR for further process
              </h2>
            )}
            {processData &&
              processData.round_type === "profile" &&
              processData.completed === false && (
                <h2 style={{ fontWeight: 500 }}>
                  Please fill your{" "}
                  <span className={classes.textBold}>Profile</span> to Proceed to
                  Next Step
                </h2>
              )}
            {processData &&
              processData.round_type === "profile" &&
              processData.completed === true && (
                <h2 style={{ fontWeight: 500 }}>
                  Kudos! you have successfully completed your{" "}
                  <span className={classes.textBold}>
                    {processData.round_type}
                  </span>
                  , Please complete your{" "}
                  <span className={classes.textBold}>HR</span> to go to next step{" "} or  or contact HR for further process
                </h2>
              )}
            <div>
              <h2>Sequence For Test Process</h2>
              <p>Your test will be processed step wise as mentioned below</p>
              <Stepper
                activeStep={parseInt(processData?.round)}
                orientation="vertical"
              >
                {positionDetails && positionDetails[0]?.response?.map((item) =>
                  <Step key={item?.round_no}>
                    <StepLabel>
                      <h2 style={{ fontWeight: 500 }}>
                        {item.round_type === 'profile' ?
                          <span>{(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1))}</span>
                          :
                          item?.round_type === 'f2f' ?
                            item?.round_assign === true && item?.round_clear === true && item?.mark_scored !== null ?
                              <Typography style={{ color: 'green', fontWeight: 'bold' }}>
                                Face to Face Interview - Completed<i>(Hurrah,you're qualified)</i>
                                <br />
                                &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test has been successfully completed</span>
                              </Typography>
                              :
                              item?.round_assign === true && item?.round_clear === null && item?.round_complete === true ?
                                <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                  Face to Face Interview - Completed<i> (we are verifying)</i>
                                  <br />
                                  &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test has been successfully completed</span>
                                </Typography>
                                :
                                // item?.previous_round === true && item?.round_assign === false && item?.round_clear === null && item?.round_complete === null && item?.round_id === null ?
                                //   <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                //     Face to Face Interview - We are Scheduling Interview Slot
                                //     <br />
                                //     &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test is getting Scheduled,<br /><i>Sit Back and Relax...</i></span>
                                //   </Typography>
                                //   :
                                item?.previous_round === true && item?.round_assign === false && item?.round_clear === null && item?.round_complete === null && item?.round_id === null ?
                                  positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_assign === true && positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_clear === true && positionDetails && !positionDetails[0]?.response[parseInt(processData.round - 1)]?.marks_scored ?
                                    <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                      Face to Face Interview - we are reviewing
                                      {/* &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test is ready to attempt</span> */}
                                      <ul style={{ color: "black", fontWeight: 'normal' }}>
                                        Note:<li>Please wait, until you're previous rounds are verified</li>
                                      </ul>
                                    </Typography>
                                    :
                                    <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                      Face to Face Interview - Ready To Attempt(Book Interview Slot)
                                      <br />
                                      &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test is ready to attempt</span>
                                      <ul style={{ color: "black", fontWeight: 'normal' }}>
                                        Note:<li>Please select date, time slot for scheduling interview</li>
                                      </ul>
                                    </Typography>
                                  :
                                  item?.previous_round === true && item?.round_assign === true && item?.round_clear === null && item?.round_complete === null && item?.round_id !== null ?
                                    <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                      Face to Face Interview - Ready To Attempt
                                      <br />
                                      &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>Face to Face Interview</span> test is ready to attempt</span>
                                    </Typography>
                                    :
                                    item?.round_assign === true && item?.round_clear === false ?
                                      <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                                        Face to Face Interview - Rejected
                                        <br />
                                        &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}><i style={{ color: "red", fontWeight: 'bold' }}>Oops..!</i>you're rejected in Face to Face Interview. You can't been proceed for further process</span>
                                      </Typography>
                                      :
                                      <Typography style={{ color: 'gray', fontWeight: 'bold' }}>
                                        Face to Face Interview - Pending
                                        <br />
                                        &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your not qualified for <span>Face to Face Interview</span> test yet</span>
                                      </Typography>
                            :
                            item?.round_assign === true && item?.round_clear === true && item?.marks_scored ?
                              <Typography style={{ color: 'green', fontWeight: 'bold' }}>
                                {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + " Test - Completed(Hurrah,you're qualified)")}
                                <br />
                                &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>{item?.round_type}</span> test has been successfully completed</span>
                              </Typography>
                              :
                              item?.round_assign === true && item?.round_clear === true && !item?.marks_scored ?
                                <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                  {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + ' Test - Completed (we are verifying you can move to next step)')}
                                  <br />
                                  &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>{item?.round_type}</span> test has been successfully completed</span>
                                </Typography>
                                :
                                item?.round_assign === true && item?.round_clear === null ?
                                  <Typography style={{ color: 'blue', fontWeight: 'bold' }}>
                                    {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + ' Test' + ' - Ready To Attempt')}
                                    <br />
                                    &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your <span>{item?.round_type}</span> test is ready to attempt</span>
                                  </Typography>
                                  :
                                  item?.round_assign === true && item?.round_clear === false ?
                                    <Typography style={{ color: 'red', fontWeight: 'bold' }}>
                                      {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + ' Test' + ' - Rejected')}
                                      <br />
                                      &nbsp;<i style={{ color: "black", fontWeight: 'normal' }}><i style={{ color: "red", fontWeight: 'bold' }}>Oops..!</i> you're rejected in <span>{item?.round_type}</span> test. You're not qualified for futher process.</i>
                                    </Typography>
                                    :
                                    <Typography style={{ color: 'gray', fontWeight: 'bold' }}>
                                      {(item?.round_type[0]?.toUpperCase() + item?.round_type.substring(1) + ' Test' + ' - Pending')}
                                      <br />
                                      &nbsp;<span style={{ color: "black", fontWeight: 'normal' }}>Your not qualified for <span>{item?.round_type}</span> test yet</span>
                                    </Typography>
                        }
                      </h2>
                    </StepLabel>
                    <StepContent>
                      {processData &&
                        processData.round_type === "mcq" &&
                        processData.round_clear === null &&
                        processData.round_assign === true &&
                        processData.completed === null && (
                          <>
                            <a href='/applicant_test'>
                              <Button
                                variant='contained'
                                color='primary'
                                id='mcqTestBtn'
                                onClick={() => takingTest(processData.round_id)}
                              >
                                Take MCQ Test
                              </Button>
                            </a>
                          </>
                        )}
                      {processData &&
                        processData.round_type === "spoken" &&
                        processData.round_clear === null &&
                        processData.round_assign === true &&
                        processData.completed === null && (
                          <>
                            <a href='/spoken_test'>
                              <Button
                                variant='contained'
                                color='primary'
                                id='mcqTestBtn'
                                onClick={() => takingTest(processData.round_id)}
                              >
                                Take Spoken Test
                              </Button>
                            </a>
                            <br />
                            <br />
                            <strong>Note:</strong><p><i>Please, allow microphone access while giving spoken test.</i></p>
                          </>
                        )}
                      {processData &&
                        processData.round_type === "written" &&
                        processData.round_clear === null &&
                        processData.round_assign === true &&
                        processData.completed === null && (
                          <>
                            <a href='/written_test'>
                              <Button
                                variant='contained'
                                color='primary'
                                id='mcqTestBtn'
                                onClick={() => takingTest(processData.round_id)}
                              >
                                Take Written Test
                              </Button>
                            </a>
                          </>
                        )}
                      {positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_type !== "profile" ?
                        <>
                          {processData &&
                            processData.round_type === "f2f" &&
                            processData.round_clear === null &&
                            processData.round_assign === false &&
                            processData.completed === null &&
                            processData.round_id === null &&
                            positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_assign === true && positionDetails[0]?.response[parseInt(processData.round - 1)]?.round_clear === true && positionDetails && positionDetails[0]?.response[parseInt(processData.round - 1)]?.marks_scored &&
                            (
                              <>
                                <Grid container spacing={2} className={classes.gride}>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      // className={classes.textField}
                                      helperText="Select Date"
                                      type="date"
                                      margin="dense"
                                      className="textList"
                                      fullWidth
                                      onChange={(e) => setSelectedDate(e.target.value)}
                                      required
                                      value={selectedDate || " "}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      helperText="Select Time Slot"
                                      label="F2F Time Slots 9:30 AM - 9:30 PM"
                                      // className={classes.textField}
                                      className="textList"
                                      margin="dense"
                                      // required
                                      onChange={e => setSelectedTime(e.target.value)}
                                      select
                                      value={selectedTime || ''}
                                      variant="outlined"
                                      fullWidth
                                    >
                                      {F2FTimeSlots
                                        ? (F2FTimeSlots.map(data => (
                                          <MenuItem
                                            value={data.send}
                                            key={data.id}
                                            name={data.value}
                                          >
                                            {data.value ? data.value : ''}
                                          </MenuItem>
                                        ))) : ''}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <Button
                                      variant='contained'
                                      color='primary'
                                      id='mcqTestBtn'
                                      disabled={(selectedTime === " " || selectedDate === " ") ? true : false}
                                      onClick={() => handleScheduleInterview()}
                                    >
                                      Schedule Interview Slot
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                        </>
                        :
                        <>
                          {processData &&
                            processData.round_type === "f2f" &&
                            processData.round_clear === null &&
                            processData.round_assign === false &&
                            processData.completed === null &&
                            processData.round_id === null &&
                            (
                              <>
                                <Grid container spacing={2} className={classes.gride}>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      // className={classes.textField}
                                      helperText="Select Date"
                                      type="date"
                                      margin="dense"
                                      className="textList"
                                      fullWidth
                                      onChange={(e) => setSelectedDate(e.target.value)}
                                      required
                                      value={selectedDate || " "}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <TextField
                                      helperText="Select Time Slot"
                                      label="F2F Time Slots 9:30 AM - 9:30 PM"
                                      // className={classes.textField}
                                      className="textList"
                                      margin="dense"
                                      // required
                                      onChange={e => setSelectedTime(e.target.value)}
                                      select
                                      value={selectedTime || ''}
                                      variant="outlined"
                                      fullWidth
                                    >
                                      {F2FTimeSlots
                                        ? (F2FTimeSlots.map(data => (
                                          <MenuItem
                                            value={data.send}
                                            key={data.id}
                                            name={data.value}
                                          >
                                            {data.value ? data.value : ''}
                                          </MenuItem>
                                        ))) : ''}
                                    </TextField>
                                  </Grid>
                                  <Grid item md={12} xs={12}>
                                    <Button
                                      variant='contained'
                                      color='primary'
                                      id='mcqTestBtn'
                                      disabled={(selectedTime === " " || selectedDate === " ") ? true : false}
                                      onClick={() => handleScheduleInterview()}
                                    >
                                      Schedule Interview Slot
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                        </>
                      }
                      {processData &&
                        processData.round_type === "f2f" &&
                        processData.round_clear === null &&
                        processData.round_assign === true &&
                        processData.completed === null &&
                        processData.round_id !== null && (
                          <>
                            <a href='/facetofaceround'>

                              <Button
                                variant='contained'
                                color='primary'
                                id='mcqTestBtn'
                                onClick={() => takingTest(processData.round_id)}
                              >
                                Take Face To Face Interview
                              </Button>
                            </a>
                          </>
                        )}
                    </StepContent>
                  </Step>
                )}
              </Stepper>
            </div>
            {processData &&
              processData.round_type === "mcq" &&
              processData.round_clear === false &&
              showClearedMsg(processData.round_clear)}
            {processData &&
              (processData.round_type === "mcq" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f") &&
              processData.round_clear === true &&
              processData.next_round !== "" &&
              successfullyCompleted(
                processData.round_type,
                processData.next_round
              )}

            {/* {processData &&
            processData.round_type === "written" &&
            processData.round_assign === true &&
            processData.completed === true &&
            showClearedMsg(processData.round_clear)} */}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f") &&
              processData.round_assign === true &&
              processData.completed === true &&
              showClearedMsg(processData.round_clear, processData.round_type)}


            {processData &&
              processData.next_round === "f2f" &&
              processData.completed === true && (
                <>
                  <br></br> <h2 style={{ fontWeight: 500 }}>
                    <span className={classes.textBold}>
                      Face to Face Interview {" "}
                    </span>
                    will be Assigned shortly, Please wait for HR to assign you a slot.
                  </h2>
                </>
              )}



            {processData &&
              processData.next_round === "" &&
              processData.completed === true &&
              processData.round_clear === true && (
                <>
                  <h2 style={{ fontWeight: 500 }}>
                    Congrats You have completed all the rounds successfully
                  </h2>
                  <div >
                    <Typography style={{ fontSize: '25px' }}>
                      <strong style={{ color: '#4c7bac' }}>Offer Letter Section</strong>
                      {/* {offerDetails?.results[0]} */}
                    </Typography>
                  </div>
                  <div style={{ marginTop: '50px' }}>
                    {offerDetails?.results[0] ?
                      <Grid container spacing={1}>
                        <Grid item md={2} />
                        <Grid item md={12} xs={12}>
                          <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                            <Grid container>
                              <Grid item md={9} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ color: 'black' }}><strong>To View Offer Letter :</strong> </Typography>
                              </Grid>
                              <Grid item md={3} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ color: 'black' }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleOpen()}
                                  >
                                    Click Here
                                  </Button>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      :
                      <Grid container spacing={1}>
                        <Grid item md={2} />
                        <Grid item md={12} xs={12}>
                          <Box border={1} style={{ borderRadius: '5px', padding: '8px', color: 'lightgray' }}>
                            <Grid container>
                              <Grid item md={3} xs={12}>
                                <Typography style={{ color: 'black' }}><strong>We Are Revewing your application</strong> </Typography>
                              </Grid>
                              {/* <Grid item md={9} xs={12}>
                                    <Typography style={{ color: 'black' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpen()}
                                        >
                                            Click Here
                                        </Button>
                                    </Typography>
                                </Grid> */}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    }
                  </div>
                </>
              )}
          </div>
          <div className={classes.rightBox} style={{ width: "40vh", height: "40vh", marginLeft: "15%", }}>
            {processData &&
              processData.round_type === "registration" &&
              showRegistration()}
            {processData && processData.round_type === "profile" && showProfile()}
            {/* {processData &&
            processData.round_type === "mcq" &&
            processData.round_clear === null &&
            showMcqAssign()} */}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === true &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Processing...",
                "blue",
                processData.round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.round_clear === true &&
              processData.round_assign === true &&
              processData.completed === true &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Cleared",
                "green",
                processData.next_round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.round_clear === false &&
              processData.round_assign === true &&
              processData.completed === true &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Rejected",
                "red",
                processData.next_round
              )}
            {processData &&
              processData.round_type === "written" &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === null &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Written Test",
                "black",
                processData.next_round
              )}
            {processData &&
              processData.round_type === "spoken" &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === null &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Spoken Test",
                "black",
                processData.next_round
              )}
            {processData &&
              processData.round_type === "f2f" &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === null &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "Face to Face Interview",
                "black",
                processData.next_round
              )}
            {processData &&
              processData.round_type === "mcq" &&
              processData.round_clear === null &&
              processData.round_assign === true &&
              processData.completed === null &&
              // processData.next_round !== "" &&
              roundData(
                processData,
                "MCQ Test",
                "black",
                processData.next_round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.next_round === "" &&
              processData.completed === true &&
              processData.round_clear === true &&
              !offerDetails?.results[0] &&
              roundData(
                processData,
                "Congrats All Process Completed, Offer letter will be released soon...",
                "green",
                processData.next_round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.next_round === "" &&
              processData.completed === true &&
              processData.round_clear === true &&
              offerDetails?.results[0] &&
              roundData(
                processData,
                "Offer letter released successfully",
                "green",
                processData.next_round
              )}
            {processData &&
              (processData.round_type === "written" ||
                processData.round_type === "spoken" ||
                processData.round_type === "f2f" ||
                processData.round_type === "mcq") &&
              processData.next_round === "" &&
              processData.completed === null &&
              processData.round_clear === null &&
              processData.round_assign === false &&
              processData.round_id === null &&
              roundData(
                processData,
                "We Are Reviewing",
                "blue",
                processData.next_round
              )}
          </div>
        </div>
        {open ?
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              style={{
                width: '-webkit-fill-available',
                textAlign: 'left',
                alignItems: 'left',
                justifyContent: 'center'
              }}
            // fullScreen={fullScreen}
            >
              <DialogTitle id="responsive-dialog-title" style={{ textAlign: 'center', backgroundColor: 'primary' }}>Offer Letter Preview</DialogTitle>
              <DialogContent
              >
                <DialogContentText
                  id="responsive-dialog-description"
                  tabIndex={-1}
                >
                  <div id='divToPrint'>
                    <div id=":n8">
                      <div
                        class="adn ads"
                        style={{ display: "dataMessageId=#msg-f:1728703631299798012 dataLegacyMessage-id=17fd96cb298d63fc" }}>
                        <div class="">
                          <div id=":oo" class="ii gt" jslog="20277; u014N:xr6bB; 4:W251bGwsbnVsbCxbXV0.">
                            <div id=":on" class="a3s aiL ">
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}>Dear {offerDetails?.results[0]?.candidate_name}</b>
                              ,<br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}>Congratulations!!
                              </b>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>This is with reference to your application and subsequent interviews you had with us.
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>We would like to offer you the position of
                                <b> {offerDetails?.results[0]?.designation} </b>
                                with
                                <b> {offerDetails?.results[0]?.school_name}. </b>
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>This offer<b> (subject to you joining us) on {offerDetails?.results[0]?.date_of_joining} </b> will carry an annual CTC of <b>INR {offerDetails?.results[0]?.ctc_offered}/- ({toWordsconver(offerDetails?.results[0]?.ctc_offered)}).</b></span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}> The offer break-up is provided below.</span><br />
                              <span>
                                <table style={{ borderCollapse: 'collapse', width: '303pt' }} width="404" cellspacing="0" cellpadding="0" border="0">
                                  <colgroup>
                                    <col style={{ width: '155pt' }} width="206" />
                                    <col style={{ width: '73pt' }} width="98" />
                                    <col style={{ width: '75pt' }} width="100" />
                                  </colgroup>
                                  <tbody>
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', width: '155pt', color: 'white', fontWeight: '700', verticalAlign: 'middle', borderWidth: '1pt 0.5pt 0.5pt 1pt', borderStyle: 'solid', borderColor: 'windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} width="206" height="19">Salary Details</td>
                                      <td style={{ borderLeft: 'none', width: '73pt', color: 'white', fontWeight: '700', textAlign: 'center', verticalAlign: 'middle', borderTop: '1pt solid windowtext', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif' }} width="98">Per month</td>
                                      <td style={{ borderLeft: 'none', width: '75pt', color: 'white', fontWeight: '700', textAlign: 'center', verticalAlign: 'middle', borderTop: '1pt solid windowtext', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif' }} width="100">Per Annum</td>
                                    </tr>
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }} height="19">Fixed Gross</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][0] : offerDetails?.results[0]?.salary_excel_data['Per Month'][0]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][0] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][0]} </td>
                                    </tr>
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Basic Allowance</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][1] : offerDetails?.results[0]?.salary_excel_data['Per Month'][1]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][1] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][1]} </td>
                                    </tr>
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">HRA</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][2] : offerDetails?.results[0]?.salary_excel_data['Per Month'][2]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][2] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][2]} </td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Conveyance&nbsp;</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][3] : offerDetails?.results[0]?.salary_excel_data['Per Month'][3]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][3] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][3]} </td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Medical&nbsp; Allowance&nbsp;</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][4] : offerDetails?.results[0]?.salary_excel_data['Per Month'][4]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][4] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][4]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Statutory Bonus</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][5] : offerDetails?.results[0]?.salary_excel_data['Per Month'][5]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][5] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][5]}</td>
                                    </tr >
                                    <tr style={{
                                      height: '14.4pt'
                                    }} height="19"><td style={{ height: '14.4pt', borderTop: 'none', color: 'black', fontWeight: '700', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'rgb(191,191,191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Gross Salary</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(191, 191, 191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }
                                      } >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][6] : offerDetails?.results[0]?.salary_excel_data['Per Month'][6]} </td >
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(191, 191, 191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][6] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][6]} </td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Employee contribution to PF</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][7] : offerDetails?.results[0]?.salary_excel_data['Per Month'][7]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][7] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][7]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Employee contribution&nbsp; to ESIC</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][8] : offerDetails?.results[0]?.salary_excel_data['Per Month'][8]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][8] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][8]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Profession Tax</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][9] : offerDetails?.results[0]?.salary_excel_data['Per Month'][9]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][9] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][9]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }} height="19">Retention Fund*</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][10] : offerDetails?.results[0]?.salary_excel_data['Per Month'][10]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][10] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][10]}</td>
                                    </tr >
                                    <tr style={{
                                      height: '14.4pt'
                                    }} height="19"><td style={{ height: '14.4pt', borderTop: 'none', color: 'black', fontWeight: '700', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'rgb(191,191,191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Net Salary</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(191, 191, 191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }
                                      } >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][11] : offerDetails?.results[0]?.salary_excel_data['Per Month'][11]}</td >
                                      <td style={{ borderTop: 'none', borderLeft: 'none', fontWeight: '700', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(191, 191, 191)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][11] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][11]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Employer contribution to PF</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][12] : offerDetails?.results[0]?.salary_excel_data['Per Month'][12]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][12] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][12]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="19">Employer contribution&nbsp; to ESIC</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][13] : offerDetails?.results[0]?.salary_excel_data['Per Month'][13]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][13] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][13]}</td>
                                    </tr >
                                    <tr style={{ height: '15pt' }} height="20">
                                      <td style={{ height: '15pt', borderTop: 'none', color: 'black', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="20">Gratuity</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][14] : offerDetails?.results[0]?.salary_excel_data['Per Month'][14]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][14] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][14]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }} height="19">Retention additional Incentive**</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][15] : offerDetails?.results[0]?.salary_excel_data['Per Month'][15]} </td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', background: 'rgb(252,213,180)', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][15] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][15]}</td>
                                    </tr >
                                    <tr style={{ height: '14.4pt' }} height="19">
                                      <td style={{ height: '14.4pt', borderTop: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', borderLeft: '1pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }} height="19">Medical Insurance cost***</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '0.5pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][16] : offerDetails?.results[0]?.salary_excel_data['Per Month'][16]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', borderRight: '1pt solid windowtext', borderBottom: '0.5pt solid windowtext', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', color: 'black', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][16] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][16]} </td>
                                    </tr >
                                    <tr style={{ height: '15pt' }} height="20">
                                      <td style={{ height: '15pt', borderTop: 'none', color: 'white', fontWeight: '700', verticalAlign: 'middle', borderRight: '0.5pt solid windowtext', borderBottom: '1pt solid windowtext', borderLeft: '1pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', whiteSpace: 'nowrap' }} height="20">Cost To Company&nbsp;</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', color: 'white', fontWeight: '700', borderRight: '0.5pt solid windowtext', borderBottom: '1pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_month'] ? offerDetails?.results[0]?.salary_excel_data['per_month'][17] : offerDetails?.results[0]?.salary_excel_data['Per Month'][17]}</td>
                                      <td style={{ borderTop: 'none', borderLeft: 'none', color: 'white', fontWeight: '700', borderRight: '1pt solid windowtext', borderBottom: '1pt solid windowtext', background: 'black', paddingTop: '1px', paddingRight: '1px', paddingLeft: '1px', fontSize: '11pt', fontFamily: 'Calibri,sans-serif', verticalAlign: 'bottom', whiteSpace: 'nowrap' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{offerDetails?.results[0]?.salary_excel_data['per_annum'] ? offerDetails?.results[0]?.salary_excel_data['per_annum'][17] : offerDetails?.results[0]?.salary_excel_data['Per Annum'][17]}</td>
                                    </tr >
                                  </tbody >
                                </table >
                              </span >
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>This is a full-time position and there will be a probationary period of <b> {offerDetails?.results[0]?.probation_period} </b> and the notice period will be <b> {offerDetails?.results[0]?.notice_period}</b>. Your date of joining will be on <b> {offerDetails?.results[0]?.date_of_joining}</b>. Reporting Time on the date of joining will be at <b>{offerDetails?.results[0]?.reporting_time}</b>. Please revert with a confirmation of your acceptance of the offer and date of joining by <b>{offerDetails?.results[0]?.confirm_before_date}</b>.
                              </span>
                              <br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}>You are required to submit the following documents on or before {offerDetails?.results[0]?.submit_documents_before_date}.
                              </b>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;.10th &amp; 12th Passing Certificates/ Marksheets - Originals and Photocopy
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Graduation certificate/ Mark sheets - Originals and Photocopy</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. PG certificate / Mark sheet copies - If any</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Aadhaar card</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Pan Card</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Appraisal/Promotion/increment letter of last organization - both Original and Photocopy -- If Any</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Appointment letter of the last organisation</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Relieving letter of the last organisation</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. 4 passport size colour photographs</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Cancelled Cheque/bank account details proof (Joint account not allowed)</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Last 3 Months Payslips and Bank Statement copy, if applicable</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Universal Account Number (Related to PF, if applicable)</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Employee State Insurance details from last organization (if applicable)</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Latest Resume copy</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Permanent  Address Proof
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>&nbsp;&nbsp;. Correspondence Address Proof</span>
                              <br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>(Bring all original documents for Verification and all the photocopies should be self-attested)
                              </b>

                              <br />
                              <br />
                              <br />
                              <br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>Important Points:</b>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>1.       Your net salary will be subject to income tax deductible at source. At the time of joining, we request you to submit your investment/tax deduction declaration in our employee HR portal (GreytHR) to ensure that TDS applicable is correctly calculated.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>2.       By accepting this offer/reporting for duty at the branch you confirm and agree that you are bound by the organization policies and rules prescribed in the employee handbook which will be available with the branch EA and your employee portal (GreytHR) and the policies and rules will come into effect from the day you report to the branch.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>3.       This offer to you has been made based on information furnished by you. However, if there is a discrepancy in the documents, certificates or information given by you, we reserve the right to terminate the employment with immediate effect with no liability for any compensation to be paid to you.
                              </span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>4.       You provide us with the right to conduct background checks to validate your educational and professional credentials</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>5.       Orchids The International School reserves the right to alter the salary structure components and benefits provided as per its requirement at any time, including but limited to the retention deduction, retention fund, insurance benefit, transportation benefit. However, in case such alteration is made, the gross salary of the employee will not be reduced.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>6.       All employees joining by 30th of September of a calendar year are eligible for increment and appraisal in the next calendar year of joining. Employees joining on or after 1st of October will NOT be eligible for increments and appraisal in the next appraisal cycle but will be eligible for increments in the appraisal cycle that comes after that.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>7.      In case you do not have an updated Adhaar card you will be considered as a retainer and will not be considered as a regular employee. In such a case you will work as a retainer payroll for a maximum of 2 months and if you do not provide the updated Adhaar soft copy by then, the organization will be forced to remove you from the organization and the notice period payment will be recovered from you.  Aadhaar copy should match with online adhaar records. To facilitate this, download your latest E-aadhaar copy that is not more than 1 week old and provide the same at the time of joining.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>8.       In case your work location is a school premise, it is mandatory to get the police verification done at your end within one month of joining the organization to get your salary processed. The branch EA/HR can guide you on this in case you need any help.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>9. Subject to the terms of the letter of appointment (LOA) to be signed by the employee, the employee shall be eligible for, and the Employer shall pay to the Employee, a Retention Payment; provided that the Employee remains in continuous employment with the Employer until the end of the school Academic year as defined in the policies (usually March end for this purpose but subject to change). Except as provided in the LOA Employee shall not be eligible for, and shall not be paid, the Retention Payment if Employee’s employment with the Employer is terminated for any reason on or prior to the end of the academic year, including, without limitation, a termination due to resignation by the Employee herself or termination for Cause (as defined in the LOA) by the Employer due to grave misconduct.</span>
                              <br />
                              <span style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', fontFamily: 'Arial,Helvetica,sans-serif' }}>10. Medical insurance will be provided to the employee, the employee’s spouse,and a maximum of 2 dependent children. The insurance cost is an approxima tion of the insurance cost and can vary.</span>
                              <br />
                              <br />
                              <u style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}><b>Contact person from HR team after joining: {offerDetails?.results[0]?.hr_name} (<a>{offerDetails?.results[0]?.hr_email}</a>)  ({offerDetails?.results[0]?.hr_mobile})</b> <br /><br /><b>Contact person in case there is any issue with joining formalities, induction session or any other HR-related concern after joining - <a>{offerDetails?.results[0]?.escalation_point_email}</a></b>
                              </u>
                              <br />
                              <br />
                              <b style={{ fontVariantNumeric: 'normal', fontVariantEastAsian: 'normal', fontStretch: 'normal', fontSize: 'small', lineHeight: '1.5', fontFamily: 'Arial,Helvetica,sans-serif' }}> Congratulations and wishing you a successful career at ORCHIDS The International School!!!</b>
                              <br />
                              <br />
                              <table style={{ width: '470px' }} width="470" cellspacing="0" cellpadding="0" border="0">
                                <tbody>
                                  <tr valign="top">
                                    <td style={{ width: '10px', paddingRight: '10px' }}>
                                      <b>
                                        <font face="arial, sans-serif" color="#000000">
                                          <img
                                            alt="photo"
                                            style={{ borderRadius: '0px', width: '65px', height: '49.9052px', maxWidth: '120px' }}
                                            width="65"
                                            height="49.9052132701"
                                            src="https://ci6.googleusercontent.com/proxy/CEXxPZUvWSWfVFq3V8RS6sz0dxpeVHhhLV9Fp71KsMp7gl-0XYEdkqAAL5Ioc9UovWD9e9PTXb-JnpnD8ijBAGkt0Bo0fjgcbNLpvs8apOWDgzTgu-qnFOVgKRgfxxbJLwpEed32qNQ8m1mtEHAxf-o5D0sxOjQpGpJQVr9qmQAIICm_1Qm-SwJahQj8PdtoEUWFl-UzyEBSgLRPnr7emhks=s0-d-e1-ft#https://s3.amazonaws.com/ucwebapp.wisestamp.com/faaa76e0-d0f4-480c-a06c-2bebbce88316/orchidsinternationalschoollogo.format_png.resize_200x.png"
                                          />
                                          &nbsp;
                                        </font>
                                      </b>
                                    </td>
                                    <td style={{ borderRight: '1px solid rgb(69,102,142)' }}>
                                    </td>
                                    <td style={{ fontStretch: 'normal', lineHeight: 'normal', textAlign: 'initial', padding: '0px 10px' }}>
                                      <div
                                        style={{
                                          marginBottom: '5px',
                                          marginTop: '0px'
                                        }}
                                      >
                                        <font
                                          face="arial, sans-serif"
                                          color="#000000">
                                          <b>Best regards,</b>
                                        </font>
                                      </div>
                                      <div style={{ marginBottom: '5px', marginTop: '0px' }}>
                                        <font
                                          face="arial, sans-serif"
                                          color="#000000">
                                          <b>
                                            {offerDetails?.results[0]?.submited_by}
                                          </b>
                                        </font>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </DialogContentText >
              </DialogContent >
              <DialogActions style={{ justifyContent: 'space-around' }}>
                <div
                // style={{
                //   width: '100%',
                //   justifyContent: 'end',
                //   display: 'flex',
                //   marginTop: '10px',
                // }}
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      float: 'left'
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'end',
                    display: 'flex',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    onClick={() => handleAcceptDeclineOffer('decline')}
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      float: 'left',
                      backgroundColor: '#c33434'
                    }}
                  >
                    Decline
                  </Button>
                  <Button
                    onClick={() => handleAcceptDeclineOffer('accept')}
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    style={{
                      marginTop: '10px',
                      marginLeft: '10px',
                      float: 'left'
                    }}
                  >
                    Accept
                  </Button>
                </div>
                <div
                  style={{
                    width: '90%',
                    justifyContent: 'flex-end',
                    display: 'flex',
                    marginTop: '10px',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PrintIcon />}
                    onClick={() => onButtonClick()}
                    style={{ textTransform: 'none' }}
                  >
                    Download PDF
                  </Button>
                </div>
              </DialogActions>
            </Dialog >
          </div >
          : ""}
      </MediaQuery>
      {/* {processData && processData.round_type === "mcq" && <ApplicantTest />} */}
    </div>
  );
}

export default withStyles(styles)(Process);

// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
// import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
// import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: "100%"
//   },
//   backButton: {
//     marginRight: theme.spacing(1)
//   },
//   instructions: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1)
//   }
// }));

// function getSteps() {
//   return [
//     "Select master blaster campaign settings",
//     "Create an ad group",
//     "Create an ad"
//   ];
// }

// function getStepContent(stepIndex) {
//   switch (stepIndex) {
//     case 0:
//       return "Select campaign settings...";
//     case 1:
//       return "What is an ad group anyways?";
//     case 2:
//       return "This is the bit I really care about!";
//     default:
//       return "Unknown stepIndex";
//   }
// }

// function process() {
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const classes = useStyles();
//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const [activeStep, setActiveStep] = useState(0);
//   const steps = getSteps();

//   const handleNext = () => {
//     setActiveStep(prevActiveStep => prevActiveStep + 1);
//     document.getElementById(activeStep + 1).style.display = "block";
//   };

//   const handleBack = () => {
//     setActiveStep(prevActiveStep => prevActiveStep - 1);
//     document.getElementById(activeStep).style.display = "none";
//   };

//   return (
//     <div className={classes.root}>
//       <Stepper activeStep={activeStep} alternativeLabel>
//         {steps.map((label, index) => (
//           <Step
//             key={label}
//             id={index}
//             style={{ display: index === 0 ? "block" : "none" }}
//           >
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//       <div>
//         {activeStep === steps.length ? (
//           <div>
//             <Typography className={classes.instructions}>
//               All steps completed
//             </Typography>
//           </div>
//         ) : (
//           <div>
//             <Typography className={classes.instructions}>
//               {getStepContent(activeStep)}
//             </Typography>
//             <div>
//               <Button
//                 disabled={activeStep === 0}
//                 onClick={handleBack}
//                 className={classes.backButton}
//               >
//                 Back
//               </Button>
//               <Button variant='contained' color='primary' onClick={handleNext}>
//                 {activeStep === steps.length - 1 ? "Finish" : "Next"}
//               </Button>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default process;

