import { Avatar, Button, Drawer, Empty, Row, Space, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import "./chatBox.css";
import TextArea from "antd/es/input/TextArea";
import { SendOutlined, LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import urls from "../../../../url";
function ClarityChatBox({ open, onClose, mrf }) {
  const [messages, setMessages] = useState([]);
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [desc, setDesc] = useState("");
  const [sendLoader, setSendLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchMessages({
      mrf_id: mrf,
    });
  }, []);
  let user_id = userData?.personal_info?.user_id;
  function fetchMessages(params = {}) {
    setLoading(true);
    axios
      .get(`${urls.mrfChat}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.personal_info.token}`,
        },
      })
      .then((response) => {
        setMessages(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function sendMessage() {
    setSendLoader(true);
    let formData = new FormData();
    formData.append("mrf", mrf);
    formData.append("message", desc);
    formData.append("user", userData?.personal_info?.user_id);
    formData.append("erp", userData?.academic_profile?.erp);
    axios
      .post(`${urls.mrfChat}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.personal_info.token}`,
        },
      })
      .then((response) => {
        message.success("Message sent successfully!");
        setDesc("");
        fetchMessages({
          mrf_id: mrf,
        });
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setSendLoader(false);
      });
  }
  return (
    <React.Fragment>
      <Drawer
        footer={
          <div
            align="start"
            className="th-custom-space-1"
            style={{ width: "100%", position: "relative" }}
          >
            <TextArea
              style={{ paddingRight: "40px" }}
              rows={3}
              value={desc}
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              placeholder="Type your message here..."
            />
            {sendLoader ? (
              <LoadingOutlined
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  fontSize: "20px",
                  color: "#3F96FE",
                }}
              />
            ) : (
              <SendOutlined
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  fontSize: "20px",
                  color: desc?.length > 0 ? "#3F96FE" : "#BFBFBF",
                  cursor: desc?.length > 0 ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (desc?.length > 0) sendMessage();
                }}
                type="primary"
              />
            )}
          </div>
        }
        open={open}
        onClose={() => {
          onClose();
          setDesc("");
        }}
        title={"Get Clarity"}
      >
        {loading ? (
          <Spin />
        ) : messages?.length > 0 ? (
          messages?.map((msg, ind) => {
            let prevSenderSame =
              ind > 0 && messages[ind - 1]?.user == msg?.user;
            const words = msg?.employee_name?.split(" "); // Split name into words
            const initials = words
              ?.map((word) => word.charAt(0))
              ?.join("")
              .toUpperCase(); // Get first letter of each word
            return msg?.user == user_id ? (
              <Row style={{ marginTop: prevSenderSame ? "10px" : "20px" }}>
                <div style={{ width: "100%" }}>
                  <Space
                    align="start"
                    className="th-custom-space"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row-reverse",
                    }}
                  >
                    <Avatar style={{ background: prevSenderSame && "#fff" }}>
                      {initials}
                    </Avatar>
                    <div className="th-other-sender">
                      <strong>{!prevSenderSame && "You"}</strong>
                      <p style={{ margin: "0" }}>{msg?.message}</p>
                    </div>
                  </Space>
                </div>
              </Row>
            ) : (
              <Row style={{ marginTop: prevSenderSame ? "10px" : "20px" }}>
                <div style={{ width: "100%" }}>
                  <Space
                    align="start"
                    className="th-custom-space"
                    style={{ width: "100%" }}
                  >
                    <Avatar
                      style={{
                        background: prevSenderSame ? "#fff" : "#3F96FE",
                      }}
                    >
                      {initials}
                    </Avatar>
                    <div
                      className="th-other-sender"
                      style={{ background: "#ebf1f8" }}
                    >
                      <strong style={{ color: "#3F96FE" }}>
                        {!prevSenderSame && msg?.employee_name}
                      </strong>
                      <p style={{ margin: "0" }}>{msg?.message}</p>
                    </div>
                  </Space>
                </div>
              </Row>
            );
          })
        ) : (
          <Empty description="No messages yet!" />
        )}

        {/* <Row style={{ marginTop: "10px" }}>
          <div style={{ width: "100%" }}>
            <Space
              align="start"
              className="th-custom-space"
              style={{ width: "100%" }}
            >
              <Avatar style={{ background: "#fff" }}></Avatar>
              <div
                className="th-other-sender"
                style={{ background: "#ebf1f8" }}
              >
                <p style={{ margin: "0" }}>Hello, This is a message!</p>
              </div>
            </Space>
          </div>
        </Row> */}
      </Drawer>
    </React.Fragment>
  );
}

export default ClarityChatBox;
