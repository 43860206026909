/* eslint-disable no-multi-str */
import React, { useState,useEffect } from 'react';
import tinymce from 'tinymce';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
    Typography,
    Divider,
    Grid,
    TextField,
    MenuItem,
    Paper,
    Button,
    withStyles,
    IconButton,
    Select,
  } from '@material-ui/core';
import PropTypes from 'prop-types';
import DeleteIcon from "@material-ui/icons/Delete";
import styles from './addQuestion.style';
import urls from '../../../url'
import { useAlert } from '../../../hoc/alert/alert'
import Loader from '../../../hoc/loader'
import useFetch from '../../../hoc/useFetch';
import './pluGins';

function AddQuestion ({classes}) {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [grade, setGrade] = useState('');
  const [subject,setSubject] = useState('');
  const [value, setValue] = React.useState('');
  const [chapter, setChapters] = useState(null);
  const [editorsValue, setEditorsValue] = useState({question: '',option1: '',option2: '',option3: '',option4: ''})
  const [editor, setEditor] = useState(null);
  const [tinyInit, setTinyInit] = useState('uioi');
  const [bulkFile, setBulkFile] = useState('');
  const [positionType, setPositionType] = useState("");


  const alert = useAlert();

  const {
    data: gradeData,
    isLoading: gradeLoading,
    doFetch: fetchGrade,
  } = useFetch([], { suppressAlert: true });
    const {
    data: subjectData,
    isLoading: subjectLoading,
    doFetch: fetchSubject,
  } = useFetch([], { suppressAlert: true });
  const {
    data : addQuestionRes,
    isLoading: addQuesLoading,
    doFetch : addQuestion,
  } = useFetch([], { suppressAlert: true });
   const {
     data :chapterData,
     doFetch:fetchChapter
   } = useFetch([], { suppressAlert: true });

   useEffect(() => {
    if (addQuestionRes) {
      setEditorsValue({question: '',option1: '',option2: '',option3: '',option4: ''})
      setValue('')
      setGrade('')
      setSubject('')
      setChapters('')
      setBulkFile('')
      tinymce.editors.map(ed => {
        ed.setContent('')
      })
      setTinyInit(addQuestionRes)
    }
  }, [addQuestionRes])

   useEffect(() => {
    if (tinyInit) {
      tinymce.init({
        selector: '.dgf',
        skin_url: `${process.env.PUBLIC_URL}/skins/lightgray`,
        plugins: 'image insertdatetime media table paste code wordcount searchreplace visualblocks code fullscreen advlist autolink lists charmap print preview anchor',
        toolbar: 'undo redo | formatselect | bold italic backcolor | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
        image_title: true,
        setup: editor => {
          // setEditor(editor)
          editor.on('keyup change', () => {
            const content = editor.getContent()
            setEditorsValue((vl) => {
              vl[tinymce.activeEditor.id] = content
              return vl
            })
          })
        },
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.onchange = function () {
            var file = this.files[0];
            var reader = new FileReader();
            reader.onload = function () {
              var id = 'blobid' + (new Date()).getTime();
              var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(',')[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              cb(blobInfo.blobUri(), { title: file.name });
            };            
            reader.readAsDataURL(file);
          };
          input.click();
        }
      });
      return () => {
        tinymce.remove(editor);
      }
    }
  }, [editor, tinyInit])

  useEffect(()=>{
    if(grade){
    fetchSubject({
      url: `${urls.subjectMapping}?grade_id=${grade}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      }
    });
  }
  },[auth.personal_info.token, fetchSubject, grade])

     
  useEffect(()=>{
    if(subject){
      fetchChapter({
        url: `${urls.subjectMapping}?grade_id=${grade}&subject_id=${subject}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  },[auth.personal_info.token,subject])

  useEffect(() => {
    if (positionType) {
      fetchGrade({
        url: `${urls.newGrade}?position_type=${positionType}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [fetchGrade,auth,positionType]);
 
  const handleOptionValue = event => {
  setValue(event.target.value)
  };
  const addQuestionFunction =()=> {
    const option ={
      'option1': editorsValue.option1,
      'option2': editorsValue.option2,
      'option3': editorsValue.option3,
      'option4': editorsValue.option4,
    }
    let val
    if(value){ 
      val = editorsValue[value]
    }
    const correctAns = {
      [value] : val
    }
    const addQuesData = {
      'grade' :  grade,
      'subject' :  subject,
      'chapter' : chapter ? chapter:'',
      'question' : editorsValue.question,
      'correct_ans' : JSON.stringify(correctAns),
      option:JSON.stringify(option)
    }
    if(!grade){
      alert.warning('Select Grade')
      return;
    }else if(!subject){
      alert.warning('Select Subject')
      return;
    }else if(!editorsValue.question){
      alert.warning('Enter Question')
      return;
    }else if(!editorsValue.option1){
      alert.warning('Enter option1')
      return;
    }else if(!editorsValue.option2){
      alert.warning('Enter option2')
      return;
    }else if(!editorsValue.option3){
      alert.warning('Enter option3')
      return;
    }else if(!editorsValue.option4){
      alert.warning('Enter option4')
      return;
    }else if(!value){
      alert.warning('Choose Correct Answer')
      return;
    }
    addQuestion({
      url: urls.addQuestions,
      method: 'POST',
      body: addQuesData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
       'Content-Type':'application/json'
      },
    })
  }

  let loader = null;
  if (gradeLoading || addQuesLoading || subjectLoading) {
    loader = <Loader open />;
  }

  const handleBulkQuestions = (value) => {
    setBulkFile(value);
  }

  const clearBulkFunction = () => {
    setBulkFile('');
  }
const handleBulkUpload = () => {
  const formData = new FormData();
  formData.append("grade_id", grade);
  formData.append("subject_id", subject);
  formData.append("chapter", chapter ? chapter:'');
  formData.append("file", bulkFile);

  
  if(!grade){
    alert.warning('Select Grade')
    return;
  }else if(!subject){
    alert.warning('Select Subject')
    return;
  }
  addQuestion({
    url: urls.bulkUploadQuestions,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${auth.personal_info.token}`,
    },
    body: formData,
    
  })
}

const positionTypeList = [
  {
    value: "1",
    label: "Academic",
  },
  {
    value: "2",
    label: "Non Academic",
  },
  {
    value: "3",
    label: "IT Recruitment",
  },
  {
    value: "4",
    label: "Marketing and Communications",
  },
  {
    value: "5",
    label: "Sales and Business Development",
  },
];
  

  return(
    <React.Fragment>
      <Typography variant='h4' className="headings"><strong>Create Question</strong></Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper} className="role" style={{padding:"10px"}}>
        <Grid container spacing={2}>
        <Grid item md={3} xs={12} >
            {/* <Typography>
              {" "}
              Select Position Type <b style={{ color: "red" }}>*</b>
            </Typography> */}
            <TextField
              select
              required
              fullWidth
              label="Position Type"
              margin="dense"
              value={positionType || ""}
              onChange={(e, id) => {
                setPositionType(e.target.value);
              }}
              variant="outlined"
            >
              {positionTypeList.map((option, index) => (
                <MenuItem key={index} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label={positionType=="Academic" ?"Grade Name":"Level Name"}
              className={classes.textField}
              margin="dense"
              // required
              onChange={e => setGrade(e.target.value)}
              select
              value={grade || ''}
              variant="outlined"
            >
              {/* {gradeData.results.map(data => (
                  <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.grade_name}
                  >
                      {data.grade_name ? data.grade_name : ''}
                  </MenuItem>
                  ))} */}
                   { gradeData && gradeData.map((option) => (
                <MenuItem key={option.id} value={option.id} name={option.grade_name}>
                  {option.grade_name}
                </MenuItem>
              ))}
              </TextField>
              </Grid>
              <Grid item md={4} xs={12}>
              {grade && <TextField
                  label={positionType=="Academic" ?"Subject":"Topic"}
                  className={classes.textField}
                  margin="dense"
                  required
                  onChange={e => setSubject(e.target.value)}
                  select
                  value={subject || ''}
                  variant="outlined"
              >
                  {subjectData && subjectData && subjectData.map(data => (
                  <MenuItem
                      value={data.subject.id}
                      key={data.subject.id}
                      name={data.subject.subject_name}
                  >
                      {data.subject.subject_name ? data.subject.subject_name : ''}
                  </MenuItem>
                  ))}
              </TextField>}
              </Grid>
              <Grid item md={4} xs={12}>
                {subject && chapterData && chapterData.length !==0 && <TextField
                  label="Chapter"
                  className={classes.textField}
                  margin="dense"
                  required
                  onChange={e => setChapters(e.target.value)}
                  select
                  value={chapter || ''}
                  variant="outlined"
              >
                  {chapterData
                  ? (chapterData.map(data => (
                  <MenuItem
                      value={data.chapter.id}
                      key={data.chapter.id}
                      name={data.chapter.chapter_name}
                  >
                      {data.chapter.chapter_name ? data.chapter.chapter_name : ''}
                  </MenuItem>
                  )))
                : (<MenuItem
                    value='0'
                    key='0'
                    name='None'
                  >
                    None
                  </MenuItem>)
                }
          </TextField>}
          </Grid>
          {grade && subject ?
          <Grid item xs={12} md={4}>
          <Typography className={classes.label} variant="h6" className="textDisplay" noWrap>
            Bulk Upload Questions (format: .csv)
          </Typography>
          <input
            style={{display: 'none'}}
            type="file"
            id="excel-file"
            accept=".csv"
            onChange={(e) => handleBulkQuestions(e.target.files[0])}
          />
          <label htmlFor="excel-file">
            <Button
              variant="contained"
              component="span"
              size="small"
              // fullWidth
              color="primary"
              startIcon={<CloudUploadIcon />}
              id="bulkUpload"
            >
              Bulk Upload
            </Button>
            &nbsp;&nbsp;
            {bulkFile.name}
          </label>
          {grade && subject && bulkFile && (
            <IconButton size="small" onClick={() => clearBulkFunction()}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
        :""}
        {grade && subject && bulkFile?
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              component="span"
              // size="small"
              // fullWidth
              color="primary"
              id="bulkUpload"
              onClick={handleBulkUpload}
            >
              Submit
            </Button>
            &nbsp;&nbsp;
        </Grid>
        :""}
          </Grid>
          <Divider className={classes.divider} />
          {(<>
          <Typography variant="h6">Question :</Typography>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <textarea
                  id='question'
                  className='dgf'
                  style={{ height: 250 }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
              <Typography variant="h6">Option 1 :</Typography>
               <Grid container>
                    <Grid item md={9} xs={12}>
                      <textarea
                        id='option1'
                        className='dgf'
                        style={{ height: 250 }}
                      />
                    </Grid>
                    <Grid item md={3} xs ={12}>
                      <Grid className={classes.checkBox}>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleOptionValue}>
                            <FormControlLabel value="option1" control={<Radio />} label="is correct " />
                        </RadioGroup>
                      </FormControl>
                      </Grid>
                    </Grid>
               </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
              <Typography variant="h6">Option 2 :</Typography>
               <Grid container>
                    <Grid item md={9} xs={12}>
                      <textarea
                        id='option2'
                        className='dgf'
                        style={{ height: 250 }}
                      />
                    </Grid>
                    <Grid item md={3} xs ={12}>
                    <Grid className={classes.checkBox}>
                      <FormControl component="fieldset" className={classes.formControl}>
                          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleOptionValue}>
                              <FormControlLabel value="option2" control={<Radio />} label="is correct " />
                          </RadioGroup>
                        </FormControl>
                      </Grid>  
                  </Grid>
               </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
              <Typography variant="h6">Option 3 :</Typography>
               <Grid container>
                    <Grid item md={9} xs={12}>
                      <textarea
                        id='option3'
                        className='dgf'
                        style={{ height: 250 }}
                      />
                    </Grid>
                    <Grid item md={3} xs ={12}>
                    <Grid className={classes.checkBox}>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleOptionValue}>
                            <FormControlLabel value="option3" control={<Radio />} label="is correct " />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    </Grid>
               </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
              <Typography variant="h6">Option 4 :</Typography>
               <Grid container>
                    <Grid item md={9} xs={12}>
                      <textarea
                        id='option4'
                        className='dgf'
                        style={{ height: 250 }}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                    <Grid className={classes.checkBox}>
                      <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleOptionValue}>
                            <FormControlLabel value="option4" control={<Radio />} label="is correct " />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    </Grid>
               </Grid>
              </Grid>
              <Grid item md={2} xs={12} className={classes.applyButtonSection}>
                <Button
                  className={classes.applyButton}
                  color="primary"
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={() => addQuestionFunction()}
                >
                  create
                </Button>
              </Grid>
           </Grid>
           </>)}   
         </Paper>
         {loader}
      </React.Fragment>          
  );
}
AddQuestion.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
  };
export default withStyles(styles)( AddQuestion);