export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: '5px 40px',
      marginTop: '15px',
    },
    paper: {
      position: 'absolute',
      width: '60%',
      marginLeft: '15%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: true,
    },
    paperMain: {
    },
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    fixed : {
      height : '245px',
      overflowY : 'scroll',
    },
    loginButton: {
      margin: '20px 0px',
      display: 'grid',
    },
    Button: {
      borderRadius: '0px',
    },
    button: {
        margin: theme.spacing(1),
      },
      input: {
        display: 'none',
      },
});
