export default theme => ({
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      addSubmitButton: {
        display: 'flex',
        justifyContent: 'center',
      },
      buttonfinal: {
        padding: '15px',
      },
})