import { CalendarToday, Settings } from "@material-ui/icons";
import TodayIcon from "@material-ui/icons/Today";
import {
  Dashboard as DashboardIcon,
  Edit as EditIcon,
  NoteAdd as NoteAddIcon,
  FileCopy as FileCopyIcon,
  CloudUpload as CloudUploadIcon,
} from "@material-ui/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ListIcon from "@material-ui/icons/List";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import SwitchVideoIcon from "@material-ui/icons/SwitchVideo";
import RepeatIcon from "@material-ui/icons/Repeat";
import ForwardIcon from "@material-ui/icons/Forward";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import ListAltIcon from "@material-ui/icons/ListAlt";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ContactsIcon from "@material-ui/icons/Contacts";
import AssignmentIcon from "@material-ui/icons/Assignment";
import {
  SlackOutlined,
  StarFilled,
  MenuUnfoldOutlined,
  ReadFilled,
  SnippetsFilled,
  IdcardFilled,
  MoneyCollectOutlined,
} from "@ant-design/icons";

import {
  Add as AddIcon,
  PermIdentity as UserIcon,
  // Menu as MenuIcon,
  // Close as CloseIcon,
} from "@material-ui/icons";
import TeacherReferralRepo from "../components/teacherReferralRepo/teacherReferralRepo";
const menu = [
  {
    label: "OverView",
    icon: <AssignmentIcon />,
    link: "/applicants-overview",
    roles: [1, 3, 4],
  },
  // DASHBOARDS
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/admin-dashboard",
    roles: [1],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/recruiter-dashboard",
    roles: [3],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/hrhead-dashboard",
    roles: [4],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/superadmin-dashboard",
    roles: [10],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/principal-dashboard",
    roles: [8],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/invigilator-dashboard",
    roles: [20],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/ea-dashboard",
    roles: [19],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/reviewer-dashboard",
    roles: [11],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/interviewer-dashboard",
    roles: [7],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/subjecthead-dashboard",
    roles: [6],
  },
  {
    label: "Dashboard",
    icon: <TodayIcon />,
    link: "/mrf-approver-dashboard",
    roles: [13, 21],
  },
  {
    label: "Team Performance",
    icon: <TodayIcon />,
    link: "/team-performance",
    roles: [1],
  },
  {
    label: "Calendar",
    icon: <TodayIcon />,
    link: "/calendar",
    roles: [7],
  },
  {
    label: "Config",
    icon: <Settings />,
    roles: [1, 3, 4],
    children: [
      {
        label: "Position List",
        icon: <ListIcon />,
        link: "/update_positions",
        roles: [1, 3, 4],
      },
      {
        label: "Position Types",
        icon: <ListIcon />,
        link: "/position_types",
        roles: [1],
      },
      {
        label: "Designation details",
        icon: <PlaylistAddIcon />,
        link: "/designation_details",
        roles: [1],
      },
      {
        label: "Academic Year",
        icon: <PlaylistAddIcon />,
        link: "/academic_year",
        roles: [1],
      },
      {
        label: "City",
        icon: <PlaylistAddIcon />,
        link: "/city",
        roles: [1],
      },
      {
        label: "Branch",
        icon: <PlaylistAddIcon />,
        link: "/branch",
        roles: [1],
      },
      {
        label: "State",
        icon: <PlaylistAddIcon />,
        link: "/state",
        roles: [1],
      },
      {
        label: "State City Details",
        icon: <PlaylistAddIcon />,
        link: "/state_city_details",
        roles: [1],
      },
      {
        label: "Branch details",
        icon: <PlaylistAddIcon />,
        link: "/branch_details",
        roles: [1],
      },

      // {
      //   label: "Role details",
      //   icon: <PlaylistAddIcon />,
      //   link: "/role_details",
      //   roles: [1],
      // },
      {
        label: "Grade/Level",
        icon: <PlaylistAddIcon />,
        link: "/grade",
        roles: [1],
      },
      // {
      //   label: "Interview Panel",
      //   icon: <PlaylistAddIcon />,
      //   link: "/interview_panel",
      //   roles: [1],
      // },
      {
        label: "Employee List",
        icon: <ListIcon />,
        link: "/employee_List",
        roles: [1],
      },
      // {
      //   label: "Create Position",
      //   icon: <AddIcon />,
      //   link: "/create_positions",
      //   roles: [1, 3, 4],
      // },
      // {
      //   label: "Create Role",
      //   icon: <AddIcon />,
      //   link: "/add_role",
      //   roles: [1],
      // },
      // {
      //   label: "Create Employee",
      //   icon: <AddIcon />,
      //   link: "/create_Employee",
      //   roles: [1],
      // },
      {
        label: "User List",
        icon: <UserIcon />,
        link: "/user_Table",
        roles: [10],
      },
    ],
  },
  {
    label: "Test Config",
    icon: <Settings />,
    roles: [1, 6],
    children: [
      {
        label: "Subjects/Topics",
        icon: <PlaylistAddIcon />,
        link: "/viewSubjects",
        roles: [1],
      },
      {
        label: "Chapters",
        icon: <PlaylistAddIcon />,
        link: "/View_Chapters",
        roles: [1],
      },
      {
        label: "Create Question",
        icon: <PlaylistAddIcon />,
        link: "/add_Question",
        roles: [1, 6],
      },
      {
        label: "Questions",
        icon: <PlaylistAddIcon />,
        link: "/View_Questions",
        roles: [1, 6],
      },
      // {
      //   label: "F2F Questions",
      //   icon: <PlaylistAddIcon />,
      //   link: "/view_F2F_questions",
      //   roles: [1],
      // },
      // {
      //   label: "F2F Parameters",
      //   icon: <PlaylistAddIcon />,
      //   link: "/View_F2F_parameter",
      //   roles: [1],
      // },
      {
        label: "Written Topics",
        icon: <PlaylistAddIcon />,
        link: "/create_topic",
        roles: [1],
      },
      {
        label: "Spoken Topics",
        icon: <PlaylistAddIcon />,
        link: "/spoken_topic",
        roles: [1],
      },
      // {
      //   label: "MCQ Test Papers",
      //   icon: <PlaylistAddIcon />,
      //   link: "/question_paper",
      //   roles: [1],
      // },
      {
        label: "Instructions",
        icon: <PlaylistAddIcon />,
        link: "/View_Instructions",
        roles: [1],
      },
    ],
  },
  {
    label: "MRF Application",
    icon: <MenuUnfoldOutlined />,
    roles: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
    ],
    children: [
      {
        label: "MRF Dashboard",
        icon: <TodayIcon />,
        link: "/mrf_dashboard",
        roles: [1, 3, 4, 21],
      },
      {
        label: "MRF List",
        icon: <AddIcon />,
        link: "/mrf_list",
        roles: [
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
        ],
      },
      // {
      //   label: "Other Job Posts",
      //   icon: <AddIcon />,
      //   link: "/other-job-posts",
      //   roles: [1, 3],
      // },
      // {
      //   label: "Applicant History",
      //   icon: <PlaylistAddIcon />,
      //   link: "/applicant_history",
      //   roles: [1],
      // },
      // {
      //   label: "LinkedIn Scraper",
      //   icon: <PlaylistAddIcon />,
      //   link: "/linkedin_scraper",
      //   roles: [1],
      // },
      // {
      //   label: "Other Applicants",
      //   icon: <ContactsIcon />,
      //   link: "/other_applicants",
      //   roles: [1],
      // },
      // {
      //   label: "Bulk Candidate Upload",
      //   icon: <PlaylistAddIcon />,
      //   link: "/bulk_candidate",
      //   roles: [1],
      // },
    ],
  },

  {
    label: "Offer Letter",
    icon: <FileCopyIcon />,
    roles: [1, 3, 4, 13, 21],
    children: [
      {
        label: "Send Offer",
        icon: <MailOutlineIcon />,
        link: "/offer_letters",
        roles: [1, 3, 4],
      },
      {
        label: "Offer Approval List",
        icon: <TodayIcon />,
        link: "/offer_letter_list_mrf",
        roles: [1, 4, 13, 21],
      },
      {
        label: "Released Offer Letter",
        icon: <ContactMailIcon />,
        link: "/offer_letters_List",
        roles: [1, 3],
      },
    ],
  },
  {
    label: "Reports",
    icon: <FileCopyIcon />,
    roles: [1, 3, 4, 6, 7, 8, 10, 11, 19],
    children: [
      {
        label: "Users Report",
        icon: <FileCopyIcon />,
        link: "/report",
        roles: [1, 3, 19],
      },
      {
        label: "Offer Report",
        icon: <FileCopyIcon />,
        link: "/offer_report",
        roles: [1, 3, 4],
      },
      {
        label: "Position Report",
        icon: <FileCopyIcon />,
        link: "/position_report",
        roles: [1, 3],
      },
      {
        label: "Resume Repository",
        icon: <FileCopyIcon />,
        link: "/resume_repository",
        roles: [1, 3, 4, 6, 7, 8, 10, 11],
      },
    ],
  },
  {
    label: "Application",
    icon: <Settings />,
    roles: [1, 3, 4, 6, 7, 8, 10, 11, 19, 20],
    children: [
      // {
      //   label: "Transfer Requests",
      //   icon: <DashboardIcon />,
      //   link: "/transfer_requests",
      //   roles: [8],
      // },
      {
        label: "Search Applicant",
        icon: <FindInPageIcon />,
        link: "/search_applicant_profile",
        roles: [3, 19],
      },
      // {
      //   label: "Applicant Profile",
      //   icon: <UserIcon />,
      //   link: "/applicant_profile",
      //   roles: [3],
      // },
      {
        label: "Assign To Recruiter",
        icon: <ForwardIcon />,
        link: "/assignToRecruiter",
        roles: [4],
      },
      // {
      //   label: "ReAssign Recruiter",
      //   icon: <ForwardIcon />,
      //   link: "/assignToOtherRecruiter",
      //   roles: [3, 4, 19],
      // },
      {
        label: "Score Upload",
        icon: <CloudUploadIcon />,
        link: "/Score_upload",
        roles: [1, 3, 19, 20],
      },
      {
        label: "Face To Face",
        icon: <SwitchVideoIcon />,
        link: "/Face_To_Face_All",
        roles: [1, 3, 19],
      },

      {
        label: "Re Schedule Face To Face",
        icon: <RepeatIcon />,
        link: "/Face_To_Face_Reassign",
        roles: [3, 19],
      },
      // {
      //   label: "OcfpMcq Applicant",
      //   icon: <FileCopyIcon />,
      //   link: "/Ocfp_MCQ",
      //   roles: [3],
      // },
      // {
      //   label: "Ocfp Assign",
      //   icon: <FileCopyIcon />,
      //   link: "/Ocfp_Assign",
      //   roles: [4],
      // },
      // {
      //   label: "Ocfp ReAssign",
      //   icon: <FileCopyIcon />,
      //   link: "/Ocfp_ReAssign",
      //   roles: [4],
      // },

      {
        label: "Campus Drive",
        icon: <FileCopyIcon />,
        link: "/campusDrive",
        roles: [4],
      },
      {
        label: "Applicant Review",
        icon: <UserIcon />,
        link: "/applicant_review",
        roles: [11],
      },
      // {
      //   label: "Applicant History",
      //   icon: <PlaylistAddIcon />,
      //   link: "/applicant_history",
      //   roles: [3, 4, 6, 7, 8, 10, 11, 19, 20],
      // },
      // {
      //   label: "Other Applicants",
      //   icon: <ContactsIcon />,
      //   link: "/other_applicants",
      //   roles: [3, 4, 19],
      // },
      // {
      //   label: "Previous Candidate",
      //   icon: <UserIcon />,
      //   link: "/previous_candidate",
      //   roles: [3],
      // },
    ],
  },
  {
    label: "Interviews",
    icon: <ReadFilled />,
    roles: [3, 4, 7, 8, 19],
    children: [
      {
        label: "Interview Detail",
        icon: <PlaylistAddIcon />,
        link: "/interview_details",
        roles: [3],
      },
      {
        label: "Todays Interviews",
        icon: <TodayIcon />,
        link: "/todays_interview",
        roles: [3, 4, 7, 8, 19],
      },
      {
        label: "All Interviews",
        icon: <TodayIcon />,
        link: "/all-interviews",
        roles: [3, 4, 7, 8, 19],
      },
      // {
      //   label: "Upcoming Interviews",
      //   icon: <WatchLaterIcon />,
      //   link: "/upComming_interview",
      //   roles: [3, 4, 7, 8, 19],
      // },
      // {
      //   label: "Pending Interviews",
      //   icon: <WatchLaterIcon />,
      //   link: "/pending_interview",
      //   roles: [3, 4, 7, 8],
      // },
      // {
      //   label: "Completed Interviews",
      //   icon: <FileCopyIcon />,
      //   link: "/interviews_completed",
      //   roles: [3, 4, 7, 8],
      // },
      // {
      //   label: "OCFC Interview",
      //   icon: <FileCopyIcon />,
      //   link: "/ocfcHrInterview",
      //   roles: [4, 7],
      // },
    ],
  },
  {
    label: "Employee Rewards",
    icon: <StarFilled />,
    link: "/employee_rewards",
    roles: [1, 3],
  },
  {
    label: "Teacher Referral Repository",
    icon: <IdcardFilled />,
    link: "/teacher_referral_repository",
    roles: [1, 3],
  },
];

export default menu;
