import {
  Button,
  Card,
  Flex,
  Row,
  Col,
  Select,
  Table,
  Typography,
  message,
  Switch,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Space,
  Tag,
} from "antd";
import React, { useDebugValue, useEffect, useState } from "react";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import urls from "../../../url";
import axios from "axios";
import { useForm } from "antd/es/form/Form";

const PositionType = () => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [tableData, setTableData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [addPositionForm] = useForm();
  const [filterForm] = useForm();
  const [open, setOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateId, setUpdateId] = useState("");

  const showDrawer = () => {
    setOpen(true);
    listEmpDetails({ is_budget_approver: true });
  };

  const onClose = () => {
    setOpen(false);
    setIsUpdate(false);
    addPositionForm.resetFields();
  };

  useEffect(() => {
    fetchPositionTypesList();
    fetchRoleData();
  }, []);

  const handleSwitchChange = (record) => {
    let status = !record?.is_deleted;
    let id = record?.id;
    updatePositionStatus(id, status);
  };

  const updatePositionStatus = (id, status) => {
    axios
      .put(
        `${urls.positionTypes}${id}/`,
        { is_deleted: status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${name.personal_info.token}`,
          },
        }
      )
      .then((response) => {
        fetchPositionTypesList();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchPositionTypesList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setTableData(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchRoleData = (params = {}) => {
    axios
      .get(`${urls.createEmpRole}`, { params: params })
      .then((response) => {
        if (response?.status === 200) {
          setRoleList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      });
  };

  const listEmpDetails = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.retrieveEmployeeList}`, { params: params })
      .then((response) => {
        if (response?.status === 200) {
          setEmployeeList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const checkDuplicate = () => {
    const { position_type } = addPositionForm.getFieldsValue();

    const lowercaseString = position_type.toLowerCase();
    const isDuplicate = tableData.filter(
      (each) => each?.position_type?.toLowerCase() == lowercaseString
    );

    if (isDuplicate?.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddPosition = () => {
    if (isUpdate) {
      updateExistingPosition();
    } else {
      addNewPosition();
    }
  };

  const updateExistingPosition = () => {
    const { position_type, budget_approver } = addPositionForm.getFieldsValue();
    axios
      .put(`${urls.positionTypes}${updateId}/`, {
        position_type: position_type,
        budget_approver: budget_approver,
      })
      .then((response) => {
        fetchPositionTypesList();
        message.success("Data updated successfully");
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        onClose();
        setIsUpdate(false);
        setUpdateId("");
      });
  };

  const addNewPosition = () => {
    let res = checkDuplicate();

    if (!res) {
      const {
        position_type,
        budget_approver,
      } = addPositionForm.getFieldsValue();

      if (position_type) {
        axios
          .post(
            `${urls.positionTypes}`,
            { position_type, budget_approver },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${name.personal_info.token}`,
              },
            }
          )
          .then((response) => {
            message.success("Position Created Successfully");
            fetchPositionTypesList();
            onClose();
          })
          .catch((error) => {
            message.error(
              error?.response?.data?.message ?? "Something went wrong!"
            );
          });
      }
    } else {
      message.error("Position Type already exists.");
      onClose();
    }
  };

  const handleUpdate = (data) => {
    console.log("id", data);
    setIsUpdate(true);
    setUpdateId(data?.id);
    addPositionForm.setFieldsValue({
      position_type: data?.position_type,
      budget_approver: data?.budget_approver ?? "",
    });
    showDrawer();
  };

  const handleConfirm = (record) => {
    handleSwitchChange(record);
    confirm();
  };

  const confirm = () => {
    message.success("Position Deleted Successfully");
  };

  const cancel = () => {
    message.error("Cancel");
  };

  const handleSearch = () => {
    const { search } = filterForm.getFieldsValue();
    if (search) {
      fetchPositionTypesList({ search: search, page: page });
    } else {
      fetchPositionTypesList();
    }
  };

  const employeeOptions = employeeList.map((each) => {
    return (
      <Select.Option key={each?.id} value={each?.user}>
        {each?.name} - [{each?.erp}]
      </Select.Option>
    );
  });

  const columns = [
    {
      title: <strong>S.No.</strong>,
      key: "id",
      dataIndex: "id",
      align: "center",
      render: (data, reports, index) => <span>{index + 1}</span>,
    },
    {
      title: <strong>Position Name</strong>,
      key: "question",
      dataIndex: "question",
      render: (data, record) => (
        <span>{record?.position_type ? record?.position_type : "-"}</span>
      ),
    },
    {
      title: <strong>Budget Approver Name</strong>,
      key: "buget_approver",
      dataIndex: "buget_approver",
      align: "center",
      render: (data, record) => (
        <span>{record?.name ? record?.name : "-"}</span>
      ),
    },
    {
      title: <strong>Budget Approver ERP</strong>,
      key: "erp",
      dataIndex: "erp",
      align: "center",
      render: (data, record) => <span>{record?.erp ? record?.erp : "-"}</span>,
    },
    {
      title: <strong>Action</strong>,
      key: "action",
      dataIndex: "action",
      align: "center",
      render: (data, record) => (
        <Tag
          icon={<EditOutlined />}
          color="processing"
          style={{ cursor: "pointer" }}
          onClick={() => handleUpdate(record)}
        >
          Update
        </Tag>
      ),
    },
    {
      title: <strong>Status</strong>,
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (data, record) => (
        <Popconfirm
          title="Delete the position type"
          description="Are you sure to delete this position?"
          onConfirm={() => handleConfirm(record)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Tag
            icon={<DeleteOutlined />}
            color="error"
            style={{ cursor: "pointer" }}
          >
            Delete
          </Tag>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Space
        style={{
          justifyContent: "space-between",
          width: "100%",
          margin: "10px",
        }}
      >
        <div className="page_title">
          <strong> Position Types</strong>
        </div>
        <Space>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={showDrawer}
            type={"primary"}
          >
            Add Position Type
          </Button>
        </Space>
      </Space>
      <Card>
        <Form id="filterForm" form={filterForm} layout="vertical">
          <Row gutter={24}>
            <Col xs={6}>
              <Form.Item name="search">
                <Input placeholder="Search Position Type" />
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Button type={"primary"} onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Table
        dataSource={tableData}
        loading={loading}
        columns={columns}
        count={tableData?.length}
        pagination={{
          total: tableData?.length,
          current: page,
          onChange: (page) => {
            setPage(page);
          },
        }}
      />

      <Drawer
        title={isUpdate ? "Update Position" : "Add New Position"}
        width={"400px"}
        onClose={() => {
          onClose();
        }}
        open={open}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => {
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button form="addPositionForm" type="primary" htmlType="submit">
              {isUpdate ? "Update" : "Add"}
            </Button>
          </div>
        }
      >
        <Form
          id="addPositionForm"
          form={addPositionForm}
          layout="vertical"
          onFinish={handleAddPosition}
        >
          <Row>
            <Col xs={24}>
              <Form.Item
                name="position_type"
                label="Position Name"
                rules={[
                  { required: true, message: "Please enter Position Name" },
                ]}
              >
                <Input placeholder="Add Position Name" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="budget_approver"
                label="Budget Approver"
                rules={[
                  { required: true, message: "Please enter budget approver" },
                ]}
              >
                <Select allowClear placeholder="Select Budget Approver">
                  {employeeOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default PositionType;
