import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Form,
  Input,
  Col,
  Row,
  Pagination,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Typography as AntdTypography,
  Card,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  Modal,
  TablePagination,
  InputBase,
  TableContainer,
  Select,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./grade.style";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import SearchIcon from "@material-ui/icons/Search";
import MediaQuery from "react-responsive";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";

function Grade({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [role, setRole] = useState("");
  const [roled, setRoled] = useState("");
  const [inerviewPanel, setInerviewPanel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [applicentName, setApplicentName] = useState("");
  const [bulkFile, setBulkFile] = useState("");
  const [accordianOpen, setAccordianOpen] = useState(false);
  const [accordianOpenFilter, setAccordianOpenFilter] = useState(false);
  const [cityName, setCityName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [checkedLoc, setCheckedLoc] = useState([]);
  const [checkedLoca, setCheckedLoca] = useState("");
  const [positionType, setPositionType] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [visible, setVisible] = useState(false);
  const [isActive, setIsActive] = useState(null);

  const [branch, setBranch] = useState("");
  const [stateCode, setStateCode] = useState("");

  const {
    data: addInterviewPanelResponse,
    isLoading: addInterviewPanelResponseLoading,
    doFetch: addInterview,
  } = useFetch([], { suppressAlert: true });
  const {
    data: tableData,
    isLoading: tableDataLoading,
    doFetch: fetchTableData,
  } = useFetch([], { suppressAlert: true });
  const {
    data: tableDatad,
    isLoading: tableDataLoadings,
    doFetch: fetchTableDatad,
  } = useFetch([], { suppressAlert: true });

  const {
    data: updatainteriewData,
    isLoading: updatainteriewDataLoading,
    doFetch: fetchUpdataInterview,
  } = useFetch([], { suppressAlert: true });
  const [deleteInterviewData, fetchDeleteInterview] = useState();
  // const {
  //   data: deleteInterviewData,
  //   isLoading: deleteInterviewDataLoading,
  //   doFetch: fetchDeleteInterview,
  // } = useFetch([], { suppressAlert: true });

  // const fetchSearch = (e) => {
  //   setPage(0);
  //   setRowsPerPage(50);
  //   if (e.keyCode === 13) {
  //     const data = document.getElementById("search-bar").value;
  //     setApplicentName(document.getElementById("search-bar").value);
  //     functionSearch(data);
  //   }
  // };
  function functionSearch(data) {
    if (data !== "") {
      fetchTableData({
        url: `${urls.filterGrade}?page=${page}&page_size=${rowsPerPage ||
          50}&search=${data}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      getTableData();
    }
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }
  const clearBulkFunction = () => {
    setBulkFile("");
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }
  function getTableData() {
    fetchTableData({
      url: `${urls.getGrade}?page_size=${rowsPerPage || 50}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  function tableDropdown() {
    fetchTableDatad({
      url: `${urls.newGrade}?position_type=${positionType}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  useEffect(() => {
    if (positionType) {
      tableDropdown();
    }
  }, [auth, positionType]);

  useEffect(() => {
    if (auth) {
      getTableData();
    }
  }, [
    auth,
    addInterviewPanelResponse,
    updatainteriewData,
    deleteInterviewData,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (addInterviewPanelResponse) {
      setCheckedLoc("");
      setRoled("");
    }
  }, [addInterviewPanelResponse]);

  const alert = useAlert();

  const handleBulkQuestions = (value) => {
    setBulkFile(value);
  };

  const handleClose = () => {
    setRole("");
    setInerviewPanel("");
    setOpen(false);
    setChecked(null);
  };

  useEffect(() => {
    if (updatainteriewData) {
      setOpen(false);
      setCityName("");
      setBulkFile("");
    }
  }, [updatainteriewData]);

  let loader = null;
  if (
    addInterviewPanelResponseLoading ||
    tableDataLoading ||
    updatainteriewDataLoading
  ) {
    loader = <Loader open />;
  }

  function updateInterviewFunction() {
    const data = {
      grade_id: selectedRoleId,
      grade_name: role,
      is_active: checked,
    };
  }
  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity,
  } = useFetch([], { suppressAlert: true });
  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [fetchCity]);

  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });

  function handleMultipleChange(event) {
    console.log(event.target.value);
    setBranch(event.target.value);
  }
  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [checkedLoc]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  function fetchData() {
    fetchTableData({
      url: `${
        urls.filterGrade
      }?grade_id=${role}&page=${page}&page_size=${rowsPerPage || 50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  function handleCityMultipleChange(event) {
    setCheckedLoc(event.target.value);
  }
  function handleCityMultipleChanges(event) {
    setCheckedLoca(event.target.value);
  }
  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  function editFunction(data) {
    setVisible(true);
    form.setFieldsValue({
      grade_name: data.grade_name,
      position_type: data.position_type,
    });
    setSelectedRoleId(data?.id);
    setIsActive(data?.is_active);
  }

  function addInterviewPanel(formValue) {
    // const city_object = checkedLoc.map((item) => item)
    if (!selectedRoleId) {
      addInterview({
        url: urls.postGrade,
        method: "POST",
        body: formValue,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    } else {
      fetchUpdataInterview({
        url: `${urls.updateGrade}`,
        method: "PUT",
        body: { ...formValue, grade_id: selectedRoleId, is_active: isActive },
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          "Content-Type": "application/json",
        },
      });
    }
  }

  const [form] = Form.useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    setSelectedRoleId(null);
    setIsActive(null);
  };

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Grade Name</strong>,
      dataIndex: "grade_name",
      key: "grade_name",
      align: "center",
    },
    {
      title: <strong>Position Type</strong>,
      dataIndex: "position_type",
      key: "position_type",
      align: "center",
    },

    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
    {
      title: "Status",
      key: "is_active",
      render: (data) => (
        <AntdSwitch
          onChange={() => {
            fetchUpdataInterview({
              url: `${urls.updateGrade}`,
              method: "PUT",
              body: {
                grade_name: data?.grade_name,
                grade_id: data?.id,
                is_active: !data?.is_active,
              },
              headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                "Content-Type": "application/json",
              },
            });
          }}
          checked={data?.is_active}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>Grade/Level Details</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add Grade/Level Details
          </AntdButton>
        </div>
      </div>

      <AntdDrawer
        title="Create Grade/Level Details"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          setSelectedRoleId(null);
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="grade_level" type="primary" htmlType="submit">
              Add
            </AntdButton>
          </div>
        }
      >
        <Form
          id="grade_level"
          form={form}
          layout="vertical"
          onFinish={addInterviewPanel}
        >
          <Row>
            {selectedRoleId ? null : (
              <Col xs={24}>
                <Form.Item
                  name="position_type"
                  label="Select Position Type"
                  rules={[
                    { required: true, message: "Please Select Position" },
                  ]}
                >
                  <AntdSelect
                    value={positionType || ""}
                    onChange={(e, id) => {
                      setPositionType(e);
                    }}
                    placeholder="Position Name"
                  >
                    {positionTypeList.map((option, index) => (
                      <AntdSelect.Option key={option.id} value={option.label}>
                        {option.label}
                      </AntdSelect.Option>
                    ))}
                  </AntdSelect>
                </Form.Item>
              </Col>
            )}

            <Col xs={24}>
              <Form.Item
                name="grade_name"
                label={positionType == "Academic" ? "Grade Name" : "Level Name"}
                placeholder={
                  positionType == "Academic" ? "Grade Name" : "Level Name"
                }
                rules={[{ required: true, message: "Please enter Level Name" }]}
              >
                <Input
                  placeholder={
                    positionType == "Academic" ? "Grade Name" : "Level Name"
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>
      <Card style={{ marginBottom: "10px" }}>
        <Row>
          <Col md={6}>
            <AntdSelect
              style={{ width: "100%" }}
              value={positionType || "Select Position Type"}
              onChange={(e, id) => {
                setPositionType(e);
              }}
            >
              {positionTypeList.map((option, index) => (
                <AntdSelect.Option
                  key={index}
                  value={option.label}
                  name={option.id}
                >
                  {option.label}
                </AntdSelect.Option>
              ))}
            </AntdSelect>
          </Col>
          <Col md={6} style={{ paddingInlineStart: "10px" }}>
            <AntdSelect
              style={{ width: "100%" }}
              value={role || "Select Grade"}
              onChange={(e) => {
                setRole(e);
              }}
            >
              {tableDatad &&
                tableDatad.map((option) => (
                  <AntdSelect.Option
                    key={option.id}
                    value={option.id}
                    name={option.grade_name}
                  >
                    {option.grade_name}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
          </Col>

          {/* <Col xs={2} md={4} style={{ paddingInline: "10px" }}> */}
          <AntdButton
            onClick={() => fetchData()}
            type="primary"
            style={{ marginInline: "10px" }}
          >
            Filter
          </AntdButton>
          {/* </Col> */}

          <AntdButton
            type="primary"
            disabled={positionType ? false : true}
            onClick={() => {
              setPositionType(null);
            }}
            // style={{ width: "100%" }}
          >
            Clear All
          </AntdButton>
        </Row>
      </Card>

      {tableData.results && tableData.length !== 0 && (
        <AntdTable
          columns={columns}
          dataSource={tableData.results}
          pagination={{
            current: page,
            total: tableData?.count,
            pageSize: rowsPerPage,
            onChange: (current, pageSize) => {
              setPage(current);
              setRowsPerPage(pageSize);
            },
            pageSizeOptions: [50, 75, 100],
          }}
        />
      )}
    </React.Fragment>
  );
}
Grade.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(Grade);
