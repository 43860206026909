export const ccEmailList = [
  {
    id: 1,
    email: "hr_sampark@orchids.edu.in",
  },
  {
    id: 2,
    email: "hr.corporate@orchids.edu.in",
  },
  {
    id: 3,
    email: "sai.vadlamani@orchids.edu.in",
  },
  {
    id: 4,
    email: "adil.khan@orchids.edu.in",
  },
  {
    id: 5,
    email: "rahulsingh@orchids.edu.in",
  },
];
