import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Col,
  Row,
  Pagination,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Typography as AntdTypography,
  Card,
  message,
  InputNumber,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Dialog,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  Modal,
  TablePagination,
  InputBase,
  TableContainer,
  Select,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import styles from "./crudDesignation.style";
import AddIcon from "@material-ui/icons/Add";
import urls from "../../../url";
import axios from "axios";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { makeStyles } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Drawer from "@material-ui/core/Drawer";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";

function CrudDesignation({ classes }) {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [loadings, setLoadings] = useState(false);
  const [filterLoadings, setFilterLoadings] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [tableData, setTableData] = useState([]);
  const [namePost, setNamePost] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checked, setChecked] = React.useState(false);
  const [selected, setSelected] = React.useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState("");

  const [visible, setVisible] = useState(false);
  const [positionType, setPositionType] = useState();
  const [positionValue, setPositionValue] = useState(null);

  const [id, setId] = useState();
  const handleClickOpen = (index) => {
    setOpen(true);
    setId(index);
    setShowEdit(true);
    // setId(data1);
    // setSelected(data2);
  };
  useEffect(() => {
    axios
      .get(urls?.positionTypes, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name?.personal_info?.token}`,
        },
      })
      .then((res) => {
        console.log(res, "Position");
        setPositionType(res?.data);
      })
      .catch((error) => {
        // Handle error here
        console.error("Error fetching position types:", error);
      });
  }, []);
  useEffect(() => {
    if (showEdit) {
      setNamePost(tableData[id]?.name);
      setChecked(!tableData[id]?.is_delete);
    }
  }, [showEdit, id]);

  const handleClickOpend = () => {
    setOpen(true);
  };

  const [isDelete, setIsDelete] = useState();
  const handleClickDelete = (e) => {
    setIsDelete();
  };

  const handleClose = () => {
    setOpen(false);
    setId(false);
    setNamePost(null);
    setChecked(null);
  };

  const [totalPages, setTotalPages] = useState("");

  const getDesignationDetails = () => {
    setFilterLoadings(true);
    axios
      .get(
        `${
          urls?.designationDetails
        }?page=${page}&position_type=${positionValue ||
          ""}&page_size=${rowsPerPage}&is_delete=${isDelete}`
      )
      .then((response) => {
        console.log(response, "total");
        setFilterLoadings(false);
        if (response) {
          setTotalPages(response?.data?.count);
          console.log(response?.data?.results, "Pratik");
          setTableData(response?.data?.results);
          console.log("success", "success");
        } else {
          console.log("error", "error");
        }
      });
  };

  const handleSubmitForm = () => {
    let data = {
      name: namePost,
    };

    axios.post(`${urls?.designationDetails}`, data).then((response) => {
      if (response) {
        handleClose();
        getDesignationDetails();
        setNamePost(null);
        console.log("success", "success");
      } else {
        console.log("error", "error");
      }
    });
  };

  const handleUpdate = () => {
    let updateData = {
      name: namePost,
      is_delete: checked === true ? false : true,
    };

    axios
      .put(`${urls?.designationDetails}${tableData[id].id}/`, updateData)
      .then((response) => {
        if (response) {
          handleClose();
          console.log(response, "response");
          getDesignationDetails();
          setShowEdit(false);

          console.log("success", "success");
        } else {
          console.log("erroe", "error");
        }
      });
  };
  function functionSearch(data) {
    setSearchData(data);
  }

  const handleChangePage = (event, newPage) => {
    console.log(newPage, "newPage");
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(10);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event?.target?.value);
    console.log(event?.target?.value, "event.target.value");
    setPage(0);
  };

  useEffect(() => {
    getDesignationDetails();
  }, [rowsPerPage, page, searchData]);

  const [form] = Form.useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRoleId(null);
  };

  function editFunction(data) {
    console.log(data, "sghhd");
    setVisible(true);
    form.setFieldsValue({
      name: data?.name,
      position_type: data?.position_type,
      weightage: data?.weightage,
    });

    setSelectedRoleId(data?.id);
  }

  function addInterviewPanel(formValue) {
    setLoadings(true);
    // const city_object = checkedLoc.map((item) => item)
    console.log(selectedRoleId, "selectedRoleId");
    if (!selectedRoleId) {
      axios
        .post(`${urls?.designationDetails}`, formValue)
        .then((response) => {
          if (response) {
            handleClose();
            getDesignationDetails();
            setNamePost(null);
            setLoadings(false);
            onClose();
            message.success("Designation created successfully");

            console.log("success", "success");
          } else {
            message.error("Not able to create designation right now");

            console.log("error", "error");
          }
        })
        .catch(() => {
          setLoadings(false);

          form.resetFields();
          onClose();
        });
    } else {
      axios
        .put(`${urls?.designationDetails}${selectedRoleId}/`, formValue)
        .then((response) => {
          if (response) {
            handleClose();
            console.log(response, "response");
            getDesignationDetails();
            setShowEdit(false);
            setLoadings(false);
            onClose();
            message.success("Updated successfully");

            console.log("success", "success");
          } else {
            setLoadings(false);
            onClose();
            message.error("Not able to update right now");

            console.log("erroe", "error");
          }
        })
        .catch(() => {
          form.resetFields();
          onClose();
        });
    }
  }

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Designation Name</strong>,
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <strong>Position</strong>,
      dataIndex: "position_type",
      key: "position_type",
      align: "center",
      render: (record) => {
        const matchedPositionType = positionType?.find(
          (type) => type?.id === record
        );
        return (
          <span>
            {matchedPositionType ? matchedPositionType?.position_type : ""}
          </span>
        );
      },
    },

    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
    {
      title: "Status",
      key: "is_delete",
      render: (data) => (
        <AntdSwitch
          onChange={() => {
            console.log(data, "data");
            axios
              .put(`${urls?.designationDetails}${data?.id}/`, {
                is_delete: !data?.is_delete,
              })
              .then((response) => {
                if (response) {
                  handleClose();
                  console.log(response, "response");
                  getDesignationDetails();
                  setShowEdit(false);

                  console.log("success", "success");
                } else {
                  console.log("error", "error");
                }
              });
          }}
          checked={!data?.is_delete}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>Designation Deatils</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add Designation
          </AntdButton>
        </div>
      </div>

      <Card style={{ marginBottom: "10px" }}>
        <Row gutter={18}>
          <Col md={6}>
            <AntdSelect
              showSearch
              filterOption={(input, options) => {
                return (options?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              placeholder="Select Position"
              onChange={(e) => setPositionValue(e)}
              value={positionValue}
              style={{ width: "100%" }}
              maxTagCount={3}
            >
              {positionType &&
                positionType?.map((data) => (
                  <AntdSelect.Option key={data?.id} value={data?.id}>
                    {data?.position_type}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
          </Col>

          {/* <Col xs={2} md={4} style={{ paddingInline: "10px" }}> */}
          <AntdButton
            onClick={() => getDesignationDetails()}
            type="primary"
            style={{ marginInline: "10px" }}
            loading={filterLoadings}
          >
            Filter
          </AntdButton>
          {/* </Col> */}

          <AntdButton
            type="primary"
            disabled={positionValue ? false : true}
            onClick={() => setPositionValue(null)}
            // style={{ width: "100%" }}
          >
            Clear All
          </AntdButton>
        </Row>
      </Card>

      <AntdDrawer
        title="Create"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          setSelectedRoleId(null);
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton
              form="name"
              type="primary"
              htmlType="submit"
              loading={loadings}
            >
              Add
            </AntdButton>
          </div>
        }
      >
        <Form
          id="name"
          form={form}
          layout="vertical"
          onFinish={addInterviewPanel}
        >
          <Row>
            <Col xs={24}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position",
                  },
                ]}
                labelCol={{ span: 24 }}
              >
                <AntdSelect
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Position*"
                >
                  {positionType &&
                    positionType.map((data) => (
                      <AntdSelect.Option
                        value={data?.id}
                        key={data?.id}
                        name={data?.id}
                      >
                        {data?.position_type}
                      </AntdSelect.Option>
                    ))}
                </AntdSelect>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="name"
                label="Name"
                placeholder="Designation Name"
                rules={[
                  { required: true, message: "Please enter Designation Name" },
                ]}
              >
                <Input placeholder="Designation Name" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="weightage"
                label="Weightage"
                placeholder="Weightage"
                rules={[{ required: true, message: "Please enter Weightage" }]}
              >
                <InputNumber
                  placeholder="Weightage"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>

      <>
        {tableData && (
          <AntdTable
            columns={columns}
            dataSource={tableData}
            pagination={{
              current: page,
              total: totalPages,
              pageSize: rowsPerPage,
              onChange: (current, pageSize) => {
                setPage(current);
                setRowsPerPage(pageSize);
              },
              pageSizeOptions: [10, 25, 50],
            }}
          />
        )}
      </>
    </React.Fragment>
  );
}

export default withStyles(styles)(CrudDesignation);
