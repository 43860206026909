export default (theme) => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: "5px 40px",
      marginTop: "15px",
    },
    createPosition: {
      padding: "10px",
    },
    textField: {
      width: "100%",
      marginTop: "-2px",
    },
    applyButtonSection: {
      display: "block",
      margin: "0 auto",
      padding: "20px",
    },
    applyButton: {
      margin: "7px 0px",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    nextButton: {
      float: "right",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      // boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: true,
    },
  });
  