import { Col, Row, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const Dashboard = () => {
  const history = useHistory();
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));

  useEffect(() => {
    const role = userData?.personal_info?.role.toLowerCase();
    if (role != 13 || role != 21) {
      history.push(`/${role?.split(" ")?.join("-")}-dashboard`);
    }
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Typography.Title type="primary" level={3}>
            Dashboard
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title type="secondary" level={4}>
            {" "}
            ({userData?.academic_profile?.name})
          </Typography.Title>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
