import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";

import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  TablePagination,
  InputBase,
  TableContainer,
  Select,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import PropTypes from "prop-types";
import styles from "./crudBranch.style";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import SearchIcon from "@material-ui/icons/Search";
import MediaQuery from "react-responsive";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import Close from "@material-ui/icons/Close";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Switch from "@material-ui/core/Switch";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Form,
  Input,
  Col,
  Row,
  Pagination,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Typography as AntdTypography,
  Card,
  Input as AntdInput,
  Modal,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

function CrudBranch({ classes }) {
  const history = useHistory();
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [role, setRole] = useState("");
  const [inerviewPanel, setInerviewPanel] = useState("");
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(1);
  const [applicentName, setApplicentName] = useState("");
  const [bulkFile, setBulkFile] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const [branch, setBranch] = useState("");
  const [accordianOpen, setAccordianOpen] = useState(false);
  const [firstPocErpCode, setFirstPocErpCode] = useState();
  const [firstPocHrName, setFirstPocHrName] = useState("");
  const [firstPocEmail, setFirstPocEmail] = useState("");
  const [firstPocContact, setFirstPocContact] = useState();
  const [firstEscalationName, setFirstEscalationName] = useState("");
  const [firstEscalationEmail, setFirstEscalationEmail] = useState("");
  const [acadName, setAcadName] = useState("");
  const [acadEmail, setAcadEmail] = useState("");
  const [acadContact, setAcadContact] = useState("");
  const [opsManagerName, setOpsManagerName] = useState("");
  const [opsManagerEmail, setOpsManagerEmail] = useState("");
  const [opsManagerContact, setOpsManagerContact] = useState("");
  const [opsZonalName, setOpsZonalName] = useState("");
  const [opsZonalEmail, setOpsZonalEmail] = useState("");
  const [opsZonalContact, setOpsZonalContact] = useState("");
  const [bdmName, setBdmName] = useState("");
  const [bdmId, setBdmId] = useState();
  const [bdmContact, setBdmContact] = useState();
  const [principalContact, setPrincipalContact] = useState("");
  const [principalEmail, setPrincipalEmail] = useState("");
  const [principalName, setPrincipalName] = useState("");
  const [cicEmail, setCicEmail] = useState("");
  const [ticEmail, setTicEmail] = useState("");
  const [addedBy, setAddedBy] = useState(auth.personal_info.user_id);
  const [secondEscalationName, setSecondEscalationName] = useState("");
  const [secondEscalationEmail, setSecondEscalationEmail] = useState("");
  const [accordianOpenFilter, setAccordianOpenFilter] = useState(false);
  const [accordianBulkFilter, setAccordianBulkFilter] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [
    fetchingInterviewPanelData,
    setFechingInterviewPanelData,
  ] = React.useState();

  const {
    data: roleData,
    isLoading: roleDataLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });
  const {
    data: inerviewData,
    isLoading: inerviewDataLoading,
    doFetch: fetchinterviewPanelData,
  } = useFetch([], { suppressAlert: true });
  const {
    data: addInterviewPanelResponse,
    isLoading: addInterviewPanelResponseLoading,
    doFetch: addInterview,
  } = useFetch([], { suppressAlert: true });
  const {
    data: tableData,
    isLoading: tableDataLoading,
    doFetch: fetchTableData,
  } = useFetch("", { suppressAlert: true });

  const {
    data: updatainteriewData,
    isLoading: updatainteriewDataLoading,
    doFetch: fetchUpdataInterview,
  } = useFetch([], { suppressAlert: true });
  const [deleteInterviewData, fetchDeleteInterview] = useState();

  // const {
  //   data: deleteInterviewData,
  //   isLoading: deleteInterviewDataLoading,
  //   doFetch: fetchDeleteInterview,
  // } = useFetch([], { suppressAlert: true });

  const fetchSearch = (e) => {
    setPage(1);
    setRowsPerPage(50);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicentName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };
  function functionSearch(data) {
    // fetchApplicent({
    //   url: `${urls.searchApplicentRecruiter}?page=${page +
    //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${name.personal_info.token}`
    //   }
    // });
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }
  const clearBulkFunction = () => {
    setBulkFile("");
  };

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }

  // useEffect(() => {
  //   if (auth) {
  //     fetchRole({
  //       url: urls.interviewPanalRolesApi,
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${auth.personal_info.token}`,
  //       }
  //     });
  //   }
  // }, [fetchRole,fetchinterviewPanelData,auth]);

  useEffect(() => {
    if (role) {
      fetchinterviewPanelData({
        url: `${urls.interviewersRoleWiseEmployeeAdminApi}?role_id=${role}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [role]);

  function getTableData() {
    fetchTableData({
      url: `${urls.getBranch}?page_size=${rowsPerPage || 50}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (auth) {
      getTableData();
    }
  }, [
    auth,
    addInterviewPanelResponse,
    updatainteriewData,
    deleteInterviewData,
    page,
    rowsPerPage,
  ]);
  console.log(addInterviewPanelResponse, "addinterview");

  useEffect(() => {
    if (addInterviewPanelResponse || updatainteriewData) {
      setCheckedLoc("");
      setBranch("");
      setFirstPocErpCode("");
      setFirstPocEmail("");
      setFirstPocHrName("");
      setFirstPocContact("");
      setFirstEscalationName("");
      setFirstEscalationEmail("");
      setSecondEscalationName("");
      setSecondEscalationEmail("");
      setAcadContact("");
      setAcadEmail("");
      setAcadName("");
      setOpsManagerName("");
      setOpsManagerContact("");
      setOpsManagerEmail("");
      setOpsZonalName("");
      setOpsZonalEmail("");
      setOpsZonalContact("");
      setPrincipalName("");
      setPrincipalContact("");
      setPrincipalEmail("");
      setBdmName("");
      setBdmId("");
      setBdmContact("");
      setCicEmail("");
      setTicEmail("");
      setChecked(null);
    }
  }, [addInterviewPanelResponse, updatainteriewData]);

  const alert = useAlert();

  function addInterviewPanel() {
    if (branch == "" && firstPocErpCode == "" && firstPocHrName == "") {
      alert.warning("Please Fill All Field");
      return;
    }
    const formData = new FormData();
    formData.append("branch_id", branch);
    formData.append("first_st_poc_erp_code", firstPocErpCode);
    formData.append(
      "first_St_poc_from_hr_name_EA_or_EA_substitute_email",
      firstPocHrName
    );
    formData.append("first_st_poc_email", firstPocEmail);
    formData.append("first_st_poc_contact_number", firstPocContact);
    formData.append("first_st_escalation_point_name", firstEscalationName);
    formData.append("first_escalation_point_email", firstEscalationEmail);
    formData.append("second_escalation_point_name", secondEscalationName);
    formData.append("second_escalation_point_email", secondEscalationEmail);
    formData.append("cic_email", cicEmail);
    formData.append("tic_email", ticEmail);
    formData.append("bdm_contact_number", bdmContact);
    formData.append("bdm_id", bdmId);
    formData.append("bdm_name", bdmName);
    formData.append("ops_manager", opsManagerName);
    formData.append("ops_manager_contact_number", opsManagerContact);
    formData.append("ops_manager_email", opsManagerEmail);
    formData.append("ops_zonal_head_contact_number", opsZonalContact);
    formData.append("ops_zonal_head_email", opsZonalEmail);
    formData.append("ops_zonal_head_name", opsZonalName);
    formData.append("principal_contact_number", principalContact);
    formData.append("principal_email", principalEmail);
    formData.append("principal_name", principalName);
    formData.append("acad_ea_name", acadName);
    formData.append("acad_ea_email", acadEmail);
    formData.append("acad_ea_contact_number", acadContact);
    formData.append("added_by", addedBy);

    addInterview({
      url: urls.postBranch,
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  const handleBulkUpload = () => {
    const File = new FormData();
    File.append("file", bulkFile);
    fetch(urls.uploadBranch, {
      method: "POST",
      body: File,
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status_code === 201) {
          addInterview(response);
          setBulkFile("");

          alert.success("File Added Successfully");
        } else {
          alert.error("Failed To Add");
        }
      })
      .catch(() => {
        alert.error("Failed to add");
      });
  };
  const handleBulkQuestions = (value) => {
    setBulkFile(value);
  };
  const fetchData = () => {
    if (branch == "") {
      alert.warning("please select a branch");
      return;
    }
    fetchTableData({
      url: `${urls.filterBranch}?branch_id=${branch}&page_size=${rowsPerPage ||
        50}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  };

  const handleClose = () => {
    setRole("");
    setInerviewPanel("");
    setOpen(false);
    setChecked(null);
  };

  useEffect(() => {
    if (updatainteriewData) {
      setOpen(false);
      setRole("");
      setInerviewPanel("");
    }
  }, [updatainteriewData]);

  let loader = null;
  if (
    addInterviewPanelResponseLoading ||
    roleDataLoading ||
    tableDataLoading ||
    inerviewDataLoading ||
    updatainteriewDataLoading
  ) {
    loader = <Loader open />;
  }
  const [id, setId] = useState();

  function editFunction(data, i) {
    setOpen(true);
    console.log(tableData && tableData.results[i].branch, "tabledata");

    setCheckedLoc(tableData && tableData?.results[i]?.branch?.city_id?.id);
    setBranch(tableData && tableData?.results[i]?.branch?.id);
    setFirstPocErpCode(data?.first_st_poc_erp_code);
    setFirstPocEmail(data?.first_st_poc_email);
    setFirstPocHrName(
      data?.first_St_poc_from_hr_name_EA_or_EA_substitute_email
    );
    setFirstPocContact(data.first_st_poc_contact_number);
    setFirstEscalationName(data?.first_st_escalation_point_name);
    setFirstEscalationEmail(data?.first_escalation_point_email);
    setSecondEscalationName(data?.second_escalation_point_name);
    setSecondEscalationEmail(data?.second_escalation_point_email);
    setAcadContact(data?.acad_ea_contact_number);
    setAcadEmail(data?.acad_ea_email);
    setAcadName(data?.acad_ea_name);
    setOpsManagerName(data?.ops_manager);
    setOpsManagerContact(data?.ops_manager_contact_number);
    setOpsManagerEmail(data?.ops_manager_email);
    setOpsZonalName(data?.ops_zonal_head_name);
    setOpsZonalEmail(data?.ops_zonal_head_email);
    setOpsZonalContact(data?.ops_zonal_head_contact_number);
    setPrincipalName(data?.principal_name);
    setPrincipalContact(data?.principal_contact_number);
    setPrincipalEmail(data?.principal_email);
    setBdmName(data?.bdm_name);
    setBdmId(data?.bdm_id);
    setBdmContact(data?.bdm_contact_number);
    setCicEmail(data?.cic_email);
    setTicEmail(data?.tic_email);
    setId(data?.id);
    setChecked(data?.is_active);
  }

  function updateInterviewFunction() {
    const fields = new FormData();
    fields.append("id", id);
    fields.append("branch_id", branch);
    fields.append("first_st_poc_erp_code", firstPocErpCode);
    fields.append("first_st_poc_email", firstPocEmail);
    fields.append("first_st_poc_contact_number", firstPocContact);
    fields.append("first_st_escalation_point_name", firstEscalationName);
    fields.append(
      "first_St_poc_from_hr_name_EA_or_EA_substitute_email",
      firstPocHrName
    );
    fields.append("first_escalation_point_email", firstEscalationEmail);
    fields.append("second_escalation_point_name", secondEscalationName);
    fields.append("second_escalation_point_email", secondEscalationEmail);
    fields.append("cic_email", cicEmail);
    fields.append("tic_email", ticEmail);
    fields.append("bdm_contact_number", bdmContact);
    fields.append("bdm_id", bdmId);
    fields.append("bdm_name", bdmName);
    fields.append("ops_manager", opsManagerName);
    fields.append("ops_manager_contact_number", opsManagerContact);
    fields.append("ops_manager_email", opsManagerEmail);
    fields.append("ops_zonal_head_contact_number", opsZonalContact);
    fields.append("ops_zonal_head_email", opsZonalEmail);
    fields.append("ops_zonal_head_name", opsZonalName);
    fields.append("principal_contact_number", principalContact);
    fields.append("principal_email", principalEmail);
    fields.append("principal_name", principalName);
    fields.append("acad_ea_name", acadName);
    fields.append("acad_ea_email", acadEmail);
    fields.append("acad_ea_contact_number", acadContact);
    fields.append("is_active", checked ? "True" : "False");

    fetchUpdataInterview({
      url: `${urls.updateBranch}`,
      method: "PUT",
      body: fields,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        // "Content-Type": "application/json",
      },
    });
  }
  // const [deleteModel,setDeleteModel]=useState(false);
  // const [deleteId,setDeleteId]=useState();
  // function deleteTopic(data){
  //   setDeleteModel(true);
  //   setDeleteId(data?.id);
  // }
  // function cancelDelete(){
  //   setDeleteModel(false);
  //   setDeleteId(null);
  // }

  // function deleteFunction() {
  //   const body = {
  //     'id' : deleteId,
  //     'is_active' :false
  //   }
  //   axios.put(
  //     `${urls.updateBranch}`,body,{
  //     method: "PUT",
  //     headers: {
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //       "Content-Type": "application/json",
  //     },
  //   }
  //   ).then((response) => response.json())
  //   .then((response) => {
  //     // console.log(data,"data");
  //     // fetchDeleteInterview(data);
  //     cancelDelete();
  //     alert.success("data deleted successfully")
  //     setPage(0)
  //   setRowsPerPage(50)

  //   }).catch(() => {
  //     alert.success("data deleted successfully")

  //     cancelDelete();
  //     getTableData();

  //   });

  // }

  useEffect(() => {
    if (fetchingInterviewPanelData) {
      fetchTableData({
        url: `${urls.filterBranch}?page_size=${rowsPerPage ||
          50}&page=${page}&search=${fetchingInterviewPanelData}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [fetchingInterviewPanelData]);
  const fetchInterviewPanel = (e) => {
    setFechingInterviewPanelData(e.target.value);
  };
  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches,
  } = useFetch([], { suppressAlert: true });
  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [fetchCity]);
  function handleCityMultipleChange(event) {
    setCheckedLoc(event);
    setBranch();
  }
  function handleMultipleChange(event) {
    setBranch(event);
  }
  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
  }, [checkedLoc]);
  const handlePocErp = (e) => {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 11
    ) {
      setFirstPocErpCode(e.target.value);
    }
  };
  const handlePocContact = (e) => {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 10
    ) {
      setFirstPocContact(e.target.value);
    }
  };
  const handleAcadContact = (e) => {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 10
    ) {
      setAcadContact(e.target.value);
    }
  };
  const handleOpsManager = (e) => {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 10
    ) {
      setOpsManagerContact(e.target.value);
    }
  };
  const handleOpsZonal = (e) => {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 10
    ) {
      setOpsZonalContact(e.target.value);
    }
  };
  const handleBdmContact = (e) => {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 10
    ) {
      setBdmContact(e.target.value);
    }
  };
  const handlePrincipalContact = (e) => {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 10
    ) {
      setPrincipalContact(e.target.value);
    }
  };

  function changeActive(yourData) {
    const formData = new FormData();

    const obj = {
      id: yourData.id,
      branch_id: yourData?.branch?.id,
      first_st_poc_erp_code: yourData?.first_st_poc_erp_code,
      first_St_poc_from_hr_name_EA_or_EA_substitute_email:
        yourData?.first_St_poc_from_hr_name_EA_or_EA_substitute_email,
      first_st_poc_email: yourData.first_st_poc_email,
      first_st_poc_contact_number: yourData?.first_st_poc_contact_number,
      first_st_escalation_point_name: yourData.first_st_escalation_point_name,
      first_escalation_point_email: yourData.first_escalation_point_email,
      cic_email: yourData.cic_email,
      tic_email: yourData.tic_email,
      second_escalation_point_name: yourData?.second_escalation_point_name,
      second_escalation_point_email: yourData?.second_escalation_point_email,
      bdm_contact_number: yourData.bdm_contact_number,
      bdm_id: yourData.bdm_id,
      bdm_name: yourData.bdm_name,
      ops_manager: yourData.ops_manager,
      ops_manager_contact_number: yourData?.ops_manager_contact_number,
      ops_manager_email: yourData?.ops_manager_email,
      ops_zonal_head_contact_number: yourData?.ops_zonal_head_contact_number,
      ops_zonal_head_email: yourData?.ops_zonal_head_email,
      ops_zonal_head_name: yourData?.ops_zonal_head_name,
      principal_contact_number: yourData?.principal_contact_number,
      principal_email: yourData?.principal_email,
      principal_name: yourData?.principal_name,
      acad_ea_name: yourData?.acad_ea_name,
      acad_ea_email: yourData?.acad_ea_email,
      acad_ea_contact_number: yourData?.acad_ea_contact_number,

      is_active: yourData?.is_active ? "False" : "True",
    };

    Object.entries(obj).forEach(([key, value]) => {
      formData.append(key, value);
    });

    fetchUpdataInterview({
      url: `${urls.updateBranch}`,
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        // "Content-Type": "application/json",
      },
    });
  }

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Branch Name</strong>,
      dataIndex: "branch",
      key: "branch",
      align: "center",
      render: (branch) => (branch ? branch?.branch_name : ""),
    },
    {
      title: <strong>City Name</strong>,
      dataIndex: "branch",
      key: "city",
      align: "center",
      render: (branch) => (branch ? branch?.city_id?.city_name : ""),
    },
    {
      title: <strong>Principal Name</strong>,
      dataIndex: "principal_name",
      key: "principal_name",
      align: "center",
    },
    {
      title: <strong>First Escalation Name</strong>,
      dataIndex: "first_st_escalation_point_name",
      key: "first_st_escalation_point_name",
      align: "center",
    },
    {
      title: <strong>Second Escalation Name</strong>,
      dataIndex: "second_escalation_point_name",
      key: "second_escalation_point_name",
      align: "center",
    },
    {
      title: <strong>Zonal Head Name</strong>,
      dataIndex: "ops_zonal_head_name",
      key: "ops_zonal_head_name",
      align: "center",
    },
    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() =>
                history.push({
                  pathname: "/crudBranchForm",
                  state: { yourData: record },
                })
              }
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
    {
      title: "Status",
      key: "is_active",

      render: (data) => (
        <AntdSwitch
          onChange={() => {
            changeActive(data);
          }}
          checked={data?.is_active}
        />
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const csvForm = useForm();

  return (
    <React.Fragment>
      <Modal
        title="Bulk Upload"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form id="bulkUploadForm" layout="vertical" onFinish={handleBulkUpload}>
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item label="Upload">
                <AntdInput
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => handleBulkQuestions(e.target.files[0])}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={6} style={{ paddingTop: "30px" }}>
              <Form.Item>
                <AntdButton type="primary" htmlType="submit">
                  Upload
                </AntdButton>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <p>Guidelines:</p>
        <ol>
          <li>
            Download the upload sample of the excel file here :{" "}
            <a
              // href={UploadFormat}
              className="pt-1 text-primary"
              style={{ cursor: "pointer" }}
            >
              Download Sample
            </a>
          </li>
          <li>
            Please don't remove or manipulate any header in the file format.
          </li>
        </ol>
      </Modal>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>Branch Details</strong>
        </div>

        <div>
          <Row gutter={16}>
            {/* <Col>
              <AntdButton
                type="primary"
                icon={<CloudUploadOutlined />}
                onClick={() => showModal()}
              >
                Bulk Upload
              </AntdButton>
            </Col> */}
            <Col>
              <AntdButton
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => history.push({ pathname: "/crudBranchForm" })}
              >
                Add Branch
              </AntdButton>
            </Col>
          </Row>
        </div>
      </div>

      <Card style={{ marginBottom: "10px" }}>
        <Row gutter={16}>
          <Col md={6}>
            <AntdSelect
              style={{ width: "100%" }}
              value={checkedLoc || "Select City Name"}
              onChange={handleCityMultipleChange}
              showSearch
              filterOption={(input, options) => {
                return (options?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {cityList &&
                cityList.length !== 0 &&
                cityList.map((option) => (
                  <AntdSelect.Option
                    key={option.value}
                    value={option.id}
                    name={option.id}
                  >
                    {option.city_name}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
          </Col>
          <Col md={6}>
            <AntdSelect
              style={{ width: "100%" }}
              value={branch || "Select Branch"}
              onChange={handleMultipleChange}
              showSearch
              filterOption={(input, options) => {
                return (options?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {branchList &&
                branchList.map((data) => (
                  <AntdSelect.Option
                    value={data.id}
                    key={data.id}
                    name={data.branch_name}
                  >
                    {data.branch_name ? data.branch_name : ""}
                  </AntdSelect.Option>
                ))}
            </AntdSelect>
          </Col>

          <AntdButton
            onClick={fetchData}
            type="primary"
            disabled={branch ? false : true}
            style={{ marginInline: "10px" }}
          >
            Filter
          </AntdButton>
          {/* </Col> */}

          <AntdButton
            type="primary"
            disabled={branch ? false : true}
            onClick={() => {
              setBranch(null);
              setCheckedLoc(null);
            }}
            // style={{ width: "100%" }}
          >
            Clear
          </AntdButton>
        </Row>
      </Card>

      {tableData.results && tableData.length !== 0 && (
        <AntdTable
          columns={columns}
          dataSource={tableData.results}
          //
          pagination={{
            current: page,
            total: tableData?.count,
            pageSize: rowsPerPage,
            onChange: (current, pageSize) => {
              setPage(current);
              setRowsPerPage(pageSize);
            },
            pageSizeOptions: [50, 75, 100],
          }}
          loading={
            addInterviewPanelResponseLoading ||
            roleDataLoading ||
            tableDataLoading ||
            inerviewDataLoading ||
            updatainteriewDataLoading
          }
        />
      )}
    </React.Fragment>
  );
}
CrudBranch.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CrudBranch);
