/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Paper,
  TextField,
  MenuItem,
  Grid,
  Button,
  Select,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  IconButton,
  TablePagination,
  TableContainer,
} from '@material-ui/core';
import urls from '../../../url'
import styles from './ocfpAssign.style';
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader';
import { useAlert } from '../../../hoc/alert/alert'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

const OcfpAssign = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [openning, setOpenning] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [selectedApplicents, setSelectedApplicents] = useState([]);
  const [hrHead, setHrhead] = useState('');
  const [interviewer, setInterviewer] = useState('');
  const [getApplicants, setgetApplicants] = useState('')


const {
  data: getOpennings,
  isLoading: gettingOpennins,
  doFetch: fetchOpennings
} = useFetch(null);

const {
  data: getApplicantsData,
  isLoading: gettingApplicants,
  doFetch: fetchApplicents
} = useFetch(null);

const {
  data: getInterviewer,
  isLoading: gettingInterviewer,
  doFetch: fetchInterviewer
} = useFetch([]);

const {
  data: getHrHeadList,
  isLoading: gettingHrHeadList,
  doFetch: fetchHrHeadList
} = useFetch([]);

const {
  data: assignAppplicants,
  isLoading:assigningApplicents,
  doFetch: fetchToAssignApplicents
} = useFetch (null)

  const alert = useAlert();

  let loader = null;
  if (gettingOpennins || gettingApplicants || gettingInterviewer || gettingHrHeadList || assigningApplicents) {
    loader = <Loader open />;
  }

  useEffect(()=>{
    if (getApplicantsData) {
     setgetApplicants(getApplicantsData)
    }
  },[getApplicantsData])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    !rowsPerPage && setRowsPerPage(50)
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0)
  }
  
  function lastPageChange(lastPage) {
    setPage(lastPage)
    !rowsPerPage && setRowsPerPage(50)
  }

  useEffect(() => {
    if (name) {
      fetchOpennings({
        url: urls.openningsListApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchInterviewer({
        url: urls.InterviewerListApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchHrHeadList({
        url: urls.hrHeadListApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchHrHeadList, fetchInterviewer, fetchOpennings, name])

  useEffect(()=>{
    if (assignAppplicants) {
      setInterviewer('')
      setHrhead('')
      setSelectedApplicents([]);
      setOpenning('');
      setgetApplicants('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[assignAppplicants])

  function functionToGetApplicants() {
    if (!openning) {
      alert.warning('select Openning');
      return;
    }
    fetchApplicents({
      url: `${urls.OcfpAssignApplicentsApi}?position_id=${openning}&page=${page+1}&page_size=${rowsPerPage || 50}&start_date=${startDate || null}&end_date=${endDate || null}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }


  const handleSub = (value) => () => {
    const currentIndex = selectedApplicents.indexOf(value);
    const newChecked = [...selectedApplicents];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedApplicents(newChecked);
  };

  function selectAllFunction() {
    if ((selectedApplicents && selectedApplicents.length !==0 && selectedApplicents.length) === (getApplicants && getApplicants.result &&  getApplicants.result.length !==0 && getApplicants.result.length)) {
      setSelectedApplicents([]);
      return
    }
    if (getApplicants && getApplicants.result.length !==0) {
      var array = []
      for(var i=0; i<getApplicants.result.length; i+=1){
        array.push(getApplicants.result[i].id)
      }
      setSelectedApplicents(array)
      return 
    }
  }

  function AssignOcfpFunction(){
    if(!interviewer || !hrHead || selectedApplicents.length===0){
      alert.warning('fill the Fileds');
      return;
    }
    const data = {
      applicant : selectedApplicents,
      interviewer_id:interviewer,
      hr_id :hrHead
    }
    fetchToAssignApplicents({
      url: urls.assignOcfpApi,
      method: 'PUT',
      body:data,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }

  return (
    <React.Fragment>
      <Typography className="headings" variant="h4"><strong>OCFP Assign</strong></Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <span>Select Openning <strong style={{color:' red'}}>*</strong></span>
               <Select
                margin="dense"
                select
                fullWidth
                // className={classes.selectFile}
                className="textList"
                label='Select Openning'
                value={openning|| ''}
                onChange={(e) => setOpenning(e.target.value)}
                variant="outlined"
              >
                {getOpennings
                  && getOpennings.length !== 0
                  && getOpennings.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.title}
                    >
                      {data.title ? data.title : ''}
                    </MenuItem>
                  ))}
              </Select>
              </Grid>
              <Grid item md={3} xs={12}>
              <span>Select Start Date</span>
                <TextField
                  margin="dense"
                  value={startDate|| ''}
                  type="date"
                  style={{marginTop: "0px"}}
                  onChange={(e) => setStartDate(e.target.value)}
                  // className={classes.textField}
                  className="textList"
                  fullWidth

                  variant="outlined"
                />
              </Grid>
              <Grid item md={3} xs={12}>
              <span>Select End Date</span>
                <TextField
                  margin="dense"
                  value={endDate|| ''}
                  type="date"
                  onChange={(e) => setEndDate(e.target.value)}
                  // className={classes.textField}
                  fullWidth
                  style={{marginTop: "0px"}}
                  className="textList"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Button color="primary" fullWidth variant="contained" className={classes.button} onClick={()=> functionToGetApplicants()}>Get</Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
        {getApplicants && getApplicants.result.length ===0 && <Typography variant="h4" style={{marginTop:'50px', color:'blue', textAlign:'center'}}>Results Not Found</Typography>} 
        {getApplicants && getApplicants.result.length !==0 && 
        <>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <TableContainer className="role">
                <Table>
                  <TableHead>
                    <TableRow>
                    <TableCell float="left">
                        <Typography><strong>Select All</strong>
                        <Checkbox
                          checked={(selectedApplicents && selectedApplicents.length !==0 && selectedApplicents.length) === (getApplicants && getApplicants.result &&  getApplicants.result.length !==0 && getApplicants.result.length)}
                          onClick={()=> selectAllFunction()}
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        </Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography><strong>S.No</strong></Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography><strong>Name</strong></Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography><strong>Register Date</strong></Typography>
                      </TableCell>
                      <TableCell float="left">
                        <Typography><strong>Opennings</strong></Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getApplicants && getApplicants.result.length !==0 && getApplicants.result.map((data,index)=>{
                        const subId = `Sub Id : ${data.id}`;
                        return(
                          <TableRow key={data.id}>
                             <TableCell float="left">
                                <Checkbox
                                  edge="start"
                                  color="primary"
                                  checked={selectedApplicents.indexOf(data.id) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  onClick={handleSub(data.id)}
                                  inputProps={{ 'aria-labelledby': subId }}
                                />
                              </TableCell>
                              <TableCell float="left">
                                <Typography>{index+1}</Typography>
                              </TableCell>
                              <TableCell float="left">
                                <Typography>{data.name}</Typography>
                              </TableCell>
                              <TableCell float="left">
                                <Typography>{data.register_date}</Typography>
                              </TableCell>
                              <TableCell float="left">
                                <Typography>{data.position}</Typography>
                              </TableCell>
                          </TableRow>
                        )})}
                  </TableBody>
                </Table>
                </TableContainer>
              </Grid>
              <Grid item md={4} xs={12}>
              <span>Select Hr Head <strong style={{color:' red'}}>*</strong></span>
               <Select
                margin="dense"
                select
                fullWidth
                // className={classes.selectFile}
                className="textList"
                label='Select Openning'
                value={hrHead|| ''}
                onChange={(e) => setHrhead(e.target.value)}
                variant="outlined"
              >
                {getHrHeadList
                  && getHrHeadList.length !== 0
                  && getHrHeadList.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.name}
                    >
                      {data.name ? data.name : ''}
                    </MenuItem>
                  ))}
              </Select>
              </Grid>
              <Grid item md={4} xs={12}>
              <span>Select Interviewer <strong style={{color:' red'}}>*</strong></span>
               <Select
                margin="dense"
                select
                fullWidth
                // className={classes.selectFile}
                className="textList"
                label='Select Openning'
                value={interviewer|| ''}
                onChange={(e) => setInterviewer(e.target.value)}
                variant="outlined"
              >
                {getInterviewer
                  && getInterviewer.length !== 0
                  && getInterviewer.map((data) => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.name}
                    >
                      {data.name ? data.name : ''}
                    </MenuItem>
                  ))}
              </Select>
              </Grid>
              <Grid item md={2} xs={12}>
                 <Button color="primary" fullWidth variant="contained" onClick={()=> AssignOcfpFunction()} style={{marginTop:'22px'}}>Submit</Button>
              </Grid>
            </Grid>
            </Paper>
            <Divider className={classes.divider} />
            {getApplicants && getApplicants.result.length !==0 &&
            <Paper className={classes.paper}> 
            <TableContainer className="role">
            <Table>
            <TableBody>
                <TableRow>
                  <TablePagination 
                    style={{marginTop:'15px'}}
                    colSpan={5}
                    rowsPerPageOptions={[50, 75, 100]}
                    component="div"
                    count={getApplicants && getApplicants.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      'aria-label': 'previous page',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'next page',
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => lastPageChange(getApplicants.total_pages - 1)}
                      disabled={page === (+getApplicants.total_pages - 1)}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </TableContainer>
          </Paper>}
          </>}
        </Grid>
      </Grid>
      {loader}
    </React.Fragment>
  )
}

OcfpAssign.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(OcfpAssign);