export default theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  applyButtonSection: {
    display: 'block',
    margin: '0 auto',
    padding: '20px'
  },
  applyButtonModal: {
    marginTop: '6px',
  },
  applyButtonModal1: {
    marginLeft: '35%'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  title: {
    float: 'left',
    fontSize: '18px',
  },
  textField: {
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1, 2, 1),
  },
  paperr: {
    // width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1, 2, 1),
  },
});
