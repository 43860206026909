/* eslint-disable no-multi-str */
import React, { useState,useEffect } from 'react';
import {
    Divider,
    Grid,
    TextField,
    MenuItem,
    Paper,
    Button,
    withStyles,
    Fab,
    Modal,
    Typography
  } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './createChapter.style';
import urls from '../../../url'
import { useAlert } from '../../../hoc/alert/alert'
import Loader from '../../../hoc/loader'
import useFetch from '../../../hoc/useFetch';
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


function CreateChapter({classes}){
    const [auth] = useState(JSON.parse(localStorage.getItem('user')));
    const [grade, setGrade] = useState('');
    const [subject,setSubject] = useState('');
    const [chapter, setChapters] = useState('')
    const [open ,setOpen] = useState(false);
    const [positionType, setPositionType] = useState("");


    const alert = useAlert();

    const {
        data: gradeData,
        isLoading: gradeLoading,
        doFetch: fetchGrade,
      } = useFetch([], { suppressAlert: true });
        const {
        data: subjectData,
        isLoading: subjectLoading,
        doFetch: fetchSubject,
      } = useFetch([], { suppressAlert: true });

      const {
          data:createChapterData,
          isLoading:createChapterDataLoading,
          doFetch:fetchAddChapter
      } = useFetch([], { suppressAlert: true });

      useEffect(()=>{
          if(createChapterData){
              setGrade('');
              setSubject('');
              setChapters('');
              setOpen(false)
          }
      },[createChapterData])

      useEffect(()=>{
        if(grade){
        fetchSubject({
          url: `${urls.subjectMapping}?grade_id=${grade}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
          }
        });
      }
      },[grade])

      useEffect(() => {
        if (positionType) {
          fetchGrade({
            url: `${urls.newGrade}?position_type=${positionType}`,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.personal_info.token}`,
            }
          });
        }
      }, [fetchGrade,auth,positionType]);

      let loader = null;
      if (gradeLoading || subjectLoading ||createChapterDataLoading ) {
        loader = <Loader open />;
      }
      function createChapter(){
       if(!grade){
           alert.warning('Selct Grade')
           return
       }else if(!subject ){
        alert.warning('Selct Subject')
        return
       }else if(!chapter){
        alert.warning('Enter Chapter')
        return
       }

       const formData = new FormData();
       formData.append('chapter_name', chapter);
       formData.append('grade', grade);
       formData.append('subject', subject);

       fetchAddChapter({
           url:urls.createChapterApi,
           method:'POST',
           body:formData,
           headers: {
            Authorization: `Bearer ${auth.personal_info.token}`,
          }
       });
      }
      const handleClose = () => {
        setOpen(false);
        setGrade('');
        setSubject('');
        setChapters('');
      };

const positionTypeList = [
  {
    value: "1",
    label: "Academic",
  },
  {
    value: "2",
    label: "Non Academic",
  },
  {
    value: "3",
    label: "IT Recruitment",
  },
  {
    value: "4",
    label: "Marketing and Communications",
  },
  {
    value: "5",
    label: "Sales and Business Development",
  },
];

return(
   <React.Fragment>
     <Fab title="Add Chapter" color="primary" aria-label="add"  size="small" className={classes.fab} onClick={()=>{setOpen(true)}}>
      <AddIcon fontSizeSmall></AddIcon>
      </Fab>
          <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{width:"80%",marginLeft:"10%"}}
        >
        <Fade in={open}>
        <Paper className={classes.paperr}>
          <Typography className="headings" variant='h4'><strong>Add Chapter</strong></Typography>
            <Divider className={classes.divider} />
              <Grid container spacing={2}>
              <Grid item md={4} xs={12} >
           
            <TextField
              select
              required
              fullWidth
              label="Position Type"
              margin="dense"
              value={positionType || ""}
              onChange={(e, id) => {
                setPositionType(e.target.value);
              }}
              variant="outlined"
            >
              {positionTypeList.map((option, index) => (
                <MenuItem key={index} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    label={positionType=="Academic" ?"Grade":"Level"}
                    // className={classes.textField}
                    fullWidth
                    className="textList"
                    margin="dense"
                    // required
                    onChange={e => setGrade(e.target.value)}
                    select
                    value={grade || ''}
                    variant="outlined"
                  >
                    {gradeData.map((data) => (
                        <MenuItem
                            value={data.id}
                            key={data.id}
                            name={data.grade_name}
                        >
                            {data.grade_name ? data.grade_name : ''}
                        </MenuItem>
                        ))}
                    </TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                    <TextField
                        label={positionType=="Academic" ?"Subject":"Topic"}
                        // className={classes.textField}
                        fullWidth
                        className="textList"
                        margin="dense"
                        required
                        onChange={e => setSubject(e.target.value)}
                        select
                        value={subject || ''}
                        variant="outlined"
                    >
                        {subjectData && subjectData && subjectData.map(data => (
                        <MenuItem
                            value={data.subject.id}
                            key={data.subject.id}
                            name={data.subject.subject_name}
                        >
                            {data.subject.subject_name ? data.subject.subject_name : ''}
                        </MenuItem>
                        ))}
                    </TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                    <TextField
                        label="Chapter"
                        // className={classes.textField}
                        fullWidth
                        className="textList"
                        margin="dense"
                        type='text'
                        required
                        onChange={e => setChapters(e.target.value)}
                        value={chapter || ''}
                        variant="outlined"
                    >
                    </TextField>
                    </Grid>
                    <Grid container align='center' justify="center">
                      <Grid item md={3} xs={12}>
                        <Button
                            // className={classes.applyButtonModal}
                            color="primary"
                            variant="contained"
                            fullWidth
                            size="large"
                            // style = {{marginLeft:'35%'}}
                            onClick={() => createChapter()}
                          >
                        Add
                      </Button>
                    </Grid> 
                    </Grid>
                 </Grid>
                <Divider className={classes.divider} />
             </Paper>
           </Fade> 
         </Modal>
    {loader}
</React.Fragment>
    )
}
CreateChapter.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
  };
export default withStyles(styles)(CreateChapter);