/* eslint-disable max-len */
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  Grid,
  TextField,
  withWidth,
  withStyles,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";

import useFetch from "../hoc/useFetch";
import styles from "./login.styles";
import urls from "../url";
import axios from "axios";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useAlert } from "../hoc/alert/alert";
import LoginForm from "./login-form";
import TabPanel from "../hoc/tab-panel/index";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import LoginOtpForm from "./login-otp-form";

const Login = ({ classes, width: brkPoint }) => {
  const emailRef = useRef();
  const passRef = useRef();
  const phoneRef = useRef();
  const mailRef = useRef();
  const otpRef = useRef();
  const theme = useTheme();
  // const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [loginSet, setLoginSet] = useState(true);
  const [loginEmailSet, setLoginEmailSet] = useState(true);
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const alert = useAlert();

  const {
    data: userData,
    // isLoading: contactSourceLoading,
    doFetch: fetchUserData,
  } = useFetch(null);

  // useEffect(() => {
  //   emailRef.current.value = ""
  //   if (userData) {
  //     localStorage.setItem("user", JSON.stringify(userData));
  //     window.location.reload();
  //   }
  // }, [userData]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginSet = () => {
    setLoginSet(!loginSet);
  };

  const handleLoginMailSet = () => {
    setLoginEmailSet(!loginEmailSet);
  };
  const tabStyle = {
    width: "63%",
    margin: "3% auto",
    borderRadius: "10px 10px 0 0",
  };

  const handleLoginUserSet = () => {
    setLoginSet(true);
    setLoginEmailSet(true);
    setPhoneNumber("");
    setEmail("");
    setOtp("");
    setIsOtpSend(false);
  };

  const getRenderBasedView = () => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        loginHandler();
      } else {
      }
    };

    const loginHandler = () => {
      const username = emailRef.current.value;
      const password = passRef.current.value;

      if (!username.length || !password.length) {
        return;
      }
      const body = {
        username,
        password,
      };

      fetchUserData({
        url: urls.login,
        method: "POST",
        body,
        headers: {
          "Content-Type": "application/json",
        },
      });
    };
    const loginHandlerOTP = () => {
      if (!phoneNumber && !email) {
        alert.warning("Please Enter a valid Input");
        return;
      }
      if (email) {
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(email)) {
          alert.warning("Please enter valid Email...");
          return;
        } else if (email.match(/[0-9]+/)) {
          alert.warning("Please enter valid Email Not a Number...");
          return;
        }
      }

      if (phoneNumber) {
        var IndNum = /^[0]?[56789]\d{9}$/;
        if (!IndNum.test(phoneNumber)) {
          alert.warning("Please enter valid mobile number...!");
          return;
        }
      }
      try {
        axios
          .get(
            `${urls.loginOtpSend}?mobile_number=${
              phoneNumber ? phoneNumber : ""
            }&email=${email ? email : ""}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
            if (response.status === 201) {
              setIsOtpSend(true);
              setOtp("");
            } else {
              alert.error("Invalid Mobile Number");
            }
          });
      } catch (e) {
        alert.error("Invalid");
      }
    };

    const verifyHandlerOtp = () => {
      if (!otp) {
        return;
      }
      axios
        .get(
          `${urls.loginOtpVerify}?mobile_number=${
            phoneNumber ? phoneNumber : ""
          }&email=${email ? email : ""}&otp=${otp ? otp : ""}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem("user", JSON.stringify(response.data));
            window.location.reload();
          } else {
            alert.error("invalid");
          }
        });
    };

    const viewOpenigHandler = (val) => {
      localStorage.setItem("loginValType", val);
      window.location.href = "/register";
    };

    const newOpening = (
      <div className={classes.paper} squared>
        <Typography variant="h5" className={classes.heading}>
          New To Orchids?
        </Typography>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <div className={classes.loginButton}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => viewOpenigHandler("viewOpening")}
              >
                View Opening
              </Button>
              <br />
              <br />
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => viewOpenigHandler("viewTransfer")}
              >
                Transfer
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );

    const loginPaper = (
      <div className={classes.paper} squared>
        <div style={{ textAlign: "center" }}>
          <LockOutlinedIcon
            style={{
              border: "1px solid",
              borderRadius: "50px",
              padding: "10px",
              color: "#3f51b5",
              fontSize: "55px",
            }}
          />
        </div>
        <Typography variant="h5" className={classes.heading}>
          Login
        </Typography>
        <TabPanel
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabValues={["Login", "Login Using OTP"]}
          fullWidth={true}
          style={tabStyle}
        />
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={tabValue}
        >
          <LoginForm setLoading={setLoading} />
          <LoginOtpForm setLoading={setLoading} />
        </SwipeableViews>
      </div>
    );

    function copyRightsFooter() {
      return (
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright  "}
          &copy; {new Date().getFullYear()}, K12 Techno Services Pvt. Ltd.
        </Typography>
      );
    }

    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.root}
        style={{ width: "100%" }}
      >
        <Grid item md={6} lg={6} sm={12}>
          {loginPaper}
        </Grid>
        {/* <Grid item md={5} lg={5}>
          {copyRightsFooter}
        </Grid> */}
      </Grid>
    );
  };

  return <React.Fragment>{getRenderBasedView()}</React.Fragment>;
};

Login.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  width: PropTypes.string.isRequired,
};

export default withStyles(styles)(withWidth()(Login));
