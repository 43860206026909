export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    applyButtonSection :{
     display : 'block',
     margin : '0 auto',
     padding : '20px'
    },
    applyButtonModal : {
        marginTop: '6px',
    },
    applyButton:{
    marginLeft:'5px',
    marginTop:'5px'
    },
    questionWrap: {
      marginTop: 10,
      // marginRight: 30,
      marginBottom: 50,
      // marginLeft: 30,
      padding: 0
    },
    questionContainer: {
      padding: 10
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    title : {
      float : 'left',
      fontSize : '18px',
    },
    paper : {
      padding:'20px',
    },
    textField: {
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    checkBox : {
        marginTop : '100px',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      padding: theme.spacing(1, 2, 1),
    },
    optionDiv: {
      flexBasis: '40%',
      margin: '2px 0',
      padding: '5px',
    }
    });
  