import React, { useState, useEffect } from 'react';
import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  Card,
  withStyles,
  TablePagination,
  IconButton,
  TableRow,
  TableCell,
  TableFooter,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import styles from './viewQuestion.style';
import urls from '../../../url';
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader';
import EditQuestion from '../editQuestion/editQuestion';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useAlert } from '../../../hoc/alert/alert';
import axios from 'axios';

function ViewQuestion({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [grade, setGrade] = useState('');
  const [subject, setSubject] = useState('');
  const [chapter, setChapters] = useState('\""');
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [propsData, setPropsData] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [pagination, setPagination] = useState(false)
  const [hRef, setHRef] = useState("");
  const [numOfQuestions, setNumOfQuestions] = useState(0);
  const [value, setValue] = useState("");
  const categoryList = [
    {
      value: "1",
      label: "Academic"
    },
    {
      value: "2",
      label: "Non Academic"
    },
    {
      value: "3",
      label: "IT Recruitment"
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    }
  ];

  const questionsNumber = [
    {
      id: 1,
      number: 10,
    },
    {
      id: 2,
      number: 20,
    },
    {
      id: 3,
      number: 30,
    },
    {
      id: 1,
      number: 40,
    }
  ]



  const alert = useAlert();

  const {
    data: gradeData,
    isLoading: gradeLoading,
    doFetch: fetchGrade,
  } = useFetch([], { suppressAlert: true });
  const {
    data: subjectData,
    isLoading: subjectLoading,
    doFetch: fetchSubject,
  } = useFetch([], { suppressAlert: true });
  const {
    data: chapterData,
    isLoading: chaptersLoading,
    doFetch: fetchChapter,
  } = useFetch([], { suppressAlert: true });
  const {
    data: getQuestionRes,
    isLoading: GetQuesLoading,
    doFetch: viewQuestions,
  } = useFetch("", { suppressAlert: true });
  const {
    data: DeleteData,
    isLoading: DeleteQuesLoading,
    doFetch: DelQuestions
  } = useFetch([], { suppressAlert: true });

  const deleteQuestion = () => {
    DelQuestions({
      url: `${urls.updateQuestionApi}${propsData.question_id}/retrieve_update_destroy_questions/`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json'
      },
    });
  }



  const GetQuestions = () => {
    // if (!grade || !subject) {
    //   alert.warning('Fill all the Fields')
    //   return
    // }
    setShowEdit(false)
    viewQuestions({
      url: `${urls.viewQuestionApi}?grade_id=${grade}&subject_id=${subject}&chapter_id=${chapter}&page_size=${rowsPerPage || 50}&page=${page + 1}`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
        'Content-Type': 'application/json'
      },
    });
  }

  function GetQuestionsCsv() {
    if (!grade || !subject) {
      alert.warning('Fill all the Fields')
      return
    }
    setShowEdit(false)
    axios.get(`${urls.downloadQuestionApi}?grade_id=${grade}&subject_id=${subject}&chapter_id=${chapter}`,
      {
        headers: {
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json'
        },

      });
  }
  function handleChangePage(event, newPage) {
    setPagination(true)
    setPage(newPage)
    { !rowsPerPage && setRowsPerPage(50) }
  }

  function handleChangeRowsPerPage(event) {
    setPagination(true)
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPagination(true)
    setPage(0)
  }

  function lastPageChange(lastPage) {
    setPagination(true)
    setPage(lastPage)
  };


  useEffect(() => {
    if (pagination || DeleteData) {
      GetQuestions()
    }
  }, [page, rowsPerPage, DeleteData])

  function deleteProperty(ind) {
    setIsDelete(true)
    setPage(0)
    setPropsData(getQuestionRes.result[ind])
  }
  useEffect(() => {
    if (propsData && isDelete) {
      deleteQuestion()
      setIsDelete(false)
    }
  }, [propsData])


  useEffect(() => {
    if (grade) {
      fetchSubject({
        url: `${urls.subjectMapping}?grade_id=${grade}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [grade])

  useEffect(() => {
    if (subject) {
      fetchChapter({
        url: `${urls.subjectMapping}?grade_id=${grade}&subject_id=${subject}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [subject])
  useEffect(() => {
    if (value) {
      fetchGrade({
        url: `${urls.newGrade}?position_type=${value}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [fetchGrade, auth,value]);
  useEffect(() => {
    setHRef([
      {
        csv: `${urls.downloadQuestionApi}?grade_id=${grade}&subject_id=${subject}&chapter_id=${chapter}&export_type=csv`,
      },
    ]);
  }, [setHRef, grade, subject]);


  function pageRefreshinEdit() {
    setPage(0)
    setShowEdit(false)
    GetQuestions()
  }
  function showEditProperty(ind) {
    setShowEdit(true)
    setPropsData(getQuestionRes.result[ind])
  }

  function EditQuestionComp() {
    return (
      <React.Fragment>
        <EditQuestion data={propsData} getQuestions={pageRefreshinEdit} />
      </React.Fragment>
    )
  }

  let loader = null;
  if (gradeLoading || subjectLoading || chaptersLoading || GetQuesLoading || DeleteQuesLoading) {
    loader = <Loader open />;
  }

  function handleDownloadWord() {
    if (grade && subject) {
      window.open(`${urls.downloadQuestionWordApi}?grade_id=${grade}&subject_id=${subject}&chapter_id=${chapter}&number_of_questions=${numOfQuestions}&export_type=docx`, '_blank')
    }
  }


  return (
    <React.Fragment>
      <Typography className="headings" variant='h4'><strong>Questions</strong></Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper} >
        <Grid container spacing={2} >
        <Grid item md={3} xs={12}>
            {/* <Typography>Category</Typography> */}
            <TextField
              required
              label="Position Type"
              select
              fullWidth
              margin='dense'
              value={value || ""}
              onChange={e => setValue(e.target.value)}
              variant='outlined'
            >
              {categoryList.map(option => (
                <MenuItem key={option.id} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label={value=="Academic" ?"Grade":"Level"}
              // className={classes.textField}
              fullWidth
              className="textList"
              margin="dense"
              required
              onChange={e => setGrade(e.target.value)}
              select
              value={grade || ''}
              variant="outlined"
            // disabled={showEdit === true} 
            >
              {gradeData && gradeData && gradeData.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.grade_name}
                >
                  {data.grade_name ? data.grade_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={3} xs={12}>
            {grade && <TextField
              label={value=="Academic" ?"Subject":"Topic"}
              // className={classes.textField}
              fullWidth
              className="textList"
              margin="dense"
              required
              onChange={e => setSubject(e.target.value)}
              select
              value={subject || ''}
              variant="outlined"
            // disabled={showEdit === true} 
            >
              {subjectData && subjectData && subjectData.map(data => (
                <MenuItem
                  value={data.subject.id}
                  key={data.subject.id}
                  name={data.subject.subject_name}
                >
                  {data.subject.subject_name ? data.subject.subject_name : ''}
                </MenuItem>
              ))}
            </TextField>}
          </Grid>
          <Grid item md={3} xs={12}>
            {subject && <TextField
              label="Chapter"
              // className={classes.textField}
              fullWidth
              className="textList"
              margin="dense"
              required
              onChange={e => setChapters(e.target.value)}
              select
              value={chapter || ''}
              variant="outlined"
            // disabled={showEdit === true} 
            >
              {chapterData && chapterData.length !== 0 ?
                chapterData.map(data => (
                  <MenuItem
                    value={data.chapter.id}
                    key={data.chapter.id}
                    name={data.chapter.chapter_name}
                  >
                    {data.chapter.chapter_name ? data.chapter.chapter_name : ''}
                  </MenuItem>
                )) :
                (<MenuItem
                  value='""'
                  key='0'
                  name='Chapters not Found'
                >
                  Chapters not Found
                </MenuItem>)
              }
            </TextField>}
          </Grid>

          <Grid item md={3} xs={12}>
            {subject && <Button
              className={classes.applyButton}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              onClick={() => GetQuestions(setPage(0))}
            // disabled={showEdit === true} 
            >
              Get Question
            </Button>}
          </Grid>
          <Grid item md={2} xs={12}>
            <Button
              style={{ marginTop: "33px" }}
              variant="contained"
              color="primary"
              href={hRef && hRef[0].csv}
              // onClick={downloadMainCsvFunc}
              disabled={!grade || !subject}
            >
              Download Excel
            </Button>
          </Grid>
          <Grid item md={2} xs={12}>
          {/* <Typography>Number Of Questions</Typography> */}

            {grade && subject ?<>
            <Typography>Number Of Questions</Typography>
            <TextField
              style={{ marginTop: "8px" }}
              // label="Number Of Questions"
              // className={classes.textField}
              fullWidth
              className="textList"
              margin="dense"
              required
              onChange={e => setNumOfQuestions(e.target.value)}
              select
              value={numOfQuestions || ''}
              variant="outlined"
            // disabled={showEdit === true} 
            >
              {questionsNumber && questionsNumber.map(data => (
                <MenuItem
                  value={data.number}
                  key={data.id}
                  name={data.number}
                >
                  {data.number ? data.number : ''}
                </MenuItem>
              ))}
            </TextField>
            </>:null}
          </Grid>
          <Grid item md={2} xs={12}>
            <Button
              style={{ marginTop: "33px" }}
              variant="contained"
              color="primary"
              onClick={handleDownloadWord}
              disabled={!grade || !subject || !numOfQuestions}
            >
              Download Word
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Divider className={classes.divider} />
      {getQuestionRes && getQuestionRes?.result?.length === 0 ? <h1 style={{ marginTop: '10%', textAlign: 'center', color: 'blue' }}>Records Not Found</h1> : ''}
      {!showEdit && getQuestionRes && getQuestionRes?.result?.map((item, index) => {
        return (<Paper elevation={4} className={classes.questionWrap} >
          <Card className={classes.questionContainer}>
            <div style={{ marginLeft: '80%' }}>
              <Button onClick={() => showEditProperty(index)}>Edit</Button>
              <Button onClick={() => deleteProperty(index)}>In Active</Button>
            </div>
            <h3>Question {index + 1}</h3>
            {ReactHtmlParser(item.question)}

            <h3>Options</h3>
            <table>
              <tr>
                <td><Button style={{ textTransform: 'inherit', position: 'left' }}> 1 : &nbsp;{ReactHtmlParser(item.options.map((item) => { return item })[0],)}</Button></td>
                <td><Button style={{ textTransform: 'inherit', position: 'right' }}> 2 :  &nbsp;{ReactHtmlParser(item.options.map((item) => { return item })[1],)}</Button></td>
              </tr>
              <tr>
                <td><Button style={{ textTransform: 'inherit', position: 'left' }}> 3 :  &nbsp;{ReactHtmlParser(item.options.map((item) => { return item })[2],)}</Button></td>
                <td><Button style={{ textTransform: 'inherit', position: 'right' }}> 4 :  &nbsp;{ReactHtmlParser(item.options.map((item) => { return item })[3],)}</Button></td>
              </tr>
            </table>
            <h3>Correct Ans</h3>
            <tr><td>
              {item.correct_ans.option1 ? <Button style={{ textTransform: 'initial' }}>1 :  &nbsp;{ReactHtmlParser(item.correct_ans.option1)}</Button> : ''}
              {item.correct_ans.option2 ? <Button style={{ textTransform: 'initial' }}>2 :  &nbsp;{ReactHtmlParser(item.correct_ans.option2)}</Button> : ''}
              {item.correct_ans.option3 ? <Button style={{ textTransform: 'initial' }}>3 :  &nbsp;{ReactHtmlParser(item.correct_ans.option3)}</Button> : ''}
              {item.correct_ans.option4 ? <Button style={{ textTransform: 'initial' }}>4 :  &nbsp;{ReactHtmlParser(item.correct_ans.option4)}</Button> : ''}
            </td></tr>
          </Card>
        </Paper>
        )
      })
      }
      {!showEdit && getQuestionRes && getQuestionRes && getQuestionRes.result.length !== 0 &&
        <TableFooter>
          <TableRow>
            <TablePagination
              colSpan={6}
              labelDisplayedRows={() => `Page ${page + 1} of ${+getQuestionRes.total_pages}`}
              rowsPerPageOptions={[50, 75, 100]}
              count={+getQuestionRes.count}
              rowsPerPage={rowsPerPage || 50}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'Rows per page' },
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <TableCell style={{ marginTop: '13px' }}>
              <IconButton
                onClick={firstPageChange}
                disabled={page === 0 || page === 1}
              >
                <FirstPageIcon />
              </IconButton>
              <IconButton
                onClick={() => lastPageChange(getQuestionRes.total_pages - 1)}
                disabled={page === (+getQuestionRes.total_pages - 1)}
              >
                <LastPageIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      }
      {showEdit ? EditQuestionComp() : ''}
      {loader}
    </React.Fragment>
  )
}
ViewQuestion.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ViewQuestion);
