import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import urls from "../../../url";
const interviewDetailList = [
  {
    label: "Today Interview",
  },
  {
    label: "Upcoming Interview",
  },
  {
    label: "Pending Interview",
  },
  {
    label: "Completed Interview",
  },
];

function InterviewList() {
  const [form] = useForm();
  const [interviewStatus, setInterviewStatus] = useState();
  const [interviews, setInterviews] = useState([]);
  const [count, setCount] = useState();
  const [total, setTotal] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [cancelModal, setCancelModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelId, setCancelId] = useState();
  useEffect(() => {
    if (form && form.getFieldValue("interview_status")) {
      let values = form.getFieldsValue();
      fetchInterviews({
        page: page,
        interview_status: values.interview_status,
        start_date: values?.date_range
          ? dayjs(values?.date_range[0]).format("YYYY-MM-DD")
          : null,
        end_date: values?.date_range
          ? dayjs(values?.date_range[1]).format("YYYY-MM-DD")
          : null,
        page_size: rowsPerPage,
      });
    }
  }, [page, rowsPerPage]);
  function handleFilter(values) {
    setPage(1);
    fetchInterviews({
      page: 1,
      interview_status: values.interview_status,
      start_date: values?.date_range
        ? dayjs(values?.date_range[0]).format("YYYY-MM-DD")
        : null,
      end_date: values?.date_range
        ? dayjs(values?.date_range[1]).format("YYYY-MM-DD")
        : null,
      page_size: rowsPerPage,
    });
  }
  function fetchInterviews(params = {}) {
    setLoading(true);
    axios
      .get(`${urls.interviewDetailApi}`, { params: { ...params } })
      .then((res) => {
        setInterviews(res.data?.results);
        setCount(res?.data?.count);
        setPage(res?.data?.current_page);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function cancelInterview(values) {
    axios
      .put(`${urls.cancelInterview}${cancelId}/`, values)
      .then((res) => {
        message.success("Cancelled Interview!");
        setCancelId(null);
        setCancelModal(false);
        form.resetFields(["comment"]);
        let values = form.getFieldsValue();
        fetchInterviews({
          page: page,
          interview_status: values.interview_status,
          start_date: values?.date_range
            ? dayjs(values?.date_range[0]).format("YYYY-MM-DD")
            : null,
          end_date: values?.date_range
            ? dayjs(values?.date_range[1]).format("YYYY-MM-DD")
            : null,
          page_size: rowsPerPage,
        });
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? "Something went wrong!");
      });
  }
  const columns = [
    {
      title: "Interview Date",
      key: "start_date",
      dataIndex: "start_date",
      render: (data) => <span>{dayjs(data)?.format("DD MMM, YYYY")}</span>,
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "applicant_data",
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: "Interviewer",
      key: "zoom",
      dataIndex: "zoom",
      render: (data) => (
        <span>
          {data?.interviewer_data?.interviewer_name}(
          {data?.interviewer_data?.interviewer_username})
        </span>
      ),
    },
    {
      title: "Recruiter",
      key: "recruiter",
      dataIndex: "scheduled_by",
      render: (data) => <span>{data}</span>,
    },
  ];
  if (interviewStatus !== "Completed Interview") {
    columns.push({
      title: "Action",
      key: "action",
      render: (data) => (
        <Tag
          onClick={() => {
            setCancelModal(true);
            setCancelId(data?.id);
          }}
          color={"error"}
        >
          Cancel Interview
        </Tag>
      ),
    });
  }
  return (
    <Card>
      <Row justify="space-between">
        <Col span={18}>
          <Typography className="page_title">Interview List</Typography>
        </Col>
      </Row>
      <Form
        id="filterForm"
        form={form}
        onFinish={handleFilter}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
          >
            <Form.Item
              name="interview_status"
              label="Interview Status"
              rules={[
                {
                  required: true,
                  message: "This is a required field",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, options) => {
                  return (options?.children ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                placeholder="Select Designation"
                allowClear
                onChange={(e) => {
                  setInterviewStatus(e);
                }}
                style={{ width: "100%" }}
              >
                {interviewDetailList?.map((each) => (
                  <Select.Option key={each?.label} value={each?.label}>
                    {each?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!interviewStatus?.includes("Today") && (
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="date_range" label="Date Range">
                <DatePicker.RangePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          )}
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
          >
            <Form.Item label=" ">
              <Button form="filterForm" htmlType="submit" type="primary">
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columns}
        dataSource={interviews}
        pagination={{
          current: page,
          total: count,
          pageSize: rowsPerPage,
          onChange: (current, pageSize) => {
            setPage(current);
            setRowsPerPage(pageSize);
          },
          pageSizeOptions: [25, 50, 75, 100],
        }}
        loading={loading}
      />
      <Modal
        open={cancelModal}
        title="Cancel Interview"
        footer={[
          <Button
            onClick={() => {
              setCancelModal(false);
              form.resetFields(["comment"]);
            }}
          >
            Cancel
          </Button>,
          <Button type="primary" htmlType="submit" form="cancelForm">
            Submit
          </Button>,
        ]}
      >
        <Form
          id="cancelForm"
          form={form}
          onFinish={cancelInterview}
          layout="vertical"
        >
          <Form.Item
            name={"comment"}
            label="Reason"
            rules={[
              {
                required: true,
                message: "This is a required field",
              },
            ]}
          >
            <TextArea placeholder="Enter Reason" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
}

export default InterviewList;
