import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableFooter,
  IconButton,
  Select,
  Box,
  Card,
  CardContent,
  Paper,
  TextField,
  MenuItem,
  InputBase,
  TableContainer
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import Modal from "../../../hoc/modal/modal";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import styles from "./applicantProfile.style";
import Loader from "../../../hoc/loader";
import Profile from "../../applicant/profile/profile";
import { useAlert } from "../../../hoc/alert/alert";
import DownloadLink from "react-download-link";
import MediaQuery from "react-responsive";


// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     'aria-controls': `scrollable-auto-tabpanel-${index}`,
//   };
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const ApplicantProfile = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [Id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [applicantId, setApplicantId] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [Role, setRole] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [viewOpenning, setViewOpennings] = useState("");
  const [urlHit, setUrlHit] = useState(1);
  const [applicentName, setApplicentName] = useState('');
  
   

  const alert = useAlert();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList
  } = useFetch("", { suppressAlert: true });

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings
  } = useFetch([], { suppressAlert: true });

  const {
    data: ProcessingData,
    isLoading: processingLoading,
    doFetch: fetchProcessing
  } = useFetch([], { suppressAlert: true });

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant
  } = useFetch("", { suppressAlert: true });

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail
  } = useFetch("", { suppressAlert: true });


  const {
    data: assignOthersPosition,
    isLoading: assignOthersPositionLoading,
    doFetch: fetchOthersPosition
  } = useFetch("", { suppressAlert: true });
  
 

  function functionToFetchApplicents() {
    setOpen(false);
    fetchApplicant({
      url: `${
        urls.retrieveApplicant
      }?position_id=${viewOpenning}&position_type=${positionType}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=${page +
        1}&page_size=${rowsPerPage || 50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }

  useEffect(() => {
    if ((page || rowsPerPage) && positionType) {
      fetchApplicant({
        url: `${
          urls.retrieveApplicant
        }?position_id=${viewOpenning}&position_type=${positionType}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=${page +
          1}&page_size=${rowsPerPage || 50}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  }, [ProcessingData, page, fetchApplicant, rowsPerPage, name]);

  useEffect(() => {
    if (assignOthersPosition && urlHit > 0) {
      functionToFetchApplicents();
    }
  }, [assignOthersPosition, urlHit]);

  function functionToGetApplicents() {
    functionToFetchApplicents();
  }

  function handleProcess(ID, hr, ide) {
    fetchProcessing({
      url: `${urls.startProcess}?id=${ID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }
  useEffect(()=>{
    if(applicentName){
      fetchApplicant({
        url: `${urls.searchApplicantRecruiter}?position_id=${viewOpenning}&position_type=${positionType}&role_id=${RoleId}&page=${page +
          1}&page_size=${rowsPerPage || 50}&search=${applicentName}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  },[applicentName])  
  const fetchApplicantData = (e) => {

    setApplicentName(e.target.value)
    
    }; 
  // const fetchSearch = e => {
  //   setPage(0);
  //   setRowsPerPage(5);
  //   if (e.keyCode === 13) {
  //     const data = document.getElementById("search-bar").value;
  //     setApplicentName(document.getElementById("search-bar").value);
  //     functionSearch(data);
  //   }
  // };

  function functionSearch(data) {
    fetchApplicant({
      url: `${urls.searchApplicantRecruiter}?page=${page +
        1}&page_size=${rowsPerPage || 50}&search=${data}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }

  useEffect(() => {
    if (applicentName.length > 4) {
      functionSearch(applicentName)
    }
  }, [applicentName])

  function updateHandler(ID, hr, ide) {
    setRole("");
    // setPosition('')
    setApplicantId(ide);
    const firstName = name.personal_info.first_name;
    setEdit(firstName === hr ? false : true);
    setOpen(true);
    fetchApplicantDetail({
      url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }

  const positionTypeList = [
    {
      value: "1",
      label: "Academic"
    },
    {
      value: "2",
      label: "Non Academic"
    },
    {
      value: "3",
      label: "IT Recruitment"
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    }
  ];

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  }, [positionType]);

  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  }, [RoleId, positionType]);

  const handleStartDateChange = val => {
    setStartDate(val);
  };
  const handleEndDateChange = val => {
    setEndDate(val);
  };

  const onEditClick = () => {
    setShowEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowEdit(false);
  };
  let loader = null;
  if (
    processingLoading ||
    applicantsListLoading ||
    gettingRoleList ||
    gettingViewOpennings ||
    applicantDetailLoading ||
    assignOthersPositionLoading
  ) {
    loader = <Loader open />;
  }

  function assignJobFunction() {
    if (!Role) {
      alert.warning("select the Fields");
      return;
    }
    const UploadData = {
      role_id: Role,
      applicant_id:
        applicantDetail &&
        applicantDetail.applicant &&
        applicantDetail.applicant.id
    };
    fetchOthersPosition({
      url: urls.RecruterUpdateOthersRoleApi,
      method: "PUT",
      body: UploadData,
      headers: {
        Authorization: `Bearer ${name.personal_info.token}`,
        "Content-Type": "application/json"
      }
    });
  }

  function profileTable() {
    let applicantTable = null;
    if (
      applicantsList &&
      applicantsList.result &&
      applicantsList.result.length === 0
    ) {
      applicantTable = (
        <React.Fragment>
          <Typography
            style={{ textAlign: "center", color: "blue", marginTop: "100px" }}
            variant='h4'
          >
            Records Not Found
          </Typography>
        </React.Fragment>
      );
    }
    if (
      applicantsList &&
      applicantsList.result &&
      applicantsList.result.length
    ) {
      applicantTable = (
        <React.Fragment>
          
          <Paper style={{ marginTop: "10px" }}>
          <TableContainer className="role">
            <Table>
              
              <TableHead>
                <TableRow>
                  <TableCell align='left'><strog>S.No</strog></TableCell>
                  <TableCell align='left'><strong>Name</strong></TableCell>
                  <TableCell align='left'><strong>Contact No</strong></TableCell>
                  <TableCell align='left'><strong>Grade</strong></TableCell>
                  <TableCell align='left'><strong>Branch Interested</strong></TableCell>
                  <TableCell align='left'><strong>QP Assign</strong></TableCell>
                  <TableCell align='left'><strong>Cur. Location</strong></TableCell>
                  <TableCell align='left'><strong>Hr Name </strong></TableCell>
                  {/* <TableCell align='left'><strong>Hr Comment</strong></TableCell> */}
                  <TableCell align='left'><strong>Action</strong></TableCell>
                  <TableCell align='left'><strong>Process</strong></TableCell>
                </TableRow>
              </TableHead>
              
              <TableBody>
                {applicantsList.result.map((details, i) => (

                  <TableRow key={details.id}>
                    
                    <TableCell align='left'>{i + 1}</TableCell>
                    <TableCell align='left'>{details.name}</TableCell>
                    <TableCell align='left'>{details.contact_no}</TableCell>
                    <TableCell align='left'>{details.grade}</TableCell>
                    <TableCell align='left'>{details.branch}</TableCell>
                    <TableCell align='left'>
                      <div>
                        {details.QP_Assign ? "Assigned" : "Not Assigned"}
                      </div>
                      <div>
                        {details.QP_Assign
                          ? "Access Code:- " + details.access_code
                          : " "}
                      </div>
                    </TableCell>
                    <TableCell align='left'>{details.Location}</TableCell>
                    <TableCell align='left'>
                      {details.Hr_Assign ? details.Hr_name : "New"}
                    </TableCell>
                    {/* <TableCell align='left'>
                      {details.Hr_Assign ? details.comment_profile : "New"}
                    </TableCell> */}
                    <TableCell align='left'>
                      <Button
                        className={classes.addButton}
                        // color="primary"
                        size='small'
                        variant='contained'
                        onClick={() =>
                          updateHandler(
                            details.id,
                            details.Hr_name,
                            details.applicant_id
                          )
                        }
                      >
                        Details
                      </Button>
                    </TableCell>
                    <TableCell align='left'>
                      <Button
                        className={classes.addButton}
                        // color="primary"
                        size='small'
                        variant='contained'
                        disabled={details.process_start}
                        onClick={() =>
                          handleProcess(
                            details.id,
                            details.Hr_name,
                            details.applicant_id
                          )
                        }
                      >
                        Process
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ marginTop: "15px" }}
                  colSpan={5}
                  rowsPerPageOptions={[50,75, 100]}
                  component="div"
                  count={applicantsList && applicantsList.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "previous page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page"
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      lastPageChange(applicantsList.total_pages - 1)
                    }
                    disabled={page === +applicantsList.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
            </TableContainer>
           
            {/* : null} */}
          </Paper> 
        </React.Fragment>
      );
    }
    return applicantTable;
  }

  let modal = null;
  if (applicantDetail) {
    modal = (
      <Modal click={handleClose} large style={{ padding: "15px" }} open={open} >
        <React.Fragment>
          
          
          {!showEdit && (
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
            <Typography className="headings"  variant='h4'><strong>Details</strong></Typography>
            <Divider className={classes.divider} />
            </Grid>
              <Grid item md={6} xs={12} className={classes.gridOne}>
                <Typography className="textDisplay" variant='h5'>
                  <strong> Name :</strong>{" "}
                  {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.first_name}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} className={classes.gridOne}>
                <Typography className="textDisplay"  variant='h5'>
                 <strong>PhoneNo :{" "}</strong> 
                  {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.contact_no}
                </Typography>
              </Grid>
              {applicantDetail &&
                applicantDetail.applicant &&
                applicantDetail.applicant.applicant_role &&
                applicantDetail.applicant.applicant_role.applicant_role_name ===
                "other" && (
                  <Grid item md={12} xs={12} container spacing={2}>
                    <Grid item md={4} xs={12}>
                      <TextField
                        label='Role'
                        className={classes.textField}
                        margin='dense'
                        required
                        onChange={e => setRole(e.target.value)}
                        select
                        value={Role || ""}
                        variant='outlined'
                      >
                        {roleList &&
                          roleList.map(data => (
                            <MenuItem
                              value={data.id}
                              key={data.id}
                              name={data.applicant_role_name}
                            >
                              {data.applicant_role_name
                                ? data.applicant_role_name
                                : ""}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Button
                        color='primary'
                        className={classes.button}
                        size='large'
                        margin='dense'
                        variant='contained'
                        onClick={() => assignJobFunction()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                )}
              <Grid item md={6} xs={12} className={classes.gridOne}>
                <div style={{ display: "flex" }}>
                <Typography className="textDisplay"  variant="h5">
                <strong>Email :</strong>{" "}
                {(applicantDetail &&
                      applicantDetail.applicant &&
                      applicantDetail.applicant.user &&
                      applicantDetail.applicant.user.email) ||
                      ""}
              </Typography>
                  {/* <b style={{ display: "flex", marginTop: "7px" }}>
                    Email:{" "}
                    {(applicantDetail &&
                      applicantDetail.applicant &&
                      applicantDetail.applicant.user &&
                      applicantDetail.applicant.user.email) ||
                      ""}
                  </b> */}
                </div>
                <div style={{ display: "flex" }}>
                <Typography className="textDisplay"  variant="h5">
                <strong>Grade :</strong>{" "}
                {(applicantDetail &&
                      applicantDetail.applicant &&
                      applicantDetail.applicant.relevant_fk &&
                      applicantDetail.applicant.relevant_fk.grade &&
                      applicantDetail.applicant.relevant_fk.grade.grade_name) ||
                      ""}
              </Typography>
                  {/* <b style={{ display: "flex", marginTop: "7px" }}>
                    Grade:{" "}
                    {(applicantDetail &&
                      applicantDetail.applicant &&
                      applicantDetail.applicant.relevant_fk &&
                      applicantDetail.applicant.relevant_fk.grade &&
                      applicantDetail.applicant.relevant_fk.grade.grade_name) ||
                      ""}
                  </b> */}
                </div>
                <div style={{ display: "flex" }}>
                <Typography className="textDisplay"  variant="h5">
                <strong>Role :</strong>{" "}
                {(applicantDetail &&
                      applicantDetail.applicant &&
                      applicantDetail.applicant.applicant_role &&
                      applicantDetail.applicant.applicant_role
                        .applicant_role_name) ||
                      ""}
              </Typography>
                  {/* <b style={{ display: "flex", marginTop: "7px" }}>
                    Role:{" "}
                    {(applicantDetail &&
                      applicantDetail.applicant &&
                      applicantDetail.applicant.applicant_role &&
                      applicantDetail.applicant.applicant_role
                        .applicant_role_name) ||
                      ""}
                  </b> */}
                </div>
                <div style={{ display: "flex" }}>
                <Typography className="textDisplay"  variant="h5">
                <strong>Position Applied :</strong>{" "}
                {(applicantDetail &&
                      applicantDetail.applicant.position_apply &&
                      applicantDetail.applicant.position_apply.title) ||
                      ""}
              </Typography>
                  {/* <b style={{ display: "flex", marginTop: "7px" }}>
                    Position Applied:{" "}
                    {(applicantDetail &&
                      applicantDetail.applicant.position_apply &&
                      applicantDetail.applicant.position_apply.title) ||
                      ""}
                  </b> */}
                </div>
                <div style={{ display: "flex" }}>
                <Typography className="textDisplay"  variant="h5">
                <strong> Branch Interested :</strong>{" "}
                {(applicantDetail.applicant &&
                      applicantDetail.applicant.relevant_fk &&
                      applicantDetail.applicant.relevant_fk.branch &&
                      applicantDetail.applicant.relevant_fk.branch
                        .branch_name) ||
                      ""}
              </Typography>
                  {/* <b style={{ display: "flex", marginTop: "7px" }}>
                    Branch Interested:{" "}
                    {(applicantDetail.applicant &&
                      applicantDetail.applicant.relevant_fk &&
                      applicantDetail.applicant.relevant_fk.branch &&
                      applicantDetail.applicant.relevant_fk.branch
                        .branch_name) ||
                      ""}
                  </b> */}
                </div>
                <div style={{ display: "flex" }}>
                <Typography className="textDisplay"  variant="h5">
                <strong>  Application Date:</strong>{" "}
                {applicantDetail.applicant.register_date || ""}
              </Typography>
                  {/* <b style={{ display: "flex", marginTop: "7px" }}>
                    Application Date:{" "}
                    {applicantDetail.applicant.register_date || ""}
                  </b> */}
                </div>
              </Grid>
              {/* <Grid item md={3} className={classes.item} /> */}
              <Grid item md={4} xs={12} className={classes.item}>
                <Button
                  className={classes.addButton}
                  color='primary'
                  size='small'
                  // disabled={edit}
                  variant='contained'
                  onClick={onEditClick}
                >
                  Edit Applicant Details?
                </Button>
              </Grid>
              {/* <Grid item md={3} className={classes.item}/> */}
              <Grid item md={4} xs={6} className={classes.item}>
                
                      <Typography className="textDisplay" variant="h5"> <strong>Resume</strong></Typography>
                      </Grid>
                      <Grid item md={6} xs={6} className={classes.item}>
                        {applicantDetail.applicant &&
                          applicantDetail.applicant.resume ? (
                            <a
                              target='_blank'
                              href={applicantDetail.applicant.resume || ""}
                              onclick="window.open(this.href); return false;"
                            >
                              {" "}
                              <Typography className="textDisplay" variant="h5"> View Resume</Typography>
                            </a>
                          ) : (
                            "Resume Not Uploaded"
                          )}
              </Grid>
              
              <Grid item md={4} xs={12} className={classes.gridOne}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant='h6' component='h2'>
                      1.MCQ{" "}
                      {applicantDetail && applicantDetail.mcq_test_completed ? (
                        <a>
                          {" "}
                          Completed{" "}
                          <Typography variant='h6'>
                            Marks=
                            {(applicantDetail &&
                              applicantDetail.mcq_test_completed &&
                              applicantDetail.mcq_score &&
                              applicantDetail.mcq_score.mark_scored) ||
                              "Not Uploaded"}
                          </Typography>
                        </a>
                      ) : (
                          "Pending"
                        )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridOne}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant='h6' component='h2'>
                      2.Written Test{" "}
                      {applicantDetail &&
                        applicantDetail.written_test_completed ? (
                          <a>
                            {" "}
                          Completed{" "}
                            <Typography variant='h6'>
                              Marks=
                            {(applicantDetail &&
                                applicantDetail.written_test_completed &&
                                applicantDetail.written_score &&
                                applicantDetail.written_score.mark_scored) ||
                                "Not Uploaded"}
                            </Typography>
                          </a>
                        ) : (
                          "Pending"
                        )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={4} xs={12} className={classes.gridOne}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant='h6' component='h2'>
                      3.Spoken Test{" "}
                      {applicantDetail &&
                        applicantDetail.spoken_test_completed ? (
                          <a>
                            {" "}
                          Completed{" "}
                            <Typography variant='h6'>
                              Marks=
                            {(applicantDetail &&
                                applicantDetail.spoken_test_completed &&
                                applicantDetail.spoken_score &&
                                applicantDetail.spoken_score.mark_scored) ||
                                "Not Uploaded"}
                            </Typography>
                          </a>
                        ) : (
                          "Pending"
                        )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              {applicantDetail &&
                applicantDetail.applicant_f2f &&
                applicantDetail.applicant_f2f.map((item, index) => (
                  <Grid
                    item
                    md={12}
                    xs={12}
                    className={classes.gridOnee}
                    key={index}
                  >
                    <Card className={classes.card}>
                      <CardContent>
                        <Typography variant='h6' component='h2'>
                          {4 + index}: {item.round}
                        </Typography>
                        <Typography variant='h6' component='h2'>
                          Marks : {item.mark_scored || "pending"}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          )}
          {showEdit && (
            <Profile applicantId={applicantId} recallApi={setUrlHit} />
          )}
        </React.Fragment>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item md={5} xs={12}>
          <Typography className ="headings" variant='h4'><strong>Applicant Profile</strong></Typography>
        </Grid>
        {/* <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="I'm Looking For..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              id='search-bar'
              onChange={fetchApplicantData} 
            />
          </div>
        </Grid> */}
          <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={fetchApplicantData}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />

      
      <div className={classes.root}>
        <Paper className={classes.paperrrrr}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography>
                {" "}
                Select Position Type &nbsp; <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                // required
                fullWidth
                className="textList"
                margin='dense'
                value={positionType || ""}
                onChange={e => setPositionType(e.target.value)}
                variant='outlined'
              >
                {positionTypeList.map((option, index) => (
                  <MenuItem key={index} value={option.label} name={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>
                {" "}
                Select Role &nbsp; <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                // required
                fullWidth
                className="textList"
                margin='dense'
                value={RoleId || ""}
                onChange={e => setRoleId(e.target.value)}
                variant='outlined'
              >
                {roleList &&
                  roleList.length !== 0 &&
                  roleList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.applicant_role_name}
                    >
                      {option.applicant_role_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>Select Opening</Typography>
              <Select
                // required
                fullWidth
                className="textList"
                margin='dense'
                value={viewOpenning || ""}
                onChange={e => setViewOpennings(e.target.value)}
                variant='outlined'
              >
                {viewOpenningsData &&
                  viewOpenningsData.length !== 0 &&
                  viewOpenningsData.map((option, index) => (
                    <MenuItem key={index} value={option.id} name={option.title}>
                      {option.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>Start Date</Typography>
              <TextField
                // className={classes.textField}
                margin='dense'
                required
                fullWidth
                className="textList"
                onChange={e => handleStartDateChange(e.target.value)}
                type='date'
                value={startDate || ""}
                variant='outlined'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>End Date</Typography>
              <TextField
                // className={classes.textField}
                className="textList"
                margin='dense'
                required
                fullWidth
                onChange={e => handleEndDateChange(e.target.value)}
                type='date'
                value={endDate || ""}
                variant='outlined'
              />
            </Grid>
            <Grid item md={2} xs={12} style={{ marginTop: "33px" }}>
              <Button
                variant='contained'
                color='primary'
                fullWidth
                onClick={functionToGetApplicents}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
       

       
        <Divider className={classes.divider} />
        {profileTable()}
      </div>
      
      {modal}
      {loader}
    </React.Fragment>
  );
};

ApplicantProfile.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};

export default withStyles(styles)(ApplicantProfile);
