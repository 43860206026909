import React, { useState, useEffect } from "react";
import urls from "../../../url";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
import {
  Card,
  Col,
  Row,
  DatePicker,
  Typography,
  message,
  Progress,
  Space,
  Form,
  Select,
  Button,
  Table,
} from "antd";
import { DownloadOutlined, BarChartOutlined } from "@ant-design/icons";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const { RangePicker } = DatePicker;
const columns = [
  { title: "Recruiter Name", key: "recruiter_name" },
  { title: "Position Created", key: "position_created" },
  { title: "Applicant Registered", key: "applicant_registered" },
  { title: "Shortlisted", key: "shortlisted" },
  { title: "Interview Scheduled", key: "interview_scheduled" },
  { title: "Offer Letter Released", key: "letter_released" },
];
const cardHeadStyle = {
  padding: "0.5rem 0.75rem 0 0.75rem",
  borderBottom: "none",
  minHeight: "auto",
};
const TeamPerformance = () => {
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const todayDate = moment().format("YYYY-MM-DD");
  const [recruiterList, setRecruiterList] = useState([]);
  const [recruiterReports, setRecruiterReports] = useState(null);
  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [fetchingReports, setFetchingReports] = useState(false);
  const [positionType, setPositionType] = useState();
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [fetchingTeamPerformance, setFetchingTeamPerformance] = useState(false);
  const [performanceData, setPerformanceData] = useState({});
  useEffect(() => {
    fetchRecruiterList();
    fetchPositionType();
    fetchTeamPerformanceData();
  }, []);
  const fetchRecruiterList = () => {
    axios
      .get(`${urls.retrieveRecuriterPanel}`, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          setRecruiterList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {});
  };
  const fetchTeamPerformanceData = () => {
    setFetchingTeamPerformance(true);
    const params = {
      position: selectedPosition == "all" ? null : selectedPosition,
      employee: selectedRecruiter?.toString(),
      start_date: startDate ?? todayDate,
      end_date: endDate ?? todayDate,
    };
    axios
      .get(`${urls.tempPerformance}`, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
        params: { ...params },
      })
      .then((response) => {
        if (response?.status === 200) {
          setPerformanceData(response?.data);
          message.success("Data fetched successfully!");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setFetchingTeamPerformance(false);
      });
  };
  const fetchRecruiterReports = (id = selectedRecruiter) => {
    setFetchingReports(true);
    const params = {
      recruiter: id?.toString(),
      created_at_gte: startDate ?? todayDate,
      created_at_lte: endDate ?? todayDate,
    };
    axios
      .get(`${urls.recruiterReports}`, {
        headers: {
          //   "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
        params: { ...params },
      })
      .then((response) => {
        if (response?.status === 200) {
          // Csv file response
          message.success("Download Started!");
          const blob = new Blob([response?.data], { type: "text/csv" });
          const url = URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = `Recruiters_report.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
          setRecruiterReports(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setFetchingReports(false);
      });
  };
  const fetchPositionType = () => {
    axios
      .get(urls?.positionTypes, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.personal_info.token}`,
        },
      })
      .then((res) => {
        setPositionType(res.data);
      })
      .catch((error) => {
        // Handle error here
        console.error("Error fetching position types:", error);
      });
  };
  return (
    <>
      <div className="page_title" style={{}}>
        <strong>Team Performance</strong>
      </div>
      <Card>
        <Row gutter={[16, 16]}>
          <Col md={6} sm={12} xs={24}>
            <Select
              allowClear
              placeholder="Select Position"
              style={{ width: "100%" }}
              showSearch
              value={selectedPosition}
              defaultValue={selectedPosition}
              filterOption={(input, options) => {
                return (options?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              onChange={(e) => {
                setSelectedPosition(e);
              }}
            >
              <Select.Option key={"all"} value={"all"}>
                All
              </Select.Option>
              {positionType &&
                positionType.length !== 0 &&
                positionType.map((option, index) => {
                  return (
                    <Select.Option key={option?.id} value={option?.id}>
                      {option?.position_type ?? ""}
                    </Select.Option>
                  );
                })}
            </Select>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select Recruiter"
              style={{ width: "100%" }}
              showSearch
              defaultValue={selectedRecruiter}
              value={selectedRecruiter}
              filterOption={(input, options) => {
                return (options?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              onChange={(arr) => {
                if (arr?.includes("all")) {
                  setSelectedRecruiter(recruiterList?.map((each) => each?.id));
                } else if (arr?.includes("deselect_all")) {
                  setSelectedRecruiter([]);
                } else setSelectedRecruiter(arr);
              }}
              maxTagCount="responsive"
            >
              {selectedRecruiter?.length === recruiterList.length ? (
                <Select.Option key={"deselect_all"} value={"deselect_all"}>
                  Deselect All
                </Select.Option>
              ) : (
                <Select.Option key={"all"} value={"all"}>
                  All
                </Select.Option>
              )}
              {recruiterList &&
                recruiterList.length !== 0 &&
                recruiterList.map((option, index) => {
                  return (
                    <Select.Option key={option?.id} value={option?.id}>
                      {option?.first_name ? option?.first_name : ""}
                    </Select.Option>
                  );
                })}
            </Select>
          </Col>
          <Col md={6} sm={12} xs={24}>
            <RangePicker
              defaultValue={[dayjs(startDate), dayjs(endDate)]}
              value={[dayjs(startDate), dayjs(endDate)]}
              onChange={(e) => {
                if (e) {
                  setStartDate(e[0]?.format("YYYY-MM-DD"));
                  setEndDate(e[1]?.format("YYYY-MM-DD"));
                }
              }}
              style={{ width: "100%" }}
            />
          </Col>
          <Col>
            <Button
              style={{ width: "100%" }}
              onClick={() => fetchTeamPerformanceData()}
              type="primary"
              loading={fetchingTeamPerformance}
            >
              Filter
            </Button>
          </Col>
          <Col>
            <Button
              icon={<DownloadOutlined />}
              style={{ width: "100%" }}
              onClick={() => fetchRecruiterReports()}
              type="primary"
              disabled={selectedRecruiter?.length !== 0 ? false : true}
              loading={fetchingReports}
            >
              Download
            </Button>
          </Col>
        </Row>
      </Card>
      <Row gutter={[16, 16]} style={{ marginBlock: "16px" }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
        >
          <Card
            title={<div>Job Counts</div>}
            headStyle={cardHeadStyle}
            bodyStyle={{ display: "flex", justifyContent: "center" }}
            style={{
              borderWidth: "2px",
            }}
          >
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "pie",
                  height: 290,
                },
                title: {
                  text: null,
                  align: "left",
                },
                tooltip: {
                  headerFormat: "",
                  pointFormat:
                    '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                    "Count: <b>{point.y}</b><br/>",
                },
                plotOptions: {
                  pie: {
                    allowPointSelect: true,
                    borderWidth: 2,
                    cursor: "pointer",
                    dataLabels: {
                      enabled: true,
                      format: "<b>{point.name}</b><br>{point.y}",
                      distance: 20,
                    },
                  },
                },
                credits: {
                  enabled: false,
                },

                series: [
                  {
                    minPointSize: 10,
                    innerSize: "40%",

                    zMin: 0,
                    name: "countries",
                    borderRadius: 5,
                    data: [
                      {
                        name: "Active Jobs",
                        y:
                          performanceData?.total_jobs_posted_count -
                          performanceData?.job_closed_count,
                      },
                      {
                        name: "Closed Jobs",
                        y: performanceData?.job_closed_count,
                      },
                    ],
                    colors: ["#1E4DB6", "#E39E33"],
                  },
                ],
              }}
            />
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
        >
          <Row gutter={[8, 8]}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
              <Card
                title={<div>Avg. time to hire</div>}
                headStyle={cardHeadStyle}
                bodyStyle={{ textAlign: "center" }}
              >
                <Progress
                  type="circle"
                  percent={performanceData?.avg_hire_rate == 0 ? 0 : 100}
                  size={100}
                  strokeColor={"#52c41a"}
                  format={(percent) => {
                    return (
                      <div
                        style={{
                          color:
                            performanceData?.avg_hire_rate == 0
                              ? ""
                              : "#52c41a",
                        }}
                      >
                        <div>{performanceData?.avg_hire_rate}</div>
                        <div style={{ fontSize: "12px" }}>Days</div>
                      </div>
                    );
                  }}
                />
              </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
              <Card
                title={<div>Avg. time to reject</div>}
                headStyle={cardHeadStyle}
                bodyStyle={{ textAlign: "center" }}
              >
                <Progress
                  size={100}
                  type="circle"
                  percent={performanceData?.avg_reject_rate == 0 ? 0 : 100}
                  strokeColor={"#f5222d"}
                  format={(percent) => {
                    return (
                      <div
                        style={{
                          color:
                            performanceData?.avg_reject_rate == 0
                              ? ""
                              : "#f5222d",
                        }}
                      >
                        <div>{performanceData?.avg_reject_rate}</div>
                        <div style={{ fontSize: "12px" }}>Days</div>
                      </div>
                    );
                  }}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={[8, 8]} style={{ marginTop: "8px" }}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
              <Card
                title={<div>Candidates Hired</div>}
                headStyle={cardHeadStyle}
                bodyStyle={{ textAlign: "center" }}
              >
                <Progress
                  type="circle"
                  percent={
                    performanceData?.candidate_hired_count == 0 ? 0 : 100
                  }
                  strokeColor={"#2f54eb"}
                  size={100}
                  format={(percent) => {
                    return (
                      <div
                        style={{
                          color:
                            performanceData?.candidate_hired_count == 0
                              ? ""
                              : "#2f54eb",
                        }}
                      >
                        {performanceData?.candidate_hired_count}
                      </div>
                    );
                  }}
                />
              </Card>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }}>
              <Card
                title={<div>Candidates Rejected</div>}
                headStyle={cardHeadStyle}
                bodyStyle={{ textAlign: "center" }}
              >
                <Progress
                  size={100}
                  type="circle"
                  strokeColor={"#722ed1"}
                  percent={
                    performanceData?.candidate_rejected_count == 0 ? 0 : 100
                  }
                  format={(percent) => {
                    return (
                      <div
                        style={{
                          color:
                            performanceData?.candidate_rejected_count == 0
                              ? ""
                              : "#722ed1",
                        }}
                      >
                        {performanceData?.candidate_rejected_count}
                      </div>
                    );
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row>
        <Card title={"Recruiter Performance"} style={{ width: "100%" }}>
          <Table columns={columns} scroll={{ x: "max-content" }} />
        </Card>
      </Row> */}
    </>
  );
};

export default TeamPerformance;
