export default theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
  },
  applyButtonSection :{
    display: 'block',
    // margin: '0 auto',
    marginTop: '-4px',
    padding: '20px',
  },
  helpeText :{
    color: 'red',
    fontSize: 'small',
    textAlign: 'left'
  },
  applyButton : {
    margin: '0px 0px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  title : {
    float : 'left',
    fontSize : '18px',
  },
  paper : {
    padding:'20px',
  },
  textField: {
    width: '100%',
  },
  });
