import React, { useState } from 'react'
import Typography from "@material-ui/core/Typography";

const ReviewerDashBoard = () => {
  const [profile] = useState(JSON.parse(localStorage.getItem('user')));

  return (
    <div>
      <Typography>
        Welcome to <strong style={{ fontSize: '40px', color: 'rgb(229 167 78)' }}>Reviewer Dashboard</strong>({profile?.academic_profile?.name})
      </Typography>


    </div>
  )
}

export default ReviewerDashBoard;