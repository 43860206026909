import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Paper,
  Select,
  MenuItem,
  TablePagination,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Backdrop,
  InputBase,
  FormControl,
  FormHelperText,
  Modal,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import { Fetch } from "../../../hoc/fetch";
import Loader from "../../../hoc/loader";
import styles from "./applicentHistory.style";
import { useAlert } from "../../../hoc/alert/alert";
import MediaQuery from "react-responsive";
import { useHistory } from "react-router-dom";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 750,
      minWidth: 'fit-content',
    },
  },
};


const OtherApplicants = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [topStartDate, setTopStartDate] = useState(null);
  const [topEndDate, setTopEndDate] = useState(null);
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [viewOpenning, setViewOpennings] = useState(null);
  const [csv, setCsv] = useState("");
  const [hRef, setHRef] = useState("");
  const [bottomHRef, setBottomHRef] = useState("");
  const [applicentName, setApplicentName] = useState("");
  const [testType, setTestType] = useState("");
  const [openAudio, setOpenAudio] = useState(null);
  const [viewId, setViewId] = useState("");
  const alert = useAlert();
  const [click, setClick] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const textAreaRef = useRef(null);

  // Created by: Shiva Shankar

  const [createModal, setCreateModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState('');
  const [positionApply, setPositionApply] = useState('');
  const [applicantName, setApplicantName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [MobileNu, setMobileNu] = useState('');
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [confrmPassword, setConfrmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [expYear, setExpYear] = useState("");
  const [ctc, setCTC] = useState("");
  const [resume, setResume] = useState("");
  const [position, setPosition] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const [currentCity, setCurrentCity] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [graduation, setGraduation] = useState("");
  const [postGraduation, setPostGraduation] = useState("");
  const [college, setCollege] = useState("");
  const [bED, setBED] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");
  const [branchPreference1, setBranchPreference1] = useState("");
  const [branchPreference2, setBranchPreference2] = useState("");
  const [branchPreference3, setBranchPreference3] = useState("");
  const [applicantEditForm, setApplicantEditForm] = useState(false);
  const [editPostionType, setEditPostionType] = useState('');
  const [deleteApplicantForm, setDeleteApplicationForm] = useState(false);
  const [selectedDeleteApplicant, setSelectedDeleteApplicant] = useState('');
  const [branch, setBranch] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  // if (mobNu.match(/[a-z@]/i)) {
  //   email_id = mobNu;
  // } else {
  //   mobile = mobNu;
  // }

  const [data, setData] = useState({
    age: "",
    name: "hai",
  });


  function handleChange(event) {
    setData((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));
  }
  const {
    data: regFormResponse,
    isLoading: regFormLoading,
    doFetch: formDetails,
  } = Fetch(null);


  const { data: graduationList, doFetch: fetchGraduationList } = Fetch(null);

  const { data: collegeList, doFetch: fetchCollegeList } = Fetch(null);

  const { data: postGraduationList, doFetch: fetchPostGraduationList } = Fetch(
    null
  );

  useEffect(() => {
    fetchGraduationList({
      url: urls.graduationList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchPostGraduationList({
      url: urls.postGraduationList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    fetchCollegeList({
      url: urls.postCollegeList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }, [
    fetchGraduationList,
    fetchPostGraduationList,
    fetchCollegeList,
  ]);

  useEffect(() => {
    if (regFormResponse) {
      alert.warning(regFormResponse);
    }
  }, [regFormResponse]);

  const completeApplication = () => {
    if (
      !name || !password || !confrmPassword || emailId
        ? !emailId
        : !email || MobileNu
          ? !MobileNu
          : !mobNo ||
          !currentCity ||
          // || !birthDate || !gender || !graduation || !postGraduation || !bED || !expYear || !ctc || !noticePeriod || !branchPreference1 || !branchPreference2 || !branchPreference3 ||
          !resume
    ) {
      alert.warning("Fill All The Fields");
      return;
    }
    if (password !== confrmPassword) {
      alert.warning("Please enter correct Password");
      return;
    }

    if ((emailId || email).match(/[a-z@]/i)) {
      const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!filter.test(emailId || email)) {
        alert.warning("Please enter valid Email...");
        return;
      }
    } else {
      var IndNum = /^[0]?[789]\d{9}$/;
      if (!IndNum.test(mobNo || MobileNu)) {
        alert.warning("Please enter valid mobile number...!");
        return;
      }
    }
    const formData = new FormData();
    const origin = JSON.parse(sessionStorage.getItem('origin'));
    formData.append("name", applicantName);
    formData.append("contact_no", `${mobNo || MobileNu}`);
    formData.append("email", `${email || emailId}`);
    formData.append("password", password);
    formData.append("current_location", currentCity);
    formData.append("resume", resume);
    formData.append("origin", origin ? origin.params : '');
    {
      positionApply && formData.append("position_apply", positionApply?.id);
    }
    {
      positionApply?.title
        ? formData.append("position_name", positionApply?.title)
        : formData.append("role", "other");
    }
    formDetails({
      url: urls.registrationComplete,
      method: "Post",
      body: formData,
    });
  };

  useEffect(() => {
    if (regFormResponse && regFormResponse) {
      alert.success("Registration completed successfully");
      setApplicantName('');
      setPassword('');
      setConfrmPassword('');
      setEmail('');
      setMobNo('');
      setResume('');
      setPositionType('');
      setCreateModal(false);



    }
    if (regFormResponse && regFormResponse === "Applicant already exists") {
      alert.warning("Applicant already exists");
    }
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowCPassword = () => {
    setShowCPassword(!showCPassword);
  };

  const handleMouseDownCPassword = (event) => {
    event.preventDefault();
  };

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList,
  } = useFetch([], { suppressAlert: true });

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings,
  } = useFetch(null);

  const {
    data: f2fData,
    isLoading: gettingF2Fdata,
    doFetch: fetchF2Fdetails,
  } = useFetch("", { suppressAlert: true });

  const {
    data: downloadMainCsvData,
    isLoading: downloadMainCsvLoader,
    doFetch: downloadMainCsv,
  } = useFetch(null);

  const {
    data: getViewDaata,
    isLoading: gettingViewData,
    doFetch: fetchViewData,
  } = useFetch(null);

  const {
    data: positionList,
    isLoading: gettingOpeningData,
    doFetch: fetchViewPositionData,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches
  } = useFetch([], { suppressAlert: true });

  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity
  } = useFetch([], { suppressAlert: true });

  let loader = null;
  if (gettingRoleList || gettingViewOpennings || gettingF2Fdata || gettingOpeningData || gettingBranches || gettingCity) {
    loader = <Loader open />;
  }


  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [FetchRoleList, name.personal_info.token, positionType]);

  // Other Applicants

  // useEffect(() => {
  //   if (positionType && RoleId) {
  //     fetchViewOpennings({
  //       url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}`,
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${name.personal_info.token}`,
  //       },
  //     });
  //   }
  // }, [RoleId, fetchViewOpennings, name.personal_info.token, positionType]);

  // const downloadMainCsvFunc = () => {
  //   downloadMainCsv({
  //     url: `${urls.downloadMainCsv}?start_date=${topStartDate}&end_date=${topEndDate}&export_type=csv`,
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${name.personal_info.token}`
  //     }
  //   });
  // };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess('Copied!');
  };

  useEffect(() => {
    setHRef([
      {
        csv: `${urls.downloadMainCsv}?start_date=${topStartDate}&end_date=${topEndDate}&export_type=csv`,
      },
    ]);
  }, [setHRef, topStartDate, topEndDate]);

  useEffect(() => {
    setBottomHRef([
      {
        csv: `${urls.downloadApplicentHistoryCsv}?role_id=${RoleId}&position_id=${viewOpenning}&start_date=${startDate}&end_date=${endDate}&export_type=csv`,
      },
    ]);
  }, [setBottomHRef, RoleId, viewOpenning, startDate, endDate]);

  // function downloadBottomCsvFunc() {
  //   // if (!RoleId || !positionType || !viewOpenning) {
  //   //   alert.warning("Fill all details");
  //   //   return;
  //   // }
  //   downloadMainCsv({
  //     url: `${urls.downloadMainCsv}?role_id=${RoleId}&position_id=${viewOpenning}&${startDate}&${endDate}`,
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${name.personal_info.token}`
  //     }
  //   });
  // }

  useEffect(() => {
    if (downloadMainCsvData) {
      setCsv(downloadMainCsvData);
    }
  }, [downloadMainCsvData]);

  // const startTest = id => {
  //   fetchQues({
  //     url: `${urls.applicentMcqTest}?round_id=${id}`,
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${auth.personal_info.token}`
  //     }
  //   });
  // };

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };
  const handleTopStartDateChange = (val) => {
    setTopStartDate(val);
  };
  const handleTopEndDateChange = (val) => {
    setTopEndDate(val);
  };
  const handleCloseAudio = () => {
    setOpenAudio(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  function functiontoSearchF2FDetails(rowsPerPage, page) {
    // if (!RoleId || !positionType || !viewOpenning) {
    //   alert.warning("Fill all details");
    //   return;
    // }
    fetchF2Fdetails({
      url: `${urls.othersApplicantList}?page_size=${rowsPerPage}&page=${page}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (page === 0) {
      functiontoSearchF2FDetails(rowsPerPage, 1);
    } else {
      functiontoSearchF2FDetails(rowsPerPage, page);
    }

  }, [rowsPerPage, page]);

  useEffect(() => {
    if (applicentName) {
      fetchF2Fdetails({
        url: `${urls.othersApplicantList}?search=${applicentName}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }

  }, [applicentName])

  const fetchApplicantData = (e) => {
    setApplicentName(e.target.value);
  };

  // Created by: Shiva Shankar
  // Purpose: Create Applicant

  function createApplicantModal() {
    setCreateModal(true);
  }

  function handleCloseApplicantModal() {
    setCreateModal(false);
  }

  function handleEditApplicant() {
    const updateObj = {
      id: selectedApplicant?.id,
      position_id: editPostionType?.id
    }
    axios.post(`${urls.otherApplicantsUpdate}`,
      updateObj,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      }).then((response) => {
        console.log('response', response);
        if (response?.status === 201) {
          setApplicantEditForm(false);
          setPositionType('');
          setSelectedApplicant('');
          setRoleId('');
          setEditPostionType('');
          alert.success("Updated Applicant Successfully");
          functiontoSearchF2FDetails(rowsPerPage, 1);
        }
      })
  }

  function handleCloseApplicantEditModal() {
    setApplicantEditForm(false);
    setPositionType('');
    setRoleId('');
    setPositionType('');
    setSelectedApplicant('');
  }


  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewPositionData({
        url: `${urls.fetchOpeningDataAllList}?opening_type=${positionType}&role=${RoleId}&branch_id=${selectedApplicant?.branch?.id}&city_id=${selectedApplicant?.city?.id}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [positionType, RoleId, fetchViewPositionData])

  function handleUpdateApplicant(data) {
    setSelectedApplicant(data);
    setApplicantEditForm(true);
  }

  function handleDeleteApplicant(data) {
    setSelectedDeleteApplicant(data);
    setDeleteApplicationForm(true);
  }
  function handleDeleteApplicantClose() {
    setDeleteApplicationForm(false);
  }


  function handleDeleteConfirmApplicant() {
    const deleteObj = {
      username: selectedDeleteApplicant?.user?.username,
      active_status: false
    }
    axios.post(`${urls.deleteApplicant}`,
      deleteObj,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      }
    ).then((response) => {
      if (response.data.status_code === 200) {
        setDeleteApplicationForm(false);
        alert.success(response.data.messsage);
        functiontoSearchF2FDetails();
      }
    })
  }

  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })

  }, [fetchCity]);

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
    }
  }, [checkedLoc])
  function handleBranch(e) {
    let eventIndex = e.target.value[e.target.value.length - 1];

    // eventIndex===branchList[0].id?setBranch([eventIndex]):e.target.value[0]===branchList[0].id?setBranch(e.target.value.shift()):setBranch(e.target.value);

    if (eventIndex === branchList[0].id) {
      setBranch([eventIndex])
    } else {
      if (e.target.value[0] === branchList[0].id) {
        setBranch(e.target.value.shift());
      }
      setBranch(e.target.value);
    }
  }

  function functionToFetchApplicents() {
    fetchF2Fdetails({
      url: `${urls.othersApplicantList
        }?page=${page + 1}&page_size=${rowsPerPage || 50}&city_id=${checkedLoc}&branch_id=${branch}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={6} xs={12}>
          <MediaQuery minWidth={451}>
            <Typography variant="h4"><strong>Other Applicants</strong></Typography>
          </MediaQuery>
          <MediaQuery maxWidth={450}>
            <Typography className="headings" variant="h4"><strong>Other Applicants</strong></Typography>
          </MediaQuery>
        </Grid>
        <Grid item md={6} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="search"
              autoComplete="on"
              onChange={fetchApplicantData}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item md={4} xs={12}>
          <Typography>City</Typography>
          <Select
            required
            fullWidth
            // multiple
            margin='dense'
            value={checkedLoc || ""}
            onChange={e => setCheckedLoc(e.target.value)}
            variant='outlined'
          >
            {cityList && cityList.length !== 0 &&
              cityList.map(option => (
                <MenuItem key={option.value} value={option.id} name={option.id}>
                  {option.city_name}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography>Branch</Typography>
          <Select
            required
            fullWidth
            // multiple
            margin='dense'
            value={branch || ""}
            onChange={handleBranch}
            variant='outlined'
          >
            {branchList && branchList.length !== 0 &&
              branchList.map(option => (
                <MenuItem key={option.value} value={option.id} name={option.id}>
                  {option.branch_name}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item md={2} xs={12} style={{ marginTop: "24px" }}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={functionToFetchApplicents}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      {f2fData && f2fData.results.length === 0 && (
        <Typography
          variant="h4"
          style={{ textAlign: "center", marginTop: "45px" }}
        >
          <strong>
            No Records Found
          </strong>
        </Typography>
      )}
      {f2fData && f2fData.results.length !== 0 && (
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            {f2fData && f2fData.results.length !== 0 && (
              <Grid item md={12} xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography>
                          <strong>S.No</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography style={{ width: "130px" }}>
                          <strong >Applicant Name</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }} >
                        <Typography>
                          <strong>Contact </strong>{" "}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }} >
                        <Typography style={{ width: "150px" }}>
                          {" "}
                          <strong>Position Looking</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }} >
                        <Typography style={{ width: "40px" }}>
                          {" "}
                          <strong>City</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }} >
                        <Typography>
                          <strong>Branch</strong>
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography style={{ width: "150px" }}>
                          <strong>Registration Date </strong>{" "}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography style={{ width: "150px" }}>
                          <strong>Resume</strong>{" "}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: '1px solid black' }}>
                        <Typography style={{ display: "flex", justifyContent: "center" }}>
                          <strong>Actions</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {f2fData &&
                      f2fData.results.length !== 0 &&
                      f2fData.results.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell align="center" style={{ border: '1px solid black' }}>{index + 1}</TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            {(data?.name) ||
                              ""}
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            {(data?.contact_no) ||
                              ""}
                          </TableCell>

                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            {(data?.position_other_looking) ||
                              ""}
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            {(data.city && data?.city?.city_name) ||
                              ""}
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            {(data?.branch && data?.branch?.branch_name) ||
                              ""}
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            {(data?.register_date) ||
                              ""}
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            {data.resume ? (
                              <a
                                target='_blank'
                                href={data?.resume || ""}
                                onclick="window.open(this.href); return false;"
                              >
                                {" "}
                                View Resume
                              </a>
                            ) : (
                              "Resume Not Uploaded"
                            )}
                          </TableCell>
                          <TableCell align="center" style={{ border: '1px solid black' }}>
                            <Button
                              color="primary"
                              onClick={() => handleUpdateApplicant(data)}
                            >
                              <EditIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TablePagination
                        colSpan={6}
                        labelDisplayedRows={() =>
                          `Page ${page + 1} of ${+f2fData.total_pages}`
                        }
                        rowsPerPageOptions={[50, 75, 100]}
                        count={+f2fData.count}
                        rowsPerPage={rowsPerPage || 50}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Rows per page" },
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                      <TableCell style={{ marginTop: "13px" }}>
                        <IconButton
                          onClick={firstPageChange}
                          disabled={page === 0 || page === 1}
                        >
                          <FirstPageIcon />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            lastPageChange(f2fData.total_pages - 1)
                          }
                          disabled={page === +f2fData.total_pages - 1}
                        >
                          <LastPageIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
        </Paper>
      )}
      {Audio}
      {loader}
      {applicantEditForm ?
        (
          <Dialog
            open={applicantEditForm}
            onClose={handleCloseApplicantEditModal}
            aria-labelledby="responsive-dialog-title"
            style={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            fullScreen={fullScreen}
          >
            <DialogTitle id="responsive-dialog-title">Update Applicant</DialogTitle>
            <DialogContent
            >
              <DialogContentText
                id="responsive-dialog-description"
                tabIndex={-1}
              >

                <Grid className={classes.registraionPage}>
                  <Paper className={classes.paper}>
                    <Divider className={classes.divider} />
                    <Grid container spacing={1}>
                      <Grid item md={12} xs={12}>
                        <Typography float="left">
                          Select Position Type: <b style={{ color: "red" }}>*</b>
                        </Typography>
                        <Select
                          required
                          fullWidth
                          margin="dense"
                          value={positionType || ""}
                          onChange={(e, id) => {
                            setPositionType(e.target.value);
                          }}
                          variant="outlined"
                        >
                          {positionTypeList.map((option, index) => (
                            <MenuItem key={index} value={option.label} name={option.id}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      {positionType && (
                        <>
                          <Grid item md={12} xs={12}>
                            <Typography>
                              Select Role: <b style={{ color: "red" }}>*</b>
                            </Typography>
                            <Select
                              required
                              fullWidth
                              margin="dense"
                              value={RoleId || ""}
                              onChange={(e) => setRoleId(e.target.value)}
                              variant="outlined"
                            >
                              {roleList &&
                                roleList.length !== 0 &&
                                roleList.map((option, index) => (
                                  <MenuItem
                                    key={index}
                                    value={option.id}
                                    name={option.applicant_role_name}
                                  >
                                    {option.applicant_role_name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Grid>
                        </>
                      )}
                      {positionType && RoleId && (
                        <>
                          <Grid item md={12} xs={12}>
                            <Typography>
                              Select Position : <b style={{ color: "red" }}>*</b>
                            </Typography>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              margin="dense"
                              required
                              fullWidth
                              onChange={e => setEditPostionType(e.target.value)}
                              value={editPostionType || ' '}
                              variant="outlined"
                              type="text"
                              MenuProps={MenuProps}
                            >
                              <MenuItem style={{ minWidth: 'fit-content' }} value="">
                                <em>None</em>
                              </MenuItem>
                              {positionList && positionList?.map((option) => (
                                <MenuItem style={{ minWidth: 'fit-content' }} key={option.id} value={option}>
                                  {option.title}
                                </MenuItem>
                              )).sort()}
                            </Select>
                          </Grid>

                        </>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-around' }}>
              <Button
                onClick={handleCloseApplicantEditModal}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: '10px',
                  marginLeft: '10px',
                  float: 'left'
                }}
              >
                Cancel
              </Button>

              <Button
                onClick={handleEditApplicant}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: '10px',
                  marginLeft: '10px'
                }}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ''
        )
      }
      {deleteApplicantForm ?
        (
          <Dialog
            open={deleteApplicantForm}
            onClose={handleDeleteApplicantClose}
            aria-labelledby="responsive-dialog-title"
            style={{
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          // fullScreen={fullScreen}
          >
            <DialogTitle id="responsive-dialog-title">Delete Applicant</DialogTitle>
            <DialogContent
            >
              <DialogContentText
                id="responsive-dialog-description"
                tabIndex={-1}
              >

                <Grid className={classes.registraionPage}>
                  <Paper className={classes.paper}>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                      <Grid item md={12}>
                        <Typography className={classes.label} style={{ float: 'left' }} noWrap>
                          {" "}
                          Are You Sure you want to delete this Applicant ? <b style={{ color: "red" }}>{selectedDeleteApplicant?.user?.first_name}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={4} />
                    </Grid>
                  </Paper>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-around' }}>
              <Button
                onClick={handleDeleteApplicantClose}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: '10px',
                  marginLeft: '10px',
                  float: 'left'
                }}
              >
                Close
              </Button>

              <Button
                onClick={handleDeleteConfirmApplicant}
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginTop: '10px',
                  marginLeft: '10px'
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ''
        )
      }
    </React.Fragment>
  );
};

OtherApplicants.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(OtherApplicants);
