export default () => ({
  mainDiv: {
    height: "100vh",
    width: "80%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  registraionPage: {
    background: "#f1f1f1",
    padding: "60px"
  },
  paper: {
    padding: "20px",
    width: "100%"
  },
  label: {
    fontSize: "14px"
  },
  jobTitle: {
    color: "#42c1a9"
  },
  title: {
    marginTop: "20px"
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  fileUpload: {
    textAlign: "center",
    background: "#f7f7f7",
    padding: "20px",
    position: "relative",
    borderRadius: "0px"
  },
  completeAppForm: {
    width: "100%",
    margin: "20px 0px  20px",
    textAlign: "center",
    padding: "20px",
    background: "#40c1a9",
    color: "#fff"
  }
});
