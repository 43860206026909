import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  TableFooter,
  TablePagination,
  Paper,
  Modal,
} from '@material-ui/core';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import styles from './writtenScoreUpload.style';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReactHtmlParser from 'react-html-parser';
import { useAlert } from '../../../hoc/alert/alert'
// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     'aria-controls': `scrollable-auto-tabpanel-${index}`,
//   };
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const WrittenScoreUpload = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [value, setValue] = React.useState(0);
  const [Id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [isChecked, setIsChecked] = useState({});
  const [testScore, setTestScore] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sizechanged, setSizeChanged] = useState(false);
  const [applicantId, setApplicantId] = useState('');
  const [viewWrittenTestImage , setViewWrittenTestImage] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [Loder ,setLoader] = useState(false)
  const [viewWrittenTestData, setViewWrittenTestData] = useState('')


  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id)
  }

  const alert = useAlert();

  // const {
  //   data:viewWrittenTestData,
  //   isLoading:viewWrittenTestDataLoading,
  //   doFetch:fetchviewWrittenTestData
  // } = useFetch(null)
  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch(null);

  const {
    data: ViewWrittenTestDetail,
    doFetch: fetchViewWrittenTest,
  } = useFetch(null);

  const {
    // data: contacted,
    isLoading: contactedLoading,
    doFetch: fetchContacted,
  } = useFetch(null);

  const {
    data: subjectDetail,
    isLoading: subjectDetailLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  const {
    data: branch,
    isLoading: branchLoading,
    doFetch: fetchBranch,
  } = useFetch(null);

  const {
    data: paperList,
    isLoading: paperListLoading,
    doFetch: fetchPaperList,
  } = useFetch(null);

  const {
    data: scoreUploaded,
    isLoading: scoreUploadedLoading,
    doFetch: fetchUploadTestScore,
  } = useFetch(null);

  // useEffect(()=>{
  //   if(viewWrittenTestData){
  //     setViewWrittenTestImage(true)
  //   }
  // },[viewWrittenTestData])

  let loader = null;
  if (scoreUploadedLoading || paperListLoading || branchLoading || subjectDetailLoading || Loder || contactedLoading || applicantDetailLoading || applicantsListLoading || profileDetailLoading ) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.academicRoleList,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchSubject({
        url: urls.subjectApiView,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranch({
        url: urls.branch,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchBranch, fetchPaperList, fetchProfile, fetchSubject, name]);


  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setId(profileDetail[0].id);
    }
  }, [profileDetail])

  useEffect(() => {
    if (Id || scoreUploaded) {
      fetchApplicant({
        url: `${urls.writtenScoreRetrieveApiView}?role_id=${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchPaperList({
        url: `${urls.testPaperList}?role=${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setIsChecked({});
      setOpen(false)
      setTestScore('');
    }
  }, [Id, fetchApplicant, fetchPaperList, name, scoreUploaded])

  const handleStartDateChange = (val) => {
    setStartDate(val)
  }
  const handleEndDateChange = (val) => {
    setEndDate(val);
  }
  const handleGetData = () => {
    if (startDate == null) {
      alert.warning('Please Select Start Date..!')
      return;
    }
    if (endDate == null) {
      alert.warning('Please Select End Date..!')
      return;
    }
    fetchApplicant({
      url: `${urls.writtenScoreRetrieveApiView}?role_id=${Id}&page_size=${rowsPerPage || 50}&page=${page + 1}&start_date=${startDate}&end_date=${endDate}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })

  }

  function updateHandler(ID, hr) {
    setApplicantId(ID)
    const firstName = name.personal_info.first_name
    setEdit(firstName === hr ? false : true)
    setOpen(true);
  }
  function viewHandler(ID) {
    if(ID)
    {
      fetchViewWrittenTest({
        url: `${urls.viewWrittenTest}?applicant_id=${ID}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setViewOpen(true);
    }
  }
  // function viewHandler(ID) {
  //   if(ID)
  //   {
  //     fetchViewWrittenTest({
  //       url: `${urls.viewWrittenTest}?applicant_id=${ID}`,
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${name.personal_info.token}`,
  //       },
  //     });
  //     setViewOpen(true);
  //   }
  // }

  const handleClose = () => {
    setOpen(false);
    setViewWrittenTestImage(false);
  };
  const viewHandleClose = () => {
    setViewOpen(false);
  };

  const handleBranch = (value) => () => {
    const currentIndex = filteredBranch.indexOf(value);
    const newChecked = [...filteredBranch];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredBranch(newChecked);
  };

  const handleSub = (value) => () => {
    const currentIndex = filteredSub.indexOf(value);
    const newChecked = [...filteredSub];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredSub(newChecked);
  };

  const applyFilter = () => {
    fetchApplicant({
      url: `${urls.writtenScoreRetrieveApiView}?branch=${filteredBranch}&subject=${filteredSub}&role_id=${Id}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (page) {
      fetchApplicant({
        url: `${urls.writtenScoreRetrieveApiView}?branch=${filteredBranch}&subject=${filteredSub}&role_id=${Id}&page=${page + 1}&page_size=${rowsPerPage}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [page, fetchApplicant, rowsPerPage, name,
    filteredBranch, filteredSub, Id]);

  useEffect(() => {
    if (sizechanged) {
      fetchApplicant({
        url: `${urls.writtenScoreRetrieveApiView}?branch=${filteredBranch}&subject=${filteredSub}&role_id=${Id}&page=${page || 1}&page_size=${rowsPerPage}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
    setSizeChanged(false);
  }, [page,
    sizechanged,
    fetchApplicant,
    rowsPerPage,
    filteredSub, filteredBranch, Id, name]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!newPage) {
      fetchApplicant({
        url: `${urls.writtenScoreRetrieveApiView}?branch=${filteredBranch}&subject=${filteredSub}&role_id=${Id}&page_size=${rowsPerPage}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
    setSizeChanged(true);
  }

  const subjectList = () => {
    let subject = null;
    if (subjectDetail && subjectDetail.length) {
      subject = subjectDetail.map(value => {
        const subId = `Sub Id : ${value.id}`;
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleSub(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredSub.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': subId }}
              />
            </ListItemIcon>
            <ListItemText id={subId} primary={value.subject_name} />
          </ListItem>
        );
      })
    }
    return subject;
  }

  const branchList = () => {
    let branchDetail = null;
    if (branch && branch.length) {
      branchDetail = branch.map(value => {
        const branchId = `Branch Id : ${value.id}`;
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleBranch(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredBranch.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': branchId }}
              />
            </ListItemIcon>
            <ListItemText id={branchId} primary={value.branch_name} />
          </ListItem>
        );
      })
    }
    return branchDetail;
  }

  function viewwrittenTestFunction(id){
    setLoader(true)
    fetch(`${urls.viewWrittenTestApi}?applicant_id=${id}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
  })
  .then(res=>
    {
    if(res.status === 404){
      setLoader(false)
      alert.error('File not Uploaded')
      
  }
  if(res.status === 200){
      setLoader(false)
      setViewWrittenTestImage(true)
      alert.success('Success')
      return res.json()
      
  }
  if(res.status != 200 && res.status != 404){
      alert.warning('somthing went wrong please try again ')
      
  }
    
}).then(data => {
  setViewWrittenTestData(data)
})
    // fetchviewWrittenTestData({
    //   url: `${urls.viewWrittenTestApi}?applicant_id=${id}`,
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${name.personal_info.token}`,
    //   },
    // })
  }

  const uploadTestScore = () => {
    if(testScore === ''){
      alert.warning('enter score out of 30')
      return;
    }
    const body = {
      applicant: applicantId,
      score: testScore
    }

    fetchUploadTestScore({
      url: urls.uploadWrittenScore,
      method: 'post',
      body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  function profileTable() {
    let applicantTable = null;
    if(applicantsList && applicantsList.result && applicantsList.result.length===0){
      applicantTable = (
        <React.Fragment>
          <Typography style={{textAlign:'center', color:'blue',marginTop:'100px'}} variant='h4'>Records Not Found</Typography>
        </React.Fragment>
      )
    }
    if (applicantsList && applicantsList.result && applicantsList.result.length) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{marginTop:'10px'}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Subject Interested</TableCell>
                <TableCell align="left">Grade</TableCell>
                <TableCell align="left">Branch Interested</TableCell>
                <TableCell align="left">View Written</TableCell>
                <TableCell align="left">Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applicantsList.result.map((details, i) => (
                <TableRow key={details.id}>
                  <TableCell align="left">{details.name}</TableCell>
                  <TableCell align="left">{details.subject}</TableCell>
                  <TableCell align="left">{details.grade}</TableCell>
                  <TableCell align="left">{details.branch}</TableCell>
                  <TableCell align="left">
                    <Button
                      onClick={()=>viewwrittenTestFunction(details.id)}
                      className={classes.addButton}
                      // color="primary"
                      size="small"
                      variant="contained"
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      className={classes.addButton}
                      // color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => updateHandler(details.id, details.Hr_name)}
                    >
                      Upload
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow className={classes.tableFooter}>
                <TablePagination
                  className={classes.paginationSegment}
                  rowsPerPageOptions={[50, 75, 100]}
                  count={+applicantsList.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          </Paper>
        </React.Fragment>
      )
    }
    return applicantTable;
  }

  function testScoreFunction(e){
      setTestScore(e.target.value)
  }

  const modal = (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open} className={classes.paperr}>
          <div>
            <Grid>
              <Paper className={classes.paperquestion}>
                <Typography variant='h5'>Upload Written Score out of 30</Typography>
                <Divider className={classes.divider} />
                <Grid container spacing={2}>
                  <Grid item md={8} xs={16}>
                    <TextField
                      label="Test Score"
                      type="number"
                      margin="dense"
                      required
                      fullWidth
                      onInput = {(e) =>{e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)}}
                      value={testScore || ''}
                      onChange={e => (e.target.value>=0 && e.target.value<= 30) && testScoreFunction(e)}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Button
                      className={classes.addButton}
                      color="primary"
                      size="small"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      onClick={uploadTestScore}
                    >
                      Upload
                    </Button>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </Paper>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  )

  const viewModal = (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={viewOpen}
        onClose={viewHandleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={viewOpen} className={classes.paperr}>
          <div>
            <Grid>
              {
                (ViewWrittenTestDetail !=null ?
                  <Paper className={classes.paperquestion}>
                    <Typography variant='h5'> User Status </Typography>
                    <img  alt="crash" src={ViewWrittenTestDetail.written_test_file}/>
                  </Paper> 
                  :
                  <Paper className={classes.paperquestion}>
                    <Typography variant='h5'> Records Not Founded </Typography>
                  </Paper>)
              }
            </Grid>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  )



  return (
    <React.Fragment>
       <Grid container spacing={2} className={classes.modalPaper}>
         <Grid item sm={12} md={5} xs={12}>
          <Typography variant="h4">Written Score Upload</Typography>
         </Grid>
         <Grid item sm={5} md={3} xs={7}>
         <label>Written Completed Date</label>
           <TextField
              helperText="Start Date"
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={e => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || ' '}
              variant="outlined"
            />
        </Grid>
        {/* &nbsp; */}
        <Grid item sm={5} md={3} xs={7}>
          <TextField
            style={{ marginTop: '28px' }}
            helperText="End Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={e => handleEndDateChange(e.target.value)}
            type="date"
            value={endDate || ' '}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={2} md={1} xs={6}>
          <Button variant="contained" color="primary" className={classes.button} style={{ marginTop: '28px'}} onClick={handleGetData}>Get</Button>
        </Grid>
       </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
        <Grid item md={3} className={classes.paperMain}>
          <Typography variant="h6" className={classes.heading}>Filter By</Typography>
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classes.heading}>Subjects</Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                {subjectList()}
              </Paper>
            </Grid>
          </List>
          <Typography variant="h6" className={classes.heading}>Branch</Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                {branchList()}
              </Paper>
            </Grid>
          </List>
          <Grid className={classes.loginButton}>
            <Button
              className={classes.Button}
              color="primary"
              variant="contained"
              size="large"
              onClick={applyFilter}
            >
              Apply Filter
              </Button>
          </Grid>
        </Grid>
        <Grid item md={9} className={classes.paperMain}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {profileDetail && profileDetail.length && profileDetail.map((role, i) => (
                <Tab key={role.id} label={role.applicant_role_name} />
              ))}
            </Tabs>
          </AppBar>
            {profileTable()}
        </Grid>
      </Grid>
      {modal}
      {viewModal}
      {loader}
      {viewWrittenTestImage && 
      <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={viewWrittenTestImage}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
      >
        <Fade in={viewWrittenTestImage} className={classes.paperrr}>
        <div>
        <Grid>
        <Paper className={classes.paperquestion}>
          <Typography variant='h5'>View Written Test</Typography>
          <Divider className={classes.divider}/>
          <Grid container spacing={2}>
          <Grid item md={8} xs={16}>
            <p className={classes.paragraph}><b>Topic Name: </b>{(viewWrittenTestData &&viewWrittenTestData.topic && viewWrittenTestData.topic.topic_name) || ''}</p>
          </Grid>
          </Grid>
          <Divider className={classes.divider}/>
          <Grid container spacing={2}>
          <Grid item md={3}/>
          <Grid item md={12} xs={12}>
          <a target="_blank" href={viewWrittenTestData && viewWrittenTestData.written_test_file}> <Button color="primary"size="small" variant="contained" >Click Here to View written Test</Button> </a>
          </Grid>
          </Grid>
          <Divider className={classes.divider}/>
          </Paper>
          </Grid>
          </div>
          </Fade>
      </Modal>
  </React.Fragment>
}
    </React.Fragment>
  )
}

WrittenScoreUpload.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(WrittenScoreUpload);
