export default theme => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: true,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: {
    margin: 'auto',
    width: '100%',
    flexGrow: 1,
},
gridTwo: {
    marginTop: '15px',
    display: 'flex',
},
gridOne: {
    marginTop: theme.spacing(2),
    display: 'inline',
},
gridOnee: {
  marginTop: theme.spacing(2),
  display:'inline',

},
qulification: {
    marginTop: theme.spacing(2),
    // marginLeft: theme.spacing(6),
},
formControl: {
    margin: theme.spacing(3),
  },
group: {
    margin: theme.spacing(1, 0),
    display: 'flex',
},
paperContentLarge: {
    padding: '15px',
    width: '50%',
    margin: 'auto',
    overflowY: 'hidden',
},
  qualificationpaper:{
    padding: theme.spacing(1, 2, 1),
  },
  nextButton:{
    marginTop:'20px',
    alignItem:'right'
  },
  addQufButton:{
    padding: theme.spacing(1, 2, 1),
    float:'right'
  },
  AddButton:{
    padding: theme.spacing(1,5,1),
    float:'right'
  },
  square: {
    color: '#fff',
    // borderRadius: 0, 
    width: '150px',
    height:'150px',
    marginTop: theme.spacing(1),
   },
  fileUpload : {
    margin: theme.spacing(1),
    // float:'left',
  },
});
