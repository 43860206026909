import React, { useState, useEffect } from "react";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Paper,
  Button,
  Select,
  TextField,
  InputLabel,
  Input,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel
} from "@material-ui/core";
import styles from "./OcfcInterview.style";
import urls from "../../../url";
import useFetch from "../../../hoc/fetch/useFetch";
import Loader from "../../../hoc/loader";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

function OcfcInterview({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [salary, setSalary] = useState("");
  const [salaryId, setSalaryId] = useState(0);
  const [customSalary, setCustomSalary] = useState("");
  const [overAllValue, setOverallValue] = useState("");
  const [audioUpload, setAudioUpload] = useState("");
  const [reason, setReason] = useState("");
  const [processId, setProcessId] = useState("");
  const [refresh, setRefresh] = useState("");

  const handleSalaryChange = event => {
    setSalary(event.target.value);
    setSalaryId(event.target.name);
  };

  const handleOverallChange = event => {
    setOverallValue(event.target.value);
  };

  const audioUploadFunc = data => {
    setAudioUpload(data);
  };

  const handleReasonChange = event => {
    setReason(event.target.value);
  };

  const handleCustomSalary = event => {
    setCustomSalary(event.target.value);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("audio_file", audioUpload);
    formData.append("applicant", processId);
    formData.append("reason", reason);
    salaryId !== "salarySelect" && formData.append("salary", salaryId);
    formData.append("new_salary", customSalary || null);
    formData.append("is_qualify", overAllValue === "Yes" ? true : false);
    const response = await fetch(urls.getOcfcHrInterviewApi, {
      method: "POST", // or 'PUT'
      body: formData, // data can be `string` or {object}!
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    const getDataiNFO = await response.json();
    setRefresh(processId);
    setAudioUpload("");
    setProcessId("");
    setReason("");
    setSalaryId("");
    setCustomSalary("");
    setSalary("");
    setOverallValue("");
    setOpen(false);
    return getDataiNFO;
  };

  const {
    data: ocfcInterviewers,
    isLoading: ocfcLoading,
    doFetch: fetchOcfcInterviewList
  } = useFetch(null);

  const {
    data: processModalInfo,
    isLoading: processModalLoading,
    doFetch: fetchProcessModalInfo
  } = useFetch(null);

  const {
    data: getSalarySlabData,
    isLoading: processSalarySlabLoading,
    doFetch: fetchSalarySlabInfo
  } = useFetch(null);

  let loader = null;
  if (ocfcLoading || processModalLoading || processSalarySlabLoading) {
    loader = <Loader open />;
  }

  const processCandidate = id => {
    setProcessId(id);
    fetchProcessModalInfo({
      url: `${urls.getProcessModalInfoApi}?applicant=${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    fetchSalarySlabInfo({
      url: `${urls.getSalarySlabApi}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    setOpen(true);
  };

  const handleClose = () => {
    setAudioUpload("");
    setSalary("");
    setProcessId("");
    setReason("");
    setSalaryId("");
    setCustomSalary("");
    setOverallValue("");
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchOcfcInterviewList({
      url: `${urls.getOcfcHrInterviewApi}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  }, [refresh]);

  return (
    <div>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Typography className="headings" variant='h4'><strong>OCFC Interview</strong></Typography>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' id='nameHead'>
               <strong> Name</strong>
              </TableCell>
              <TableCell align='center' id='dateHead'>
                <strong>Contact</strong>
              </TableCell>
              <TableCell align='center' id='scoreHead'>
                <strong>Process</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ocfcInterviewers &&
              ocfcInterviewers.map((row, index) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell align='center'>{row.applicant.name}</TableCell>
                  <TableCell align='center'>
                    {row.applicant.contact_no}
                  </TableCell>
                  <TableCell align='center' id='scoreHead'>
                    <Button
                      size='small'
                      fullWidth
                      color='primary'
                      variant='contained'
                      onClick={() => processCandidate(row.id)}
                    >
                      Process
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <Dialog
        open={open}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle
          id='alert-dialog-title'
          style={{ width: "80%", margin: "0 auto", textAlign: "center" }}
        >
          OCFP Interview Details
        </DialogTitle>
        <DialogContent>
          <Paper elevation={2}>
            <Grid container style={{ width: "80%", margin: "0 auto" }}>
              <Grid item md={6} xs={12} xs={12} style={{ marginTop: "10px" }}>
                <FormLabel component='legend'>PDF file</FormLabel>
                <p>
                  Open a PDF file of &nbsp;
                  <a
                    href={
                      processModalInfo && processModalInfo.presentation_file
                    }
                    target='_new'
                  >
                    {processModalInfo && processModalInfo.topic_name}
                  </a>
                  .
                </p>
              </Grid>

              <Grid item md={6} xs={12} xs={12} style={{ marginTop: "10px" }}>
                <FormLabel component='legend'>Audio File</FormLabel>
                <audio controls>
                  <source
                    src={processModalInfo && processModalInfo.audio_file}
                    type='audio/mp3'
                  />
                </audio>
              </Grid>
            </Grid>
          </Paper>
          <Paper
            style={{ marginTop: "20px", paddingBottom: "10px" }}
            elevation={3}
          >
            <h3 style={{ paddingTop: "10px", margin: "0 auto", width: "80%" }}>
              Salary Form
            </h3>
            <Grid container style={{ margin: "10px auto", width: "80%" }}>
              <Grid item md={12} xs={12} style={{ marginTop: "10px" }}>
                <FormLabel component='legend'>Overall Qualify</FormLabel>
                <RadioGroup
                  row
                  aria-label=''
                  name='overAllQualify'
                  value={overAllValue}
                  onChange={handleOverallChange}
                >
                  <FormControlLabel
                    value='Yes'
                    control={<Radio color='primary' />}
                    label='Accept'
                  />
                  <FormControlLabel
                    value='No'
                    control={<Radio color='primary' />}
                    label='Reject'
                  />
                </RadioGroup>
              </Grid>
              <Grid item md={12}>
                <TextField
                  fullWidth
                  id='outlined-multiline-flexible'
                  label='Reason to Accept/Reject'
                  multiline
                  rowsMax='4'
                  value={reason}
                  onChange={handleReasonChange}
                  variant='outlined'
                  style={{ marginTop: "15px" }}
                />
              </Grid>
              <Grid item md={6} xs={12} style={{ marginTop: "15px" }}>
                <FormLabel component='legend'>Salary</FormLabel>
                <RadioGroup
                  name='salarySelect'
                  value={salary}
                  onChange={handleSalaryChange}
                >
                  {getSalarySlabData &&
                    getSalarySlabData.map((item, index) => (
                      <FormControlLabel
                        key={item.id}
                        value={item.salary}
                        name={item.id}
                        control={<Radio color='primary' />}
                        label={item.salary}
                      />
                    ))}
                  <FormControlLabel
                    value='other'
                    control={<Radio color='primary' />}
                    label='Other'
                  />
                </RadioGroup>
              </Grid>
              {salary && salary === "other" ? (
                <Grid item md={6}>
                  <TextField
                    fullWidth
                    id='outlined-multiline-flexible'
                    label='Enter Your Salary'
                    value={customSalary}
                    onChange={handleCustomSalary}
                    variant='outlined'
                    style={{ marginTop: "15px" }}
                    type='number'
                  />
                </Grid>
              ) : (
                <Grid item md={6} />
              )}
              <Grid item md={6} xs={12} style={{ marginTop: "15px" }}>
                <FormLabel component='legend'>Audio File Upload</FormLabel>
                <div className={classes.root} id='uploadVideo'>
                  <input
                    accept='audio/mp3'
                    className={classes.input}
                    id='contained-button-file'
                    multiple
                    type='file'
                    onChange={e => audioUploadFunc(e.target.files[0])}
                    style={{ display: "none" }}
                  />
                  <label htmlFor='contained-button-file'>
                    <Button
                      style={{ marginTop: "5px" }}
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      component='span'
                      startIcon={<CloudUploadIcon />}
                    >
                      Audio File Upload
                    </Button>
                    <span>{audioUpload && audioUpload.name}</span>
                  </label>
                </div>
              </Grid>
            </Grid>
          </Paper>
          <Grid container style={{ marginTop: "25px" }}>
            <Grid item md={4} />
            <Grid item md={4}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                className={classes.button}
                component='span'
                onClick={() => handleSubmit()}
                style={{ marginBottom: "15px" }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item md={4} />
          </Grid>
        </DialogContent>
      </Dialog>
      {loader}
    </div>
  );
}

export default withStyles(styles)(OcfcInterview);
