import React, { useState, useEffect } from "react";
import tinymce from "tinymce";
import { useHistory } from "react-router-dom";
// import PropTypes from 'prop-types';
import ViewOpening from "../../../../src/register/register";
import axios from "axios";
import {
  Form,
  Select as AntdSelect,
  Row,
  Col,
  Card,
  Input as AntdInput,
  Checkbox as AntdCheckbox,
  Button as AntdButton,
  Steps,
  message,
} from "antd";

import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Paper,
  Button,
  Select,
  TextField,
  InputLabel,
  Input,
  FormControl,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import styles from "./createPositions.style";
import { useAlert } from "../../../hoc/alert/alert";
import Loader from "../../../hoc/loader";
import "../addQuestion/pluGins";
import PositionStep2 from "./positionstep2/positionStep2";
import MediaQuery from "react-responsive";
import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/paste";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/table";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import { Editor } from "@tinymce/tinymce-react";
import { useLocation } from "react-router-dom";

const steps = [
  {
    title: "First",
  },
  {
    title: "Last",
  },
];

const CreatePositions = ({ classes }) => {
  const history = useHistory();
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const location = useLocation();

  const passedData = location?.state?.data;
  const [positionValue, setPositionValue] = useState("");
  const [gradeValue, setGradeValue] = useState("");
  const [applicantRole, setApplicantRole] = useState("");
  const [subject, setSubject] = useState("");
  const [cityName, setCityName] = useState();
  const [title, setTitle] = useState("");
  const [experience, setExperience] = useState("");
  const [skill, setSkill] = useState("");
  const [ins, setIns] = useState("");
  const [editor, setEditor] = useState(null);
  const [branchName, setBranchName] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);
  const [JobType, setJobType] = useState("");
  const [recruterName, setRecruterName] = useState("");
  const [contactNo, setContectNo] = useState("");
  const [salary, setSalary] = useState("");
  const [editorsValue, setEditorsValue] = useState();
  const [editorsValues, setEditorsValues] = useState();

  const [priority, setPriority] = useState("");
  const [render, setRender] = useState(false);
  const [otherSalary, setOtherSalary] = useState("");
  const [experienceOther, setexperienceOther] = useState("");
  const [jobTypeOther, setJobTypeOther] = useState("");
  const [transferValue, setIsTransferValue] = useState(false);
  const [QuestionPaperList, setQuestonPaperList] = useState([]);
  const [campusDriven, setCampusDriven] = useState(false);
  const [selectedPositionId, setSelectedPositionId] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [mrfId, setMrfId] = useState(null);

  const [activeStep, setActiStep] = useState(0);
  const [successUrl, setSuccessUrl] = useState(null);

  const loginValType = localStorage.getItem("loginValType");

  const [isChecked, setIsChecked] = useState(false);
  const [isCities, setIsCities] = useState(false);

  const [addLoader, setAddLoader] = useState(false);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    if (isChecked === false) {
      const ids = branch.map((item) => item.id);
      setBranchName(ids);
    } else {
      setBranchName();
    }
  };

  const [form] = Form.useForm();

  function apply(QuestionPaperList) {
    if (!editorsValues) {
      return message.error("Enter Job Description!");
    }
    setAddLoader(true);
    let roundsList = QuestionPaperList;
    if (roundsList == "") {
      alert.warning("select the round");
    } else {
      roundsList.unshift({
        round_no: 0,
        round_type: "profile",
        round_complete: false,
      });
      const data = {
        position_type_fk: positionValue,
        department_fk: applicantRole,
        designation_fk: applicantRole,
        grade: gradeValue,
        subject: subject,
        title: title,
        experience: experience,
        other_experience: experienceOther,
        city: Array.isArray(cityName) ? cityName : [cityName],
        idealCandidate: editorsValue,
        job_type: JobType,
        other_job_type: jobTypeOther,
        description: editorsValues,
        skill: skill,
        salary: salary,
        other_salary: otherSalary,
        priority: priority,
        recruiter_name: recruterName,
        recruiter_number: contactNo,
        is_transfer_allowed: transferValue,
        is_campus_drive: campusDriven,
        // mrf: location.state.mrf,
      };
      if (selectedPositionId) {
        // console.log(branchName, "branch");
        console.log(data, "data");
        axios({
          url: urls.updatePositions,
          method: "put",
          data: {
            ...data,
            id: selectedPositionId,
            is_active: isActive,
            branch: Array.isArray(branchName) ? branchName : [branchName],
            round_details: roundsList,
            branch_name: passedData.branch_name,
            city_name: passedData.city_name,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${name.personal_info.token}`,
          },
        })
          .then((response) => {
            if (response.status == "200") {
              message.success("Position Succesfully Updated");
              history.push({ pathname: successUrl });
              console.log(response.data);
            } else {
              message.error("Something went wrong");
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error:", error);
          })
          .finally(() => {
            setAddLoader(false);
          });
      } else {
        axios({
          url: urls.creatPositionUrl,
          method: "POST",
          data: {
            ...data,
            rounds: roundsList,
            branch_ids: Array.isArray(branchName) ? branchName : [branchName],
            mrf: mrfId,
          },
          headers: {
            Authorization: `Bearer ${name.personal_info.token}`,
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status == "201") {
              message.success("Position Created");
              if (successUrl) {
                history.push({ pathname: successUrl });
              } else {
                history.push({ pathname: "/update_positions" });
              }

              console.log(response.data);
            } else {
              message.error("Something went wrong");
            }
          })
          .catch((error) => {
            // Handle error
            console.error("Error:", error);
          })
          .finally(() => {
            setAddLoader(false);
          });
      }
    }
    setQuestonPaperList([]);
  }

  useEffect(() => {
    if (passedData && passedData.url == "/update_positions") {
      setSuccessUrl(passedData.url);
      console.log(passedData, "passedData");
      setSelectedPositionId(passedData.id);
      setPositionValue(passedData.position_type_fk);
      setApplicantRole(passedData.designation_fk);
      setGradeValue(passedData.grade);
      setSubject(passedData.subject);
      setTitle(passedData.title);
      setExperience(passedData.experience);
      setexperienceOther(passedData.other_experience);
      setCityName(passedData.city);
      setBranchName(passedData.branch);
      // setEditorsValue(passedData.idealCandidate);
      setJobType(passedData.job_type);
      setJobTypeOther(passedData.other_job_type);
      // setEditorsValues(passedData.description);
      setSkill(passedData.skill);
      setSalary(passedData.salary);
      setOtherSalary(passedData.other_salary);
      setPriority(passedData.priority);
      setRecruterName(passedData.recruiter_name);
      setContectNo(passedData.recruiter_number);
      setIsTransferValue(passedData.is_transfer_allowed);
      setCampusDriven(passedData.is_campus_drive);
      setQuestonPaperList(passedData.round_details);
      setIsActive(passedData.is_active);
      setEditorsValues(passedData.description);

      form.setFieldsValue({
        branch_ids: passedData.branch,
        department_fk: passedData?.designation_fk,
      });
      form.setFieldsValue(passedData);
    } else if (passedData && passedData.url == "/mrf_list") {
      setSuccessUrl(passedData.url);
      console.log(passedData, "passedData");
      setSelectedPositionId(null);
      setMrfId(passedData.id);

      setPositionValue(passedData.position_type_fk);
      setApplicantRole(passedData.department_fk);
      setGradeValue(passedData.grade);
      setSubject(passedData.subject);
      setTitle(passedData.position);
      setExperience(passedData.experience);
      setexperienceOther(passedData.other_experience);
      setCityName(passedData.location);
      setBranchName(passedData.branch);
      setEditorsValue(passedData.idealCandidate);

      setJobType(passedData.job_type);
      setJobTypeOther(passedData.other_job_type);
      setEditorsValues(passedData.description);
      setSkill(passedData.skill);
      setSalary(passedData.salary);
      setOtherSalary(passedData.other_salary);
      setPriority(passedData.priority);
      setRecruterName(passedData.assigned_recruiter_name);
      setContectNo(passedData.assigned_recruiter_number);

      setIsActive(passedData.is_active);

      form.setFieldsValue({
        recruiter_name: passedData.assigned_recruiter_name,
        title: passedData.position,
        city: passedData.location,
        branch_ids: passedData.branch,
        priority: passedData.priority,
        recruiter_number: passedData.assigned_recruiter_number,
      });
      form.setFieldsValue(passedData);
    }
  }, [passedData]);

  const handleOnChangeCity = () => {
    setIsCities(!isCities);
    if (isCities === false) {
      const ids = cityData.map((item) => item.id);
      setCityName(ids);
    } else {
      setCityName();
      setBranchName();
    }
  };

  const alert = useAlert();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const jobTypeList = [
    { value: "1", label: "Intern" },
    { value: "2", label: "Part Time" },
    { value: "3", label: "Full Time" },
    { value: "4", label: "Other" },
  ];

  const Priority = [
    { value: 1, label: "P1" },
    { value: 2, label: "P2" },
    { value: 3, label: "P3" },
    { value: 4, label: "P4" },
    { value: 5, label: "P5" },
  ];

  const salaryRange = [
    { value: "1", label: "1-3" },
    { value: "2", label: "3-5" },
    { value: "3", label: "5-7" },
    { value: "4", label: "7-10" },
    { value: "5", label: "10+" },
    { value: "6", label: "Other" },
  ];
  const experianceRange = [
    { value: "fresher", label: "fresher" },
    { value: "less than one year", label: "less than one year" },
    { value: "1", label: "1-3" },
    { value: "2", label: "3-5" },
    { value: "3", label: "5-7" },
    { value: "4", label: "7-9" },
    { value: "5", label: "9-11" },
    { value: "6", label: "11-13" },
    { value: "7", label: "13-15" },
    { value: "8", label: "Other" },
  ];

  // const applicentRolesForNonAcmidecs = [
  //   {value: '1', label: 'Driver'},
  //   {value: '2', label: 'Logistics'},
  //   {value: '3', label: 'Other'},
  // ]

  // const applicentRolesForIT = [
  //   {value: '1', label: 'FroentEnd'},
  //   {value: '2', label: 'BackEnd'},
  //   {value: '3', label: 'Other'},
  // ]

  // const activeNonActive = [
  //   {
  //     value: "true",
  //     label: "Active"
  //   },
  //   {
  //     value: "false",
  //     label: "Non Active"
  //   }
  // ];
  const [positionType, setPositionType] = useState([]);

  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        setPositionType(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const {
    data: userData,
    // isLoading: contactSourceLoading,
    doFetch: fetchUserData,
  } = useFetch(null);
  const {
    data: createPositionResponse,
    isLoading: createPositionLoding,
    doFetch: createPositionDetails,
  } = useFetch([], { suppressAlert: true });

  const {
    data: updatePosition,
    isLoading: updatePositionLoading,
    doFetch: fetchUpdatePosition,
  } = useFetch(null);

  const {
    data: grade,
    isLoading: gradeLoading,
    doFetch: fetchGrade,
  } = useFetch([], { suppressAlert: true });

  const {
    data: role,
    isLoading: RoleDataLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });
  const {
    data: subjectData,
    isLoading: SubjectLoading,
    doFetch: fetchSubject,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branch,
    isLoding: branchLoding,
    doFetch: fetchBranch,
  } = useFetch([], { suppressAlert: true });

  const { data: cityData, doFetch: fetchCity } = useFetch([], {
    suppressAlert: true,
  });

  useEffect(() => {
    fetchPositionTypesList();
    fetchGrade({
      url: `${urls.newGrade}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }, [name]);

  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }, [fetchCity, name.personal_info.token]);

  useEffect(() => {
    if (gradeValue) {
      fetchSubject({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeValue}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    } else {
    }
  }, [gradeValue, name.personal_info.token]);

  useEffect(() => {
    if (cityName) {
      fetchBranch({
        url: `${urls.branchCityMapping}?city_id=${cityName}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [cityName, name.personal_info.token]);

  useEffect(() => {
    if (positionValue) {
      fetchRole({
        url: `${urls.designationListApi}?position_type=${positionValue}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchRole, fetchGrade, name.personal_info.token, positionValue]);

  // useEffect(() => {
  //   fetchGrade({
  //     url: `${urls.gradeMapping}?position_type=${positionValue}`,
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${name.personal_info.token}`
  //     }
  //   });
  // }, [fetchGrade, name.personal_info.token]);
  // useEffect(() => {
  //   if (name) {
  //     fetchProfile({
  //       url: urls.retrieveApplicant,
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${name.personal_info.token}`,
  //       },
  //     });
  //   }
  // }, [fetchProfile, name]);

  function handleMultipleChange(event) {
    console.log(event);
    if (event.includes("All")) {
      const ids = branch.map((item) => item.id);

      setBranchName(ids);
    } else {
      setBranchName(event);
    }
  }
  function handleCityMultipleChange(event) {
    if (event.includes("All")) {
      const ids = cityData.map((item) => item.id);
      setCityName(ids);
      setBranchName(); // Assuming setBranchName needs to be reset
      setIsChecked(false);
    } else {
      // Handle the case when "All" is not present in the array
      setCityName(event);
      setBranchName(); // Assuming setBranchName needs to be reset
      setIsChecked(false);
    }
  }

  useEffect(() => {
    if (createPositionResponse !== null && pageRefresh === true) {
      setPositionValue("");
      setGradeValue("");
      setApplicantRole("");
      setSubject("");
      setCityName();
      setTitle("");
      setExperience("");
      setSkill("");
      setBranchName();
      setIsChecked(false);
      setIsCities(false);
      setPageRefresh(false);
      // setEditorsValue({ description: "", idealCandidate: "" });
      setPriority("");
      setEditor("");
      setIns("");
      // tinymce.remove(editor);
      setRender(true);
      setIsTransferValue(false);
      setCampusDriven(false);
      setSalary("");
      setRecruterName("");
      setContectNo("");
      setJobType("");
      setActiStep(0);
      setIsActive(null);
      setSuccessUrl(null);
      setMrfId(null);
    }
  });

  let loader = null;
  if (
    branchLoding ||
    gradeLoading ||
    createPositionLoding ||
    RoleDataLoading ||
    SubjectLoading
  ) {
    loader = <Loader open />;
  }

  const nextStep = () => {
    if (!editorsValues) {
      return message.error("Enter Job Description!");
    }
    setActiStep(1);
    // if (JobType === "Other" || experience === "Other") {
    //   if (
    //     !positionValue ||
    //     !title ||
    //     !experienceOther ||
    //     !jobTypeOther ||
    //     !skill ||
    //     !priority ||
    //     !recruterName ||
    //     !contactNo ||
    //     !applicantRole
    //   ) {
    //     alert.warning("please fill all mandatory fields *");
    //   } else {
    //     setActiStep(1);
    //   }
    // } else {
    //   if (
    //     !positionValue ||
    //     !title ||
    //     !experience ||
    //     !JobType ||
    //     !skill ||
    //     !salary ||
    //     !priority ||
    //     !recruterName ||
    //     !contactNo ||
    //     !applicantRole
    //   ) {
    //     alert.warning("please fill all mandatory fields *");
    //   } else {
    //     setActiStep(1);
    //   }
    // }
  };

  function functionHandleback(data) {
    setQuestonPaperList(data);
    setActiStep(0);
  }
  function handleMobileNO(e) {
    const re = /^[0-9]+$/g;
    // console.log(e, "e");
    // console.log(e.target, "target");
    if ((e === "" || re.test(e)) && e.length <= 10) {
      setContectNo(e);
    }
  }

  // useEffect(() => {
  //   if (role || render === true || activeStep === 2) {
  //     tinymce.init({
  //       selector: '.dgf',
  //       apiKey:"avuvy4c3g10sluz8vrm03j34cawyl6ajjms52nhfulk40aeb",
  //       skin_url: `${process.env.PUBLIC_URL}/skins/lightgray`,
  //       plugins: [
  //         'advlist autolink lists link image charmap print preview anchor',
  //         'searchreplace visualblocks code fullscreen',
  //         'insertdatetime media table paste code help wordcount'
  //       ],
  //       toolbar: 'undo redo | formatselect | bold italic backcolor | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
  //       image_title: true,
  //       branding: false,
  //       setup: editor => {
  //         // setEditor(editor)
  //         editor.on('keyup change', () => {
  //           const content = editor.getContent()
  //           setEditorsValue((vl) => {
  //             vl[tinymce.activeEditor.id] = content
  //             return vl
  //           })
  //         })
  //       },
  //       automatic_uploads: true,
  //       file_picker_types: 'image',
  //       file_picker_callback: function (cb, value, meta) {
  //         var input = document.createElement('input');
  //         input.setAttribute('type', 'file');
  //         input.setAttribute('accept', 'image/*');
  //         input.onchange = function () {
  //           var file = this.files[0];
  //           var reader = new FileReader();
  //           reader.onload = function () {
  //             var id = 'blobid' + (new Date()).getTime();
  //             var blobCache = tinymce.activeEditor.editorUpload.blobCache;
  //             var base64 = reader.result.split(',')[1];
  //             var blobInfo = blobCache.create(id, file, base64);
  //             blobCache.add(blobInfo);
  //             cb(blobInfo.blobUri(), { title: file.name });
  //           };
  //           reader.readAsDataURL(file);
  //         };
  //         input.click();
  //       }
  //     });
  //     return () => {
  //       tinymce.remove(editor);
  //     }
  //   }
  // }, [role, render, activeStep])

  const NonAcadmicPosition = () => (
    <>
      {/* <Form>
        <Form.Item label="Applicant Role">
          <Select>
            {role &&
              role.length !== 0 &&
              role.map((data) => (
                <Select.Option
                  value={data.id}
                  key={data.id}
                  name={data.applicant_role_name}
                >
                  {data.applicant_role_name ? data.applicant_role_name : ""}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form> */}
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <TextField
            label="Applicant Role"
            fullWidth
            className="textList"
            margin="dense"
            onChange={(e) => setApplicantRole(e.target.value)}
            select
            required
            value={applicantRole || ""}
            variant="outlined"
          >
            {role &&
              role.length !== 0 &&
              role.map((data) => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.applicant_role_name}
                >
                  {data.applicant_role_name ? data.applicant_role_name : ""}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  );

  const onAcadmicPosition = () => (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <TextField
          label="Grade"
          fullWidth
          className="textList"
          margin="dense"
          onChange={(e) => setGradeValue(e.target.value)}
          select
          required
          value={gradeValue || ""}
          variant="outlined"
        >
          {grade &&
            grade.map((data) => (
              <MenuItem value={data.id} key={data.id} name={data.grade_name}>
                {data.grade_name ? data.grade_name : ""}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          label="Applicant Role"
          fullWidth
          className="textList"
          margin="dense"
          onChange={(e) => setApplicantRole(e.target.value)}
          select
          required
          value={applicantRole || ""}
          variant="outlined"
        >
          {role &&
            role.length !== 0 &&
            role.map((data) => (
              <MenuItem
                value={data.id}
                key={data.id}
                name={data.applicant_role_name}
              >
                {data.applicant_role_name ? data.applicant_role_name : ""}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          label="Subject"
          fullWidth
          className="textList"
          margin="dense"
          onChange={(e) => setSubject(e.target.value)}
          select
          required
          value={subject || ""}
          variant="outlined"
        >
          {subjectData &&
            subjectData.length >= 0 &&
            subjectData.map((data) => (
              <MenuItem
                value={data.subject.id}
                key={data.subject.id}
                name={data.subject.subject_name}
              >
                {data.subject.subject_name}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
    </Grid>
  );
  return (
    <React.Fragment>
      <Card>
        <h1>
          <strong>Create Position</strong>
        </h1>
        <Steps
          style={{ padding: "10px 50px 50px 50px" }}
          current={activeStep}
          items={items}
          onChange={() => {
            setActiStep(1);
          }}
        />

        {activeStep === 0 && (
          <>
            <Form
              scrollToFirstError
              id="position_form"
              form={form}
              onFinish={nextStep}
            >
              <Row gutter={24}>
                <Col md={8}>
                  <Form.Item
                    name="position_type_fk"
                    label="Position Type"
                    value={positionValue}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Position",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Position*"
                      showSearch
                      filterOption={(input, options) => {
                        return (options?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      onChange={(e) => setPositionValue(e)}
                    >
                      {positionType &&
                        positionType.map((data) => (
                          <AntdSelect.Option value={data.id} key={data.id}>
                            {data.position_type}
                          </AntdSelect.Option>
                        ))}
                    </AntdSelect>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="city"
                    label="Select City"
                    value={gradeValue || ""}
                    rules={[
                      {
                        required: true,
                        message: "Please Select City",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      mode="multiple"
                      placeholder="City*"
                      onChange={(event) => handleCityMultipleChange(event)}
                    >
                      {/* <AntdSelect.Option value="All">All</AntdSelect.Option> */}
                      {cityData &&
                        cityData &&
                        cityData.map((data) => (
                          <AntdSelect.Option
                            value={data.id}
                            key={data.id}
                            name={data.city_name}
                          >
                            {data.city_name ? data.city_name : ""}
                          </AntdSelect.Option>
                        ))}
                    </AntdSelect>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  {positionValue !== "IT Requriment" && (
                    <>
                      <Form.Item
                        name="branch_ids"
                        label="Select Branch"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Branch",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                      >
                        <AntdSelect
                          mode="multiple"
                          placeholder="Branch*"
                          onChange={handleMultipleChange}
                        >
                          {branch &&
                            branch &&
                            branch.map((data) => (
                              <AntdSelect.Option
                                value={data.id}
                                key={data.id}
                                name={data.branch_name}
                              >
                                {data.branch_name ? data.branch_name : ""}
                              </AntdSelect.Option>
                            ))}
                        </AntdSelect>
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="department_fk"
                    label="Select Designation"
                    value={applicantRole}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Designation",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Designation*"
                      showSearch
                      filterOption={(input, options) => {
                        return (options?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      onChange={(e) => setApplicantRole(e)}
                    >
                      {role &&
                        role.length !== 0 &&
                        role.map((data) => (
                          <AntdSelect.Option
                            value={data.id}
                            key={data.id}
                            name={data.applicant_role_name}
                          >
                            {data.name}
                          </AntdSelect.Option>
                        ))}
                    </AntdSelect>
                  </Form.Item>
                </Col>
                {/* <Col md={8}>
                  <Form.Item
                    name="applicant_role"
                    label="Select Applicant Role"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Role",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Applicant Role*"
                      onChange={(e) => setApplicantRole(e)}
                    >
                      {role &&
                        role.length !== 0 &&
                        role.map((data) => (
                          <AntdSelect.Option
                            value={data.id}
                            key={data.id}
                            name={data.applicant_role_name}
                          >
                            {data.applicant_role_name
                              ? data.applicant_role_name
                              : ""}
                          </AntdSelect.Option>
                        ))}
                    </AntdSelect>
                  </Form.Item>
                </Col> */}
                <Col md={8}>
                  <Form.Item
                    name="grade"
                    label={`Select ${
                      positionValue !== "Academic" ? "Level" : "Grade"
                    }`}
                    rules={[
                      {
                        required: positionValue === "Academic",
                        message: `Please Select ${
                          positionValue !== "Academic" ? "Level" : "Grade"
                        }`,
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Grade*"
                      showSearch
                      filterOption={(input, options) => {
                        return (options?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      onChange={(e) => setGradeValue(e)}
                    >
                      {grade &&
                        grade.map((data) => (
                          <AntdSelect.Option
                            value={data.id}
                            key={data.id}
                            name={data.grade_name}
                          >
                            {data.grade_name ? data.grade_name : ""}
                          </AntdSelect.Option>
                        ))}
                    </AntdSelect>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="subject"
                    label={`Select ${
                      positionValue !== "Academic" ? "Topic" : "Subject"
                    }`}
                    rules={[
                      {
                        required: positionValue === "Academic",
                        message: `Please Select ${
                          positionValue !== "Academic" ? "Topic" : "Subject"
                        }`,
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Subject*"
                      showSearch
                      filterOption={(input, options) => {
                        return (options?.children ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      onChange={(e) => setSubject(e)}
                    >
                      {subjectData &&
                        subjectData.length >= 0 &&
                        subjectData.map((data) => (
                          <AntdSelect.Option
                            value={data.subject.id}
                            key={data.subject.id}
                            name={data.subject.subject_name}
                          >
                            {data.subject.subject_name}
                          </AntdSelect.Option>
                        ))}
                    </AntdSelect>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="title"
                    label="Position Title"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Position Title ",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdInput
                      placeholder="Position Title*"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="priority"
                    label="Select Priority"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Priority",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Priority*"
                      onChange={(e) => setPriority(e)}
                    >
                      {Priority.map((option) => (
                        <AntdSelect.Option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="experience"
                    label="Select Experience"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Experience",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Experience (in Years) *"
                      onChange={(e) => setExperience(e)}
                    >
                      {experianceRange.map((option) => (
                        <AntdSelect.Option
                          key={option.label}
                          value={option.label}
                        >
                          {option.label}
                        </AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </Form.Item>
                  {experience === "Other" && (
                    <Form.Item
                      name="other_experience"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Other Experience",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                    >
                      <AntdInput.TextArea
                        row={4}
                        placeholder="Enter Other Experience*"
                        onChange={(e) => setexperienceOther(e.target.value)}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="salary"
                    label="Select Salary (lpa) *"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Experience",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Salary*"
                      onChange={(e) => setSalary(e)}
                    >
                      {salaryRange.map((option) => (
                        <AntdSelect.Option
                          key={option.value}
                          value={option.label}
                        >
                          {option.label}
                        </AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </Form.Item>
                  {salary === "Other" && (
                    <>
                      <Form.Item
                        name="other_salary"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Other Salary",
                          },
                        ]}
                        labelCol={{ span: 24 }}
                      >
                        <AntdInput.TextArea
                          placeholder="Enter Other Salary"
                          rows={2}
                          onChange={(e) => setOtherSalary(e.target.value)}
                        />
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="job_type"
                    label="Select Job Type"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Job Type",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdSelect
                      placeholder="Job Type*"
                      onChange={(e) => setJobType(e)}
                    >
                      {jobTypeList.map((option) => (
                        <AntdSelect.Option
                          key={option.value}
                          value={option.label}
                        >
                          {option.label}
                        </AntdSelect.Option>
                      ))}
                    </AntdSelect>
                  </Form.Item>
                  {JobType === "Other" && (
                    <Form.Item
                      name="other_job_type"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Other Job",
                        },
                      ]}
                      labelCol={{ span: 24 }}
                    >
                      <AntdInput.TextArea
                        placeholder="Enter Other job"
                        rows={2}
                        onChange={(e) => setJobTypeOther(e.target.value)}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col md={8}>
                  <Form.Item
                    name="skill"
                    label="Skills"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Skills",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                  >
                    <AntdInput.TextArea
                      row={2}
                      placeholder="Skills*"
                      onChange={(e) => setSkill(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      gap: "10%",
                    }}
                  >
                    <div>
                      <Form.Item valuePropName="checked" name="is_campus_drive">
                        <AntdCheckbox
                          onChange={(e) => setCampusDriven(e.target.checked)}
                        >
                          Campus Drive
                        </AntdCheckbox>
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item
                        valuePropName="checked"
                        name="is_transfer_allowed"
                      >
                        <AntdCheckbox
                          onChange={(e) => setIsTransferValue(e.target.checked)}
                        >
                          Will Transfer
                        </AntdCheckbox>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col md={24}>
                  <Form.Item label="Ideal Candidate" labelCol={{ span: 24 }}>
                    <Editor
                      apiKey="c03cc5kmzse4y7i90fwrbswwrzl9g3rxbtd23ohrzt8pcd1k"
                      init={{
                        height: 200,
                        plugins: "link image code table",
                        toolbar:
                          "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | outdent indent | code", // Add 'table' to the toolbar
                      }}
                      value={editorsValue}
                      onEditorChange={(content) => setEditorsValue(content)}
                    />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <Form.Item
                    // name="description"
                    label={
                      <span>
                        Job Description <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    labelCol={{ span: 24 }}
                  >
                    <Editor
                      apiKey="c03cc5kmzse4y7i90fwrbswwrzl9g3rxbtd23ohrzt8pcd1k"
                      init={{
                        height: 200,
                        plugins: "link image code table",
                        toolbar:
                          "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | outdent indent | code", // Add 'table' to the toolbar
                      }}
                      value={editorsValues}
                      onEditorChange={(content) => setEditorsValues(content)}
                    />
                  </Form.Item>
                </Col>
                <Col md={24}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: `${successUrl ? "space-between" : "end"}`,
                    }}
                  >
                    {successUrl && (
                      <div>
                        <AntdButton
                          onClick={() => history.push({ pathname: successUrl })}
                          danger
                        >
                          Cancel
                        </AntdButton>
                      </div>
                    )}
                    <div>
                      <AntdButton
                        type="primary"
                        htmlType="submit"
                        form="position_form"
                      >
                        Next
                      </AntdButton>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </>
        )}

        {activeStep === 1 && (
          <PositionStep2
            backFunction={functionHandleback}
            functionSubmit={apply}
            paperList={QuestionPaperList}
            grade={gradeValue}
            subject={subject}
            successUrl={successUrl}
            addLoader={addLoader}
            setAddLoader={setAddLoader}
          />
        )}
      </Card>
    </React.Fragment>
  );
};

CreatePositions.propTypes = {};

export default withStyles(styles)(CreatePositions);
