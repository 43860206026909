import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Typography,
  Input,
  Col,
  message,
  Select,
  Form,
  DatePicker,
  Space,
  Button,
  Tag,
  Table,
  Modal,
  TimePicker,
  Spin,
  Tooltip,
  Badge,
} from "antd";
import axios from "axios";
import urls from "../../../url";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import AdditionalCC from "./AdditionalCC";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import {
  EditOutlined,
  SyncOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { Option } from "antd/es/mentions";
import "./style.css";

const OfferLetterListViewOptimized = () => {
  const userData = useSelector((state) => state?.auth?.user);
  const [filterForm] = useForm();
  const [modalForm] = useForm();
  const [revokeReasonModalForm] = useForm();
  const { RangePicker } = DatePicker;

  const [loading, setLoading] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openRevokeReasonModal, setOpenRevokeReasonModal] = useState(false);
  const [openForwardOfferModal, setOpenForwardOfferModal] = useState(false);
  const [editPreview, setEditPreview] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [designationName, setDesignationName] = useState();
  const [tags, setTags] = useState([]);
  const [emailSubject, setEmailSubject] = useState();
  const [emailContent, setEmailContent] = useState();
  const [dateRange, setDateRange] = useState();
  const [vacancyCode, setVacancyCode] = useState("");
  const [candidateDetails, setCandidateDetails] = useState();
  const [offerId, setOfferId] = useState();
  const [showRevoked, setShowRevoked] = useState(false);
  const [isRevoked, setIsRevoked] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewContent, setPreviewContent] = useState();
  const [ccEmailArray, setCCEmailArray] = useState([]);
  const [search, setSearch] = useState("");
  const [offerOptionList] = useState([
    {
      id: 1,
      value: "False",
      children: "Released",
    },
    {
      id: 2,
      value: "True",
      children: "Revoked",
    },
  ]);
  const [previewData, setPreviewData] = useState([
    {
      from_email: "",
      to_email: "",
      cc_email: [],
      email_subject: "",
      email_body: "",
    },
  ]);
  const [branchData, setBranchData] = useState({
    hr_email: "",
    hr_mobile: "",
    hr_name: "",
    escalation_point_email: "",
    location: "",
  });
  const ccEmailList = [
    {
      id: 1,
      email: "hr_sampark@orchids.edu.in",
    },
    {
      id: 2,
      email: "hr.corporate@orchids.edu.in",
    },
    {
      id: 3,
      email: "sai.vadlamani@orchids.edu.in",
    },
    {
      id: 4,
      email: "adil.khan@orchids.edu.in",
    },
    {
      id: 5,
      email: "rahulsingh@orchids.edu.in",
    },
  ];
  const genderList = [
    { id: "female", value: "Female", label: "Female" },
    { id: "male", value: "Male", label: "Male" },
    { id: "other", value: "Other", label: "Other" },
  ];
  const fetchPositionTypeList = () => {
    setLoading(true);
    axios
      .get(`${urls.positionTypes}`)
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchDesignationList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.designationListApi}`, {
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchTableData = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.releasedOfferLetterList}`, {
        params: params,
      })
      .then((response) => {
        setTableData(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchStateList = () => {
    setLoading(true);
    axios
      .get(`${urls.stateListApi}`)
      .then((response) => {
        setStateList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchBranchList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.branchListApi}`, {
        params: params,
      })
      .then((response) => {
        setBranchList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchPositionTypeList();
    fetchStateList();
    fetchBranchList();
  }, []);
  useEffect(() => {
    const values = filterForm.getFieldsValue();
    fetchTableData({
      page: page,
      page_size: pageSize,
      position_type: values?.position_type,
      designation: values?.designation,
      is_revoked: values?.revoke,
      start_date:
        values?.date_range && values?.date_range[0].format("YYYY-MM-DD"),
      end_date:
        values?.date_range && values?.date_range[1].format("YYYY-MM-DD"),
      search: search,
    });
  }, [page]);
  const handleFilter = () => {
    if (page === 1) {
      const values = filterForm.getFieldsValue();
      fetchTableData({
        page: page,
        page_size: pageSize,
        position_type: values?.position_type,
        designation: values?.designation,
        start_date:
          values?.date_range && values?.date_range[0].format("YYYY-MM-DD"),
        end_date:
          values?.date_range && values?.date_range[1].format("YYYY-MM-DD"),
        is_revoked: values?.revoke,
        search: search,
      });
    } else {
      setPage(1);
    }
  };
  const createPayloadData = () => {
    const values = modalForm.getFieldsValue();
    const payloadData = {
      candidate_email: values?.candidate_email,
      candidate_name: values?.candidate_name,
      gender: values?.gender,
      designation: designationName,
      school_name:
        "ORCHIDS - The International School" +
        " - " +
        branchData?.location +
        " , " +
        values?.state,
      date_of_joining: dayjs(values?.date_of_joining).format("YYYY-MM-DD"),
      designation_id: values?.designation,
      fixed_gross_per_month: values?.ctc_per_annum,
      ctc_per_annum: values?.ctc_per_annum,
      probation_period: "6 Month",
      notice_period: "1 Month",
      reporting_time: dayjs(values?.reporting_time, "HH:mm").format("hh:mm A"),
      confirm_before_date: dayjs(values?.confirm_before_date).format(
        "YYYY-MM-DD"
      ),
      submit_documents_before_date: dayjs(
        values?.submit_documents_before_date
      ).format("YYYY-MM-DD"),
      hr_name: branchData?.hr_name,
      hr_email: branchData?.hr_email,
      hr_mobile: branchData?.hr_mobile,
      escalation_point_email: branchData?.escalation_point_email,
      submited_by: userData?.academic_profile?.name,
      //   academic_type: ,
      role: "",
      recruiter_id: userData?.personal_info?.user_id,
      cc: [...values?.cc, ...tags],
      state: values?.state,
      position_type: values?.position_type,
      designation_fk: values?.designation,
      candidate_contact_no: values?.candidate_contact_no,
      branch: values?.branch,
      mrf_vacancy_code: values?.mrf_vacancy_code,
      reporting_manager: values?.reporting_manager,
    };
    return payloadData;
  };
  const handleViewPreview = () => {
    setLoading(true);
    let payloadData = createPayloadData();
    axios
      .post(
        `${urls.offerLetterRelease}?is_preview=True&is_academic=False&designation=${designationName}`,
        payloadData
      )
      .then((response) => {
        if (response?.data?.status_code === 201) {
          setOpenPreviewModal(true);
          setPreviewData({
            from_email: response?.data?.from_email,
            to_email: response?.data?.to_email,
            cc_email: response?.data?.cc_email,
            email_subject: response?.data?.email_subject,
            email_body: response?.data?.email_body,
          });
          setEmailSubject(response?.data?.email_subject);
          setEmailContent(response?.data?.email_body);
          message.success(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleReSendEmail = () => {
    setLoading(true);
    let payloadData = createPayloadData();

    // note: extra payload to add in case of sending offer letter
    payloadData = {
      ...payloadData,
      email_subject: emailSubject,
      email_content: emailContent,
      offer_id: offerId,
    };
    axios
      .post(
        `${urls.offerLetterRelease}?is_preview=Resend&is_academic=False&designation=${designationName}`,
        payloadData
      )
      .then((response) => {
        if (response?.data?.status_code === 201) {
          message.success(response?.data?.message);
          handleClosePreviewModal();
          handleCloseFormModal();
          handleFilter();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOfferOption = (val) => {
    if (val === "True") {
      setIsRevoked(true);
    } else {
      setIsRevoked(false);
    }
  };

  const handleRevokeOfferLetter = () => {
    setLoading(true);
    const values = revokeReasonModalForm.getFieldsValue();
    const formData = {
      reason: values?.reason,
      cc:
        offerId?.cc !== "None"
          ? JSON.parse(offerId?.cc?.replace(/'/g, '"')) ?? ""
          : ccEmailArray?.length
          ? ccEmailArray
          : "none",
    };
    axios
      .put(`${urls.OfferLetterRevokeApi}${offerId?.id}/`, formData)
      .then((response) => {
        if (response?.status === 200) {
          message.success("Offer Revoked Successfully");
          setOfferId(null);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        closeRevokeReasonModal();
        fetchTableData({
          page: 1,
          pageSize: pageSize,
        });
        setLoading(false);
      });
  };

  const closeRevokeReasonModal = () => {
    revokeReasonModalForm.resetFields();
    setOpenRevokeReasonModal(false);
    setCCEmailArray([]);
  };

  const handleCloseFormModal = () => {
    modalForm.resetFields();
    setDesignationName();
    setDesignationList([]);
    setTags([]);
    setBranchData([]);
    setOpenFormModal(false);
  };

  const handleOpenReSendOfferLetterModal = (data) => {
    modalForm.setFieldsValue({
      candidate_name: data?.candidate_name,
      candidate_email: data?.candidate_email,
      candidate_contact_no: data?.candidate_contact_no,
      gender: data?.gender,
      position_type: data?.position_type,
      designation: data?.designation_fk,
      state: data?.state,
      branch: data?.branch,
      date_of_joining: dayjs(data?.date_of_joining),
      ctc_per_annum: data?.fixed_gross_per_month,
      reporting_time: dayjs(data?.reporting_time, "hh:mm"),
      submit_documents_before_date: dayjs(data?.submit_documents_before_date),
      confirm_before_date: dayjs(data?.confirm_before_date),
      cc_email: "",
      reporting_manager: data?.reporting_manager,
    });
    fetchDesignationList({
      position_type: data?.position_type,
    });
    setCandidateDetails(data);
    setDesignationName(data?.designation);
    setBranchData({
      hr_email: data?.hr_email,
      hr_mobile: data?.hr_mobile,
      hr_name: data?.hr_name,
      escalation_point_email: data?.escalation_point_email,
      location: data?.location,
    });
    setOfferId(data?.id);
    setOpenFormModal(true);
  };

  const handleClosePreviewModal = () => {
    setOpenPreviewModal(false);
    setPreviewData([
      {
        from_email: "",
        to_email: "",
        cc_email: [],
        email_subject: "",
        email_body: "",
      },
    ]);
    setEmailContent();
    setEmailSubject();
    setEditPreview(false);
  };
  const [bottomHRef, setBottomHRef] = useState("");
  const handleDownloadReport = () => {
    let formData = filterForm.getFieldsValue();

    if (formData.date_range) {
      let startDate = dayjs(formData.date_range[0]).format("YYYY-MM-DD");
      let endDate = dayjs(formData.date_range[1]).format("YYYY-MM-DD");
      let url = formData?.revoke
        ? `${
            urls.offerCsv
          }?active=${true}&start_date=${startDate}&end_date=${endDate}&is_revoked=${
            formData?.revoke == "True" ? true : false
          }&download=true`
        : `${
            urls.offerCsv
          }?active=${true}&start_date=${startDate}&end_date=${endDate}&download=true`;
      let a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      a.click();
      a.remove();
    }
  };

  const handlePreview = (data) => {
    console.log(data);
    setPreviewModal(true);
    setPreviewContent(data);
  };

  const handleClosePreview = () => {
    setPreviewModal(false);
    setPreviewContent({});
  };

  const handleAddEmail = () => {
    const { add_cc } = revokeReasonModalForm.getFieldsValue();
    if (!ccEmailArray.includes(add_cc)) {
      setCCEmailArray([...ccEmailArray, add_cc]);
      revokeReasonModalForm.setFieldsValue({ add_cc: "" });
    }
  };

  const offerOption = offerOptionList?.map((each) => (
    <Option key={each?.id} value={each?.value}>
      {each?.children}
    </Option>
  ));

  const columns = [
    {
      title: "ID",
      key: "id",
      render: (data) => <span>{data?.applicant ? data?.applicant : "-"}</span>,
    },
    {
      title: "Offer Released On",
      key: "release_date",
      align: "center",
      render: (data, record) => (
        <>
          <div>{data?.created_date}</div>
          {record?.is_resend ? (
            <Badge
              count="Revised"
              color="blue"
              style={{
                marginRight: "10px",
                justifyItems: "center",
              }}
              size="small"
            />
          ) : (
            ""
          )}
        </>
      ),
      //  ),
    },
    {
      title: "MRF Vacancy Code",
      key: "mrf_vacancy_code",
      align: "center",
      dataIndex: "vacancy_code",
      render: (data, record) => (
        <span>{record?.mrf_vacancy_code ?? "No Data"}</span>
      ),
    },
    {
      title: "Applicant Name",
      key: "applicant_name",
      render: (data) => <span>{data?.candidate_name}</span>,
    },
    {
      title: "Contact No",
      key: "contact_no",
      render: (data) => <span>{data?.candidate_contact_no ?? "No Data"}</span>,
    },
    {
      title: "Position Type",
      key: "position_type_name",
      render: (data) => <span>{data?.position_type_name ?? "No Data"}</span>,
    },
    {
      title: "Designation",
      key: "designation",
      render: (data) => <span>{data?.designation ?? "No Data"}</span>,
    },
    {
      title: "Date Of Joining",
      key: "date_of_joining",
      align: "center",
      render: (data) => (
        <span>
          {data?.date_of_joining !== "Invalid Date"
            ? data?.date_of_joining ?? "-"
            : "-"}
        </span>
      ),
    },
    {
      title: "CTC (LPA)",
      key: "ctc",
      align: "center",
      render: (data) => <span>{data?.fixed_gross_per_month ?? "-"}</span>,
    },
    {
      title: "Recruiter",
      key: "recruiter",
      align: "center",
      render: (data) => <span> {data?.submited_by ?? "-"}</span>,
    },
    {
      title: "Revoked By",
      key: "revoked_by",
      align: "center",
      className: `${!isRevoked ? "myDisplay" : ""}`,
      render: (data) => (
        <span> {data?.offer_letter_revoked_by_name ?? "-"}</span>
      ),
    },
    {
      title: "Re-Send - Count",
      key: "Re-Send",
      className: `${isRevoked ? "myDisplay" : ""}`,
      render: (data, record, i) => (
        <span>
          <span>Count: {data?.resend_count}</span>
        </span>
      ),
    },
    {
      title: "Preview",
      key: "preview",
      render: (data) => (
        <Tag
          color="processing"
          style={{ fontStyle: "italic", cursor: "pointer" }}
          onClick={() => handlePreview(data)}
        >
          {" "}
          Preview
        </Tag>
      ),
    },
    {
      title: "Revoke",
      key: "Revoke",
      render: (data, record, i) => (
        <span>
          {data?.is_revoked ? (
            <Tooltip title={`Reason: ${data?.revoked_reason}`}>
              <span style={{ color: "red", fontStyle: "italic" }}>
                Revoked <InfoCircleOutlined />
              </span>
            </Tooltip>
          ) : (
            <Tag
              color="error"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOfferId(data);
                setOpenRevokeReasonModal(true);
              }}
            >
              Revoke
            </Tag>
          )}
        </span>
      ),
    },
  ];

  return (
    <>
      <Card>
        <Row
          gutter={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 16 }}
          >
            <Typography className="page_title">
              Released Offer Letter List{" "}
            </Typography>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={6}
            style={{ display: "flex", gap: "10px" }}
          >
            <Form.Item>
              <Input
                placeholder="Search candidate"
                onChange={(e) => setSearch(e.target.value)}
                allowClear
              />
            </Form.Item>
            <Button type="primary" onClick={handleFilter}>
              Search
            </Button>
          </Col>
        </Row>
        <Form
          id="filterForm"
          form={filterForm}
          onFinish={handleFilter}
          layout="vertical"
        >
          <Row
            align="middle"
            gutter={24}
            style={{
              marginBottom: "20px",
            }}
          >
            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
              <Form.Item name="date_range" label="Start Date - End Date">
                <RangePicker
                  onChange={(e) => {
                    setDateRange(e);
                  }}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={6}>
              <Form.Item name="revoke" label="Offer Letter Type">
                <Select
                  showSearch
                  allowClear
                  onChange={handleOfferOption}
                  placeholder="Select Type"
                  style={{ width: "100%" }}
                >
                  {offerOption}
                </Select>
              </Form.Item>
            </Col>
            <Col md={3}>
              <Button
                style={{ width: "100%" }}
                form="filterForm"
                htmlType="submit"
                type="primary"
              >
                Filter
              </Button>
            </Col>
            <Col md={3}>
              <Button
                // style={{ width: "100%" }}
                type="primary"
                // href={bottomHRef && bottomHRef[0].csv}
                disabled={!dateRange}
                onClick={handleDownloadReport}
              >
                Download CSV
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={tableData?.results}
          pagination={{
            showSizeChanger: false,
            total: tableData?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
        />
      </Card>
      {openFormModal && (
        <Modal
          title={
            <Typography className="page_title">
              Re-Sending Offer Letter
            </Typography>
          }
          open={openFormModal}
          centered
          onCancel={handleCloseFormModal}
          maskClosable={false}
          width={1000}
          style={{ maxHeight: "90vh", overflow: "auto" }}
          footer={[
            <Row justify="space-around">
              <Col>
                <Button type="default" onClick={handleCloseFormModal}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  form="modalForm"
                  htmlType="submit"
                  type="primary"
                  icon={loading ? <SyncOutlined spin /> : null}
                >
                  Preview
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form
            id="modalForm"
            form={modalForm}
            layout="vertical"
            onFinish={handleViewPreview}
          >
            <Row align="middle" gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="candidate_name"
                  label="First Name, Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Candidate Name",
                    },
                  ]}
                >
                  <Input placeholder="Enter Candidate Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="candidate_email"
                  label="Candidate Email"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Candidate Email",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const filterEmailId = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!value || value.match(filterEmailId)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter a Valid Email")
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Candidate Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="candidate_contact_no"
                  label="Candidate Mobile No."
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Candidate Mobile No.",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const filterMobile = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
                        if (!value || value.match(filterMobile)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter a Valid Mobile Number")
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Enter Candidate Mobile No." />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Gender",
                    },
                  ]}
                >
                  <Select placeholder="Select Gender">
                    {genderList &&
                      genderList.length !== 0 &&
                      genderList.map((option, index) => {
                        return (
                          <Select.Option key={index} value={option?.id}>
                            {option?.label}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="position_type"
                  label="Position Type"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Position Type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Position Type"
                    onChange={() => {
                      const position_type = modalForm.getFieldsValue()
                        ?.position_type;
                      modalForm.setFieldsValue({
                        designation: null,
                      });
                      if (position_type) {
                        fetchDesignationList({
                          position_type: position_type,
                        });
                      } else {
                        setDesignationList([]);
                        setDesignationName();
                      }
                    }}
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {positionTypeList?.map((each) => (
                      <Select.Option key={each?.id} value={each?.id}>
                        {each?.position_type}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="designation"
                  label="Designation"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Designation",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Designation"
                    onChange={(e, val) => setDesignationName(val?.children)}
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {designationList?.map((each) => {
                      return (
                        <Select.Option key={each?.id} value={each?.id}>
                          {each?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    {
                      required: true,
                      message: "Please Select State",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select State"
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {stateList &&
                      stateList.length !== 0 &&
                      stateList.map((option, index) => {
                        return (
                          <Select.Option key={index} value={option?.state_name}>
                            {option?.state_name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="branch"
                  label="Branch"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Branch",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Branch"
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    onChange={(e, val) =>
                      setBranchData({
                        location: val?.data?.branch?.city_id?.city_name,
                        escalation_point_email: val?.data?.first_st_poc_email,
                        hr_name: val?.data?.first_st_escalation_point_name,
                        hr_mobile: val?.data?.first_st_poc_contact_number,
                        hr_email: val?.data?.first_escalation_point_email,
                      })
                    }
                  >
                    {branchList &&
                      branchList.length !== 0 &&
                      branchList.map((option, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={option?.branch?.id}
                            data={option}
                          >
                            {option?.branch?.branch_name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              {candidateDetails?.mrf_vacancy_code &&
                candidateDetails?.mrf_vacancy_code?.length > 0 && (
                  <Col span={12}>
                    <Form.Item
                      name="mrf_vacancy_code"
                      label="Vacancy Code"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Vacancy Code",
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          setVacancyCode(e);
                        }}
                        placeholder="Vacancy Code"
                        className="w-100"
                      >
                        {candidateDetails?.mrf_vacancy_code?.map((each) => (
                          <Select.Option
                            disabled={each?.is_used}
                            key={each?.id}
                            value={each?.id}
                          >
                            {each?.code} {each?.is_used ? "(Occupied)" : ""}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              <Col span={12}>
                <Form.Item
                  name="date_of_joining"
                  label="Date of Joining"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date of Joining",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => {
                      return current < dayjs().startOf("day");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ctc_per_annum"
                  label="Fixed Gross Per Annum in Digits"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Salary Details",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Salary Details"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="reporting_time"
                  label="Reporting Time"
                  rules={[
                    {
                      required: true,
                      message: "Please Reporting Time",
                    },
                  ]}
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    use12Hours
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="submit_documents_before_date"
                  label="Submit Documents Before Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Submit Documents Before Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => {
                      return current < dayjs().startOf("day");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="confirm_before_date"
                  label="Confirm Before Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Submit Documents Before Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => {
                      return current < dayjs().startOf("day");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="reporting_manager"
                  label="Reporting Manager Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter reporting manager email",
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const filterEmailId = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!value || value.match(filterEmailId)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please Enter a Valid Email")
                        );
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Reporting Manager" className="w-100" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="cc"
                  label="CC"
                  rules={[
                    {
                      required: true,
                      message: "Please Select CC Mails",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    maxTagCount={3}
                    placeholder="Select CC Mails"
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {ccEmailList &&
                      ccEmailList.length !== 0 &&
                      ccEmailList.map((option, index) => {
                        return (
                          <Select.Option key={index} value={option?.email}>
                            {option?.email}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Additional CC's">
                  <AdditionalCC tags={tags} setTags={setTags} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}

      {openPreviewModal && (
        <Modal
          title={
            <Space style={{ width: "100%", justifyContent: "space-between" }}>
              <Typography className="page_title">
                Re-Sending Offer Letter Preview"
              </Typography>
              <Space>
                <Button
                  onClick={() => setEditPreview(true)}
                  icon={<EditOutlined style={{ fontSize: "14px" }} />}
                >
                  Edit
                </Button>
                <Button onClick={handleClosePreviewModal}>Close</Button>
              </Space>
            </Space>
          }
          open={openPreviewModal}
          // onCancel={handleCloseViewPreview}
          centered
          closeIcon={null}
          width={1000}
          style={{ maxHeight: "90vh", overflow: "auto" }}
          footer={[
            <Row justify="space-around">
              <Col>
                <Button type="default" onClick={handleClosePreviewModal}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => handleReSendEmail()}
                  icon={loading ? <SyncOutlined spin /> : null}
                  type="primary"
                >
                  Send
                </Button>
              </Col>
            </Row>,
          ]}
        >
          {loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "230px",
              }}
            >
              <Spin size="large" />
            </div>
          )}
          {!loading && (
            <>
              <div style={{ marginBottom: "50px" }}>
                <div style={{ marginLeft: "50px" }}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <strong>
                      From:<span style={{ visibility: "hidden" }}>H</span>
                    </strong>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: previewData?.from_email,
                      }}
                    />
                  </div>
                  <br />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <strong>
                      To:<span style={{ visibility: "hidden" }}>H</span>
                    </strong>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: previewData?.to_email,
                      }}
                    />
                  </div>
                  <br />
                  <strong>CC:</strong>
                  {previewData?.cc_email &&
                    previewData?.cc_email?.length > 0 &&
                    previewData?.cc_email.map((tag, i) => (
                      <span key={i}>
                        {i > 0 && " "}
                        <ul>
                          <li>
                            <div dangerouslySetInnerHTML={{ __html: tag }} />
                          </li>
                        </ul>
                      </span>
                    ))}
                  <br />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    <strong>
                      Subject:<span style={{ visibility: "hidden" }}>H</span>
                    </strong>
                    {editPreview ? (
                      <>
                        <TextArea
                          value={emailSubject}
                          onChange={(e) => setEmailSubject(e.target.value)}
                        />
                      </>
                    ) : (
                      <div
                        style={{ maxWidth: "100%" }}
                        dangerouslySetInnerHTML={{
                          __html: previewData?.email_subject,
                        }}
                      />
                    )}
                  </div>
                  {editPreview ? (
                    <Editor
                      apiKey="c03cc5kmzse4y7i90fwrbswwrzl9g3rxbtd23ohrzt8pcd1k"
                      init={{
                        height: 500,
                        plugins: "link image code table",
                        toolbar:
                          "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | outdent indent | code",
                      }}
                      value={emailContent}
                      onEditorChange={(content) => setEmailContent(content)}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: previewData?.email_body,
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </Modal>
      )}

      {openRevokeReasonModal && (
        <Modal
          title="Reason for Revoking"
          open={openRevokeReasonModal}
          onCancel={closeRevokeReasonModal}
          centered
          width={500}
          style={{ maxHeight: "90vh", overflow: "auto" }}
          footer={[
            <Row justify="space-around">
              <Col>
                <Button type="default" onClick={closeRevokeReasonModal}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  form="revokeReasonModalForm"
                  htmlType="submit"
                  icon={loading ? <SyncOutlined spin /> : null}
                >
                  Revoke
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form
            id="revokeReasonModalForm"
            form={revokeReasonModalForm}
            layout="vertical"
            onFinish={handleRevokeOfferLetter}
          >
            {offerId?.cc && (
              <>
                <div>
                  <Space
                    wrap
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <span>CCs :</span>
                    {offerId?.cc !== "None"
                      ? JSON.parse(offerId?.cc?.replace(/'/g, '"'))?.map(
                          (each) => <Tag>{each}</Tag>
                        )
                      : ccEmailArray?.map((each) => <Tag>{each}</Tag>)}
                  </Space>
                </div>
                {offerId?.cc == "None" ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Form.Item
                      name="add_cc"
                      style={{ width: "300px", marginTop: "25px" }}
                    >
                      <Input placeholder="Add CC Email" />
                    </Form.Item>
                    <Button type="primary" onClick={handleAddEmail}>
                      Add
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            <Row align="middle" gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="reason"
                  label="Reason"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Reason",
                    },
                  ]}
                >
                  <TextArea
                    rows={3}
                    maxLength={1500}
                    showCount
                    placeholder="Enter Reason"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      <Modal
        open={previewModal}
        onCancel={handleClosePreview}
        footer={null}
        width={1000}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        title={
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography className="page_title">{"Offer Letter"}</Typography>
          </Space>
        }
      >
        <div style={{ marginBottom: "50px" }}>
          <div style={{ marginLeft: "50px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <strong>To: {"  "}</strong>
              {"  "}
              <div
                dangerouslySetInnerHTML={{
                  __html: previewContent?.candidate_email,
                }}
              />
            </div>
            <br />
            <strong>CC:</strong>
            {previewContent?.cc != "None" &&
              (previewContent?.cc &&
                JSON.parse(previewContent?.cc?.replace(/'/g, '"'))?.map(
                  (tag, i) => (
                    <span key={i}>
                      {i > 0 && " "}
                      <ul>
                        <li>
                          <div dangerouslySetInnerHTML={{ __html: tag }} />
                        </li>
                      </ul>
                    </span>
                  )
                ))}
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              <strong>
                Subject:<span style={{ visibility: "hidden" }}>H</span>
              </strong>

              <div
                style={{ maxWidth: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: previewContent?.email_subject,
                }}
              />
            </div>

            <div
              dangerouslySetInnerHTML={{
                __html: previewContent?.offer_letter,
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OfferLetterListViewOptimized;
