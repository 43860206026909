import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Button,
  Table,
  Tag,
  Modal,
  message,
  Tooltip,
  Typography,
  Drawer,
} from "antd";
import axios from "axios";
import moment from "moment";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Profile from "../../applicant/profile/profileNew";
import { useAlert } from "../../../hoc/alert/alert";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";

const SearchApplicantProfileNew = () => {
  const { Option } = Select;
  const [searchApplicantProfileForm] = useForm();
  const [reassignForm] = useForm();
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [applicantId, setApplicantId] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [Role, setRole] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [search, setSearch] = useState("");
  const [branch, setBranch] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [openingList, setOpeningList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [applicantList, setApplicantList] = useState([]);

  useEffect(() => {
    fetchPositionTypes();
    fetchCityList();
  }, []);

  useEffect(() => {
    fetchApplicantsList();
  }, [page]);

  useEffect(() => {
    const id = setTimeout(() => {
      fetchApplicantsList();
    }, 2000);
    return () => clearTimeout(id);
  }, [search]);

  function fetchPositionTypes() {
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  }
  const handlePositionTypesChange = (e, val) => {
    setPositionType(val?.value);
    const position_type = searchApplicantProfileForm.getFieldsValue()
      ?.position_type;
    searchApplicantProfileForm.setFieldsValue({
      role: null,
    });
    if (position_type) {
      fetchDesignationList({
        position_type: position_type,
      });
      fetchOpeningList({
        position_type: position_type,
      });
    } else {
      setDesignationList([]);
    }
  };

  const fetchDesignationList = (params = {}) => {
    axios
      .get(`${urls.designationListApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        setDesignationList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const handleDesignationChange = (e, val) => {
    setRoleId(val?.value);
    const { position_type, role } = searchApplicantProfileForm.getFieldsValue();
    if (position_type && role) {
      fetchOpeningList({
        position_type: position_type,
        role: role,
      });
    }
  };

  const fetchCityList = () => {
    setLoading(true);
    axios
      .get(`${urls.cityList}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCityList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCityChange = (e, val) => {
    setCheckedLoc(val?.value);
    const city = searchApplicantProfileForm.getFieldsValue()?.city;
    searchApplicantProfileForm.setFieldsValue({
      branch: null,
    });
    if (city) {
      fetchBranchList({
        city_id: city,
      });
    } else {
      setBranchList([]);
    }
  };

  const fetchBranchList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.branchCityMapping}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setBranchList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchOpeningList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.ViewOpenningsApi}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
      })
      .then((response) => {
        if (response.status === 200) {
          setOpeningList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchApplicantsList = () => {
    const {
      position_type,
      role,
      branch,
      city,
      start_date,
      end_date,
    } = searchApplicantProfileForm.getFieldsValue();

    let obj = {
      position_type_fk: position_type,
      designation_fk: role,
      page: page,
      page_size: rowsPerPage,
    };

    if (branch) {
      obj.branch_id = branch;
    }
    if (city) {
      obj.city_id = city;
    }
    if (start_date) {
      obj.start_date = dayjs(start_date).format("YYYY-MM-DD");
    }
    if (end_date) {
      obj.end_date = dayjs(end_date).format("YYYY-MM-DD");
    }
    if (search) {
      obj.search = search;
    }

    setLoading(true);
    axios
      .get(`${urls.applicantsProfiles}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: obj,
      })
      .then((response) => {
        if (response.status === 200) {
          setApplicantList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (data) => <span>{data?.applicant?.name ?? "-"}</span>,
    },
    {
      title: "Contact No",
      key: "contact_no",
      render: (data) => <span>{data?.applicant?.contact_no ?? "-"}</span>,
    },
    {
      title: "Position",
      key: "position",
      render: (data) => <span>{data?.position_type_name ?? "-"}</span>,
    },
    {
      title: "Designation",
      key: "designation",
      render: (data) => <span>{data?.designation_name ?? "-"}</span>,
    },
    {
      title: "City",
      key: "city",
      render: (data) => <span> {data?.city_name ?? "-"}</span>,
    },
    {
      title: "Branch",
      key: "branch",
      render: (data) => <span> {data?.branch_name ?? "-"}</span>,
    },
    {
      title: "Grade",
      key: "branch",
      render: (data) => <span> {data?.grade_name ?? "-"}</span>,
    },
    {
      title: "Subject",
      key: "branch",
      render: (data) => <span> {data?.subject_name ?? "-"}</span>,
    },
    {
      title: "Process Status",
      key: "process",
      width: "10%",
      render: (data) => (
        <span>
          <Tag>{data?.current_status?.title ?? "-"}</Tag>
        </span>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between">
        <Col span={18} className="page_title">
          Search Applicant Profile
        </Col>
        <Col span={6}>
          <Input
            placeholder="I'm Looking For..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>{" "}
      <div className="page_body">
        <Form
          id="searchApplicantProfileForm"
          form={searchApplicantProfileForm}
          layout="vertical"
          onFinish={fetchApplicantsList}
        >
          <Row align="middle" gutter={24}>
            <Col span={6}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Position Type"
                  onChange={(e, val) => handlePositionTypesChange(e, val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {positionTypeList &&
                    positionTypeList.length !== 0 &&
                    positionTypeList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.position_type}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  onChange={(e, val) => handleDesignationChange(e, val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {designationList &&
                    designationList.length !== 0 &&
                    designationList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="city" label="City">
                <Select
                  placeholder="Select City"
                  onChange={(e, val) => handleCityChange(e, val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {cityList &&
                    cityList.length !== 0 &&
                    cityList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.city_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="branch" label="Branch">
                <Select
                  placeholder="Select Branch"
                  onChange={(e, val) => setBranch(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {branchList &&
                    branchList.length !== 0 &&
                    branchList.map((option, index) => {
                      return (
                        <Option key={option?.value} value={option?.id}>
                          {option?.branch_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item name="opeening" label="Opening">
                <Select
                  placeholder="Select Opening"
                  onChange={(e, val) => setViewOpennings(e, val)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {openingList &&
                    openingList.length !== 0 &&
                    openingList.map((option, index) => {
                      return (
                        <Option key={option?.id} value={option?.id}>
                          {option?.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col> */}

            <Col span={6}>
              <Form.Item name="start_date" label="Start Date">
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e, val) => handleStartDateChange(val)}
                  disabledDate={(current) => {
                    return current > moment(endDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="end_date" label="End Date">
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e, val) => handleEndDateChange(val)}
                  disabledDate={(current) => {
                    return current < moment(startDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Button
                form="searchApplicantProfileForm"
                htmlType="submit"
                type="primary"
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={applicantList?.results}
          pagination={{
            showSizeChanger: false,
            total: applicantList?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
        />
      </div>
    </>
  );
};

export default SearchApplicantProfileNew;
