import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  TableFooter,
  TablePagination,
  Paper,
  InputBase,
  Select,
  TableContainer,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import urls from "../../../url";
import Modal from "../../../hoc/modal/modal";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./assignOtherRecruiter.style";
import { useAlert } from "../../../hoc/alert/alert";
import DownloadLink from "react-download-link";
import CloseIcon from '@material-ui/icons/Close';

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     'aria-controls': `scrollable-auto-tabpanel-${index}`,
//   };
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AssignOtherRecruiter = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [value, setValue] = React.useState(0);
  const [Id, setId] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [isChecked, setIsChecked] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [testPaper, setTestPaper] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [profileId, setProfileId] = useState("");
  const [applicentName, setApplicentName] = useState("");
  const alert = useAlert();
  const [searchApplicantData, setSearchApplicantData] = useState();
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [viewOpenning, setViewOpennings] = useState("");

  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id);
    if (profileDetail[newValue].applicant_role_name === "Teacher") {
      setProfileId(2);
    } else if (profileDetail[newValue].applicant_role_name === "Coordinator") {
      setProfileId(3);
    } else if (profileDetail[newValue].applicant_role_name === "Principal") {
      setProfileId(4);
    }
  }

  const {
    data: RolesData,
    isLoading: RolesDataLoading,
    doFetch: FetchRoles,
  } = useFetch(null);

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch("", { suppressAlert: true });

  const {
    data: applicantDetail,
    isLoading: applicantDetailLoading,
    doFetch: fetchApplicantDetail,
  } = useFetch([], { suppressAlert: true });

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings,
  } = useFetch("", { suppressAlert: true });

  const {
    data: paperList,
    isLoading: paperListLoading,
    doFetch: fetchPaperList,
  } = useFetch("", { suppressAlert: true });

  const {
    data: paperAssigned,
    isLoading: paperAssignedLoading,
    doFetch: fetchPaperAssigned,
  } = useFetch("", { suppressAlert: true });

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList,
  } = useFetch("", { suppressAlert: true });

  const positionTypeList = [
    {
      value: "1",
      label: "Academic",
    },
    {
      value: "2",
      label: "Non Academic",
    },
    {
      value: "3",
      label: "IT Recruitment",
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    },
  ];

  let loader = null;
  if (
    paperAssignedLoading ||
    paperListLoading ||
    applicantDetailLoading ||
    applicantsListLoading ||
    profileDetailLoading
  ) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [RoleId, positionType]);

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [positionType]);

  useEffect(() => {
    if (paperAssigned) {
      fetchApplicant({
        url: `${
          urls.reassignRetrieveApplicant
        }?position_id=${viewOpenning}&role_id=${RoleId}${
          startDate ? `&start_date=${startDate}` : ""
        }${endDate ? `&end_date=${endDate}` : ""}&page=${page +
          1}&page_size=${rowsPerPage || 50}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setIsChecked({});
      setSelectAll(false);
      setTestPaper("");
    }
  }, [paperAssigned, fetchApplicant, name, page, rowsPerPage]);

  useEffect(() => {
    fetchPaperList({
      url: urls.retrieveRecuriterPanel,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }, [Id, fetchPaperList, name]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const handleGetData = () => {
    // if (startDate == null) {
    //   alert.warning("Please Select Start Date..!");
    //   return;
    // }
    // if (endDate == null) {
    //   alert.warning("Please Select End Date..!");
    //   return;
    // }
    setPage(0);
    setIsChecked({});
    setSelectAll(false);
    fetchApplicant({
      url: `${
        urls.reassignRetrieveApplicant
      }?position_id=${viewOpenning}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=${page + 1 }&page_size=${rowsPerPage ||
        50}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  // useEffect(() => {
  //   handleGetData();
  // },[page,rowsPerPage])

  function updateHandler(ID, hr) {
    const firstName = name.personal_info.first_name;
    setEdit(firstName === hr ? false : true);
    setOpen(true);
    fetchApplicantDetail({
      url: `${urls.applicantViewProfileRetrieve}?id=${ID}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const applyFilter = () => {
    setPage(0);
    setIsChecked({});
    setSelectAll(false);
    fetchApplicant({
      url: `${urls.reassignRetrieveApplicant}?position_id=${viewOpenning}${
        startDate ? `&start_date=${startDate}` : ""
      }${
        endDate ? `&end_date=${endDate}` : ""
      }&role_id=${RoleId}&page=1&page_size=${rowsPerPage || 10}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  useEffect(() => {
    if (page || rowsPerPage) {
      fetchApplicant({
        url: `${
          urls.reassignRetrieveApplicant
        }?position_id=${viewOpenning}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=${page +
          1}&page_size=${rowsPerPage || 50}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [page, fetchApplicant, rowsPerPage, name]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(50);
    
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }

  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(50);
  }

  const checkboxChangeHandler = (e, id) => {
    const checked = { ...isChecked };
    if (e.target.checked) {
      checked[id] = true;
    } else {
      checked[id] = false;
      setSelectAll(false);
    }
    setIsChecked(checked);
  };

  const checkAllLeadsChangeHandler = (e) => {
    const checked = {};
    if (applicantsList && applicantsList.result.length) {
      applicantsList.result.forEach((ele) => {
        checked[ele.id] = e.target.checked;
      });
      setIsChecked(checked);
      setSelectAll(!selectAll);
    }
  };

  const assignPaper = () => {
    const checkedRow = Object.keys(isChecked).filter((ele) => isChecked[ele]);
    if (checkedRow.length === 0) {
      alert.warning("select to assign");
      return;
    }
    if (!testPaper) {
      alert.warning("select Test Paper");
      return;
    }
    const body = {
      applicant: checkedRow,
      recruiter: testPaper,
      // role: Id
    };
    fetchPaperAssigned({
      url: urls.reassignedToRecruiterAPIView,
      method: "put",
      body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  function profileTable() {
    let applicantTable = null;
    if (
      applicantsList &&
      applicantsList.result &&
      applicantsList.result.length === 0
    ) {
      applicantTable = (
        <React.Fragment>
          <Typography
            style={{ textAlign: "center", color: "blue", marginTop: "100px" }}
            variant="h4"
          >
            Records Not Found
          </Typography>
        </React.Fragment>
      );
    }
    // assignotherrecruiter

    if (
      applicantsList &&
      applicantsList.result &&
      applicantsList.result.length
    ) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{ marginTop: "10px" }}>
            <TableContainer className="role">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{border: '1px solid black' }}>
                    <input
                      type="checkbox"
                      style={{ width: "20px", height: "20px" }}
                      checked={selectAll}
                      onChange={checkAllLeadsChangeHandler}
                    />
                  </TableCell>
                  <TableCell align="center" style={{border: '1px solid black' }}><strong>Name</strong></TableCell>
                  <TableCell align="center" style={{border: '1px solid black' }}><strong>Contact</strong></TableCell>
                  <TableCell align="center" style={{border: '1px solid black' }}><strong>Recruiter</strong></TableCell>
                  <TableCell align="center" style={{border: '1px solid black' }}><strong>Grade</strong></TableCell>
                  <TableCell align="center" style={{border: '1px solid black' }}><strong>Branch Interested</strong></TableCell>
                  <TableCell align="center" style={{border: '1px solid black' }}><strong>Details</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicantsList.result.map((details, i) => (
                  <TableRow key={details.id}>
                    <TableCell align="center" style={{border: '1px solid black' }}>
                      <input
                        type="checkbox"
                        style={{ width: "20px", height: "20px" }}
                        checked={isChecked[details.id]}
                        onChange={(e) => {
                          checkboxChangeHandler(e, details.id);
                        }}
                      />
                    </TableCell>
                    <TableCell align="left" style={{border: '1px solid black' }}>{details.name || ""}</TableCell>
                    <TableCell align="left" style={{border: '1px solid black' }}>
                      {details.contact_no || ""}
                    </TableCell>

                    <TableCell align="left" style={{border: '1px solid black' }}>{details.Hr_name || ""}</TableCell>
                    <TableCell align="left" style={{border: '1px solid black' }}>{details.grade || ""}</TableCell>
                    <TableCell align="left" style={{border: '1px solid black' }}>{details.branch || ""}</TableCell>
                    <TableCell align="center" style={{border: '1px solid black' }}>
                      <Button
                        className={classes.addButton}
                        // color="primary"
                        size="small"
                        variant="contained"
                        onClick={() =>
                          updateHandler(details.id, details.Hr_name)
                        }
                      >
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={5}
                    labelDisplayedRows={() =>
                      `Page ${page + 1} of ${+applicantsList.total_pages}`
                    }
                    rowsPerPageOptions={[50, 75, 100]}
                    rowsPerPage={rowsPerPage || 10}
                    count={+applicantsList.count}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: "13px" }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        lastPageChange(applicantsList.total_pages - 1)
                      }
                      disabled={page === +applicantsList.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
            </TableContainer>
            <Grid container spacing={3} style={{ marginTop: "6px" }}>
              <Grid item md={3} xs={12}>
                <TextField
                  label="Recruiter"
                  margin="dense"
                  required
                  fullWidth
                  className="textList"
                  style={{marginLeft:"10px"}}
                  select
                  // SelectProps={{ multiple: true }}
                  value={testPaper}
                  onChange={(e) => setTestPaper(e.target.value)}
                  variant="outlined"
                >
                  {paperList &&
                    paperList.map((data) => (
                      <MenuItem
                        value={data.id}
                        key={data.id}
                        name={data.first_name}
                      >
                        {data.first_name ? data.first_name : ""}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item md={3} xs={10}>
                <Button
                  className={classes.addButton}
                  // color="primary"
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={assignPaper}
                >
                  Assign
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </React.Fragment>
      );
    }
    return applicantTable;
  }

  function idFunction() {
    if (profileId === 4) {
      return (
        <Grid item md={4} className={classes.gridOne}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="h2">
                6.Cultural Fit Round{" "}
                {applicantDetail &&
                applicantDetail.f2f_score.length !== 0 &&
                (
                  applicantDetail &&
                  applicantDetail.f2f_score.length !== 0 &&
                  applicantDetail.f2f_score
                    .filter(
                      (check) => check.round.round_name === "Cultural Fit Round"
                    )
                    .map((items) => {
                      return items.mark_scored;
                    })
                ).length !== 0 ? (
                  <a>
                    Completed{" "}
                    <Typography variant="h6">
                      Marks=
                      {(applicantDetail &&
                        applicantDetail.f2f_score.length > 0 &&
                        applicantDetail.f2f_score
                          .filter(
                            (check) =>
                              check.round.round_name === "Cultural Fit Round"
                          )
                          .map((items) => {
                            return items.mark_scored;
                          })) ||
                        "Not Uploaded"}
                    </Typography>
                  </a>
                ) : (
                  " Pending"
                )}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      );
    }
  }

  let modal = null;
  if (applicantDetail) {
    modal = (
      <Modal click={handleClose} large style={{ padding: "15px" }} open={open}>
        <React.Fragment>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Typography  className="headings" variant="h4">
            {" "}
            <strong> Details</strong>
          </Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon/>
        </IconButton>
        </div>
          <Divider className={classes.divider} />
          <Grid container >
            <Grid item md={6} xs={12} className={classes.gridOne}>
              <Typography className="textDisplay" variant="h5">
                <strong> Name :</strong>{" "}
                {applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.user &&
                  applicantDetail.applicant.user.first_name}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridOne}>
              <Typography className="textDisplay" variant="h5">
                <strong>PhoneNo :</strong>{" "}
                {applicantDetail &&
                  applicantDetail.applicant &&
                  applicantDetail.applicant.contact_no}
              </Typography>
            </Grid>

            <Grid item md={5} className={classes.gridOne}>
              <div style={{ display: "flex" }}>
              <Typography className="textDisplay"  variant="h5">
                <strong>Email :</strong>{" "}
                {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.email) ||
                    ""}
              </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Email:{" "}
                  {(applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.user &&
                    applicantDetail.applicant.user.email) ||
                    ""}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
              <Typography className="textDisplay"  variant="h5">
                <strong>Grade :</strong>{" "}
                {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name}
              </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Grade:{" "}
                  {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.grade &&
                    applicantDetail.applicant.relevant_fk.grade.grade_name}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
              <Typography className="textDisplay"  variant="h5">
                <strong>Position Applied :</strong>{" "}
                {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name}
              </Typography>

                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Position Applied:{" "}
                  {applicantDetail &&
                    applicantDetail.applicant &&
                    applicantDetail.applicant.applicant_role &&
                    applicantDetail.applicant.applicant_role
                      .applicant_role_name}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
              <Typography className="textDisplay"  variant="h5">
                <strong> Branch Interested :</strong>{" "}
                {applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name}
              </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Branch Interested:{" "}
                  {applicantDetail.applicant &&
                    applicantDetail.applicant.relevant_fk &&
                    applicantDetail.applicant.relevant_fk.branch &&
                    applicantDetail.applicant.relevant_fk.branch.branch_name}
                </b> */}
              </div>
              <div style={{ display: "flex" }}>
              <Typography className="textDisplay"  variant="h5">
                <strong>Application Date :</strong>{" "}
                {applicantDetail?.applicant?.register_date?applicantDetail?.applicant?.register_date : ""}
              </Typography>
                {/* <b style={{ display: "flex", marginTop: "7px" }}>
                  Application Date:{" "}
                  {applicantDetail.applicant.register_date || ""}
                </b> */}
              </div>
            </Grid>
            <Grid item md={3} className={classes.item} />

            <Grid item md={10} xs={12} className={classes.item}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                    <Typography className="textDisplay" variant="h5"> <strong>Resume</strong></Typography>
                    </TableCell>
                    <TableCell >
                      {applicantDetail.applicant &&
                      applicantDetail.applicant.resume ? (
                        <a
                          target="_blank"
                          href={applicantDetail.applicant.resume || ""}
                          onclick="window.open(this.href); return false;"
                        >
                          {" "}
                          <Typography className="textDisplay" variant="h5">  View Resume </Typography>
                        </a>
                      ) : (
                        "Resume Not Uploaded"
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid item md={4} xs={12} className={classes.gridOne}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography className="textDisplay" variant="h5" component="h2">
                    1.MCQ{" "}
                    {applicantDetail && applicantDetail.mcq_test_completed ? (
                      <a>
                        {" "}
                        Completed{" "}
                        <Typography className="textDisplay" variant="h6">
                          Marks=
                          {(applicantDetail &&
                            applicantDetail.mcq_test_completed &&
                            applicantDetail.mcq_score &&
                            applicantDetail.mcq_score.mark_scored) ||
                            "Not Uploaded"}
                        </Typography>
                      </a>
                    ) : (
                      "Pending"
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} className={classes.gridOne}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography className="textDisplay" variant="h5" component="h2">
                    2.Written Test{" "}
                    {applicantDetail &&
                    applicantDetail.written_test_completed ? (
                      <a>
                        {" "}
                        Completed{" "}
                        <Typography  className="textDisplay" variant="h5">
                          Marks=
                          {(applicantDetail &&
                            applicantDetail.written_test_completed &&
                            applicantDetail.written_score &&
                            applicantDetail.written_score.mark_scored) ||
                            "Not Uploaded"}
                        </Typography>
                      </a>
                    ) : (
                      "Pending"
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={4} xs={12} className={classes.gridOne}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography  className="textDisplay" variant="h5" component="h2">
                    3.Spoken Test{" "}
                    {applicantDetail &&
                    applicantDetail.spoken_test_completed ? (
                      <a>
                        {" "}
                        Completed{" "}
                        <Typography className="textDisplay" variant="h5">
                          Marks=
                          {(applicantDetail &&
                            applicantDetail.spoken_test_completed &&
                            applicantDetail.spoken_score &&
                            applicantDetail.spoken_score.mark_scored) ||
                            "Not Uploaded"}
                        </Typography>
                      </a>
                    ) : (
                      "Pending"
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {profileId === 2 && (
              <Grid item md={4} className={classes.gridOne}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography className="textDisplay" variant="h5" component="h2">
                      4.Planner Round{" "}
                      {applicantDetail &&
                      applicantDetail.f2f_score.length !== 0 &&
                      (
                        applicantDetail &&
                        applicantDetail.f2f_score.length !== 0 &&
                        applicantDetail.f2f_score
                          .filter(
                            (check) =>
                              check.round.round_name === "Planner Round"
                          )
                          .map((items) => {
                            return items.mark_scored;
                          })
                      ).length !== 0 ? (
                        <a>
                          Completed{" "}
                          <Typography  className="textDisplay" variant="h5">
                            Marks=
                            {(applicantDetail &&
                              applicantDetail.f2f_score.length > 0 &&
                              applicantDetail.f2f_score
                                .filter(
                                  (check) =>
                                    check.round.round_name === "Planner Round"
                                )
                                .map((items) => {
                                  return items.mark_scored;
                                })) ||
                              "Not Uploaded"}
                          </Typography>
                        </a>
                      ) : (
                        " Pending"
                      )}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {(profileId === 3 || profileId === 4) && (
              <Grid item container spacing={2}>
                <Grid item md={4} className={classes.gridOne}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography  className="textDisplay" variant="h5" component="h2">
                        4.Stress Round{" "}
                        {applicantDetail &&
                        applicantDetail.f2f_score.length !== 0 &&
                        (
                          applicantDetail &&
                          applicantDetail.f2f_score.length !== 0 &&
                          applicantDetail.f2f_score
                            .filter(
                              (check) =>
                                check.round.round_name === "Stress Round"
                            )
                            .map((items) => {
                              return items.mark_scored;
                            })
                        ).length !== 0 ? (
                          <a>
                            Completed{" "}
                            <Typography className="textDisplay" variant="h5">
                              Marks=
                              {(applicantDetail &&
                                applicantDetail.f2f_score.length > 0 &&
                                applicantDetail.f2f_score
                                  .filter(
                                    (check) =>
                                      check.round.round_name === "Stress Round"
                                  )
                                  .map((items) => {
                                    return items.mark_scored;
                                  })) ||
                                "Not Uploaded"}
                            </Typography>
                          </a>
                        ) : (
                          " Pending"
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item md={4} className={classes.gridOne}>
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography className="textDisplay"  variant="h5" component="h2">
                        5.Leadership Round{" "}
                        {applicantDetail &&
                        applicantDetail.f2f_score.length !== 0 &&
                        (
                          applicantDetail &&
                          applicantDetail.f2f_score.length !== 0 &&
                          applicantDetail.f2f_score
                            .filter(
                              (check) =>
                                check.round.round_name === "Leadership Round"
                            )
                            .map((items) => {
                              return items.mark_scored;
                            })
                        ).length !== 0 ? (
                          <a>
                            Completed{" "}
                            <Typography className="textDisplay"  variant="h5">
                              Mark=
                              {(applicantDetail &&
                                applicantDetail.f2f_score.length > 0 &&
                                applicantDetail.f2f_score
                                  .filter(
                                    (check) =>
                                      check.round.round_name ===
                                      "Leadership Round"
                                  )
                                  .map((items) => {
                                    return items.mark_scored;
                                  })) ||
                                "Not Uploaded"}
                            </Typography>
                          </a>
                        ) : (
                          " Pending"
                        )}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {idFunction()}
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      </Modal>
    );
  }

  const fetchSearch = (e) => {
    setPage(0);
    setRowsPerPage(5);
    if (e.keyCode === 13) {
      const data = document.getElementById("search-bar").value;
      setApplicentName(document.getElementById("search-bar").value);
      functionSearch(data);
    }
  };

  function functionSearch(data) {
    fetchApplicant({
      url: `${urls.searchApplicantRecruiter}?page=${page +
        1}&page_size=${rowsPerPage || 5}&search=${data}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (searchApplicantData) {
      fetchApplicant({
        url: `${
          urls.reassignRetrieveApplicant
        }?position_id=${viewOpenning}&role_id=${RoleId}&start_date=${startDate}&end_date=${endDate}&page=1&page_size=${rowsPerPage ||
          10}&search=${searchApplicantData}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [searchApplicantData]);
  const searchDataFunction = (e) => {
    setSearchApplicantData(e.target.value);
  };
  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={5} xs={12}>
          <Typography className="headings" variant="h4"><strong>Re-Assign To Recruiter</strong></Typography>
        </Grid>

        <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={searchDataFunction}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.root}>
        <Paper className={classes.paperrrrr}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography>
                {" "}
                Select Position Type &nbsp; <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={positionType || ""}
                onChange={(e) => setPositionType(e.target.value)}
                variant="outlined"
              >
                {positionTypeList.map((option, index) => (
                  <MenuItem key={index} value={option.label} name={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>
                {" "}
                Select Role &nbsp; <b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={RoleId || ""}
                onChange={(e) => setRoleId(e.target.value)}
                variant="outlined"
              >
                {roleList &&
                  roleList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.applicant_role_name}
                    >
                      {option.applicant_role_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>Select Opening</Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin="dense"
                value={viewOpenning || ""}
                onChange={(e) => setViewOpennings(e.target.value)}
                variant="outlined"
              >
                {viewOpenningsData &&
                  viewOpenningsData.length !== 0 &&
                  viewOpenningsData.map((option, index) => (
                    <MenuItem key={index} value={option.id} name={option.title}>
                      {option.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>Start Date</Typography>
              <TextField
                // className={classes.textField}
                margin="dense"
                required
                className="textList"
                fullWidth
                onChange={(e) => handleStartDateChange(e.target.value)}
                type="date"
                value={startDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>End Date</Typography>
              <TextField
                // className={classes.textField}
                margin="dense"
                required
                fullWidth
                className="textList"
                onChange={(e) => handleEndDateChange(e.target.value)}
                type="date"
                value={endDate || ""}
                variant="outlined"
              />
            </Grid>
            <Grid item md={2} xs={12} style={{ marginTop: "33px" }}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleGetData}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Divider className={classes.divider} />
        {profileTable()}
      </div>
      {modal}
      {loader}
    </React.Fragment>
  );
};

AssignOtherRecruiter.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(AssignOtherRecruiter);
