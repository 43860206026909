import React, { useState, useRef, useEffect } from "react";
import {
  Divider,
  Grid,
  Paper,
  withStyles,
  Button,
  TextField,
  Typography,
  Radio
} from "@material-ui/core";
import Webcam from "react-webcam";
import PropTypes from "prop-types";
import styles from "./writtenTest.Style";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Icon from "@material-ui/core/Icon";
import "./writtenTest.css";
import MediaQuery from "react-responsive";
import { UltimateTextToImage } from "ultimate-text-to-image";
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';

function WrittenTest({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [fileData, setFileData] = useState("");
  const [showTopic, setShowTopic] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [loaderd, setLoader] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [start, setStart] = useState(false);
  const [radio, setRadio] = useState("y");
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [topic, setTopic] = useState('');
  const [duration, setDuration] = useState(1800);
  const [editorsValue, setEditorsValue] = useState();

  useEffect(() => {
    if (auth?.academic_profile?.applicant_role?.applicant_role_name.toLowerCase().includes('sales')) {
      setDuration(5400);
    } else {
      setDuration(1800);
    }
  }, [auth]);


  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (duration > 0) {
      setDuration(t => t - 1);
    }
  }, [1000]);


  // using the asynchronous API with await

  const webcamRef = useRef(null);
  const alert = useAlert();

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };
  const handleDeviceRadio = val => {
    setRadio(val);
  };
  const handleCameraRadio = val => {
    setRadio(val);
  };

  const {
    data: TopicRes,
    isLoading: TopicResLoading,
    doFetch: fetchTopicResponse
  } = useFetch(null);

  const {
    data: WrittenTestAssignResponse,
    isLoading: WrittenTestAssignResponseLoading,
    doFetch: fetchWrittenTestAssignResponse
  } = useFetch(null);

  var loader = null;
  if (TopicResLoading || WrittenTestAssignResponseLoading || loaderd) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (auth) {
      writtenTestRes();
    }
  }, [auth]);

  function writtenTestRes() {
    fetchWrittenTestAssignResponse({
      url: urls.applicantWrittenTestAssignResApi,
      method: "GET",
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    setShowTopic(true);
  }

  // function sendAccesCode() {
  //   if (!accessCode) {
  //     alert.warning('enter access code')
  //     return
  //   }
  //   setLoader(true)
  //   fetch(`${urls.accessCodeApi}?access_code=${accessCode}`, {
  //     method: 'Get',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     },
  //   }).then(res => {
  //     if (res.status === 404) {
  //       setLoader(false)
  //       alert.warning('please enter valid access code')
  //       return
  //     }
  //     if (res.status === 200) {
  //       setShowTopic(true)
  //       setLoader(false)
  //       setAccessCode('')
  //       alert.success('access code verified')
  //     }
  //     if (res.status !== 200) {
  //       alert.warning('somthing went wrong please try again ')
  //       return
  //     }
  //   })
  // }
  useEffect(() => {
    // if (
    //   WrittenTestAssignResponse &&
    //   WrittenTestAssignResponse.written_test_assign === true &&
    //   WrittenTestAssignResponse.written_test_completed === false
    // ) {
    fetchTopicResponse({
      url: `${urls.getTopicApi}?round_id=${localStorage.getItem("round_id")}`,
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    // }
  }, []);

  function submitData() {
    if (auth?.academic_profile?.applicant_role?.applicant_role_name.toLowerCase().includes('ocfp') || auth?.academic_profile?.applicant_role?.applicant_role_name.toLowerCase().includes('sales')) {

      if (!fileData) {
        alert.warning("Upload File");
        return;
      }
      var formData = new FormData();
      formData.append("written_test_file", fileData);
      setLoader(true);
      fetch(
        `${urls.submitTopicApi}${TopicRes &&
        TopicRes.id}/update_written_test_topic/`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${auth.personal_info.token}`
          }
        }
      ).then(res => {
        if (res.status === 200) {
          setShowTopic(false);
          setLoader(false);
          setSuccessful(true);
          // writtenTestRes()
          alert.success("Successfully Submitted");
          window.location = "/process";
        }
        if (res.status !== 200) {
          alert.warning("somthing went wrong please try again ");
          return;
        }
      });
    }
    else {
      if (!editorsValue) {
        alert.warning("Fill topic");
        return;
      }
      let text1 = "Topic is :" + TopicRes?.topic?.topic_name + "\n";
      let text2 = editorsValue;
      let result = text1.concat(text2);

      const textToImage = new UltimateTextToImage(result, {
        width: 400,
        maxWidth: 1000,
        maxHeight: 1000,
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontSize: 24,
        minFontSize: 10,
        lineHeight: 40,
        autoWrapLineHeightMultiplier: 1.2,
        margin: 20,
        marginBottom: 40,
        align: "left",
        valign: "middle",
        borderSize: 2,
      }).render();
      const dataUrlPng = textToImage.toDataUrl(); // image/png by default
      // const dataUrlJpeg = textToImage.toDataUrl("image/jpeg", { quality: 80 });
      var url = dataUrlPng;
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          var formData = new FormData();
          formData.append("written_test_file", blob, "filename");
          setLoader(true);
          fetch(
            `${urls.submitTopicApi}${TopicRes &&
            TopicRes.id}/update_written_test_topic/`,
            {
              method: "PUT",
              body: formData,
              headers: {
                Authorization: `Bearer ${auth.personal_info.token}`
              }
            }
          ).then(res => {
            if (res.status === 200) {
              setShowTopic(false);
              setLoader(false);
              setSuccessful(true);
              // writtenTestRes()
              alert.success("Successfully Submitted");
              window.location = "/process";
            }
            if (res.status !== 200) {
              alert.warning("somthing went wrong please try again ");
              return;
            }
          });
        });
    }

  }
  const capture = () => {
    var imgSrc = null;
    imgSrc = webcamRef.current.getScreenshot();
    if (imgSrc !== null) {
      alert.success("Photo Taken Successfully..!!");
    } else {
      alert.warning("Photo Not Taken Try Again..!!");
    }
    var url = imgSrc;
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        var formData = new FormData();
        formData.append("written_test_file", blob, "filename");
        setLoader(true);
        fetch(
          `${urls.submitTopicApi}${TopicRes &&
          TopicRes.id}/update_written_test_topic/`,
          {
            method: "PUT",
            body: formData,
            headers: {
              Authorization: `Bearer ${auth.personal_info.token}`
            }
          }
        ).then(res => {
          if (res.status === 200) {
            setShowTopic(false);
            setLoader(false);
            setSuccessful(true);
            // writtenTestRes()
            alert.success("Successfully Uploaded");
          }
          if (res.status !== 200) {
            alert.warning("somthing went wrong please try again ");
            return;
          }
        });
      });
  };

  function successMsg() {
    return (
      <Grid container spacing={2}>
        <Grid item md={4} className={classes.paperMain} />
        <Grid item md={8} className={classes.paperMain}>
          {/* <Card className={classes.card}> */}
          <Typography
            variant='h4'
            style={{ color: "blue", fontFamily: "Times", marginTop: "50px" }}
          >
            You have successfully completed the Test
          </Typography>
          {/* </Card> */}
        </Grid>
      </Grid>
    );
  }

  function countDown() {
    const time = duration;
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor((time / 60) % 60);
    const hours = Math.floor((time / 3600))
    return (
      <Grid item sm={4} md={12} xs={12}
        style={{
          textAlign: 'center',
        }}
      >
        {auth?.academic_profile?.applicant_role?.applicant_role_name.toLowerCase().includes('sales') ?
          <Typography style={{ fontSize: '20px' }}>Remaining Time :{minutes > 10 ? <span style={{ fontSize: "40px", fontWeight: 'bold', color: 'rgb(63 81 181)' }}>{hours}:{minutes}:{seconds}</span> : <span style={{ fontSize: "40px", fontWeight: 'bold', color: 'red' }}>{hours}:{minutes}:{seconds}</span>}</Typography>
          :
          <Typography style={{ fontSize: '20px' }}>Remaining Time :{minutes > 10 ? <span style={{ fontSize: "40px", fontWeight: 'bold', color: 'rgb(63 81 181)' }}>{minutes}:{seconds}</span> : <span style={{ fontSize: "40px", fontWeight: 'bold', color: 'red' }}>{minutes}:{seconds}</span>}</Typography>
        }
      </Grid>
    );
  }

  useEffect(() => {
    if (countDown().props?.children[0] === 0 && countDown().props?.children[2] === 0) {
      submitData();
    }
  }, [countDown])

  function completedMsg() {
    return (
      <Grid container spacing={2}>
        <Grid item md={2} className={classes.paperMain} />
        <Grid item md={8} className={classes.paperMain}>
          {/* <Card className={classes.card}> */}
          <Typography
            variant='h4'
            style={{ color: "blue", fontFamily: "Times", marginTop: "50px" }}
          >
            You have already completed the Test
          </Typography>
          {/* </Card> */}
        </Grid>
      </Grid>
    );
  }
  const cameraStart = () => {
    setStart(true);
  };
  // const cameraStop = () => {
  //   setStart(false);
  // }

  return (
    <React.Fragment>
      <Typography variant='h4' style={{ fontWeight: "bold" }}>Written Topic</Typography>
      <Divider className={classes.divider} />
      {/* {WrittenTestAssignResponse && WrittenTestAssignResponse.written_test_assign === false ? <Grid className={classes.GridText}><Typography variant='h4' className={classes.papertext}>Written Topic is not Assigned please Contact to HR </Typography></Grid> : ''} */}
      {/* {WrittenTestAssignResponse && WrittenTestAssignResponse.written_test_assign === true && WrittenTestAssignResponse && WrittenTestAssignResponse.written_test_completed === false && !successful && showTopic === false &&
        <Grid container spacing={2} className={classes.gride}>
          <Grid item md={4} xs={12} sm={12} />
          <Grid item sm={2} md={3} xs={12} sm={12}>
            <TextField
              id="title-position"
              label="Enter Access Code"
              margin="dense"
              required
              fullWidth
              value={accessCode || ''}
              onChange={e => setAccessCode(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12} sm={12}>
            <Button
              className={classes.applyButton}
              style={{ margin: '13px' }}
              disabled={!accessCode}
              color="primary"
              variant="contained"
              size="small"
              onClick={() => sendAccesCode()}
            >
              Submit
          </Button>
          </Grid>
        </Grid>} */}

      {showTopic && (
        <>
          {countDown()}
          {countDown().props?.children?.props?.children[1]?.props?.children[0] !== 0 || countDown().props?.children?.props?.children[1]?.props?.children[2] !== 0 ?
            <div>
              <Paper className={classes.paper}>
                <Grid container spacing={2} className={classes.gride}>
                  <Grid item md={12} xs={12} sm={12}>
                    <strong>Topic Is :</strong>
                    <p>{TopicRes && TopicRes.topic.topic_name}</p>
                    {auth?.academic_profile?.applicant_role?.applicant_role_name.toLowerCase().includes('sales') ?
                      <>
                        <div>
                          <iframe
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${TopicRes && TopicRes.topic.written_topic_excel}`}
                            height="600px"
                            width="100%"
                            frameBorder="0"
                          />
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          href={TopicRes && TopicRes.topic.written_topic_excel}
                        >
                          Please Click here to Download Excel
                        </Button>
                      </>
                      : (
                        <>
                        </>
                      )
                    }
                  </Grid>
                </Grid>
              </Paper>
              <Divider className={classes.divider} />
              {/* <Grid className={classes.modalPaper}> */}
              {/* <h2 id='transition-modal-title'>Upload With Us</h2>
            <div>
              <Radio
                checked={selectedValue === "a"}
                onChange={handleChange}
                value='a'
                hideradio
                name='radio-button-demo'
                inputProps={{ "aria-label": "A" }}
                onClick={() => handleDeviceRadio("y")}
              />{" "}
              Device
              <Radio
                checked={selectedValue === "b"}
                onChange={handleChange}
                value='b'
                hideradio
                name='radio-button-demo'
                inputProps={{ "aria-label": "B" }}
                onClick={() => handleCameraRadio("z")}
              />
              Camera
            </div> */}
              {/* </Grid> */}

              <div className={classes.root}>
                <Grid container spacing={2}>
                  {auth?.academic_profile?.applicant_role?.applicant_role_name.toLowerCase().includes('ocfp') ?
                    <Grid item sm={6} xs={12}>
                      <Typography variant='h4' className={classes.papertext}>
                        Upload File
                      </Typography>
                      <input
                        style={{ display: "none" }}
                        accept='image/*'
                        className={classes.fileUpload}
                        id='outlined-button-file'
                        multiple
                        type='file'
                        onChange={e => setFileData(e.target.files[0])}
                      />
                      <label htmlFor='outlined-button-file'>
                        <Button
                          variant='contained'
                          color='primary'
                          component='span'
                          className={classes.fileUpload}
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload
                        </Button>
                        <Button className={classes.bold}>
                          {fileData && fileData.name}
                        </Button>
                      </label>
                    </Grid>
                    :
                    auth?.academic_profile?.applicant_role?.applicant_role_name.toLowerCase().includes('sales') ?
                      <Grid item sm={6} xs={12}>
                        <Typography variant='h4' className={classes.papertext}>
                          Upload Excel File
                        </Typography>
                        <input
                          style={{ display: "none" }}
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          className={classes.fileUpload}
                          id='outlined-button-file'
                          multiple
                          type='file'
                          onChange={e => setFileData(e.target.files[0])}
                        />
                        <label htmlFor='outlined-button-file'>
                          <Button
                            variant='contained'
                            color='primary'
                            component='span'
                            className={classes.fileUpload}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload
                          </Button>
                          <Button className={classes.bold}>
                            {fileData && fileData.name}
                          </Button>
                        </label>
                      </Grid>
                      :
                      <Grid item md={8} xs={12}>
                        <Typography variant='h6'>Write Topic in 200 words </Typography>
                        <MediaQuery minWidth={451}>
                          <textarea
                            id='topic'
                            className='dgf '
                            required
                            rows="4" cols="50" maxlength="2000"
                            style={{ height: 250, width: '-webkit-fill-available' }}
                            value={editorsValue || ""}
                            onChange={(e) => setEditorsValue(e.target.value)}
                          />
                        </MediaQuery>
                        <MediaQuery maxWidth={450}>
                          <textarea
                            id='topic'
                            className='dgf '
                            required
                            rows="4" cols="50" maxlength="2000"
                            style={{ height: 250, width: 230 }}
                            value={editorsValue}
                            onChange={(e) => setEditorsValue(e.target.value)}

                          />
                        </MediaQuery>
                      </Grid>

                  }
                </Grid>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Grid item md={2} xs={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.buttonn}
                        endIcon={<Icon>send</Icon>}
                        // disabled={(!topic && !fileData) ? true : false}

                        disabled={(!editorsValue && !fileData) ? true : false}
                        onClick={() => submitData()}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
            :
            <Typography
              variant='h4'
              style={{ color: "red", fontFamily: "Nunito", marginTop: "50px", textAlign: 'center' }}
            >
              Your Test Session Expired
            </Typography>

          }

          {/* {radio == "z" ? (
                    <Grid item xs={12} sm={6}>
                      <Typography variant='h4' className={classes.papertext}>
                        Take a Picture
                      </Typography>
                      <Button
                        style={{ margin: "2px" }}
                        variant='contained'
                        color='primary'
                        onClick={cameraStart}
                      >
                        Start Camera
                      </Button> */}
          {/* <Button
                  style={{ margin: '2px' }}
                  variant="contained"
                  color="primary"
                  onClick={cameraStop}
                >
                  Stop Camera
                        </Button> */}
          {/* {start ? (
                        <div>
                          <Webcam
                            audio={false}
                            height={350}
                            ref={webcamRef}
                            screenshotFormat='image/jpeg'
                            width={350}
                          ></Webcam>
                        </div>
                      ) : (
                        ""
                      )}
                      <Button
                        variant='contained'
                        style={{ margin: "2px" }}
                        color='primary'
                        onClick={capture}
                        disabled={!start}
                      >
                        Capture photo
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )} */}
          {/* </Grid> */}
          {/* </div> */}
        </>
      )}
      {successful && successMsg()}

      {WrittenTestAssignResponse &&
        WrittenTestAssignResponse.written_test_completed === true &&
        WrittenTestAssignResponse.written_test_assign === true &&
        completedMsg()}
      {loader}
    </React.Fragment>
  );
}
WrittenTest.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};
export default withStyles(styles)(WrittenTest);
