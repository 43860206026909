import React, { useState, useEffect } from 'react';
import styles from './createEmployee.style';
import PropTypes from 'prop-types';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import { useAlert } from '../../../hoc/alert/alert'
import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
} from '@material-ui/core';

const CreateEmployee = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [erp, setErp] = useState('');
  const [number, setNumber] = useState('');
  const [branch, setBranch] = useState('')
  const [userCitys, setUserCitys] = useState('');
  const [role, setRole] = useState('');
  const [pageRefresh, setPageRefresh] = useState(false);
  const filterEmailId = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;


  const {
    data: roleData,
    isLoading: roleLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });

  const {
    data: cityList,
    doFetch: fetchCityList,
  } = useFetch([], { suppressAlert: true });
  const {
    data: branchList,
    doFetch: fetchBranchList,
  } = useFetch([], { suppressAlert: true });

  const alert = useAlert();
  const {
    data: createEmpResponse,
    isLoading: responseLoading,
    doFetch: createEmpDetails,
  } = useFetch("", { suppressAlert: false });

  useEffect(() => {
    if (pageRefresh === true && createEmpResponse !== null) {
      setEmail('')
      setErp('')
      setUserCitys([''])
      setBranch([''])
      setName('')
      setNumber('')
      setRole('')
      setPageRefresh(false)
    }
    if (createEmpResponse) {
      if (createEmpResponse === "success") {
        alert.success('Employee has Successfully register')
        return
      }
      else if (createEmpResponse === "Employee already register") {
        alert.error('Employee has Already register')
        return
      }
    }
  }, [createEmpResponse])




  const createEmployeeFunction = () => {
    if (!name || !erp || !number || !email || !userCitys || !role || !branch) {
      alert.warning('Select All Fields');
      return;
    }
    if (number.length !== 10) {
      alert.warning('Phone No. should be of 10 digits');
      return;
    }
    if (erp.length !== 11) {
      alert.warning('Erp Number should be of 11 digits');
      return;
    }

    setPageRefresh(true)
    const formData = new FormData();
    formData.append('name', name);
    formData.append('erp', erp);
    formData.append('email', email);
    formData.append('phone_no', number);
    formData.append('city', userCitys);
    formData.append('branch', branch)
    formData.append('role_id', role);

    createEmpDetails({
      url: urls.employeeRegistration,
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }
  useEffect(() => {
    if (auth) {
      fetchRole({
        url: urls.createEmpRole,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
      fetchCityList({
        url: urls.cityList,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [fetchRole, fetchCityList, auth]);


  useEffect(() => {
    if (auth && (userCitys !== '')) {
      fetchBranchList({
        url: `${urls.branchCityMapping}?city_id=${userCitys}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [userCitys, auth.personal_info.token])

  let loader = null;
  if (roleLoading || responseLoading) {
    loader = <Loader open />;
  }

 
  function handleMobileNO(e) {
    const re= /^[0-9]+$/g
    if ((e.target.value === '' || re.test(e.target.value))&&(e.target.value.length <= 10)) {
      setNumber(e.target.value)
      
    }
  }
  function handleErp(e) {
    const re= /^[0-9]+$/g
    if ((e.target.value === '' || re.test(e.target.value))&&(e.target.value.length <= 11)) {
      setErp(e.target.value)
      
    }
  }


  return (
    <React.Fragment>
      <Typography className="headings" variant="h4"><strong>Create Employee</strong></Typography>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              label="Name"
              margin="dense"
              fullWidth
              required
              value={name}
              onChange={e => setName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12} >
            <TextField
              label="Email"
              margin="dense"
              type='email'
              fullWidth
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
              variant="outlined"
            />
            <span className={classes.helpeText}>
                      {email && email.match(filterEmailId) === null
                        ? "Enter Valid EmailId"
                        : ""}
                    </span>
          </Grid>
          <Grid item md={4} xs={12} >
            <TextField
              label="Phone Number"
              margin="dense"
              type='text'
              fullWidth
              required
              value={number}
              onChange={e => handleMobileNO(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12} >
            <TextField
              label="Erp"
              margin="dense"
              type='text'
              fullWidth
              required
              value={erp}
              onChange={e => handleErp(e)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12} >
            <TextField
              label="City "
              className={classes.textField}
              margin="dense"
              required
              select
              onChange={e => setUserCitys(e.target.value)}
              // SelectProps={{ multiple: true }}
              value={userCitys || ''}
              variant="outlined"
            >
              {cityList && cityList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.city_name}
                >
                  {data.city_name ? data.city_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12} >
            <TextField
              label="Branch "
              className={classes.textField}
              margin="dense"
              required
              select
              onChange={e => setBranch(e.target.value)}
              // SelectProps={{ multiple: true }}
              value={branch || ''}
              variant="outlined"
            >
              {branchList && branchList.map(data => (
                <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.branch_name}
                >
                  {data.branch_name ? data.branch_name : ''}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={4} xs={12} >
            <TextField
              label="Role"
              className={classes.textField}
              margin="dense"
              required
              onChange={e => setRole(e.target.value)}
              select
              value={role || ''}
              variant="outlined"
            >
              {roleData
                ? (roleData.map(data => (
                  <MenuItem
                    value={data.id}
                    key={data.id}
                    name={data.role_name}
                  >
                    {data.role_name ? data.role_name : ''}
                  </MenuItem>
                )))
                : (<MenuItem
                  value='0'
                  key='0'
                  name='None'
                >
                  None
                </MenuItem>)
              }
            </TextField>
          </Grid>
          <Grid xs={12} md={3} className={classes.applyButtonSection}>
            <Button
              className={classes.applyButton}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              onClick={() => createEmployeeFunction()}
              disabled={(!name || !erp || !number || !email || !userCitys || !role || !branch) ? true : false}
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {loader}
    </React.Fragment>
  );
}
CreateEmployee.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CreateEmployee);
