import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./interviewDetail.Style";
import {
    Typography,
    Divider,
    withStyles,
    Grid,
    Button,
    TextField,
    Paper,
    Select,
    MenuItem,
    TablePagination,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    TableFooter,
    Dialog,
    Backdrop
  } from "@material-ui/core";
  import urls from "../../../url";
  import useFetch from "../../../hoc/useFetch";
import axios from "axios";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Loader from '../../../hoc/loader';
import Close from "@material-ui/icons/Close";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";


function InterviewDetails({classes}) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [index, setIndex] = useState("");
  const [interviewStatus, setInterviewStatus] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [openView, setOpenView] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const history = useHistory();
  // const [RoleId, setRoleId] = useState("");

  const useStyles = makeStyles((theme) => ({
    dialogMarks: {
      height:380,
      width: '35%',
      padding: theme.spacing(2, 3, 2)
    }
  }));

  const classesD = useStyles();

  const intviewDetailList = [
    {
      value: "1",
      label: "Today Interview",
    },
    {
      value: "2",
      label: "Upcoming Interview",
    },
    {
      value: "3",
      label: "Pending Interview",
    },
    {
      value: "4",
      label: "Completed Interview",
    },
  ];

  const {
    data: getInterviewDetailData,
    isLoading: viewInterviewDetailLoading,
    doFetch: fetchgetInterViewDetail,
  } = useFetch("", { suppressAlert: true });

  function getInterviewDetails() {

    fetchgetInterViewDetail({
      url: `${
        urls.recruiterInterviewDetails
      }?interview_status=${interviewStatus ? interviewStatus : history?.location?.state}&page=${page+1}&page_size=${rowsPerPage || 50}${startDate ? `&start_date=${startDate}` : ""
    }${endDate ? `&end_date=${endDate}` : ""
    }`,
      method: "GET",
      headers: {
        "Content-Type": "apinterviewStatusplication/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  function searchFunction() {
    fetchgetInterViewDetail({
      url: `${
        urls.recruiterInterviewDetails
      }?interview_status=${interviewStatus}&search=${searchData || ''}`,
      method: "GET",
      headers: {
        "Content-Type": "apinterviewStatusplication/json",
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  }

  const interviewStatusName = history?.location?.state;
// const {
//   data: roleList,
//   isLoading: gettingRoleList,
//   doFetch: FetchRoleList,
// } = useFetch(null, { suppressAlert: true });

// const [totalPages, setTotalPages] = useState("");

// const getInterviewDetails = () =>{
//   axios.get(`${urls.interviewDetailApi}?`)
//   .then((response) =>{
//     console.log(response,"total");
//     if(response) {
//         setInterviewStatus(response.data.result);
//     } else {
//       console.log('error', 'error');
//     }
//   })
// }

function functionSearch(data){
  setSearchData(data);
}

useEffect(() => {
  if (auth) {
    searchFunction();
  }
}, [searchData]);

useEffect(()=>{
    if(auth){
        getInterviewDetails()
    }
},[auth, page, rowsPerPage, startDate, endDate]);


const handleStartDateChange = (val) => {
  setStartDate(val);
};
const handleEndDateChange = (val) => {
  setEndDate(val);
};

const {
  data: getZoomRecordinfData,
  isLoading: ViewZoomRecordingLoading,
  doFetch: fetchgetZoomRecording,
} = useFetch("", { suppressAlert: true });

// useEffect(() => {
//   // if (positionType) {
//     FetchRoleList({
//       url: `${urls.RoleListApi}?position_type=Academic`,
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${auth.personal_info.token}`,
//       },
//     });
  
//   // } 
//  }, [FetchRoleList]);

const getData1 = getZoomRecordinfData?.code;
const getData2 = getZoomRecordinfData?.message;

const [viewName, setViewName] = useState('');
// const [zoomId, setZoomId] = useState('');
const [comments, setComments] = useState('');

function getAudio(data1,data2,data3) {

  setOpenView(true);
  setViewName(data1);
  setComments(data3);
  // setZoomId(data2)

  fetchgetZoomRecording({
    url: `${
      urls.getZoomRecording
    }?f2f_id=${data2}`,
    method: "GET",
    headers: {
      "Content-Type": "apinterviewStatusplication/json",
      Authorization: `Bearer ${auth.personal_info.token}`,
    },
  });
}


const handleClose = () => {
  setOpenView(false);
};

function handleChangePage(event, newPage) {
  setPage(newPage)
  if (!rowsPerPage) {
      setRowsPerPage(50)
  }
  }

  function handleChangeRowsPerPage(event) {
      setRowsPerPage(event.target.value);
      setPage(0);
  }
  function firstPageChange() {
      setPage(0)
  }

  function lastPageChange(lastPage) {
      setPage(lastPage)
  };

  let loader = null;
  if (viewInterviewDetailLoading || ViewZoomRecordingLoading) {
    loader = <Loader open />;
  }

  return (
    <React.Fragment>
            <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={6} xs={12}>
            <Typography variant="h4">
              <strong>Interview Details</strong>
            </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
            <div className={classes.search}>
            <TextField
            id="search-bar"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            label="I'm Looking For..."
            variant="outlined"
            size="small"
            name="subject"
            autoComplete="on"
            onChange={(e) => functionSearch(e.target.value)}
          />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
      <Grid container spacing={2}>
      {/* <Grid item md={3} xs={12}>
            <Typography style={{ marginBottom: "8px" }}>
              {" "}
              Select Role
            </Typography>
            <Select
              // required
              fullWidth
              margin="dense"
              // value={RoleId || ""}
              // onChange={(e) => setRoleId(e.target.value)}
              variant="outlined"
            >
              {roleList &&
                roleList.length !== 0 &&
                roleList.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.id}
                    name={option.applicant_role_name}
                  >
                    {option.applicant_role_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid> */}
      <Grid item md={3} xs={12}>
      <Typography style={{ marginBottom: "8px" }}>
              {" "}
              Select Interview Status <b style={{ color: "red" }}>*</b>
            </Typography>
            <Select
              required
              fullWidth
              margin="dense"
              value={interviewStatus ? interviewStatus : interviewStatusName }
              onChange={(e, id) => {
                setInterviewStatus(e.target.value);
              }}
              variant="outlined"
            >
            {intviewDetailList.map((option, index) => (
               <MenuItem key={index} value={option.label} name={option.id}>
                 {option.label}
               </MenuItem>
            ))}
            </Select>
      </Grid>
      {interviewStatus == "Upcoming Interview" || interviewStatus =="Pending Interview" || interviewStatus =="Completed Interview" ?
      <Grid item md={2} xs={12}>
            <Typography>Start Date</Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              // required
              fullWidth
              onChange={(e) => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || ""}
              variant="outlined"
            />
          </Grid>
          : ""
          }
          {interviewStatus == "Upcoming Interview" || interviewStatus =="Pending Interview" || interviewStatus =="Completed Interview" ?
          <Grid item md={2} xs={12}>
            <Typography>End Date</Typography>
            <TextField
              className={classes.textField}
              margin="dense"
              // required
              fullWidth
              onChange={(e) => handleEndDateChange(e.target.value)}
              type="date"
              value={endDate || ""}
              variant="outlined"
            />
          </Grid>
          : ""
        }
      <Grid item md={2} xs={12}>
            <Button
              style={{ marginTop: "33px" }}
              variant="contained"
              color="primary"
              onClick={getInterviewDetails}
            >
              Check
            </Button>
          </Grid>
      </Grid>
      </Paper>
      <Divider className={classes.divider} />
      {getInterviewDetailData && getInterviewDetailData.results.length === 0 ? (
        <h1
          align="center"
          style={{ marginTop: "10%", textAlign: "center", color: "blue" }}
        >
          Records Not Found
        </h1>
      ) : (
        ""
      )}
      {getInterviewDetailData && getInterviewDetailData.results.length !== 0 && (
      <Paper>
          <React.Fragment>
          <TableContainer className="role">
          <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>S.No.</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Name</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Mobile No.</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Registration Date</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>MCQ Status{" "}(Marks)</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Spoken Status{" "}(Marks)</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Written Status{" "}(Marks)</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Interview Date</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>F2F Feedback</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Interview Recordings</strong></TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}><strong>Interviewer</strong></TableCell>
                  {/* <TableCell align="center" style={{ border: "1px solid black"}}><strong>Recruiter</strong></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {getChaptersData &&
                  getChaptersData.results.map((data, i) => ( */}
                {getInterviewDetailData &&
                // getInterviewDetailData.results.length !== 0 &&
                  getInterviewDetailData.results.map((data, i) => (
                    <TableRow /* key={data.id} */ >
                      <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        {data?.applicant?.applicant?.user?.first_name}
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        {data?.applicant?.applicant?.user?.username}
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                      {data?.start_date.slice(0, 10)}
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <Typography style ={{display: "flex", justifyContent: "center"}}>
                            {!data?.mcq_test?.id && <TableCell align="center" style={{border: "none"}}> 
                                {" "} 
                                {/* {data?.spoken_test?.id ? data?.spoken_test?.id : "Not Assigned"} */}
                            </TableCell>}
                            {data?.mcq_test?.id && 
                            !data?.mcq_test?.submitted_at &&<TableCell align="center">
                                {"Not Completed"}
                            </TableCell>
                            }
                          {data?.mcq_test?.id && 
                           data?.mcq_test?.submitted_at &&
                            !data?.mcq_test?.mark_scored ? (
                              <p>
                                COMPLETED 
                              </p>
                            ) :
                            ""
                            }
                          {data?.mcq_test?.id && 
                            data?.mcq_test?.submitted_at &&
                            data?.mcq_test?.mark_scored ? (
                              <p>
                                COMPLETED - {data?.mcq_test?.mark_scored } 
                              </p>
                            ) :
                            ""
                          }
                        </Typography>
                      </TableCell>

                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <Typography style ={{display: "flex", justifyContent: "center"}}>
                            {!data?.spoken_test?.id && <TableCell align="center" style={{border: "none"}}> 
                                {" "} 
                            </TableCell>}
                            {data?.spoken_test?.id && 
                            !data?.spoken_test?.submitted_at &&<TableCell align="center">
                                {"Not Completed"}
                            </TableCell>
                            }
                            {data?.spoken_test?.id &&
                            data?.spoken_test?.submitted_at && 
                            data?.spoken_test?.cut_off_clear === true ? (
                              <p>
                                QUALIFIED - {data?.spoken_test?.mark_scored} 
                              </p>
                            ) :
                            ""
                            }
                            {data?.spoken_test?.id &&
                            data?.spoken_test?.submitted_at && 
                            data?.spoken_test?.cut_off_clear === false ? (
                              
                              <p style={{ color: "blue" }}>
                                UNQUALIFIED - {data?.spoken_test?.mark_scored} 
                              </p>
                            ) : ""
                            } 
                        </Typography>
                      </TableCell>                      
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                      <Typography style ={{display: "flex", justifyContent: "center"}}>
                            {!data?.written_test?.id && <TableCell align="center" style={{border: "none"}}> 
                                {" "} 
                                {/* {data?.spoken_test?.id ? data?.spoken_test?.id : "Not Assigned"} */}
                            </TableCell>}
                            {data?.written_test?.id && 
                            !data?.written_test?.submitted_at &&<TableCell align="center">
                                {"Not Completed"}
                            </TableCell>
                            }

                            {data?.written_test?.id &&
                            data?.written_test?.submitted_at && 
                            data?.written_test?.cut_off_clear === true ? (
                              <p>
                                QUALIFIED - {data?.written_test?.mark_scored} 
                              </p>
                            ) :
                            ""
                            }

                            {data?.written_test?.id &&
                            data?.written_test?.submitted_at && 
                            data?.written_test?.cut_off_clear === false ? (
                              
                              <p style={{ color: "blue" }}>
                                UNQUALIFIED - {data?.written_test?.mark_scored} 
                              </p>
                            ) : ""
                            }
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        {data?.zoom?.start_time.slice(0, 10)}
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                      <Typography style ={{display: "flex", justifyContent: "center"}}>
                            {!data?.zoom?.id && <TableCell align="center" style={{border: "none"}}> 
                                {"Not Assigned"} 
                                {/* {data?.spoken_test?.id ? data?.spoken_test?.id : "Not Assigned"} */}
                            </TableCell>}
                            {data?.zoom?.id && 
                            !data?.mark_scored &&<TableCell align="center" style={{border: "none"}}>
                                {"PENDING"}
                            </TableCell>
                            }
                            {data?.zoom?.id && 
                            data?.mark_scored ? (
                              <p>
                                COMPLETED - {data?.mark_scored} 
                              </p>
                            ) :
                            ""
                            }
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        <Button
                          // style={{ marginTop: "33px" }}
                          variant="contained"
                          color="primary"
                          onClick={() => getAudio(data?.applicant?.applicant?.user?.first_name,data?.id,data?.comment)}
                          // onClick={() => getMarks(item.applicant && item.applicant.id, item.id, item.total_mark)}
                          >
                         View
                        </Button>
                      </TableCell>
                      <TableCell align="center" style={{ border: "1px solid black"}}>
                        {data?.zoom?.interviewer_name?.interviewer_name}
                      </TableCell>
                      {/* <TableCell align="center" style={{ border: "1px solid black"}}>
                        {data?.applicant?.recruiter?.first_name}
                      </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="bottom" alignItems="bottom">
        {getInterviewDetailData && getInterviewDetailData.results.length !== 0 &&
          <Paper className={classes.tablepagination}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+getInterviewDetailData.total_pages}`}
                  // rowsPerPageOptions={[12]}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={+getInterviewDetailData.count}
                  rowsPerPage={rowsPerPage || 50}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(getInterviewDetailData.total_pages - 1)}
                    disabled={page === (+getInterviewDetailData.total_pages - 1)}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Paper>
        }
      </Grid>

      <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          // className={classes.modal}
          classes={{ paper: classesD.dialogMarks }}
          open={openView}
          maxWidth="35%"
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div>
            <Grid>
              {/* <Paper className={classes.paper}> */}
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h5">
                    <strong> Interview Recordings</strong>
                  </Typography>
                  <Typography className={classes.TableHeadtabBottom}>
                    <p>{getData2 ? getData2 : " " }</p>
                    </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
                <Divider className={classes.divider} />
          
                  {
                  <Grid container spacing={2}>  
                    <Grid item md={12} xs={12}>
                 
                       {/* {getZoomRecordinfData &&
                     
                        getZoomRecordinfData.results.map((data, i) => ( */}
                    {/* if (getZoomRecordinfData?.code == '3301') {
                      alert.warning("This recording does not exist.");
                       return;
                    } */}
                        
                      <div>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                    {/* <FormControl variant="outlined">
                    <InputLabel 
                    value={viewName}
                    /> */}
                  <Grid item md={4} xs={12}>
                   <Typography style={{ marginBottom: "8px" }}>
                    Candidate Name
                  </Typography>
                    <TextField
                        type='text'
                        disabled
                        // label='Update Name'
                        // variant="outlined"
                        width="100px"
                        margin='dense'
                        value={ viewName || ''}
                        // onChange={(e) => props.setUpdateName(e.target.value)}
                        // className='product-name'
                      /> 
                      </Grid>
                     
                      <Grid item md={4} xs={12}>
                   <Typography style={{ marginBottom: "8px" }}>
                    Passcode
                  </Typography>

                  {/* <form>
                  <Typography style={{ marginBottom: "8px" }}>
                    {" "}
                    Passcode
                  </Typography>
                      <textarea
                        ref={textAreaRef}
                        value={getZoomRecordinfData?.password}
                      />
                    </form> */}
                
                  <TextField
                     type='text'
                     id="filled-password-input"
                     margin='dense'
                     width="100px"
                    //  ref={textAreaRef}
                     value={getZoomRecordinfData?.password || ''}
                     disabled={getData1 == 3301}
                     // label="Passcode"
                     // type="password"
                     // autoComplete="current-password"
                    //  variant="filled"
                  />
                  {/* {document.queryCommandSupported("copy") && (
                    <div>
                    <Tooltip title="Copy">
                    <FileCopyIcon 
                    onClick={copyToClipboard} 
                    />
                    </Tooltip>
                    {copySuccess}
                    </div>
                  //   <div>
                  //   <button onClick={copyToClipboard}>Copy</button>
                  //   {copySuccess}
                  // </div>
                  )} */}
                  </Grid>
             
                  </div>

                  <Grid item md={12} xs={12}>
                   <Typography style={{ marginTop: "10px" }}>
                    Comment
                  </Typography>
                    <TextField
                        type='text'
                        fullWidth
                        rows="3"
                        margin="dense"
                        multiline
                        variant="outlined"
                        disabled
                        // label='Update Name'
                        // variant="outlined"
                        width="100px"
                        value={ comments || ''}
                        // onChange={(e) => props.setUpdateName(e.target.value)}
                        // className='product-name'
                      /> 



                  {/* <Grid item md={12} xs={12}>
                      <TextField
                        label="Comments"
                        
                        fullWidth
                        rows="2"
                        // required
                        margin="dense"
                        multiline
                        variant="outlined"
                        value={comments || ''}
                        onChange={(e) => setComments(e.target.value)}
                      />
                    </Grid> */}
                      </Grid>
          
                    <div style={{display:'flex', justifyContent: 'center'}}>
                    <Button
                          style={{ marginTop: "20px" }}
                          variant="contained"
                          color="primary"
                          href={getZoomRecordinfData?.share_url}
                          target={"_blank"}
                          disabled={getData1 == 3301}
                          >
                         Click to Play
                      </Button>
                      </div>
                      </div>
                    </Grid>
                  </Grid>
}
              {/* </Paper> */}
            </Grid>
          </div>
        </Dialog>
          </React.Fragment>
      </Paper>
    )}
       {loader}
    </React.Fragment>
  )
}

InterviewDetails.propTypes = {
classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(InterviewDetails);
