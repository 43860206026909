import {
  Button,
  Card,
  DatePicker,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  EyeOutlined,
  PlusCircleOutlined,
  RiseOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import urls from "../../../url";
import dayjs from "dayjs";
import { DeleteOutline } from "@material-ui/icons";
import { useForm } from "antd/es/form/Form";
import { useLocation } from "react-router-dom";
import moment from "moment";
function JobPosts() {
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginData] = useState(JSON.parse(localStorage.getItem("user")));
  const [showDrawer, setShowDrawer] = useState(false);
  const role = loginData && loginData.personal_info.role_id;
  const user_id = loginData && loginData.personal_info?.user_id;
  const [screenshot, setScreenhot] = useState();
  const [editId, setEditId] = useState(false);
  const [form] = useForm();
  const location = useLocation();
  let { mrf, position } = location.state;
  useEffect(() => {
    fetchJobList({
      mrf: mrf,
      position: position,
    });
  }, []);
  function fetchJobList(params = {}) {
    setLoading(true);
    axios
      .get(`${urls.otherJobPosts}`, {
        params: { ...params },
        headers: {
          Authorization: `Bearer ${loginData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          setJobList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleDelete(id) {
    axios
      .delete(`${urls.otherJobPosts}${id}/`, {
        headers: {
          Authorization: `Bearer ${loginData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        message.success("Post Deleted Successfully!");
        fetchJobList({
          mrf: mrf,
          position: position,
        });
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  }
  function handleUpdate(data) {
    setEditId(data);
    setShowDrawer(true);
    console.log(data);
    form.setFieldsValue({
      posted_on: moment(data.posted_on),
      job_portal_url: data.job_portal_url,
      no_of_vacancies: data.no_of_vacancies,
    });
  }
  const columns = [
    {
      title: "Posted On",
      key: "posted_on",
      dataIndex: "posted_on",
      render: (data) => <span>{dayjs(data)?.format("DD MMM, YYYY")}</span>,
    },
    {
      title: "Vacancies",
      key: "no_of_vacancies",
      dataIndex: "no_of_vacancies",
    },
    {
      title: "Screenshot",
      key: "screenshot",
      dataIndex: "screenshot",
      render: (data) => <Image src={data} height={80} alt={"screenshot"} />,
    },
    {
      title: "Action",
      key: "action",
      render: (data) => (
        <Space>
          <a href={data?.job_portal_url} target="_blank">
            <Tag color={"purple"}>Go to post</Tag>
          </a>
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => handleUpdate(data)}
            icon={<EditOutlined />}
            color="warning"
          >
            Edit
          </Tag>
          <Popconfirm
            okText="Ok"
            cancelText="cancel"
            title="Are you sure to delete?"
            onConfirm={() => handleDelete(data?.id)}
          >
            <Tag
              style={{ cursor: "pointer" }}
              icon={<DeleteOutlined />}
              color="error"
            >
              Delete
            </Tag>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const closeDrawer = () => {
    setShowDrawer(false);
    setEditId(null);
    form.resetFields();
  };

  const handlePost = (formValues) => {
    let formData = new FormData();
    formData.append("recruiter", user_id);
    Object.keys(formValues).forEach((key) => {
      if (key === "screenshot") {
        if (screenshot) {
          formData.append(key, screenshot, screenshot.name);
        }
      } else if (key === "posted_on") {
        formData.append(key, dayjs(formValues[key]).format("YYYY-MM-DD"));
      } else formData.append(key, formValues[key]);
    });
    if (position) {
      formData.append("position", position);
    }
    if (mrf) {
      formData.append("mrf", mrf);
    }
    if (editId) {
      axios
        .put(`${urls.otherJobPosts}${editId?.id}/`, formData, {
          headers: {
            Authorization: `Bearer ${loginData?.personal_info?.token}`,
          },
        })
        .then((response) => {
          message.success("Updated Successfully!");
          closeDrawer();
          fetchJobList({
            mrf: mrf,
            position: position,
          });
        })
        .catch((error) => {
          message.error(
            error?.response?.data?.message ?? "Something went wrong!"
          );
        });
    } else {
      axios
        .post(`${urls.otherJobPosts}`, formData, {
          headers: {
            Authorization: `Bearer ${loginData?.personal_info?.token}`,
          },
        })
        .then((response) => {
          closeDrawer();
          message.success("Post Screenshot Added Successfully!");
          fetchJobList({
            mrf: mrf,
            position: position,
          });
        })
        .catch((error) => {
          message.error(
            error?.response?.data?.message ?? "Something went wrong!"
          );
        });
    }
  };
  return (
    <React.Fragment>
      <Card>
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <div>
            <strong>Other Job Posts</strong>
          </div>
          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setShowDrawer(true);
            }}
            type={"primary"}
          >
            Add Job Post
          </Button>
        </Space>
        <Table
          dataSource={jobList}
          columns={columns}
          loading={loading}
          scroll={{ x: "max-content" }}
          pagination={false}
        />
      </Card>
      <Drawer
        open={showDrawer}
        onClose={closeDrawer}
        title={`${editId ? "Edit" : "Add"} Other Job Post`}
        footer={
          <Space>
            <Button onClick={closeDrawer}>Cancel</Button>
            <Button type="primary" htmlType="submit" form="otherJobForm">
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          form={form}
          id="otherJobForm"
          onFinish={handlePost}
        >
          <Form.Item
            name={"job_portal_url"}
            label="Portal Url"
            rules={[{ required: true, message: "Portal url is required!" }]}
          >
            <Input placeholder="Portal URL" className="w-100" />
          </Form.Item>
          <Form.Item
            name={"no_of_vacancies"}
            label="No. Of Vacancies"
            rules={[
              { required: true, message: "No. Of Vacancies is required!" },
            ]}
          >
            <InputNumber
              min={1}
              placeholder="No. Of Vacancies"
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            name={"screenshot"}
            label="Screenshot"
            rules={[
              {
                required: editId ? (editId?.screenshot ? false : true) : true,
                message: "Screenshot is required!",
              },
            ]}
          >
            <Input
              onChange={(e) => setScreenhot(e.target.files[0])}
              accept="image/*"
              type="file"
              className="w-100"
            />
          </Form.Item>
          <Form.Item
            name={"posted_on"}
            label="Posted On"
            rules={[{ required: true, message: "Posted On is required!" }]}
          >
            <DatePicker placeholder="Post Date" className="w-100" />
          </Form.Item>
        </Form>
      </Drawer>
    </React.Fragment>
  );
}

export default JobPosts;
