import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import urls from "../../../../url";
import axios from "axios";
import {
  Button,
  Table,
  Tag,
  Row,
  Col,
  Form,
  Select,
  Radio,
  DatePicker,
  Spin,
  Timeline,
  Drawer,
  Tooltip,
  Space,
  Input,
  Modal,
  Empty,
  message,
  AutoComplete,
  Switch,
  InputNumber,
  Avatar,
} from "antd";
import {
  EditOutlined,
  EyeOutlined,
  ClearOutlined,
  PlusCircleOutlined,
  RiseOutlined,
  CheckCircleOutlined,
  DatabaseOutlined,
  CloseCircleOutlined,
  PauseCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useForm } from "antd/es/form/Form";
import ClarityChatBox from "./clarityChatBox";
import debounce from "lodash/debounce";
import MrfDetailDrawer from "./mrfDetailDrawer";
import dayjs from "dayjs";
const mrfStatusList = [
  { id: "approve", value: "Approve" },
  { id: "pending", value: "Pending" },
  { id: "reject", value: "Reject" },
  { id: "hold", value: "Hold" },
];
const mrfActiveStatusList = [
  { id: "true", value: "Active" },
  { id: "false", value: "Deactive" },
];
const mrfTypeList = [
  { label: "New Hire", value: "new_hire" },
  { label: "Replacement", value: "replacement" },
  { label: "Buffer", value: "buffer" },
];
const MrfList = () => {
  const { RangePicker } = DatePicker;
  const [formRef] = useForm();
  const { TextArea } = Input;
  const [feedBackModalForm] = useForm();
  const [approveFeedBackModalForm] = useForm();
  const [assigneeForm] = useForm();
  const { Option } = Select;
  const history = useHistory();
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const [progressDrawer, setProgressDrawer] = useState(false);
  const [assignDrawer, setAssignDrawer] = useState(false);
  const [feedBackModal, setFeedBackModal] = useState(false);
  const [approveFeedBackModal, setApproveFeedBackModal] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [progressRowData, setProgressRowData] = useState([]);
  const [progressLoading, setProgressLoading] = useState(false);
  const [recruiter, setRecruiter] = useState();
  const [recruiterList, setRecruiterList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [clarityDrawer, setClarityDrawer] = useState(false);
  const [selectedMrf, setSelectedMrf] = useState();
  const [raiseLoading, setRaiseLoading] = useState(false);
  let role = userData?.personal_info?.role_id;
  let employeeId = userData?.personal_info?.user_id;
  const [positionTypesList, setPositionTypesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState();
  const [mrfList, setMrfList] = useState([]);
  const [flag, setFlag] = useState(true);
  const [searchText, setSearchText] = useState();
  const [branchList, setBranchList] = useState([]);
  const [employee, setEmployee] = useState();
  const [additionalRecruiterList, setAdditionalRecruiterList] = useState([]);
  const [mrfDetailsDrawer, setMrfDetailsDrawer] = useState(false);
  const [replacementData, setReplacementData] = useState([]);
  const [isReAssign, setIsReAssign] = useState(false);
  const [isReplacement, setIsReplacemet] = useState(false);
  useEffect(() => {
    fetchPositionTypesList();
    fetchRecruiterList();
    fetchBranchList();
  }, []);
  useEffect(() => {
    fetchMrfList();
  }, [currentPage, flag, pageSize]);

  console.log("++", replacementData);
  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`)
      .then((response) => {
        setPositionTypesList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };
  const fetchBranchList = () => {
    axios
      .get(`${urls.branch}`)
      .then((response) => {
        setBranchList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };
  const fetchTimeLine = (id) => {
    setProgressLoading(true);
    axios
      .get(`${urls.mrfTimeLine}?mrf=${id}`)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          setProgressRowData(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setProgressLoading(false);
      });
  };
  const closeProgressDrawer = () => setProgressDrawer(false);
  const closeAssignDrawer = () => {
    setAssignDrawer(false);
    assigneeForm.resetFields();
  };
  const closeFeedBackModal = () => {
    setFeedBackModal(false);
    feedBackModalForm.resetFields();
  };
  const closeApproveFeedBackModal = () => {
    setApproveFeedBackModal(false);
    approveFeedBackModalForm.resetFields();
    setReplacementData([]);
    setIsReplacemet(false);
  };
  const handleClear = () => {
    formRef.resetFields();
    setCurrentPage(1);
    setEmployee(null);
    setEmployeeList([]);
    setFlag(!flag);
  };
  const handleFilter = () => {
    if (currentPage != 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(1);
      fetchMrfList({
        firstPage: 1,
      });
    }
  };
  const fetchMrfList = (params = {}) => {
    const { firstPage } = params;
    console.log(firstPage, "test");

    const values = formRef.getFieldsValue();
    let start_date, end_date;
    if (values?.date_range) {
      start_date = values?.date_range[0].format("YYYY-MM-DD");
      end_date = values?.date_range[1].format("YYYY-MM-DD");
    }
    const formDataParams = {
      start_date: start_date ? start_date + "T00:00:00" : null,
      end_date: end_date ? end_date + "T23:59:59" : null,
      position_type: values?.position_type ? values?.position_type : null,
      position_type_fk: values?.position_type_fk
        ? values?.position_type_fk
        : null,
      status: values?.status ? values?.status : null,
      is_active: values?.active_status ? values?.active_status : null,
      page: firstPage ? firstPage : currentPage,
      page_size: pageSize,
      id: values?.id,
      mrf_type: values?.mrf_type,
      branch: values?.branch,
      // employee: userData.personal_info?.user_id,
    };

    if (role != 1 && role != 4 && role != 21 && role != 13) {
      formDataParams.emp_user_id = employeeId;
    } else {
      formDataParams.employee = employee ?? null;
    }
    setLoading(true);
    axios
      .get(`${urls.mrfForm}`, {
        params: formDataParams,
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          setMrfList(response?.data?.results);
          setTotalCount(response?.data?.count);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchRecruiterList = () => {
    axios
      .get(`${urls.retrieveRecuriterPanel}`)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          setRecruiterList(response?.data);
          setAdditionalRecruiterList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {});
  };

  const handleStatus = (id, val, vacancies, data) => {
    if (data?.mrf_type === "replacement") {
      setIsReplacemet(true);
      setReplacementData(JSON.parse(data?.replacement_json));
      let dataForDate = JSON.parse(data?.replacement_json);
      console.log("++", dataForDate);
      dataForDate?.forEach((each, index) => {
        approveFeedBackModalForm?.setFieldsValue({
          [`replacement_exit_date_${index}`]: dayjs(each?.exit_date),
          [`replacement_joining_date_${index}`]: dayjs(each?.joining_date),
        });
      });
    }
    if (val === "reject") {
      setFeedBackModal(true);
    } else {
      approveFeedBackModalForm.setFieldsValue({
        no_of_vacancies: vacancies,
      });
      setApproveFeedBackModal(true);
    }
  };

  const handleReAssign = (e, data) => {
    e.stopPropagation();
    setRowId(data?.id);
    console.log("==", data?.assigned_recruter);
    assigneeForm.setFieldValue("asignee", data?.assigned_recruter);
    assigneeForm.setFieldValue(
      "asignee_additional",
      data?.additional_recruiter
    );
    // assigneeForm.setFieldsValue({
    //   asignee: data?.assigned_recruiter,
    //   asignee_additional: data?.additional_recruiter,
    // });
    setIsReAssign(true);
    setAssignDrawer(true);
  };

  const handleHoldStatus = (id, val) => {
    const values = approveFeedBackModalForm.getFieldsValue();
    const formData = new FormData();
    if (val) {
      formData.append("status", val);
    }
    axios
      .put(`${urls.mrfForm}${id}/`, formData)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          message.success("MRF Status Changed Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        fetchMrfList();
      });
  };

  const handleReject = () => {
    const values = feedBackModalForm.getFieldsValue();
    setLoading(true);
    const formData = new FormData();
    formData.append("status", "reject" ?? null);
    formData.append("remark", values?.remarks ?? null);
    axios
      .put(`${urls.mrfForm}${rowId}/`, formData)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          message.success("Status Updated Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        closeFeedBackModal();
        fetchMrfList();
      });
  };

  const handleChange = (index, name, value) => {
    let arr = replacementData;
    arr[index][name] = value;
    setReplacementData([...arr]);
  };

  const handleApprove = () => {
    setApproveLoading(true);
    const values = approveFeedBackModalForm.getFieldsValue();
    const formData = new FormData();

    formData.append("status", "approve");
    formData.append("verified_by", userData?.personal_info?.user_id ?? null);
    formData.append("no_of_vacancies", values?.no_of_vacancies);
    formData.append("max_budget", values?.max_budget);
    formData.append(
      "mrf_status_date",
      dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSSSS")
    );
    if (replacementData?.length) {
      formData.append("replacement_json", JSON.stringify(replacementData));
    }
    axios
      .put(`${urls.mrfForm}${rowId}/`, formData)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          message.success("MRF Approved Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        closeApproveFeedBackModal();
        fetchMrfList();
        setApproveLoading(false);
      });
    // setAssignDrawer(true);
  };

  const handleAssignRecruiter = () => {
    setLoading(true);
    const { asignee, asignee_additional } = assigneeForm.getFieldsValue();
    let obj = {
      assigned_recruter: asignee,
      status: "approve" ?? null,
      // verified_by: userData?.personal_info?.user_id ?? null,
    };
    if (asignee_additional) {
      obj.additional_recruiter = [...asignee_additional];
    }
    axios
      .put(`${urls.mrfForm}${rowId}/`, obj)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          message.success("Recruiter Assigned Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
        closeAssignDrawer();
        fetchMrfList();
      });
  };

  const handleDeactivateMRF = (mrfId, currStatus) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("is_active", !currStatus);
    axios
      .put(`${urls.mrfForm}${mrfId}/`, formData)
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          if (currStatus) {
            message.success("MRF Deactivated Successfully");
          } else {
            message.success("MRF Activated Successfully");
          }
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
        fetchMrfList();
      });
  };

  const handleDebounce = useCallback(
    debounce((value) => {
      fetchEmployee({ erp: value });
    }, 700),
    []
  );

  const handleRecruiterChange = (e, val) => {
    let { asignee, asignee_additional } = assigneeForm.getFieldsValue();
    assigneeForm.resetFields(["asignee_additional"]);
    setRecruiter(val?.value);
    let newRecruiterList = recruiterList.filter((each) => each?.id !== asignee);
    setAdditionalRecruiterList(newRecruiterList);
  };

  const mrfStatusOptions = mrfStatusList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });

  const mrfTypeOptions = mrfTypeList.map(({ label, value }) => {
    return (
      <Option key={value} value={value}>
        {label}
      </Option>
    );
  });

  const mrfActiveStatusOptions = mrfActiveStatusList.map((each) => {
    return (
      <Option key={each?.id} value={each?.id}>
        {each?.value}
      </Option>
    );
  });
  const positionTypesOptions = positionTypesList?.map((each) => (
    <Option key={each?.id} value={each?.id}>
      {each?.position_type}
    </Option>
  ));
  const branchOptions = branchList?.map((each) => (
    <Option key={each?.id} value={each?.id}>
      {each?.branch_name}
    </Option>
  ));

  const columns = [
    {
      title: "MRF Id",
      key: "id",
      dataIndex: "id",
      fixed: "left",
      render: (data) => (
        <Space>
          <Tag
            icon={<PlusOutlined />}
            style={{ padding: "0px 3px", fontSize: "13px" }}
            className="th-pointer"
          />
          <div>{data}</div>
        </Space>
      ),
    },
    {
      title: "Position Title",
      key: "position",
      render: (data) => (
        <span>
          {data?.position?.length > 26 ? (
            <Tooltip
              autoAdjustOverflow="false"
              placement="bottomLeft"
              title={data?.position}
              overlayStyle={{ maxWidth: "40%", minWidth: "20%" }}
            >
              {`${data?.position.substring(0, 26)}...`}
            </Tooltip>
          ) : (
            data?.position
          )}
        </span>
      ),
    },
    {
      title: "Designation",
      key: "designation",
      render: (data) => (
        <span>
          {data?.designation_fk_name?.length > 26 ? (
            <Tooltip
              autoAdjustOverflow="false"
              placement="bottomLeft"
              title={data?.designation_fk_name}
              overlayStyle={{ maxWidth: "40%", minWidth: "20%" }}
            >
              {`${data?.designation_fk_name.substring(0, 26)}...`}
            </Tooltip>
          ) : (
            data?.designation_fk_name
          )}
        </span>
      ),
    },
    {
      title: "Academic Year",
      key: "academi_year",
      align: "center",
      render: (data) => (
        <span>{data?.academic_year ? data?.academic_year : "-"}</span>
      ),
    },
    {
      title: "Date",
      key: "created_at",
      render: (data) => (
        <span>{moment(data?.created_at).format("YYYY-MM-DD")}</span>
      ),
      sorter: (a, b) =>
        moment(a.created_at).valueOf() - moment(b.created_at).valueOf(),
    },
    {
      title: "Branch",
      key: "branch",
      align: "center",
      render: (data) => <span>{data?.branch_name}</span>,
    },
    {
      title: "MRF Type",
      key: "mrf_type",
      align: "center",
      render: (data) => (
        <span style={{ textTransform: "capitalize" }}>
          {data?.mrf_type?.split("_")?.join(" ")}
        </span>
      ),
    },
    {
      title: "MRF Approved By",
      key: "mrf_approved_by",
      align: "center",
      render: (data) => (
        <span>
          {data?.status === "approve" ? (
            data?.verified_by
          ) : (
            <span style={{ fontStyle: "italic", color: "red" }}>
              {"Yet to be approved"}
            </span>
          )}
        </span>
      ),
    },
    {
      title: "Budget (Annual)",
      key: "budget",
      align: "center",
      render: (data) => <span>{data?.max_budget ?? "-"}</span>,
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      render: (data) => {
        let allowedRoles = [21, 13];
        return (
          <>
            <div>
              <span>
                {!allowedRoles?.includes(userData.personal_info?.role_id) ? (
                  <>
                    {data?.status === "approve" && (
                      <span style={{ color: "green", fontStyle: "italic" }}>
                        Approved
                      </span>
                    )}
                    {data?.status === "pending" && (
                      <span style={{ color: "blue", fontStyle: "italic" }}>
                        Pending
                      </span>
                    )}
                    {data?.status === "hold" && (
                      <span style={{ color: "blue", fontStyle: "italic" }}>
                        Hold
                      </span>
                    )}
                    {data?.status === "reject" && (
                      <Tooltip title={`Rejection Remarks: ${data?.remark}`}>
                        <span style={{ color: "red", fontStyle: "italic" }}>
                          Rejected <InfoCircleOutlined />
                        </span>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <>
                    {data?.status === "pending" ? (
                      <Space>
                        <Tooltip
                          autoAdjustOverflow="false"
                          placement="bottom"
                          title="APPROVE"
                        >
                          <Tag
                            icon={<CheckCircleOutlined />}
                            color="green"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleStatus(
                                data?.id,
                                "approve",
                                data?.no_of_vacancies,
                                data
                              );
                              setRowId(data?.id);
                            }}
                          >
                            Approve
                          </Tag>
                        </Tooltip>
                        <Tooltip
                          autoAdjustOverflow="false"
                          placement="bottom"
                          title="REJECT"
                        >
                          <Tag
                            icon={<CloseCircleOutlined />}
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleStatus(data?.id, "reject");
                              setRowId(data?.id);
                            }}
                          >
                            Reject
                          </Tag>
                        </Tooltip>
                        <Tooltip
                          autoAdjustOverflow="false"
                          placement="bottom"
                          title="HOLD"
                        >
                          <Tag
                            icon={<PauseCircleOutlined />}
                            color="warning"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleHoldStatus(data?.id, "hold");
                              setRowId(data?.id);
                            }}
                          >
                            Hold
                          </Tag>
                        </Tooltip>
                      </Space>
                    ) : data?.status === "hold" ? (
                      <Tooltip
                        autoAdjustOverflow="false"
                        placement="bottom"
                        title="Send for approval"
                      >
                        <Tag
                          icon={<CheckCircleOutlined />}
                          color="purple"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleHoldStatus(data?.id, "pending");
                            setRowId(data?.id);
                          }}
                        >
                          Send for approval
                        </Tag>
                      </Tooltip>
                    ) : (
                      <>
                        {data?.status === "approve" && (
                          <span style={{ color: "green", fontStyle: "italic" }}>
                            Approved
                          </span>
                        )}
                        {data?.status === "reject" && (
                          <Tooltip title={`Rejection Remarks: ${data?.remark}`}>
                            <span style={{ color: "red", fontStyle: "italic" }}>
                              Rejected <InfoCircleOutlined />
                            </span>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
            <div>
              {data?.position_apply_id === null &&
                userData.personal_info?.role_id === 1 &&
                data?.status !== "pending" && (
                  <Space>
                    <Tooltip
                      autoAdjustOverflow="false"
                      placement="bottom"
                      title="PENDING"
                    >
                      <Tag
                        color="blue"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleHoldStatus(data?.id, "pending");
                          setRowId(data?.id);
                        }}
                      >
                        Change Status to Pending
                      </Tag>
                    </Tooltip>
                  </Space>
                )}
            </div>
          </>
        );
      },
    },

    {
      title: "Assignee",
      key: "asignee",
      align: "center",
      render: (data) => {
        let allowedRoles = [4];
        return (
          <>
            {allowedRoles?.includes(userData.personal_info?.role_id) ? (
              <span>
                <Row justify="center">
                  {data?.status === "pending" ? (
                    <span style={{ fontStyle: "italic" }}>
                      {"Yet to be approved"}
                    </span>
                  ) : data?.status === "reject" ? (
                    <span style={{ fontStyle: "italic", marginRight: "5px" }}>
                      {"MRF rejected"}
                    </span>
                  ) : (
                    data?.assigned_recruter === null && (
                      <Tag
                        onClick={(e) => {
                          e.stopPropagation();
                          setRowId(data?.id);
                          setAssignDrawer(true);
                        }}
                        color="processing"
                        style={{ cursor: "pointer" }}
                      >
                        Assign
                      </Tag>
                    )
                  )}

                  {data?.status != "pending" &&
                    data?.assigned_recruiter_name !== null && (
                      <>
                        <Tag>{data?.assigned_recruiter_name}</Tag>
                        <Tag
                          onClick={(e) => {
                            handleReAssign(e, data);
                          }}
                          color="processing"
                          style={{ cursor: "pointer" }}
                        >
                          Re-assign
                        </Tag>
                      </>
                    )}
                </Row>
              </span>
            ) : data?.status === "pending" ? (
              <span style={{ fontStyle: "italic" }}>
                {"Yet to be approved"}
              </span>
            ) : data?.status === "reject" ? (
              <span style={{ fontStyle: "italic", marginRight: "5px" }}>
                {"MRF rejected"}
              </span>
            ) : data?.status == "approve" ? (
              <span style={{ fontStyle: "italic" }}>
                {data?.assigned_recruiter_name ?? "Approved"}
              </span>
            ) : data?.status == "hold" ? (
              <span style={{ fontStyle: "italic" }}>{"On Hold"}</span>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (data) => (
        <Space direction="horizontal">
          {data?.status === "pending" && (
            <Tooltip autoAdjustOverflow="false" placement="bottom" title="EDIT">
              <Tag
                icon={<EditOutlined style={{ fontSize: "16px" }} />}
                color="warning"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push({
                    pathname: `/mrf_form`,
                    state: { rowData: data },
                  });
                }}
              ></Tag>
            </Tooltip>
          )}
          <Tooltip
            autoAdjustOverflow="false"
            placement="bottom"
            title="PROGRESS"
          >
            <Tag
              onClick={(e) => {
                e.stopPropagation();
                setProgressDrawer(true);
                fetchTimeLine(data?.id);
              }}
              color="geekblue"
              icon={<RiseOutlined />}
              style={{ cursor: "pointer", fontSize: "16px" }}
            ></Tag>
          </Tooltip>
          {data?.description_file && (
            <Tooltip
              autoAdjustOverflow="false"
              placement="bottom"
              title="VIEW JD"
            >
              <Tag
                color="error"
                style={{ cursor: "pointer", fontSize: "16px" }}
                icon={<EyeOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(data?.description_file, "_blank");
                }}
              ></Tag>
            </Tooltip>
          )}
          {(userData?.personal_info?.role_id == 13 ||
            userData?.personal_info?.role_id == 21 ||
            userData?.personal_info?.role_id == 1 ||
            userData?.personal_info?.user_id == data?.employee) &&
            data?.status === "hold" && (
              <Tooltip
                autoAdjustOverflow="false"
                placement="bottom"
                title="Get Clarity"
              >
                <Tag
                  color="green"
                  style={{ cursor: "pointer", fontSize: "16px" }}
                  icon={<ClearOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    setClarityDrawer(true);
                    setSelectedMrf(data?.id);
                  }}
                ></Tag>
              </Tooltip>
            )}

          {data?.status === "approve" && data?.position_apply_id && (
            <Tooltip
              autoAdjustOverflow="false"
              placement="bottom"
              title="APPLICANTS"
            >
              <Tag
                icon={<DatabaseOutlined />}
                color="purple"
                style={{ cursor: "pointer", fontSize: "16px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname: `/mrf_form_applicants`,
                    state: {
                      rowData: data,
                      mrf: data?.id,
                      position_id: data?.position_apply_id,
                      url: "/mrf_list",
                    },
                  });
                }}
              ></Tag>
            </Tooltip>
          )}
          {data?.status === "approve" && data?.position_apply_id === null && (
            <Tooltip
              autoAdjustOverflow="false"
              placement="bottom"
              title="ADD POSITION"
            >
              <Tag
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/create_positions", {
                    data: { ...data, url: "/mrf_list" },
                  });
                }}
                style={{ cursor: "pointer" }}
                color="processing"
                icon={<PlusCircleOutlined />}
              ></Tag>
            </Tooltip>
          )}
        </Space>
      ),
    },
    {
      title: "MRF Current Status",
      key: "mrf_current_status",
      align: "center",
      render: (data) => (
        <Space>
          <Tooltip
            autoAdjustOverflow="false"
            placement="bottom"
            title={data?.is_active ? "DEACTIVATE MRF" : "ACTIVATE MRF"}
          >
            <Switch
              checked={data?.is_active}
              checkedChildren="Activated"
              unCheckedChildren="Deactivated"
              onChange={(e, event) => {
                event.stopPropagation();
                handleDeactivateMRF(data?.id, data?.is_active);
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  function fetchEmployee(params = {}) {
    if (params?.erp?.length < 1) return;
    setRaiseLoading(true);
    setEmployeeList([]);
    axios
      .get(`${urls.employeeListGlobal}`, {
        params: { ...params },
      })
      .then((response) => {
        if (response?.status === 200) {
          const options = response?.data?.data;
          setEmployeeList(options);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        setRaiseLoading(false);
      });
  }

  function handleOpenDetails(rowData) {
    setMrfDetailsDrawer(true);
    setSelectedMrf(rowData?.id);
  }

  function handleCloseDetails() {
    setMrfDetailsDrawer(false);
    setSelectedMrf();
  }
  return (
    <>
      <div className="page_body">
        <Row justify={"space-between"} align={"middle"}>
          <div className="page_title">MRF List</div>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => history.push("mrf_form")}
          >
            Add MRF
          </Button>
        </Row>

        <Form
          id="filterForm"
          layout="vertical"
          form={formRef}
          onFinish={() => handleFilter()}
          style={{ width: "100%", marginBlock: "20px" }}
        >
          <Row justify="" align="middle" gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="date_range" label="Start Date - End Date">
                <RangePicker allowClear style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="position_type_fk" label="Position Types">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  // onChange={handlePositionTypesChange}
                  placeholder="Select Position Type"
                  style={{ width: "100%" }}
                >
                  {positionTypesOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="mrf_type" label="MRF Type">
                <Select allowClear placeholder="Select MRF Type">
                  {mrfTypeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="status" label="MRF Status">
                <Select allowClear placeholder="Select MRF Status">
                  {mrfStatusOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="branch" label="Branch">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Branch"
                >
                  {branchOptions}
                </Select>
              </Form.Item>
            </Col>
            {(role == 1 || role == 4 || role == 13 || role == 21) && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <Form.Item name="raised_by" label="Raised By">
                  <AutoComplete
                    placeholder="Search by Name / ERP / Email"
                    onSearch={(text) => {
                      setSearchText(text);
                      handleDebounce(text);
                    }}
                    onSelect={(value, obj) => {
                      setEmployee(obj?.obj?.user);
                      setEmployeeList([]);
                    }}
                    suffixIcon={raiseLoading && <Spin />}
                    allowClear
                    notFoundContent={<Empty />}
                  >
                    {employeeList?.map((employee) => (
                      <Option
                        key={employee?.erp}
                        value={`${employee?.name} | ${employee?.erp}`}
                        obj={employee}
                      >
                        {employee?.name} | {employee?.email}
                      </Option>
                    ))}
                  </AutoComplete>
                </Form.Item>
              </Col>
            )}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Form.Item name="id" label="MRF ID">
                <Input allowClear className="w-100" placeholder="MRF ID" />
              </Form.Item>
            </Col>
            {/* </Row>
          <Row justify="end"> */}
            <Button
              className="button secondary"
              style={{ marginLeft: "8px" }}
              onClick={() => handleClear()}
            >
              Clear
            </Button>
            <Button
              form="filterForm"
              htmlType="submit"
              type="primary"
              className="button primary"
              style={{ marginLeft: "8px" }}
            >
              Filter
            </Button>
            {/* {userData.personal_info?.role_id !== 3 && ( */}

            {/* )} */}
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={mrfList}
          pagination={{
            total: totalCount,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
            current: currentPage,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
          onRow={(row) => ({
            onClick: () => {
              handleOpenDetails(row);
            },
          })}
        />
      </div>
      <Drawer
        title="Progress"
        open={progressDrawer}
        onClose={closeProgressDrawer}
        footer={
          <div>
            <Button onClick={closeProgressDrawer}>Close</Button>
          </div>
        }
        width={500}
      >
        <div>
          {progressLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "250px",
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              {progressRowData && progressRowData?.length === 0 && (
                <Empty
                  style={{ marginTop: "200px" }}
                  description="Process Not Started"
                />
              )}
              {progressRowData && progressRowData?.length !== 0 && (
                <>
                  <Timeline pending={false} mode="left">
                    {progressRowData?.map((eachStep) => {
                      return (
                        !eachStep?.applicant && (
                          <Timeline.Item
                            color="green"
                            dot={<CheckCircleOutlined />}
                            label={
                              <>
                                <div
                                  style={{
                                    fontSize: "14px",
                                    color: "#a1a1c2",
                                  }}
                                >
                                  {moment(eachStep?.created_at).format(
                                    "MMMM Do YYYY"
                                  )}
                                </div>
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "#a1a1c2",
                                  }}
                                >
                                  {moment(eachStep?.created_at).format(
                                    "h:mm:ss a"
                                  )}
                                </div>
                              </>
                            }
                          >
                            <span style={{ color: "#595c97" }}>
                              {" "}
                              {eachStep?.title}
                            </span>
                          </Timeline.Item>
                        )
                      );
                    })}
                  </Timeline>
                </>
              )}
            </>
          )}
        </div>
      </Drawer>
      <Drawer
        title={
          isReAssign
            ? "Re-assign Recruiter To Approve"
            : "Assign Recruiter To Approve"
        }
        open={assignDrawer}
        onClose={closeAssignDrawer}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={closeAssignDrawer}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" form="assigneeForm" htmlType="submit">
                Assign
              </Button>
            </Col>
          </Row>,
        ]}
        width={500}
      >
        <>
          <Form
            id="assigneeForm"
            layout="vertical"
            form={assigneeForm}
            onFinish={handleAssignRecruiter}
          >
            <Row align="middle" gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="asignee"
                  label="Primary Recruiter"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Primary Recruiter",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Recruiter"
                    onChange={(e, val) => {
                      handleRecruiterChange(e, val);
                    }}
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {recruiterList &&
                      recruiterList.length !== 0 &&
                      recruiterList.map((option, index) => {
                        return (
                          <Option key={option?.id} value={option?.id}>
                            {option.first_name ? option.first_name : ""}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="asignee_additional"
                  label="Additional Recruiter"
                >
                  <Select
                    mode="multiple"
                    placeholder="Select Recruiter"
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {additionalRecruiterList &&
                      additionalRecruiterList.length !== 0 &&
                      additionalRecruiterList.map((option, index) => {
                        return (
                          <Option key={option?.id} value={option?.id}>
                            {option.first_name ? option.first_name : ""}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      </Drawer>
      <Modal
        title="Reason for Rejecting"
        open={feedBackModal}
        onCancel={closeFeedBackModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={closeFeedBackModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" form="feedBackModalForm" htmlType="submit">
                Reject
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="feedBackModalForm"
          form={feedBackModalForm}
          layout="vertical"
          onFinish={handleReject}
        >
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Remarks",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  maxLength={1500}
                  showCount
                  placeholder="Enter Remarks"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Approving MRF"
        open={approveFeedBackModal}
        onCancel={closeApproveFeedBackModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={closeApproveFeedBackModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                form="approveFeedBackModalForm"
                htmlType="submit"
                loading={approveLoading}
              >
                Approve
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="approveFeedBackModalForm"
          form={approveFeedBackModalForm}
          layout="vertical"
          onFinish={handleApprove}
        >
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="no_of_vacancies"
                label="No of Vacancy"
                rules={[
                  {
                    required: true,
                    message: "Please Enter No of Vacancy",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Enter No of Vacancy"
                  disabled={isReplacement}
                  min={1}
                  max={10}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="max_budget"
                label="Maximum Budget For 1 Position (Annual)"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Max Budget",
                  },
                ]}
              >
                <InputNumber
                  addonBefore="Rs"
                  min={0}
                  placeholder="Enter Max Budget"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {isReplacement && replacementData?.length > 0
              ? replacementData?.map((each, index) => (
                  <div
                    style={{
                      backgroundColor: "#F0F0F0",
                      width: "100%",
                      margin: "10px",
                      padding: "10px",
                    }}
                  >
                    <Col span={24}>
                      <Form.Item
                        name={`replacement_exit_date_${index}`}
                        label={`Exit Date of ${each?.name}`}
                        rules={[
                          {
                            required: true,
                            message: "This field is required!",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={(e) => {
                            handleChange(
                              index,
                              "exit_date",
                              dayjs(e).format("YYYY-MM-DD")
                            );
                          }}
                          style={{ width: "100%" }}
                          placeholder="Enter Exit Date"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={`replacement_joining_date_${index}`}
                        label={`Tentative Joining Date`}
                        rules={[
                          {
                            required: true,
                            message: "This field is required!",
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={(e) => {
                            handleChange(
                              index,
                              "joining_date",
                              dayjs(e).format("YYYY-MM-DD")
                            );
                          }}
                          style={{ width: "100%" }}
                          placeholder="Enter Tentative Joining Date"
                        />
                      </Form.Item>
                    </Col>
                  </div>
                ))
              : ""}
          </Row>
        </Form>
      </Modal>
      {clarityDrawer && (
        <ClarityChatBox
          open={clarityDrawer}
          onClose={() => {
            setReplacementData([]);
            setClarityDrawer(false);
            setSelectedMrf(null);
          }}
          mrf={selectedMrf}
        />
      )}
      <MrfDetailDrawer
        open={mrfDetailsDrawer}
        onClose={handleCloseDetails}
        mrfId={selectedMrf}
      />
    </>
  );
};

export default MrfList;
