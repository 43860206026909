import React, { useState, useEffect } from "react";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Paper,
  Button,
  Select,
  TextField,
  InputLabel,
  Input,
  FormControl,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import styles from "./TransferRequest.style";
import urls from "../../url";
import useFetch from "../../hoc/fetch/useFetch";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Loader from "../../hoc/loader";

function TransferRequest({ classes }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [requestType, setRequestType] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [viewReason, setViewReason] = useState("");
  const [selectedId, setSelectedId] = useState(0);
  const [rejectReason, setRejectReason] = useState("");

  const {
    data: requestTypeData,
    isLoading: getRequestTypeLoading,
    doFetch: fetchRequestTypeList
  } = useFetch(null);

  let loader = null;
  if (getRequestTypeLoading) {
    loader = <Loader open />;
  }
  const handleRequestChanges = e => {
    setRequestType(e.target.value);
    fetchRequestTypeList({
      url: `${urls.requestTypes}?request=${e.target.value}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  };

  const viewReasonClick = reason => {
    setOpen(true);
    setViewReason(reason);
  };

  const rejectTransferClick = id => {
    setRejectOpen(true);
    setSelectedId(id);
  };

  const pricipalAcceptReject = async (accept, id) => {
    setSelectedId(id);
    let data = {};
    if (requestType === "in_request") {
      if (accept === true) {
        data = {
          transfer: id,
          is_accepted_by_new_branch: accept ? true : false
        };
      } else if (accept === false) {
        data = {
          transfer: selectedId,
          is_accepted_by_new_branch: accept ? true : false,
          reason_by_new_branch: rejectReason
        };
      }
    } else if (requestType === "out_request") {
      if (accept === true) {
        data = {
          transfer: id,
          is_accepted_by_current_branch: accept ? true : false
        };
      } else if (accept === false) {
        data = {
          transfer: selectedId,
          is_accepted_by_current_branch: accept ? true : false,
          reason_by_current_branch: rejectReason
        };
      }
    }

    const fetchResult = fetch(`${urls.transferAuntenticate}`, {
      method: "POST", // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        "Content-Type": "Application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
    const response = await fetchResult;
    const jsonData = await response.json();
    if (jsonData === "success") {
      alert.success("Successfully Assigned");
    }
    return jsonData;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRejectClose = () => {
    setRejectOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //   useEffect(() => {

  //   }, []);
  return (
    <div>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Typography variant='h6'>Select Request Type</Typography>
          <TextField
            className={classes.textField}
            required
            label='Select Request Type'
            margin='dense'
            onChange={handleRequestChanges}
            select
            value={requestType}
            variant='outlined'
          >
            <MenuItem value='in_request' key={1}>
              Incoming Requests
            </MenuItem>
            <MenuItem value='out_request' key={2}>
              Outgoing Requests
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' id='nameHead'>
                Name
              </TableCell>
              <TableCell align='center' id='dateHead'>
                Erp
              </TableCell>
              <TableCell align='center' id='scoreHead'>
                Mobile
              </TableCell>
              {/* <TableCell align='center' id='scoreHead'>
                Email
              </TableCell> */}
              <TableCell align='center' id='scoreHead'>
                {requestType === "in_request" ? "From Branch " : "To Branch"}
              </TableCell>
              <TableCell align='center' id='scoreHead'>
                Reason
              </TableCell>
              <TableCell align='center' id='scoreHead'></TableCell>
              <TableCell align='center' id='scoreHead'></TableCell>
              <TableCell align='center'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requestTypeData &&
              requestTypeData.results.map((row, index) => (
                <TableRow hover tabIndex={-1} key={row.id}>
                  <TableCell align='center'>{row.name}</TableCell>
                  <TableCell align='center'>{row.erp}</TableCell>
                  <TableCell align='center'>{row.mobile}</TableCell>
                  {/* <TableCell align='center'>{row.email}</TableCell> */}
                  <TableCell align='center'>
                    {requestType === "in_request"
                      ? row.from_branch.branch_name
                      : row.to_branch.branch_name}
                  </TableCell>
                  <TableCell align='center' id='scoreHead'>
                    {/* {row.reason_of_transfer} */}
                    <Button
                      size='small'
                      fullWidth
                      color='primary'
                      variant='contained'
                      onClick={() => viewReasonClick(row.reason_of_transfer)}
                    >
                      Reason To Transfer
                    </Button>
                  </TableCell>
                  <TableCell align='center'></TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      fullWidth
                      color='primary'
                      variant='contained'
                      onClick={() => pricipalAcceptReject(true, row.id)}
                    >
                      Accept
                    </Button>
                  </TableCell>
                  <TableCell align='center'>
                    <Button
                      size='small'
                      fullWidth
                      color='primary'
                      variant='contained'
                      onClick={() => rejectTransferClick(row.id)}
                    >
                      Reject
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {requestTypeData && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={requestTypeData.results.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <Dialog
        open={open}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' style={{ textAlign: "center" }}>
          Reason for Transfer{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <p>{viewReason}</p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={rejectOpen}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onClose={handleRejectClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' style={{ textAlign: "center" }}>
          Reason for Reject Transfer{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <Grid container>
              <Grid item md={12}>
                <TextField
                  id='outlined-textarea'
                  label='Reason To Reject Transfer'
                  placeholder='Write a Reason Here...'
                  multiline
                  fullwidth
                  variant='outlined'
                  onChange={e => setRejectReason(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Divider />
            <Button
              size='small'
              color='primary'
              variant='contained'
              onClick={() => pricipalAcceptReject(false)}
            >
              Submit
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {loader}
    </div>
  );
}

export default withStyles(styles)(TransferRequest);
