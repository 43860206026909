import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import urls from "../../../../url";
import axios from "axios";
import {
  Button,
  Table,
  Tag,
  Row,
  Spin,
  Timeline,
  Drawer,
  Image,
  Empty,
  Radio,
  Modal,
  Tooltip,
  Form,
  Col,
  Switch,
  Space,
  message,
  DatePicker,
  Select,
  Input,
  InputNumber,
  TimePicker,
  Rate,
  Avatar,
  Typography,
  Divider,
} from "antd";
import moment from "moment";
import {
  RiseOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  SyncOutlined,
  DownloadOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { CloudUploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import OfferLetterDrawer from "./OfferLetterDrawer";
import Search from "antd/es/input/Search";

const MrfFormApplicants = ({ location }) => {
  const history = useHistory();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [formRef] = useForm();
  const [shortlistFeedbackForm] = useForm();
  const [shortlistStatus, setShortlistStatus] = useState();
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [rowData] = useState(location?.state ? location?.state?.rowData : null);
  const [state] = useState(location?.state);
  const [applicantsData, setApplicantsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [open, setOpen] = useState(false);
  const [mrf] = useState(location?.state?.mrf);
  const [position_id] = useState(location?.state?.position_id);
  const [goBackUrl] = useState(location?.state?.url);
  const [progressRowData, setProgressRowData] = useState([]);
  const [progressLoading, setProgressLoading] = useState(false);
  const [feedBackModal, setFeedBackModal] = useState(false);
  const [candidateFeedBackModal, setCandidateFeedBackModal] = useState(false);
  const [feedBackModalForm] = useForm();
  const [candidateFeedBackModalForm] = useForm();
  const [docModal, setDocModal] = useState(false);
  const [docDetails, setDocDetails] = useState([]);
  const [applicant, setApplicant] = useState(false);
  const [docLoading, setDocLoading] = useState(false);
  const [docId, setDocId] = useState();
  const [candidateId, setCandidateId] = useState();
  const [page, setPage] = useState(1);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);
  const [selectedApplicantId, setSelectedApplicantId] = useState(null);
  const [isuploadingResume, setIsUploadingResume] = useState(false);
  const [openShortlistFeedbackModal, setOpenShortlistFeedbackForm] = useState(
    false
  );
  const [applicantId, setApplicantId] = useState();
  const desc = ["No Hire", "Average", "Good", "Very Good", "Excellent"];
  const [interviewProcessId, setInterviewProcessId] = useState();
  const [testFeedbackForm] = useForm();
  const [assignInterviewForm] = useForm();

  const [testFeedBackModal, setTestFeedbackModal] = useState(false);
  const [assignInterviewModal, setAssignInterviewModal] = useState(false);

  const [testType, setTestType] = useState();
  const [testRoundDetails, setTestRoundDetails] = useState([]);
  const [testData, setTestData] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [interviewerList, setInterviewerList] = useState([]);
  const [recruiterList, setRecruiterList] = useState([]);
  const [interviewType, setInterviewType] = useState();
  const [interviewRoundDetails, setInterviewRoundDetails] = useState([]);
  const [isClassDemo, setIsClassDemo] = useState("");
  const [multiSelect, setMultiSelect] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [offerLetterDrawer, setOfferLetterDrawer] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClosedDocModal = () => setDocModal(false);
  const applicantStatus = [
    {
      value: "shortlisted",
      label: "Shortlisted",
    },
    {
      value: "not_shortlisted",
      label: "Not Shortlisted",
    },
    {
      value: "pending",
      label: "Pending",
    },
  ];
  const joiningStatus = [
    {
      value: "true",
      label: "Joined",
    },
    {
      value: "false",
      label: "Not Joined",
    },
  ];
  useEffect(() => {
    if (docModal) {
      fetchDocDetails();
    }
  }, [docModal]);
  useEffect(() => {
    if (page) {
      fetchApplicants();
    }
  }, [page]);

  const handleCloseMultiSelect = () => {
    setSelectedRowKeys([]);
    setMultiSelect(false);
  };

  const downloadMutipleApplicantsProfile = () => {
    message.success("Downloading....", 5);
    let params = {
      download: "True",
      position_apply: position_id,
      applicants_id: selectedRowKeys.toString(),
    };

    axios
      .get(`${urls.multipleApplicantDetails}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
        params: params,
        responseType: "blob",
      })
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.toLowerCase().includes("application/json")) {
          return false;
        } else {
          const blob = window.URL.createObjectURL(
            new Blob([response?.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            })
          );
          let link = document.createElement("a");
          link.href = blob;
          link.setAttribute("download", "Applicant_Profile.xlsx");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
      })
      .finally(() => {
        handleCloseMultiSelect();
      });
  };

  const handleMultiSelectButton = () => {
    if (multiSelect) {
      if (selectedRowKeys.length) {
        downloadMutipleApplicantsProfile();
      } else {
        message.warning("Please select atleast one applicant");
      }
    } else {
      setMultiSelect(true);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record?.applicant?.is_nj_form_completed == false,
    }),
  };

  const fetchApplicants = () => {
    setLoading(true);
    const values = formRef.getFieldsValue();
    let created_at__gte, created_at__lte;
    if (values?.created_date_range) {
      created_at__gte = values?.created_date_range[0].format("YYYY-MM-DD");
      created_at__lte = values?.created_date_range[1].format("YYYY-MM-DD");
    }
    let joining_date__gte, joining_date__lte;
    if (values?.joining_date_range) {
      joining_date__gte = values?.joining_date_range[0].format("YYYY-MM-DD");
      joining_date__lte = values?.joining_date_range[1].format("YYYY-MM-DD");
    }
    const formDataParams = {
      created_at__gte: created_at__gte ? created_at__gte + "T00:00:00" : null,
      created_at__lte: created_at__lte ? created_at__lte + "T23:59:59" : null,
      joining_date__gte: joining_date__gte ? joining_date__gte : null,
      joining_date__lte: joining_date__lte ? joining_date__lte : null,
      shortlist_status: values?.status ? values?.status : null,
      is_joined: values?.is_joined,
      page: page,
      search: values?.search ? values?.search : "",
    };

    axios
      .get(`${urls.retrieveApplicants}${position_id}/`, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
        params: formDataParams,
      })
      .then((response) => {
        if (response?.status === 200) {
          setApplicantsData(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchTimeLine = (rowId) => {
    setProgressLoading(true);
    const params = {
      applicant: rowId,
      mrf: mrf ? mrf : null,
      position: position_id ?? null,
    };
    axios
      .get(`${urls.mrfTimeLine}`, {
        params: { ...params },
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          setProgressRowData(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setProgressLoading(false);
      });
  };
  const fetchDocDetails = () => {
    setDocLoading(true);
    const params = {
      applicant: applicant,
      position_apply: position_id,
    };
    axios
      .get(`${urls.applicantDocuments}`, {
        params: { ...params },
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          setDocDetails(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setDocLoading(false);
      });
  };

  const handleOpenShortlistFeedbackModal = (applicantId) => {
    setOpenShortlistFeedbackForm(true);
    setApplicantId(applicantId);
  };

  const handleCloseShortlistFeedbackModal = () => {
    setIsClassDemo("");
    setOpenShortlistFeedbackForm(false);
    shortlistFeedbackForm.resetFields();
  };

  const shortlistFeedback = () => {
    setLoading(true);
    const values = shortlistFeedbackForm.getFieldsValue();

    const payload = {
      shortlist_status: values?.shortlist_status,
      feedback: values?.shortlist_status,
      position_apply: position_id,
      applicant: applicantId,
      verified_by: userData?.personal_info?.user_id,
      recruiter_feedback: {
        current_compensation: values?.current_compensation,
        expected_compensation: values?.expected_compensation,
        notice_period: values?.notice_period,
        red_flag: values?.red_flag,
        rating: values?.rating,
        behavioural_evaluation: values?.behavioural_evaluation,
        functional_evaluation: values?.functional_evaluation,
      },
    };

    axios
      .put(`${urls.shortList}/`, payload, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          message.success("Status Updated Successfully");
          handleCloseShortlistFeedbackModal();
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        fetchApplicants();
      });
  };

  const handleJoiningStatus = (val, id, applicant) => {
    setLoading(true);
    const formData = {};
    formData.is_joined = val;
    axios
      .put(`${urls.joiningStatus}/${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          message.success("Status Updated Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        fetchApplicants();
        setLoading(false);
      });
  };

  const handleDocStatus = (id, val) => {
    if (val === "reject") {
      setFeedBackModal(true);
    } else {
      setDocLoading(true);
      const formData = new FormData();
      formData.append("is_approved", true);
      formData.append("applicant", applicant);
      formData.append("position_apply", position_id);
      axios
        .put(`${urls.applicantDocuments}/${id}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.personal_info?.token}`,
          },
        })
        .then((response) => {
          if (response?.status === 200) {
            message.success("Status Updated Successfully");
          }
        })
        .catch((error) => {
          message.error("Something Went Wrong. Please Try Again");
        })
        .finally(() => {
          fetchDocDetails();
        });
    }
  };

  const handleDocReject = () => {
    const values = feedBackModalForm.getFieldsValue();
    setLoading(true);
    const formData = new FormData();
    formData.append("is_rejected", true);
    formData.append("feedback", values?.remarks);
    formData.append("applicant", applicant);
    formData.append("position_apply", position_id);
    axios
      .put(`${urls.applicantDocuments}/${docId}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          message.success("Status Updated Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        closeFeedBackModal();
        fetchDocDetails();
        setLoading(false);
      });
  };

  const handleCandidateStatus = (id, interview_id, val) => {
    setInterviewProcessId(interview_id);
    if (val === "reject") {
      setCandidateFeedBackModal(true);
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("status", "accept");
      formData.append("applicant_id", id);
      formData.append("position_id", position_id);
      formData.append("interview_process", interview_id);
      axios
        .post(`${urls.applicantAcceptReject}/`, formData, {
          headers: {
            Authorization: `Bearer ${userData?.personal_info?.token}`,
          },
        })
        .then((response) => {
          if (response?.status === 200) {
            message.success("Status Updated Successfully");
          }
        })
        .catch((error) => {
          message.error("Something Went Wrong. Please Try Again");
        })
        .finally(() => {
          fetchApplicants();
          setLoading(false);
        });
    }
  };

  const handleCandidateReject = () => {
    const values = candidateFeedBackModalForm.getFieldsValue();
    setLoading(true);
    const formData = new FormData();
    formData.append("status", "reject");
    formData.append("remarks", values?.remarks);
    formData.append("applicant_id", candidateId);
    formData.append("position_id", position_id);
    formData.append("interview_process", interviewProcessId);
    axios
      .post(`${urls.applicantAcceptReject}/`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          message.success("Status Updated Successfully");
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        closeCandidateFeedBackModal();
        fetchApplicants();
        setLoading(false);
      });
  };

  const handleClear = () => {
    formRef.resetFields();
    fetchApplicants();
  };

  const closeFeedBackModal = () => {
    setFeedBackModal(false);
    feedBackModalForm.resetFields();
  };
  const closeCandidateFeedBackModal = () => {
    setCandidateFeedBackModal(false);
    candidateFeedBackModalForm.resetFields();
  };
  const handleResumeUpload = (e) => {
    setSelectedResume(e.target.files[0]);
  };
  const handleSubmitResume = () => {
    const resumeFile = new FormData();
    resumeFile.append("resume", selectedResume);
    resumeFile.append("master", true);
    resumeFile.append("applicant_id", selectedApplicantId);
    setIsUploadingResume(true);
    axios
      .put(`${urls.uploadResumeImageApi}`, resumeFile, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
      })
      .then((res) => {
        fetchApplicants();
        message.success("Resume uploaded successfully!");
      })
      .catch((err) => {
        message.error("Failed to Upload Resume!");
      })
      .finally(() => {
        handleCloseUploadModal();
        setIsUploadingResume(false);
      });
  };
  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setSelectedResume(null);
    setSelectedApplicantId(null);
  };

  const fetchTestRoundDetails = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.scoreUploadApplicentsList}`, {
        params: params,
      })
      .then((response) => {
        setTestRoundDetails(response?.data?.result[0]);
        fetchTestDetails({
          round_id: response?.data?.result[0]?.round_id,
          round_type: params?.round_type,
        });
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchTestDetails = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.ViewTestFileApi}`, {
        params: params,
      })
      .then((response) => {
        setTestData(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const uploadTestFeedback = () => {
    setLoading(true);
    const values = testFeedbackForm.getFieldsValue();
    const params = {
      applicant: testRoundDetails?.id,
      feedback: {
        behavioural_evaluation: values?.behavioural_evaluation,
        functional_evaluation: values?.functional_evaluation,
      },
      passing_status: values?.passing_status,
      round_id: testRoundDetails?.round_id,
      round_type: testType,
    };
    axios
      .put(`${urls.uploadTestScoreApi}`, params)
      .then((response) => {
        message.success(
          response?.data?.response ?? "Feedback Uploaded Successfully"
        );
        handleCloseTestFeedbackModal();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchRoleList = () => {
    setLoading(true);
    axios
      .get(`${urls.interviewPanalRolesApi}`)
      .then((response) => {
        setRoleList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchRecruiterList = () => {
    setLoading(true);
    axios
      .get(`${urls.retrieveRecuriterPanel}`)
      .then((response) => {
        setRecruiterList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchInterviewerList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.interviewersRoleWiseEmployeeAdminApi}`, {
        params: params,
      })
      .then((response) => {
        setInterviewerList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchInterviewRoundDetails = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.f2fApplicentsApi}`, {
        params: params,
      })
      .then((response) => {
        setInterviewRoundDetails(response?.data?.result[0]);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const assignInterview = () => {
    setLoading(true);
    const values = assignInterviewForm.getFieldsValue();
    const params = {
      applicant: interviewRoundDetails?.id,
      duration: values?.duration,
      interviewers_list:
        values?.recruiter && values?.recruiter?.length > 0
          ? [...values?.recruiter, values?.interviewer]
          : [values?.interviewer],
      // format : 2024-02-26,14:52:00
      start_time: `${dayjs(values?.select_date).format("YYYY-MM-DD")},${dayjs(
        values?.select_time
      ).format("HH:mm")}:00`,
      user: values?.interviewer,
      is_class_demo: isClassDemo,
    };
    axios
      .post(`${urls.assignPlannerRound}`, params)
      .then((response) => {
        message.success(
          response?.data?.message ?? "Interview Scheduled Successfully"
        );
        handleCloseAssignInterviewModal();
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
        setIsClassDemo("");
      });
  };
  const handleAssignInterview = () => {
    // check whether interviewer is free or not
    const values = assignInterviewForm.getFieldsValue();
    const params = {
      user: values?.role,
      duration: values?.duration,
      start_time: `${dayjs(values?.select_date).format("YYYY-MM-DD")},${dayjs(
        values?.select_time
      ).format("HH:mm")}:00`,
    };
    setLoading(true);
    axios
      .get(`${urls.checkInterviewFree}`, {
        params: params,
      })
      .then((response) => {
        // if interviewer is free
        if (response?.data?.response === "success") {
          assignInterview();
        } else {
          message.error("Interviewer is not free for selected time");
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenTestFeedbackModal = (data, testType) => {
    fetchTestRoundDetails({
      round_type: testType,
      applicant: data?.applicant?.id,
    });
    setTestType(testType);
    setTestFeedbackModal(true);
  };
  const handleOpenAssignInterviewModal = (data, interviewType) => {
    setIsClassDemo(data?.current_status?.is_class_demo);

    fetchInterviewRoundDetails({
      applicant: data?.applicant?.id,
      is_class_demo: interviewType === "class_demo" ? true : false,
    });
    setInterviewType(interviewType);
    fetchRoleList();
    fetchRecruiterList();
    setAssignInterviewModal(true);
  };
  const handleCloseTestFeedbackModal = () => {
    testFeedbackForm.resetFields();
    setTestType();
    setTestRoundDetails([]);
    setTestData([]);
    setTestFeedbackModal(false);
    fetchApplicants();
  };
  const handleCloseAssignInterviewModal = () => {
    assignInterviewForm.resetFields();
    setInterviewerList([]);
    setInterviewType();
    setInterviewRoundDetails([]);
    setAssignInterviewModal(false);
    fetchApplicants();
  };

  const columns = [
    {
      title: "Candidate Name-[ID]",
      key: "name",
      render: (data) => (
        <Space>
          {data?.applicant?.photo ? (
            <Avatar size={50} src={data?.applicant?.photo} />
          ) : (
            <Avatar
              style={{ textTransform: "capitalize" }}
              size={40}
              src={data?.applicant?.photo}
            >
              {data?.applicant?.name?.substring(0, 1)}
            </Avatar>
          )}
          <span>
            {data?.applicant?.name ? data?.applicant?.name : "No data"} - [{" "}
            {data?.applicant?.user} ]
          </span>
        </Space>
      ),
    },
    {
      title: "Contact No",
      key: "contact_no",
      render: (data) => <span>{data?.applicant?.contact_no}</span>,
    },
    {
      title: "Resume",
      key: "resume",
      render: (data) => {
        const startsWithHTTP = data?.applicant?.resume_link?.startsWith(
          "http:"
        );
        const startsWithHTTPS = data?.applicant?.resume_link?.startsWith(
          "https:"
        );
        return (
          <>
            <Space wrap>
              {data?.applicant?.resume || data?.applicant?.resume_link ? (
                <a
                  href={data?.applicant?.resume ?? data?.applicant?.resume_link}
                  target="_blank"
                >
                  <Tag color="purple">View Resume</Tag>
                </a>
              ) : null}
            </Space>
            <div>
              <Tag
                color="geekblue"
                onClick={() => {
                  setOpenUploadModal(true);
                  setSelectedApplicantId(data?.applicant?.user);
                }}
                style={{ cursor: "pointer" }}
              >
                Upload resume
              </Tag>
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (data) =>
        data?.shortlist_status === "pending" ? (
          <>
            <Col>
              <span style={{ color: "blue", fontStyle: "italic" }}>
                Pending
              </span>
            </Col>
            <Col>
              <Tooltip
                autoAdjustOverflow="false"
                placement="bottom"
                title="SHORTLIST / REJECT"
              >
                <Tag
                  onClick={() =>
                    handleOpenShortlistFeedbackModal(data?.applicant?.id)
                  }
                  color="blue"
                  style={{ cursor: "pointer" }}
                >
                  Shortlist / Reject
                </Tag>
              </Tooltip>
            </Col>
          </>
        ) : (
          <>
            {data?.shortlist_status === "shortlisted" && (
              <Tooltip title={`Remarks: ${data?.feedback}`}>
                <span style={{ color: "green", fontStyle: "italic" }}>
                  Shortlisted <InfoCircleOutlined />
                </span>
              </Tooltip>
            )}
            {data?.shortlist_status === "not_shortlisted" && (
              <Tooltip title={`Remarks: ${data?.feedback}`}>
                <span style={{ color: "red", fontStyle: "italic" }}>
                  Rejected <InfoCircleOutlined />
                </span>
              </Tooltip>
            )}
          </>
        ),
    },
    {
      title: "Progress",
      key: "progress",
      render: (data) => (
        <span>
          <Tag
            onClick={() => {
              setOpen(true);
              fetchTimeLine(data?.applicant?.id);
            }}
            color="geekblue"
            icon={<RiseOutlined />}
            style={{ cursor: "pointer" }}
          >
            Progress
          </Tag>
        </span>
      ),
    },
    {
      title: "Current Status",
      key: "current_status",
      render: (data) => (
        <>
          <div>
            <Space>
              <Tag>
                {data?.current_status
                  ? data?.current_status?.title
                  : "Process not started"}
              </Tag>
            </Space>
          </div>
          <div className="">
            {data?.current_status?.process_complete &&
              data?.applicant_interview_process?.is_selected && (
                <Tag
                  onClick={() => {
                    setOfferLetterDrawer(true);
                    setApplicantId(data?.applicant?.id);
                  }}
                  color={"geekblue"}
                  style={{ padding: "5px 10px", cursor: "pointer" }}
                >
                  Offer Letter
                </Tag>
              )}
          </div>
          <div>
            {data?.current_status?.process_complete &&
              !data?.applicant_interview_process?.is_selected &&
              !data?.applicant_interview_process?.is_rejected && (
                <>
                  <>
                    <span>
                      Candidate cleared all rounds. <br /> Do you want to{" "}
                    </span>

                    <Tooltip
                      autoAdjustOverflow="false"
                      placement="bottom"
                      title="SHORTLIST"
                    >
                      <Tag
                        icon={<CheckCircleOutlined />}
                        color="green"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCandidateStatus(
                            data?.applicant?.id,
                            data?.applicant_interview_process?.id,
                            "approve"
                          );
                        }}
                      >
                        Shortlist
                      </Tag>
                    </Tooltip>
                    <Tooltip
                      autoAdjustOverflow="false"
                      placement="bottom"
                      title="REJECT"
                    >
                      <Tag
                        icon={<CloseCircleOutlined />}
                        color="red"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCandidateStatus(
                            data?.applicant?.id,
                            data?.applicant_interview_process?.id,
                            "reject"
                          );
                          setCandidateId(data?.applicant?.id);
                        }}
                      >
                        Reject
                      </Tag>
                    </Tooltip>
                  </>
                </>
              )}
          </div>
        </>
      ),
    },
    {
      title: "Submit Feedback",
      key: "submit_feedback",
      render: (data) => (
        <>
          {data?.current_status?.is_spoken && (
            <Tag
              color="geekblue"
              icon={<CloudUploadOutlined />}
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenTestFeedbackModal(data, "spoken")}
            >
              Submit Spoken Feedback
            </Tag>
          )}
          {data?.current_status?.is_written && (
            <Tag
              color="geekblue"
              icon={<CloudUploadOutlined />}
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenTestFeedbackModal(data, "written")}
            >
              Submit Written Feedback
            </Tag>
          )}
          {data?.current_status?.is_f2f && (
            <Tag
              color="geekblue"
              icon={<CloudUploadOutlined />}
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenAssignInterviewModal(data, "f2f")}
            >
              Schedule Interview
            </Tag>
          )}
          {data?.current_status?.is_class_demo && (
            <Tag
              color="geekblue"
              icon={<CloudUploadOutlined />}
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenAssignInterviewModal(data, "class_demo")}
            >
              Schedule Class Demo
            </Tag>
          )}
          {!data?.current_status?.is_spoken &&
            !data?.current_status?.is_written &&
            !data?.current_status?.is_f2f &&
            !data?.current_status?.is_class_demo && (
              <Tag icon={<CloudUploadOutlined />}>In Process</Tag>
            )}
        </>
      ),
    },
    {
      title: "NJ Form Completion Status",
      key: "form_status",
      render: (data) => (
        <Tag
          color={data?.applicant?.is_nj_form_completed ? "processing" : "error"}
        >
          {data?.applicant?.is_nj_form_completed
            ? "Completed"
            : "Not Completed"}
        </Tag>
      ),
    },
    {
      title: "View Profile",
      key: "view_profile",
      render: (data) => (
        <span>
          <Tag
            onClick={() => {
              history.push({
                pathname: `/view-applicant-profile`,
                prevState: {
                  ...state,
                },
                state: {
                  applicantId: data?.applicant?.id,
                },
              });
            }}
            color="blue"
            icon={<EyeOutlined />}
            style={{ cursor: "pointer" }}
          >
            View Profile
          </Tag>
        </span>
      ),
    },
    {
      title: "Joining Status",
      key: "joining_status",
      render: (data) =>
        data?.applicant_interview_process?.is_offer_letter_accepted ? (
          <Switch
            checkedChildren="Joined"
            unCheckedChildren="Not Joined"
            checked={data?.is_joined}
            onChange={(e) => {
              handleJoiningStatus(e, data?.id, data?.applicant);
            }}
          />
        ) : (
          <span>Process not completed yet!</span>
        ),
    },
  ];

  const modalColumns = [
    {
      title: "Document",
      key: "documents",
      width: "50%",
      render: (data) => <span>{data?.file_name}</span>,
    },
    {
      title: "View",
      key: "view",
      width: "10%",
      render: (data) => (
        <a href={data?.files} target="_blank">
          <Tag color="blue" icon={<EyeOutlined />}></Tag>
        </a>
      ),
    },
    {
      title: "Status",
      key: "status",
      align: "center",
      width: "40%",
      render: (data) => (
        <>
          {data?.is_approved ? (
            <span style={{ color: "green", fontStyle: "italic" }}>
              Approved
            </span>
          ) : data?.is_rejected ? (
            <Tooltip title={`Rejection Remarks: ${data?.feedback}`}>
              <span style={{ color: "red", fontStyle: "italic" }}>
                Rejected <InfoCircleOutlined />
              </span>
            </Tooltip>
          ) : (
            <Space>
              <Tooltip
                autoAdjustOverflow="false"
                placement="bottom"
                title="APPROVE"
              >
                <Tag
                  icon={<CheckCircleOutlined />}
                  color="green"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDocStatus(data?.id, "approve");
                  }}
                >
                  Approve
                </Tag>
              </Tooltip>
              <Tooltip
                autoAdjustOverflow="false"
                placement="bottom"
                title="REJECT"
              >
                <Tag
                  icon={<CloseCircleOutlined />}
                  color="red"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDocStatus(data?.id, "reject");
                    setDocId(data?.id);
                  }}
                >
                  Reject
                </Tag>
              </Tooltip>
            </Space>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="page_body">
        <Row style={{ justifyContent: "space-between", marginBottom: "10px" }}>
          <Col className="page_title">{rowData?.position} Applicants List</Col>
          <Col>
            <Space>
              {multiSelect && (
                <Button type="default" danger onClick={handleCloseMultiSelect}>
                  Close Multi Select
                </Button>
              )}
              <Button
                size="medium"
                type="primary"
                onClick={handleMultiSelectButton}
              >
                {multiSelect
                  ? "Download"
                  : "Download Multiple Applicants Profile"}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  history.push("/bulk_candidate", {
                    data: {
                      position: position_id,
                      goBackUrl: "mrf_form_applicants",
                    },
                  });
                }}
              >
                <CloudUploadOutlined style={{ marginInlineEnd: "5px" }} />
                Upload Candidates
              </Button>

              <Button
                type="default"
                onClick={() => history.push({ pathname: goBackUrl })}
              >
                Go Back
              </Button>
            </Space>
          </Col>
        </Row>

        <Form
          id="filterForm"
          layout="vertical"
          form={formRef}
          onFinish={() => fetchApplicants()}
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          <Row justify="" align="middle" gutter={24}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              {" "}
              <Form.Item name="created_date_range" label="Created Date Range">
                <RangePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              {" "}
              <Form.Item name="joining_date_range" label="Joining Date Range">
                <RangePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              {" "}
              <Form.Item name="status" label="Applicant Status">
                <Select placeholder="Select Applicant Status">
                  {applicantStatus.map((option, index) => {
                    return (
                      <Option key={option?.value} value={option?.value}>
                        {option?.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              {" "}
              <Form.Item name="is_joined" label="Joining Status">
                <Select placeholder="Select Joining Status">
                  {joiningStatus.map((option, index) => {
                    return (
                      <Option key={option?.value} value={option?.value}>
                        {option?.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              {" "}
              <Form.Item name="search" label="Search Candidate">
                <Input placeholder="Search by ID / Name / Mobile No." />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <Space>
                <Button
                  className="button secondary"
                  onClick={() => handleClear()}
                >
                  Clear
                </Button>{" "}
                <Button
                  form="filterForm"
                  htmlType="submit"
                  type="primary"
                  className="button primary"
                >
                  Filter
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>

        {applicantsData && (
          <Table
            rowSelection={multiSelect && rowSelection}
            columns={columns}
            dataSource={applicantsData?.results}
            scroll={{ x: "max-content" }}
            rowKey={(data) => data?.applicant?.id}
            pagination={{
              showSizeChanger: false,
              total: applicantsData?.count,
              onChange: (page) => {
                setPage(page);
              },
              current: page,
            }}
            loading={loading}
            expandable={{
              expandedRowRender: (record) =>
                record.shortlist_status === "shortlisted" && (
                  <>
                    <Row align="middle" gutter={48}>
                      <Col>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Current Compensation (in Rs) :
                          </span>
                          <span>
                            {record?.recruiter_feedback?.current_compensation}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Expected Compensation (in Rs) :
                          </span>
                          <span>
                            {record?.recruiter_feedback?.expected_compensation}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Notice Period (in days) :
                          </span>
                          <span>
                            {record?.recruiter_feedback?.notice_period}
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Candidate Rating :
                          </span>
                          <span>{record?.recruiter_feedback?.rating}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row align="middle" gutter={48}>
                      <Col>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Feedback :</span>
                          <span>{record?.feedback}</span>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Red Flag :</span>
                          <span>{record?.recruiter_feedback?.red_flag}</span>
                        </div>
                      </Col>
                    </Row>
                  </>
                ),
            }}
          />
        )}
      </div>
      <Drawer
        title="Timeline"
        open={open}
        onClose={handleClose}
        footer={
          <div>
            <Button onClick={handleClose}>Cancel</Button>
          </div>
        }
        width={500}
      >
        <div>
          {progressLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "250px",
              }}
            >
              <Spin />
            </div>
          ) : (
            <>
              {progressRowData && progressRowData?.length === 0 && (
                <Empty
                  style={{ marginTop: "200px" }}
                  description="Process Not Started"
                />
              )}
              {progressRowData && progressRowData?.length !== 0 && (
                <>
                  <Timeline
                    pending={
                      !progressRowData[progressRowData?.length - 1]
                        ?.process_complete
                    }
                    mode="left"
                  >
                    {progressRowData?.map((eachStep) => {
                      return (
                        <Timeline.Item
                          color="green"
                          dot={<CheckCircleOutlined />}
                          label={
                            <>
                              <div
                                style={{
                                  fontSize: "14px",
                                  color: "#a1a1c2",
                                }}
                              >
                                {moment(eachStep?.created_at).format(
                                  "MMMM Do YYYY"
                                )}
                              </div>
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "#a1a1c2",
                                }}
                              >
                                {moment(eachStep?.created_at).format(
                                  "h:mm:ss a"
                                )}
                              </div>
                            </>
                          }
                        >
                          <span style={{ color: "#595c97" }}>
                            {" "}
                            {eachStep?.title}
                          </span>
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                </>
              )}
            </>
          )}
        </div>
      </Drawer>
      {/* Document reject modal */}
      <Modal
        title="Reason for Rejecting"
        open={feedBackModal}
        onCancel={closeFeedBackModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={closeFeedBackModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" form="feedBackModalForm" htmlType="submit">
                Reject
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="feedBackModalForm"
          form={feedBackModalForm}
          layout="vertical"
          onFinish={handleDocReject}
        >
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Remarks",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  maxLength={1500}
                  showCount
                  placeholder="Enter Remarks"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* Rejecting candidate after clearing all rounds modal */}
      <Modal
        title="Reason for Rejecting"
        open={candidateFeedBackModal}
        onCancel={closeCandidateFeedBackModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={closeCandidateFeedBackModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                form="candidateFeedBackModalForm"
                htmlType="submit"
              >
                Reject
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="candidateFeedBackModalForm"
          form={candidateFeedBackModalForm}
          layout="vertical"
          onFinish={handleCandidateReject}
        >
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="remarks"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Remarks",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  maxLength={1500}
                  showCount
                  placeholder="Enter Remarks"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        // title="Documents"
        open={docModal}
        onCancel={handleClosedDocModal}
        centered
        width={700}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={false}
      >
        <>
          <Table
            columns={modalColumns}
            dataSource={docDetails}
            scroll={{ x: "max-content" }}
            rowKey={(data) => data?.id}
            pagination={false}
            loading={docLoading}
          />
        </>
      </Modal>
      <Modal
        title="Upload Resume"
        open={openUploadModal}
        onCancel={() => {
          handleCloseUploadModal();
        }}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={
          <>
            <Button
              loading={isuploadingResume}
              type="primary"
              onClick={handleSubmitResume}
            >
              Upload
            </Button>
          </>
        }
      >
        <Form.Item
          name="resume"
          label="Resume"
          rules={[{ required: true, message: "Please select resume" }]}
        >
          <Input type="file" onChange={(e) => handleResumeUpload(e)} />
        </Form.Item>
      </Modal>
      <Modal
        title="Shortlisting Feedback"
        open={openShortlistFeedbackModal}
        onCancel={handleCloseShortlistFeedbackModal}
        style={{ maxHeight: "80vh", overflow: "auto" }}
        centered
        footer={[
          <Row justify="space-around">
            <Col>
              <Button
                type="default"
                onClick={handleCloseShortlistFeedbackModal}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                form="shortlistFeedbackForm"
                icon={loading ? <SyncOutlined spin /> : <CloudUploadOutlined />}
              >
                Submit
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="shortlistFeedbackForm"
          onFinish={shortlistFeedback}
          form={shortlistFeedbackForm}
          layout="vertical"
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="shortlist_status"
                label="Shortlist Status"
                rules={[
                  {
                    required: true,
                    message: "Please Select Shortlist Status",
                  },
                ]}
              >
                <Select
                  placeholder="Select Shortlist Status"
                  onChange={(e, val) => setShortlistStatus(val?.value)}
                >
                  <Select.Option key={1} value={"shortlisted"}>
                    Shortlist
                  </Select.Option>
                  <Select.Option key={2} value={"not_shortlisted"}>
                    Reject
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {shortlistStatus === "shortlisted" ? (
              <>
                <Col span={24}>
                  <Form.Item
                    name="current_compensation"
                    label="Current Compensation"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Current Compensation",
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore="Rs"
                      min={0}
                      placeholder="Enter Current Compensation"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="expected_compensation"
                    label="Expected Compensation"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Expected Compensation",
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore="Rs"
                      min={0}
                      placeholder="Enter Expected Compensation"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="notice_period"
                    label="Notice Period (in Days)"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Notice Period",
                      },
                    ]}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Enter Notice Period (in Days)"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="functional_evaluation"
                    label="Functional Evaluation"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Functional Evaluation",
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      maxLength={300}
                      count={{
                        max: 300,
                        show: true,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="behavioural_evaluation"
                    label="Behavioural Evaluation"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Behavioural Evaluation",
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      maxLength={300}
                      count={{
                        max: 300,
                        show: true,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="red_flag" label="Red Flag (if any)">
                    <TextArea
                      rows={3}
                      maxLength={300}
                      count={{
                        max: 300,
                        show: true,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Candidate Rating"
                    name="rating"
                    layout="horizontal"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Rating",
                      },
                    ]}
                  >
                    <Rate tooltips={desc} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Form.Item
                    name="functional_evaluation"
                    label="Functional Evaluation"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Functional Evaluation",
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      maxLength={300}
                      count={{
                        max: 300,
                        show: true,
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="behavioural_evaluation"
                    label="Behavioural Evaluation"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Behavioural Evaluation",
                      },
                    ]}
                  >
                    <TextArea
                      rows={3}
                      maxLength={300}
                      count={{
                        max: 300,
                        show: true,
                      }}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Modal>

      {testFeedBackModal && (
        <Modal
          title={<span className="page_title">Submit {testType} feedback</span>}
          open={testFeedBackModal}
          onCancel={handleCloseTestFeedbackModal}
          footer={[
            <Row justify="space-around">
              <Col>
                <Button type="default" onClick={handleCloseTestFeedbackModal}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  form="testFeedbackForm"
                  icon={
                    loading ? <SyncOutlined spin /> : <CloudUploadOutlined />
                  }
                >
                  Submit
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form
            id="testFeedbackForm"
            onFinish={uploadTestFeedback}
            form={testFeedbackForm}
            layout="vertical"
          >
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "40px",
                }}
              >
                <Spin size="large" />
              </div>
            )}
            <Row gutter={24}>
              {!loading && (
                <>
                  {testType === "spoken" && (
                    <>
                      <Col
                        span={24}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        Topic Name :{" "}
                        <b>{testData?.filedata?.response[0]?.question}</b>
                      </Col>
                      <Col span={24}>
                        {testData && testData?.filedata?.spoken_audio ? (
                          <audio controls preload="auto">
                            <source
                              src={testData && testData?.filedata?.spoken_audio}
                              type="audio/mpeg"
                            />
                          </audio>
                        ) : (
                          <b>No Audio is found</b>
                        )}
                      </Col>
                    </>
                  )}
                  {testType === "written" && (
                    <>
                      <Col
                        span={24}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        Question :{" "}
                        <b>{testData?.filedata?.response[0]?.question}</b>
                      </Col>
                      <Col span={24}>
                        Answer :{" "}
                        {testData && testData?.filedata?.written_test_text ? (
                          <span>{testData?.filedata?.written_test_text}</span>
                        ) : (
                          <b>No Text is found</b>
                        )}
                      </Col>
                    </>
                  )}
                </>
              )}
              <br />
              <br />
              <Col span={24}>
                <Form.Item
                  name="passing_status"
                  label="Passing Status"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Passing Status",
                    },
                  ]}
                >
                  <Select placeholder="Passing Status">
                    <Select.Option value={true}>Shortlist</Select.Option>
                    <Select.Option value={false}>Reject</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="functional_evaluation"
                  label="Functional Evaluation"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Functional Evaluation",
                    },
                  ]}
                >
                  <TextArea
                    rows={3}
                    maxLength={300}
                    count={{
                      max: 300,
                      show: true,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="behavioural_evaluation"
                  label="Behavioural Evaluation"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Behavioural Evaluation",
                    },
                  ]}
                >
                  <TextArea
                    rows={3}
                    maxLength={300}
                    count={{
                      max: 300,
                      show: true,
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {assignInterviewModal && (
        <Modal
          title={<span className="page_title">Schedule Interview</span>}
          open={assignInterviewModal}
          onCancel={handleCloseAssignInterviewModal}
          centered
          width={500}
          style={{ maxHeight: "90vh", overflow: "auto" }}
          footer={[
            <Row justify="space-around">
              <Col>
                <Button
                  type="default"
                  onClick={handleCloseAssignInterviewModal}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  form="assignInterviewForm"
                  icon={<CloudUploadOutlined />}
                  loading={loading}
                >
                  Submit
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <Form
            id="assignInterviewForm"
            onFinish={handleAssignInterview}
            form={assignInterviewForm}
            layout="vertical"
          >
            <Row align="middle" gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="role"
                  label="Role"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Role",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Role"
                    onChange={() => {
                      const role = assignInterviewForm.getFieldsValue()?.role;
                      assignInterviewForm.setFieldsValue({
                        interviewer: null,
                      });
                      if (role) {
                        fetchInterviewerList({
                          role_id: role,
                        });
                      }
                    }}
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {roleList &&
                      roleList.length !== 0 &&
                      roleList.map((option, index) => {
                        return (
                          <Option key={option?.value} value={option?.id}>
                            {option?.role_name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="interviewer"
                  label="Interviewer"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Interviewer",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Interviewer"
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {interviewerList &&
                      interviewerList.length !== 0 &&
                      interviewerList.map((option, index) => {
                        return (
                          <Option key={index} value={option?.id}>
                            {option.first_name
                              ? `${option.first_name} [ ${option.erp ?? "-"} ]`
                              : ""}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="recruiter" label="Select Recruiters">
                  <Select
                    placeholder="Select Recruiter"
                    mode="multiple"
                    allowClear
                    showSearch
                    filterOption={(input, options) => {
                      return (options?.children ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {recruiterList &&
                      recruiterList.length !== 0 &&
                      recruiterList.map((option, index) => {
                        return (
                          <Option key={option?.id} value={option?.id}>
                            {option.first_name
                              ? `${option.first_name} [${option?.username}]`
                              : ""}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="select_date"
                  label="Select Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Date",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="select_time"
                  label="Select Time"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Time",
                    },
                  ]}
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    format="HH:mm"
                    use12Hours
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="duration"
                  label="Duration in minutes"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Duration (in min)",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter Duration (in min)"
                    min={1}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
      {offerLetterDrawer && (
        <OfferLetterDrawer
          open={offerLetterDrawer}
          onClose={() => {
            setOfferLetterDrawer(false);
            setApplicantId(null);
          }}
          applicantId={applicantId}
          designation={rowData?.department_fk}
          position={rowData?.position_type_fk}
        />
      )}
    </>
  );
};

export default MrfFormApplicants;
