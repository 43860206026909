
import React, { useState, useEffect } from 'react';
import styles from './userTable.style';
import PropTypes from 'prop-types';
import urls from '../../../url';
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader';
import { useAlert } from '../../../hoc/alert/alert';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import {
    Typography,
    Divider,
    Grid,
    TextField,
    MenuItem,
    Paper,
    Button,
    withStyles,
    Table,
    TableCell,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Checkbox,
    Modal,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';

const UserTable = ({ classes }) => {
    const [auth] = useState(JSON.parse(localStorage.getItem('user')));

    const [open, setOpen] = React.useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(null);
    const [Id, setId] = React.useState(null);
    const [index, setIndex] = React.useState(null);

    const [name, setName] = useState('')
    const [erp, setErp] = useState('')
    const [branch, setBranch] = useState('')
    const [userCitys, setUserCitys] = useState([]);


    const alert = useAlert();

    const {
        data: userTable,
        isLoading: userTableLoading,
        doFetch: fetchUserTable
    } = useFetch();
    const {
        data: updataData,
        isLoading: UpdateDataLoading,
        doFetch: fectchUpdataData
    } = useFetch(null)

    const {
        data: cityList,
        doFetch: fetchCityList,
    } = useFetch(null);

    useEffect(() => {
        if (auth) {
            fetchUserTable({
                url: urls.userTableList,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.personal_info.token}`,
                }
            });
            fetchCityList({
                url: urls.cityList,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.personal_info.token}`,
                },
            });
        }
        
    }, [fetchUserTable, fetchCityList, auth]);

    let loader = null;
    if (userTableLoading || UpdateDataLoading) {
        loader = <Loader open />;
    }



    useEffect(() => {
        if (page || rowsPerPage) {
            fetchUserTable({
                url: `${urls.userTableList}?page=${page + 1}&page_size=${rowsPerPage || 10}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.personal_info.token}`,
                },
            });
        }
    }, [page, fetchUserTable, rowsPerPage, auth]);

    useEffect(() => {
        if (updataData) {
            setOpen(false);
        }

        fetchUserTable({
                    url: urls.userTableList,
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.personal_info.token}`,
                    }
                })
    
    }, [updataData,fetchUserTable])


    const updateRole = () => {
        if (!userCitys.length || !name || !erp || !Id) {
            alert.warning('fill all the Fields')
            return
        }

        const updatedata = {
            'name': name,
            'erp': erp,
            'branch': branch,
            'city': userCitys,
            'id': Id,

        }
        fectchUpdataData({
            url: `${urls.updateHrHeadDetail}UpdateHrHeadDetail/`,
            body: updatedata,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.personal_info.token}`,
            },
        });
    }

    function handleChangePage(event, newPage) {
        setPage(newPage)
        !rowsPerPage && setRowsPerPage(10)
    }
    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
        setPage(0);
    }
    function firstPageChange() {
        setPage(0)
    }
    function lastPageChange(lastPage) {
        setPage(lastPage)
        !rowsPerPage && setRowsPerPage(10)
    }

    const handleClose = () => {
        setOpen(false);
        setUserCitys([]);
        setId(null);
    };

    const editHandler = (id, i) => {
        setIndex(i);
        setId(id);
        setName(userTable && userTable[i] && userTable[i].name || '')
        setErp(userTable && userTable[i] && userTable[i].erp || '')
        setBranch(userTable && userTable[i] && userTable[i].branch || '')
        setUserCitys(userTable && userTable[i] && userTable[i].city.map((item)=>{return item.id}) || '')

        setOpen(true)
    }


    function profileTable() {
        let applicantTable = null;
        if (userTable && userTable.length === 0) {
            applicantTable = (
                <React.Fragment>
                    <Typography style={{ textAlign: 'center', color: 'blue', marginTop: '100px' }} variant='h4'>Records Not Found</Typography>
                </React.Fragment>
            )
        }
        if (userTable && userTable.length) {
            applicantTable = (
                <React.Fragment>
                    <Paper className={classes.paper} style={{ marginTop: '10px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">S.No</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Erp Number</TableCell>
                                    <TableCell align="left">City</TableCell>
                                    <TableCell align="left">branch</TableCell>
                                    <TableCell align="left">Update</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userTable.map((details, i) => (
                                    <TableRow key={details.id}>
                                        <TableCell align="left">{i + 1}</TableCell>
                                        <TableCell align="left">{details.name}</TableCell>
                                        <TableCell align="left">{details.erp}</TableCell>
                                        <TableCell align="left">
                                            {details.city.map(data => (
                                                <MenuItem
                                                    value={data.id}
                                                    key={data.id}
                                                    name={data.city_name}
                                                >
                                                    {data.city_name ? data.city_name : ''}
                                                </MenuItem>
                                            ))
                                            }
                                        </TableCell>
                                        <TableCell align="left">{details.branch && details.branch.branch_name || ''}</TableCell>

                                        <TableCell align="left">
                                            <Button
                                                className={classes.addButton}
                                                size="small"
                                                variant="contained"
                                                margin="dense"
                                                color="primary"
                                                onClick={() => editHandler(details.id, i)}
                                            >
                                                Edit
                                         </Button>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={5}
                                    labelDisplayedRows={() => `Page ${page + 1} of ${+userTable.total_pages}`}
                                    rowsPerPageOptions={[10, 20, 30]}
                                    rowsPerPage={rowsPerPage || 10}
                                    count={+userTable.count}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'Rows per page' },
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    TablePagi />
                                <TableCell style={{ marginTop: '13px' }}>
                                    <IconButton
                                        onClick={firstPageChange}
                                        disabled={page === 0 || page === 1}
                                    >
                                        <FirstPageIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => lastPageChange(userTable.total_pages - 1)}
                                        disabled={page === (+userTable.total_pages - 1)}
                                    >
                                        <LastPageIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Paper>
                </React.Fragment>
            )
        }
        return applicantTable;
    }

    let modal = null;
    if (userTable) {
        modal = (
            <React.Fragment>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <Paper className={classes.paperr}>
                            <Typography variant="h4">User Table Update</Typography>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <TextField
                                        label="Name"
                                        className={classes.textField}
                                        // disabled={userName ? false : true}
                                        margin="dense"
                                        required
                                        onChange={e => setName(e.target.value)}
                                        value={name || ''}
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item md={4} xs={12}>
                                    <TextField
                                        label="Erp Number "
                                        type='number'
                                        className={classes.textField}
                                        // disabled={erp ? false : true}
                                        margin="dense"
                                        required
                                        onChange={(e) => e.target.value.length < 12 ? setErp(e.target.value) : ''}
                                        value={erp || ''}
                                        variant="outlined"
                                    >
                                    </TextField>
                                </Grid>
                                <Grid item md={4} xs={12} >
                                    <TextField
                                        label="City "
                                        className={classes.textField}
                                        // disabled={userCitys ? false : true}
                                        margin="dense"
                                        required
                                        select
                                        onChange={e => setUserCitys(e.target.value)}
                                        SelectProps={{ multiple: true }}
                                        value={userCitys || ''}
                                        variant="outlined"
                                    >
                                        {cityList && cityList.map(data => (
                                            <MenuItem
                                                value={data.id}
                                                key={data.id}
                                                name={data.city_name}
                                            >
                                                {data.city_name ? data.city_name : ''}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Button variant="contained" color="primary" margin="dense" className={classes.button} style={{ marginTop: '10px' }} onClick={updateRole}>Update</Button>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                        </Paper>
                    </Fade>
                </Modal>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Typography variant="h4">User Table</Typography>
            <Divider className={classes.divider} />
            {profileTable()}
            {loader}
            {modal}
        </React.Fragment>
    );
}
UserTable.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(UserTable);