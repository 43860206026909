import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  TableFooter,
  TablePagination,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
  InputBase,
  TableContainer,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Chip,
  Box,
  FormControlLabel,
  Modal,
} from "@material-ui/core";
import urls from "../../../url";
import SearchIcon from "@material-ui/icons/Search";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./offerLetter.Style";
import Icon from "@material-ui/core/Icon";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useAlert } from "../../../hoc/alert/alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import MomentUtils from "@date-io/moment";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import "./style.css";
import clsx from "clsx";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 750,
      minWidth: "fit-content",
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// const styles = {
//   container: {
//       display: 'flex',
//       flexWrap: 'wrap',
//   },
//   textField: {
//       width: 300,
//       margin: 100,
//   },
//   //style for font size
//   resize:{
//     fontSize:50
//   },
//   }

const OfferLetter = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [value, setValue] = React.useState(0);
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [Id, setId] = React.useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pagination, setPagination] = useState(false);
  const [pageChange, setPageChange] = useState(false);
  const [profileId, setProfileId] = useState("");
  const [applicentName, setApplicentName] = useState("");
  const alert = useAlert();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [bcc, setBcc] = useState([]);
  const [cC, setCC] = useState([
    "hr_sampark@orchids.edu.in",
    "offers@orchids.edu.in",
    "rahulsingh@orchids.edu.in",
  ]);
  const [escaltionPointEmail, setEscaltionPointEmail] = useState("");
  const [hrMobile, setHrMobile] = useState("");
  const [hrEmail, setHrEmail] = useState("");
  const [submitDocBeforeDate, setSubmitDocBeforeDate] = useState(new Date());
  const [designationId, setDesignationId] = useState("");
  const [schoolName, setSchoolName] = useState(
    "ORCHIDS - The International School"
  );
  const [cTC, setCTC] = useState([]);
  const [ctcPerAnnum, setCtcPerAnnum] = useState([]);
  const [doJoining, setDoJoining] = useState(new Date());

  const [probationPeriod, setProbationPeriod] = useState("1 Year");
  const [noticePeriod, setNoticePeriod] = useState("3 Months");
  const [reportingTime, setReportingTime] = useState(null);
  const [hrName, setHrName] = useState("");
  const [cbDate, setCBDate] = useState(new Date());
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [flag, setFlag] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedCtcWords, setSelectedCtcWords] = useState("");
  const [selectedCtcDigits, setSelectedCtcDigits] = useState("");
  const [outers, setOuters] = useState(false);
  const [send, setSend] = useState(false);
  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [academics, setAcademics] = useState("");
  const [roLe, setRole] = useState("");
  const [location, setLocation] = useState("");
  const [branches, setBranches] = useState("");
  const [gender, setGender] = useState("");
  const [state, setState] = useState("");
  const [options, setOption] = useState({});
  const [principalEmail, setPrincipalEmail] = useState("");
  const uSer = JSON.parse(localStorage.getItem("user"));
  const [submitDate, setSubmitDate] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [docsDate, setDocsDate] = useState("");
  const [reportT, setReportT] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [isChecked, setIsChecked] = useState({});
  const [postForm, setPostForm] = useState(false);
  const [designationName, setDesignationName] = useState([]);
  const [branchListName, setBranchListName] = useState([]);
  const [searchData, setSearchData] = useState(null);
  const [emailFrom, setEmailFrom] = useState([]);
  const [emailTo, setEmailTo] = useState([]);
  const [emailCc, setEmailCc] = useState([]);
  const [emailSubject, setEmailSubject] = useState([]);
  const [emailPreview, setEmailPreview] = useState([]);

  const filterEmailId = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const handleClickOpen = (data) => {
    setPostForm(true);
    // setOpen(false);

    setCandidateDetails(data);

    setUserName(data?.applicant?.user?.first_name);
    setUserEmail(data?.applicant?.user?.email);
  };

  function handleClickResendOpen(data) {
    axios
      .get(`${urls.offerLetterList}?search=${data?.applicant?.user?.email}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSend(true);
          setCandidateDetails(data);
          setUserName(data?.applicant?.user?.first_name);
          setUserEmail(data?.applicant?.user?.email);
          let branch = "";
          let branch_name = "";
          if (response?.data?.results[0]?.school_name.includes("ORCHIDS")) {
            branch = response?.data?.results[0]?.school_name?.split("-");
            branch_name = branch[2]?.split(",");
          } else {
            branch = response?.data?.results[0]?.school_name?.split("-");
            branch_name = branch[1]?.split(",");
          }

          const monthNames = [
            {
              id: 1,
              name: "January",
              value: "01",
            },
            {
              id: 2,
              name: "February",
              value: "02",
            },
            {
              id: 3,
              name: "March",
              value: "03",
            },
            {
              id: 4,
              name: "April",
              value: "04",
            },
            {
              id: 5,
              name: "May",
              value: "05",
            },
            {
              id: 6,
              name: "June",
              value: "06",
            },
            {
              id: 7,
              name: "July",
              value: "07",
            },
            {
              id: 8,
              name: "August",
              value: "08",
            },
            {
              id: 9,
              name: "September",
              value: "09",
            },
            {
              id: 10,
              name: "October",
              value: "10",
            },
            {
              id: 11,
              name: "November",
              value: "11",
            },
            {
              id: 12,
              name: "December",
              value: "12",
            },
          ];

          let confirmBeforDate = response?.data?.results[0]?.confirm_before_date.split(
            " "
          );
          let subconfirmDate = "";
          const dataA = monthNames.map((item) => {
            if (item.name === confirmBeforDate[1]) {
              subconfirmDate =
                confirmBeforDate[2] +
                "-" +
                item.value +
                "-" +
                confirmBeforDate[0].slice(0, 2);
            }
          });
          const ConfirmBeforedate = subconfirmDate;

          let submitDocsBeforeDate = response?.data?.results[0]?.submit_documents_before_date.split(
            " "
          );
          let subsubmitDocsBeforeDate = "";
          const dataB = monthNames.map((item) => {
            if (item.name === submitDocsBeforeDate[1]) {
              subsubmitDocsBeforeDate =
                submitDocsBeforeDate[2] +
                "-" +
                item.value +
                "-" +
                submitDocsBeforeDate[0].slice(0, 2);
            }
          });
          const submitDocumentsBeforeDate = subsubmitDocsBeforeDate;
          let joiniDate = response?.data?.results[0]?.date_of_joining.split(
            " "
          );
          let subjoiniDate = "";
          const dataC = monthNames.map((item) => {
            if (item.name === joiniDate[1]) {
              subjoiniDate =
                joiniDate[2] +
                "-" +
                item.value +
                "-" +
                joiniDate[0].slice(0, 2);
            }
          });
          const joiningDate = subjoiniDate;
          let reportingT = "";
          if (response?.data?.results[0]?.reporting_time) {
            let reportingTimes = response?.data?.results[0]?.reporting_time.split(
              ":"
            );
            let subReportingTimes = reportingTimes[1].split(" ");
            let rTime = "";
            if (subReportingTimes[1].includes("P.M")) {
              rTime = parseInt(reportingTimes[0]) + 12;
              reportingT = rTime + ":" + subReportingTimes[0];
              // setReportT(reportingT);
              setReportingTime(reportingT);
            } else {
              reportingT = reportingTimes[0] + ":" + subReportingTimes[0];
              // setReportT(reportingT);
              setReportingTime(reportingT);
            }
          }
          // setSelectedEditItem(data);
          setSchoolName(response?.data?.results[0]?.school_name);
          setDoJoining(joiningDate);
          setCTC(response?.data?.results[0]?.fixed_gross_per_month);
          setCtcPerAnnum(response?.data?.results[0]?.ctc_per_annum);
          setProbationPeriod(response?.data?.results[0]?.probation_period);
          setNoticePeriod(response?.data?.results[0]?.notice_period);
          setCBDate(ConfirmBeforedate);
          setSubmitDocBeforeDate(submitDocumentsBeforeDate);
          // setHrName(data?.hr_name);
          // setHrEmail(data?.hr_email);
          // setHrMobile(data?.hr_mobile);
          setEscaltionPointEmail(
            response?.data?.results[0]?.escalation_point_email
          );
          setPrincipalEmail("");
          setCC(["hr_sampark@orchids.edu.in", "offers@orchids.edu.in"]);
          setBcc([]);
          setCandidateName(response?.data?.results[0]?.candidate_name);
          setCandidateEmail(response?.data?.results[0]?.candidate_email);
          setDesignation(response?.data?.results[0]?.designation);
          setDesignationId(response?.data?.results[0]?.designationId);
          setRole("");
          if (branch_name) {
            let final_branch = branch_name[0].substring(1);
            setBranches(final_branch);
          }
          setLocation("");
          setState(response?.data?.results[0]?.state);
          // setSubmitDate(submitDocumentsBeforeDate);
          // setDocsDate(ConfirmBeforedate);
          // setJoiningDate(joiningDate);
          // setReportT(data?.reporting_time);
          // setOpen(true);
          // setIndex(i);
        }
      });
  }

  const handleClose = () => {
    setSchoolName("");
    setDoJoining("");
    setCTC("");
    setCtcPerAnnum("");
    setProbationPeriod("");
    setNoticePeriod("");
    setReportingTime("");
    setCBDate(null);
    setSubmitDocBeforeDate(null);
    setHrName("");
    setHrEmail("");
    setHrMobile("");
    setEscaltionPointEmail("");
    setPrincipalEmail("");
    setCC(["hr_sampark@orchids.edu.in", "offers@orchids.edu.in"]);
    setBcc([]);
    setSubmitDate("");
    setDocsDate("");
    setJoiningDate("");
    setReportT("");
    setState("");
    setBranches("");
    setOpen(false);
  };

  const handleCloseOuters = () => {
    setSchoolName("");
    setDoJoining("");
    setCTC("");
    setCtcPerAnnum("");
    setProbationPeriod("");
    setNoticePeriod("");
    setDoJoining(new Date());
    setReportingTime("");
    setCBDate(new Date());
    setSubmitDocBeforeDate(new Date());
    setHrName("");
    setHrEmail("");
    setHrMobile("");
    setEscaltionPointEmail("");
    setCC(["hr_sampark@orchids.edu.in", "offers@orchids.edu.in"]);
    setBcc([]);
    setCandidateName("");
    setPrincipalEmail("");
    setCandidateEmail("");
    setDesignation("");
    setRole("");
    setSubmitDate("");
    setDocsDate("");
    setJoiningDate("");
    setReportT("");
    setSend(false);
    setPostForm(false);
    setOuters(false);
  };
  const handleClosePreview = () => {
    // setSchoolName('');
    // setDoJoining('');
    // setCTC('');
    // setProbationPeriod('');
    // setNoticePeriod('');
    // setReportingTime('');
    // setCBDate(null);
    // setSubmitDocBeforeDate(null);
    // setHrName('');
    // setHrEmail('');
    // setHrMobile('');
    // setEscaltionPointEmail('');
    setCC([]);
    // setBcc('');
    setFlag(false);
  };

  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id);
    if (profileDetail[newValue].applicant_role_name === "Teacher") {
      setProfileId(2);
    } else if (profileDetail[newValue].applicant_role_name === "Coordinator") {
      setProfileId(3);
    } else if (profileDetail[newValue].applicant_role_name === "Principal") {
      setProfileId(4);
    }
  }

  const {
    data: subjectDetail,
    isLoading: subjectDetailLoading,
    doFetch: fetchSubject,
  } = useFetch([], { suppressAlert: true });

  const {
    data: branch,
    isLoading: branchLoading,
    doFetch: fetchBranch,
  } = useFetch([], { suppressAlert: true });

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch([], { suppressAlert: true });

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch([], { suppressAlert: true });

  let loader = null;
  if (
    applicantsListLoading ||
    profileDetailLoading ||
    branchLoading ||
    subjectDetailLoading
  ) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.FaceToFaceRolesApi,
        method: "GET",
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          "Content-Type": "application/json",
        },
      });
      fetchSubject({
        url: urls.subjectApiView,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranch({
        url: urls.branch,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchProfile, name, fetchSubject, fetchBranch]);

  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setId(profileDetail[0].id);
      if (profileDetail[0].applicant_role_name === "Teacher") {
        setProfileId(2);
      } else if (profileDetail[0].applicant_role_name === "Coordinator") {
        setProfileId(3);
      } else if (profileDetail[0].applicant_role_name === "Principal") {
        setProfileId(4);
      }
    }
  }, [profileDetail]);

  useEffect(() => {
    if (Id) {
      setPageChange(false);
      setStartDate("");
      setEndDate("");
      setFilteredBranch("");
      setFilteredSub("");
      fetchApplicant({
        url: `${urls.OfferLetterOutBoundRetriveApi}?role_id=${Id}&page=${page +
          1}&page_size=${rowsPerPage || 50}&search=${searchData || ""}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [Id, fetchApplicant, name, searchData]);

  useEffect(() => {
    if (applicantsList) {
      setPageChange(true);
    }
  }, [applicantsList]);

  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };
  const handleGetData = () => {
    if (startDate === "") {
      alert.warning("Please Select Start Date..!");
      return;
    }
    if (endDate === "") {
      alert.warning("Please Select End Date..!");
      return;
    }
    setPage(0);
    fetchApplicant({
      url: `${
        urls.OfferLetterOutBoundRetriveApi
      }?role_id=${Id}&page_size=${rowsPerPage ||
        50}&page=1&start_date=${startDate}&end_date=${endDate}${
        filteredBranch && filteredBranch.length !== 0
          ? `&branch=${filteredBranch}`
          : ""
      }${
        filteredSub && filteredSub.length !== 0 ? `&subject=${filteredSub}` : ""
      }`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  };

  function functionSearch(data) {
    setSearchData(data);
  }

  const handleBranch = (value) => () => {
    const currentIndex = filteredBranch.indexOf(value);
    const newChecked = [...filteredBranch];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredBranch(newChecked);
  };

  const handleSub = (value) => () => {
    const currentIndex = filteredSub.indexOf(value);
    const newChecked = [...filteredSub];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredSub(newChecked);
  };
  let checkedRow = [];

  function applyFilter() {
    if (filteredBranch === "" && filteredSub === "") {
      alert.warning("select fields either branch or subject");
      return;
    }
    if (
      (filteredBranch && filteredBranch.length === 0 && filteredSub === "") ||
      (filteredBranch === "" && (filteredSub && filteredSub.length === 0))
    ) {
      alert.warning("select fields either branch or subject");
      return;
    }
    if (
      filteredBranch &&
      filteredBranch.length === 0 &&
      (filteredSub && filteredSub.length === 0)
    ) {
      alert.warning("select fields either branch or subject");
      return;
    }
    checkedRow = Object.keys(isChecked).filter((ele) => isChecked[ele]);

    setPage(0);
    fetchApplicant({
      // url: `${urls.OfferLetterOutBoundRetriveApi}?branch=${filteredBranch}&subject=${filteredSub}&role_id=${Id}&page=1&page_size=${rowsPerPage || 10}${startDate ? `&start_date=${startDate}`:''}${endDate ? `&end_date=${endDate}`:''}`,
      // url: `${urls.OfferLetterOutBoundRetriveApi}${filteredBranch.length > 0 && filteredSub.length === 0 ? `?branch=${filteredBranch}` : filteredBranch.length > 0 && filteredSub.length > 0 ? `?branch=${filteredBranch}` : ''}${filteredSub.length > 0 && filteredBranch.length === 0 ? `?subject=${filteredSub}` : filteredSub.length > 0 && filteredBranch.length > 0 ? `&subject=${filteredSub}` : ''}&role_id=${Id}&page=1&page_size=${rowsPerPage || 10}${startDate ? `&start_date=${startDate}` : ''}${endDate ? `&end_date=${endDate}` : ''}`,
      url: `${
        urls.OfferLetterOutBoundRetriveApi
      }?branch=${checkedRow}&subject=${filteredSub}&role_id=${Id}&page=1&page_size=${rowsPerPage ||
        10}${startDate ? `&start_date=${startDate}` : ""}${
        endDate ? `&end_date=${endDate}` : ""
      }`,

      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (pagination) {
      fetchApplicant({
        url: `${urls.OfferLetterOutBoundRetriveApi}?&role_id=${Id}&page=${page +
          1}&page_size=${rowsPerPage || 50}${
          filteredBranch && filteredBranch.length !== 0
            ? `&branch=${filteredBranch}`
            : ""
        }${
          filteredSub && filteredSub.length !== 0
            ? `&subject=${filteredSub}`
            : ""
        }${startDate ? `&start_date=${startDate}` : ""}${
          endDate ? `&end_date=${endDate}` : ""
        }`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
    setPagination(false);
  }, [page, fetchApplicant, rowsPerPage, name, Id]);

  function handleChangePage(event, newPage) {
    setPagination(true);
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }

  function handleChangeRowsPerPage(event) {
    setPagination(true);
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPagination(true);
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPagination(true);
    setPage(lastPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }

  function handleAdditional(e) {
    const {
      target: { value },
    } = e;
    setBcc(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  }

  function handleSendEmail() {
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (candidateEmail) {
      if (!filter.test(candidateEmail)) {
        alert.warning("Please enter valid Email...");
        return;
      }
    }
    if (userEmail) {
      if (!filter.test(userEmail)) {
        alert.warning("Please enter valid Email...");
        return;
      }
    }

    var total = [];
    if (bcc.length > 0) {
      total = cC.push.apply(cC, bcc);
    }
    if (academics == "True" ? principalEmail : "") {
      total = cC.push(principalEmail);
    }
    if (escaltionPointEmail) {
      total = cC.push(escaltionPointEmail);
    }
    if (hrEmail) {
      total = cC.push(hrEmail);
    }
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let confirmBeforeDate = "";

    if (cbDate) {
      let cbNewDate = new Date(cbDate);
      let cbDay = cbNewDate.getDate();
      let cbMonth = cbNewDate.getMonth();
      let cbYear = cbNewDate.getFullYear();
      if (cbDay === 1) {
        confirmBeforeDate =
          "0" + cbDay + "st " + monthNames[cbMonth] + " " + cbYear;
      } else if (cbDay === 2) {
        confirmBeforeDate =
          "0" + cbDay + "nd " + monthNames[cbMonth] + " " + cbYear;
      } else if (cbDay === 3) {
        confirmBeforeDate =
          "0" + cbDay + "rd " + monthNames[cbMonth] + " " + cbYear;
      } else if (cbDay > 9) {
        confirmBeforeDate = cbDay + "th " + monthNames[cbMonth] + " " + cbYear;
      } else {
        confirmBeforeDate =
          "0" + cbDay + "th " + monthNames[cbMonth] + " " + cbYear;
      }
      setDocsDate(confirmBeforeDate);
    }
    let dateOfJoining = "";
    if (doJoining) {
      let doJoiningNewDate = new Date(doJoining);
      let doJoiningDay = doJoiningNewDate.getDate();
      let doJoiningMonth = doJoiningNewDate.getMonth();
      let doJoiningYear = doJoiningNewDate.getFullYear();
      if (doJoiningDay === 1) {
        dateOfJoining =
          "0" +
          doJoiningDay +
          "st " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      } else if (doJoiningDay === 2) {
        dateOfJoining =
          "0" +
          doJoiningDay +
          "nd " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      } else if (doJoiningDay === 3) {
        dateOfJoining =
          "0" +
          doJoiningDay +
          "rd " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      } else if (doJoiningDay > 9) {
        dateOfJoining =
          doJoiningDay +
          "th " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      } else {
        dateOfJoining =
          "0" +
          doJoiningDay +
          "th " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      }
      setJoiningDate(dateOfJoining);
    }
    let submitDocBeforeDates = "";
    if (submitDocBeforeDate) {
      let submitDocBeforeDateNewDate = new Date(submitDocBeforeDate);
      let submitDocBeforeDateDay = submitDocBeforeDateNewDate.getDate();
      let submitDocBeforeDateMonth = submitDocBeforeDateNewDate.getMonth();
      let submitDocBeforeDateYear = submitDocBeforeDateNewDate.getFullYear();
      if (submitDocBeforeDateDay === 1) {
        submitDocBeforeDates =
          "0" +
          submitDocBeforeDateDay +
          "st " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      } else if (submitDocBeforeDateDay === 2) {
        submitDocBeforeDates =
          "0" +
          submitDocBeforeDateDay +
          "nd " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      } else if (submitDocBeforeDateDay === 3) {
        submitDocBeforeDates =
          "0" +
          submitDocBeforeDateDay +
          "rd " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      } else if (submitDocBeforeDateDay > 9) {
        submitDocBeforeDates =
          submitDocBeforeDateDay +
          "th " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      } else {
        submitDocBeforeDates =
          "0" +
          submitDocBeforeDateDay +
          "th " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      }
      setSubmitDate(submitDocBeforeDates);
    }
    let reportingT = "";
    if (reportingTime) {
      let reportingTimes = reportingTime.split(":");
      let rTime = "";
      if (reportingTimes[0] > "12") {
        rTime = parseInt(reportingTimes[0]) - 12;
        reportingT = rTime + ":" + reportingTimes[1] + " P.M";
        setReportT(reportingT);
      } else {
        reportingT = reportingTimes[0] + ":" + reportingTimes[1] + " A.M";
        setReportT(reportingT);
      }
    }
    let sName =
      "ORCHIDS - The International School" + " - " + branches + "," + location;
    const data = {
      candidate_email:
        candidateEmail != ""
          ? candidateEmail
          : candidateDetails?.applicant?.user?.email,
      candidate_name:
        candidateName != ""
          ? candidateName
          : candidateDetails?.applicant?.user?.first_name,
      gender: gender,
      designation:
        designation != ""
          ? designation
          : candidateDetails?.applicant?.applicant_role?.applicant_role_name,
      role:
        roLe != ""
          ? roLe
          : candidateDetails?.applicant?.relevant_fk?.grade?.grade_name +
            "(" +
            candidateDetails?.applicant?.relevant_fk?.subjects?.subject_name +
            ")",
      school_name: sName,
      date_of_joining: dateOfJoining,
      designation_id: designationId,
      fixed_gross_per_month: cTC,
      ctc_per_annum: ctcPerAnnum,
      probation_period: academics == "False" ? "6 Months" : "1 Year",
      notice_period: academics == "False" ? "1 Month" : "3 Months",
      reporting_time: reportingT,
      confirm_before_date: confirmBeforeDate,
      submit_documents_before_date: submitDocBeforeDates,
      hr_name: hrName,
      hr_email: hrEmail,
      hr_mobile: hrMobile,
      escalation_point_email: escaltionPointEmail,
      submited_by: uSer?.academic_profile?.name,
      academic_type: academics,
      recruiter_id: uSer?.personal_info?.user_id,
      cc: total?.length > 0 ? total : cC,
      state: state,
      // "bcc": bcc
    };
    axios
      .post(
        `${urls.offerLetterRelease}?is_preview=False&is_academic=${academics ||
          ""}&designation=${designation || ""}`,
        data
      )
      .then((response) => {
        if (response?.data?.status_code === 201) {
          alert.success(response?.data?.message);
          setFlag(false);
          setOpen(false);
          setSend(false);
          setOuters(false);
          setSchoolName("");
          setDoJoining("");
          setCTC("");
          setCtcPerAnnum("");
          setProbationPeriod("");
          setNoticePeriod("");
          setReportingTime("");
          setCBDate(null);
          setSubmitDocBeforeDate(null);
          setHrName("");
          setHrEmail("");
          setHrMobile("");
          setEscaltionPointEmail("");
          setPrincipalEmail("");
          setCC(["hr_sampark@orchids.edu.in", "offers@orchids.edu.in"]);
          setBcc([]);
          setCandidateName("");
          setCandidateEmail("");
          setDesignation("");
          setAcademics("");
          setRole("");
          setBranches("");
          setLocation("");
          setState("");
          setSubmitDate("");
          setDocsDate("");
          setJoiningDate("");
          setReportT("");
        } else {
          alert.error("Something Went Wrong...");
        }
      })
      .catch((error) => {
        alert.error(error);
      });
  }

  function handlePreview() {
    const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (candidateEmail) {
      if (!filter.test(candidateEmail)) {
        alert.warning("Please enter valid Email...");
        return;
      }
    }
    if (userEmail) {
      if (!filter.test(userEmail)) {
        alert.warning("Please enter valid Email...");
        return;
      }
    }
    if (!designation) {
      alert.warning("Please Select Designation");
      return;
    }
    var total = [];
    if (bcc.length > 0) {
      total = cC.push.apply(cC, bcc);
    }
    if (academics == "True" ? principalEmail : "") {
      total = cC.push(principalEmail);
    }
    if (escaltionPointEmail) {
      total = cC.push(escaltionPointEmail);
    }
    if (hrEmail) {
      total = cC.push(hrEmail);
    }
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let confirmBeforeDate = "";

    if (cbDate) {
      let cbNewDate = new Date(cbDate);
      let cbDay = cbNewDate.getDate();
      let cbMonth = cbNewDate.getMonth();
      let cbYear = cbNewDate.getFullYear();
      if (cbDay === 1) {
        confirmBeforeDate =
          "0" + cbDay + "st " + monthNames[cbMonth] + " " + cbYear;
      } else if (cbDay === 2) {
        confirmBeforeDate =
          "0" + cbDay + "nd " + monthNames[cbMonth] + " " + cbYear;
      } else if (cbDay === 3) {
        confirmBeforeDate =
          "0" + cbDay + "rd " + monthNames[cbMonth] + " " + cbYear;
      } else if (cbDay > 9) {
        confirmBeforeDate = cbDay + "th " + monthNames[cbMonth] + " " + cbYear;
      } else {
        confirmBeforeDate =
          "0" + cbDay + "th " + monthNames[cbMonth] + " " + cbYear;
      }
      setDocsDate(confirmBeforeDate);
    }
    let dateOfJoining = "";
    if (doJoining) {
      let doJoiningNewDate = new Date(doJoining);
      let doJoiningDay = doJoiningNewDate.getDate();
      let doJoiningMonth = doJoiningNewDate.getMonth();
      let doJoiningYear = doJoiningNewDate.getFullYear();
      if (doJoiningDay === 1) {
        dateOfJoining =
          "0" +
          doJoiningDay +
          "st " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      } else if (doJoiningDay === 2) {
        dateOfJoining =
          "0" +
          doJoiningDay +
          "nd " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      } else if (doJoiningDay === 3) {
        dateOfJoining =
          "0" +
          doJoiningDay +
          "rd " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      } else if (doJoiningDay > 9) {
        dateOfJoining =
          doJoiningDay +
          "th " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      } else {
        dateOfJoining =
          "0" +
          doJoiningDay +
          "th " +
          monthNames[doJoiningMonth] +
          " " +
          doJoiningYear;
      }
      setJoiningDate(dateOfJoining);
    }
    let submitDocBeforeDates = "";
    if (submitDocBeforeDate) {
      let submitDocBeforeDateNewDate = new Date(submitDocBeforeDate);
      let submitDocBeforeDateDay = submitDocBeforeDateNewDate.getDate();
      let submitDocBeforeDateMonth = submitDocBeforeDateNewDate.getMonth();
      let submitDocBeforeDateYear = submitDocBeforeDateNewDate.getFullYear();
      if (submitDocBeforeDateDay === 1) {
        submitDocBeforeDates =
          "0" +
          submitDocBeforeDateDay +
          "st " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      } else if (submitDocBeforeDateDay === 2) {
        submitDocBeforeDates =
          "0" +
          submitDocBeforeDateDay +
          "nd " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      } else if (submitDocBeforeDateDay === 3) {
        submitDocBeforeDates =
          "0" +
          submitDocBeforeDateDay +
          "rd " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      } else if (submitDocBeforeDateDay > 9) {
        submitDocBeforeDates =
          submitDocBeforeDateDay +
          "th " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      } else {
        submitDocBeforeDates =
          "0" +
          submitDocBeforeDateDay +
          "th " +
          monthNames[submitDocBeforeDateMonth] +
          " " +
          submitDocBeforeDateYear;
      }
      setSubmitDate(submitDocBeforeDates);
    }
    let reportingT = "";
    if (reportingTime) {
      let reportingTimes = reportingTime.split(":");
      let rTime = "";
      if (reportingTimes[0] > "12") {
        rTime = parseInt(reportingTimes[0]) - 12;
        reportingT = rTime + ":" + reportingTimes[1] + " PM";
        setReportT(reportingT);
      } else {
        reportingT = reportingTimes[0] + ":" + reportingTimes[1] + " AM";
        setReportT(reportingT);
      }
    }
    let sName =
      "ORCHIDS - The International School" + " - " + branches + "," + location;
    const data = {
      candidate_email:
        candidateEmail != ""
          ? candidateEmail
          : candidateDetails?.applicant?.user?.email,
      candidate_name:
        candidateName != ""
          ? candidateName
          : candidateDetails?.applicant?.user?.first_name,
      gender: gender,
      designation:
        designation != ""
          ? designation
          : candidateDetails?.applicant?.applicant_role?.applicant_role_name,
      role:
        roLe != ""
          ? roLe
          : candidateDetails?.applicant?.relevant_fk?.grade?.grade_name +
            "(" +
            candidateDetails?.applicant?.relevant_fk?.subjects?.subject_name +
            ")",
      school_name: sName,
      date_of_joining: dateOfJoining,
      designation_id: designationId,
      fixed_gross_per_month: cTC,
      ctc_per_annum: ctcPerAnnum,
      probation_period: academics == "False" ? "6 Months" : "1 Year",
      notice_period: academics == "False" ? "1 Month" : "3 Months",
      reporting_time: reportingT,
      confirm_before_date: confirmBeforeDate,
      submit_documents_before_date: submitDocBeforeDates,
      hr_name: hrName,
      hr_email: hrEmail,
      hr_mobile: hrMobile,
      escalation_point_email: escaltionPointEmail,
      submited_by: uSer?.academic_profile?.name,
      academic_type: academics,
      recruiter_id: uSer?.personal_info?.user_id,
      cc: total?.length > 0 ? total : cC,
      state: state,
      // "bcc": bcc
    };
    axios
      .post(
        `${urls.offerLetterRelease}?is_preview=True&is_academic=${academics ||
          ""}&designation=${designationId?.toString() || ""}`,
        data
      )
      .then((response) => {
        if (response?.data?.status_code === 201) {
          // alert.success(response?.data?.message);
          setFlag(true);
          setSelectedItem(response?.data);
          setEmailFrom(response?.data?.from_email);
          setEmailTo(response?.data?.to_email);
          setEmailCc(response?.data?.cc_email);
          setEmailSubject(response?.data?.email_subject);
          setEmailPreview(response?.data?.email_body);
          setSelectedCtcWords(response?.data?.ctc_in_words);
          setSelectedCtcDigits(response?.data?.ctc_in_digit);
        } else {
          alert.error("Something Went Wrong...");
        }
      })
      .catch((error) => {
        alert.error(error);
      });
  }

  const subjectList = () => {
    let subject = null;
    if (subjectDetail && subjectDetail.length) {
      subject = subjectDetail.map((value) => {
        const subId = `Sub Id : ${value.id}`;
        return (
          <ListItem
            key={value.id}
            role={undefined}
            dense
            button
            onClick={handleSub(value.id)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredSub.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": subId }}
              />
            </ListItemIcon>
            <ListItemText id={subId} primary={value.subject_name} />
          </ListItem>
        );
      });
    }
    return subject;
  };
  const checkAllLeadsChangeHandler = (e) => {
    const checked = {};
    if (branch && branch.length) {
      branch.forEach((ele) => {
        checked[ele.id] = e.target.checked;
      });
      setIsChecked(checked);
      setSelectAll(!selectAll);
    }
  };
  const checkboxChangeHandler = (e, id) => {
    const checked = { ...isChecked };
    if (e.target.checked) {
      checked[id] = true;
    } else {
      checked[id] = false;
      setSelectAll(false);
    }
    setIsChecked(checked);
  };

  const branchList = () => {
    let branchDetail = null;
    if (branch && branch.length) {
      branchDetail = branch.map((value) => {
        const branchId = `Branch Id : ${value.id}`;
        return (
          <>
            {/* <ListItem key={value.id} role={undefined} dense button onClick={handleBranch(value.id)}> */}
            <ListItem>
              <ListItemIcon>
                {/* <Checkbox
                edge="start"
                color="primary"
                checked={filteredBranch.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': branchId }}
              /> */}
                <input
                  type="checkbox"
                  checked={isChecked[value.id] || false}
                  onChange={(e) => {
                    checkboxChangeHandler(e, value.id);
                  }}
                />
              </ListItemIcon>
              <ListItemText id={branchId} primary={value.branch_name} />
            </ListItem>
          </>
        );
      });
    }
    return branchDetail;
  };

  function addScore(a, b, c, d, e, f, g) {
    return (parseInt(a) || 0) +
      (parseInt(b) || 0) +
      (parseInt(c) || 0) +
      ((d.length !== 0 && parseInt(d[0])) || 0) +
      ((e.length !== 0 && parseInt(e[0])) || 0) +
      ((f.length !== 0 && parseInt(f[0])) || 0) +
      ((g.length !== 0 && parseInt(g[0])) || 0) ===
      0
      ? ""
      : (parseInt(a) || 0) +
          (parseInt(b) || 0) +
          (parseInt(c) || 0) +
          ((d.length !== 0 && parseInt(d[0])) || 0) +
          ((e.length !== 0 && parseInt(e[0])) || 0) +
          ((f.length !== 0 && parseInt(f[0])) || 0) +
          ((g.length !== 0 && parseInt(g[0])) || 0);
  }

  const OrganizationNames = [
    {
      id: 1,
      name: "ORCHIDS The International School - Sahakarnagar Branch, Bangalore",
      value:
        "ORCHIDS The International School - Sahakarnagar Branch, Bangalore",
    },
    {
      id: 2,
      name: "ORCHIDS The International School - Masjid Bunder Branch, Mumbai",
      value: "ORCHIDS The International School - Masjid Bunder Branch, Mumbai",
    },
    {
      id: 3,
      name: "K12 Techno Services Pvt. Ltd (Bengaluru)!!",
      value: "K12 Techno Services Pvt. Ltd (Bengaluru)!!",
    },
    {
      id: 4,
      name: "K12 Techno Services Pvt. Ltd (Mumbai)!!",
      value: "K12 Techno Services Pvt. Ltd (Mumbai)!!",
    },
  ];

  const monthList = [
    {
      id: 1,
      name: "1 Month",
      value: "1 Month",
    },
    {
      id: 2,
      name: "2 Months",
      value: "2 Months",
    },
    {
      id: 3,
      name: "3 Months",
      value: "3 Months",
    },
    {
      id: 4,
      name: "4 Months",
      value: "4 Months",
    },
    {
      id: 5,
      name: "5 Months",
      value: "5 Months",
    },
    {
      id: 6,
      name: "6 Months",
      value: "6 Months",
    },
    {
      id: 7,
      name: "7 Months",
      value: "7 Months",
    },
    {
      id: 8,
      name: "8 Months",
      value: "8 Months",
    },
    {
      id: 9,
      name: "9 Months",
      value: "9 Months",
    },
    {
      id: 10,
      name: "10 Months",
      value: "10 Months",
    },
    {
      id: 11,
      name: "11 Months",
      value: "11 Months",
    },
    {
      id: 12,
      name: "12 Months",
      value: "12 Months",
    },
  ];

  const escaltionPointEmailList = [
    {
      id: 1,
      name: "Contact person - after joining: monali.ghorpade@orchids.edu.in",
      email: "monali.ghorpade@orchids.edu.in",
    },
    {
      id: 2,
      name: "Escalation 1 -  sheela@orchids.edu.in",
      email: " sheela@orchids.edu.in",
    },
    {
      id: 3,
      name: " Escalation 2- dhruv.shandil@k12technoservices.com",
      email: "dhruv.shandil@k12technoservices.com",
    },
  ];

  const ccEmailList = [
    {
      id: 1,
      email: "hr_sampark@orchids.edu.in",
    },
    {
      id: 2,
      email: "hr.corporate@orchids.edu.in",
    },
    {
      id: 3,
      email: "sai.vadlamani@orchids.edu.in",
    },
    {
      id: 4,
      email: "adil.khan@orchids.edu.in",
    },
    {
      id: 5,
      email: "rahulsingh@orchids.edu.in",
    },
  ];

  const locations = [
    {
      id: 1,
      location_name: "Pune",
    },
    {
      id: 2,
      location_name: "Mumbai",
    },
    {
      id: 3,
      location_name: "Kolkata",
    },
    {
      id: 4,
      location_name: "Bangalore",
    },
    {
      id: 5,
      location_name: "Chennai",
    },
    {
      id: 6,
      location_name: "Vizag",
    },
    {
      id: 8,
      location_name: "Aurangabad",
    },
  ];

  const orchidsBranches = [
    {
      id: 1,
      branch_name: "Aadgaon",
      hr_name: "Kinjyol Vernekar",
      hr_mobile: "9324589967",
      escalation_email: "ea1.oisang@orchids.edu.in",
      city: "Aurangabad",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisang@orchids.edu.in",
    },
    {
      id: 49,
      branch_name: "Ambegaon",
      hr_name: "Twinkle Khandelwal",
      hr_mobile: "8421422745",
      escalation_email: "ea1.oisa@orchids.edu.in",
      city: "Pune",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisa@orchids.edu.in",
    },
    {
      id: 50,
      branch_name: "Bachupally",
      hr_name: "Akkapeddy Shalini",
      hr_mobile: "7337202314",
      escalation_email: "ea1.oisbp@orchids.edu.in",
      city: "Hyderabad",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisbp@orchids.edu.in",
    },
    {
      id: 51,
      branch_name: "Bannerghatta",
      hr_name: "SINI SIMON",
      hr_mobile: "7259594203",
      escalation_email: "ea1.oisbg@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisbg@orchids.edu.in",
    },
    {
      id: 52,
      branch_name: "Bollaram",
      hr_name: "Akkapeddy Shalini",
      hr_mobile: "7337202314",
      escalation_email: "ea1.oisbr@orchids.edu.in",
      city: "Hyderabad",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisbr@orchids.edu.in",
    },
    {
      id: 53,
      branch_name: "Borivali",
      hr_name: "Ramita Bhungavale",
      hr_mobile: "7977282242",
      escalation_email: "ea1.oisbv@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisbv@orchids.edu.in",
    },
    {
      id: 54,
      branch_name: "BTM Layout",
      hr_name: "Sadiya Banu",
      hr_mobile: "8073287953",
      escalation_email: "ea1.oisb@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "srprincipal.oisb@orchids.edu.in ",
    },
    {
      id: 2,
      branch_name: "C V Raman Nagar",
      hr_name: "Ranjitha.S",
      hr_mobile: "8431243193",
      escalation_email: "ea1.oiscvr@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oiscvr@orchids.edu.in",
    },
    {
      id: 3,
      branch_name: "Chinchwad",
      hr_name: "NISHA HARSHAD HIWALE",
      hr_mobile: "7507808949",
      escalation_email: "ea1.oiscw@orchids.edu.in",
      city: "Pune",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oiscw@orchids.edu.in",
    },
    {
      id: 4,
      branch_name: "Dombivli",
      hr_name: "NIKHITA AJIT KARMARKAR",
      hr_mobile: "7045325759",
      escalation_email: "ea1.oisdom@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisdom@orchids.edu.in",
    },
    {
      id: 5,
      branch_name: "Golden Heights",
      hr_name: "Radhika Khandelwal",
      hr_mobile: "6367452946",
      escalation_email: "ea1.oisgh@orchids.edu.in",
      city: "Gurgaon",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisgh@orchids.edu.in",
    },
    {
      id: 6,
      branch_name: "Golf Course Road",
      hr_name: "Renuka Narayan Yadav",
      hr_mobile: "9767145516",
      escalation_email: "ea1.oisgcr@orchids.edu.in",
      city: "Gurgaon",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisgcr@orchids.edu.in",
    },
    {
      id: 7,
      branch_name: "Grant Road",
      hr_name: "Sadaf Zahiruddin Shaikh",
      hr_mobile: "9967591052",
      escalation_email: "ea1.oisgt@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisp@orchids.edu.in",
    },
    {
      id: 8,
      branch_name: "Gurgaon South City 1",
      hr_name: "Renuka Narayan Yadav",
      hr_mobile: "9767145516",
      escalation_email: "ea1.oisgsc@orchids.edu.in",
      city: "Gurgaon",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisgsc@orchids.edu.in",
    },
    {
      id: 9,
      branch_name: "Haralur",
      hr_name: "Anjana Vr",
      hr_mobile: "8943293769",
      escalation_email: "ea1.oishl@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oishl@orchids.edu.in",
    },
    {
      id: 10,
      branch_name: "Horamavu",
      hr_name: "Chandini S S",
      hr_mobile: "8792918799",
      escalation_email: "ea1.oishm@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oishm@orchids.edu.in",
    },
    {
      id: 11,
      branch_name: "J P Nagar",
      hr_name: "Harshitha M",
      hr_mobile: "9148037732",
      escalation_email: "ea1.oisjp@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisjpn@orchids.edu.in",
    },
    {
      id: 12,
      branch_name: "Jalahalli",
      hr_name: "LANCY PRIYA",
      hr_mobile: "9513765250",
      escalation_email: "ea1.oisj@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisj@orchids.edu.in",
    },
    {
      id: 13,
      branch_name: "Jubilee Hills",
      hr_name: "S Kalyani",
      hr_mobile: "9513922756",
      escalation_email: "ea1.oish@orchids.edu.in",
      city: "Hyderabad",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oish@orchids.edu.in",
    },
    {
      id: 14,
      branch_name: "Kadugodi",
      hr_name: "Snehaa M",
      hr_mobile: "9686777579",
      escalation_email: "ea1.oiskg@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oiskg@orchids.edu.in",
    },
    {
      id: 15,
      branch_name: "Kapra",
      hr_name: "Akkapeddy Shalini",
      hr_mobile: "7337202314",
      escalation_email: "ea1.oiskp@orchids.edu.in",
      city: "Hyderabad",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oiskp@orchids.edu.in",
    },
    {
      id: 16,
      branch_name: "Kharadi",
      hr_name: "Twinkle Khandelwal",
      hr_mobile: "8421422745",
      escalation_email: "ea1.oiskr@orchids.edu.in",
      city: "Pune",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oiskr@orchids.edu.in",
    },
    {
      id: 17,
      branch_name: "Koparkhairane",
      hr_name: "Priya Gurav",
      hr_mobile: "9664033855",
      escalation_email: "ea1.oiskkh@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      princpal_email: "principal.oiskkh@orchids.edu.in",
    },
    {
      id: 18,
      branch_name: "Koradi Road",
      hr_name: "Priyanka Ritesh Bodake",
      hr_mobile: "8879428016",
      escalation_email: "ea1.oisnr@orchids.edu.in",
      city: "Nagpur",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisnr@orchids.edu.in",
    },
    {
      id: 19,
      branch_name: "Kurla",
      hr_name: "Aaisha Khan",
      hr_mobile: "9768357448",
      escalation_email: "ea2.kurla@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisk@orchids.edu.in",
    },
    {
      id: 20,
      branch_name: "Magadi Road",
      hr_name: "SOWMYA NAYAKA V",
      hr_mobile: "9663565171",
      escalation_email: "ea1.oismgd@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oismgd@orchids.edu.in",
    },
    {
      id: 21,
      branch_name: "Mahalakshmi Layout",
      hr_name: "Shilpa Sophia P",
      hr_mobile: "8618294714",
      escalation_email: "ea1.oisml@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisml@orchids.edu.in",
    },
    {
      id: 22,
      branch_name: "Majestic",
      hr_name: "KAVITHA SRINIVASA",
      hr_mobile: "9008035930",
      escalation_email: "ea1.oismj@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oismj@orchids.edu.in",
    },
    {
      id: 23,
      branch_name: "Malad East",
      hr_name: "Ramita Bhungavale",
      hr_mobile: "7977282242",
      escalation_email: "ea1.oisme@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisme@orchids.edu.in",
    },
    {
      id: 24,
      branch_name: "Mangalore PU",
      hr_name: "Varsha Nandurkar",
      hr_mobile: "9901177290",
      escalation_email: "ea1.mpuc@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal@mpuc.ac.in",
    },
    {
      id: 25,
      branch_name: "Manjiri",
      hr_name: "Twinkle Khandelwal",
      hr_mobile: "8421422745",
      escalation_email: "ea1.oismn@orchids.edu.in",
      city: "Pune",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oismn@orchids.edu.in",
    },
    {
      id: 26,
      branch_name: "Masjid Bunder",
      hr_name: "Sadaf Zahiruddin Shaikh",
      hr_mobile: "9967591052",
      escalation_email: "ea1.oisp@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisp@orchids.edu.in",
    },
    {
      id: 27,
      branch_name: "Mulund",
      hr_name: "Rajani Chetan Patre",
      hr_mobile: "7045002075",
      escalation_email: "ea1.oismu@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oismu@orchids.edu.in",
    },
    {
      id: 28,
      branch_name: "Mysore Road",
      hr_name: "Shwetha KN",
      hr_mobile: "9972306741",
      escalation_email: "ea1.oism@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oism@orchids.edu.in",
    },
    {
      id: 29,
      branch_name: "New Koparkhairane",
      hr_name: "Bhavana Sharma",
      hr_mobile: "9594697062",
      escalation_email: "ea1.oiskkh2@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oiskkh2@orchids.edu.in",
    },
    {
      id: 30,
      branch_name: "New Nagarbhavi",
      hr_name: "SUMATHI S N",
      hr_mobile: "8431566564",
      escalation_email: "ea1.oisngr@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "Principal.oisngr@orchids.edu.in",
    },
    {
      id: 31,
      branch_name: "New Town",
      hr_name: "Magdelena Moushumi Biswas",
      hr_mobile: "9004493027",
      escalation_email: "ea1.oisnt@orchids.edu.in",
      city: "Kolkata",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisnt@orchids.edu.in",
    },
    {
      id: 32,
      branch_name: "Nigdi",
      hr_name: "Priyanka Ritesh Bodake",
      hr_mobile: "8879428016",
      escalation_email: "ea1.oisngd@orchids.edu.in",
      city: "Pune",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisngd@orchids.edu.in",
    },
    {
      id: 33,
      branch_name: "Orlem",
      hr_name: "Sanvedana Swapnil Shrivardhankar",
      hr_mobile: "9769985862",
      escalation_email: "ea1.oismld@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oismld@orchids.edu.in",
    },
    {
      id: 34,
      branch_name: "Panathur",
      hr_name: "Smita Kumar",
      hr_mobile: "9082386952",
      escalation_email: "ea1.oisptr@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "Principal.oisptr@orchids.edu.in",
    },
    {
      id: 35,
      branch_name: "Rajaji Nagar",
      hr_name: "Nandini K",
      hr_mobile: "9739330114",
      escalation_email: "ea1.oisrn@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisrn@orchids.edu.in",
    },
    {
      id: 36,
      branch_name: "Ramanashree",
      hr_name: "Divya K M",
      hr_mobile: "9740394767",
      escalation_email: "ea1.oisvn@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisvn@orchids.edu.in",
    },
    {
      id: 37,
      branch_name: "Sahakar Nagar",
      hr_name: "Usha D",
      hr_mobile: "8105778979",
      escalation_email: "ea1.oisv@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisv@orchids.edu.in",
    },
    {
      id: 38,
      branch_name: "Sarjapur",
      hr_name: "Nandinipriya K",
      hr_mobile: "8660800543",
      escalation_email: "ea1.oiss@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oiss@orchids.edu.in",
    },
    {
      id: 39,
      branch_name: "SchooVi Kids",
      hr_name: "Madhuri",
      hr_mobile: "9916266657",
      escalation_email: "ea.oischoovi@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisv@orchids.edu.in",
    },
    {
      id: 40,
      branch_name: "Seawoods",
      hr_name: "Soniya Sunil Dhainje",
      hr_mobile: "9665460200",
      escalation_email: "ea1.oissw@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oissw@orchids.edu.in",
    },
    {
      id: 41,
      branch_name: "Tathawade",
      hr_name: "NISHA HARSHAD HIWALE",
      hr_mobile: "7507808949",
      escalation_email: "ea1.oistwd@orchids.edu.in",
      city: "Pune",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oistwd@orchids.edu.in",
    },
    {
      id: 42,
      branch_name: "Thane",
      hr_name: "Rajani Chetan Patre",
      hr_mobile: "7045002075",
      escalation_email: "ea1.oist@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oist@orchids.edu.in",
    },
    {
      id: 43,
      branch_name: "Thuraipakkam",
      hr_name: "Prathima Sree Prakasam",
      hr_mobile: "9944680029",
      escalation_email: "ea1.oistp@orchids.edu.in",
      city: "Chennai",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oistp@orchids.edu.in",
    },
    {
      id: 44,
      branch_name: "Undri",
      hr_name: "Shivani Harshvardhan Shakya",
      hr_mobile: "7049223389",
      escalation_email: "ea1.oisnib@orchids.edu.in",
      city: "Pune",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisnib@orchids.edu.in",
    },
    {
      id: 45,
      branch_name: "Vashi",
      hr_name: "Dimple Ramesh Motwani",
      hr_mobile: "7208866392",
      escalation_email: "ea1.oisva@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisvsh@orchids.edu.in",
    },

    {
      id: 55,
      branch_name: "Vikhroli",
      hr_name: "Ashwini Sanjay Haldankar",
      hr_mobile: "8097492123",
      escalation_email: "ea1.oisvkh@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisvkh@orchids.edu.in",
    },
    {
      id: 46,
      branch_name: "Yari Road",
      hr_name: "Ramita Bhungavale",
      hr_mobile: "7977282242",
      escalation_email: "ea1.oisy@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oisy@orchids.edu.in",
    },
    {
      id: 47,
      branch_name: "Yelahanka",
      hr_name: "Aishwarya N",
      hr_mobile: "9741823557",
      escalation_email: "ea1.oisyh@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oisyh@orchids.edu.in",
    },
    {
      id: 48,
      branch_name: "Yelahanka PU",
      hr_name: "Varsha Nandurkar",
      hr_mobile: "9901177290",
      escalation_email: "ea1.tpuyh@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "",
    },
    {
      id: 56,
      branch_name: "Perumbakam",
      hr_name: "Prathima Sree Prakasam",
      hr_mobile: "9944680029",
      escalation_email: "ea1.oispb@orchids.edu.in",
      city: "Chennai",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oispb@orchids.edu.in",
    },
    {
      id: 57,
      branch_name: "Chunabhatti",
      hr_name: "Aaisha Khan",
      hr_mobile: "9768357448",
      escalation_email: "ea1.oisch@orchids.edu.in",
      city: "Mumbai",
      escalation_name: "Naseeruddin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oiscb@orchids.edu.in",
    },
    {
      id: 58,
      branch_name: "Electronic City",
      hr_name: "Nandinipriya K",
      hr_mobile: "8660800543",
      escalation_email: "ea1.oise@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oise@orchids.edu.in",
    },
    {
      id: 59,
      branch_name: "Kanakanagar",
      hr_name: "",
      hr_mobile: "",
      escalation_email: "ea1.oiskn@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "",
    },
    {
      id: 60,
      branch_name: "Pallavaram",
      hr_name: "",
      hr_mobile: "",
      escalation_email: "ea1.oispr@orchids.edu.in",
      city: "Chennai",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "",
    },
    {
      id: 61,
      branch_name: "Tumkur",
      hr_name: "Lancy Priya",
      hr_mobile: "9513765250",
      escalation_email: "ea1.oistk@orchids.edu.in",
      city: "Tumkur",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "principal.oistk@orchids.edu.in",
    },
    {
      id: 62,
      branch_name: "CVRN PU",
      hr_name: "Varsha Nandurkar",
      hr_mobile: "9901177290",
      escalation_email: "ea1.tpuyh@orchids.edu.in",
      city: "Bangalore",
      escalation_name: "Neha Singh",
      escalation_point_email: "neha.singh@orchids.edu.in",
      principal_email: "",
    },
    {
      id: 63,
      branch_name: "Manglaya",
      hr_name: "Naseerudin Idris",
      hr_mobile: "8660800543",
      escalation_email: "ea1.oismga@orchids.edu.in",
      city: "Indore",
      escalation_name: "Naseerudin Idris",
      escalation_point_email: "naseerudin.idris@orchids.edu.in",
      principal_email: "principal.oismga@orchids.edu.in",
    },
  ];

  const [newStateName, setNewStateName] = useState([]);
  function newStateList() {
    axios
      .get(`${urls.stateListApi}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        setNewStateName(response.data);
      })
      .catch(() => {
        console.log("error");
      });
  }
  function NewBranchList() {
    axios
      .get(`${urls.branchListApi}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data, "responsedata");
        setBranchListName(response.data);
      })
      .catch(() => {
        console.log("error");
      });
  }
  function designationList() {
    if (academics) {
      axios
        .get(`${urls.designationListApi}?is_academic=${academics || ""}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${name.personal_info.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setDesignationName(response.data);
        })
        .catch(() => {
          console.log("error");
        });
    }
  }

  useEffect(() => {
    designationList();
    NewBranchList();
    newStateList();
  }, [name, academics]);

  // useEffect(() => {
  //   designationList();
  // },[academics])

  const orchidsDesignations = [
    {
      id: 1,
      name: "PSYCHOLOGIST",
    },
    {
      id: 2,
      name: "Special Educator",
    },
    {
      id: 3,
      name: "Behavioural Counsellor",
    },
    {
      id: 4,
      name: "Senior Faculty - Chemistry",
    },
    {
      id: 5,
      name: "Senior Faculty - Maths",
    },
    {
      id: 6,
      name: "Senior Faculty - Physics",
    },
    {
      id: 7,
      name: "Senior Faculty - Science",
    },
    {
      id: 8,
      name: "Senior Faculty - Maths and Science",
    },
    {
      id: 9,
      name: "Subject Ambassador - Mathematics",
    },
    {
      id: 10,
      name: "Subject Ambassador - Science",
    },
    {
      id: 11,
      name: "EYP Teacher",
    },
    {
      id: 12,
      name: "Intern - Teaching",
    },
    {
      id: 13,
      name: "Lab Assistant",
    },
    {
      id: 14,
      name: "Part Time Teacher - Accountancy",
    },
    {
      id: 15,
      name: "Part Time Teacher - Music",
    },
    {
      id: 16,
      name: "Pre-Primary Teacher - Literacy",
    },
    {
      id: 17,
      name: "Pre-Primary Teacher - Literacy & Numeracy",
    },
    {
      id: 18,
      name: "Pre-Primary Teacher - Numeracy",
    },
    {
      id: 19,
      name: "Pre-Primary Teacher - Trainee",
    },
    {
      id: 20,
      name: "Primary Teacher - Bengali",
    },
    {
      id: 21,
      name: "Primary Teacher - Computer Science",
    },
    {
      id: 22,
      name: "Primary Teacher - English",
    },
    {
      id: 23,
      name: "Primary Teacher - French",
    },
    {
      id: 24,
      name: "Primary Teacher - Hindi",
    },
    {
      id: 25,
      name: "Primary Teacher - IDP",
    },
    {
      id: 26,
      name: "Primary Teacher - Kannada",
    },
    {
      id: 27,
      name: "Primary Teacher - Marathi",
    },
    {
      id: 28,
      name: "Primary Teacher - Maths",
    },
    {
      id: 29,
      name: "Primary Teacher - Sanskrit",
    },
    {
      id: 30,
      name: "Primary Teacher - Science",
    },
    {
      id: 31,
      name: "Primary Teacher - Tamil",
    },
    {
      id: 32,
      name: "Primary Teacher - Telugu",
    },
    {
      id: 33,
      name: "Primary Teacher - Trainee",
    },
    {
      id: 34,
      name: "Psychologist Intern",
    },
    {
      id: 35,
      name: "PYP Teacher",
    },
    {
      id: 36,
      name: "Secondary Teacher - Bengali",
    },
    {
      id: 37,
      name: "Secondary Teacher - Biology",
    },
    {
      id: 38,
      name: "Secondary Teacher - Chemistry",
    },
    {
      id: 39,
      name: "Secondary Teacher - Computer Science",
    },
    {
      id: 40,
      name: "Secondary Teacher - English",
    },
    {
      id: 41,
      name: "Secondary Teacher - French",
    },
    {
      id: 42,
      name: "Secondary Teacher - Geography",
    },
    {
      id: 43,
      name: "Secondary Teacher - Hindi",
    },
    {
      id: 44,
      name: "Secondary Teacher - History & Civics",
    },
    {
      id: 45,
      name: "Secondary Teacher - Kannada",
    },
    {
      id: 46,
      name: "Secondary Teacher - Marathi",
    },
    {
      id: 47,
      name: "Secondary Teacher - Maths",
    },
    {
      id: 48,
      name: "Secondary Teacher - Physics",
    },
    {
      id: 49,
      name: "Secondary Teacher - Sanskrit",
    },
    {
      id: 50,
      name: "Secondary Teacher - Science",
    },
    {
      id: 51,
      name: "Secondary Teacher - Social Studies",
    },
    {
      id: 52,
      name: "Secondary Teacher - Tamil",
    },
    {
      id: 53,
      name: "Secondary Teacher - Telugu",
    },
    {
      id: 54,
      name: "Secondary Teacher - Trainee",
    },
    {
      id: 55,
      name: "Senior Teacher - English",
    },
    {
      id: 56,
      name: "Senior Teacher - Maths",
    },
    {
      id: 57,
      name: "Senior Teacher - Pre-Primary",
    },
    {
      id: 58,
      name: "Teacher - Abacus",
    },
    {
      id: 59,
      name: "Teacher - Archery",
    },
    {
      id: 60,
      name: "Teacher - Art & Crafts",
    },
    {
      id: 61,
      name: "Teacher - Bengali",
    },
    {
      id: 62,
      name: "Teacher - Communication Skills",
    },
    {
      id: 63,
      name: "Teacher - Computer Science",
    },
    {
      id: 64,
      name: "Teacher - Dance",
    },
    {
      id: 65,
      name: "Teacher - Economics and Business Studies",
    },
    {
      id: 66,
      name: "Teacher - English",
    },
    {
      id: 67,
      name: "Teacher - French",
    },
    {
      id: 68,
      name: "Teacher - Hindi",
    },
    {
      id: 69,
      name: "Teacher - ICT",
    },
    {
      id: 70,
      name: "Teacher - IDP",
    },
    {
      id: 71,
      name: "Teacher - Kannada",
    },
    {
      id: 72,
      name: "Teacher - Marathi",
    },
    {
      id: 73,
      name: "Teacher - Maths",
    },
    {
      id: 74,
      name: "Teacher - Music",
    },
    {
      id: 75,
      name: "Teacher - Photography",
    },
    {
      id: 76,
      name: "Teacher - Physical Education",
    },
    {
      id: 77,
      name: "Teacher - Pottery",
    },
    {
      id: 78,
      name: "Teacher - Public Speaking",
    },
    {
      id: 79,
      name: "Teacher - Sanskrit",
    },
    {
      id: 80,
      name: "Teacher - Science",
    },
    {
      id: 81,
      name: "Teacher - Skating",
    },
    {
      id: 82,
      name: "Teacher - Social Studies",
    },
    {
      id: 83,
      name: "Teacher - Sports",
    },
    {
      id: 84,
      name: "Teacher - Swimming",
    },
    {
      id: 85,
      name: "Teacher - Taekwondo",
    },
    {
      id: 3,
      name: "Teacher - Telugu",
    },
    {
      id: 86,
      name: "Teacher - Theatre",
    },
    {
      id: 87,
      name: "Teacher - Trainee",
    },
    {
      id: 88,
      name: "Teacher - Yoga",
    },
    {
      id: 89,
      name: "Higher Secondary Teacher - Economics",
    },
    {
      id: 90,
      name: "Higher Secondary Teacher - Accounts",
    },
    {
      id: 91,
      name: "Higher Secondary Teacher - Business Studies",
    },
    {
      id: 92,
      name: "Higher Secondary Teacher - Computer Science",
    },
    {
      id: 93,
      name: "Higher Secondary Teacher - Biology",
    },
    {
      id: 94,
      name: "Higher Secondary Teacher - Biology and Chemistry",
    },
    {
      id: 95,
      name: "Higher Secondary Teacher - Social Studies",
    },
    {
      id: 96,
      name: "Higher Secondary Teacher - Science",
    },
    {
      id: 97,
      name: "Higher Secondary Teacher - Maths",
    },
    {
      id: 98,
      name: "Higher Secondary Teacher - Accounts and Business Studies",
    },
    {
      id: 99,
      name: "Lecturer - Biology",
    },
    {
      id: 100,
      name: "Lecturer - Chemistry",
    },
    {
      id: 101,
      name: "Lecturer - Commerce",
    },
    {
      id: 102,
      name: "Lecturer - Computer Science",
    },
    {
      id: 103,
      name: "Lecturer - Economics",
    },
    {
      id: 104,
      name: "Lecturer - Electronics",
    },
    {
      id: 105,
      name: "Lecturer - English",
    },
    {
      id: 106,
      name: "Lecturer - Kannada",
    },
    {
      id: 107,
      name: "Lecturer - Mathematics",
    },
    {
      id: 108,
      name: "Lecturer - Physical Education",
    },
    {
      id: 109,
      name: "Lecturer - Physics",
    },
    {
      id: 110,
      name: "Lecturer - Sanskrit",
    },
    {
      id: 111,
      name: "Lecturer - Statistics",
    },
    {
      id: 112,
      name: "Subject Ambassador - Art & Crafts",
    },
    {
      id: 113,
      name: "Subject Ambassador - Computer Science",
    },
    {
      id: 114,
      name: "Subject Ambassador - Dance",
    },
    {
      id: 115,
      name: "Subject Ambassador - English",
    },
    {
      id: 116,
      name: "Subject Ambassador - English & IDP",
    },
    {
      id: 117,
      name: "Subject Ambassador - French",
    },
    {
      id: 118,
      name: "Subject Ambassador - Hindi",
    },
    {
      id: 119,
      name: "Subject Ambassador - Kannada",
    },
    {
      id: 120,
      name: "Subject Ambassador - Literacy",
    },
    {
      id: 121,
      name: "Subject Ambassador - Marathi",
    },
    {
      id: 122,
      name: "Subject Ambassador - Music",
    },
    {
      id: 123,
      name: "Subject Ambassador - Numeracy",
    },
    {
      id: 124,
      name: "Subject Ambassador - Physical Education",
    },
    {
      id: 125,
      name: "Subject Ambassador - Public Speaking",
    },
    {
      id: 126,
      name: "Subject Ambassador - Social Studies",
    },
    {
      id: 127,
      name: "Subject Ambassador - Theatre",
    },
    {
      id: 128,
      name: "Lecturer - Hindi",
    },
    {
      id: 129,
      name: "Lecturer - IFRS",
    },
    {
      id: 130,
      name: "Senior Secondary Teacher - Computer Science",
    },
    {
      id: 131,
      name: "EYP Teacher - English",
    },
    {
      id: 132,
      name: "Senior Secondary Teacher - English",
    },
    {
      id: 134,
      name: "Teacher - HIndi and Sanskrit",
    },
    {
      id: 135,
      name: "EYP Teacher - Maths",
    },
    {
      id: 136,
      name: "EYP Teacher - Marathi",
    },
    {
      id: 137,
      name: "EYP Teacher - Kannada",
    },
    {
      id: 138,
      name: "EYP Teacher - Hindi",
    },
    {
      id: 139,
      name: "Primary Teacher - Maths and Science",
    },
    {
      id: 140,
      name: "Higher Secondary Teacher - English",
    },
    {
      id: 141,
      name: "Teacher - Chemistry",
    },
    {
      id: 142,
      name: "Higher Secondary Teacher - Political Science",
    },
    {
      id: 143,
      name: "Higher Secondary Teacher - Political Science (Part Time)",
    },
    {
      id: 144,
      name: "Coordinator - EYP",
    },
    {
      id: 145,
      name: "Coordinator - Primary",
    },
    {
      id: 146,
      name: "Coordinator - Secondary",
    },
    {
      id: 147,
      name: "Coordinator - Trainee",
    },
    {
      id: 148,
      name: "Director",
    },
    {
      id: 149,
      name: "Principal",
    },
    {
      id: 150,
      name: "Senior Principal",
    },
    {
      id: 151,
      name: "Vice Principal",
    },
    {
      id: 152,
      name: "Deputy Head - Branch Academics",
    },
    {
      id: 153,
      name: "Coordinator - Middle School",
    },
    {
      id: 154,
      name: "Head - AOL Academic Excellence",
    },
    {
      id: 155,
      name: "Head - AOL Academic Implementation",
    },
    {
      id: 156,
      name: "Primary Teacher - Financial Literacy",
    },
    {
      id: 157,
      name: "Librarian",
    },
    {
      id: 158,
      name: "Teacher - Day Care",
    },
    {
      id: 159,
      name: "Teacher - DIY",
    },
    {
      id: 160,
      name: "Lecturer - Fashion Designer",
    },
  ];

  const states = [
    { code: "AN", name: "Andaman and Nicobar Islands" },
    { code: "AP", name: "Andhra Pradesh" },
    { code: "AR", name: "Arunachal Pradesh" },
    { code: "AS", name: "Assam" },
    { code: "BR", name: "Bihar" },
    { code: "CG", name: "Chandigarh" },
    { code: "CH", name: "Chhattisgarh" },
    { code: "DH", name: "Dadra and Nagar Haveli" },
    { code: "DD", name: "Daman and Diu" },
    { code: "DL", name: "Delhi" },
    { code: "GA", name: "Goa" },
    { code: "GJ", name: "Gujarat" },
    { code: "HR", name: "Haryana" },
    { code: "HP", name: "Himachal Pradesh" },
    { code: "JK", name: "Jammu and Kashmir" },
    { code: "JH", name: "Jharkhand" },
    { code: "KA", name: "Karnataka" },
    { code: "KL", name: "Kerala" },
    { code: "LD", name: "Lakshadweep" },
    { code: "MP", name: "Madhya Pradesh" },
    { code: "MH", name: "Maharashtra" },
    { code: "MN", name: "Manipur" },
    { code: "ML", name: "Meghalaya" },
    { code: "MZ", name: "Mizoram" },
    { code: "NL", name: "Nagaland" },
    { code: "OR", name: "Odisha" },
    { code: "PY", name: "Puducherry" },
    { code: "PB", name: "Punjab" },
    { code: "RJ", name: "Rajasthan" },
    { code: "SK", name: "Sikkim" },
    { code: "TN", name: "Tamil Nadu" },
    { code: "TS", name: "Telangana" },
    { code: "TR", name: "Tripura" },
    { code: "UK", name: "Uttarakhand" },
    { code: "UP", name: "Uttar Pradesh" },
    { code: "WB", name: "West Bengal" },
  ];
  useEffect(() => {
    if (branches) {
      let data = branchListName.map((item) => {
        if (item?.branch?.branch_name === branches) {
          setLocation(item?.branch?.city_id?.city_name);
          setEscaltionPointEmail(item.first_st_poc_email);
          setHrName(item.first_st_escalation_point_name);
          setHrMobile(item.first_st_poc_contact_number);
          setHrEmail(item.first_escalation_point_email);
          setPrincipalEmail(item.principal_email);
        }
      });
    }
  }, [branches]);
  const handleMobileNo = (e) => {
    const re = /^[0-9]+$/g;
    if (
      (e.target.value === "" || re.test(e.target.value)) &&
      e.target.value.length <= 7
    ) {
      setCTC(e.target.value || "");
      setCtcPerAnnum(e.target.value || "");
    }
  };
  // const handleDateChange = (e) => {
  //   setDoJoining(e);
  // };

  const handleEmail = (e) => {
    setCandidateEmail(e.target.value);
    setUserEmail(e.target.value);
  };
  const handleChangeCC = (event) => {
    const {
      target: { value },
    } = event;
    setCC(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function profileTable() {
    let applicantTable = null;
    if (
      applicantsList &&
      applicantsList.results &&
      applicantsList.results.length === 0 &&
      pageChange
    ) {
      applicantTable = (
        <React.Fragment>
          <Typography
            variant="h5"
            style={{ color: "blue", textAlign: "center", marginTop: "17%" }}
          >
            Records Not Found
          </Typography>
        </React.Fragment>
      );
    }
    if (
      applicantsList &&
      applicantsList.results &&
      applicantsList.results.length &&
      pageChange
    ) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{ marginTop: "10px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ border: "1px solid black" }}
                  >
                    <strong>S.No</strong>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "1px solid black" }}
                  >
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "1px solid black" }}
                  >
                    <strong>Mobile No.</strong>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "1px solid black" }}
                  >
                    <strong>Offer Status</strong>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "1px solid black" }}
                  >
                    <strong>Applicant Status</strong>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "1px solid black" }}
                  >
                    <strong>Offer Letter</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applicantsList.results.map((details, i) => (
                  <TableRow key={details.id}>
                    <TableCell
                      align="center"
                      style={{ border: "1px solid black" }}
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ border: "1px solid black" }}
                    >
                      {(details.applicant &&
                        details.applicant.user &&
                        details.applicant.user.first_name &&
                        details.applicant.user.first_name) ||
                        ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ border: "1px solid black" }}
                    >
                      {details?.applicant?.user?.username}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ border: "1px solid black" }}
                    >
                      {details.is_offer_letter_realesed == true ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ border: "1px solid black" }}
                    >
                      {details.is_offer_letter_accepted == true ? (
                        <CheckIcon style={{ color: "green" }} />
                      ) : (
                        <CloseIcon style={{ color: "red" }} />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ border: "1px solid black" }}
                    >
                      {details.is_offer_letter_realesed == true ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          endIcon={<Icon>send</Icon>}
                          onClick={() => handleClickResendOpen(details)}
                        >
                          Resend
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          endIcon={<Icon>send</Icon>}
                          onClick={() => handleClickOpen(details)}
                        >
                          Send
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <TableRow className={classes.tableFooter}>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() =>
                    `Page ${page + 1} of ${+applicantsList.total_pages}`
                  }
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  count={+applicantsList.count}
                  rowsPerPage={rowsPerPage || 50}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "Rows per page" },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: "13px" }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      lastPageChange(applicantsList.total_pages - 1)
                    }
                    disabled={page === +applicantsList.total_pages - 1}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Paper>
          {open ? (
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
              style={{
                width: "-webkit-fill-available",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
              // fullScreen={fullScreen}
            >
              <DialogTitle id="responsive-dialog-title">
                Offer Letter
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="responsive-dialog-description"
                  tabIndex={-1}
                >
                  <Grid container spacing={2} className={classes.modalPaper}>
                    <Grid item sm={6} md={6} xs={6}>
                      <FormControl
                        style={{
                          display: "unset",
                          marginLeft: "10px",
                          top: "9px",
                        }}
                      >
                        {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={(e) => setState(e.target.value)}
                          value={state || ""}
                          variant="outlined"
                          type="text"
                          // input={<OutlinedInput label="Organization" />}
                          MenuProps={MenuProps}
                        >
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            value=""
                          >
                            <em>None</em>
                          </MenuItem>
                          {newStateName
                            .map((option, index) => (
                              <MenuItem
                                style={{ minWidth: "fit-content" }}
                                key={index}
                                value={option.state_name}
                              >
                                {option.state_name}
                              </MenuItem>
                            ))
                            .sort()}
                        </Select>
                        <FormHelperText
                          style={{
                            marginLeft: "23px",
                            fontSize: "14px",
                            fontWeight: "bolder",
                          }}
                        >
                          State
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} md={6} xs={6}>
                      <FormControl
                        style={{
                          display: "unset",
                          marginLeft: "10px",
                          top: "9px",
                        }}
                      >
                        {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-helper-label"
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={(e) => setBranches(e.target.value)}
                          value={branches || ""}
                          variant="outlined"
                          type="text"
                          // input={<OutlinedInput label="Organization" />}
                          MenuProps={MenuProps}
                        >
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            value=""
                          >
                            <em>None</em>
                          </MenuItem>
                          {branchListName
                            .map((option, index) => (
                              <MenuItem
                                style={{ minWidth: "fit-content" }}
                                key={index}
                                value={option?.branch?.branch_name}
                              >
                                {option?.branch?.branch_name}
                              </MenuItem>
                            ))
                            .sort()}
                        </Select>
                        <FormHelperText style={{ marginLeft: "23px" }}>
                          Branch
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item sm={6} md={6} xs={6}>
                      <TextField
                        style={{ marginLeft: "10px" }}
                        helperText="Date of Joining"
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={(e) => setDoJoining(e.target.value)}
                        type="date"
                        value={doJoining || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={6} md={6} xs={6}>
                      <TextField
                        style={{ marginLeft: "10px" }}
                        helperText="Fixed Gross Per Month in Digits"
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={(e) =>
                          e.target.value.length <= 6
                            ? setCTC(e.target.value)
                            : ""
                        }
                        type="number"
                        value={cTC || ""}
                        variant="outlined"
                      />
                    </Grid>
                    {/* <Grid item sm={6} md={6} xs={6}>
                        <FormControl style={{ display: 'unset', marginLeft: '10px', top: '9px' }}>
                          <Select
                            className={classes.textField}
                            margin="dense"
                            required
                            fullWidth
                            onChange={e => setProbationPeriod(e.target.value)}
                            type="text"
                            value={'1 Year' || ''}
                            variant="outlined"
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {monthList.map((option) => (
                              <MenuItem key={option.id} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}</Select>
                          <FormHelperText style={{ marginLeft: '23px' }}>Probation Period</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item sm={6} md={6} xs={6}>
                        <FormControl style={{ display: 'unset', marginLeft: '10px', top: '9px' }}>
                          <Select
                            className={classes.textField}
                            margin="dense"
                            required
                            fullWidth
                            onChange={e => setNoticePeriod(e.target.value)}
                            type="text"
                            value={'3 Months' || ''}
                            variant="outlined"
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {monthList.map((option) => (
                              <MenuItem key={option.id} value={option.value}>
                                {option.name}
                              </MenuItem>
                            ))}</Select>
                          <FormHelperText style={{ marginLeft: '23px' }}>Notice Period</FormHelperText>
                        </FormControl>
                      </Grid> */}
                    <Grid item sm={6} md={6} xs={6}>
                      <TextField
                        style={{ marginLeft: "10px" }}
                        helperText="Reporting Time"
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={(e) => setReportingTime(e.target.value)}
                        type="time"
                        value={reportingTime || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={6} md={6} xs={6}>
                      <TextField
                        style={{ marginLeft: "10px" }}
                        helperText="Confirm Before Date"
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={(e) => setCBDate(e.target.value)}
                        type="date"
                        value={cbDate || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={6} md={6} xs={6}>
                      <TextField
                        style={{ marginLeft: "10px" }}
                        helperText="Submit Documents Before Date"
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={(e) => setSubmitDocBeforeDate(e.target.value)}
                        type="date"
                        value={submitDocBeforeDate || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={6} md={6} xs={6}>
                      <FormControl
                        style={{
                          display: "unset",
                          marginLeft: "10px",
                          top: "9px",
                        }}
                      >
                        {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>CC</InputLabel> */}
                        <Select
                          className={classes.textField}
                          margin="dense"
                          required
                          fullWidth
                          onChange={handleChangeCC}
                          type="email"
                          value={cC || ""}
                          variant="outlined"
                          multiple={true}
                          // input={<OutlinedInput id="select-multiple-chip" label="CC" />}
                          renderValue={(selected) => (
                            <Box
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 1.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          <MenuItem key="0" value="0">
                            <em>None</em>
                          </MenuItem>
                          {ccEmailList.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.email}
                              style={getStyles(option?.email, cC, theme)}
                            >
                              {option.email}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText style={{ marginLeft: "23px" }}>
                          CC
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {/* {cC?.length != 0 ? ( */}
                    <Grid item sm={6} md={6} xs={6}>
                      <TextField
                        style={{ marginLeft: "10px" }}
                        helperText="Additional CC's"
                        className={classes.textField}
                        margin="dense"
                        fullWidth
                        multiple
                        onChange={(e) => {
                          handleAdditional(e);
                        }}
                        type="email"
                        value={bcc || ""}
                        variant="outlined"
                      />
                    </Grid>
                    {/* ) : ('')} */}
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: "space-around" }}>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    float: "left",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handlePreview}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                  disabled={
                    !submitDocBeforeDate ||
                    !cC ||
                    !cbDate ||
                    !reportingTime ||
                    !cTC ||
                    !ctcPerAnnum ||
                    !doJoining ||
                    !branches ||
                    !state
                      ? true
                      : false
                  }
                >
                  Preview
                </Button>
                <Button
                  onClick={handleSendEmail}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                  disabled={
                    !submitDocBeforeDate ||
                    !cC ||
                    !cbDate ||
                    !reportingTime ||
                    !cTC ||
                    !ctcPerAnnum ||
                    !doJoining ||
                    !branches ||
                    !state
                      ? true
                      : false
                  }
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            ""
          )}
          {flag ? (
            <Dialog
              open={flag}
              // onClose={handleCloseOuters}
              aria-labelledby="responsive-dialog-title"
              style={{
                width: "-webkit-fill-available",
                textAlign: "left",
                alignItems: "left",
                justifyContent: "center",
              }}
              // fullScreen={fullScreen}
            >
              <DialogTitle
                id="responsive-dialog-title"
                style={{ textAlign: "center", backgroundColor: "primary" }}
              >
                Offer Letter Preview
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="responsive-dialog-description"
                  tabIndex={-1}
                >
                  <p>from: offers@orchids.edu.in</p>
                  <p>
                    to:{" "}
                    {candidateEmail != ""
                      ? candidateEmail
                      : candidateDetails?.applicant?.user?.email}
                  </p>
                  <p>
                    cc:
                    <ul>
                      {cC.map((item) => (
                        <li>{item}</li>
                      ))}
                    </ul>
                  </p>
                  <p>
                    <b>
                      Dear{" "}
                      {candidateName != ""
                        ? candidateName
                        : candidateDetails?.applicant?.user?.first_name}
                    </b>
                    ,
                  </p>
                  <p>
                    <b>Congratulations!! </b>
                  </p>
                  <p>
                    This is with reference to your application and subsequent
                    interviews you had with us.
                  </p>
                  <p>
                    We would like to offer you the position of{" "}
                    <b>
                      {designation != ""
                        ? designation
                        : candidateDetails?.applicant?.applicant_role
                            ?.applicant_role_name}
                    </b>{" "}
                    with{" "}
                    <b>
                      {"ORCHIDS - The International School" +
                        " - " +
                        branches +
                        "," +
                        location}
                      .
                    </b>
                  </p>
                  <p>
                    This offer
                    <b> (subject to you joining us) on {joiningDate} </b> will
                    carry an annual CTC of{" "}
                    <b>
                      INR {selectedCtcDigits}/- ({selectedCtcWords}per annum)
                    </b>
                    .<br />
                    The offer break-up is provided below.
                  </p>
                  <Table>
                    <TableHead
                      style={{
                        border: "1px solid",
                        textAlign: "center",
                        backgroundColor: "black",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid", color: "white" }}
                        >
                          <strong>Salary Details</strong>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid", color: "white" }}
                        >
                          <strong>Per Month</strong>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid", color: "white" }}
                        >
                          <strong>Per Annum</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedItem &&
                        selectedItem?.result?.salary_details?.map(
                          (item, index) => (
                            <TableRow key={index}>
                              <TableCell
                                align="center"
                                style={{ border: "1px solid" }}
                              >
                                <strong>{item ? item : ""}</strong>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ border: "1px solid" }}
                              >
                                {selectedItem?.result?.per_month[index]
                                  ? selectedItem?.result?.per_month[index]
                                  : ""}
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{ border: "1px solid" }}
                              >
                                {selectedItem?.result?.per_annum[index]
                                  ? selectedItem?.result?.per_annum[index]
                                  : ""}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                    </TableBody>
                  </Table>
                  {/* {selectedItem} */}

                  <p>
                    This is a full-time position and there will be a
                    probationary period of{" "}
                    <b> {academics == "False" ? "6 Months" : "1 Year"} </b> and
                    the notice period will be{" "}
                    <b> {academics == "False" ? "1 Month" : "3 Months"}</b>.
                    Your date of joining will be on <b>{joiningDate} </b>.
                    Reporting Time on the date of joining will be at{" "}
                    <b> {reportT}</b>. Please revert with a confirmation of your
                    acceptance of the offer and date of joining by{" "}
                    <b> {docsDate}</b>.
                  </p>
                  <p>
                    <b>
                      You are required to submit the following documents on or
                      before {submitDate}.
                    </b>
                  </p>
                  <p>
                    <b style={{ textDecoration: "underline" }}>
                      Please note: You are required to submit the soft copies of
                      your documents/details using the link below by 5 pm, on
                      the day before your joining date :
                    </b>
                  </p>
                  <Table>
                    <TableHead
                      style={{
                        border: "1px solid",
                        textAlign: "center",
                        backgroundColor: "yellow",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid", color: "black" }}
                        >
                          <strong>S.No.</strong>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid", color: "black" }}
                        >
                          <strong>Language</strong>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid", color: "black" }}
                        >
                          <strong>Link</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          1
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          English
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          <a
                            href="https://bit.ly/Corp_WelcomeForm"
                            target="_blank"
                          >
                            https://bit.ly/Corp_WelcomeForm
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          2
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          Hindi (हिन्दी)
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          <a
                            href="https://bit.ly/WelcomeForm_Hindi"
                            target="_blank"
                          >
                            https://bit.ly/WelcomeForm_Hindi
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          3
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          Marathi (मराठी)
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          <a
                            href="https://bit.ly/Welcomeform_Marathi"
                            target="_blank"
                          >
                            https://bit.ly/Welcomeform_Marathi
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          4
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          Kannada (ಕನ್ನಡ)
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          <a
                            href="https://bit.ly/WelcomeForm_Kannada"
                            target="_blank"
                          >
                            https://bit.ly/WelcomeForm_Kannada
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          5
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          Bengali (বাংলা)
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          <a
                            href="https://bit.ly/Welcomeform_Bengali"
                            target="_blank"
                          >
                            https://bit.ly/Welcomeform_Bengali
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          6
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          Tamil (தமிழ்)
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          <a
                            href="https://bit.ly/WelcomeForm_Tamil"
                            target="_blank"
                          >
                            https://bit.ly/WelcomeForm_Tamil
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          7
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          Telugu (తెలుగు)
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ border: "1px solid" }}
                        >
                          <a
                            href="https://bit.ly/WelcomeForm_Telugu"
                            target="_blank"
                          >
                            https://bit.ly/WelcomeForm_Telugu
                          </a>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <ul>
                    <li>
                      10th & 12th Passing Certificates/ Marksheets - Originals
                      and Photocopy
                    </li>
                    <li>
                      Graduation certificate/ Mark sheets - Originals and
                      Photocopy
                    </li>
                    <li>PG certificate / Mark sheet copies - If any</li>
                    <li>Aadhaar card</li>
                    <li>Pan Card</li>
                    <li>
                      Appraisal/Promotion/increment letter of last organization
                      - both Original and Photocopy -- If Any
                    </li>
                    <li>Appointment letter of the last organisation</li>
                    <li>Relieving letter of the last organisation</li>
                    <li>4 passport size colour photographs</li>
                    <li>
                      Cancelled Cheque/bank account details proof (Joint account
                      not allowed)
                    </li>
                    <li>
                      Last 3 Months Payslips and Bank Statement copy, if
                      applicable
                    </li>
                    <li>
                      Universal Account Number (Related to PF, if applicable)
                    </li>
                    <li>
                      Employee State Insurance details from last organization
                      (if applicable)
                    </li>
                    <li>Latest Resume copy</li>
                    <li>Permanent Address Proof</li>
                    <li>Correspondence Address Proof</li>
                    <br />
                  </ul>
                  <b>
                    <p>
                      (Bring all original documents for Verification and all the
                      photocopies should be self-attested)
                    </p>
                    <p>Important Points:</p>
                  </b>
                  <ol Type="1">
                    <li>
                      Your net salary will be subject to income tax deductible
                      at source. At the time of joining, we request you to
                      submit your investment/tax deduction declaration in our
                      employee HR portal (GreytHR) to ensure that TDS applicable
                      is correctly calculated.
                    </li>
                    <li>
                      By accepting this offer/reporting for duty at the branch
                      you confirm and agree that you are bound by the
                      organization policies and rules prescribed in the employee
                      handbook which will be available with the branch EA and
                      your employee portal (GreytHR) and the policies and rules
                      will come into effect from the day you report to the
                      branch.
                    </li>
                    <li>
                      This offer to you has been made based on information
                      furnished by you. However, if there is a discrepancy in
                      the documents, certificates or information given by you,
                      we reserve the right to terminate the employment with
                      immediate effect with no liability for any compensation to
                      be paid to you.
                    </li>
                    <li>
                      You provide us with the right to conduct background checks
                      to validate your educational and professional credentials
                    </li>
                    <li>
                      Orchids The International School reserves the right to
                      alter the salary structure components and benefits
                      provided as per its requirement at any time, including but
                      limited to the retention deduction, retention fund,
                      insurance benefit, transportation benefit. However, in
                      case such alteration is made, the gross salary of the
                      employee will not be reduced.
                    </li>
                    <li>
                      All employees joining by 30th of September of a calendar
                      year are eligible for increment and appraisal in the next
                      calendar year of joining. Employees joining on or after
                      1st of October will NOT be eligible for increments and
                      appraisal in the next appraisal cycle but will be eligible
                      for increments in the appraisal cycle that comes after
                      that.
                    </li>
                    <li>
                      In case you do not have an updated Adhaar card you will be
                      considered as a retainer and will not be considered as a
                      regular employee. In such a case you will work as a
                      retainer payroll for a maximum of 2 months and if you do
                      not provide the updated Adhaar soft copy by then, the
                      organization will be forced to remove you from the
                      organization and the notice period payment will be
                      recovered from you. Aadhaar copy should match with online
                      adhaar records. To facilitate this, download your latest
                      E-aadhaar copy that is not more than 1 week old and
                      provide the same at the time of joining.
                    </li>
                    <li>
                      In case your work location is a school premise, it is
                      mandatory to get the police verification done at your end
                      within one month of joining the organization to get your
                      salary processed. The branch EA/HR can guide you on this
                      in case you need any help.
                    </li>
                    <li>
                      Subject to the terms of the letter of appointment (LOA) to
                      be signed by the employee, the employee shall be eligible
                      for, and the Employer shall pay to the Employee, a
                      Retention Payment; provided that the Employee remains in
                      continuous employment with the Employer until the end of
                      the school Academic year as defined in the policies
                      (usually March end for this purpose but subject to
                      change). Except as provided in the LOA Employee shall not
                      be eligible for, and shall not be paid, the Retention
                      Payment if Employee’s employment with the Employer is
                      terminated for any reason on or prior to the end of the
                      academic year, including, without limitation, a
                      termination due to resignation by the Employee herself or
                      termination for Cause (as defined in the LOA) by the
                      Employer due to grave misconduct.
                    </li>
                    <li>
                      Medical insurance will be provided to the employee, the
                      employee's spouse, and a maximum of 2 dependent children.
                      The insurance cost is an approximation of the insurance
                      cost and can vary.
                    </li>
                  </ol>
                  <p>
                    <u>
                      Contact person from HR team after joining:{hrName} ({" "}
                      {hrEmail}) ({hrMobile})
                    </u>
                  </p>
                  <p>
                    Contact person in case there is any issue with joining
                    formalities, induction session or any other HR-related
                    concern after joining - {escaltionPointEmail}{" "}
                  </p>
                  <p>
                    <u>
                      Congratulations and wishing you a successful career at
                      ORCHIDS The International School!!!
                    </u>
                  </p>
                  <img
                    style={{ width: "25%" }}
                    src="https://ci6.googleusercontent.com/proxy/CEXxPZUvWSWfVFq3V8RS6sz0dxpeVHhhLV9Fp71KsMp7gl-0XYEdkqAAL5Ioc9UovWD9e9PTXb-JnpnD8ijBAGkt0Bo0fjgcbNLpvs8apOWDgzTgu-qnFOVgKRgfxxbJLwpEed32qNQ8m1mtEHAxf-o5D0sxOjQpGpJQVr9qmQAIICm_1Qm-SwJahQj8PdtoEUWFl-UzyEBSgLRPnr7emhks=s0-d-e1-ft#https://s3.amazonaws.com/ucwebapp.wisestamp.com/faaa76e0-d0f4-480c-a06c-2bebbce88316/orchidsinternationalschoollogo.format_png.resize_200x.png"
                  />
                  <br />
                  <p>
                    <b>
                      Warm Regards,
                      <br />
                      Team Recruitment, Orchids - The International School
                    </b>
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: "space-around" }}>
                <Button
                  onClick={handleClosePreview}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    float: "left",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSendEmail}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                  disabled={
                    !submitDocBeforeDate ||
                    !cC ||
                    !cbDate ||
                    !reportingTime ||
                    !cTC ||
                    !ctcPerAnnum ||
                    !doJoining ||
                    !branches ||
                    !state
                      ? true
                      : false
                  }
                >
                  Send
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            ""
          )}
        </React.Fragment>
      );
    }
    return applicantTable;
  }

  // const fetchSearch = e => {
  //   setPage(0);
  //   setRowsPerPage(5);
  //   if (e.keyCode === 13) {
  //     const data = document.getElementById("search-bar").value;
  //     setApplicentName(document.getElementById("search-bar").value);
  //     functionSearch(data);
  //   }
  // };

  // function functionSearch(data) {
  //   // fetchApplicant({
  //   //   url: `${urls.searchApplicantRecruiter}?page=${page +
  //   //     1}&page_size=${rowsPerPage || 5}&search=${data}`,
  //   //   method: "GET",
  //   //   headers: {
  //   //     "Content-Type": "application/json",
  //   //     Authorization: `Bearer ${name.personal_info.token}`
  //   //   }
  //   // });
  // }

  const handleOuters = () => {
    setBranches("");
    setState("");
    setOuters(true);
  };

  const academicList = [
    {
      id: 1,
      value: "True",
      label: "Academic",
    },
    {
      id: 2,
      value: "False",
      label: "Sales and Business Development",
    },
  ];

  const genderList = [
    { id: 1, value: "Female", label: "Female" },
    { id: 2, value: "Male", label: "Male" },
  ];

  const salesList = [
    {
      id: 1,
      value: 1,
      label: "PRO Event",
    },
    {
      id: 2,
      value: 2,
      label: "PRO Lead Generation (Morning)",
    },
    {
      id: 3,
      value: 3,
      label: "PRO Lead Generation (Evening)",
    },
    {
      id: 4,
      value: 4,
      label: "Branch Counsellor",
    },
    {
      id: 5,
      value: 5,
      label: "Business Development Associate",
    },
    {
      id: 6,
      value: 6,
      label: "Manager - Business Development (Counseling)",
    },
    {
      id: 7,
      value: 7,
      label: "City Head - Counselling Operations",
    },
    {
      id: 8,
      value: 8,
      label: "Customer Support Executive",
    },
  ];

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.modalPaper}>
        <Grid item sm={12} md={6} xs={12}>
          <Typography className="headings" variant="h4">
            <strong>Offer Letter Release</strong>
          </Typography>
        </Grid>
        {/* <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onKeyDown={fetchSearch}
            />
          </div>
        </Grid> */}
        <Grid item md={6} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={(e) => functionSearch(e.target.value)}
            />
          </div>
        </Grid>
        <Grid item sm={5} md={3} xs={5} style={{ marginTop: "13px" }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<Icon>send</Icon>}
            onClick={handleOuters}
          >
            Release Offer Letter
          </Button>
          {/* <p>For Candidates Not registered on Career Box</p> */}
        </Grid>
        <Grid item sm={5} md={3} xs={5}>
          <TextField
            style={{ marginLeft: "10px" }}
            helperText="Start Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={(e) => handleStartDateChange(e.target.value)}
            type="date"
            value={startDate || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={5} md={3} xs={5}>
          <TextField
            style={{ marginLeft: "10px" }}
            helperText="End Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={(e) => handleEndDateChange(e.target.value)}
            type="date"
            value={endDate || ""}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={2} md={1} xs={2}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{ marginTop: "10px", marginLeft: "10px" }}
            onClick={handleGetData}
          >
            Get
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={2} className={classes.paperMain}>
          <Typography variant="h6" className={classes.heading}>
            <strong>Filter By</strong>
          </Typography>
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classes.heading}>
            <strong>Subjects</strong>
          </Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>{subjectList()}</Paper>
            </Grid>
          </List>
          <Typography variant="h6" className={classes.heading}>
            <strong>Branch</strong>
          </Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                <ListItem>
                  <ListItemIcon>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={selectAll}
                          onChange={checkAllLeadsChangeHandler}
                        />
                      }
                      // label="Select All"
                    />
                  </ListItemIcon>
                  <ListItemText primary="Select All" />
                </ListItem>

                {branchList()}
              </Paper>
            </Grid>
          </List>
          <Grid className={classes.loginButton}>
            <Button
              className={classes.Button}
              color="primary"
              variant="contained"
              size="large"
              onClick={() => applyFilter()}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
        <Grid item md={10} className={classes.paperMain}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {profileDetail &&
                profileDetail.length &&
                profileDetail.map((role, i) => (
                  <Tab
                    style={{
                      width: "500px",
                      marginRight: "4%",
                      marginLeft: "4%",
                    }}
                    key={role.id}
                    label={role.position_type + " " + role.applicant_role_name}
                  />
                ))}
            </Tabs>
          </AppBar>
          {profileTable()}
        </Grid>
      </Grid>
      {send ? (
        <Dialog
          open={send}
          // onClose={handleCloseOuters}
          aria-labelledby="responsive-dialog-title"
          style={{
            width: "-webkit-fill-available",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
          // fullScreen={fullScreen}
        >
          <DialogTitle id="responsive-dialog-title">
            Offer Letter Release
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="responsive-dialog-description" tabIndex={-1}>
              <Grid container spacing={2} className={classes.modalPaper}>
                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="First Name, Last Name"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    onChange={(e) => setUserName(e.target.value)}
                    type="text"
                    value={userName || ""}
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Candidate Email"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    // onChange={e => setCandidateEmail(e.target.value)}
                    onChange={handleEmail}
                    type="email"
                    value={userEmail || ""}
                    variant="outlined"
                  />
                  <span className={classes.helpeText}>
                    {userEmail && userEmail.match(filterEmailId) === null
                      ? "Enter Valid EmailId"
                      : ""}
                  </span>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setGender(e.target.value)}
                      value={gender || ""}
                      variant="outlined"
                      type="text"
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}
                      {genderList
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            id={option?.id}
                            value={option.id}
                          >
                            {option?.label}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      Select Gender
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setAcademics(e.target.value)}
                      value={academics || ""}
                      variant="outlined"
                      type="text"
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}

                      {academicList.map((option, index) => (
                        <MenuItem
                          key={index}
                          id={option?.id}
                          value={option.value}
                          name={option.id}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ marginLeft: "23px" }}>
                      Position Type
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e, value) => {
                        setDesignation(e?.target?.value);
                        setDesignationId(value?.props?.id);
                      }}
                      value={designation}
                      variant="outlined"
                      type="text"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}
                      {academics == "True"
                        ? designationName &&
                          designationName.length !== 0 &&
                          designationName
                            .map((option, index) => (
                              <MenuItem
                                style={{ minWidth: "fit-content" }}
                                key={index}
                                value={option.name}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                            .sort()
                        : academics &&
                          salesList &&
                          salesList.length !== 0 &&
                          salesList.map((option, index) => (
                            <MenuItem
                              key={index}
                              id={option?.id}
                              value={option.label}
                              name={option?.label}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                    </Select>
                    <FormHelperText style={{ marginLeft: "23px" }}>
                      Designation
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setState(e.target.value)}
                      value={state || ""}
                      variant="outlined"
                      type="text"
                      id="state"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        style={{ minWidth: "fit-content" }}
                        key="0"
                        value="0"
                      >
                        <em>None</em>
                      </MenuItem>
                      {newStateName
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            value={option.state_name}
                          >
                            {option.state_name}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      State
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setBranches(e.target.value)}
                      value={branches || ""}
                      variant="outlined"
                      type="text"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        style={{ minWidth: "fit-content" }}
                        key="0"
                        value="0"
                      >
                        <em>None</em>
                      </MenuItem>
                      {branchListName
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            value={option?.branch?.branch_name}
                          >
                            {option?.branch?.branch_name}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      Branch
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      style={{ marginLeft: "10px" }}
                      margin="dense"
                      // minDate={moment().toDate()}
                      // id="date-picker-inline"
                      format="DD/MM/YYYY"
                      helperText="Date of Joining"
                      // defaultValue={moment().format()}
                      // autoOk={true}
                      // showTodayButton={true}

                      // label="Date of Joining"
                      className={classes.textFieldOne}
                      fullWidth
                      value={doJoining}
                      // onChange={handleDateChange}
                      onChange={(e) => setDoJoining(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Fixed Gross Per Month in Digits"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    // onChange={e => e.target.value.length <= 6 ? (setCTC(e.target.value)) : ''}
                    onChange={handleMobileNo}
                    type="text"
                    value={cTC || ""}
                    variant="outlined"
                  />
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Reporting Time"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    onChange={(e) => setReportingTime(e.target.value)}
                    type="time"
                    value={reportingTime || ""}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      style={{ marginLeft: "10px" }}
                      margin="dense"
                      // minDate={moment().toDate()}
                      id="date-picker-inline"
                      format="DD/MM/YYYY"
                      helperText="Submit Documents Before Date"
                      // label="Date of Joining"
                      className={classes.textFieldOne}
                      value={submitDocBeforeDate || ""}
                      required
                      fullWidth
                      onChange={(e) => setSubmitDocBeforeDate(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>CC</InputLabel> */}
                    <Select
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={handleChangeCC}
                      type="email"
                      value={cC || ""}
                      variant="outlined"
                      multiple={true}
                      // input={<OutlinedInput id="select-multiple-chip" label="CC" />}
                      renderValue={(selected) => (
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem key="0" value="None">
                          <em>None</em>
                        </MenuItem> */}
                      {ccEmailList.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.email}
                          style={getStyles(option?.email, cC, theme)}
                        >
                          {option.email}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      CC
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* {cC?.length != 0 ? ( */}
                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Additional CC's"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    multiline
                    onChange={(e) => {
                      handleAdditional(e);
                    }}
                    type="email"
                    value={bcc || ""}
                    variant="outlined"
                  />
                </Grid>
                {/* ) : ('')} */}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-around" }}>
            <Button
              onClick={handleCloseOuters}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                float: "left",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handlePreview}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
              disabled={
                !submitDocBeforeDate ||
                !cC ||
                !cbDate ||
                !reportingTime ||
                !cTC ||
                !ctcPerAnnum ||
                !doJoining ||
                !branches ||
                !state
                  ? true
                  : false
              }
            >
              Preview
            </Button>
            <Button
              onClick={handleSendEmail}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
              disabled={
                !submitDocBeforeDate ||
                !cC ||
                !cbDate ||
                !reportingTime ||
                !cTC ||
                !ctcPerAnnum ||
                !doJoining ||
                !branches ||
                !state
                  ? true
                  : false
              }
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
      {postForm ? (
        <Dialog
          open={postForm}
          // onClose={handleCloseOuters}
          aria-labelledby="responsive-dialog-title"
          style={{
            width: "-webkit-fill-available",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
          // fullScreen={fullScreen}
        >
          <DialogTitle id="responsive-dialog-title">
            Offer Letter Release
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="responsive-dialog-description" tabIndex={-1}>
              <Grid container spacing={2} className={classes.modalPaper}>
                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="First Name, Last Name"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    onChange={(e) => setUserName(e.target.value)}
                    type="text"
                    value={userName || ""}
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Candidate Email"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    // onChange={e => setCandidateEmail(e.target.value)}
                    onChange={handleEmail}
                    type="email"
                    value={userEmail || ""}
                    variant="outlined"
                  />
                  <span className={classes.helpeText}>
                    {userEmail && userEmail.match(filterEmailId) === null
                      ? "Enter Valid EmailId"
                      : ""}
                  </span>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setGender(e.target.value)}
                      value={gender || ""}
                      variant="outlined"
                      type="text"
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}
                      {genderList
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            id={option?.id}
                            value={option.id}
                          >
                            {option?.label}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      Select Gender
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setAcademics(e.target.value)}
                      value={academics || ""}
                      variant="outlined"
                      type="text"
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}
                      {academicList.map((option, index) => (
                        <MenuItem
                          key={index}
                          id={option?.id}
                          value={option.value}
                          name={option.id}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ marginLeft: "23px" }}>
                      Position Type
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e, value) => {
                        setDesignation(e?.target?.value);
                        setDesignationId(value?.props?.id);
                      }}
                      value={designation}
                      variant="outlined"
                      type="text"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}
                      {academics == "True"
                        ? designationName &&
                          designationName.length !== 0 &&
                          designationName
                            .map((option, index) => (
                              <MenuItem
                                id={option?.id}
                                style={{ minWidth: "fit-content" }}
                                key={index}
                                value={option.name}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                            .sort()
                        : academics &&
                          salesList &&
                          salesList.length !== 0 &&
                          salesList.map((option, index) => (
                            <MenuItem
                              key={index}
                              id={option?.id}
                              value={option.label}
                              name={option?.label}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                    </Select>
                    <FormHelperText style={{ marginLeft: "23px" }}>
                      Designation
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setState(e.target.value)}
                      value={state || ""}
                      variant="outlined"
                      type="text"
                      id="state"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        style={{ minWidth: "fit-content" }}
                        key="0"
                        value="0"
                      >
                        <em>None</em>
                      </MenuItem>
                      {newStateName
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            value={option.state_name}
                          >
                            {option.state_name}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      State
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setBranches(e.target.value)}
                      value={branches || ""}
                      variant="outlined"
                      type="text"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        style={{ minWidth: "fit-content" }}
                        key="0"
                        value="0"
                      >
                        <em>None</em>
                      </MenuItem>
                      {branchListName
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            value={option?.branch?.branch_name}
                          >
                            {option?.branch?.branch_name}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      Branch
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      style={{ marginLeft: "10px" }}
                      margin="dense"
                      minDate={moment().toDate()}
                      // id="date-picker-inline"
                      format="DD/MM/YYYY"
                      helperText="Date of Joining"
                      // defaultValue={moment().format()}
                      // autoOk={true}
                      // showTodayButton={true}

                      // label="Date of Joining"
                      className={classes.textFieldOne}
                      fullWidth
                      value={doJoining}
                      // onChange={handleDateChange}
                      onChange={(e) => setDoJoining(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Fixed Gross Per Year in Digits"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    // onChange={e => e.target.value.length <= 6 ? (setCTC(e.target.value)) : ''}
                    onChange={handleMobileNo}
                    type="text"
                    value={cTC || ""}
                    variant="outlined"
                  />
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Reporting Time"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    onChange={(e) => setReportingTime(e.target.value)}
                    type="time"
                    value={reportingTime || ""}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      style={{ marginLeft: "10px" }}
                      margin="dense"
                      minDate={moment().toDate()}
                      id="date-picker-inline"
                      format="DD/MM/YYYY"
                      helperText="Submit Documents Before Date"
                      // label="Date of Joining"
                      className={classes.textFieldOne}
                      value={submitDocBeforeDate || ""}
                      required
                      fullWidth
                      onChange={(e) => setSubmitDocBeforeDate(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>CC</InputLabel> */}
                    <Select
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={handleChangeCC}
                      type="email"
                      value={cC || ""}
                      variant="outlined"
                      multiple={true}
                      // input={<OutlinedInput id="select-multiple-chip" label="CC" />}
                      renderValue={(selected) => (
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem key="0" value="None">
                          <em>None</em>
                        </MenuItem> */}
                      {ccEmailList.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.email}
                          style={getStyles(option?.email, cC, theme)}
                        >
                          {option.email}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      CC
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* {cC?.length != 0 ? ( */}
                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Additional CC's"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    multiline
                    onChange={(e) => {
                      handleAdditional(e);
                    }}
                    type="email"
                    value={bcc || ""}
                    variant="outlined"
                  />
                </Grid>
                {/* ) : ('')} */}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-around" }}>
            <Button
              onClick={handleCloseOuters}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                float: "left",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handlePreview}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
              disabled={
                !submitDocBeforeDate ||
                !cC ||
                !cbDate ||
                !reportingTime ||
                !cTC ||
                !ctcPerAnnum ||
                !doJoining ||
                !branches ||
                !state
                  ? true
                  : false
              }
            >
              Preview
            </Button>
            <Button
              onClick={handleSendEmail}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
              disabled={
                !submitDocBeforeDate ||
                !cC ||
                !cbDate ||
                !reportingTime ||
                !cTC ||
                !ctcPerAnnum ||
                !doJoining ||
                !branches ||
                !state
                  ? true
                  : false
              }
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}

      {outers ? (
        <Dialog
          open={outers}
          // onClose={handleCloseOuters}
          aria-labelledby="responsive-dialog-title"
          style={{
            width: "-webkit-fill-available",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
          // fullScreen={fullScreen}
        >
          <DialogTitle id="responsive-dialog-title">
            Offer Letter Release
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="responsive-dialog-description" tabIndex={-1}>
              <Grid container spacing={2} className={classes.modalPaper}>
                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="First Name, Last Name"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    onChange={(e) => setCandidateName(e.target.value)}
                    type="text"
                    value={candidateName || ""}
                    variant="outlined"
                    InputLabelProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }}
                  />
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Candidate Email"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    // onChange={e => setCandidateEmail(e.target.value)}
                    onChange={handleEmail}
                    type="email"
                    value={candidateEmail || ""}
                    variant="outlined"
                  />
                  <span className={classes.helpeText}>
                    {candidateEmail &&
                    candidateEmail.match(filterEmailId) === null
                      ? "Enter Valid EmailId"
                      : ""}
                  </span>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setGender(e.target.value)}
                      value={gender || ""}
                      variant="outlined"
                      type="text"
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}
                      {genderList
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            id={option?.id}
                            value={option.id}
                          >
                            {option?.label}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      Select Gender
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {/* <Grid item sm={6} md={6} xs={6}>
                    <TextField
                      style={{ marginLeft: '10px' }}
                      helperText="Role"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={e => setRole(e.target.value)}
                      type="text"
                      value={roLe || ''}
                      variant="outlined"
                    />
                  </Grid> */}
                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setAcademics(e.target.value)}
                      value={academics || ""}
                      variant="outlined"
                      type="text"
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}
                      {academicList.map((option, index) => (
                        <MenuItem
                          style={{ minWidth: "fit-content" }}
                          key={index}
                          id={option?.id}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ marginLeft: "23px" }}>
                      Position Type
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e, value) => {
                        setDesignation(e?.target?.value);
                        setDesignationId(value?.props?.id);
                      }}
                      value={designation}
                      // name = {name}
                      variant="outlined"
                      type="text"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem style={{ minWidth: 'fit-content' }} key="0" value="0">
                          <em>None</em>
                        </MenuItem> */}
                      {academics == "True"
                        ? designationName &&
                          designationName.length !== 0 &&
                          designationName
                            .map((option, index) => (
                              <MenuItem
                                style={{ minWidth: "fit-content" }}
                                key={index}
                                value={option.name}
                                id={option?.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                            .sort()
                        : academics &&
                          salesList &&
                          salesList.length !== 0 &&
                          salesList.map((option, index) => (
                            <MenuItem
                              key={index}
                              id={option?.id}
                              value={option?.label}
                              name={option?.label}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}

                      {/* 
                {roleList &&
                roleList.length !== 0 &&
                roleList.map((option, index) => (
                  <MenuItem
                    key={index}
                    value={option.id}
                    name={option.applicant_role_name}
                  >
                    {option.applicant_role_name}
                  </MenuItem>
                ))} */}
                    </Select>
                    <FormHelperText style={{ marginLeft: "23px" }}>
                      Designation
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* <Grid item sm={6} md={6} xs={6}>
                    <FormControl style={{ display: 'unset', marginLeft: '10px', top: '9px' }}>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={e => setLocation(e.target.value)}
                        value={location || ''}
                        variant="outlined"
                        type="text"
                        MenuProps={MenuProps}
                      >
                        <MenuItem style={{ minWidth: 'fit-content' }} value="">
                          <em>None</em>
                        </MenuItem>
                        {locations.map((option) => (
                          <MenuItem style={{ minWidth: 'fit-content' }} key={option.id} value={option.location_name}>
                            {option.location_name}
                          </MenuItem>
                        ))}</Select>
                      <FormHelperText style={{ marginLeft: '23px' }}>Location</FormHelperText>
                    </FormControl>
                  </Grid> */}
                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setState(e.target.value)}
                      value={state || ""}
                      variant="outlined"
                      type="text"
                      id="state"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        style={{ minWidth: "fit-content" }}
                        key="0"
                        value="0"
                      >
                        <em>None</em>
                      </MenuItem>
                      {newStateName
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            value={option.state_name}
                          >
                            {option.state_name}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      State
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>Organization</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={(e) => setBranches(e.target.value)}
                      value={branches || ""}
                      variant="outlined"
                      type="text"
                      // input={<OutlinedInput label="Organization" />}
                      MenuProps={MenuProps}
                    >
                      <MenuItem
                        style={{ minWidth: "fit-content" }}
                        key="0"
                        value="0"
                      >
                        <em>None</em>
                      </MenuItem>
                      {branchListName
                        .map((option, index) => (
                          <MenuItem
                            style={{ minWidth: "fit-content" }}
                            key={index}
                            value={option?.branch?.branch_name}
                          >
                            {option?.branch?.branch_name}
                          </MenuItem>
                        ))
                        .sort()}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      Branch
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  {/* <TextField
                      style={{ marginLeft: '10px' }}
                      helperText="Date of Joining"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      minDate={moment().toDate()}

                      
                      // onChange={e => setDoJoining(e.target.value)}
                      onChange={handleDateChange}
                      type="date"
                      value={doJoining || ''}
                      variant="outlined"
                    /> */}

                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      style={{ marginLeft: "10px" }}
                      margin="dense"
                      minDate={moment().toDate()}
                      // id="date-picker-inline"
                      format="DD/MM/YYYY"
                      helperText="Date of Joining"
                      // defaultValue={moment().format()}
                      // autoOk={true}
                      // showTodayButton={true}

                      // label="Date of Joining"
                      className={classes.textFieldOne}
                      fullWidth
                      value={doJoining}
                      // onChange={handleDateChange}
                      onChange={(e) => {
                        setDoJoining(e);
                        setSubmitDocBeforeDate(
                          moment(e)
                            .subtract(2, "days")
                            .toDate()
                        );
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText={
                      (academics == "False" &&
                        designation == "Business Development Associate") ||
                      designation == "Branch Counsellor" ||
                      designation ==
                        "Manager - Business Development (Counseling)" ||
                      designation == "City Head - Counselling Operations" ||
                      designation == "Customer Support Executive"
                        ? "CTC Per Annum in Digits"
                        : "Fixed Gross Per Year in Digits"
                    }
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    // onChange={e => e.target.value.length <= 6 ? (setCTC(e.target.value)) : ''}
                    onChange={handleMobileNo || ""}
                    type="text"
                    value={
                      (academics == "False" &&
                        designation == "Business Development Associate") ||
                      designation == "Branch Counsellor" ||
                      designation ==
                        "Manager - Business Development (Counseling)" ||
                      designation == "City Head - Counselling Operations" ||
                      designation == "Customer Support Executive"
                        ? ctcPerAnnum || ""
                        : cTC || ""
                    }
                    variant="outlined"
                  />
                </Grid>
                {/* <Grid item sm={6} md={6} xs={6}>
                    <FormControl style={{ display: 'unset', marginLeft: '10px', top: '9px' }}>
                      <Select
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={e => setProbationPeriod(e.target.value)}
                        type="text"
                        value={'1 Year' || ''}
                        variant="outlined"
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {monthList.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}</Select>
                      <FormHelperText style={{ marginLeft: '23px', fontSize: '14px', fontWeight: 'bolder' }}>Probation Period</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} md={6} xs={6}>
                    <FormControl style={{ display: 'unset', marginLeft: '10px', top: '9px' }}>
                      <Select
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={e => setNoticePeriod(e.target.value)}
                        type="text"
                        value={'3 Months' || ''}
                        variant="outlined"
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {monthList.map((option) => (
                          <MenuItem key={option.id} value={option.value}>
                            {option.name}
                          </MenuItem>
                        ))}</Select>
                      <FormHelperText style={{ marginLeft: '23px', fontSize: '14px', fontWeight: 'bolder' }}>Notice Period</FormHelperText>
                    </FormControl>
                  </Grid> */}
                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Reporting Time"
                    className={classes.textField}
                    margin="dense"
                    required
                    fullWidth
                    onChange={(e) => setReportingTime(e.target.value)}
                    type="time"
                    value={reportingTime || ""}
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  {/* <TextField
                      style={{ marginLeft: '10px' }}
                      helperText="Confirm Before Date"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={e => setCBDate(e.target.value)}
                      type="date"
                      value={cbDate || ''}
                      variant="outlined"
                    /> */}
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      style={{ marginLeft: "10px" }}
                      margin="dense"
                      minDate={moment().toDate()}
                      id="date-picker-inline"
                      format="DD/MM/YYYY"
                      helperText="Confirm Before Date"
                      // label="Date of Joining"
                      className={classes.textFieldOne}
                      value={cbDate || ""}
                      required
                      fullWidth
                      onChange={(e) => setCBDate(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  {/* <TextField
                      style={{ marginLeft: '10px' }}
                      helperText="Submit Documents Before Date"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={e => setSubmitDocBeforeDate(e.target.value)}
                      type="date"
                      value={submitDocBeforeDate || ''}
                      variant="outlined"
                    /> */}
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disabled
                      inputVariant="outlined"
                      style={{ marginLeft: "10px" }}
                      margin="dense"
                      minDate={moment().toDate()}
                      id="date-picker-inline"
                      format="DD/MM/YYYY"
                      helperText="Submit Documents Before Date"
                      // label="Date of Joining"
                      className={classes.textFieldOne}
                      value={submitDocBeforeDate || ""}
                      required
                      fullWidth
                      onChange={(e) => setSubmitDocBeforeDate(e)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {/* <Grid item sm={6} md={6} xs={6}>
                    <TextField
                      style={{ marginLeft: '10px' }}
                      helperText="Hr Name"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={e => setHrName(e.target.value)}
                      type="text"
                      value={hrName || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} md={6} xs={6}>
                    <TextField
                      style={{ marginLeft: '10px' }}
                      helperText="Hr Email"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={e => setHrEmail(e.target.value)}
                      type="email"
                      value={hrEmail || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item sm={6} md={6} xs={6}>
                    <TextField
                      style={{ marginLeft: '10px' }}
                      helperText="Hr Mobile"
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={e => setHrMobile(e.target.value)}
                      type="number"
                      value={hrMobile || ''}
                      variant="outlined"
                    />
                  </Grid> */}
                {/* <Grid item sm={6} md={6} xs={6}>
                    <FormControl style={{ display: 'unset', marginLeft: '10px', top: '9px' }}>
                      <Select
                        className={classes.textField}
                        margin="dense"
                        required
                        fullWidth
                        onChange={e => setEscaltionPointEmail(e.target.value)}
                        type="email"
                        value={escaltionPointEmail || ''}
                        variant="outlined"
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {escaltionPointEmailList.map((option) => (
                          <MenuItem key={option.id} value={option.email}>
                            {option.name}
                          </MenuItem>
                        ))}</Select>
                      <FormHelperText style={{ marginLeft: '23px' }}>Escalation Point Email</FormHelperText>
                    </FormControl>
                  </Grid> */}
                <Grid item sm={6} md={6} xs={6}>
                  <FormControl
                    style={{ display: "unset", marginLeft: "10px", top: "9px" }}
                  >
                    {/* <InputLabel id="demo-simple-select-helper-label" style={{ marginLeft: '10px' }}>CC</InputLabel> */}
                    <Select
                      className={classes.textField}
                      margin="dense"
                      required
                      fullWidth
                      onChange={handleChangeCC}
                      type="email"
                      value={cC || ""}
                      variant="outlined"
                      multiple={true}
                      // input={<OutlinedInput id="select-multiple-chip" label="CC" />}
                      renderValue={(selected) => (
                        <Box
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {/* <MenuItem key="0" value="None">
                          <em>None</em>
                        </MenuItem> */}
                      {ccEmailList.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.email}
                          style={getStyles(option?.email, cC, theme)}
                        >
                          {option.email}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText
                      style={{
                        marginLeft: "23px",
                        fontSize: "14px",
                        fontWeight: "bolder",
                      }}
                    >
                      CC
                    </FormHelperText>
                  </FormControl>
                </Grid>
                {/* {cC?.length != 0 ? ( */}
                <Grid item sm={6} md={6} xs={6}>
                  <TextField
                    style={{ marginLeft: "10px" }}
                    helperText="Additional CC's"
                    className={classes.textField}
                    margin="dense"
                    fullWidth
                    multiline
                    onChange={(e) => {
                      handleAdditional(e);
                    }}
                    type="email"
                    value={bcc || ""}
                    variant="outlined"
                  />
                </Grid>
                {/* ) : ('')} */}
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-around" }}>
            <Button
              onClick={handleCloseOuters}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                float: "left",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handlePreview}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
              disabled={
                !submitDocBeforeDate ||
                !cC ||
                !cbDate ||
                !reportingTime ||
                !cTC ||
                !ctcPerAnnum ||
                !doJoining ||
                !branches ||
                !state
                  ? true
                  : false
              }
            >
              Preview
            </Button>
            <Button
              onClick={handleSendEmail}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
              disabled={
                !submitDocBeforeDate ||
                !cC ||
                !cbDate ||
                !reportingTime ||
                !cTC ||
                !ctcPerAnnum ||
                !doJoining ||
                !branches ||
                !state
                  ? true
                  : false
              }
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
      {flag ? (
        <Dialog
          open={flag}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          style={{
            width: "-webkit-fill-available",
            textAlign: "left",
            alignItems: "left",
            justifyContent: "center",
          }}
          // fullScreen={fullScreen}
        >
          <DialogTitle
            id="responsive-dialog-title"
            style={{ textAlign: "center", backgroundColor: "primary" }}
          >
            Offer Letter Preview
          </DialogTitle>
          {/* <DialogContent
            >
                { academics == "True" || designation == "Business Development Associate" || designation == "Branch Counsellor" ?
              <DialogContentText
                id="responsive-dialog-description"
                tabIndex={-1}
              >
                 <p>from: {academics == "True" ? "offers@orchids.edu.in" : "salesoffer@orchids.edu.in"}</p>
                <p>to: {candidateEmail != '' ? candidateEmail : candidateDetails?.applicant?.user?.email}</p>
                <p>cc:<ul>{cC.map((item) => <li>{item}</li>)}</ul></p>
                <p><b>Dear {candidateName != '' ? candidateName : candidateDetails?.applicant?.user?.first_name}</b>,</p>
                <p><b>Congratulations!! </b></p>
                { academics == "True" ?
                <>
                <p>This is with reference to your application and subsequent interviews you had with us.</p>
                <p>We would like to offer you the position of <b>{designation != '' ? designation : candidateDetails?.applicant?.applicant_role?.applicant_role_name}</b> with <b>{'ORCHIDS - The International School' + ' - ' + branches + ',' + location}.</b></p>
                <p>This offer<b> (subject to you joining us) on {joiningDate} </b> will carry an annual CTC of <b>INR {selectedCtcDigits}/- ({selectedCtcWords} per annum)</b>.</p>
                </>
                :
                <>
                <p>This is with reference to your application and subsequent interviews you had with us.</p>
                <p>We would like to offer you the position of <b>{designation != '' ? designation : candidateDetails?.applicant?.applicant_role?.applicant_role_name}</b> with <b>{'ORCHIDS - The International School' + ' - ' + branches + ',' + location}.</b></p>
                <p>This offer<b> (subject to you joining us on {joiningDate}) will carry an annual CTC of <b>INR {selectedCtcDigits}/- (Rupees {selectedCtcWords} per annum)</b></b></p>
                { 
                designation == "Business Development Associate" ?
                <p><b>Additionally, you are also entitled to a Performance Linked Variable of INR 1,00,000/- (Rupees One Lakh Only) annually.</b></p>
                : 
                ""
              }
                </>
                }
                <p>The offer break-up is provided below.</p>
                <Table>
                  <TableHead style={{ border: "1px solid", textAlign: 'center', backgroundColor: 'black' }}>
                    <TableRow>
                      <TableCell align="center" style={{ border: "1px solid", color: 'white' }}><strong>Salary Details</strong></TableCell>
                      <TableCell align="center" style={{ border: "1px solid", color: 'white' }}><strong>Per Month</strong></TableCell>
                      <TableCell align="center" style={{ border: "1px solid", color: 'white' }}><strong>Per Annum</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedItem && selectedItem?.result?.salary_details?.map((item, index) =>
                      <TableRow key={index}>
                        <TableCell align="center" style={{ border: "1px solid" }}><strong>{item ? item : ''}</strong></TableCell>
                        <TableCell align="right" style={{ border: "1px solid" }}>{(selectedItem?.result?.per_month[index]) ? (selectedItem?.result?.per_month[index]) : ''}</TableCell>
                        <TableCell align="right" style={{ border: "1px solid" }}>{(selectedItem?.result?.per_annum[index]) ? (selectedItem?.result?.per_annum[index]) : ''}</TableCell>
                      </TableRow>
                    )}

                  </TableBody>
                </Table>

                <p>This is a full-time position and there will be a probationary period of <b> { academics == 'False' ? '6 Months' : '1 Year'}</b>{academics == 'True' ? ' and the notice period will be' : ''}<b>{academics == 'True' ? ' 3 Month' : ''}</b>. Your date of joining will be on <b>
                  {joiningDate} </b>. Reporting Time on the date of joining will be at <b> {reportT}</b>. Please revert with a confirmation of your acceptance of the offer and date of joining by <b> {docsDate}</b>.</p>
                  { academics == "True" ?
                  <>
                <p><b>You are required to submit the following documents on or before {submitDate}.</b></p>
                <p><b style={{  textDecoration: 'underline'}}>Please note: You are required to submit the soft copies of your documents/details using the link below by 5 pm, on the day before your joining date :</b></p>
                </>
                : 
                <>
                <p><b>You are required to submit the following documents on or before {submitDate}.</b></p>
                <p><b>* Please note: </b>You are required to submit the soft copies of your documents/details using the link below by 5 pm, on the <b>day before</b> your joining date:</p>
                </>
                    }
                                <Table>
                                    <TableHead style={{ border: "1px solid", textAlign: 'center', backgroundColor: 'yellow' }}>
                                        <TableRow>
                                            <TableCell align="center" style={{ border: "1px solid", color: 'black' }}><strong>S.No.</strong></TableCell>
                                            <TableCell align="center" style={{ border: "1px solid", color: 'black' }}><strong>Language</strong></TableCell>
                                            <TableCell align="center" style={{ border: "1px solid", color: 'black' }}><strong>Link</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            <TableRow >
                                                <TableCell align="center" style={{ border: "1px solid" }}>1</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}>English</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/Corp_WelcomeForm" target="_blank">https://bit.ly/Corp_WelcomeForm</a></TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="center" style={{ border: "1px solid" }}>2</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}>Hindi (हिन्दी)</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/WelcomeForm_Hindi" target="_blank">https://bit.ly/WelcomeForm_Hindi</a></TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="center" style={{ border: "1px solid" }}>3</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}>Marathi (मराठी)</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/Welcomeform_Marathi" target="_blank">https://bit.ly/Welcomeform_Marathi</a></TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="center" style={{ border: "1px solid" }}>4</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}>Kannada (ಕನ್ನಡ)</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/WelcomeForm_Kannada" target="_blank">https://bit.ly/WelcomeForm_Kannada</a></TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="center" style={{ border: "1px solid" }}>5</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}>Bengali (বাংলা)</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/Welcomeform_Bengali" target="_blank">https://bit.ly/Welcomeform_Bengali</a></TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="center" style={{ border: "1px solid" }}>6</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}>Tamil (தமிழ்)</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/WelcomeForm_Tamil" target="_blank">https://bit.ly/WelcomeForm_Tamil</a></TableCell>
                                            </TableRow>
                                            <TableRow >
                                                <TableCell align="center" style={{ border: "1px solid" }}>7</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}>Telugu (తెలుగు)</TableCell>
                                                <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/WelcomeForm_Telugu" target="_blank">https://bit.ly/WelcomeForm_Telugu</a></TableCell>
                                            </TableRow>
                                        

                                    </TableBody>
                                    </Table>
                                    <br />
                { designation == "Business Development Associate" || designation == "Branch Counsellor" ? " " :
                <b><p>Bring all original documents for Verification and all the photocopies should be self-attested :</p></b>
                }

                { designation == "Business Development Associate" || designation == "Branch Counsellor" ? " " :
                <ul>
                  <li>10th & 12th Passing Certificates/ Marksheets - Originals and Photocopy</li>
                  <li>Graduation certificate/ Mark sheets - Originals and Photocopy</li>
                  <li>PG certificate / Mark sheet copies - If any</li>
                  <li>Aadhaar card</li>
                  <li>Pan Card</li>
                  <li>Appraisal/Promotion/increment letter of last organization - both Original and Photocopy -- If Any</li>
                  <li>Appointment letter of the last organisation</li>
                  <li>Relieving letter of the last organisation</li>
                  <li>4 passport size colour photographs</li>
                  <li>Cancelled Cheque/bank account details proof (Joint account not allowed)</li>
                  <li>Last 3 Months Payslips and Bank Statement copy, if applicable</li>
                  <li>Universal Account Number (Related to PF, if applicable)</li>
                  <li>Employee State Insurance details from last organization (if applicable)</li>
                  <li>Latest Resume copy</li>
                  <li>Permanent Address Proof</li>
                  <li>Correspondence Address Proof</li>
                  <br />
                </ul>
                }
                <b><p>Important Points:</p></b>
                { academics == "True" ?
                <ol Type="1">
                { academics == 'False' ? <li><b> All Saturdays and Sundays will be working. Week off will be given (on rotation) between Mon-Fri. </b></li> : ''}
                  <li>Your net salary will be subject to income tax deductible at source. At the time of joining, we request you to submit your investment/tax deduction declaration in our employee HR portal (GreytHR) to ensure that TDS applicable is correctly calculated.</li>
                  <li>By accepting this offer/reporting for duty at the branch you confirm and agree that you are bound by the organization policies and rules prescribed in the employee handbook which will be available with the branch EA and your employee portal (GreytHR) and the policies and rules will come into effect from the day you report to the branch.</li>
                  <li>This offer to you has been made based on information furnished by you. However, if there is a discrepancy in the documents, certificates or information given by you, we reserve the right to terminate the employment with immediate effect with no liability for any compensation to be paid to you.</li>
                  <li>You provide us with the right to conduct background checks to validate your educational and professional credentials</li>
                  <li>Orchids The International School reserves the right to alter the salary structure components and benefits provided as per its requirement at any time, including but limited to the retention deduction, retention fund, insurance benefit, transportation benefit. However, in case such alteration is made, the gross salary of the employee will not be reduced.</li>
                  <li>All employees joining by 30th of September of a calendar year are eligible for increment and appraisal in the next calendar year of joining. Employees joining on or after 1st of October will NOT be eligible for increments and appraisal in the next appraisal cycle but will be eligible for increments in the appraisal cycle that comes after that.</li>
                  <li>In case you do not have an updated Adhaar card you will be considered as a retainer and will not be considered as a regular employee. In such a case you will work as a retainer payroll for a maximum of 2 months and if you do not provide the updated Adhaar soft copy by then, the organization will be forced to remove you from the organization and the notice period payment will be recovered from you.  Aadhaar copy should match with online adhaar records. To facilitate this, download your latest E-aadhaar copy that is not more than 1 week old and provide the same at the time of joining.</li>
                  <li>In case your work location is a school premise, it is mandatory to get the police verification done at your end within one month of joining the organization to get your salary processed. The branch EA/HR can guide you on this in case you need any help.</li>
                  <li>Subject to the terms of the letter of appointment (LOA) to be signed by the employee, the employee shall be eligible for, and the Employer shall pay to the Employee, a Retention Payment; provided that the Employee remains in continuous employment with the Employer until the end of the school Academic year as defined in the policies (usually March end for this purpose but subject to change). Except as provided in the LOA Employee shall not be eligible for, and shall not be paid, the Retention Payment if Employee’s employment with the Employer is terminated for any reason on or prior to the end of the academic year, including, without limitation, a termination due to resignation by the Employee herself or termination for Cause (as defined in the LOA) by the Employer due to grave misconduct.</li>
                  <li>Medical insurance will be provided to the employee, the employee's spouse, and a maximum of 2 dependent children. The insurance cost is an approximation of the insurance cost and can vary.</li>
                </ol>
                :
                <ol Type="1">
                  <li><b> All Saturdays and Sundays will be working. Week off will be given (on rotation) between Mon-Fri. </b></li>
                  <li>Your net salary will be subject to income tax deductible at the source. At the time of joining, we request you to submit your investment/tax deduction declaration in our employee HR portal (GreytHR) to ensure that TDS applicable is correctly calculated.</li>
                  <li>By joining the organization, you confirm and agree that you will go through organization policies and employee handbook upon joining and will follow the same. The policies will be available with the branch EA and your employee portal (GreytHR) and will come into effect from the day you report to the branch</li>
                  <li>Your offer has been made based on information furnished by you. However, if there is a discrepancy in the documents, certificates, or information given by you, we retain the right to terminate the employment with immediate effect with no liability for any compensation towards you</li>
                  <li>You provide us with the right to conduct background checks to validate your educational and professional credentials</li>
                  <li>K12 Techno Services Pvt Ltd reserves the right to alter the salary structure and components as per its requirement at any time. However, in case such alteration is made, the gross salary of the employee will not be reduced.</li>
                  <li>All employees joining by the 30th of September of a calendar year are eligible for increment and appraisal in the next calendar year of joining. Employees joining on or after the 1st of October will NOT be eligible for increments and appraisal in the next appraisal cycle but will be eligible for increments in the appraisal cycle that comes after that.</li>
                  <li>In case you have PF deduction in your salary and if you do not have an updated Aadhaar Card you will be moved to retainer payroll and will not be considered as a regular employee. In such a case you will be on retainer payroll for a maximum of 2 months and if the updated Aadhaar soft copy is not provided until then, you will be removed from the organization, and notice period payment can be recovered from you.</li>
                  <li>In case your work location is a school premise, it is mandatory to get the police verification done at your end within one month of joining the organization to get your salary processed. The branch EA/HR can guide you on this in case you need any help.</li>
                  <li>You will be doing the first 2 weeks of product training</li>
                  <li>Next 4 weeks of OJT training where you will have to contribute individually as well as a team</li>
                </ol>
                
              }
              <br />
              { academics == "True" ?
                <>
                <p><u>Contact person from HR team after joining:{hrName} ( {hrEmail}) ({hrMobile})</u></p>
                <p><u>Contact person in case there is any issue with joining formalities, induction session or any other HR-related concern after joining - {escaltionPointEmail} </u></p>
                <p><b>Congratulations and We look forward to having you On Board!!</b></p>
                </>
               :
               <>

              { designation == "Branch Counsellor" ?
              <>
                <p style={{  textDecoration: 'underline'}}><b>Contact person from HR team after joining :</b> Sowmya Arjun - <a href="sowmya.arjun@orchids.edu.in">sowmya.arjun@orchids.edu.in,</a> +91-9148685116</p>
                <p style={{  textDecoration: 'underline'}}><b>Contact person in case there is any issue with joining formalities, induction session or any other HR-related concern after joining : Escalation point 1 (Naseerudin Idris - <a href="">naseeruddin.idris@orchids.edu.in</a>), Escalation point 2 (Dhruv Sandhil - <a href="">dhruv.shandil@k12technoservices.com</a>)</b></p>
                <p><b>Congratulations and We look forward to having you On Board!!</b></p><br />
                <p><b>Thanks & Regards,</b></p>
              </>
              :
              <>
                <p><b>Please have look at the induction video showing Life in K12 & views of key persons and some testimonials of employees - <a href="https://bit.ly/K12Video">https://bit.ly/K12Video</a></b></p>
                <br />
                <p><u><b>The contact person from the HR team on the day of joining : </b> <a href="monali.ghorpade@orchids.edu.in "> Monali Ghorpade - monali.ghorpade@orchids.edu.in, </a> +91-7208028250</u></p>
    
                <p><u><b>In case of any escalations, please connect with : </b>Escalation point 1 (Naseerudin Idris - <a href="">naseeruddin.idris@orchids.edu.in</a>), Escalation point 2 (Dhruv Sandhil - <a href="">dhruv.shandil@k12technoservices.com</a>)</u></p><br />
                <p><b>Congratulations and We look forward to having you On Board!!</b></p><br />
                <p><b>Welcome aboard!!</b></p><br />
                <p><b>Thanks & Regards,</b></p>
                </>
              }
                </>
            }

              </DialogContentText>
              :
              <DialogContentText>
                <p>from: {academics == "True" ? "offers@orchids.edu.in" : "salesoffer@orchids.edu.in"}</p>
                <p>to: {candidateEmail != '' ? candidateEmail : candidateDetails?.applicant?.user?.email}</p>
                <p>cc:<ul>{cC.map((item) => <li>{item}</li>)}</ul></p>
                <p><b>Dear {candidateName != '' ? candidateName : candidateDetails?.applicant?.user?.first_name}</b>,</p>
                <p><b>Congratulations!! </b></p>
                <p>As discussed, we are pleased to consider your service for the position of <b>"{designation != '' ? designation : candidateDetails?.applicant?.applicant_role?.applicant_role_name}"</b>. You will be associated with <b>{'ORCHIDS - The International School' + ', ' + branches + ', ' + location + ' '}!!</b></p>
                <p>Your service for the organization will be as a consultant to the organization being engaged on a retainer basis. Your Date of Joining is<b> {joiningDate}.</b></p><br />
                <p>The organization shall provide you with a fixed fee of <b>Rs.</b> {cTC} (Fixed) <b>{ designation == "PRO Lead Generation (Evening)" ? "+ Rs. 5,000 Only for Evening shift (as allowance)": ""}</b><b> + Performance based Incentives + Other allowances (as applicable) for the above-mentioned period.</b></p>
                <p><b>Orchids The International School </b>shall not pay any other salary/ fee/ emoluments/ perks/ benefits/ statutory payments (except deducting TDS) to you as a retainer. You will not be eligible for any fees for holidays or vacations in the course of your association with us.</p>
                <p>Please revert with a confirmation of your acceptance of the offer and date of joining by <b> {docsDate}.</b></p>
                <b>Please note:</b> 
                <ul>
                  <li>
                  You are required to submit the soft copies of your documents/details using the link below <b>before 4:00 pm one day prior to the date of joining.</b>
                  </li>
                  <li>
                  You must complete the <b>30 working days</b> with the company to be eligible for the salary.
                  </li>
                  <li style={{backgroundColor: 'yellow'}}>
                  <b>
                  All Saturdays and Sundays will be working. Week off will be given (on rotation) between Mon-Fri.
                  </b>
                  </li>
                </ul>
                  
                <p style={{  textDecoration: 'underline'}}>Your <b>HR SPOC</b> will be calling and ensuring the completion for the same.</p>
                <Table>
                  <TableHead style={{ border: "1px solid", textAlign: 'center', backgroundColor: 'yellow' }}>
                      <TableRow>
                          <TableCell align="center" style={{ border: "1px solid", color: 'black' }}><strong>S.No.</strong></TableCell>
                          <TableCell align="center" style={{ border: "1px solid", color: 'black' }}><strong>Language</strong></TableCell>
                          <TableCell align="center" style={{ border: "1px solid", color: 'black' }}><strong>Link</strong></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                          <TableRow >
                              <TableCell align="center" style={{ border: "1px solid" }}>1</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}>English</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/Corp_WelcomeForm" target="_blank">https://bit.ly/Corp_WelcomeForm</a></TableCell>
                          </TableRow>
                          <TableRow >
                              <TableCell align="center" style={{ border: "1px solid" }}>2</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}>Hindi (हिन्दी)</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/WelcomeForm_Hindi" target="_blank">https://bit.ly/WelcomeForm_Hindi</a></TableCell>
                          </TableRow>
                          <TableRow >
                              <TableCell align="center" style={{ border: "1px solid" }}>3</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}>Marathi (मराठी)</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/Welcomeform_Marathi" target="_blank">https://bit.ly/Welcomeform_Marathi</a></TableCell>
                          </TableRow>
                          <TableRow >
                              <TableCell align="center" style={{ border: "1px solid" }}>4</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}>Kannada (ಕನ್ನಡ)</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/WelcomeForm_Kannada" target="_blank">https://bit.ly/WelcomeForm_Kannada</a></TableCell>
                          </TableRow>
                          <TableRow >
                              <TableCell align="center" style={{ border: "1px solid" }}>5</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}>Bengali (বাংলা)</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/Welcomeform_Bengali" target="_blank">https://bit.ly/Welcomeform_Bengali</a></TableCell>
                          </TableRow>
                          <TableRow >
                              <TableCell align="center" style={{ border: "1px solid" }}>6</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}>Tamil (தமிழ்)</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/WelcomeForm_Tamil" target="_blank">https://bit.ly/WelcomeForm_Tamil</a></TableCell>
                          </TableRow>
                          <TableRow >
                              <TableCell align="center" style={{ border: "1px solid" }}>7</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}>Telugu (తెలుగు)</TableCell>
                              <TableCell align="center" style={{ border: "1px solid" }}><a href="https://bit.ly/WelcomeForm_Telugu" target="_blank">https://bit.ly/WelcomeForm_Telugu</a></TableCell>
                          </TableRow>
                  </TableBody>
                </Table>
                <p style={{  textDecoration: 'underline'}}><b>Contact person from HR team after joining :</b> Sowmya Arjun - <a href="sowmya.arjun@orchids.edu.in">sowmya.arjun@orchids.edu.in,</a> +91-9148685116</p>
                <p style={{  textDecoration: 'underline'}}><b>Contact person in case there is any issue with joining formalities, induction session or any other HR-related concern after joining : Escalation point 1 (Naseerudin Idris - <a href="">naseeruddin.idris@orchids.edu.in</a>), Escalation point 2 (Dhruv Sandhil - <a href="">dhruv.shandil@k12technoservices.com</a>)</b></p>
                <p><b>Congratulations and We look forward to having you On Board!!</b></p>
                <p><b>Thanks & Regards,</b></p>
                </DialogContentText>
                }
            </DialogContent> */}
          <DialogContent>
            <div style={{ marginLeft: "50px" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <strong>
                  From:<span style={{ visibility: "hidden" }}>H</span>
                </strong>{" "}
                <div dangerouslySetInnerHTML={{ __html: emailFrom }} />
              </div>
              <br />
              <div style={{ display: "flex", flexDirection: "row" }}>
                <strong>
                  To:<span style={{ visibility: "hidden" }}>H</span>
                </strong>{" "}
                <div dangerouslySetInnerHTML={{ __html: emailTo }} />
              </div>
              <br />
              {/* <p><strong>CC:<span style={{visibility: 'hidden'}}>H</span></strong><ul>{cC.map((item) => <li>{item}</li>)}</ul></p> */}
              <strong>CC:</strong>
              {emailCc.map((tag, i) => (
                <span key={i}>
                  {i > 0 && " "}
                  <ul>
                    <li>
                      <div dangerouslySetInnerHTML={{ __html: tag }} />
                    </li>
                  </ul>
                </span>
              ))}
              <br />
              {/* CC  : <div dangerouslySetInnerHTML={{__html: emailCc}}/> */}
              {/* 
            {tags.map((tag, i) => <span key={i}>
              {i > 0 && ", "}
            <Tag tag={tag} />
            </span>)} */}

              <div style={{ display: "flex", flexDirection: "row" }}>
                <strong>
                  Subject:<span style={{ visibility: "hidden" }}>H</span>
                </strong>
                <div dangerouslySetInnerHTML={{ __html: emailSubject }} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: emailPreview }} />
            </div>
          </DialogContent>

          <DialogActions style={{ justifyContent: "space-around" }}>
            <Button
              onClick={handleClosePreview}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
                float: "left",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSendEmail}
              variant="contained"
              color="primary"
              className={classes.button}
              style={{
                marginTop: "10px",
                marginLeft: "10px",
              }}
              disabled={
                !submitDocBeforeDate ||
                !cC ||
                !cbDate ||
                !reportingTime ||
                !cTC ||
                !ctcPerAnnum ||
                !doJoining ||
                !branches ||
                !state
                  ? true
                  : false
              }
            >
              Send
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
      {loader}
    </React.Fragment>
  );
};

OfferLetter.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(OfferLetter);
