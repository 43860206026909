import React, { useState, useEffect } from "react";
import urls from "../../../url";
import axios from "axios";
import {
  Col,
  Row,
  Input,
  Select,
  Form,
  DatePicker,
  Button,
  Tag,
  Table,
  InputNumber,
  Modal,
  message,
} from "antd";
import { EyeOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { SyncOutlined } from "@ant-design/icons";

const AssignScoreNew = () => {
  const [formRef] = useForm();
  const [uploadScoreFormRef] = useForm();
  const { Option } = Select;
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [viewOpenning, setViewOpennings] = useState("");
  const [rowsPerPage] = React.useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [openAudio, setOpenAudio] = useState(false);
  const [testType, setTestType] = useState(null);
  const [roundId, setRoundId] = useState(null);
  const [testScore, setTestScore] = useState(null);
  const [applicentId, setApplicentId] = useState(null);
  const [applicentName, setApplicentName] = useState(null);
  const [gradeCheck, setGradeCheck] = useState(null);
  const [subjectCheck, setSubjectCheck] = useState(null);
  const [branch, setBranch] = useState(null);
  const [checkedLoc, setCheckedLoc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [grades, setGrades] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [applicentsList, setApplicentsList] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [branchesList, setBranchesList] = useState([]);
  const [uploadScore, setUploadScore] = useState([]);
  const [getViewDaata, setGetViewDaata] = useState([]);
  const [viewOpenningsData, setViewOpenningsData] = useState([]);

  const fetchRoleList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.designationListApi}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRoleList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchViewOpennings = (params = {}) => {
    if (params.city_id && params.city_id?.length > 0) {
      params.city_id = params.city_id?.toString();
    }
    if (params.branch_id && params.branch_id?.length > 0) {
      params.branch_id = params.branch_id?.toString();
    }
    setLoading(true);
    axios
      .get(`${urls.ViewOpenningsApi}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setViewOpenningsData(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchViewData = ({ url }) => {
    setLoading(true);
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setGetViewDaata(response?.data);
          setOpenAudio(true);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchToUploadScore = ({ url, body }) => {
    setLoading(true);
    axios
      .put(`${url}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setUploadScore(response?.data);
          message.success("Feedback Submitted Successfully");
          // formRef.resetFields();
          // getApplicentsList();
          handleClearAll();
          handleClose();
          fetchApplicentList({
            url: `${
              urls.scoreUploadApplicentsList
            }?opening_id=${viewOpenning}&round_type=${testType.toLocaleLowerCase()}&designation_fk=${RoleId}${
              startDate ? `&start_date=${startDate}` : ""
            }${
              endDate ? `&end_date=${endDate}` : ""
            }&page_size=${rowsPerPage}&page=${page}&city_id=${checkedLoc}&branch_id=${branch}&position_type_fk=${positionType}`,
          });
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchApplicentList = ({ url }) => {
    setLoading(true);
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setApplicentsList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchBranches = ({ url }) => {
    setLoading(true);
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setBranchesList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchGrades = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.newGrade}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setGrades(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSubjects = ({ url }) => {
    setLoading(true);
    axios
      .get(`${url}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSubjects(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCityList = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.cityList}`, {
        params: { ...params },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setCityList(response?.data);
        }
      })
      .catch((error) => {
        message.error("Something Went Wrong. Please Try Again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const testList = [
    {
      value: "1",
      label: "Written",
    },
    {
      value: "2",
      label: "Spoken",
    },
  ];
  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      })
      .then((response) => {
        setPositionTypesList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };
  const [positionTypesList, setPositionTypesList] = useState([]);
  const positionTypesOptions = positionTypesList?.map((each) => (
    <Option key={each?.id} value={each?.id}>
      {each?.position_type}
    </Option>
  ));
  const handleClose = () => {
    setOpen(false);
    setTestScore("");
    setRoundId("");
    setApplicentId("");
    uploadScoreFormRef.resetFields();
  };
  const handleCloseAudio = () => {
    setOpenAudio(false);
  };

  function uploadTestScore(values) {
    const data = {
      applicant: applicentId,
      // marks: 1, //HARDCODED THIS FOR SOME PERSONAL REASONS
      feedback: values.feedback,
      passing_status: values.passing_status,
      round_id: roundId,
      round_type: testType.toLocaleLowerCase(),
    };
    fetchToUploadScore({
      url: urls.uploadTestScoreApi,
      body: data,
    });
  }

  useEffect(() => {
    if (gradeCheck) {
      fetchSubjects({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeCheck}`,
      });
      setSubjectCheck("");
    }
  }, [gradeCheck, fetchSubjects]);

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
      });
      setBranch("");
    }
  }, [checkedLoc]);

  useEffect(() => {
    fetchPositionTypesList();
    // fetchApplicentList({
    //   url: `${urls.scoreUploadApplicentsList}`,
    // });
  }, []);
  useEffect(() => {
    if (positionType && RoleId) {
    }
  }, [RoleId, positionType, branch, checkedLoc, subjectCheck, gradeCheck]);

  function getApplicentsList() {
    if (!positionType) {
      message.warning("Please Select Position Type");
      return;
    }
    if (!RoleId) {
      message.warning("Please Select Role");
      return;
    }
    if (!testType) {
      message.warning("Please Select Test Type");
      return;
    }
    fetchApplicentList({
      url: `${
        urls.scoreUploadApplicentsList
      }?opening_id=${viewOpenning}&round_type=${testType.toLocaleLowerCase()}&designation_fk=${RoleId}${
        startDate ? `&start_date=${startDate}` : ""
      }${
        endDate ? `&end_date=${endDate}` : ""
      }&page_size=${rowsPerPage}&page=${page}&city_id=${checkedLoc}&branch_id=${branch}&position_type_fk=${positionType}`,
    });
  }

  useEffect(() => {
    if (positionType && RoleId && testType) {
      fetchApplicentList({
        url: `${
          urls.scoreUploadApplicentsList
        }?opening_id=${viewOpenning}&round_type=${testType.toLocaleLowerCase()}&designation_fk=${RoleId}${
          startDate ? `&start_date=${startDate}` : ""
        }${
          endDate ? `&end_date=${endDate}` : ""
        }&page_size=${rowsPerPage}&page=${page}`,
      });
      setStartDate(null);
      setEndDate(null);
      setPositionType("");
      setRoleId("");
      setViewOpennings("");
    }
  }, [page]);

  function openViewModel(id) {
    if (!testType) {
      message.warning("Please Select Round Type");
      return;
    }
    fetchViewData({
      url: `${
        urls.ViewTestFileApi
      }?round_id=${id}&round_type=${testType.toLocaleLowerCase()}`,
    });
  }
  function uploadScorefunction(id, rId) {
    if (!testType) {
      message.warning("Please Select Round Type");
      return;
    }
    setRoundId(rId);
    setApplicentId(id);

    setOpen(true);
  }

  function ReAssignTest(data) {
    axios
      .get(
        `${urls.reAssignTest}?username=${data?.contact_no}&round_id=${
          data?.round_id
        }&round_type=${testType.toLowerCase()}`,
        {
          headers: {
            Authorization: `Bearer ${name.personal_info.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          message.success(response?.data);
          fetchApplicentList({
            url: `${
              urls.scoreUploadApplicentsList
            }?opening_id=${viewOpenning}&round_type=${testType.toLocaleLowerCase()}&role_id=${RoleId}${
              startDate ? `&start_date=${startDate}` : ""
            }${
              endDate ? `&end_date=${endDate}` : ""
            }&page_size=${rowsPerPage}&page=${page}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
          });
        }
      });
  }

  const fetchSearch = (e) => {
    setApplicentName(e.target.value);
  };

  useEffect(() => {
    if (applicentName) {
      // if (!positionType) {
      //   message.warning("Please Select Position Type");
      //   return;
      // }
      // if (!RoleId) {
      //   message.warning("Please Select Role");
      //   return;
      // }
      // if (!testType) {
      //   message.warning("Please Select Test Type");
      //   return;
      // }
      fetchApplicentList({
        url: `${
          urls.searchApplicantRecruiter
        }?position_id=${viewOpenning}&position_type_fk=${positionType}&designation_fk=${RoleId}&page=${page}&page_size=${rowsPerPage}&search=${applicentName}&round_type=${
          testType ? testType.toLocaleLowerCase() : ""
        }`,
      });
    }
  }, [applicentName, testType]);

  const handleClearAll = () => {
    formRef.resetFields();
    setPositionType();
    setRoleId();
    setTestType();
    setApplicentsList();
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      width: "15%",
      render: (data) => <span>{data?.name}</span>,
    },
    {
      title: "Contact No",
      key: "contact_no",
      width: "15%",
      render: (data) => <span>{data?.contact_no}</span>,
    },
    {
      title: "Branch",
      key: "branch",
      width: "15%",
      render: (data) => <span>{data?.branch}</span>,
    },
    {
      title: "Grade",
      key: "grade",
      width: "15%",
      render: (data) => <span>{data?.grade}</span>,
    },
    {
      title: "Subject",
      key: "subject",
      width: "15%",
      render: (data) => <span>{data?.subject}</span>,
    },
    {
      title: "View",
      key: "view",
      width: "10%",
      render: (data) => (
        <Tag
          color="blue"
          icon={<EyeOutlined />}
          style={{ cursor: "pointer" }}
          onClick={() => openViewModel(data?.round_id)}
        >
          View
        </Tag>
      ),
    },
    {
      title: "Upload Score",
      key: "upload_score",
      width: "15%",
      render: (data) => (
        <Tag
          color="geekblue"
          icon={<CloudUploadOutlined />}
          style={{ cursor: "pointer" }}
          onClick={() => uploadScorefunction(data?.id, data?.round_id)}
        >
          Submit Feedback
        </Tag>
      ),
    },
    // {
    //   title: "Re Assign",
    //   key: "re_assign",
    //   width: "10%",
    //   render: (data) => (
    //     <Tag
    //       color="red"
    //       style={{ cursor: "pointer" }}
    //       onClick={() => ReAssignTest(data)}
    //     >
    //       Re Assign
    //     </Tag>
    //   ),
    // },
  ];

  const handlePositionTypeChange = (value) => {
    fetchRoleList({
      position_type: value,
    });
    fetchCityList();
    setCheckedLoc("");
    setBranch("");
  };
  const handleRoleChange = (value) => {
    if (positionType && value) {
      fetchViewOpennings({
        position_type_fk: positionType,
        designation_fk: value,
        city_id: checkedLoc,
        branch_id: branch,
        grade_id: gradeCheck,
        subject_id: subjectCheck,
      });
    }
  };
  return (
    <>
      <div className="page_body">
        <Row justify="space-between">
          <Col span={18} className="page_title">
            Score Upload
          </Col>
        </Row>
        <Form
          id="scoreUploadForm"
          form={formRef}
          layout="vertical"
          onFinish={getApplicentsList}
        >
          <Row align="middle" gutter={24}>
            <Col md={6}>
              <Form.Item
                name="position_type"
                label="Position Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Position Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Position Type"
                  onChange={(e) => {
                    setPositionType(e);
                    handlePositionTypeChange(e);
                  }}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {positionTypesOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="role"
                label="Role"
                rules={[
                  {
                    required: true,
                    message: "Please Select Role",
                  },
                ]}
              >
                <Select
                  placeholder="Select Role"
                  onChange={(e) => {
                    setRoleId(e);
                    handleRoleChange(e);
                  }}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {roleList?.map((option, index) => {
                    return (
                      <Option key={index} value={option?.id}>
                        {option?.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="test_type"
                label="Test Type"
                rules={[
                  {
                    required: true,
                    message: "Please Select Test Type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Test Type"
                  onChange={(e, val) => setTestType(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {testList &&
                    testList.length !== 0 &&
                    testList.map((option, index) => {
                      return (
                        <Option key={index} value={option?.label}>
                          {option?.label}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="city" label="City">
                <Select
                  mode="multiple"
                  maxTagCount={3}
                  placeholder="Select City"
                  onChange={(e) => {
                    setCheckedLoc(e);
                    fetchViewOpennings({
                      position_type_fk: positionType,
                      designation_fk: RoleId,
                      city_id: e,
                      branch_id: branch,
                      grade_id: gradeCheck,
                      subject_id: subjectCheck,
                    });
                  }}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {cityList &&
                    cityList.length !== 0 &&
                    cityList.map((option, index) => {
                      return (
                        <Option key={index} value={option?.id}>
                          {option?.city_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item name="branch" label="Branch">
                <Select
                  mode="multiple"
                  maxTagCount={3}
                  placeholder="Select Branch"
                  onChange={(e) => {
                    setBranch(e);
                    fetchViewOpennings({
                      position_type_fk: positionType,
                      designation_fk: RoleId,
                      city_id: checkedLoc,
                      branch_id: e,
                      grade_id: gradeCheck,
                      subject_id: subjectCheck,
                    });
                  }}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {branchesList &&
                    branchesList.length !== 0 &&
                    branchesList.map((option, index) => {
                      return (
                        <Option key={index} value={option?.id}>
                          {option?.branch_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6}>
              <Form.Item
                name="opening"
                label="Opening"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Opening",
                //   },
                // ]}
              >
                <Select
                  placeholder="Select Opening"
                  onChange={(e, val) => setViewOpennings(val?.value)}
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {viewOpenningsData &&
                    viewOpenningsData.length !== 0 &&
                    viewOpenningsData.map((option, index) => {
                      return (
                        <Option key={index} value={option?.id}>
                          {option?.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col md={6}>
              <Form.Item name="date_range" label="Select Date Range">
                <DatePicker.RangePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col> */}

            <Col md={3}>
              <Button type="default" onClick={() => handleClearAll()}>
                Clear All
              </Button>
            </Col>
            <Col md={3}>
              <Button form="scoreUploadForm" htmlType="submit" type="primary">
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <Table
          columns={columns}
          dataSource={applicentsList?.result}
          pagination={{
            showSizeChanger: false,
            total: applicentsList?.count,
            onChange: (page) => {
              setPage(page);
            },
            current: page,
          }}
          scroll={{ x: "max-content" }}
          loading={loading}
          rowKey={(data) => data?.id}
        />
      </div>

      {openAudio && (
        <Modal
          title={`View  ${testType} File`}
          open={openAudio}
          onCancel={handleCloseAudio}
          footer={false}
        >
          <Row gutter={24}>
            {testType === "Written" && (
              <Col span={24}>
                {getViewDaata && getViewDaata?.fileformat === "" ? (
                  <>
                    <p style={{ fontWeight: "bold" }}>
                      Question : {getViewDaata?.filedata?.response[0]?.question}
                    </p>
                    <p>{getViewDaata?.filedata?.written_test_text}</p>
                  </>
                ) : getViewDaata?.fileformat === ".docx" ||
                  getViewDaata?.fileformat === ".jpg" ||
                  getViewDaata?.fileformat === ".png" ||
                  getViewDaata?.fileformat === ".jpeg" ? (
                  <img
                    src={
                      getViewDaata && getViewDaata.filedata.written_test_file
                    }
                    alt="crash"
                    width="100%"
                    height="80%"
                  />
                ) : getViewDaata && getViewDaata?.fileformat === ".pdf" ? (
                  <a
                    target="_blank"
                    href={
                      (getViewDaata &&
                        getViewDaata?.filedata?.written_test_file) ||
                      ""
                    }
                    onclick="window.open(this.href); return false;"
                  >
                    {" "}
                    View File
                  </a>
                ) : getViewDaata &&
                  (getViewDaata?.fileformat === ".pptx" ||
                    getViewDaata?.fileformat === ".ppt" ||
                    getViewDaata?.fileformat === ".potx" ||
                    getViewDaata?.fileformat === ".pptm" ||
                    getViewDaata?.fileformat === ".xlsx") ? (
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${(getViewDaata &&
                      getViewDaata?.filedata?.written_test_file) ||
                      ""}`}
                    width="100%"
                    height="600px"
                    frameBorder="0"
                  ></iframe>
                ) : (
                  <b>File not supported</b>
                )}
              </Col>
            )}
            {testType === "Spoken" && (
              <>
                <Col span={24} style={{ marginBottom: "10px" }}>
                  Topic Name : {getViewDaata?.filedata?.response[0]?.question}
                </Col>
                <Col span={24}>
                  {getViewDaata && getViewDaata.filedata.spoken_audio ? (
                    <audio controls preload="auto">
                      <source
                        src={getViewDaata && getViewDaata.filedata.spoken_audio}
                        type="audio/mpeg"
                      />
                    </audio>
                  ) : (
                    <b>No Audio is found</b>
                  )}
                </Col>
              </>
            )}
          </Row>
        </Modal>
      )}
      {open && (
        <Modal
          title={`Evaluate ${testType} test`}
          open={open}
          onCancel={handleClose}
          footer={[
            <Row justify="space-around">
              <Col>
                <Button type="default" onClick={handleClose}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  form="uploadScoreForm"
                  icon={
                    loading ? <SyncOutlined spin /> : <CloudUploadOutlined />
                  }
                >
                  Submit
                </Button>
              </Col>
            </Row>,
          ]}
          width={400}
        >
          <Form
            id="uploadScoreForm"
            onFinish={uploadTestScore}
            form={uploadScoreFormRef}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="passing_status"
                  label="Passing Status"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Passing Status",
                    },
                  ]}
                >
                  <Select placeholder="Passing Status">
                    <Select.Option value={true}>Shortlist</Select.Option>
                    <Select.Option value={false}>Reject</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="feedback"
                  label="Feedback"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Feedback",
                    },
                  ]}
                >
                  <TextArea
                    rows={3}
                    maxLength={300}
                    count={{
                      max: 300,
                      show: true,
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default AssignScoreNew;
