import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Form,
  Input,
  Col,
  Row,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button as AntdButton,
  Drawer as AntdDrawer,
} from "antd";

import {
  EditOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

function LinkedinScraper({ classes }) {
  const [visible, setVisible] = useState(false);
  const [form] = useForm();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: <strong>First Name</strong>,
      dataIndex: "firstName",
      key: "firstName",
      align: "center",
    },
    {
      title: <strong>Last Name</strong>,
      dataIndex: "lasstName",
      key: "lastName",
      align: "center",
    },
    {
      title: <strong>Education</strong>,
      dataIndex: "education",
      key: "education",
      align: "center",
    },
    {
      title: <strong>Experience</strong>,
      dataIndex: "experience",
      key: "experience",
      align: "center",
    },
    {
      title: <strong>Location</strong>,
      dataIndex: "location",
      key: "location",
      align: "center",
    },
    {
      title: <strong>Action</strong>,
      key: "action",
      render: (data) => (
        <AntdSpace size="">
          <AntdTag
            icon={<EditOutlined />}
            color="warning"
            style={{ cursor: "pointer" }}
            // onClick={() => this.onUpdate(data.id)}
          >
            Edit
          </AntdTag>

          <AntdPopconfirm
            title="Sure to delete?"
            // onConfirm={(e) => this.onDelete(data.id, e)}
          >
            <AntdTag
              icon={<CloseCircleOutlined />}
              color="error"
              style={{ cursor: "pointer" }}
            >
              Delete
            </AntdTag>
          </AntdPopconfirm>
        </AntdSpace>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>LinkedIn Scraper</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add Details
          </AntdButton>
        </div>
      </div>

      <AntdDrawer
        title="Add Details"
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="cityForm" type="primary" htmlType="submit">
              Add
            </AntdButton>
          </div>
        }
      >
        <Form
          id="cityForm"
          form={form}
          layout="vertical"
          // onFinish={}
        >
          <Row>
            <Col md={24}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "Please enter First Name" }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "Please enter Last Name" }]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>
      <div style={{ width: "100%", padding: "10px" }}>
        {" "}
        <AntdTable columns={columns} pagination={false} />
      </div>
    </React.Fragment>
  );
}

LinkedinScraper.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default LinkedinScraper;
