import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  TextField,
  Grid,
  Button,
  Stepper,
  Step,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  StepButton,
  Card
} from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Loader from "../../../hoc/loader";
import styles from "./applicantTest.style";
import { useAlert } from "../../../hoc/alert/alert";
import Icon from "@material-ui/core/Icon";

const ApplicantTest = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [accessCode, setAccessCode] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [showPaper, setShowPaper] = useState(false);
  const [duration, setDuration] = useState(null);
  const [showTest, setShowTest] = useState(null);
  const [loder, setLoader] = useState("");
  // const [showAccess ,setShowAccess ] = useState('')
  // const [showCompleteMessage ,setShowCompleteMessage] = useState(false)
  const alert = useAlert();

  const {
    data: instructionData,
    isLoading: instructionLoading,
    doFetch: fetchInstruction
  } = useFetch(null);

  const {
    data: testData,
    isLoading: quesLoading,
    doFetch: fetchQues
  } = useFetch(null);

  const {
    data: ansResponse,
    isLoading: ansResponseLoading,
    doFetch: fetchAnsResponse
  } = useFetch(null);

  const {
    data: applicantStatusRes,
    isLoading: applicantStatusResLoading,
    doFetch: fetchapplicantStatus
  } = useFetch(null);

  useEffect(() => {
    if (auth) {
      fetchapplicantStatus({
        url: `${urls.applicantMcqTestAssignResApi}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`
        }
      });
    }
  }, [auth]);

  useEffect(() => {
    if (testData && testData.test_detail && testData.test_detail.duration) {
      const sec = +testData.test_detail.duration * 60;
      setDuration(sec);
      setShowPaper(true);
    }
  }, [testData]);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    if (showPaper && duration > 0) {
      setDuration(t => t - 1);
    }
  }, [1000]);

  useEffect(() => {
    fetchInstruction({
      url: `${urls.retrieveInstructions}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  }, [auth, fetchInstruction]);

  const sendAccesCode = () => {
    if (!accessCode) {
      alert.warning("enter access code");
      return;
    }
    setLoader(true);
    setShowTest(false);
    fetch(`${urls.checkAccessCode}?access_code=${accessCode}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    }).then(res => {
      if (res.status === 404) {
        setLoader(false);
        alert.warning("please enter valid access code");
        return;
      }
      if (res.status === 200) {
        setShowTest(true);
        setLoader(false);
        alert.success("access code verified");
      }
      if (res.status !== 200 && res.status !== 404) {
        alert.warning("somthing went wrong please try again ");
        return;
      }
    });
  };

  const startTest = id => {
    fetchQues({
      url: `${urls.applicantMcqTest}?round_id=${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  };

  function handleChange(e, ql, index) {
    const awV = [...selectedValue];
    awV[index] = true;
    setSelectedValue(awV);
    const awa = [...selectedOption];
    const ans = { id: ql.id, answer: e.target.value };
    awa[index] = ans;
    setSelectedOption(awa);
  }

  function handleClick(e, ql, index) {
    const awV = [...selectedValue];
    awV[index] = awV[index] ? false : true;
    setSelectedValue(awV);
    const awa = [...selectedOption];
    const ans = { id: ql.id, answer: e.target.value };
    awa[index] = awa[index] ? null : ans;
    setSelectedOption(awa);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  const handleStep = step => () => {
    setActiveStep(step);
  };

  function handleNext(quesLen, stepLen, id) {
    if (stepLen < quesLen.length - 1) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    if (stepLen === quesLen.length - 1) {
      // let obj = {};
      // obj = {
      //   response: selectedOption,
      //   question_id: id,
      //   round_id: localStorage.getItem("round_id")
      // };
      // fetchAnsResponse({
      //   url: `${urls.saveApplicantMcqTest}`,
      //   body: obj,
      //   method: "put",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${auth.personal_info.token}`
      //   }
      // });

      async function fetchAnsResponse() {
        let obj = {};
        obj = {
          response: selectedOption,
          question_id: id,
          round_id: localStorage.getItem("round_id")
        };
        // setdataLoading(true);
        const response = await fetch(urls.saveApplicantMcqTest, {
          method: "PUT", // or 'PUT'
          body: JSON.stringify(obj), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth.personal_info.token}`
          }
        });
        const getData = await response.json();
        // setdataLoading(false);
        return getData;
      }

      fetchAnsResponse().then(data => {
        // setDropValues(data)
        if (data === "success") {
          window.location = "./process";
        }
      });
    }
  }

  function successMsg() {
    return (
      <Grid container spacing={2}>
        <Grid item md={3} className={classes.paperMain} />
        <Grid item md={8} className={classes.paperMain}>
          {/* <Card className={classes.card}> */}
          <Typography
            variant='h4'
            style={{ color: "blue", fontFamily: "Times", marginTop: "50px" }}
          >
            You have successfully completed the Test
          </Typography>
          {/* </Card> */}
        </Grid>
      </Grid>
    );
  }

  function completedMsg() {
    return (
      <Grid container spacing={2}>
        <Grid item md={2} className={classes.paperMain} />
        <Grid item md={8} className={classes.paperMain}>
          {/* <Card className={classes.card}> */}
          <Typography
            variant='h5'
            style={{ color: "blue", fontFamily: "Times", marginTop: "50px" }}
          >
            Thank you for applying to ORCHIDs! We wish to inform you that you
            have not achieved the threshold marks required to progress to the
            next round. We do hope you apply again to our institution! Till then
            – preparation is key!
          </Typography>
          {/* </Card> */}
        </Grid>
      </Grid>
    );
  }

  function clearMessage() {
    return (
      <Grid container spacing={2}>
        <Grid item md={2} className={classes.paperMain} />
        <Grid item md={8} xs={12} className={classes.paperMain}>
          {/* <Card className={classes.card}> */}
          <Typography
            variant='h4'
            style={{ color: "blue", fontFamily: "Times", marginTop: "50px" }}
          >
            Kudos! You have succesfully cleared the MCQ round. You will receive
            a text message once the written test will be assigned.
          </Typography>
          {/* </Card> */}
        </Grid>
        <Grid item md={5} />
        <Grid item md={4} xs={12}>
          <a href='/written_test'>
            <Button
              size='large'
              color='primary'
              variant='contained'
              endIcon={<Icon>send</Icon>}
            >
              Next
            </Button>
          </a>
        </Grid>
      </Grid>
    );
  }

  function accessCodeFunc() {
    return (
      <Grid container spacing={2}>
        <Grid item md={4} xs={12} sm={12} />
        <Grid item sm={12} md={3} xs={12}>
          <TextField
            id='title-position'
            label='Enter Access Code'
            margin='dense'
            required
            fullWidth
            value={accessCode || ""}
            onChange={e => setAccessCode(e.target.value)}
            variant='outlined'
          />
        </Grid>
        <Grid item md={2} xs={12} sm={12}>
          <Button
            className={classes.applyButton}
            style={{ margin: "13px" }}
            disabled={!accessCode}
            color='primary'
            variant='contained'
            size='small'
            onClick={() => sendAccesCode()}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  }

  function instructionFunc() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item md={3} className={classes.paperMain} />
          <Grid item md={6} className={classes.paperMain}>
            <Card className={classes.card}>
              <div>
                {ReactHtmlParser(
                  instructionData && instructionData.instruction
                )}
              </div>
              <div>
                <Button
                  className={classes.applyButton}
                  style={{ marginLeft: "40%" }}
                  // color="primary"
                  variant='contained'
                  size='small'
                  onClick={() => startTest(localStorage.getItem("round_id"))}
                >
                  Start Test
                </Button>
              </div>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  }

  function getStepContent(stepIndex) {
    const optionList = testData.question_detail[stepIndex || 0].option;
    switch (stepIndex) {
      case stepIndex:
        return (
          <div>
            <div>
              {ReactHtmlParser(
                `${stepIndex + 1}${
                  testData.question_detail[stepIndex].question
                }`
              )}
            </div>
            <FormControl
              component='fieldset'
              name='radioOption'
              className={classes.formControl}
            >
              <RadioGroup
                style={{ marginLeft: "30px" }}
                aria-label='answer'
                name='option'
                className={classes.group}
                onChange={e =>
                  handleChange(
                    e,
                    testData.question_detail[stepIndex],
                    stepIndex
                  )
                }
                onClick={e =>
                  handleClick(e, testData.question_detail[stepIndex], stepIndex)
                }
              >
                {optionList.map((opt, i) => (
                  <FormControlLabel
                    checked={
                      selectedOption &&
                      selectedOption.length &&
                      selectedOption[stepIndex] &&
                      selectedOption[stepIndex].answer - 1 === i
                        ? true
                        : false
                    }
                    key={opt.id}
                    // name={ReactHtmlParser(opt)}
                    value={opt.id}
                    control={<Radio />}
                    label={ReactHtmlParser(opt.option_name)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        );
      default:
        return "Uknown stepIndex";
    }
  }

  function countDown() {
    const time = duration;
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor((time / 60) % 60);
    return (
      <Grid item sm={4} md={12} xs={12}
        style={{
          textAlign: 'center',
        }}
      >
        <Typography style={{ fontSize: '20px' }}>Remaining Time :{minutes > 10 ? <span style={{ fontSize: "40px", fontWeight: 'bold', color: 'rgb(63 81 181)' }}>{minutes}:{seconds}</span> : <span style={{ fontSize: "40px", fontWeight: 'bold', color: 'red' }}>{minutes}:{seconds}</span>}</Typography>
      </Grid>
    );
  }

  const testQuestions = () => {
    if (
      testData &&
      testData.question_detail &&
      testData.question_detail.length
    ) {
      return (
        <Grid container spacing={3}>
          <Grid item md={12} className={classes.paperMain}>
            <Card className={classes.card}>
              {countDown()}
              {countDown().props?.children?.props?.children[1]?.props?.children[0] !== 0 || countDown().props?.children?.props?.children[1]?.props?.children[2] !== 0 ?
              <>
              <Stepper activeStep={activeStep} nonLinear>
                {testData &&
                  testData.question_detail &&
                  testData.question_detail.length &&
                  testData.question_detail.map((label, i) => (
                    <Step key={label.id}>
                      <StepButton
                        onClick={handleStep(i)}
                        completed={!!selectedValue[i]}
                      />
                    </Step>
                  ))}
              </Stepper>
              <div>
                <div>{getStepContent(activeStep)}</div>
                <div>
                  <Button
                    style={{ margin: "10px" }}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <a
                    onClick={() =>
                      handleNext(
                        testData.question_detail,
                        activeStep,
                        testData.test_detail.id
                      )
                    }
                  >
                    <Button
                      variant='contained'
                      color='primary'
                      style={{
                        marginTop: "10px",
                        float:
                          activeStep === testData.question_detail.length - 1
                            ? "right"
                            : ""
                      }}
                    >
                      {activeStep === testData.question_detail.length - 1
                        ? "Finish"
                        : "Next"}
                    </Button>
                  </a>
                </div>
              </div>
              </>
              :(
              <Typography
                variant='h4'
                style={{ color: "red", fontFamily: "Nunito", marginTop: "50px", textAlign:'center'}}
              >
                Your Test Session Expired
              </Typography>
            )
          }
            </Card>
          </Grid>
          {loder}
        </Grid>
      );
    }
    return null;
  };

  let loader = null;
  if (
    loder ||
    instructionLoading ||
    quesLoading ||
    ansResponseLoading ||
    applicantStatusResLoading
  ) {
    loader = <Loader open />;
  }

  return (
    <>
      <Typography variant='h4' style={{fontWeight: 'bold'}}>Applicant Test</Typography>
      <Divider className={classes.divider} />
      {/* {applicantStatusRes && applicantStatusRes.mcq_test_assign === false ? (
        <Grid className={classes.GridText}>
          <p
            variant='h5'
            className={classes.papertext}
            style={{ color: "blue" }}
          >
            If you have any concerns please get in touch with us at{" "}
            <a href='https://www.recruitment_mumbai@gmail.com' target='_blank'>
              recruitment_mumbai@gmail.com
            </a>{" "}
            if you have applied for <strong>Mumbai</strong>,{" "}
            <strong> Pune </strong>and <strong>Kolkata</strong> positions or{" "}
            <a
              target='_blank'
              href='https://www.recruitment_bangalore@gmail.com'
            >
              recruitment_bangalore@gmail.com
            </a>{" "}
            if you have applied for Bangalore, Hyderabad and Chennai positions.
            Best of luck!
          </p>
        </Grid>
      ) : (
        ""
      )} */}
      {/* {applicantStatusRes &&
        applicantStatusRes.mcq_test_assign === true &&
        applicantStatusRes &&
        applicantStatusRes.mcq_test_completed === false &&
        instructionData === null &&
        accessCodeFunc()} */}
      {instructionData && !showPaper && !testData && instructionFunc()}
      {showPaper && !ansResponse && testQuestions()}

      {/* {ansResponse && successMsg()} */}
      {/* {applicantStatusRes &&
        applicantStatusRes.mcq_test_completed === true &&
        applicantStatusRes.mcq_test_clear === false &&
        completedMsg()}
      {applicantStatusRes &&
        applicantStatusRes.mcq_test_completed === true &&
        applicantStatusRes.mcq_test_clear === true &&
        clearMessage()}
      {applicantStatusRes &&
        applicantStatusRes.mcq_test_completed === false &&
        applicantStatusRes.mcq_test_clear === true &&
        clearMessage()} */}
      {loader}
    </>
  );
};

ApplicantTest.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};

export default withStyles(styles)(ApplicantTest);
