import React, { useState, useEffect } from "react";
import urls from "../../../url";
import { useAlert } from "../../../hoc/alert/alert";
import { Col, Form, Row, Checkbox, DatePicker, Button, Select } from "antd";
import { Option } from "antd/es/mentions";
import moment from "moment";
import { useForm } from "antd/es/form/Form";

const OfferReportNew = () => {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [offerLetterReportForm] = useForm();
  const [check, setCheck] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [bottomHRef, setBottomHRef] = useState("");
  const [isRevoked, setIsRevoked] = useState();
  const [offerOptionList] = useState([
    {
      id: 1,
      value: "released",
      children: "Released",
    },
    {
      id: 2,
      value: "revoked",
      children: "Revoked",
    },
  ]);

  const alert = useAlert();
  const handleStartDateChange = (val) => {
    setStartDate(val);
  };
  const handleEndDateChange = (val) => {
    setEndDate(val);
  };

  const handleDownloadReport = () => {
    let formData = offerLetterReportForm.getFieldsValue();

    if (startDate && endDate) {
      formData?.revoke
        ? setBottomHRef([
            {
              csv: `${
                urls.offerCsv
              }?active=${check}&start_date=${startDate}&end_date=${endDate}&is_revoked=${
                formData?.revoke == "revoked" ? true : false
              }&download=true`,
            },
          ])
        : setBottomHRef([
            {
              csv: `${urls.offerCsv}?active=${check}&start_date=${startDate}&end_date=${endDate}&download=true`,
            },
          ]);
    }
  };

  const handleChange = (event) => {
    setCheck(event.target.checked);
  };

  const offerOption = offerOptionList?.map((each) => (
    <Option key={each?.id} value={each?.value}>
      {each?.children}
    </Option>
  ));

  return (
    <>
      <Row className="page_title">Offer Letter Report</Row>
      <div className="page_body">
        <Form
          id="offerLetterReportForm"
          form={offerLetterReportForm}
          layout="vertical"
        >
          <Row align="middle" gutter={24}>
            <Col span={4}>
              <Form.Item name="active_users">
                <Checkbox checked={check} onChange={handleChange}>
                  Active Users
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[
                  {
                    required: true,
                    message: "Start Date is Required",
                  },
                ]}
              >
                <DatePicker
                  onChange={(e, val) => handleStartDateChange(val)}
                  disabledDate={(current) => {
                    return current > moment(endDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[
                  {
                    required: true,
                    message: "End Date is Required",
                  },
                ]}
              >
                <DatePicker
                  onChange={(e, val) => handleEndDateChange(val)}
                  disabledDate={(current) => {
                    return current < moment(startDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="revoke" label="Offer Letter Type">
                <Select
                  showSearch
                  allowClear
                  placeholder="Select Type"
                  style={{ width: "100%" }}
                >
                  {offerOption}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                type="primary"
                href={bottomHRef && bottomHRef[0].csv}
                disabled={startDate && endDate ? false : true}
                onClick={handleDownloadReport}
              >
                CSV
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
export default OfferReportNew;
