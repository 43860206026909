import React, { useState, useEffect } from "react";

import {
  Form,
  Input,
  Col,
  Row,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Button as AntdButton,
  Drawer as AntdDrawer,
  message,
} from "antd";

import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { useForm } from "antd/es/form/Form";
import urls from "../../../url";
import axios from "axios";

function City({}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(1);
  const [visible, setVisible] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [form] = useForm();
  const [editId, setEditId] = useState();
  useEffect(() => {
    fetchCities({ page: page, page_size: rowsPerPage });
  }, [page, rowsPerPage]);
  function fetchCities(params = {}) {
    setLoading(true);
    axios
      .get(`${urls.cityListApi}`, { params: { ...params } })
      .then((res) => {
        setCityList(res?.data?.results);
        setCount(res?.data?.count);
      })
      .catch((err) => {
        console.error("Something went wrong!", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>City Name</strong>,
      dataIndex: "city_name",
      key: "city_name",
      align: "center",
    },

    {
      title: <strong>Action</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
            <AntdPopconfirm
              title="Are you sure to delete?"
              onConfirm={() => {
                onDelete(record?.id);
              }}
            >
              <AntdTag
                icon={<EditOutlined />}
                color="error"
                style={{ cursor: "pointer" }}
              >
                Delete
              </AntdTag>
            </AntdPopconfirm>
          </AntdSpace>
        </>
      ),
    },
  ];

  function editFunction(data) {
    form.setFieldsValue({ city_name: data?.city_name });
    setVisible(true);
    setEditId(data?.id);
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  function addCity(city) {
    if (editId) {
      axios
        .put(`${urls.cityCreateUpdateApi}${editId}`, city)
        .then((res) => {
          message.success(`City updated successfully!`);
          fetchCities({
            page: page,
            page_size: rowsPerPage,
          });
          onClose();
          setEditId(null);
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? `Something went wrong!`
          );
        });
    } else {
      axios
        .post(`${urls.cityCreateUpdateApi}`, city)
        .then((res) => {
          message.success(`City created successfully!`);
          fetchCities({
            page: page,
            page_size: rowsPerPage,
          });
          setPage(1);
          onClose();
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? `Something went wrong!`
          );
        });
    }
  }

  function onDelete(id) {
    axios
      .delete(`${urls.cityCreateUpdateApi}${id}`)
      .then((res) => {
        message.success(`City deleted successfully!`);
        fetchCities({
          page: 1,
          page_size: rowsPerPage,
        });
        setPage(1);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? `Something went wrong!`);
      });
  }

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>City</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add City
          </AntdButton>
        </div>
      </div>
      <AntdDrawer
        title={(editId ? "Edit" : "Create") + " City"}
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="cityForm" type="primary" htmlType="submit">
              {editId ? "Update" : "Add"}
            </AntdButton>
          </div>
        }
      >
        <Form id="cityForm" form={form} layout="vertical" onFinish={addCity}>
          <Row>
            <Col md={24}>
              <Form.Item
                name="city_name"
                label="City Name"
                rules={[{ required: true, message: "Please enter City Name" }]}
              >
                <Input placeholder="City Name" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>

      <AntdTable
        columns={columns}
        dataSource={cityList}
        loading={loading}
        pagination={{
          current: page,
          total: count,
          pageSize: rowsPerPage,
          onChange: (current, pageSize) => {
            setPage(current);
            setRowsPerPage(pageSize);
          },
          pageSizeOptions: [25, 50, 75, 100],
        }}
      />
    </React.Fragment>
  );
}
export default City;
