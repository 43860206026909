export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    root: {
      flexGrow: 1,
    },
    addButton: {
      padding: '5px 40px',
      marginTop: '15px',
    },
    paper: {
      padding: theme.spacing(2, 4, 3),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paperr: {
      padding: theme.spacing(1, 2, 1),
    },
    paperMain: {
    },
    card: {
      minWidth: 275,
    },
    title: {
      fontSize: 14,
    },
    fixed : {
      height : '245px',
      overflowY : 'scroll',
    },
    loginButton: {
      margin: '20px 0px',
      display: 'grid',
    },
    Button: {
      borderRadius: '0px',
    },
    button: {
        margin: theme.spacing(1),
      },
      input: {
        display: 'none',
      },
      search: {
        float: 'right',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        // backgroundColor: 'gray',
        // '&:hover': {
        //   backgroundColor: 'lightgray',
        // },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(1),
          width: 'auto',
        },
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 120,
          '&:focus': {
            width: 200,
          },
        },
      },
      searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
});
