import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './viewParameter.style';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import urls from '../../../url'
import { useAlert } from '../../../hoc/alert/alert'
import Loader from '../../../hoc/loader'
import useFetch from '../../../hoc/useFetch';
import {
    Typography,
    Divider,
    Grid,
    TextField,
    MenuItem,
    Paper,
    Button,
    withStyles,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
    TableFooter,
    TablePagination,
    Modal,
    TableContainer,
} from '@material-ui/core';
import CreateParameter from './createParameter'
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import Switch from '@material-ui/core/Switch';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import MediaQuery from 'react-responsive';
import Close from '@material-ui/icons/Close';

function ViewParameter({ classes }) {
    const [auth] = useState(JSON.parse(localStorage.getItem('user')));
    const [round, setRound] = useState('');
    const [parameter, setParameter] = useState('');
    const [open, setOpen] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [page, setPage] = React.useState(0);
    const [index, setIndex] = useState('');
    const [pagination, setPagination] = useState(false);
    const [status, setStatus] = useState(false);
    const alert = useAlert();

    const {
        data: roleData,
        isLoading: roleLoading,
        doFetch: fetchRole,
    } = useFetch([], { suppressAlert: true });

    const {
        data: getParameterData,
        isLoading: viewParametersDataLoadinf,
        doFetch: fetchParameters
    } = useFetch("", { suppressAlert: true });

    const {
        data: updateParameterData,
        isLoading: updateParameterLoading,
        doFetch: fetchupdateParameters
    } = useFetch(null);
    const {
        data: deteteParameterData,
        isLoading: deteteParameterLoading,
        doFetch: fetchdeteteParameter
    } = useFetch(null);

    useEffect(() => {
        if (auth) {
            fetchRole({
                url: urls.getRoundMappingApi,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.personal_info.token}`,
                }
            });
        }
    }, [fetchRole, auth]);
    let loader = null;
    if (roleLoading || viewParametersDataLoadinf || updateParameterLoading || deteteParameterLoading) {
        loader = <Loader open />;
    }
    function getParameter() {
        if (!round) {
            alert.warning('Fill all the fields')
            return
        }
        fetchParameters({
            url: `${urls.viewParameterApi}?round_id=${round}&page_size=${rowsPerPage || 50}&page=${page + 1}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.personal_info.token}`,
            }
        });
    }

    function editParameter(i, e) {
        setOpen(true)
        setParameter((getParameterData && getParameterData.results.length && getParameterData.results[i].parameter.parameter) || '')
        setStatus(!(getParameterData && getParameterData.results.length && getParameterData.results[i].is_delete))
        setIndex(e)
    }

    // function deteteParameter(e) {
    //     setPage(0)
    //     fetchdeteteParameter({
    //         url: `${urls.updateParameterApi}${e}/retrieve_update_round_wise_parameters/`,
    //         method: 'DELETE',
    //         headers: {
    //             Authorization: `Bearer ${auth.personal_info.token}`,
    //             'Content-Type': 'application/json',
    //         }
    //     });
    // }

    function handleChangePage(event, newPage) {
        setPagination(true)
        setPage(newPage)
        { !rowsPerPage && setRowsPerPage(50) }
    }

    function handleChangeRowsPerPage(event) {
        setPagination(true)
        setRowsPerPage(event.target.value);
        setPage(0);
    }
    function firstPageChange() {
        setPagination(true)
        setPage(0)
    }

    function lastPageChange(lastPage) {
        setPagination(true)
        setPage(lastPage)
    };

    useEffect(() => {
        if (pagination || deteteParameterData || updateParameterData) {
            getParameter()
        }
    }, [page, rowsPerPage, deteteParameterData, updateParameterData])

    function updateParameter() {
        setOpen(false)
        setPage(0)
        if (!round) {
            alert.warning('Select Round')
            return;
        } else if(!parameter){
            alert.warning('Enter Parameter')
            return;
        }
        const UpdateData = {
            'parameter_name': parameter,
            'is_delete': status === true ? false : true
        }
        fetchupdateParameters({
            url: `${urls.updateParameterApi}${index}/retrieve_update_round_wise_parameters/`,
            method: 'PUT',
            body: UpdateData,
            headers: {
                Authorization: `Bearer ${auth.personal_info.token}`,
                'Content-Type': 'application/json'
            }
        });
    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <React.Fragment>
          <MediaQuery minWidth={451}>  <div> <b style={{ fontSize: '36px' }}>F2F Parameters</b> <b style={{ float: 'right', fontSize: '20px' }}><CreateParameter /></b> </div></MediaQuery>
          <MediaQuery maxWidth={450}>  <div> <b style={{ fontSize: '24px' }}>F2F Parameters</b> <b style={{ float: 'right' }}><CreateParameter /></b> </div></MediaQuery>
            <Divider className={classes.divider} />
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            label="Round"
                            // className={classes.textField}
                            fullWidth
                            className="textList"
                            margin="dense"
                            required
                            onChange={e => setRound(e.target.value)}
                            select
                            value={round || ''}
                            variant="outlined"
                        >
                            {roleData
                                ? (roleData.map(data => (
                                    <MenuItem
                                        value={data.id}
                                        key={data.id}
                                        name={data.round_name}
                                    >
                                        {data.round_name ? data.round_name : ''}
                                    </MenuItem>
                                )))
                                : (<MenuItem
                                    value='0'
                                    key='0'
                                    name='None'
                                >
                                    None
                </MenuItem>)
                            }
                        </TextField>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        {round && <Button
                            className={classes.applyButtonModal}
                            color="primary"
                            fullWidth
                            variant="contained"
                            size="large"
                            onClick={() => getParameter(setPage(0))}
                        >
                            Get Parameter
                  </Button>}
                    </Grid>
                </Grid>
            </Paper>
            <Divider className={classes.divider} />
            {getParameterData && getParameterData.results.length === 0 ? <h1 align="center" style={{ marginTop: '10%', textAlign: 'center', color: 'blue' }}>Records Not Found</h1> : ''}
            {getParameterData && getParameterData.results.length !== 0 && <Paper>
                <React.Fragment>
                    <TableContainer className="role">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" style={{ border: "1px solid black"}}><strong>S.No.</strong></TableCell>
                                <TableCell align="center" style={{ border: "1px solid black"}}><strong>Parameter Name</strong></TableCell>
                                <TableCell align="center" style={{ border: "1px solid black"}}><strong>Status</strong></TableCell>
                                <TableCell align="center" style={{ border: "1px solid black"}}><strong>Update</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getParameterData && getParameterData.results.map((data, i) => (
                                <TableRow key={data.id}>
                                    <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                                    <TableCell align="center" style={{ border: "1px solid black"}}>
                                        {(data.parameter && data.parameter.parameter) || ''}
                                    </TableCell>
                                    <TableCell align="center" style={{ border: "1px solid black"}}>
                                        {/* <Button
                                            className={classes.addButton}
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={() => deteteParameter(data.id)}
                                        >
                                            In Active
                                          </Button> */}
                                          {data.is_delete ?  
                                            <CancelIcon style={{ color: 'red' }} /> :
                                            <CheckCircleRoundedIcon style={{ color: 'green' }} /> 
                                          }
                                    </TableCell>
                                    <TableCell align="center" style={{ border: "1px solid black"}}>
                                        <Button
                                            className={classes.addButton}
                                            color="primary"
                                            size="small"
                                            variant="contained"
                                            onClick={() => editParameter(i, data.id)}
                                        >
                                            Update
                                       </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={6}
                                labelDisplayedRows={() => `Page ${page + 1} of ${+getParameterData.total_pages}`}
                                rowsPerPageOptions={[50, 75, 100]}
                                count={+getParameterData.count}
                                rowsPerPage={rowsPerPage || 50}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'Rows per page' },
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                            <TableCell style={{ marginTop: '13px' }}>
                                <IconButton
                                    onClick={firstPageChange}
                                    disabled={page === 0 || page === 1}
                                >
                                    <FirstPageIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => lastPageChange(getParameterData.total_pages - 1)}
                                    disabled={page === (+getParameterData.total_pages - 1)}
                                >
                                    <LastPageIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                    </TableContainer>
                </React.Fragment>
            </Paper>}

            <React.Fragment>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    // onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    style={{width:"80%",marginLeft:"10%"}}
                >
                    <Fade in={open}>
                        <div className={classes.paperr}>
                            <React.Fragment>
                                {/* <Typography variant='h4' className="headings" ><strong>Update F2F Parameter</strong></Typography> */}
                                <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h4">
                    <strong> Update F2F Parameter</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
                                <Divider className={classes.divider} />
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Parameter"
                                            margin="dense"
                                            fullWidth
                                            value={parameter || ''}
                                            onChange={e => setParameter(e.target.value)}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                    <Typography>Set This Parameter as Active/InActive :</Typography>
                                        In Active
                                        <Switch 
                                            checked={status|| false}
                                            value={status|| false}
                                            onChange={(e)=> setStatus(e.target.checked)}
                                            color="primary"
                                        />Active
                                    </Grid>
                                    <Grid container align="center" justify="center">
                                    <Grid item md={3} xs={12}>
                                        <Button
                                            // className={classes.applyButtonModal1}
                                            color="primary"
                                            fullWidth
                                            variant="contained"
                                            size="large"
                                            onClick={() => updateParameter()}
                                        >
                                            Update
                                       </Button>
                                    </Grid>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.divider} />
                            </React.Fragment>
                        </div>
                    </Fade>
                </Modal> 
            </React.Fragment>
            {loader}
        </React.Fragment>
    );
}
ViewParameter.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(ViewParameter);