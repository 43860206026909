import React from 'react';
import {
  Button
} from '@material-ui/core';
import MicOutlinedIcon from '@material-ui/icons/MicOutlined';
const audioType = 'audio/*';

class RecordingAPI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recording: false,
      audios: [],
      timeLeft: 62,
    };
  }

  async componentDidMount() {
    const stream = await navigator.mediaDevices.getUserMedia({audio: {
        echoCancellation: true,
        noiseSuppression: true
    }});
    this.audio.srcObject=stream;
    this.mediaRecorder = new MediaRecorder(stream);
    this.chunks = [];
    this.mediaRecorder.ondataavailable = e => {
      if (e.data && e.data.size > 0) {
        this.chunks.push(e.data);
      }
    };
  }

  startRecording(e) {
    e.preventDefault();
    this.chunks = [];
    if (!this.mediaRecorder) {
       alert('Allow your microphone');
       return;
    }
    this.mediaRecorder.start(10);
    this.setState({recording: true});
    setInterval(() => {
      this.state.timeLeft && this.setState({timeLeft: this.state.timeLeft - 1});
      this.state.timeLeft === 1 && this.saveAudio();
    }, 1000)
  }

  stopRecording() {
    // preventDefault();
    // this.mediaRecorder.stop();
    this.setState({recording: false});
    this.saveAudio();
  }

  saveAudio() {
    const blob = new Blob(this.chunks, {type: audioType});
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => {
      const base64AudioMessage = reader.result.split(',')[1];
      this.props.getAudio(base64AudioMessage)
    }
    const audioURL = window.URL.createObjectURL(blob);
    const audios = this.state.audios.concat([audioURL]);
    this.setState({audios});
  }

  deleteAudio(audioURL) {
    const audios = this.state.audios.filter(a => a !== audioURL);
    this.setState({audios});
  }

  render() {
    const {recording, audios} = this.state;

    return (
      <div>
        <audio
          style={{width: 400}}
          ref={a => {
            this.audio = a;
          }}>
         <p>Audio stream not available. </p>
        </audio>
        <div>
          {recording && this.state.timeLeft ? this.state.timeLeft - 2 : ''}
        </div>
        <div>
          {!recording && <Button onClick={e => this.startRecording(e)} variant="contained" style={{backgroundColor:"red",color:'white'}}>Record<MicOutlinedIcon/></Button>}
          {recording && this.state.timeLeft !== 0 ? <Button disabled >Recording...</Button> : ''}
        </div>
        <div>
          {audios && audios.length ? <h3>Play</h3> : ''}
          {audios.map((audioURL, i) => (
            <div key={`audio_${i}`}>
              <audio style={{ width: '400px', boxShadow: '5px 5px 20px rgba(0,0, 0, 0.4)', transform: 'scale(1.05)', borderRadius: '80px solid black' }} controls src={audioURL} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default RecordingAPI
