import React, { useState, useEffect } from "react";
import urls from "../../url";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Card,
  Col,
  Row,
  Statistic,
  Button,
  DatePicker,
  Form,
  message,
} from "antd";
import {
  FileSearchOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import { useForm } from "antd/es/form/Form";

const OverViewDashboard = () => {
  const { RangePicker } = DatePicker;
  const [formRef] = useForm();
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    formRef.setFieldsValue({
      date_range: [dayjs(moment().subtract(6, "days")), dayjs(moment())],
    });
    fetchMRFDashboardStatus();
  }, []);

  const fetchMRFDashboardStatus = () => {
    setLoading(true);
    const values = formRef.getFieldsValue();
    console.log(values, "values");
    let start_date, end_date;
    if (values?.date_range) {
      start_date = values?.date_range[0].format("YYYY-MM-DD");
      end_date = values?.date_range[1].format("YYYY-MM-DD");
    }
    const formDataParams = {
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null,
    };
    if (end_date) {
      const endDateMoment = moment(end_date);
      const startDateMoment = endDateMoment.clone().subtract(6, "days");
      const datesList = [];
      while (startDateMoment <= endDateMoment) {
        datesList.push(startDateMoment.format("YYYY-MM-DD"));
        startDateMoment.add(1, "day");
      }
      setDates(datesList);
      console.log(datesList, "datesList");
    }
    axios
      .get(`${urls.applicantDashboard}`, {
        headers: {
          Authorization: `Bearer ${userData?.personal_info?.token}`,
        },
        params: formDataParams,
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          message.success("Data Fetched Successfully");
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFilter = () => {
    fetchMRFDashboardStatus();
  };

  const jobCountPieOptions = {
    chart: {
      type: "pie",
      height: 200,
    },
    title: {
      text: "",
      align: "left",
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        "Count: <b>{point.y}</b><br/>",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.y}",
          distance: 20,
        },
      },
    },
    series: [
      {
        animation: {
          duration: 2000,
        },
        colorByPoint: true,
        data: [
          {
            name: "Not Live Jobs",
            y:
              dashboardData?.job_posted_cnt -
              dashboardData?.job_posted_live_cnt,
          },
          {
            name: "Live Jobs",
            y: dashboardData?.job_posted_live_cnt,
          },
        ],
        colors: ["#02C9D6", "#1E4DB6"],
      },
    ],
  };

  const candidatePieOptions = {
    chart: {
      type: "pie",
      height: 200,
    },
    title: {
      text: "",
      align: "left",
    },
    tooltip: {
      headerFormat: "",
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        "Count: <b>{point.y}</b><br/>",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.y}",
          distance: 20,
        },
      },
    },
    series: [
      {
        animation: {
          duration: 2000,
        },
        colorByPoint: true,
        data: [
          {
            name: "In Process",
            y: dashboardData?.candidate_in_process_cnt,
          },
          {
            name: "Hired",
            y: dashboardData?.candidate_hired_cnt,
          },
          {
            name: "Rejected",
            y: dashboardData?.candidate_rejected_cnt,
          },
        ],
        colors: ["#1E4DB6", "#008000", "#FF0000"],
      },
    ],
  };

  const candidateSourceChartOptions = {
    title: {
      text: "Candidates Sourced Over Time",
      align: "left",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      categories: dates.map((date) => moment(date).valueOf()),
      labels: {
        formatter: function() {
          return moment(this.value).format("DD-MM-YYYY");
        },
      },
    },
    yAxis: {
      title: {
        text: "Number of Candidates",
      },
    },

    series: [
      {
        name: "Candidates Sourced",
        data: [1, 200, 1, 4, 500, 60],
      },
    ],
  };

  return (
    <div className="">
      <Row>
        <Col className="page_title">Applicants OverView</Col>
      </Row>
      <Form
        id="filterForm"
        layout="vertical"
        form={formRef}
        onFinish={() => handleFilter()}
        style={{ marginBottom: "10px" }}
      >
        <Row justify="" align="middle" gutter={24}>
          <Col md={6}>
            <Form.Item name="date_range" label="Start Date - End Date">
              <RangePicker allowClear={false} />
            </Form.Item>
          </Col>

          <Button
            form="filterForm"
            htmlType="submit"
            type="primary"
            className="button primary"
            style={{ marginLeft: "20px" }}
          >
            Filter
          </Button>
        </Row>
      </Form>
      <Row gutter={[16, 16]}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          <Card>
            <Card style={{ background: "#dae9ff" }}>
              <Statistic
                title={
                  <Row>
                    <Col span={20}>Total Candidates Applied</Col>
                    <Col span={4} style={{ textAlign: "end" }}>
                      <UserOutlined
                        style={{ fontSize: "24px", color: "#1677ff" }}
                      />
                    </Col>
                  </Row>
                }
                value={dashboardData?.candidate_applied_cnt}
                valueStyle={{ color: "#1677ff" }}
              ></Statistic>
            </Card>
            <HighchartsReact
              highcharts={Highcharts}
              options={candidatePieOptions}
            />
          </Card>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          <Card>
            <Card style={{ background: "#F8F0FF" }}>
              <Statistic
                title={
                  <Row>
                    <Col span={20}>Total Job Count</Col>
                    <Col span={4} style={{ textAlign: "end" }}>
                      <TeamOutlined
                        style={{ fontSize: "24px", color: "#521DA9" }}
                      />
                    </Col>
                  </Row>
                }
                value={dashboardData?.job_posted_cnt}
                valueStyle={{
                  color: "#521DA9",
                }}
              ></Statistic>
            </Card>

            <HighchartsReact
              highcharts={Highcharts}
              options={jobCountPieOptions}
            />
          </Card>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <Card style={{ background: "#DAE8FF" }}>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Interview Scheduled Count</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{
                        fontSize: "24px",
                        color: "#1677ff",
                      }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardData?.interview_schld_cnt}
              valueStyle={{ color: "#1677ff" }}
            ></Statistic>
          </Card>
          <Card style={{ background: "#e9fce0" }}>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Average Hiring Rate</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{
                        fontSize: "24px",
                        color: "#52C41A",
                      }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardData?.avg_hire_rate}
              valueStyle={{ color: "#52C41A" }}
            ></Statistic>
          </Card>
          <Card style={{ background: "#F8EADC" }}>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Average Rejected Rate</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{
                        fontSize: "24px",
                        color: "#fa8c16",
                      }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardData?.avg_rejected_rate}
              valueStyle={{ color: "#fa8c16" }}
            ></Statistic>
          </Card>
        </Col>

        {/* <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
        >
          <Card>
            <HighchartsReact
              highcharts={Highcharts}
              options={candidateSourceChartOptions}
            />
          </Card>
        </Col> */}
      </Row>
    </div>
  );
};

export default OverViewDashboard;
