import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
    Typography,
    Divider,
    withStyles,
    Grid,
    Button,
    Paper
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import styles from './demoTest.style';
import { useAlert } from '../../../hoc/alert/alert'




const DemoTest = ({ classes }) => {
    const [name] = useState(JSON.parse(localStorage.getItem('user')));
    const [resume, setResume] = useState('');
    const [audio, setAudio] = useState('');
    const [loaderd, setLoader] = useState(false);
    // const [showTopic, setShowTopic] = useState(true);



    const alert = useAlert();

    const {
        data: TopicRes,
        isLoading: TopicResLoading,
        doFetch: fetchTopicResponse,
    } = useFetch(null);

    useEffect(() => {
        if (name) {
            fetchTopicResponse({
                url: urls.getTopicApiOCFPDemo,
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${name.personal_info.token}`,
                },
            })
        }
    }, [name])

    function successMsg() {
        return (
            <Grid container spacing={2}>
                <Grid item md={12} XS={12} className={classes.paperMain}>
                    {/* <Card className={classes.card}> */}
                    <Typography variant="h4" style={{ color: 'blue', fontFamily: "Times", marginTop: '50px', textAlign:'center' }}>You have successfully completed the Test</Typography>
                    {/* </Card> */}
                </Grid>
            </Grid>
        )
    }

    const complete = () => {
        if (!resume) {
            alert.warning('Upload and Resume');
            return;
        }
        if (!audio) {
            alert.warning('Upload and Audio');
            return;
        }

        const formData = new FormData();
        formData.append('presentation_file', resume);
        formData.append('audio_file', audio);

        setLoader(true)

        fetch(`${urls.submitTopicApi}${TopicRes && TopicRes.id}/retrieve_update_ocfp_demo_test/`, {
            method: 'PUT',
            body: formData,
            headers: {
                Authorization: `Bearer ${name.personal_info.token}`,
            },
        }).then(res => {
            if (res.status === 200) {
                setLoader(false)
                setAudio('');
                setResume('');
                alert.success('Successfully Submitted');
                fetchTopicResponse({
                    url: urls.getTopicApiOCFPDemo,
                    method: 'Get',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${name.personal_info.token}`,
                    },
                })
            }
            if (res.status !== 200) {
                alert.warning('somthing went wrong please try again ')
                return
            }
        })
    };

    var loader = null;
    if (TopicResLoading || loaderd) {
        loader = <Loader open />;
    }

    function handleResume(data){
        if (data.type === 'application/msword' || data.type === 'application/pdf' || data.type === 'image/jpeg') {
            setResume(data);
        }else {
            alert.warning('upload resume format: doc/pdf/jpeg');
            return;
        }
    }
    function handleAudio(data) {
        if (data.type === 'audio/mp3') {
            setAudio(data);
        }else {
            alert.warning('upload audio format: mp3')
            return;
        }
    }

    return (
        <React.Fragment>
            {/* {TopicRes && TopicRes === "test completed" && <Typography variant="h4" style={{textAlign:"center", color:'blue', marginTop:'50px'}}>Test is Already completed</Typography>} */}
            {TopicRes && TopicRes === "demo test is not assigned" && <Typography variant="h4" style={{textAlign:"center", color:'blue', marginTop:'50px'}}>Demo Test is Not Assigned</Typography>}
            {(TopicRes && TopicRes !== "test completed" && TopicRes !== "demo test is not assigned") && 
            <>
                <Grid item sm={12} md={12} xs={12}>
                    <Typography variant="h4">Demo Test</Typography>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container spacing={2} className={classes.modalPaper}>
                    <Grid item md={12} xs={12} sm={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={1}>
                                <Grid item md={12} xs={12}>
                                <Typography variant="h4" style={{color:'blue'}}>Topic: {TopicRes && TopicRes.topic_name.topic_name}</Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid item md={12} xs={12}>
                        <Paper className={classes.UploadPaper}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                        <Typography className={classes.label} variant="h6" noWrap>
                            Upload Resume (format: doc/pdf/jpeg)
                        </Typography>
                        <input
                            className={classes.fileUpload}
                            type='file'
                            id="outlined-button-file"
                            accept=".ppt,.pptx,.pdf"
                            onChange={(e) => handleResume(e.target.files[0])}
                        />
                        <label htmlFor="outlined-button-file">
                        <Button
                            variant="contained"
                            component="span"
                            size="small"
                            color='primary'
                            startIcon={<CloudUploadIcon />}
                        >
                            Browse
                        </Button>
                        {' '}
                        &nbsp;&nbsp;
                        {resume && resume.name}
                        </label>
                        </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography className={classes.label} variant="h6" noWrap>
                            Upload Audio (format: mp3)
                       </Typography>
                        <input
                            className={classes.fileUpload}
                            type='file'
                            id="outlined-button-audio"
                            accept=".mp3"
                            onChange={e => handleAudio(e.target.files[0])}
                        />
                         <label htmlFor="outlined-button-audio">
                            <Button
                            variant="contained"
                            component="span"
                            size="small"
                            color='primary'
                            startIcon={<CloudUploadIcon />}
                            >
                            Browse
                            </Button>
                            {' '}
                            &nbsp;&nbsp;
                            {audio && audio.name}
                        </label>
                        </Grid>

                    <Grid item sm={12} md={12} xs={12}>
                        <Button
                            onClick={complete}
                            className={classes.completeAppForm}
                            variant="contained"
                            color="primary"
                        >Upload</Button>
                    </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                </>
            }
            {TopicRes === "test completed" && successMsg()}

            {loader}
        </React.Fragment>
    )
}

DemoTest.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(DemoTest);