import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Table as AntdTable,
  Space as AntdSpace,
  Tag as AntdTag,
  Button as AntdButton,
  Drawer as AntdDrawer,
  Select as AntdSelect,
  Card,
  message,
  Popconfirm,
} from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import urls from "../../../url";
import axios from "axios";

function Branch({ classes }) {
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(1);
  const [multipleCities, setMultipleCities] = useState([]);
  const [visible, setVisible] = useState(false);
  const [branchList, setBranchList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [form] = useForm();
  const [editId, setEditId] = useState();
  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    fetchBranches({
      page: page,
      page_size: rowsPerPage,
      city_ids: multipleCities?.length > 0 ? multipleCities?.toString() : null,
    });
  }, [page, rowsPerPage, multipleCities]);

  function fetchBranches(params = {}) {
    setLoading(true);
    axios
      .get(`${urls.branchFilterListApi}`, { params: { ...params } })
      .then((res) => {
        setBranchList(res?.data?.results);
        setCount(res?.data?.count);
      })
      .catch((err) => {
        console.error("Something went wrong!", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function fetchCities() {
    axios
      .get(`${urls.cityList}`)
      .then((res) => {
        setCityList(res.data ?? []);
      })
      .catch((err) => {
        console.error("Something went wrong!", err);
      });
  }

  function handleMultipleChange(event) {
    let element = event?.slice(-1).pop();
    if (element == "all") {
      setMultipleCities(cityList?.map((each) => each?.id));
    } else if (element == "deselect_all") {
      setMultipleCities([]);
    } else {
      setMultipleCities(event);
    }
  }

  function editFunction(data) {
    setVisible(true);
    form.setFieldsValue({
      city_id: data?.city_id?.id,
      branch_name: data?.branch_name,
    });
    setEditId(data?.id);
  }

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  function addBranch(branch) {
    console.log(branch);
    if (editId) {
      axios
        .put(`${urls.createBranchApi}${editId}`, branch)
        .then((res) => {
          message.success(`Branch updated successfully!`);
          fetchBranches({
            page: page,
            page_size: rowsPerPage,
            city_ids:
              multipleCities?.length > 0 ? multipleCities?.toString() : null,
          });
          onClose();
          setEditId(null);
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? `Something went wrong!`
          );
        });
    } else {
      axios
        .post(`${urls.createBranchApi}`, branch)
        .then((res) => {
          message.success(`Branch created successfully!`);
          fetchBranches({
            page: 1,
            page_size: rowsPerPage,
            city_ids:
              multipleCities?.length > 0 ? multipleCities?.toString() : null,
          });
          setPage(1);
          onClose();
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message ?? `Something went wrong!`
          );
        });
    }
  }

  function onDelete(id) {
    axios
      .delete(`${urls.createBranchApi}${id}`)
      .then((res) => {
        message.success(`Branch deleted successfully!`);
        fetchBranches({
          page: 1,
          page_size: rowsPerPage,
          city_ids:
            multipleCities?.length > 0 ? multipleCities?.toString() : null,
        });
        setPage(1);
      })
      .catch((err) => {
        message.error(err?.response?.data?.message ?? `Something went wrong!`);
      });
  }
  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Branch Name</strong>,
      dataIndex: "branch_name",
      key: "branch_name",
      align: "center",
    },
    {
      title: <strong>City Name</strong>,
      dataIndex: "city_name",
      key: "city_name",
      align: "center",
      render: (text, record) => (
        <span>{record.city_id ? record.city_id.city_name : ""}</span>
      ),
    },

    {
      title: <strong>Update</strong>,
      key: "update",
      align: "center",
      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => {
                onDelete(record?.id);
              }}
            >
              <AntdTag
                icon={<EditOutlined />}
                color="error"
                style={{ cursor: "pointer" }}
              >
                Delete
              </AntdTag>
            </Popconfirm>
          </AntdSpace>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <div className="page_title" style={{}}>
          <strong>Branch</strong>
        </div>

        <div>
          <AntdButton
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add Branch
          </AntdButton>
        </div>
      </div>

      <Card style={{ marginBottom: "10px" }}>
        <Row>
          <Col md={6}>
            <AntdSelect
              mode="multiple"
              placeholder="Select City"
              onChange={handleMultipleChange}
              value={multipleCities || []}
              style={{ width: "100%" }}
              maxTagCount={3}
              allowClear="true"
              filterOption={(input, options) => {
                return (options?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {multipleCities?.length === cityList?.length ? (
                <AntdSelect.Option key={"deselect_all"} value={"deselect_all"}>
                  Deselect All
                </AntdSelect.Option>
              ) : (
                <AntdSelect.Option key={"all"} value={"all"}>
                  Select All
                </AntdSelect.Option>
              )}
              {cityList?.map((option) => (
                <AntdSelect.Option key={option.id} value={option.id}>
                  {option.city_name}
                </AntdSelect.Option>
              ))}
            </AntdSelect>
          </Col>
        </Row>
      </Card>

      <AntdTable
        columns={columns}
        dataSource={branchList}
        pagination={{
          current: page,
          total: count,
          pageSize: rowsPerPage,
          onChange: (current, pageSize) => {
            setPage(current);
            setRowsPerPage(pageSize);
          },
          pageSizeOptions: [25, 50, 75, 100],
        }}
        loading={loading}
      />
      <AntdDrawer
        title={(editId ? "Edit" : "Create") + " Branch"}
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          onClose();
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <AntdButton
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </AntdButton>
            <AntdButton form="branchForm" type="primary" htmlType="submit">
              {editId ? "Update" : "Add"}
            </AntdButton>
          </div>
        }
      >
        <Form
          id="branchForm"
          form={form}
          layout="vertical"
          onFinish={addBranch}
        >
          <Row>
            <Col xs={24}>
              <Form.Item
                name="city_id"
                label="City"
                rules={[{ required: true, message: "Please Select City" }]}
              >
                <AntdSelect
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select City"
                >
                  {cityList?.map((option) => (
                    <AntdSelect.Option key={option.id} value={option.id}>
                      {option.city_name}
                    </AntdSelect.Option>
                  ))}
                </AntdSelect>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="branch_name"
                label="Branch Name"
                rules={[
                  { required: true, message: "Please enter Branch Name" },
                ]}
              >
                <Input placeholder="Branch Name" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>
    </React.Fragment>
  );
}

export default Branch;
