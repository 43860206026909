import React, { useState, useEffect } from 'react';
import styles from './faceToFaceInterview.style';
import PropTypes from 'prop-types';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import { useAlert } from '../../../hoc/alert/alert'
import {
  Typography,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  withStyles,
  Modal,
  Fab,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddIcon from '@material-ui/icons/Add';

const F2FQuestions = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [parameters,setParameters ] = useState('');
  const [role,setRole ] = useState('');
  const [question,setQuestion] = useState('');
  const [open ,setOpen] = useState(false);

  const {
    data: roleData,
    isLoading: roleLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });
  const {
    data:ParametersData,
    isLoading:parametersDataLoading,
    doFetch:fetchParameter
  } = useFetch([], { suppressAlert: true });
  const {
    data:createF2FquestionData,
    isLoading:createF2FquestionDataLoading,
    doFetch:fetchF2FQuestion
  } = useFetch(null);


  let loader = null;
  if (roleLoading || parametersDataLoading || createF2FquestionDataLoading) {
    loader = <Loader open />;
  }

  const handleClose = () => {
    setOpen(false);
    setParameters('')
    setQuestion('')
    setRole('')
  };

  useEffect(()=>{
    if(createF2FquestionData){
      setOpen(false);
      setParameters('')
      setQuestion('')
      setRole('')
    }

  },[createF2FquestionData])

  useEffect(() => {
    if (auth) {
      fetchRole({
        url: urls.getRoundMappingApi,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.personal_info.token}`,
        }
      });
    }
  }, [fetchRole,auth]);

  useEffect(()=>{
    if(role){
    fetchParameter({
      url: `${urls.parametersApi}?round_id=${role}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      }
    });
  }
  },[role])


  const alert = useAlert();

  function createF2FQuestions(){
    if(!role){
      alert.warning('Select Round')
      return
    } else if(!parameters){
      alert.warning('Select Parameter')
      return
    } else if(!question){
      alert.warning('Enter Question')
      return
    }
    const f2fQuestionData = {
      'question':question,
      'round_id':role,
      'parameter_id':parameters
    }

    fetchF2FQuestion({
        url:urls.createF2FquestionApi,
        method:'POST',
        body:f2fQuestionData,
        headers: {
         Authorization: `Bearer ${auth.personal_info.token}`,
         'Content-Type': 'application/json',
       }
    });
  }




  return (
    <React.Fragment>
            
           <Fab title="Add F2F Questions" color="primary" aria-label="add" className={classes.fab}  size="small" onClick={()=>{setOpen(true)}}>
             <AddIcon fontSizeSmall></AddIcon>
           </Fab>
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{width:"80%",marginLeft:"10%"}}
        >
        <Fade in={open}>
        <Paper className={classes.paper}>
          <Typography className="headings" variant='h4'><strong>Add F2F Question</strong></Typography>
           <Divider className={classes.divider} />
             <Grid container spacing={2}>
              <Grid item md={6} xs={12} >
                <TextField
                label="Round"
                // className={classes.textField}
                fullWidth
                className="textList"
                margin="dense"
                required
                onChange={e => setRole(e.target.value)}
                select
                value={role || ''}
                variant="outlined"
              >
                {roleData
                  ? (roleData.map(data => (
                    <MenuItem
                      value={data.id}
                      key={data.id}
                      name={data.round_name}
                    >
                      {data.round_name ? data.round_name : ''}
                    </MenuItem>
                    )))
                  : (<MenuItem
                      value='0'
                      key='0'
                      name='None'
                    >
                      None
                    </MenuItem>)
                }
            </TextField>
            </Grid>
            <Grid item md={6} xs={12} >
                <TextField
                label="Parameter"
                // className={classes.textField}
                fullWidth
                className="textList"
                margin="dense"
                required
                onChange={e => setParameters(e.target.value)}
                select
                value={parameters || ''}
                variant="outlined"
              >
              {ParametersData
                  ? (ParametersData.map(data => (
                    <MenuItem
                      value={data.parameter?data.parameter.id:''}
                      key={data.parameter?data.parameter.id:''}
                      name={data.parameter?data.parameter.parameter:''}
                    >
                      {data.parameter && data.parameter.parameter ? data.parameter.parameter : ''}
                    </MenuItem>
                    )))
                  : (<MenuItem
                      value='0'
                      key='0'
                      name='None'
                    >
                      None
                    </MenuItem>)
                }
            </TextField>
            </Grid>
            <Grid item md={12} xs={12} >
            <TextField
             fullWidth
             label="Question"
             multiline
             rows="5"
             value={question}
             onChange={e => setQuestion(e.target.value)}
            //  className={classes.textField}
                className="textList"
             margin="normal"
             variant="outlined"
             />
             </Grid>
            <Grid  xs={12} md={2} className={classes.applyButtonSection}>
                <Button
                  className={classes.applyButton}
                  color="primary"
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={()=>{createF2FQuestions()}}
                >
                  Add 
                </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Paper>
        </Fade>
        </Modal>
        {loader}
    </React.Fragment>
  ); 
}
F2FQuestions.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(F2FQuestions);