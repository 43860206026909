import React, { useState, useEffect } from "react";
import styles from "./createTopic.style";
import PropTypes from "prop-types";
import urls from "../../../url";
import useFetch from "../../../hoc/useFetch";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Loader from "../../../hoc/loader";
import {
  Typography,
  Divider,
  TableContainer,
  Grid,
  TextField,
  MenuItem,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TableFooter,
  withStyles,
  Modal,
  TablePagination,
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { useAlert } from "../../../hoc/alert/alert";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import CancelIcon from "@material-ui/icons/Cancel";
import { Close } from "@material-ui/icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Switch from '@material-ui/core/Switch';


const CreateTopic = ({ classes }) => {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [topicName, setTopicName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [createupdateTopic, setCreateupdateTopic] = React.useState("");
  const [index, setIndex] = useState(null);
  // const [grade, setGrade] = useState('');
  // const [subject,setSubject] = useState('');
  const [newTopicRes, setNewTopicRes] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [EachMarksQuestion, setEachQuestionMarks] = useState("");
  const [cutOfMarks, setCutOfMarks] = useState("");
  const [fileData, setFileData] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [searchData, setSearchData] = useState(null);

  const alert = useAlert();

  const handleClose = () => {
    // setGrade('')
    setIndex("");
    // setSubject('')
    setTopicName("");
    setEachQuestionMarks("");
    setCutOfMarks("");
    setFileData("");
    setOpen(false);
    setChecked(null);
  };

  // const {
  //   data: gradeData,
  //   isLoading: gradeLoading,
  //   doFetch: fetchGrade,
  // } = useFetch(null);
  // const {
  //   data: subjectData,
  //   isLoading: subjectLoading,
  //   doFetch: fetchSubject,
  // } = useFetch(null);
  const {
    data: topicTableData,
    isLoading: topicTableDataLoding,
    doFetch: fetchTableData,
  } = useFetch("", {
    suppressAlert: true,
  });
  const {
    data: topicResponse,
    isLoading: topicResponseLoding,
    doFetch: createTopic,
  } = useFetch([], {
    suppressAlert: true,
  });
  const [deleteResponse, deleteData] = useState();

  // const {
  //   data: deleteResponse,
  //   isLoading: deleteResponceLoding,
  //   doFetch: deleteData,
  // } = useFetch([], {
  //   suppressAlert: true,
  // });

  useEffect(() => {
    if (topicResponse) {
      setNewTopicRes(topicResponse.results);
      setOpen(false);
      setEachQuestionMarks("");
      setCutOfMarks("");
      //  setGrade('');
      setIndex("");
      //  setSubject('');
      setTopicName("");
      setFileData("");
    }
    if (deleteResponse) {
      setNewTopicRes(deleteResponse);
    }
  }, [topicResponse, auth, deleteResponse]);

  useEffect(() => {
    if (newTopicRes) {
      // setGrade('')
      setIndex("");
      setTopicName("");
      setEachQuestionMarks("");
      setCutOfMarks("");
      // setSubject('')
      setNewTopicRes(null);
      setFileData("");
    }
  });

  const CreateTopicFunction = () => {
    if (!topicName || !cutOfMarks || !EachMarksQuestion) {
      alert.warning("Select All Fields");
      return;
    }
    const formData = new FormData();
    formData.append("topic_name", topicName);
    // formData.append('grade', grade);
    // formData.append('subject', subject);
    formData.append("cut_off", cutOfMarks);
    formData.append("each_topic_mark", EachMarksQuestion);
    formData.append("written_topic_excel", fileData);
    formData.append("is_active", true);
    createTopic({
      url: urls.topicCreateApi,
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  };
  function functionSearch(data){
    setSearchData(data);
  }

  useEffect(() => {
    if (createupdateTopic[index]) {
      // setGrade(createupdateTopic[index].grade && createupdateTopic[index].grade.id)
      // setSubject(createupdateTopic[index].subject && createupdateTopic[index].subject.id)
      setEachQuestionMarks(createupdateTopic[index].each_topic_mark);
      setCutOfMarks(createupdateTopic[index].cut_off);
      setFileData(createupdateTopic[index].written_topic_excel);
    }
  }, [createupdateTopic, index]);
  const updateTopicFunction = () => {
    if (
      !createupdateTopic[index].topic_name ||
      !cutOfMarks ||
      !EachMarksQuestion
    ) {
      alert.warning("Enter all fields");
      return;
    }
    const formData = new FormData();
    formData.append("id", createupdateTopic[index].id)
    formData.append("topic_name", createupdateTopic[index].topic_name);
    // formData.append('grade', grade);
    // formData.append('subject', subject);
    formData.append("cut_off", cutOfMarks);
    formData.append("each_topic_mark", EachMarksQuestion);
    formData.append("written_topic_excel", fileData);
    formData.append("is_active", checked);
    createTopic({
      url: `${urls.topicUpdateApi}${createupdateTopic[index].id}/retrieve_update_delete_written_topic/`,
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
      },
    });
  };

  const {
    data: addQuestionRes,
    isLoading: addQuesLoading,
    doFetch: addQuestion,
  } = useFetch(null);

  // useEffect(() => {
  //   setImg(addQuestionRes && addQuestionRes[0].question)
  // }, [addQuestionRes])

  function searchFunction() {
    fetchTableData({
    url: `${
    urls.topicGetApi
    }?search=${searchData || ''}`,
    method: "GET",
    headers: {
    "Content-Type": "apinterviewStatusplication/json",
    Authorization: `Bearer ${auth.personal_info.token}`,
    },
    });
    }
    
    useEffect(() => {
    if (auth) {
    searchFunction();
    }
    }, [auth, searchData]);


  useEffect(() => {
    if (auth) {
      fetchTableData({
        url: `${urls.topicGetApi}?page_size=${rowsPerPage || 50}&page=${page +
          1}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.personal_info.token}`,
        },
      });
    }
  }, [fetchTableData, auth, page, rowsPerPage, topicResponse, deleteResponse]);

  // useEffect(() => {
  //   if (auth) {
  //     fetchGrade({
  //       url: urls.gradeMapping,
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${auth.personal_info.token}`,
  //       }
  //     });
  //     // addQuestion({
  //     //   url: urls.addQues,
  //     //   method: 'get',
  //     //   // body: obj,
  //     //   headers: {
  //     //     Authorization: `Bearer ${auth.personal_info.token}`,
  //     //    'Content-Type':'application/json'
  //     //   },
  //     // })
  //   }
  // }, [fetchGrade,fetchSubject,fetchTableData, addQuestion, auth]);

  // useEffect(()=>{
  //   if(grade){
  //   fetchSubject({
  //     url: `${urls.subjectMapping}?grade_id=${grade}`,
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //     }
  //   });
  // }
  // },[grade])

  useEffect(() => {
    if (topicTableData) {
      setCreateupdateTopic(topicTableData.results);
    }
  }, [topicTableData]);

  const editFunction = (data,i) => {
    setOpen(true);
    setIndex(i);
    setChecked(data?.is_active);
  };
  // const deleteFunction = () => {
  //   fetch(`${urls.topicUpdateApi}${deleteId}/retrieve_update_delete_written_topic/`, {
  //     method: "DELETE",
  //     headers: {
  //       Authorization: `Bearer ${auth.personal_info.token}`,
  //       "Content-Type": "application/json",
  //     },
  //   }
  //   ).then((response) => response.json())
  //     .then((data) => {
  //       deleteData(data);
  //       cancelDelete();
  //       alert.success("data deleted successfully")
  //     }).catch(() => {
  //       alert.error("Failed to delete");
  //     });
  // };
  const changeHandler = (e, key) => {
    setCreateupdateTopic((createupdateTopic) => {
      const newData = [...createupdateTopic];
      newData[index][key] = e;
      return newData;
    });
  };

  function handleChangePage(event, newPage) {
    setPage(newPage);
    {
      !rowsPerPage && setRowsPerPage(50);
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
  }
  // const [deleteModel, setDeleteModel] = useState(false);
  // const [deleteId, setDeleteId] = useState();
  // function deleteTopic(data) {
  //   setDeleteModel(true);
  //   setDeleteId(data);
  // }

  // function cancelDelete() {
  //   setDeleteModel(false);
  //   setDeleteId(null);
  // }


  function modalOpen() {
    let modal = null;
    if (createupdateTopic && createupdateTopic[index]) {
      modal = (
        <React.Fragment>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            // onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            // style={{ width: "80%", marginLeft: "10%" }}
          >
            <Fade in={open} style={{ width: "70%", marginLeft: "10%" }}>
              <div className={classes.paper}>
                <React.Fragment>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{ marginBottom: 10, justifyContent: "space-between" }}
                  >
                    <Grid item>
                      <IconButton size="small" style={{ visibility: "hidden" }}>
                        <Close />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Typography className="headings" variant="h4">
                        <strong>Update Topic</strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton size="small" onClick={() => handleClose()}>
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                  {/* <Typography variant="h4">
                    {" "}
                    <strong> Update Topic</strong>
                  </Typography> */}
                  <Divider className={classes.divider} />
                  <Grid container spacing={2} style={{marginTop: "10px"}}>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Topic Name"
                        margin="dense"
                        fullWidth
                        // required
                        value={
                          (createupdateTopic &&
                            createupdateTopic.length &&
                            createupdateTopic[index].topic_name) ||
                          ""
                        }
                        onChange={(e) =>
                          changeHandler(e.target.value, "topic_name")
                        }
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Enter Topic Marks"
                        className={classes.textField}
                        margin="dense"
                        required
                        type="number"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 2);
                        }}
                        onChange={(e) =>
                          e.target.value < 51 &&
                          setEachQuestionMarks(e.target.value)
                        }
                        value={EachMarksQuestion || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        label="Enter Cut-of Marks"
                        className={classes.textField}
                        margin="dense"
                        required
                        type="number"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 3);
                        }}
                        onChange={(e) =>
                          e.target.value < 101 && setCutOfMarks(e.target.value)
                        }
                        value={cutOfMarks || ""}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                    <Typography style={{marginTop: "-15px"}}>Status</Typography>
                      In Active<Switch checked={checked || false} value={checked || false} onChange={(e) => setChecked(e.target.checked)} color='primary'/> Active
                    </Grid>
                    {fileData ?
                      (
                        <>
                          <Grid item md={8} xs={12}>
                            {typeof (fileData) === "string" ?
                              <iframe
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileData}`}
                                height="100%"
                                width="100%" />
                              : ""}
                          </Grid>
                          <Grid item md={2} xs={12} style={{ display: "contents", textAlign: "center" }}>
                            <input
                              style={{ display: "none" }}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              className={classes.fileUpload}
                              id='outlined-button-file'
                              multiple
                              type='file'
                              onChange={e => setFileData(e.target.files[0])}
                            />
                            <label htmlFor='outlined-button-file' className={classes.applyButtonSection}>
                              <Button
                                variant='contained'
                                color='primary'
                                component='span'
                                size="large"
                                className={classes.fileUpload}
                                startIcon={<CloudUploadIcon />}
                              >
                                Upload Excel
                              </Button>
                              <Button className={classes.bold}>
                                {fileData && fileData.name}
                              </Button>
                            </label>
                          </Grid>
                        </>
                      )
                      :
                      ""
                    }
                    <Grid container align="center" justify="center">
                      <Grid item md={3} xs={12} >
                        <Button
                          // className={classes.applyButtonModal}
                          color="primary"
                          fullWidth
                          variant="contained"
                          size="large"
                          onClick={() => updateTopicFunction()}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </React.Fragment>
              </div>
            </Fade>
          </Modal>
        </React.Fragment>
      );
    }
    return modal;
  }
  function tableTopicDetails() {
    let tableData = null;
    if (topicTableData && topicTableData.results.length) {
      tableData = (
        <React.Fragment>
          <TableBody>
            {topicTableData.results.map((data, i) => (
              <TableRow key={data.id}>
                <TableCell align="center" style={{ border: "1px solid black"}}>{i + 1}</TableCell>
                {/* You are responsible for customers living in France, so you are interested in retrieving information about orders originated from that country. Although, multiple different options were available, you have decided to implement RFM analysis to get some basic understanding about the customers in your segment of the company. RFM method is a basic tool allowing for a quick assessment of the customer value. RFM abbreviation represents three dimensions of the technique: recency, frequency, and monetary value. These dimensions can be described as: */}

                <TableCell align="center" style={{ border: "1px solid black"}}>{data.topic_name || ""}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.each_topic_mark || ""}</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>{data.cut_off || ""}</TableCell>
                {/* <TableCell align="center" style={{ border: "1px solid black"}}>{data.grade && data.grade.grade_name || ''}</TableCell>
                  <TableCell align="center" style={{ border: "1px solid black"}}>{data.subject && data.subject.subject_name || ''}</TableCell> */}
                <TableCell align="center" style={{ border: "1px solid black"}}>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => editFunction(data,i)}
                  >
                    Update
                  </Button>
                </TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>
                  {data.is_active ? (
                    <CheckCircleRoundedIcon style={{ color: "green" }} />
                  ) : (
                    <CancelIcon style={{ color: "red" }} />
                  )}
                </TableCell>
                {/* <TableCell align="center" style={{ border: "1px solid black"}}>
                  <Button
                    className={classes.addButton}
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => deleteTopic(data.id)}
                  >
                    In Active
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </React.Fragment>
      );
    }
    return tableData;
  }

  let loader = null;
  if (topicResponseLoding || topicTableDataLoding) {
    loader = <Loader open />;
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'space-between' }}>
      <div>
      <Typography className="headings" variant="h4"><strong>Written Topics</strong></Typography>
      </div>
      <Paper style={{ margin: '5px' }} >
          <TextField
            id="search-bar"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            label="I'm Looking For..."
            variant="outlined"
            size="small"
            name="subject"
            autoComplete="on"
            onChange={(e) => functionSearch(e.target.value)}
          />
    </Paper>
    </div>
      <Divider className={classes.divider} />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              label="Topic Name"
              margin="dense"
              fullWidth
              // required
              value={topicName}
              onChange={(e) => setTopicName(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Enter Topic Marks"
              className={classes.textField}
              margin="dense"
              required
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 2);
              }}
              onChange={(e) =>
                e.target.value < 51 && setEachQuestionMarks(e.target.value)
              }
              value={EachMarksQuestion || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Enter Cut-off Marks (%)"
              className={classes.textField}
              margin="dense"
              required
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              }}
              onChange={(e) =>
                e.target.value < 101 && setCutOfMarks(e.target.value)
              }
              value={cutOfMarks || ""}
              variant="outlined"
            />
          </Grid>
          <Grid item md={2} xs={12} style={{ display: "contents", textAlign: "center" }}>
            <input
              style={{ display: "none" }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              className={classes.fileUpload}
              id='outlined-button-file'
              multiple
              type='file'
              onChange={e => setFileData(e.target.files[0])}
            />
            <label htmlFor='outlined-button-file' className={classes.applyButtonSection}>
              <Button
                variant='contained'
                color='primary'
                component='span'
                size="large"
                className={classes.fileUpload}
                startIcon={<CloudUploadIcon />}
              >
                Upload Excel
              </Button>
              <Button className={classes.bold}>
                {fileData && fileData.name}
              </Button>
            </label>
          </Grid>
          {/* <Grid item md={4} xs={12}>
            <TextField
              label="Grade"
              className={classes.textField}
              margin="dense"
              required
              onChange={e => setGrade(e.target.value)}
              select
              value={grade || ''}
              variant="outlined"
            >
              {gradeData && gradeData && gradeData.map(data => (
              <MenuItem
                  value={data.id}
                  key={data.id}
                  name={data.grade_name}
              >
                  {data.grade_name ? data.grade_name : ''}
              </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          {/* <Grid item md={4} xs={12}>
          {grade && <TextField
                    label="Subject"
                    className={classes.textField}
                    margin="dense"
                    required
                    onChange={e =>setSubject(e.target.value)}
                    select
                    value={subject || ''}
                    variant="outlined"
                >
                    {subjectData && subjectData && subjectData.map(data => (
                    <MenuItem
                        value={data.subject.id}
                        key={data.subject.id}
                        name={data.subject.subject_name}
                    >
                        {data.subject.subject_name ? data.subject.subject_name : ''}
                    </MenuItem>
                    ))}
                </TextField>}
          </Grid> */}
          <Grid item md={2} xs={12} className={classes.applyButtonSection}>
            <Button
              className={classes.applyButton}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              onClick={() => CreateTopicFunction()}
            >
              Create Topic
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Divider className={classes.divider} />
      {topicTableData && topicTableData.results.length === 0 ? (
        <h1 style={{ textAlign: "center", marginTop: "10%", color: "blue" }}>
          No Records Found
        </h1>
      ) : (
        ""
      )}
      {topicTableData && topicTableData.results.length !== 0 && (
        <Paper>
          <React.Fragment>
            <TableContainer className="role">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>S.No.</strong></TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>Topic Name</strong></TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>Actual Marks</strong></TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}>
                      <strong> Cut-Off marks (%)</strong>
                    </TableCell>
                    {/* <TableCell align="center" style={{ border: "1px solid black"}}>Grade</TableCell>
                <TableCell align="center" style={{ border: "1px solid black"}}>Subject</TableCell> */}
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>Update</strong></TableCell>
                    <TableCell align="center" style={{ border: "1px solid black"}}><strong>Status</strong></TableCell>
                  </TableRow>
                </TableHead>
                {tableTopicDetails()}
              </Table>

              {modalOpen()}
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={6}
                    labelDisplayedRows={() =>
                      `Page ${page + 1} of ${+topicTableData.total_pages}`
                    }
                    rowsPerPageOptions={[10, 25, 50, 75, 100]}
                    count={+topicTableData.count}
                    rowsPerPage={rowsPerPage || 50}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                  <TableCell style={{ marginTop: "13px" }}>
                    <IconButton
                      onClick={firstPageChange}
                      disabled={page === 0 || page === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        lastPageChange(topicTableData.total_pages - 1)
                      }
                      disabled={page === +topicTableData.total_pages - 1}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </TableContainer>
          </React.Fragment>
        </Paper>
      )}
      {/* {deleteModel == true && deleteId &&
        <Dialog
          open={deleteModel}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id='draggable-dialog-title'
          >
            Delete Topic
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete ?
            </DialogContentText>
          </DialogContent>


          <Divider className={classes.divider} />
          <DialogActions>
            <Button onClick={cancelDelete} >
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              style={{ color: 'white' }}
              onClick={deleteFunction}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      } */}

      {loader}
    </React.Fragment>
  );
};
CreateTopic.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(CreateTopic);
