import React, { useState, useEffect } from "react";
import urls from "../../../../url";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  Card,
  Col,
  Row,
  Statistic,
  Button,
  Select,
  DatePicker,
  Form,
  message,
  AutoComplete,
} from "antd";
import {
  FileSearchOutlined,
  TeamOutlined,
  UserOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useForm } from "antd/es/form/Form";

const MrfDashboard = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [formRef] = useForm();
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  let role = userData?.personal_info?.role_id;
  let employeeId = userData?.personal_info?.user_id;
  const [employeeList, setEmployeeList] = useState([]);
  const [searchText, setSearchText] = useState();
  const [employee, setEmployee] = useState();
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [positionTypeList, setPositionTypeList] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [offerLetterDrawer, setOfferLetterDrawer] = useState(false);
  useEffect(() => {
    fetchMRFDashboardStatus();
    fetchPositionTypesList();
  }, []);

  const fetchPositionTypesList = () => {
    axios
      .get(`${urls.positionTypes}`)
      .then((response) => {
        setPositionTypeList(response?.data);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      });
  };

  const fetchMRFDashboardStatus = () => {
    setLoading(true);
    const values = formRef.getFieldsValue();
    let start_date, end_date;
    if (values?.date_range) {
      start_date = values?.date_range[0].format("YYYY-MM-DD");
      end_date = values?.date_range[1].format("YYYY-MM-DD");
    }
    const formDataParams = {
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null,
      position_type_fk:
        values?.position_type?.length > 0
          ? values?.position_type?.toString()
          : null,
    };

    if (role != 1 && role != 4) {
      formDataParams.emp_user_id = employeeId;
    } else {
      formDataParams.assigned_recruter_id = employee ?? null;
    }
    axios
      .get(`${urls.mrfDashboard}`, {
        params: formDataParams,
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          message.success("Data Fetched Successfully");
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getReport = () => {
    setReportLoading(true);
    const values = formRef.getFieldsValue();
    let start_date, end_date;
    if (values?.date_range) {
      start_date = values?.date_range[0].format("YYYY-MM-DD");
      end_date = values?.date_range[1].format("YYYY-MM-DD");
    }
    const formDataParams = {
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null,
      position_type_fk:
        values?.position_type?.length > 0
          ? values?.position_type?.toString()
          : null,
    };

    if (role != 1 && role != 4) {
      formDataParams.emp_user_id = employeeId;
    } else {
      formDataParams.assigned_recruter_id = employee ?? null;
    }
    axios
      .get(`${urls.mrfReport}`, {
        params: formDataParams,
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "mrf_report.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          message.success("Data Fetched Successfully");
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        setReportLoading(false);
      });
  };
  const getPendingReport = () => {
    setReportLoading(true);
    const values = formRef.getFieldsValue();
    let start_date, end_date;
    if (values?.date_range) {
      start_date = values?.date_range[0].format("YYYY-MM-DD");
      end_date = values?.date_range[1].format("YYYY-MM-DD");
    }
    const formDataParams = {
      start_date: start_date ? start_date : null,
      end_date: end_date ? end_date : null,
      position_type_fk:
        values?.position_type?.length > 0
          ? values?.position_type?.toString()
          : null,
    };

    if (role != 1 && role != 4) {
      formDataParams.emp_user_id = employeeId;
    } else {
      formDataParams.assigned_recruter_id = employee ?? null;
    }
    axios
      .get(`${urls.mrfPendingReport}`, {
        params: formDataParams,
      })
      .then((response) => {
        if (response?.status === 200) {
          console.log(response, "response");
          const blob = new Blob([response?.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "mrf_report.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          message.success("Data Fetched Successfully");
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        setReportLoading(false);
      });
  };

  const handleClear = () => {
    formRef.resetFields();
    fetchMRFDashboardStatus();
  };
  const handleFilter = () => {
    fetchMRFDashboardStatus();
  };

  function handleSearch() {
    if (searchText?.length > 4) {
      fetchEmployee({
        erp: searchText,
      });
    }
  }
  function fetchEmployee(params = {}) {
    setEmployeeList([]);
    if (params?.erp?.length < 4) return;
    axios
      .get(`${urls.employeeListGlobal}`, {
        params: { ...params },
      })
      .then((response) => {
        if (response?.status === 200) {
          const options = response?.data?.data;
          setEmployeeList(options);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      });
  }

  const positionTypeOptions = positionTypeList.map((each) => {
    return (
      <Select.Option key={each?.id} value={each?.id}>
        {each?.position_type}
      </Select.Option>
    );
  });

  return (
    <div className="">
      <Row>{/* <Col className="page_title">MRF Dashboard</Col> */}</Row>
      <Form
        id="filterForm"
        layout="vertical"
        form={formRef}
        onFinish={() => handleFilter()}
        style={{ marginBottom: "10px" }}
      >
        <Row justify="" align="middle" gutter={24}>
          <Col md={6}>
            <Form.Item name="date_range" label="Start Date - End Date">
              <RangePicker />
            </Form.Item>
          </Col>
          <Col md={6}>
            <Form.Item name="position_type" label="Position Type">
              <Select
                mode="multiple"
                maxTagCount={1}
                placeholder="Select Position Type"
                onChange={(e) => {
                  let element = e?.slice(-1).pop();
                  if (element == "all") {
                    formRef.setFieldValue(
                      "position_type",
                      positionTypeList?.map((each) => each?.id)
                    );
                    setSelectedPositions(
                      positionTypeList?.map((each) => each?.id)
                    );
                  } else if (element == "deselect_all") {
                    formRef.setFieldValue("position_type", []);
                    setSelectedPositions([]);
                  }
                }}
              >
                {selectedPositions?.length === positionTypeList?.length ? (
                  <Select.Option key={"deselect_all"} value={"deselect_all"}>
                    Deselect All
                  </Select.Option>
                ) : (
                  <Select.Option key={"all"} value={"all"}>
                    Select All
                  </Select.Option>
                )}
                {positionTypeOptions}
              </Select>
            </Form.Item>
          </Col>
          {(role == 1 || role == 4) && (
            <Col md={4}>
              <Form.Item name="raised_by" label="Raised By">
                <AutoComplete
                  onSearch={handleSearch}
                  placeholder="Search by ERP.."
                  onChange={(text) => {
                    setSearchText(text);
                  }}
                  onSelect={(value, obj) => {
                    setEmployee(obj?.obj?.user);
                  }}
                  allowClear
                >
                  {employeeList?.map((employee) => (
                    <Option
                      key={employee?.erp}
                      value={`${employee?.name} | ${employee?.erp}`}
                      obj={employee}
                    >
                      {employee?.name} | {employee?.erp}
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Col>
          )}
          <Button
            className="button secondary"
            style={{ marginLeft: "20px" }}
            onClick={() => handleClear()}
          >
            Clear
          </Button>
          <Button
            form="filterForm"
            htmlType="submit"
            type="primary"
            className="button primary"
            style={{ marginLeft: "20px" }}
          >
            Filter
          </Button>
          <Button
            type="primary"
            className="button primary"
            style={{ marginLeft: "20px" }}
            icon={<DownloadOutlined />}
            onClick={getReport}
            loading={reportLoading}
          >
            Get Approved Report
          </Button>
          <Button
            type="primary"
            className="button primary"
            style={{ marginLeft: "20px" }}
            icon={<DownloadOutlined />}
            onClick={getPendingReport}
            loading={reportLoading}
          >
            Get Pending/Rejected Report
          </Button>
        </Row>
      </Form>
      <Row gutter={[16, 16]}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              height: "100%",
            }}
          >
            <Card style={{ marginBottom: "20px", background: "#dae9ff" }}>
              <Statistic
                title={
                  <Row>
                    <Col span={20}>Total MRF Count</Col>
                    <Col span={4} style={{ textAlign: "end" }}>
                      <UserOutlined
                        style={{ fontSize: "24px", color: "#1677ff" }}
                      />
                    </Col>
                  </Row>
                }
                value={dashboardData?.total_mrf_count}
                valueStyle={{ color: "#1677ff" }}
              ></Statistic>
            </Card>

            <Card style={{ marginBottom: "20px", background: "#f8ebdc" }}>
              <Statistic
                title={
                  <Row>
                    <Col span={20}>Active MRF Count</Col>
                    <Col span={4} style={{ textAlign: "end" }}>
                      <TeamOutlined
                        style={{ fontSize: "24px", color: "#fa8c16" }}
                      />
                    </Col>
                  </Row>
                }
                value={dashboardData?.active_mrf_count}
                valueStyle={{
                  color: "#fa8c16",
                }}
              ></Statistic>
            </Card>

            <Card style={{ background: "#E9FCE9" }}>
              <Statistic
                title={
                  <Row>
                    <Col span={20}>Inactive MRF Count</Col>
                    <Col span={4} style={{ textAlign: "end" }}>
                      <TeamOutlined
                        style={{ fontSize: "24px", color: "#008000" }}
                      />
                    </Col>
                  </Row>
                }
                value={dashboardData?.inactive_mrf_count}
                valueStyle={{
                  color: "#008000",
                }}
              ></Statistic>
            </Card>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
        >
          <Card style={{ borderWidth: "2px" }}>
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "pie",
                  height: 270,
                },
                title: {
                  text: "MRF Counts",
                  align: "left",
                },
                tooltip: {
                  headerFormat: "",
                  pointFormat:
                    '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                    "Count: <b>{point.y}</b><br/>",
                },
                credits: {
                  enabled: false,
                },
                series: [
                  {
                    minPointSize: 10,
                    innerSize: "40%",

                    zMin: 0,
                    name: "countries",
                    borderRadius: 5,
                    data: [
                      {
                        name: "Active MRF",
                        y: dashboardData?.active_mrf_count,
                      },
                      {
                        name: "Inactive MRF",
                        y: dashboardData?.inactive_mrf_count,
                      },
                      {
                        name: "Pending MRF",
                        y: dashboardData?.pending_mrf_count,
                      },
                      {
                        name: "Rejected MRF",
                        y: dashboardData?.rejected_mrf_count,
                      },
                    ],
                    colors: ["#1E4DB6", "#02C9D6", "#E39E33", "#14BCC7"],
                  },
                ],
              }}
            />

            {/* Data retrieved from https://worldpopulationreview.com/country-rankings/countries-by-density */}
          </Card>
        </Col>

        {/* <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row style={{}}>
                  <Col span={20}>Inactive MRF Count</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <TeamOutlined
                      style={{ fontSize: "24px", color: "#52c41a" }}
                    />
                  </Col>
                </Row>
              }
              valueStyle={{ color: "#52c41a" }}
              value={dashboardData?.inactive_mrf_count}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Pending MRF Count</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <TeamOutlined
                      style={{ fontSize: "24px", color: "#1677ff" }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardData?.pending_mrf_count}
              valueStyle={{ color: "#1677ff" }}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row style={{}}>
                  <Col span={20}>Rejected MRF Count</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <TeamOutlined
                      style={{ fontSize: "24px", color: "#fa8c16" }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardData?.rejected_mrf_count}
              valueStyle={{ color: "#fa8c16" }}
            ></Statistic>
          </Card>
        </Col>  */}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Position Created Count</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{
                        fontSize: "24px",
                        color: "#52c41a",
                      }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardData?.position_created_count}
              valueStyle={{ color: "#52c41a" }}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Total Applicant Count</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{
                        fontSize: "24px",
                        color: "#1677ff",
                      }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardData?.applicant_count}
              valueStyle={{ color: "#1677ff" }}
            ></Statistic>
          </Card>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
        >
          {" "}
          <Card>
            <Statistic
              title={
                <Row>
                  <Col span={20}>Offer Letter Released Count</Col>
                  <Col span={4} style={{ textAlign: "end" }}>
                    <FileSearchOutlined
                      style={{
                        fontSize: "24px",
                        color: "#fa8c16",
                      }}
                    />
                  </Col>
                </Row>
              }
              value={dashboardData?.offer_letter_released_count}
              valueStyle={{ color: "#fa8c16" }}
            ></Statistic>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MrfDashboard;
