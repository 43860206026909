import React, { useState, useEffect } from "react";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Box,
  Button,
  Modal,
  Backdrop,
  Paper,
  TextField,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import urls from "../../url";
import useFetch from "../../hoc/useFetch";
import styles from "./viewprofile.style";
import { useAlert } from '../../hoc/alert';
import Loader from "../../hoc/loader";

const ViewProfile = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [open ,setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const alert = useAlert();

  const {
      data: profileData,
      isLoading: gettingProfileData,
      doFetch: fetchProfileData,
  } = useFetch(null);

  useEffect(()=>{
      if (name) {
        fetchProfileData({
            url: urls.userProfileApi,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${name.personal_info.token}`
            }
        })
      }
  },[name])

  function changePasswordSubmit() {
      if (!oldPassword) {
          alert.warning('Enter old Password');
          return;
      }
      if (!newPassword) {
        alert.warning('Enter New Password');
        return;
    }
    if (!confirmPassword) {
        alert.warning('Enter Confirm Password');
        return;
    }
    if (newPassword !== confirmPassword) {
        alert.warning('Confirm Password Is Not Maching');
        return;
    }
    const data = {
        'old_password': oldPassword,
        'new_password': newPassword,
    }

    setloading(true);
    fetch(`${urls.changePasswordApiinProfil}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${name.personal_info.token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (res.status === 400) {
        setloading(false);
        alert.warning('Old Password Is Wrong');
      }
      if (res.status === 200) {
        setloading(false);
        setOpen(false);
        setOldPassword('');
        setConfirmPassword('');
        setNewPassword('');
        alert.success('Password Successfully Changed');
        return res.json();
      }
      if (res.status !== 200 && res.status !== 400) {
        setloading(false);
        alert.warning('somthing went wrong please try again ');
      }
  })
}


  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let loader = null;
  if (gettingProfileData || loading) {
    loader = <Loader open />;
  }

  const handleClose = () => {
    setOpen(false);
    setOldPassword('');
    setConfirmPassword('');
    setNewPassword('');
  };


  const changPasswordModel = (
    <React.Fragment> 
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        >                    
          <Paper className={classes.paper}>
            <Typography className="headings" variant='h4'><strong>Change Password</strong></Typography>
            <Divider className={classes.divider}/>
            <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                    <TextField
                        label="Old Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        value={oldPassword || ''}
                        onChange={(e)=> setOldPassword(e.target.value)}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        label="New Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        value={newPassword || ''}
                        onChange={(e)=> setNewPassword(e.target.value)}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <TextField
                        label="Confirm Password"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        value={confirmPassword || ''}
                        onChange={(e)=> setConfirmPassword(e.target.value)}
                    />
             {newPassword && confirmPassword && confirmPassword !== newPassword
                ? <FormHelperText id="component-error-text">Confirm Password should match New password</FormHelperText>
                : null
              }
                </Grid>
                <Grid container direction align="flex-end" justify="flex-end"> 
                <Grid item md={3} xs={12} >
                    <Button color="primary" variant="contained" fullWidth onClick={()=> changePasswordSubmit()}>Change Password</Button>
                </Grid>
                </Grid>
            </Grid>
            </Paper>
        </Modal>
    </React.Fragment>
)

function functionOpenModel(){
    setOpen(true);
}

  
  return (
    <React.Fragment>
      <Typography className="headings" variant='h4'><strong>Profile Info</strong></Typography>
      <Divider className={classes.divider} />
      <Grid container spacing={1}>
          <Grid item md={2}/>
          <Grid item md={8} xs={12}>
              <Box border={1} style={{borderRadius:'5px', padding:'8px', color:'lightgray'}}>
                  <Grid container>
                      <Grid item md={3} xs={12}>
                          <Typography  style={{color:'black'}}><strong>Name :</strong> </Typography>
                      </Grid>
                      <Grid item md={9} xs={12}>
                          <Typography   style={{color:'black'}}>
                              {profileData && profileData.user && profileData.user.first_name}</Typography>
                      </Grid>
                  </Grid>
              </Box>
          </Grid>
          <Grid item md={2}/>
          <Grid item md={2}/>
          <Grid item md={8} xs={12}>
              <Box border={1} style={{borderRadius:'5px', padding:'8px', color:'lightgray'}}>
                  <Grid container>
                      <Grid item md={3} xs={12}>
                          <Typography  style={{color:'black'}}><strong>ERP :</strong></Typography>
                      </Grid>
                      <Grid item md={9} xs={12}>
                          <Typography   style={{color:'black'}}>
                              {profileData && profileData.erp}
                          </Typography>
                      </Grid>
                  </Grid>
              </Box>
          </Grid>
          <Grid item md={2}/>
          <Grid item md={2}/>
          <Grid item md={8} xs={12}>
              <Box border={1} style={{borderRadius:'5px', padding:'8px', color:'lightgray'}}>
                  <Grid container>
                      <Grid item md={3} xs={12}>
                          <Typography style={{color:'black'}}><strong>Phone Number :</strong></Typography>
                      </Grid>
                      <Grid item md={9} xs={12}>
                          <Typography   style={{color:'black'}}>
                              {profileData && profileData.phone_no}
                          </Typography>
                      </Grid>
                  </Grid>
              </Box>
          </Grid>
          <Grid item md={2}/>
          <Grid item md={2}/>
          <Grid item md={8} xs={12}>
              <Box border={1} style={{borderRadius:'5px', padding:'8px', color:'lightgray'}}>
                  <Grid container>
                      <Grid item md={3} xs={12}>
                          <Typography  style={{color:'black'}}><strong>Email Id :</strong></Typography>
                      </Grid>
                      <Grid item md={9} xs={12}>
                          <Typography   style={{color:'black'}}>
                          {profileData && profileData.user && profileData.user.email}
                         </Typography>
                      </Grid>
                  </Grid>
              </Box>
          </Grid>
          <Grid item md={2}/>
          <Grid item md={2}/>
          <Grid item md={8} xs={12}>
              <Box border={1} style={{borderRadius:'5px', padding:'8px', color:'lightgray', overflowWrap:"break-word" }}>
                  <Grid container>
                      <Grid item md={3} xs={4}>
                          <Typography style={{color:'black'}}> <strong>Location :</strong></Typography>
                      </Grid>
                      <Grid item md={9} xs={12}>
                          <Typography  style={{color:'black'}}>
                              {profileData && profileData.city && profileData.city.length && profileData.city.map((item,index)=>(
                                `${item.city_name},`
                                       
                              ))}
                              </Typography>
                      </Grid>
                  </Grid>
              </Box>
          </Grid>
          <Grid item md={2}/>
          <Grid item md={2}/>
          <Grid item md={8} xs={12}>
              <Box border={1} style={{borderRadius:'5px', padding:'8px', color:'lightgray'}}>
                  <Grid container>
                      <Grid item md={3} xs={4}>
                          <Typography  style={{color:'black'}}><strong>Role :</strong></Typography>
                      </Grid>
                      <Grid item md={9} xs={8}>
                          <Typography   style={{color:'black'}}>
                              {profileData && profileData.role && profileData.role.role_name}
                              </Typography>
                      </Grid>
                  </Grid>
              </Box>
          </Grid>
          <Grid item md={2}/>
          <Grid item md={2}/>
          <Grid item md={8} xs={12}>
              <Box border={1} style={{borderRadius:'5px', padding:'8px', color:'lightgray'}}>
                  <Grid container>
                      <Grid item md={3} xs={4}>
                          <Typography style={{color:'black'}}><strong>Branch :</strong></Typography>
                      </Grid>
                      <Grid item md={9} xs={8}>
                          <Typography   style={{color:'black'}}>
                          {profileData && profileData.branch && profileData.branch.length!==0 && profileData.branch.map((item,index)=>(
                            
                            item.branch_name||""         
                              ))}
                              </Typography>
                      </Grid>
                  </Grid>
              </Box>
          </Grid>
          <Grid item md={2}/>
          <Grid item md={8}/>
          <Grid item md={2} xs={12}>
              <Button color="primary" onClick={()=> functionOpenModel()}>Change Password</Button>
          </Grid>
      </Grid>
      {changPasswordModel}
      {loader}
    </React.Fragment>
  );
};

ViewProfile.propTypes = {};

export default withStyles(styles)(ViewProfile);
