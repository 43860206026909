import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  Select,
  Paper,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  TableFooter,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  InputBase,
  TableContainer,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";

import urls from "../../../url";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import useFetch from "../../../hoc/useFetch";
import styles from "./assignScore.style";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import Loader from "../../../hoc/loader";
import { useAlert } from "../../../hoc/alert/alert";
import MediaQuery from "react-responsive";
import axios from "axios";

const AssignScore = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem("user")));
  const [positionType, setPositionType] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [viewOpenning, setViewOpennings] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const alert = useAlert();
  const [openAudio, setOpenAudio] = useState(null);
  const [testType, setTestType] = useState("");
  const [roundId, setRoundId] = useState("");
  const [testScore, setTestScore] = useState("");
  const [applicentId, setApplicentId] = useState("");
  const [applicentName, setApplicentName] = useState('');
  const [gradeCheck, setGradeCheck] = useState('');
  const [subjectCheck, setSubjectCheck] = useState('');
  const [branch, setBranch] = useState("");
  const [checked, setChecked] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");

  const {
    data: roleList,
    isLoading: gettingRoleList,
    doFetch: FetchRoleList
  } = useFetch([], { suppressAlert: true });

  const {
    data: uploadScore,
    isLoading: uploadingScore,
    doFetch: fetchToUploadScore
  } = useFetch(null);

  const {
    data: getViewDaata,
    isLoading: gettingViewData,
    doFetch: fetchViewData
  } = useFetch(null, { suppressAlert: true });

  const {
    data: viewOpenningsData,
    isLoading: gettingViewOpennings,
    doFetch: fetchViewOpennings
  } = useFetch(null, { suppressAlert: true });

  const {
    data: applicentsList,
    isLoading: gettingApplicentList,
    doFetch: fetchApplicentList
  } = useFetch(null, { suppressAlert: true });

  const {
    data: branchesList,
    isLoading: gettingBranches,
    doFetch: fetchBranches
  } = useFetch([], { suppressAlert: true });

  const {
    data: grades,
    isLoading: gradesLoading,
    doFetch: fetchGrades
  } = useFetch([], { suppressAlert: true });

  const {
    data: subjects,
    isLoading: subjectsLoading,
    doFetch: fetchSubjects
  } = useFetch([], { suppressAlert: true });

  const { data: gradeList, doFetch: fetchGradeList } = useFetch(null);

  const { data: cityList, doFetch: fetchCityList } = useFetch(null, { suppressAlert: true });
  const {
    data: subjectData,
    isLoading: subjectLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  const { data: branchList, doFetch: fetchBranchList } = useFetch(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(10);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function firstPageChange() {
    setPage(0);
  }

  function lastPageChange(lastPage) {
    setPage(lastPage);
    !rowsPerPage && setRowsPerPage(10);
  }

  const handleStartDateChange = val => {
    setStartDate(val);
  };
  const handleEndDateChange = val => {
    setEndDate(val);
  };

  const testList = [
    {
      value: "1",
      label: "Written"
    },
    {
      value: "2",
      label: "Spoken"
    }
  ];
  const positionTypeList = [
    {
      value: "1",
      label: "Academic"
    },
    {
      value: "2",
      label: "Non Academic"
    },
    {
      value: "3",
      label: "IT Recruitment"
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    }
  ];

  const handleClose = () => {
    setOpen(false);
    setTestScore("");
    setRoundId("");
    setApplicentId("");
  };
  const handleCloseAudio = () => {
    setOpenAudio(false);
  };

  useEffect(() => {
    if (uploadScore) {
      setOpen(false);
      setTestScore("");
      setRoundId("");
      setApplicentId("");
      getApplicentsList();
    }
  }, [uploadScore]);

  function uploadTestScore() {
    if (!testScore) {
      alert.warning("Enter Score");
      return;
    }
    if (!testType) {
      alert.warning("Please Select Test Type");
      setOpen(false);
      return;
    }
    const data = {
      applicant: applicentId,
      score: testScore,
      round_id: roundId,
      round_type: testType.toLocaleLowerCase()
    };
    fetchToUploadScore({
      url: urls.uploadTestScoreApi,
      body: data,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }

  useEffect(() => {
    if (gradeCheck) {
      fetchSubjects({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeCheck}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
      setSubjectCheck("");
    }
  }, [gradeCheck, fetchSubjects])

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
      setBranch("");
    }
  }, [checkedLoc])

  function handleBranch(e) {
    setBranch(e.target.value);
  }

  const Audio = (
    <React.Fragment>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openAudio}
        // onClose={handleCloseAudio}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Paper className={classes.paperr}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: 10, justifyContent: "space-between" }}
          >
            <Grid item>
              <IconButton size="small" style={{ visibility: "hidden" }}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item>

              <Typography className='headings' variant='h5'><strong>{`View  ${testType} File`}</strong></Typography>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => handleCloseAudio()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>



          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            {testType === "Written" && (
              <Grid item md={12} xs={12}>

                {getViewDaata && (getViewDaata?.fileformat === ".docx" || getViewDaata?.fileformat === ".jpg" || getViewDaata?.fileformat === ".png" || getViewDaata?.fileformat === ".jpeg" || getViewDaata?.fileformat === "") ?
                  <img
                    src={getViewDaata && getViewDaata.filedata.written_test_file}
                    alt="crash"
                    width="100%"
                    height="80%"
                  />
                  :
                  getViewDaata && (getViewDaata?.fileformat === ".pdf") ?
                    <a
                      target='_blank'
                      href={getViewDaata && getViewDaata?.filedata?.written_test_file || ""}
                      onclick="window.open(this.href); return false;"
                    >
                      {" "}
                      View File
                    </a>

                    :
                    getViewDaata && (getViewDaata?.fileformat === ".pptx" || getViewDaata?.fileformat === ".ppt" || getViewDaata?.fileformat === ".potx" || getViewDaata?.fileformat === ".pptm" || getViewDaata?.fileformat === ".xlsx") ?
                      <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${getViewDaata && getViewDaata?.filedata?.written_test_file || ""}`}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                      >
                      </iframe>
                      :
                      <b>File not supported</b>
                }
              </Grid>
            )}
            {testType === "Spoken" && (
              <Grid item md={12} xs={12}>

                {getViewDaata && getViewDaata.filedata.spoken_audio ? (
                  <audio controls preload="auto">
                    <source
                      src={getViewDaata && getViewDaata.filedata.spoken_audio}
                      type="audio/mpeg"
                    />
                  </audio>
                ) : (
                  <b>No Audio is found</b>
                )}
              </Grid>
            )}

          </Grid>
          <Divider className={classes.divider} />
        </Paper>
      </Modal>
    </React.Fragment>
  );

  const modal = (
    <React.Fragment>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        style={{ width: "80%", marginLeft: "10%" }}
      >
        <Paper className={classes.paperr}>

          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: 10, justifyContent: "space-between" }}
          >
            <Grid item>
              <IconButton size="small" style={{ visibility: "hidden" }}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item>

              <Typography className="headings" variant='h5'><strong>{`Upload ${testType} Score`} out of {testType === 'Written' ? '30' : '20'}</strong></Typography>
            </Grid>
            <Grid item>
              <IconButton size="small" onClick={() => handleClose()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>



          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item md={8} xs={16}>
              <TextField
                label='Test Score'
                type='number'
                margin='dense'
                required
                fullWidth
                className="textList"
                value={testScore || ""}
                onInput={e => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 2);
                }}
                onChange={e => ((testType === 'Written' && e.target.value >= 0 && e.target.value <= 30) || (testType === 'Spoken' && e.target.value >= 0 && e.target.value <= 20)) &&
                  setTestScore(e.target.value)
                }
                variant='outlined'
              />
            </Grid>
            <Grid item md={4} xs={6}>
              <Button
                className={classes.addButton}
                color='primary'
                size='small'
                variant='contained'
                startIcon={<CloudUploadIcon />}
                onClick={uploadTestScore}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </Paper>
      </Modal>
    </React.Fragment>
  );

  useEffect(() => {
    if (positionType) {
      FetchRoleList({
        url: `${urls.RoleListApi}?position_type=${positionType}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
      fetchGrades({
        url: `${urls.newGrade}?position_type=${positionType}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });
      fetchCityList({
        url: urls.cityList,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      setChecked("");
      setCheckedLoc("");
      setBranch("");
      setGradeCheck("");
      setSubjectCheck("");
    }
  }, [positionType]);

  useEffect(() => {
    if (positionType && RoleId) {
      fetchViewOpennings({
        url: `${urls.ViewOpenningsApi}?position_type=${positionType}&role=${RoleId}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
    }
  }, [RoleId, positionType, branch, checkedLoc, subjectCheck, gradeCheck]);

  function getApplicentsList() {
    if (!positionType) {
      alert.warning("Please Select Position Type");
      return;
    }
    if (!RoleId) {
      alert.warning("Please Select Role");
      return;
    }
    if (!testType) {
      alert.warning("Please Select Test Type");
      return;
    }
    fetchApplicentList({
      url: `${urls.scoreUploadApplicentsList
        }?opening_id=${viewOpenning}&round_type=${testType.toLocaleLowerCase()}&role_id=${RoleId}${startDate ? `&start_date=${startDate}` : ""
        }${endDate ? `&end_date=${endDate}` : ""
        }&page_size=${rowsPerPage}&page=${page + 1}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }

  useEffect(() => {
    if (positionType && RoleId && testType) {
      fetchApplicentList({
        url: `${urls.scoreUploadApplicentsList
          }?opening_id=${viewOpenning}&round_type=${testType.toLocaleLowerCase()}&role_id=${RoleId}${startDate ? `&start_date=${startDate}` : ""
          }${endDate ? `&end_date=${endDate}` : ""
          }&page_size=${rowsPerPage}&page=${page + 1}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      });
      setStartDate(null);
      setEndDate(null);
      setPositionType("");
      setRoleId("");
      setViewOpennings("");
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (getViewDaata) {
      setOpenAudio(true);
    }
  }, [getViewDaata]);

  function openViewModel(id) {
    if (!testType) {
      alert.warning("Please Select Round Type");
      return;
    }
    fetchViewData({
      url: `${urls.ViewTestFileApi}?round_id=${id}&round_type=${testType.toLocaleLowerCase()}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${name.personal_info.token}`
      }
    });
  }
  function uploadScorefunction(id, rId) {
    if (!testType) {
      alert.warning("Please Select Round Type");
      return;
    }
    setRoundId(rId);
    setApplicentId(id);
    setOpen(true);
  }

  function ReAssignTest(data) {
    axios.get(`${urls.reAssignTest}?username=${data?.contact_no}&round_id=${data?.round_id}&round_type=${testType.toLowerCase()}`,
      {
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json'
        },
      }

    ).then((response) => {
      if (response.status === 200) {
        alert.success(response?.data);
        fetchApplicentList({
          url: `${urls.scoreUploadApplicentsList}?opening_id=${viewOpenning}&round_type=${testType.toLocaleLowerCase()}&role_id=${RoleId}${startDate ? `&start_date=${startDate}` : ""
            }${endDate ? `&end_date=${endDate}` : ""
            }&page_size=${rowsPerPage}&page=${page + 1}&city_id=${checkedLoc}&branch_id=${branch}&grade_id=${gradeCheck}&subject_id=${subjectCheck}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${name.personal_info.token}`
          }
        })
      }
    })
  }

  const fetchSearch = e => {
    // setPage(0);
    // setRowsPerPage(5);
    // if (e.keyCode === 13) {
    //   const data = document.getElementById("search-bar").value;
    //   setApplicentName(document.getElementById("search-bar").value);
    //   functionSearch(data);
    // }
    setApplicentName(e.target.value);
  };

  useEffect(() => {
    if (applicentName) {
      if (!positionType) {
        alert.warning('Please Select Position Type');
        return;
      }
      if (!RoleId) {
        alert.warning('Please Select Role');
        return;
      }
      fetchApplicentList({
        url: `${urls.searchApplicantRecruiter}?position_id=${viewOpenning}&position_type=${positionType}&role_id=${RoleId}&page=${page +
          1}&page_size=${rowsPerPage || 5}&search=${applicentName}&round_type=${testType.toLocaleLowerCase()}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${name.personal_info.token}`
        }
      })
    }
  }, [applicentName, testType])


  let loader = null;
  if (
    gettingRoleList ||
    gettingViewOpennings ||
    gettingApplicentList ||
    gettingViewData ||
    uploadingScore ||
    gettingBranches ||
    gradesLoading ||
    subjectsLoading

  ) {
    loader = <Loader open />;
  }
  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item md={5} xs={12} style={{ float: 'left' }}>
          <Typography className="headings" variant='h4'><strong>Score Upload</strong></Typography>
        </Grid>
        {/* <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="I'm Looking For..."
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              id='search-bar'
              onChange={fetchSearch}
            />
          </div>
        </Grid> */}
        <Grid item md={7} xs={12}>
          <div className={classes.search}>
            <TextField
              id="search-bar"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              label="I'm Looking For..."
              variant="outlined"
              size="small"
              name="subject"
              autoComplete="on"
              onChange={fetchSearch}
            />
          </div>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.root}>
        <Paper className={classes.paperrrrr}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={12}>
              <Typography>
                {" "}
                Select Position Type<b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin='dense'
                value={positionType || ""}
                onChange={e => setPositionType(e.target.value)}
                variant='outlined'
              >
                {positionTypeList.map((option, index) => (
                  <MenuItem key={index} value={option.label} name={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>
                {" "}
                Select Role<b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin='dense'
                value={RoleId || ""}
                onChange={e => setRoleId(e.target.value)}
                variant='outlined'
              >
                {roleList &&
                  roleList.length !== 0 &&
                  roleList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.id}
                      name={option.applicant_role_name}
                    >
                      {option.applicant_role_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>
                Select Test Type<b style={{ color: "red" }}>*</b>
              </Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin='dense'
                value={testType || ""}
                onChange={e => setTestType(e.target.value)}
                variant='outlined'
              >
                {testList &&
                  testList.length !== 0 &&
                  testList.map((option, index) => (
                    <MenuItem
                      key={index}
                      value={option.label}
                      name={option.label}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>City</Typography>
              <Select
                required
                fullWidth
                // multiple
                margin='dense'
                value={checkedLoc || ""}
                onChange={e => setCheckedLoc(e.target.value)}
                variant='outlined'
              >
                {cityList && cityList.length !== 0 &&
                  cityList.map(option => (
                    <MenuItem key={option.value} value={option.id} name={option.id}>
                      {option.city_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>Branch</Typography>
              <Select
                required
                fullWidth
                // multiple
                margin='dense'
                value={branch || ""}
                onChange={handleBranch}
                variant='outlined'
              >
                {branchesList && branchesList.length !== 0 &&
                  branchesList.map(option => (
                    <MenuItem key={option.value} value={option.id} name={option.id}>
                      {option.branch_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>{positionType == "Academic" ? "Grade" : "Level"}</Typography>
              <Select
                required
                fullWidth
                margin='dense'
                value={gradeCheck || ""}
                onChange={e => setGradeCheck(e.target.value)}
                variant='outlined'
              >
                {grades && grades.map(option => (
                  <MenuItem key={option.id} value={option.id} name={option.id}>
                    {option.grade_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>{positionType == "Academic" ? "Subject" : "Topic"}</Typography>
              <Select
                required
                fullWidth
                margin='dense'
                value={subjectCheck || ""}
                onChange={e => setSubjectCheck(e.target.value)}
                variant='outlined'
              >
                {subjects && subjects && subjects.length > 0 && subjects.map(option => (
                  <MenuItem key={option.subject.id} value={option.subject.id} name={option.subject.id}>
                    {option.subject.subject_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography>Select Opening</Typography>
              <Select
                required
                fullWidth
                className="textList"
                margin='dense'
                value={viewOpenning || ""}
                onChange={e => setViewOpennings(e.target.value)}
                variant='outlined'
              >
                {viewOpenningsData &&
                  viewOpenningsData.length !== 0 &&
                  viewOpenningsData.map((option, index) => (
                    <MenuItem key={index} value={option.id} name={option.title}>
                      {option.title}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>Start Date</Typography>
              <TextField
                className={classes.textField}
                margin='dense'
                required
                fullWidth
                onChange={e => handleStartDateChange(e.target.value)}
                type='date'
                value={startDate || ""}
                variant='outlined'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography>End Date</Typography>
              <TextField
                className={classes.textField}
                margin='dense'
                required
                fullWidth
                onChange={e => handleEndDateChange(e.target.value)}
                type='date'
                value={endDate || ""}
                variant='outlined'
              />
            </Grid>
            <Grid item md={4} xs={12} style={{ marginTop: "33px" }}>
              <Button
                variant='contained'
                color='primary'
                onClick={getApplicentsList}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Divider className={classes.divider} />
      </div>
      {applicentsList && applicentsList.result.length === 0 && (
        <Typography
          variant='h4'
          style={{ color: "blue", marginTop: "50px", textAlign: "center" }}
        >
          Records Not Found
        </Typography>
      )}
      {applicentsList && applicentsList.result.length !== 0 && (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TableContainer className="role">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" style={{ border: '1px solid black' }}>
                      <Typography><strong>S.No</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid black' }}>
                      <Typography><strong>Name</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid black' }}>
                      <Typography><strong>Branch</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid black' }}>
                      <Typography><strong>Grade</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid black' }}>
                      <Typography><strong>Subject</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid black' }}>
                      <Typography><strong>View</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid black' }}>
                      <Typography><strong>Upload Score</strong></Typography>
                    </TableCell>
                    <TableCell align="center" style={{ border: '1px solid black' }}>
                      <Typography><strong>Re Assign</strong></Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applicentsList &&
                    applicentsList.result.length !== 0 &&
                    applicentsList.result.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" style={{ border: '1px solid black' }}>
                          <Typography>{index + 1}</Typography>
                        </TableCell>
                        <TableCell float='left' style={{ border: '1px solid black' }}>
                          <Typography>{data.name || ""}</Typography>
                        </TableCell>
                        <TableCell float='left' style={{ border: '1px solid black' }}>
                          <Typography>{data.branch || ""}</Typography>
                        </TableCell>
                        <TableCell float='left' style={{ border: '1px solid black' }}>
                          <Typography>{data.grade || ""}</Typography>
                        </TableCell>
                        <TableCell float='left' style={{ border: '1px solid black' }}>
                          <Typography>{data.subject}</Typography>
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid black' }}>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => openViewModel(data.round_id)}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid black' }}>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              uploadScorefunction(data.id, data.round_id)
                            }
                          >
                            Upload Score
                          </Button>
                        </TableCell>
                        <TableCell align="center" style={{ border: '1px solid black' }}>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              ReAssignTest(data)
                            }
                          >
                            Re Assign
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Table>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      style={{ marginTop: "15px" }}
                      colSpan={5}
                      rowsPerPageOptions={[5, 10, 15]}
                      component='div'
                      count={applicentsList && applicentsList.count}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                        "aria-label": "previous page"
                      }}
                      nextIconButtonProps={{
                        "aria-label": "next page"
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <TableCell>
                      <IconButton
                        onClick={firstPageChange}
                        disabled={page === 0 || page === 1}
                      >
                        <FirstPageIcon />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          lastPageChange(applicentsList.total_pages - 1)
                        }
                        disabled={page === +applicentsList.total_pages - 1}
                      >
                        <LastPageIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

          </Grid>
        </Grid>
      )}

      {Audio}
      {modal}
      {loader}
    </React.Fragment>
  );
};

AssignScore.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired
};

export default withStyles(styles)(AssignScore);
