import React, {useState , useEffect} from 'react';
import tinymce from 'tinymce';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
    Typography,
    Divider,
    Grid,
    Paper,
    Button,
    withStyles,
  } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './editQuestion.style';
import urls from '../../../url'
import { useAlert } from '../../../hoc/alert/alert'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import '../addQuestion/pluGins'

function EditQuestion ({classes,data, getQuestions}) {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [question, setQuestion] = useState({question:data.question});
  const [option1, setOption1] = useState(data.options[0]);
  const [option2, setOption2] = useState(data.options[1]);
  const [option3, setOption3] = useState(data.options[2]);
  const [option4, setOption4] = useState(data.options[3]);
  const [editorsValue, setEditorsValue] = useState({question: '',option1: '',option2: '',option3: '',option4: ''})
  const initialOption = Object.keys(data.correct_ans)
  const [value, setValue] = React.useState(initialOption[0]);
  const [editor] = useState(null);

  const {
    data : editQuestionRes,
    isLoading: updateLoading,
    doFetch : editQuestion,
  } = useFetch(null);

  const alert = useAlert();

  useEffect(()=>{
   if(editQuestionRes){
    getQuestions()
   }
  },[editQuestionRes])

   useEffect(() => {
    tinymce.init({
      selector: '.dgf',
      plugins: 'image insertdatetime media table paste code wordcount searchreplace visualblocks code fullscreen advlist autolink lists charmap print preview anchor',
      toolbar: 'undo redo | formatselect | bold italic backcolor | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
      image_title: true,
      setup: editor => {
        editor.on('keyup change', () => {
          const content = editor.getContent()
          setEditorsValue((vl) => {
            vl[tinymce.activeEditor.id] = content
            return vl
          })
          
        })
      },
      automatic_uploads: true,
      file_picker_types: 'image',
      file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function () {
          var file = this.files[0];
          var reader = new FileReader();
          reader.onload = function () {
            var id = 'blobid' + (new Date()).getTime();
            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };            
          reader.readAsDataURL(file);
        };
        input.click();
      }
    })
    return () => {
      tinymce.remove(editor);
    }
  }, [])

  useEffect(() => {
    setEditorsValue((vl) => {
      vl.question = question.question;
      vl.option1= option1;
      vl.option2= option2;
      vl.option3= option3;
      vl.option4= option4;
      return vl
    })
  })
 
  const handleOptionValue = event => {
  setValue(event.target.value)
  };

  const editQuestionFunction =()=> {
    const option ={
      'option1': editorsValue.option1,
      'option2': editorsValue.option2,
      'option3': editorsValue.option3,
      'option4': editorsValue.option4,
    }
    let val
    if(value){ 
      val = editorsValue[value]
    }
    const correctAns = {
      [value] : val
    }
    const addQuesData = {
      'question' : editorsValue.question,
      'correct_ans' : JSON.stringify(correctAns),
      option:JSON.stringify(option)
    }
    if(!editorsValue.question || !editorsValue.option1 || !editorsValue.option2 || !editorsValue.option3 || !editorsValue.option4 || !value){
      alert.warning('Fill all the Fields')
      return
    }
    else {
    editQuestion({
      url: `${urls.updateQuestionApi}${data.question_id}/retrieve_update_destroy_questions/`,
      method: 'PUT',
      body: addQuesData,
      headers: {
        Authorization: `Bearer ${auth.personal_info.token}`,
       'Content-Type':'application/json'
      },
    })
   }
  }

  let loader = null;
  if (updateLoading) {
    loader = <Loader open />;
  }

    return(
        <React.Fragment>
         <Divider className={classes.divider} />
         <Paper className={classes.paper} className="role">
            <Divider className={classes.divider} />
            <Typography variant="h6">Question :</Typography>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <textarea
                    id='question'
                    className='dgf'
                    value={question['question']}
                    style={{ height: 250 }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                <Typography variant="h6">Option 1 :</Typography>
                 <Grid container>
                      <Grid item md={9} xs={12}>
                        <textarea
                          id='option1'
                          value={option1}
                          className='dgf'
                          style={{ height: 250 }}
                        />
                      </Grid>
                      <Grid item md={3} xs ={12}>
                        <Grid classNhandleOptionValueame={classes.checkBox}>
                        <FormControl component="fieldset" className={classes.formControl}>
                          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleOptionValue}>
                              <FormControlLabel value="option1" control={<Radio />} label="is correct " />
                          </RadioGroup>
                        </FormControl>
                        </Grid>
                      </Grid>
                 </Grid>
                </Grid>
                <Grid item md={12} xs={12}>
                <Typography variant="h6">Option 2 :</Typography>
                 <Grid container>
                      <Grid item  md={9} xs={12}>
                        <textarea
                          id='option2'
                          value={option2}
                          className='dgf'
                          style={{ height: 250 }}
                        />
                      </Grid>
                      <Grid item md={3} xs ={12}>
                      <Grid className={classes.checkBox}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleOptionValue}>
                                <FormControlLabel value="option2" control={<Radio />} label="is correct " />
                            </RadioGroup>
                          </FormControl>
                        </Grid>  
                    </Grid>
                 </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                <Typography variant="h6">Option 3 :</Typography>
                 <Grid container>
                      <Grid item md={9} xs={12}>
                        <textarea
                          id='option3'
                          className='dgf'
                          value={option3}
                          style={{ height: 250 }}
                        />
                      </Grid>
                      <Grid item md={3} xs ={12}>
                      <Grid className={classes.checkBox}>
                        <FormControl component="fieldset" className={classes.formControl}>
                          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleOptionValue}>
                              <FormControlLabel value="option3" control={<Radio />} label="is correct " />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      </Grid>
                 </Grid>
                </Grid>

                <Grid item md={12} xs={12}>
                <Typography variant="h6">Option 4 :</Typography>
                 <Grid container>
                      <Grid item md={9} xs={12}>
                        <textarea
                          id='option4'
                          className='dgf'
                          value={option4}
                          style={{ height: 250 }}
                        />
                      </Grid>
                      <Grid item md ={3} xs={12}>
                      <Grid className={classes.checkBox}>
                        <FormControl component="fieldset" className={classes.formControl}>
                          <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleOptionValue}>
                              <FormControlLabel value="option4" control={<Radio />} label="is correct " />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      </Grid>
                 </Grid>
                </Grid>
                <Grid item md={3} xs={10} className={classes.applyButtonSection}>
                  <Button
                    className={classes.applyButton}
                    color="primary"
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={() => editQuestionFunction()}
                  >
                    Save Question
                  </Button>
                </Grid>
             </Grid>   
             <Divider className={classes.divider} />
           </Paper>
           {loader}
        </React.Fragment>          
    );
}
EditQuestion.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
  };
export default withStyles(styles)(EditQuestion);