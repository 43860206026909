import React, { useEffect, useState } from "react";
import urls from "../../../url";
import axios from "axios";
import {
  Card,
  message,
  Row,
  Col,
  Spin,
  Button,
  Space,
  Form,
  Modal,
  Input,
  Tooltip,
} from "antd";
import {
  SyncOutlined,
  InfoCircleOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import "./style.css";
const budgetApprover = [13, 21];
const offerApprover = [1, 4];
const OfferLetterApproval = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const offerId = urlParams.get("offer_id");

  const { TextArea } = Input;
  const [offerLetterData, setOfferLetterData] = useState();
  const [loading, setLoading] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const [feedBackModalForm] = useForm();
  const userData = useSelector((state) => state?.auth?.user);
  const role = userData?.personal_info?.role_id;
  const isBudgetApprover = userData?.academic_profile?.is_budget_approver;
  useEffect(() => {
    if (offerId) {
      fetchOfferLetterData();
    }
  }, [offerId]);
  const [activeTab, setActiveTab] = useState("1");
  const tabContent = {
    "1": (
      <>
        <div style={{ marginBottom: "5px" }}>
          <strong>Position Budget(Annual)</strong> :{" "}
          <span className="text-capitalize">
            ₹ {offerLetterData?.budget ?? "-"}
          </span>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <strong>Offered CTC(Annual)</strong> :{" "}
          <span className="text-capitalize">
            ₹ {offerLetterData?.ctc_offered ?? "-"}
          </span>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <strong>Raised By</strong> :{" "}
          <span className="text-capitalize">
            {offerLetterData?.submited_by ?? "-"}
          </span>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <strong>Designation</strong> :{" "}
          <span className="text-capitalize">
            {offerLetterData?.designation ?? "-"}
          </span>
        </div>

        <div style={{ marginBottom: "5px" }}>
          <strong>Joining Date</strong> :{" "}
          <span className="text-capitalize">
            {dayjs(offerLetterData?.date_of_joining).format("Do MMM, YYYY")}
          </span>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <strong>Vacancy Code</strong> :{" "}
          <span className="text-capitalize">
            {offerLetterData?.code ?? "N/A"}
          </span>
        </div>
      </>
    ),
    "2": (
      <>
        <div style={{ marginBottom: "5px" }}>
          <strong>Canidate Name</strong> :{" "}
          <span className="text-capitalize">
            {offerLetterData?.candidate_name ?? "-"}
          </span>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <strong>Email</strong> :{" "}
          <span className="text-capitalize">
            {offerLetterData?.candidate_email ?? "-"}
          </span>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <strong>Contact</strong> :{" "}
          <span className="text-capitalize">
            {offerLetterData?.candidate_contact_no}
          </span>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <strong>Gender</strong> :{" "}
          <span className="text-capitalize">
            {offerLetterData?.gender ?? "-"}
          </span>
        </div>
      </>
    ),
  };
  const fetchOfferLetterData = () => {
    setLoading(true);
    axios
      .get(`${urls.offerLetterApproval}${offerId}/`)
      .then((response) => {
        if (response.status === 200) {
          setOfferLetterData(response?.data[0]);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleStatus = (val) => {
    const values = feedBackModalForm.getFieldsValue();
    const formData = new FormData();
    if (
      offerLetterData?.budget_approver_id &&
      offerLetterData?.budget_approver_status == "pending"
    ) {
      formData.append("budget_approver_status", val);
      if (val == "rejected") {
        setRejectLoader(true);
        formData.append(
          "budget_approver_feedback",
          values?.reporting_manager_feedback
        );
      } else {
        setActionLoader(true);
      }
    } else {
      formData.append("released_status", val);
      if (val == "rejected") {
        formData.append(
          "reporting_manager_feedback",
          values?.reporting_manager_feedback
        );
      }
    }

    axios
      .put(`${urls.offerLetterApproval}${offerId}/`, formData)
      .then((response) => {
        if (response.status === 200) {
          fetchOfferLetterData();
          closeFeedBackModal();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ?? "Something went wrong!"
        );
      })
      .finally(() => {
        setRejectLoader(false);
        setActionLoader(false);
      });
  };

  const closeFeedBackModal = () => {
    setFeedbackModalOpen(false);
    feedBackModalForm.resetFields();
  };

  return (
    <>
      <Row gutter={24}>
        <Col md={16}>
          <Card
            loading={loading}
            title={
              <p style={{ fontWeight: 500, fontSize: "18px" }}>
                {budgetApprover?.includes(role)
                  ? "Budget Approval"
                  : "Offer Approval"}
              </p>
            }
            extra={<FilePdfOutlined style={{ fontSize: "18px" }} />}
          >
            <div
              className="offer-preview th-hidden-scrollbar"
              dangerouslySetInnerHTML={{
                __html: offerLetterData?.offer_letter,
              }}
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card
            loading={loading}
            onTabChange={(e) => {
              setActiveTab(e);
            }}
            tabProps={{
              activeKey: activeTab,
            }}
            tabList={[
              {
                key: "1",
                label: "Offer Details",
              },
              {
                key: "2",
                label: "Candidate Details",
              },
            ]}
          >
            <div style={{ minHeight: "170px" }}>{tabContent[activeTab]}</div>
          </Card>
          <Card hidden={loading} bodyStyle={{ padding: "10px" }}>
            {isBudgetApprover ? (
              offerLetterData?.budget_approver_id &&
              offerLetterData?.budget_approver_status == "pending" ? (
                <Row justify={"end"}>
                  <Space>
                    <Button
                      onClick={() => setFeedbackModalOpen(true)}
                      size="large"
                      danger
                      disabled={actionLoader}
                      loading={rejectLoader}
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={() => handleStatus("approved")}
                      size="large"
                      type="primary"
                      loading={actionLoader}
                      disabled={rejectLoader}
                    >
                      Approve Budget
                    </Button>
                  </Space>
                </Row>
              ) : (
                <div
                  style={{ textTransform: "capitalize", textAlign: "center" }}
                >
                  Budget {offerLetterData?.budget_approver_status}{" "}
                  {offerLetterData?.budget_approver_status == "rejected" && (
                    <Tooltip title={offerLetterData?.budget_approver_feedback}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  )}
                </div>
              )
            ) : (
              ""
            )}
            {offerApprover?.includes(role) ? (
              offerLetterData?.budget_approver_id ? (
                offerLetterData?.budget_approver_status == "approved" ? (
                  offerLetterData?.released_status == "pending" ? (
                    <Row justify={"end"}>
                      <Space>
                        <Button
                          onClick={() => setFeedbackModalOpen(true)}
                          size="large"
                          danger
                          disabled={actionLoader}
                          loading={rejectLoader}
                        >
                          Reject
                        </Button>
                        <Button
                          onClick={() => handleStatus("approved")}
                          size="large"
                          type="primary"
                          loading={actionLoader}
                          disabled={rejectLoader}
                        >
                          Approve Offer
                        </Button>
                      </Space>
                    </Row>
                  ) : (
                    <div
                      style={{
                        textTransform: "capitalize",
                        textAlign: "center",
                      }}
                    >
                      Offer Letter {offerLetterData?.released_status}
                      {offerLetterData?.released_status == "rejected" && (
                        <Tooltip
                          title={offerLetterData?.reporting_manager_feedback}
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      )}
                    </div>
                  )
                ) : (
                  <></>
                )
              ) : offerLetterData?.released_status == "pending" ? (
                <Row justify={"end"}>
                  <Space>
                    <Button
                      onClick={() => setFeedbackModalOpen(true)}
                      size="large"
                      danger
                      disabled={actionLoader}
                      loading={rejectLoader}
                    >
                      Reject
                    </Button>
                    <Button
                      onClick={() => handleStatus("approved")}
                      size="large"
                      type="primary"
                      loading={actionLoader}
                      disabled={rejectLoader}
                    >
                      Approve Offer
                    </Button>
                  </Space>
                </Row>
              ) : (
                <div
                  style={{ textTransform: "capitalize", textAlign: "center" }}
                >
                  Offer Letter {offerLetterData?.released_status}
                  {offerLetterData?.released_status == "rejected" && (
                    <Tooltip
                      title={offerLetterData?.reporting_manager_feedback}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  )}
                </div>
              )
            ) : (
              ""
            )}
          </Card>
        </Col>
      </Row>
      <Modal
        title="Reason for Rejecting"
        open={feedbackModalOpen}
        onCancel={closeFeedBackModal}
        centered
        width={500}
        style={{ maxHeight: "90vh", overflow: "auto" }}
        footer={[
          <Row justify="space-around">
            <Col>
              <Button type="default" onClick={closeFeedBackModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button type="primary" form="feedBackModalForm" htmlType="submit">
                Reject
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          id="feedBackModalForm"
          form={feedBackModalForm}
          layout="vertical"
          onFinish={() => handleStatus("rejected")}
        >
          <Row align="middle" gutter={24}>
            <Col span={24}>
              <Form.Item
                name="reporting_manager_feedback"
                label="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Remarks",
                  },
                ]}
              >
                <TextArea
                  rows={3}
                  maxLength={1500}
                  showCount
                  placeholder="Enter Remarks"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default OfferLetterApproval;
