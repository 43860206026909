import {
  Menu as MenuIcon,
  MenuOpen,
  Input as InputIcon,
  SupervisedUserCircleOutlined,
  VerifiedUser,
  Person,
  ViewAgenda,
} from "@material-ui/icons";
import { Button, Dropdown } from "antd";
import { Header } from "antd/es/layout/layout";
import React from "react";
import { useHistory } from "react-router-dom";

function TopBar({ setCollapsed, collapsed, colorBgContainer, handleMenu }) {
  const history = useHistory();
  return (
    <Header
      className="d-flex justify-content-between align-items-center px-3"
      style={{
        paddingRight: "1rem",
        paddingLeft: "1rem",
        background: colorBgContainer,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #e4e9f0",
      }}
    >
      <Button
        type="text"
        icon={collapsed ? <MenuIcon /> : <MenuOpen />}
        onClick={() => setCollapsed(!collapsed)}
        style={{
          fontSize: "16px",
          width: 64,
          height: 64,
        }}
      />
      <Dropdown
        menu={{
          items: [
            {
              label: "View Profile",
              key: "0",
              icon: <ViewAgenda fontSize="small" />,
              onClick: () => {
                history.push(`/View_Profile`);
              },
            },
            {
              label: "Logout",
              key: "1",
              icon: <InputIcon fontSize="small" />,
              onClick: () => {
                handleMenu();
              },
            },
            // {
            //   label: "2nd menu item",
            //   key: "2",
            //   icon: <UserOutlined />,
            // },
            // {
            //   label: "3rd menu item",
            //   key: "3",
            //   icon: <UserOutlined />,
            //   danger: true,
            // },
          ],
        }}
      >
        <Button shape="circle" icon={<Person />} />
      </Dropdown>
      {/* <Button
        type="text"
        onClick={handleMenu}
        icon={<InputIcon fontSize="small" />}
        style={{
          fontSize: "16px",
          width: 64,
          height: 64,
        }}
      /> */}
    </Header>
  );
}

export default TopBar;
