export default theme => ({
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },  
    paper: {
      width: '100%',
      marginLeft: 'auto',
      backgroundColor: theme.palette.background.paper,
      // boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 2),
    },
  });