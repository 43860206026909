import React, { useState } from 'react'
import Typography from "@material-ui/core/Typography";

const Dashboard = () => {
  const [profile] = useState(JSON.parse(localStorage.getItem('user')));

  return (
    <div>
      <Typography>
        Welcome to <strong style={{ fontSize: '40px', color: 'rgb(183 87 143)' }}>Hr Head Dashboard</strong>({profile?.academic_profile?.name})
      </Typography>
    </div>
  )
}

export default Dashboard;