import {
  Space,
  Button,
  Table,
  Modal,
  Form,
  Card,
  Row,
  Col,
  Input,
  Drawer,
  message,
  Tag,
  Tooltip,
  DatePicker,
  Radio,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useAlert } from "../../../hoc/alert/alert";
import { useLocation } from "react-router-dom";

import { useEffect, useState } from "react";

import axios from "axios";

import {
  SyncOutlined,
  CloudUploadOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import UploadFormat from "../../../utils/sample_files/candidateSample.xlsx";
import urls from "../../../url";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

export default function BulkCandidate() {
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const passedData = location?.state?.data;
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bulkFile, setBulkFile] = useState("");
  const [resumeFile, setResumeFile] = useState("");
  const [resumeType, setResumeType] = useState("link");
  const [form] = useForm();
  const [addCandidate] = useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
    addCandidate.resetFields();
    setResumeFile(null);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleBulkQuestions = (value) => {
    setBulkFile(value);
  };

  function UploadFile() {
    console.log(passedData, "passedData");
    const file = new FormData();
    passedData && file.append("postions_id", passedData?.position);
    file.append("file", bulkFile);

    axios
      .post(urls.bulkRegister, file)
      .then((response) => {
        if (response.data.status_code == 200) {
          form.resetFields();
          setBulkFile("");
          getTableData({
            position_id: passedData?.position,
          });
          setIsModalOpen(false);

          message.success("File Added Successfully");
        } else {
          message.error("Failed To Add");
        }
      })
      .catch((error) => {
        message.error("Failed to add");
      });
  }

  const uploadCandidate = (values) => {
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("email", values?.email);
    formData.append("contact_no", values?.contact_no);
    formData.append(
      "date_of_birth",
      dayjs(values?.date_of_birth).format("YYYY-MM-DD")
    );
    formData.append("address", values?.address);
    formData.append(
      "positions_id",
      passedData?.position ? passedData?.position : ""
    );
    formData.append("source", values?.source);
    if (resumeType == "upload") {
      formData.append("resume", resumeFile, resumeFile?.name);
    } else {
      formData.append("resume_link", values?.resume);
    }

    axios
      .post(`${urls.singleCandidateUpload}`, formData)
      .then((response) => {
        if (response.data.status_code == 200) {
          message.success("Submitted Successfully");
          onClose();
          history.go(-1);
        } else {
          message.error("Something went wrong!");
        }
      })
      .catch((error) => {
        message.error(error.response?.data ?? "Something went wrong!");
      });
  };

  function getTableData(params = {}) {
    setLoading(true);
    axios
      .get(urls.registrationStatus, {
        params: { ...params },
      })
      .then((response) => {
        // Assuming the response.data is an array of objects
        setTableData(response?.data?.data?.applicant_registration_status);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleUpload = (e) => {
    if (e.target.files[0]) {
      const allowedExtensions = [".pdf"];
      const fileExtension = e.target.files[0]?.name
        .split(".")
        .pop()
        .toLowerCase();
      if (!allowedExtensions?.includes(`.${fileExtension}`)) {
        message.error("Document must be in PDF format only");
        addCandidate.resetFields(["resume"]);
        e.target.files = null;
        return;
      }
      if (e.target.files[0].size > 10242880) {
        message.error("File Size should be less than 10MB");
        addCandidate.resetFields(["resume"]);
      } else {
        setResumeFile(e.target.files[0]);
      }
    } else {
      setResumeFile(null);
    }
  };

  useEffect(() => getTableData({ position_id: passedData?.position }), []);

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const columns = [
    {
      title: <strong>S.No.</strong>,
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: <strong>Name</strong>,
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: <strong>Contact</strong>,
      dataIndex: "contact_no",
      key: "contact_no",
      align: "center",
    },
    {
      title: <strong>Email</strong>,
      dataIndex: "email",
      key: "email",
      align: "center",
    },

    {
      title: "Status",
      key: "status",

      render: (data) => (
        <>
          {data?.status.includes("Failed") ? (
            <Tooltip title={data?.status}>
              <Tag icon={<InfoCircleOutlined />} color="red">
                Failed
              </Tag>
            </Tooltip>
          ) : (
            <Tag color="green">Pass</Tag>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Card>
        {" "}
        <div
          className=""
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <div className="page_title">
            <strong>Candidates</strong>
          </div>

          <div>
            <Row gutter={16}>
              <Col>
                <Button
                  type="primary"
                  icon={<SyncOutlined />}
                  onClick={() => {
                    getTableData({ position_id: passedData?.position });
                  }}
                  loading={loading}
                >
                  Sync
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon={<CloudUploadOutlined />}
                  onClick={() => showModal()}
                >
                  Bulk Upload
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showDrawer}
                >
                  Single Candidate
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <Space
          style={{ justifyContent: "space-between", width: "100%" }}
        ></Space>
        <Table
          dataSource={tableData}
          columns={columns}
          scroll={{ x: "max-content" }}
          pagination={false}
          loading={loading}
        />
      </Card>
      <Modal
        title="Bulk Upload"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          id="bulkUploadForm"
          layout="vertical"
          onFinish={UploadFile}
        >
          <Row gutter={16}>
            <Col sm={24} md={12}>
              <Form.Item name="file" label="Upload">
                <Input
                  type="file"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => handleBulkQuestions(e.target.files[0])}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={6} style={{ paddingTop: "30px" }}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Upload
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <p>Guidelines:</p>
        <ol>
          <li>
            Download the upload sample of the excel file here :{" "}
            <a
              href={UploadFormat}
              className="pt-1 text-primary"
              style={{ cursor: "pointer" }}
            >
              Download Sample
            </a>
          </li>
          <li>
            Please don't remove or manipulate any header in the file format.
          </li>
        </ol>
      </Modal>
      <Drawer
        title={"Add Candidate"}
        width={450}
        onClose={onClose}
        open={openDrawer}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button form="addCandidate" type="primary" htmlType="submit">
              Add
            </Button>
          </div>
        }
      >
        <Form
          onFinish={uploadCandidate}
          id="addCandidate"
          form={addCandidate}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                required
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value.trim() === "") {
                        return Promise.reject(
                          new Error("This field is required")
                        );
                      }
                      if (/\d/.test(value)) {
                        return Promise.reject(
                          new Error("Name cannot contain numbers")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "This field is required" },
                  {
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="contact_no"
                label="Contact No."
                rules={[
                  { required: true, message: "This field is required" },
                  {
                    pattern: /^[0]?[6789]\d{9}$/,
                    message: "Please enter a valid mobile number",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Contact No."
                  maxLength={10}
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="date_of_birth"
                label="Birth Date"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <DatePicker
                  placeholder="Enter Birth Date"
                  disabledDate={disableFutureDates}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="address"
                label="Address"
                required
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value.trim() === "") {
                        return Promise.reject(
                          new Error("This field is required")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="source"
                label="Source"
                required
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value || value.trim() === "") {
                        return Promise.reject(
                          new Error("This field is required")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Example : LinkedIn" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"resume"}
                extra={resumeType == "upload" ? "PDF Upload Only" : ""}
                label={
                  <span>
                    Resume{" "}
                    <Radio.Group
                      style={{ marginTop: "10px" }}
                      value={resumeType}
                      size="small"
                      buttonStyle="solid"
                      onChange={(e) => {
                        setResumeType(e.target.value);
                        addCandidate.resetFields(["resume"]);
                        setResumeFile(null);
                      }}
                    >
                      <Radio.Button value="upload">Upload</Radio.Button>
                      <Radio.Button value="link">Link</Radio.Button>
                    </Radio.Group>
                  </span>
                }
                rules={[
                  { required: true, message: "This field is required" },
                  {
                    pattern: new RegExp(
                      /\b((http|https):\/\/)?(www\.)?([\w\-]+\.)+[\w\-]+(\/[\w\-.,@?^=%&:\/~+#]*)?\b/
                    ),
                    message: "Please enter a valid resume link",
                  },
                ]}
              >
                {resumeType == "link" ? (
                  <Input type="link" placeholder="Enter resume link" />
                ) : (
                  <Input
                    type="file"
                    accept="application/pdf"
                    footer="allowed file formats: pdf"
                    onChange={(e) => {
                      handleUpload(e);
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
