import React, { useState, useEffect } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Select, MenuItem } from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";
import MenuIcon from "@material-ui/icons/Menu";
import urls from "../url";
import useFetch from "../hoc/useFetch";
import { Fetch } from "../hoc/fetch";
import { useAlert } from "../hoc/alert";
import Loader from "../hoc/loader";
import Registration from "./registrationPage/registration";
import ReportProblemSharpIcon from "@material-ui/icons/ReportProblemSharp";
import ReactHtmlParser from "react-html-parser";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Link } from "react-router-dom";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Grid,
  withStyles,
  FormControl,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
  TextField,
  TableFooter,
  TablePagination,
  TableRow,
  Table,
  TableBody,
  TableCell,
  StepIcon
} from "@material-ui/core";
import { textAlign } from "@material-ui/system";
import TransferPage from "./transferPage/TransferPage";

const styles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  heading: {
    fontSize: "14px",
    fontWeight: "bold"
  },

  applyButton: {
    margin: "0px 0px"
  },
  Button: {
    borderRadius: "0px"
  },
  OpeningPage: {
    // background: "#dadada",
    padding: "20px",
    overflow: "Hidden"
  },

  typeLabel: {
    marginLeft: "7px"
  },
  openingPageStyle: {
    borderRadius: "0px",
    margin: "15px 0px",
    padding: "15px"
  },
  loginButton: {
    margin: "20px 0px",
    display: "grid"
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  paperrr: {
    padding: theme.spacing(1, 5, 1),
    overflow: true,
  },
  paper: {
    padding: "10px",
    borderRadius: "0px"
  },
  onScroll: {
    height: "245px",
    overflowY: "scroll"
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "0px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  // root: {
  //   color: primary,
  //   '&$checked': {
  //      color: primary,
  //   },
  // },
  Titleeeee: {
    rowsMax: "1"
  },

  checked: {}
}));

const ViewOpening = () => {

  const classes = styles();
  const [value, setValue] = useState("");
  const [checked, setChecked] = useState("");
  const [checkedLoc, setCheckedLoc] = useState("");
  const [open, setOpen] = React.useState(false);
  const [MobileNu, setMobileNu] = useState(null);

  const [readMore, setReadMore] = useState(true)
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState("");
  const [applytitle, setapplytitle] = useState("");
  const [page, setPage] = useState(0);
  const [jobSearch, setJobSearch] = useState("");
  const [radio, setRadio] = useState("y");
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [searchTrue, setSearchTrue] = useState("");
  const [showDesc, setShowDesc] = useState("enable");
  const [onetimePass, setOneTimePass] = useState(true);
  const [toggleState, setToggleState] = useState("off");
  const [applicantRoleName, setApplicantRoleName] = useState("");
  const [toBranchName, setToBranchName] = useState({});
  const [branch, setBranch] = useState("");
  const [city, setCity] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [gradeCheck, setGradeCheck] = useState('');
  const [subjectCheck, setSubjectCheck] = useState('');

  var toggleArray = [];
  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const loginValType = localStorage.getItem("loginValType");

  const alert = useAlert();
  const apply = (id, title, role, totBranchName) => {
    if (role) {
      setApplicantRoleName(role);
      setUserId(id);
      setapplytitle(title);
      setOpen(true);
      setToBranchName(totBranchName);
    } else {
      alert.error("OOPS..! --> For This Position Applicat Role does not Exist.Please,Update it")
    }
  };
  const applyOthers = () => {
    // setApplicantRoleName(role);
    // setUserId(id);
    // setapplytitle(title);
    setOpen(true);
    // setToBranchName(totBranchName);
  }

  const homeDetails = new URLSearchParams(window.location.search);
  if (window.location && window.location.search) {
    const obj = {
      params: homeDetails.has('q') && homeDetails.get('q'),
    }
    sessionStorage.setItem('origin', JSON.stringify(obj));
  }

  const handleMobileRadio = val => {
    setRadio(val);
  };
  const handleEmailRadio = val => {
    setRadio(val);
  };
  const handleClose = () => {
    setOpen(false);
    setMobileNu("");
  };
  const {
    data: otpResponse,
    isLoading: otpResponseLoading,
    doFetch: mobilrNumberVerified
  } = useFetch(null);
  const {
    data: otpVerify,
    isLoading: otpVerifyLoading,
    doFetch: otpVerified
  } = Fetch(null);
  const {
    data: roles,
    isLoading: rolesLoading,
    doFetch: fetchRoles
  } = useFetch([], { suppressAlert: true });
  const {
    data: branchList,
    isLoading: gettingBranches,
    doFetch: fetchBranches
  } = useFetch([], { suppressAlert: true });

  const {
    data: cityList,
    isLoading: gettingCity,
    doFetch: fetchCity
  } = useFetch([], { suppressAlert: true });

  const {
    data: openingData,
    isLoading: openingLoading,
    doFetch: fetchOpening
  } = useFetch(null);

  const {
    data: transferData,
    isLoading: transferLoading,
    doFetch: fetchTransfer
  } = useFetch(null);

  const {
    data: grades,
    isLoading: gradesLoading,
    doFetch: fetchGrades
  } = useFetch([], { suppressAlert: true });

  const {
    data: subjects,
    isLoading: subjectsLoading,
    doFetch: fetchSubjects
  } = useFetch([], { suppressAlert: true });

  const categoryList = [
    {
      value: "1",
      label: "Academic"
    },
    {
      value: "2",
      label: "Non Academic"
    },
    {
      value: "3",
      label: "IT Recruitment"
    },
    {
      value: "4",
      label: "Marketing and Communications",
    },
    {
      value: "5",
      label: "Sales and Business Development",
    }
  ];

  // useEffect(() => {
  //   setSelectedOptions([{ label: "All", value: "*" }, ...options]);
  // }, []);


  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }


  useEffect(() => {
    if (otpResponse && (otpResponse.applicant_role || otpResponse.register_date)) {
      setOpen(false);
      setMobileNu("");
      alert.warning(
        otpResponse.applicant_role ?
          `You have already Registered for ${otpResponse.applicant_role
            .applicant_role_name || ""} Role on ${otpResponse.register_date ||
            ""}` : `You have already Registered on ${otpResponse.register_date ||
            ""}`
      );
      setOneTimePass(false);
    } else if (otpResponse === "otp generated") {
      alert.success(
        "An OTP has been sent to your registered mobile-No / email-Id"
      );
      setOpen(true);
      setOneTimePass(true);
    }
  }, [alert, otpResponse]);

  useEffect(() => {
    if (otpVerify && otpVerify.response !== "success") {
      alert.warning(otpVerify.response);
    } else if (otpVerify && otpVerify.response === "success") {
      alert.success("OTP verified successfully");
    }
  }, [alert, otpVerify]);

  useEffect(() => {
    if (value) {
      fetchRoles({
        url: `${urls.RoleListApi}?position_type=${value}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });
      fetchGrades({
        url: `${urls.newGrade}?position_type=${value}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
    }
  }, [fetchRoles, value])

  useEffect(() => {
    if (gradeCheck) {
      fetchSubjects({
        url: `${urls.viewSubjectApiView}?grade_id=${gradeCheck}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
    }
  }, [gradeCheck, fetchSubjects])

  useEffect(() => {
    fetchCity({
      url: urls.cityList,
      flag: false,
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
    // if (loginValType === "viewOpening") {
    //   fetchOpening({
    //     url: `${urls.fetchOpeningData}?opening_type=${value}`,
    //     method: "GET",
    //     flag: false,
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   });
    // }
    if (loginValType === "viewTransfer") {
      fetchTransfer({
        url: urls.fetchTransferData,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });
    }
  }, [fetchRoles, fetchOpening, fetchTransfer, fetchCity, loginValType]);

  useEffect(() => {
    if (checkedLoc) {
      fetchBranches({
        url: `${urls.branchCityMapping}?city_id=${checkedLoc}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      })
    }
  }, [checkedLoc])

  const VerifyMobileNu = () => {
    if (MobileNu == null) {
      alert.warning("Please First Enter Mobile OR Email..!");
      return;
    }
    if (radio === "z") {
      if (MobileNu.match(/[a-z@]/i)) {
        const filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(MobileNu)) {
          alert.warning("Please enter valid Email...");
          return;
        }
      } else if (MobileNu.match(/[0-9]+/)) {
        alert.warning("Please enter valid Email Not a Number...");
        return;
      }
    } else {
      var IndNum = /^[0]?[56789]\d{9}$/;
      if (!IndNum.test(MobileNu)) {
        alert.warning("Please enter valid mobile number...!");
        return;
      }
    }

    mobilrNumberVerified({
      url: `${urls.mobileNumSendUrl}?${radio === "y" ? "mobile_number" : "email"
        }=${MobileNu}`,
      method: "Get",
      flag: true,
      headers: {
        "Content-Type": "application/json"
      }
    });
  };
  const VerifyOtp = () => {
    otpVerified({
      url: `${urls.otpVerifyurl}?otp=${otp}&mobile_number=${MobileNu}`,
      method: "Get",
      flag: true,
      headers: {
        "Content-Type": "application/json"
      }
    });
  };


  useEffect(() => {
    if (page && loginValType === "viewOpening") {
      fetchOpening({
        url: `${urls.fetchOpeningData}?opening_type=${value}&applicant_role=${checked}&location=${checkedLoc}&branch_id=${branch}&page=${page + 1}&page_size=${20}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });

    } else if (page && loginValType === "viewTransfer") {
      fetchTransfer({
        url: urls.fetchTransferData,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });

    }
  }, [page, fetchOpening, fetchTransfer, loginValType, value, checked, checkedLoc, branch]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
    if (!newPage && loginValType === "viewOpening") {
      fetchOpening({
        url: `${urls.fetchOpeningData}?opening_type=${value}&applicant_role=${checked}&location=${checkedLoc}&branch_id=${branch}&page_size=${20}`,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });
    } else if (!newPage && loginValType === "viewTransfer") {
      fetchTransfer({
        url: urls.fetchTransferData,
        method: "GET",
        flag: false,
        headers: {
          "Content-Type": "application/json"
        }
      });
    }
  }

  useEffect(() => {
    if (jobSearch) {
      setSearchTrue(true);
    }
  }, [jobSearch]);

  useEffect(() => {
    if (value) {
      setChecked([])
    }
  }, [value])

  useEffect(() => {
    if (
      (jobSearch.length >= 4 && loginValType === "viewOpening") ||
      (jobSearch.length === 0 && searchTrue && loginValType === "viewOpening")
    ) {
      fetchOpening({
        url: `${urls.fetchOpeningData}?opening_type=${value}&searching=${jobSearch}`,
        method: "get",
        flag: true,
        headers: {
          "Content-Type": "application/json"
        }
      });
    } else if (
      (jobSearch.length >= 4 && loginValType === "viewTransfer") ||
      (jobSearch.length === 0 && searchTrue && loginValType === "viewTransfer")
    ) {
      fetchTransfer({
        url: `${urls.fetchTransferData}?searching=${jobSearch}`,
        method: "GET",
        flag: true,
        headers: {
          "Content-Type": "application/json"
        }
      });
    }
  }, [fetchOpening, fetchTransfer, jobSearch, loginValType, searchTrue, value]);

  var fetchSearch = e => {
    let { target: { value: jobSearch = "" } = {} } = e;
    setJobSearch(jobSearch);
    if (e.keyCode === 13) {
      if (
        (jobSearch.length >= 4 || jobSearch.length === 0) &&
        loginValType === "viewOpening"
      ) {
        fetchOpening({
          url: `${urls.fetchOpeningData}?searching=${jobSearch}`,
          method: "get",
          flag: true,
          headers: {
            "Content-Type": "application/json"
          }
        });
      } else if (
        (jobSearch.length >= 4 || jobSearch.length === 0) &&
        loginValType === "viewTransfer"
      ) {
        fetchTransfer({
          url: `${urls.fetchTransferData}?searching=${jobSearch}`,
          method: "GET",
          flag: true,
          headers: {
            "Content-Type": "application/json"
          }
        });
      }
    }
  };

  function handleBranch(e) {
    let eventIndex = e.target.value[e.target.value.length - 1];

    // eventIndex===branchList[0].id?setBranch([eventIndex]):e.target.value[0]===branchList[0].id?setBranch(e.target.value.shift()):setBranch(e.target.value);

    if (eventIndex === branchList[0].id) {
      setBranch([eventIndex])
    } else {
      if (e.target.value[0] === branchList[0].id) {
        setBranch(e.target.value.shift());
      }
      setBranch(e.target.value);
    }
  }


  function handleCity(e){
    setGradeCheck('')
    setSubjectCheck('')
    if(e){
      setCheckedLoc(e.target.value)
    }
  }

  function handleGrade(e){
    setGradeCheck('')
    if(e){
      setGradeCheck(e.target.value);
    }

  }

  function openingTypeChange(event) {
    setValue(event.target.value);
  }
  const filterBy = () => {
    if(!value){
      alert.warning("please select category");
      return;   
     }
     if(checked == ""){
      alert.warning("please select role");
      return;   
     }
     if(!checkedLoc){
      alert.warning("please select city");
      return;   
     }
  //    if(value == "Academic" || value == " Non Academic"){
  //   if(!branch){
  //     alert.warning("please select branch");
  //     return;   
  //    }
  // }
    
     
    if (loginValType === "viewOpening" && value && checked && checkedLoc) {
      fetchOpening({
        url: `${urls.fetchOpeningData}?opening_type=${value}&applicant_role=${checked}&location=${checkedLoc}&grade=${value == "Academic"?gradeCheck:""}&branch_id=${value == "Academic"?branch:""}&subject=${value == "Academic"?subjectCheck:""}&page=${page + 1}&page_size=${20}`,
        method: "get",
        flag: true,
        headers: {
          "Content-Type": "application/json"
        }
      });
    } else if(loginValType === "viewTransfer" && value && checked && checkedLoc) {
      fetchTransfer({
        url: `${urls.fetchTransferData}?opening_type=${value}&applicant_role=${checked}&location=${checkedLoc}&branch_id=${branch}&grade=${value == "Academic"?gradeCheck:""}&subject=${value == "Academic"?subjectCheck:""}`,
        method: "GET",
        flag: true,
        headers: {
          "Content-Type": "application/json"
        }
      });
    }
    else {
      alert.warning("Please Select all Fields");
    }
  };
  const handleRoleValue = value => () => {
    const currentIndex = checked.indexOf(value);

    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const handleLocationValue = data => () => {
    const cIndex = checkedLoc.indexOf(data);
    const nChecked = [...checkedLoc];
    if (cIndex === -1) {
      nChecked.push(data);
    } else {
      nChecked.splice(cIndex, 1);
    }
    setCheckedLoc(nChecked);
  };


  let loader = null;
  if (
    rolesLoading ||
    otpVerifyLoading ||
    openingLoading ||
    otpResponseLoading ||
    transferLoading ||
    gettingBranches ||
    gettingCity ||
    gradesLoading ||
    subjectsLoading
  ) {
    loader = <Loader open />;
  }

  function truncate(str, no_words) {
    return str
      .split(" ")
      .splice(0, no_words)
      .join(" ");
  }
  function toggle(id) {
    toggleArray.push(id);
    setToggleState(toggleState === "off" ? "on" : "off");
  }

  const openingList = finalData => {

    let opening = null;
    if (finalData && finalData.results && finalData.results.length > 0) {
      opening = finalData.results.map((data, id) => (
        <div className={classes.root} style={{ marginBottom: "2px" }}>
          <ExpansionPanel style={{ backgroundColor: "#ffe5b4" }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Grid container md={12}>
                <Grid item md={4} xs={12}>
                  <Typography
                    variant='h8'
                    style={{ fontWeight: "bold", float: "left" }}
                  >
                    {data.title || ""}
                  </Typography>
                </Grid>
                <Grid item md={5} xs={12}>
                  <Typography
                    variant='h8'
                    style={{ fontWeight: "bold", float: "center" }}
                  >
                    {(data.branch && data.branch.branch_name) || ""}
                    {data.branch && data.branch.branch_name ? "," : ""}
                    {(data.city && data.city.code_name) || ""}
                  </Typography>
                </Grid>
                <Grid item md={2} xs={12}>
                  <Typography
                    variant='h8'
                    style={{ fontWeight: "bold", float: "right" }}
                  >
                    {data.position_created_ago || ""}
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails style={{ backgroundColor: "white" }}>
              <Grid container md={12}>
                <Grid item md={3} xs={12}>
                  <Typography style={{fontSize: "14px"}}>
                    <strong>Experience:</strong> {(data && data.experience) || ""}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography style={{fontSize: "14px"}}>
                    <strong>Skills:</strong> {data.skill || ""}
                  </Typography>
                </Grid>
                {value === "IT Recruitment" ? "" :
                  <>
                    <Grid item md={3} xs={12}>
                      <Typography style={{fontSize: "14px"}}>
                        <strong>Grade:</strong> {(data.grade && data.grade.grade_name) || ""}{" "}
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Typography style={{fontSize: "14px"}}>
                        <strong>Subject:</strong> {(data.grade && data.subject.subject_name) || ""}{" "}
                      </Typography>
                    </Grid>
                  </>
                }
                <Grid item md={12} xs={12}>

                  <label style={{ fontWeight: "bold" }}>
                    Description :{" "}
                  </label>


                  {

                    data.description &&
                    parseInt(
                      data.description
                        .replace(/(<([^>]+)>)/gi, "")
                        .split(" ")
                        .splice(0, 20)
                        .join(" ").length
                    ) > 2 && (
                      <div>
                        {readMore ? (<Typography>
                          {ReactHtmlParser(truncate(data.description, 10))}{" "}
                        </Typography>
                        ) : (<Typography>
                          {ReactHtmlParser(truncate(data.description, 10000))}{" "}
                        </Typography>)
                        }
                        <u value={readMore} onClick={() => setReadMore(!readMore)} style={{ color: "blue", textAlign: "center", cursor: 'pointer' }}>
                          {
                            !readMore ? "Read Less..." : "...Read More"
                          }
                        </u>
                      </div>
                    )
                  }


                </Grid>
                <Grid item md={3} xs={12}>
                  <Typography style={{fontSize: "14px"}}>
                    <strong>Salary:</strong> {data.salary || ""} LPA
                  </Typography>
                </Grid>
                <Grid md={12} className={classes.applyButton}>
                  <Button
                    className={classes.Button}
                    color='primary'
                    variant='contained'
                    size='large'
                    style={{ float: "right" }}
                    onClick={() =>
                      apply(
                        data.id,
                        data.title,
                        data.applicant_role &&
                          data.applicant_role.applicant_role_name
                          ? data.applicant_role.applicant_role_name
                          : null,
                        data.branch
                      )
                    }
                  >
                    Apply Now
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      ));
    }
    return opening;
  };
  const jobApplySection = () => (
    <React.Fragment>
      <Grid className={classes.root}>
        <AppBar position='static'>
          <Toolbar>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='inherit'
              aria-label='open drawer'
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant='h6' noWrap>
              CareerBox
            </Typography>
            <Link to='/' style={{ color: "white", marginRight: "15px" }}>
              Login
            </Link>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder='Search…'
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                id='search-bar'
                inputProps={{ "aria-label": "search" }}
                onKeyDown={fetchSearch}
              />
            </div>
          </Toolbar>
        </AppBar>
      </Grid>
      <Grid className={classes.OpeningPage}>
        <Typography variant='h4'><strong>Openings</strong></Typography>
        <Divider className={classes.divider} />
        {/* <Paper className={classes.paperrr}> */}
        <Grid container spacing={2} className={classes.paperrr}>
          <Grid item md={3} xs={12}>
            <Typography>Category<strong style={{ color: 'red' }}>*</strong></Typography>
            <Select
              required
              fullWidth
              margin='dense'
              value={value || ""}
              onChange={e => setValue(e.target.value)}
              variant='outlined'
            >
              {categoryList.map(option => (
                <MenuItem key={option.id} value={option.label} name={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>Role<strong style={{ color: 'red' }}>*</strong></Typography>
            <Select
              required
              fullWidth
              // multiple
              margin='dense'
              value={checked || ""}
              onChange={e => setChecked(e.target.value)}
              variant='outlined'
            >
              {roles && roles && roles.length > 0 &&
                roles.map(option => (
                  <MenuItem key={option.value} value={option.id} name={option.id}>
                    {option.applicant_role_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>City<strong style={{ color: 'red' }}>*</strong></Typography>
            <Select           
              required
              fullWidth
              // multiple
              margin='dense'
              value={checkedLoc || ""}
              // onChange={e => setCheckedLoc(e.target.value)}
              onChange={handleCity}
              variant='outlined'
            >
              {cityList && cityList.length !== 0 &&
                cityList.map(option => (
                  <MenuItem key={option.value} value={option.id} name={option.id}>
                    {option.city_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
           {
            value == "Academic"?
          
          <Grid item md={3} xs={12}>
            <Typography>Branch<strong style={{ color: 'red' }}>*</strong></Typography>
            <Select
              required
              fullWidth
              // multiple
              margin='dense'
              value={branch || ""}
              onChange={handleBranch}
              variant='outlined'
            >
              {branchList && branchList.length !== 0 &&
                branchList.map(option => (
                  <MenuItem key={option.value} value={option.id} name={option.id}>
                    {option.branch_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          : null} 
         {value == "Academic" &&
          <Grid item md={3} xs={12}>
            <Typography>Grade</Typography>
            <Select
              required
              fullWidth
              margin='dense'
              value={gradeCheck || ""}
              // onChange={e => setGradeCheck(e.target.value)}
              onChange={handleGrade}
              variant='outlined'
            >
              {grades  && grades.map(option => (
                <MenuItem key={option.id} value={option.id} name={option.id}>
                  {option.grade_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
}
{value == "Academic" &&

          <Grid item md={3} xs={12}>
            <Typography>Subject</Typography>
            <Select
              required
              fullWidth
              margin='dense'
              value={subjectCheck || ""}
              onChange={e => setSubjectCheck(e.target.value)}
              variant='outlined'
            >
              {subjects && subjects && subjects.length > 0 && subjects.map(option => (
                <MenuItem key={option.subject.id} value={option.subject.id} name={option.subject.id}>
                  {option.subject.subject_name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
}
        
          <Grid item md={1} xs={12} style={{ marginTop: '10px' }}>
            <Button
              style={{ marginTop: '10px' }}
              color='primary'
              fullWidth
              variant='contained'
              size='large'
              onClick={filterBy}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        {/* </Paper> */}
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item md={2} />
          <Grid item xs={12} md={8}>
            {(openingData &&
              openingData.results &&
              openingData.results.length === 0) ||
              (transferData &&
                transferData.results &&
                transferData.results.length === 0) && value && checked && checkedLoc && branch ? (
              // <>
              //   <ReportProblemSharpIcon
              //     style={{ marginTop: "10%" }}
              //     fontSize='large'
              //     color='action'
              //   />
              //   <p style={{ marginTop: "1%" }}>
              //     Not Able to Find Suitable Openings ?{" "}
              //   </p>
              //   <p style={{ marginTop: "1%" }}>
              //     No Worry, Still you apply to us and we will review your
              //     application{" "}
              //   </p>
              //   <Button
              //     className={classes.Button}
              //     color='primary'
              //     variant='contained'
              //     size='large'
              //     onClick={() => applyOthers()}
              //   >
              //     Apply Here
              //   </Button>
              // </>
              ""
            ) : (
              <div>
                {(openingData &&
                  openingData.results &&
                  openingData.results.length !== 0) ||
                  (transferData &&
                    transferData.results &&
                    transferData.results.length !== 0) ? "" : (
                  <div style={{ marginTop: "10%", textAlign: "center" }}>
                    <h2>No Records Found</h2>
                    <h4 style={{ color: '#97abe5' }}>Search Openings Using Category, Role, City and Branch</h4>
                  </div>
                )}
              </div>

            )}
            {(openingData || transferData) && (
              <Grid>
                {openingList(openingData || transferData)}
                {((openingData && openingData.count) ||
                  (transferData && transferData.count)) && (
                    <>
                      <Paper className={classes.openingPageStyle}>
                        <Grid item xs={12}>
                          <TableFooter>
                            <TableRow className={classes.tableFooter}>
                              <TablePagination
                                // className={classes.paginationSegment}
                                component='div'
                                colSpan={3}
                                count={
                                  openingData
                                    ? +openingData.count
                                    : +transferData.count
                                }
                                rowsPerPageOptions={false}
                                rowsPerPage={20}
                                labelRowsPerPage={false}
                                page={page}
                                SelectProps={{
                                  // inputProps: { 'aria-label': 'Rows per page' },
                                  native: false
                                }}
                                onChangePage={handleChangePage}
                              />
                            </TableRow>
                          </TableFooter>
                        </Grid>
                      </Paper>
                      <Paper className={classes.openingPageStyle}>
                        {/* <Grid xs={12} md={12} style={{ textAlign: 'center' }}>
                          <p style={{ marginTop: "1%" }}>
                            Not Able to Find Suitable Openings ?{" "}
                          </p>
                          <p style={{ marginTop: "1%" }}>
                            No Worry, Still you apply to us and we will review your
                            application{" "}
                          </p>
                          <Button
                            className={classes.Button}
                            color='primary'
                            variant='contained'
                            size='large'
                            onClick={() => applyOthers()}
                          >
                            Apply Others
                          </Button>
                        </Grid> */}
                      </Paper>
                    </>
                  )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={open}>
            <Grid className={classes.modalPaper}>
              <h2 id='transition-modal-title'>Verify With Us</h2>
              <div>
                <Radio
                  checked={selectedValue === "a"}
                  onChange={handleChange}
                  value='a'
                  hideradio
                  name='radio-button-demo'
                  inputProps={{ "aria-label": "A" }}
                  onClick={() => handleMobileRadio("y")}
                />{" "}
                Mobile
                <Radio
                  checked={selectedValue === "b"}
                  onChange={handleChange}
                  value='b'
                  hideradio
                  name='radio-button-demo'
                  inputProps={{ "aria-label": "B" }}
                  onClick={() => handleEmailRadio("z")}
                />
                Email
              </div>
              {otpResponse && onetimePass ? (
                <label>Enter OTP :</label>
              ) : (
                <span>
                  {selectedValue === "a" ? (
                    <label>Enter Mobile Number :</label>
                  ) : (
                    <label>Enter Email Id :</label>
                  )}
                </span>
              )}
              {otpResponse && onetimePass ? (
                <React.Fragment>
                  <TextField
                    label='Otp'
                    type='Number'
                    margin='dense'
                    required
                    fullWidth
                    value={otp}
                    onChange={e => setOtp(e.target.value)}
                    variant='outlined'
                  />
                  <Button
                    className={classes.Button}
                    color='primary'
                    variant='contained'
                    size='large'
                    onClick={VerifyOtp}
                  >
                    Otp Verify
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TextField
                    label={radio === "y" ? "Mobile" : "Email"}
                    type={radio === "y" ? "number" : "email"}
                    margin='dense'
                    required
                    fullWidth
                    value={MobileNu}
                    onChange={e =>
                      setMobileNu(
                        radio === "y"
                          ? Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 10)
                          : e.target.value
                      )
                    }
                    variant='outlined'
                  />
                  <Button
                    className={classes.Button}
                    color='primary'
                    variant='contained'
                    size='large'
                    onClick={VerifyMobileNu}
                  >
                    {radio === "y" ? "Verify Mobile " : "Verify E-mail"}
                  </Button>
                </React.Fragment>
              )}
            </Grid>
          </Fade>
        </Modal>
      </Grid>
      {loader}
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <>
        {otpVerify &&
          otpVerify.response === "success" &&
          loginValType === "viewOpening" ? (
          <Registration
            jobId={userId}
            title={applytitle}
            mobNu={MobileNu}
            applicantRoleName={applicantRoleName}
          />
        ) : otpVerify &&
          otpVerify.response === "success" &&
          loginValType === "viewTransfer" ? (
          <TransferPage branchName={toBranchName} />
        ) : (
          jobApplySection()
        )}
      </>
      {loader}
    </React.Fragment>
  );
};
// ViewOpening.propTypes = {
//   classes: PropTypes.instanceOf(Object).isRequired,
// };
export default withStyles(styles)(ViewOpening);
