import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  TableFooter,
  TablePagination,
  Paper,
  IconButton,
} from '@material-ui/core';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import styles from './scoreAssignedBy.Style';
import Icon from '@material-ui/core/Icon';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useAlert } from '../../../hoc/alert/alert'

const ScoreAssignedBy = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [value, setValue] = React.useState(0);
  const [Id, setId] = React.useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pagination ,setPagination] = useState(false)
  const [pageChange , setPageChange] = useState(false)
  const [hRef, setHRef] = useState('');
  const alert = useAlert();

  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id)
  }


  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);


  let loader = null;
  if (applicantsListLoading || profileDetailLoading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.FaceToFaceRolesApi,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      });
    }
  }, [fetchProfile, name]);


  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setId(profileDetail[0].id);
    }
  }, [profileDetail])

  useEffect(() => {
    setHRef([{ csv:`${urls.getCSVfileAPi}?role_id=${Id}&start_data=${startDate}&end_date=${endDate}&export_type=csv`}]);
}, [setHRef, startDate, endDate]);


  useEffect(() => {
    if (Id){
      setPageChange(false)
      setStartDate('')
      setEndDate('')
      fetchApplicant({
        url: `${urls.ScoreAssignedApi}?role_id=${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [Id, fetchApplicant, name])

  useEffect(()=>{
    if(applicantsList){
    setPageChange(true)
    }
  },[applicantsList])

  const handleStartDateChange = (val) => {
    setStartDate(val)
  }
  const handleEndDateChange = (val) => {
    setEndDate(val);
  }

  const handleGetData = () => {
    if (startDate === '') {
      alert.warning('Please Select Start Date..!')
      return;
    }
    if (endDate === '') {
      alert.warning('Please Select End Date..!')
      return;
    }
    setPage(0)
    fetchApplicant({
      url: `${urls.ScoreAssignedApi}?role_id=${Id}&page_size=${rowsPerPage || 50}&page=1&start_date=${startDate}&end_date=${endDate}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }
 

  useEffect(() => {
    if (pagination) {
      fetchApplicant({
        url: `${urls.ScoreAssignedApi}?&role_id=${Id}&page=${page + 1}&page_size=${rowsPerPage || 50}${startDate ? `&start_date=${startDate}`:''}${endDate ? `&end_date=${endDate}`:''}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
    setPagination(false)
  }, [page, fetchApplicant, rowsPerPage, name, Id]);


  function handleChangePage(event, newPage) {
    setPagination(true)
    setPage(newPage)
   {!rowsPerPage && setRowsPerPage(50)} 
  }

  function handleChangeRowsPerPage(event) {
    setPagination(true)
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPagination(true)
    setPage(0)
  }
    
  function lastPageChange(lastPage) {
    setPagination(true)
    setPage(lastPage)
    {!rowsPerPage && setRowsPerPage(50)} 
  };

  function profileTable() {
    let applicantTable = null;
    if(applicantsList && applicantsList.results && applicantsList.results.length===0 && pageChange){
      applicantTable = (
        <React.Fragment>
          <Typography variant='h5' style={{color:'blue',textAlign:'center',marginTop:'17%'}}>
            Records Not Found
          </Typography>
        </React.Fragment>
      )
    }
    if (applicantsList && applicantsList.results && applicantsList.results.length && pageChange) {
      applicantTable = (
        <React.Fragment>
          <Paper style={{ marginTop: '10px' }}>
          <Table>
            <TableHead>
            <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">Applicant Name</TableCell>
                  <TableCell align="left">Registration Data</TableCell>
                  <TableCell align="left">Recruiter Name</TableCell>
                  <TableCell align="left">MCQ Test <br/> (Score out of 50)</TableCell>
                  <TableCell align="left">Written Test  <br/> (Score out of 30)</TableCell>
                  <TableCell align="left">Spoken Test  <br/> (Score out of 20)</TableCell>
                  <TableCell align="left">Round 1 <br/> (Score out of 20) </TableCell>
                  {(Id === 3 || Id === 4) && <TableCell align="left">Round 2 <br/> (Score out of 20)</TableCell>}
                  {Id === 4 &&  <TableCell align="left">Round 3 <br/> (Score out of 20)</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
              {applicantsList.results.map((details, i) => (
                <TableRow key={details.id}>
                  <TableCell align="left">{i+1}</TableCell>
                  <TableCell align="left">{(details.applicant && details.applicant.user && details.applicant.user.first_name && details.applicant.user.first_name) || ''}</TableCell>
                  <TableCell align="left">{(details.applicant && details.applicant.register_date) || ''}</TableCell>
                  <TableCell align="left">{(details.recruiter && details.recruiter && details.recruiter.first_name) || ''}</TableCell>
                  <TableCell align="left">{(details.mcq_score && details.mcq_score.mark_scored) || 'Not Mentioned'}</TableCell>
                  <TableCell align="left">{(details.written_score && details.written_score.mark_scored) || 'Not Mentioned'}</TableCell>
                  {(details.spoken_score && details.spoken_score.mark_scored && <TableCell align="left">
                       Score : {(details.spoken_score && details.spoken_score.mark_scored) || ''}<br/>
                       Uploaded By : {(details.spoken_score && details.spoken_score.user && details.spoken_score.user.first_name) || 'Not Mentioned'}
                    </TableCell> )||
                   <TableCell align="left">
                    Not Mentioned
                   </TableCell>
                   }
                   {(Id===2 && details.f2f_score && details.f2f_score.length !==0 && details.f2f_score.filter(check =>check.round.round_name === "Planner Round").map((items)=>{return items.mark_scored}).length!==0 &&
                   <TableCell align="left">
                       Score : {(details.f2f_score && details.f2f_score && details.f2f_score.filter(check =>check.round.round_name === "Planner Round").map((items)=>{return items.mark_scored})) || 'Not Mentioned'}<br/>
                       Uploaded By : {(details.f2f_score && details.f2f_score && details.f2f_score.filter(check =>check.round.round_name === "Planner Round").map((items)=>{return items.user.first_name})) || 'Not Mentioned'}
                   </TableCell>) ||(Id===2 && <TableCell align="left">Not Mentioned</TableCell>)
                    }

                  {((Id===3 || Id===4) && details.f2f_score && details.f2f_score.length !==0 && details.f2f_score.filter(check =>check.round.round_name === "Stress Round").map((items)=>{return items.mark_scored}).length!==0 &&
                  <TableCell align="left">
                        Score : {(details.f2f_score && details.f2f_score && details.f2f_score.filter(check =>check.round.round_name === "Stress Round").map((items)=>{return items.mark_scored})) || 'Not Mentioned'}<br/>
                        Uploaded By : {(details.f2f_score && details.f2f_score && details.f2f_score.filter(check =>check.round.round_name === "Stress Round").map((items)=>{return items.user.first_name})) || 'Not Mentioned'}
                  </TableCell>)||((Id===3 || Id===4) && <TableCell align="left">Not Mentioned</TableCell>)
                }

                  {((Id === 3 || Id === 4) && details.f2f_score && details.f2f_score.length!==0 && details.f2f_score.filter(check =>check.round.round_name === "Leadership Round").map((items)=>{return items.mark_scored}).length!==0 &&
                     <TableCell align="left">
                          Score:{(details.f2f_score && details.f2f_score && details.f2f_score.filter(check =>check.round.round_name === "Leadership Round").map((items)=>{return items.mark_scored})) || 'Not Mentioned'}<br/>
                          Uploaded By : {(details.f2f_score && details.f2f_score && details.f2f_score.filter(check =>check.round.round_name === "Leadership Round").map((items)=>{return items.user.first_name})) || 'Not Mentioned'}
                     </TableCell>) || ((Id===3 || Id===4)&& <TableCell align="left">Not Mentioned</TableCell>)
                    }

                  {(Id === 4 && details.f2f_score && details.f2f_score.length !==0 && details.f2f_score.filter(check =>check.round.round_name === "Cultural Fit Round").map((items)=>{return items.mark_scored}).length
                  !==0 &&
                     <TableCell align="left">
                            Score : {(details.f2f_score && details.f2f_score && details.f2f_score.filter(check =>check.round.round_name === "Cultural Fit Round").map((items)=>{return items.mark_scored})) || 'Not Mentioned'}<br/>
                            Uploaded By : {(details.f2f_score && details.f2f_score && details.f2f_score.filter(check =>check.round.round_name === "Cultural Fit Round").map((items)=>{return items.user.first_name})) || 'Not Mentioned'}
              </TableCell>)|| (Id === 4 && <TableCell align="left">Not Mentioned</TableCell>) }

                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TableFooter>
              <TableRow className={classes.tableFooter}>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+applicantsList.total_pages}`}
                  rowsPerPageOptions={[50, 75, 100]}
                  count={+applicantsList.count}
                  rowsPerPage={rowsPerPage || 50}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(applicantsList.total_pages - 1)}
                    disabled={page === (+applicantsList.total_pages - 1)}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
            </Paper>
        </React.Fragment>
      )
    }
    return applicantTable;
  }

  return (
    <React.Fragment>
       <Grid container spacing={2} className={classes.modalPaper}>
         <Grid item sm={12} md={4} xs={12}>
          <Typography variant="h4">Applicant History</Typography>
         </Grid>
         <Grid item sm={5} md={3} xs={5}>
          <label style={{marginLeft:'5px'}}>Registration Date</label>
           <TextField
              style={{ marginLeft: '10px' }}
              helperText="Start Date"
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={e => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || ' '}
              variant="outlined"
            />
        </Grid>
        <Grid item sm={5} md={3} xs={5}>
          <TextField
            style={{ marginLeft: '10px',marginTop:'28px' }}
            helperText="End Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={e => handleEndDateChange(e.target.value)}
            type="date"
            value={endDate || ' '}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={2} md={2} xs={2}>
          <Button variant="contained" color="primary" className={classes.button} style={{ marginTop: '10px', marginLeft: '10px',marginTop:'28px' }} onClick={handleGetData}>Get</Button>
          {applicantsList && applicantsList.results && 
           <Button
           variant="contained" 
           color="primary" 
           className={classes.button} 
           href={hRef[0].csv}
           style={{ marginTop: '10px', marginLeft: '10px',marginTop:'28px' }}>CSV</Button> || ''}
        </Grid>
       </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} className={classes.paperMain}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {profileDetail && profileDetail.length && profileDetail.map((role, i) => (
                <Tab style={{width:'500px',marginRight:'5%',marginLeft:'5%'}} key={role.id} label={role.applicant_role_name} />
              ))}
            </Tabs>
          </AppBar>
            {profileTable()}
        </Grid>
      </Grid>
      {loader}
    </React.Fragment>
  )
}

ScoreAssignedBy.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(ScoreAssignedBy);
