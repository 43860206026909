import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Table,
  Space as AntdSpace,
  Tag as AntdTag,
  Popconfirm as AntdPopconfirm,
  Switch as AntdSwitch,
  Button,
  Drawer as AntdDrawer,
  Select,
  Space,
  Card,
  message,
  Typography,
} from "antd";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import urls from "../../../url";
import axios from "axios";
import { useForm } from "antd/es/form/Form";

const EmployeeListNew = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isActive, setIsactive] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [employeeListData, setEmployeeListData] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [roleData, setRoleData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [budgetApproverList, setBudgetApproverList] = useState([
    {
      id: 1,
      value: true,
      label: "Yes",
    },
    {
      id: 2,
      value: false,
      label: "No",
    },
  ]);
  const [form] = useForm();
  const [filterForm] = useForm();

  useEffect(() => {
    fetchRoleData();
    fetchCityList();
  }, []);

  useEffect(() => {
    handleGetData();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (isUpdate) {
      fetchAllBranchList();
    }
  }, [isUpdate]);

  const showDrawer = () => {
    setVisible(true);
    fetchAllBranchList();
  };

  const handleSearch = () => {
    let { role, city, branch } = filterForm.getFieldsValue();
    let obj = {
      page: 1,
      page_size: rowsPerPage || 50,
    };
    if (role) {
      obj.role_id = role;
    }
    if (city) {
      obj.city = city;
    }
    if (branch) {
      obj.branch = branch;
    }
    if (search) {
      obj.search = search;
    }
    listEmpDetails(obj);
  };

  const fetchCityList = (params = {}) => {
    axios
      .get(`${urls.cityList}`, { params: params })
      .then((response) => {
        if (response?.status === 200) {
          setCityList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      });
  };

  const fetchBranchList = (params = {}) => {
    axios
      .get(`${urls.branchCityMapping}`, {
        params: params,
      })
      .then((response) => {
        if (response?.status === 200) {
          setBranchList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      });
  };

  const fetchAllBranchList = () => {
    axios
      .get(`${urls.branch}`)
      .then((response) => {
        if (response?.status === 200) {
          setBranchList(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      });
  };

  const handleCityChange = () => {
    const city = filterForm.getFieldsValue()?.city;
    filterForm.setFieldsValue({
      branch: null,
    });
    if (city) {
      fetchBranchList({
        city_id: city,
      });
    } else {
      setBranchList([]);
    }
  };

  const listEmpDetails = (params = {}) => {
    setLoading(true);
    axios
      .get(`${urls.retrieveEmployeeList}`, { params: params })
      .then((response) => {
        if (response?.status === 200) {
          setEmployeeListData(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClose = () => {
    setVisible(false);
    setSelectedRoleId(null);
    setIsUpdate(false);
    form.resetFields();
  };

  const createEmployee = (data) => {
    axios
      .post(`${urls.employeeRegistration}`, data)
      .then((response) => {
        if (response?.status === 201) {
          listEmpDetails({
            role_id: role,
            page: 1,
            page_size: rowsPerPage || 50,
            search: search,
          });
          message.success("Employee created successfully!");
          onClose();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      });
  };

  const createEmployeeFunction = () => {
    const values = form?.getFieldsValue();

    if (!selectedRoleId) {
      createEmployee({
        ...values,
        branch: values?.branch?.toString(),
        city: values?.city?.toString(),
        erp: values?.username,
        name: values?.first_name,
        is_budget_approver: values?.is_budget_approver,
      });
    } else {
      handleUpdate({ ...values, is_active: isActive });
    }
  };

  const fetchRoleData = (params = {}) => {
    axios
      .get(`${urls.createEmpRole}`, { params: params })
      .then((response) => {
        if (response?.status === 200) {
          setRoleData(response?.data);
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      });
  };

  const handleSubmit = () => {
    setPage(1);
    let {
      role,
      city,
      branch,
      is_budget_approver,
    } = filterForm.getFieldsValue();
    let obj = {
      page: 1,
      page_size: rowsPerPage || 50,
    };
    if (role) {
      obj.role_id = role;
    }
    if (city) {
      obj.city = city;
    }
    if (branch) {
      obj.branch = branch;
    }
    if (search) {
      obj.search = search;
    }
    if (is_budget_approver == true) {
      obj.is_budget_approver = true;
    }
    if (is_budget_approver == false) {
      obj.is_budget_approver = false;
    }
    listEmpDetails(obj);
  };

  const handleGetData = () => {
    let {
      role,
      city,
      branch,
      is_budget_approver,
    } = filterForm.getFieldsValue();
    let obj = {
      page: page,
      page_size: rowsPerPage || 50,
    };
    if (role) {
      obj.role_id = role;
    }
    if (city) {
      obj.city = city;
    }
    if (branch) {
      obj.branch = branch;
    }
    if (search) {
      obj.search = search;
    }
    if (is_budget_approver == true) {
      obj.is_budget_approver = true;
    }
    if (is_budget_approver == false) {
      obj.is_budget_approver = false;
    }
    listEmpDetails(obj);
  };

  const handleStatusChange = (id, data) => {
    axios
      .put(`${urls.updateEmployeeDetails}${id}/`, data)
      .then((response) => {
        if (response?.status === 200) {
          handleGetData();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        form.resetFields();
        setRole("");
        setSelectedRoleId("");
        setIsUpdate(false);
      });
  };

  const handleUpdate = (data) => {
    axios
      .put(`${urls.updateEmployeeDetails}${selectedRoleId}/`, data)
      .then((response) => {
        if (response?.status === 200) {
          handleGetData();
          onClose();
        }
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      });
  };

  const handleDownloadList = () => {
    let values = filterForm.getFieldsValue();
    let params = {
      download: "true",
    };
    if (values?.role) {
      params.role_id = values.role;
    }
    if (values?.city) {
      params.city = values.city;
    }
    if (values?.branch) {
      params.branch = values.branch;
    }
    if (search) {
      params.search = search;
    }
    if (values?.is_budget_approver == true) {
      params.is_budget_approver = true;
    }
    if (values?.is_budget_approver == false) {
      params.is_budget_approver = false;
    }
    setDownloadLoading(true);
    axios
      .get(`${urls.downloadEmployeeReport}`, {
        params: params,
        responseType: "blob",
      })
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.toLowerCase().includes("application/json")) {
          return false;
        } else {
          const blob = window.URL.createObjectURL(
            new Blob([response?.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
            })
          );
          let link = document.createElement("a");
          link.href = blob;
          link.setAttribute("download", "Employee_Reports.csv");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        message.error(
          err?.response?.data?.message ??
            "Something Went Wrong. Please Try Again"
        );
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const editFunction = (data) => {
    setIsUpdate(true);
    setVisible(true);
    form.setFieldsValue({
      phone_no: data?.phone_no,
      username: data?.erp,
      city: data?.city?.map((cityObj) => cityObj.id) || [],
      branch: data?.branch?.map((branchObj) => branchObj.id) || [],
      first_name: data?.name,
      role_id: data?.role?.id,
      email: data?.user?.email,
      username: data?.user?.username,
      is_budget_approver:
        data?.is_budget_approver == true
          ? true
          : data?.is_budget_approver == false
          ? false
          : "",
    });
    setIsactive(data?.is_active);
    setSelectedRoleId(data?.id);
  };

  const budgetApproverOptions = budgetApproverList.map((each) => {
    return (
      <Select.Option key={each?.id} value={each?.value}>
        {each?.label}
      </Select.Option>
    );
  });

  const columns = [
    {
      title: " S.No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Erp No.",
      dataIndex: "erp",
      key: "erp",
      ellipsis: true,
    },
    {
      title: "Phone No.",
      dataIndex: "phone_no",
      key: "phone_no",
      ellipsis: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      ellipsis: true,
      render: (text, record) => <span>{record?.role?.role_name ?? "-"}</span>,
    },

    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (text, record) => (
        <span style={{ textTransform: "capitalize" }}>
          <Typography style={{ maxWidth: "150px" }}>
            <Typography.Text
              ellipsis={
                record?.city?.length > 3
                  ? {
                      tooltip: `${record?.city
                        ?.map((e) => e?.city_name)
                        ?.join(", ")}`,
                    }
                  : false
              }
            >
              {record?.city?.map((e) => e?.city_name)?.join(", ")}
            </Typography.Text>
          </Typography>
        </span>
      ),
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (text, record) => (
        <Typography style={{ maxWidth: "150px" }}>
          <Typography.Text
            ellipsis={
              record?.branch?.length > 3
                ? {
                    tooltip: `${record?.branch
                      ?.map((e) => e?.branch_name)
                      ?.join(", ")}`,
                  }
                : false
            }
          >
            {record?.branch?.map((e) => e?.branch_name)?.join(", ")}
          </Typography.Text>
        </Typography>
      ),
    },
    {
      title: "Is Budget Approver",
      dataIndex: "is_budget_approver",
      key: "is_budget_approver",
      render: (text, record) => (
        <span>
          {record?.is_budget_approver == true
            ? "Yes"
            : record?.is_budget_approver == false
            ? "No"
            : "-"}
        </span>
      ),
      width: 100,
    },

    {
      title: "Update",
      key: "update",

      render: (text, record) => (
        <>
          <AntdSpace size="">
            <AntdTag
              icon={<EditOutlined />}
              color="warning"
              style={{ cursor: "pointer" }}
              onClick={() => editFunction(record)}
            >
              Update
            </AntdTag>
          </AntdSpace>
        </>
      ),
    },
    {
      title: "Status",
      key: "is_active",
      render: (data) => (
        <AntdSwitch
          onChange={() => {
            setSelectedRoleId(data?.id);
            const updatedata = {
              phone_no: data?.phone_no,
              username: data?.erp,
              city: data?.city?.map((cityObj) => cityObj.id) || [],
              branch: data?.branch?.map((branchObj) => branchObj.id) || [],
              first_name: data?.name,
              role_id: data?.role?.id,
              email: data?.user?.email,
            };
            handleStatusChange(data?.id, {
              ...updatedata,
              is_active: !data?.is_active,
            });
          }}
          checked={data?.is_active}
        />
      ),
    },
  ];

  const cityOptions = cityList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.city_name}
    </Select.Option>
  ));
  const branchOptions = branchList?.map((each) => (
    <Select.Option key={each?.id} value={each?.id}>
      {each?.branch_name}
    </Select.Option>
  ));

  return (
    <>
      <Row
        style={{
          margin: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Col xs={24} sm={24} md={12} lg={10} xl={12}>
          <div className="page_title">
            <strong>Employee</strong>
          </div>
        </Col>

        <Col
          style={{
            display: "flex",
            justifyContent: "right",
            gap: "10px",
          }}
        >
          <Input
            value={search}
            style={{ width: "250px", height: "32px" }}
            placeholder="search"
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            disabled={!search?.length}
            type="primary"
            onClick={() => handleSearch()}
          >
            Search
          </Button>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => showDrawer()}
          >
            Add Employee
          </Button>
        </Col>
      </Row>
      <Card style={{ marginBottom: "10px", position: "sticky" }}>
        <Form
          id="filterForm"
          form={filterForm}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row align="middle" gutter={24}>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Form.Item name="role" label="Role :">
                <Select
                  placeholder={"Select Role"}
                  onChange={(e) => {
                    setRole(e);
                    setPage(1);
                  }}
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {roleData.map((data) => (
                    <Select.Option
                      value={data.id}
                      key={data.id}
                      name={data.role_name}
                    >
                      {data.role_name ? data.role_name : ""}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Form.Item name="city" label="City :">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  style={{ width: "100%" }}
                >
                  {cityOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Form.Item name="branch" label="Branch :">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select Branch"
                  style={{ width: "100%" }}
                >
                  {branchOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Form.Item
                name="is_budget_approver"
                label="Is Budget Approver? :"
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  placeholder="Select budget approval status"
                  style={{ width: "100%" }}
                >
                  {budgetApproverOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={6}>
              <Space>
                <Button
                  type="default"
                  onClick={() => {
                    filterForm.resetFields();
                    setRole("");
                  }}
                >
                  Clear All
                </Button>
                <Button form="filterForm" htmlType="submit" type="primary">
                  Get
                </Button>
                <Button
                  onClick={handleDownloadList}
                  type="primary"
                  loading={downloadLoading}
                  disabled={role ? false : true}
                >
                  Download
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
      <Table
        columns={columns}
        dataSource={employeeListData?.results}
        pagination={{
          current: page,
          total: employeeListData.count,
          pageSize: rowsPerPage,
          onChange: (current, pageSize) => {
            setPage(current);
            setRowsPerPage(pageSize);
          },
          pageSizeOptions: [50, 75, 100],
        }}
        style={{ backgroundColor: "#ffffff" }}
        scroll={{ y: "calc(100vh - 450px)", x: "max-content" }}
        loading={loading}
        rowKey={(e) => e?.id}
      />

      <AntdDrawer
        title={isUpdate ? "Update Employee Details" : "Add Employee"}
        width={window.innerWidth > 786 ? 500 : window.innerWidth - 10}
        onClose={() => {
          form.resetFields();
          onClose();
          setSelectedRoleId(null);
        }}
        open={visible}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button form="employee" type="primary" htmlType="submit">
              {isUpdate ? "Update" : "Add"}
            </Button>
          </div>
        }
      >
        <Form
          onFinish={createEmployeeFunction}
          id="employee"
          form={form}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col xs={24}>
              <Form.Item
                name="first_name"
                label="Name"
                rules={[{ required: true, message: "Please Enter Name" }]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please Enter Email" },
                  {
                    pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input placeholder="Email" type="email" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="phone_no"
                label="Phone Number"
                rules={[
                  { required: true, message: "Please Enter Phone Number" },
                ]}
              >
                <Input
                  maxLength={10}
                  placeholder="phone number"
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="username"
                label="ERP"
                rules={[{ required: true, message: "Please Enter ERP" }]}
              >
                <Input
                  type="text"
                  placeholder="ERP"
                  maxLength={11}
                  disabled={isUpdate}
                  onKeyPress={(e) => {
                    const pattern = /^[0-9]$/;
                    const inputChar = String.fromCharCode(e.charCode);
                    if (!pattern.test(inputChar)) {
                      e.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="city"
                label="City"
                rules={[{ required: true, message: "Please Select City" }]}
              >
                <Select mode="multiple" placeholder="Select City">
                  {cityOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="branch"
                label="Branch"
                rules={[{ required: true, message: "Please Select Branch" }]}
              >
                <Select mode="multiple" placeholder="Select Branch">
                  {branchOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="role_id"
                label="Role"
                rules={[{ required: true, message: "Please Select a Role" }]}
              >
                <Select
                  placeholder="Select Role"
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {roleData ? (
                    roleData.map((data) => (
                      <Select.Option key={data.id} value={data.id}>
                        {data.role_name}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option key="0" value="0">
                      None
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="is_budget_approver"
                label="Is Budget Approver ?"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Budget Approver",
                  },
                ]}
              >
                <Select
                  placeholder="Select Budget Approver Status"
                  showSearch
                  filterOption={(input, options) => {
                    return (options?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {budgetApproverOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntdDrawer>
    </>
  );
};

export default EmployeeListNew;
