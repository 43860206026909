/* eslint-disable no-multi-str */
import React, { useState,useEffect } from 'react';
import tinymce from 'tinymce';
import {
    Divider,
    Typography,
    Grid,
    TextField,
    MenuItem,
    Paper,
    Button,
    withStyles,
    Fab,
    Modal,
    Card,
    IconButton
  } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './instructions.Style';
import urls from '../../../url'
import { useAlert } from '../../../hoc/alert/alert'
import Loader from '../../../hoc/loader'
import useFetch from '../../../hoc/useFetch';
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ReactHtmlParser from 'react-html-parser';
import '../addQuestion/pluGins';
import Switch from '@material-ui/core/Switch';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import MediaQuery from 'react-responsive';
import Close from '@material-ui/icons/Close';


function Instructions ({classes}) {
  const [auth] = useState(JSON.parse(localStorage.getItem('user')));
  const [role, setRole] = useState('');
  const [roleDataVal,setRoleDataVal] = useState('')
  const [editor, setEditor] = useState(null);
  const [tinyInit, setTinyInit] = useState('iuy');
  const [editorsValue, setEditorsValue] = useState({instruction: 'ioi'})
  const [ins, setIns] = useState('');
  const [open ,setOpen] = useState(false);
  const [showEdit ,setShowEdit] = useState(false);
  const [status, setStatus] = useState(false);
  const [indexId ,setIndexId] = useState('')

  const alert = useAlert();

  const {
    data: roleData,
    isLoading: roleDataLoading,
    doFetch: fetchRole,
  } = useFetch([], { suppressAlert: true });

  const {
      data:AddInstructionRes,
      isLoading:AddInstructionResLoading,
      doFetch:fetchAddInstruction
  } = useFetch(null);

  const {
      data:getInstructionsData,
      isLoading:getInstructionsDataLoading,
      doFetch:fetchInstructionsData
  }=useFetch([], { suppressAlert: true })

  const {
      data:updateIndtuctionData,
      isLoading:updataInstructionDataLoading,
      doFetch:fetchUpdateInstruction
  } = useFetch(null)

  function afterAddEdit(){
    setOpen(false);
    setShowEdit(false)
    setRoleDataVal('')
    setEditorsValue({instruction:''})
    tinymce.editors.map(ed => {
      ed.setContent('')
    })
    setTinyInit(AddInstructionRes)
  }

  useEffect(()=>{
    if(AddInstructionRes || updateIndtuctionData){
      afterAddEdit();
      getInstructions()
    }
  },[AddInstructionRes,updateIndtuctionData])

  const handleClose = () => {
    afterAddEdit()
  };

  function getInstructions(){
      if(!role){
          alert.warning('please select Role')
          return
      }
    fetchInstructionsData({
        url:`${urls.getInstructionApi}?role=${role}`,
        method:'GET',
        headers:{
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.personal_info.token}`,
        },
    })
  }

   useEffect(() => {
    if (role||roleDataVal) {
      tinymce.init({
        selector: '#instruction',
        skin_url: `${process.env.PUBLIC_URL}/skins/lightgray`,
        plugins: 'image insertdatetime media table paste code wordcount searchreplace visualblocks code fullscreen advlist autolink lists charmap print preview anchor',
        toolbar: 'undo redo | formatselect | bold italic backcolor | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code',
        image_title: true,
        setup: editor => {
          // setEditor(editor)
          editor.on('keyup change', () => {
            const content = editor.getContent()
            setIns(content)
           
            setEditorsValue((vl) => {
                
              vl.instruction = content
              return vl
            })
          })
        },
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
          input.onchange = function () {
            var file = this.files[0];
            var reader = new FileReader();
            reader.onload = function () {
              var id = 'blobid' + (new Date()).getTime();
              var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(',')[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              cb(blobInfo.blobUri(), { title: file.name });
            };            
            reader.readAsDataURL(file);
          };
          input.click();
        }
      });
      return () => {
        tinymce.remove(editor);
      }
    }
  },[editor, role,roleDataVal])

  useEffect(()=>{
    fetchRole({
      url: urls.ocfpApplicantRollApi,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.personal_info.token}`,
      }
    });
  },[auth.personal_info.token,])

  function addInstructionFunction(){
      if(!role){
          alert.warning('Select Role')
          return
      }else if(!editorsValue.instruction){
        alert.warning('Enter Instruction')
        return
      }
      const data={
          'role':role,
          'instruction':editorsValue.instruction
      }
      fetchAddInstruction({
          url:urls.addInstructionApi,
          method:'POST',
          body:data,
          headers:{
            Authorization: `Bearer ${auth.personal_info.token}`,
            'Content-Type': 'application/json',
          },
      })
  }

  function showEditInstruction(index){
      setIndexId(index)
      setOpen(true)
      setShowEdit(true)
  }

  useEffect(()=>{
      if(showEdit){
        setRoleDataVal(getInstructionsData[indexId].role.id)
        setRole(getInstructionsData[indexId].role.id)
        setEditorsValue({instruction:getInstructionsData[indexId].instruction})
        setStatus(!(getInstructionsData[indexId].is_delete))
      }
  },[showEdit])

  function updateInstructionFunction(){
    if(!role){
      alert.warning('Select Role')
      return
      }else if(!editorsValue.instruction){
        alert.warning('Enter Instruction')
        return
      }
    const data={
        role_id:role,
        instruction:editorsValue.instruction,
        is_delete: status === true ? false : true
    }
    fetchUpdateInstruction({
        url:`${urls.updateInstructionApi}${getInstructionsData[indexId].id}/update_instructions/`,
        method:'PUT',
        body:data,
        headers:{
          Authorization: `Bearer ${auth.personal_info.token}`,
          'Content-Type': 'application/json',
        },

    })
  } 

  

  let loader = null;
  if (roleDataLoading || AddInstructionResLoading ||getInstructionsDataLoading ||updataInstructionDataLoading) {
    loader = <Loader open />;
  }

    return(
      <React.Fragment>
        <MediaQuery minWidth={451}> <Typography variant='h4'><strong>Instructions</strong>
          <Fab title = "Add Instructions" color="primary" aria-label="add" size="small" className={classes.fab} onClick={()=>{setOpen(true);setRole('');setShowEdit(false);setEditorsValue({instruction:''})}}>
           <AddIcon fontSizeSmall></AddIcon>
          </Fab>
        </Typography>
        </MediaQuery>
        <MediaQuery maxWidth={450}> <Typography className="headings" variant='h4'><strong>Instructions</strong>
          <Fab title = "Add Instructions" color="primary" aria-label="add" size="small" className={classes.fab} onClick={()=>{setOpen(true);setRole('');setShowEdit(false);setEditorsValue({instruction:''})}}>
           <AddIcon fontSizeSmall></AddIcon>
          </Fab>
        </Typography>
        </MediaQuery>
       <Divider className={classes.divider} />
          <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          // onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
        <Fade in={open}>
        <Paper className={classes.paper}>
        {/* <Typography className="headings" variant='h4'><strong>{showEdit ?'Update Instructions':'Create Instructions'}</strong></Typography> */}
        <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginBottom: 10 ,justifyContent: "space-between" }}
              >
                <Grid item>
                  <IconButton size="small" style={{ visibility: "hidden" }}>
                    <Close />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography className="headings" variant="h4">
                    <strong> {showEdit ?'Update Instructions':'Create Instructions'}</strong>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => handleClose()}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
        <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextField
                label="Role"
                // className={classes.textField}
                fullWidth
                className="textList"
                margin="dense"
                required
                onChange={e => setRole(e.target.value)}
                select
                disabled={showEdit===true}
                value={role || ''}
                variant="outlined"
              >
                {roleData
                  ? (roleData.map(data => (
                    <MenuItem
                        value={data.id}
                        key={data.id}
                        name={data.applicant_role_name}
                    >
                        {data.applicant_role_name ? data.applicant_role_name : ''}
                    </MenuItem>
                    ))):''}
                </TextField>
                </Grid>
            </Grid>
            <Divider className={classes.divider} />
            {role &&<Typography variant="h6">Instruction :</Typography>}
                {role &&<Grid item md={12} xs={12}>
                  <textarea
                    id='instruction'
                    className='dgf'
                    style={{ height: 250 }}
                    value={editorsValue['instruction']||''}
                    // onChange={(e)=>setEditorsValue(e.target.value)}
                  />
                </Grid>}
                {/* <Divider className={classes.divider} /> */}
                {showEdit === true && 
                 <Grid item md={12} xs={12}>
                  <Typography variant="h6">Set This Instruction as Active/InActive :</Typography>
                      In Active
                      <Switch 
                          checked={status|| false}
                          value={status|| false}
                          onChange={(e)=> setStatus(e.target.checked)}
                          color="primary"
                      />Active
                  </Grid>
                }
               {/* <Divider/> */}
               <Grid container align="center" justify="center" style={{marginTop:"10px"}}>
                <Grid item md={2} xs={12} >
                  <Button
                    // className={classes.applyButton}
                    color="primary"
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={() => {showEdit ? updateInstructionFunction():addInstructionFunction()}}
                  >
                    {showEdit ?'Update':'Create'}
                  </Button>
                </Grid>
                </Grid>
                
           </Paper>
           </Fade>
           </Modal>
           {loader}
           <React.Fragment>
              <Paper className={classes.paperpaper}>
                {/* <Typography variant='h5' >View Instructions</Typography>  */}
                 <Grid container spacing={2}>
                   <Grid item md={4} xs={12}>
                    <TextField
                      label="Role"
                      // className={classes.textField}
                      fullWidth
                      className="textList"
                      margin="dense"
                      required
                      onChange={e => setRole(e.target.value)}
                      select
                      value={role || ''}
                      variant="outlined"
                    >
                      {roleData
                        ? (roleData.map(data => (
                          <MenuItem
                              value={data.id}
                              key={data.id}
                              name={data.applicant_role_name}
                          >
                              {data.applicant_role_name ? data.applicant_role_name : ''}
                          </MenuItem>
                          ))):''}
                      </TextField>
                      </Grid>
                      <Grid item md={4} xs={12} className={classes.applyButtonSection}>
                        <Button
                          className={classes.applyButton}
                          color="primary"
                          variant="contained"
                          size="large"
                          onClick={() => getInstructions()}
                        >
                          View Instruction
                        </Button>
                      </Grid>
                  </Grid>
               </Paper>
           </React.Fragment>
         <React.Fragment>
           {getInstructionsData && getInstructionsData.length===0 && <Typography variant='h4' style={{color:'blue',textAlign:"center",marginTop:'10%'}}>Instructions Not Found</Typography> || ''}
               {getInstructionsData && getInstructionsData.map((item,index)=>{
                    return(<Paper elevation={4} className={classes.questionWrap}>
                    <Card className={classes.questionContainer}>
                      <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                          <h3 style={{float:'left'}}>Instruction {index+1}</h3>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          {item.is_delete ?  
                            <Button disabled style={{color:"black"}}> In Active &nbsp;<CancelIcon style={{ color: 'red' }} /></Button> :
                            <Button disabled style={{color:"black"}}> Active &nbsp; <CheckCircleRoundedIcon style={{ color: 'green' }} /></Button>
                           }
                        </Grid>
                        <Grid item md={1} xs={12}>
                        <Button className ={classes.editbutton} onClick={()=>showEditInstruction(index)}>Update</Button><br/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <p>{ReactHtmlParser(item.instruction)}</p>
                        </Grid>
                      </Grid>
                   </Card>
                   </Paper>)
               })}
           </React.Fragment>
        </React.Fragment>          
    );
}
Instructions.propTypes = {
    classes: PropTypes.instanceOf(Object).isRequired,
  };
export default withStyles(styles)(Instructions);