/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { List, Modal, Pagination } from "antd";
import urls from "../../../url";

function Parameters({ classes, onCloseFunction, open, title }) {
  let param_id = localStorage.getItem("param_id");
  const [userData] = useState(JSON.parse(localStorage.getItem("user")));
  const [rowsPerPage, setRowsPerPage] = React.useState(null);
  const [page, setPage] = useState(1);
  const [questionData, setQuestionData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchQuestionsVal(param_id).then((data) => {
      setQuestionData(data);
    });
  }, [page, param_id, rowsPerPage, userData]);

  useEffect(() => {
    if (questionData) {
      setLoading(false);
    }
  }, [questionData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    !rowsPerPage && setRowsPerPage(10);
  };

  async function fetchQuestionsVal(id) {
    setLoading(true);
    const response = await fetch(
      `${
        urls.getQuestionsVal
      }?parameter_id=${id}&page=${page}&page_size=${rowsPerPage || 10}`,
      {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData.personal_info.token}`,
        },
      }
    );
    const getData = await response.json();
    return getData;
  }
  return (
    <React.Fragment>
      <Modal
        open={open}
        onCancel={onCloseFunction}
        footer={
          <Pagination
            showSizeChanger={false}
            defaultCurrent={1}
            current={page}
            total={questionData?.count}
            style={{ textAlign: "end" }}
            onChange={(page, pageSize) => {
              handleChangePage(page, page);
            }}
          />
        }
        title={title ?? "Questions"}
      >
        <List
          style={{ maxHeight: "60vh", overflowY: "scroll" }}
          bordered
          loading={loading}
          dataSource={questionData?.results}
          renderItem={(item) => <List.Item>{item?.question}</List.Item>}
        />
      </Modal>
    </React.Fragment>
  );
}

export default Parameters;
