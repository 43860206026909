import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {
  Typography,
  Divider,
  withStyles,
  Grid,
  Button,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Tab,
  Tabs,
  AppBar,
  TableFooter,
  TablePagination,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import urls from '../../../url'
import useFetch from '../../../hoc/useFetch';
import Loader from '../../../hoc/loader'
import styles from './faceToFace.Style';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useAlert } from '../../../hoc/alert/alert'

const FaceToFace = ({ classes }) => {
  const [name] = useState(JSON.parse(localStorage.getItem('user')));
  const [value, setValue] = React.useState(0);
  const [filteredSub, setFilteredSub] = useState([]);
  const [filteredBranch, setFilteredBranch] = useState([]);
  const [Id, setId] = React.useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pagination ,setPagination] = useState(false);
  const [pageChange ,setPageChange] = useState(false)
  const [profileName ,setProfileName] = useState('')

  const alert = useAlert();

  function handleChange(event, newValue) {
    setValue(newValue);
    setId(profileDetail[newValue].id)
    setProfileName(profileDetail[newValue].applicant_role_name)
  }

  const {
    data: subjectDetail,
    isLoading: subjectDetailLoading,
    doFetch: fetchSubject,
  } = useFetch(null);

  const {
    data: branch,
    isLoading: branchLoading,
    doFetch: fetchBranch,
  } = useFetch(null);

  const {
    data: profileDetail,
    isLoading: profileDetailLoading,
    doFetch: fetchProfile,
  } = useFetch(null);

  const {
    data: applicantsList,
    isLoading: applicantsListLoading,
    doFetch: fetchApplicant,
  } = useFetch(null);

  let loader = null;
  if (applicantsListLoading || profileDetailLoading || branchLoading ||subjectDetailLoading) {
    loader = <Loader open />;
  }

  useEffect(() => {
    if (name) {
      fetchProfile({
        url: urls.FaceToFaceRolesApi,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${name.personal_info.token}`,
          'Content-Type': 'application/json',
        },
      });
      fetchSubject({
        url: urls.subjectApiView,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
      fetchBranch({
        url: urls.branch,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [fetchProfile, name,fetchSubject,fetchBranch]);


  useEffect(() => {
    if (profileDetail && profileDetail.length) {
      setId(profileDetail[0].id);
      setProfileName(profileDetail[0].applicant_role_name)
    }
  }, [profileDetail])

  useEffect(() => {
    if (Id) {
      setPageChange(false)
      setStartDate('')
      setEndDate('')
      setFilteredBranch('')
      setFilteredSub('')
      fetchApplicant({
        url: `${urls.FaceToFaceScoreRetriveApi}?role_id=${Id}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
  }, [Id, fetchApplicant, name])

  useEffect(()=>{
      if(applicantsList){
        setPageChange(true)
      }
  },[applicantsList])

  const handleStartDateChange = (val) => {
    setStartDate(val)
  }
  const handleEndDateChange = (val) => {
    setEndDate(val);
  }
  const handleGetData = () => {
    if (startDate === '') {
      alert.warning('Please Select Start Date..!')
      return;
    }
    if (endDate === '') {
      alert.warning('Please Select End Date..!')
      return;
    }
    setPage(0)
    fetchApplicant({
      url: `${urls.FaceToFaceScoreRetriveApi}?role_id=${Id}&page_size=${rowsPerPage || 10}&page=1&start_date=${startDate}&end_date=${endDate}${filteredBranch && filteredBranch.length !==0 ? `&branch=${filteredBranch}`:''}${filteredSub && filteredSub.length!==0 ? `&subject=${filteredSub}`:''}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    })
  }

  const handleBranch = (value) => () => {
    const currentIndex = filteredBranch.indexOf(value);
    const newChecked = [...filteredBranch];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredBranch(newChecked);
  };

  const handleSub = (value) => () => {
    const currentIndex = filteredSub.indexOf(value);
    const newChecked = [...filteredSub];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setFilteredSub(newChecked);
  };

  const applyFilter = () => {
    if(filteredBranch === '' && filteredSub === '' ){
      alert.warning('select fields either branch or subject')
      return
    }
    if(((filteredBranch &&filteredBranch.length===0) && filteredSub === '') || (filteredBranch === '' && (filteredSub && filteredSub.length===0))){
      alert.warning('select fields either branch or subject')
      return
    }
    if((filteredBranch &&filteredBranch.length===0) && (filteredSub && filteredSub.length===0)){
      alert.warning('select fields either branch or subject')
      return
    }
    setPage(0)
    fetchApplicant({
      url: `${urls.FaceToFaceScoreRetriveApi}${filteredBranch.length>0 && filteredSub.length===0  ? `?branch=${filteredBranch}`: filteredBranch.length>0 && filteredSub.length>0 ? `?branch=${filteredBranch}`:''}${filteredSub.length>0 && filteredBranch.length===0  ? `?subject=${filteredSub}`: filteredSub.length>0 &&filteredBranch.length>0  ?`&subject=${filteredSub}`:''}&role_id=${Id}&page=1&page_size=${rowsPerPage || 10}${startDate ? `&start_date=${startDate}`:''}${endDate ? `&end_date=${endDate}`:''}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${name.personal_info.token}`,
      },
    });
  }

  useEffect(() => {
    if (pagination) {
      fetchApplicant({
        url: `${urls.FaceToFaceScoreRetriveApi}?&role_id=${Id}&page=${page + 1}&page_size=${rowsPerPage || 10}${filteredBranch && filteredBranch.length !==0 ? `&branch=${filteredBranch}`:''}${filteredSub && filteredSub.length!==0 ? `&subject=${filteredSub}`:''}${startDate ? `&start_date=${startDate}`:''}${endDate ? `&end_date=${endDate}`:''}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${name.personal_info.token}`,
        },
      });
    }
    setPagination(false)
  }, [page, fetchApplicant, rowsPerPage, name, Id]);


  function handleChangePage(event, newPage) {
    setPagination(true)
    setPage(newPage)
   {!rowsPerPage && setRowsPerPage(10)} 
  }

  function handleChangeRowsPerPage(event) {
    setPagination(true)
    setRowsPerPage(event.target.value);
    setPage(0);
  }
  function firstPageChange() {
    setPagination(true)
    setPage(0)
  }
    
  function lastPageChange(lastPage) {
    setPagination(true)
    setPage(lastPage)
    {!rowsPerPage && setRowsPerPage(10)} 
  };

  const subjectList = () => {
    let subject = null;
    if (subjectDetail && subjectDetail.length) {
      subject = subjectDetail.map(value => {
        const subId = `Sub Id : ${value.id}`;
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleSub(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredSub.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': subId }}
              />
            </ListItemIcon>
            <ListItemText id={subId} primary={value.subject_name} />
          </ListItem>
        );
      })
    }
    return subject;
  }

  const branchList = () => {
    let branchDetail = null;
    if (branch && branch.length) {
      branchDetail = branch.map(value => {
        const branchId = `Branch Id : ${value.id}`;
        return (
          <ListItem key={value.id} role={undefined} dense button onClick={handleBranch(value.id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                color="primary"
                checked={filteredBranch.indexOf(value.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': branchId }}
              />
            </ListItemIcon>
            <ListItemText id={branchId} primary={value.branch_name} />
          </ListItem>
        );
      })
    }
    return branchDetail;
  }
  function addScore(a,b,c,d){
    return ((((a.length!==0 &&  parseInt(a[0]))  || 0) + ((b.length!==0 &&  parseInt(b[0]))  || 0)+ ((c.length!==0 && parseInt(c[0])) || 0)+((d.length!==0 &&  parseInt(d[0]))  || 0) )===0 ? '' :((a.length!==0 &&  parseInt(a[0]))  || 0) + ((b.length!==0 &&  parseInt(b[0]))  || 0)+ ((c.length!==0 && parseInt(c[0])) || 0)+((d.length!==0 &&  parseInt(d[0]))  || 0))
  }

  function profileTable() {
    let applicantTable = null;

    if(applicantsList && applicantsList.results && applicantsList.results.length===0 && pageChange){
      applicantTable = (
        <React.Fragment>
          <Typography variant='h5' style={{color:'blue',textAlign:'center',marginTop:'17%'}}>
            Records Not Found
          </Typography>
        </React.Fragment>
      )
    }
    if (applicantsList && applicantsList.results && applicantsList.results.length && pageChange) {
      applicantTable = (
        <React.Fragment>
           <Paper style={{ marginTop: '10px' }}>
          <Table>
            <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="center">Round 1 <br/> (Score out of 20)</TableCell>
                  {(profileName ==="Coordinator"   || profileName === "Principal") && <TableCell align="center">Round 2 <br/> (Score out of 20)</TableCell>}
                  {profileName === "Principal" && <TableCell align="center">Round 3 <br/> (Score out of 20)</TableCell>}
                  <TableCell align="center">Total <br/>{profileName=== "Teacher" ? '(Score out of 20)':''}{profileName=== "Coordinator" ? '(Score out of 40)':''}{profileName === "Principal" ? '(Score out of 60)':''}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {applicantsList.results.map((details, i) => (
                <TableRow key={details.id}>
                  <TableCell align="left">{i+1}</TableCell>
                  <TableCell align="left">{(details.applicant && details.applicant.user && details.applicant.user.first_name && details.applicant.user.first_name) || '' }</TableCell>
                  {profileName=== "Teacher" ? <TableCell align="center">{(details.f2f_details && details.f2f_details && details.f2f_details.filter(check =>check.round.round_name === "Planner Round").map((items)=>{return items.mark_scored})) || ''}</TableCell>:''}
                  {(profileName=== "Coordinator" || profileName=== "Principal") && <TableCell align="center">{(details.f2f_details && details.f2f_details && details.f2f_details.filter(check =>check.round.round_name === "Stress Round").map((items)=>{return items.mark_scored})) || ''}</TableCell>}
                  {(profileName === "Coordinator" || profileName === "Principal") && <TableCell align="center">{(details.f2f_details && details.f2f_details && details.f2f_details.filter(check =>check.round.round_name === "Leadership Round").map((items)=>{return items.mark_scored})) || ''}</TableCell>}
                  {profileName === "Principal" && <TableCell align="center">{(details.f2f_details && details.f2f_details && details.f2f_details.filter(check =>check.round.round_name === "Cultural Fit Round").map((items)=>{return items.mark_scored})) || ''}</TableCell>}
                  <TableCell align="center">{addScore(((details.f2f_details && details.f2f_details && details.f2f_details.filter(check =>check.round.round_name === "Stress Round").map((items)=>{return items.mark_scored}))|| 0),((details.f2f_details && details.f2f_details && details.f2f_details.filter(check =>check.round.round_name === "Leadership Round").map((items)=>{return items.mark_scored})) || 0),((details.f2f_details && details.f2f_details && details.f2f_details.filter(check =>check.round.round_name === "Planner Round").map((items)=>{return items.mark_scored})) || 0),((details.f2f_details && details.f2f_details && details.f2f_details.filter(check =>check.round.round_name === "Cultural Fit Round").map((items)=>{return items.mark_scored})) || 0))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  labelDisplayedRows={() => `Page ${page + 1} of ${+applicantsList.total_pages}`}
                  rowsPerPageOptions={[10, 20, 30]}
                  count={+applicantsList.count}
                  rowsPerPage={rowsPerPage || 10}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'Rows per page' },
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <TableCell style={{ marginTop: '13px' }}>
                  <IconButton
                    onClick={firstPageChange}
                    disabled={page === 0 || page === 1}
                  >
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => lastPageChange(applicantsList.total_pages - 1)}
                    disabled={page === (+applicantsList.total_pages - 1)}
                  >
                    <LastPageIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
            </Paper>
        </React.Fragment>
      )
    }
    return applicantTable;
  }

  return (
    <React.Fragment>
       <Grid container spacing={2} className={classes.modalPaper}>
         <Grid item sm={12} md={4} xs={12}>
          <Typography variant="h4">Face To Face</Typography>
         </Grid>
         <Grid item sm={5} md={3} xs={5}>
           <TextField
              style={{ marginLeft: '10px' }}
              helperText="Start Date"
              className={classes.textField}
              margin="dense"
              required
              fullWidth
              onChange={e => handleStartDateChange(e.target.value)}
              type="date"
              value={startDate || ' '}
              variant="outlined"
            />
        </Grid>
        <Grid item sm={5} md={3} xs={5}>
          <TextField
            style={{ marginLeft: '10px' }}
            helperText="End Date"
            className={classes.textField}
            margin="dense"
            required
            fullWidth
            onChange={e => handleEndDateChange(e.target.value)}
            type="date"
            value={endDate || ' '}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={2} md={2} xs={2}>
          <Button variant="contained" color="primary" className={classes.button} style={{ marginTop: '10px', marginLeft: '10px' }} onClick={handleGetData}>Get</Button>
        </Grid>
       </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={3}>
      <Grid item md={3} className={classes.paperMain}>
          <Typography variant="h6" className={classes.heading}>Filter By</Typography>
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classes.heading}>Subjects</Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                {subjectList()}
              </Paper>
            </Grid>
          </List>
          <Typography variant="h6" className={classes.heading}>Branch</Typography>
          <List className={classes.root}>
            <Grid className={classes.fixed}>
              <Paper>
                {branchList()}
              </Paper>
            </Grid>
          </List>
          <Grid className={classes.loginButton}>
            <Button
              className={classes.Button}
              color="primary"
              variant="contained"
              size="large"
              onClick={applyFilter}
            >
              Apply Filter
              </Button>
          </Grid>
        </Grid>
        <Grid item md={9} className={classes.paperMain}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {profileDetail && profileDetail.length && profileDetail.map((role, i) => (
                <Tab style={{width:'40%',marginLeft:'8%'}} key={role.id} label={role.applicant_role_name} />
              ))}
            </Tabs>
          </AppBar>
            {profileTable()}
        </Grid>
      </Grid>
      {loader}
    </React.Fragment>
  )
}

FaceToFace.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(FaceToFace);
