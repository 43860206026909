import React, { useState, useEffect } from "react";
import urls from "../../url";
import useFetch from "../../hoc/fetch/useFetch";
import {
  Typography,
  withStyles,
  Divider,
  Grid,
  Paper,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Loader from "../../hoc/loader";
import styles from "./TransferRequest.style";

function AcceptedRequests({ classes, requestType }) {
  const [auth] = useState(JSON.parse(localStorage.getItem("user")));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [viewReason, setViewReason] = useState("");

  const {
    data: acceptedList,
    isLoading: acppetedListLodaing,
    doFetch: fetchAcceptedList
  } = useFetch(null);

  let loader = null;
  if (acppetedListLodaing) {
    loader = <Loader open />;
  }
  const viewReasonClick = reason => {
    setOpen(true);
    setViewReason(reason);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchAcceptedList({
      url: `${urls.acceptedRequests}?request_type=${"accepted"}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth.personal_info.token}`
      }
    });
  }, []);
  return (
    <div>
      <div>
        <Paper className={classes.root}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell align='center' id='nameHead'>
                  Name
                </TableCell>
                <TableCell align='center' id='dateHead'>
                  Erp
                </TableCell>
                <TableCell align='center' id='scoreHead'>
                  Mobile
                </TableCell>
                <TableCell align='center' id='scoreHead'>
                  {requestType === "in_request" ? "From Branch " : "To Branch"}
                </TableCell>
                <TableCell align='center' id='scoreHead'>
                  Reason
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acceptedList &&
                acceptedList.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell align='center'>{row.transfer.name}</TableCell>
                    <TableCell align='center'>{row.transfer.erp}</TableCell>
                    <TableCell align='center'>{row.transfer.mobile}</TableCell>
                    {/* <TableCell align='center'>{row.email}</TableCell> */}
                    <TableCell align='center'>
                      {requestType === "accepted"
                        ? row.transfer.from_branch.branch_name
                        : row.transfer.to_branch.branch_name}
                    </TableCell>
                    <TableCell align='center' id='scoreHead'>
                      {/* {row.reason_of_transfer} */}
                      <Button
                        size='small'
                        fullWidth
                        color='primary'
                        variant='contained'
                        onClick={() => viewReasonClick(row.reason_of_transfer)}
                      >
                        Reason To Transfer
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {acceptedList && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component='div'
              count={acceptedList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          )}
        </Paper>
        <Dialog
          open={open}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' style={{ textAlign: "center" }}>
            Reason for Transfer{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <p>{viewReason}</p>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        {loader}
      </div>
    </div>
  );
}

export default withStyles(styles)(AcceptedRequests);
